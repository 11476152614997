/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SendNotification from "../../components/SendNotification";
import {
  EmptyState,
  dialogStyle,
  formatDate,
  formatServerValue,
  isArrayWithValues,
  settingsEndpoint,
  validateNumber,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CustomChip from "../../components/CustomChip";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";

const PushNotification = ({ customerInfo }) => {
  const [showSendNotification, setShowNotification] = useState(false);
  const pushNotificationCustomerObject = useSelector(
    (state) => state.chats.pushNotificationCustomerObject
  );
  const dispatch = useDispatch()

  const singalNotificationHistory = useSelector(state => state.chats?.[`push_notification_history_${customerInfo?.id}`])
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false)
  useEffect(() => {

    setLogs(pushNotificationCustomerObject?.[customerInfo?.id] || []);

    return () => { };
  }, [pushNotificationCustomerObject, customerInfo?.id]);

  useEffect(async () => {
    if (!singalNotificationHistory) {
      setLoading(true)
      let historyRes = await settingsEndpoint({
        endpoint: `push_notification_history_${customerInfo?.id}`,
        method: 'GET',
      })
      setLoading(false)
      dispatch({
        type: EDIT_CHATS,
        payload: {
          [`push_notification_history_${customerInfo?.id}`]: historyRes
        }
      })
    }

    return () => {

    }
  }, [])

  useEffect(() => {

    setLogs(pre => ([...pre, ...(Object.values(singalNotificationHistory || {}))]))
    return () => {

    }
  }, [singalNotificationHistory,])


  return (
    <Box p={"0 14px"}>
      <Stack
        direction={"row"}
        alignContent={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Push Notification</Typography>
        {isArrayWithValues(customerInfo?.push_notify) ? (
          <Button onClick={() => setShowNotification(true)} variant="contained">
            Send Notification
          </Button>
        ) : null}
      </Stack>
      <PushNotificationTable rows={logs} loading={loading} />
      <Dialog
        fullWidth
        maxWidth="md"
        sx={dialogStyle}
        // fullScreen={fullScreen}
        open={showSendNotification}
        onClose={() => setShowNotification(false)}
        aria-labelledby="responsive-dialog-title"
      // {...globalPopupStyle}
      >
        <DialogTitle id="responsive-dialog-title">
          Push notifications
        </DialogTitle>
        <DialogContent>
          <SendNotification
            onClose={() => setShowNotification(false)}
            include_external_user_ids={[customerInfo?.id?.toString()]}
          // include_player_ids={
          // [
          //   "bd75b32d-864f-45b9-b914-5987d3b8cc57",
          //   "322a1fcf-edf4-4038-bea9-fce0a1dea317",
          //   "8b1ed032-982c-4a81-9c4d-7d03d7327794",
          // ]
          // customerInfo?.push_notify
          // }
          />
        </DialogContent>
      </Dialog>

      {/* <SendNotification /> */}
    </Box>
  );
};

export default PushNotification;

const PushNotificationTable = ({ rows, loading }) => {
  return (
    <>
      {loading ?
        <Stack
          sx={{ height: "60vh" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          rowGap={3}
        >
          <CircularProgress />
          <Typography variant="h6">Getting logs...</Typography>
        </Stack>

        :
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Sent At</TableCell>
                <TableCell>Campaign</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArrayWithValues(rows) ? rows?.sort((a, b) => validateNumber(b?.time) - validateNumber(a?.time))?.map((row) => (
                <TableRow
                  key={row.time}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.payload?.name}
                  </TableCell>
                  <TableCell>{row?.payload?.contents?.en}</TableCell>
                  <TableCell>
                    <CustomChip
                      type={
                        row?.status === "invalid_external_user_ids"
                          ? "failed"
                          : row?.status
                      }
                      status={formatServerValue(row?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">

                      {formatDate(new Date(row?.time), "dd/mm/yyyy")}
                    </Typography>

                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">

                      {row?.campaign_name || "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )) : <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell colSpan={5}>
                  {/* <Typography>No Sms Template found!</Typography> */}
                  <EmptyState text={"No Personalize Sent Yet!"} />
                </TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>}
    </>
  );
};
