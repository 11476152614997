import CustomUser from "./components/CustomUser";
import CustomBot from "./components/CustomBot";
import CustomStart from "./components/CustomStart";
import CustomDefaultFallback from "./components/CustomDefaultFallback";
import UserAttachment from "./components/UserAttachment";
import Question from "./components/Question";
import Success from "./components/Success";
import Failure from "./components/Failure";
import Filter from "./components/Filter";
import Subscribe from "./components/Subscribe";
import Unsubscribe from "./components/Unsubscribe";
import GoToStep from "./components/GoToStep";
import WhatsappTemplate from "./components/WhatsappTemplate";
import LiveAgent from "./components/LiveAgent";
import ProductCatalog from "./components/ProductCatalog";
import Email from "./components/Email";
import Sms from "./components/Sms";
import PushNotification from "./components/PushNotification";
import Delay from "./components/Delay";

export const BotNode = ({ data }) => {
  return <CustomBot data={data} />;
};

export const UserNode = ({ data }) => {
  return <CustomUser data={data} />;
};

export const StartNode = ({ data }) => {
  return <CustomStart data={data} />;
};

export const DefaultFallback = () => {
  return <CustomDefaultFallback />;
};

export const UserAttachmentNode = () => {
  return <UserAttachment />;
};

export const QuestionNode = () => {
  return <Question />;
};

export const SuccessNode = () => {
  return <Success />;
};

export const FailureNode = () => {
  return <Failure />;
};

export const FilterNode = () => {
  return <Filter />;
};

export const SubscribeNode = () => {
  return <Subscribe />;
};

export const UnsubscribeNode = () => {
  return <Unsubscribe />;
};

export const GoToStepNode = () => {
  return <GoToStep />;
};

export const WhatsappTemplateNode = () => {
  return <WhatsappTemplate />;
};

export const LiveAgentNode = () => {
  return <LiveAgent />;
};

export const ProductCatalogNode = () => {
  return <ProductCatalog />;
};

export const EmailNode = () => {
  return <Email />;
};

export const SmsNode = () => {
  return <Sms />;
};

export const PushNotificationNode = () => {
  return <PushNotification />;
};

export const DelayNode = () => {
  return <Delay />
}
