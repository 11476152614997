export const goToBlockPopover = {
  outerBox: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
    width: "315px",
    marginBottom: "10px",
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-around",
    alignItems: "center",
    padding: "0 15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontFamily: "Inter",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
  mainTitle: {
    color: "#9ba6b3",
    fontSize: "15px",
    marginBottom: "10px",
    fontWeight: 600,
  },
  blockName: {
    color: "#22272d",
    fontSize: "15px",
  },
};
