import { FiberManualRecord, History } from "@material-ui/icons";
import {
  Add,
  Circle,
  Language,
  PhoneIphone,
  ProductionQuantityLimits,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  allStockStatus,
  dialogStyle,
  EmptyState,
  fetchProducts,
  formatDate,
  formatServerValue,
  getAxiosError,
  getRelativeTimeDifference,
  getToken,
  getWebsite,
  globalColorsLight,
  isArrayWithValues,
  isObjWithValues,
  jweroColorPalette,
  sectionLabel,
  StyledTableRow,
  validateNumber,
} from "../../../helper";
import { updateCustomerInRedux } from "../../../redux/actions/crmActions";
import { paymentMethods } from "../../../redux/actions/productActions";
import {
  DigigoldMenuSvg,
  NoOrder,
  OrdersIllustration,
  UserDigigoldSvg,
} from "../../../Svgs";
import { TotalCard } from "../../catalogs/CatalogHelper";
import { NoTasks } from "../../chat/ChatHelper";
import { currencySymbols } from "../../pricing/Gold";
import SelectProducts from "../../components/SelectProducts";
import { Divider } from "@material-ui/core";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import CustomChip from "../../components/CustomChip";

const Orders = ({ customerInfo, setCustomerInfo }) => {
  const [customer, setCustomer] = useState(customerInfo);
  useEffect(() => {
    setCustomer(customerInfo);
  }, [customerInfo]);
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const paymentsMethods = useSelector((state) => state.product.PaymentMethod);
  const disptach = useDispatch();
  useEffect(() => {
    if (!paymentsMethods) disptach(paymentMethods());
  }, []);
  const [paymentGetWaysArray, setPaymentGetWaysArray] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isArrayWithValues(paymentsMethods))
      setPaymentGetWaysArray(paymentsMethods);
  }, [paymentsMethods]);

  useEffect(() => {
    navigate(`/crm/${id}/#orders`);
  }, [id]);

  useEffect(async () => {
    let website = getWebsite();
    let token = getToken();
    if ((!website && !token) || !customerInfo?.id) return;
    setFetchingOrders(true);
    try {
      const { data: orderIds } = await axios({
        url: `${website}/wp-json/store/v1/customer/${customerInfo?.id}/orders`,
        // params: { include: customerInfo.orders.join(",") },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(orderIds, "<<<<< ids");
      if (isArrayWithValues(orderIds.data)) {
        const response = await axios({
          url: `${website}/wp-json/wc/v3/orders`,
          params: { include: orderIds.data.join(",") },
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        // console.log(orderIds, "<<<<< ids");

        setCustomerInfo({ ...customerInfo, ordersList: response.data });

        dispatch(
          updateCustomerInRedux({ ...customerInfo, ordersList: response.data })
        );
      } else {
        throw new Error("Order ids not found not found");
      }
    } catch (error) {
      setCustomerInfo({ ...customerInfo, ordersList: [] });
      dispatch(updateCustomerInRedux({ ...customerInfo, ordersList: [] }));
      console.log(error, "<<<,orders");
    }
    setFetchingOrders(false);
  }, [customerInfo?.id]);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [viewOrderDailog, setViewOrderDailog] = useState(false);
  return (
    <Box sx={{ backgroundColor: "#fff", padding: 4, marginTop: 3 }}>
      {/* <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="h5">Customer Orders</Typography>
        <Button
          variant="contained"
          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          onClick={(e) => {
            setViewOrderDailog(true);
          }}
        >
          Add
        </Button>
      </Stack> */}
      {fetchingOrders && <LoadingCustomerData />}
      {isArrayWithValues(customerInfo.ordersList) && !fetchingOrders && (
        <NewOrder
          customerInfo={customerInfo}
          isIpad={isIpad}
          navigate={navigate}
          setViewOrderDailog={setViewOrderDailog}
        />
      )}
      {isArrayWithValues(customerInfo.ordersList) && !fetchingOrders && false && (
        <TableContainer sx={{ width: "100%" }} component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead>
              {isIpad ? (
                <TableRow>{null}</TableRow>
              ) : (
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Mode</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Products</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isArrayWithValues(customerInfo.ordersList) &&
                customerInfo.ordersList.map((order) => (
                  <React.Fragment key={order.id}>
                    {isIpad ? (
                      <TableRow sx={{ width: "100%" }}>
                        <TableCell
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="button">
                            Order #{order.id}
                          </Typography>
                          <Typography variant="button">
                            {order.currency_symbol}
                            {order.total}
                            <Typography
                              variant="caption"
                              sx={{ color: "#525F4" }}
                            >
                              {/* {order.total_tax && `-Tax : ${order.total_tax}`} */}
                              {order.total_tax &&
                                `-${order?.tax_lines?.[0]?.label} : ${order.total_tax}`}
                            </Typography>
                          </Typography>
                          <Typography>
                            <Circle
                              fontSize="small"
                              style={{
                                height: "8px",
                                width: "7px",
                                marginRight: "8px",
                                color:
                                  order.status === "completed" ||
                                  order.status === "pending" ||
                                  order.status === "processing"
                                    ? "#2DCE89"
                                    : "#ff0000",
                              }}
                            />
                            {order.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        hover
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/orders/${order.id}`)}
                      >
                        <TableCell>
                          <Typography variant="h6">
                            Order #{order.id}
                          </Typography>

                          <Typography sx={{ color: "#525F94" }}>
                            {order.date_created}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {order.currency_symbol}
                            {order.total} /-
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "#525F4" }}
                          >
                            {order.total_tax &&
                              `-${order?.tax_lines?.[0]?.label} : ${order.total_tax} /-`}
                            {/* {order.total_tax && `-Tax : ${order.total_tax}`} */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: "#525F4" }}>
                            {order.payment_method}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            <Circle
                              fontSize="small"
                              style={{
                                height: "8px",
                                width: "7px",
                                marginRight: "8px",
                                color:
                                  order.status === "completed" ||
                                  order.status === "pending" ||
                                  order.status === "processing"
                                    ? "#2DCE89"
                                    : "#ff0000",
                              }}
                            />
                            {order.status}
                          </Typography>
                          <Typography>{order.date_modified}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {isArrayWithValues(order.line_items)
                              ? order.line_items.map((product) => (
                                  <Typography key={product.id}>
                                    {product.name}
                                  </Typography>
                                ))
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isArrayWithValues(customerInfo.ordersList) && !fetchingOrders && (
        <Stack
          sx={{
            height: "calc(65vh - 74px)",
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginTop: 10 }}>
            <OrdersIllustration />
          </Box>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ color: "#808080" }}
          >
            Seems, No Orders created for this user.
          </Typography>
          <Button
            variant="contained"
            sx={{ paddingTop: "2px", paddingBottom: "2px", marginTop: 5 }}
            onClick={() => {
              setViewOrderDailog(true);
            }}
          >
            Create First Order
          </Button>
        </Stack>
      )}
      {/* <AddOrder
        customerInfo={customerInfo}
        viewOrderDailog={viewOrderDailog}
        setViewOrderDailog={setViewOrderDailog}
        paymentsMethods={paymentGetWaysArray}
      /> */}
      {/* {viewOrderDailog && ( */}
      <OrderPopup
        customerInfo={customerInfo}
        viewOrderDailog={viewOrderDailog}
        setViewOrderDailog={setViewOrderDailog}
        paymentGetWaysArray={paymentGetWaysArray}
      />
      {/* )} */}
    </Box>
  );
};

export default Orders;

const catalogCardStyles = {
  backgroundColor: "#f6f6f6",
};
const pieOptions = {
  chart: {
    // width: 380,
    type: "pie",
  },
  labels: ["Website", "Digital Gold"],
  // fill: {
  // },
  colors: jweroColorPalette?.reverse(),
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export const LoadingCustomerData = ({ loadingText }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: "calc(80vh - 200px)" }}
    >
      <CircularProgress />
      {/* <LinearProgress sx={{ width: "60%" }} /> */}
      <Typography
        variant="h6"
        align="center"
        sx={{ color: "text.secondary" }}
        mt={6}
      >
        {loadingText || "Loading Orders"}...
      </Typography>
    </Stack>
  );
};

const NewOrder = ({ customerInfo, isIpad, navigate, setViewOrderDailog }) => {
  console.log(customerInfo, "<NewOrder");

  const totalAmountOrder =
    customerInfo?.ordersList
      ?.map((obj) => validateNumber(obj?.total))
      ?.reduce((a, b) => a + b, 0) || 0;
  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    if (isArrayWithValues(customerInfo?.ordersList)) {
      let orders = customerInfo?.ordersList?.map((obj) => {
        let order = { ...obj };
        let meta_data = order?.meta_data || [];
        for (const metaObj of meta_data) {
          order[metaObj?.key] = metaObj?.value;
        }
        return order;
      });
      console.log(orders);
      setAllOrders(orders);
    } else setAllOrders([]);
  }, [customerInfo?.ordersList?.length]);
  let website =
    allOrders.filter((obj) => obj?.virtual_order !== "digigold")?.length || 0;
  let digiGoldLen =
    allOrders.filter((obj) => obj?.virtual_order === "digigold")?.length || 0;
  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ ...sectionLabel, mb: "10px" }}>Orders</Typography>
        <Button
          variant="contained"
          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          onClick={(e) => {
            setViewOrderDailog(true);
          }}
          startIcon={<Add />}
        >
          Add manual order
        </Button>
      </Stack>

      <Grid container spacing={7} rowSpacing={5}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TotalCard
            title="Orders"
            value={customerInfo?.ordersList?.length || 0}
            hideGraph
            containerStyle={{
              ...catalogCardStyles,
            }}
            values={[
              {
                title: (
                  <Stack rowGap={3}>
                    <Stack direction={"row"} gap={"6px"}>
                      <Language />
                      <Typography>Website</Typography>
                    </Stack>

                    <Typography variant="h6">{website}</Typography>
                    {/* <Typography>
                    {validateNumber(
                      Math.round(
                        (analyticsData?.sessions?.website /
                          analyticsData?.sessions?.total) *
                        100
                      )
                    )}
                    %{" "}
                  </Typography> */}
                  </Stack>
                ),
              },
              {
                title: (
                  <Stack rowGap={3}>
                    <Stack direction={"row"} gap={"8px"}>
                      <UserDigigoldSvg />
                      <Typography>Digital Gold </Typography>
                    </Stack>
                    <Typography variant="h6">
                      {digiGoldLen}
                      {/* {analyticsData?.sessions?.mobile || 0} */}
                    </Typography>
                    {/* <Typography>
                      {validateNumber(
                      Math.round(
                        (analyticsData?.sessions?.mobile /
                          analyticsData?.sessions?.total) *
                        100
                      )
                    )}
                      %{" "}
                    </Typography> */}
                  </Stack>
                ),
              },
            ]}
            hideNavigate
            // graphData={topGraphs?.plans}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={4}>
          <TotalCard
            title="Orders"
            value={customerInfo?.ordersList?.length || 0}
            hideGraph
            containerStyle={{
              ...catalogCardStyles,
            }}
            values={[
              {
                title: (
                  <Stack rowGap={3}>
                    <Typography variant="h6">Ready Stock</Typography>
                    <Typography variant="h6">
                      {customerInfo?.ordersList?.filter(
                        (obj) => obj?.status === "completed"
                      )?.length || 0}
                    </Typography>
                   
                  </Stack>
                ),
              },
              {
                title: (
                  <Stack rowGap={3}>
                    <Typography variant="h6">Make to order</Typography>
                    <Typography variant="h6">
                      {customerInfo?.ordersList?.filter(
                        (obj) => obj?.status === "completed"
                      )?.length || 0}
                    </Typography>
                    
                  </Stack>
                ),
              },
            ]}
            hideNavigate
          // graphData={topGraphs?.plans}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TotalCard
            title="Total order amount"
            value={`${customerInfo?.ordersList?.[0]?.currency_symbol} ${totalAmountOrder}`}
            hideGraph
            containerStyle={{
              ...catalogCardStyles,
            }}
            values={[
              {
                title: (
                  <Stack rowGap={3}>
                    <Typography variant="h6" color={"text.secondary"}>
                      Orders
                    </Typography>

                    <Typography variant="h6" color={"text.primary"}>
                      {customerInfo?.ordersList?.length || 0}
                    </Typography>
                  </Stack>
                ),
              },
              {
                title: (
                  <Stack rowGap={3}>
                    <Typography variant="h6" color={"text.secondary"}>
                      Avg Product value
                    </Typography>
                    <Typography variant="h6" color={"text.primary"}>
                      {Math.round(
                        totalAmountOrder / customerInfo?.ordersList?.length
                      )}
                      .00
                    </Typography>
                  </Stack>
                ),
              },
            ]}
            hideNavigate
            // graphData={topGraphs?.plans}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ my: 3 }}>
          {/* <Typography variant="h5">Channel wise orders</Typography> */}
        </Grid>
        <Grid item xs={0} md={3}></Grid>
        {false && (
          <Grid item xs={12} sm={12} md={6}>
            <ReactApexChart
              options={pieOptions}
              series={[
                allOrders.filter((obj) => obj?.virtual_order !== "digigold")
                  ?.length || 0,
                allOrders.filter((obj) => obj?.virtual_order === "digigold")
                  ?.length || 0,
              ]}
              type="pie"
              width={"100%"}
              maxWidth={"400px"}
            />
          </Grid>
        )}
      </Grid>

      <OrderList
        totalAmountOrder={totalAmountOrder}
        customerInfo={customerInfo}
        allOrders={allOrders}
        navigate={navigate}
        isIpad={isIpad}
        setViewOrderDailog={setViewOrderDailog}
      />
    </Box>
  );
};

const OrderList = ({
  customerInfo,
  navigate,
  isIpad,
  setViewOrderDailog,
  allOrders,
  totalAmountOrder,
}) => {
  const [selectedTab, setSelectedTab] = useState("Website");
  const [orderlist, setOrderList] = useState([]);
  useEffect(() => {
    if (selectedTab === "Website") {
      setOrderList(
        allOrders.filter((obj) => obj?.virtual_order !== "digigold") || []
      );
    } else
      setOrderList(
        allOrders.filter((obj) => obj?.virtual_order === "digigold") || []
      );
  }, [allOrders, selectedTab, customerInfo]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h5">Channel wise orders</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table
              aria-label="simple table"
              sx={{ border: 1, borderColor: "divider" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Channel </TableCell>
                  <TableCell align="center">Orders</TableCell>
                  <TableCell align="center">Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    Website
                  </TableCell>
                  <TableCell align="center">
                    {allOrders.filter(
                      (obj) => obj?.virtual_order !== "digigold"
                    )?.length || 0}
                  </TableCell>
                  <TableCell align="center">
                    {allOrders
                      .filter((obj) => obj?.virtual_order !== "digigold")
                      ?.map((obj) => obj?.total)
                      ?.reduce(
                        (a, b) => validateNumber(a) + validateNumber(b),
                        0
                      ) || 0}
                    .00
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    Digital gold
                  </TableCell>
                  <TableCell align="center">
                    {allOrders.filter(
                      (obj) => obj?.virtual_order === "digigold"
                    )?.length || 0}
                  </TableCell>
                  <TableCell align="center">
                    {allOrders
                      .filter((obj) => obj?.virtual_order === "digigold")
                      ?.map((obj) => obj?.total)
                      ?.reduce(
                        (a, b) => validateNumber(a) + validateNumber(b),
                        0
                      ) || 0}
                    .00
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 700 }}
                  >
                    Total
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    {allOrders?.length || 0}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    {allOrders
                      ?.map((obj) => validateNumber(obj?.total))
                      ?.reduce((a, b) => a + b, 0) || 0}
                    .00
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <ReactApexChart
            options={pieOptions}
            series={[
              allOrders.filter((obj) => obj?.virtual_order !== "digigold")
                ?.length || 0,
              allOrders.filter((obj) => obj?.virtual_order === "digigold")
                ?.length || 0,
            ]}
            type="pie"
            width={"100%"}
            maxWidth={"400px"}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <ReactApexChart
            options={pieOptions}
            series={[
              allOrders.filter((obj) => obj?.virtual_order !== "digigold")
                ?.length || 0,
              allOrders.filter((obj) => obj?.virtual_order === "digigold")
                ?.length || 0,
            ]}
            type="pie"
            width={"100%"}
            maxWidth={"400px"}
          /> */}
        </Grid>
      </Grid>
      <ValueBy orderlist={orderlist} totalAmountOrder={totalAmountOrder} />
      <Stack
        sx={{ my: 3, mx: 5 }}
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}
      >
        <Typography variant="h5">Orders List</Typography>
        <Button
          variant="contained"
          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          onClick={(e) => {
            setViewOrderDailog(true);
          }}
          startIcon={<Add />}
        >
          Add manual order
        </Button>
      </Stack>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
      >
        <Tab label={"Website"} value={"Website"} />
        <Tab label={"Digital gold"} value={"Digital gold"} />
      </Tabs>

      {isArrayWithValues(orderlist) ? (
        <TableContainer sx={{ width: "100%" }} component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead>
              {isIpad ? (
                <TableRow>{null}</TableRow>
              ) : (
                <TableRow>
                  <TableCell>Order no.</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Order date</TableCell>
                  <TableCell>Qty.</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Payment Details</TableCell>
                  <TableCell>Order Status</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isArrayWithValues(customerInfo.ordersList) &&
                orderlist.map((order) => {
                  let discount_total = validateNumber(order?.discount_total);
                  return (
                    <React.Fragment key={order.id}>
                      {isIpad ? (
                        <TableRow sx={{ width: "100%" }}>
                          <TableCell
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="button">
                              Order #{order.id}
                            </Typography>
                            <Typography variant="button">
                              {order.currency_symbol}
                              {order.total} /-
                              <Typography
                                variant="caption"
                                sx={{ color: "#525F4" }}
                              >
                                {order.total_tax &&
                                  `-${order?.tax_lines?.[0]?.label} : ${order.total_tax}`}
                                {/* {order.total_tax && `-Tax : ${order.total_tax}`} */}
                              </Typography>
                            </Typography>
                            <Typography>
                              <Circle
                                fontSize="small"
                                style={{
                                  height: "8px",
                                  width: "7px",
                                  marginRight: "8px",
                                  color:
                                    order.status === "completed" ||
                                    order.status === "pending" ||
                                    order.status === "processing"
                                      ? "#2DCE89"
                                      : "#ff0000",
                                }}
                              />
                              {order.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <StyledTableRow
                          hover
                          sx={{ cursor: "pointer" }}
                          onClick={() => navigate(`/orders/${order.id}`)}
                        >
                          <TableCell>
                            <Typography variant="h6">
                              Order #{order.id}
                            </Typography>
                            {order?.line_items?.[0]?.image?.src ? (
                              <Avatar
                                src={order?.line_items?.[0]?.image?.src}
                              />
                            ) : null}
                            {/* <Typography sx={{ color: "#525F94" }}>
                      {order.date_created}
                    </Typography> */}
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {isArrayWithValues(order.line_items)
                                ? order.line_items.map((product) => (
                                    <Typography key={product.id}>
                                      {product.name}
                                    </Typography>
                                  ))
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ color: "#525F4" }}>
                              {formatDate(
                                new Date(order.date_created),
                                "relative_date"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {order?.line_items
                              ?.map((obj) => validateNumber(obj?.quantity))
                              ?.reduce((a, b) => a + b, 0)}
                            {/* <Typography>{order.date_modified}</Typography> */}
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5"></Typography>
                            {discount_total ? (
                              <Stack>
                                <Typography
                                  sx={{ textDecorationLine: "line-through" }}
                                >
                                  {order.currency_symbol}
                                  {validateNumber(order.total) +
                                    validateNumber(discount_total)}
                                </Typography>
                                <Typography variant="h6">
                                  {order.currency_symbol} {order.total}.00
                                </Typography>
                              </Stack>
                            ) : (
                              <Typography variant="h5">
                                {" "}
                                {order.currency_symbol}
                                {order.total}.00
                              </Typography>
                            )}
                            {/* {order.total} */}

                            <Typography
                              variant="caption"
                              sx={{ color: "#525F4" }}
                            >
                              {/* {order.total_tax && `Tax : ${order.total_tax}`} */}
                              {order.total_tax &&
                                `-${order?.tax_lines?.[0]?.label} : ${order.total_tax}.00`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {order?.payment_method_title}
                            </Typography>
                            <Typography>
                              Type:{" "}
                              {order?.manual_payment === "yes"
                                ? "Manual"
                                : "Automatic"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <Circle
                                fontSize="small"
                                style={{
                                  height: "8px",
                                  width: "7px",
                                  marginRight: "8px",
                                  color:
                                    order.status === "completed" ||
                                    order.status === "pending" ||
                                    order.status === "processing"
                                      ? "#2DCE89"
                                      : "#ff0000",
                                }}
                              />
                              {order.status}
                            </Typography>
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoTasks
          text={
            <Box sx={{ textAlign: "center" }}>
              <Typography>No orders on {selectedTab}</Typography>

              {/* <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                  // startIcon={<Add />}
                  // onClick={() => navigate("/chats")}
                  >
                    Send
                  </Button> */}
            </Box>
          }
          icon={
            <ProductionQuantityLimits
              style={{ height: "70px", width: "70px", color: "#808080" }}
            />
          }
        />
      )}
    </>
  );
};

const ValueBy = ({ orderlist, totalAmountOrder }) => {
  const [currentTab, setCurrentTab] = useState("orders");
  const currency = useSelector(
    (state) => state.settings.productSettings?.default_currency
  );

  let data =
    currentTab === "orders"
      ? orderlist?.length || 0
      : orderlist?.map((obj) => obj?.line_items)?.flat()?.length || 0;
  const options = {
    chart: {
      type: "radialBar",
    },
    colors: [jweroColorPalette[currentTab === "orders" ? 1 : 2]],
    labels: [formatServerValue(currentTab)],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            fontSize: "14px",
            formatter: function (val) {
              return data;
            },
          },
          total: {
            show: false,
            label: "Total",
            color: "#373d3f",
            fontSize: "16px",
            fontFamily: undefined,
            fontWeight: 600,
            formatter: data,
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const baroptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: jweroColorPalette,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: orderlist?.map((obj, index) => index + 1),
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {},
    },
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="h5">Average value by</Typography>
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => {
            setCurrentTab(newValue);
          }}
        >
          <Tab value={"orders"} label={"Orders"} />
          <Tab value={"products"} label={"Products"} />
        </Tabs>
        <ReactApexChart options={options} series={[99]} type="radialBar" />
        <Grid
          // item

          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            rowGap: 2,
          }}
        >
          <Typography variant="h6">Avg {currentTab} Value</Typography>
          <Typography variant="h6">
            {currencySymbols[currency]}{" "}
            {Math.ceil(validateNumber(totalAmountOrder / data || 0))}.00
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} md={5}>
        <Typography variant="h5">Average value by</Typography>
        <ReactApexChart
          options={baroptions}
          series={[
            {
              name: "Order",
              data: orderlist?.map((obj, index) => index + 1),
            },
            {
              name: "Products",
              data: orderlist?.map((obj) => obj?.line_items?.length || 0),
            },
          ]}
          type="bar"
          height={350}
        />
      </Grid>
    </Grid>
  );
};

const AddOrder = ({
  viewOrderDailog,
  setViewOrderDailog,
  customerInfo,
  paymentsMethods,
}) => {
  let fetchingInterval, customerSearchTimeout;
  const [catalogProducts, setCatalogProducts] = useState([]);
  const [fetchingCatalogProducts, setFetchingCatalogProducts] = useState(false);
  const [orderForm, setOrderForm] = useState({
    selectedPaymentMethod: "",
    selectedPaymentStatus: "",
    products: [],
  });

  // let {selectedPaymentStatus, selectedPaymentMethod, selectedProducts} =
  // this.state;
  const onProductSearch = async (value) => {
    if (!value || (value && value.length < 2)) return;
    if (customerSearchTimeout) clearTimeout(customerSearchTimeout);
    console.log(value, "<<<");
    customerSearchTimeout = setTimeout(async () => {
      fetchProducts(
        { search: value },
        setUpdatedProducts,
        setFetchingCatalogProducts
      );
    }, 500);
  };

  const setUpdatedProducts = (products) => {
    if (isArrayWithValues(products)) {
      let arr = [];
      for (let i = 0; i < products.length; i++) {
        console.log(products?.[i]);
        arr.push({
          label: (
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Avatar
                src={
                  products?.[i]?.images?.[0]?.src ||
                  "/static/img/woocommerce_placeholder.png"
                }
              />{" "}
              {products?.[i]?.name}
              <Typography> SKU: {products?.[i]?.sku}</Typography>
              {products?.[i]?.stock_status === "instock" && (
                <Typography
                  variant="caption"
                  color="success.main"
                  fontWeight={"bold"}
                >
                  In stock
                </Typography>
              )}
              {products?.[i]?.stock_status === "onbackorder" && (
                <Typography
                  variant="caption"
                  color="warning.main"
                  fontWeight={"bold"}
                >
                  Make to order
                </Typography>
              )}
              {products?.[i]?.stock_status === "outofstock" && (
                <Typography
                  variant="caption"
                  color="error.main"
                  fontWeight={"bold"}
                >
                  Out of stock
                </Typography>
              )}
            </Stack>
          ),
          value: products[i].id,
        });
      }
      console.log(arr);
      setCatalogProducts(arr);
    }
  };
  const changeOrderForm = (payload) => {
    setError({});
    setOrderForm((pre) => ({ ...pre, ...payload }));
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const submitOrder = async () => {
    let errorObj = {};
    if (!isArrayWithValues(orderForm?.products)) {
      console.log(orderForm);
      errorObj.products = "Required";
      // return setError((pre) => ({ ...pre, products: true }));
    }
    if (!orderForm?.selectedPaymentStatus)
      errorObj.selectedPaymentStatus = "Required";
    // return setError((pre) => ({ ...pre, selectedPaymentStatus: true }));
    // if (!orderForm?.selectedPaymentMethod)
    //   errorObj.selectedPaymentMethod = "Required";
    // return setError((pre) => ({ ...pre, selectedPaymentMethod: true }));
    if (isObjWithValues(errorObj)) {
      setError(errorObj);
      return;
    }

    setLoading(true);
    let userId = customerInfo.id;

    console.log(JSON.stringify(customerInfo.billing, null, 2));
    console.log(JSON.stringify(customerInfo.shipping, null, 2));
    let billing = customerInfo.billing;
    let shipping = customerInfo.shipping;
    if (!billing.email) delete billing.email;
    let obj = {
      payment_method: orderForm.selectedPaymentMethod.value
        ? orderForm.selectedPaymentMethod.value
        : "",
      payment_method_title: orderForm.selectedPaymentMethod.label
        ? orderForm.selectedPaymentMethod.label
        : "",
      billing,
      shipping,
      set_paid: false,
      status: orderForm.selectedPaymentStatus,
      meta_data: [
        {
          key: "manual_payment",
          value: "yes",
        },
      ],
    };
    console.log(obj.status, obj);
    let line_items = [];
    if (Array.isArray(orderForm.products) && orderForm.products.length > 0) {
      for (let i in orderForm.products) {
        line_items.push({
          product_id: orderForm.products[i].value,
          quantity: 1,
        });
      }
    }
    obj.line_items = line_items;
    obj.customer_id = userId;
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    let headersList = {
      Authorization: `Basic ${token}`,
    };
    try {
      const res = await axios({
        url: `${website}/wp-json/wc/v3/orders`,
        method: "POST",
        headers: headersList,
        data: obj,
      });
      console.log(res.data);
      let customer = customerInfo;
      if (isArrayWithValues(customer.ordersList)) {
        customer.ordersList.unshift(res.data);
      } else {
        customer.ordersList = [res.data];
      }
      dispatch(updateCustomerInRedux(customer));
      setLoading(false);
      setViewOrderDailog(false);
    } catch (error) {
      console.log(getAxiosError(error));
    } finally {
      setLoading(false);
    }

    console.log(orderForm);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={viewOrderDailog}
      onClose={() => {
        setViewOrderDailog(false);
      }}
      sx={dialogStyle}
      {...globalPopupStyle}
    >
      <DialogTitle>
        {/* Create New Order */}
        Add Manual Order
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={3}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                Payment Method*
              </Typography>
              <Select
                size="small"
                fullWidth
                value={orderForm.selectedPaymentMethod}
                error={error?.selectedPaymentMethod}
                helperText={error?.selectedPaymentMethod}
                onChange={(e) => {
                  console.log(e.target.value);
                  changeOrderForm({ selectedPaymentMethod: e.target.value });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[...paymentsMethods].map((item) => (
                  <MenuItem key={item.label} value={item}>
                    {item.label === "Cash on delivery" ? "Cash" : item.label}
                  </MenuItem>
                ))}
                {/* <MenuItem value="bank_tarnsfer">Direct bank transfer</MenuItem>
                <MenuItem value="cash_on_delivery">Cash on delivery</MenuItem>
                <MenuItem value="other">Other</MenuItem> */}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                Status*
              </Typography>
              <Select
                size="small"
                fullWidth
                error={error?.selectedPaymentStatus}
                value={orderForm.selectedPaymentStatus}
                helperText={error?.selectedPaymentStatus}
                onChange={(e) => {
                  changeOrderForm({ selectedPaymentStatus: e.target.value });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="processing">Processing</MenuItem>
                <MenuItem value="on-hold">On hold</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
                <MenuItem value="refunded">Refunded</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Select Product*</Typography>
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={catalogProducts}
              size="small"
              error={error?.products}
              // sx={{ width: 300 }}
              value={orderForm.products}
              loading={fetchingCatalogProducts}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error?.products}
                  helperText={error?.products}
                  // label="UP SELL"
                  onChange={(e) => onProductSearch(e.target.value)}
                />
              )}
              onChange={(e, value) => changeOrderForm({ products: value })}
              noOptionsText="Please enter 3 or more letters"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setViewOrderDailog(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={submitOrder}
          variant="contained"
        >
          {/* Order */}
          Create Order
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const OrderPopup = ({
  viewOrderDailog,
  setViewOrderDailog,
  paymentGetWaysArray,
  customerInfo,
}) => {
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const dispatch = useDispatch();
  const [orderForm, setOrderForm] = useState({
    products: [],
  });
  const [note, setNote] = useState("");
  const [error, setError] = useState({});
  const [orderInfoDialog, setOrderInfoDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeOrderForm = (payload) => {
    setOrderForm((pre) => ({ ...pre, ...payload }));
  };
  const navigate = useNavigate();
  const submitOrder = async () => {
    console.log(orderForm);
    let userId = customerInfo.id;

    console.log(JSON.stringify(customerInfo.billing, null, 2));
    console.log(JSON.stringify(customerInfo.shipping, null, 2));
    let billing = customerInfo.billing;
    let shipping = customerInfo.shipping;
    if (!billing.email) delete billing.email;
    let obj = {
      payment_method: orderForm?.selectedPaymentMethod?.value
        ? orderForm?.selectedPaymentMethod?.value
        : "",
      payment_method_title: orderForm?.selectedPaymentMethod?.label
        ? orderForm?.selectedPaymentMethod?.label
        : "",
      billing,
      shipping,
      created_at: orderForm?.created_at,
      set_paid: false,
      status: orderForm?.selectedPaymentStatus,
      meta_data: [
        {
          key: "manual_payment",
          value: "yes",
        },
      ],
    };
    if (orderForm?.delivery_date) {
      obj?.meta_data?.push({
        key: "delivery_date",
        value: Date.parse(orderForm?.delivery_date),
      });
    }
    let line_items = orderForm?.products?.map((product) => ({
      product_id: product?.id,
      quantity: 1,
    }));
    obj.line_items = line_items;
    obj.customer_id = userId;
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    let res;
    setLoading(true);
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/orders`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: obj,
      });
      console.log(data);
      res = data;
      if (data) {
        let customer = customerInfo;
        if (isArrayWithValues(customer.ordersList)) {
          customer.ordersList.unshift(data);
        } else {
          customer.ordersList = [data];
        }
        dispatch(updateCustomerInRedux(customer));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to create Order",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
    if (note && res?.id) {
      try {
        setLoading(true);
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/orders/${res?.id}/notes`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            note,
            added_by_user: true,
          },
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    console.log("res");
    if (res?.id) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Order Created Successfully!",
          severity: "success",
        })
      );
      setOrderInfoDialog(false);
      navigate(`/orders/${res?.id}`);
      setOrderForm({});
      setNote("");
    }
  };
  const closeProductSelection = () => {
    setViewOrderDailog(false);
  };
  const onProductSelect = (products) => {
    console.log(products);
    changeOrderForm({ products });
    setOrderInfoDialog(true);
    setViewOrderDailog(false);
  };
  return (
    <>
      <Dialog
        open={viewOrderDailog}
        onClose={closeProductSelection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <SelectProducts
          onClose={closeProductSelection}
          onSelect={onProductSelect}
          onlyImage
          title={"Select Products for Order"}
        />
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={orderInfoDialog}
        onClose={() => {
          setOrderInfoDialog(false);
          setOrderForm({
            products: [],
          });
          setNote("");
        }}
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>
          {/* Create New Order */}
          Add Manual Order
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={3} spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack direction={"row"} gap={2}>
                <Typography variant="h6">Products </Typography>
                <Typography variant="h5">
                  ({orderForm?.products?.length})
                </Typography>
              </Stack>
              <List>
                {orderForm?.products?.map((obj) => {
                  let statusChip = allStockStatus?.find(
                    (i) => i.value === obj?.stock_status
                  );
                  return (
                    <Fragment key={obj?.id}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src={obj?.images?.[0]?.src}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={obj?.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {obj?.sku}
                              </Typography>{" "}
                              {"  - "}
                              <CustomChip
                                status={statusChip?.label}
                                type={statusChip?.type}
                                size="small"
                              />
                              {/* {obj?.stock_status === "instock" && (
                                <Typography
                                  variant="caption"
                                  color="success.main"
                                  fontWeight={"bold"}
                                >
                                  In stock
                                </Typography>
                              )}
                              {obj?.stock_status === "onbackorder" && (
                                <Typography
                                  variant="caption"
                                  color="warning.main"
                                  fontWeight={"bold"}
                                >
                                  Make to order
                                </Typography>
                              )}
                              {obj?.stock_status === "outofstock" && (
                                <Typography
                                  variant="caption"
                                  color="error.main"
                                  fontWeight={"bold"}
                                >
                                  Out of stock
                                </Typography>
                              )} */}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </Fragment>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                    Order Placed on
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      // disableFuture
                      // label="First Purchase"
                      // value={value}
                      // onChange={(newValue) => {}}
                      value={orderForm?.created_at || null}
                      onChange={(newValue) =>
                        changeOrderForm({ created_at: newValue })
                      }
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                    Payment Method*
                  </Typography>
                  <Select
                    size="small"
                    fullWidth
                    value={orderForm.selectedPaymentMethod}
                    error={error?.selectedPaymentMethod}
                    helperText={error?.selectedPaymentMethod}
                    onChange={(e) => {
                      console.log(e.target.value);
                      changeOrderForm({
                        selectedPaymentMethod: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">
                      {/* <em> */}
                      None
                      {/* // </em> */}
                    </MenuItem>
                    {[...(paymentGetWaysArray || [])].map((item) => (
                      <MenuItem key={item.label} value={item}>
                        {item.label === "Cash on delivery"
                          ? "Cash"
                          : item.label}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="bank_tarnsfer">Direct bank transfer</MenuItem>
                <MenuItem value="cash_on_delivery">Cash on delivery</MenuItem>
                <MenuItem value="other">Other</MenuItem> */}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                    Status*
                  </Typography>
                  <Select
                    size="small"
                    fullWidth
                    error={error?.selectedPaymentStatus}
                    value={orderForm.selectedPaymentStatus}
                    helperText={error?.selectedPaymentStatus}
                    onChange={(e) => {
                      changeOrderForm({
                        selectedPaymentStatus: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">
                      {/* <em> */}
                      None
                      {/* </em> */}
                    </MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="processing">Processing</MenuItem>
                    <MenuItem value="on-hold">On hold</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                    <MenuItem value="failed">Failed</MenuItem>
                    <MenuItem value="refunded">Refunded</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ marginTop: 3 }}>
                    Delivery Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      // disableFuture
                      // label="First Purchase"
                      value={orderForm?.delivery_date || null}
                      onChange={(newValue) =>
                        changeOrderForm({ delivery_date: newValue })
                      }
                      // onChange={(newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Note</Typography>
              <TextField
                size="small"
                multiline
                rows={3}
                fullWidth
                onChange={(e) => {
                  setNote(e?.target?.value);
                }}
                value={note}
              />
            </Grid>
            {/* <Grid item container spacing={2}></Grid> */}
            {/* <Grid item xs={12}>
              <Typography variant="subtitle2">Select Product*</Typography>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={catalogProducts}
                size="small"
                error={error?.products}
                // sx={{ width: 300 }}
                value={orderForm.products}
                loading={fetchingCatalogProducts}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error?.products}
                    helperText={error?.products}
                    // label="UP SELL"
                    onChange={(e) => onProductSearch(e.target.value)}
                  />
                )}
                onChange={(e, value) => changeOrderForm({ products: value })}
                noOptionsText="Please enter 3 or more letters"
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOrderInfoDialog(false);
              setNote("");
              setOrderForm({
                products: [],
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={submitOrder}
            variant="contained"
          >
            {/* Order */}
            Create Order
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
