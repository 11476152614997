import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  Autocomplete,
  Chip,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { userStyles } from "./styles/userStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DataObjectIcon from "@mui/icons-material/DataObject";
import UserInputAttributes from "./helper/UserInputAttributes";
import CustomTooltip from "../components/CustomTooltip";
// import UserInputEmoji from "./helper/UserInputEmoji";

const styles = userStyles;

const Sidebar = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [textFields, setTextFields] = useState([""]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [userResponsesError, setUserResponsesError] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);

  const handleTextFieldChange = (index, value) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value;
    if (value.trim() === "" && index < textFields.length - 1) {
      updatedTextFields.splice(index + 1, 1);
    }

    if (
      index === textFields.length - 1 &&
      textFields.length < 5 &&
      value.trim() !== ""
    ) {
      updatedTextFields.push("");
    }

    setTextFields(updatedTextFields);
  };

  const handleAttributeSelect = (index, value) => {
    let updatedTextFields = [...textFields];
    let fieldValue = updatedTextFields[index];
    updatedTextFields[index] = fieldValue + `{{${value}}}`;
    setTextFields(updatedTextFields);
    setAttributeAnchorEl(null);
  };

  const handleEmojiPicker = (e) => {
    setEmojiAnchorEl(e.currentTarget);
  };

  const handleEmojiSelect = (index, emoji) => {
    let updatedTextFields = [...textFields];
    let fieldValue = updatedTextFields[index];
    const emojiText = emoji.emoji;
    updatedTextFields[index] = fieldValue + emojiText;
    setTextFields(updatedTextFields);
    setEmojiAnchorEl(false);
  };

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  const handleDeleteTextField = (index) => {
    if (index > 0) {
      const updatedTextFields = [...textFields];
      updatedTextFields.splice(index, 1);
      setTextFields(updatedTextFields);
    }
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && userInput.trim() !== "") {
      setSelectedValues([...selectedValues, userInput.trim()]);
      setUserInput("");
    }
  };

  const handleDeleteValue = (valueToDelete) => {
    setSelectedValues((prevValues) =>
      prevValues.filter((value) => value !== valueToDelete)
    );
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (textFields[0] == "") {
      setUserResponsesError(true);
    }

    if (textFields[0] != "" && title) {
      const submittedUserData = {
        title,
        userResponse: textFields,
        keywords: selectedValues,
      };
      console.log(submittedUserData);
      dispatch({ type: actions.CLOSE_SIDEBAR_USER });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    dispatch({ type: actions.CLOSE_SIDEBAR_USER });
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);
    const node = currentNode?.fieldData;
    if (node?.title) {
      setTitle(node?.title);
      setTextFields(node?.userResponse);
      setSelectedValues(node?.keywords);
    }
  }, [userId]);

  return (
    <Card style={styles.customCard}>
      <form>
        <div style={styles.topCard}>
          <div style={styles.innerCard}>
            <div style={styles.userHeader}>
              <PersonIcon />
              <Typography variant="span" style={styles.headerTitle}>
                USER INPUT
              </Typography>
            </div>
            <div style={styles.headerIconsMainDiv}>
              <IconButton style={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon style={styles.headerIcons} />
              </IconButton>
              <IconButton style={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon style={styles.headerIcons} />
              </IconButton>
            </div>
          </div>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            value={title}
            inputProps={{
              maxLength: 40,
            }}
            required
          />
        </div>
        <div style={styles.responseSection}>
          <div style={styles.responseDiv}>
            <IconButton style={styles.iconsButton}>
              {/* <Tooltip
                title="Keyword is a matching system in bot. It works great when you want a unique phrase or word to trigger a bot response."
                placement="top"
                arrow
              > */}
              <CustomTooltip
                title="Keyword is a matching system in bot. It works great when you want a unique phrase or word to trigger a bot response."
                position="left"
                fontBold={500}
              >
                <HelpOutlineIcon style={styles.icons} />
              </CustomTooltip>
              {/* </Tooltip> */}
            </IconButton>
            <Typography variant="span" style={styles.labels}>
              Keywords
            </Typography>
          </div>
          <div>
            <Autocomplete
              multiple
              fullWidth
              id="custom-autocomplete"
              options={[]}
              value={selectedValues}
              onChange={(_, newValue) => setSelectedValues(newValue) || []}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="+ Add Keyword"
                  placeholder="Enter Keyword and press Enter"
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    f
                    onDelete={() => handleDeleteValue(option)}
                    deleteIcon={<CloseIcon />}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </div>
          <div style={styles.responseDiv}>
            <IconButton style={styles.iconsButton}>
              <CustomTooltip
                title="User's says matching system in bot. This system analyzes the full user query to pair it with the bot response that matches the best."
                position="left"
                fontBold={500}
              >
                <HelpOutlineIcon style={styles.icons} />
              </CustomTooltip>
            </IconButton>
            <Typography variant="span" style={styles.labels}>
              User Says
            </Typography>
          </div>

          {textFields &&
            textFields.length > 0 &&
            textFields.map((value, index) => (
              <div key={index} style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  placeholder="Enter user message"
                  style={{
                    marginBottom: "20px",
                    borderRadius: "8px",
                    fontSize: "50px",
                    backgroundColor: "#ffffff",
                    fontFamily: "Inter, Roboto, sans-serif",
                  }}
                  inputProps={{
                    maxLength: 256,
                  }}
                  key={index}
                  fullWidth
                  value={value}
                  onChange={(e) => handleTextFieldChange(index, e.target.value)}
                  error={userResponsesError}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         sx={inputFieldStyles.iconButton}
                  //         onClick={handleAttributePopover}
                  //       >
                  //         <DataObjectIcon sx={inputFieldStyles.icon} />
                  //       </IconButton>
                  //       <UserInputAttributes
                  //         attributeAnchorEl={attributeAnchorEl}
                  //         setAttributeAnchorEl={setAttributeAnchorEl}
                  //         handleAttributeSelect={handleAttributeSelect}
                  //         arrIndex={index}
                  //       />

                  //       <IconButton
                  //         sx={inputFieldStyles.iconButton}
                  //         onClick={handleEmojiPicker}
                  //       >
                  //         <InsertEmoticonIcon sx={inputFieldStyles.icon} />
                  //       </IconButton>
                  //       <UserInputEmoji
                  //         emojiAnchorEl={emojiAnchorEl}
                  //         setEmojiAnchorEl={setEmojiAnchorEl}
                  //         handleEmojiSelect={handleEmojiSelect}
                  //         arrIndex={index}
                  //       />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
                {index > 0 && (
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteTextField(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
        </div>
      </form>
    </Card>
  );
};

export default Sidebar;
