import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CustomDialog from "../../../../helper/utility/CustomDialog";
import {
  DefaultLabel,
  DefaultLabelSmall,
  PrimaryLabel,
  PrimaryLabelSmall,
} from "../../../../helper/utility/Labels";
import CustomField from "../../../../helper/utility/CustomField";
import CustomCheckbox from "../../../../helper/utility/CustomCheckbox";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getRandomString,
  getTimeInMilliSeconds,
  isArrayWithValues,
  isObjWithValues,
  unformatServerValue,
  validateNumber,
} from "../../../../helper";
import { RESEVERD_KEYS_FROM_CUSTOM_FIELDS } from "../utils";
import { createCustomField } from "../helpers";
import { editChats } from "../../../../redux/actions/chatActions";
import {
  setGloba,
  setGlobalToast,
  setGlobalToastlToast,
} from "../../../../redux/actions/helperActions";
import { editSettings } from "../../../../redux/actions/settingActions";
// import { useSignal } from "@preact/signals-react";
const DROPDOWN_TYPES = ["checkbox", "dropdown", "radio"];

const ALL_TYPES = [
  {
    label: "Text Field",
    value: "text",
    description: "Generate text input for various information",
  },
  {
    label: "Number",
    value: "number",
    description:
      "Structured input for phone numbers with country code  selection",
  },
  {
    label: "Date",
    value: "date",
    description: "Choose a date from calendar using a date picker",
  },
  {
    label: "Time",
    value: "time",
    description: "Choose a time using a time picker",
  },
  // {
  //   label: "Radio Button",
  //   value: "radio",
  //   description: "Select one option from a list",
  // },
  {
    label: "Multi Select",
    value: "checkbox",
    description: "Select multiple options from a list",
  },
  {
    label: "Pick List",
    value: "dropdown",
    description: "Select one or multiple options from a compact list",
  },
];

const ALL_MODULES = [
  {
    label: "Account Info",
    value: "account_info",
    path: ["CRM", "Info", "Profile", "Account Info"],
  },
  {
    label: "Personal Info",
    value: "personal_info",
    path: ["CRM", "Info", "Profile", "Personal Info"],
  },
  {
    label: "Address Info",
    value: "address_info",
    path: ["CRM", "Info", "Profile", "Address Info"],
  },
  {
    label: "Other Info",
    value: "other_info",
    path: ["CRM", "Info", "Profile", "Other Info"],
  },
  {
    label: "Social Info",
    value: "social_info",
    path: ["CRM", "Info", "Profile", "Social Info"],
  },
];

const initialState = {
  title: "",
  type: "",
  module: "",
  mandatory: false,
  unique: false,
  show_tooltip: false,
  description: "",
  options: ["", ""],
  team_members: [],
};

const initialErrorState = {
  title: "",
  type: "",
  module: "",
  mandatory: "",
  unique: "",
  description: "",
  options: "",
};
const AddCustomField = forwardRef((props, ref) => {
  // const form = useSignal(initialState);
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const createRef = useRef(null);
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  const [CRMFields, setCRMFields] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_id = useSelector((state) => state.user.id);
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    setCRMFields(Object.values(custom_fields?.crm));
  }, [custom_fields]);

  const editForm = (payload) => {
    // form.value = { ...form, ...payload };
    if (isObjWithValues(errors)) setErrors({});
    setForm((state) => ({ ...state, ...payload }));
  };

  const editErrors = (payload) => {
    setErrors((state) => ({ ...state, ...payload }));
  };

  useImperativeHandle(ref, () => {
    return {
      setupForm(form) {
        _setupForm(form);
      },
      open: createRef?.current?.open,
      close: createRef?.current?.close,
    };
  });

  const _setupForm = (form) => {
    setIsEdit(true);
    if (form?.options) form.options = form?.options?.map((i) => i?.value);
    form.show_tooltip = Boolean(form?.description);
    editForm(form);
  };

  const onSubmit = useCallback(async () => {
    let payload = {
      id: form.id || getRandomString(10),
      created_on: form.created_on || getTimeInMilliSeconds(),
      value: unformatServerValue(form.title),
      is_admin: form.is_admin || isAdmin,
      created_by: form.created_by || user_id,
      ...form,
    };
    let errors = {};
    if (!form.title) errors.title = "Please enter title";
    if (form.title?.length > 32)
      errors.title = "Title length must be 32 characters";
    if (!form.module) errors.module = "Please select module";
    if (
      RESEVERD_KEYS_FROM_CUSTOM_FIELDS?.includes(
        unformatServerValue(form.title)
      )
    )
      errors.title = "Title is reserverd, please choose different title";
    if (!isArrayWithValues(form.options) && DROPDOWN_TYPES?.includes(form.type))
      errors.options = "Please enter at least one options";
    if (
      form.options?.findIndex((i) => !i) > -1 &&
      DROPDOWN_TYPES?.includes(form.type) &&
      !isEdit
    )
      errors.options = "Please enter all options";
    if (CRMFields?.find((i) => i.title === payload.title) && !isEdit)
      errors.title = "Title is already assigned";

    if (isObjWithValues(errors)) {
      editErrors({ ...initialErrorState, ...errors });
      return;
    }
    payload.options = payload.options?.filter(Boolean);
    payload.options = payload.options?.map((i) => ({
      label: i?.trim(),
      value: i?.trim(),
    }));
    setLoading(true);
    let res = await createCustomField({ payload });

    if (res?.success && isObjWithValues(res?.data?.crm)) {
      dispatch(editSettings({ custom_fields: res?.data }));
      editForm(initialState);
      // handleClose();
      createRef?.current?.close();
      dispatch(
        setGlobalToast({
          show: true,
          message: isEdit
            ? "Custom Field updated successfully"
            : "Custom Field added successfully",
          severity: "success",
        })
      );
    } else
      dispatch(
        setGlobalToast({
          show: true,
          message: isEdit
            ? "Unable to update custom field"
            : "Unable to add custom field",
          severity: "error",
        })
      );
    setLoading(false);
  }, [form]);

  const onDialogClose = useCallback(() => {
    editForm(initialState);
  }, []);

  return (
    <Box>
      <CustomDialog
        submitButtonProps={{ onClick: onSubmit, loading }}
        title={"Create New Field"}
        ref={createRef}
        onClose={onDialogClose}
      >
        <Alert severity="info" sx={{ mb: 3 }}>
          By creating a custom fields, you can capture important data specific
          to your business requirements by specifying some field-related
          information. These fields can be used for Segmentation, filters etc
        </Alert>
        <FieldDetails form={form} editForm={editForm} errors={errors} />
        <Divider style={{ margin: "16px 0px 20px 0px" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomFieldSettings form={form} editForm={editForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TeamMemberAssign form={form} editForm={editForm} />
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>
  );
});

export default AddCustomField;

const FieldDetails = ({ form, editForm, errors }) => {
  const [selectedType, setSelectedType] = useState("");
  const editOption = (payload, index) => {
    let options = form?.options;
    options[index] = payload;
    editForm({ options });
  };

  useEffect(() => {
    setSelectedType(ALL_TYPES?.find((i) => i.value === form?.type)?.label);
  }, [form?.type]);
  const onOptionRemove = useCallback(
    (index) => {
      let options = form?.options;
      options.splice(index, 1);
      editForm({ options });
    },
    [form?.options]
  );

  const onAddOptions = useCallback(() => {
    let options = form?.options?.concat([""]);
    editForm({ options });
  }, [form?.options]);

  return (
    <>
      <PrimaryLabelSmall sx={{ mb: 3, fontWeight: "600" }}>
        Field Details
      </PrimaryLabelSmall>
      <Grid container spacing={3} rowGap={2}>
        <Grid item xs={4.5}>
          <CustomField
            value={form?.title}
            onChange={(e) => editForm({ title: e })}
            label="Label"
            textFieldProps={{
              placeholder: "Enter field title",
              helperText: errors?.title || "Max 32 charecters",
              error: Boolean(errors?.title),
            }}
            tooltip={"Specify a name for the field"}
          />
        </Grid>

        <Grid item xs={4.5}>
          <CustomField
            value={form?.type}
            onChange={(e) => editForm({ type: e })}
            label="Type"
            type="dropdown"
            textFieldProps={{
              placeholder: "Enter field title",
              helperText: errors?.type,
              error: Boolean(errors?.type),
            }}
            options={ALL_TYPES}
            helperText={errors?.type}
            error={Boolean(errors?.type)}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={4.5}>
          <CustomField
            value={form?.module}
            onChange={(e) => editForm({ module: e })}
            label={"CRM Profile Section"}
            tooltip="Select a section under which you want to place this field"
            textFieldProps={{
              placeholder: "Enter field title",
              helperText: errors?.module,
              error: Boolean(errors?.module),
            }}
            type="dropdown"
            options={ALL_MODULES}
            helperText={errors?.module}
            error={Boolean(errors?.module)}
          />
        </Grid>
      </Grid>
      {DROPDOWN_TYPES.includes(form.type) && (
        <>
          <DefaultLabelSmall sx={{ mt: 5 }}>Add Properties</DefaultLabelSmall>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={3}>
                {form?.options?.map((value, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={3.5}>
                      <CustomField
                        label={`${selectedType} Option ${index + 1}`}
                        value={form.options?.[index]}
                        onChange={(e) => editOption(e, index)}
                        labelRight={
                          index > 1 ? (
                            <PrimaryLabelSmall
                              size="small"
                              color="error"
                              onClick={() => onOptionRemove(index)}
                              sx={{ m: 0, fontSize: 11, cursor: "pointer" }}
                            >
                              Remove
                            </PrimaryLabelSmall>
                          ) : null
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Button startIcon={<Add />} sx={{ mt: 2 }} onClick={onAddOptions}>
            Add More
          </Button>
        </>
      )}
    </>
  );
};

const CustomFieldSettings = ({ form, editForm }) => {
  return (
    <>
      <PrimaryLabelSmall sx={{ mb: 3, fontWeight: "600" }}>
        Field Settings
      </PrimaryLabelSmall>
      <Box style={{}}>
        <CustomCheckbox
          value={form?.mandatory}
          onChange={(e) => editForm({ mandatory: e })}
          label="Mandetory Field"
          subtitle="Enabling this will mark the field as a required field"
          containerStyle={{ marginTop: 6 }}
        />
        <CustomCheckbox
          value={form?.unique}
          onChange={(e) => editForm({ unique: e })}
          label="Do not allow duplicate values"
          subtitle="Enabling this will prevent the creation of duplicate records"
          containerStyle={{ marginTop: 6 }}
        />
        <CustomCheckbox
          value={form?.show_tooltip}
          onChange={(e) => editForm({ show_tooltip: e })}
          label="Add tooltip"
          subtitle="Enabling this will add a description for the field"
          containerStyle={{ marginTop: 6 }}
        />
        {form?.show_tooltip && (
          <CustomField
            value={form?.description}
            onChange={(e) => editForm({ description: e })}
            textFieldProps={{
              label: "Enter tooltip content",
              multiline: true,
              rows: 3,
            }}
            sx={{ mt: 4 }}
          />
        )}
      </Box>
    </>
  );
};

const TeamMemberAssign = ({ form, editForm }) => {
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  let [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    let selectedTeamMembers = form?.team_members || [];
    let members = [...(reduxTeamMembers || [])]?.filter(
      (i) => !selectedTeamMembers?.find((j) => j.value === i.id)
    );
    setTeamMembers(
      members?.map((i) => {
        return {
          label: `${i.first_name} ${i.last_name}`,
          value: i.id,
        };
      })
    );
  }, [form.team_members, reduxTeamMembers]);

  useEffect(() => {
    if (!isArrayWithValues(reduxTeamMembers)) return;
    let selectedTeamMembers = form?.team_members || [];
    setTeamMembers(
      [...reduxTeamMembers]
        ?.filter((i) => !selectedTeamMembers?.find((j) => j.value === i.id))
        ?.map((i) => {
          return {
            label: `${i.first_name} ${i.last_name}`,
            value: i.id,
          };
        })
    );
  }, [reduxTeamMembers]);
  console.log(teamMembers, "<<< team members");
  return (
    <>
      <PrimaryLabelSmall sx={{ mb: 3, fontWeight: "600" }}>
        Allowed access to #{validateNumber(form?.team_members?.length)} Team
        Members
      </PrimaryLabelSmall>
      <Autocomplete
        multiple
        options={teamMembers}
        value={form?.team_members}
        onChange={(e, value) => editForm({ team_members: value })}
        getOptionLabel={(option) => option?.label}
        // defaultValue={[top100Films[13]]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Team Members"
            placeholder="Select team member"
          />
        )}
      />
    </>
  );
};
