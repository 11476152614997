import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import TopSection from "./components/TopSection";
import MessageSection from "./components/MessageSection";
import BottomSection from "./components/BottomSection";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { saveFlow } from "./helper/SaveFlow";
import * as actions from "../../../../../redux/actions/flowsActions";
import { chatbotStyles } from "./styles/chatbotStyles";
import Footer from "./helper/Footer";
import { backendApiCall } from "../../../../../helper";

const styles = chatbotStyles;

const Index = ({ data }) => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.flow?.testBotMessages);
  const prevNode = useSelector((state) => state.flow?.chatbotMessageResponse);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(true);

  const sendTime = (currTime) => {
    const formattedTime = format(currTime, "HH:mm");
    return formattedTime;
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputText(e.target.value);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === "") return;
    const newMessage = {
      id: Date.now(),
      text: inputText,
      isUser: true,
      time: sendTime(Date.now()),
    };
    setTimeout(() => {
      dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });
    }, [700]);

    setInputText("");
    const obj = {
      flow_data: data,
      user_response: inputText,
      prev_node: prevNode,
    };
    setTimeout(async () => {
      await backendApiCall({
        endpoint: "api/test-chatbot",
        method: "POST",
        data: obj,
      });
      // await saveFlow(obj);
    }, [700]);
  };

  const sendDataToBackend = async () => {
    const responseObj = {
      flowData: data,
      fallbackNodeId: "0002",
      currentNodeId: "0004",
    };
    // await saveFlow(responseObj);
    await backendApiCall({
      endpoint: "api/test-chatbot",
      method: "POST",
      data: responseObj,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      sendDataToBackend();
    }
  }, []);

  return (
    <Box sx={styles.outerBox}>
      <TopSection sendDataToBackend={sendDataToBackend} />
      <MessageSection messages={messages} data={data} />
      <BottomSection
        handleSendMessage={handleSendMessage}
        handleInputChange={handleInputChange}
        inputText={inputText}
        setInputText={setInputText}
      />
      <Footer />
    </Box>
  );
};

Index.propTypes = {
  setChatbot: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default Index;
