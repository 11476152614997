import { useSelector } from "react-redux";
import { Popover, Box, Typography } from "@mui/material";
import { optionPopoverStyles } from "../styles/optionPopoverStyles";
import { preDefinedAttributes } from "../helper/preDefinedAttributes";

const styles = optionPopoverStyles;

const SaveResponsePopover = ({
  questionId,
  questions,
  setQuestions,
  saveOptionsAnchorEl,
  setSaveOptionsAnchorEl,
}) => {
  const open = saveOptionsAnchorEl.openedPopoverId === questionId;
  const id = open ? "simple-popover" : undefined;
  const globalAttributes = useSelector((state) => state.flow?.globalAttributes);

  const handleClosePopover = () => {
    setSaveOptionsAnchorEl({
      openedPopoverId: null,
      anchorEl: null,
    });
  };

  const handleOptionClick = (type) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === questionId) {
          const updatedQuestion = { ...question };
          updatedQuestion.saveAttribute = type;
          return updatedQuestion;
        }
        return question;
      });
    });
    handleClosePopover();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={saveOptionsAnchorEl.anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box sx={{ ...styles.outerBox, width: "250px" }}>
        <Box
          sx={{
            ...styles.innerBox,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <Typography variant="h6" sx={styles.mainTitle}>
            PRE-DEFINED ATTRIBUTES
          </Typography>
        </Box>
        {preDefinedAttributes.length > 0 &&
          preDefinedAttributes.map((attribute, index) => (
            <Box
              key={index}
              sx={styles.innerBox}
              onClick={() => handleOptionClick(attribute)}
            >
              <Typography variant="span" sx={styles.innerBoxTitle}>
                {attribute}
              </Typography>
            </Box>
          ))}
        <Box
          sx={{
            ...styles.innerBox,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
         {globalAttributes.length > 0 && <Typography variant="h6" sx={{...styles.mainTitle, marginTop: "10px"}}>
            CUSTOM ATTRIBUTES
          </Typography>}
        </Box>

        {globalAttributes.length > 0 &&
          globalAttributes.map((attribute, index) => (
            <Box
              key={index}
              sx={styles.innerBox}
              onClick={() => handleOptionClick(attribute)}
            >
              <Typography variant="span" sx={styles.innerBoxTitle}>
                {attribute}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};

export default SaveResponsePopover;
