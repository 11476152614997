import { default as React, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { visuallyHidden } from "@mui/utils";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Switch from "rc-switch";
import {
  a11yProps,
  Base64,
  dialCodes,
  dialogStyle,
  formatDate,
  getAxiosError,
  getRandomString,
  getSocialIcon,
  getToken,
  getWebsite,
  getWooCommerce,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  secondaryLabel,
  settingsEndpoint,
  TabPanel,
  validateEmail,
  validateNumber,
} from "../../../helper";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_SETTINGS,
  addTeamMember,
} from "../../../redux/actions/settingActions";
import {
  LoadingButton,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/lab";
import { TeamSelect } from "../../../Svgs";
import styled from "styled-components";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../../redux/actions/helperActions";
import { getConversations } from "../../../redux/actions/chatActions";
import { ContentCopy, Delete, YouTube } from "@mui/icons-material";
import axios from "axios";
import { tutorialData } from "../../../App";
import PopperTooltip from "../../components/PopperTooltip";
import { StackRow } from "../../components/helper";
import UpgradeDialog from "../../components/UpgradeDialog";
import { commonDialCodes } from "../../crm/Add";
import displayState from "../../../validateProduct";

export default function RolesList() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [formOpen, setFormOpen] = React.useState(false);
  const [editObj, setEditObj] = React.useState({});
  const [showDelete, setShowDelete] = useState(false);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const fetchingTeamMembers = useSelector(
    (state) => state.settings.fetchingTeamMembers
  );

  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );

  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );
  const storeUsed = useSelector((state) => state.user.store_used);
  const storePlan = useSelector((state) => state.user.store_plan);
  const [limitReached, setLimitReached] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const upgradeRef = useRef();

  const closeDelete = () => setShowDelete(false);

  const openDelete = (id) => {
    setShowDelete(id);
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  console.log(isArrayWithValues(reduxTeamMembers), storePlan.users);
  React.useEffect(() => {
    if (isArrayWithValues(reduxTeamMembers) && storePlan.users) {
      setLimitReached(reduxTeamMembers.length >= Number(storePlan.users));
    } else if (storePlan && validateNumber(storePlan.users) == 0)
      setLimitReached(true);
  }, [storePlan, reduxTeamMembers]);
  React.useEffect(() => {
    if (isArray(reduxTeamMembers)) setRows([...reduxTeamMembers]);
  }, [reduxTeamMembers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const toggleForm = () => {
    setFormOpen(!formOpen);
    if (formOpen) setEditObj({});
  };
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const onMemberClick = (row) => {
    // console.log(row);
    setEditObj(row);
    toggleForm(true);
  };

  const onClose = () => {
    if (isObjWithValues(editObj)) {
      setEditObj({});
    }
    toggleForm(false);
  };

  let website = getWebsite();
  let token = getToken();
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const onDeleteCombination = async () => {
    try {
      setDeleting(true);
      let user = rows.find((i) => i.id === showDelete);
      let customer = await fetchCustomerByEmail(user.email);
      if (!customer.data) throw new Error();
      let updatedRoles = await updateCustomerRoles({
        id: customer.data,
        roles: [],
      });
      let array = [...(reduxTeamMembers || [])];
      array = array.filter((i) => i.id !== user.id);
      if (array?.length === 0) {
        await settingsEndpoint({
          endpoint: "team_members",
          method: "DELETE",
          params: {
            force_delete: 1,
          },
        });
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            teamMembers: [],
          },
        });
        setDeleting(false);
        closeDelete();
        dispatch(
          setGlobalToast({
            show: true,
            message: "User deleted successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          addTeamMember(array, false, () => {
            setDeleting(false);
            closeDelete();
            dispatch(
              setGlobalToast({
                show: true,
                message: "User deleted successfully!",
                severity: "success",
              })
            );
          })
        );
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't delete user! Please try again",
          severity: "error",
        })
      );
    }
  };

  const fetchCustomerByEmail = async (email) => {
    let website = getWebsite();
    let token = getToken();
    try {
      if (website && token) {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/customer/create`,
          data: { value: email },
          headers: { Authorization: `Basic ${token}` },
          method: "POST",
        });
        if (data.success) return data;
        // console.log(data);
        // if (isArrayWithValues(data)) return data;
      }
      // console.log(info);
      // if (info.website && info.token) {
      //   let { data } = await axios({
      //     url: `${info.website}/wp-json/wc/v3/customers`,
      //     params: { search: email },
      //     headers: { Authorization: `Basic ${info.token}` },
      //   });
      //   console.log(data);
      //   if (isArrayWithValues(data)) return data;
      // }
    } catch (error) {
      return false;
    }
  };
  const updateCustomerRoles = async (customer) => {
    let website = getWebsite();
    let token = getToken();
    try {
      if (website && token) {
        let { data } = await axios({
          url: `${website}/wp-json/wp/v2/users/${customer.id}`,
          method: "POST",
          headers: { Authorization: `Basic ${token}` },
          params: {
            ...customer,
          },
        });
        console.log(data);
        return data;
      }
      // if (info.website && info.token) {
      //   let { data } = await axios({
      //     url: `${info.website}/wp-json/wc/v3/customers/${customer.id}`,
      //     data: customer,
      //     method: "PUT",
      //     headers: { Authorization: `Basic ${info.token}` },
      //   });
      //   console.log(data);
      //   return data;
      // }
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const onAdd = () => {
    if (limitReached && !isDev())
      upgradeRef.current?.setUpgradeData({
        title: "You need to buy 1 user subscription to add a Team Member.",
        subtitle: "Contact: +91 916 995 995 9",
        primaryButton: "Contact Sales",
        primaryButtonClick: () => {
          window.open("tel:9169959959");
        },
      });
    else toggleForm();
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          // size="small"
          variant="contained"
          color="primary"
          sx={{ whiteSpace: "nowrap" }}
          // onClick={() => toggleForm()}
          onClick={onAdd}
          disabled={fetchingAllSettings || !allSettingsFetched}
        >
          Add member
        </Button>
      </Stack>
      {!isArrayWithValues(reduxTeamMembers) && fetchingTeamMembers && (
        <Box
          sx={{ marginTop: "25vh", marginBottom: "20vh", textAlign: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isArrayWithValues(reduxTeamMembers) && !fetchingTeamMembers && (
        <Box sx={{ marginTop: "10vh" }}>
          <Stack direction="row" justifyContent={"center"}>
            <TeamSelect />
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <Typography variant="h5">No team members added yet!</Typography>
          </Stack>
        </Box>
      )}
      {isArrayWithValues(rows) && (
        <Paper sx={{ width: "100%", mb: 2, mt: 5 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            dispatch={dispatch}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // let status = allStatus[getRandomInt(2)];
                    let { status } = row;
                    let encoded = JSON.stringify({
                      email: row.email,
                      website,
                      token:
                        token.slice(0, 3) + getRandomString(6) + token.slice(3),
                    });

                    let invite_link = `https://app.jwero.ai/auth/member_register?e=${Base64.btoa(
                      encoded
                    )}`;
                    return (
                      <>
                        <TableRow
                          hover
                          sx={{ cursor: "pointer" }}
                          onClick={() => onMemberClick(row)}
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Typography variant="body2" fontWeight={"bold"}>
                                {row.first_name} {row.last_name}
                              </Typography>
                              {isIpad &&
                                (status === "Active" ? (
                                  <Chip
                                    label={status}
                                    variant="outlined"
                                    color={"success"}
                                    size="small"
                                    sx={{
                                      border: isDarkTheme
                                        ? "0.7px solid"
                                        : "0px",
                                      backgroundColor: isDarkTheme
                                        ? "transparent"
                                        : "#E6F8E0",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label={status}
                                    variant="outlined"
                                    color={"error"}
                                    size="small"
                                    sx={{
                                      border: isDarkTheme
                                        ? "0.7px solid"
                                        : "0px",
                                      backgroundColor: isDarkTheme
                                        ? "transparent"
                                        : "#FFE3E1",
                                    }}
                                  />
                                ))}
                            </Stack>
                            {isIpad && (
                              <>
                                <Typography sx={{ color: "text.secondary" }}>
                                  {row.email}
                                </Typography>
                                <Stack direction="row" alignItems={"center"}>
                                  <strong>Invitation Link: </strong>
                                  <Typography sx={{ ml: 1 }}>
                                    {invite_link.substring(0, 25) + "..."}
                                  </Typography>
                                  <Tooltip title="Share this link to invite team member">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(
                                          invite_link
                                        );
                                        dispatch(
                                          setGlobalToast({
                                            show: true,
                                            severity: "success",
                                            message:
                                              "Invitation link is copied",
                                          })
                                        );
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </>
                            )}
                          </TableCell>
                          {!isIpad && (
                            <>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">
                                {row.created
                                  ? formatDate(
                                      new Date(row.created),
                                      "dd:mm:yyyy hh:mm am/pm"
                                    )
                                  : ""}
                              </TableCell>
                              <TableCell align="left">
                                {status === "complete" ? (
                                  <Chip
                                    label={status}
                                    variant="outlined"
                                    color={"success"}
                                    size="small"
                                    sx={{
                                      border: isDarkTheme
                                        ? "0.7px solid"
                                        : "0px",
                                      backgroundColor: isDarkTheme
                                        ? "transparent"
                                        : "#E6F8E0",
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label={status}
                                    variant="outlined"
                                    color={"error"}
                                    size="small"
                                    sx={{
                                      border: isDarkTheme
                                        ? "0.7px solid"
                                        : "0px",
                                      backgroundColor: isDarkTheme
                                        ? "transparent"
                                        : "#FFE3E1",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Stack direction="row" alignItems={"center"}>
                                  {/* <Typography>Invitation Link: </Typography> */}
                                  <Typography>
                                    {invite_link.substring(0, 14) + "..."}
                                  </Typography>
                                  <Tooltip title="Share this link to invite team member">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(
                                          invite_link
                                        );
                                        dispatch(
                                          setGlobalToast({
                                            show: true,
                                            severity: "success",
                                            message:
                                              "Invitation link is copied",
                                          })
                                        );
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowDelete(row.id);
                                    // navigator.clipboard.writeText(invite_link);
                                    // dispatch(
                                    //   setGlobalToast({
                                    //     show: true,
                                    //     severity: "success",
                                    //     message: "Invitation link is copied",
                                    //   })
                                    // );
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper>
      )}
      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          Delete following user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be done
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={deleting}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={formOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        sx={{ margin: 0, padding: 0, ...dialogStyle }}
        {...globalPopupStyle}
      >
        <AddForm toggleForm={toggleForm} editObj={editObj} />
      </Dialog>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}

      <UpgradeDialog ref={upgradeRef} />
    </Box>
  );
}

const AddForm = ({ toggleForm, editObj }) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [searchedCustomer, setSearchedCustomer] = React.useState([]);
  const [customerSearching, setCustomerSearching] = React.useState(false);
  const [customerSearch, setCustomerSearch] = React.useState("");
  const [productAccess, setProductAccess] = React.useState([
    "edit",
    "delete",
    "bulk_edit",
    "create_catalog",
    // "create_quotation",
  ]);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  let customerSearchTimeout;
  const [customerChooseType, setCustomerChooseType] = React.useState("all");
  const [CRMAccess, setCRMAccess] = React.useState([
    "add",
    "edit",
    "chat",
    "catalog",
  ]);
  const [chatsAccess, setChatsAccess] = React.useState(false);
  const [chatCustomerChooseType, setChatCustomerChooseType] =
    React.useState("all");

  const [selectedChatCustomers, setSelectedChatCustomers] = useState([]);
  const [excludedChatCustomers, setExcludedChatCustomers] = useState([]);
  const [settingsAccess, setSettingsAccess] = React.useState(false);
  const [integrationsAccess, setIntegrationsAccess] = React.useState(false);
  const [catalogsAccess, setCatalogsAccess] = React.useState(false);
  const [advancedForm, setAdvancedForm] = useState({
    ip: "",
    start_login_time: "",
    end_login_time: "",
    start_day: "",
    end_day: "",
  });
  const [allChatCustomers, setAllChatCustomers] = useState([]);
  const [form, setForm] = useState({
    dashboard_access: true,
    sales_app_access: true,
    has_digigold_access: false,
    delete_ledger_entry: false,
  });

  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));

  const [toast, setToast] = React.useState({ show: false });
  const [isEditing, setIsEditing] = React.useState(false);
  let reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  let reduxConversations = useSelector((state) => state.chats.conversations);

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const all_products_access = useSelector(
    (state) => state?.user?.store_plan?.all_products_access
  );
  const addingTeamMember = useSelector(
    (state) => state.settings.addingTeamMember
  );
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const editAdvancedForm = (payload) =>
    setAdvancedForm((state) => ({ ...state, ...payload }));

  const dispatch = useDispatch();

  const tabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (!isArrayWithValues(reduxConversations)) {
      dispatch(getConversations());
    }
  }, []);

  console.log(all_products_access);

  useEffect(() => {
    if (isArrayWithValues(reduxConversations)) {
      let array = [];
      for (let obj of reduxConversations) {
        let name =
          obj.name ||
          obj.username ||
          `${obj.first_name || ""} ${obj.last_name}`;
        if (name?.trim())
          array.push({
            label:
              obj.name ||
              obj.username ||
              `${obj.first_name || ""} ${obj.last_name}`,
            value: obj.id,
            platform: obj?.platform,
            contact_type: obj?.contact_type,
          });
      }
      setAllChatCustomers(array);
    }
  }, [reduxConversations]);

  React.useEffect(() => {
    if (isObjWithValues(editObj)) {
      let {
        first_name,
        last_name,
        email,
        phone,
        dialcode,
        access,
        settingsAccess,
        integrationsAccess,
        assigned_customers,
        id,
        customerIncluded,
        customerExcluded,
        catalogsAccess,
        advanced_access,
        chatsAccess,
        assignedChatCustomers,
        excludedChatCustomers,
        chatCustomerExcluded,
        chatCustomerIncluded,
        has_digigold_access,
        delete_ledger_entry,
      } = editObj;
      setFirstName(first_name);
      setLastName(last_name);
      setEmail(email);
      setSettingsAccess(settingsAccess);
      setChatsAccess(chatsAccess);
      setIntegrationsAccess(integrationsAccess);
      setCatalogsAccess(catalogsAccess);

      editForm({ dialcode, phone, has_digigold_access, delete_ledger_entry });
      setIsEditing(true);

      if (isObjWithValues(access)) {
        let { product, crm } = access;
        if (isArrayWithValues(product)) setProductAccess(product);
        if (isArrayWithValues(crm)) setCRMAccess(crm);
      }
      if (Array.isArray(assigned_customers))
        setSelectedCustomers(assigned_customers);
      if (Array.isArray(assignedChatCustomers))
        setSelectedChatCustomers(assignedChatCustomers);
      if (Array.isArray(excludedChatCustomers))
        setExcludedChatCustomers(excludedChatCustomers);
      if (customerExcluded) setCustomerChooseType("exclude");
      if (customerIncluded) setCustomerChooseType("include");
      if (chatCustomerExcluded) setChatCustomerChooseType("exclude");
      if (chatCustomerIncluded) setChatCustomerChooseType("include");
      if (isObjWithValues(advanced_access)) editAdvancedForm(advanced_access);
      // toggleForm(true);
    } else setIsEditing(false);
  }, [editObj]);
  // const [settingsAccess, setSettingsAccess] = React.useState([
  //   "masters",
  //   "product_grouping",
  //   "team_members",
  //   "size_masters",
  // ]);
  const onStateChange = (setState, value) => setState && setState(value);
  const onSubmit = () => {
    let {
      dashboard_access,
      sales_app_access,
      has_digigold_access,
      delete_ledger_entry,
    } = form;
    let obj = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: form?.phone,
      dialcode: form?.dialcode,
      access: {
        product: productAccess,
        crm: CRMAccess,
      },
      settingsAccess,
      integrationsAccess,
      catalogsAccess,
      assigned_customers: selectedCustomers,
      advanced_access: advancedForm,
      chatsAccess,
      assignedChatCustomers: selectedChatCustomers?.map((obj) => {
        delete obj.contact_type;
        return obj;
      }),
      excludedChatCustomers: excludedChatCustomers?.map((obj) => {
        delete obj.contact_type;
        return obj;
      }),
      dashboard_access,
      sales_app_access,
      has_digigold_access,
      delete_ledger_entry,
    };
    if (
      (advancedForm.start_login_time && !advancedForm.end_login_time) ||
      (!advancedForm.start_login_time && advancedForm.end_login_time)
    ) {
      setSelectedTab(2);
      return dispatch(
        setGlobalToast({
          show: true,
          severity: "error",
          message: "Please Enter start and end time OR Clear the time",
        })
      );
    }
    if (!email) {
      setSelectedTab(0);
      return dispatch(
        setGlobalToast({
          show: true,
          severity: "error",
          message: "Please enter user email",
        })
      );
    }
    if (email && !validateEmail(email)) {
      setSelectedTab(0);
      return dispatch(
        setGlobalToast({
          show: true,
          severity: "error",
          message: "Please enter valid user email",
        })
      );
    }
    if (!isEditing) {
      obj.id = Date.now();
      obj.created = Date.now();
      obj.status = "pending";
    }
    if (customerChooseType !== "all")
      if (customerChooseType === "include") {
        obj.customerExcluded = false;
        obj.customerIncluded = true;
      }
    if (customerChooseType === "exclude") {
      obj.customerExcluded = true;
      obj.customerIncluded = false;
    }
    if (chatCustomerChooseType !== "all") {
      obj.chatCustomerExcluded = chatCustomerChooseType === "exclude";
      obj.chatCustomerIncluded = chatCustomerChooseType === "include";
    }
    if (chatCustomerChooseType === "all") {
      obj.chatCustomerExcluded = false;
      obj.chatCustomerIncluded = false;
    }

    // console.log(reduxTeamMembers);
    console.log(JSON.stringify(obj, null, 2));
    if (Array.isArray(reduxTeamMembers) && !isEditing) {
      let duplicateEmail = reduxTeamMembers.find((i) => i.email === email);
      if (duplicateEmail)
        return dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "This email already exist!",
          })
        );
    }
    let temp = reduxTeamMembers || [];
    let array = [];
    let selectedCustomerIds = selectedChatCustomers?.map((o) => o.value);
    for (let member of temp) {
      if (member?.id !== editObj?.id) {
        member.assignedChatCustomers = member.assignedChatCustomers?.filter(
          (o) => !selectedCustomerIds?.includes(o?.value)
        );
      }
      array.push(member);
    }

    if (!isEditing)
      dispatch(
        addTeamMember(array.concat(obj), toggleForm, () => {
          dispatch(
            setGlobalToast({
              show: true,
              message:
                "Invite link is created, kindly Copy and share it via Email or other channel to Team member",
              severity: "success",
              timeout: 10000,
            })
          );
        })
      );
    if (isEditing) {
      let updatedObj = { ...editObj, ...obj };
      array = array.map((i) => (i.id === editObj.id ? updatedObj : i));
      dispatch(addTeamMember(array, toggleForm));
    }
  };
  const onCustomerChange = (value) => {
    setCustomerSearch(value);
    if (value.length >= 3)
      customerSearchTimeout = setTimeout(() => {
        fetchCustomers(value, setCustomerSearching, setSearchedCustomer);
      }, 400);
  };
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const hasProduct = (product) => user_products?.includes(product);
  let currentLetter = null;
  // console.log(selectedChatCustomers, excludedChatCustomers, allChatCustomers);
  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <DialogTitle id="alert-dialog-title">
        {isEditing ? "Edit Member" : "Invite new member"}
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={tabChange}
          aria-label="basic tabs example"
          sx={{ mb: 2 }}
        >
          <Tab label="Basic Info" {...a11yProps(0)} />
          <Tab label="Access" {...a11yProps(1)} />
          <Tab label="Advanced" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label>First Name</Label>
              <TextField
                // label="First Name"
                fullWidth
                // sx={{ mt: 4 }}
                value={firstName}
                onChange={(e) => onStateChange(setFirstName, e.target.value)}
                // size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Last Name</Label>
              <TextField
                // label="Last Name"
                fullWidth
                // sx={{ mt: 4 }}
                value={lastName}
                onChange={(e) => onStateChange(setLastName, e.target.value)}
                // size="small"
              />
            </Grid>
          </Grid>
          <Label>Email</Label>
          <TextField
            // label="Email"
            type="email"
            fullWidth
            // sx={{ mt: 4 }}
            disabled={isEditing && !isDev()}
            value={email}
            onChange={(e) => onStateChange(setEmail, e.target.value)}
            // size="small"
          />
          <Label>Mobile</Label>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Autocomplete
                // disablePortal
                // inputValue={form?.dialcode}
                value={form?.dialcode || ""}
                id="combo-box-demo"
                options={[
                  ...commonDialCodes?.map((obj) => ({
                    ...obj,
                    group: "Common Dial Codes",
                  })),
                  ...dialCodes?.map((obj) => ({ ...obj, group: "Dial Codes" })),
                ]}
                groupBy={(option) => option.group}
                PaperComponent={({ children }) => (
                  <Paper style={{ width: 300 }}>{children}</Paper>
                )}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  editForm({ dialcode: newValue?.value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                // label="Email"

                fullWidth
                type="number"
                // sx={{ mt: 4 }}
                disabled={isEditing && !isDev()}
                value={form?.phone || ""}
                onChange={(e) => editForm({ phone: e.target.value })}
                // size="small"
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Stack
            direction={"row"}
            mt={3}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography mt={0} variant="body2">
              Dashboard Access
            </Typography>
            <Switch
              style={
                form.dashboard_access
                  ? {
                      margin: "0px 8px",
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                  : {
                      margin: "0px 8px",
                    }
              }
              checked={form.dashboard_access}
              onChange={(e) => editForm({ dashboard_access: e })}
            />
          </Stack>
          <Collapse in={form.dashboard_access}>
            <>
              {displayState({
                key: "productStack",
              }) && (
                <>
                  {hasProduct("chats") || hasProduct("crm") ? null : (
                    <Stack
                      direction={"row"}
                      mt={2}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Typography mt={0} mr={2} variant="body2">
                        Products
                      </Typography>
                      <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={productAccess}
                          multiple
                          onChange={(e) =>
                            onStateChange(setProductAccess, e.target.value)
                          }
                          autoWidth
                          label="Age"
                          variant="standard"
                          placeholder="Select "
                        >
                          <MenuItem value={"view"}>View</MenuItem>
                          <MenuItem value={"edit"}>Edit</MenuItem>
                          <MenuItem value={"add"}>Add</MenuItem>
                          <MenuItem value={"delete"}>Delete</MenuItem>
                          <MenuItem value={"bulk_edit"}>Bulk Edit</MenuItem>
                          <MenuItem value={"create_catalog"}>
                            Create Catalog
                          </MenuItem>
                          {/* <MenuItem value={"create_quotation"}>Create Quotation</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Stack>
                  )}
                </>
              )}
              {onlyChats ? null : (
                <>
                  {displayState({
                    key: "crmStack",
                  }) && (
                    <>
                      <Stack
                        direction={"row"}
                        mt={2}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography mt={0} mr={2} variant="body2">
                          CRM
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={CRMAccess}
                            multiple
                            onChange={(e) =>
                              onStateChange(setCRMAccess, e.target.value)
                            }
                            autoWidth
                            variant="standard"
                            placeholder="None"
                          >
                            <MenuItem value={"add"}>Add</MenuItem>
                            <MenuItem value={"edit"}>Edit</MenuItem>
                            <MenuItem value={"catalogs"}>Catalogs</MenuItem>
                            {/* <MenuItem value={"quotations"}>Quotations</MenuItem> */}
                            {/* <MenuItem value={"chat"}>Chat</MenuItem> */}
                            {/* <MenuItem value={"analytics"}>Analytics</MenuItem> */}
                            <MenuItem value={"notes"}>Notes</MenuItem>
                            {/* <MenuItem value={"pricing"}>Pricing</MenuItem> */}
                            {/* <MenuItem value={"contacts"}>Contacts</MenuItem> */}
                            {/* <MenuItem value={"bulk_edit"}>Bulk Edit</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Stack>
                      {onlyChats ? null : (
                        <Stack
                          direction={"row"}
                          mt={3}
                          justifyContent="space-between"
                          alignItems={"center"}
                        >
                          <Typography mt={0} variant="body2">
                            Choose customers
                          </Typography>
                          <Stack direction={"row"} alignItems="center">
                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                              <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={customerChooseType}
                                // multiple
                                onChange={(e) =>
                                  onStateChange(
                                    setCustomerChooseType,
                                    e.target.value
                                  )
                                }
                                autoWidth
                                variant="standard"
                                placeholder="None"
                              >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"include"}>Include</MenuItem>
                                <MenuItem value={"exclude"}>Exclude</MenuItem>
                                {/* <MenuItem value={"bulk_edit"}>Bulk Edit</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Stack>
                        </Stack>
                      )}
                      <Collapse
                        in={
                          customerChooseType === "include" ||
                          customerChooseType === "exclude"
                        }
                      >
                        <Autocomplete
                          // freeSolo
                          disablePortal
                          openOnFocus
                          id="free-solo-2-demo"
                          sx={{ mt: 2 }}
                          multiple
                          disableClearable
                          getOptionLabel={(option) => option.label || ""}
                          options={searchedCustomer}
                          noOptionsText={
                            customerSearch
                              ? "No customer found"
                              : "Enter 3 or more character"
                          }
                          value={selectedCustomers}
                          loading={customerSearching}
                          onChange={(e, value) => setSelectedCustomers(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={customerSearch}
                              onChange={(e) => onCustomerChange(e.target.value)}
                              label="Search customers"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                // endAdornment: (
                                //   <React.Fragment>
                                //     {customerSearching ? (
                                //       <CircularProgress color="inherit" size={20} />
                                //     ) : null}
                                //     {params.InputProps.endAdornment}
                                //   </React.Fragment>
                                // ),
                              }}
                              size="small"
                              helperText={
                                customerChooseType === "include"
                                  ? `${
                                      firstName ? firstName : "User"
                                    } will only able to see above customers`
                                  : `${
                                      firstName ? firstName : "User"
                                    } won't be able to see above customers`
                              }
                              FormHelperTextProps={{ sx: { color: "red" } }}
                            />
                          )}
                        />
                      </Collapse>
                    </>
                  )}
                </>
              )}

              {displayState({
                key: "chatsStack",
              }) && (
                <>
                  {" "}
                  <Stack
                    direction={"row"}
                    mt={3}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography mt={0} variant="body2">
                      Chats
                    </Typography>
                    <Switch
                      style={
                        chatsAccess
                          ? {
                              margin: "0px 8px",
                              border: `1px solid ${backgroundColor}`,
                              backgroundColor,
                            }
                          : {
                              margin: "0px 8px",
                            }
                      }
                      checked={chatsAccess}
                      onChange={(e) => setChatsAccess(e)}
                    />
                  </Stack>
                  <Collapse in={chatsAccess}>
                    <Stack
                      direction={"row"}
                      mt={3}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Typography mt={0} variant="body2">
                        Choose chat customers
                      </Typography>
                      <Stack direction={"row"} alignItems="center">
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={chatCustomerChooseType}
                            // multiple
                            onChange={(e) => {
                              onStateChange(
                                setChatCustomerChooseType,
                                e.target.value
                              );
                            }}
                            autoWidth
                            variant="standard"
                            placeholder="None"
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"include"}>Include</MenuItem>
                            <MenuItem value={"exclude"}>Exclude</MenuItem>
                            {/* <MenuItem value={"bulk_edit"}>Bulk Edit</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Stack>

                    <Collapse
                      in={
                        chatCustomerChooseType === "include" ||
                        chatCustomerChooseType === "exclude"
                      }
                    >
                      <Autocomplete
                        // freeSolo
                        disablePortal
                        openOnFocus
                        id="free-solo-2-demo"
                        sx={{ mt: 2 }}
                        multiple
                        disableClearable
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                          return (
                            <StackRow gap={1}>
                              {getSocialIcon(option?.platform)}{" "}
                              {option?.label || ""}
                              {option?.contact_type && (
                                <Chip
                                  size="small"
                                  label={option?.contact_type}
                                />
                              )}
                            </StackRow>
                          );
                        }}
                        renderGroup={(params) => (
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="primary"
                              sx={{
                                position: "sticky",
                                top: "-8px",
                                padding: "4px 10px",
                                backgroundColor: "background.paper",
                                zIndex: 12,
                              }}
                            >
                              Total Chats ({params.children.length})
                            </Typography>
                            {params.children}
                          </div>
                        )}
                        groupBy={() => "Options"}
                        // renderOption={(props, option, third, forth) => {
                        //   console.log(props, option, third, forth);
                        // }}

                        // groupBy={(option) => option.label.charAt(0)}
                        // renderGroup={(params, group) => (
                        //   <li key={group} {...params}>
                        //     {params.group && (
                        // <GroupHeader>Hi</GroupHeader>
                        //     )}
                        //     <GroupItems>{params.children}</GroupItems>
                        //   </li>
                        // )}
                        ListboxProps={{ sx: { maxHeight: "250px" } }}
                        filterOptions={(fieldTypeList, state) => {
                          console.log(fieldTypeList, state, "<<<<falsjdfl");
                          return fieldTypeList.filter((opt) =>
                            chatCustomerChooseType === "include"
                              ? !selectedChatCustomers.find(
                                  (i) => i.value == opt.value
                                )
                              : chatCustomerChooseType === "exclude"
                              ? !excludedChatCustomers.find(
                                  (i) => i.value == opt.value
                                )
                              : true
                          );
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.label}
                              avatar={getSocialIcon(option?.platform)}
                              size="small"
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        options={allChatCustomers}
                        noOptionsText={
                          customerSearch
                            ? "No customer found"
                            : "Enter 3 or more character"
                        }
                        value={
                          chatCustomerChooseType === "include"
                            ? selectedChatCustomers
                            : excludedChatCustomers
                        }
                        loading={customerSearching}
                        onChange={(e, value) =>
                          chatCustomerChooseType === "include"
                            ? setSelectedChatCustomers(value)
                            : setExcludedChatCustomers(value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // value={customerSearch}
                            // onChange={(e) => onCustomerChange(e.target.value)}
                            label="Search customers"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              // endAdornment: (
                              //   <React.Fragment>
                              //     {customerSearching ? (
                              //       <CircularProgress color="inherit" size={20} />
                              //     ) : null}
                              //     {params.InputProps.endAdornment}
                              //   </React.Fragment>
                              // ),
                            }}
                            size="small"
                            helperText={
                              chatCustomerChooseType === "include"
                                ? `${
                                    firstName ? firstName : "User"
                                  } only able to see above customers`
                                : `${
                                    firstName ? firstName : "User"
                                  } won't be able to see above customers`
                            }
                            value={
                              chatCustomerChooseType === "include"
                                ? selectedChatCustomers
                                : excludedChatCustomers
                            }
                            loading={customerSearching}
                            onChange={(e, value) =>
                              chatCustomerChooseType === "include"
                                ? setSelectedChatCustomers(value)
                                : setExcludedChatCustomers(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // value={customerSearch}
                                // onChange={(e) => onCustomerChange(e.target.value)}
                                label="Search customers"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  // endAdornment: (
                                  //   <React.Fragment>
                                  //     {customerSearching ? (
                                  //       <CircularProgress color="inherit" size={20} />
                                  //     ) : null}
                                  //     {params.InputProps.endAdornment}
                                  //   </React.Fragment>
                                  // ),
                                }}
                                size="small"
                                helperText={
                                  chatCustomerChooseType === "include"
                                    ? `${
                                        firstName ? firstName : "User"
                                      } only able to see above customers`
                                    : `${
                                        firstName ? firstName : "User"
                                      } won't be able to see above customers`
                                }
                                FormHelperTextProps={{ sx: { color: "red" } }}
                              />
                            )}
                          />
                        )}
                      />
                    </Collapse>
                  </Collapse>
                </>
              )}
              <Stack
                direction={"row"}
                mt={3}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography mt={0} variant="body2">
                  Digigold Access
                </Typography>
                <Switch
                  style={
                    form.has_digigold_access
                      ? {
                          margin: "0px 8px",
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                        }
                      : {
                          margin: "0px 8px",
                        }
                  }
                  checked={form.has_digigold_access}
                  onChange={(e) => editForm({ has_digigold_access: e })}
                />
              </Stack>
              <Stack
                direction={"row"}
                mt={3}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography mt={0} variant="body2">
                  Delete ledger/order from digigold
                </Typography>
                <Switch
                  style={
                    form.delete_ledger_entry
                      ? {
                          margin: "0px 8px",
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                        }
                      : {
                          margin: "0px 8px",
                        }
                  }
                  checked={form.delete_ledger_entry}
                  onChange={(e) => editForm({ delete_ledger_entry: e })}
                />
              </Stack>
              <Stack
                direction={"row"}
                mt={3}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography mt={0} variant="body2">
                  Settings
                </Typography>
                <Switch
                  style={
                    settingsAccess
                      ? {
                          margin: "0px 8px",
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                        }
                      : {
                          margin: "0px 8px",
                        }
                  }
                  checked={settingsAccess}
                  onChange={(e) => setSettingsAccess(e)}
                />
              </Stack>
              <Stack
                direction={"row"}
                mt={3}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography mt={0} variant="body2">
                  Integrations
                </Typography>
                <Switch
                  style={
                    integrationsAccess
                      ? {
                          margin: "0px 8px",
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                        }
                      : {
                          margin: "0px 8px",
                        }
                  }
                  checked={integrationsAccess}
                  onChange={(e) => setIntegrationsAccess(e)}
                />
              </Stack>
              {displayState({
                key: "catalogStack",
              }) && (
                <Stack
                  direction={"row"}
                  mt={3}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography mt={0} variant="body2">
                    Catalogs
                  </Typography>
                  <Switch
                    style={
                      catalogsAccess
                        ? {
                            margin: "0px 8px",
                            border: `1px solid ${backgroundColor}`,
                            backgroundColor,
                          }
                        : {
                            margin: "0px 8px",
                          }
                    }
                    checked={catalogsAccess}
                    onChange={(e) => setCatalogsAccess(e)}
                  />
                </Stack>
              )}
            </>
          </Collapse>
          {all_products_access && !isArrayWithValues(user_products) && (
            <Stack
              direction={"row"}
              mt={3}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography mt={0} variant="body2">
                Sales App Access
              </Typography>
              <Switch
                style={
                  form.sales_app_access
                    ? {
                        margin: "0px 8px",
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                    : {
                        margin: "0px 8px",
                      }
                }
                checked={form.sales_app_access}
                onChange={(e) => editForm({ sales_app_access: e })}
              />
            </Stack>
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <AdvancedForm form={advancedForm} editForm={editAdvancedForm} />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm}>Cancel</Button>
        <LoadingButton
          loading={addingTeamMember}
          onClick={onSubmit}
          autoFocus
          variant="contained"
        >
          {isEditing ? "Update" : "Invite"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const AdvancedForm = ({ form, editForm }) => {
  let ip = useSelector((state) => state.settings.ip);
  return (
    <Box>
      <Label style={{ display: "flex", alignItems: "center", gap: "3px" }}>
        IP Address{" "}
        <Typography
          variant="caption"
          sx={{ ...secondaryLabel, fontWeight: "500" }}
        >
          {ip ? `(Current IP: ${ip})` : ""}
        </Typography>{" "}
        <PopperTooltip
          points={[
            "Write IP Address(es) for a secured access.",
            "You can add mutliple ids (seperated by comma) or can add range eg.192.168.0.1-192.168.0.255.",
            "Leave blank, allows to access system from any ip",
          ]}
        />
      </Label>
      <TextField
        // label="Last Name"
        placeholder="For e.g 192:168:0:0"
        fullWidth
        // sx={{ mt: 4 }}
        value={form.ip}
        onChange={(e) => editForm({ ip: e.target.value })}
        // size="small"
        // helperText="For IP range: 192:168:0:1-192:168:0:255"
        // helperText=""
      />
      {/* <FormHelperText></FormHelperText> */}
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        {/* <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          sx={{ mt: "12px", mb: 5 }}
        > */}
        <Label style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          Time Range{" "}
          <PopperTooltip
            points={[
              "Allow a time range, during which , users can access the system.",
              "Leave blank, allows to access system without restriction",
            ]}
          />
        </Label>

        {/* </Stack> */}
        {form.start_login_time || form.end_login_time ? (
          <Button
            size="small"
            onClick={() =>
              editForm({ start_login_time: "", end_login_time: "" })
            }
          >
            Clear Time
          </Button>
        ) : null}
      </Stack>
      <Stack direction="row" alignItems={"center"} sx={{ mt: 2 }} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            // label="Anniversary"
            value={form.start_login_time ? form.start_login_time : null}
            onChange={(newValue) => {
              console.log(newValue);
              editForm({ start_login_time: newValue });
            }}
            renderInput={(params) => {
              return <TextField fullWidth {...params} />;
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            // label="Anniversary"
            value={form.end_login_time ? form.end_login_time : null}
            onChange={(newValue) => {
              editForm({ end_login_time: newValue });
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Stack>
      <FormHelperText> </FormHelperText>
      <Label style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        Day Range{" "}
        <PopperTooltip
          points={[
            "Select work days, during which , users can access the system.",
            "Leave blank will allow access the system, all days without restriction",
          ]}
        />
      </Label>
      <Stack direction="row" alignItems={"center"} sx={{ mt: 2 }} spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Start Day</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.start_day}
            label="Start Day"
            onChange={(e) => editForm({ start_day: e.target.value })}
          >
            {daysArray.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">End Day</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.end_day}
            label="End Day"
            onChange={(e) => editForm({ end_day: e.target.value })}
          >
            {daysArray.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <FormHelperText> </FormHelperText>
    </Box>
  );
};

export const daysArray = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

const fetchCustomers = async (search, setLoading, setState) => {
  setLoading && setLoading(true);
  let WooCommerce = await getWooCommerce();
  if (WooCommerce) {
    WooCommerce.get("customers", { search })
      .then((res) => {
        let { data } = res;
        if (isArrayWithValues(data)) {
          let array = data.map((i) => ({
            label: `${i.first_name} ${i.last_name}`.trim() || i.email,
            value: i.id,
          }));
          setState(array);
        }
      })
      .finally(() => setLoading && setLoading(false));
  }
};

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    // protein,
  };
}

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 5px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "link",
    numeric: false,
    disablePadding: false,
    label: "Invitation Link",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];
const headCellsMobile = [
  {
    id: "members",
    numeric: false,
    disablePadding: false,
    label: "Members",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const data = isIpad ? [] : headCells;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {data.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, dispatch } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Team members
          <IconButton
            onClick={() =>
              dispatch(
                setGlobalTutorial({
                  show: true,
                  url: tutorialData?.teamMember?.link,
                  title: tutorialData?.teamMember?.title,
                })
              )
            }
          >
            <YouTube />
          </IconButton>
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
