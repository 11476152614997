export const getAllowedNodes = ({ currentNodeType, isNodeLast }) => {
  const allNodes = [
    "bot",
    "user",
    // "user-attachment",
    "question",
    "filter",
    "fallback",
    "go-to-step",
    "whatsapp-template",
    "live-agent",
    "product-catalog",
    // "email",
    // "sms",
    // "push-notification",
    // "delay",
    "subscribe",
    "unsubscribe",
  ];

  const canBeAddedLastOnly = [
    "whatsapp-template",
    "question",
    "email",
    "sms",
    "push-notification",
    "live-agent",
  ];

  const multipleAddedNodes = ["user", "user-attachment", "filter", "fallback"];

  let currentValidNodes = isNodeLast
    ? allNodes
    : allNodes.filter((node) => !canBeAddedLastOnly.includes(node));

  switch (currentNodeType) {
    case "user": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      return resultNodes;
    }
    case "filter": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      return resultNodes;
    }
    case "fallback": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      resultNodes.push("filter");
      return resultNodes;
    }
    default:
      return currentValidNodes;
  }
};
