export const initialNodes = {
  startNode: {
    id: "0001",
    type: "start",
    data: { label: "Start" },
    position: {
      x: 0,
      y: 0,
    },
    fieldData: [],
    title: "Start Point",
    userConnected: 0,
  },

  defaultFallbackNode: {
    id: "0002",
    type: "fallback",
    data: { label: "Default Fallback" },
    position: {
      x: 210,
      y: 75,
    },
    fieldData: [],
    title: "Default Fallback"
  },

  BotNodeFallback: {
    id: "0003",
    type: "bot",
    userConnected: 0,
    data: { label: "Bot" },
    position: {
      x: 450,
      y: 75,
    },
    fieldData: []
  },

  BotNode: {
    id: "0004",
    type: "bot",
    userConnected: 0,
    data: { label: "Bot defaultfallback" },
    position: {
      x: 210,
      y: -75,
    },
    fieldData: []
  },
};
