import { MoreVert, OpenInNew } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Rating,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { dialogStyle, formatDate, getAxiosError, getId, getStoreEmail, getToken, getWebsite, isArrayWithValues, } from "../../../helper";
import { deleteReview, getReplies, getReviews, updateReview } from "../../../redux/actions/pagesActions";
import { QuillWrapper } from "../../products/AddProduct";
// import "../../../App.css"
const tabs = [
    { label: "All", value: "all" },
    { label: "Approved", value: "approved" },
    { label: "Un Approved", value: "hold" },
    { label: "Replied", value: "replied" },
    { label: "Spam", value: "spam" },
];
const Reviews = () => {
    const [selectedTab, setSelectedTab] = useState("all");
    const [allReviews, setAllReviews] = useState([]);
    const [allReplies, setAllReplies] = useState([])
    const [allReviewsStore, setAllReviewsStore] = useState([])
    const allReduxReviews = useSelector((state) => state.pages.allReviews) || []
    const allReduxReplies = useSelector((state) => state.pages.allReplies);
    const fetchingReviews = useSelector((state) => state.pages.fetchingReviews);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!isArrayWithValues(allReduxReviews)) dispatch(getReviews());
        if (!isArrayWithValues(allReduxReplies)) dispatch(getReplies());
    }, []);
    useEffect(() => {
        if (isArrayWithValues(allReduxReviews)) setAllReviews(allReduxReviews);
        if (isArrayWithValues(allReduxReviews)) setAllReviewsStore(allReduxReviews);
    }, [allReduxReviews]);
    useEffect(() => {
        if (isArrayWithValues(allReduxReplies)) setAllReplies(allReduxReplies);
    }, [allReduxReplies]);

    useEffect(() => {
        if (isArrayWithValues(allReduxReplies) && isArrayWithValues(allReduxReviews)) {
            let newArr = allReduxReviews.map(data => {
                let replyArr = allReplies.filter(reply => reply.parent == data.id)
                console.log(replyArr);
                return { ...data, replies: replyArr }
            })
            console.log(newArr);
            setAllReviewsStore(newArr)
            setAllReviews(newArr);
        }
    }, [allReplies, allReduxReviews])


    const onTabChange = (val) => {
        // if (!isArrayWithValues(allReduxReviews)) return
        setSelectedTab(val)
        if (val === "all") setAllReviews(allReduxReviews)
        else if (val === "replied") {
            let filterArr = allReviewsStore.filter(obj => obj.replies)
            console.log(filterArr, allReviewsStore);
            setAllReviews(filterArr)
        }
        else {
            let filterArr = allReduxReviews.filter(obj => obj.status === val)
            setAllReviews(filterArr)
        }
    }
    return (
        <Box sx={{ padding: 0 }}>
            <Stack sx={{ position: "sticky", top: "0", marginTop: "0", padding: "12px", backgroundColor: "#fff", zIndex: 11 }}>
                <Typography variant="h3" >Reviews</Typography>
                <Stack direction={"row"} sx={{ mt: 5, overflow: "auto" }} >
                    {tabs.map((i) => (
                        <Button
                            variant={"text"}
                            startIcon={i.icon}
                            // variant={selectedTab !== i.value ? "outlined" : "contained"}
                            sx={{
                                // gap: "15px",
                                padding: "6px 20px",
                                backgroundColor:
                                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                                color: "#000",
                                // color: selectedTab === i.value ? "primary.main" : "#000",
                                "&:hover": {
                                    backgroundColor:
                                        selectedTab === i.value ? "#e6e6e6" : "transparent",
                                },
                            }}
                            onClick={() => onTabChange(i.value)}
                        >
                            <Typography>{i.label}</Typography>
                        </Button>
                    ))}
                </Stack>
                <Divider sx={{ mt: 3 }} />
            </Stack>
            <Box sx={{ padding: "12px", marginBottom: "60px" }}>
                <Grid container spacing={7} rowSpacing={6}>
                    {allReviews.map((obj) => (

                        <Grid item lg={6} xs={12}>
                            <ReviewCard obj={obj} setAllReplies={setAllReplies} />
                        </Grid>
                    ))}
                </Grid>

                {fetchingReviews && <Grid container spacing={5} rowSpacing={4}>
                    {Array(8).fill("").map((i) => (
                        <Grid item lg={6} xs={12}>
                            <LoadingCards />
                        </Grid>

                    ))}
                </Grid>}
            </Box>
        </Box>
    );
};

export default Reviews;

const LoadingCards = () => {
    return <Card sx={{ padding: "16px", }} className="shadow_card">
        <Stack sx={{ mb: 3 }} justifyContent="space-between" direction={"row"}>
            <Stack direction={"row"} sx={{ gap: "10px" }} alignItems="center">
                <Skeleton variant="circular" width={40} height={40} />
                <Stack>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "100px" }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "60px" }} />

                </Stack>
            </Stack>
        </Stack>
        <Skeleton variant="rounded" width={"90%"} height={30} />
        <Stack direction={"row"} sx={{ gap: "8px" }} alignItems="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "70%" }} />

        </Stack>
        <Stack direction={"row"} sx={{ gap: "8px", mt: 3 }} alignItems="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "30px" }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "30px" }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "30px" }} />
        </Stack>
    </Card>
}

const ReviewCard = ({ obj, setAllReplies }) => {

    let { id,
        date_created,
        reviewer,
        reviewer_email,
        rating,
        product_name,
        status,
        review,
        verified,
        product_permalink,
        reviewer_avatar_urls,

    } = obj;
    let replies = obj?.replies || []
    const [loading, setLoading] = useState(false)
    let avatarArray = Object.values(reviewer_avatar_urls) || [];
    const dispatch = useDispatch()
    const buttonArr = [
        { label: "Approved", value: "approved" },
        { label: "Un Approved", value: "hold" },
        { label: "Replied", value: "replied" },
        { label: "Spam", value: "spam" },
        // { label: "Delete", value: "delete" },
    ];

    const onGoproduct = () => {
        window.open(product_permalink, "_blank");
    };
    const [showReviewForm, setShowReviewForm] = useState(false)
    const onUpdateStatus = async (val) => {
        setShowReviewForm(obj)
        if (val === "replied") return
        setLoading(val)
        await dispatch(updateReview(id, { status: val }))
        setLoading(false)
    }
    const ondelete = async () => {
        setLoading("delete")
        await dispatch(deleteReview(id))
        setLoading(false)
    }

    return (
        <Card sx={{ padding: "25px", }} className="shadow_card">
            <Stack sx={{ mb: 3 }} justifyContent="space-between" direction={"row"}>
                <Stack direction={"row"} sx={{ gap: "10px" }} alignItems="center">
                    <Avatar />
                    <Stack>
                        <Typography>{reviewer}</Typography>
                        <Typography>{reviewer_email}</Typography>
                    </Stack>
                </Stack>
                <IconButton onClick={onGoproduct}>
                    <OpenInNew fontSize="small" />
                </IconButton>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "10px", mb: 3 }} alignItems="center">
                <Rating name="read-only" value={rating} readOnly size="small" />
                <Typography>
                    {formatDate(new Date(date_created), "relative_date")}
                </Typography>
            </Stack>
            <div
                dangerouslySetInnerHTML={{ __html: review }}
                style={{ fontSize: "15px", fontWeight: "400" }}
            ></div>
            <Stack direction={"row"} sx={{ gap: "8px" }} alignItems="center">
                <Typography>In Response To </Typography>{" "}
                <Typography variant="subtitle2">{product_name}</Typography>
            </Stack>
            <Stack direction={"row"} sx={{ gap: "8px", mt: 3 }} alignItems="center">
                {buttonArr.map((obj) => (
                    <LoadingButton
                        loading={loading === obj.value}
                        sx={{ padding: "0px 8px", borderRadius: 4 }}
                        variant={status === obj.value ? "contained" : "outlined"}
                        size="small"
                        onClick={() => {
                            onUpdateStatus(obj.value)
                        }}
                    >
                        {obj.label}
                    </LoadingButton>
                ))}
                <LoadingButton
                    loading={loading === "delete"}
                    variant="outlined"
                    size="small"
                    sx={{ padding: "0px 8px", borderRadius: 4 }}
                    color="error"
                    onClick={ondelete}
                >
                    Delete
                </LoadingButton>
            </Stack>
            {isArrayWithValues(replies) ? <Stack sx={{ mt: 1 }}>
                <Typography variant="subtitle2">Replies</Typography>
                {replies.map(obj => <Stack > <Typography variant="subtitle2">Author : {obj?.author_name}</Typography>  <Typography>{obj?.content?.rendered.replace(/(<\/?\w+?>)\s*?(<\/?\w+?>)|(<\/?\w+?>)/g, '')}</Typography> </Stack>)}
            </Stack> : null}
            <ReplyRviewDailog open={showReviewForm} handleClose={() => setShowReviewForm(false)} setAllReplies={setAllReplies} />
        </Card>
    );
};


const ReplyRviewDailog = ({ open, handleClose, setAllReplies }) => {

    const [reviewContent, setReviewContent] = useState("")
    const [loading, setLoading] = useState(false)
    const id = getId()
    const email = getStoreEmail()

    const onSubmit = async () => {
        let website = getWebsite();
        let token = getToken();
        if (!website && !token) return false
        setLoading(true)
        try {
            const res = await axios({
                url: `${website}/wp-json/wp/v2/comments`,
                headers: {
                    Authorization: `Basic ${token}`
                },
                method: "POST",
                data: {
                    author: id,
                    author_email: email,
                    parent: open?.id,
                    content: reviewContent,
                    post: open?.product_id
                }
            })
            if (res?.data?.id) {
                setAllReplies(pre => ([...pre, res?.data]))
                handleClose()

            }
            console.log(res);
        } catch (error) {
            console.log(getAxiosError(error));
        }
        setLoading(false)


    }
    const globalPopupStyle = useSelector(state => state.helper.globalPopupStyle) || {}
    return <Dialog open={open} onClose={handleClose} sx={dialogStyle} fullWidth maxWidth="sm" {...globalPopupStyle} >
        <DialogTitle>Reply Review ({open?.reviewer})</DialogTitle>
        <DialogContent>
            <QuillWrapper>

                <ReactQuill value={reviewContent} onChange={(e) => setReviewContent(e)} />
            </QuillWrapper>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} onClick={onSubmit}>Reply</LoadingButton>
            </DialogActions>
        </DialogContent>
    </Dialog>
}