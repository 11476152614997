export const canBeAddedAnywhere = [
  "bot",
  "user",
  "whatsapp-template",
  "product-catalog",
  "filter",
  "email",
  "sms",
  "push-notification",
  "subscribe",
  "unsubscribe",
  "success",
  "failure",
  "delay"
];

export const canOnlyBeAddedAtLast = ["live-agent", "go-to-step", "question"];

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const ifLast = (edges, currentNodeId) => {
  const checkEdges = edges.find((edge) => edge.source === currentNodeId);
  if (checkEdges) {
    return false;
  }
  return true;
};

export const nodeCanBeAdded = ({ edges, currentNode, nodeAddedType }) => {
  const currentNodeId = currentNode?.id;

  if (currentNode?.type === "user" && nodeAddedType === "user") {
    return false;
  }

  if (canBeAddedAnywhere.includes(nodeAddedType)) {
    return true;
  }

  if (canOnlyBeAddedAtLast.includes(nodeAddedType)) {
    const flag = ifLast(edges, currentNodeId);
    if (flag) {
      return true;
    }
  }

  return false;
};

export const nodeCanBeAddedErrorMessage = ({
  edges,
  currentNode,
  nodeAddedType,
}) => {
  const currentNodeId = currentNode?.id;

  if (currentNode?.type === "user" && nodeAddedType === "user") {
    return "Can't add user after user node.";
  }

  if (canBeAddedAnywhere.includes(nodeAddedType)) {
    return "allowed";
  }

  if (canOnlyBeAddedAtLast.includes(nodeAddedType)) {
    const flag = ifLast(edges, currentNodeId);
    if (flag) {
      return "allowed";
    }
  }

  return `${capitalizeFirstLetter(
    nodeAddedType
  )} node can be added at the end only.`;
};
