/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Divider as MuiDivider,
  Link,
  CardContent,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  DialogActions,
  Stack,
  Chip,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  Popover,
  Button,
  Alert,
  AvatarGroup,
  Drawer,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import {
  Attachment,
  CrownSvg,
  InstagramSvg,
  LeadIcon,
  Messenger,
  Whatsapp,
} from "../../../Svgs";
import {
  ConfimPopup,
  backendDoamin,
  containsEmoji,
  dialogStyle,
  formatAMPM,
  formatDate,
  formatServerValue,
  formatWhatsappMessage,
  getAuthToken,
  getAxiosError,
  getConversationById,
  getMediaType,
  getMetaValueFromProduct,
  getNameFromPlatform,
  getRandomString,
  getRelativeTimeDifference,
  getStoreName,
  getTemplateVariableObjFromCustomer,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isNumber,
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
  validateNumber,
  wrapLinksWithTag,
} from "../../../helper";
import "../../../App.css";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";

import {
  ArrowBack,
  ArticleOutlined,
  CameraAlt,
  Close,
  ContentCopy,
  DeleteOutlineOutlined,
  Done,
  DoneAll,
  DoneAllRounded,
  FormatListBulleted,
  HeadsetMic,
  InsertDriveFileOutlined,
  Inventory,
  KeyboardArrowDownRounded,
  LocalOffer,
  MoreVert,
  North,
  People,
  PeopleAltOutlined,
  PermMedia,
  Person,
  PersonOutlineOutlined,
  PlayArrow,
  PostAdd,
  ReceiptOutlined,
  Reply,
  ReplyAll,
  SentimentSatisfiedOutlined,
  South,
  Star,
  TaskOutlined,
  TrendingFlatRounded,
} from "@mui/icons-material";

import { useFullScreenHandle } from "react-full-screen";
import { CustomerLabelView } from "../../crm/CRM";

import { LoadingButton } from "@mui/lab";
import SelectProducts from "../../components/SelectProducts";

import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../crm/View";
import { CRMfilters, filterColorObj } from "../ChatsSocial";

import ProductMediaLibrary from "../../components/ProductMediaLibrary";
import {
  EDIT_CHATS,
  addResponseInChat,
  assignedFilter,
  bulkActionChats,
  deleteConversation,
  getTempletes,
  putMergeProfiles,
  readMessage,
  setUpConversations,
  updateChatObject,
  updateCrmIdinSetting,
} from "../../../redux/actions/chatActions";
import TempleteList from "../../components/TempleteList";
import { Add, MailOutline } from "@material-ui/icons";

import { setGlobalToast } from "../../../redux/actions/helperActions";
import { updateCustomerInRedux } from "../../../redux/actions/crmActions";
import UserTasks, { CreateUserTask } from "../UserTasks";
import AddCannedMessages from "./AddCannedMessages";
import { primaryLabel } from "../ChatHelper";

import { currencySymbols } from "../../pricing/Gold";
import ChatProducts from "./ChatProducts";
import { useMemo } from "react";
import { memo } from "react";
import { channels } from "../../customers/components/Communication";
import { StoryReply } from "./helper";
import BroadcastList from "./BroadcastList";
// import MappingChats from "../chat/components/MappingChats";
import asyncComponent from "../../../components/Async";
import Loader from "../../../components/Loader";
import EmojiPicker from "emoji-picker-react";
import NewMediaLibrary from "../../../mediaLibrary/NewMediaLibrary";
import { createChatUser, getChatsUsers, settingApi } from "../apiFunctions";
import Tags from "./Tags";
import { getChatUserActivities } from "../../../helper/api/userActivity";

const MappingChats = asyncComponent(() => import("./MappingChats"));
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

// height: 100%;

const ChatInput = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.paper};

  // min-height: 74px;
`;
// align-centent: center;
// margin: ${(props) => (props.isIpad ? "15px" : "30px")};
export const ChatMessage = styled(Box)`
  // margin: ${(props) => "22px"};
  text-align: ${(props) => props.position};
  display: "flex";
  align-items: self-start;
`;

// background-color: ${(props) =>
//   props.isSender
//     ? props.theme.palette.primary.main
//     : props.theme.palette.background.paper};
export const ChatMessageInner = styled.div`
  display: inline-block;
  overflow: hidden;
  border-radius: 8px;
  padding: 0px 6px;
`;

export const ChatMessageTime = styled(Typography)`
  text-align: right;
`;

export const ChatMessageBubble = styled.div`
  margin-right: auto;
`;

// const labels = [
//   { label: "New Customer", value: "New Customer", color: "#8FA940" },
//   { label: "New Order", value: "New Order", color: "#C1A13F" },
//   { label: "Pending payment", value: "Pending payment", color: "#A42B70" },
//   { label: "Paid", value: "Paid", color: "#732438" },
//   { label: "Order Complete", value: "Order Complete", color: "#EEB130" },
// ];

export const check24HoursWindowClose = (chats, user) => {
  let isClosed = false;
  if (isArrayWithValues(chats)) {
    for (let i = chats.length - 1; i >= 0; i--) {
      if (chats[i] && !chats[i]?.is_sender) {
        let currentTime = Date.now() / 1000;
        let { time } = chats[i];
        if (currentTime - validateNumber(time) / 1000 > 86400) isClosed = true;
        // setIsWindowClosed(true);
        else isClosed = false;
        // setIsWindowClosed(false);
      } else isClosed = true;
    }
  }
  if (user?.platform === "webchat") {
    if (user?.hasOwnProperty("agent_can_respond")) {
      isClosed = !user?.agent_can_respond;
    } else isClosed = !user?.meta_data?.agent_can_respond;
  }
  return isClosed;
};
const MapContainer = ({ latitude, longitude }) => {
  const apiKey = "YOUR_API_KEY";
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=400x300`;

  return (
    <div>
      <img src={mapUrl} alt="Map" width="400" height="300" />
    </div>
  );
};

const ChatWindow = (props) => {
  let {
    customerLoading,
    customerName,
    customerProfile,
    chatsTabSelected,
    customerLabel,
    customer,
    customerInfo,
    setCustomerView,
  } = props;
  let { platform } = customer;
  const chatsParent = useRef(null);
  const location = useLocation();
  // const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [activities, setActivities] = useState([]);
  const reduxTags = useSelector((state) => state?.chats?.chat_tags);
  // const [customerId, setCustomerId] = useState(null);
  // const [fetchingChats, setFetchingChats] = useState(false);
  // const [chatsRendered, setChatsRendered] = useState(false);

  // const [sendingImage, setSendingImage] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [caption, setCaption] = useState("");
  const [imageToUpload, setImageToUpload] = useState(null);
  const [rowImageToUpload, setRowImageToUpload] = useState(null);

  // const [showProductDialog, toggleProductDialog] = useState(false);
  // const [productSearch, setProductSearch] = useState("");
  // const [fetchingProducts, setFetchingProducts] = useState(false);
  // const [fetchingCatalogProducts, setFetchingCatalogProducts] = useState(false);
  // const [productsSearched, setProductsSearched] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  // const [catalogDialog, setCatalogDialog] = useState(false);
  // const [catalogProducts, setCatalogProducts] = useState([]);
  // const [catalogError, setCatalogError] = useState("");
  // const [creatingCatalog, setCreatingCatalog] = useState(false);

  const [sessionEnded, setSessionEnded] = useState(false);
  const [notOptedIn, setNotOptedIn] = useState(false);
  const [isWindowClosed, setIsWindowClosed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  // const [selectedCatalogProducts, setSelectedCatalogProducts] = useState([]);
  const [propsChatsLength, setPropChatsLength] = useState(0);
  const [messageImageView, setMessageImageView] = useState({
    url: "",
    show: false,
  });
  // const [isFullScreen, setIsFullScreen] = useState(false);
  // const [quotationDialog, setQuotaionDialog] = useState(false);
  // const [customerSearching, setCustomerSearching] = useState(false);
  // const [showNotesDialog, setShowNotesDialog] = useState(false);
  // const [noteForm, setNoteForm] = useState({
  //   title: "",
  //   note: "",
  // });
  // const [allQuotationCustomers, setQuotationCustomers] = useState([]);
  // const [catalogForm, setCatalogForm] = useState({
  //   content: "",
  //   private: "off",
  //   expiry: "",
  //   title: "",
  //   customers: [],
  //   products: [],
  //   public: false,
  // });
  const [selectProductOpen, setSelectProductOpen] = useState(false);

  // const [quotationForm, setQuotationForm] = useState({
  //   customers: {},
  //   title: "",
  //   otp_enabled: false,
  //   products: [],
  // });
  // const [speedDialOpen, setSpeedDialOpen] = useState(false);
  // const [quotationError, setQuotationError] = useState("");
  const [customerLabelId, setCustomerLabelId] = useState(false);
  // const [noteLoading, setNoteLoading] = useState(false);
  // const [scheduleDate, setScheduleDate] = useState(new Date());
  const [integratedSocials, setIntegratedSocials] = useState([]);
  // const [customer, setCustomer] = useState(false);
  const [productsId, setProductsId] = useState([]);
  ///////////////
  const [openCreateTemplete, setopenCreateTemplete] = useState(false);
  const [openTempleteList, setOpenTempleteList] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [mediaLibraryImage, setMediaLibraryImage] = useState([]);
  const [templateObj, setTemplateObj] = useState({});
  // const [sendingMessage, setSendingMessage] = useState(false)
  const [metaIntegration, setMetaIntegration] = useState({});
  const [integratedApps, setIntegratedApps] = useState([]);
  const [replyMessage, setReplyMessage] = useState(null);
  const [allWhatsappTemplates, setAllWhatsAppTemplates] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [lastIntreact, setLastIntreact] = useState("");
  const [toggleDeleteDialog, setToggleDeleteDialog] = useState(false);
  const [deletingConversation, setdeletingConversation] = useState(false);
  const [anchorElCanned, setAnchorElCanned] = React.useState(null);
  const [showChats, setShowChats] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [textTemplate, setTextTemplate] = useState({});
  const [deleteTag, setDeleteTag] = useState(false);
  const [deletingTag, setDeletingTag] = useState(false);

  let fetchingInterval;
  const fullscreenHandle = useFullScreenHandle();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxMetaIntegrations = useSelector(
    (state) => state.settings.metaIntegration
  );
  const reduxWebchatIntegration = useSelector(
    (state) => state.settings.webchatIntegration
  );
  let allCannedMessages = useSelector((state) => state.chats.cannedMessages);
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const [userIncompleteTasks, setUserInCompleteTasks] = useState([]);

  useEffect(() => {
    if (!isArrayWithValues(allUserTasks)) return setUserInCompleteTasks([]);
    setUserInCompleteTasks(
      allUserTasks.filter(
        (i) => i?.user_id == customer?.id && i?.status !== "complete"
      )
    );
  }, [allUserTasks, customer]);

  const checkOptedIn = (chats) => {
    if (isArrayWithValues(chats))
      setNotOptedIn(Boolean(!chats.find((i) => !i?.is_sender)));
    else setNotOptedIn(true);
    // if (isArrayWithValues(chats)) {
    //   let notOptedIn;
    //   let length = chats.length > 175 ? 176 : chats.length;
    //   // let length = chats.length > 5 ? 6 : chats.length;
    //   let temp = 0;
    //   for (let i = 0; i < length; i++) {
    //     let obj = chats[i];
    //     if (
    //       !obj.destination &&
    //       !obj.msg_text &&
    //       !obj.source &&
    //       !obj['src.name"'] &&
    //       !obj.sender
    //     ) {
    //       temp++;
    //     }
    //   }
    //   if (temp === length) {
    //     setNotOptedIn(true);
    //     return;
    //   } else setNotOptedIn(false);
    // }
  };
  const check24HoursWindow = (chats, user) => {
    let status = "open";
    if (isArrayWithValues(chats)) {
      let length = chats.length;
      for (let i = length - 1; i >= 0; i--) {
        if (chats[i] && !chats[i]?.is_sender) {
          let currentTime = Date.now() / 1000;
          let { time } = chats[i];
          if (currentTime - validateNumber(time) / 1000 > 86400)
            status = "closed";
          else status = "open";
          break;
        }
      }
    }
    if (user?.platform === "webchat") {
      if (user?.hasOwnProperty("agent_can_respond")) {
        status = user?.agent_can_respond ? "open" : "closed";
      } else status = user?.meta_data?.agent_can_respond ? "open" : "closed";
    }

    setIsWindowClosed(status === "closed");
  };

  const getActivities = async () => {
    let activities = await getChatUserActivities({ user: customer });
    if (isArrayWithValues(activities)) {
      setActivities(activities);
    }
  };

  useEffect(() => {
    getActivities();
  }, [customer.conversation]);

  useEffect(() => {
    let conversation = [];
    if (isArrayWithValues(customer.conversation)) {
      conversation = conversation.concat(customer.conversation);
      if (isArrayWithValues(activities)) {
        conversation = conversation
          ?.concat(activities)
          ?.sort((a, b) => validateNumber(a?.time) - validateNumber(b?.time));
      }
    }
    setChats(conversation);
    setSelectedTab(customer?.platform);
  }, [customer.conversation, activities]);

  // useEffect(() => {
  //   setChats(customer.conversation || []);
  // }, [customer.conversation]);
  // useEffect(() => {
  //   if (isArray(customer.conversation)) {

  //     // setChats(customer.conversation);
  //   }
  //   // setTimeout(() => {

  //   // }, 1000);

  //   else setChats([]);
  // }, [customer.conversation, customer]);

  // useEffect(() => {
  //   setCustomer(props.customer);
  // }, [props.customer]);
  // useMemo(() => {
  //   setTimeout(() => {
  //     scrollToBottom();
  //   }, 2000);
  // }, [chats]);
  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const actions = [
    {
      icon: <ImageOutlinedIcon />,
      name: "Image",
      onClick: () => {
        inputFile.current.click();
      },
    },
    {
      icon: <Inventory2OutlinedIcon />,
      name: "Products",
      onClick: () => setSelectProductOpen("select_product"),
    },
    {
      icon: <AutoStoriesOutlinedIcon />,
      name: "Catalog",
      onClick: () => setSelectProductOpen(true),
    },
    {
      icon: <ReceiptOutlined />,
      name: "Quotation",
      onClick: () => setSelectProductOpen("quotation"),
    },
  ];
  useEffect(() => {
    return () => clearInterval(fetchingInterval);
  }, []);

  // useEffect(() => {
  //   onProductSearch(productSearch);
  // }, [productSearch]);

  // useEffect(() => {
  //   if (showProductDialog && !isArrayWithValues(productsSearched))
  //     fetchProducts(productSearch, setProductsSearched, setFetchingProducts);
  // }, [showProductDialog]);

  const scrollToBottom = () => {
    const domNode = chatsParent.current;
    if (domNode && domNode.scrollHeight) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  };

  useEffect(() => {
    const domNode = chatsParent.current;
    if (isArrayWithValues(chats) && isArrayWithValues(props.chats)) {
      if (propsChatsLength !== chats.length) {
        if (domNode.scrollHeight - domNode.scrollTop < 1000) {
          scrollToBottom();
          setPropChatsLength(chats.length);
          if (sessionEnded) setSessionEnded(false);
        }
      }
    }
    checkOptedIn(chats);
    check24HoursWindow(chats, customer);
    scrollToBottom();
    // if (isArrayWithValues(props.chats)) {
    //   setChatsRendered(true);
    //   if (domNode.scrollHeight - domNode.scrollTop < 1000) {
    //     setTimeout(() => scrollToBottom(), 0);
    //   }
    //   if (isArrayWithValues(chats)) {
    //     let length = chats.length;
    //     for (let i = 0; i < length; i++) {
    //       let obj = props.chats[i];
    //       if (obj.sender) {
    //         let currentTime = Date.now() / 1000;
    //         let daysDiff = numDaysBetween(
    //           new Date(currentTime),
    //           new Date(obj.time)
    //         );
    //         console.log(Math.floor(daysDiff) > 0);
    //         if (Math.floor(daysDiff) > 0) setSessionEnded(true);
    //         break;
    //       }
    //     }
    //   }
    // }
    // if (isNumber(domNode.scrollHeight) && isNumber(domNode.scrollTop)) {
    //   let scrolled = domNode.scrollTop - domNode.scrollHeight;
    //   scrolled = Math.abs(scrolled);
    //   if (scrolled < 1000) domNode.scrollTop = domNode.scrollHeight;
    // }
  }, [chats, customer]);

  // useEffect(() => {
  //   if (isArrayWithValues(props.chats)) {
  //     checkOptedIn(props.chats);
  //     setPropChatsLength(props.chats.length);
  //     // if (shouldReverse(props.chats)) setChats([...props.chats].reverse());
  //     // else setChats(props.chats);
  //     let length = [...props.chats].length;
  //     let noSenderMessages = true;
  //     for (let i = 0; i < length; i++) {
  //       let obj = props.chats[i];
  //       if (obj.sender) {
  //         noSenderMessages = false;
  //         let currentTime = Date.now() / 1000;
  //         let daysDiff = numDaysBetween(
  //           new Date(currentTime),
  //           new Date(obj.time)
  //         );
  //         if (Math.floor(daysDiff) > 0) setSessionEnded(true);
  //         else setSessionEnded(false);
  //         break;
  //       }
  //     }
  //     if (noSenderMessages) {
  //       setNotOptedIn(true);
  //     }
  //   } else {
  //     setNotOptedIn(true);
  //     // setChats([]);
  //   }
  // }, [props.chats]);

  // useEffect(() => {
  //   if (!sessionEnded && !notOptedIn) setFetchingChats(true);
  //   else setFetchingChats(false);
  // }, [sessionEnded, notOptedIn]);

  // var numDaysBetween = function (d1, d2) {
  //   var today = d2.getTime() / 1000;
  //   var diff = Math.abs(d1 - today);
  //   return diff / (60 * 60 * 24);
  // };

  useMemo(() => {
    let pieces = location.pathname.split("/");
    setShowHeader(!pieces.includes("crm"));
    // if (pieces[pieces.length - 1] !== "chats")

    //   setCustomerId(pieces[pieces.length - 1]);

    // return () => clearInterval(fetchingInterval);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    // Simulating an asynchronous operation
    setTimeout(() => {
      // Update the state here...
      setIsLoading(false);
    }, 2000); // Simulating a 2-second delay
  }, [location]);
  // useEffect(() => {
  //   if (fetchingChats) {
  //     clearInterval(fetchingInterval);
  //     if (customer.id)
  //       fetchingInterval = setInterval(() => {
  //         fetchChats(customer.id, setChats);
  //         // if (props.id)
  //         // else {
  //         //   let pieces = location.pathname.split("/");
  //         //   fetchChats(pieces[pieces.length - 1], setChats);
  //         // }
  //       }, 10000);
  //   } else clearInterval(fetchingInterval);
  //   return () => {
  //     clearInterval(fetchingInterval);
  //   };
  // }, [fetchingChats]);

  // const onMessageSend = () => {
  //   if (
  //     new Date(scheduleDate).toISOString().substring(0, 10) !==
  //     new Date().toISOString().substring(0, 10)
  //   ) {
  //     setSendingMessage(true);
  //     setTimeout(() => {
  //       setToast({
  //         show: true,
  //         message: `Message scheduled for ${formatDate(
  //           new Date(scheduleDate),
  //           "dd:mm:yyyy hh:mm am/pm"
  //         )}`,
  //         severity: "success",
  //       });
  //       setSendingMessage(false);
  //       setScheduleDate(new Date());
  //     }, 3000);
  //     return;
  //   }
  //   setFetchingChats(false);
  //   setSendingMessage(true);
  //   setMessage("");
  //   let messageObj = {
  //     whatsapp_chat: {
  //       type: "text",
  //       message,
  //     },
  //   };

  //   const website = getWebsite();
  //   const token = getToken();
  //   if (website && token)
  //     axios({
  //       url: `${website}/wp-json/wc/v3/customers/${customerId}`,
  //       data: { ...messageObj },
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //       method: "POST",
  //     })
  //       .then((res) => {
  //         res = res.data;
  //         if (res && isArrayWithValues(res.whatsapp_chat)) {
  //           if (shouldReverse(res.whatsapp_chat))
  //             setChats(res.whatsapp_chat.reverse());
  //           else setChats(res.whatsapp_chat);
  //           // scrollToBottom();
  //         }
  //       })
  //       .catch((e) => console.log(getAxiosError(e)))
  //       .finally(() => setSendingMessage(false));
  // };

  // const onImageSend = () => {
  //   setFetchingChats(false);
  //   setSendingImage(true);

  //   const formData = new FormData();
  //   formData.append(`whatsapp_chat[type]`, "image");
  //   formData.append(`whatsapp_chat[message]`, caption);
  //   // formData.append("file", {
  //   //   name: `test`,
  //   //   uri: image,
  //   //   type: "image/jpg",
  //   // });
  //   // formData.append("image", image, image.name);
  //   formData.append("file", rowImageToUpload);

  //   setImageToUpload("");
  //   setRowImageToUpload("");
  //   // return;
  //   const website = getWebsite();
  //   const token = getToken();
  //   const headers = {
  //     Accept: "image/*",
  //     Authorization: `Basic ${token}`,
  //     // "Content-Type": "multipart/form-data",
  //   };
  //   let url = `${website}/wp-json/wc/v3/customers/${customerId}`;
  //   if (website && token)
  //     // axios({
  //     //   url: `${website}/wp-json/wc/v3/customers/${customerId}`,
  //     //   data: formData,
  //     //   // data: { ...messageObj },
  //     //   headers: {
  //     //     Accept: "image/*",
  //     //     Authorization: `Basic ${token}`,
  //     //     // "Content-Type": "multipart/form-data",
  //     //   },
  //     //   method: "POST",
  //     // })
  //     axios
  //       .post(url, formData, { headers })
  //       .then((res) => {
  //         res = res.data;
  //         if (res && isArrayWithValues(res.whatsapp_chat)) {
  //           setChats(res.whatsapp_chat.reverse());
  //           scrollToBottom();
  //         }
  //       })
  //       .catch((e) => console.log(getAxiosError(e)))
  //       .finally(() => {
  //         setSendingImage(false);
  //       });
  // };

  // const onProductSelect = (product) => {
  //   toggleProductDialog(false);
  //   let { name, price, categories, permalink, sku, images, status } = product;
  //   let category = categories && categories[0] && categories[0].slug;
  //   name = name.charAt(0).toUpperCase() + name.slice(1);
  //   if (category)
  //     category = category.charAt(0).toUpperCase() + category.slice(1);
  //   let image =
  //     images && Array.isArray(images) && images.length > 0 && images[0].src;
  //   let message = `Product: ${name}\nPrice: ₹${price} \n${category ? `Category: ${category}\n` : ""
  //     }${permalink}`;
  //   setMessage(message);
  //   // let products = [...selectedProducts];
  //   // if (products.find((i) => i.id === product.id))
  //   //   products = products.filter((i) => i.id !== product.id);
  //   // else products.push(product);
  //   // setSelectedProducts(products);
  // };

  // const onFileDrop = (value) => {
  //   const types = ["image/jpeg", "image/png", "image/gif", "image/x-icon"];
  //   let size = Math.round(validateNumber(value.size / 1000));
  //   let type = value.type;
  //   if (!types.includes(type))
  //     return setToast({
  //       show: true,
  //       message: "Only JPG, PNG and GIF formats are allowed",
  //       severity: "error",
  //     });
  //   if (size > 512)
  //     return setToast({
  //       show: true,
  //       message: "File size should not exceed 500kb.",
  //       severity: "error",
  //     });
  //   if (value) {
  //     setRowImageToUpload(value);
  //     setImageToUpload(URL.createObjectURL(value));
  //   }
  // };

  // const changeCatalogForm = (payload) => {
  //   setCatalogForm({ ...catalogForm, ...payload });
  // };

  // const onProductSearch = async (value) => {
  //   if (!value || (value && value.length < 2)) return;
  //   if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

  //   customerSearchTimeout = setTimeout(async () => {
  //     fetchProducts(value, setUpdatedProducts, setFetchingCatalogProducts);
  //   }, 500);
  // };

  // const setUpdatedProducts = (products) => {
  //   if (isArrayWithValues(products)) {
  //     let arr = [];
  //     for (let i = 0; i < products.length; i++) {
  //       arr.push({
  //         label: `${products[i].name}`,
  //         value: products[i].id,
  //       });
  //     }
  //     setCatalogProducts(arr);
  //   }
  // };

  // const onCreateCatalog = () => {
  //   try {
  //     let { title, expiry, content, products } = catalogForm;

  //     if (!title) return setCatalogError("Please enter title");
  //     if (!catalogForm.public) {
  //       if (!expiry) return setCatalogError("Please enter expiry");
  //       if (expiry > 48 || expiry < 1)
  //         return setCatalogError("Expiry should be miniumn 1 to maximum 48");
  //     }
  //     setCatalogError("");
  //     let obj = {
  //       title,
  //       content,
  //       expiry,
  //       isPublic: catalogForm.public,
  //       // products: selectedProducts,
  //       customers: [customerId],
  //     };
  //     if (isArrayWithValues(products) && !catalogForm.public) {
  //       obj.products = products.map((i) => i.value && i.value);
  //       obj.products = obj.products.toString();
  //     }
  //     setCreatingCatalog(true);
  //     // return;
  //     // axios
  //     //   .post(
  //     //     "https://www.tiarabytj.com/wp-json/store/v1/catalogs?type=add_catalogue",
  //     //     JSON.stringify({ obj }),
  //     //     {
  //     //       headers: {
  //     //         Authorization:
  //     //           "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //         "Content-Type": "application/json",
  //     //       },
  //     //     }
  //     //   )
  //     // axios({
  //     //   url: "https://www.tiarabytj.com/wp-json/store/v1/catalogs",
  //     //   headers: {
  //     //     Authorization: "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //     "Content-Type": "application/json",
  //     //     "Access-Control-Allow-Origin": "*",
  //     //   },
  //     //   params: { type: "add_catalogue" },
  //     //   method: "POST",
  //     //   data: JSON.stringify({ data: { ...obj } }),
  //     // })
  //     // axios
  //     //   .post(
  //     //     "https://www.tiarabytj.com/wp-json/store/v1/catalogs?type=add_catalogue",
  //     //     JSON.stringify({ data: { ...obj } }),
  //     //     {
  //     //       headers: {
  //     //         Authorization:
  //     //           "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //         "Access-Control-Allow-Origin": "*",
  //     //       },
  //     //     }
  //     //   )

  //     const website = getWebsite();
  //     const token = getToken();
  //     if (website && token)
  //       axios({
  //         url: `${website}/wp-json/store/v1/catalogs?type=add_catalogue`,
  //         data: JSON.stringify({ data: { ...obj } }),
  //         headers: {
  //           Authorization: `Basic ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //       })
  //         .then((res) => {
  //           setCatalogDialog(false);
  //           let { data } = res;
  //           if (isArrayWithValues(data.data))
  //             setMessage(
  //               `${message ? `${message} \n ` : ""}${data.data[0].link}`
  //             );
  //           // setToast({ isError: false, message: "Catalog added successfully!" });
  //           setToast({
  //             show: true,
  //             severity: "success",
  //             message: "Catalog added successfully!",
  //           });
  //         })
  //         .catch((e) => {
  //           console.log(getAxiosError(e));
  //           setToast({
  //             show: true,
  //             severity: "error",
  //             message: "Couldn't add Catalog, try again!",
  //           });
  //         })
  //         .finally(() => {
  //           setCreatingCatalog(false);
  //         });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onQuotationCustomerSearch = async (value, setState) => {
  //   if (!value || (value && value.length < 2)) return;
  //   if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

  //   customerSearchTimeout = setTimeout(async () => {
  //     setCustomerSearching(true);
  //     await fetchCustomers(value, setState);
  //     setCustomerSearching(false);
  //   }, 500);
  // };
  // const setUpdatedQuotationCustomers = (customers) => {
  //   if (isArrayWithValues(customers)) {
  //     let arr = [];
  //     for (let i = 0; i < customers.length; i++) {
  //       arr.push({
  //         label: `${customers[i].first_name} ${customers[i].last_name}`,
  //         value: customers[i].id,
  //       });
  //     }
  //     setQuotationCustomers(arr);
  //   }
  // };

  // const onQuotationCreate = () => {
  //   let { customers } = quotationForm;
  //   let obj = { ...quotationForm, customer: `${customers.value}` };
  //   dispatch(
  //     createQuotation(
  //       obj,
  //       (quotation) => {
  //         setToast({
  //           show: true,
  //           message: "Quotation created successfully!",
  //           severity: "success",
  //         });
  //         if (quotation.link) setMessage(quotation.link);
  //         closeQuotationDialog();
  //       },
  //       () => {
  //         setToast({
  //           show: true,
  //           message: "Couldn't create quotation, Please try again later",
  //           severity: "error",
  //         });
  //       }
  //     )
  //   );
  //   console.log(quotationForm);
  // };

  // const fullscreenChange = useCallback(
  //   (state, handle) => {
  //     if (handle === fullscreenHandle) {
  //       setIsFullScreen(state);
  //     }
  //   },
  //   [fullscreenHandle]
  // );

  // const changeQuotationForm = (payload) => {
  //   if (quotationError) setQuotationError("");
  //   setQuotationForm({ ...quotationForm, ...payload });
  // };
  // const closeQuotationDialog = () => setQuotaionDialog(false);

  const onProductsSelected = (products) => {
    if (!isArrayWithValues(products)) return;

    let action = {
      catalog_id: selectedCatalog,
    };
    let productData = products.map((obj) => {
      if (isArrayWithValues(obj?.categories)) {
        return {
          catagory: obj?.categories[0]?.name || "Miscellaneous",
          product_retailer_id: `${obj.sku.replaceAll(" ", "_")}_${obj.id}`,
        };
      }
    });

    let response = [];
    let obj = [];

    for (let temp of productData) {
      if (!Array.isArray(obj[temp.catagory])) obj[temp.catagory] = [];
      if (obj[temp.catagory]) {
        obj[temp.catagory].push(temp.product_retailer_id);
      } else {
        obj[temp.catagory] = [temp.product_retailer_id];
      }
      // obj[temp.catagory] ? obj[temp.catagory].push(temp.product_retailer_id) :
    }

    for (let key of Object.keys(obj)) {
      let object = {
        title: key,
        product_items: obj[key].map((i) => ({
          product_retailer_id: i,
        })),
      };
      response.push(object);
    }

    action.sections = response;
    let payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: customer.platform_id,

      type: "interactive",
      interactive: {
        type: "product_list",
        header: {
          type: "TEXT",
          text: "Kindly find the products as requested",
        },
        body: {
          text: "Earrings and Ladies Rings Collection",
        },
        footer: {
          text: `powered by ${getWebsite().replace(/(^\w+:|^)\/\//, "")}`,
        },
        action: action,
      },
    };

    sendMessage(payload);
    setSelectProductOpen(false);
    // if (selectProductOpen === "quotation") {
    //   setQuotationForm((state) => ({
    //     ...state,
    //     products: products.map((i) => i.id).join(),
    //   }));
    //   setSelectProductOpen(false);
    //   setQuotaionDialog(true);
    // } else if (selectProductOpen === "select_product") {
    //   let i = 1;
    //   let string = "";
    //   for (let product of products) {
    //     let { name, permalink } = product;
    //     string += `  ${i}. ${name}: ${permalink}\n`;
    //     i++;
    //   }
    //   setMessage((state) => (state ? `\n${string}` : string));
    //   setSelectProductOpen(false);
    // } else {
    //   setCatalogForm((state) => ({
    //     ...state,
    //     products: products.map((i) => ({ label: i.name, value: i.id })),
    //   }));
    //   setSelectProductOpen(false);
    //   setCatalogDialog(true);
    // }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  // const changeNotesForm = (p) => {
  //   return setNoteForm((state) => ({ ...state, ...p }));
  // };

  // const onAddNote = async () => {
  //   setNoteLoading(true);
  //   const obj = {
  //     additional_custom_data: {
  //       notes: {
  //         request: "add",
  //         data: noteForm,
  //       },
  //     },
  //   };
  //   try {
  //     await updateCustomer(customer.id, obj);
  //     setShowNotesDialog(false);
  //     setToast({
  //       show: true,
  //       message: "Note added successfully!",
  //       severity: "success",
  //     });
  //   } catch (error) {
  //     console.log(getAxiosError(error));
  //     setToast({
  //       show: true,
  //       message: "Couldn't add Note! Please try again later",
  //       severity: "error",
  //     });
  //   }
  //   setNoteLoading(false);
  // };
  /////

  const [formOpen, setFormOpen] = useState(false);
  const [openBroadCast, setOpenBroadCast] = useState(false);
  // const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);
  ///
  const totalConversationsCount = useSelector(
    (state) => state.chats.totalConversationsCount
  );
  const reduxTemplates = useSelector((state) => state.chats.whatsappTempletes);

  const addCannedMessageRef = useRef();

  const openCannedPopover = (event) => {
    setAnchorElCanned(event.currentTarget);
  };

  const closeCannedPopover = () => {
    setAnchorElCanned(null);
  };

  const cannedOpen = Boolean(anchorElCanned);
  const id = cannedOpen ? "simple-popover" : undefined;
  // useEffect(() => {
  //   let conversation = customer.conversation || [];
  //   let lastObj = conversation.reverse().find((obj) => !obj.is_sender);
  //   setLastIntreact(lastObj?.time || "");
  // }, [customer]);

  // const reduxMetaIntegrations = useSelector(
  //   (state) => state.settings.metaIntegration
  // );

  useMemo(() => {
    if (!isArrayWithValues(reduxTemplates)) dispatch(getTempletes());
  }, []);
  useMemo(() => {
    if (isArrayWithValues(reduxTemplates))
      setAllWhatsAppTemplates(reduxTemplates);
  }, [reduxTemplates]);
  const ProductPopup = useMemo(
    () => (
      <>
        <ChatProducts
          open={isArrayWithValues(productsId)}
          productsId={productsId}
          onClose={() => setProductsId([])}
        />
      </>
    ),
    [productsId]
  );

  // useEffect(() => {
  //   if (!isObjWithValues(reduxMetaIntegrations)) dispatch(getMetaIntegration())
  // }, [])
  useMemo(() => {
    let connected = [];
    if (isObjWithValues(reduxMetaIntegrations)) {
      setMetaIntegration(reduxMetaIntegrations);

      Object.values(reduxMetaIntegrations).map((i) => {
        connected = connected.concat(i.connected);
      });
    }
    if (
      isObjWithValues(reduxWebchatIntegration) &&
      reduxWebchatIntegration?.integrated
    ) {
      connected.push("webchat");
    }
    setIntegratedSocials(connected);
  }, [reduxMetaIntegrations]);
  // useEffect(() => {
  //   if (isObjWithValues(reduxMetaIntegrations)) {
  //     setMetaIntegration(reduxMetaIntegrations);
  //     let connected = [];

  //     Object.values(reduxMetaIntegrations).map((i) => {
  //       connected = connected.concat(i.connected);
  //     });

  //     setIntegratedSocials(connected);
  //   }
  // }, [reduxMetaIntegrations]);
  const globalToast = useSelector((state) => state.helper.globalToast);
  useMemo(() => {
    if (!isObjWithValues(reduxMetaIntegrations)) return;
    let apps = [];
    for (const key in metaIntegration) {
      let connectedApps = metaIntegration[key].connected || [];
      apps = [...apps, ...connectedApps];
      if (connectedApps.includes("commerce")) {
        let selected_id = metaIntegration[key].select_commerce_catalogs || "";
        setSelectedCatalog(selected_id);
      }
    }
    setIntegratedApps(apps);
  }, [metaIntegration]);

  const setTemplate = (obj, _, selectedTemplete) => {
    let variableObj = getTemplateVariableObjFromCustomer(
      customerInfo || customer
    );
    setTextTemplate(selectedTemplete);
    let template = { ...obj };

    // console.log(payload);
    let errorFound = false;
    if (isArrayWithValues(template.components)) {
      let components = [];
      for (let component of template.components) {
        if (isArrayWithValues(component.parameters)) {
          let parameters = [];
          for (let parameter of component.parameters) {
            let { text } = parameter || {};
            let variableText = text
              ? text?.includes("{") && text?.includes("}")
                ? variableObj?.[text] || " "
                : text
              : text;
            if (!variableText) variableText = " ";
            parameters.push({ ...parameter, text: variableText });
          }
          components.push({ ...component, parameters });
        }
      }
      if (isArrayWithValues(components)) template = { ...template, components };

      // payload.template.components = components;
    }
    console.log(template, "<<<<2");
    // if (isArrayWithValues(obj.components)) {
    //   let components = [];
    //   for (let component of obj.components) {
    //     if (isArrayWithValues(component.parameters)) {
    //       let parameters = [];
    //       for (let parameter of component.parameters) {
    //         let { text } = parameter;
    //         // if (!variableObj[text]) {
    //         //   errorFound = true;
    //         //   break;
    //         // }
    //         let variableText = text
    //           ? text.includes("{") && text.includes("}")
    //             ? variableObj[text]
    //             : ""
    //           : text;
    //         parameters.push({ ...parameter, text: variableText });
    //       }
    //       components.push({ ...component, parameters });
    //     }
    //   }
    //   if (isArrayWithValues(components)) obj = { ...obj, components };
    //   payload = {
    //     ...obj,
    //     template: { ...obj, components },
    //   };
    //   payload.template.components = components;
    // }
    // setOpenTempleteList(false);
    setTemplateObj(template);
  };
  const setImages = async (arr) => {
    setMediaLibraryOpen(false);
    if (isArrayWithValues(arr)) setMediaLibraryImage(arr);
    if (arr?.length < 2) return;
    // console.log(arr, "<<<arr>>>");
    // return;
    for await (const mediaObj of arr) {
      let mediaType = getMediaType(mediaObj);
      // mediaObj?.mime_type?.includes("video")
      //   ? "video"
      //   : mediaObj?.mime_type?.includes("application")
      //   ? "document"
      //   : "image";

      setSendingMessage(true);
      let payload = {};
      if (platform === "instagram") {
        if (messageInput) payload.message = { text: messageInput };
        payload.recipient = { id: customer.platform_id };
        // if (messageInput) payload.message = { text: messageInput };
        if (isArrayWithValues(arr)) {
          payload.message = {
            // ...payload,
            attachment: {
              type: "image",
              payload: {
                url: mediaObj.source_url,
                // is_reusable: true,
              },
            },
          };
        }
      }
      if (platform === "facebook") {
        // playload.messaging_type = "text";
        if (messageInput) {
          payload.recipient = { id: customer.platform_id };
          payload.message = { text: messageInput };
        }
        if (isArrayWithValues(arr)) {
          payload.recipient = { id: customer.platform_id };

          payload.message = {
            attachment: {
              type: "image",
              payload: {
                url: mediaObj.source_url,
                is_reusable: true,
              },
            },
          };

          // payload.message = {
          //   attachment: {
          //     type: "image",
          //     payload: {
          //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
          //       is_reusable: true
          //     }
          //   }
          // }
        }

        // payload.message = {
        //   attachment: {
        //     type: "image",
        //     payload: {
        //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
        //       is_reusable: true
        //     }
        //   }
        // }
      }
      if (platform === "whatsapp") {
        if (messageInput)
          payload.message = {
            ...payload,
            text: {
              body: messageInput,
            },
          };
        payload = {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: customer.platform_id,
          type: "text",
          // text: {
          //   body: messageInput,
          // },
        };
        if (isObjWithValues(replyMessage)) {
          console.log(replyMessage);
          payload = {
            ...payload,
            context: {
              message_id: replyMessage?.obj?.message?.id,
            },
          };
        }
        if (isArrayWithValues(arr)) {
          // console.log(mediaLibraryImage?.mime_type?.includes("video"),);
          payload = {
            ...payload,
            [mediaType]:
              mediaType !== "document"
                ? {
                    link: mediaObj.source_url,
                  }
                : {
                    link: mediaObj.source_url,
                    filename: mediaObj?.file_name,
                  },
            type: mediaType,
          };

          delete payload.text;
        }
        if (isArrayWithValues(arr) && messageInput) {
          payload = {
            ...payload,
            type: mediaType,
            [mediaType]: {
              link: mediaObj.source_url,
              caption: messageInput,
            },
          };
          if (mediaType === "document") {
            payload[mediaType].filename = mediaObj?.file_name;
          }
        }
        if (isObjWithValues(templateObj)) {
          payload = { ...payload, type: "template", template: templateObj };
          delete payload.text;
        }
      }
      console.log(payload);
      await sendMessage(payload);
    }
    console.log(arr);
  };
  let broadcastListStr = `${
    customer?.broadcastList
      ?.slice(0, 2)
      ?.map((obj) => obj?.label)
      ?.join(",") || ""
  }${
    customer?.broadcastList?.slice(2, customer?.broadcastList?.length)?.length
      ? " +" +
        customer?.broadcastList?.slice(2, customer?.broadcastList?.length)
          ?.length
      : ""
  }`;

  // useEffect(() => {
  //   if (isObjWithValues(templateObj)) sendMessage();
  // }, [templateObj]);
  const getWhatsappNo = (customer) => {
    if (!customer) return "";
    let { meta_data, billing } = customer;
    let obj = {
      phone: billing?.phone,
    };
    let metaKeys = ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"];
    for (let metaObj of meta_data)
      if (metaKeys.includes(metaObj.key)) obj[metaObj.key] = metaObj.value;
    if (obj.whatsapp && obj.dialcode_whatsapp)
      return `${obj.dialcode_whatsapp}${obj.whatsapp}`;
    else if (obj.phone && obj.dialcode_mobile)
      return `${obj.dialcode_mobile}${obj.phone}`;
    return "";
  };
  const sendMessage = async (newpayload) => {
    const website = getWebsite();
    const token = getToken();
    // new database token
    const authToken = getAuthToken();
    // if (!website || !token) return;
    let mediaType = getMediaType(mediaLibraryImage[0]);
    // let mediaType = mediaLibraryImage[0]?.mime_type?.includes("video")
    //   ? "video"
    //   : mediaLibraryImage[0]?.mime_type?.includes("application")
    //   ? "document"
    //   : "image";

    let payload = {};
    if (platform === "instagram") {
      if (messageInput) payload.message = { text: messageInput };
      payload.recipient = { id: customer.platform_id };
      let supportedMediaType = ["audio", "file", "image", "video"];
      // if (messageInput) payload.message = { text: messageInput };
      if (isArrayWithValues(mediaLibraryImage)) {
        payload.message = {
          // ...payload,
          attachment: {
            type: mediaType,
            payload: {
              url: mediaLibraryImage[0].source_url,
              // is_reusable: true,
            },
          },
        };
        if (!supportedMediaType?.includes(mediaType)) {
          dispatch(
            setGlobalToast({
              show: true,
              message: `${formatServerValue(
                platform
              )} not support ${mediaType} media type`,
              severity: "error",
            })
          );
          return;
        }
      }
    }
    if (platform === "facebook") {
      // playload.messaging_type = "text";
      if (messageInput) {
        payload.recipient = { id: customer.platform_id };
        payload.message = { text: messageInput };
      }
      if (isArrayWithValues(mediaLibraryImage)) {
        let supportedMediaType = ["audio", "file", "image", "video"];
        payload.recipient = { id: customer.platform_id };

        payload.message = {
          attachment: {
            type: mediaType,
            payload: {
              url: mediaLibraryImage[0].source_url,
              is_reusable: true,
            },
          },
        };

        // payload.message = {
        //   attachment: {
        //     type: "image",
        //     payload: {
        //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
        //       is_reusable: true
        //     }
        //   }
        // }
      }

      // payload.message = {
      //   attachment: {
      //     type: "image",
      //     payload: {
      //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
      //       is_reusable: true
      //     }
      //   }
      // }
    }

    if (platform === "whatsapp") {
      payload = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: customer?.platform_id,
        type: "text",
        text: {
          body: messageInput,
        },
      };
      if (isObjWithValues(replyMessage)) {
        console.log(replyMessage);
        payload = {
          ...payload,
          context: {
            message_id: replyMessage?.obj?.message?.id,
          },
        };
      }
      if (isArrayWithValues(mediaLibraryImage)) {
        // console.log(mediaLibraryImage?.mime_type?.includes("video"),);
        payload = {
          ...payload,
          [mediaType]:
            mediaType !== "document"
              ? {
                  link: mediaLibraryImage[0].source_url,
                }
              : {
                  link: mediaLibraryImage[0].source_url,
                  filename: mediaLibraryImage[0]?.file_name,
                },
          type: mediaType,
        };

        delete payload.text;
      }
      if (isArrayWithValues(mediaLibraryImage) && messageInput) {
        payload = {
          ...payload,
          type: mediaType,
          [mediaType]: {
            link: mediaLibraryImage[0].source_url,
            caption: messageInput,
          },
        };
        if (mediaType === "document") {
          payload[mediaType].filename = mediaLibraryImage[0]?.file_name;
        }
      }
      if (isObjWithValues(templateObj)) {
        payload = { ...payload, type: "template", template: templateObj };
        delete payload.text;
      }
    }

    if (platform === "webchat") {
      payload = {
        to: customer?.platform_id,
        type: "text",
        text: messageInput,
      };

      // if (media) {
      //   payload[getMediaType(media)] = {
      //     link:
      //       media?.source_url ||
      //       media?.fileCopyUri ||
      //       media?.path ||
      //       media?.uri,
      //   };
      //   if (caption) payload[getMediaType(media)].caption = caption;
      // }
      // let type = media ? getMediaType(media) : "text";
      // payload.type = type;
    }

    let whatsappNo = getWhatsappNo(customerInfo);
    if (!whatsappNo && !payload.to && platform === "whatsapp") {
      setTemplateObj({});
      setSendingMessage(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: "No Whatsapp number found!",
          severity: "error",
        })
      );
    }
    let newWhatsappCustomer = false;
    if (!payload.to && platform === "whatsapp") {
      payload.to = whatsappNo;
      newWhatsappCustomer = true;
    }
    let data = {
      platform,
      payload,
      user_info: {
        id: customer?.id,
        platform_id: customer?.platform_id,
      },
    };

    if (isObjWithValues(newpayload)) {
      data = {
        platform: platform || "whatsapp",
        payload: newpayload,
      };
    }
    if (isObjWithValues(templateObj))
      data.meta_data = { template: textTemplate };
    setSendingMessage(true);
    if (isObjWithValues(customerInfo)) {
      try {
        if (newWhatsappCustomer) {
          let updateCustomerRes = updateCustomer(customerInfo?.id, {
            meta_data: [{ key: "whatsapp_id", value: payload.to }],
          });
          let id = `${getTimeInMilliSeconds()}_${getRandomString(4)}`;
          dispatch(
            putMergeProfiles({
              [id]: {
                id,
                crm_id: customerInfo?.id,
                profiles: [{ platform: "whatsapp", platform_id: payload.to }],
              },
            })
          );
          if (updateCustomerRes)
            dispatch(updateCustomerInRedux(updateCustomerRes));
          let chatsUsers = await getChatsUsers({
            params: { platform_id: payload.to },
          });
          if (chatsUsers?.data?.[0]) {
            //update customer
          } else {
            //create customer
            let { meta_data, first_name, last_name, id } = customerInfo;
            let company_name = getMetaValueFromProduct(
              customerInfo,
              "company_name"
            );
            let contact_type = getMetaValueFromProduct(
              customerInfo,
              "contact_type"
            );
            let { billing } = customer;
            let obj = {
              phone: billing?.phone,
            };
            let metaKeys = ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"];
            for (let metaObj of meta_data)
              if (metaKeys.includes(metaObj.key))
                obj[metaObj.key] = metaObj.value;
            let numObj = {};
            if (obj.whatsapp && obj.dialcode_whatsapp)
              numObj = {
                dialcode_whatsapp: obj?.dialcode_whatsapp,
                whatsapp: obj?.whatsapp,
              };
            // `${obj.dialcode_whatsapp}${obj.whatsapp}`;
            else if (obj.phone && obj.dialcode_mobile)
              numObj = {
                dialcode_mobile: obj.dialcode_mobile,
                whatsapp: obj?.phone,
              };
            // `${obj.dialcode_mobile}${obj.phone}`;
            dispatch(
              bulkActionChats({
                data: {
                  create: [
                    {
                      first_name,
                      last_name,
                      platform_id: payload.to,
                      contact_type,
                      company_name,
                      // whatsapp : ,
                      // dialcode_whatsapp : ,
                      // customer_id: id,
                      platform: "whatsapp",
                      ...numObj,
                    },
                  ],
                },
              })
            );
            // let conversations = await createChatUser({
            //   payload: {
            //     first_name,
            //     last_name,
            //     platform_id: payload.to,
            //     contact_type,
            //     company_name,
            //     // whatsapp : ,
            //     // dialcode_whatsapp : ,
            //     // customer_id: id,
            //     platform: "whatsapp",
            //     ...numObj,
            //   },
            // });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    console.log("sending message");
    try {
      const res = await axios({
        url: `${backendDoamin}/social/message`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: "POST",
        data: {
          ...data,
        },
      });
      console.log(res?.data);
      if (res.data.success) {
        // if (newWhatsappCustomer) {
        //   console.log(chats);
        //   let res = await getChatsUsers({ params: { platform_id: payload.to } })
        //     ?.data?.[0];
        //   // getConversationById({ platform }, payload.to);
        //   console.log(res, "<<< RES CHAT");
        //   if (isObjWithValues(customerInfo)) {
        //     let { meta_data, first_name, last_name, id } = customerInfo;
        //     // let conversations = await addConversation({
        //     //   id: payload.to,
        //     //   name: `${first_name || ""} ${last_name || ""}`,
        //     //   number: payload.to,
        //     //   status_updated: Date.now(),
        //     //   customer_id: id,
        //     // });
        //     let conversations = await createChatUser({
        //       payload: {
        //         first_name,
        //         last_name,
        //         platform_id: payload.to,
        //         customer_id: id,
        //         platform: "whatsapp",
        //       },
        //     });
        //     dispatch(
        //       updateCrmIdinSetting(id, [
        //         {
        //           platform: "whatsapp",
        //           id: payload.to,
        //         },
        //       ])
        //     );
        //     console.log(conversations, "<<<< convesations");
        //     if (isArrayWithValues(meta_data)) {
        //       let whatsapp_id = meta_data.find((i) => i.key === "whatsapp_id");
        //       if (!whatsapp_id) {
        //         let updatedCustomer = await updateCustomer(id, {
        //           meta_data: [
        //             {
        //               key: "whatsapp_id",
        //               value: payload.to,
        //             },
        //           ],
        //         });
        //         console.log(updatedCustomer, "<<<<<< updatdCustomer");
        //         dispatch(updateCustomerInRedux(updatedCustomer));
        //       }
        //     }
        //   }
        // }
        // console.log(res?.data?.data, customer?.platform_id, { platform });
        dispatch(addResponseInChat(res?.data?.data, customer.id, { platform }));
      }
      if (isObjWithValues(res.data?.data?.error)) {
        dispatch(
          setGlobalToast({
            show: true,
            message:
              res.data?.data?.error?.error_data?.details ||
              "Unable to send message!",
            severity: "error",
          })
        );
      }

      console.log(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSendingMessage(false);
      setReplyMessage(null);
      setMessageInput("");
      setMediaLibraryImage([]);
      setTemplateObj({});
      scrollToBottom();
    }
  };

  useMemo(() => {
    if (isObjWithValues(templateObj)) sendMessage();
  }, [templateObj]);

  useMemo(() => {
    if (!isObjWithValues(customer)) return;
    if (
      customer?.conversation &&
      !customer?.conversation[customer?.conversation?.length - 1]?.is_read
    )
      dispatch(readMessage(customer));
  }, [customer]);

  //
  const TotalConversationsCount = useMemo(
    () => (
      <>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <North
            style={{
              height: "15px",
              width: "15px",
              fill: "blue",
            }}
          />

          {customer?.conversation?.filter((o) => o?.is_sender)?.length || 0}
          <South
            style={{
              height: "15px",
              width: "15px",
              fill: "green",
            }}
          />
          {customer?.conversation?.filter((o) => !o?.is_sender)?.length || 0}
        </Stack>
      </>
    ),
    [customer?.conversation]
  );

  // const facebookThumbup = async () => {
  //   const website = getWebsite();
  //   const token = getToken();
  //   if (!website || !token) return;
  //   try {
  //     const res = await axios({
  //       url: `${website}/wp-json/store/v1/social/message`,
  //       method: "POST",
  //       data: {
  //         platform: "facebook",
  //         payload: {
  //           recipient: { id: customer.id },
  //           message: {
  //             type: "like_heart",
  //           },
  //         },
  //         // platform: "whatsapp",
  //         // payload: {
  //         //   messaging_product: "whatsapp",
  //         //   recipient_type: "individual",
  //         //   to: customer.number,
  //         //   type: "text",
  //         //   text: {
  //         //     body: messageInput,
  //         //   },
  //         // },
  //       },
  //     });
  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const FilterClose = () => {
    setAnchorEl(null);
  };
  const FilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterOpen = Boolean(anchorEl);
  const [loadingFilter, setLoadingFilter] = useState("");

  const onFilterAssigning = async (val) => {
    const website = getWebsite();
    const token = getToken();
    if (!website || !token) return;
    const res = await axios({
      url: `${website}/wp-json/store/v1/social/conversation/${customer.id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        platform: platform,
        payload: {
          status: val,
          status_updated: Date.now(),
        },
        // "is_read": true
      },
    });
    console.log(res.data);
    await dispatch(assignedFilter(customer, val));
    dispatch(setUpConversations(allReduxConversations));
    setLoadingFilter("");
    dispatch(
      setGlobalToast({
        ...globalToast,
        show: true,
        message: `Customer status ${val}`,
        severity: "success",
      })
    );
  };

  // useEffect(() => {
  //   if (customer.conversation[conversation.length - 1].time)
  //   setSessionEnded()
  // }, [customer])
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const onCannedMessageSelect = (message) => {
    setMessageInput((state) => `${state} ${message}`);
  };

  const inputRef = useRef(null);

  const onClick = (arg) => {
    setMessageInput((pre) => `${pre} ${arg?.emoji}`);
  };
  const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);

  const handleClick = (event) => {
    setAnchorElEmoji(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElEmoji(null);
  };

  const open = Boolean(anchorElEmoji);
  const idEmoji = open ? "simple-popover" : undefined;

  const onFileDrop = (files) => {
    console.log(files);
  };

  const onConfirm = async (tags) => {
    let tagsObject = { ...tags };
    let putSettingsData = {};

    if (!isObjWithValues(tagsObject)) return;
    for (let id in tagsObject) {
      putSettingsData[id] = {
        ...(tagsObject?.[id] || {}),
        contact_ids: [
          ...new Set([...(tagsObject?.[id]?.contact_ids || []), customer?.id]),
        ],
      };
    }

    let res = await settingApi({
      data: putSettingsData,
      endpoint: "chat_tags",
      method: "PUT",
    });
    console.log(res);
    if (res?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: {
          chat_tags: res?.data,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Tag Updated Successfully",
          severity: "success",
        })
      );
      dispatch(
        bulkActionChats({
          data: {
            update: [
              {
                id: customer?.id,
                meta_data: {
                  tags: [
                    ...new Set([
                      ...(customer?.meta_data?.tags || []),
                      ...Object.keys(tags || {}),
                    ]),
                  ],
                },
              },
            ],
          },
        })
      );
      setOpenTags(false);
    }
  };
  const onDeleteTags = async (tagId) => {
    dispatch(
      bulkActionChats({
        data: {
          update: [
            {
              id: customer?.id,
              meta_data: {
                tags: customer?.meta_data?.tags?.filter((str) => str !== tagId),
              },
            },
          ],
        },
        setLoading: setDeletingTag,
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Tag Updated Successfully",
              severity: "success",
            })
          );
          setDeleteTag(false);
        },
      })
    );
  };
  return (
    <>
      <input
        type="file"
        id="file"
        onChange={(e) => onFileDrop(e.target.files[0])}
        ref={inputFile}
        style={{ display: "none" }}
      />
      <ConfimPopup
        open={deleteTag}
        closeLabel={"Close"}
        heading={`Are you Sure you want to remove tag ${reduxTags?.[deleteTag]?.title}`}
        handleClose={() => setDeleteTag(false)}
        subtitle={"This action cannot be undone"}
        button={
          <LoadingButton
            color="error"
            variant="contained"
            loading={deletingTag}
            onClick={() => {
              onDeleteTags(deleteTag);
            }}
          >
            Delete
          </LoadingButton>
        }
      />
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      {/* <FullScreen handle={fullscreenHandle} onChange={fullscreenChange}> */}
      <Dialog
        open={openTags}
        onClose={() => setOpenTags(false)}
        maxWidth="xs"
        fullWidth
        sx={dialogStyle}
      >
        <Tags onConfirm={onConfirm} />
      </Dialog>
      <Box
        className="scrollbar-hidden"
        sx={{
          position: "relative",
          width: "100%",
          backgroundImage:
            platform === "whatsapp"
              ? !showChats
                ? "#fff"
                : "url(/static/img/whatsappBackground.png)"
              : "#fff",
        }}
      >
        {ProductPopup}

        {showHeader && (
          <Card sx={{ borderRadius: "0px", position: "sticky", top: 0 }}>
            <CardContent
              sx={{
                "&:last-child": { paddingBottom: "16px" },
                paddingLeft: isIpad ? 0 : "16px",
              }}
              style={
                isIpad
                  ? { padding: "6px", paddingLeft: isIpad ? 0 : "10px" }
                  : {}
              }
            >
              <Stack
                // direction={isIpad ? "column" : "row"}
                direction={"row"}
                // flexWrap={"wrap"}
                sx={{ backgroundColor: "head", px: isIpad ? 0 : 2 }}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  sx={{ backgroundColor: "head", px: 0 }}
                  alignItems="center"
                >
                  {isIpad ? (
                    <IconButton
                      onClick={() => {
                        navigate(`/chats`);
                        setCustomerView(false);
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  ) : null}
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <Box
                        sx={{ backgroundColor: "#fff", borderRadius: "50%" }}
                      >
                        {platform === "facebook" ? (
                          <Messenger height={18} width={18} />
                        ) : platform === "instagram" ? (
                          <InstagramSvg height={20} width={20} />
                        ) : (
                          platform === "whatsapp" && (
                            <Whatsapp height={18} width={18} />
                          )
                        )}
                      </Box>
                    }
                  >
                    <Badge
                      sx={{
                        ".MuiBadge-badge": {
                          top: "5px",
                          right: "5px",
                        },
                      }}
                      badgeContent={
                        customer?.contact_type === "customer" ? (
                          <CrownSvg
                            height={15}
                            width={15}
                            style={{ transform: "rotate(29deg)" }}
                          />
                        ) : null
                      }
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Avatar
                        alt={customerName}
                        src={customerProfile}
                        sx={
                          isIpad
                            ? { width: 40, height: 40 }
                            : { width: 50, height: 50 }
                        }
                      />
                    </Badge>
                  </Badge>
                  <Box ml={4}>
                    <Stack>
                      <Stack direction={"row"} alignItems="center" gap={"10px"}>
                        <Typography variant="h6" fontSize={"15px"}>
                          {getNameFromPlatform(customer) || ""}
                        </Typography>
                        {TotalConversationsCount}
                        {/* {customer?.contact_type ? (
                          <Chip
                            size="small"
                            label={formatServerValue(customer?.contact_type)}
                          />
                        ) : null} */}
                        {customer?.teamMember ? (
                          <Tooltip title={customer?.teamMember || ""}>
                            <HeadsetMic
                              style={{
                                // position: "absolute",
                                // top: "5px",
                                // right: favorite ? "30px" : "5px",
                                fontSize: "13px",
                                bottom: "5px",
                                // right: "5px",
                                color: "#808080",
                              }}
                              fontSize="small"
                            />
                          </Tooltip>
                        ) : null}
                        {customer?.broadcastList ? (
                          <Tooltip title={broadcastListStr}>
                            <PeopleAltOutlined
                              style={{
                                // position: "absolute",
                                // top: "5px",
                                // right: favorite ? "30px" : "5px",
                                fontSize: "13px",
                                bottom: "5px",
                                // right: "5px",
                                color: "#808080",
                              }}
                              fontSize="small"
                            />
                          </Tooltip>
                        ) : null}
                      </Stack>

                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={2}
                        sx={{ mt: 2 }}
                      >
                        {customer?.meta_data?.tags?.map((str) => {
                          return (
                            <Chip
                              icon={
                                <LocalOffer
                                  style={{ color: reduxTags?.[str]?.color }}
                                />
                              }
                              size={"small"}
                              label={reduxTags?.[str]?.title}
                              onDelete={() => setDeleteTag(str)}
                            />
                          );
                        })}
                        <Chip
                          icon={<Add />}
                          size={"small"}
                          label={"Add Tags"}
                          onClick={() => setOpenTags(true)}
                        />
                      </Stack>

                      <Typography variant="h6" fontSize={"15px"}>
                        {customer?.names?.map((name, index) => (
                          <span>
                            {name}{" "}
                            {name && index !== customer?.names?.length - 1
                              ? ","
                              : ""}
                          </span>
                        )) || ""}
                      </Typography>

                      {lastIntreact ? (
                        <Typography>{`${
                          getRelativeTimeDifference(new Date(lastIntreact))
                            .value
                        } ${
                          getRelativeTimeDifference(new Date(lastIntreact))
                            .label
                        } ago`}</Typography>
                      ) : null}
                    </Stack>
                  </Box>
                </Stack>
                {/* <Stack direction={"row"} alignItems="center"> */}

                {/* </Stack> */}
                {
                  <Stack
                    direction={isIpad ? "row-reverse" : "row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={
                      isIpad
                        ? {
                            // width: "100%",
                            mt: 2,
                          }
                        : {}
                    }
                  >
                    {true ? (
                      <Stack direction={"row"} alignItems="center" gap={2}>
                        {!isIpad && (
                          <IconButton
                            onClick={() =>
                              props.setShowProfile &&
                              props.setShowProfile((state) => !state)
                            }
                            sx={{
                              border: `1px solid ${
                                filterColorObj[customer?.status] ||
                                "transparent"
                              }`,
                            }}
                          >
                            <Person />{" "}
                          </IconButton>
                        )}

                        {false && (
                          <Button
                            onClick={() => setShowChats((state) => !state)}
                            sx={{ mx: 2, whiteSpace: "nowrap" }}
                            variant="outlined"
                            size="small"
                          >
                            Show{" "}
                            {showChats
                              ? `activity${
                                  userIncompleteTasks?.length
                                    ? ` (${userIncompleteTasks?.length})`
                                    : ""
                                }`
                              : "chats"}
                          </Button>
                        )}

                        <IconButton
                          onClick={FilterClick}
                          size="small"
                          sx={{}}
                          aria-controls={
                            filterOpen ? "account-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={filterOpen ? "true" : undefined}
                        >
                          <MoreVert />
                        </IconButton>
                      </Stack>
                    ) : null}
                    {!isArrayWithValues(customer?.ids) && (
                      <Stack direction={"row"} alignItems="center">
                        {customer?.status && false ? (
                          <Tooltip
                            title={formatServerValue(customer?.status || "")}
                          >
                            <Box>{getStatusIcon(customer?.status)}</Box>
                          </Tooltip>
                        ) : null}
                        {Object.values(customer?.profiles || {})?.length >
                          1 && (
                          <Box sx={{ backgroundColor: "Background.paper" }}>
                            <Stack
                              direction="row"
                              sx={{
                                backgroundColor: "#fff",
                                gap: "5px",
                                mr: 2,
                              }}
                            >
                              {channels.map((b) => {
                                if (!customer?.profiles?.[b.value]) return;
                                return (
                                  <IconButton
                                    // variant={"text"}
                                    // startIcon={b.icon}
                                    sx={{
                                      border: `2px solid `,
                                      borderColor:
                                        selectedTab === b.value
                                          ? "primary.main"
                                          : "transparent",
                                      // padding: "6px 20px",

                                      // backgroundColor:
                                      //   selectedTab === b.value ? "#e6e6e6" : "transparent",
                                    }}
                                    onClick={() => {
                                      customer?.profiles?.[b.value] &&
                                        navigate(
                                          `/chats/${
                                            customer?.profiles?.[b.value]
                                          }`
                                        );
                                      setSelectedTab(b.value);
                                    }}
                                  >
                                    {b.icon}
                                    {/* <Typography>{b.label}</Typography> */}
                                  </IconButton>
                                );
                              })}
                            </Stack>
                          </Box>
                        )}
                        {/* {!isIpad && (
                          <Box>
                            <IconButton
                              onClick={() =>
                                props.setShowProfile &&
                                props.setShowProfile((state) => !state)
                              }
                              sx={{
                                border: `1px solid ${
                                  filterColorObj[customer?.status] ||
                                  "transparent"
                                }`,
                              }}
                            >
                              <Person />{" "}
                            </IconButton>
                            {false && (
                              <Button
                                onClick={() => setShowChats((state) => !state)}
                                sx={{ mx: 2 }}
                                variant="outlined"
                                size="small"
                              >
                                Show{" "}
                                {showChats
                                  ? ` activity${
                                      userIncompleteTasks?.length
                                        ? ` (${userIncompleteTasks?.length})`
                                        : ""
                                    }`
                                  : "chats"}
                              </Button>
                            )}
                            <IconButton
                              onClick={FilterClick}
                              size="small"
                              sx={{}}
                              aria-controls={
                                filterOpen ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={filterOpen ? "true" : undefined}
                            >
                              <MoreVert />
                            </IconButton>
                          </Box>
                        )} */}
                      </Stack>
                    )}
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={filterOpen}
                      onClose={FilterClose}
                      onClick={FilterClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32)) ",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        onClick={() => {
                          dispatch(
                            bulkActionChats({
                              data: {
                                update: [
                                  {
                                    id: customer?.id,
                                    contact_type: "customer",
                                  },
                                ],
                              },
                              setLoading: (val) => {
                                dispatch(
                                  setGlobalToast({
                                    ...globalToast,
                                    show: val,
                                    message: `Marking as Customer...`,
                                    severity: "success",
                                  })
                                );
                              },
                              onSuccess: () => {
                                dispatch(
                                  setGlobalToast({
                                    ...globalToast,
                                    show: val,
                                    message: `Marked as Customer Successfully!`,
                                    severity: "success",
                                  })
                                );
                              },
                            })
                          );
                        }}
                        // onClick={() => onFilterChange(i.value)}
                      >
                        <ListItemIcon>
                          <PersonOutlineOutlined />
                        </ListItemIcon>
                        Mark As Customer
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(
                            bulkActionChats({
                              data: {
                                update: [
                                  {
                                    id: customer?.id,
                                    contact_type: "lead",
                                  },
                                ],
                              },
                              setLoading: (val) => {
                                dispatch(
                                  setGlobalToast({
                                    ...globalToast,
                                    show: val,
                                    message: `Marking as Lead...`,
                                    severity: "success",
                                  })
                                );
                              },
                              onSuccess: () => {
                                dispatch(
                                  setGlobalToast({
                                    ...globalToast,
                                    show: val,
                                    message: `Marked as Lead Successfully!`,
                                    severity: "success",
                                  })
                                );
                              },
                            })
                          );
                        }}
                        // onClick={() => onFilterChange(i.value)}
                      >
                        <ListItemIcon style={{ opacity: "0.7" }}>
                          <LeadIcon />
                        </ListItemIcon>
                        {/* {i.icon}  */}
                        Mark As Lead
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenBroadCast(true);
                        }}
                        // onClick={() => {
                        //   dispatch(
                        //     updateChatObject(customer, {
                        //       payload: {
                        //         contact_type: "lead",
                        //       },
                        //     }, (val) => {
                        //       // console.log(val);
                        //       dispatch(
                        //         setGlobalToast({
                        //           ...globalToast,
                        //           show: val,
                        //           message: `Marking as Lead...`,
                        //           severity: "success",
                        //         })
                        //       );
                        //     })
                        //   );
                        // }}
                        // onClick={() => onFilterChange(i.value)}
                      >
                        <ListItemIcon style={{ opacity: "0.7" }}>
                          <People />
                        </ListItemIcon>
                        {/* {i.icon}  */}
                        Add to Broadcast
                      </MenuItem>
                      {CRMfilters.map((i) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              dispatch(
                                bulkActionChats({
                                  data: {
                                    update: [
                                      {
                                        id: customer?.id,
                                        status:
                                          customer.status === i.value
                                            ? ""
                                            : i?.value,
                                      },
                                    ],
                                  },
                                  setLoading: (val) => {
                                    dispatch(
                                      setGlobalToast({
                                        ...globalToast,
                                        show: val,
                                        message: `${
                                          customer.status === i.value
                                            ? "Removing From"
                                            : "Marking as"
                                        } ${formatServerValue(i?.value)}...`,
                                        severity: "success",
                                      })
                                    );
                                  },
                                  onSuccess: () => {
                                    dispatch(
                                      setGlobalToast({
                                        ...globalToast,
                                        show: val,
                                        message: `Marked as Lead Successfully!`,
                                        severity: "success",
                                      })
                                    );
                                  },
                                })
                              );
                            }}
                            // onClick={() =>
                            //   onFilterAssigning(
                            //     customer.status === i.value ? "" : i.value
                            //   )
                            // }
                            sx={{
                              minHeight: "auto",
                              color:
                                customer.status === i.value
                                  ? "danger.main"
                                  : "text.primary",
                            }}
                          >
                            <ListItemIcon>{i.icon}</ListItemIcon>
                            {customer.status === i.value
                              ? i.removeLabel
                              : i.label}
                          </MenuItem>
                        );
                      })}
                      {isIpad ? (
                        <>
                          <MenuItem
                            onClick={() => {
                              props.setShowProfile &&
                                props.setShowProfile((state) => !state);
                            }}
                          >
                            <ListItemIcon>
                              <Person />
                            </ListItemIcon>
                            Profile
                          </MenuItem>
                        </>
                      ) : null}
                      <MenuItem
                        onClick={() => {
                          setToggleDeleteDialog(true);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteOutlineOutlined />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  </Stack>
                }
              </Stack>
            </CardContent>
          </Card>
        )}
        <Divider />
        <Dialog
          open={openBroadCast}
          onClose={() => {
            setOpenBroadCast(false);
          }}
          sx={{ ...dialogStyle }}
        >
          <BroadcastList
            selectedCustomers={[customer]}
            handleClose={() => {
              setOpenBroadCast(false);
            }}
            hideView
            hideDelete
          />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpenBroadCast(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {!showChats ? (
          <UserTasks customer={customer} />
        ) : (
          <Box sx={{}}>
            {imageToUpload ? (
              <ImagePreview
                src={imageToUpload}
                caption={caption}
                setCaption={setCaption}
                // onImageSend={onImageSend}
                setImageToUpload={setImageToUpload}
                setRowImageToUpload={setRowImageToUpload}
              />
            ) : null}
            {/* <div
              ref={chatsParent}
              style={{
                width: "100%",
                overflow: "scroll",
                height: isIpad
                  ? showHeader
                    ? "calc(100vh - 180px)"
                    : "calc(100vh - 276px)"
                  : showHeader
                    ? "calc(100vh - 332px)"
                    : "calc(100vh - 332px)",
              }}
              className="scrollbar-hidden"
            >
              {customerLoading && !isArrayWithValues(chats) && (
                <Box sx={{ mt: 5 }} className="scrollbar-hidden">
                  <Stack direction="row" justifyContent={"center"}>
                    <Chip
                      icon={
                        <CircularProgress
                          style={{ height: "16px", width: "16px" }}
                        />
                      }
                      label="Loading"
                      variant="outlined"
                    />
                  </Stack>
                </Box>
              )} */}
            {isLoading ? (
              <Box
                sx={{
                  height: isIpad
                    ? showHeader
                      ? "calc(100vh - 180px)"
                      : "calc(100vh - 276px)"
                    : showHeader
                    ? "calc(100vh - 332px)"
                    : "calc(100vh - 332px)",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <MappingChats
                chatsParent={chatsParent}
                customerLoading={customerLoading}
                chats={chats}
                platform={platform}
                allWhatsappTemplates={allWhatsappTemplates}
                setProductsId={setProductsId}
                setReplyMessage={setReplyMessage}
                setFormOpen={setFormOpen}
                setMessageImageView={setMessageImageView}
                showHeader={showHeader}
                isIpad={isIpad}
              />
            )}

            {/* </div> */}

            <ChatInput
              isMobile={isMobile}
              container
              alignItems="center"
              sx={{
                color: "text.secondary",
                position: "relative",
                padding: "10px",
                // position: "absolute"
                position: "sticky",
                bottom: "0px",
                // flexDirection: isObjWithValues(replyMessage) ? "column" : "row"
              }}
            >
              {isObjWithValues(replyMessage) && isIpad && (
                <Stack
                  direction={{ lg: "row", md: "column" }}
                  justifyContent="space-between"
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  sx={{
                    padding: "0px 20px",
                    backgroundColor: "#f1f1f1",
                    borderRadius: "10px",
                    width: "inherit",
                    mb: 2,
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "10px", width: "-webkit-fill-available" }}
                    justifyContent="space-between"
                  >
                    <Stack>
                      {replyMessage.message && (
                        <Typography>{replyMessage.message}</Typography>
                      )}
                      {replyMessage.header && (
                        <Typography sx={{ fontWeight: "700" }}>
                          {replyMessage.header}
                        </Typography>
                      )}
                      {replyMessage.body && (
                        <Typography>{replyMessage.body}</Typography>
                      )}
                      {replyMessage.image && (
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <CameraAlt
                            style={{
                              height: "15px",
                              width: "15px",
                              color: "808080",
                            }}
                          />{" "}
                          Photo
                        </Typography>
                      )}
                      {replyMessage?.document || replyMessage?.audio ? (
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <CameraAlt
                            style={{
                              height: "15px",
                              width: "15px",
                              color: "808080",
                            }}
                          />{" "}
                          Media
                        </Typography>
                      ) : null}
                    </Stack>
                    <Stack>
                      {replyMessage.image && (
                        <img
                          alt="Reply"
                          src={replyMessage.image}
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "4px",
                          }}
                        />
                      )}
                      {replyMessage?.document ||
                        (replyMessage?.audio && (
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{
                              backgroundColor: "#ccc",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <PermMedia />
                          </Stack>
                        ))}
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      setReplyMessage({});
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              )}
              {integratedApps.includes(platform) || true ? (
                <>
                  {isArrayWithValues(mediaLibraryImage) && (
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems={"center"}
                      sx={{
                        padding: "0px 20px",
                        backgroundColor: "#f1f1f1",
                        borderRadius: "10px",
                        mb: "8px",
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        sx={{ gap: "10px" }}
                      >
                        {mediaLibraryImage?.map((o) =>
                          o?.mime_type?.includes("image") ? (
                            <img
                              src={o?.source_url || ""}
                              style={{ height: "30px", width: "30px" }}
                            />
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems="center"
                              sx={{
                                backgroundColor: "#ccc",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <PermMedia />
                            </Stack>
                          )
                        )}
                        {/* {mediaLibraryImage[0]?.mime_type?.includes("image") ? (
                          <img
                            src={mediaLibraryImage[0]?.source_url || ""}
                            style={{ height: "30px", width: "30px" }}
                          />
                        ) : null} */}
                        {/* {!mediaLibraryImage[0]?.mime_type?.includes("image") ? (
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{
                              backgroundColor: "#ccc",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <PermMedia />
                          </Stack>
                        ) : null} */}

                        <Typography>
                          {mediaLibraryImage[0]?.file_name || ""}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setMediaLibraryImage([]);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Stack>
                  )}
                  <Stack
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #808080",
                      borderColor: "grey.500",
                      width: "100%",
                      padding: "3px",
                    }}
                    // direction={{ lg: "row", md: "column" }}
                    // direction={
                    //   isObjWithValues(replyMessage) || isIpad ? "column" : "row"
                    // }
                    direction={"row"}
                  >
                    {" "}
                    {isObjWithValues(replyMessage) && (
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"center"}
                        sx={{
                          padding: "0px 20px",
                          backgroundColor: "#f1f1f1",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          sx={{ gap: "10px", width: "-webkit-fill-available" }}
                          justifyContent="space-between"
                        >
                          <Stack>
                            {replyMessage.message && (
                              <Typography>{replyMessage.message}</Typography>
                            )}
                            {replyMessage.header && (
                              <Typography sx={{ fontWeight: "700" }}>
                                {replyMessage.header}
                              </Typography>
                            )}
                            {replyMessage.body && (
                              <Typography>{replyMessage.body}</Typography>
                            )}
                            {replyMessage.image && (
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {" "}
                                <CameraAlt
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    color: "808080",
                                  }}
                                />{" "}
                                Photo
                              </Typography>
                            )}
                          </Stack>
                          <Stack>
                            {replyMessage.image && (
                              <img
                                src={replyMessage.image}
                                style={{
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "4px",
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                        <IconButton
                          onClick={() => {
                            setReplyMessage({});
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Stack>
                    )}
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      sx={{ flex: 1 }}
                    >
                      <TextField
                        ref={inputRef}
                        onKeyDown={(e) => {
                          // console.log(
                          //   e.key === "Enter",
                          //   e.metaKey,
                          //   e.ctrlKey,
                          //   messageInput?.trim()
                          // );
                          if (
                            e.key === "Enter" &&
                            (e.ctrlKey || e.metaKey) &&
                            messageInput?.trim()
                          )
                            setMessageInput((pre) => `${pre}\n`);
                          else if (
                            e.key === "Enter" &&
                            // (e.ctrlKey || e.metaKey) &&
                            messageInput?.trim()
                          )
                            sendMessage();
                        }}
                        // onKeyDown={(e) => {
                        //   console.log(
                        //     e.key === "Enter",
                        //     e.metaKey,
                        //     e.ctrlKey,
                        //     messageInput?.trim()
                        //   );
                        //   if (
                        //     e.key === "Enter" &&
                        //     (e.ctrlKey || e.metaKey) &&
                        //     messageInput?.trim()
                        //   )
                        //     sendMessage();
                        // }}
                        disabled={
                          notOptedIn ||
                          isWindowClosed ||
                          !integratedSocials.includes(platform) ||
                          customer?.ids
                        }
                        onPaste={(event) => {
                          event.preventDefault();
                          const pastedText =
                            event.clipboardData.getData("text");
                          const modifiedText = pastedText.replace(
                            /\r?\n/g,
                            "\r\n"
                          ); // convert all line endings to \r\n
                          const selectionStart = event.target.selectionStart;
                          const selectionEnd = event.target.selectionEnd;
                          const newValue =
                            messageInput?.substring(0, selectionStart) +
                            modifiedText +
                            messageInput?.substring(selectionEnd);
                          setMessageInput(newValue);
                          // const pastedText = event.clipboardData.getData("text");
                          // const modifiedText = pastedText.replace(/\r?\n/g, "\r\n"); // convert all line endings to \r\n
                          // console.log(modifiedText);
                          // setMessageInput(modifiedText);
                        }}
                        placeholder={"Type your message here..."}
                        autoFocus
                        fullWidth
                        multiline={!isIpad}
                        rows={isIpad ? 1 : 2}
                        maxRows={isIpad ? 1 : 2}
                        // size={"small"}
                        sx={{
                          cursor: "none",
                          ".MuiInputBase-input": isIpad
                            ? { padding: "10px" }
                            : {},
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          flex: 1,
                          // padding: "10px",
                          // color:
                          //   !isWindowClosed && !notOptedIn
                          //     ? "text.primary"
                          //     : "danger.main",
                        }}
                        // helperText=""
                        // FormHelperTextProps={{ sx: { textAlign: "right" } }}
                        onChange={(e) => setMessageInput(e.target.value)}
                        value={
                          customer?.ids
                            ? "You can only mointor chats in merged view"
                            : notOptedIn
                            ? "Customer has not yet opted-in to receive your whatsapp message from you"
                            : isWindowClosed
                            ? customer?.platform === "webchat"
                              ? `Chat closed. You'll be able to send messages once user reopen the conversation.`
                              : `As per ${platform} rules, you can only respond to a customer within 24 hours of their message`
                            : !integratedSocials.includes(platform)
                            ? `${platform || ""} is not integrated`
                            : messageInput
                        }
                      />
                      {!(
                        notOptedIn ||
                        isWindowClosed ||
                        !integratedSocials.includes(platform) ||
                        customer?.ids
                      ) && isIpad ? (
                        <IconButton onClick={() => setOpenOptions(true)}>
                          <Add />
                        </IconButton>
                      ) : null}
                      {!(
                        notOptedIn ||
                        isWindowClosed ||
                        !integratedSocials.includes(platform) ||
                        customer?.ids
                      ) && isIpad ? (
                        <IconButton
                          disabled={sendingMessage || !messageInput}
                          onClick={() => sendMessage()}
                        >
                          <SendIcon />
                        </IconButton>
                      ) : null}
                    </Stack>
                    {!isArrayWithValues(customer?.ids) && !isIpad && (
                      <Stack
                        direction={"row"}
                        justifyContent="end"
                        gap={isIpad ? "0" : "10px"}
                        alignItems="flex-end"
                        sx={
                          isIpad
                            ? {}
                            : {
                                paddingRight: "7px",
                                gap: "10px",
                                paddingBottom: "7px",
                              }
                        }
                      >
                        {/* {platform === "whatsapp" && <IconButton onClick={() => {
                    inputFile.current.click();
                  }}>
                    <UploadFile />
                  </IconButton>} */}
                        {
                          // selectedCatalog &&
                          !isWindowClosed &&
                          !notOptedIn &&
                          platform === "whatsapp" ? (
                            <Tooltip title="Catalog">
                              <IconButton
                                onClick={() => setSelectProductOpen(true)}
                              >
                                <Inventory />
                              </IconButton>
                            </Tooltip>
                          ) : null
                        }
                        {platform === "whatsapp" && (
                          <Tooltip title="Whatsapp Template">
                            <IconButton>
                              <InsertDriveFileOutlined
                                style={{ height: "20px", width: "20px" }}
                                onClick={() => setOpenTempleteList(true)}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!isWindowClosed &&
                          !notOptedIn &&
                          platform !== "webchat" && (
                            <Tooltip title={"Attachment"}>
                              <IconButton
                                // onClick={() => inputFile.current.click()}
                                onClick={() => setMediaLibraryOpen(true)}
                              >
                                <Attachment
                                  size="small"
                                  style={{ height: "20px", width: "20px" }}
                                  onClick={() => setopenCreateTemplete(true)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        {/* {platform === "facebook" && false && (
                        <IconButton onClick={facebookThumbup}>
                          <ThumbUp
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#808080",
                            }}
                          />
                        </IconButton>
                      )} */}
                        {/* {platform === "instagram" && (
                        <IconButton>
                          {" "}
                          <Favorite
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#808080",
                            }}
                          />
                        </IconButton>
                      )} */}
                        {mediaLibraryOpen && (
                          <NewMediaLibrary
                            open={mediaLibraryOpen}
                            handleClose={() => {
                              setMediaLibraryOpen(false);
                            }}
                            platform={platform}
                            setParentImages={setImages}
                            multiple={true}
                            enable_crop={false}
                            hideUploadInfo={true}
                          />
                        )}
                        {!isWindowClosed && !notOptedIn && (
                          <>
                            <Tooltip title={"Quik reply"}>
                              <IconButton
                                onClick={
                                  openCannedPopover
                                  // addCannedMessageRef.current?.openAddCannedMessages()
                                }
                              >
                                <PostAdd
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "#808080",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              aria-describedby={idEmoji}
                              onClick={handleClick}
                            >
                              <SentimentSatisfiedOutlined
                                color={"action"}
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  color: "#808080",
                                }}
                              />
                            </IconButton>
                          </>
                        )}

                        {!!messageInput ||
                        isArrayWithValues(mediaLibraryImage) ||
                        isObjWithValues(templateObj) ? (
                          <LoadingButton
                            variant={isIpad ? "text" : "outlined"}
                            endIcon={<SendIcon />}
                            loading={sendingMessage}
                            size="small"
                            onClick={() => sendMessage()}
                            sx={
                              isIpad
                                ? { minWidth: "auto" }
                                : { padding: "5px 5px" }
                            }
                          >
                            {isIpad ? "" : "Send"}
                          </LoadingButton>
                        ) : null}
                      </Stack>
                    )}
                  </Stack>
                  <Popover
                    id={idEmoji}
                    open={open}
                    anchorEl={anchorElEmoji}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <EmojiPicker
                      onEmojiClick={onClick}
                      autoFocusSearch={false}
                      // theme={Theme.AUTO}
                      // searchDisabled
                      // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                      // height={350}
                      // width="50%"
                      // emojiVersion="0.6"
                      // lazyLoadEmojis={true}
                      // previewConfig={{
                      //   defaultCaption: "Pick one!",
                      //   defaultEmoji: "1f92a" // 🤪
                      // }}
                      // suggestedEmojisMode={SuggestionMode.RECENT}
                      // skinTonesDisabled
                      // searchPlaceHolder="Filter"
                      // defaultSkinTone={SkinTones.MEDIUM}
                      // emojiStyle={EmojiStyle.NATIVE}
                      // categories={[
                      //   {
                      //     name: "Fun and Games",
                      //     category: Categories.ACTIVITIES
                      //   },
                      //   {
                      //     name: "Smiles & Emotions",
                      //     category: Categories.SMILEYS_PEOPLE
                      //   },
                      //   {
                      //     name: "Flags",
                      //     category: Categories.FLAGS
                      //   },
                      //   {
                      //     name: "Yum Yum",
                      //     category: Categories.FOOD_DRINK
                      //   }
                      // ]}
                    />
                  </Popover>
                  <Drawer
                    anchor={"bottom"}
                    open={openOptions}
                    onClose={() => {
                      setOpenOptions(false);
                    }}
                    sx={{
                      "	.MuiDrawer-paperAnchorBottom": {
                        borderTopLeftRadius: 9,
                        borderTopRightRadius: 9,
                      },
                    }}
                  >
                    <List>
                      {[
                        {
                          show:
                            !isWindowClosed &&
                            !notOptedIn &&
                            platform === "whatsapp",
                          icon: <Inventory />,
                          title: "WhatsApp Catalog",
                          onClick: () => setSelectProductOpen(true),
                        },
                        {
                          show: platform === "whatsapp",
                          icon: <InsertDriveFileOutlined />,
                          onClick: () => setOpenTempleteList(true),
                          title: "Template",
                        },
                        {
                          show: true,
                          icon: (
                            <Attachment
                            // size="small"
                            // style={{ height: "20px", width: "20px" }}
                            // onClick={() => setopenCreateTemplete(true)}
                            />
                          ),
                          onClick: () => setMediaLibraryOpen(true),
                          title: "Attachment",
                        },
                        // "Inbox", "Starred", "Send email", "Drafts"
                      ].map((obj, index) => {
                        return (
                          <>
                            <ListItem key={obj?.title} disablePadding>
                              <ListItemButton
                                onClick={() => {
                                  setOpenOptions(false);
                                  obj?.onClick && obj?.onClick();
                                }}
                              >
                                <ListItemIcon>{obj?.icon}</ListItemIcon>
                                <ListItemText primary={obj?.title} />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                          </>
                        );
                      })}
                    </List>
                  </Drawer>
                  <Typography
                    sx={{
                      marginLeft: "auto",
                      textAlign: "right",
                      ...secondaryLabelSmall,
                      fontSize: "10px",
                      mr: 1,
                      mt: 0.5,
                      visibility: messageInput?.trim() ? "visible" : "hidden",
                    }}
                  >
                    Enter to Send message
                  </Typography>
                </>
              ) : (
                <>
                  {sessionEnded ? (
                    <Grid item>
                      <Alert
                        variant="outlined"
                        severity="info"
                        sx={{
                          p: 0,
                          borderWidth: 0,
                          fontSize: isMobile ? "12px" : "15px",
                          alignItems: "center",
                        }}
                      >
                        As per WhatsApp rules, you can only respond to a
                        customer within 24 hours of their message.
                      </Alert>
                    </Grid>
                  ) : (
                    <>
                      {" "}
                      {isObjWithValues(customer) && (
                        <Grid item>
                          <Alert
                            variant="outlined"
                            severity="info"
                            sx={{
                              p: 0,
                              borderWidth: 0,
                              fontSize: isMobile ? "12px" : "15px",
                              alignItems: "center",
                            }}
                          >
                            {`${platform} is not integrated`}
                          </Alert>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}

              <TempleteList
                open={openTempleteList}
                handleClose={() => setOpenTempleteList(false)}
                setParentTemplate={setTemplate}
                newMediaLibrary={true}
              />
              {/* <ProductMediaLibrary
                open={mediaLibraryOpen}
                onChange={() => {
                  setMediaLibraryOpen(false);
                }}
                setParentImages={setImages}
                multiple={true}
                enable_crop={false}
                hideUploadInfo={true}
              /> */}
              <AddCannedMessages ref={addCannedMessageRef} />
              <Popover
                id={id}
                open={cannedOpen}
                anchorEl={anchorElCanned}
                onClose={closeCannedPopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                // sx={{ width: "270px" }}
              >
                <CannedMessages
                  onCreate={() =>
                    addCannedMessageRef.current?.openAddCannedMessages()
                  }
                  onClose={closeCannedPopover}
                  onSelect={onCannedMessageSelect}
                  allCannedMessages={allCannedMessages}
                />
              </Popover>
            </ChatInput>
          </Box>
        )}
      </Box>

      {/* <Dialog
        open={showNotesDialog}
        onClose={() => setShowNotesDialog(false)}
        maxWidth="sm"
        fullWidth
        sx={dialogStyle}
      >
        <DialogTitle>Add Note ({customerName})</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Title</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="email"
            fullWidth
            variant="standard"
            value={noteForm.title}
            onChange={(e) => changeNotesForm({ title: e.target.value })}
          />
          <Typography variant="h6">Description</Typography>
          <TextField
            margin="dense"
            id="name"
            label="Description"
            type="email"
            fullWidth
            variant="standard"
            multiline
            rows={3}
            value={noteForm.note}
            onChange={(e) => changeNotesForm({ note: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNotesDialog(false)}>Cancel</Button>
          <LoadingButton loading={noteLoading} onClick={onAddNote}>
            Add Note
          </LoadingButton>
        </DialogActions>
      </Dialog> */}

      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(selectProductOpen)}
        onClose={() => setSelectProductOpen(false)}
      >
        <SelectProducts
          facebookProduct={true}
          onClose={() => setSelectProductOpen(false)}
          onSelect={onProductsSelected}
          extraParams={{
            "custom_filter[0][key]": "_wc_facebook_sync_enabled",
            "custom_filter[0][value]": "yes",
          }}
        />
      </Dialog>
      {/* </FullScreen> */}
      <Dialog
        maxWidth="xs"
        fullWidth
        open={customerLabelId}
        onClose={() => setCustomerLabelId(false)}
        {...globalPopupStyle}
      >
        <CustomerLabelView
          id={customerLabelId}
          onClose={() => setCustomerLabelId(false)}
          setCustomer={props.setCrmViewCustomer}
        />
      </Dialog>
      <Dialog
        open={messageImageView.show}
        onClose={() =>
          setMessageImageView((pre) => ({ ...pre, show: false, type: "" }))
        }
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
      >
        {/* <DialogTitle>
          <IconButton>
            <a download={messageImageView.url}>
              <Download />
            </a>
          </IconButton>
        </DialogTitle> */}
        <DialogContent
          sx={{
            padding: "8px",
            "&:last-child": { paddingBottom: "3px" },
            height: "100%",
          }}
        >
          {!messageImageView.type && (
            // <StoryReply url={messageImageView.url} style={{
            //   width: "100%",
            //   maxHeight: "80vh",
            //   padding: 0,
            //   margin: 0,
            //   objectFit: "contain",
            // }} />
            <img
              src={messageImageView.url}
              // src="https://i.natgeofe.com/n/c0e0a134-3e97-4b8f-9f7b-9d11f5e1bf02/comedy-wildlife-awards-squirel-stop.jpg?w=636&h=475"
              style={{
                width: "100%",
                maxHeight: "80vh",
                padding: 0,
                margin: 0,
                objectFit: "contain",
              }}
            />
          )}
          {messageImageView.type && (
            <video
              style={{
                width: "100%",
                maxHeight: "80vh",
                padding: 0,
                margin: 0,
                objectFit: "contain",
                borderRadius: "15px",
              }}
              controls
            >
              <source src={messageImageView.url} type="video/mp4" />
              {/* <source src="movie.ogg" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
      {/* <Dialog
        onClose={() => toggleProductDialog(false)}
        open={showProductDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        sx={{
          minHeight: "60vh",
          padding: "",
        }}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Select products</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            sx={{
              minHeight: "60vh",
              padding: "",
            }}
            tabIndex={-1}
          >
            <TextField
              value={productSearch}
              onChange={(e) => {
                setProductSearch(e.target.value);
                if (e.target.value.length > 2)
                  return fetchProducts(
                    productSearch,
                    setProductsSearched,
                    setFetchingProducts
                  );
              }}
              sx={{ mb: 2 }}
              variant="outlined"
              fullWidth
              size="small"
              type="search"
              placeholder="Search products"
              // onSubmit={() =>
              //   fetchProducts(
              //     productSearch,
              //     setProductsSearched,
              //     setFetchingProducts
              //   )
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      onClick={() =>
                        fetchProducts(
                          productSearch,
                          setProductsSearched,
                          setFetchingProducts
                        )
                      }
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onSubmit={() => console.log("submit")}
            />
           
            {isArrayWithValues(productsSearched)
              ? productsSearched.map((product) => {
                let { images, id } = product;
                let image = isArrayWithValues(images) && images[0].src;
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "7px 3px",
                      backgroundColor: selectedProducts.find(
                        (i) => i.id === id
                      )
                        ? "Highlight"
                        : "transparent",
                    }}
                    key={product.id}
                    onClick={() => onProductSelect(product)}
                  >
                    {image ? (
                      <img
                        src={image}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                        onError={() => (image = "")}
                      />
                    ) : (
                      <img
                        src={"/static/img/woocommerce_placeholder.png"}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div style={{ marginLeft: "6px" }}>{product.name}</div>
                  </div>
                );
              })
              : null}
            {fetchingProducts ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <CircularProgress size="13px" />
                <Typography variant="body1" ml={2}>
                  Loading products.
                </Typography>
              </div>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask customer={customer} closeForm={closeForm} />
      </Dialog>
      {/* <Dialog
        open={catalogDialog}
        onClose={() => setCatalogDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">Send catalog</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container spacing={2} mt={1}>
            
              <Grid item xs={12} md={catalogForm.public ? 12 : 6}>
                <DropdownLabel>Catalog title</DropdownLabel>
                <TextField
                  value={catalogForm.title}
                  onChange={(e) => changeCatalogForm({ title: e.target.value })}
                  fullWidth
                  size="small"
                  id="outlined"
                  variant="outlined"
                />
              </Grid>
              {!catalogForm.public ? (
                <Grid item xs={12} md={6}>
                  <DropdownLabel>Expired in (min 1/max 48)</DropdownLabel>
                  <TextField
                    value={catalogForm.expiry}
                    onChange={(e) =>
                      changeCatalogForm({ expiry: e.target.value })
                    }
                    fullWidth
                    size="small"
                    id="outlined"
                    variant="outlined"
                    // label="Gemstone Pieces"
                    type="number"
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
              // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <DropdownLabel>Select product(s)</DropdownLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={catalogProducts}
                  size="small"
                  // sx={{ width: 300 }}
                  value={catalogForm.products}
                  loading={fetchingCatalogProducts}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="UP SELL"
                      onChange={(e) => onProductSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) =>
                    changeCatalogForm({ products: value })
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
              // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <DropdownLabel>Description</DropdownLabel>
                <TextField
                  value={catalogForm.content}
                  onChange={(e) =>
                    changeCatalogForm({ content: e.target.value })
                  }
                  fullWidth
                  size="small"
                  variant="outlined"
                  // label="Gemstone Pieces"
                  // type="number"
                  id="outlined-textarea"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContentText>
          <Collapse in={catalogError ? true : false}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ marginTop: "10px" }}
            >
              {catalogError}
            </Alert>
          </Collapse>
        </DialogContent>

        <DialogActions sx={{ marginRight: "15px" }}>
          <Button onClick={() => setCatalogDialog(false)}>Cancel</Button>
          <LoadingButton
            loading={creatingCatalog}
            variant="outlined"
            onClick={() => onCreateCatalog()}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog
        open={quotationDialog}
        maxWidth="sm"
        fullWidth
        onClose={closeQuotationDialog}
        TransitionComponent={Slide}
        sx={{ padding: "26px" }}
      >
        <DialogTitle>Create Quotation</DialogTitle>
        <DialogContent sx={{}}>
          <Collapse in={Boolean(quotationError)}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ my: 3, padding: "2px 7px" }}
            >
              {quotationError}
            </Alert>
          </Collapse>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <DropdownLabel>Title</DropdownLabel>
          <TextField
            autoFocus
            // id="outlined-basic"
            size="small"
            // label=""
            variant="standard"
            fullWidth
            value={quotationForm.title}
            onChange={(e) => changeQuotationForm({ title: e.target.value })}
          />
          <Box sx={{ mt: 2.5 }} />
          <DropdownLabel>Select Customer</DropdownLabel>
          <Autocomplete
            // ref={selectQuotaionCustomerRef}
            disablePortal
            id="combo-box-demo"
            options={allQuotationCustomers}
            size="small"
            // sx={{ width: 300 }}
            value={quotationForm.customers}
            getOptionLabel={(option) => option.label || ""}
            // loading={customerSearching}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                // label="UP SELL"
                onChange={(e) =>
                  onQuotationCustomerSearch(
                    e.target.value,
                    setUpdatedQuotationCustomers
                  )
                }
              />
            )}
            onChange={(e, value) => changeQuotationForm({ customers: value })}
            ListboxProps={{
              style: {
                maxHeight: "100px",
              },
            }}
          />
          <Box sx={{ mt: 2.5 }} />
          <FormGroup sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  // checked={quotationForm.otp_enabled}
                  // onChange={(e) =>
                  //   changeQuotationForm({
                  //     otp_enabled: e,
                  //   })
                  // }
                  style={
                    quotationForm.otp_enabled
                      ? {
                        // margin: "4px 8px",
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                      : {
                        // margin: "4px 8px",
                      }
                  }
                />
              }
              label="Enable OTP"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeQuotationDialog}>Cancel</Button>
          <LoadingButton
            onClick={onQuotationCreate}
          // loading={creatingQuotation}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={toggleDeleteDialog}
        onClose={() => {
          setToggleDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete conversation ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setToggleDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={deletingConversation}
            onClick={async () => {
              setdeletingConversation(true);
              await dispatch(deleteConversation([customer]));
              dispatch(
                setGlobalToast({
                  show: true,
                  message: "Chat Deleted Successfully!",
                  severity: "success",
                })
              );
              setdeletingConversation(false);
              setToggleDeleteDialog(false);
            }}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CannedMessages = ({ onCreate, onClose, onSelect, allCannedMessages }) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(allCannedMessages)) return;
    setRows(Object.values(allCannedMessages).reverse());
  }, [allCannedMessages]);
  return (
    <Box sx={{ p: 4, pt: 2, width: "250px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography sx={{ ...primaryLabel, fontSize: "16px" }}>
          Quick replies
        </Typography>
        <IconButton
          onClick={() => {
            // onClose && onClose();
            onCreate && onCreate();
          }}
          size="small"
        >
          <Add />
        </IconButton>
      </Stack>
      {rows.map((row, index) => {
        return (
          <>
            {index !== 0 ? <Divider /> : null}
            <Stack
              direction="row"
              sx={{ my: 1.5, cursor: "pointer" }}
              onClick={() => {
                onSelect(row.message);
                onClose();
              }}
            >
              <Typography sx={{ ...primaryLabel }}> {row.message}</Typography>
            </Stack>
          </>
        );
      })}
    </Box>
  );
};

const Chat = (props) => {
  let [whatsappChats, setWhatsappChats] = useState([]);
  let [propChats, setPropChats] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerProfile, setCustomerProfile] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  useEffect(() => {
    let { customer } = props;
    let { profile_pic } = customer;
    if (isArrayWithValues(customer.whatsapp_chat))
      setPropChats(customer.whatsapp_chat);
    else setPropChats([]);
    let { first_name, last_name, name, username } = customer;
    // if (isArrayWithValues(meta_data)) {
    // let profileObj = meta_data.find((i) => i.key === "profile_url");
    // let labelObj = meta_data.find((i) => i.key === "label");
    // if (isObjWithValues(profileObj)) setCustomerProfile(profileObj.value);
    // else setCustomerProfile("");
    // if (isObjWithValues(labelObj)) setCustomerLabel(labelObj.value);
    // }
    setCustomerName(
      `${first_name || ""} ${last_name || ""} ${name || ""} ${username || ""} `
    );
  }, [props.customer]);
  let { customer, customerInfo, setCustomerView } = props;
  let { profile_pic } = customer;
  // const allReduxConversations = useSelector(
  //   (state) => state.chats.conversations
  // );
  // useEffect(() => {
  //   if (isArrayWithValues(propChats)) setWhatsappChats(propChats);
  //   else setWhatsappChats([]);
  // }, [propChats]);

  return (
    <React.Fragment>
      <ChatWindow
        setCustomerView={setCustomerView}
        // chats={whatsappChats}
        customerName={customerName}
        id={props.customer && props.customer.id}
        customerLoading={props.customerLoading}
        customerProfile={profile_pic}
        customer={customer}
        customerInfo={customerInfo}
        chatsTabSelected={props.chatsTabSelected}
        customerLabel={customerLabel}
        setCrmViewCustomer={props.setCrmViewCustomer}
        setShowProfile={props.setShowProfile}
      />
    </React.Fragment>
  );
};

export default Chat;

const ImagePreview = (props) => {
  const {
    caption,
    setCaption,
    // onImageSend,
    src,
    setImageToUpload,
    setRowImageToUpload,
  } = props;
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        sx={{
          height: "calc(61vh)",
          backgroundColor: "AppWorkspace",
          textAlign: "center",
          position: "relative",
          width: "100%",
        }}
        item
        xs={12}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setImageToUpload("");
            setRowImageToUpload("");
          }}
        >
          <Close />
        </IconButton>
        <div
          style={{
            backgroundColor: "AppWorkspace",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isIpad ? "80vw" : "40vw",
          }}
        >
          <img
            src={src}
            style={{
              width: isIpad ? "70vw" : "20vw",
              maxHeight: isIpad ? "70vw" : "20vw",
              boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.54)",
            }}
          />

          <Box sx={{ height: "15px" }} />
          <ChatInput container>
            <Grid item style={{ flexGrow: 1, flex: 1 }}>
              <TextField
                variant="outlined"
                label="Type your caption"
                fullWidth
                multiline
                // sx={{ marginBottom: "30px" }}
                maxRows={4}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <Fab
                  color="primary"
                  aria-label="add"
                  size="medium"
                  // onClick={onImageSend}
                >
                  <SendIcon />
                </Fab>
              </Box>
            </Grid>
          </ChatInput>
        </div>
      </Grid>
    </>
  );
};

const getStatusIcon = (status) => {
  switch (status) {
    case "follow_up":
      return <Star style={{ color: filterColorObj?.[status] }} />;
      break;
    case "Unread":
      return <MailOutline style={{ color: filterColorObj[status] }} />;
      break;
    case "done":
      return <Done style={{ color: filterColorObj[status] }} />;
      break;

    default:
      return "";
      break;
  }
};

const shouldReverse = (chats) => {
  if (isArrayWithValues(chats)) {
    let length = chats.length;
    let startTime, endTime;
    for (let i = 0; i < length; i++) {
      let time = chats[i].time || chats[i].time;
      if (isNumber(time)) {
        if (Number(time).toString().length >= 13) time = time / 1000;
        startTime = time;
        break;
      }
    }
    for (let i = length - 1; i >= 0; i--) {
      let time = chats[i].time || chats[i].time;
      if (isNumber(time)) {
        if (time.toString().length >= 13) time = time / 1000;
        endTime = time;
        break;
      }
    }
    return startTime > endTime;
  }
};

const fetchProducts = async (search, setState, setLoading) => {
  // const controller = new AbortController();
  // const signal = controller.signal;

  const website = await getWebsite();
  const token = await getToken();
  if (setLoading) setLoading(true);
  let res = await axios({
    url: `${website}/wp-json/wc/v3/products`,
    params: {
      search,
      _fields: "id, name, sku, images,price, categories, permalink",
    },
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  if (res.data) setState && setState(res.data);
  if (setLoading) setLoading(false);
};

export const fetchChats = async (id, setState) => {
  console.log("Fetching chats", Date.now());
  if (!id) return;
  const website = await getWebsite();
  const token = await getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/wc/v3/customers/${id}?random=${Math.random()}`,
      // url: `${website}/wp-json/wc/v3/customers/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",

        // "Cache-Control": "no-cache",
        // Pragma: "no-cache",
        // Expires: "0",
      },
      method: "GET",
    })
      .then((res) => {
        res = res.data;
        if (res && isArrayWithValues(res.whatsapp_chat))
          setState(res.whatsapp_chat.reverse());
        // Snackbar.show({text: 'User info updated!'});
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        // Snackbar.show({text: 'Info not updated!'});
      })
      .finally(() => {});
};
// {!isIpad && false && (
//   <Stack
//     direction={"row"}
//     sx={{ backgroundColor: "head", px: 2 }}
//     alignItems="center"
//     justifyContent={"space-between"}
//   >
//     {/* {customerId && (
//   <IconButton onClick={() => setShowNotesDialog(true)}>
//     <StickyNote2 />
//   </IconButton>
// )} */}

//     <LoadingButton
//       loading={loadingFilter === "follow_up"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={
//           loadingFilter === "follow_up" && { display: "none" }
//         }
//         onClick={() => {
//           // dispatch(spamCustomer(customer, customer.status === "spam" ? "" : "Spam"))
//           onFilterAssigning(
//             customer.status === "follow_up" ? "" : "follow_up"
//           );
//           setLoadingFilter("follow_up");
//         }}
//       >
//         <Tooltip title={`Mark as Follow up`}>
//           <Star
//             style={{
//               color:
//                 customer.status === "follow_up"
//                   ? filterColorObj.follow_up
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     {/* <IconButton onClick={() => {
//   onFilterAssigning("follow_up")
// }}>
//   <Tooltip title="Mark as Follow up">
//     <Star style={{ color: customer.status === "follow_up" ? "#FFD700" : null }} />
//   </Tooltip>
// </IconButton> */}
//     <LoadingButton
//       loading={loadingFilter === "unread"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "unread" && { display: "none" }}
//         onClick={() => {
//           onFilterAssigning(
//             customer.status === "unread" ? "" : "unread"
//           );
//           setLoadingFilter("unread");
//         }}
//       >
//         <Tooltip title={`Mark as unread`}>
//           <MarkEmailRead
//             style={{
//               color:
//                 customer.status === "unread"
//                   ? filterColorObj.unread
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>

//     <LoadingButton
//       loading={loadingFilter === "done"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "done" && { display: "none" }}
//         onClick={() => {
//           onFilterAssigning(
//             customer.status === "done" ? "" : "done"
//           );
//           setLoadingFilter("done");
//         }}
//       >
//         <Tooltip title={`Mark as done`}>
//           <Done
//             style={{
//               color:
//                 customer.status === "done"
//                   ? filterColorObj.done
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     <LoadingButton
//       loading={loadingFilter === "delete"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "delete" && { display: "none" }}
//         onClick={() => {
//           setToggleDeleteDialog(true);
//         }}
//       >
//         <Tooltip title="Delete conversation">
//           <Delete />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     <LoadingButton
//       loading={loadingFilter === "span"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "span" && { display: "none" }}
//         onClick={() => {
//           // dispatch(spamCustomer(customer, customer.status === "spam" ? "" : "Spam"))
//           onFilterAssigning(
//             customer.status === "spam" ? "" : "spam"
//           );
//           setLoadingFilter("span");
//         }}
//       >
//         <Tooltip
//           title={`Move to ${
//             customer.status === "spam" ? "UnSpam" : "Spam"
//           }`}
//         >
//           <Report
//             style={{
//               color:
//                 customer.status === "spam"
//                   ? filterColorObj.spam
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     {/* <IconButton onClick={() => {
//   onFilterAssigning("done")
// }}>
//   <Tooltip title="Move to Done" >
//     <Done style={{ color: customer.status === "done" ? "#0000FF" : null }} />
//   </Tooltip>
// </IconButton> */}
//     {customer && (
//       <Button
//         onClick={() =>
//           props.setShowProfile &&
//           props.setShowProfile((state) => !state)
//         }
//       >
//         Show Profile
//       </Button>
//     )}
//   </Stack>
// )}

export const addConversation = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data, status } = await axios({
        url: `${website}/wp-json/store/v1/settings/platform_conversations_whatsapp`,
        headers: { Authorization: `Basic ${token}` },
      });
      if (
        isArrayWithValues(data?.data) &&
        data?.data?.find((i) => i.id == obj.id)
      ) {
        return {
          error: true,
          message: "Duplicate Customer found",
          code: "duplicate_customer",
        };
      }
      console.log(status, data);
      if (
        status === 200
        //  && isArray(data?.data)
      ) {
        try {
          let { data: postData } = await axios({
            url: `${website}/wp-json/store/v1/settings/platform_conversations_whatsapp`,
            headers: { Authorization: `Basic ${token}` },
            method: "POST",
            data: {
              type: "platform_conversations_whatsapp",
              data: [...(data?.data || [])].concat([obj]),
            },
          });
          console.log(postData, "post data chats");
          return postData.data;
        } catch (error) {
          console.log(getAxiosError(error));
          return false;
        }
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};

export function generateContactString(data) {
  if (data.length === 1) {
    const contact = data[0];
    return (
      contact.name.formatted_name ||
      `${contact.name.first_name} ${contact.name.last_name}`
    );
  }

  let formattedName = "";
  let otherContactsCount = 0;

  data.forEach((contact, index) => {
    if (index === data.length - 1) {
      formattedName =
        contact.name.formatted_name ||
        `${contact.name.first_name} ${contact.name.last_name}`;
    } else {
      otherContactsCount++;
    }
  });

  return `${formattedName} and ${otherContactsCount} other contact(s)`;
}
