import {
    Autocomplete,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SmsTemplateList } from "../../integrations/components/sms/Templates";
import {
    dialogStyle,
    fetchIPInfo,
    formatDate,
    getRandomString,
    getTemplateVariableObjFromCustomer,
    getToken,
    getWebsite,
    isObjWithValues,
    sendExotelSms,
    sendSmsMsg91,
    xml2Json,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "./Info";
import { PhoneSvg } from "../../../Svgs";
import { variables } from "../../components/TempleteList";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { getIp } from "../../../App";
import { UAParser } from "ua-parser-js";
import axios from "axios";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import { startCampaignFromSocket } from "./EmailTemplate";
function extractValuesMsg91(str) {
    var regex = /##(.*?)##/g;
    var matches = [];
    var match;

    while ((match = regex.exec(str))) {
        matches.push(match[1]);
    }

    return matches;
}
function extractValuesExotel(str) {
    var regex = /##(.*?)##/g;
    var matches = [];
    var match;

    while ((match = regex.exec(str))) {
        matches.push(match[1]);
    }

    return matches;
}
const SendSms = ({
    open,
    handleClose,
    customer,
    selected,
    heading,
    sendWithCampagin: sendWithCampaign,
    onEditForm,
    returnPayload,
}) => {
    const dispatch = useDispatch();
    const smsIntegration = useSelector((state) => state.settings.smsIntegration);

    const [selectedTemplate, setSelectedTemplate] = useState(false);
    const [sendingSms, setSendingSms] = useState(false);
    const [variableState, setVariableState] = useState({});
    const [templateString, setTemplateString] = useState("");
    const getTemplate = (template) => {
        let variablesArray =
            smsIntegration?.selected_channel === "msg91"
                ? extractValuesMsg91(template?.template_data)
                : extractValuesExotel(template?.body);
        console.log(template);
        let instalVariableObj = {};
        setTemplateString(template?.template_data || template?.body);
        console.log(variablesArray);
        for (const key of variablesArray || []) {
            instalVariableObj[key] = "";
            // setTemplateString(pre => pre.replace(/##(\w+)##/g, (match, varName) => {
            //     return <strong>{key}</strong>;
            // }))
        }
        console.log(template);
        // setTemplateString(template?.template_data);
        setVariableState(instalVariableObj);
        setSelectedTemplate({ ...template, id: template?.id || template?.dtl_template_id });
    };

    function replaceVar(varName, varValue) {
        setTemplateString((str) => {
            const regex = new RegExp(varName, "g");
            setTemplateString(str.replace(regex, varValue));
            return str.replace(regex, varValue);
        });
    }

    function replaceVariable({ template, key, value }) {
        return template.replace(`{${key}}`, value);
    }

    const onSendMessage = async () => {
        if (smsIntegration?.selected_channel === "msg91") {
            let varState = { ...variableState };
            let variableObj = getTemplateVariableObjFromCustomer(customer);

            for (const key of Object.keys(variableState || {})) {
                if (variableObj?.[variableState?.[key]]) {
                    varState[key] = variableObj?.[variableState?.[key]];
                    // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
                }
            }

            setSendingSms(true);

            let res = await sendSmsMsg91(
                {
                    template_id: selectedTemplate?.template_id,
                    sender: smsIntegration?.[smsIntegration?.selected_channel]?.sender_id,
                    mobiles: `${customer?.dialcode_mobile}${customer.billing?.phone}`,
                    ...varState,
                },
                smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
            );
            dispatch(
                setGlobalToast({
                    show: true,
                    message: `Message initiated!`,
                    severity: "success",
                })
            );
            setSendingSms(false);
            handleClose();
        }

        if (smsIntegration?.selected_channel === "exotel") {
            setSendingSms(true);
            // data array should be like  [ {To : "91XXXXXX" , Body : "Abc" }]
            let dataArray = [];

            let varState = { ...variableState };
            let variableObj = getTemplateVariableObjFromCustomer(customer);
            let Body = selectedTemplate?.body
            for (const key of Object.keys(variableState || {})) {
                if (variableObj?.[variableState?.[key]]) {
                    varState[key] = variableObj?.[variableState?.[key]];
                    Body = replaceVariable({ template: Body, key, value: variableState?.[key] })
                    // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
                }
            }
            //This is a test message powered by Exotel. Report abuse to +918088919888 -Exotel
            // for
            let response = await sendExotelSms({
                ...(smsIntegration?.exotel || {}),
                dataArray: [
                    {
                        To: `${customer?.dialcode_mobile}${customer.billing?.phone}`,
                        Body: Body,
                    },
                ],
            });
            setSendingSms(false);
            let parser = new DOMParser();

            console.log(xml2Json(parser.parseFromString(response?.error?.data, 'text/xml')));

            let errorString = xml2Json(parser.parseFromString(response?.error?.data, 'text/xml'))?.TwilioResponse?.RestException?.Message?.["#text"]

            if (response?.error) dispatch(
                setGlobalToast({
                    show: true,
                    message: `${errorString}`,
                    severity: "error",
                })
            );
            else {

                dispatch(
                    setGlobalToast({
                        show: true,
                        message: `Message initiated!`,
                        severity: "success",
                    })
                );
                setSendingSms(false);
                handleClose();
            }
        }
    };

    const sendCampaign = async () => {
        let website = getWebsite();
        let token = getToken();

        if (website && token) {
            setSendingSms(true);
            let time = Date.now();
            let id = `${time}_${getRandomString(5)}`;
            let historyObj = {
                id,
                campaign_info: {
                    selected_ids: selected?.map((obj) => ({ id: obj.id })) || [],
                    history_obj_id: id,
                    template_id: selectedTemplate?.template_id,
                    template_name: selectedTemplate?.template_name,
                    channel: smsIntegration?.selected_channel,
                    // channel: msg91,
                    campaign_type: "sms",
                    template: {
                        variables: variableState,
                    },
                },
                is_campaign: false,
                start_time: time,
                ip: await getIp(),
                ua: new UAParser().getUA(),
            };

            if (!historyObj.name)
                historyObj.name = formatDate(new Date(time), "dd:mm:yyyy hh:mm am/pm");
            let location = await fetchIPInfo(historyObj.ip);
            historyObj.location = location;

            // return;
            let res = await axios({
                url: `${website}/wp-json/store/v1/settings/campaign_history`,
                headers: {
                    Authorization: `Basic ${token}`,
                },
                method: "PUT",
                data: {
                    setting: "campaign_history",
                    data: {
                        [id]: historyObj,
                    },
                },
            });
            if (res?.data?.success) {
                dispatch({
                    type: EDIT_CHATS,
                    payload: {
                        campaignHistory: res?.data?.data,
                    },
                });
                dispatch(
                    setGlobalToast({
                        show: true,
                        severity: "success",
                        message: "Bulk SMS Started successfully!",
                    })
                );
                setSendingSms(false);
                handleClose();
                startCampaignFromSocket({ id, website });
            }
        }

        setSendingSms(false);
        handleClose();
    };

    const onReturnPayload = () => {
        for (const key of Object.keys(variableState || {})) {
            replaceVar(key, variableState?.[key]);
        }

        onEditForm({
            template_id: selectedTemplate?.template_id,
            template_name: selectedTemplate?.template_name,
            channel: "msg91",
            campaign_type: "sms",
            template: {
                variables: variableState,
            },
            templateString,
            sender_id: smsIntegration?.[smsIntegration?.selected_channel]?.sender_id,
        });
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ ...dialogStyle }}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {isObjWithValues(selectedTemplate) && (
                    <IconButton onClick={() => setSelectedTemplate(true)}>
                        <ArrowBack />
                    </IconButton>
                )}
                {heading || "Send SMS"}
            </DialogTitle>
            <DialogContent>
                {isObjWithValues(selectedTemplate) ? (
                    <Box>
                        <Grid container spacing={15}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={4}>
                                    {Object.keys(variableState)?.map((val) => (
                                        <Grid item xs={12} sm={6} md={6} key={val}>
                                            <Label>{val}</Label>
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                // value={headerVariable}
                                                // onChange={(e, a) => onheadingchange(e, a)}
                                                inputValue={variableState[val] || ""}
                                                // onInputChange={(e, a) => onheadingchange(e, a)}
                                                onInputChange={(e, value) => {
                                                    setVariableState((pre) => ({
                                                        ...pre,
                                                        [val]: value,
                                                    }));
                                                    // replaceVar(
                                                    //     variableState[val]
                                                    //         ? variableState[val]
                                                    //         : `##${val}##`,
                                                    //     value
                                                    // );
                                                }}
                                                options={variables.map((option) => option.label)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                    // label="freeSolo"

                                                    // placeholder="Enter content for {{1}}"
                                                    // error={headerError}
                                                    />
                                                )}
                                            />
                                            {/* <TextField
                                                size="small"
                                                value={variableState[val] || ""}
                                                onChange={(e) => {
                                                    setVariableState((pre) => ({
                                                        ...pre,
                                                        [val]: e.target.value,
                                                    }));
                                                    replaceVar(
                                                        variableState[val]
                                                            ? variableState[val]
                                                            : `##${val}##`,
                                                        e.target.value
                                                    );
                                                }}
                                                fullWidth
                                            /> */}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{ position: "relative", overflow: "hidden" }}
                            >
                                <PhoneSvg height="60vh" />
                                <Card
                                    sx={{
                                        position: "absolute",
                                        top: "60%",
                                        width: "76%",
                                        right: "25px",
                                        padding: "10px",
                                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                        borderRadius: "18px",
                                    }}
                                >
                                    <Stack direction={"row"} justifyContent="space-between">
                                        {/* <Typography>{formData.title}</Typography> */}
                                        <Typography variant="h6">
                                            JW-
                                            {
                                                smsIntegration?.[smsIntegration?.selected_channel]
                                                    ?.sender_id
                                            }
                                        </Typography>
                                        <Typography
                                            textAlign={"end"}
                                            sx={{ justifyItems: "flex-end" }}
                                        >
                                            now
                                        </Typography>
                                    </Stack>
                                    <Stack gap={1}>
                                        <Typography>{templateString}</Typography>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <SmsTemplateList onSelectTemplate={getTemplate} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {isObjWithValues(selectedTemplate) &&
                    (returnPayload ? (
                        <Button variant="contained" onClick={onReturnPayload}>
                            Select
                        </Button>
                    ) : (
                        <LoadingButton
                            loading={sendingSms}
                            variant="contained"
                            onClick={() =>
                                sendWithCampaign ? sendCampaign() : onSendMessage()
                            }
                            autoFocus
                        >
                            Send
                        </LoadingButton>
                    ))}
            </DialogActions>
        </Dialog>
    );
};

export default SendSms;
