import { onAddFallback } from "../../utils/FallbackNode";
import * as actions from "../../../../../../../redux/actions/flowsActions";

export const createFallbackNode = async ({
  dispatch,
  nodes,
  nodeId,
  btnsObj,
}) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  let currCount = currNode?.userConnected;

  const type = currNode?.type;

  const axis = {
    x: currNode?.position.x + 240,
    y: currNode?.position.y,
  };

  if (currCount === 0) {
    axis.y = currNode.position.y;
  } else if (currCount === 1) {
    axis.y = currNode?.position?.y + 70;
  } else if (currCount === 2) {
    axis.y = currNode?.position?.y - 90;
  } else {
    axis.y = currNode?.position?.y - 85 * (currCount - 1);
  }

  onAddFallback(dispatch, axis, nodeId, btnsObj);
  let updateCount = currNode?.userConnected + 1;

  dispatch({
    type: actions.UPDATE_USER_CONNECTED,
    payload: { id: nodeId, count: updateCount },
  });
};
