import { Box, Typography, Avatar } from "@mui/material";
import logo from "../assets/jwero-bg-removed.png";

const footer = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f7f7f5",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    overflow: "hidden",
    padding: "7px 0px 7px 0px",
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "10px",
  },
  logo: {
    width: 16,
    height: 16,
    position: "relative",
    top: "1px",
  },
};

const Footer = () => {
  return (
    <Box sx={footer.container}>
      <Typography variant="span" sx={footer.label}>
        Powered by
      </Typography>
      <Avatar alt="jwero ai logo" src={logo} sx={footer.logo} />
      <Typography variant="span" sx={footer.label}>
        Jwero AI
      </Typography>
    </Box>
  );
};

export default Footer;
