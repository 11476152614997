import { ArrowBack, Cancel, Delete, Edit } from "@material-ui/icons";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  formatDate,
  getAxiosError,
  getMedia,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../../helper";
import {
  addBloginRedux,
  addCategoryinRedux,
  deleteBlogCategory,
  deleteBlogOnRedux,
  getBlogs,
  getBlogsCategories,
  replaceBloginRedux,
} from "../../../redux/actions/pagesActions";
import styled from "styled-components";
import ProductMediaLibrary from "../../components/ProductMediaLibrary";
import { FileUpload } from "@mui/icons-material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allReduxBlogs = useSelector((state) => state.pages.allBlogs);
  const fetchingReduxBlogs = useSelector((state) => state.pages.fetchingBlogs);
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const location = useLocation();
  const [allBlogs, setAllBlogs] = useState([]);
  const [openCategoeryForm, setOpenCategoeryForm] = useState(false);

  useEffect(() => {
    if (!isArrayWithValues(allReduxBlogs)) dispatch(getBlogs());
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(allReduxBlogs)) setAllBlogs(allReduxBlogs);
  }, [allReduxBlogs]);
  let { hash } = location;

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{ margin: "10px", mr: 5 }}
        alignItems="center"
      >
        <Typography variant="h3">Blogs</Typography>
        {hash === "" ? (
          <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(`/frontend#add`)}
            >
              Add New Blog
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpenCategoeryForm(true)}
            >
              Add New Category
            </Button>
          </Stack>
        ) : null}
      </Stack>
      {!fetchingReduxBlogs ? (
        <Box>
          {hash.includes("edit") || hash.includes("add") ? (
            <BlogForm blogsCategories={blogsCategories} />
          ) : (
            <AllBlogs
              allBlogs={allBlogs}
              fetchingReduxBlogs={fetchingReduxBlogs}
            />
          )}
        </Box>
      ) : (
        <Stack
          sx={{ height: "calc(100vh - 200px)", width: "100%" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fetching blogs ...
          </Typography>
          <LinearProgress sx={{ width: "50%" }} />
        </Stack>
      )}
      <CreateCategoryPopup
        open={openCategoeryForm}
        handleClose={() => setOpenCategoeryForm(false)}
      />
    </Box>
  );
};

export default Blogs;

const AllBlogs = ({ allBlogs, fetchingReduxBlogs }) => {
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(allBlogs)) setBlogs(allBlogs);
  }, [allBlogs]);

  const [selectedTab, setSelectedTab] = useState("all");
  const onChangeCategoery = (id) => {
    if (!isArrayWithValues(allBlogs)) return;
    if (id === "all") setBlogs(allBlogs);
    else {
      let filterAll = allBlogs.filter((obj) => obj?.categories?.includes(id));
      setBlogs(filterAll);
    }
    setSelectedTab(id);
  };
  return (
    <Box sx={{ padding: "20px" }}>
      {!fetchingReduxBlogs && (
        <Stack sx={{ mb: 2, mt: 2 }}>
          {/* <Divider /> */}
          <Stack direction={"row"} sx={{ gap: "7px", mb: 2 }}>
            <Button
              variant={"text"}
              // startIcon={b.icon}
              sx={{
                padding: "6px 20px",
                backgroundColor:
                  selectedTab === "all" ? "#e6e6e6" : "transparent",
                color: "#000",

                "&:hover": {
                  backgroundColor:
                    selectedTab === "all" ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => onChangeCategoery("all")}
            >
              <Typography>All</Typography>
            </Button>
            {blogsCategories.map((b) => (
              <Button
                variant={"text"}
                // startIcon={b.icon}
                sx={{
                  padding: "6px 20px",
                  backgroundColor:
                    selectedTab === b.id ? "#e6e6e6" : "transparent",
                  color: "#000",

                  "&:hover": {
                    backgroundColor:
                      selectedTab === b.id ? "#e6e6e6" : "transparent",
                  },
                }}
                onClick={() => onChangeCategoery(b.id)}
              >
                <Typography>{b.name}</Typography>
              </Button>
            ))}
          </Stack>
          <Divider />
        </Stack>
      )}
      <Grid
        container
        spacing={4}
        sx={{ width: "100%", height: "100%", marginBottom: "60px" }}
      >
        {fetchingReduxBlogs ? (
          <>
            <Stack
              sx={{ height: "100%", width: "100%" }}
              justifyContent="center"
              alignItems={"center"}
            >
              <LinearProgress sx={{ width: "50%" }} />
            </Stack>
          </>
        ) : null}
        {blogs.map((obj) => (
          <Grid item xs={isMobile ? 12 : 6} md={6} lg={4}>
            <BlogCard blog={obj} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const BlogCard = ({ blog }) => {
  let {
    date,
    link,
    date_gmt,
    title,
    content,
    excerpt,
    featured_media,
    id,
    status,
  } = blog;
  let contentBody = content?.rendered || "";
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(async () => {
    let img = "";
    console.log(featured_media);
    setLoadingImage(true);
    if (featured_media && blog?.source_url) setImage(blog?.source_url);
    if (featured_media && !blog?.source_url) {
      img = await getMedia(featured_media);
      dispatch(replaceBloginRedux({ ...blog, source_url: img?.source_url }));
      setImage(img?.source_url);
    }
    setLoadingImage(false);
  }, [blog]);

  const onDeleteBlog = async () => {
    console.log(id);
    let website = getWebsite();
    let token = getToken();
    try {
      const res = await axios({
        url: `${website}/wp-json/wp/v2/posts/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },
      });
      if (res.data?.deleted) {
        dispatch(deleteBlogOnRedux(id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog Deleted!",
            severity: "success",
          })
        );
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  const onStatusChnage = async (e) => {
    let website = getWebsite();
    let token = getToken();
    dispatch(replaceBloginRedux({ ...blog, status: e ? "publish" : "darft" }));
    dispatch(
      setGlobalToast({
        show: true,
        message: "Blog Update successfully!",
        severity: "success",
      })
    );
    let res = await axios({
      url: `${website}/wp-json/wp/v2/posts/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: {
        ...blog,
        status: e ? "publish" : "darft",
      },
    });
    console.log(res);
    if (res.data?.id) {
    } else {
      dispatch(
        replaceBloginRedux({ ...blog, status: !e ? "publish" : "darft" })
      );
      dispatch(
        setGlobalToast({
          show: true,
          message: "Blog Update Fail!",
          severity: "success",
        })
      );
    }
  };

  return (
    <Card className="shadow_card">
      {loadingImage ? (
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ height: "250px", width: "100%" }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <img
          src={image}
          style={{ height: "250px", objectFit: "cover", width: "100%" }}
        />
      )}

      <Stack sx={{ padding: "20px", paddingTop: "10px", paddingBottom: "5px" }}>
        <Typography variant="h6">{title?.rendered}</Typography>
        <Box>
          <Typography sx={{ marginTop: "7px", color: "#808080" }}>
            {contentBody.replace(/<\/?[^>]+(>|$)/g, "").length > 80
              ? `${contentBody.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 80)}...`
              : contentBody.replace(/<\/?[^>]+(>|$)/g, "")}
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography>
            {formatDate(new Date(date_gmt), "relative_date")}
          </Typography>
          <Stack direction={"row"} alignItems="center">
            <Switch
              onChange={(e) => onStatusChnage(e)}
              checked={status === "publish"}
              style={
                status === "publish"
                  ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {}
              }
            />
            <IconButton>
              <Edit
                onClick={() => {
                  navigate(`/frontend#edit${id}`);
                }}
              />
            </IconButton>
            <IconButton onClick={onDeleteBlog}>
              <Delete />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

const BlogForm = () => {
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const allReduxBlogs = useSelector((state) => state.pages.allBlogs) || [];
  const theme = useTheme()
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { hash } = location;
  const [allBlogs, setAllBlogs] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [imageObj, setImageObj] = useState({});
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(() => {
    if (isArrayWithValues(allReduxBlogs)) setAllBlogs(allReduxBlogs);
  }, [allReduxBlogs]);
  useEffect(() => {
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(blogsCategories)) setAllCategories(blogsCategories);
  }, [blogsCategories]);

  const defaultState = {
    title: "",
    content: "",
    categories: [],
    featured_media: "",
  };
  const [formData, setFormData] = useState(defaultState);
  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const setImage = (arr) => {
    console.log(arr);
    setImageObj(arr[0]);
    toggleMediaLibrary(false);
    onFormEdit({ featured_media: arr[0]?.id });
  };
  const onSubmit = async () => {
    let website = getWebsite();
    let token = getToken();
    console.log(formData);
    // if(formData)
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/wp/v2/posts${formData.id ? `/${formData.id}` : ""
        }`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: formData,
    });
    console.log(res);
    if (res.data?.id) {
      if (formData.id) {
        dispatch(
          replaceBloginRedux({ ...res.data, source_url: imageObj?.source_url })
        );
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog Update successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          addBloginRedux({ ...res.data, source_url: imageObj?.source_url })
        );
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog created successfully!",
            severity: "success",
          })
        );
      }

      navigate("/frontend");
    }
    setLoading(false);
  };

  const onCheckBoxChange = (id) => {
    let arr = formData.categories;
    if (arr.includes(id)) arr = arr.filter((e) => e !== id);
    else arr.push(id);
    onFormEdit({ categories: arr });
  };
  useEffect(async () => {
    if (hash.includes("edit") && isArrayWithValues(allBlogs)) {
      let idNumber = hash.slice(5);
      console.log(idNumber);
      let obj = allBlogs.find((o) => Number(o.id) === Number(idNumber));
      console.log(obj);
      // let {  id } = obj;
      setFormData({
        title: obj?.title?.rendered || "",
        content: obj?.content?.rendered || "",
        categories: obj?.categories || "",
        featured_media: obj?.featured_media || "",
        id: obj?.id,
      });
      let img = {};
      if (obj?.featured_media) img = await getMedia(obj?.featured_media);
      setImageObj(img);
    }
  }, [hash, allBlogs]);

  return (
    <Box sx={{ padding: "10px 20px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{ mb: 3 }}
        alignItems="center"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
          <IconButton
            onClick={() => {
              navigate("/frontend");
            }}
          >
            <ArrowBack />{" "}
          </IconButton>
          <Typography variant="h5">
            {formData.id ? "Update" : "Add"} Blog
          </Typography>
        </Stack>
        <LoadingButton loading={loading} onClick={onSubmit} variant="contained">
          {formData.id ? "Update" : "Save"}
        </LoadingButton>
      </Stack>
      <Grid container spacing={5} direction={isIpad ? "column-reverse" : "row"}>
        <Grid item xs={12} lg={8}>
          <Card className="shadow_card" sx={{ padding: "20px" }}>
            <Stack>
              <Stack sx={{ mb: 4 }}>
                <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                  Title
                </Typography>
                <TextField
                  size="small"
                  value={formData?.title}
                  onChange={(e) => onFormEdit({ title: e.target.value })}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                  Description
                </Typography>
                <QuillWrapper>
                  <ReactQuill
                    style={{ borderRadius: "8px" }}
                    theme="snow"
                    value={formData?.content}
                    onChange={(e) => onFormEdit({ content: e })}
                    placeholder="Type something.."
                  // onChange={(e) => onEditProduct({ description: e })}
                  />
                </QuillWrapper>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4} sx={isIpad ? { display: "flex", gap: "20px" } : {}}>
          <Card className="shadow_card" sx={{ padding: "20px" }}>
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Image
            </Typography>
            {isObjWithValues(imageObj) ? (
              <Box
                sx={{ position: "relative", maxHeight: "160px", width: "100%" }}
              >
                <img
                  src={imageObj?.source_url}
                  style={{
                    maxHeight: "160px",
                    width: "100%",
                    objectFit: "cover",
                    textAlign: "center",
                    borderRadius: "8px",
                    border: "0.5px solid #ccc",
                  }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    right: "-12px",
                    top: "-12px",
                    zIndex: 2,
                    overflow: "hidden",
                  }}
                  onClick={() => setImageObj({})}
                  aria-label="delete"
                >
                  <Cancel
                    size="small"
                    color="primary"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              <Card
                onClick={() => toggleMediaLibrary(true)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <CardContent
                  fullWidth
                  id="outlined"
                  style={{
                    // borderWidth: 2,
                    // borderStyle: "dashed",
                    // borderColor: "#bababa",
                    height: "160px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  hoverTitle=""
                >
                  <Stack
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    {loadingImage ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {" "}
                        <FileUpload
                          color="rgba(0,0,0,0.3)"
                          style={{
                            height: "40px",
                            width: "40px",
                            // backgroundColor: "rgba(0,0,0,0.3)",
                            // position: "absolute",
                            // top: "50%",
                            // left: "50%",
                            // transform: "translate(-50%, -50%)",
                          }}
                        />
                        <Typography>Image </Typography>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Card>
          <Card className="shadow_card" sx={isIpad ? { padding: "20px", } : { padding: "20px", mt: 7 }}>
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Blogs Category
            </Typography>
            {isArrayWithValues(allCategories) ? (
              <>
                <FormGroup>
                  {allCategories.map((obj) => (
                    <FormControlLabel
                      sx={{ "MuiFormControlLabel-label": { width: "100%" } }}
                      control={
                        <Checkbox
                          checked={formData.categories.includes(obj.id)}
                          onChange={() => onCheckBoxChange(obj.id)}
                        />
                      }
                      label={
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                        >
                          <Typography variant="subtitle2">
                            {" "}
                            {obj.name}
                          </Typography>{" "}
                          <IconButton
                            onClick={() => dispatch(deleteBlogCategory(obj))}
                            size="small"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Stack>
                      }
                    />
                  ))}
                </FormGroup>
              </>
            ) : null}
          </Card>
        </Grid>
      </Grid>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={toggleMediaLibrary}
        setParentImages={setImage}
        // id={"mobile_1"}
        multiple={false}
      // selectedImages={images}
      />
    </Box>
  );
};

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: calc(100vh - 390px);,
    border-radius: 6px;
  }
`;

const CreateCategoryPopup = ({ open, handleClose }) => {
  let website = getWebsite();
  let token = getToken();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    console.log(formData);
    // if(formData)
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/wp/v2/categories`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: formData,
    });
    console.log(res);
    if (res.data?.id) {
      dispatch(addCategoryinRedux(res.data));
      dispatch(
        setGlobalToast({
          show: true,
          message: "Category created successfully!",
          severity: "success",
        })
      );
      handleClose();
    }
    setLoading(false);
  };
  const globalPopupStyle = useSelector(state => state.helper.globalPopupStyle) || {}
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={dialogStyle}
      fullWidth
      maxWidth="sm"
      {...globalPopupStyle}
    >
      <DialogTitle>Add New Category </DialogTitle>
      <DialogContent>
        <Stack sx={{ mb: 3, mt: 3 }}>
          <Typography variant="subtitle2" sx={{ fontSize: "15px" }}>
            Category Name
          </Typography>
          <TextField
            size="small"
            placeholder="Name"
            onChange={(e) =>
              setFormData((pre) => ({ ...pre, name: e.target.value }))
            }
            value={formData.name}
          />
        </Stack>
        <Stack sx={{ mb: 3, mt: 1 }}>
          <Typography variant="subtitle2" sx={{ fontSize: "15px" }}>
            Description
          </Typography>
          <TextField
            size="small"
            multiline
            rows={3}
            placeholder="Description"
            onChange={(e) =>
              setFormData((pre) => ({ ...pre, description: e.target.value }))
            }
            value={formData.description}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={onSubmit}>
          Save
        </LoadingButton>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
