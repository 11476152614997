export const updateNodePositions = ({ nodes, edges, startId, updateXBy }) => {
  const initialStartEdge = edges.find((edge) => edge.source === startId)?.source;
  if(!initialStartEdge){
      return nodes
  }
  const edgesToTraverse = [initialStartEdge];
  const nodesToUpdateId = [];

  while (edgesToTraverse.length > 0) {
    const currentNodeId = edgesToTraverse.shift();
    const edgesToUpdate = edges.filter((edge) => edge.source === currentNodeId);
    const edgesToUpdateTarget = edgesToUpdate.map((edge) => edge.target);
    nodesToUpdateId.push(...edgesToUpdateTarget);
    edgesToTraverse.push(...edgesToUpdateTarget);
  }

  const updateNodes = nodes.map((node) => {
    if (nodesToUpdateId.includes(node.id)) {
      return {
        ...node,
        position: {
          ...node.position,
          x: node.position.x + updateXBy,
        },
      };
    }
    return node;
  });

  return updateNodes;
};
