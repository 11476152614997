import { useEffect, useRef, useState } from "react";
import { Panel } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { savingData } from "../helper/SaveElements";
import { Box, Button, IconButton, Alert } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Chatbot from "../chatbot/index";
import TuneIcon from "@mui/icons-material/Tune";
import { updateNodesWithRef } from "../utils/addReference";
import ModeSwitch from "../helper/ModeSwitch";
import BoltIcon from "@mui/icons-material/Bolt";
import PluginPopover from "../helper/PluginPopver";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
// import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import { panelStyles } from "../styles/panelStyles";
import ChatbotSetting from "../helper/chatbot-setting";
import { LoadingButton } from "@mui/lab";
import { EDIT_ELEMENT_IN_REDUX } from "../../../../../redux/actions/flowsActions";
import * as actions from "../../../../../redux/actions/flowsActions";
import CustomTooltip from "./CustomTooltip";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { backendApiCall } from "../../../../../helper";

const styles = panelStyles;

const PanelComponent = ({ id }) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const title = useSelector((state) => state.flow?.title);
  const flowChannels = useSelector((state) => state.flow?.flowChannels);
  const flow_details = useSelector((state) => state.flow?.flow_details);
  const chabotMinimized = useSelector((state) => state.flow?.isMinimized);
  const actionsMenu = useSelector((state) => state.flow?.actionsMenu);
  const [showSuccess, setShowSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionsAnchor, setActionsAnchor] = useState(null);
  const [chatbot, setChatbot] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [saving, setSaving] = useState(false);

  const pluginRef = useRef();

  useEffect(() => {
    pluginRef?.current?.setPreForm(flowChannels);
  }, [flow_details]);

  const onSave = async () => {
    try {
      setChatbot(false);
      const data = {
        ...(id ? flow_details : {}),
        title: title,
        nodes: nodes,
        edges: edges,
        channels: flowChannels,
        type: "chatbot",
        // theme_mode: mode,
      };
      data.nodes = updateNodesWithRef(nodes, edges);
      setSaving(true);
      if (!id) {
        await backendApiCall({
          endpoint: `flow/`,
          method: "POST",
          data: data,
        });
      } else {
        await backendApiCall({
          endpoint: `flow/${id}`,
          method: "PUT",
          data: data,
        });
      }
      console.log("Clicked");
      dispatch(
        setGlobalToast({
          show: true,
          message: "Flow has been saved",
          severity: "success",
        })
      );
      // savingData(nodes, edges);
      // setShowSuccess(true);
      // setTimeout(() => {
      //   setShowSuccess(false);
      // }, 1000);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("SAVED");
      setSaving(false);
    }
  };

  const handleChatbot = async () => {
    const data = updateNodesWithRef(nodes, edges);
    setData(data);
    dispatch({ type: EDIT_ELEMENT_IN_REDUX, payload: { isMinimized: false } });
  };

  const handlePlugin = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSetting = () => {
    setOpenSetting(true);
  };

  const openActions = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { actionsMenu: !actionsMenu },
    });
  };

  return (
    <Panel position="top-right">
      {/* {showSuccess && (
        <Alert sx={styles.successMessage} severity="success">
          Saved Succesfully !!
        </Alert>
      )} */}
      <Box sx={styles.panelBox}>
        <CustomTooltip title="Mode" position="top" fontBold={600}>
          <IconButton
            sx={{
              ...styles.pluginIconBox,
              backgroundColor: "white",
              padding: 0,
            }}
          >
            <ModeSwitch />
          </IconButton>
        </CustomTooltip>
        {/* <CustomTooltip title={"Actions"} position={"top"} fontBold={600}>
          <IconButton
            sx={{ ...styles.pluginIconBox, backgroundColor: "white" }}
            onClick={openActions}
          >
            <ElectricBoltIcon
              sx={{
                ...styles.pluginIcon,
                backgroundColor: "white",
                color: "black",
                fontSize: "30px",
                // transform: "rotate(15deg)",
              }}
            />
          </IconButton>
        </CustomTooltip> */}
        <CustomTooltip title={"Integrations"} position={"top"} fontBold={600}>
          <IconButton onClick={handlePlugin} sx={styles.pluginIconBox}>
            <TuneIcon sx={{ ...styles.pluginIcon, color: "black" }} />
          </IconButton>
        </CustomTooltip>
        <PluginPopover
          ref={pluginRef}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        {/* <IconButton onClick={handleSetting} sx={{...styles.pluginIconBox, marginRight: "17px"}}>
          <SettingsIcon sx={styles.pluginIcon} />
        </IconButton> */}
        {/* <ChatbotSetting
          openSetting={openSetting}
          setOpenSetting={setOpenSetting}
        /> */}
        <Button
          sx={styles.testBotButton}
          variant="outlined"
          onClick={handleChatbot}
          // size="small"
        >
          Test your Bot
        </Button>
        <LoadingButton
          // sx={styles.saveButton}
          sx={{
            ml: 2,
            textTransform: "none",
            borderRadius: "7px",
            border: "none",
          }}
          variant="contained"
          startIcon={<SendIcon />}
          onClick={onSave}
          // size="small"
          loading={saving}
        >
          Save
        </LoadingButton>
      </Box>

      {!chabotMinimized && <Chatbot data={data} />}
    </Panel>
  );
};

export default PanelComponent;
