import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";

import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Stack,
  Autocomplete as MuiAutocomplete,
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  ArrowBackIosNew,
  ContactSupportOutlined,
  DeviceUnknown,
  Menu as MenuIcon,
  SupportAgent,
  TrafficRounded,
} from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { useTheme } from "@mui/system";
import {
  getAdminName,
  getSafe,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  // website,
} from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { StorefrontOutlined } from "@material-ui/icons";
import HelpPopper from "../../pages/components/HelpPopper";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Autocomplete = styled(MuiAutocomplete)`
  border-radius: 2px;
  background-color: ${(props) => darken(0.05, props.theme.header.background)};
  display: none;
  position: relative;
  width: 100%;
  min-width: 250px;

  &:hover {
    background-color: ${(props) => darken(0.08, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

// position: absolute;
const SearchIconWrapper = styled.div`
  width: 22px;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

// const Support = () => {
//   return (
//     <div
//       className="zsiq_floatmain zsiq_theme1 siq_bR"
//       data-id="zsalesiq"
//       style="visibility: hidden; display: block;"
//     >
//       <div
//         id="zsiq_float"
//         className="zsiq_float "
//         style="font-family: inherit; display: block;"
//       >
//         <div className="zsiq_flt_rel">
//           <em id="zsiq_agtpic" className="zsiq_user siqicon siqico-chat"></em>
//           <div id="titlediv" className="zsiq_cnt zsiqhide_tip">
//             <div id="zsiq_maintitle" className="zsiq_ellips" title="Hey there!">
//               Hey there!
//             </div>
//             <p
//               id="zsiq_byline"
//               className="zsiq_ellips"
//               title="How may I help you today?"
//             >
//               How may I help you today?
//             </p>
//             <em className="siqico-close"></em>
//           </div>
//           <em
//             id="zsiq_unreadcnt"
//             className="zsiq_unrdcnt"
//             style="display: none;"
//           ></em>
//           <em
//             id="zsiq_avcall"
//             className="zsiqmin_unrdcnt zsiq_unrdcnt siqico-mincall"
//             style="display: none;"
//           ></em>
//         </div>
//       </div>
//     </div>
//   );
// };
const Navbar = ({ onDrawerToggle }) => {
  const [notificationsLoading, setNotificationsLoading] = React.useState(false);
  // const [allNotifications, setAllNotifications] = React.useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [showChats, setShowChats] = React.useState(true);
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const allNotifications = useSelector((state) => state.settings.notifications);
  const store_plan = useSelector((state) => state.user.store_plan);
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const [showStoreIcon, setShowStoreIcon] = React.useState(true);
  const [onlyCRMProduct, setOnlyCRMProduct] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    setOnlyCRMProduct(
      user_products?.length === 1 && user_products.includes("crm")
    );
  }, [user_products]);

  const openVar = Boolean(anchorEl);
  const id = openVar ? "simple-popover" : undefined;
  ////////
  // React.useEffect(async () => {
  //   let array = await getNotifications(setNotificationsLoading);
  //   if (isArrayWithValues(array)) {
  //     setAllNotifications(array);
  //     dispatch({ type: "SET_NOTIFICATIONS", payload: array.reverse() });
  //   }
  // }, []);

  React.useEffect(() => {
    if (isArrayWithValues(store_plan?.user_products)) {
      let { user_products } = store_plan;
      setShowStoreIcon(
        !(
          user_products?.length == 1 &&
          (user_products?.includes("chats") ||
            user_products?.includes("catalog") ||
            user_products?.includes("crm")) ||
          user_products?.includes("whatsapp")
        )
      );
      let ignored = ["e-commerce", "ornate", "crm"];
      if (user_products?.length == 1 && ignored.includes(user_products?.[0]))
        setShowChats(false);
    }
  }, [store_plan]);

  const onGotoWebsite = () => {
    let website = getWebsite();
    if (website) {
      window.open(website);
    }
  };
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            {!isIpad ? (
              <Grid item>
                <img
                  src="/static/img/brands/jwero_logo.png"
                  height={"50px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                />
              </Grid>
            ) : (
              <Grid item>
                <img
                  src="/static/img/brands/jwero_logo.png"
                  height={"30px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard")}
                />
              </Grid>
            )}
            <Grid item>
              <Stack direction={"row"} alignItems="center" ml={2}>
                {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
                </Search> */}
                {onlyChats ? null : onlyCRMProduct ? (
                  <SearchCustomers />
                ) : (
                  <SearchProducts />
                )}
              </Stack>
            </Grid>
            <Grid item xs />
            <Grid item>
              {store_plan?.user_products?.some((i) =>
                ["chats", "catalogs"].includes(i)
              ) || isDev() ? (
                <Tooltip title="Help">
                  <IconButton
                    onClick={handleClick}
                  // onClick={() =>
                  //   window.$zoho.salesiq.floatwindow.visible("show")
                  // }
                  // className=""
                  >
                    <ContactSupportOutlined />
                  </IconButton>
                </Tooltip>
              ) : null}
              {/* {onlyChats ? null : (
              )} */}
              <Tooltip title="Raise a Ticket ">
                <IconButton
                  onClick={() => {
                    console.log(window.$zoho);
                    window.$zoho.salesiq.floatwindow.visible("show");
                  }}
                // className=""
                >
                  <SupportAgent />
                </IconButton>
              </Tooltip>
              {showStoreIcon && !isPhone ? (
                <Tooltip title="Go to website">
                  <IconButton
                    color="inherit"
                    onClick={onGotoWebsite}
                    size="large"
                  >
                    <StorefrontOutlined />
                  </IconButton>
                </Tooltip>
              ) : null}
              {/* <Support /> */}
              {showChats && !isPhone && (
                <NavbarMessagesDropdown notifications={allNotifications} />
              )}
              <NavbarNotificationsDropdown notifications={allNotifications} />
              {/* <NavbarLanguagesDropdown /> */}
              <NavbarUserDropdown showStoreIcon={showStoreIcon} />

              {!isIpad ? (
                <span style={{ fontWeight: "700", fontSize: "15px" }}>
                  {getAdminName()}
                </span>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <HelpPopper
        id={id}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={openVar}
      />
    </React.Fragment>
  );
};

export default withTheme(Navbar);

const SearchProducts = () => {
  const [search, setSearch] = React.useState("");

  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [searchedData, setSearchedData] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const headerBackground = theme.header.background || "#fff";

  React.useEffect(() => {
    if (search.length >= 3) onSearch();
  }, [search]);
  React.useEffect(() => {
    if (isObjWithValues(value)) {
      let obj = { ...value };
      delete obj.label;
      delete obj.value;
      console.log(obj);
      dispatch({ type: "SET_PRODUCT_DATA", payload: { editProduct: obj } });
      setTimeout(() => navigate(`/products/edit/${value.id}`));
      // navigate(`/products/add`);
    }
  }, [value]);

  const onSearch = () => {
    getSearchData(search, setSearchedData, setSearchLoading);
    // setParams({ ...params, search });
    // dispatch(fetchAllProducts({ ...params, search }, true));
  };
  const onItemClick = (obj) => {
    if (!isObjWithValues(obj)) obj = {};
    setValue(obj);
  };

  return (
    <Autocomplete
      disablePortal
      // id="combo-box-demo"
      options={searchedData || []}
      sx={{
        width: 500,
        backgroundColor: "green",
        borderBottom: 0,
        "&:hover": {
          backgroundColor: "blue",
        },
        pl: 2,
      }}
      loading={searchLoading}
      loadingText="Loading products"
      noOptionsText="Enter 3 or more character"
      getOptionLabel={(option) => option.label ?? ""}
      // value={value ?? { label: "", value: "" }}
      onChange={(e, value) => {
        onItemClick(value);
      }}
      // inputValue={inputValue || ""}
      // onInputChange={(event, newInputValue) => {
      //   setInputValue(newInputValue);
      // }}
      renderInput={(params) => {
        return (
          // <Input placeholder={t("Search")} {...params} />

          <TextField
            {...params}
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                // sx={{ color: darken(0.05, headerBackground) }}
                >
                  <SearchIconWrapper>
                    <SearchIcon color={darken(0.2, headerBackground)} />
                  </SearchIconWrapper>
                  {/* <Search>
          </Search> */}
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            placeholder="Search products"
            // label="Search"
            fullWidth
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <SearchIconWrapper>
            //         <SearchIcon />
            //       </SearchIconWrapper>
            //     </InputAdornment>
            //   ),
            // }}

            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            // onKeyDown={onKeyDown}
            // size="small"
            variant="standard"
            sx={{
              py: 1,
              backgroundColor: "transparent",
              // backgroundColor: darken(0.01, headerBackground),
              "&:hover": darken(0.05, headerBackground),
              border: "0px solid transparent",
              "&:before": {
                borderBottom: 0,
              },
              // ml: 2,
            }}
          />
        );
      }}
    />
  );
};
const SearchCustomers = () => {
  const [search, setSearch] = React.useState("");

  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [searchedData, setSearchedData] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const headerBackground = theme.header.background || "#fff";

  React.useEffect(() => {
    if (search.length >= 3) onSearch();
  }, [search]);
  React.useEffect(() => {
    if (isObjWithValues(value)) {
      let obj = { ...value };
      delete obj.label;
      delete obj.value;
      console.log(obj);
      // dispatch({ type: "SET_PRODUCT_DATA", payload: { editProduct: obj } });
      setTimeout(() => navigate(`/crm/${value.id}`));
      // navigate(`/products/add`);
    }
  }, [value]);

  const onSearch = () => {
    getSearchData(search, setSearchedData, setSearchLoading, true);
    // setParams({ ...params, search });
    // dispatch(fetchAllProducts({ ...params, search }, true));
  };
  const onItemClick = (obj) => {
    if (!isObjWithValues(obj)) obj = {};
    setValue(obj);
  };

  return (
    <Autocomplete
      disablePortal
      // id="combo-box-demo"
      options={searchedData || []}
      sx={{
        width: 500,
        backgroundColor: "green",
        borderBottom: 0,
        "&:hover": {
          backgroundColor: "blue",
        },
        pl: 2,
      }}
      loading={searchLoading}
      loadingText="Loading customers"
      noOptionsText="Enter 3 or more character"
      getOptionLabel={(option) => option.label ?? ""}
      // value={value ?? { label: "", value: "" }}
      onChange={(e, value) => {
        onItemClick(value);
      }}
      // inputValue={inputValue || ""}
      // onInputChange={(event, newInputValue) => {
      //   setInputValue(newInputValue);
      // }}
      renderInput={(params) => {
        return (
          // <Input placeholder={t("Search")} {...params} />

          <TextField
            {...params}
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                // sx={{ color: darken(0.05, headerBackground) }}
                >
                  <SearchIconWrapper>
                    <SearchIcon color={darken(0.2, headerBackground)} />
                  </SearchIconWrapper>
                  {/* <Search>
          </Search> */}
                </InputAdornment>
              ),
              endAdornment: null,
            }}
            placeholder="Search customers"
            // label="Search"
            fullWidth
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <SearchIconWrapper>
            //         <SearchIcon />
            //       </SearchIconWrapper>
            //     </InputAdornment>
            //   ),
            // }}

            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            // onKeyDown={onKeyDown}
            // size="small"
            variant="standard"
            sx={{
              py: 1,
              backgroundColor: "transparent",
              // backgroundColor: darken(0.01, headerBackground),
              "&:hover": darken(0.05, headerBackground),
              border: "0px solid transparent",
              "&:before": {
                borderBottom: 0,
              },
              // ml: 2,
            }}
          />
        );
      }}
    />
  );
};

const getNotifications = async (setLoading) => {
  let token = getToken();
  let website = getWebsite();
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: `Basic ${token}`,
  };

  let res = await fetch(`${website}/wp-json/store/v1/notifications`, {
    method: "GET",
    headers: headersList,
  })
    .then(function (response) {
      return response.text();
    })
    .then(function (data) {
      return data;
    })
    .catch((e) => "error");
  setLoading(false);
  try {
    res = JSON.parse(res);
  } catch {
    res = false;
  }

  // console.log(res);
  if (Array.isArray(res)) return res;
  // if (res.data) return res.data;

  // console.log(token);
  // let res = await axios({
  //   method: "GET",
  //   url: `${website}/wp-json/store/v1/notifications`,
  //   headers: {
  //     Authentication: `Basic ${token}`,
  //     // Accept: "*/*",
  //   },
  // });
  // setLoading(false);
  // if (res.data) return res.data;
};

const getSearchData = async (search, setState, setLoading, from_crm) => {
  setLoading && setLoading(true);
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    let res = await axios({
      url: `${website}/wp-json/wc/v3/${from_crm ? "customers" : "products"}`,
      params: { search },
      headers: {
        Authorization: `Basic ${token}`,
      },
      // signal,
    });
    setLoading && setLoading(false);
    console.log(res.data);
    if (isArrayWithValues(res.data)) {
      let { data } = res;

      if (from_crm)
        setState(
          data.map((i) => ({
            ...i,
            label: `${i.first_name || ""} ${i.last_name || ""}`,
            value: i.id,
          }))
        );
      else
        setState(
          data.map((i) => ({ ...i, label: i.name, value: i.sku || "NO_SKU" }))
        );
      return true;
    }
  }
  setLoading && setLoading(false);
};
