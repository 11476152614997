import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { columns, products_keys_mapping } from "../utils";

const ImportDialog = ({
  open,
  handleClose,
  onAddFile,
  heading,
  downloadCsv,
}) => {
  // const onDownloadTemplate = ({ data, fileName, fileType }) => {
  //   let header = {};
  //   columns?.forEach((obj) => {
  //     header[obj?.label] = "";
  //   });
  //   // Create a blob with the data we want to download as a file
  //   const blob = new Blob([data], { type: fileType });

  //   const a = document.createElement("a");
  //   a.download = fileName;
  //   a.href = window.URL.createObjectURL(blob);
  //   const clickEvt = new MouseEvent("click", {
  //     view: window,
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   a.dispatchEvent(clickEvt);
  //   a.remove();
  // };
  // const downloadCsv = () => {
  //   let arr = Object.keys(products_keys_mapping)?.map((key) => ({
  //     label: products_keys_mapping?.[key]?.column_name,
  //     value: key,
  //   }));
  //   onDownloadTemplate({
  //     data: [
  //       arr?.map((o) => o?.label)?.join(","),
  //       arr?.map((o) => "")?.join(","),
  //     ]?.join("\n"),
  //     fileName: "Jwero Products Sheet.csv",
  //     fileType: "text/csv",
  //   });
  // };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {/* Import product by CSV */}
        {heading}
      </DialogTitle>
      <DialogContent>
        <Typography>
          Download a{" "}
          {/* <Button onClick={downloadCsv}>sample CSV template</Button>{" "} */}
          <Link
            onClick={(e) => {
              e.preventDefault();
              downloadCsv();
            }}
          >
            sample CSV template
          </Link>{" "}
          to see an example of the format required
        </Typography>
        <Stack
          sx={{
            height: "100px",
            borderRadius: 2,
            backgroundColor: "ButtonHighlight",
            my: 2,
          }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() => onAddFile && onAddFile()}
        >
          <Button
            size="small"
            // onClick={() => onAddFile && onAddFile()}
            sx={{ boxShadow: 3 }}
          >
            Add file
          </Button>
        </Stack>
        <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
          {/* Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        {/* <Button onClick={handleClose} autoFocus>
          Import
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
