import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { smsStyles } from "./styles/smsStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import { fetchSmsTemplates } from "./helper/sms-tempaltes";
import CircularProgress from "@mui/material/CircularProgress";
import OptionsPopover from "./components/OptionsPopover";
import TemplatePreview from "./components/TemplatePreview";
// import BlockOptions from "./components/BlockOptions";

const styles = smsStyles;

const SidebarSms = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState(null);
  const [currentTemplateTitle, setCurrentTemplateTitle] = useState("");
  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openSmsSidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        fieldsData: data,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openSmsSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const setTemplatesInitially = async () => {
    const templates = await fetchSmsTemplates();
    setTemplates(templates);
    return true;
  };

  const handleSelectedOption = (currTemplateTitle) => {
    const selectedTemplate = templates.find(
      (template) => template.flow_name === currTemplateTitle
    );

    setCurrentTemplateTitle(currTemplateTitle);
    setCurrentSelectedTemplate(selectedTemplate);
    setOptionsAnchorEl(null);
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);

    const delay = () => new Promise((resolve) => setTimeout(resolve, 2000));

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.fieldsData) {
        setData(node?.fieldsData);
      } else {
        setFetchingTemplates(true);

        setTimeout(() => {
          if (setTemplatesInitially()) {
            setFetchingTemplates(false);
          }
        }, [2000]);
        setTemplatesInitially();
      }
    }
  }, [userId, nodes]);

  return (
    <Box sx={styles.customCard}>
      <form>
        <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <SmsIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Sms
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            value={title}
            required
          />
        </Box>
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Select SMS template
            </Typography>
            {fetchingTemplates ? (
              <CircularProgress />
            ) : (
              <TextField
                size="small"
                value={currentTemplateTitle || ""}
                placeholder="Select template"
                onClick={handleClickPopover}
                sx={{ marginBottom: "20px" }}
              />
            )}
          </Box>
          {currentTemplateTitle !== "" && (
            <TemplatePreview
              currentSelectedTemplate={currentSelectedTemplate}
            />
          )}
        </Box>
        <OptionsPopover
          templates={templates}
          optionsAnchorEl={optionsAnchorEl}
          setOptionsAnchorEl={setOptionsAnchorEl}
          handleSelectedOption={handleSelectedOption}
        />
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Box>
  );
};

export default SidebarSms;
