/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import phone from "phone";

import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Divider as MuiDivider,
  Link,
  Paper,
  useMediaQuery,
  Stack,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Skeleton,
  InputAdornment,
  CardContent,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Slide,
  CircularProgress,
  Tabs,
  Tab,
  Drawer,
  ListItemButton,
  Checkbox,
  LinearProgress,
  linearProgressClasses,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  ListItemAvatar,
  FormControlLabel,
  AvatarGroup,
  Radio,
  Collapse,
  TablePagination,
  DialogContentText,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";

import {
  backendApiCall,
  checkSamePlatform,
  dialogStyle,
  EmptyState,
  fetchIPInfo,
  formatDate,
  formatServerValue,
  formatWhatsappMessage,
  getAllConversations,
  getAxiosError,
  getChannelData,
  getDateString,
  getNameFromPlatform,
  getRandomString,
  getStoreId,
  getTemplateVariableObjFromCustomer,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  numberFromPhoneNumber,
  reduceLargeText,
  secondaryLabel,
  sendSingleTemplate,
  sleep,
  TabPanel,
  updateCustomer,
  validateNumber,
} from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchChatCustomers } from "../../redux/actions/crmActions";
import {
  InstagramSvg,
  Whatsapp,
  Messenger,
  ChatIllustration,
  NoChats,
  WhatsappSvg,
  EmptyChats,
  NoSheets,
  CrownSvg,
  WebchatSvg,
} from "../../Svgs";
import {
  ArrowBackIosNew,
  AssignmentIndOutlined,
  Campaign,
  Close,
  DescriptionOutlined,
  Favorite,
  FavoriteBorder,
  FileDownloadDoneOutlined,
  FilterAltOutlined,
  HeadsetMic,
  Launch,
  LocalOffer,
  LocalPhone,
  ManageAccountsOutlined,
  MarkEmailRead,
  MarkEmailReadOutlined,
  MarkEmailUnreadOutlined,
  Merge,
  NavigateNext,
  PeopleAltOutlined,
  Person,
  PersonAddAltOutlined,
  PlaylistAddCheck,
  PushPin,
  PushPinOutlined,
  Report,
  ReportOutlined,
  Search,
  Sync,
  // Tune,
} from "@mui/icons-material";
import {
  addMessageInRedux,
  assignBroadcast,
  bulkActionChats,
  deleteConversation,
  EDIT_CHATS,
  getConversations,
  putBroadcastList,
  putMergeProfiles,
  readMessage,
  updateChatCustomer,
  updateChatObject,
  updateChatUserInRedux,
} from "../../redux/actions/chatActions";
import { LoadingButton } from "@mui/lab";
// import AddCustomer from "../customers/AddCustomer";
import SelectCustomer from "../components/SelectCustomer";
import { CustomerLabelView } from "../crm/CRM";
import { Add, Delete, Edit } from "@material-ui/icons";
// import useAuth from "../../hooks/useAuth";
// import { AnalyticsChip } from "../dashboards/Default";
import asyncComponent from "../../components/Async";
// import { startTransition } from "react";
import TempleteList from "../components/TempleteList";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { StackRow } from "./components/helper";
import {
  addTeamMember,
  EDIT_SETTINGS,
} from "../../redux/actions/settingActions";
import BroadcastList from "./components/BroadcastList";
import MergeView from "./components/MergeView";
import MergeCreate from "./components/MergeCreate";
import ChatsFilters from "./components/ChatsFilters";
import ConnectChannel from "./components/ConnectChannel";
import DataNotFound from "../components/DataNotFound";
import {
  clearSpreadsheet,
  CRMSheetHistory,
  CRMSheetLoading,
  exportSpreadsheet,
  getSheetId,
  setSpreadsheetDropdowns,
} from "../customers/Customers";
import { getIp } from "../../App";
import { UAParser } from "ua-parser-js";
import { TaskRow } from ".";
import { CreateUserTask } from "./UserTasks";
import { channels } from "../customers/components/Communication";
import { CRMMenuIcon } from "../../Svgs";
import { check24HoursWindowClose } from "./components/ChatWindow";
import displayState from "../../validateProduct";
import AddChats from "./components/AddChats";
import { backendEndpoints, sendCampaign, settingApi } from "./apiFunctions";
import { createCustomerForCrm } from "../customers/helpers";
import Tags from "./components/Tags";
import JweroSyncHistory from "../products/components/JweroSyncHistory";
import { getChatsUserConversation } from "../../helper/api/chat_user";
// import { createCustomerForCrm } from "../customers/helpers";

export const filterColorObj = {
  spam: "#FF0000",
  done: "#60c361",
  unanswered: "#6495ed",
  unread: "#1cb2b7",
  follow_up: "#ffd700",
};
export const sentimentIcons = {
  positive: "😀",
  negative: "😡",
  Anger: "😠",
  Confusion: "😕",
  Excitement: "😄",
  Fear: "😨",
  Gratitude: "🙏",
  Joy: "😂",
  Love: "❤️",
  Sadness: "😢",
  Surprise: "😮",
  Neutral: "😐",

  Happiness: "😄",
  Sadness: "😔",
  Excitement: "😁",
  Frustration: "😤",
  Disappointment: "😥",
  Anger: "😡",
};

export const chatsLimit = 10000;

const Loader = () => {
  return (
    <Stack
      sx={{ width: "-webkit-fill-available", height: "70vh" }}
      justifyContent="center"
      alignItems={"center"}
    >
      <CircularProgress color="primary" disableShrink />
    </Stack>
  );
};

const ChatView = asyncComponent(
  () => import("./components/ChatWindow"),
  100,
  <Loader />
);

export const CRMfilters = [
  {
    label: "Mark as Follow up",
    removeLabel: "Remove Follow up",
    value: "follow_up",
    icon: (
      <MarkEmailReadOutlined
      // style={{ height: "10px", height: "10px", color: "#ffd700" }}
      />
    ),
    number: 0,
  },
  {
    label: "Mark as Unread",
    removeLabel: "Remove Unread",
    value: "unread",
    icon: (
      <MarkEmailUnreadOutlined
      // style={{ height: "10px", height: "10px", color: "#1cb2b7" }}
      />
    ),
  },
  // {
  //   label: "Unanswered",
  //   removeLabel: "Remove Unanswered",
  //   value: "unanswered",
  //   icon: (
  //     <Circle style={{ height: "10px", height: "10px", color: "#6495ed" }} />
  //   ),
  //   number: 0,
  // },
  {
    label: "Mark as Done",
    removeLabel: "Remove Done",
    value: "done",
    icon: (
      <FileDownloadDoneOutlined
      // style={{ height: "10px", height: "10px", color: "#60c361" }}
      />
    ),
    number: 0,
  },
  {
    label: "Mark as Spam",
    removeLabel: "Remove Spam",
    value: "spam",
    icon: (
      <ReportOutlined
      // style={{ height: "10px", height: "10px", color: "#FF0000" }}
      />
    ),
    number: 0,
  },
  // { label: "Assigned", value: "assigned", icon: <Circle style={{ height: "10px", height: "10px", color: "#ADD8E6" }} /> },
];
// const defaultParams = { page: 1, per_page: 100 };

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

// width: 100%;
const ChatContainer = styled(Grid)``;
// height: 81vh;
// margin-top: 14px;
// overflow: auto;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;
// overflow: auto;
// const ChatSidebar = asyncComponent(() => (<ChatSidebarGrid />), 100, <Loader />);

const ChatMain = styled(Grid)``;
// overflow: auto;

export const SmallAvatar = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.palette.background.paper}`,
  padding: 2,
  backgroundColor: `${theme.palette.background.paper}`,
  borderRadius: "50%",
}));
const logoStyle = {
  // width: 20,
  // height: 20,
  border: `2px solid #fff`,
  backgroundColor: `#fff`,
  borderRadius: "50%",
};
let perPage = 100;
const ChatWindow = forwardRef(
  (
    {
      defaultParams,
      selectedTab,
      setSelectedTab,
      unreadCount,
      params,
      setParams,
    },
    ref
  ) => {
    const [addFormView, setAddFormView] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [viewSearch, setViewSearch] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [searchTerm, setSearchTerm] = useState("");
    const [customerView, setCustomerView] = useState({});
    const [showProfile, setShowProfile] = useState(false);
    const [listTab, setListTab] = useState("all");
    const [filterView, setFilterView] = useState(false);
    const [filterObj, setFilterObj] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const filterOpen = Boolean(anchorEl);
    const [conversations, setConversations] = useState([]);
    const [showAction, setShowAction] = useState(false);
    const [showManage, setShowManage] = useState(false);
    const [showSync, setShowSync] = useState(false);
    const [isSocialMediaConnected, setIsSocialMediaConnected] = useState(false);
    const [bulkSelectedGroup, setBulkSelectedGroup] = useState([]);
    const [hasBoradCast, setHasBoradCast] = useState(false);
    const [anchorElAdd, setAnchorElAdd] = useState(null);
    const [showJweroSync, setShowJweroSync] = useState(false);

    const openAdd = Boolean(anchorElAdd);
    let searchTimeOut;

    const user_products = useSelector(
      (state) => state.user?.store_plan?.user_products
    );
    // const paramChatId = useSelector((state) => state.chats.paramChatId);
    const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
    const crmGrouping = useSelector((state) => state.customers.crmGrouping);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const chatsAccess = useSelector((state) => state.user.chatsAccess);
    const broadcastList = useSelector((state) => state.chats.broadcastList);
    const chatsGroupings = useSelector((state) => state.chats.chatsGroupings);
    const user_type = useSelector((state) => state.user.user_type);
    const user = useSelector((state) => state.user);
    const allSettingsFetched = useSelector(
      (state) => state.settings.allSettingsFetched
    );
    const metaIntegration = useSelector(
      (state) => state.settings.metaIntegration
    );
    const googleSheetIntegrated = Object.values(
      useSelector((state) => state.settings.googleIntegration) || {}
    )
      .map((o) => (isArrayWithValues(o?.connected) ? o.connected : []))
      ?.flat()
      ?.includes("google_sheets");
    const whatsappIntegrated = true;

    // const tour_history =useSelector
    // const { setSteps, setCurrentStep, setIsOpen } = useTour();
    // const [count, setCount] = useState({
    //   unread: 0
    // })

    const allReduxConversations = useSelector(
      (state) => state.chats.conversations
    );

    const conversationsFetched = useSelector(
      (state) => state.chats.conversationsFetched
    );
    const fetchingConversations = useSelector(
      (state) => state.chats.fetchingConversations
    );
    const noMoreConversations = useSelector(
      (state) => state.chats.noMoreConversations
    );
    const customerSearchTextfieldRef = useRef();

    useImperativeHandle(ref, () => {
      return {
        setContactView(contact) {
          console.log(contact, "received contact");
          setCustomerView(contact);
        },
        getContactView() {
          return customerView;
        },
      };
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      if (
        Array.isArray(allReduxConversations) &&
        allReduxConversations.length > 0
      ) {
        let currentPage = newPage + 1;
        // console.log(
        //   allReduxConversations.length / rowsPerPage <= currentPage,
        //   !fetchingConversations,
        //   !noMoreConversations,
        //   Array.isArray(allReduxConversations),
        //   allReduxConversations.length > 0
        // );
        if (
          allReduxConversations.length / rowsPerPage <= currentPage &&
          !fetchingConversations &&
          !noMoreConversations
        ) {
          setParams({ ...params, page: params.page + 1 });
        }
      }
    };
    let { hash } = location;
    useEffect(() => {
      if (hash && hash.includes("open")) {
        navigate("/chats");
        setShowJweroSync(true);
      }
    }, [hash]);

    const setupConversations = async ({ id }) => {
      let conversationsRes = await getChatsUserConversation({ id });
      if (conversationsRes?.success) {
        let conversation = conversationsRes?.data?.conversations;

        dispatch(
          updateChatUserInRedux({
            user: {
              id,
              conversation: conversation,
              conversations_fetched: true,
            },
          })
        );
      }
    };

    useEffect(() => {
      if (
        !customerView?.conversations_fetched &&
        !isArrayWithValues(customerView?.conversation) &&
        customerView?.id
      ) {
        setupConversations({ id: customerView?.id });
      }
    }, [customerView]);

    useEffect(() => {
      let { platform } = params;
      if (platform === "all_messages") platform = null;
      dispatch(getConversations({ ...params, platform }));

      return () => {};
    }, [params]);

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      // setPage(0);
    };

    useEffect(() => {
      setParams({
        platform: selectedTab,
        page: 1,
        per_page: perPage,
      });
    }, [selectedTab]);

    useEffect(() => {
      setListTab("all");
    }, [selectedTab]);

    // const openSync = () => {
    //   setShowSync(true);
    // };
    const handleCloseAdd = () => {
      console.log("close");
      setAnchorElAdd(null);
    };
    const closeSync = () => {
      setShowSync(false);
    };

    const onCustomerPress = useCallback((customerObj) => {
      setCustomerView(customerObj || {});
      customerObj?.id && navigate(`/chats/${customerObj?.id || ""}`);
    }, []);

    const customerPlaceholder = () => (
      <Stack spacing={1}>
        <div style={{ display: "flex", padding: "10px 0px 10px 20px" }}>
          <Skeleton variant="circular" width={40} height={40} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: 12 }}>
              {/* <Skeleton variant="text" style={{ width: "40vw" }} />
              <Skeleton variant="text" style={{ width: "45vw" }} /> */}
              <Skeleton
                variant="rectangle"
                style={{ width: "180px", height: 15, borderRadius: 8 }}
              />
              <Skeleton
                variant="rectangle"
                style={{
                  width: "150px",
                  height: 12,
                  marginTop: 10,
                  borderRadius: 8,
                }}
              />
            </div>
          </div>
        </div>
      </Stack>
    );
    const onKeyDown = (e) => {
      if (e.key === "Enter" && searchTerm) {
        setParams((pre) => ({
          ...pre,
          page: 1,
          per_page: perPage,
          search: searchTerm,
        }));
      }
    };
    const onSearch = (e) => {
      setSearchTerm(e.target.value || "");
      let value = e?.target?.value || e;
      if (page) setPage(0);
    };

    // const [filterValue, setFilterValue] = useState("all");

    const onMetaIntegrate = (isSocialMediaConnected) => {
      if (isSocialMediaConnected) navigate(`/integrations`);
      else setAddFormView(true);
      // setSteps(metaIntegrationSteps);
      // setCurrentStep(0);
      // dispatch(
      //   setHelperData({
      //     restrictedTour: [0, 1, 2, 4],
      //   })
      // );
      // setIsOpen(true);
    };
    useEffect(() => {
      // setUpConversations(allReduxConversations || []);
      if (isArray(allReduxConversations)) {
        setConversations(allReduxConversations);
      }
      if (isObjWithValues(customerView)) {
        let updatedCustomer = allReduxConversations?.find(
          (obj) => obj?.id === customerView?.id
        );
        // if (isObjWithValues(updatedCustomer)) {
        setCustomerView(updatedCustomer);
        // }
      }

      return () => {};
    }, [allReduxConversations]);

    const onChangeFilter = (newValue) => {
      setListTab(newValue);
      setPage(0);
      let statusArray = ["follow_up", "done", "spam"];
      let pagination = { per_page: perPage, page: 1 };
      if (newValue === "all")
        setParams((pre) => ({ ...pagination, platform: selectedTab }));
      else if (statusArray?.includes(newValue))
        setParams((pre) => ({
          ...pagination,
          status: newValue,
          platform: selectedTab,
        }));
      else if (newValue === "unread")
        setParams((pre) => ({
          ...pagination,
          unread: 1,
          platform: selectedTab,
        }));
    };

    const onAllSelectChange = () => {
      if (conversations.length === selectedCustomer.length)
        setSelectedCustomer([]);
      else setSelectedCustomer(conversations || []);
    };
    // const open = Boolean(anchorEl);
    // const id = open ? "simple-popover" : undefined;

    /// ipad bulk action
    const [anchorElBulk, setAnchorElBulk] = React.useState(null);

    // const handleClickBulk = (event) => {
    //   setAnchorElBulk(event.currentTarget);
    // };

    const handleCloseBulk = () => {
      setAnchorElBulk(null);
    };

    const filterSubmitHandler = () => {
      // if (isArrayWithValues(filterObj.status)){
      console.log(filterObj);
      if (
        isObjWithValues(filterObj) &&
        Object.values(filterObj || {})?.find((o) => {
          if (isArray(o))
            if (isArrayWithValues(o)) return o;
            else return false;

          if (o) return o;
        })
      ) {
        setParams((pre) => {
          pre = {
            platform: pre?.platform,
          };
          pre.page = 1;
          pre.per_page = perPage;
          if (isArrayWithValues(filterObj?.status))
            pre.status = filterObj?.status?.join(",");
          if (isArrayWithValues(filterObj?.contact_type))
            pre.contact_type = filterObj?.contact_type?.join(",");
          if (filterObj?.active_chats) pre.active_window = 1;
          return { ...pre };
        });
        let conversationsArray = [];
      } else {
        dispatch(getConversations({ ...params, page: params.page + 1 }));
      }

      setFilterView(false);

      // }
      // setConversations()
    };
    // const ChatViewMemo = useMemo(() => <ChatView
    //   setCustomerView={setCustomerView}
    //   customer={customerView}
    //   customerLoading={false}
    //   setShowProfile={setShowProfile}
    // />, [customerView])
    useEffect(() => {
      let isSocialMediaConnected = false;
      if (allSettingsFetched) {
        if (!metaIntegration) isSocialMediaConnected = false;
        else if (isObjWithValues(metaIntegration)) {
          let socialMedia = ["facebook", "instagram", "whatsapp"];
          if (
            Object.values(metaIntegration).find((i) =>
              i?.connected?.find((i) => socialMedia.includes(i))
            )
          )
            isSocialMediaConnected = false;
        } else isSocialMediaConnected = true;
        setIsSocialMediaConnected(isSocialMediaConnected);
      }
    }, [metaIntegration, allSettingsFetched]);
    const [chatSheetLoading, setChatsSheetLoading] = useState(false);
    const [syncProgress, setSyncProgress] = useState(0);
    const openBulk = Boolean(anchorElBulk);
    const idBulk = openBulk ? "simple-popover" : undefined;
    // if (!chatsAccess && !isAdmin)
    //   return (
    //     <Box>
    //       <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
    //         <ChatsSvg />
    //       </Stack>
    //       <Stack direction={"row"} justifyContent={"center"}>
    //         {/* <SettingsIllustration /> */}
    //         <Typography variant="h6" mt={3} sx={{ color: "text.secondary" }}>
    //           You don't have access to change or view Chats!
    //         </Typography>
    //       </Stack>
    //     </Box>
    //   );
    const closeShowJweroSync = () => {
      setShowJweroSync(false);
    };
    return (
      <Box sx={{}}>
        <JweroSyncHistory
          type={"chats"}
          showSync={showJweroSync}
          closeSync={closeShowJweroSync}
        />
        {whatsappIntegrated ? (
          <SyncDialog
            googleSheetIntegrated={googleSheetIntegrated}
            showSync={showSync}
            setShowSync={setShowSync}
            closeSync={closeSync}
            broadcastList={broadcastList}
            chatSheetLoading={chatSheetLoading}
            setChatsSheetLoading={setChatsSheetLoading}
            setSyncProgress={setSyncProgress}
          />
        ) : (
          <Dialog
            open={showSync}
            onClose={() => {
              closeSync();
            }}
            maxWidth="xs"
            sx={{ ...dialogStyle }}
            fullWidth
            // fullScreen={isPhone}
            keepMounted={false}
          >
            <DialogTitle>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="h6" id="alert-dialog-title"></Typography>
                <IconButton onClick={() => closeSync()}>
                  <Close />
                </IconButton>
              </Stack>
            </DialogTitle>

            <Box sx={{ p: 3, pt: 0, width: "80%", margin: "auto" }}>
              <DataNotFound
                sx={{ marginTop: "0" }}
                illustration={<WhatsappSvg height={100} width={100} />}
                subtitle={
                  <>
                    You forgot whatsapp integration?
                    <br />
                    Lets do it now and rocknroll!
                  </>
                  // "?? "
                }
                // title={"WhatsApp is not integrated!"}
                createButton={
                  <Button
                    variant="contained"
                    onClick={() => navigate("/integrations#Meta")}
                    // size={"small"}
                    sx={{ mt: 3 }}
                  >
                    Integrate WhatsApp
                  </Button>
                }
              />
            </Box>
          </Dialog>
        )}

        <Dialog
          open={chatSheetLoading}
          // onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <CRMSheetLoading
            open={chatSheetLoading}
            syncProgress={syncProgress}
            heading={"Chats Sheet Sync"}
          />
        </Dialog>
        <Drawer
          sx={{
            "	.MuiDrawer-paperAnchorBottom": {
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            },
            "	.MuiDrawer-paperAnchorRight": {
              height: "calc(100svh - 20px)",
              margin: "10px",
              borderRadius: 4,
            },
          }}
          anchor={isMobile ? "bottom" : "right"}
          open={filterView}
          onClose={() => {
            setFilterView(false);
          }}
        >
          <Card
            className="scrollbar-hidden"
            sx={{
              width: isMobile ? "100%" : "350px",
              height: isMobile ? "70svh" : "100%",
              padding: "20px",
              paddingBottom: isMobile ? 0 : "20px",
              overflow: "hidden",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Chats Filters</Typography>
              <Close
                fontSize="small"
                onClick={() => {
                  setFilterView(false);
                }}
              />
            </Stack>
            <Box sx={{ height: "10px" }} />
            <Divider />
            <Stack
              sx={{
                height: isMobile ? "calc(70svh - 93px)" : "calc(90svh - 53px)",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              <ChatsFilters
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                reduxTeamMembers={reduxTeamMembers}
              />
            </Stack>

            <Button
              variant="contained"
              sx={{ padding: "3px 16px" }}
              onClick={filterSubmitHandler}
              // loading={true}
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                setFilterObj({});
                // filterSubmitHandler();
                setFilterView(false);
                setParams({
                  page: 1,
                  per_page: perPage,
                  platform: selectedTab,
                });
              }}
            >
              Reset
            </Button>
          </Card>
        </Drawer>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={showProfile ? 9 : 12}>
            <ChatContainer
              style={
                isIpad
                  ? {
                      height: isObjWithValues(customerView)
                        ? "98svh"
                        : "calc(100svh - 68px)",
                    }
                  : {}
              }
              container
              component={Card}
            >
              <Grid
                item
                xs={0}
                lg={
                  displayState({
                    key: "chatsChannelBar",
                    user_products: user_products,
                  })
                    ? 0.5
                    : 0
                }
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  overflowY: "scroll",
                  display: isIpad && "none",
                  // minWidth: "48px"
                }}
                className="scrollbar-hidden"
              >
                {displayState({
                  key: "chatsChannelBar",
                  user_products: user_products,
                }) && (
                  <Tabs
                    orientation="vertical"
                    value={selectedTab}
                    onChange={(e, newValue) => {
                      setSelectedTab(newValue);
                    }}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      ".MuiTabs-indicator": {
                        left: 0,
                        width: "3px",
                      },
                    }}
                  >
                    <Tab
                      label={"All"}
                      sx={{ padding: 0, minWidth: "" }}
                      value={"all_messages"}

                      // icon={<ChatsPrimary />}
                    />
                    <Tab
                      className="himanshu-tab"
                      icon={
                        <div>
                          <Badge
                            badgeContent={unreadCount?.["whatsapp"]}
                            color="primary"
                          >
                            <Whatsapp />
                          </Badge>
                        </div>
                      }
                      sx={{ padding: 0, minWidth: "" }}
                      value="whatsapp"
                    />
                    <Tab
                      icon={
                        <div>
                          <Badge
                            badgeContent={unreadCount?.["facebook"]}
                            color="primary"
                          >
                            <Messenger />
                          </Badge>
                        </div>
                      }
                      sx={{ padding: 0, minWidth: "" }}
                      value="facebook"
                    />

                    <Tab
                      icon={
                        <div>
                          <Badge
                            badgeContent={unreadCount?.["instagram"]}
                            color="primary"
                          >
                            <InstagramSvg />
                          </Badge>
                        </div>
                      }
                      sx={{ padding: 0, minWidth: "" }}
                      value="instagram"
                    />
                    <Tab
                      icon={
                        <div>
                          <Badge
                            badgeContent={unreadCount?.["webchat"]}
                            color="primary"
                          >
                            <WebchatSvg />
                          </Badge>
                        </div>
                      }
                      sx={{ padding: 0, minWidth: "" }}
                      value="webchat"
                    />
                  </Tabs>
                )}
              </Grid>

              <ChatSidebar
                item
                xs={12}
                md={4}
                lg={3}
                // sx={
                //   isIpad && !isObjWithValues(customerView)
                //     ? { display: showAction ? "block" : "none" }
                //     : {}
                // }
                sx={
                  isIpad
                    ? {
                        display: isObjWithValues(customerView)
                          ? "none"
                          : showAction
                          ? "none"
                          : "block",
                      }
                    : {}
                }
              >
                <Grid item xs={12}>
                  <Box>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent="space-between"
                      // margin="auto"
                      gap={"10px"}
                      sx={{ px: 3, py: 2, height: "68px" }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{ width: "100%" }}
                        justifyContent="space-around"
                      >
                        <Stack
                          justifyContent={"center"}
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                          rowGap={"4px"}
                          onClick={() => {
                            setViewSearch(true);
                            customerSearchTextfieldRef?.current?.focus();
                          }}
                        >
                          <Search
                            color="action"
                            style={{ cursor: "pointer" }}
                          />
                          <Typography color={"text.secondary"}>
                            Search
                          </Typography>
                        </Stack>

                        {false && (
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{ cursor: "pointer" }}
                            rowGap={"4px"}
                            onClick={() => setShowSync(true)}
                            // onClick={() => navigate(`/chats/sheet`)}
                          >
                            <Sync
                              color="action"
                              style={{ cursor: "pointer" }}
                            />
                            <Typography color={"text.secondary"}>
                              Sync
                            </Typography>
                          </Stack>
                        )}

                        <Stack
                          justifyContent={"center"}
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                          rowGap={"4px"}
                          // onClick={() => setAddFormView(true)}
                          onClick={(e) => {
                            setAnchorElAdd(e.currentTarget);
                          }}
                        >
                          <PersonAddAltOutlined
                            color="action"
                            style={{ cursor: "pointer" }}
                          />
                          <Typography color={"text.secondary"}>Add</Typography>
                        </Stack>
                        <Menu
                          id={
                            Boolean(anchorElAdd) ? "simple-popover" : undefined
                          }
                          onBlur={handleCloseAdd}
                          anchorEl={anchorElAdd}
                          open={Boolean(anchorElAdd)}
                          onClose={() => handleCloseAdd()}
                          onClick={() => handleCloseAdd()}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleCloseAdd();
                              setAddFormView(true);
                            }}
                          >
                            Add contact
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate(`/chats/sheet`);
                              setTimeout(() => handleCloseAdd(), 100);
                            }}
                          >
                            Add Bulk Contacts
                          </MenuItem>
                        </Menu>

                        <Stack
                          justifyContent={"center"}
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                          rowGap={"4px"}
                          onClick={() => setFilterView(true)}
                          // onClick={() => {
                          //   seViewSearch(true);
                          //   customerSearchTextfieldRef?.current?.focus();
                          // }}
                        >
                          <Badge
                            color="secondary"
                            variant="dot"
                            invisible={
                              !Object.values(filterObj || {})?.find((o) => {
                                if (isArray(o))
                                  if (isArrayWithValues(o)) return o;
                                  else return false;

                                if (o) return o;
                              })
                              //  !isObjWithValues(filterObj)
                            }
                          >
                            <FilterAltOutlined
                              color="action"
                              style={{ cursor: "pointer" }}
                            />
                          </Badge>
                          <Typography color={"text.secondary"}>
                            Filter
                          </Typography>
                        </Stack>

                        <Stack
                          justifyContent={"center"}
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                          rowGap={"4px"}
                          onClick={() => {
                            setShowManage((pre) => {
                              if (pre) setSelectedCustomer([]);

                              return !pre;
                            });
                          }}
                        >
                          <Badge
                            color="secondary"
                            variant="dot"
                            invisible={!showManage}
                          >
                            <ManageAccountsOutlined
                              color="action"
                              style={{ cursor: "pointer" }}
                            />
                          </Badge>

                          <Typography color={"text.secondary"}>
                            Action
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider />
                    <Collapse in={viewSearch}>
                      <TextField
                        size="small"
                        fullWidth
                        variant="standard"
                        placeholder="Search Customer"
                        onChange={onSearch}
                        onKeyDown={onKeyDown}
                        value={searchTerm}
                        ref={customerSearchTextfieldRef}
                        // sx={{ px: 3, py: 2, height: "68px" }}
                        sx={{
                          // transform: viewSearch ? "scaleY(1)" : "scaleY(0)",
                          width: viewSearch ? "100%" : "0%",
                          // height: viewSearch ? "100%" : "0%",
                          overflow: "hidden",
                          // display: viewSearch ? "block" : "none",
                          transition: `all ${viewSearch ? "0.3s" : "0s"}`,
                          px: 3,
                          py: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{}}>
                              <Search />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setSearchTerm("");
                                setViewSearch(false);
                                // setConversations(channelWiseChats);
                                setParams((pre) => ({
                                  ...pre,
                                  page: 1,
                                  per_page: perPage,
                                  search: null,
                                }));
                                setPage(0);
                              }}
                            >
                              <Close />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Collapse>
                  </Box>
                </Grid>

                <Tabs
                  onChange={(e, newValue) => {
                    setBulkSelectedGroup([]);
                    onChangeFilter(newValue);
                  }}
                  value={listTab}
                  sx={{
                    boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                    borderBottom: "divider",
                  }}
                  scrollButtons={false}
                  variant="scrollable"
                >
                  <Tab label="All" value={"all"} />
                  <Tab label={"Unread"} value={"unread"} />
                  <Tab label="Follow up" value={"follow_up"} />
                  <Tab label="Broadcast list" value={"groups"} />
                  {false && <Tab label="Merged" value={"merged"} />}
                  {false && <Tab label="Favourite" value={"favourite"} />}

                  {/* <Tab label="Done" value={"done"} />
                <Tab label="Spam" value={"spam"} /> */}
                </Tabs>
                {fetchingConversations ? (
                  <Box
                    style={{
                      height: "14px",
                      display: "block",
                    }}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    {new Array(40).fill(1).map((i, index) => {
                      return (
                        <div key={index} style={{ alignItems: "center" }}>
                          {customerPlaceholder()}
                        </div>
                      );
                    })}
                  </Box>
                ) : null}
                {/* {fetchingConversations ? null : ( */}
                <Box
                  sx={{
                    display: fetchingConversations ? "none" : "block",
                  }}
                >
                  <TabPanel index={listTab} value={"merged"}>
                    <MergeView
                      allReduxConversations={allReduxConversations}
                      isIpad={isIpad}
                      chatsGroupings={chatsGroupings}
                      onCustomerPress={onCustomerPress}
                    />
                  </TabPanel>
                  <TabPanel index={listTab} value={"groups"}>
                    <GroupTab
                      isIpad={isIpad}
                      crmGrouping={Object.values(broadcastList || {})}
                      // allReduxConversations={channelWiseChats}
                      allReduxConversations={conversations}
                      setSelectedCustomer={setSelectedCustomer}
                      selectedCustomer={selectedCustomer}
                      onCustomerPress={onCustomerPress}
                      showManage={showManage}
                      setShowManage={setShowManage}
                      customerView={customerView}
                      bulkSelectedGroup={bulkSelectedGroup}
                      setBulkSelectedGroup={setBulkSelectedGroup}
                    />
                  </TabPanel>
                  <List
                    hidden={listTab === "groups" || listTab === "merged"}
                    disablePadding
                    style={{
                      maxHeight: "100%",
                      height: isIpad ? "calc(100svh - 228px)" : "66svh",
                      overflowY: "scroll",
                      maxWidth: "100%",
                      position: !isArrayWithValues(conversations)
                        ? "relative"
                        : "",
                      // gridAutoRows:{}
                    }}
                    className="scrollbar-hidden"
                  >
                    {isArrayWithValues(conversations) && (
                      <ListItem
                        // key={id}
                        button
                        sx={{
                          // padding: "8px 14px",
                          flex: 1,
                          borderLeftWidth: 3,
                          borderLeftStyle: "solid",
                          borderLeftColor: "transparent",
                          transition: "borderLeftWidth 1s",
                          borderBottom: "0.3px solid  #e8e8e8",
                          position: "sticky",
                          top: "0px",
                          zIndex: 12,
                          backgroundColor: "background.paper",
                          ":hover": {
                            backgroundColor: "background.paper",
                          },
                          padding: "0px",
                        }}
                      >
                        <TablePagination
                          sx={{
                            // maxWidth: "fit-content",
                            marginLeft: "auto",
                            ".MuiTablePagination-selectLabel": {
                              display: "none",
                            },
                            ".MuiTablePagination-toolbar": {
                              padding: 0,
                            },
                            ".MuiInputBase-root": {
                              display: "none",
                            },
                          }}
                          className="scrollbar-hidden"
                          component="div"
                          count={conversations?.length || 0}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          size="small"
                          // labelRowsPerPage={"per page"}
                        />
                      </ListItem>
                    )}
                    {/* <Stack>

                  {Object.values(filterObj || {}).flat()?.map(key =>)}
                  </Stack> */}
                    {/* { && ( */}
                    <Collapse
                      in={isArrayWithValues(selectedCustomer)}
                      style={{ transformOrigin: "0 0 0" }}
                    >
                      <ListItem
                        // key={id}
                        button
                        sx={{
                          // padding: "8px 14px",
                          flex: 1,
                          borderLeftWidth: 3,
                          borderLeftStyle: "solid",
                          borderLeftColor: "transparent",
                          transition: "borderLeftWidth 1s",
                          borderBottom: "0.3px solid  #e8e8e8",
                          position: "sticky",
                          zIndex: 10,
                          backgroundColor: "background.paper",
                          top: "0px",
                          ":hover": {
                            backgroundColor: "background.paper",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                          sx={{ width: "100%" }}
                        >
                          <Typography>
                            {selectedCustomer?.length} contacts selected
                          </Typography>
                          <Button
                            color="error"
                            endIcon={<Close />}
                            onClick={() => {
                              setShowManage(false);
                              setSelectedCustomer([]);
                            }}
                          >
                            Clear
                          </Button>
                        </Stack>
                      </ListItem>
                    </Collapse>
                    {/* )} */}

                    {/* <> */}
                    <Box
                      style={{
                        position: "sticky",
                        top: "53px",
                        zIndex: 15,
                        backgroundColor: "background.paper",
                      }}
                    >
                      <Collapse
                        in={
                          showManage && isArrayWithValues(allReduxConversations)
                        }
                      >
                        <ListItem
                          // key={id}
                          button
                          sx={{
                            // padding: "8px 14px",
                            flex: 1,
                            borderLeftWidth: 3,
                            borderLeftStyle: "solid",
                            borderLeftColor: "transparent",
                            transition: "borderLeftWidth 1s",
                            borderBottom: "0.3px solid  #e8e8e8",
                            backgroundColor: "background.paper",
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              size="small"
                              onChange={onAllSelectChange}
                              checked={
                                conversations.length ===
                                selectedCustomer?.length
                              }
                            />
                          </ListItemIcon>
                          <Stack
                            direction={"row"}
                            sx={{ width: "100%" }}
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h6">
                              {conversations.length === selectedCustomer?.length
                                ? "Unselect all"
                                : "Select all"}
                            </Typography>
                            {/* {selectedCustomer.length ? (
                            <Typography variant="h6">
                              ({selectedCustomer.length})
                            </Typography>
                          ) : null} */}
                          </Stack>
                        </ListItem>
                      </Collapse>
                    </Box>
                    {/* </> */}

                    {isArrayWithValues(conversations) &&
                    !fetchingConversations ? (
                      <>
                        {conversations
                          .sort(
                            (a, b) =>
                              validateNumber(b.pinned) -
                              validateNumber(a.pinned)
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((customer, index) => (
                            <ChatListRow
                              key={index}
                              // key={customer.id}
                              onCustomerPress={onCustomerPress}
                              customer={customer}
                              customerView={customerView}
                              showManage={showManage}
                              setSelectedCustomer={setSelectedCustomer}
                              selectedCustomer={selectedCustomer}
                            />
                          ))}
                      </>
                    ) : null}
                    {!isArrayWithValues(conversations) &&
                      !fetchingConversations && (
                        <Box
                          sx={{
                            textAlign: "center",
                            marginTop: "25svh",
                            marginBottom: "23svh",
                          }}
                        >
                          <Person
                            style={{ width: 50, height: 50, fill: "#d1d1d1" }}
                          />
                          <Typography
                            sx={{
                              ...secondaryLabel,
                              mt: 3,
                              fill: "#92929299",
                              fontWeight: "600",
                            }}
                          >
                            No Customers
                          </Typography>
                        </Box>
                      )}
                  </List>
                  {isArrayWithValues(allReduxConversations) && isIpad && (
                    <Tabs
                      // orientation="vertical"
                      value={selectedTab}
                      onChange={(e, newValue) => setSelectedTab(newValue)}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      sx={{
                        // position: !isArrayWithValues(conversations) ? "absolute" : "sticky",
                        position: "absolute",
                        bottom: "1px",
                        backgroundColor: "background.paper",
                        // width: "100%",
                        zIndex: 1,

                        // ".MuiTabs-indicator": {
                        //   left: 0,
                        //   width: "3px",
                        // },
                      }}
                    >
                      <Tab
                        label={"All"}
                        // sx={{ padding: 0, minWidth: "" }}
                        value={"all_messages"}

                        // icon={<ChatsPrimary />}
                      />
                      <Tab
                        className="himanshu-tab"
                        icon={
                          <div>
                            <Badge
                              badgeContent={unreadCount?.["whatsapp"]}
                              color="primary"
                            >
                              <Whatsapp />
                            </Badge>
                          </div>
                        }
                        // sx={{ padding: 0, minWidth: "" }}
                        value="whatsapp"
                      />
                      <Tab
                        icon={
                          <div>
                            <Badge
                              badgeContent={unreadCount?.["facebook"]}
                              color="primary"
                            >
                              <Messenger />
                            </Badge>
                          </div>
                        }
                        // sx={{ padding: 0, minWidth: "" }}
                        value="facebook"
                      />
                      {(getWebsite()?.includes("tiarabytj") || true) && (
                        <Tab
                          icon={
                            <div>
                              <Badge
                                badgeContent={unreadCount?.["instagram"]}
                                color="primary"
                              >
                                <InstagramSvg />
                              </Badge>
                            </div>
                          }
                          // sx={{ padding: 0, minWidth: "" }}
                          value="instagram"
                        />
                      )}
                    </Tabs>
                  )}
                  {isArrayWithValues(selectedCustomer) && isIpad && (
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      aria-label="add"
                      sx={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        zIndex: 3,
                      }}
                      onClick={() => setShowAction(true)}
                    >
                      {/* <NavigateNext sx={{ mr: 1 }} /> */}
                      Action
                    </Fab>
                  )}
                </Box>
                {/* )} */}
              </ChatSidebar>

              <ChatMain
                item
                xs={12}
                // md={8}
                // lg={9}
                // md={7.5}
                // lg={8.5}
                md={
                  displayState({
                    key: "chatsChannelBar",
                    user_products: user_products,
                  })
                    ? 7.5
                    : 7.9
                }
                lg={
                  displayState({
                    key: "chatsChannelBar",
                    user_products: user_products,
                  })
                    ? 8.5
                    : 8.95
                }
                sx={
                  // isIpad && isArrayWithValues(selectedCustomer) && showAction && {
                  //   display  :
                  // }
                  isIpad && !isObjWithValues(customerView)
                    ? { display: showAction ? "block" : "none" }
                    : {}
                }
              >
                {isSocialMediaConnected &&
                !isArrayWithValues(allReduxConversations) &&
                !fetchingConversations ? (
                  <DataNotFound
                    illustration={<NoChats height={300} width={300} />}
                    subtitle={
                      // "To access chats dashboard you need to integrate atleast one social media and select page"
                      isSocialMediaConnected
                        ? "You have not integrated any channel to begin conversations with your contacts"
                        : ""
                    }
                    title={
                      isSocialMediaConnected
                        ? "Lets integrate your first Channel"
                        : "Create your first customer"
                    }
                    createButton={
                      <Button
                        variant="contained"
                        onClick={() => onMetaIntegrate(isSocialMediaConnected)}
                        // size={"small"}
                        sx={{ mt: 3 }}
                      >
                        {isSocialMediaConnected
                          ? isIpad
                            ? "Integrate"
                            : "Start integration"
                          : "Create Cutomer"}
                      </Button>
                    }
                  />
                ) : (
                  <>
                    {isArrayWithValues(selectedCustomer) && (
                      <BulkAction
                        hasBoradCast={hasBoradCast}
                        setHasBoradCast={setHasBoradCast}
                        // selectedGroup={selectedGroup}
                        setShowManage={setShowManage}
                        allReduxConversations={allReduxConversations}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        setCustomerView={setCustomerView}
                        id={idBulk}
                        open={openBulk}
                        anchorEl={anchorElBulk}
                        onClose={handleCloseBulk}
                        showAction={showAction}
                        setShowAction={setShowAction}
                        bulkSelectedGroup={bulkSelectedGroup}
                        user_type={user_type}
                        setListTab={setListTab}
                      />
                    )}
                    {isObjWithValues(customerView) &&
                    !isArrayWithValues(selectedCustomer) ? (
                      <ChatView
                        setCustomerView={setCustomerView}
                        customer={customerView}
                        customerLoading={false}
                        setShowProfile={setShowProfile}
                      />
                    ) : (
                      <DataNotFound
                        subtitle={"No chat selected"}
                        title={"Jwero chats"}
                        illustration={<EmptyChats height={300} width={300} />}
                        sx={{ my: 25 }}
                      />
                    )}
                  </>
                )}
              </ChatMain>
            </ChatContainer>
          </Grid>
          <Grid
            hidden={isIpad ? true : !showProfile}
            item
            xs={12}
            md={4}
            lg={isIpad ? 0 : showProfile ? 3 : 0}
          >
            {showProfile && !isIpad ? (
              <CustomerProfile
                customer={customerView}
                setCustomerView={setCustomerView}
                setShowProfile={setShowProfile}
                showProfile={showProfile}
              />
            ) : null}
          </Grid>
        </Grid>
        {isIpad && (
          <Drawer
            sx={{
              "	.MuiDrawer-paperAnchorBottom": {
                borderTopLeftRadius: 9,
                borderTopRightRadius: 9,
              },
              "	.MuiDrawer-paperAnchorRight": {
                height: "50svh",
                margin: "10px",
                borderRadius: 4,
              },
            }}
            anchor={"bottom"}
            open={showProfile}
            onClose={() => {
              setShowProfile(false);
            }}
          >
            <CustomerProfile
              customer={customerView}
              setShowProfile={setShowProfile}
              showProfile={showProfile}
            />
          </Drawer>
        )}

        {whatsappIntegrated && addFormView ? (
          <AddChats
            whatsapp={true}
            setAddFormView={setAddFormView}
            addFormView={addFormView}
            // customer={customer}
            // customerInfo={customerInfo}
            // setToast={setToast}
          />
        ) : (
          <Dialog
            open={addFormView}
            onClose={() => {
              setAddFormView(false);
            }}
            maxWidth="xs"
            sx={{ ...dialogStyle }}
            fullWidth
            // fullScreen={isPhone}
            keepMounted={false}
          >
            <DialogTitle>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="h6" id="alert-dialog-title"></Typography>
                <IconButton onClick={() => setAddFormView(false)}>
                  <Close />
                </IconButton>
              </Stack>
            </DialogTitle>

            <Box sx={{ p: 3 }}>
              <DataNotFound
                illustration={<WhatsappSvg height={100} width={100} />}
                subtitle={
                  <>
                    {" "}
                    You forgot whatsapp integration??
                    <br />
                    Lets do it now and rocknroll!
                  </>
                  // " "
                }
                // title={"WhatsApp is not integrated!"}
                createButton={
                  <Button
                    variant="contained"
                    onClick={() => navigate("/integrations#Meta")}
                    // size={"small"}
                    sx={{ mt: 3 }}
                  >
                    Integrate WhatsApp
                  </Button>
                }
              />
            </Box>
          </Dialog>
        )}
      </Box>
    );
  }
);
export const ChatListRow = ({
  onCustomerPress,
  customer,
  customerView,
  showManage,
  setSelectedCustomer,
  selectedCustomer,
}) => {
  let {
    first_name,
    last_name,
    id,
    platform,
    name,
    profile_pic,
    conversation,
    username,
    status,
    favorite,
    pinned,
  } = customer;

  let fullName = getNameFromPlatform({
    ...(customer || {}),
    ...(customer?.meta_data?.[`${platform}_info`] || {}),
  });

  // `${first_name || ""} ${last_name || ""} ${name || customer?.number || ""
  //   } ${username || ""}`;
  let lastMessageObj = conversation?.[conversation?.length - 1];
  // let lastMessageObj = [...(conversation || [])]?.reverse()?.find(obj => obj?.is_sender)
  let seen = lastMessageObj?.is_read;
  // let seen = typeof lastMessageObj?.is_read === "boolean" ? lastMessageObj?.is_read : true;
  let unSeen =
    typeof lastMessageObj?.is_read === "boolean"
      ? !lastMessageObj?.is_read
      : false;
  // let lastMessageTime = formatDate(new Date(lastMessageObj?.time), "hh:mm");
  let lastMessageTime = formatDate(new Date(lastMessageObj?.time), "hh:mm");
  let lastMessage = "";
  if (platform === "whatsapp") {
    lastMessage = lastMessageObj?.message?.text?.body || "";
  }
  if (platform !== "whatsapp")
    lastMessage = lastMessageObj?.message?.text || "";

  lastMessage = formatWhatsappMessage(lastMessage);
  let messageUnread = conversation?.filter((m) => m?.is_read === 0);
  const selected =
    selectedCustomer?.findIndex((i) => i?.id == customer?.id) !== -1;

  let broadcastListStr = `${
    customer?.broadcastList
      ?.slice(0, 2)
      ?.map((obj) => obj?.label)
      ?.join(",") || ""
  }${
    customer?.broadcastList?.slice(2, customer?.broadcastList?.length)?.length
      ? " +" +
        customer?.broadcastList?.slice(2, customer?.broadcastList?.length)
          ?.length
      : ""
  }`;
  const onBulkSelect = (e) => {
    if (!selected) {
      setSelectedCustomer((pre) => [...pre, customer]);
    } else {
      setSelectedCustomer((pre) => pre.filter((ob) => ob.id !== customer?.id));
    }
  };
  // let lastMessage = getLastMessage(customer.whatsapp_chat);
  let isDeleted = lastMessageObj?.is_deleted;
  // conversation?.[customerView?.conversation?.length - 1]?.is_deleted;
  return (
    <ListItem
      // key={id}
      button
      onClick={(e) => {
        // e.preventDefault();
        // e.stopPropagation();
        showManage && onBulkSelect();
        !showManage && onCustomerPress && onCustomerPress(customer, showManage);
      }}
      sx={{
        padding: "15px 14px",
        flex: 1,
        position: "relative",
        backgroundColor: customerView?.id === id ? "#f2f2f2" : "transparent",
        borderLeftColor: `${
          customerView?.id === id ? "#0A7CFF" : "transparent"
        }`,
        borderLeftWidth: 3,
        borderLeftStyle: "solid",
        transition: "borderLeftWidth 1s",
        borderBottom: "0.3px solid #e8e8e8",
        "&:hover": {
          backgroundColor: customerView?.id === id ? "#f2f2f2" : "transparent",
        },
      }}
    >
      {showManage ? (
        // <ListItemIcon>
        <Checkbox size="small" checked={selected} />
      ) : // </ListItemIcon>
      null}
      <ListItemIcon>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            // <SmallAvatar>
            <Box sx={logoStyle}>
              {platform === "facebook" ? (
                <Messenger height={18} width={18} />
              ) : platform === "instagram" ? (
                <InstagramSvg height={18} width={18} />
              ) : platform === "whatsapp" ? (
                <Whatsapp height={18} width={18} />
              ) : platform === "webchat" ? (
                <WebchatSvg height={18} width={18} />
              ) : null}
            </Box>
            // {/* </SmallAvatar> */}
          }
        >
          <Badge
            sx={{
              ".MuiBadge-badge": {
                top: "5px",
                right: "5px",
              },
            }}
            badgeContent={
              customer?.contact_type === "customer" ? (
                <CrownSvg
                  height={15}
                  width={15}
                  style={{ transform: "rotate(29deg)" }}
                />
              ) : null
            }
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Avatar
              alt={fullName}
              src={profile_pic || ""}
              sx={{
                width: 50,
                height: 50,
                border: "2px solid",
                borderColor: status ? filterColorObj[status] : "transparent",
              }}
            />
          </Badge>
        </Badge>
      </ListItemIcon>
      <Stack sx={{ width: "100%" }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          style={{ alignItems: "center", width: "100%" }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: seen ? "500" : "500" }}
          >
            {fullName.length > 20 ? `${fullName.slice(0, 20)}...` : fullName}
          </Typography>
          <Stack>
            <Typography
              sx={{
                color: isArrayWithValues(messageUnread) ? "#3ddc84" : "#808080",
                fontSize: "11px",
              }}
            >
              {lastMessageObj?.time &&
                getDateString(new Date(lastMessageObj?.time))}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          style={{ alignItems: "center", width: "100%" }}
        >
          <Typography
            sx={{
              color: seen ? "#808080" : "#000",
              fontWeight: seen ? null : "700",
            }}
            noWrap
            dangerouslySetInnerHTML={{
              __html: formatWhatsappMessage(
                reduceLargeText(
                  isDeleted
                    ? "This message has been unsent"
                    : typeof lastMessage === "string"
                    ? lastMessage
                        ?.replace(/\n/g, "")
                        ?.replace(/&lt;br&gt;/g, " ")
                        ?.replaceAll("<br>", " ")
                        ?.trim()
                    : "",
                  20
                )
              ),
            }}
          />
          {/* </Typography> */}
          {/* {lastMessage.length > 20
              ? `${lastMessage.slice(0, 20)}...`
              : lastMessage} */}
          <Stack
            sx={{ mr: 2 }}
            direction={"row"}
            alignItems="center"
            gap={"5px"}
          >
            {favorite ? (
              <Tooltip title={"Favorite"}>
                <Favorite
                  style={{
                    fontSize: "13px",
                    // position: "absolute",
                    top: "5px",
                    right: pinned ? "30px" : "5px",
                    bottom: "5px",
                    // right: "5px",
                    color: "#E54854",
                  }}
                  fontSize="small"
                />
              </Tooltip>
            ) : null}
            {customer?.teamMember ? (
              <Tooltip title={customer?.teamMember || ""}>
                <HeadsetMic
                  style={{
                    // position: "absolute",
                    // top: "5px",
                    // right: favorite ? "30px" : "5px",
                    fontSize: "13px",
                    bottom: "5px",
                    // right: "5px",
                    color: "#808080",
                  }}
                  fontSize="small"
                />
              </Tooltip>
            ) : null}
            {customer?.broadcastList ? (
              <Tooltip title={broadcastListStr}>
                <PeopleAltOutlined
                  style={{
                    // position: "absolute",
                    // top: "5px",
                    // right: favorite ? "30px" : "5px",
                    fontSize: "13px",
                    bottom: "5px",
                    // right: "5px",
                    color: "#808080",
                  }}
                  fontSize="small"
                />
              </Tooltip>
            ) : null}
            {pinned ? (
              <Tooltip title={"Pinned"}>
                <PushPinOutlined
                  style={{
                    // position: "absolute",
                    fontSize: "13px",
                    top: "5px",
                    right: "5px",
                    color: "#808080",
                    transform: "rotate(45deg)",
                  }}
                  fontSize="small"
                />
              </Tooltip>
            ) : null}
            {lastMessageObj?.meta_data?.sentimental_analysis?.emotion ? (
              <Tooltip
                title={
                  lastMessageObj?.meta_data?.sentimental_analysis?.emotion || ""
                }
              >
                <Box>
                  {sentimentIcons?.[
                    lastMessageObj?.meta_data?.sentimental_analysis?.emotion
                  ] || ""}
                </Box>
              </Tooltip>
            ) : null}
            {customer?.merge_profiles?.crm_id && isDev() && false ? (
              <Tooltip title={"CRM"}>
                <span>
                  <CRMMenuIcon
                    style={{
                      // position: "absolute",
                      // fontSize: "13px",
                      height: "14px",

                      top: "5px",
                      right: "5px",
                      // color: "#808080",
                      // transform: "rotate(45deg)",
                    }}
                    fontSize="small"
                  />
                </span>
              </Tooltip>
            ) : null}

            {isArrayWithValues(messageUnread) && (
              <Box
                sx={{
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#3ddc84",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                {messageUnread.length}
              </Box>
              // <Badge component={Box}

              //   badgeContent={messageUnread.length}
              //   color="success"
              // ></Badge>
            )}
          </Stack>
        </Stack>
        <Stack
          alignItems={"baseline"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Box>
            {customer?.contact_type ? (
              <Chip
                color={
                  customer?.contact_type === "customer" ? "primary" : "success"
                }
                variant="outlined"
                sx={{
                  "	.MuiChip-labelSmall": {
                    fontSize: "10px",
                    py: "6px",
                    letterSpacing: "1px",
                    fontWeight: "700",
                  },
                  height: "18px",
                  border: "1.5px solid",
                }}
                label={formatServerValue(customer?.contact_type || "")}
                size="small"
              />
            ) : null}
          </Box>
          <Box>
            {isArrayWithValues(customer?.conversation) &&
            !check24HoursWindowClose(customer?.conversation, customer) ? (
              <Typography
                variant="subtitle2"
                sx={{ display: "flex", alignItems: "center" }}
                color={"success.main"}
              >
                <Box className="example">
                  <Box className="dot"></Box>
                </Box>
                Open
              </Typography>
            ) : null}
          </Box>
        </Stack>
      </Stack>
    </ListItem>
  );
};

const CustomerProfile = ({ customer, setShowProfile, showProfile }) => {
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  const [CRMCustomFields, setCRMCustomFields] = useState([]);
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [info, setInfo] = useState({});

  let {
    first_name,
    last_name,
    id,
    platform,
    name,
    profile_pic,
    conversation,
    username,
    status,
    label,
    company_name,
    contact_type,
    platform_id,
    email,
  } = customer || {};
  let whatsapp, dialcode_whatsapp;
  let { isValid, phoneNumber, countryCode } = phone(`+${platform_id}`);
  let fullName = `${first_name || ""} ${last_name || ""} ${name || ""} ${
    username || ""
  }`;
  if (isValid) {
    (whatsapp = phoneNumber?.replace(countryCode, "")),
      (dialcode_whatsapp = countryCode?.replace("+", ""));
  }
  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    setCRMCustomFields(Object.values(custom_fields?.crm));
  }, [custom_fields]);
  let formDisabledKeys = [
    "whatsapp",
    "dialcode_whatsapp",
    "phone",
    "dialcode_phone",
  ];

  let number = "";
  number = customer?.number;
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [meargeCustomer, setMeargeCustomer] = useState(false);
  // const [toggleProductDialog, setToggleProductDialog] = useState(true);
  const [showCustomer, setShowCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [fetchingCustomerDetails, setFetchingCustomerDetails] = useState(false);
  const [profiles, setProfiles] = useState(false);
  // const [meargeCustomer, setMeargeCustomer] = useState(false);
  const [mergingCustomer, setMergingCustomer] = useState(false);
  const [allCustomerGroup, setAllCustomerGroup] = useState([]);

  const [mediaslist, setMediaList] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [noteForm, setNoteForm] = useState({
    title: "",
    note: "",
  });
  const [deleteBroadcast, setDeleteBroadcast] = useState({});
  const [deletingBroadcast, setDeleteingBroadcast] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const closeForm = () => setFormOpen(false);
  const openUpdateForm = (edit) => {
    setEditForm(edit);
    setFormOpen(true);
  };

  const openAddForm = (edit) => {
    setEditForm({});
    setFormOpen(true);
  };
  const allReduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const broadcast_list = useSelector((state) => state.chats.chat_broadcasts);
  const mergeProfiles = useSelector((state) => state?.chats?.mergeProfiles);

  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  const closeBroadcastDelete = () => {
    setDeleteBroadcast({});
  };

  const onDeleteBroadcast = async (i) => {
    setDeleteingBroadcast(true);

    dispatch(
      assignBroadcast({
        usersMetaData: [
          {
            id: customer?.id,
            meta_data: {
              broadcast: customer?.meta_data?.broadcast?.filter(
                (str) => str !== i
              ),
            },
          },
        ],
        broadcastId: i,
        remove: true,
      })
    );
    dispatch(
      setGlobalToast({
        show: true,
        message: `${getNameFromPlatform(
          customer || {}
        )} removed from broadcast ${broadcast_list?.[i]?.title} Successfully!`,
      })
    );
    // let label = customer?.label?.filter((str) => str != i?.id);
    // let updatedCustomer = {
    //   ...customer,
    //   label: label,
    //   broadcastList: customer.broadcastList?.filter((obj) => obj?.id != i?.id),
    // };
    // console.log(i, customer, label, "<<label");

    // await dispatch(
    //   updateChatObject(updatedCustomer, {
    //     payload: {
    //       label: label,
    //       // label: [...new Set(arr)],
    //     },
    //   })

    // );
    // setCustomerView((pre) => ({

    //   // ...pre,
    //   // label: label,
    //   // broadcastList: pre.broadcastList?.filter((obj) => obj?.id != i?.id),
    // }));
    setDeleteingBroadcast(false);
    closeBroadcastDelete();
  };
  useEffect(() => {
    if (!isArrayWithValues(allUserTasks)) return;
    if (isObjWithValues(customer)) {
      setAllTasks(
        allUserTasks.filter(
          (i) => i.user_id == customer.id && i.status !== "complete"
        )
      );
    } else setAllTasks(allUserTasks);
  }, [allUserTasks, customer]);

  let tanikachannels = 3;
  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxCustomerGroup))
  //     dispatch(getGroupingData());
  // }, []);
  useEffect(() => {
    if (isObjWithValues(allReduxCustomerGroup))
      setAllCustomerGroup(allReduxCustomerGroup);
  }, [allReduxCustomerGroup]);

  const changeNotesForm = (p) => {
    return setNoteForm((state) => ({ ...state, ...p }));
  };
  const dispatch = useDispatch();
  const onAddNote = async () => {
    setNoteLoading(true);
    const obj = {
      additional_custom_data: {
        notes: {
          request: "add",
          data: noteForm,
        },
      },
    };
    // try {
    //   await updateCustomer(customer.id, obj);
    //   setShowNotesDialog(false);
    //   setToast({
    //     show: true,
    //     message: "Note added successfully!",
    //     severity: "success",
    //   });
    // } catch (error) {
    //   console.log(getAxiosError(error));
    //   setToast({
    //     show: true,
    //     message: "Couldn't add Note! Please try again later",
    //     severity: "error",
    //   });
    // }
    setNoteLoading(false);
  };
  const [addFormView, setAddFormView] = useState(false);
  const [create, setCreate] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerId, setCustomerId] = useState(false);
  const [messageImageView, setMessageImageView] = useState({
    url: "",
    show: false,
  });

  // const createCustomer = () => {};
  // useEffect(() => {
  //   if (!showProfile) return
  //   getCustomerData()
  // }, [customer])
  // const allReduxCustomers =
  //   useSelector((state) => state.customers.allCustomers) || [];
  // useEffect(async () => {
  //   setFetchingCustomerDetails(true);
  //   if (isObjWithValues(customer)) {
  //     if (customer.customer_id) {
  //       let customerInfo = allReduxCustomers.find(
  //         (o) => o.id === customer.customer_id
  //       );

  //       if (isObjWithValues(customerInfo)) {
  //         setCustomerInfo(customerInfo);
  //       } else {
  //         let fetchCustomerRes = await getCustomer(customer.customer_id);
  //         await setCustomerInfo(fetchCustomerRes.data);
  //         // dispatch(addCustomerInRedux(fetchCustomerRes.data));
  //       }
  //     } else {
  //       setCustomerInfo({});
  //     }
  //   } else {
  //     setCustomerInfo({});
  //   }
  //   if (customer?.dialcode_mobile) {
  //     setCustomerInfo(customer || {});
  //   }
  //   setFetchingCustomerDetails(false);
  // }, [customer]);
  useEffect(() => {
    // console.log("mergeProfiles  ,", mergeProfiles);
    let value = Object.values(mergeProfiles || {})?.find((obj) =>
      obj?.profiles?.find((o) => o?.id === customer?.platform_id)
    );

    if (value) setProfiles(value);
    return () => {};
  }, [customer, mergeProfiles]);

  const onMeargeCustomer = async () => {
    setMergingCustomer(true);
    let data = {};
    if (profiles?.crm_id) {
      // if (isObjWithValues(customer?.merge_profiles)) {
      //   data = {
      //     [customer?.merge_profiles?.id]: {
      //       ...customer?.merge_profiles,
      //       crm_id: selectedCustomer?.id,
      //     },
      //   };
      // } else {
      //   let id = `${Date.now()}_${getRandomString(5)}`;
      //   data = {
      //     [id]: {
      //       id,
      //       crm_id: selectedCustomer?.id,
      //       profiles: [
      //         {
      //           id: customer?.id,
      //           platform: customer?.platform,
      //         },
      //       ],
      //     },
      //   };
      // }
    } else {
      if (isObjWithValues(profiles)) {
        data = {
          [profiles?.id]: {
            ...profiles,
            crm_id: selectedCustomer?.id,
          },
        };
      } else {
        let id = `${Date.now()}_${getRandomString(5)}`;
        data = {
          [id]: {
            id,
            crm_id: selectedCustomer?.id,
            profiles: [
              {
                id: customer?.platform_id,
                platform: customer?.platform,
              },
            ],
          },
        };
      }
    }
    setMergingCustomer(true);

    // return
    dispatch(
      putMergeProfiles(data, setMergingCustomer, () => {
        setCreate(false);
        setShowCustomer(false);
        dispatch(
          setGlobalToast({
            show: true,
            message: `${getNameFromPlatform(customer)} Added in CRM `,
            severity: "success",
          })
        );
      })
    );
    // setMergingCustomer(true);
    let updateObject = {
      first_name,
      last_name,
      email,
      meta_data: [
        {
          key: `${customer?.platform}_id`,
          value: `${customer?.platform_id}`,
        },
      ],
    };
    if (company_name) {
      updateObject.meta_data.push({ key: "company_name", value: company_name });
      updateObject = {
        ...updateObject,
        billing: {
          company: company_name,
        },
      };
    }
    if (contact_type)
      updateObject.meta_data.push({ key: "contact_type", value: contact_type });

    let res = await updateCustomer(selectedCustomer?.id, updateObject);
    // setMergingCustomer(false);
    // await updateCustomer(selectedCustomer.id, {
    //   meta_data: [{ key: `${platform}_id`, value: id }],
    // });
    // dispatch(
    //   updateChatObject(customer, {
    //     platform,
    //     payload: {
    //       customer_id: selectedCustomer.id,
    //     },
    //   })
    // );
    // dispatch(
    //   updateCustomerInRedux({
    //     ...selectedCustomer,
    //     meta_data: [
    //       ...selectedCustomer.meta_data,
    //       { key: `${platform}_id`, value: id },
    //     ],
    //   })
    // );
    // setShowCustomer(false);
    // setMergingCustomer(false);
    // setToast({ ...toast, show: true, message: `success`, severity: "success" });
  };
  const createCustomerAndMerge = async (payload) => {
    let crmCustomerData = {
      meta_data: [{ [`${platform}_id`]: platform_id }],
    };
    let billingKeys = [
      "phone",
      "address_1",
      "address_2",
      "city",
      "state",
      "postcode",
      "country",
    ];
    let mainKeys = ["email", "first_name", "last_name"];
    Object.entries(payload || {})?.forEach(([key, value]) => {
      console.log(key, value);
      if (billingKeys?.includes(key)) crmCustomerData[key] = value;
      else if (!mainKeys?.includes(key))
        crmCustomerData.meta_data.push({
          //  [key]: value
          key,
          value,
        });
      // crmCustomerData[key] = value;
      else crmCustomerData[key] = value;
    });
    if (payload?.company_name) {
      crmCustomerData = {
        ...crmCustomerData,
        billing: {
          company: payload?.company_name,
        },
      };
    }
    // return;
    let customerRes = await createCustomerForCrm({ data: crmCustomerData });
    if (!customerRes?.id)
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to create customer`,
          severity: "error",
        })
      );
    setCreate(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: `${getNameFromPlatform(customer)} Added in CRM `,
        severity: "success",
      })
    );
    let profileId = `${getTimeInMilliSeconds()}_${getRandomString(4)}`;
    let putData = {
      id: profileId,
      crm_id: customerRes?.id,
      profiles: [{ platform, id: platform_id }],
    };

    dispatch(putMergeProfiles({ [profileId]: putData }, false, () => {}));
  };
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(customer) && !isArrayWithValues(allCustomerGroup))
      return;
    let group_namesObj = customerInfo?.meta_data?.find(
      (o) => o.key === `group_name_${getStoreId()}`
    );
    let groups = group_namesObj?.value || [];
    let groupArr = groups?.map(
      (i) => allCustomerGroup?.find((o) => o.group_name === i) || {}
    );
    setGroups(groupArr);
  }, [customerInfo, allCustomerGroup]);
  useMemo(() => {
    if (!isArrayWithValues(customer?.conversation)) return;
    if (platform === "whatsapp") {
      let mediaArray = customer?.conversation.filter(
        (obj) => obj?.message?.image?.link
      );
      let mediaLinkArry = mediaArray.map((obj) => obj?.message?.image?.link);
      setMediaList(mediaLinkArry);
    } else {
      let mediaArray = customer?.conversation.filter((obj) =>
        isArrayWithValues(obj?.message?.attachments)
      );
      let mediaLinkArry = mediaArray.filter(
        (obj) => obj?.message?.attachments[0]?.type === "image"
      );
      let photoMedia = mediaLinkArry.map(
        (obj) => obj?.message?.attachments[0]?.payload?.url
      );
      setMediaList(photoMedia);
    }
  }, [customer?.conversation]);
  // useEffect(() => {
  //   if (!isArrayWithValues(customer?.conversation)) return;
  //   if (platform === "whatsapp") {
  //     let mediaArray = customer?.conversation.filter(
  //       (obj) => obj?.message?.image?.link
  //     );
  //     let mediaLinkArry = mediaArray.map((obj) => obj?.message?.image?.link);
  //     setMediaList(mediaLinkArry);
  //   } else {
  //     let mediaArray = customer?.conversation.filter((obj) =>
  //       isArrayWithValues(obj?.message?.attachments)
  //     );
  //     let mediaLinkArry = mediaArray.filter(
  //       (obj) => obj?.message?.attachments[0]?.type === "image"
  //     );
  //     let photoMedia = mediaLinkArry.map(
  //       (obj) => obj?.message?.attachments[0]?.payload?.url
  //     );
  //     setMediaList(photoMedia);
  //   }
  // }, [customer?.conversation]);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  let address = useMemo(
    () => `${customerInfo?.billing?.address_1 || ""} 
                  ${customerInfo?.billing?.address_2 || ""}
                  ${customerInfo?.billing?.city || ""}
            ${customerInfo?.billing?.postcode || ""}`,
    [customerInfo]
  );
  const details = useMemo(() => {
    let showKeys = [
      "email",
      "address_1",
      "address_2",
      "city",
      "postcode",
      "state",
      "country",
      "contact_type",
      "company_name",
      "team_member",
      // "follower_count"
      // {

      //   value: "follower_count",
      //   label: "Followers"
      // }
    ];
    let billing = customer?.billing || {};
    let fullCustomer = {
      ...customer,
      ...billing,
      team_member: customer?.teamMember,
    };

    return (
      <Stack rowGap={2} mt={3}>
        {Object.keys(fullCustomer || {}).map((key) => {
          // if (isObjWithValues(key)) {
          //   return <Stack direction={"row"} alignItems={"center"} gap={2}>
          //     <Typography fontWeight={700}>
          //       {key?.label}
          //     </Typography>{" "}
          //     <Typography>{fullCustomer[key?.value]}</Typography>{" "}
          //   </Stack>
          // }
          if (!fullCustomer[key]) return;
          if (showKeys.includes(key))
            return (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Typography>{formatServerValue(key || "")}:</Typography>
                {fullCustomer[key]?.length > 18 && false ? (
                  <Tooltip title={fullCustomer[key]}>
                    <Typography fontWeight={700}>
                      {reduceLargeText(fullCustomer[key], 18)}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography fontWeight={700}>
                    {reduceLargeText(fullCustomer[key], 18)}
                  </Typography>
                )}
              </Stack>
            );
        })}
      </Stack>
    );
  }, [customer, showProfile]);
  const onUpdateChats = async (chatState) => {
    let updateKeys = [
      "first_name",
      "last_name",
      "email",
      "company_name",
      "contact_type",
      "dialcode_whatsapp",
      "whatsapp",
    ];
    let updateObj = {};
    let metaKeys = ["contact_type", "dialcode_whatsapp", "whatsapp"];
    let customerData = {
      meta_data: [],
    };

    Object.entries(chatState || {})?.forEach(([key, value]) => {
      if (updateKeys?.includes(key)) updateObj[key] = value;
      if (metaKeys?.includes(key) && updateObj[key])
        customerData.meta_data.push({ key, value });
      else if (updateObj[key]) customerData[key] = value;
    });
    if (profiles?.crm_id) {
      await updateCustomer(profiles?.crm_id, customerData);
    }
    dispatch(
      bulkActionChats({
        data: {
          update: [{ id: customer?.id, ...updateObj }],
        },

        onSuccess: () => {
          setAddFormView(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Chats Update Successfully",
              severity: "success",
            })
          );
        },
      })
    );
  };
  return (
    <>
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      <Dialog
        maxWidth="xs"
        fullWidth
        sx={{ ...dialogStyle, height: "70vh" }}
        open={customerId}
        onClose={() => setCustomerId(false)}
        {...globalPopupStyle}
      >
        <CustomerLabelView
          id={customerId}
          customer={customerInfo}
          onClose={() => setCustomerId(false)}
          chatDetail={customer}
          label={label}
          // setCustomer={props.setCrmViewCustomer}
        />
      </Dialog>
      {addFormView ? (
        <AddChats
          setAddFormView={setAddFormView}
          addFormView={addFormView}
          chat={{ ...(customer || {}), whatsapp, dialcode_whatsapp }}
          disabledKeys={formDisabledKeys}
          onSubmitFunction={onUpdateChats}
          // requiredFelids
        />
      ) : null}

      <Card sx={{ height: "calc(100svh - 105px)", overflow: "auto" }}>
        <CardContent sx={{ p: 0 }}>
          <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack
              p={4}
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: "0",
                background: "#fff",
                zIndex: 10,
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  // <SmallAvatar>
                  <Box sx={logoStyle}>
                    {platform === "facebook" ? (
                      <Messenger height={18} width={18} />
                    ) : platform === "instagram" ? (
                      <InstagramSvg height={18} width={18} />
                    ) : (
                      platform === "whatsapp" && (
                        <Whatsapp height={18} width={18} />
                      )
                    )}
                  </Box>
                  // {/* </SmallAvatar> */}
                }
              >
                <Avatar
                  alt={getNameFromPlatform(customer || {})}
                  src={profile_pic || ""}
                  sx={{
                    width: 50,
                    height: 50,
                    border: "2px solid",
                    borderColor: status
                      ? filterColorObj[status]
                      : "transparent",
                  }}
                />
              </Badge>
              <Box>
                <Typography
                  ml={2}
                  variant="h4"
                  fontSize="16px"
                  sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                >
                  {getNameFromPlatform(customer || {})}{" "}
                  {Object.values(customer?.profiles || {})?.length > 1 && (
                    <>
                      <Tooltip
                        title={`Profile Merged ${Object.keys(
                          customer?.profiles || {}
                        )
                          ?.map((str) => formatServerValue(str))
                          ?.join(",  ")}`}
                      >
                        <IconButton>
                          <Merge />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Typography>

                {profiles?.crm_id && (
                  <Link
                    component="button"
                    underline="hover"
                    sx={{ ml: 2 }}
                    onClick={() => {
                      window.open(
                        `${isDev() ? "http" : "https"}://${
                          window.location.host
                        }/crm/${profiles?.crm_id}`,
                        "_blank"
                      );
                    }}
                  >
                    Go to profile {">"}
                  </Link>
                )}

                <Typography ml={2} mt={1.4} fontSize="12px">
                  {info.contact_type || ""}
                </Typography>
                {/* <Button onClick={() => navigate(`/customers/${info.id}`)}>
              View Profile
            </Button> */}
              </Box>
              {customer?.meta_data?.follower_count && (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ ml: 2 }}
                >
                  <Typography ml={2} variant="h4" fontSize="16px">
                    {customer?.meta_data?.follower_count}
                  </Typography>
                  <Typography>Followers</Typography>
                </Stack>
              )}

              {/* <Typography ml={2}  fontSize="13px">
        {customerView.first_name || ""} {customerView.last_name || ""}
      </Typography> */}
            </Stack>
            <IconButton onClick={() => setShowProfile(false)}>
              <Close />
            </IconButton>
          </Stack>

          <Divider />
          <Box p={4}>
            <Stack direction={"row"} alignItems="center" gap={2}>
              <Typography variant="subtitle1">Contact Details</Typography>
              {customer?.id && (
                <IconButton size="small" onClick={() => setAddFormView(true)}>
                  <Edit />
                </IconButton>
              )}
            </Stack>
            <Stack mt={1.5} direction="row" alignItems={"center"}>
              {/* {number ||  customer?. ? (
                <>
                  <LocalPhone style={{ height: "15px", width: "15px" }} />
                  <Typography ml={1} fontSize={"13px"}>
                    {number}
                  </Typography>
                </>
              ) : null} */}

              {platform === "whatsapp" ? (
                <>
                  <LocalPhone style={{ height: "15px", width: "15px" }} />
                  <Typography ml={1} fontSize={"13px"}>
                    {customer?.platform_id}
                  </Typography>
                </>
              ) : null}
            </Stack>
            {details}
            <Stack rowGap={2}>
              {CRMCustomFields?.map((o) => {
                console.log(o);
                let { title, value } = o;
                if (!customer?.meta_data?.[value]) return;
                return (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography>{title}:</Typography>{" "}
                    <Typography fontWeight={700}>
                      {reduceLargeText(customer?.meta_data?.[value], 18)}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
          <DeleteDialog
            onDelete={() => {
              onDeleteBroadcast(deleteBroadcast);
            }}
            open={isObjWithValues(deleteBroadcast)}
            handleClose={closeBroadcastDelete}
            deleteing={deletingBroadcast}
            heading={`Are you sure you want to Remove ${getNameFromPlatform(
              customer || {}
            )} from broadcast ${
              // deleteBroadcast?.label
              broadcast_list?.[deleteBroadcast]?.title
            }`}
            content={"Action cannot be undone"}
          />

          {Object.values(customer?.profiles || {})?.length > 1 && (
            <>
              <Box p={4} sx={{ backgroundColor: "Background.paper" }}>
                {/* <Divider /> */}
                <Typography variant="h5">Channels</Typography>
                <Stack
                  direction="row"
                  sx={{ backgroundColor: "#fff", gap: "5px", mr: 2 }}
                >
                  {channels.map((b) => {
                    if (!customer?.profiles?.[b.value]) return;
                    return (
                      <IconButton
                        // variant={"text"}
                        // startIcon={b.icon}
                        sx={{
                          border: `2px solid `,
                          borderColor:
                            // selectedTab === b.value
                            //   ? "primary.main"
                            // :
                            "transparent",

                          // padding: "6px 20px",

                          // backgroundColor:
                          //   selectedTab === b.value ? "#e6e6e6" : "transparent",
                        }}
                        // onClick={() => {
                        //   customer?.profiles?.[b.value] &&
                        //     navigate(
                        //       `/chats/${customer?.profiles?.[b.value]
                        //       }`
                        //     );
                        //   setSelectedTab(b.value);
                        // }}
                      >
                        {b.icon}
                        {/* <Typography>{b.label}</Typography> */}
                      </IconButton>
                    );
                  })}
                </Stack>
              </Box>
              <Divider />
            </>
          )}
          <Stack direction={"row"} sx={{ gap: "10px", mt: 2 }}>
            {/* {!fetchingCustomerDetails && (
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  onClick={() => setAddFormView(true)}
                >
                  {customer?.customer_id ? "Edit Details" : "  Add to CRM"}
                </Button>
              )} */}
            <Box sx={{ px: 2 }}>
              {displayState({
                user_products,
                key: "mergeOptions",
              }) && (
                <>
                  {user_products?.length === 1 &&
                  user_products?.includes("chats") ? null : (
                    <>
                      {!profiles?.crm_id && (
                        <Button
                          sx={{ mt: 1 }}
                          variant="outlined"
                          onClick={() => setShowCustomer(true)}
                        >
                          Add to CRM
                        </Button>
                      )}
                    </>
                  )}
                  {/* {validateNumber(profiles?.profiles?.length) <
                  tanikachannels && (
                  <Button
                    sx={{ mt: 1 }}
                    variant="outlined"
                    onClick={() => setMeargeCustomer(customer)}
                  >
                    Merge Profile
                  </Button>
                )} */}
                </>
              )}
            </Box>
          </Stack>
          {isArrayWithValues(customer?.meta_data?.broadcast) && (
            <Box p={4}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography variant="h5">BroadcastList</Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{ gap: "8px", flexWrap: "wrap", mt: 1 }}
              >
                {isArrayWithValues(customer?.meta_data?.broadcast) ? (
                  customer?.meta_data?.broadcast.map((i) => {
                    if (!broadcast_list?.[i]?.title) return <></>;
                    return (
                      <>
                        <Chip
                          // size="small"
                          // icon={
                          //   <LocalOffer
                          //     style={{
                          //       height: "13px",
                          //       width: "13px",
                          //       // color: i && i?.color,
                          //     }}
                          //   />
                          // }
                          label={broadcast_list?.[i]?.title}
                          onDelete={async () => {
                            setDeleteBroadcast(i);
                            // let label = customer?.label?.filter(
                            //   (str) => str != i?.id
                            // );
                            // let updatedCustomer = {
                            //   ...customer,
                            //   label: label,
                            //   broadcastList: customer.broadcastList?.filter(
                            //     (obj) => obj?.id != i?.id
                            //   ),
                            // };
                            // console.log(i, customer);
                            // setCustomerView((pre) => ({
                            //   ...pre,
                            //   label: label,
                            //   broadcastList: pre.broadcastList?.filter(
                            //     (obj) => obj?.id != i?.id
                            //   ),
                            // }));
                            // await dispatch(
                            //   updateChatObject(updatedCustomer, {
                            //     payload: {
                            //       label: label,
                            //       // label: [...new Set(arr)],
                            //     },
                            //   })
                            // );
                          }}
                        />
                      </>
                    );
                  })
                ) : (
                  <Typography
                    my={1.5}
                    color="text.secondary"
                    textAlign={"center"}
                  >
                    No Broadcast
                  </Typography>
                )}
              </Stack>
            </Box>
          )}

          {!fetchingCustomerDetails && false && (
            <>
              <Box p={4}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography variant="h5">Tags</Typography>
                  {customer.customer_id && (
                    <IconButton
                      onClick={() => setCustomerId(customer.customer_id)}
                    >
                      <Add />
                    </IconButton>
                  )}
                </Stack>
                <Stack direction={"row"} sx={{ gap: "8px", flexWrap: "wrap" }}>
                  {isArrayWithValues(groups) &&
                    groups.map((i) => {
                      if (!i?.group_name) return <></>;
                      return (
                        <>
                          <Chip
                            size="small"
                            icon={
                              <LocalOffer
                                style={{
                                  height: "13px",
                                  width: "13px",
                                  color: i && i?.color,
                                }}
                              />
                            }
                            label={i?.group_name}
                            // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                          />
                        </>
                      );
                    })}
                </Stack>

                {!isArrayWithValues(groups) && (
                  <Typography
                    my={1.5}
                    color="text.secondary"
                    textAlign={"center"}
                  >
                    No Tags
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box p={4}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography variant="h5">Activities</Typography>
                  {customer && (
                    <IconButton onClick={openAddForm}>
                      <Add />
                    </IconButton>
                  )}
                </Stack>
                {allTasks?.map((i) => {
                  return (
                    <TaskRow
                      row={i}
                      showCustomerName={false}
                      openUpdateForm={openUpdateForm}
                    />
                  );
                })}
                {!isArrayWithValues(allTasks) && (
                  <Typography
                    my={1.5}
                    color="text.secondary"
                    textAlign={"center"}
                  >
                    No Activities
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Divider />
          <Box p={4}>
            <Stack>
              <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
                Attachment
              </Typography>
              <Grid container spacing={4} rowSpacing={2}>
                {mediaslist.map((item) => (
                  <Grid item key={item} xs={6}>
                    <img
                      onClick={() =>
                        setMessageImageView({ url: item, show: true })
                      }
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "cover",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        borderRadius: "6px",
                      }}
                      src={`${item}`}
                      // srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      // alt={item}
                      loading="lazy"
                    />
                  </Grid>
                ))}
              </Grid>

              {/* <ImageList sx={{ width: "100px" }} cols={2} rowHeight={164}>
                {mediaslist.map((item) => (
                  <ImageListItem key={item}>
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src={`${item}`}
                      // srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={item}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList> */}
            </Stack>
            {!isArrayWithValues(mediaslist) && (
              <Typography my={1.5} color="text.secondary" textAlign={"center"}>
                No Attachment
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask
          customer={customer}
          closeForm={closeForm}
          updateForm={editForm}
        />
      </Dialog>
      <Dialog
        open={messageImageView.show}
        onClose={() => setMessageImageView((pre) => ({ ...pre, show: false }))}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          sx={{
            padding: "8px",
            "&:last-child": { paddingBottom: "3px" },
            height: "100%",
          }}
        >
          <img
            src={messageImageView.url}
            // src="https://i.natgeofe.com/n/c0e0a134-3e97-4b8f-9f7b-9d11f5e1bf02/comedy-wildlife-awards-squirel-stop.jpg?w=636&h=475"
            style={{
              width: "100%",
              maxHeight: "80svh",
              padding: 0,
              margin: 0,
              objectFit: "contain",
            }}
          />
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={showNotesDialog}
        onClose={() => setShowNotesDialog(false)}
        maxWidth="sm"
        fullWidth
        sx={dialogStyle}
      >
        <DialogTitle>Add Note ({fullName})</DialogTitle>
        <DialogContent>
         
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="email"
            fullWidth
            variant="standard"
            value={noteForm.title}
            onChange={(e) => changeNotesForm({ title: e.target.value })}
          />
      
          <TextField
            margin="dense"
            id="name"
            label="Description"
            type="email"
            fullWidth
            variant="standard"
            multiline
            rows={3}
            value={noteForm.note}
            onChange={(e) => changeNotesForm({ note: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNotesDialog(false)}>Cancel</Button>
          <LoadingButton loading={noteLoading} onClick={onAddNote}>
            Add Note
          </LoadingButton>
        </DialogActions>
      </Dialog> */}

      {create && (
        <AddChats
          setAddFormView={setCreate}
          addFormView={create}
          heading={"Adding contacts in CRM"}
          onClose={() => {
            setShowCustomer(true);
          }}
          // onSubmitFunction={(formData) => {
          //   console.log(formData);
          // }}
          onSubmitFunction={createCustomerAndMerge}
          disabledKeys={formDisabledKeys}
          formData={{
            first_name,
            last_name,
            company_name,
            contact_type,
            whatsapp,
            dialcode_whatsapp,
            email,
          }}
          submitLabel={"Add"}
          // chat={customer}
        />
      )}
      <Dialog
        open={showCustomer}
        onClose={() => setShowCustomer(false)}
        maxWidth="sm"
        fullWidth
        sx={isIpad ? {} : dialogStyle}
        fullScreen={isIpad}
      >
        <DialogContent>
          <SelectCustomer
            createButton={true}
            createOnClick={() => {
              // setAddFormView(true);
              setCreate(true);
              setShowCustomer(false);
            }}
            heading={"Add to CRM"}
            onClose={() => setShowCustomer(false)}
            onSelect={setSelectedCustomer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCustomer(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            disabled={!isObjWithValues(selectedCustomer)}
            loading={mergingCustomer}
            onClick={onMeargeCustomer}
          >
            Merge Customer
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={meargeCustomer}
        onClose={() => {
          setMeargeCustomer(false);
        }}
        sx={{ ...dialogStyle }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <ConnectChannel
          customer={meargeCustomer}
          onClose={() => {
            setMeargeCustomer(false);
          }}
          merge_profiles={profiles}
        />
      </Dialog>
      {/* <AddCustomer
        whatsapp={true}
        setAddFormView={setAddFormView}
        addFormView={addFormView}
      // customer={customer}
      // customerInfo={customerInfo}
      // setToast={setToast}
      /> */}
    </>
  );
};

const Chat = forwardRef(({ unreadCount, params, setParams }, ref) => {
  const [defaultParams, setDefaultParams] = useState({
    page: 1,
    per_page: 100,
  });
  const [selectedTab, setSelectedTab] = useState("all_messages");
  const assignedCustomers = useSelector(
    (state) => state.user.assigned_customers
  );
  const customerIncluded = useSelector((state) => state.user.customerIncluded);
  const allReduxCustomers = useSelector(
    (state) => state.customers.allChatCustomers
  );
  // const store_url = useSelector((state) => state);
  let website = getWebsite();
  const fetchedTeamMembers = useSelector(
    (state) => state.settings.fetchedTeamMembers
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const chatWindowRef = useRef();

  const onRefresh = () => {
    dispatch(fetchChatCustomers({ ...defaultParams }, true));
  };

  useImperativeHandle(ref, () => {
    return {
      getContactView: chatWindowRef?.current?.getContactView,
      setContactView: chatWindowRef?.current?.setContactView,
    };
  });

  useEffect(() => {
    if (typeof customerIncluded === "boolean" && fetchedTeamMembers) {
      if (isArrayWithValues(assignedCustomers)) {
        let params = { ...defaultParams };
        if (customerIncluded)
          params = {
            ...params,
            include: assignedCustomers.map((i) => i.value),
          };
        else
          params = {
            ...params,
            exclude: assignedCustomers.map((i) => i.value),
          };
        // dispatch(fetchChatCustomers(params));
        setDefaultParams((state) => ({ ...state, ...params }));
      } else {
        // if (!isArrayWithValues(allReduxCustomers))
        // dispatch(fetchChatCustomers(defaultParams));
      }
    }
  }, [customerIncluded, assignedCustomers, fetchedTeamMembers]);

  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxCustomers) && fetchedTeamMembers)
  //     dispatch(fetchChatCustomers(defaultParams));
  // }, [fetchedTeamMembers]);

  // useEffect(() => {
  //   if (isArrayWithValues(reduxIntegrations)) {
  //     let obj = reduxIntegrations.find((i) => i.gupshup);
  //     if (isObjWithValues(obj) && obj.gupshup) {
  //       let { gupshup } = obj;
  //       let { gupshup_app_name, gupshup_api_key, enabled } = gupshup;
  //       if (gupshup_api_key && enabled) setIsGupshupIntegrated(true);
  //       else setIsGupshupIntegrated(false);
  //     } else setIsGupshupIntegrated(false);
  //   }
  // }, [reduxIntegrations]);

  // if (!isGupshupIntegrated)
  //   return (
  //     <Box
  //       sx={{
  //         width: "300px",
  //         margin: "auto",
  //         marginTop: "18vh",
  //         marginBottom: "10vh",
  //         textAlign: "center",
  //       }}
  //     >
  //       <SocialIllustration />
  //       <Typography variant="h6" color="text.secondary" mt={5}>
  //         Looks like Gupshup not integrated yet!
  //       </Typography>
  //     </Box>
  //   );

  return (
    // <Box sx={{ width: isIpad ? "100%" : "82%" }}>
    <Box sx={{ width: "100%" }}>
      <Helmet title="Chat" />

      <ChatWindow
        defaultParams={defaultParams}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        unreadCount={unreadCount}
        params={params}
        setParams={setParams}
        ref={chatWindowRef}
      />
    </Box>
  );
});

export default Chat;

const getLastMessage = (chats) => {
  if (isArrayWithValues(chats)) {
    for (let i = 0; i < chats.length; i++) {
      let obj = chats[i];
      if (obj.msg_text) return { message: obj.msg_text, status: obj.status };
      if (obj.message && obj.message.text) return { message: obj.message.text };
    }
  }
  return {};
};
const defaultSendingTemplate = {
  loading: false,
  total: 0,
  totalSent: 0,
  percentage: 0,
};
const BulkAction = memo(
  ({
    hasBoradCast,
    setHasBoradCast,
    setShowManage,
    selectedCustomer,
    setSelectedCustomer,
    allReduxConversations,
    id,
    open,
    anchorEl,
    onClose,
    showAction,
    setShowAction,
    selectedGroup,
    bulkSelectedGroup,
    setCustomerView,
    setListTab,
    user_type,
  }) => {
    const headerHeight = "116px";
    const dispatch = useDispatch();
    const [showExisting, setShowExisting] = useState(false);
    const [customerId, setCustomerId] = useState(false);
    const [showBulkTemplate, setShowBulkTemplate] = useState(false);
    const [showAssginChat, setShowAssginChat] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [addingfav, setAddingFav] = useState(false);
    const [favoriteAction, setfavoriteAction] = useState(true);
    const [pinnedAction, setPinnedAction] = useState(true);
    const [pinning, setPinning] = useState(false);
    const [bulkActionState, setBulkActionState] = useState({});
    const [deletingConversation, setdeletingConversation] = useState(false);
    const [sendingBulkTemplates, setSendingBulkTemplates] = useState(
      defaultSendingTemplate
    );
    // const [hasBoradCast, setHasBoradCast] = useState(false);
    const [customerStatus, setCustomerStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [bulkEditState, setBulkEditState] = useState({});
    const [showTemplateButton, setShowTemplateButton] = useState(false);
    const [toggleDeleteDialog, setToggleDeleteDialog] = useState(false);
    const [selectedAction, setSelectedAction] = useState(false);
    const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
    const addingTeamMember = useSelector(
      (state) => state.settings.addingTeamMember
    );
    const globalPopupStyle =
      useSelector((state) => state.helper.globalPopupStyle) || {};
    const theme = useTheme();
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const bulkActionRef = useRef();
    const location = useLocation();

    React.useEffect(() => {
      if (isArrayWithValues(reduxTeamMembers))
        setTeamMembers([...reduxTeamMembers]);
    }, [reduxTeamMembers]);
    useEffect(async () => {
      // if (isIpad) {
      // setHideTabs(true)
      let { hash } = location || {};

      if (hasBoradCast) {
        setTimeout(() => {
          setHasBoradCast(true);
          setSelectedAction("add_to_group");
        }, [1000]);
      }
      // if (location?.search?.includes("?Broadcast") && !isIpad) {

      // }

      // console.log(searchParams(location))
      // }
      return () => {
        setShowAction(false);
        setHasBoradCast(false);
      };
    }, [isIpad, location]);

    // useEffect(() => {
    //   setTimeout(() => {
    //     // setHasBoradCast(true);
    //     setHasBoradCast(false);
    //     return () => {
    //       setShowAction(false)
    //     }
    //     // setSelectedAction("add_to_group");
    //   }, [1000]);
    // }, []);

    useMemo(() => {
      setfavoriteAction(!selectedCustomer.every((obj) => obj?.favorite));
      setPinnedAction(!selectedCustomer.every((obj) => obj?.pinned));
      setCustomerStatus({
        follow_up: selectedCustomer.every((obj) => obj?.status === "follow_up"),
        spam: selectedCustomer.every((obj) => obj?.status === "spam"),
      });
    }, [selectedCustomer]);

    useEffect(() => {
      if (isArrayWithValues(selectedCustomer))
        setSelectedCustomer((pre) =>
          pre.map((obj) => allReduxConversations.find((o) => o.id === obj?.id))
        );
      return () => {
        setShowAction(false);
        setHasBoradCast(false);
      };
    }, [allReduxConversations]);

    const clearBulk = () => {
      setShowManage(false);
      setShowAction(false);
      setSelectedCustomer([]);
      setHasBoradCast(false);
      // setCustomerView({});
    };

    const setDefaultState = () => {
      setBulkActionState({});
      setSendingBulkTemplates(defaultSendingTemplate);

      // setSelectedAction(false);
      // setShowAction(false);
      // setSelectedCustomer([]);
      // setCustomerStatus({});
    };

    useMemo(() => {
      setDefaultState();
    }, [selectedAction]);
    const onSendBulkTemplate = async (e) => {
      // let params = isArrayWithValues(bulkSelectedGroup)
      //   ? {
      //       body: {
      //         custom_filter: [
      //           {
      //             key: "broadcast",
      //             value: bulkSelectedGroup?.map((obj) => obj?.id)?.join(","),
      //             compare: "LIKE",
      //           },
      //         ],
      //       },
      //     }
      //   : { includes: selectedCustomer?.map((i) => i.id)?.join() };
      let success = false;
      if (isArrayWithValues(bulkSelectedGroup)) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Bulk messages initiated Successfully!",
            severity: "success",
          })
        );
        await clearBulk();
        setShowAction(false);
        setBulkActionState({});
        setLoading(false);
        setBulkActionState({});
        setSelectedAction(false);
        setSelectedCustomer([]);
        for await (const groupObj of bulkSelectedGroup) {
          let data = await sendCampaign({
            channel: "whatsapp",
            params: {
              body: {
                custom_filter: [
                  {
                    key: "broadcast",
                    value: groupObj?.id,
                    compare: "LIKE",
                  },
                ],
              },
            },
            // : { includes: selectedCustomer?.map((i) => i.id)?.join() },
            template: e,
            type: isArrayWithValues(bulkSelectedGroup) ? "broadcast" : "bulk",
          });
          if (data.success) success = true;
          else success = false;
        }
      } else {
        let data = await sendCampaign({
          channel: "whatsapp",
          params: {
            includes: selectedCustomer?.map((i) => i.id)?.join(),
          },
          // : { includes: selectedCustomer?.map((i) => i.id)?.join() },
          template: e,
          type: isArrayWithValues(bulkSelectedGroup) ? "broadcast" : "bulk",
        });
        if (data.success) success = true;
        else success = false;
      }

      if (success) {
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Internal Sever Error",
            severity: "error",
          })
        );
      }
      ///
    };

    const addToFavorite = async (val) => {
      const showToast = (length) => {
        dispatch(
          setGlobalToast({
            show: true,
            message: `${length} Customers  ${
              favoriteAction ? "Added to favorite" : "Remove from favorite"
            }`,
            severity: "success",
          })
        );
      };
      let actionArray = selectedCustomer.map((obj) => ({
        id: obj?.id,
        platform: obj?.platform,
        data: { favorite: val },
      }));
      await dispatch(updateChatCustomer(actionArray, setAddingFav, showToast));
      await clearBulk();
    };
    const addToPined = (val) => {
      if (val) {
        if (
          allReduxConversations?.filter((obj) => obj?.pinned)?.length > 3 ||
          selectedCustomer.filter((obj) => !obj?.pinned)?.length > 3
        ) {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Cannot pinned more than 3 chats`,
              severity: "error",
            })
          );
          clearBulk();
          return;
        }
      }
      const showToast = (length) => {
        dispatch(
          setGlobalToast({
            show: true,
            message: `${length} Customers  ${
              favoriteAction ? "Added to Pinned" : "Remove from pinned"
            }`,
            severity: "success",
          })
        );
      };
      let actionArray = selectedCustomer.map((obj, index) => ({
        id: obj?.id,
        platform: obj?.platform,
        data: { pinned: val ? Date.now() + index : val },
      }));
      dispatch(updateChatCustomer(actionArray, setPinning, showToast));
      clearBulk();
    };

    const bulkActionFunction = async () => {
      let data = { ...bulkActionState };
      console.log(data);
      dispatch(
        bulkActionChats({
          data: {
            update: selectedCustomer?.map((obj) => {
              return {
                id: obj?.id,
                ...data,
              };
            }),
          },
          setLoading,
          onSuccess: () => {
            dispatch(
              setGlobalToast({
                show: true,
                message: `${
                  selectedCustomer?.length || ""
                } chats edited successfully`,
                severity: "success",
              })
            );
            clearBulk();
            setLoading(false);
            setBulkActionState({});
            setSelectedAction(false);
            setShowAction(false);
            setSelectedCustomer([]);
          },
        })
      );

      // if (bulkActionState?.favorite) {
      //   data.favorite = favoriteAction;
      // }
      // if (bulkActionState?.pinned) {
      //   data.pinned = pinnedAction;
      // }
      // if (
      //   customerStatus?.follow_up &&
      //   bulkActionState?.status === "follow_up"
      // ) {
      //   data.status = "";
      // }
      // if (customerStatus?.spam && bulkActionState?.status === "spam") {
      //   data.status = "";
      //   delete data.contact_type;
      // }
      // // if (!customerStatus?.spam || bulkActionState?.status !== "spam") {
      // //   delete data.contact_type
      // // }
      // setLoading(true);

      // if (isArrayWithValues(selectedCustomer)) {
      //   for await (const iterator of selectedCustomer) {
      //     // let arr = Array.isArray(iterator?.label) ? iterator?.label : [];
      //     // console.log(arr, arr.push(obj.id));
      //     // arr.push(obj.id);

      //     // return
      //     await dispatch(
      //       updateChatObject(iterator, {
      //         payload: {
      //           ...data,
      //           // label: [...new Set(arr)],
      //         },
      //       })
      //     );
      //     // onClose();
      //   }
      // }
    };

    // const actionFire =()=>{
    //   switch (selectedAction) {
    //     case "send_template":
    //       return {
    //         label: "Send Template"

    //       }

    //       break;

    //     default:
    //       break;
    //   }
    // }

    // const list = [
    //   { label: "Send Template" },
    //   { label: "Add to group" },
    //   { label: "Assigned Chats" },
    //   { label: "Add to favorite" },
    //   { label: "Add to pinned" },
    //   { label: "Delete Chats" },
    // ]

    // const fav =
    const onBulkEdit = (payload) => {
      setBulkActionState(payload);
    };
    return (
      <Box sx={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            lg={4}
            xs={!selectedAction ? 12 : 0}
            sx={{
              borderRight: "0.3px solid #e8e8e8",
              display: selectedAction && isIpad ? "none" : "block",
            }}
          >
            {/* <BulkActionOptions /> */}
            <Stack
              direction={"row"}
              sx={{
                height: headerHeight,
                borderBottom: "0.3px solid #e8e8e8",
                py: 4,
                px: 4,
              }}
              justifyContent="space-between"
              alignItems={"center"}
              rowGap={2}
              // sx={{
              //   height: "116px",
              //   borderBottom: "0.3px solid #e8e8e8",
              //   px: isIpad ? 1 : 3,
              // }}
              // justifyContent="center"
              // alignContent={"space-between"}
              // rowGap={2}
              // direction={isIpad ? "row" : "column"}
            >
              {isIpad && (
                <IconButton onClick={() => setShowAction(false)}>
                  <ArrowBackIosNew />
                </IconButton>
              )}
              <Stack rowGap={2}>
                <Typography variant="h4" color={"text.secondary"}>
                  Actions settings
                </Typography>
                <Typography variant="h6" color={"text.secondary"}>
                  Select the action you want to make
                </Typography>
              </Stack>
            </Stack>
            <Box sx={{ bgcolor: "background.paper" }}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItemButton
                  sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                  onClick={() => {
                    setSelectedAction("send_template");
                    setShowBulkTemplate(true);
                    // setDefaultState()
                  }}
                  selected={"send_template" === selectedAction}
                >
                  <ListItemAvatar
                    sx={{ color: "primary.main", opacity: "0.8" }}
                  >
                    <DescriptionOutlined color={"#808080"} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      "	.MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: "500",
                      },
                      color: "text.secondary",
                    }}
                    primary="Send Template"
                  />
                  {/* <IconButton> */}
                  <NavigateNext fontSize="large" style={{ color: "#808080" }} />
                  {/* </IconButton> */}
                </ListItemButton>
                {!isArrayWithValues(bulkSelectedGroup) && (
                  <>
                    <ListItemButton
                      sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                      selected={selectedAction === "add_to_group"}
                      onClick={() => {
                        setSelectedAction("add_to_group");
                        setCustomerId((pre) => !pre);
                        // setDefaultState()
                      }}
                    >
                      <ListItemAvatar
                        sx={{ color: "primary.main", opacity: "0.8" }}
                      >
                        <PeopleAltOutlined />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          "	.MuiListItemText-primary": {
                            fontSize: "16px",
                            fontWeight: "500",
                          },
                          color: "text.secondary",
                        }}
                        primary="Add to BroadcastList"
                      />
                      {/* <IconButton> */}
                      <NavigateNext
                        fontSize="large"
                        style={{ color: "#808080 " }}
                      />
                      {/* </IconButton> */}
                    </ListItemButton>
                    {isAdmin && isArrayWithValues(teamMembers) ? (
                      <ListItemButton
                        sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                        selected={selectedAction === "bulk_assign"}
                        onClick={() => {
                          setSelectedAction("bulk_assign");
                          setShowAssginChat(true);
                          // setDefaultState()
                        }}
                      >
                        <ListItemAvatar
                          sx={{ color: "primary.main", opacity: "0.8" }}
                        >
                          <AssignmentIndOutlined />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            "	.MuiListItemText-primary": {
                              fontSize: "16px",
                              fontWeight: "500",
                            },
                            color: "text.secondary",
                          }}
                          primary="Assign to team member"
                        />
                        {/* <IconButton> */}
                        <NavigateNext
                          fontSize="large"
                          style={{ color: "#808080 " }}
                        />
                        {/* </IconButton> */}
                      </ListItemButton>
                    ) : null}
                    <ListItemButton
                      sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                      selected={selectedAction === "bulk_action"}
                      onClick={() => {
                        setSelectedAction("bulk_action");
                        // setDefaultState()
                      }}
                      // selected={selectedIndex === 0}
                      // onClick={() => addToFavorite(favoriteAction)}
                    >
                      <ListItemAvatar
                        sx={{ color: "primary.main", opacity: "0.8" }}
                      >
                        <PlaylistAddCheck />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          "	.MuiListItemText-primary": {
                            fontSize: "16px",
                            fontWeight: "500",
                          },
                          color: "text.secondary",
                        }}
                        primary={"Bulk Action"}
                      />
                      {/* <IconButton> */}
                      <NavigateNext
                        fontSize="large"
                        style={{ color: "#808080 " }}
                      />
                      {/* </IconButton> */}
                    </ListItemButton>

                    {false && (
                      <ListItemButton
                        sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                        selected={selectedAction === "merge_view"}
                        onClick={() => {
                          setSelectedAction("merge_view");
                          // setDefaultState()
                        }}
                        // selected={selectedIndex === 0}
                        // onClick={() => addToFavorite(favoriteAction)}
                      >
                        <ListItemAvatar
                          sx={{ color: "primary.main", opacity: "0.8" }}
                        >
                          <Merge />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            "	.MuiListItemText-primary": {
                              fontSize: "16px",
                              fontWeight: "500",
                            },
                            color: "text.secondary",
                          }}
                          primary={"Create Merge View"}
                        />

                        <NavigateNext
                          fontSize="large"
                          style={{ color: "#808080 " }}
                        />
                        {/* </IconButton> */}
                      </ListItemButton>
                    )}
                    {/*  <LoadingButton
                        disabled={addingfav || pinning}
                        loading={pinning}
                        startIcon={<Delete />}
                        onClick={() => {
                          setToggleDeleteDialog(true);
                        }}
                      >
                        Bulk Delete
                      </LoadingButton> */}
                    <ListItemButton
                      sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                      // selected={selectedAction === "bulk_delete"}
                      onClick={() => {
                        setToggleDeleteDialog(true);
                        setSelectedAction("bulk_delete");
                        // setDefaultState()
                      }}
                      // selected={selectedIndex === 0}
                      // onClick={() => addToFavorite(favoriteAction)}
                    >
                      <ListItemAvatar
                        sx={{ color: "primary.main", opacity: "0.8" }}
                      >
                        <Delete />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          "	.MuiListItemText-primary": {
                            fontSize: "16px",
                            fontWeight: "500",
                          },
                          color: "text.secondary",
                        }}
                        primary={"Bulk delete"}
                      />
                      {/* <IconButton> */}
                      {/* <NavigateNext
                        fontSize="large"
                        style={{ color: "#808080 " }}
                      /> */}
                      {/* </IconButton> */}
                    </ListItemButton>
                  </>
                )}
                {/* <ListItemButton
                  sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                  // selected={selectedIndex === 0}
                  onClick={() => addToFavorite(favoriteAction)}
                >
                  <ListItemAvatar
                    sx={{ color: "primary.main", opacity: "0.8" }}
                  >
                    <FavoriteBorder />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      "	.MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: "500",
                      },
                      color: "text.secondary",
                    }}
                    primary={`${favoriteAction ? "Add to" : "Remove from"
                      } favorite`}
                  />
                  <IconButton>
                    <NavigateNext fontSize="large" />
                  </IconButton>
                </ListItemButton>
                <ListItemButton
                  sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                  // selected={selectedIndex === 0}
                  onClick={() => addToPined(pinnedAction)}
                >
                  <ListItemAvatar
                    sx={{ color: "primary.main", opacity: "0.8" }}
                  >
                    <PushPinOutlined />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      "	.MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: "500",
                      },
                      color: "text.secondary",
                    }}
                    primary={`${pinnedAction ? "Add to" : "Remove from"
                      } pinned`}
                  />
                  <IconButton>
                    <NavigateNext fontSize="large" />
                  </IconButton>
                </ListItemButton>
                <ListItemButton
                  sx={{ borderBottom: "0.3px solid #e8e8e8", py: 4 }}
                  // selected={selectedIndex === 0}
                  onClick={() => {
                    setToggleDeleteDialog(true);
                  }}
                >
                  <ListItemAvatar
                    sx={{ color: "primary.main", opacity: "0.8" }}
                  >
                    <DeleteOutlineOutlined />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      "	.MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: "500",
                      },
                      color: "text.secondary",
                    }}
                    primary={"Delete"}
                  />
                  <IconButton>
                    <NavigateNext fontSize="large" />
                  </IconButton>
                </ListItemButton> */}
              </List>
            </Box>
          </Grid>
          <Grid item lg={8} sx={selectedAction && isIpad ? 12 : 0}>
            <Stack
              direction={"row"}
              sx={{
                height: headerHeight,
                borderBottom: "0.3px solid #e8e8e8",
                py: 4,
                px: 4,
              }}
              justifyContent="space-between"
              alignItems={"center"}
              rowGap={2}
              // alignItems=""
            >
              {isIpad && (
                <IconButton onClick={() => setSelectedAction(false)}>
                  <ArrowBackIosNew />
                </IconButton>
              )}

              <Stack rowGap={2}>
                <Typography variant="h4" color={"text.secondary"}>
                  {getBulkActionHeading(selectedAction)?.primary || ""}
                </Typography>
                <Typography variant="h6" color={"text.secondary"}>
                  {getBulkActionHeading(selectedAction)?.secondary || ""}
                </Typography>
              </Stack>
              {selectedAction === "send_template" && showTemplateButton && (
                <Button
                  variant="contained"
                  onClick={() => {
                    if (bulkActionRef.current)
                      bulkActionRef.current.onSendTemplete();
                  }}
                >
                  Send Template
                </Button>
              )}
              {selectedAction === "bulk_assign" && (
                <LoadingButton
                  loading={loading || addingTeamMember}
                  variant="contained"
                  onClick={async () => {
                    await bulkActionRef.current.onAssgin();
                  }}
                >
                  Assign
                </LoadingButton>
              )}
              {selectedAction === "bulk_action" && (
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={() => {
                    bulkActionFunction();
                  }}
                >
                  Bulk Update
                </LoadingButton>
              )}
              {selectedAction === "merge_view" && (
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    if (bulkActionRef.current)
                      bulkActionRef.current.onCreateMerge();
                  }}
                >
                  {showExisting ? "Add" : "Create"} Merge
                </LoadingButton>
              )}
            </Stack>

            <TabPanel
              value={"add_to_group"}
              index={selectedAction}
              style={{
                maxHeight: isIpad ? "71svh" : "66svh",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              <BroadcastList
                asBox={true}
                selectedCustomers={selectedCustomer}
                hasBoradCast={hasBoradCast}
                setHasBoradCast={setHasBoradCast}
                clearBulk={clearBulk}
              />
            </TabPanel>
            <TabPanel
              value={"merge_view"}
              index={selectedAction}
              style={{
                maxHeight: isIpad ? "71svh" : "66svh",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              <MergeCreate
                selectedCustomers={selectedCustomer}
                setLoading={setLoading}
                ref={bulkActionRef}
                clearBulk={clearBulk}
                showExisting={showExisting}
                setShowExisting={setShowExisting}
                setCustomerView={setCustomerView}
                setListTab={setListTab}
              />
            </TabPanel>
            <TabPanel
              value={"send_template"}
              index={selectedAction}
              style={{
                maxHeight: isIpad ? "71svh" : "66svh",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              {checkSamePlatform(
                [...selectedCustomer, ...bulkSelectedGroup]?.filter(Boolean)
              ) === "whatsapp" ? (
                <TempleteList
                  open={showBulkTemplate}
                  handleClose={() => setShowBulkTemplate(false)}
                  setParentTemplate={onSendBulkTemplate}
                  hideRejected
                  asBox={true}
                  ref={bulkActionRef}
                  setShowTemplateButton={setShowTemplateButton}
                  newMediaLibrary={true}
                />
              ) : (
                <EmptyState
                  heading={"Send Pre-made Messages to WhatsApp Users Only"}
                  icon={<WhatsappSvg height={40} width={40} />}
                  button={
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedCustomer((pre) =>
                          pre?.filter((obj) => obj?.platform === "whatsapp")
                        );
                      }}
                    >
                      Remove non-whatsapp users
                    </Button>
                  }
                />
              )}
            </TabPanel>

            <TabPanel
              value={"bulk_assign"}
              index={selectedAction}
              style={{
                maxHeight: isIpad ? "71svh" : "66svh",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              <AssignChats
                clearBulk={clearBulk}
                teamMembers={teamMembers}
                setSelectedCustomer={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
                onClose={() => setShowAssginChat(false)}
                ref={bulkActionRef}
                setLoading={setLoading}
              />
            </TabPanel>
            <TabPanel value={"bulk_action"} index={selectedAction}>
              <Box p={4}>
                <Stack>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={Boolean(
                          bulkActionState?.status === "follow_up"
                        )}
                        onChange={() => {
                          onBulkEdit({ status: "follow_up" });
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {/* {customerStatus?.follow_up
                          ? "Remove from "
                          : "Mark as "}{" "} */}
                        Mark as Follow up{" "}
                        <MarkEmailRead
                        // style={{ height: "10px", height: "10px", color: "#ffd700" }}
                        />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    // value="spam"
                    control={
                      <Radio
                        checked={bulkActionState?.status === "spam"}
                        onChange={() => {
                          onBulkEdit({ status: "spam" });
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {/* {customerStatus?.spam ? "Remove from " : "Mark as "}{" "} */}
                        Mark as spam
                        <Report
                        // style={{ height: "10px", height: "10px", color: "#FF0000" }}
                        />
                      </Typography>
                    }
                  />{" "}
                  {false && (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={Boolean(bulkActionState?.favorite)}
                            onChange={(e) => {
                              onBulkEdit({ favorite: e.target.checked });
                              // e.target.checked
                              //   ? onBulkEdit({ status: "spam" })
                              //   : onBulkEdit({ status: "" });
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              color: "text.secondary",
                              fontSize: "14px",
                              fontWeight: "500",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {`${
                              favoriteAction ? "Add to" : "Remove from"
                            } favorite`}{" "}
                            <Favorite style={{ color: "#808080" }} />
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={Boolean(bulkActionState?.pinned)}
                            onChange={(e) => {
                              onBulkEdit({ pinned: e.target.checked });
                              // e.target.checked
                              //   ? onBulkEdit({ status: "spam" })
                              //   : onBulkEdit({ status: "" });
                            }}
                            disabled={
                              allReduxConversations?.filter(
                                (obj) => obj?.pinned
                              )?.length > 3 ||
                              selectedCustomer.filter((obj) => !obj?.pinned)
                                ?.length > 3
                            }
                          />
                        }
                        disabled={
                          allReduxConversations?.filter((obj) => obj?.pinned)
                            ?.length > 3 ||
                          selectedCustomer.filter((obj) => !obj?.pinned)
                            ?.length > 3
                        }
                        label={
                          <Typography
                            sx={{
                              color: "text.secondary",
                              fontSize: "14px",
                              fontWeight: "500",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {`${
                              pinnedAction ? "Add to" : "Remove from"
                            } pinned`}{" "}
                            <PushPin
                              style={{
                                // position: "absolute",
                                // top: "5px",
                                // right: "5px",
                                color: "#808080",
                                transform: "rotate(45deg)",
                              }}
                            />
                          </Typography>
                        }
                      />
                    </>
                  )}
                  <FormControlLabel
                    // value="customer"
                    control={
                      <Radio
                        checked={Boolean(
                          bulkActionState?.contact_type === "customer"
                        )}
                        onChange={(e) => {
                          onBulkEdit({ contact_type: "customer" });
                          // e.target.checked
                          //   ? onBulkEdit({ status: "spam" })
                          //   : onBulkEdit({ status: "" });
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Customer
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    // value="lead"
                    control={
                      <Radio
                        checked={Boolean(
                          bulkActionState?.contact_type === "lead"
                        )}
                        onChange={(e) => {
                          onBulkEdit({ contact_type: "lead" });
                          // e.target.checked
                          //   ? onBulkEdit({ status: "spam" })
                          //   : onBulkEdit({ status: "" });
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Lead
                      </Typography>
                    }
                  />
                </Stack>

                <Typography></Typography>
                {/* <FormControl>
                
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={bulkActionState?.status}
                    onChange={(e) => onBulkEdit({ status: e.target.value })}
                  >
                    <FormControlLabel
                      value={"follow_up"}
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: "14px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {customerStatus?.follow_up
                            ? "Remove from "
                            : "Mark as "}{" "}
                          Follow up{" "}
                          <MarkEmailRead
                          // style={{ height: "10px", height: "10px", color: "#ffd700" }}
                          />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="spam"
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: "14px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {customerStatus?.spam ? "Remove from " : "Mark as "}{" "}
                          spam
                          <Report
                          // style={{ height: "10px", height: "10px", color: "#FF0000" }}
                          />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <FormGroup>
             
                  <FormControlLabel
                    control={
                      <Radio
                        checked={bulkActionState?.favorite}
                        onChange={(e) => {
                          onBulkEdit({ favorite: e.target.checked });
                          // e.target.checked
                          //   ? onBulkEdit({ status: "spam" })
                          //   : onBulkEdit({ status: "" });
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {`${
                          favoriteAction ? "Add to" : "Remove from"
                        } favorite`}{" "}
                        <Favorite style={{ color: "#808080" }} />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={bulkActionState?.pinned}
                        onChange={(e) => {
                          onBulkEdit({ pinned: e.target.checked });
                          // e.target.checked
                          //   ? onBulkEdit({ status: "spam" })
                          //   : onBulkEdit({ status: "" });
                        }}
                        disabled={
                          allReduxConversations?.filter((obj) => obj.pinned)
                            ?.length > 3 ||
                          selectedCustomer.filter((obj) => !obj.pinned)
                            ?.length > 3
                        }
                      />
                    }
                    disabled={
                      allReduxConversations?.filter((obj) => obj.pinned)
                        ?.length > 3 ||
                      selectedCustomer.filter((obj) => !obj.pinned)?.length > 3
                    }
                    label={
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {`${pinnedAction ? "Add to" : "Remove from"} pinned`}{" "}
                        <PushPin
                          style={{
                            // position: "absolute",
                            // top: "5px",
                            // right: "5px",
                            color: "#808080",
                            transform: "rotate(45deg)",
                          }}
                        />
                      </Typography>
                    }
                  />
                </FormGroup>
                <Collapse
                  in={
                    customerStatus?.spam || bulkActionState?.status !== "spam"
                  }
                >
                  <Box sx={{ my: 3 }}>
                    <Typography variant="h6">Contact type</Typography>
                    <FormControl>
                     
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={bulkActionState?.contact_type}
                        onChange={(e) =>
                          onBulkEdit({ contact_type: e.target.value })
                        }
                      >
                        <FormControlLabel
                          value="customer"
                          control={<Radio />}
                          label={
                            <Typography
                              sx={{
                                color: "text.secondary",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Customer
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="lead"
                          control={<Radio />}
                          label={
                            <Typography
                              sx={{
                                color: "text.secondary",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Lead
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
               
                  </Box>
                </Collapse> */}
              </Box>
            </TabPanel>

            {/* <Box hidden={!customerId}>
              <CustomerLabelView
                id={customerId}
                onClose={() => {
                  setCustomerId(false);
                  clearBulk();
                }}
                whatsappCustomerArray={selectedCustomer}
                broadcast={true}
              />
            </Box> */}
            {/* </Dialog> */}
            <Box hidden={!showBulkTemplate}></Box>
            {/* <Dialog
              maxWidth="sm"
              fullWidth
              sx={{ ...dialogStyle }}
              open={showAssginChat}
              onClose={() => setShowAssginChat(false)}
              {...globalPopupStyle}
              PaperComponent={Paper}
            > */}
            {/* <Box hidden={!clearBulk}>
              <AssignChats
                clearBulk={clearBulk}
                teamMembers={teamMembers}
                setSelectedCustomer={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
                onClose={() => setShowAssginChat(false)}
              />
            </Box> */}
            {/* </Dialog> */}

            <Dialog
              open={sendingBulkTemplates.loading}
              // onClose={close}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">
                {"Sending Bulk Template Messages"}
              </DialogTitle>
              <DialogContent>
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  sx={{ margin: "10px auto", alignSelf: "center" }}
                >
                  <Whatsapp
                    width="130px"
                    height="130px"
                    // className="animated_background_color"
                  />
                </Stack>
                <Box sx={{ mt: 6 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={sendingBulkTemplates.percentage}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "right",
                      mr: 2,
                      mt: 2,
                      fontWeight: "600",
                    }}
                  >
                    {sendingBulkTemplates.totalSent} /{" "}
                    {sendingBulkTemplates.total}
                  </Typography>
                </Box>
                {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
              </DialogContent>
            </Dialog>
            <DeletePopup
              toggleDeleteDialog={toggleDeleteDialog}
              setToggleDeleteDialog={setToggleDeleteDialog}
              dispatch={dispatch}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              setShowAction={setShowAction}
              setShowManage={setShowManage}
              clearBulk={clearBulk}
              asBox={true}
            />
          </Grid>
        </Grid>
        {/* {addToPined} */}
        {isIpad ? (
          <Box>
            <Menu
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={onClose}
              onClick={onClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32)) ",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <Box sx={{ bgcolor: "background.paper" }}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItemButton
                    // selected={selectedIndex === 0}
                    onClick={() => setShowBulkTemplate(true)}
                  >
                    <ListItemIcon>
                      <DescriptionOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Send Template" />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 0}
                    onClick={() => setCustomerId((pre) => !pre)}
                  >
                    <ListItemIcon>
                      <PeopleAltOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Group" />
                  </ListItemButton>
                  {isAdmin && isArrayWithValues(teamMembers) ? (
                    <ListItemButton
                      // selected={selectedIndex === 0}
                      onClick={() => setShowAssginChat(true)}
                    >
                      <ListItemIcon>
                        <AssignmentIndOutlined />
                      </ListItemIcon>
                      <ListItemText primary=" Assigned Chats" />
                    </ListItemButton>
                  ) : null}
                  <ListItemButton
                    // selected={selectedIndex === 0}
                    onClick={() => addToFavorite(favoriteAction)}
                  >
                    <ListItemIcon>
                      <FavoriteBorder />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${
                        favoriteAction ? "Add to" : "Remove from"
                      } favorite`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 0}
                    onClick={() => addToPined(pinnedAction)}
                  >
                    <ListItemIcon>
                      <PushPinOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${
                        pinnedAction ? "Add to" : "Remove from"
                      } pinned`}
                    />
                  </ListItemButton>
                </List>
              </Box>
            </Menu>
          </Box>
        ) : null}
        {!isIpad ? (
          <>
            <Stack
              hidden={true}
              sx={{ minHeight: "60svh", width: "100%", display: "none" }}
              justifyContent="center"
              alignItems={"center"}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "100%" }}
              >
                <ChatIllustration height={200} width={200} />
                <Typography variant="h5" mb={3}>
                  {selectedCustomer.length} conversations selected
                </Typography>
                <Box>
                  <Grid container spacing={4} rowSpacing={3}>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={6}
                    >
                      <Button
                        disabled={addingfav || pinning}
                        onClick={() => setShowBulkTemplate(true)}
                        startIcon={<DescriptionOutlined />}
                      >
                        Send Template
                      </Button>
                    </Grid>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={6}
                    >
                      <Button
                        disabled={addingfav || pinning}
                        startIcon={<PeopleAltOutlined />}
                        onClick={() => setCustomerId((pre) => !pre)}
                      >
                        Add to Group
                      </Button>
                    </Grid>
                    {isAdmin && isArrayWithValues(teamMembers) ? (
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                        xs={6}
                      >
                        <Button
                          disabled={addingfav || pinning}
                          startIcon={<AssignmentIndOutlined />}
                          onClick={() => setShowAssginChat(true)}
                        >
                          Assigned Chats
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={6}
                    >
                      <LoadingButton
                        disabled={addingfav || pinning}
                        loading={addingfav}
                        startIcon={<FavoriteBorder />}
                        onClick={() => addToFavorite(favoriteAction)}
                      >
                        {favoriteAction ? "Add to" : "Remove from"} favorite
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={6}
                    >
                      <LoadingButton
                        disabled={addingfav || pinning}
                        loading={pinning}
                        startIcon={<PushPinOutlined />}
                        onClick={() => addToPined(pinnedAction)}
                      >
                        {pinnedAction ? "Add to" : "Remove from"} pinned
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={6}
                    >
                      <LoadingButton
                        disabled={addingfav || pinning}
                        loading={pinning}
                        startIcon={<Delete />}
                        onClick={() => {
                          setToggleDeleteDialog(true);
                        }}
                      >
                        Bulk Delete
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Divider my={3} />
                  <Button
                    disabled={addingfav || pinning}
                    startIcon={<Close />}
                    onClick={() => setSelectedCustomer([])}
                  >
                    Clear
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </>
        ) : null}
      </Box>
    );
  }
);

const getBulkActionHeading = (key) => {
  switch (key) {
    case "send_template":
      return {
        primary: "Send Template",
        secondary: "Select the template you want to send",
      };
      break;
    case "add_to_group":
      return {
        primary: "Add to BroadcastList",
        secondary: "Select the group you want to send",
      };
      break;
    case "bulk_action":
      return {
        primary: "Bulk Actions",
        secondary: "Select the action you want to perform with the contacts",
      };
      break;
    case "bulk_assign":
      return {
        primary: "Bulk Assign",
        secondary: "Select the team member you want to assign selected chats",
      };
      break;
    case "merge_view":
      return {
        primary: "Create Merge View",
        secondary:
          "Merge multiple conversations in one window to mointor chats",
      };
      break;

    default:
      break;
  }
};

const DeletePopup = ({
  toggleDeleteDialog,
  setToggleDeleteDialog,
  dispatch,
  selectedCustomer,
  setSelectedCustomer,
  setShowAction,
  setShowManage,
  asBox,
  clearBulk,
}) => {
  const [deletingConversation, setdeletingConversation] = useState(false);

  // if (asBox) return <DialogContent />
  return (
    <Dialog
      open={toggleDeleteDialog}
      components={Box}
      onClose={() => {
        setToggleDeleteDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={dialogStyle}
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete conversation ?
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setToggleDeleteDialog(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={deletingConversation}
          onClick={() => {
            // setdeletingConversation(true);
            dispatch(
              deleteConversation(
                selectedCustomer,
                setToggleDeleteDialog,
                () => {
                  dispatch(
                    setGlobalToast({
                      show: true,
                      message: "Conversations Delete Successfully!",
                      severity: "success",
                    })
                  );
                  // setdeletingConversation(false);
                  setSelectedCustomer([]);
                  // selectedCustomer({});
                  setToggleDeleteDialog(false);
                  setShowAction && setShowAction(false);
                  setShowManage && setShowManage(false);
                  clearBulk && clearBulk();
                }
              )
            );
          }}
          autoFocus
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const AssignChats = memo(
  forwardRef(
    (
      {
        teamMembers: reduxTeamMembers,
        onClose,
        selectedCustomer,
        clearBulk,
        setSelectedCustomer,
        setLoading,
      },
      ref
    ) => {
      const dispatch = useDispatch();
      const [selectedMembers, setSelectedMember] = useState("");
      const [teamMembers, setTeamMembers] = useState([]);
      const [alreadyAssignChats, setAlreadyAssignChats] = useState([]);
      const [viewAlreadyAssignChats, setviewAlreadyAssignChats] =
        useState(false);
      // const [loading, setLoading] = useState(false);
      const onSelectMember = (id) => {
        setSelectedMember(id);
        // if (!selectedMembers.includes(id)) {
        //   setSelectedMember((pre) => [...pre, id]);
        // } else {
        //   setSelectedMember((pre) => pre.filter((preId) => preId !== id));
        // }
      };
      useMemo(() => {
        if (isArrayWithValues(reduxTeamMembers))
          setTeamMembers(reduxTeamMembers);
      }, [reduxTeamMembers]);

      const onAssgin = async () => {
        setLoading(true);
        let newTeamMemberArr = [];
        let idsSelectedCustomer = selectedCustomer?.map((obj) => obj?.id);

        for (let index = 0; index < teamMembers.length; index++) {
          let element = teamMembers[index];
          if (selectedMembers === element.id) {
            let assigned_chat_customers = element?.assignedChatCustomers || [];
            assigned_chat_customers = [
              ...assigned_chat_customers?.filter(
                (o) => !idsSelectedCustomer?.includes(o.value)
              ),
              ...selectedCustomer.map((obj) => ({
                label: getNameFromPlatform(obj),
                value: obj.id,
                platform: obj?.platform,
              })),
            ];
            element.assignedChatCustomers = assigned_chat_customers;
          } else {
            let assigned_chat_customers = element?.assignedChatCustomers || [];
            assigned_chat_customers = assigned_chat_customers?.filter(
              (o) => !idsSelectedCustomer?.includes(o.value)
            );
            //  [
            //   ...assigned_chat_customers,
            //   ...selectedCustomer.map((obj) => ({
            //     label: getNameFromPlatform(obj),
            //     value: obj.id,
            //   })),
            // ];
            element.assignedChatCustomers = assigned_chat_customers;
          }
          newTeamMemberArr.push(element);
        }

        // return
        await dispatch(
          addTeamMember(
            newTeamMemberArr,
            () => {
              // onClose();
              // clearBulk();
            },
            () => {
              setLoading(false);
              clearBulk();
              dispatch(
                setGlobalToast({
                  show: true,
                  message: `${selectedCustomer?.length} customers Assigned to ${
                    teamMembers?.find((obj) => obj?.id === selectedMembers)
                      ?.first_name || ""
                  } ${
                    teamMembers?.find((obj) => obj?.id === selectedMembers)
                      ?.last_name || ""
                  }`,
                  severity: "success",
                })
              );
            }
          )
        );

        setLoading(false);
        // await clearBulk();
      };

      useMemo(() => {
        let teamMembersAssign = reduxTeamMembers
          ?.map((obj) =>
            obj?.assignedChatCustomers?.map((ob) => ({
              ...ob,
              teamMemberName: `${obj?.first_name} ${obj?.last_name}`,
              teamMemberId: obj?.id,
            }))
          )
          .flat();

        let alreadyAssigned = [];
        for (const customer of selectedCustomer) {
          let teamMemberObj = teamMembersAssign?.find(
            (obj) => obj.value === customer?.id
          );
          if (teamMemberObj) {
            alreadyAssigned.push({
              ...customer,
              teamMemberName: teamMemberObj?.teamMemberName,
              teamMemberId: teamMemberObj?.teamMemberId,
            });
          }
        }

        setAlreadyAssignChats(alreadyAssigned);
      }, [selectedCustomer]);

      const onRemove = (customer) => {
        setAlreadyAssignChats((pre) =>
          pre.filter((obj) => obj?.id !== customer?.id)
        );
        setSelectedCustomer((pre) =>
          pre.filter((obj) => obj?.id !== customer?.id)
        );
      };
      const onSearch = (e) => {
        if (e.target.value) {
          setTeamMembers(
            reduxTeamMembers?.filter((obj) =>
              `${obj.first_name || ""} ${obj?.last_name}`
                ?.toLowerCase()
                ?.includes(e.target.value?.toLowerCase())
            ) || []
          );
        } else setTeamMembers(reduxTeamMembers || []);
      };
      useImperativeHandle(ref, () => ({
        onAssgin() {
          onAssgin();
        },
      }));

      return (
        <DialogContent>
          {/* <DialogTitle>Assign Chats</DialogTitle> */}
          {!viewAlreadyAssignChats && (
            <>
              <Typography sx={{ mb: 2 }}>Your team members </Typography>
              <TextField
                size="small"
                fullWidth
                placeholder="Search"
                onChange={onSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              {isArrayWithValues(alreadyAssignChats) && (
                <Alert
                  severity="error"
                  sx={{
                    my: 2,
                    fontSize: "11px",
                    display: "flex",
                    alignItems: "center",
                    py: "2px",
                  }}
                  action={
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => setviewAlreadyAssignChats(true)}
                    >
                      View
                    </Button>
                  }
                >
                  {alreadyAssignChats?.length} selected contacts are already
                  assigned. New assign will override the previous one
                </Alert>
              )}
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {isArrayWithValues(teamMembers) ? <></> : null}
                {isArrayWithValues(teamMembers) &&
                  teamMembers?.map((member) => {
                    let value = member.id;
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem
                        key={value}
                        secondaryAction={
                          <Stack direction={"row"} alignItems="center" gap={3}>
                            {member?.assignedChatCustomers?.length}
                            <AvatarGroup max={4}>
                              <Avatar sx={{ width: 20, height: 20 }} />
                              <Avatar sx={{ width: 20, height: 20 }} />
                            </AvatarGroup>
                          </Stack>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={() => onSelectMember(member.id)}
                          dense
                          selected={selectedMembers === member.id}
                        >
                          <ListItemIcon>
                            <Radio
                              edge="start"
                              checked={selectedMembers === member.id}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <Avatar>{/* <ImageIcon /> */}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{
                              "	.MuiListItemText-primary": {
                                fontSize: "16px",
                                fontWeight: "500",
                              },
                            }}
                            id={labelId}
                            primary={`${member.first_name || ""} ${
                              member.last_name || ""
                            }`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
              {/* <DialogTitle>Assign Chats</DialogTitle> */}
              {/* <ol> */}
              {isArrayWithValues(alreadyAssignChats) && false ? (
                <>
                  <DialogTitle>Already assign chats</DialogTitle>

                  <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>Sr.</TableCell> */}
                          <TableCell>Name</TableCell>
                          <TableCell>Team Member</TableCell>
                          <TableCell></TableCell>
                          {/* <TableCell >Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {alreadyAssignChats.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell >{++index}</TableCell> */}
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>{row.teamMemberName}</TableCell>
                            <TableCell>
                              <Button
                                color="error"
                                variant="outlined"
                                onClick={() => onRemove(row)}
                              >
                                Remove
                              </Button>
                              {/* <IconButton onClick={() => onRemove(row)}>
                        <Close />
                      </IconButton> */}
                            </TableCell>
                            {/* <TableCell >{row.protein}</TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null}

              {/* {alreadyAssignChats.map((obj, index) => (
        <Stack direction={"row"} gap={2} alignItems="center">
          <Typography>{++index}</Typography>
          <Typography>
            {obj?.name} is Assigned to {obj?.teamMemberName}
          </Typography>{" "}
          <Button variant="outlined" color="error" size="small" >
            Remove
          </Button>
        </Stack>
      ))} */}
              {/* </ol> */}
              {false && (
                <StackRow>
                  <Button onClick={onClose}>Cancel</Button>
                  <LoadingButton
                    variant="contained"
                    onClick={onAssgin}
                    // loading={loading}
                  >
                    Assign
                  </LoadingButton>
                </StackRow>
              )}
            </>
          )}
          {viewAlreadyAssignChats && (
            <>
              <Stack direction={"row"} alignItems="center" gap={3}>
                <Button
                  startIcon={<ArrowBackIosNew />}
                  onClick={() => setviewAlreadyAssignChats(false)}
                >
                  Go back
                </Button>
              </Stack>

              <Stack rowGap={4}>
                {alreadyAssignChats?.map((obj) => {
                  return (
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction={"row"} alignItems="center" gap={3}>
                        <Avatar src={obj?.profile_pic} />
                        <Stack>
                          <Typography variant="h6">{obj.name}</Typography>
                          <Typography>
                            Assigned to :{obj?.teamMemberName}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={() => onRemove(obj)}
                      >
                        Remove from selection
                      </Button>
                    </Stack>
                  );
                })}
              </Stack>
            </>
          )}
        </DialogContent>
      );
    }
  )
);

const SyncDialog = ({
  closeSync,
  setShowSync,
  showSync,
  broadcastList,
  setSyncProgress,
  setChatsSheetLoading,
  chatSheetLoading,
  googleSheetIntegrated,
}) => {
  const [openSheetLoading, setOpenSheetLoading] = useState(false);
  const reduxGoogleSpreadsheet = useSelector(
    (state) => state.settings.googleSpreadsheet
  );
  const chatsSpreadsheetHistory = useSelector(
    (state) => state.chats.chatsSpreadsheetHistory
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openSheet = async () => {
    let { chats_spreadsheet_id } = reduxGoogleSpreadsheet || {};
    if (!chats_spreadsheet_id) {
      setOpenSheetLoading(true);
      chats_spreadsheet_id = await createSpreadsheet(
        dispatch,
        setOpenSheetLoading
      );
      await onSyncPress();
      setOpenSheetLoading(false);
    }
    // chats_spreadsheet_id = reduxGoogleSpreadsheet?.chats_spreadsheet_id
    window.open(
      `https://docs.google.com/spreadsheets/d/${chats_spreadsheet_id}/edit#gid=0`,
      "_blank"
    );
  };

  const onSyncPress = async () => {
    setSyncProgress(0);
    closeSync();
    let addedObjectIds = [];

    setChatsSheetLoading(true);

    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      // ip:
    };

    let location = await fetchIPInfo(historyObj.ip);
    let crmSheetCustomerErrors = [];
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    setSyncProgress(9);
    let allCustomers = (await getAllConversations()) || [];
    setSyncProgress(29);
    allCustomers = allCustomers?.map((obj) => {
      if (
        isArrayWithValues(Object.values(broadcastList || {})) &&
        isArrayWithValues(obj?.label)
      ) {
        for (const labelId of obj?.label || []) {
          let broadcastListObj = Object.values(broadcastList || {}).find(
            (o) => o.id === labelId
          );
          if (isObjWithValues(broadcastListObj)) {
            obj.labelArray = `${obj.labelArray || ""}${
              broadcastListObj?.label + ","
            }`;
          }
        }
      }

      obj.action = "synced";
      return obj;
    });

    // return;
    let newCreateedLabel = false;
    let newLabelObj = {};
    let { chats_spreadsheet_id } = reduxGoogleSpreadsheet;
    if (!chats_spreadsheet_id) {
      chats_spreadsheet_id = await createSpreadsheet(dispatch);
    }
    setSyncProgress(42);
    let spreadsheetData = await getChannelData(
      "google_sheets",
      `/spreadsheets/${chats_spreadsheet_id}/values:batchGet?majorDimension=ROWS&ranges=1:20000`
    );
    setSyncProgress(49);

    // return;
    let sheetRowsLength = spreadsheetData?.valueRanges?.[0]?.values?.length;
    if (
      isArrayWithValues(spreadsheetData.valueRanges) &&
      isArrayWithValues(spreadsheetData.valueRanges[0].values)
    ) {
      let bulkObj = getBulkCreateUpdateObject(
        spreadsheetData.valueRanges[0].values
      );

      // console.log(bulkObj, addLimitReached, "bulk obj before");
      // if (addLimitReached) bulkObj.create = [];
      if (
        isArrayWithValues(bulkObj.create) &&
        isArrayWithValues(allCustomers)
      ) {
        let remainCustomer = chatsLimit - allCustomers?.length;
        bulkObj.create = bulkObj.create.filter((customer) => {
          let isDuplicate = allCustomers?.find(
            (obj) => obj?.number === customer?.number
          );

          if (isDuplicate)
            crmSheetCustomerErrors.push({
              ...customer,
              action: "create",
              error_message: "Duplicate Customer",
            });
          if (!validateNumber(numberFromPhoneNumber(customer?.number))) {
            crmSheetCustomerErrors.push({
              ...customer,
              action: "create",
              error_message: "Number Field Required",
              number: "",
            });
            isDuplicate = true;
          }

          if (remainCustomer <= 0) {
            crmSheetCustomerErrors.push({
              ...customer,
              action: "create",
              error_message: "Max limit reached",
              // number: customer?.number,
            });
            isDuplicate = true;
          }
          if (
            !isDuplicate &&
            customer?.email &&
            allCustomers?.find((obj) => obj?.email === customer?.email)
          ) {
            crmSheetCustomerErrors.push({
              ...customer,
              action: "create",
              error_message: "Email alreday exixts",
              // number: customer?.number,
            });
            isDuplicate = true;
          }

          if (!isDuplicate) remainCustomer = remainCustomer - 1;

          return !Boolean(isDuplicate);
        });
        bulkObj.create = bulkObj.create.filter(Boolean);
      }
      console.log(bulkObj.create, "<<klajsdlfjdsfl");
      if (isArrayWithValues(bulkObj.create))
        addedObjectIds = bulkObj.create?.map((i) => i?.id || i?.number);
      // adding id to update customers
      if (
        isArrayWithValues(bulkObj.update) &&
        isArrayWithValues(allCustomers)
      ) {
        bulkObj.update = bulkObj.update.map((customer) => {
          let oldCustomer = allCustomers?.find(
            (obj) => obj?.number === customer?.number
          );
          if (oldCustomer?.id) {
            if (
              oldCustomer?.email &&
              [...Object.values(bulkObj || {}), ...(allCustomers || [])].find(
                (obj) => obj?.email === oldCustomer?.email
              )
            ) {
              crmSheetCustomerErrors.push({
                ...customer,
                action: "update",
                error_message: "Customer Email is already there",
              });
              return false;
            }
            if (customer?.labelArray) {
              let broadcastLabels = customer?.labelArray?.split(",") || [];

              for (const label of broadcastLabels) {
                if (label) {
                  let oldLabel = Object.values(broadcastList || {}).find(
                    (o) =>
                      (o.label || "")?.replaceAll(" ", "")?.toLowerCase() ===
                      label?.replaceAll(" ", "")?.toLowerCase()
                  );
                  if (isObjWithValues(oldLabel)) {
                    customer.label = [
                      ...new Set([...(customer?.label || []), oldLabel?.id]),
                    ];
                  } else {
                    newCreateedLabel = true;
                    let labelId = `${Date.now()}_${getRandomString(4)}`;
                    newLabelObj = {
                      ...newLabelObj,
                      [labelId]: {
                        id: labelId,
                        label: label,
                      },
                    };
                    customer.label = [
                      ...new Set([...(customer?.label || []), labelId]),
                    ];
                    broadcastList = { ...broadcastList, ...newLabelObj };
                  }
                }
              }
            }
            return { ...oldCustomer, ...customer, action: "synced" };
          } else {
            crmSheetCustomerErrors.push({
              ...customer,
              action: "update",
              error_message: "Customer Not Found",
            });
            return false;
          }
        });
        bulkObj.update = bulkObj.update.filter(Boolean);
      }

      // return

      // await addConversation(allCustomers)
      // let bulkResponse = await addAndUpdateBulkProducts(
      //   bulkObj,
      //   setSyncProgress
      // );
      // console.log(bulkResponse);
      if (isArrayWithValues(bulkObj.create)) {
        allCustomers = [
          ...(bulkObj.create || [])?.map(
            (customer) => {
              customer.id = numberFromPhoneNumber(customer?.number);
              customer.number = numberFromPhoneNumber(customer?.number);
              customer.error_message = "";
              customer.action = "synced";
              customer.platform = "whatsapp";
              // if (customer)
              if (customer?.labelArray) {
                let broadcastLabels = customer?.labelArray?.split(",") || [];

                for (const label of broadcastLabels) {
                  if (label) {
                    let oldLabel = Object.values(broadcastList || {}).find(
                      (o) =>
                        (o.label || "")?.replaceAll(" ", "")?.toLowerCase() ===
                        label?.replaceAll(" ", "")?.toLowerCase()
                    );
                    if (isObjWithValues(oldLabel)) {
                      customer.label = [
                        ...new Set([...(customer?.label || []), oldLabel?.id]),
                      ];
                    } else {
                      newCreateedLabel = true;
                      let labelId = `${Date.now()}_${getRandomString(4)}`;
                      newLabelObj = {
                        ...newLabelObj,
                        [labelId]: {
                          id: labelId,
                          label: label,
                        },
                      };
                      customer.label = [
                        ...new Set([...(customer?.label || []), labelId]),
                      ];
                      broadcastList = { ...broadcastList, ...newLabelObj };
                    }
                  }
                }
              }
              return customer;
            }

            // ({
            //   ...obj,
            //   // id: numberFromPhoneNumber(obj?.number),
            //   // number: numberFromPhoneNumber(obj?.number),
            //   id: obj?.number,
            //   action: "synced",
            //   platform: "whatsapp",
            // })
          ),
        ].concat(allCustomers);
      }
      if (isArrayWithValues(bulkObj?.update)) {
        allCustomers = allCustomers?.map((chatObj) => {
          let newChat = bulkObj?.update?.find(
            (newObj) => newObj?.number === chatObj?.number
          );
          if (isObjWithValues(newChat))
            return { ...chatObj, ...newChat, action: "synced" };
          return chatObj;
        });
        //   dispatch(updateMultipleCustomersInRedux(bulkResponse?.update));
        //   let updatedCustomers = [...bulkResponse.update];
        //   // here were assigning
        //   allCustomers = allCustomers.map((customer) => {
        //     let obj = bulkResponse.update.find((i) => i.id === customer.id);
        //     if (obj)
        //       updatedCustomers = updatedCustomers.filter((i) => i.id != obj.id);
        //     return obj || customer;
        //   });
        //   //remaining updated customers as we wont be able to find new customers in allCustomers
        //   if (isArrayWithValues(updatedCustomers))
        //     allCustomers = allCustomers.concat(updatedCustomers);
      }
      // console.log(bulkObj, "bulkobj before history");
      if (bulkObj.create) historyObj.create = bulkObj.create.length;
      if (bulkObj.update) historyObj.update = bulkObj.update.length;
    }

    if (newCreateedLabel) {
      dispatch(putBroadcastList(newLabelObj));
    }
    // return

    let array = createSpreadSheetData([
      ...(allCustomers || [])
        ?.filter((obj) => obj?.platform === "whatsapp")
        ?.sort(
          (a, b) =>
            validateNumber(b?.last_message) - validateNumber(a?.last_message)
        ),
      ...crmSheetCustomerErrors,
    ]);
    // if(isArra)\

    // return
    await addConversation(
      allCustomers.map((obj) => {
        // obj.id = obj?.number
        // if (obj.action === "create") addedObjectIds?.push(obj?.id);
        let deleteArr = [
          "action",
          "error_message",
          "labelArray",
          "conversation",
          "password",
        ];
        for (const deleteKey of deleteArr) {
          delete obj[deleteKey];
        }
        obj.billing = {};
        let billingArray = [
          "address_1",
          "address_2",
          "city",
          "postcode",
          "country",
          "state",
        ];
        for (const key of Object.keys(obj)) {
          if (billingArray.includes(key) && obj[key]) {
            obj.billing[key] = obj[key];
            delete obj[key];
          }
        }
        obj.conversation = [];
        return obj;
      })
    );
    console.log(addedObjectIds, "<<<asldfjaskfj");
    if (isArrayWithValues(addedObjectIds))
      await addEmptyConversations(addedObjectIds?.filter(Boolean));

    setSyncProgress(73);

    // console.log(
    //   array,
    //   allCustomers,
    //   crmSheetCustomerErrors,
    //   "spreadsheet data 1"
    // );
    if (isArrayWithValues(allCustomers))
      historyObj.exported = allCustomers.length;
    // if (sheetRowsLength && sheetRowsLength > array?.length) {
    //   let extraRows = sheetRowsLength - array.length;
    //   array = [
    //     ...array,
    //     ...Array.from(
    //       new Array(extraRows).fill(
    //         Array.from(new Array(array?.[0]?.length)).fill("")
    //       )
    //     ),
    //   ];
    // }
    // console.log(array, "spreadsheet data 2");
    // setSyncProgress(97);
    await clearSpreadsheet(array, chats_spreadsheet_id);
    setSyncProgress(79);
    const exportRes = await exportSpreadsheet(array, chats_spreadsheet_id);
    setSyncProgress(88);
    // console.log(exportRes);

    const dropdownData = {
      actions: ["create", "update", "synced"],
      contact_type: ["customer", "lead"],
    };
    let sheetIdObj = await getSheetId(chats_spreadsheet_id);
    console.log(sheetIdObj);
    if (isArrayWithValues(sheetIdObj?.sheets)) {
      let sheetId = sheetIdObj.sheets[0]?.properties?.sheetId;
      // console.log(sheetId);
      const dropdownBody = getSpreadsheetDropdowns(
        sheetId,
        array[0],
        dropdownData
      );
      console.log(dropdownData, dropdownBody, "DROPDOWN DATA");
      await setSpreadsheetDropdowns(chats_spreadsheet_id, dropdownBody);
    }
    setSyncProgress(98);
    historyObj.end = Math.floor(Date.now() / 1000);

    if (!isDev()) await addHistroyObj({ [getRandomString(10)]: historyObj });
    setSyncProgress(100);
    await sleep(200);
    setChatsSheetLoading(false);
    setShowSync(true);
    dispatch(getConversations());
    dispatch(
      setGlobalToast({
        show: true,
        message: "Chats Sheet sync complete",
        severity: "success",
      })
    );
  };
  const addHistroyObj = async (obj) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/chats_spreadsheet_history`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: { type: `chats_spreadsheet_history`, data: obj },
        });
        if (data.success && isObjWithValues(data.data))
          dispatch({
            type: EDIT_CHATS,
            payload: {
              chatsSpreadsheetHistory: data.data,
            },
          });
        return true;
      } catch (error) {
        return false;
      }
    }
  };
  const getSpreadsheetDropdowns = (sheetId, headerArray, dropdownData) => {
    if (isArrayWithValues(headerArray) && isObjWithValues(dropdownData)) {
      console.log(headerArray);
      let validations = {
        // ethincity: {
        //   index: headerArray.indexOf("Ethnicity"),
        //   type: "ONE_OF_LIST",
        //   values: dropdownData.ethincity,
        // },
        // language: {
        //   index: headerArray.indexOf("Language"),
        //   type: "ONE_OF_LIST",
        //   values: dropdownData.language,
        // },
        // religion: {
        //   index: headerArray.indexOf("Religion"),
        //   type: "ONE_OF_LIST",
        //   values: dropdownData.religion,
        // },
        actions: {
          index: headerArray.indexOf("Actions"),
          type: "ONE_OF_LIST",
          values: ["create", "update", "synced"],
        },
        contact_type: {
          index: headerArray.indexOf("Contact type"),
          type: "ONE_OF_LIST",
          values: ["customer", "lead"],
        },
        // profession: {
        //   index: headerArray.indexOf("Profession"),
        //   type: "ONE_OF_LIST",
        //   values: dropdownData.profession,
        // },
      };

      const keys = Object.keys(validations);
      const length = Object.keys(validations).length;
      const data = [];
      for (let i = 0; i < length; i++) {
        let value = validations[keys[i]];
        let { values } = value;
        let dropdownData = values.map((i) => [{ userEnteredValue: i }]);
        data.push({
          setDataValidation: {
            range: {
              sheetId,
              startRowIndex: 1,
              startColumnIndex: value.index,
              endColumnIndex: value.index + 1,
            },
            rule: {
              condition: {
                type: value.type,
                values: dropdownData,
              },
              showCustomUi: true,
            },
          },
        });
      }
      console.log(JSON.stringify(data, null, 2));
      return data;
    }
  };

  const addConversation = async (arr) => {
    let website = getWebsite();
    let token = getToken();
    try {
      let { data: postData } = await axios({
        url: `${website}/wp-json/store/v1/settings/platform_conversations_whatsapp`,
        headers: { Authorization: `Basic ${token}` },
        method: "POST",
        data: {
          type: "platform_conversations_whatsapp",
          data: arr,
        },
      });
      return postData.data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  return (
    <Dialog
      open={showSync}
      onClose={() => closeSync()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{ ...dialogStyle }}
      keepMounted={false}
    >
      {/* <DialogTitle    id="alert-dialog-title">CRM Sheet</DialogTitle> */}
      <DialogContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
          flexWrap="wrap"
        >
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
              Chat Sheet
            </Typography>
          </Stack>
          {googleSheetIntegrated && (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={1}
              pr={1}
              flex={1}
            >
              {" "}
              <LoadingButton
                loading={chatSheetLoading}
                endIcon={<Sync />}
                size="small"
                variant="contained"
                onClick={() => onSyncPress()}
                // onClick={() => navigate("/chats/sheet")}
                sx={{ whiteSpace: "nowrap" }}
              >
                Sync Chats
              </LoadingButton>
              <LoadingButton
                loading={openSheetLoading}
                endIcon={<Launch />}
                size="small"
                variant="outlined"
                onClick={openSheet}
                sx={{ whiteSpace: "nowrap" }}
              >
                Open Sheet
              </LoadingButton>
            </Stack>
          )}
          {!googleSheetIntegrated && (
            <IconButton onClick={closeSync}>
              <Close />
            </IconButton>
          )}
        </Stack>

        {googleSheetIntegrated && (
          <CRMSheetHistory
            chatsHistory={chatsSpreadsheetHistory}
            heading={"Chats Sheet Sync"}
          />
        )}
        {!googleSheetIntegrated && (
          <DataNotFound
            illustration={<NoSheets width="130px" height="130px" />}
            subtitle={`Oops! It is required to integrate Google Sheet to Add, Access or Update Customer Information. 
              Go to Integration > Google > Google Sheet to Integrate
              `}
            title={""}
            createButton={
              <Button
                variant="contained"
                onClick={() => navigate("/integrations#Google")}
                // size={"small"}
                sx={{ mt: 3 }}
              >
                Integrate Google Sheets
              </Button>
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {googleSheetIntegrated && (
          <Button variant="outlined" startIcon={<Close />} onClick={closeSync}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const GroupTab = memo(
  ({
    isIpad,
    crmGrouping,
    // allReduxConversations,
    setSelectedCustomer,
    selectedCustomer,
    onCustomerPress,
    showManage,
    customerView,
    setShowManage,
    bulkSelectedGroup,
    setBulkSelectedGroup,
  }) => {
    const dispatch = useDispatch();
    const [groups, setGroups] = useState({});
    const [selectedGroup, setSelectedGroup] = useState({});

    const [deleteObj, setDeleteObj] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({ page: 1, per_page: perPage });
    const [conversations, setConversations] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const broadcast = useSelector((state) => state?.chats?.chat_broadcasts);
    const allReduxConversations = useSelector(
      (state) => state?.chats?.conversations
    );
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      if (
        Array.isArray(allReduxConversations) &&
        allReduxConversations.length > 0
      ) {
        let currentPage = newPage + 1;
        // console.log(
        //   allReduxConversations.length / rowsPerPage <= currentPage,
        //   !fetchingConversations,
        //   !noMoreConversations,
        //   Array.isArray(allReduxConversations),
        //   allReduxConversations.length > 0
        // );
        if (allReduxConversations.length / rowsPerPage <= currentPage) {
          setParams({ ...params, page: params.page + 1 });
        }
      }
    };
    console.log(broadcast);
    // useMemo(() => {
    //   setSelectedCustomer([])
    //   setShowManage(false)
    // }, [])

    useEffect(() => {
      if (isObjWithValues(broadcast)) {
        setGroups(broadcast);
      }

      return () => {};
    }, [broadcast]);

    useEffect(() => {
      if (isArrayWithValues(allReduxConversations))
        setConversations(allReduxConversations);

      return () => {};
    }, [allReduxConversations]);

    useEffect(() => {
      if (isObjWithValues(selectedGroup)) dispatch(getConversations(params));

      return () => {};
    }, [params]);
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      // setPage(0);
    };

    const onDelete = async (id) => {
      setLoading(true);

      // // setDeletingId(id);
      // let website = getWebsite();
      // let token = getToken();
      // const res = await axios({
      //   url: `${website}/wp-json/store/v1/settings/broadcast_list`,
      //   headers: {
      //     Authorization: `Basic ${token}`,
      //   },
      //   method: "DELETE",
      //   data: {
      //     id,
      //   },
      // });
      let res = await backendApiCall({
        endpoint: `broadcast/${id}`,
        method: "DELETE",
      });

      if (res?.success) {
        dispatch({
          type: EDIT_CHATS,
          payload: { chat_broadcasts: res?.data },
        });
        // setShowTextFeild(false);
      }
      setDeleteObj({});
      setSelectedGroup({});
      // setDeletingId(false);
      setLoading(false);
    };

    useMemo(() => {
      setSelectedCustomer(Object.values(bulkSelectedGroup).flat());
    }, [bulkSelectedGroup]);

    const onSelectList = (obj) => {
      // let overData = { ...bulkSelectedGroup };
      // if (bulkSelectedGroup?.[obj?.group_detail?.id]) {
      //   delete overData[obj?.group_detail?.id];
      //   setBulkSelectedGroup(overData);
      // } else {
      //   setBulkSelectedGroup((pre) => ({
      //     ...pre,
      //     [obj?.group_detail?.id]: obj?.customers,
      //   }));
      // }

      setBulkSelectedGroup((pre) => {
        if (pre?.some((o) => o?.id == obj?.id))
          pre = pre?.filter((o) => o?.id !== obj?.id);
        else pre = [...pre, obj];
        return pre;
      });
    };

    return (
      <Box>
        <Dialog
          open={isObjWithValues(deleteObj)}
          onClose={() => setDeleteObj({})}
          sx={{ ...dialogStyle }}
        >
          <DialogTitle>
            Are you sure You want to Delete Broadcast list {deleteObj?.title}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteObj({})}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={() => onDelete(deleteObj?.id)}
              variant="contained"
              color="error"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <List
          // hidden={listTab === "groups"}
          disablePadding
          style={{
            maxHeight: "100%",
            height: isIpad ? "71svh" : "66svh",
            overflowY: "scroll",
            maxWidth: "100%",

            // gridAutoRows:{}
          }}
          className="scrollbar-hidden"
        >
          {!isObjWithValues(selectedGroup) &&
            Object.keys(groups || {}).map((key) => {
              return (
                <ListItem
                  key={key}
                  sx={{
                    borderBottom: "0.3px solid #e8e8e8",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    showManage && onSelectList(groups?.[key] || {});
                    !showManage && setSelectedGroup(groups?.[key] || {});
                    setParams({
                      page: 1,
                      per_page: perPage,
                      custom_filter: [
                        {
                          key: "broadcast",
                          value: key,
                          compare: "LIKE",
                        },
                      ],
                    });
                  }}
                >
                  {showManage && (
                    <Checkbox
                      //  checked={Boolean(bulkSelectedGroup?.[key])}
                      // checked={isObjWithValues(selected)}
                      checked={(isArrayWithValues(bulkSelectedGroup)
                        ? bulkSelectedGroup
                        : []
                      )
                        ?.map((obj) => obj?.id)
                        ?.includes(key)}
                    />
                  )}
                  <ListItemAvatar sx={{ color: "primary.main" }}>
                    <Campaign style={{ transform: "rotate(-35deg)" }} />
                    {/* <Avatar> */}
                    {/* <LocalOffer
                      style={{
                        color: groups?.[key]?.group_detail?.color || "",
                      }}
                    /> */}
                    {/* </Avatar> */}
                  </ListItemAvatar>
                  <ListItemText
                    primary={groups?.[key]?.title || ""}
                    secondary={`${
                      groups?.[key]?.contact_ids?.length || ""
                    } contacts`}
                  />
                  <ListItemIcon></ListItemIcon>
                </ListItem>
              );
            })}

          {isObjWithValues(selectedGroup) && (
            <>
              <ListItem sx={{ borderBottom: "0.3px solid #e8e8e8" }}>
                <IconButton onClick={() => setSelectedGroup({})}>
                  <ArrowBackIosNew />
                </IconButton>
                <ListItemAvatar sx={{ color: "primary.main" }}>
                  <Campaign style={{ transform: "rotate(-35deg)" }} />
                  {/* <Avatar> */}
                  {/* <LocalOffer
                    style={{
                      color: selectedGroup?.group_detail?.color || "",
                    }}
                  /> */}
                  {/* </Avatar> */}
                </ListItemAvatar>
                <ListItemText
                  primary={selectedGroup?.title || ""}
                  secondary={`${
                    selectedGroup?.contact_ids?.length || ""
                  } contacts`}
                />

                <ListItemIcon>
                  <IconButton
                    onClick={() => setDeleteObj(selectedGroup)}
                    // onClick={() => setDeleteObj({ id: selectedGroup?.id })}
                  >
                    <Delete />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
              <ListItem
                // key={id}
                button
                sx={{
                  // padding: "8px 14px",
                  flex: 1,
                  borderLeftWidth: 3,
                  borderLeftStyle: "solid",
                  borderLeftColor: "transparent",
                  transition: "borderLeftWidth 1s",
                  borderBottom: "0.3px solid  #e8e8e8",
                  position: "sticky",
                  top: "0px",
                  zIndex: 12,
                  backgroundColor: "background.paper",
                  ":hover": {
                    backgroundColor: "background.paper",
                  },
                  padding: "0px",
                }}
              >
                <TablePagination
                  sx={{
                    // maxWidth: "fit-content",
                    marginLeft: "auto",
                    ".MuiTablePagination-selectLabel": {
                      display: "none",
                    },
                    ".MuiTablePagination-toolbar": {
                      padding: 0,
                    },
                    ".MuiInputBase-root": {
                      display: "none",
                    },
                  }}
                  className="scrollbar-hidden"
                  component="div"
                  count={conversations?.length || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  size="small"
                  // labelRowsPerPage={"per page"}
                />
              </ListItem>
              {conversations?.map((obj) => {
                return (
                  <ChatListRow
                    key={obj.id}
                    onCustomerPress={onCustomerPress}
                    customer={obj}
                    customerView={customerView}
                    showManage={showManage}
                    setSelectedCustomer={setSelectedCustomer}
                    selectedCustomer={selectedCustomer}
                  />
                );
              })}
            </>
          )}
        </List>
      </Box>
    );
  }
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#00e676" : "#00e676",
  },
}));
const createSpreadsheet = async (dispatch, setOpenSheetLoading) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/channel/google_sheets`,
      params: { endpoint: "/spreadsheets" },
      method: "POST",
      data: {
        payload: {
          properties: {
            title: "Tanika - Chats Sheet",
          },
        },
      },
    });
    if (data.spreadsheetId) {
      let { data: updateData } = await axios({
        url: `${website}/wp-json/store/v1/settings/google_spreadsheet`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "google_spreadsheet",
          data: {
            chats_spreadsheet_id: data.spreadsheetId,
          },
        },
        method: "PUT",
      });
      if (updateData.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            googleSpreadsheet: updateData.data,
          },
        });
      }
      setOpenSheetLoading && setOpenSheetLoading(false);
      return data.spreadsheetId;
    }
  }
};
let sheetHeaders = [
  { label: "Actions", value: "action" },
  { label: "Errors", value: "error_message" },
  // { label: "Registered Date", value: "date_created" },
  // { label: "ID", value: "id" },
  { label: "Full Name", value: "name" },
  // { label: "First Name", value: "first_name" },
  // { label: "Last Name", value: "last_name" },
  // { label: "Channel", value: "platform" },

  { label: "Phone Number", value: "number" },
  { label: "Email", value: "email" },
  { label: "Contact type", value: "contact_type" },

  { label: "Broadcast", value: "labelArray" },

  // { label: "Nickname", value: "nickname" },
  // { label: "Dial Code 1", value: "dialcode_mobile" },
  // { label: "Dial Code 2", value: "dialcode_whatsapp" },
  // { label: "Whatsapp", value: "whatsapp" },
  // { label: "Groupings", value: `group_name_${storeId}` },
  { label: "Address Line 1", value: "address_1" },
  { label: "Address Line 2", value: "address_2" },
  { label: "City", value: "city" },
  { label: "Postcode", value: "postcode" },
  { label: "State", value: "state" },
  { label: "Country", value: "country" },
  // { label: "Branch", value: "branch_from" },
];

const createSpreadSheetData = (chats) => {
  let spreadsheetArray = [];
  spreadsheetArray.push(sheetHeaders.map((i) => i.label));
  for (let chat of chats || []) {
    let { billing } = chat || {};

    if (isObjWithValues(billing))
      for (let key in billing) if (billing[key]) chat[key] = billing[key];

    spreadsheetArray.push(
      sheetHeaders.map((i) =>
        typeof chat[i.value] === "string" && chat[i.value] ? chat[i.value] : ""
      )
    );
  }
  return spreadsheetArray;
};

const getBulkCreateUpdateObject = (sheetData) => {
  let headerRow = sheetData[0];
  let data = { create: [], update: [] };
  let actionIndex = headerRow.findIndex((i) => i === "Actions");
  for (let productArray of sheetData) {
    let action = productArray[actionIndex];
    console.log(action, "<<< action");
    let product = getProductObjectFromSheetArray(headerRow, productArray);
    if (action === "create") {
      product.password = "password";
    }
    if (action === "create" || action === "update")
      data?.[action]?.push(product);
  }
  return data;
};

const getProductObjectFromSheetArray = (headerRow, productArray) => {
  let customerObj = {
    // index,
  };
  let length = headerRow.length;
  let whiteSpacesToRemove = [
    "number",
    "whatsapp",
    "dialcode_whatsapp",
    "dialcode_mobile",
    // "email",
  ];
  for (let i = 0; i < length; i++) {
    let keyObj = sheetHeaders.find((obj) => obj.label === headerRow[i]);
    if (keyObj) {
      if (whiteSpacesToRemove.includes(keyObj.value))
        customerObj[keyObj.value] = `${productArray[i]}`
          .replace(/\s/g, "")
          .trim();
      else customerObj[keyObj.value] = productArray[i];
    }
  }

  let billingKeys = [
    "first_name",
    "last_name",
    "company",
    "address_1",
    "address_2",
    "city",
    "postcode",
    "country",
    "state",
    "email",
    "phone",
  ];
  let showInObject = ["first_name", "last_name", "email", "phone"];

  let meta_data = [];
  let billing = {};
  for (let i of billingKeys) {
    if (customerObj.hasOwnProperty(i)) billing[i] = customerObj[i];
    if (!showInObject.includes(i)) delete customerObj[i];
  }
  customerObj.billing = billing;
  if (!customerObj.email || !customerObj?.billing?.email) {
    delete customerObj?.email;
    delete customerObj?.billing?.email;
  }

  return customerObj;
};

export const addEmptyConversations = async (ids) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let payload = {};
    ids?.map((i) => {
      payload[`whatsapp_${i}`] = [];
    });

    console.log(payload, "payload to update");
    let { data: postData } = await axios({
      url: `${website}/wp-json/store/v1/settings/?options=${ids?.join()}`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: {
        // type: "platform_conversations_whatsapp",
        ...payload,
      },
    });
    return postData.data;
  } catch (error) {
    console.log(getAxiosError(error));
    return false;
  }
};

const DeleteDialog = ({
  open,
  handleClose,
  heading,
  content,
  deleteing,
  onDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{ ...dialogStyle }}
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {heading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={deleteing}
          onClick={() => {
            onDelete && onDelete();
          }}
          color={"error"}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
