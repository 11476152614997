import React from "react";
import { useSelector } from "react-redux";
import { isArrayWithValues, mergeConversation } from "../../../helper";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Stack,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import { Avatar } from "@mui/material";
import { useState } from "react";
import { ArrowBackIosNew, Merge } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const MergeView = ({
  allReduxConversations,
  isIpad,
  onCustomerPress,
  action,
  setShowExisting,
  setPreMerge,
  preMerge,
}) => {
  const chatsGroupings = useSelector((state) => state.chats.chatsGroupings);
  let { id } = useParams() || {};
  // function mergeConversation(customer) {
  //   // let customer = {
  //   //   id: "nrvflijnm",
  //   //   name: "merge",
  //   //   platform: "whatsapp",
  //   //   ids: ["919998060495", "919967283656", "919167707645"],
  //   //   // conversation :
  //   // };
  //   let names = [];

  //   let conversationArray = [];
  //   for (const id of customer?.ids) {
  //     let customer = allReduxConversations?.find((obj) => obj.id === id);
  //     if (customer) {

  //       names.push(
  //         `${customer?.first_name || ""} ${customer?.last_name || ""} ${customer?.name || ""
  //         }`
  //       );

  //     }
  //     if (customer && isArrayWithValues(customer?.conversation)) {
  //       conversationArray = [
  //         ...conversationArray,
  //         ...customer?.conversation?.map((obj) => {

  //           obj.from = `${customer?.first_name || ""} ${customer?.last_name || ""} ${customer?.name || ""
  //             }`
  //           obj.number = customer?.number
  //           obj.to = `${customer?.first_name || ""} ${customer?.last_name || ""} ${customer?.name || ""
  //             }`
  //           return obj
  //         }),
  //       ];
  //     }
  //   }

  //   customer.names = names;
  //   customer.conversation = conversationArray.sort(
  //     (a, b) => validateNumber(b.time) - validateNumber(a.time)
  //   );
  //   return customer;
  // }
  return (
    <>
      {action && (
        <Stack direction={"row"} alignItems="center" gap={2}>
          <IconButton onClick={() => setShowExisting(false)}>
            <ArrowBackIosNew />
          </IconButton>
          <Typography color={"text.secondary"} variant="h6">
            Add to Existing view
          </Typography>
        </Stack>
      )}
      <List
        // hidden={listTab === "groups"}
        disablePadding
        style={{
          maxHeight: "100%",
          maxHeight: isIpad ? "71vh" : "66vh",
          overflowY: "scroll",
          maxWidth: "100%",
          cursor: "pointer",
          // gridAutoRows:{}
        }}
        className="scrollbar-hidden"
      >
        {Object.values(chatsGroupings || {})?.map((obj) => {
          if (
            !isArrayWithValues(
              mergeConversation(obj, allReduxConversations)?.conversation
            )
          )
            return;
          return (
            <ListItem
              onClick={() => {
                onCustomerPress &&
                  onCustomerPress(
                    mergeConversation(obj, allReduxConversations)
                  );
                action && setPreMerge && setPreMerge(obj);
              }}
              selected={obj?.id === id}
              sx={{
                padding: "15px 14px",
                flex: 1,
                position: "relative",
                // backgroundColor: customerView.id === id ? "#f2f2f2" : "transparent",
                // borderLeftColor: `${customerView.id === id ? "#0A7CFF" : "transparent"
                //   }`,
                // borderLeftWidth: 3,
                // borderLeftStyle: "solid",
                // transition: "borderLeftWidth 1s",
                borderBottom: "0.3px solid #e8e8e8",
                // "&:hover": {
                //   backgroundColor: customerView.id === id ? "#f2f2f2" : "transparent",
                // },
              }}
            >
              {action && <Checkbox checked={preMerge?.id === obj?.id} />}
              <ListItemAvatar>
                <Avatar>
                  <Merge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    {obj?.name}{" "}
                    {obj?.isAutoGentrated && (
                      <Chip size="small" label="Auto Genrated" />
                    )}
                  </Stack>
                }
                secondary={`${obj?.ids?.length || ""} peoples`}
              />
            </ListItem>
          );
        })}
        {/* <ListItem  sx={{
          padding: "15px 14px",
          flex: 1,
          position: "relative",
          // backgroundColor: customerView.id === id ? "#f2f2f2" : "transparent",
          // borderLeftColor: `${customerView.id === id ? "#0A7CFF" : "transparent"
          //   }`,
          // borderLeftWidth: 3,
          // borderLeftStyle: "solid",
          // transition: "borderLeftWidth 1s",
          borderBottom: "0.3px solid #e8e8e8",
          // "&:hover": {
          //   backgroundColor: customerView.id === id ? "#f2f2f2" : "transparent",
          // },
        }}>
    <ListItemAvatar>
          <Avatar>
       
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
    </ListItem> */}
      </List>
    </>
  );
};

export default MergeView;
