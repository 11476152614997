import { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filterStyles } from "./styles/filterStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import FilterHeader from "./components/FilterHeader";
import FilterSection from "./components/FilterSection";
import { getRandomId } from "../utils";

const styles = filterStyles;

const SidebarFilter = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [filters, setFilters] = useState([[]]);
  const [mainCondition, setMainCondition] = useState("and");

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onAddFilter = () => {
    setFilters((prevFilters) => [...prevFilters, []]);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        fieldsData: {
          condition: mainCondition,
          filters,
        },
      };
      dispatch({ type: actions.SIDEBAR_FILTER, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_FILTER, payload: false });
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);

    if (currentNode) {
      const fieldData = currentNode?.fieldData;
      if (fieldData?.title) {
        setTitle(currentNode.fieldData.title);
      }
      if (fieldData?.fieldsData) {
        setFilters(fieldData?.fieldsData?.filters);
        setMainCondition(fieldData?.fieldsData?.condition);
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <FilterHeader
          handleCheck={handleCheck}
          handleClose={handleClose}
          title={title}
          setTitle={setTitle}
          titleError={titleError}
        />

        <FilterSection
          filters={filters}
          setFilters={setFilters}
          onAddFilter={onAddFilter}
          mainCondition={mainCondition}
          setMainCondition={setMainCondition}
        />

        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarFilter;
