import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import NodeOptions from "../helper/NodeOptions";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { goToStepStyles } from "../styles/goToStepStyles";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = goToStepStyles;

const GoToStep = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [isHovered, setIsHovered] = useState(false);

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_GO_TO_STEP, payload: true });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ paddingBottom: "10px" }}>
        {isHovered ? (
          <div style={{ paddingLeft: "12px" }}>
            <NodeOptions nodeId={nodeId} nodeType="go-to-step" />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              maxWidth: "100px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#566e8a",
              }}
            >
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>
      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        <Box sx={styles.nodeBody} onClick={handleMain}>
          <IconButton sx={styles.iconButton}>
            <SwapCallsSharpIcon sx={styles.nodeIcon} />
          </IconButton>
          <Typography variant="span" style={styles.titleName}>
            Go to step
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GoToStep;
