import { WhatsappTemplateNode } from "../../utils/WhatsappTemplateNode";

export const createWhatsappTemplateNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  const axis = {
    x: currNode.position.x,
    y: currNode.position.y,
  };
  WhatsappTemplateNode(dispatch, axis, nodeId);
};
