import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { UnicommerceSvg } from "../../../../Svgs";
import { TabPanel } from "../../../../helper";
import Integration from "./Integration";

function Unicommerce() {
  const [selectedTab, setSelectedTab] = useState("integration");
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <UnicommerceSvg height={25} width={25} /> Unicommerce
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {/* {reduxWooCommerceIntegration?.integrated
        ? [
            <Tab label="Mapping" value="mapping" />,
            <Tab label="Import" value="import" />,
            <Tab label="Activity" value="activity" />,
            <Tab label="Settings" value="settings" />,
          ]
        : null} */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>
      <TabPanel value={selectedTab} index={"overview"}></TabPanel>
    </Box>
  );
}

export default Unicommerce;
