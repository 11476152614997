import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import axios from "axios";
import { AmazonSvg } from "../../../../Svgs";
import {
  amazonMarketplaceIds,
  Base64,
  dialogStyle,
  getAxiosError,
  getStoreId,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  nodeWebsite,
  settingsEndpoint,
} from "../../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useDispatch } from "react-redux";
import TabOverview from "./TabOverview";
import TabIntegration from "./TabIntegration";
import { AmazonSP } from "./constants";
import Settings from "./components/Settings";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import Activities from "./components/Activities";

function Amazon() {
  const [selectedTab, setSelectedTab] = useState("integration");
  const [showAmazonConfig, setShowAmazonConfig] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [configForm, setConfigForm] = useState({
    marketplace_id: "",
  });
  const [authConfig, setAuthConfig] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const editForm = (payload) =>
    setConfigForm((state) => ({ ...state, ...payload }));

  const closeAmazonConfig = () => setShowAmazonConfig(false);
  const openAmazonConfig = () => setShowAmazonConfig(true);

  // Amazon OAUTH2 params get
  useEffect(() => {
    let params_string = location.search;
    let authCode = new URLSearchParams(params_string).get("spapi_oauth_code");
    let authState = new URLSearchParams(params_string).get("state");
    let authAmazonSellerID = new URLSearchParams(params_string).get(
      "selling_partner_id"
    );

    if (!authCode || !authState) return;

    if (authState?.includes("amazon")) {
      try {
        if (authState.split("_")?.[1] == getStoreId()) {
          setSelectedTab("integration");
          exchangeCodeForAccessToken(authCode, authState, authAmazonSellerID);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  // Exchange AuthCode for AccessToken
  const exchangeCodeForAccessToken = async (authCode, authState, sellerID) => {
    console.log("cAlling", authCode, authState, sellerID);
    setAuthConfig({ authCode, authState, sellerID });
    openAmazonConfig();
    // dispatch({
    //   type: EDIT_SETTINGS,
    //   payload: {
    //     integratingAmazon: true,
    //   },
    // });

    // let headers = {
    //   // "Content-Type": "application/x-www-form-urlencoded",
    //   Authorization: `Basic ${token}`,
    // };

    // dispatch({
    //   type: EDIT_SETTINGS,
    //   payload: {
    //     integratingAmazon: false,
    //   },
    // });
  };

  const updateAmazonTokens = async (amazonIntegrationData) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      try {
        console.debug("Updating integrationData", amazonIntegrationData);

        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/amazon_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "amazon_integration",
            data: amazonIntegrationData,
          },
        });

        if (data.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              amazonIntegration: data.data,
            },
          });
        }
      } catch (err) {
        console.log("Saving Amazon Integration", err);
      }
    }
  };

  const onConfigSubmit = async () => {
    setConfigLoading(true);
    let { marketplace_id } = configForm;
    let obj = amazonMarketplaceIds.find(
      (i) => i.marketplace_id === marketplace_id
    );
    console.log(obj);
    // return;
    let { country, endpoint, region } = obj;
    const website = getWebsite();
    const token = getToken();
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: { code: authConfig.authCode, website },
      url: `${nodeWebsite}/amazon_token`,
    };
    // console.log(obj, requestOptions);
    // return;
    try {
      // fetch(`${website}/wp-json/store/v1/channel/amazon`, requestOptions)
      let { data: textResponse } = await axios({
        ...requestOptions,
      });
      if (textResponse.success) {
        const integrationData = {
          integrated: true,
          seller_id: authConfig.sellerID,
          endpoint,
          region,
          country,
          time: getTimeInMilliSeconds(),
        };
        await updateAmazonTokens(integrationData);
        await settingsEndpoint({
          endpoint: "amazon_settings",
          data: {
            ...configForm,
          },
          method: "PUT",
        });
        await subscribeToEvents();
        closeAmazonConfig();
        dispatch(
          setGlobalToast({
            show: true,
            message: "Amazon integrated successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Couldn't integrate Amazon! please try again",
            severity: "error",
          })
        );
      }
    } catch (amazonCodeExchangeError) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't integrate Amazon! please try again",
          severity: "error",
        })
      );
    }
    // let data = amazonMarketplaceIds.find();

    setConfigLoading(false);
  };

  const subscribeToEvents = async () => {
    let website = getWebsite();
    let token = getToken();
    try {
      await axios({
        url: `${nodeWebsite}/channel/amazon`,
        method: "POST",
        data: {
          path: "/notifications/v1/subscriptions/FEED_PROCESSING_FINISHED",
          // path: "/listings/2021-08-01/items/A25B3P49S4EZL/DC-BR-040?marketplaceIds=A21TJRUUN4KGV",
          // regionName: "eu-west-1",
          // serviceName: "execute-api",
          method: "POST",
          website,
          body: {
            destinationId: "db405866-7727-47de-b55e-3106cf42676c",
            payloadVersion: "1.0",
          },
        },
        headers: { Authorization: `Basic ${token}` },
      });
      await axios({
        url: `${nodeWebsite}/channel/amazon`,
        method: "POST",
        data: {
          path: "/notifications/v1/subscriptions/REPORT_PROCESSING_FINISHED",
          // path: "/listings/2021-08-01/items/A25B3P49S4EZL/DC-BR-040?marketplaceIds=A21TJRUUN4KGV",
          // regionName: "eu-west-1",
          // serviceName: "execute-api",
          method: "POST",
          website,
          body: {
            destinationId: "db405866-7727-47de-b55e-3106cf42676c",
            payloadVersion: "1.0",
          },
        },
        headers: { Authorization: `Basic ${token}` },
      });
      // console.log(data, "<<<<<<");
      // return data;
    } catch (error) {
      error = getAxiosError(error) || error;
      console.log(error);
    }
    // try {
    //   let { data } = await axios({
    //     url: `${nodeWebsite}/channel/amazon`,
    //     method: "POST",
    //     data: {
    //       path: "/notifications/v1/subscriptions/ORDER_STATUS_CHANGE",
    //       // path: "/listings/2021-08-01/items/A25B3P49S4EZL/DC-BR-040?marketplaceIds=A21TJRUUN4KGV",
    //       // regionName: "eu-west-1",
    //       // serviceName: "execute-api",
    //       method: "POST",
    //       website,
    //       body: {
    //         destinationId: "13370077-580e-4902-84c1-1f98eb4954ad",
    //         payloadVersion: "1.0",
    //       },
    //     },
    //     headers: { Authorization: `Basic ${token}` },
    //   });
    //   console.log(data, "<<<<<<");
    //   return data;
    // } catch (error) {
    //   error = getAxiosError(error) || error;
    //   console.log(error);
    // }
  };

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <AmazonSvg height={25} width={25} /> Amazon
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          {/* <Tab label="Overview" value="overview" /> */}
          <Tab label="Integration" value="integration" />
          <Tab label="Activities" value="activities" />
          {/* <Tab label="Settings" value="settings" /> */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <TabOverview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <TabIntegration />
      </TabPanel>
      <TabPanel value={selectedTab} index={"settings"}>
        <Settings />
      </TabPanel>
      <TabPanel value={selectedTab} index={"activities"}>
        <Activities />
      </TabPanel>

      <Dialog
        open={showAmazonConfig}
        onClose={closeAmazonConfig}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Amazon Integration"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select you market place
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 3 }}>
            {/* <InputLabel id="demo-simple-select-label">
              Select marketplace
            </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={configForm.marketplace_id}
              // label="Select marketplace"
              MenuProps={{ sx: { maxHeight: "250px" } }}
              onChange={(e) => editForm({ marketplace_id: e.target.value })}
            >
              {amazonMarketplaceIds.map((i) => {
                return (
                  <MenuItem value={i.marketplace_id}>
                    {i.country} ({i.marketplace_id})
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAmazonConfig}>Close</Button>
          <LoadingButton
            loading={configLoading}
            variant="contained"
            onClick={onConfigSubmit}
            disabled={!configForm.marketplace_id}
            autoFocus
          >
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Amazon;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}
