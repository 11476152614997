import {
  Button,
  Grid,
  InputAdornment,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  IconButton,
  Chip,
  useMediaQuery,
  LinearProgress,
  Autocomplete,
  Select,
  MenuItem,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  CardContent,
  ListSubheader,
  FormControl,
  InputLabel,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Collapse,
  OutlinedInput,
  Avatar,
  Alert,
  Box,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@mui/system";
import { useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";

import {
  Add,
  CheckCircle,
  Close,
  Delete,
  DeleteOutlineOutlined,
  EditOutlined,
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import styled from "@emotion/styled";
import {
  createCustomer,
  dialCodes,
  dialCodesObj,
  dialogStyle,
  formatDate,
  formatServerValue,
  getAdminName,
  getAxiosError,
  getId,
  getIsAdmin,
  getIsBackendMigrated,
  getMetaValueFromProduct,
  getRandomString,
  getRelativeTimeDifference,
  getShortRefLink,
  getStoreName,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  secondaryLabel,
  StyledTableRow,
  updateCustomer,
  validateEmail,
  validateNumber,
} from "../../../helper";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { DatePicker, LocalizationProvider } from "@mui/lab";

import axios from "axios";
import { AnalyticsSvg, NotesSvg, SessionsSvg, Windows } from "../../../Svgs";
import { UAParser } from "ua-parser-js";
import { useDispatch } from "react-redux";
import { getDropdownData } from "../../../redux/actions/persistActions";
import { DesktopDatePicker } from "@mui/lab";
import {
  sendCRMInvite,
  updateCRMInvite,
  updateCustomerInRedux,
} from "../../../redux/actions/crmActions";
import { commonDialCodes } from "../../crm/Add";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { AnalyticsCard } from "../../integrations/components/ornate";
import ReactApexChart from "react-apexcharts";

import {
  Apple,
  ContentCopy,
  Language,
  Laptop,
  PhoneIphone,
} from "@mui/icons-material";

import { addUserActivity } from "../../../redux/actions/hubActions";
import Switch from "rc-switch";
import InfoTooltip from "../../components/InfoTooltip";
import { addTeamMember } from "../../../redux/actions/settingActions";

import UserTasks from "../../chat/UserTasks";
import { updateChatUserFromCrm } from "../helpers";
import CustomField from "../../../helper/utility/CustomField";
import { scrollToTagId } from "../../../utils/js";
import { CUSTOMER_CONTACT_TYPES } from "../../../constants/crm";
const Info = ({
  customerInfoData,
  fetchingCustomers,
  setCustomerInfoData,
  viewAllInfo,
}) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.paper;
  const inistal = {
    avatar_url: "",
    billing: {
      address_1: "",
      address_2: "",
      city: "",
      company: "",
      country: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      postcode: "",
      state: "",
    },
    catalogs: [],
    customer_analytics: false,
    date_created: "",
    date_created_gmt: "",
    date_modified: "",
    date_modified_gmt: "",
    email: "",
    first_name: "",
    id: "",
    is_paying_customer: false,
    last_name: "",
    company_name: "",
    company_code: "",
    meta_data: [],
    nickname: "",
    notes: [],
    orders: [],
    payment_links: [],
    phone: "",
    dialcode_mobile: "91",
    dialcode_whatsapp: "91",
    role: "",
    sessions: {},
    shipping: {},
    address_2: "",
    username: "",
    whatsapp: "",
    whatsapp_chat: "",
    contact_type: "",
    _links: [],
  };
  const allReduxCustomers = useSelector(
    (state) => state.customers.allCustomers
  );
  const [customerInfo, setCustomerInfo] = useState(inistal);
  const [crmAccess, setCrmAccess] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  let { hash } = location;
  const access = useSelector((state) => state.user.access);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user_type = useSelector((state) => state.user.user_type);

  useEffect(() => {
    if (isArray(access?.crm)) setCrmAccess(access?.crm);
  }, [access]);
  useEffect(() => {
    dispatch(getDropdownData());
  }, []);

  useEffect(() => {
    if (!hash) {
      if (id) {
        navigate(`/crm/${id}/#Info`);
      }
    } else {
      navigate(`/crm/${id}/#Info`);
    }
  }, [id]);
  useEffect(() => {
    setCustomerInfo(customerInfoData);
  }, [customerInfoData, allReduxCustomers]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {fetchingCustomers ? (
        <div
          style={{
            height: "calc(65vh - 74px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 108px)",
            overflowY: "scroll",
          }}
          // style={{ height: "calc(100vh - 108px)", overflowY: "scroll" }}
          className="scrollbar-hidden"
        >
          <Box sx={{ width: "40%" }}>
            <LinearProgress />
            <Typography
              variant="h5"
              sx={{
                fontSize: "16px",
                textAlign: "center",
                mt: 4,
                color: "text.secondary",
              }}
            >
              Fetching Details...
            </Typography>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            //   borderBottom: 1,
            //   borderColor: "divider",
            backgroundColor: "transparent",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              // backgroundColor: "transparent",
              position: "sticky",
              top: "67px",
              boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
              zIndex: 30,
              backgroundColor: backgroundColor,
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Profile" />
            {/* <Tab label="Analytics" /> */}
            {/* <Tab label="Sessions" /> */}
            <Tab
              label="Notes"
              // hasAccess={crmAccess.includes("notes") || isAdmin}
            />
            <Tab
              label="Tasks"
              // hasAccess={crmAccess.includes("notes") || isAdmin}
            />
            {/* {workingCrm && <Tab label="Sessions" />} */}
            {user_type === "supplier" && [
              // <Tab label="Access" />,
              <Tab label="Contacts" />,
            ]}
          </Tabs>

          <TabPanel
            value={value}
            customerInfo={customerInfo}
            fetchingCustomers={fetchingCustomers}
            customerInfoData={customerInfoData}
            crmAccess={crmAccess}
            isAdmin={isAdmin}
            user_type={user_type}
            setCustomerInfo={setCustomerInfoData}
            viewAllInfo={viewAllInfo}
          />
        </Box>
      )}
    </>
  );
};
function TabPanel(props) {
  const {
    children,
    value,
    index,
    customerInfo,
    customerInfoData,
    fetchingCustomers,
    crmAccess,
    isAdmin,
    user_type,
    setCustomerInfo,
    viewAllInfo,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === 0 && (
        <Profile
          customerInfo={customerInfo}
          fetchingCustomers={fetchingCustomers}
          crmAccess={crmAccess}
          isAdmin={isAdmin}
          viewAllInfo={viewAllInfo}
        />
      )}
      {/* {value === 1 && (
        <>
          {false ? (
            <NewAnalytics />
          ) : (
            <Analytics customerInfo={customerInfo} />
          )}
        </>
      )} */}

      {/* {value === 1 && <Sessions customerInfo={customerInfo} />} */}
      {value === 1 && (
        <Notes
          customerInfo={customerInfoData}
          crmAccess={crmAccess}
          isAdmin={isAdmin}
        />
      )}
      {value === 2 && <UserTasks user_from={"crm"} customer={customerInfo} />}
      {/* {value === 3 && <Sessions customerInfo={customerInfo} />} */}
      {/* {value === 3 && user_type === "supplier" && (
        <Access
          customerInfo={customerInfoData}
          fetchingCustomers={fetchingCustomers}
          crmAccess={crmAccess}
          isAdmin={isAdmin}
        />
      )} */}
      {value === 3 && user_type === "supplier" && (
        <Contacts
          customer={customerInfoData}
          setCustomer={setCustomerInfo}
          fetchingCustomers={fetchingCustomers}
          crmAccess={crmAccess}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Info;

const defaultForm = {
  access: [],
  product_groups: [],
  all_product_access: false,
};

const Access = ({ customerInfo }) => {
  // const [customerInfoData, setCustomerInfoData] = useState(customerInfo);
  const [updating, setUpdating] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [form, setForm] = useState({ ...defaultForm });

  const dispatch = useDispatch();

  const changeForm = (payload) =>
    setForm((state) => ({ ...state, ...payload }));

  let { access, product_groups } = form || {};

  const [productGroups, setProductGroups] = useState([
    // {id: 1, enabled: true,title: "All", group_no:0}
  ]);

  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );
  const crmInvites = useSelector((state) => state.customers.crmInvites);
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const store_id = useSelector((state) => state.user.store_id);
  const store_used = useSelector((state) => state.user.store_used);
  const user_type = useSelector((state) => state.user.user_type);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  useEffect(() => {
    if (isArrayWithValues(reduxProductGroups))
      setProductGroups(reduxProductGroups.filter((i) => i.enabled));
  }, [reduxProductGroups]);

  useEffect(() => {
    if (!isArrayWithValues(crmInvites)) return setForm(defaultForm);
    let obj = crmInvites.find((i) => i.crm_id == customerInfo.id);
    if (obj) changeForm({ ...obj });
    else return setForm(defaultForm);
  }, [crmInvites, customerInfo?.id]);

  const handleProductGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    return changeForm({
      product_groups: typeof value === "string" ? value.split(",") : value,
    });
  };

  const onAccessClick = (value) => {
    if (!value) return;
    let array = [].concat(access);
    if (array.includes(value)) array = array.filter((i) => i !== value);
    else array.push(value);
    changeForm({ access: array.filter(Boolean) });
  };

  // const sendInvitation = async () => {
  //   try {
  //     setUpdating(true);
  //     await sendInvitationRetailer(id);
  //     await addRetailerIdInInvite(retailerInvites);
  //     onUpdate({ connect_status: "invited" });
  //   } catch (error) {
  //     setToast({
  //       show: true,
  //       message: "Unable to send invitation, please try again later",
  //       severity: "error",
  //     });
  //   }
  // };

  const onUpdate = async () => {
    // let tem = {
    //   id: "10",
    //   success: true,
    //   url: "https://tanikatechjewelsprivatelimited.app.tanika.tech",
    //   key: "ck_2e104e3ab36efda7481f9d0ce0145346438ad595",
    //   secret: "cs_52ad74cc34d70fe38c74d77951a25a39c1dfae42",
    //   status: "connected",
    //   name: "Tanika Tech Jewels Private Limited",
    //   supplier: "Tanika Tech",
    //   retailer_id: 9,
    //   retailer_name: "Manav Jagani",
    //   access: [
    //     "retailer_app",
    //     "catalogs",
    //     "website",
    //     "instore",
    //     "social_media",
    //   ],
    //   details: {
    //     store_description: "Tanika Tech Jewels Private Limited",
    //     store_email: "care@tanika.tech",
    //     store_logo:
    //       "https://tanikatechjewelsprivatelimited.app.tanika.tech/wp-content/uploads/sites/8/2022/07/tanika_icon.png",
    //     store_color: "#050064",
    //     products: 146,
    //   },
    // };
    let totalProductsAccess = 0;
    let maxProductsLength = 500;
    if (form.all_product_access) {
      totalProductsAccess = validateNumber(store_used.products);
    }
    if (isArrayWithValues(form.product_groups)) {
      let array = [...(form.product_groups || [])];
      for (let group of array) {
        let groupObj = productGroups.find((i) => i.id == group);
        if (groupObj)
          totalProductsAccess += validateNumber(groupObj?.product_ids?.length);
      }
    }
    if (totalProductsAccess > maxProductsLength) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Couldn't give access to more than ${maxProductsLength} products!`,
          severity: "error",
        })
      );
    }
    // return console.log("success");
    let website = getWebsite();
    let id = `${new Date().getTime()}_${getRandomString(6)}`;
    let refObj = {};
    let formId = form.uid || id;
    let store_name = getStoreName();
    let userName = getAdminName();
    if (userName) {
      let names = userName.split(" ");
      if (isArrayWithValues(names) && names.length > 1) {
        refObj.first_name = names[0];
        refObj.last_name = names[names.length - 1];
      } else if (isArrayWithValues(names)) refObj.first_name = names[0];
    }
    //status should be before spreading form
    refObj = {
      ...refObj,
      // status: form.status || "invited",
      uid: formId,
      // ...form,
      // product_groups: form.product_groups,
      // access: form.access,
      store_id,
      url: website,
      store_name,
      // company_name: store_name,
    };
    let retailerObj = {
      status: "invited",
      uid: formId,
      crm_id: customerInfo.id,
      first_name: customerInfo?.first_name,
      last_name: customerInfo?.last_name,
      company_name: customerInfo?.company_name,
      email: customerInfo?.email,
      ...form,
    };
    if (retailerObj.status !== "connected")
      retailerObj.ref_link = await getShortRefLink(refObj);
    // retailerObj = {
    //   status: "requested",
    //   uid: formId,
    //   id: store_id,
    //   ...form,
    //   store_id,
    //   url: website,
    // };
    // console.log(retailerObj, retailerObj);
    // return;

    changeForm({
      uid: formId,
      ref_link: retailerObj.ref_link || form.ref_link,
    });
    dispatch(
      updateCRMInvite({
        obj: retailerObj,
        setUpdating,
        url: retailerObj.url || "",
        // retailerObj: { [retailerObj.uid]: retailerObj },
      })
    );
  };

  const onCopyLink = (link) => {
    navigator?.clipboard?.writeText(link);
    if (navigator?.clipboard?.writeText)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Link copied to clipboard",
          severity: "success",
        })
      );
    else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't copy!",
          severity: "error",
        })
      );
  };

  const onSendInvitation = async () => {
    let id = `${new Date().getTime()}_${getRandomString(6)}`;
    let formId = form.uid || id;
    let retailerObj = {
      status: "invited",
      uid: formId,
      crm_id: customerInfo.id,
      first_name: customerInfo?.first_name,
      last_name: customerInfo?.last_name,
      company_name: customerInfo?.company_name,
      email: customerInfo?.email,
      ...form,
    };

    changeForm({
      uid: formId,
    });
    dispatch(
      sendCRMInvite({
        payload: retailerObj,
        setUpdating: setInviting,
        // retailerObj: { [retailerObj.uid]: retailerObj },
      })
    );
  };

  // return (
  //   <Box sx={{ textAlign: "center", marginTop: "12%" }}>
  //     <SettingsIllustration style={{ width: 250, height: 250 }} />
  //     <Typography sx={{ ...secondaryLabel, mt: 3, fontSize: "14px" }}>
  //       Customer not connected yet
  //     </Typography>
  //     <Button sx={{ mt: 2 }} startIcon={<MailOutline />}>
  //       Send Invite
  //     </Button>
  //   </Box>
  // );

  return (
    <Box sx={{ p: 5, pt: 3 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ marginTop: "18px" }}
      >
        <SectionLabel sx={{ m: 0, p: 0 }} style={{ margin: 0 }}>
          User Access
        </SectionLabel>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"flex-end"}
          sx={{ marginTop: "18px" }}
        >
          {form.status === "connected" ? (
            <Typography sx={{ mr: 2, color: "success.main" }}>
              Connected
            </Typography>
          ) : form.status === "invited" ? (
            <Typography sx={{ mr: 2, color: "info.main" }}>Invited</Typography>
          ) : (
            <LoadingButton loading={inviting} onClick={onSendInvitation}>
              Send Invite
            </LoadingButton>
          )}
          <LoadingButton
            loading={updating || fetchingAllSettings}
            variant="contained"
            size="small"
            onClick={onUpdate}
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>

      <Stack direction={"row"} sx={{ flexWrap: "wrap", mt: 2 }}>
        {AllAcceess.map((item) => {
          return (
            <AccessCard
              obj={item}
              isMobile={isMobile}
              isSelected={Array.isArray(access) && access.includes(item.value)}
              onClick={onAccessClick}
            />
          );
        })}
      </Stack>
      <FormGroup
        sx={{ flex: 1, pt: 3 }}
        style={{
          padding: "0px",
          paddingTop: "13px",
          paddingRight: "10px",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={form.all_product_access}
              onChange={(e) =>
                changeForm({
                  all_product_access: e,
                })
              }
              style={
                form.all_product_access
                  ? {
                      margin: "4px 8px",
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                      marginLeft: "auto",
                    }
                  : {
                      margin: "4px 8px",
                      marginLeft: "auto",
                    }
              }
            />
          }
          label={`All Products Access ${
            store_used.products ? ` (${store_used.products})` : ""
          }`}
          labelPlacement="start"
        />
      </FormGroup>
      <Collapse in={!form.all_product_access}>
        <div>
          <FormControl sx={{ mt: 4 }} fullWidth>
            <InputLabel id="demo-multiple-chip-label">
              Select Product Groups
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // labelId="demo-multiple-chip-label"
              // id="demo-multiple-chip"
              multiple
              value={product_groups || []}
              onChange={handleProductGroupChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Select Product Groups"
                />
              }
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value.id} label={value.title} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {productGroups.map((group) => {
                return (
                  <MenuItem value={group.id}>
                    {group.title}{" "}
                    {group.product_ids ? `(${group.product_ids?.length})` : ""}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Collapse>
      <Collapse in={form?.ref_link}>
        <Typography sx={{ ...secondaryLabel, mt: 3 }}>Invite Link</Typography>
        <TextField
          // label="Invite Link"
          defaultValue={form?.ref_link}
          value={form?.ref_link}
          fullWidth
          // size="small"
          sx={{ mt: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => onCopyLink(form?.ref_link)}
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Collapse>
    </Box>
  );
};

let arrayValueTypes = ["checkbox"];
export const CustomFieldView = ({
  field = {},
  customerInfo,
  onEditCustomer,
  errors,
  textFieldProps = {},
}) => {
  // console.log(field?.options, field?.title, "<<< field options");
  return (
    <Box>
      <CustomField
        label={field.title}
        // value={"hello"}
        value={customerInfo?.[field?.value]}
        onChange={(e) => {
          onEditCustomer({ [field.value]: e });
        }}
        backgroundColor={"transparent"}
        tooltip={field?.description}
        textFieldProps={{
          size: "medium",
          ...textFieldProps,
          // value: customerInfo?.[field?.value],
          // onChange: (e) => {
          //   onEditCustomer({ [field.value]: e?.target?.value || e });
          // },
          // value:
          //   customerInfo?.[field?.value] ||
          //   arrayValueTypes?.includes(field?.value)
          //     ? []
          //     : "",
        }}
        type={field.type}
        options={field.options}
        helperText={errors?.[field?.value]}
        error={Boolean(errors?.[field?.value])}
      />
    </Box>
  );
};

const allMetaKeys = [
  "whatsapp",
  "user_birthday",
  "user_anniversary",
  "profession",
  "annual_income",
  "ethincity",
  "language",
  "religion",
  "sub_sect",
  "customer_since",
  "first_purchase",
  "total_purchase",
  "visits_in_showroom",
  "last_visit_in_showrrom",
  "visits_on_website",
  "last_visits_in_website",
  "customer_facebook",
  "customer_instagram",
  "customer_linkedin",
  "customer_twitter",
  "customer_youtube",
  "customer_pinterest",
  "gender",
  "phone_alt",
  "dialcode_mobile",
  "dialcode_whatsapp",
  "branch_from",
  "company_name",
  "company_code",
  "product_groups",
  "sales_person",
  "unique_id",
  "contact_type",
  "digt_countrycode",
  "digits_phone_no",
  "lead_source",
];
const Profile = ({
  customerInfo,
  fetchingCustomers,
  isAdmin,
  crmAccess,
  viewAllInfo,
}) => {
  const [customerInfoData, setCustomerInfoData] = useState(customerInfo);
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  const [hasError, setHasError] = useState({});
  const [customFieldsBySections, setCustomFieldsBySections] = useState({});
  const [CRMCustomFields, setCRMCustomFields] = useState([]);
  const [metaKeys, setMetaKeys] = useState(allMetaKeys);
  const [errors, setErrors] = useState({});
  const allReduxCustomers = useSelector(
    (state) => state.customers.allCustomers
  );

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isArrayWithValues(CRMCustomFields)) return;
    let fields = CRMCustomFields?.map((i) => i?.value)?.filter(Boolean);
    setMetaKeys((state) => [...new Set([...state, ...fields])]);
  }, [CRMCustomFields]);

  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    let bySections = {};
    setCRMCustomFields(Object.values(custom_fields?.crm));
    Object.entries(custom_fields?.crm).forEach(([key, value]) => {
      let section = value.module;
      // let section = "account_info";
      if (!bySections[section]) bySections[section] = [];
      bySections[section].push(value);
    });
    setCustomFieldsBySections(bySections);
  }, [custom_fields]);

  useEffect(() => {
    setupCustomer(customerInfo, true);
    // setCustomerInfoData(customerInfo);
  }, [customerInfo?.id]);

  const onEditCustomer = (payload, replace) => {
    setCustomerInfoData((state) =>
      replace ? payload : { ...state, ...payload }
    );
    if (isObjWithValues(errors)) setErrors({});
    if (isObjWithValues(hasError)) setHasError({});
    // setProduct({ ...product, ...payload });
  };
  // theme
  const getMetaValueFromCustomer = (customer, key) => {
    let { meta_data } = customer;
    if (!isArrayWithValues(meta_data)) return false;
    let obj = meta_data.find((i) => i.key === key);
    if (!obj) return false;
    return obj.value;
  };

  useEffect(() => {
    // setCustomerInfoData(...(customerInfo || {}), ...metaObjectValue);
    // setCustomerInfoData(metaObjectValue);
    setupCustomer(customerInfo);
  }, [allReduxCustomers, customerInfo, metaKeys]);

  const setupCustomer = (customerInfo = {}, shouldReplace) => {
    let { meta_data, ...customer } = customerInfo;
    let metaObjectValue = { ...customer };
    for (const iterator of metaKeys) {
      let value = getMetaValueFromCustomer(customerInfo, iterator);
      if (value) {
        metaObjectValue[iterator] = value;
      }
    }
    if (isArrayWithValues(CRMCustomFields)) {
      for (let field of CRMCustomFields) {
        if (
          field?.type === "checkbox" &&
          isArrayWithValues(metaObjectValue?.[field?.value]) &&
          typeof metaObjectValue?.[field?.value][0] === "string"
        )
          metaObjectValue[field?.value] = metaObjectValue[field?.value]?.map(
            (i) => ({ label: i, value: i })
          );
      }
    }
    if (metaObjectValue.dialcode_mobile) {
      let obj = dialCodes.find(
        (i) => i.value === metaObjectValue.dialcode_mobile
      );
      if (obj) metaObjectValue.dialcode_mobile = obj.label;
    }

    if (metaObjectValue.dialcode_whatsapp) {
      let obj = dialCodes.find(
        (i) => i.value === metaObjectValue.dialcode_whatsapp
      );
      if (obj) metaObjectValue.dialcode_whatsapp = obj.label;
    }
    if (metaObjectValue?.digits_phone_no && !customerInfo?.billing?.phone)
      metaObjectValue = {
        ...metaObjectValue,
        phone: metaObjectValue?.digits_phone_no,
        billing: {
          phone: metaObjectValue?.digits_phone_no,
        },
      };
    if (metaObjectValue?.digt_countrycode && !customerInfo?.dialcode_mobile)
      metaObjectValue = {
        ...metaObjectValue,
        dialcode_mobile: metaObjectValue?.digt_countrycode?.replaceAll("+", ""),
      };
    onEditCustomer(metaObjectValue, shouldReplace);
  };

  return (
    <>
      {fetchingCustomers ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box sx={{ padding: 5 }}>
          <AccountInfo
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            isPhone={isPhone}
            metaKeys={metaKeys}
            crmAccess={crmAccess}
            isAdmin={isAdmin}
            setHasError={setHasError}
            hasError={hasError}
            CRMCustomFields={CRMCustomFields}
            setErrors={setErrors}
          />
          <CustomFieldsView
            module={"account_info"}
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            errors={errors}
            customFieldsBySections={customFieldsBySections}
          />

          {/* <AccessInfo
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            isPhone={isPhone}
            metaKeys={metaKeys}
            crmAccess={crmAccess}
            isAdmin={isAdmin}
            setHasError={setHasError}
            hasError={hasError}
          /> */}
          <PersonalInfo
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            isPhone={isPhone}
            // setHasError={setHasError}
          />

          <CustomFieldsView
            module={"personal_info"}
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            customFieldsBySections={customFieldsBySections}
            errors={errors}
          />
          <AddressInfo
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            isPhone={isPhone}
            // setHasError={setHasError}
          />

          <CustomFieldsView
            module={"address_info"}
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
            customFieldsBySections={customFieldsBySections}
            errors={errors}
          />
          {viewAllInfo && (
            <>
              <OtherInfo
                onEditCustomer={onEditCustomer}
                customerInfo={customerInfoData}
                isPhone={isPhone}
                // setHasError={setHasError}
              />

              <CustomFieldsView
                module={"other_info"}
                onEditCustomer={onEditCustomer}
                customerInfo={customerInfoData}
                customFieldsBySections={customFieldsBySections}
                errors={errors}
              />
              <SocialInfo
                onEditCustomer={onEditCustomer}
                customerInfo={customerInfoData}
                isPhone={isPhone}
              />

              <CustomFieldsView
                module={"social_info"}
                onEditCustomer={onEditCustomer}
                customerInfo={customerInfoData}
                customFieldsBySections={customFieldsBySections}
                errors={errors}
              />
            </>
          )}
          {/* <Grouping
            onEditCustomer={onEditCustomer}
            customerInfo={customerInfoData}
          /> */}
        </Box>
      )}
    </>
  );
};

const CustomFieldsView = ({
  customFieldsBySections,
  onEditCustomer,
  customerInfo,
  module,
  errors,
}) => {
  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      {customFieldsBySections?.[module]?.map((i) => {
        return (
          <Grid item key={i.value} xs={12} md={6} id={`field_${i.value}`}>
            <CustomFieldView
              field={i}
              onEditCustomer={onEditCustomer}
              customerInfo={customerInfo}
              errors={errors}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const defaultContact = {
  first_name: "",
  last_name: "",
  email: "",
  job_role: "",
  whatsapp: "",
  whatsapp_dial: { label: "India 🇮🇳 (+91)", value: "91" },
  access: [],
};

export const jobRoles = [
  { label: "Proprietor", value: "proprietor" },
  { label: "Partner", value: "partner" },
  { label: "Director", value: "director" },
  { label: "Store Manager", value: "store_manager" },
  { label: "Sales Person", value: "sales_person" },
  { label: "Counter Sales Person", value: "counter_sales_person" },
  { label: "Floor Manager", value: "floor_manager" },
  { label: "Purchase Executive", value: "purchase_executive" },
  { label: "Purchase Manager", value: "purchase_manager" },
  { label: "Purchase Manager - Gold", value: "purchase_manager_gold" },
  {
    label: "Purchase Manager - Diamond",
    value: "purchase_manager_diamond",
  },
  { label: "Account Manager", value: "account_manager" },
  { label: "Account Executive", value: "account_executive" },
];

const ContactAccess = [
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "SMS", value: "SMS" },
];

const Contacts = ({ customer, setCustomer }) => {
  const [_showAddDialog, setShowAddDialog] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [addForm, setAddForm] = useState(defaultContact);
  const [contacts, setContacts] = useState([]);
  const [addingContact, setAddingContact] = useState(false);
  const [editId, setEditId] = useState("");
  const [alert, setAlert] = useState({ show: false });
  const [showDeleteContact, setShowDeleteContact] = useState(false);

  useEffect(() => {
    if (isObjWithValues(customer))
      setCustomerName(`${customer.first_name} ${customer.last_name}`);
    if (customer && isArrayWithValues(customer.meta_data)) {
      let { meta_data } = customer;
      let contactsObj = meta_data.find((i) => i.key === "contacts");
      if (contactsObj && isArrayWithValues(contactsObj.value))
        setContacts(contactsObj.value);
    }
  }, [customer]);

  const hideAddDialog = () => {
    setShowAddDialog(false);
    if (editId) setAddForm(defaultContact);
    setEditId(false);
    if (showDeleteContact) setShowDeleteContact(false);
  };
  const showAddDialog = () => setShowAddDialog(true);

  const editAddForm = (payload) => {
    if (alert.show) setAlert((state) => ({ ...state, show: false }));
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const onAddContact = async () => {
    let {
      first_name,
      last_name,
      email,
      whatsapp,
      whatsapp_dial,
      job_role,
      access,
    } = addForm;
    // if (
    //   !first_name ||
    //   !last_name ||
    //   !email ||
    //   !job_role ||
    //   !whatsapp ||
    //   !isArrayWithValues(access)
    // )
    //   return setAlert({
    //     show: true,
    //     message: "Please fill all fields!",
    //     severity: "error",
    //   });
    // if (!validateEmail(email))
    //   return setAlert({
    //     show: true,
    //     message: "Please enter valid email!",
    //     severity: "error",
    //   });
    // if (whatsapp.length < 7)
    //   return setAlert({
    //     show: true,
    //     message: "Please enter valid Whatsapp No!",
    //     severity: "error",
    //   });

    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts.push({
      ...addForm,
      id: getRandomString(8),
      created: parseInt(Date.now() / 1000),
      whatsapp_dial: whatsapp_dial.value,
    });
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();
    if (res) setCustomer(res);
  };
  const onEditContact = async () => {
    let {
      first_name,
      last_name,
      email,
      whatsapp,
      whatsapp_dial,
      job_role,
      access,
    } = addForm;
    if (
      !first_name ||
      !last_name ||
      !email ||
      !whatsapp
      // ||
      // !job_role ||
      // !isArrayWithValues(access)
    )
      return setAlert({
        show: true,
        message: "Please fill required fields!",
        severity: "error",
      });
    if (email && !validateEmail(email))
      return setAlert({
        show: true,
        message: "Please enter valid email!",
        severity: "error",
      });
    if (whatsapp.length < 7)
      return setAlert({
        show: true,
        message: "Please enter valid Whatsapp No!",
        severity: "error",
      });

    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts = contacts.map((i) => {
      return i.id === editId
        ? {
            ...i,
            ...addForm,
            whatsapp_dial: whatsapp_dial.value,
          }
        : i;
    });
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();

    if (res) setCustomer(res);
  };

  const onDeleteContact = async () => {
    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts = contacts.filter((i) => i.id !== editId);
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();
    if (res) setCustomer(res);
  };

  const onEditPress = (contact) => {
    let whatsapp_dial = dialCodes.find((i) => i.value == contact.whatsapp_dial);
    setEditId(contact.id);
    setAddForm({ ...contact, whatsapp_dial });
    showAddDialog();
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <Typography variant="h6" ml={1} fontWeight="bold">
                {customerName && `${customerName}'s`} Contacts
              </Typography>
              <InfoTooltip title="Other contacts of the same organisation. You can add new contact" />
            </Stack>
            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={showAddDialog}
            >
              New
            </Button>
          </Stack>

          {contacts.map((contact) => {
            return (
              <Stack
                direction="row"
                hover
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "rgba(150, 150, 150,0.1)" },
                }}
                my={1}
                py={4}
                pl={2}
                onClick={() => onEditPress(contact)}
              >
                <Stack alignItems={"center"} direction="row" mr={2}>
                  {/* <AccountBox /> */}
                  <Avatar sx={{ width: 34, height: 34 }} />
                </Stack>

                <Box>
                  <Stack direction={"row"} alignItems="center">
                    <Typography variant="h6" sx={{ my: 0, mr: 1 }}>
                      {contact.first_name} {contact.last_name}
                    </Typography>
                    {/* <ContactAccessIcon
                    tooltip="Whatsapp"
                    icon={
                      <WhatsappOutlined
                        style={{ height: "18px", width: "18px" }}
                      />
                    }
                  />
                  <ContactAccessIcon
                    tooltip="Email"
                    icon={
                      <EmailOutlined
                        style={{ height: "18px", width: "18px" }}
                      />
                    }
                  />
                  <ContactAccessIcon
                    tooltip="SMS"
                    icon={
                      <ChatOutlined style={{ height: "18px", width: "18px" }} />
                    }
                  /> */}
                  </Stack>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1, mr: 2 }}
                    fontSize="12px"
                  >
                    {formatDate(
                      new Date(+contact.created * 1000),
                      "dd:mm:yyyy hh:mm am/pm"
                    )}
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </CardContent>
      </Card>
      <Dialog
        open={_showAddDialog}
        onClose={hideAddDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            {editId ? "Edit Contact" : "Add New Contact"}
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              {editId && (
                <IconButton
                  onClick={() => setShowDeleteContact(true)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              )}
              <IconButton onClick={hideAddDialog}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Collapse in={alert.show}>
            <Alert severity={alert.severity}>{alert.message}</Alert>
          </Collapse>
          <Collapse in={showDeleteContact}>
            <Alert
              severity={"error"}
              action={
                <>
                  <Button color="error" size="small" onClick={onDeleteContact}>
                    DELETE
                  </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setShowDeleteContact(false)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </>
              }
            >
              Delete this contact?
            </Alert>
          </Collapse>
          <DialogContentText id="alert-dialog-description">
            <Stack direction="row" spacing={3} mt={3}>
              <TextField
                value={addForm.first_name}
                onChange={(e) => editAddForm({ first_name: e.target.value })}
                size="small"
                fullWidth
                label="First Name*"
              />
              <TextField
                value={addForm.last_name}
                onChange={(e) => editAddForm({ last_name: e.target.value })}
                size="small"
                fullWidth
                label="Last Name*"
              />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addForm.email}
                  onChange={(e) => editAddForm({ email: e.target.value })}
                  size="small"
                  fullWidth
                  label="Email*"
                  sx={{ mt: 3 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 3 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Job Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.job_role}
                    label="Job Role"
                    onChange={(e) => editAddForm({ job_role: e.target.value })}
                  >
                    {jobRoles.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={3} sx={{ mt: 3 }}>
              <FormControl sx={{ flex: 1, minWidth: "82px" }} size="small">
                {/* <InputLabel id="demo-simple-select-label">Dial</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addForm.whatsapp_dial}
                  // label="Dial"
                  // renderValue={}
                  onChange={(e) =>
                    editAddForm({ whatsapp_dial: e.target.value })
                  }
                  renderValue={(value) => `+${value.value}`}
                >
                  {dialCodes.map((i) => (
                    <MenuItem value={i}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={addForm.whatsapp}
                onChange={(e) => editAddForm({ whatsapp: e.target.value })}
                size="small"
                fullWidth
                label="Whatsapp*"
                type="number"
              />
            </Stack>
            <FormControl
              sx={{ flex: 1, minWidth: "82px", mt: 3 }}
              fullWidth
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Access</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.access}
                label="Access"
                multiple
                // renderValue={}
                onChange={(e) => editAddForm({ access: e.target.value })}
                // renderValue={(value) => `+${value.value}`}
              >
                {ContactAccess.map((i) => (
                  <MenuItem value={i.value}>{i.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideAddDialog} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={addingContact}
            onClick={editId ? onEditContact : onAddContact}
            autoFocus
          >
            {editId ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AllAcceess = [
  { label: "Retailer App", value: "retailer_app", disabled: false },
  { label: "Catalogs", value: "catalogs", disabled: false },
  { label: "Website", value: "website", disabled: false },
  { label: "Instore", value: "instore", disabled: false },
  { label: "Social Media", value: "social_media", disabled: false },
  { label: "Market Places", value: "market_places", disabled: true },
];

const AccessCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && !obj.disabled && onClick(obj.value)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "14px",
            // background: colorsBackground[obj.value],
            // background: "linear-gradient(to right, #E1C16E,#edd595 )",
          }}
        /> */}
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <Typography
          variant={isMobile ? "body2" : "h6"}
          sx={obj.disabled ? { color: "text.secondary" } : {}}
        >
          {obj.label}
        </Typography>
      </CardContent>
    </Card>
  );
};

// infomations components
const AccountInfo = ({
  onEditCustomer,
  customerInfo,
  isPhone,
  metaKeys,
  crmAccess,
  isAdmin,
  hasError,
  setHasError,
  CRMCustomFields,
  setErrors,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user_type = useSelector((state) => state.user.user_type);
  const [teamMembers, setTeamMembers] = useState([]);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const [dialcodeInfo, setDialcodeInfo] = useState({});

  useEffect(() => {
    let info = dialCodes.find((i) => i.label === customerInfo?.dialcode_mobile);
    let whatsappInfo = dialCodes.find(
      (i) => i.label === customerInfo?.dialcode_whatsapp
    );
    let payload = {};

    if (info) payload.phone = info;
    if (whatsappInfo) payload.whatsapp = whatsappInfo;

    if (info) setDialcodeInfo((state) => ({ ...state, ...payload }));
  }, [customerInfo?.dialcode_mobile, customerInfo?.dialcode_whatsapp]);

  useEffect(() => {
    if (isArrayWithValues(reduxTeamMembers))
      setTeamMembers(
        reduxTeamMembers.map((i) => ({
          label: `${i.first_name || ""} ${i.last_name || ""}`.trim(),
          value: i.id,
        }))
      );
  }, [reduxTeamMembers]);
  const updateCustomerHandler = async () => {
    let updateObject = { ...customerInfo };
    let allTeamMember = [];

    let isAdded;
    let wantToAdd;
    for (let members of reduxTeamMembers || []) {
      isAdded = members?.assigned_customers?.find(
        (obj) => obj?.value === updateObject?.id
      );
      if (isAdded && members?.id == updateObject?.sales_person) {
        wantToAdd = false;
      }
      if (isAdded && !members?.id == updateObject?.sales_person) {
        members.assigned_customers = members?.assigned_customers?.filter(
          (o) => o.value != updateObject?.id
        );
        wantToAdd = true;
      }

      if (!isAdded && members?.id == updateObject?.sales_person) {
        members.assigned_customers = [
          ...(members.assigned_customers || []),
          {
            label: `${updateObject?.first_name} ${updateObject?.last_name}`,
            value: updateObject?.id,
          },
        ];
        wantToAdd = true;
      }
      allTeamMember.push(members);
    }

    // if (updateObject?.updateObject && )
    if (wantToAdd) {
      await dispatch(
        addTeamMember(
          allTeamMember,
          () => {
            // onClose();
            // clearBulk();
          },
          () => {}
        )
      );
    }
    // let sales_person = sales_person

    if (customerInfo?.billing?.phone && !customerInfo?.dialcode_mobile) {
      setHasError((pre) => ({ ...pre, dialcode_mobile: true }));
      dispatch(
        setGlobalToast({
          show: true,
          message: "Please Enter dialcode",
          severity: "error",
        })
      );
      return;
    }
    if (customerInfo?.whatsapp && !customerInfo?.dialcode_whatsapp) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Please Enter dialcode",
          severity: "error",
        })
      );
      setHasError((pre) => ({ ...pre, dialcode_whatsapp: true }));
      return;
    }
    setLoading(true);
    let errors = {};

    if (updateObject.company_name)
      updateObject.billing.company_name = updateObject.company_name;

    if (!updateObject.email) {
      delete updateObject.email;
      delete updateObject.billing.email;
    } else {
      updateObject.billing.email = updateObject.email;
    }
    let digits_phone_no = getMetaValueFromProduct(
      customerInfo,
      "digits_phone_no"
    );
    let digt_countrycode = getMetaValueFromProduct(
      customerInfo,
      "digt_countrycode"
    );
    let digits_phone = getMetaValueFromProduct(customerInfo, "digits_phone");
    if (!customerInfo?.billing?.phone && digits_phone_no) {
      customerInfo.billing.phone = digits_phone_no;
    }

    let meta_data = [...(updateObject.meta_data || [])];

    if (customerInfo?.billing?.phone) {
      meta_data.push({
        key: "digits_phone_no",
        value: customerInfo?.billing?.phone,
      });
    }
    if (customerInfo?.dialcode_mobile) {
      meta_data.push({
        key: "digt_countrycode",
        value: `+${
          dialCodesObj[customerInfo?.dialcode_mobile] ||
          customerInfo?.dialcode_mobile
        }`,
      });
    }
    if (customerInfo?.dialcode_mobile && customerInfo?.billing?.phone) {
      meta_data.push({
        key: "digits_phone",
        value: `+${dialCodesObj[customerInfo?.dialcode_mobile]}${
          customerInfo?.billing?.phone
        }`,
      });
    }
    for (let item of metaKeys) {
      if (customerInfo[item]) {
        let metaObj = { key: item, value: customerInfo[item] };

        let existingObj = meta_data.find((i) => i.key == item);
        if (existingObj)
          meta_data = meta_data.map((i) => (i.key === item ? metaObj : i));
        else meta_data.push(metaObj);
        // meta_data = meta_data.map((obj) =>
        //   meta_data.find((o) => o.key === item) ? metaObj : obj
        // );
        // meta_data.push({ key: item, value: customerInfo[item] });
      }
      // if (customerInfo[item]) {
      //   const metaObject = customerInfo.meta_data.find(
      //     (obj) => obj.key === item
      //   );

      //   console.log(customerInfo[item], metaObject);
      //   if (metaObject) {
      //     const replaceMetaData = customerInfo.meta_data.map((obj) =>
      //       obj.key === metaObject.key
      //         ? { ...metaObject, value: customerInfo[item] }
      //         : obj
      //     );

      //     updateObject.meta_data = replaceMetaData;
      //     delete updateObject[item];
      //   } else {
      //     console.log("updating", item, updateObject.meta_data.length);
      //     updateObject.meta_data = [
      //       ...updateObject.meta_data,
      //       { key: item, value: customerInfo[item] },
      //     ];
      //     // updateObject.meta_data.push({ key: item, value: customerInfo[item] });
      //     console.log("length", updateObject.meta_data.length);
      //   }
      // }
    }
    if (isArrayWithValues(CRMCustomFields)) {
      for (let field of CRMCustomFields) {
        let { value, type, mandatory } = field;
        if (updateObject?.[value]) {
          if (type === "checkbox") {
            if (isArrayWithValues(updateObject?.[value])) {
              console.log(
                "inside checkbox",
                updateObject?.[value]?.map((i) => i.value)
              );
              meta_data.push({
                key: value,
                value: updateObject?.[value]?.map((i) => i.value),
                // type === "data" || type === "time" ? new Date(type) :
              });
            }
          } else
            meta_data.push({
              key: value,
              value: updateObject?.[value],
              // type === "data" || type === "time" ? new Date(type) :
            });
          delete updateObject?.[value];
        } else if (mandatory) {
          errors[value] = "Field is required";
        }
      }
    }
    if (isObjWithValues(errors)) {
      setErrors(errors);
      scrollToTagId(`field_${Object.keys(errors)?.[0]}`);
      setLoading(false);
      return;
    }

    updateObject.meta_data = meta_data;
    if (isArrayWithValues(updateObject.meta_data)) {
      let dialcodeObj = updateObject.meta_data.find(
        (i) => i.key === "dialcode_mobile"
      );
      let dialcodeWhatsappObj = updateObject.meta_data.find(
        (i) => i.key === "dialcode_whatsapp"
      );
      let whatsappObj = updateObject.meta_data.find(
        (i) => i.key === "whatsapp"
      );
      if (dialcodeWhatsappObj && whatsappObj) {
        let whatsapp_id = `${dialCodesObj[dialcodeWhatsappObj.value]}${
          whatsappObj.value
        }`;
        // updateObject.meta_data = updateObject.meta_data.map((i) =>
        //   i.key === "whatsapp_id"
        //     ? { key: "whatsapp_id", value: whatsapp_id }
        //     : i
        // );
      }
      if (dialcodeObj) {
        updateObject.meta_data = updateObject.meta_data.map((i) =>
          i.key === "dialcode_mobile"
            ? { key: "dialcode_mobile", value: dialCodesObj[dialcodeObj.value] }
            : i
        );
      }
      if (dialcodeWhatsappObj) {
        updateObject.meta_data = updateObject.meta_data.map((i) =>
          i.key === "dialcode_whatsapp"
            ? {
                key: "dialcode_whatsapp",
                value: dialCodesObj[dialcodeWhatsappObj.value],
              }
            : i
        );
      }
    }
    // if (isDev()) {
    //   console.log(JSON.stringify(updateObject, null, 2), customerInfo);
    //   setLoading(false);
    //   return;
    // }
    let whatsapp_id = getMetaValueFromProduct(updateObject, "whatsapp_id");
    if (isObjWithValues(whatsapp_id) && getIsBackendMigrated("chats"))
      await updateChatUserFromCrm({ customer: updateObject });
    //update object of customer
    const res = await updateCustomer(updateObject.id, updateObject);
    if (res?.error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: res?.error?.message,
          severity: "error",
        })
      );
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: "User updated successfully!",
          severity: "success",
        })
      );
      dispatch(updateCustomerInRedux(res));
    }
    // console.log(res);

    dispatch(
      addUserActivity({
        event: "customer_update",
        event_info: {
          _id: res?.id,
          name: `${res?.first_name || ""} ${res?.last_name || ""}`,
          email: res?.email,
        },
      })
    );

    setLoading(false);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <SectionLabel sx={{ m: 0 }}>Account Info</SectionLabel>
        <Stack direction={"row"} spacing={3}>
          {/* <FormGroup size="small">
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select size="small" label="Type">
              <MenuItem value={"customer"}>Customer</MenuItem>
              <MenuItem value={"lead"}>Lead</MenuItem>
            </Select>
          </FormGroup> */}
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{ padding: "2px 16px" }}
            onClick={updateCustomerHandler}
            disabled={!crmAccess?.includes("edit") && !isAdmin}
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>
      <Box sx={{ height: "13px" }}></Box>
      <Grid container spacing={3} rowSpacing={5}>
        <Grid item xs={6}>
          <Label>First Name</Label>
          <TextField
            // label="First Name"
            name="first_name"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ first_name: e.target.value });
            }}
            value={customerInfo?.first_name}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Last Name</Label>
          <TextField
            // label="Last Name"
            // name="last_name"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                last_name: e.target.value,
              });
            }}
            value={customerInfo.last_name}
          />
        </Grid>
        {user_type === "supplier" || true ? (
          <>
            <Grid item xs={6}>
              <Label>Company Name</Label>
              <TextField
                // label="Last Name"
                // name="last_name"
                fullWidth
                onChange={(e) => {
                  onEditCustomer({
                    company_name: e.target.value,
                  });
                }}
                value={customerInfo.company_name || ""}
              />
            </Grid>
            <Grid item xs={6}>
              <Label>Company Code</Label>
              <TextField
                // label="Last Name"
                // name="last_name"
                fullWidth
                onChange={(e) => {
                  onEditCustomer({
                    company_code: e.target.value,
                  });
                }}
                value={customerInfo.company_code || ""}
              />
            </Grid>
          </>
        ) : null}
        {/* 
        <Grid item xs={6}>
          <Label>Company Name</Label>
          <TextField
            // label="Last Name"
            // name="last_name"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                company_name: e.target.value,
              });
            }}
            value={customerInfo.company_name}
          />
        </Grid> */}
        <Grid item xs={isPhone ? 12 : 6}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Label>
              Email{" "}
              {customerInfo?.username === customerInfo.email
                ? "(Primary)"
                : null}
            </Label>
          </Stack>
          <TextField
            // disabled
            // label="Email"
            // name="email"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                email: e.target.value,
                billing: { ...customerInfo.billing, email: e.target.value },
              });
            }}
            value={customerInfo.email}
          />
        </Grid>
        {/* <Grid item xs={isPhone ? 12 : 6}>
          <Label>Nickname</Label>
          <TextField
            // label="Nickname"
            // name="nickname"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ nickname: e.target.value });
            }}
            value={customerInfo.nickname}
          />
        </Grid> */}
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>
            Mobile{" "}
            {customerInfo?.billing?.phone?.includes(customerInfo?.username)
              ? "(Primary)"
              : null}
          </Label>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} xl={3}>
              {/* <MuiPhoneNumber
                defaultCountry={"us"}
                variant="outlined"
                size="medium"
                fullWidth
                onChange={(e) => console.log(e)}
              /> */}
              <Select
                disabled={customerInfo?.otp_verified}
                fullWidth
                error={hasError?.dialcode_mobile}
                onChange={(e) => {
                  setHasError((pre) => ({ ...pre, dialcode_mobile: false }));

                  onEditCustomer({
                    dialcode_mobile: e.target.value,
                  });
                }}
                value={customerInfo.dialcode_mobile || ""}
                MenuProps={{ sx: { maxHeight: "400px" } }}
                // renderValue={(value) => `+${value.value}`}
                renderValue={(e) =>
                  `+${dialCodesObj[e] || customerInfo.dialcode_mobile || ""}`
                }
              >
                <ListSubheader>Common Dial Codes</ListSubheader>
                {commonDialCodes.map((i) => {
                  return (
                    <MenuItem key={i.value} value={i.label}>
                      {i.label}
                    </MenuItem>
                  );
                })}
                <ListSubheader>Dial Codes</ListSubheader>
                {dialCodes.map((obj) => (
                  <MenuItem key={obj.value} value={obj.label}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8} md={8} xl={9}>
              <TextField
                type="number"
                disabled={customerInfo?.otp_verified}
                fullWidth
                onChange={(e) => {
                  onEditCustomer({
                    billing: {
                      ...customerInfo.billing,
                      phone: e.target.value?.substring(
                        0,
                        dialcodeInfo?.phone?.max ? dialcodeInfo?.phone?.max : 13
                      ),
                    },
                  });
                }}
                value={
                  customerInfo.billing.phone ? customerInfo.billing.phone : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>WhatsApp</Label>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4} xl={3}>
              <Select
                error={hasError?.dialcode_whatsapp}
                fullWidth
                onChange={(e) => {
                  setHasError((pre) => ({ ...pre, dialcode_whatsapp: false }));
                  onEditCustomer({
                    dialcode_whatsapp: e.target.value,
                  });
                }}
                value={customerInfo.dialcode_whatsapp || ""}
                MenuProps={{ sx: { maxHeight: "400px" } }}
                // renderValue={(value) => `+${value.value}`}
                renderValue={(e) => `+${dialCodesObj[e]}`}
                renderMenu
              >
                {/* {Object.keys(dialCodesObj).map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))} */}
                <ListSubheader>Common Dial Codes</ListSubheader>
                {commonDialCodes.map((i) => {
                  return (
                    <MenuItem key={i.value} value={i.label}>
                      {i.label}
                    </MenuItem>
                  );
                })}
                <ListSubheader>Dial Codes</ListSubheader>
                {dialCodes.map((obj) => (
                  <MenuItem key={obj.value} value={obj.label}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8} md={8} xl={9}>
              <TextField
                // label="WhatsApp"
                // name="whatsapp"
                fullWidth
                onChange={(e) => {
                  onEditCustomer({
                    whatsapp: e.target.value?.substring(
                      0,
                      dialcodeInfo?.whatsapp?.max
                        ? dialcodeInfo?.whatsapp?.max
                        : 13
                    ),
                  });
                }}
                value={customerInfo.whatsapp || ""}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label>Team Member</Label>
          <FormControl fullWidth>
            <Select
              value={customerInfo?.sales_person || ""}
              onChange={(e) => {
                onEditCustomer({ sales_person: e.target.value });
              }}
              fullWidth
            >
              {teamMembers.map((i) => {
                return <MenuItem value={i.value}>{i.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Label>Contact Type</Label>
          <FormControl fullWidth>
            <Select
              value={customerInfo.contact_type}
              onChange={(e) => {
                onEditCustomer({ contact_type: e.target.value });
              }}
              fullWidth
            >
              {CUSTOMER_CONTACT_TYPES.map((i) => {
                return (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <FormControl color="secondary">
            <RadioGroup row sx={{ gap: "8px" }}>
              <Button
                onClick={() => onEditCustomer({ contact_type: "customer" })}
                variant="outlined"
                color="secondary"
              >
                <FormControlLabel
                  control={
                    <Radio checked={customerInfo.contact_type === "customer"} />
                  }
                  label="Customer"
                />
              </Button>
              <Button
                onClick={() => onEditCustomer({ contact_type: "lead" })}
                variant="outlined"
                color="secondary"
              >
                <FormControlLabel
                  control={
                    <Radio checked={customerInfo.contact_type === "lead"} />
                  }
                  label="Lead"
                />
              </Button>
            </RadioGroup>
          </FormControl> */}
        </Grid>
        {customerInfo.unique_id && (
          <Grid item xs={12} sm={6}>
            <Label>Unique ID</Label>
            <TextField
              // label="Mobile"
              // name="phone"
              fullWidth
              // onChange={(e) => {
              //   onEditCustomer({
              //     billing: { ...customerInfo.billing, phone: e.target.value },
              //   });
              // }}
              disabled
              value={customerInfo.unique_id}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const SectionLabel = styled(Typography)`
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 18px;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #6f6f6f;
`;
// margin-top: 2px;

const PersonalInfo = ({ onEditCustomer, customerInfo, isPhone }) => {
  // let professionArray = [];

  const occupationsArray = useSelector((state) => state.persist.occupations);

  return (
    <>
      <SectionLabel>Personal Info</SectionLabel>

      <Grid container spacing={3} rowSpacing={6}>
        {/* <Box sx={{ height: "13px" }}></Box> */}

        <Grid item xs={6}>
          <Label>Birthday</Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              // label="Birthday"
              // name="birthdate"
              renderInput={(params) => (
                <TextField fullWidth label="Birthday" {...params} />
              )}
              fullWidth
              onChange={(newValue) => {
                onEditCustomer({ user_birthday: formatDate(newValue) });
              }}
              value={
                customerInfo.user_birthday ? customerInfo.user_birthday : null
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Label>Gender</Label>
          <FormControl fullWidth>
            <Select
              value={customerInfo.gender || ""}
              onChange={(e) => {
                onEditCustomer({ gender: e.target.value });
              }}
              fullWidth
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Prefer not to say</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Label>Anniversary</Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              // label="Anniversary"
              value={
                customerInfo.user_anniversary
                  ? customerInfo.user_anniversary
                  : null
              }
              onChange={(newValue) => {
                onEditCustomer({ user_anniversary: formatDate(newValue) });
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Label>Profession</Label>
          <Autocomplete
            id="combo-box-demo"
            options={occupationsArray || []}
            value={customerInfo.profession ? customerInfo.profession : ""}
            onChange={(event, newInputValue) => {
              onEditCustomer({ profession: newInputValue?.label || "" });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Annual Income</Label>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={incomeArray}
            value={customerInfo.annual_income ? customerInfo.annual_income : ""}
            onChange={(event, newInputValue) => {
              onEditCustomer({ annual_income: newInputValue });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Ethnicity</Label>
          <Autocomplete
            id="combo-box-demo"
            options={ethincityArray}
            value={customerInfo.ethincity ? customerInfo.ethincity : ""}
            onChange={(event, newInputValue) => {
              onEditCustomer({ ethincity: newInputValue });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Language</Label>
          <Autocomplete
            id="combo-box-demo"
            options={languageArray}
            value={customerInfo.language ? customerInfo.language : ""}
            onChange={(event, newInputValue) => {
              onEditCustomer({ language: newInputValue });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Religion</Label>
          <Autocomplete
            id="combo-box-demo"
            options={religionArray}
            value={customerInfo.religion ? customerInfo.religion : ""}
            onChange={(event, newInputValue) => {
              onEditCustomer({ religion: newInputValue });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      <Typography variant="caption" style={{ marginTop: 3, color: "#808080" }}>
        * This information is only for personalising product offers &
        communications
      </Typography>
    </>
  );
};

const AddressInfo = ({ onEditCustomer, customerInfo, isPhone }) => {
  const countriesArray = useSelector((state) => state.persist.countries);
  const stateArray = useSelector((state) => state.persist.states);
  const [states, setStates] = useState("");
  const [stateLabel, setStateLabel] = useState("");
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    const countrycodeInistal = customerInfo.billing.country;
    const countryObj =
      isArrayWithValues(countriesArray) &&
      countriesArray.find((item) => item.code === countrycodeInistal);

    if (countryObj) {
      setCountry(countryObj.label);
      setCountryCode(countryObj.code);
    }
  }, [customerInfo, countriesArray]);

  useEffect(() => {
    if (isObjWithValues(stateArray)) {
      for (const key in stateArray) {
        if (key === countryCode) {
          setStates(stateArray[key]);
        }
      }
    }
  }, [countryCode]);
  const [country, setCountry] = useState("");
  useEffect(() => {
    const statecodeInistal = customerInfo.billing.state;
    if (isArrayWithValues(states)) {
      const stateObj = states.find((item) => item.code === statecodeInistal);

      if (stateObj) {
        setStateLabel(stateObj.label);
      }
    }
  }, [customerInfo, states]);

  return (
    <>
      <SectionLabel>Address Info</SectionLabel>

      <Grid container spacing={3}>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Address Line 1</Label>
          <TextField
            // label="Adress Line 1"
            name="address_1"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  address_1: e.target.value,
                },
              });
            }}
            value={customerInfo.billing.address_1 || ""}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Address Line 2</Label>
          <TextField
            // label="Adress Line 2"
            name="address_2"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  address_2: e.target.value,
                },
              });
            }}
            value={customerInfo.billing.address_2 || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>City</Label>
          <TextField
            // label="City"
            name="city"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  city: e.target.value,
                },
              });
            }}
            value={customerInfo.billing.city || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Post Code</Label>
          <TextField
            // label="Post Code"
            name="postcode"
            fullWidth
            onChange={(e) => {
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  postcode: e.target.value,
                },
              });
            }}
            value={customerInfo.billing.postcode || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Country</Label>
          <Autocomplete
            id="combo-box-demo"
            options={countriesArray || []}
            value={country}
            onChange={(event, newInputValue) => {
              setCountry(newInputValue.label);
              setCountryCode(newInputValue.code);
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  country: newInputValue.code,
                },
              });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>State</Label>
          <Autocomplete
            id="combo-box-demo"
            options={states || []}
            value={stateLabel ? stateLabel : ""}
            onChange={(event, newInputValue) => {
              setStateLabel(newInputValue.label || "");
              setCountryCode(newInputValue.code);
              onEditCustomer({
                billing: {
                  ...customerInfo.billing,
                  state: newInputValue.code,
                },
              });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </>
  );
};

const OtherInfo = ({ onEditCustomer, customerInfo, isPhone }) => {
  let branches = useSelector((state) => state.settings.branch);
  const [allBranches, setAllBranches] = useState([]);
  useEffect(() => {
    if (isObjWithValues(branches)) {
      // let array =[];
      // for(let obj of Object.values(branches))
      setAllBranches(Object.values(branches).map((i) => i.branchName));
    }
  }, [branches]);
  return (
    <>
      <SectionLabel>Others Info</SectionLabel>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Label>Lead Source</Label>
          <TextField
            // label="Lead Source"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ lead_source: e.target.value });
            }}
            value={customerInfo.lead_source ? customerInfo.lead_source : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Customer Since</Label>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disableFuture
              // label="First Purchase"
              value={
                customerInfo.customer_since ? customerInfo.customer_since : null
              }
              onChange={(newValue) => {
                onEditCustomer({ customer_since: formatDate(newValue) });
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
          {/* <TextField
            // label="Customer Since"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ customer_since: e.target.value });
            }}
            value={
              customerInfo.customer_since ? customerInfo.customer_since : ""
            }
          /> */}
        </Grid>
        <Grid item xs={6}>
          <Label>First Purchase</Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disableFuture
              // label="First Purchase"
              value={
                customerInfo.first_purchase ? customerInfo.first_purchase : null
              }
              onChange={(newValue) => {
                onEditCustomer({ first_purchase: formatDate(newValue) });
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Label>Total Purchase</Label>
          <TextField
            type="number"
            // label="Total Purchase"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ total_purchase: e.target.value });
            }}
            value={
              customerInfo.total_purchase ? customerInfo.total_purchase : ""
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Label>Showroom Visits</Label>
          <TextField
            // label="Showroom Visits"
            type="number"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ visits_in_showroom: e.target.value });
            }}
            value={
              customerInfo.visits_in_showroom
                ? customerInfo.visits_in_showroom
                : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Last Visited</Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disableFuture
              // label="Last Visited"
              value={
                customerInfo.last_visit_in_showrrom
                  ? customerInfo.last_visit_in_showrrom
                  : null
              }
              onChange={(newValue) => {
                onEditCustomer({
                  last_visit_in_showrrom: formatDate(newValue),
                });
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Label>Website Visits</Label>
          <TextField
            type="number"
            disabled
            // label="Website Visits"
            fullWidth
            onChange={(e) => {
              onEditCustomer({ visits_on_website: e.target.value });
            }}
            value={
              customerInfo.visits_on_website
                ? customerInfo.visits_on_website
                : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Last Visited</Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disableFuture
              disabled
              // label="Last Visited"
              // value={
              //   metaDataPicker("last_visit_on_website")
              //     ? metaDataPicker("last_visit_on_website").value === "" && null
              //     : null
              // }
              // onChange={inputChangeHandler}
              value={
                customerInfo.last_visits_in_website
                  ? customerInfo.last_visits_in_website
                  : null
              }
              onChange={(newValue) => {
                onEditCustomer({
                  last_visits_in_website: formatDate(newValue),
                });
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Label>Branch</Label>
          <FormControl fullWidth>
            <Select
              value={customerInfo.branch_from || ""}
              onChange={(e) => {
                onEditCustomer({ branch_from: e.target.value });
              }}
              fullWidth
            >
              {allBranches.map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
              {/* <MenuItem value="male">Male</MenuItem>
              <MenuItem value="other">Prefer not to say</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

const SocialInfo = ({ customerInfo, isPhone, onEditCustomer }) => {
  return (
    <>
      <SectionLabel>Social Info</SectionLabel>
      <Grid container spacing={3}>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Facebook</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Facebook"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Facebook />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_facebook
                ? customerInfo.customer_facebook
                : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_facebook: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Instagram</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Instagram"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Instagram />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_instagram
                ? customerInfo.customer_instagram
                : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_instagram: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Twitter</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Twitter"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Twitter />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_twitter ? customerInfo.customer_twitter : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_twitter: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Linkedin</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Linkedin"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedIn />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_linkedin
                ? customerInfo.customer_linkedin
                : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_linkedin: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Youtube</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Youtube"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <YouTube />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_youtube ? customerInfo.customer_youtube : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_youtube: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={isPhone ? 12 : 6}>
          <Label>Pinterest</Label>
          <TextField
            id="input-with-icon-textfield"
            // label="Pintesrest"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Pinterest />
                </InputAdornment>
              ),
            }}
            value={
              customerInfo.customer_pinterest
                ? customerInfo.customer_pinterest
                : ""
            }
            onChange={(e) => {
              onEditCustomer({ customer_pinterest: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Grouping = () => {
  // style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  //
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  // auto complete
  const allGroupsRedux = useSelector((state) => state.customers.crmGrouping);
  // useEffect(() => {
  //   if (!isArrayWithValues(allGroupsRedux)) dispatch(getGroupingData());
  // }, []);

  const fixedOptions = ["text", "abc"];
  const [value, setValue] = React.useState([...fixedOptions]);
  return (
    <>
      <Typography variant="h6" sx={{ margin: "10px 0" }} mt={10}>
        Grouping
      </Typography>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={fixedOptions}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label="Fixed tag" placeholder="Favorites" />
        )}
      />
      <Button onClick={handleOpen}>Add Group</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Group Title
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: "8px" }}>
            <Grid item xs={12}>
              <TextField label="Gorup Name" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Description" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              ></LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex" }}
              justifyContent="flex-end"
            >
              <Button onClose={handleClose}>Cancel</Button>
              <Button>Add Group</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const Analytics = ({ customerInfo }) => {
  const [info, setInfo] = useState({
    sessions: "",
    timeSpent: "",
    website: "",
    instore: "",
    productsViewed: 0,
  });

  useEffect(() => {
    if (isArrayWithValues(customerInfo.customer_analytics)) {
      let website = 0,
        instore = 0,
        time = 0,
        viewed = 0;
      for (let obj of customerInfo.customer_analytics) {
        let { channel, logged_in, logged_out, data } = obj;
        if (channel === "instore") instore++;
        if (channel === "website") website++;
        if (validateNumber(logged_in) && validateNumber(logged_out)) {
          let timespent = Number(logged_out) - Number(logged_in);
          if (timespent > 0) time += timespent;
        }
        if (isObjWithValues(data)) {
          let { products } = data;
          if (isArrayWithValues(products)) viewed += products.length;
        }
      }
      let currentTime = Math.floor(new Date().getTime() / 1000) - time;
      let timeObj = getRelativeTimeDifference(
        new Date(currentTime * 1000),
        new Date()
      );
      let timeStr = "";
      if (timeObj) timeStr = `${timeObj.value} ${timeObj.label}`;
      setInfo({
        sessions: customerInfo.customer_analytics.length,
        timeSpent: timeStr,
        website,
        instore,
        productsViewed: viewed,
      });
    }
  }, [customerInfo]);

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ backgroundColor: "#fff", padding: 4, height: "100%" }}>
      {isArrayWithValues(customerInfo.customer_analytics) ? (
        <>
          {" "}
          <Grid container sx={{}}>
            <Grid item xs={isPhone ? 12 : 6}>
              <ReactApexChart
                options={pieOptions}
                series={[info.website, info.instore]}
                type="pie"
                width={"90%"}
              />
              {/* <Typography variant="h6" textAlign="center">
                Channel Analytics
              </Typography> */}
            </Grid>
            <Grid item xs={isPhone ? 12 : 6} alignSelf="center">
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <AnalyticsCard
                    backgroundColor={"#f4f6f8"}
                    title="Total Sessions"
                    value={info.sessions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AnalyticsCard
                    backgroundColor={"#f4f6f8"}
                    title="Time Spent"
                    value={info.timeSpent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AnalyticsCard
                    backgroundColor={"#f4f6f8"}
                    title="Products Viewed"
                    value={info.productsViewed}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AnalyticsCard
                    backgroundColor={"#f4f6f8"}
                    title="Top Product"
                    value={"N/A"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableAnalytics
            rows={customerInfo.customer_analytics}
            isPhone={isPhone}
          />
        </>
      ) : (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{ marginTop: 10 }}>
            <AnalyticsSvg />
          </Box>
          <Typography
            sx={{ color: "#808080", textAlign: "center", marginTop: "10px" }}
          >
            Data for this user will start capturing soon...
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

const pieOptions = {
  chart: {
    // width: 380,
    type: "pie",
  },
  labels: ["Website", "Instore"],
  // fill: {
  // },
  colors: ["rgb(84 ,133, 171)", "rgb(128, 156, 215)"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
const TableAnalytics = ({ rows, isPhone }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          {isPhone ? (
            <TableRow>
              <TableCell>Sessions</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell align="center">SR.NO</TableCell>
              <TableCell align="center">Session From</TableCell>
              <TableCell align="center">Time Spent</TableCell>
              <TableCell align="center">Products</TableCell>
              <TableCell align="center">Filters</TableCell>
              <TableCell>Searches</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            let timeSpent = "";
            let { logged_in, logged_out } = row;
            if (validateNumber(logged_in) && validateNumber(logged_out)) {
              let seconds = Number(logged_out) - Number(logged_in);
              let currentTime =
                Math.floor(new Date().getTime() / 1000) - seconds;
              let timeObj = getRelativeTimeDifference(
                new Date(currentTime * 1000),
                new Date()
              );
              if (timeObj) timeSpent = `${timeObj.value} ${timeObj.label}`;
            }
            console.log(row);
            return (
              <React.Fragment key={row.session}>
                {isPhone ? (
                  <TableRow>
                    <Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "600" }}>
                          {formatServerValue(row.channel)}
                        </Typography>
                        <Typography variant="subtitle2">
                          {"time spend"}{" "}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography>
                          Products:{" "}
                          {row.data
                            ? row.data.products
                              ? row.data.products.length
                              : 0
                            : 0}{" "}
                        </Typography>{" "}
                        <Typography>
                          Filters:{" "}
                          {row.data
                            ? row.data.filters_used
                              ? row.data.filters_used.length
                              : 0
                            : 0}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          Found :{" "}
                          {row.data
                            ? row.data.search_terms
                              ? row.data.search_terms.found.length
                              : 0
                            : 0}
                        </Typography>
                        <Typography>
                          Not Found :{" "}
                          {row.data
                            ? row.data.search_terms
                              ? row.data.search_terms.not_found.length
                              : 0
                            : 0}{" "}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {++index}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "600" }}>
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent="center"
                        spacing={2}
                      >
                        <Language style={{ weight: "20px", height: "20px" }} />
                        {formatServerValue(row.channel)}
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      {timeSpent}
                    </TableCell>
                    <TableCell align="center">
                      {row.data
                        ? row.data.products
                          ? row.data.products.length
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {row.data
                        ? row.data.filters_used
                          ? row.data.filters_used.length
                          : 0
                        : 0}
                    </TableCell>
                    <TableCell>
                      <Stack spacing={2}>
                        <Typography sx={{ fontSize: "12px" }}>
                          Found :{" "}
                          {row.data
                            ? row.data.search_terms
                              ? row.data.search_terms.found.length
                              : 0
                            : 0}
                        </Typography>{" "}
                        <Typography sx={{ fontSize: "12px" }}>
                          Not Found :{" "}
                          {row.data
                            ? row.data.search_terms
                              ? row.data.search_terms.not_found.length
                              : 0
                            : 0}{" "}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
// Note section
const Notes = ({ customerInfo, crmAccess, isAdmin }) => {
  const [formView, setFormView] = useState(false);
  const [deleteView, setDeleteView] = useState(false);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  if (!crmAccess?.includes("notes") && !isAdmin)
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Box sx={{ marginTop: 10 }}>
          <NotesSvg height={300} width={300} />
        </Box>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ margin: 1, color: "#808080" }}
        >
          You don't have Notes Access
        </Typography>
      </Stack>
    );
  return (
    <Box sx={{ backgroundColor: "#fff", padding: 4 }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
            {customerInfo?.first_name + " " + customerInfo?.last_name}'s Notes
          </Typography>
          <InfoTooltip
            title={"Add a note or two about this contact for your reference"}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          onClick={() => {
            setFormView(true);
          }}
        >
          Add
        </Button>
      </Stack>
      {isArrayWithValues(customerInfo.notes) ? (
        <Box sx={{ marginTop: 4 }}>
          {customerInfo.notes
            ?.sort((a, b) => validateNumber(b?.date) - validateNumber(a?.date))
            .map((note) => (
              <NotesCard
                note={note}
                setDeleteView={setDeleteView}
                setFormView={setFormView}
              />
            ))}
        </Box>
      ) : (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box sx={{ marginTop: 8 }}>
            <NotesSvg />
          </Box>
          <Typography
            variant="body2"
            margin={3}
            sx={{ color: "#808080" }}
            textAlign="center"
          >
            No Notes added yet.
          </Typography>
        </Stack>
      )}

      {formView && (
        <AddNotes
          open={formView}
          setFormView={setFormView}
          customerInfo={customerInfo}
        />
      )}
      <DeleteDailog
        deleteView={deleteView}
        customerInfo={customerInfo}
        setDeleteView={setDeleteView}
      />
    </Box>
  );
};
const DeleteDailog = ({ deleteView, setDeleteView, customerInfo }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const deleteNote = async () => {
    setLoading(true);
    const res = await createCustomer(
      {
        additional_custom_data: {
          notes: {
            request: "delete",
            // type === 'delete' ? 'delete' : isUpdate ? 'update' : 'add',
            data: {
              deleteView,
            },
          },
        },
      },
      customerInfo.id
    );

    dispatch(updateCustomerInRedux(res.data));
    setLoading(false);
    setDeleteView(false);
  };
  return (
    <Dialog
      open={Boolean(deleteView)}
      keepMounted
      onClose={() => setDeleteView(false)}
      maxWidth="sm"
      fullWidth
      sx={dialogStyle}
    >
      <DialogTitle>
        <Typography variant="h4">Delete following note?</Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setDeleteView(false)}>Close</Button>
        <LoadingButton
          loading={loading}
          color="error"
          variant="contained"
          onClick={deleteNote}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const NotesCard = ({ note, setDeleteView, setFormView }) => {
  const teamMembers = useSelector((state) => state.settings.teamMembers) || [];
  const getByString = () => {
    let str = "";
    let created_by = note?.extra_data?.created_by;
    let updated_by = note?.extra_data?.updated_by;
    if (created_by) {
      if (created_by?.isAdmin) str = `Created by Admin`;
      let team_member = teamMembers?.find((obj) => obj?.id === created_by?.id);
      if (team_member)
        str = `Created by ${team_member?.first_name || ""} ${
          team_member?.last_name || ""
        }`;
    }
    if (updated_by) {
      if (updated_by?.isAdmin) str = `Updated by Admin`;
      let team_member = teamMembers?.find((obj) => obj?.id === updated_by?.id);
      if (team_member)
        str = `Updated by ${team_member?.first_name || ""} ${
          team_member?.last_name || ""
        }`;
    }
    return str;
  };
  return (
    <Card
      sx={{
        backgroundColor: "#fafafa",
        margin: "10px 0px",
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "5px" },
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight="bold">
            {note.title}
          </Typography>
          <secondaryLabel>
            {note?.date
              ? formatDate(
                  new Date(validateNumber(note?.date) * 1000),
                  // "relative_date"
                  "dd:mm:yyyy hh:mm am/pm"
                )
              : null}
            {note?.extra_data?.module
              ? ` Via ${
                  note?.extra_data?.module === "crm"
                    ? "CRM"
                    : formatServerValue(note?.extra_data?.module)
                }`
              : null}
          </secondaryLabel>
        </Stack>

        <div style={{ display: "flex", marginTop: "6px" }}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Note:
          </Typography>
          <Typography variant="body2" fontWeight="bold" ml={1}>
            {note.note}
          </Typography>
        </div>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography>{getByString()}</Typography>
        </Stack>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            onClick={() => setFormView(note)}
            startIcon={<EditOutlined />}
            // disabled={!crmAccess.includes("edit")}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="error"
            startIcon={<DeleteOutlineOutlined />}
            onClick={() => setDeleteView(note)}
            // disabled={!crmAccess.includes("edit")}
          >
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
const AddNotes = ({ open, setFormView, customerInfo }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const createNote = async () => {
    setLoading(true);
    const res = await createCustomer(
      {
        additional_custom_data: {
          notes: {
            request: isObjWithValues(open) ? "update" : "add",
            // type === 'delete' ? 'delete' : isUpdate ? 'update' : 'add',
            data: {
              ...inputData,
              extra_data: {
                module: "crm",
                [`${isObjWithValues(open) ? "updated" : "created"}_by`]: {
                  id: getId(),
                  isAdmin: isAdmin,
                },
              },
            },
          },
        },
      },
      customerInfo.id
    );

    dispatch(updateCustomerInRedux(res.data));
    setLoading(false);
    setFormView(false);
  };
  const [inputData, setInputData] = useState({
    title: "",
    note: "",
  });
  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
  };
  useEffect(() => {
    if (isObjWithValues(open)) setInputData(open);
  }, [open]);

  return (
    <Dialog
      open={Boolean(open)}
      onClose={() => {
        setFormView(false);
      }}
      sx={dialogStyle}
    >
      <DialogTitle>
        {isObjWithValues(open) ? "Update" : "Add"} Notes
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Title"
              variant="standard"
              fullWidth
              onChange={inputChangeHandler}
              value={inputData.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="note"
              label="Description"
              type="textarea"
              variant="standard"
              fullWidth
              onChange={inputChangeHandler}
              value={inputData.note}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFormView(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={createNote}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const Sessions = ({ customerInfo }) => {
  const sessionsFilters = [
    { label: "All Sessions", value: "all_sessions" },
    { label: "Website", value: "website" },
    { label: "Mobile App", value: "mobile_app" },
    { label: "ISO app", value: "iso_app" },
  ];

  const [sessions, setSessions] = useState([]);
  const [terminateOpen, setTerminateOpen] = useState(false);
  const [terminating, setTerminating] = useState(false);
  const [filterValue, setFilterValue] = useState("all_sessions");

  const dispatch = useDispatch();
  useEffect(() => {
    let { sessions } = customerInfo;
    if (isObjWithValues(sessions)) {
      let array = [];
      for (let key of Object.keys(sessions)) {
        array.push({
          id: key,
          ...sessions[key],
          os: new UAParser(sessions[key]?.ua).getOS(),
        });
      }
      setSessions(array);
    }
  }, [customerInfo]);
  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
    // let { sessions } = customerInfo;
    // let array = [];
    // for (let key of Object.keys(sessions)) {
    //   // if(){

    //     array.push({
    //       id: key,
    //       ...sessions[key],
    //       os: new UAParser(sessions[key]?.ua).getOS()
    //     });
    //   // }
    // }
    // setSessions(array);
  };
  const onTerminate = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        setTerminating(true);
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/customers/${customerInfo.id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "POST",
          data: {
            additional_custom_data: {
              session_terminate: terminateOpen,
            },
          },
        });
        console.log(data);
        dispatch(updateCustomerInRedux(data));
        closeTerminate();
        dispatch(
          setGlobalToast({
            show: true,
            message: "Session terminated successfully!",
            severity: "success",
          })
        );
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setTerminating(false);
    }
  };
  console.log(sessions);
  const closeTerminate = () => setTerminateOpen(false);

  return (
    <Box sx={{ backgroundColor: "#fff", padding: 4 }}>
      <Stack style={{ margin: 7 }}>
        {/* <Typography variant="caption text">
          View and manage all active sessions.
        </Typography> */}
      </Stack>
      {/* <Divider /> */}

      {isObjWithValues(customerInfo.sessions) ? (
        <>
          <Box sx={{ mt: 3 }}>
            <Stack
              direction={"row"}
              alignItems="center"
              // justifyContent={"space-between"}
              spacing={1}
            >
              <Typography variant="h5">Recent Visit Sessions</Typography>
              {/* <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                gap="10px"
              >
                <Typography variant="button">Filter by : </Typography>
                <Select
                  size="small"
                  value={filterValue}
                  onChange={onFilterChange}
                >
                  {sessionsFilters.map((obj) => (
                    <MenuItem value={obj.value}>{obj.label}</MenuItem>
                  ))}
                </Select>
              </Stack> */}
              <InfoTooltip title="Session wise activity, device used, visit time, products view, Add to Cart etc." />
            </Stack>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    {true ? (
                      <>
                        {" "}
                        <TableCell>Channel</TableCell>
                        {/* <TableCell>Location</TableCell> */}
                        <TableCell>Time spent</TableCell>
                        <TableCell>Date</TableCell>
                        {/* <TableCell align="center">
                          <RemoveRedEyeOutlined />
                        </TableCell>
                        <TableCell align="center">
                          <ShoppingCartOutlined />
                        </TableCell>
                        <TableCell align="center">
                          <AddShoppingCartOutlined />
                        </TableCell>
                        <TableCell align="left" padding="none">
                          Device
                        </TableCell> */}
                        {/* <TableCell>Location</TableCell> */}
                        {/* <TableCell>IP</TableCell> */}
                        <TableCell align="right">Action</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Sr.</TableCell>

                        <TableCell align="left" padding="none">
                          Device
                        </TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>Action</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.map((session, index) => (
                    <SessionsList
                      session={session}
                      index={index}
                      key={session.ua}
                      setTerminateOpen={setTerminateOpen}
                    />
                  ))}
                  {/* {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              
            })} */}
                </TableBody>
                {/* <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      // colSpan={3}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter> */}
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Stack
          direction="column"
          sx={{
            marginTop: "6vh",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{}}>
            <SessionsSvg />
          </Box>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ margin: 4, color: "#808080" }}
          >
            No sessions yet
          </Typography>
        </Stack>
      )}
      <Dialog
        open={Boolean(terminateOpen)}
        onClose={closeTerminate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Terminate Following Session?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This user will be automatically logged-out from the device.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTerminate}>Cancel</Button>
          <LoadingButton
            color="error"
            loading={terminating}
            onClick={onTerminate}
            autoFocus
          >
            Terminate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const SessionsList = ({ session, index, setTerminateOpen }) => {
  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: theme.palette.common.black,
  //     color: theme.palette.common.white,
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  //   },
  // }));

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     // backgroundColor: theme.palette.action.hover,
  //   },
  //   // hide last border
  //   '&:last-child td, &:last-child th': {
  //     border: 0,
  //   },
  // }));
  let { ua, ip, id, os } = session;
  // console.log(session);
  // let os = new UAParser(ua).getOS();
  let browser = new UAParser(ua).getBrowser();
  let timeSpent =
    getRelativeTimeDifference(
      new Date(session?.login * 1000),
      new Date(session?.expiration * 1000)
    ) || {};
  if (true)
    return (
      <StyledTableRow>
        <TableCell>
          <Stack>
            <Typography>Website</Typography>
            <Stack direction="row" alignItems={"center"} spacing={2}>
              {os && os.name === "Mac OS" ? (
                <Apple style={{ color: "#666666" }} />
              ) : os.name === "Windows" ? (
                <Windows style={{ color: "#666666" }} />
              ) : null}
              {os && (os.name === "Mac OS" || os.name === "Windows") ? (
                <Laptop width="20px" height="20px" />
              ) : (
                <PhoneIphone />
              )}
            </Stack>
          </Stack>
        </TableCell>
        {/* <TableCell>
          <Typography
            // variant="h6"
            fontSize={"11px"}
            sx={{ mt: 0.5 }}
          >
            Mumbai
           
          </Typography>
        </TableCell> */}
        <TableCell>
          {isObjWithValues(timeSpent)
            ? `${timeSpent?.value || ""} ${timeSpent.label || ""}`
            : ""}
        </TableCell>
        <TableCell>
          <Stack>
            <Typography>
              {session?.login
                ? formatDate(new Date(session?.login * 1000), "dd/mm/yyyy")
                : ""}
            </Typography>
            <Typography>
              {session?.login
                ? formatDate(new Date(session?.login * 1000), "hh:mm am/pm")
                : ""}
            </Typography>
          </Stack>
        </TableCell>
        {/* <TableCell align="center"> -</TableCell>
        <TableCell align="center"> - </TableCell>
        <TableCell align="center"> - </TableCell>
        <TableCell align="center"> - </TableCell> */}
        <TableCell align="center">
          <Tooltip title="Visitor has not closed the page or app.  You can Terminate it">
            <div>
              <Button color="error" onClick={() => setTerminateOpen(id)}>
                Terminate
              </Button>{" "}
            </div>
          </Tooltip>
        </TableCell>
      </StyledTableRow>
    );

  return (
    <StyledTableRow>
      <TableCell padding="checkbox" align="left">
        <Typography sx={{ ml: 3, fontWeight: "600" }}>{index + 1}</Typography>
      </TableCell>

      {/* <TableCell component="th" scope="row">
        <Typography variant="h6" sx={{ fontSize: "13px" }}>
          {row.start
            ? formatDate(new Date(row.start * 1000), "dd:mm:yyyy hh:mm am/pm")
            : ""}
        </Typography>
      </TableCell> */}
      <TableCell padding="none" sx={{ fontWeight: "600" }}>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          {os && os.name === "Mac OS" ? (
            <Apple style={{ color: "#666666" }} />
          ) : os.name === "Windows" ? (
            <Windows style={{ color: "#666666" }} />
          ) : null}
          {os && (os.name === "Mac OS" || os.name === "Windows") ? (
            <Laptop width="20px" height="20px" />
          ) : (
            <PhoneIphone />
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Typography
          // variant="h6"
          fontSize={"11px"}
          sx={{ mt: 0.5 }}
        >
          Mumbai Maharashtra India
          {/* {isObjWithValues(row.location)
            ? `${row.location.city || ""} ${row.location.state} ${
                row.location.country
              }`
            : ""} */}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          // variant="h6"
          fontSize={"11px"}
          sx={{ mt: 0.5 }}
        >
          {ip}
        </Typography>
      </TableCell>
      <TableCell>
        <Button color="error" onClick={() => setTerminateOpen(id)}>
          Terminate
        </Button>
      </TableCell>
      {/* <TableCell  align="right">
                  {row.fat}
                </TableCell> */}
    </StyledTableRow>
  );
  // const theme = useTheme();
  // const isIpad = useMediaQuery(theme.breakpoints.down("sm"));

  // const parser = new UAParser(session.ua);
  // // console.log(parser);
  // const date = new Date(session.login * 1000);
  // const timeObject = getRelativeTimeDifference(date);
  // const device = parser.getOS();
  // const browser = parser.getBrowser();
  // console.log(browser);
  // // console.log(device);
  // return (
  //   <React.Fragment>
  //     {false ? (
  //       <Stack sx={{ mt: 2 }}>
  //         <Stack
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //           }}
  //         >
  //           <Typography variant="h4">{device.name}</Typography>
  //         </Stack>
  //         <Typography variant="caption">
  //           {`${timeObject.value} ${timeObject.label}`} Ago
  //         </Typography>
  //         <SessionsLocation ip={session.ip} />
  //       </Stack>
  //     ) : (
  //       <Stack>
  //         <Grid container sx={{ padding: 5 }}>
  //           <Grid item xs={2} justifyContent="center" alignItems="center">
  //             image
  //           </Grid>
  //           <Grid item xs={2} justifyContent="center" alignItems="center">
  //             <Typography variant="subtitle2">{device.name}</Typography>
  //             <Typography variant="caption">
  //               {`${timeObject.value} ${timeObject.label}`} Ago
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={2} justifyContent="center" alignItems="center">
  //             comp brow
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             justifyContent="center"
  //             alignItems="center"
  //             sx={{ flexWrap: "wrap" }}
  //           >
  //             <SessionsLocation ip={session.ip} />
  //           </Grid>
  //           <Grid item xs={3}>
  //             <Typography>Terminate</Typography>
  //           </Grid>
  //         </Grid>
  //         <Divider />
  //       </Stack>
  //     )}
  //   </React.Fragment>
  // );
};
const SessionsLocation = ({ ip }) => {
  const [ipInfo, setIpInfo] = useState("");
  const fetchIPInfo = (ip) => {
    let key = `64655540-8e4e-11ec-b0c0-85ea73579d48`;
    axios({
      method: "GET",
      url: `http://www.geoplugin.net/json.gp?ip=${ip}`,
    })
      .then((res) => {
        // console.log(res.data);
        let { data } = res;
        if (Object.keys(data).length > 0) {
          let { geoplugin_city, geoplugin_region, geoplugin_countryName } =
            res.data;
          setIpInfo(
            `${geoplugin_city ? geoplugin_city + ", " : ""}${
              geoplugin_region ? geoplugin_region + ", " : ""
            }${geoplugin_countryName || ""}`
          );
        }
      })
      .catch((e) => console.log(getAxiosError(e)));
  };
  useEffect(() => {
    fetchIPInfo(ip);
  }, [ip]);
  return (
    <Typography variant="OVERLINE TEXT" sx={{ flexWrap: "wrap" }}>
      {ipInfo}
    </Typography>
  );
};

export const incomeArray = [
  "0 - 2.5 Lakh",
  "2.5 - 5 Lakh",
  "5 - 10 Lakh",
  "10 - 15 Lakh",
  "15 - 20 Lakh",
  "20 - 25 Lakh",
  "25 - 30 Lakh",
  "30 - 50 Lakh",
  "50 - 75 Lakh",
  "75 Lakh - 1 Crore",
  "1 - 1.5 Crore",
  "1.5 - 2 Crore",
  "2 Crore & above",
];
export const religionArray = [
  "Hindu",
  "Jain",
  "Islam",
  "Sikh",
  "Christian",
  "Buddhist",
  "Judaism",
  "Zorastrian",
  "Others",
];
export const ethincityArray = [
  "Zomi",
  "Garhwalis",
  "Tripuri",
  "Lhona",
  "Tharus",
  "Tamils",
  "Sindhis",
  "Arabs",
  "Rajasthanis",
  "Punjabis",
  "Gujjar",
  "Gurjar",
  "Siddi",
  "Odia",
  "Gurung",
  "Tamangs",
  "Khas",
  "Magars",
  "Maithils",
  "Bishnupriya Manipuris",
  "Meitei",
  "Marathi",
  "Magahi",
  "Konkani",
  "Malyali",
  "Newars",
  "Kashmiris",
  "Tuluvas",
  "Kannadigas",
  "Karbi",
  "Khonds",
  "Dogra",
  "Parsis",
  "Bhojpuris",
  "Bhumij",
  "Santal",
  "Naga",
  "Bhils",
  "Mundas",
  "Rajbhongsi",
  "Tiwa",
  "Telugu",
  "Gujarati",
  "Dubia",
  "Gonds",
  "Garos",
  "Deccani",
  "Nagpuri",
  "Kurukh",
  "Bengalis",
  "Awadhis",
  "Chutiya",
  "Ahom",
  "Nyishi",
];
export const languageArray = [
  "Hindi",
  "Bengali",
  "Rajasthani",
  "Gujarati",
  "Urdu",
  "Marathi",
  "Telugu",
  "Tamil",
  "Kannada",
  "Malayalam",
  "Odia",
  "Punjabi",
  "Assamese",
  "Maithili",
  "Kashmiri",
  "Konkani",
  "Nepali",
  "Sindhi",
  "Bhojpuri",
  "Chhatisgarhi",
  "Haryanvi",
  "Marwari",
  "Magadhi",
  "Awadhi",
];
