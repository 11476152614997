export const botStyles = {
  mainContainer: {
    display: "flex",
    marginRight: "20px",
    marginTop: "-18px",
    flexDirection: "row-reverse",
    pointerEvents: "all",
    position: "relative",
    zIndex: 1,
  },
  customCard: {
    minWidth: "480px",
    maxWidth: "480px",
    margin: "0 auto",
    borderRadius: "5px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    marginLeft: "25px",
  },
  customCardDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "30px",
  },
  customCardHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontFamily: "Inter, Roboto, sans-serif",
    fontWeight: 600,
    color: "rgb(89, 104, 123)",
    justifyContent: "center",
  },
  customCardTitleField: {
    marginTop: "15px",
    height: "20px",
    borderRadius: "8px",
    fontSize: "50px",
    // marginBottom: "20px"
  },
  customCardBotIcon: {
    fontSize: "18px",
    marginRight: "10px",
  },
  emptyFieldError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customCardHeaderIconsDiv: {
    display: "flex",
    alignContents: "flex-end",
    left: "20px",
    position: "relative",
    bottom: "4px",
  },
  customCardHeaderIcons: {
    backgroundColor: "#dcdcdc",
    marginRight: "10px",
    borderRadius: "5px",
    height: "30px",
    width: "30px",
  },
  topCard: {
    display: "flex",
    flexDirection: "column",
    minHeight: "140px",
    maxHeight: "180px",
    transition: "0.3s ease",
    backgroundColor: "#ffffff",
    paddingLeft: "30px",
    paddingRight: "30px",
    fontFamily: "Inter, Roboto, sans-serif",
  },

  keywordTextField: {
    width: "150px",
    fontSize: "15px",
    color: "black",
    marginRight: "10px",
    borderRadius: "35px",
  },
  innerCard: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: "100%",
    maxHeight: "450px",
    minHeight: "300px",
    paddingTop: "25px",
    paddingBottom: "40px",
    backgroundColor: "#dcdcdc",

    fontFamily: "Inter, Roboto, sans-serif",
  },
  textFieldComponent: {
    backgroundColor: "white",
    borderRadius: "10px",
  },
  imageCard: {
    height: "150px",
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    margin: "0",
    marginLeft: "20px",
  },
  imageComponent: {
    transition: "transform 0.2s",
    borderRadius: "10px",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  buttonTextField: {
    backgroundColor: "white",
    borderRadius: "10px",
    fontFamily: "Inter, Roboto, sans-serif",
    color: "#050505",
    fontSize: "10px",
  },
  galleryImgCard: {
    // width: "240px",
    minHeight: "150px",
    backgroundColor: "#e3e3e3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  galleryBottom: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    padding: "0px",
  },
  galleryComponentTitle: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginBottom: "30px",
  },
};
