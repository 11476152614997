import * as actions from "../../../../../../redux/actions/flowsActions";

export const onAddUser = async (dispatch, axis, id, btnsObj) => {
  const newNodeId = Date.now().toString();

  const newNode = {
    id: newNodeId,
    type: "user",
    data: { label: "User" },
    position: axis,
    fieldData: [],
    isUser: true,
  };

  if (btnsObj) {
    const buttonData = btnsObj?.buttonData;
    newNode.fieldData = {
      title: `${buttonData?.title} Input`,
      userResponse: [buttonData?.title],
      keywords: [`${buttonData?.title}s`],
    };
  }

  const edge = {
    id: Date.now(),
    source: id,
    target: newNodeId,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
    type: "smoothstep",
  };

  await dispatch({
    type: actions.CURRENT_NODE_ID,
    payload: { nodeId: newNodeId },
  });
  dispatch({ type: actions.ADD_NODE, payload: { newNode, nodeId: id } });
  dispatch({ type: actions.ADD_EDGE, payload: edge });
  if (btnsObj?.flag) {
    return;
  }
  dispatch({ type: actions.OPEN_SIDEBAR_USER });
};
