import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { isObject } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allEmptyCurrency,
  deepObjectComparison,
  dialogStyle,
  getRandomInt,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { currencySymbols } from "./Gold";
import { setHelperData } from "../../redux/actions/helperActions";

const colorTypes = [
  "default",
  "primary",
  "secondary",
  "success",
  "warning",
  "info",
];

const defaultAddForm = {
  collections: [],
  categories: [],
  subcategories: [],
  labour_from: "gross",
  wastage: "",
  wastage_from: "weight",
  per_gram: "",
  making_from: "labour",
  minimum_making: "",
  title: "",
};

const Labour = forwardRef((props, ref) => {
  let { isDefaultCurrency, defaultCurrency, selectedCurrency } = props;
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [addForm, setAddForm] = useState(defaultAddForm);
  const [labourPricing, setLabourPricing] = useState(allEmptyCurrency);
  const [allCurrency, setAllCurrency] = useState([]);
  const [editId, setEditId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);

  const closeDelete = () => setShowDelete(false);
  const openDelete = (id) => {
    setShowDelete(id);
  };

  useImperativeHandle(ref, () => ({
    getLabourPricing() {
      return labourPricing;
    },
  }));

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.labour_pricing)
    )
      setLabourPricing(masterPricing.labour_pricing);
  }, [masterPricing]);

  const editAddForm = (payload) =>
    setAddForm((state) => ({ ...state, ...payload }));
  const closeAddForm = () => {
    setEditId(null);
    setAddForm(defaultAddForm);
    setAddFormOpen(false);
  };
  const openAddForm = () => {
    setAddForm(defaultAddForm);
    setAddFormOpen(true);
  };

  useEffect(() => {
    if (isObjWithValues(taxonomies)) {
      let { categories, collections } = taxonomies;
      if (isArrayWithValues(collections)) setAllCollections(collections);
      if (isArrayWithValues(categories)) setAllCategories(categories);
      if (isArrayWithValues(taxonomies["sub-categories"]))
        setAllSubCategories(taxonomies["sub-categories"]);
    }
  }, [taxonomies]);
  useEffect(() => {
    if (props.productSettings) {
      // let { productSettings } = props;
      // if (isArrayWithValues(productSettings.collections))
      //   setAllCollections(productSettings.collections);
      // if (isArrayWithValues(productSettings.categories))
      //   setAllCategories(productSettings.categories);
      if (isArrayWithValues(props.productSettings.currency))
        setAllCurrency(props.productSettings.currency);
    }
  }, [props.productSettings]);

  const onAddCombination = () => {
    let obj = {};
    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...labourPricing[allCurrency[i].value]];
      array.push({
        id: Math.floor(Date.now() / 1000),
        ...addForm,
        minimum_making:
          defaultCurrency === allCurrency[i].value
            ? addForm.minimum_making
            : "",
        per_gram:
          defaultCurrency === allCurrency[i].value ? addForm.per_gram : "",
      });
      obj[allCurrency[i].value] = array;
    }
    let { onMasterUpdate } = props;
    dispatch(
      setHelperData({
        changeInCombination: true,
      })
    );
    onMasterUpdate({
      labour_pricing: obj,
      setLoading,
      callback: () => {
        setAddFormOpen(false);
        setAddForm(defaultAddForm);
      },
      skipMasterUpdateHook: true,
    });
  };

  const onUpdateCombination = () => {
    let updatedLabourPricing = {};
    let previousObj = labourPricing[selectedCurrency]?.find(
      (o) => o?.id == editId
    );
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedLabourPricing[allCurrency[i].value] = labourPricing[
            `${allCurrency[i].value}`
          ].map((obj) => (obj.id === editId ? addForm : obj));
        else
          updatedLabourPricing[allCurrency[i].value] = labourPricing[
            `${allCurrency[i].value}`
          ].map((obj) =>
            obj.id === editId
              ? {
                  ...addForm,
                  per_gram: obj.per_gram,
                  minimum_making: obj.minimum_making,
                  wastage: obj.wastage,
                }
              : obj
          );
      }
    } else
      updatedLabourPricing = {
        ...labourPricing,
        [selectedCurrency]: labourPricing[selectedCurrency].map((i) =>
          i.id === editId
            ? {
                ...i,
                per_gram: addForm.per_gram,
                minimum_making: addForm.minimum_making,
                wastage: addForm.wastage,
              }
            : i
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedLabourPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedLabourPricing)[i]
        )
      )
        filteredObj[Object.keys(updatedLabourPricing)[i]] =
          updatedLabourPricing[Object.keys(updatedLabourPricing)[i]];
    // let updatedLabourPricing = [...labourPricing];
    // updatedLabourPricing = updatedLabourPricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    let { onMasterUpdate } = props;

    onMasterUpdate({
      labour_pricing: filteredObj,
      setLoading,
      callback: () => {
        setAddFormOpen(false);
        if (!deepObjectComparison(addForm, previousObj))
          dispatch(
            setHelperData({
              changeInCombination: true,
            })
          );
      },
      skipMasterUpdateHook: true,
    });
  };
  const onDeleteCombination = () => {
    let id = showDelete;
    let updatedLabourPricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedLabourPricing[allCurrency[i].value] = labourPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
        else
          updatedLabourPricing[allCurrency[i].value] = labourPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
      }
    } else
      updatedLabourPricing = {
        ...labourPricing,
        [selectedCurrency]: labourPricing[selectedCurrency].filter(
          (i) => i.id === id
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedLabourPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedLabourPricing)[i]
        )
      )
        filteredObj[Object.keys(updatedLabourPricing)[i]] =
          updatedLabourPricing[Object.keys(updatedLabourPricing)[i]];
    // let updatedLabourPricing = [...labourPricing];
    // updatedLabourPricing = updatedLabourPricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    let { onMasterUpdate } = props;
    onMasterUpdate({
      labour_pricing: filteredObj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };

  const onEditPress = (obj) => {
    setEditId(obj.id);
    setAddForm({ ...obj });
    setAddFormOpen(true);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box>
      {/* <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": { paddingBottom: "16px" },
          }}
        >
        </CardContent>
      </Card> */}
      <Stack
        alignItems={"center"}
        justifyContent="flex-end"
        direction="row"
        // p={2}
        // border="0.5px solid #d1d1d1 "
        // borderRadius={"6px"}
      >
        {/* <Typography>How do you want to set price?</Typography> */}

        <Button
          size="small"
          onClick={!isDefaultCurrency ? null : openAddForm}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          disabled={!isDefaultCurrency}
        >
          Add New Combination
        </Button>
      </Stack>
      {isIpad && (
        <Box>
          {isArrayWithValues(labourPricing[selectedCurrency]) &&
            labourPricing[selectedCurrency].map((row) => (
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Box key={row.id}>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h6">{row.title}</Typography>
                      <IconButton size="small" onClick={() => onEditPress(row)}>
                        <Edit />
                      </IconButton>
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      Collections
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.collections) &&
                        row.collections.map((collection, i) => {
                          return (
                            <Typography>
                              {collection.label}
                              {i !== row.collections.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      Categories
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.categories) &&
                        row.categories.map((category, i) => {
                          return (
                            <Typography>
                              {category.label}
                              {i !== row.categories.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2} mb={1}>
                      Info :
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Wastage: </strong>{" "}
                          {currencySymbols[selectedCurrency]}
                          {row.wastage}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Per gram: </strong>
                          {currencySymbols[selectedCurrency]}
                          {row.per_gram}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Min Making: </strong>
                          {currencySymbols[selectedCurrency]}
                          {row.minimum_making}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Box>
      )}
      {!isIpad && (
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,
            borderRadius: "10px",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="left">Collections</TableCell>
                <TableCell align="left">Categories</TableCell>
                <TableCell align="left">Sub Categories</TableCell>
                <TableCell align="left">Info</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArrayWithValues(labourPricing[selectedCurrency]) &&
                labourPricing[selectedCurrency].map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => onEditPress(row)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="left">
                      <Stack>
                        {isArrayWithValues(row.collections) &&
                          row.collections.map((collection) => {
                            return (
                              <Stack
                                my={1}
                                direction="row"
                                alignItems={"center"}
                              >
                                <Typography>{collection.label}</Typography>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {isArrayWithValues(row.categories) &&
                        row.categories.map((category) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{category.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      {isArrayWithValues(row.subcategories) &&
                        row.subcategories.map((subcategory) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{subcategory.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      <Box>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Wastage: {row.wastage}%
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Per gram:{" "}
                            {`${currencySymbols[selectedCurrency]} ${row.per_gram}`}{" "}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Min Making:
                            {`${currencySymbols[selectedCurrency]} ${row.minimum_making}`}{" "}
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          openDelete(row.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Delete Labour combination?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be done
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addFormOpen}
        onClose={closeAddForm}
        maxWidth="md"
        fullWidth
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>{editId ? "Update" : "Add"} Combination</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                variant="standard"
                value={addForm.title}
                onChange={(e) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ title: e.target.value })
                }
                sx={{ mt: 0.4 }}
                size="small"
                label="Title"
                fullWidth
                disabled={!isDefaultCurrency}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.collections}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCollections}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Collections"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ collections: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.categories}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCategories}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ categories: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.subcategories}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allSubCategories}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub Categories"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ subcategories: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} md={6}>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Labour calculation from?
                </Typography>
                <FormControl size="small" disabled={!isDefaultCurrency}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.labour_from}
                    // label="Age"
                    onChange={(e) =>
                      !isDefaultCurrency
                        ? null
                        : editAddForm({ labour_from: e.target.value })
                    }
                  >
                    <MenuItem value={"gross"}>Gross</MenuItem>
                    <MenuItem value={"net"}>Net</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.wastage}
                onChange={(e) =>
                  // !isDefaultCurrency
                  //   ? null
                  //   :
                  editAddForm({ wastage: e.target.value })
                }
                size="small"
                label="Labour Wastage?"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">On Weight</InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                // disabled={!isDefaultCurrency}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.per_gram}
                onChange={(e) => editAddForm({ per_gram: e.target.value })}
                size="small"
                label="Per gram"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">On Labour</InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.minimum_making}
                onChange={(e) =>
                  editAddForm({ minimum_making: e.target.value })
                }
                size="small"
                label="Min Making"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                  // startAdornment: (
                  //   <InputAdornment position="start">₹</InputAdornment>
                  // ),
                }}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddForm}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={editId ? onUpdateCombination : onAddCombination}
          >
            {editId ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default Labour;
