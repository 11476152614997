export const traverseAndDeleteNodes = ({ nodes, edges, id, sourceId }) => {
    console.log(sourceId, id)
  const nodesToDelete = [id];
  const edgesToDelete = [id];
  const edgesToTraverse = [sourceId, id];

  while (edgesToTraverse.length > 0) {
    const currentEdge = edgesToTraverse.shift();
    console.log(currentEdge)
    const edgesToUpdate = edges.filter((edge) => edge.source === currentEdge);
    console.log(edgesToUpdate)
    const edgesToUpdateTarget = edgesToUpdate.map((edge) => edge.target);
    console.log("EDGES TO UPDATE", edgesToUpdateTarget);
    nodesToDelete.push(...edgesToUpdateTarget);
    edgesToDelete.push(...edgesToUpdateTarget);
    edgesToTraverse.push(...edgesToUpdateTarget);
    console.log(edgesToTraverse)
  }
  const updatedNodes = nodes.filter((node) => !nodesToDelete.includes(node.id));
  const updatedEdges = edges.filter(
    (edge) => !edgesToDelete.includes(edge.target)
  );

  return { finalNodes: updatedNodes, finalEdges: updatedEdges };
};
