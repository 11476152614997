/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowBack,
  Close,
  ContentCopy,
  ErrorOutline,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Button,
  Collapse,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmailEditor from "react-email-editor";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfimPopup,
  DynamicPopover,
  EmptyState,
  formatServerValue,
  getAxiosError,
  getRandomString,
  getTemplateVariableObjFromCustomer,
  getToken,
  getWebsite,
  isDev,
  isObjWithValues,
  nodeWebsite,
  primaryLabel,
  secondaryLabel,
  TabPanel,
  textFeildRadius,
} from "../../../helper";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { StackRow } from "../../components/helper";
import { CustomeDialogTitle } from "../../customers/components/EmailTemplate";
import { PredefinedEmailTemplate } from "../../email/EmailList";
import { FormLabel } from "../../settings/Email";

const subjectLimit = 255;
let subjectLimitError = `Maximum character length is 256. Please shorten your input.`;
const predefined = "predefined";

let createTemplateToast = (name) => `Create ${name} template successfully!`;
let updateTemplateToast = (name) => `Updated ${name} template successfully!`;

const EmailBuilder = forwardRef(
  (
    {
      setCreatePage,
      createPage,
      // setLoading,
      // loading,
      setCreateTemplate,
      isEdit,
      setIsEdit,
      goBack,
      setSelectedPage,
      setPreview,
    },
    ref
  ) => {
    const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const emailEditorRef = useRef(null);
    const [page, setPage] = useState(1);
    const [templateForm, setTemplateForm] = useState({});
    const [error, setError] = useState("");
    const [createTemplatePage, setCreateTemplatePage] = useState("");
    const [editTemmplate, setEditTemplate] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [sending, setSending] = useState(false);
    const [emailPayload, setEmailPayload] = useState({});
    const websiteProductSettings = useSelector(
      (state) => state.settings.websiteProductSettings
    );

    let saveTimeOut;
    // const isIpad= useMediaQuery(theme => theme.break)
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const userAdminData = useSelector((state) => state.user?.userAdminData);
    const user_id = useSelector((state) => state.user.id);
    const logo = useSelector((state) => state.user?.store_details?.store_logo);
    ///////////////
    const [anchorElTestEmail, setAnchorElTestEmail] = React.useState(null);

    const handleClickTestEmail = (event) => {
      setAnchorElTestEmail(event.currentTarget);
    };

    const handleCloseTestEmail = () => {
      setAnchorElTestEmail(null);
    };

    const openTestEmail = Boolean(anchorElTestEmail);
    const idTestEmail = openTestEmail ? "simple-popover" : undefined;

    function replaceVariables(htmlString, customer) {
      const variableObj = getTemplateVariableObjFromCustomer(customer);

      if (!variableObj) return htmlString;

      const regex = new RegExp(Object.keys(variableObj).join("|"), "g");
      let newHTML = htmlString.replace(
        regex,
        (matched) => variableObj[matched]
      );

      return newHTML;
    }

    ///////
    // const
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const editForm = (payload) => {
      if (error) setError("");
      if (payload?.subject && payload?.subject?.length > subjectLimit)
        setError(subjectLimitError);
      else setError("");
      setTemplateForm((state) => ({ ...state, ...payload }));
    };

    const onEditPayload = (payload) =>
      setEmailPayload((state) => ({ ...state, ...payload }));
    const exportHtml = async (paramsDesign, paramsHtml) => {
      console.log(templateForm, isEdit);
      if (!templateForm.name) return setError("Please enter name");
      if (!templateForm.category) return setError("Please enter category");
      if (!templateForm.subject) return setError("Please enter subject");
      if (templateForm.subject?.length > subjectLimit)
        return setError(subjectLimitError);
      emailEditorRef.current.editor.exportHtml(
        async (data) => {
          const { design, html } = data;
          console.log("exportHtml", html);

          let putData = {};
          if (isObjWithValues(isEdit)) {
            let id = isEdit?.id;
            delete isEdit.create;
            putData = {
              [id]: {
                ...isEdit,
                // id,
                isAdmin: isEdit?.isAdmin || isAdmin,
                created_by: isEdit?.created_by || user_id,
                name: templateForm?.name,
                category: templateForm?.category,
                subject: templateForm?.subject,
                created: isEdit?.created || Date.now(),
                json_template: paramsDesign || design,
                html_template: paramsHtml || `${html}`,
                date_modified: Date.now(),
              },
            };
          } else {
            let id = `${Date.now()}_${getRandomString(4)}`;
            putData = {
              [id]: {
                id,
                isAdmin,
                created_by: user_id,
                name: templateForm?.name,
                category: templateForm?.category,
                subject: templateForm?.subject,
                created: Date.now(),
                json_template: design,
                html_template: `${html}`,
              },
            };
          }
          let website = getWebsite();
          let token = getToken();

          // return
          if (website && token) {
            setLoading && setLoading(true);
            let res = await axios({
              url: `${website}/wp-json/store/v1/settings/email_marketing_templates`,
              headers: {
                Authorization: `Basic ${token}`,
              },
              method: "PUT",
              data: {
                setting: "email_marketing_templates",
                data: putData,
              },
            });
            if (res?.data?.success) {
              dispatch({
                type: EDIT_CHATS,
                payload: {
                  emailTemplates: res?.data?.data,
                },
              });
              if (!paramsDesign) {
                // dispatch({
                //   type: EDIT_CHATS,
                //   payload: {
                //     emailTemplates: res?.data?.data,
                //   },
                // });
                dispatch(
                  setGlobalToast({
                    show: true,
                    severity: "success",
                    message: isEdit
                      ? updateTemplateToast(templateForm?.name)
                      : createTemplateToast(templateForm?.name),
                  })
                );
                setCreateTemplate && setCreateTemplate(false);
                setCreatePage && setCreatePage(false);
                setSelectedPage && setSelectedPage("templateList");
                setLoading && setLoading(false);
                setIsEdit && setIsEdit(false);
                goBack && goBack();
                console.log(Object.values(putData || {})?.[0], setPreview);
                setPreview && setPreview(Object.values(putData || {})?.[0]);
              } else {
                console.log(res?.data?.data, "<<<updated>>>");
              }
              setLoading && setLoading(false);
            }
          }
        },
        {
          cleanup: true,
          minify: true,
        }
      );
      setLoading && setLoading(false);
      // setIsEdit && setIsEdit(false)
    };
    useImperativeHandle(ref, () => ({
      exportHtml() {
        exportHtml();
      },
    }));

    // useImperativeHandle(
    //     ref,
    //   () => {
    //       return {
    //         exportHtml()

    //     }
    //   },

    // )
    const onLoad = () => {
      const editor = emailEditorRef.current;
      let first = true;
      editor.addEventListener("design:updated", () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
          clearTimeout(saveTimeOut);
          saveTimeOut = setTimeout(async () => {
            const { design, html } = data;
            onEditPayload({ body: html });
            console.log(design);
            if (first) {
              first = false;
              return;
            }
            // if (isEdit?.create || templateForm?.json_template) {

            await exportHtml(design, html);
            // }
          }, 1000);
        });
        // const design = editor.exportHtml().design;
        // // Call your onChange handler with the updated design
        // console.log(design, "<<<<Updated");
      });
      // editor instance is created
      // you can load your template here;
      // const templateJson = {};
      // emailEditorRef.current.editor.loadDesign(templateJson);
      // console.log(document.getElementsByTagName("iframe")?.[0]?.contentWindow?.document?.getElementsByClassName("blockbuilder-branding"));
    };
    // console.log(document.getElementsByTagName("iframe")?.[0]?.contentWindow?.document.querySelectorAll("div"));

    const onReady = () => {
      // editor is ready
      if (isObjWithValues(editTemmplate)) {
        emailEditorRef.current.editor.loadDesign(editTemmplate);
        setEditTemplate(false);
      }
    };

    useEffect(() => {
      setPage(createPage);
    }, [createPage]);

    const setUpTemplate = () => {
      if (isObjWithValues(isEdit)) setTemplateForm(isEdit);
      else {
        // if (isObjWithValues(templateForm?.json_template)) isEdit = templateForm;
        if (isObjWithValues(templateForm)) isEdit = templateForm;
      }

      let webiste = getWebsite();
      let replaceLink = {
        "https://facebook.com/": "fb_link",
        "https://twitter.com/": "twitter_link",
        "https://www.youtube.com/": "youtube_link",
        "https://whatsapp.com/": "whatsapp_link",
        "https://linkedin.com/": "linkedin_link",
        "https://instagram.com/": "instagram_link",
      };
      let jsonObj = isEdit?.json_template;
      // || templateForm?.json_template;

      if (isEdit?.create) {
        if (logo) {
          console.log(logo);
          if (
            jsonObj?.body?.rows?.[0]?.columns?.[0]?.contents?.[0]?.values?.src
              ?.url
          ) {
            jsonObj.body.rows[0].columns[0].contents[0].values.src.url = logo;
          }
        }
        // if (jsonObj?.body?.rows?.[1]?.columns?.[0]?.contents?.[0]?.values?.src?.url) {
        //   jsonObj.body.rows[1].columns[0].contents[0].values.src.url = "https://tiarabytj.com/wp-content/uploads/sites/27/2021/05/2_tiara-logo-1-2-1.png"
        // }
        for (const iterator of Object.keys(replaceLink)) {
          console.log(websiteProductSettings?.[replaceLink?.[iterator]]);
          if (websiteProductSettings?.[replaceLink?.[iterator]]) {
            jsonObj = JSON.stringify(jsonObj || {})?.replace(
              iterator,
              websiteProductSettings?.[replaceLink?.[iterator]] || iterator
            );
            jsonObj = JSON.parse(jsonObj);
          }
        }
      }
      jsonObj = JSON.stringify(jsonObj || {})?.replace(
        "https://www.unlayer.com",
        webiste
      );
      jsonObj = JSON.parse(jsonObj);

      setEditTemplate(
        typeof jsonObj === "string" ? JSON.parse(jsonObj) : jsonObj || {}
      );
    };
    useEffect(() => {
      setUpTemplate();
    }, [isEdit, templateForm?.json_template]);

    const onNextPress = () => {
      if (!templateForm.name) return setError("Please enter name");
      if (!templateForm.category) return setError("Please enter category");
      if (!templateForm.subject) return setError("Please enter subject");
      if (templateForm.subject?.length > subjectLimit)
        return setError(subjectLimitError);

      // setCreatePage && setCreatePage(1);
      // setPage(1);
    };

    const sendTestEmail = async () => {
      setSending(true);
      let body = replaceVariables(emailPayload.body, userAdminData || {});

      let subject = replaceVariables(templateForm.subject, userAdminData || {});

      try {
        let website = getWebsite();
        let token = getToken();
        let res = await axios({
          url: `${nodeWebsite}/email/send`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: {
            subject: subject,
            body: body,
            website,
            customer: userAdminData,

            to: [emailPayload.to],
          },
        });
      } catch (error) {}
      setSending(false);
      handleCloseTestEmail();
    };

    return (
      <>
        {createTemplatePage !== predefined && (
          <div hidden={createTemplatePage === predefined}>
            <>
              <VariablePopover
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
              <DynamicPopover
                anchorEl={anchorElTestEmail}
                handleClose={handleCloseTestEmail}
                id={idTestEmail}
                open={openTestEmail}
                content={
                  <Box p={"16px"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h5">Send Test Email</Typography>
                      <IconButton
                        onClick={() => {
                          handleCloseTestEmail();
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Stack>

                    <Stack
                      // sx={{ padding: "16px" }}
                      direction={"row"}
                      gap={2}
                      alignItems={"end"}
                      sx={{ mt: 3 }}
                    >
                      <Stack>
                        <Typography sx={{ ...secondaryLabel }}>
                          Email
                        </Typography>
                        <TextField
                          value={emailPayload?.to}
                          size={"small"}
                          type="email"
                          placeholder="abc@gmail.com"
                          onChange={(e) => {
                            onEditPayload({ to: e.target.value });
                          }}
                        />
                      </Stack>
                      <LoadingButton
                        variant="contained"
                        onClick={() => sendTestEmail()}
                        loading={sending}
                      >
                        Send
                      </LoadingButton>
                    </Stack>
                  </Box>
                }
              />

              <CustomeDialogTitle
                dialogTitle={
                  isObjWithValues(isEdit) && !isEdit?.create
                    ? "Update Email Template"
                    : "Create Email  Template"
                }
                buttons={
                  isPhone ? (
                    <></>
                  ) : (
                    <>
                      <Stack direction={"row"} alignItems={"center"} gap={2}>
                        {/* <Button
                          endIcon={<ErrorOutline />}
                          onClick={handleClick}
                        >
                          Variable
                        </Button> */}
                        {isDev() && (
                          <Button
                            // onClick={() => setOpenSendEmail(true)}
                            onClick={handleClickTestEmail}
                          >
                            Send Test Email
                          </Button>
                        )}

                        <Button
                          onClick={() => {
                            setCreateTemplatePage(predefined);
                          }}
                        >
                          Use Predefined Template
                        </Button>
                        <LoadingButton
                          loading={loading}
                          variant="contained"
                          onClick={async () => {
                            //   setLoading(true);
                            // await emailBuilderRef?.current?.exportHtml();
                            exportHtml();
                            //   setLoading(false);
                          }}
                        >
                          {isEdit && !isEdit?.create ? "Update" : "Create"}
                        </LoadingButton>
                      </Stack>
                    </>
                  )
                }
                backButton={
                  isPhone ? (
                    <></>
                  ) : (
                    <IconButton
                      onClick={() => {
                        goBack && goBack();
                        setSelectedPage && setSelectedPage("");
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  )
                }
              />
              {/* <DialogTitle
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    >
                        <IconButton
                            onClick={() => {
                                goBack && goBack()
                            }}
                        >
                            <ArrowBack />
                        </IconButton>
                        {isEdit ? "Update Template" : "Create Template"}
                    </Typography>
                    {page ? <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Button endIcon={<ErrorOutline />} onClick={handleClick}>
                            Variable
                        </Button>
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            onClick={async () => {
                                //   setLoading(true);
                                // await emailBuilderRef?.current?.exportHtml();
                                exportHtml()
                                //   setLoading(false);
                            }}
                        >
                            {isEdit ? "Update" : "Create"}
                        </LoadingButton>
                    </Stack> : null}
                </DialogTitle> */}
              <DialogContent className="scrollbar-hidden">
                {isPhone ? (
                  <EmptyState
                    text={
                      "Email builder is not available in mobile view, please use desktop to create templates"
                    }
                    button={
                      <Button
                        variant="contained"
                        onClick={() => {
                          goBack && goBack();
                          setSelectedPage && setSelectedPage("");
                        }}
                      >
                        {" "}
                        Go back
                      </Button>
                    }
                  />
                ) : (
                  <div>
                    {page || true ? (
                      <>
                        <Box mb={2}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                              <Box>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                  size="small"
                                  fullWidth
                                  placeholder="Enter name"
                                  sx={{ mt: 2, ...textFeildRadius }}
                                  value={templateForm?.name}
                                  onChange={(e) =>
                                    editForm({ name: e.target.value })
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Box>
                                <FormLabel>Category</FormLabel>

                                <TextField
                                  size="small"
                                  select
                                  placeholder="Category"
                                  value={
                                    templateForm?.category || isEdit?.category
                                  }
                                  // defaultValue={templateForm?.category}
                                  fullWidth
                                  sx={{ mt: 2, ...textFeildRadius }}
                                  onChange={(e) => {
                                    editForm({
                                      category: e.target.value,
                                    });
                                  }}
                                >
                                  {categories?.map((o) => (
                                    <MenuItem value={o.value}>
                                      {o.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                            </Grid>
                            <Grid item xs={5}>
                              <Box>
                                <FormLabel>Subject</FormLabel>
                                <TextField
                                  size="small"
                                  multiline
                                  fullWidth
                                  // rows={2}
                                  placeholder="Subject"
                                  value={templateForm?.subject}
                                  sx={{ mt: 2, ...textFeildRadius }}
                                  onChange={(e) =>
                                    editForm({
                                      subject: e.target.value,
                                    })
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                            >
                              <Button
                                endIcon={<ErrorOutline />}
                                onClick={handleClick}
                                variant="contained"
                              >
                                Variable
                              </Button>
                            </Grid>
                          </Grid>
                          <Collapse in={Boolean(error)}>
                            <Typography
                              style={{
                                ...primaryLabel,
                                marginTop: 4,
                                textAlign: "center",
                              }}
                              color="error.main"
                            >
                              {error}
                            </Typography>
                          </Collapse>
                          {/* <Stack justifyContent={"flex-end"} direction={"row"} mt={5}>
                    <Button variant="contained" onClick={onNextPress}>
                      Next
                    </Button>
                  </Stack> */}
                        </Box>

                        <EmailEditor
                          ref={emailEditorRef}
                          onLoad={onLoad}
                          onReady={onReady}
                          // options={{
                          //     tools: {
                          //         image: {
                          //             enabled: false,
                          //         },
                          //     },
                          // }}
                          minHeight={"63vh"}
                        />
                        {ref
                          ? null
                          : false && (
                              <StackRow>
                                <Button onClick={() => exportHtml()}>
                                  Export
                                </Button>
                              </StackRow>
                            )}
                      </>
                    ) : (
                      <Box>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <FormLabel>Name</FormLabel>
                              <TextField
                                size="small"
                                fullWidth
                                placeholder="Enter name"
                                sx={{ mt: 2, ...textFeildRadius }}
                                value={templateForm?.name}
                                onChange={(e) =>
                                  editForm({ name: e.target.value })
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <FormLabel>Category</FormLabel>

                              <TextField
                                size="small"
                                select
                                placeholder="Category"
                                value={
                                  templateForm?.category || isEdit?.category
                                }
                                // defaultValue={templateForm?.category}
                                fullWidth
                                sx={{ mt: 2, ...textFeildRadius }}
                                onChange={(e) => {
                                  editForm({
                                    category: e.target.value,
                                  });
                                }}
                              >
                                {categories?.map((o) => (
                                  <MenuItem value={o.value}>{o.label}</MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <FormLabel>Subject</FormLabel>
                              <TextField
                                size="small"
                                multiline
                                fullWidth
                                rows={2}
                                placeholder="Subject"
                                value={templateForm?.subject}
                                sx={{ mt: 2, ...textFeildRadius }}
                                onChange={(e) =>
                                  editForm({
                                    subject: e.target.value,
                                  })
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Collapse in={Boolean(error)}>
                          <Typography
                            style={{
                              ...primaryLabel,
                              marginTop: 4,
                              textAlign: "center",
                            }}
                            color="error.main"
                          >
                            {error}
                          </Typography>
                        </Collapse>
                        <Stack
                          justifyContent={"flex-end"}
                          direction={"row"}
                          mt={5}
                        >
                          <Button variant="contained" onClick={onNextPress}>
                            Next
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </div>
                )}
              </DialogContent>
            </>
          </div>
        )}
        <ConfimPopup
          open={openSendEmail}
          handleClose={() => setOpenSendEmail(false)}
          heading={"Send Test Email"}
          content={
            <Stack>
              <Stack>
                <Typography sx={{ ...secondaryLabel }}>Email</Typography>
                <TextField
                  value={emailPayload?.to}
                  onChange={(e) => {
                    onEditPayload({ to: e.target.value });
                  }}
                />
              </Stack>
            </Stack>
          }
          button={
            <LoadingButton onClick={() => sendTestEmail()} loading={sending}>
              Send
            </LoadingButton>
          }
        />
        {predefined === createTemplatePage && (
          <TabPanel value={predefined} index={createTemplatePage}>
            <PredefinedEmailTemplate
              setSelectedPage={setCreateTemplatePage}
              setIsEdit={setIsEdit ? setIsEdit : setTemplateForm}
            />
          </TabPanel>
        )}
      </>
    );
  }
);

export default EmailBuilder;

const categories = [
  { label: "Marketing", value: "marketing" },
  { label: "Promotional", value: "promotional" },
  { label: "Transactional", value: "transactional" },
  { label: "Greetings ", value: "greetings" },
];

export const VariablePopover = ({ anchorEl, open, handleClose, heading }) => {
  const dispatch = useDispatch();
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      PaperProps={{
        elevation: 0,

        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "16px",
          width: 300,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            borderRadius: 8,
          },
          // "&:before": {
          //     content: '""',
          //     display: "block",
          //     position: "absolute",
          //     top: 0,
          //     right: 14,
          //     width: 10,
          //     height: 10,
          //     bgcolor: "background.paper",
          //     transform: "translateY(-50%) rotate(45deg)",
          //     zIndex: 0,
          // },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <MenuList>
        <MenuItem sx={{ position: "sticky", top: 0 }}>
          <ListItemText>
            <Typography variant="h5"> Variables</Typography>
            <Typography sx={{ whiteSpace: "break-spaces" }}>
              {heading || "You can copy and past variables in the HTML file"}
            </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        {variables?.map((obj) => {
          return (
            <MenuItem key={obj?.label}>
              {/* <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Typography>{obj?.label}</Typography>
                                <IconButton>
                                    <ContentCopy />
                                </IconButton>
                            </Stack>
                            <Stack>

                            </Stack> */}
              <ListItemText>
                <Typography variant="h6"> {obj?.label}</Typography>
                <Typography
                  sx={{ fontSize: "11px", whiteSpace: "break-spaces" }}
                >
                  {obj?.label} automaticallu adds the{" "}
                  {formatServerValue(obj?.value)} of customer.
                </Typography>
              </ListItemText>
              <IconButton
                onClick={(e) => {
                  navigator.clipboard.writeText(obj?.label);
                  dispatch(
                    setGlobalToast({
                      show: true,
                      severity: "success",
                      message: `${formatServerValue(obj?.value)} is copied`,
                    })
                  );
                  handleClose();
                }}
              >
                <ContentCopy fontSize="small" color="primary" />
              </IconButton>
              {/* <Typography variant="body2" color="text.secondary">
                                ⌘X
                            </Typography> */}
            </MenuItem>
          );
        })}
      </MenuList>
      {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
    </Menu>
  );
};

const variables = [
  { label: "{First Name}", value: "first_name" },
  { label: "{Last Name}", value: "last_name" },
  { label: "{Fullname}", value: "fullname" },
  { label: "{City}", value: "city" },
  { label: "{Address}", value: "address" },
  // { label: "{Title}", value: "title" },
  // { label: "{Order id}", value: "order_id" },
  // { label: "{Payment method}", value: "payment_method" },
  // { label: "{due date}", value: "due_date" },
  // { label: "{Expiry}", value: "expiry" },
  // { label: "{amount}", value: "amount" },
  // { label: "{pieces}", value: "pieces" },
  // { label: "{Brand name}", value: "brand_name" },
  // { label: "{Brand url}", value: "brand_url" },
  // { label: "{Reference no}", value: "reference_no" },
  // { label: "{Link}", value: "link" },
  // { label: "Address", value: "address" },
  //   { label: "Whatsapp", value: "whatsapp" },
  //   { label: "Mobile", value: "mobile" },
  //   { label: "Profession", value: "profession" },
  //   { label: "Language", value: "language" },
  //   { label: "Ethinicity", value: "ethinicity" },
  //   { label: "Religion", value: "religion" },
];
