export const preDefinedAttributesTextField = [
    "Name",
    "Email",
    "Phone",
    "Gender",
    "Birthday",
    "Anniversary",
    "Contact Type",
    "Opt-In",
    // "Tags",
    // "Broadcast List",
    "City",
    "State",
    "Country",
    "Language",
    "IP",
    "Channel",
    "Referrer",
    "Brand Name",
    "Brand Address",
  ];
  