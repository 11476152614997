function getFirstPlanDate(plans) {
  let firstPlanDate = null;

  for (const planId in plans) {
    const plan = plans[planId];
    const ledger = plan.ledger;

    if (ledger.length > 0) {
      const sortedLedger = ledger.sort((a, b) => {
        const dateA = new Date(a.date_paid);
        const dateB = new Date(b.date_paid);
        return dateA - dateB;
      });

      const firstPlanPaymentDate = new Date(sortedLedger[0].date_paid);
      if (firstPlanDate === null || firstPlanPaymentDate < firstPlanDate) {
        firstPlanDate = firstPlanPaymentDate;
      }
    }
  }

  return firstPlanDate;
}
function getPlanJoiningDate(plan) {
  let firstPlanDate = null;

  const ledger = plan?.ledger;

  if (ledger.length > 0) {
    const sortedLedger = ledger.sort((a, b) => {
      const dateA = new Date(a.date_paid);
      const dateB = new Date(b.date_paid);
      return dateA - dateB;
    });

    const firstPlanPaymentDate = new Date(sortedLedger[0].date_paid);
    if (firstPlanDate === null || firstPlanPaymentDate < firstPlanDate) {
      firstPlanDate = firstPlanPaymentDate;
    }
  }

  return firstPlanDate;
}

const checkUserAllPaymentsModeInDigigold = ({ plans = {} }) => {
  for (const key in plans) {
    const ledger = plans[key].ledger;
    const duration = parseInt(plans[key].duration);

    // Check if the length of the ledger is equal to the duration
    if (ledger.length < duration) {
      return false;
    }

    // Check if all payments have been made
    const completedPayments = ledger.filter(
      (payment) =>
        payment.status === "completed" || payment.status === "processing"
    );
    if (completedPayments.length < duration) {
      return false;
    }
  }

  return true;
};

export {
  getFirstPlanDate,
  checkUserAllPaymentsModeInDigigold,
  getPlanJoiningDate,
};
