export const conditionValueStyles = {
  outerBox: {
    padding: "5px 20px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
    width: "360px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  header: {
    marginBottom: "20px",
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  icon: {
    color: "#006cff",
    fontSize: "18px",
    marginRight: "10px",
  },
  labelText: {
    fontSize: "16px",
    fontWeight: 600,
  },
  conditionDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "10px",
    marginBottom: "10px",
  },
  button: {
    color: "#26323d",
    backgroundColor: "#f0f4f7",
    border: "none",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "none",
    transition: "0.3s ease",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#006cff",
      color: "#fff",
    },
  },
  selectedButton: {
    color: "#fff",
    backgroundColor: "#006cff",
    border: "none",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "none",
    transition: "0.3s ease",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#006cff",
      color: "#fff",
    },
  },
  textfield: {
    marginTop: "5px",
    marginBottom: "15px",
  },
  submitButton: {
    backgroundColor: "#006cff",
    color: "#fff",
    transition: "0.3s ease",
    "&:hover": {
      backgroundColor: "#629efc",
      color: "#fff",
    },
  },
};
