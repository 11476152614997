import { callSocket, getWebsite } from "../../helper";

const updateProductPricing = async (
  ids,
  { defaultSettings, defaultAllTaxes }
) => {
  await callSocket({
    endpoint: "master_update_hook",
    method: "POST",
    data: {
      website: getWebsite(),
      notMaster: true,
      ids,
      defaultSettings,
      defaultAllTaxes,
    },
  });
};

export { updateProductPricing };
