import { autocompleteClasses } from "@mui/material";

export const textFieldStyles = {
  textField: {
    padding: "15px",
    background: "rgb(255, 255, 255)",
    color: "rgb(0, 0, 0)",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "4px",
    display: "inline-block",
    fontFamily: "Inter, Roboto, sans-serif",
  },
};

export const buttonFieldStyles = {
  buttonCard: {
    display: "flex",
    flexDirection: "column",
    width: "250px",
    margin: "0px",
    padding: "0px",
    border: "1.3px solid #EAEEF3",
    borderRadius: "20px",
  },
  buttonTextField: {
    color: "rgb(0,0,0)",
    backgroundColor: "rgb(255,255,255)",
    minHeight: "50px",
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "10px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    backgroundColor: "rgb(255,255,255)",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  button: {
    height: "45px",
    color: "rgb(0, 108, 255)",
    padding: "0px",
    margin: "0px",
    border: "1.5px solid #EAEEF3",
    textTransform: "none",
  },
};

export const quickRepliesStyle = {
  container: {
    display: "flex",
    flexDirection: "Column",
    margin: "4px",
  },
  textField: {
    padding: "15px",
    background: "rgb(255, 255, 255)",
    color: "rgb(0, 0, 0)",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "4px",
    display: "inline-block",
    fontFamily: "Inter, Roboto, sans-serif",
  },
  buttonDiv: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "flex-start",
    margin: "10px",
  },
  button: {
    backgroundColor: "white",
    borderRadius: "20px",
    textTransform: "none",
    color: "rgb(0, 108, 255)",
    border: "1.3px solid rgb(0, 108, 255)",
    fontWeight: "800px",
    marginRight: "8px",
    marginBottom: "10px",
  },
};

export const imageFieldStyles = {
  imageComponent: {
    transition: "transform 0.2s",
    borderRadius: "10px",
    width: "280px",
    height: "220px",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
};

export const galleryFieldStyles = {
  mainDiv: {
    marginBottom: "10px",
    display: "flex",
    padding: "10px",
  },
  divCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
  },
  divCardContent: {
    padding: 0,
    width: "227px",
    marginBottom: "10px",
    fontFamily: "Inter, Roboto, sans-serif",
  },
  cardMedia: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  galleryImgCard: {
    width: "325px",
    backgroundColor: "#e3e3e3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  galleryBottom: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  galleryComponentTitle: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginBottom: "25px",
  },
  textFieldDiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
    minHeight: "10px",
  },
  galleryTextField: {
    fontFamily: "Inter, Roboto, sans-serif",
    height: autocompleteClasses,
    overflowY: "hidden",
    whiteSpace: "pre-wrap",
    marginBottom: "5px",
    marginLeft: "15px",
  },
  button: {
    height: "45px",
    color: "rgb(0, 108, 255)",
    padding: "0px",
    margin: "0px",
    border: "1.5px solid #EAEEF3",
    textTransform: "none",
  },
};

export const videoFieldStyles = {
  card: {
    width: " 300px",
    height: "180px",
    borderRadius: "15px",
  },
  cardVideo: {
    widht: "150px",
  },
};

export const documentFieldStyles = {
  card: {
    width: " 300px",
    height: "300px",
    borderRadius: "15px",
  },
};
