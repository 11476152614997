import {
  AutoGraph,
  Chat,
  ChatOutlined,
  Circle,
  ConnectWithoutContact,
  ConnectWithoutContactOutlined,
  DeviceHub,
  Inventory2Outlined,
  PeopleOutline,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Settings,
  Link,
  Package,
} from "react-feather";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import {
  CampaignGoldSvg,
  CampaignIconSvg,
  CatalogMenuIcon,
  ChatsMenuSvg,
  Connect,
  CRMMenuIcon,
  DashboardSvg,
  DesignBankMenuSvg,
  DigigoldMenuSvg,
  FlowsSvg,
  FrontEndMenuSvg,
  IntegrationMenuSvg,
  OrdersMenuSvg,
  ProductMenuSvg,
  QuotationMenuSvg,
  RetailSupplierConnect,
  SettingsMenuSvg,
  SocialMenuSvg,
  UserActivitiesMenuSvg,
} from "../../Svgs";
import { getWebsite, isDev, isOurWebsite } from "../../helper";

export const supplierPageSection = [
  {
    href: "/dashboard",
    // icon: Sliders,
    icon: DashboardSvg,
    title: "Dashboard",
    value: "dashboard",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "Default",
    //   },
    //   {
    //     href: "/dashboard/analytics",
    //     title: "Analytics",
    //   },
    //   {
    //     href: "/dashboard/saas",
    //     title: "SaaS",
    //   },
    // ],
  },
  {
    href: "/products",
    // icon: Package,
    icon: ProductMenuSvg,
    title: "Products",
    value: "products",
    // badge: "8",
  },

  {
    href: "/crm",
    // icon: PeopleOutline,
    icon: CRMMenuIcon,
    title: "CRM",
    value: "crm",
    // badge: "8",
  },
  {
    href: "/catalogs",
    // icon: AutoStoriesOutlinedIcon,
    icon: CatalogMenuIcon,
    title: "Catalogs",
    value: "catalogs",
    // badge: "8",
  },
  {
    href: "/orders",
    // icon: ReceiptLongOutlined,
    icon: OrdersMenuSvg,
    title: "Orders",
    value: "orders",
    // badge: "8",
  },
  {
    href: "/quotations",
    // icon: ReceiptLongOutlined,
    icon: QuotationMenuSvg,
    title: "Quotations",
    value: "quotations",
    // badge: "8",
  },
  {
    href: "/chats",
    // icon: ChatOutlined,
    icon: ChatsMenuSvg,
    title: "Chats",
    value: "chats",
    // badge: "8",
    children: [
      { title: "Overview", href: "/chats#overview" },
      { title: "Message", href: "/chats" },
      { title: "Activity", href: "/chats#activities" },
    ],
  },
  {
    href: "/hub",
    // icon: DeviceHub,
    icon: SocialMenuSvg,
    title: "Social",
    value: "social",

    // badge: "8",
  },

  // {
  //   href: "/campaign",
  //   // icon: DeviceHub,
  //   icon: <CampaignIconSvg />,
  //   title: "Campaign",
  //   value: "Campaign",

  //   // badge: "8",
  // },
  // {
  //   href: "/supplier_connect",
  //   icon: ConnectWithoutContactOutlined,
  //   title: "Supplier Connect",
  //   // badge: "8",
  // },
  // {
  //   href: "/frontend",
  //   // icon: Layout,
  //   icon: FrontEndMenuSvg,
  //   title: "Frontend",
  //   // children: [
  //   //   {
  //   //     href: "/pages/profile",
  //   //     title: "Profile",
  //   //   },
  //   //   {
  //   //     href: "/pages/settings",
  //   //     title: "Settings",
  //   //   },
  //   //   {
  //   //     href: "/pages/pricing",
  //   //     title: "Pricing",
  //   //   },
  //   //   {
  //   //     href: "/pages/chat",
  //   //     title: "Chat",
  //   //   },
  //   //   {
  //   //     href: "/pages/blank",
  //   //     title: "Blank Page",
  //   //   },
  //   // ],
  // },

  {
    href: "/campaign",
    // icon: DeviceHub,
    icon: CampaignGoldSvg,
    title: "Campaign",
    value: "Campaign",

    // badge: "8",
  },
  {
    href: "/integrations",
    icon: IntegrationMenuSvg,
    title: "Integrations",
    value: "integrations",
    // badge: "8",
  },
  {
    href: "/settings",
    icon: SettingsMenuSvg,
    title: "Settings",
    value: "settings",
    // badge: "8",
  },
  {
    href: "/user_activites",
    icon: UserActivitiesMenuSvg,
    title: "Activities",
    // badge: "8",
  },
];
export const retailerPageSection = [
  {
    href: "/dashboard",
    // icon: Sliders,

    icon: DashboardSvg,
    title: "Dashboard",
    value: "dashboard",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "Default",
    //   },
    //   {
    //     href: "/dashboard/analytics",
    //     title: "Analytics",
    //   },
    //   {
    //     href: "/dashboard/saas",
    //     title: "SaaS",
    //   },
    // ],
  },
  {
    href: "/products",
    // icon: Package,
    icon: ProductMenuSvg,
    title: "Products",
    value: "products",
    // badge: "8",
  },

  {
    href: "/crm",
    // icon: PeopleOutline,
    icon: CRMMenuIcon,
    title: "CRM",
    value: "crm",
    // badge: "8",
  },
  {
    href: "/design-bank",
    // icon: Inventory2Outlined,
    icon: DesignBankMenuSvg,
    title: "Design Bank",
    value: "design_bank",
    // badge: "8",
  },
  {
    href: "/catalogs",
    // icon: AutoStoriesOutlinedIcon,
    icon: CatalogMenuIcon,
    title: "Catalogs",
    value: "catalogs",
    // badge: "8",
  },
  {
    href: "/orders",
    // icon: ReceiptLongOutlined,
    icon: OrdersMenuSvg,
    title: "Orders",
    value: "orders",
    // badge: "8",
  },

  {
    href: "/digigold",
    icon: DigigoldMenuSvg,
    iconColor: "#FFDB49",
    title: "Digigold",
    value: "digigold",
    // badge: "8",
  },
  {
    href: "/chats",
    // icon: ChatOutlined,
    icon: ChatsMenuSvg,

    title: "Chats",
    value: "chats",
    children: [
      { title: "Overview", href: "/chats#overview" },
      { title: "Message", href: "/chats" },
      { title: "Activity", href: "/chats#activities" },
    ],
    // badge: "8",
  },
  {
    href: "/hub",
    // icon: DeviceHub,
    icon: SocialMenuSvg,
    title: "Social",
    value: "social",
    // badge: "8",
  },
  // {
  //   href: "/supplier_connect",
  //   icon: RetailSupplierConnect,
  //   title: "Supplier Connect",
  //   // badge: "8",
  // },
  // {
  //   href: "/campaign",
  //   // icon: DeviceHub,
  //   icon: CampaignGoldSvg,
  //   title: "Campaign",
  //   value: "Campaign",
  //   badge: "New",
  // },
  {
    href: "/marketing_automation",
    // icon: DeviceHub,
    icon: CampaignGoldSvg,
    title: "Marketing Automation",
    value: "marketing_automation",
    badge: "New",
  },
  // {
  //   href: "/flows",
  //   // icon: DeviceHub,
  //   icon: FlowsSvg,
  //   title: "Flows",
  //   value: "flows",
  //   badge: "New",
  // },
  {
    href: "/frontend",
    icon: FrontEndMenuSvg,

    title: "Frontend",
    value: "frontend",
    // children: [
    //   {
    //     href: "/pages/profile",
    //     title: "Profile",
    //   },
    //   {
    //     href: "/pages/settings",
    //     title: "Settings",
    //   },
    //   {
    //     href: "/pages/pricing",
    //     title: "Pricing",
    //   },
    //   {
    //     href: "/pages/chat",
    //     title: "Chat",
    //   },
    //   {
    //     href: "/pages/blank",
    //     title: "Blank Page",
    //   },
    // ],
  },

  {
    href: "/integrations",
    icon: IntegrationMenuSvg,
    title: "Integrations",
    value: "integrations",
    // badge: "8",
  },
  {
    href: "/settings",
    icon: SettingsMenuSvg,
    title: "Settings",
    value: "settings",
    // badge: "8",
  },

  {
    href: "/user_activities",
    icon: UserActivitiesMenuSvg,
    title: "Activities",
    // badge: "8",
  },
];
const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "Default",
    //   },
    //   {
    //     href: "/dashboard/analytics",
    //     title: "Analytics",
    //   },
    //   {
    //     href: "/dashboard/saas",
    //     title: "SaaS",
    //   },
    // ],
  },
  {
    href: "/products",
    icon: Package,
    title: "Products",
    // badge: "8",
  },

  {
    href: "/crm",
    // icon: PeopleOutline,
    icon: CRMMenuIcon,
    title: "CRM",
    // badge: "8",
  },
  // {
  //   href: "/design_bank",
  //   icon: Inventory2Outlined,
  //   title: "Design Bank",
  //   // badge: "8",
  // },
  {
    href: "/catalogs",
    icon: AutoStoriesOutlinedIcon,
    title: "Catalogs",
    // badge: "8",
  },
  {
    href: "/orders",
    icon: ReceiptLongOutlined,
    title: "Orders",
    // badge: "8",
  },
  {
    href: "/hub",
    icon: DeviceHub,
    title: "Social",
    // badge: "8",
  },
  {
    href: "/supplier_connect",
    icon: ConnectWithoutContactOutlined,
    title: "Supplier Connect",
    // badge: "8",
  },
  {
    href: "/chats",
    icon: ChatOutlined,
    title: "Chats",
    // badge: "8",
  },
  {
    href: "/frontend",
    icon: Layout,
    title: "Frontend",
    // children: [
    //   {
    //     href: "/pages/profile",
    //     title: "Profile",
    //   },
    //   {
    //     href: "/pages/settings",
    //     title: "Settings",
    //   },
    //   {
    //     href: "/pages/pricing",
    //     title: "Pricing",
    //   },
    //   {
    //     href: "/pages/chat",
    //     title: "Chat",
    //   },
    //   {
    //     href: "/pages/blank",
    //     title: "Blank Page",
    //   },
    // ],
  },

  // {
  //   href: "/campaign",
  //   // icon: DeviceHub,
  //   icon: CampaignGoldSvg,
  //   title: "Campaign",
  //   value: "Campaign",

  //   // badge: "8",
  // },

  {
    href: "/integrations",
    icon: Link,
    title: "Integrations",
    // badge: "8",
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    // badge: "8",
  },
  {
    href: "/user_tasks",
    icon: Settings,
    title: "User tasks",
    // badge: "8",
  },

  // {
  //   href: "/masters",
  //   icon: Settings,
  //   title: "Masters",
  //   // badge: "8",
  // },
  // {
  //   href: "/visitors_info",
  //   icon: AutoGraph,
  //   title: "Visitors Info",
  //   // badge: "8",
  // },

  // {
  //   href: "/projects",
  //   icon: Briefcase,
  //   title: "Projects",
  //   badge: "8",
  // },
  // {
  //   href: "/orders",
  //   icon: ShoppingCart,
  //   title: "Orders",
  // },
  // {
  //   href: "/invoices",
  //   icon: CreditCard,
  //   title: "Invoices",
  //   children: [
  //     {
  //       href: "/invoices",
  //       title: "List",
  //     },
  //     {
  //       href: "/invoices/detail",
  //       title: "Detail",
  //     },
  //   ],
  // },
  // {
  //   href: "/tasks",
  //   icon: CheckSquare,
  //   title: "Tasks",
  //   badge: "17",
  // },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
];

const navItems = [
  {
    title: "Pages",
    pages: isDev()
      ? pagesSection
      : !isOurWebsite()
      ? pagesSection.filter(
          (obj) =>
            obj?.title !== "Supplier Connect" &&
            obj?.title !== "Design Bank" &&
            obj?.title !== "Flows" &&
            obj?.title !== "User tasks"
        )
      : pagesSection.filter((obj) => obj?.title !== "Supplier Connect"),
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Material App",
  //   pages: docsSection,
  // },
];

export default navItems;

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },
//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v3.2.1",
//   },
// ];
