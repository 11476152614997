import {
  Add,
  AddPhotoAlternateOutlined,
  Close,
  Remove,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import {
  Autocomplete,
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialCodes,
  dialogStyle,
  formatServerValue,
  getAxiosError,
  getIsBackendMigrated,
  getMetaObject,
  getMigrationInfo,
  getRandomString,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isArrayWithValues,
  isEmailValid,
  isObjWithValues,
  isOurWebsite,
  unformatServerValue,
  updateCustomer,
  validateEmail,
  validateNumber,
} from "../../helper";
import {
  addCustomerInRedux,
  addCustomerStoreUsed,
  addMultipleCustomerInRedux,
  updateCustomerInRedux,
} from "../../redux/actions/crmActions";
import {
  addNewCustomerInChats,
  bulkActionChats,
  getConversations,
  putMergeProfiles,
  updateChatCustomer,
  updateChatObject,
} from "../../redux/actions/chatActions";
import styled from "styled-components";
import { commonDialCodes } from "../crm/Add";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { addUserActivity } from "../../redux/actions/hubActions";
import { StackRow } from "../components/helper";
import { addConversation } from "../chat/components/ChatWindow";
import { addTeamMember } from "../../redux/actions/settingActions";
import { addEmptyConversations, chatsLimit } from "../chat/ChatsSocial";
import phone from "phone";
import { InstagramSvg, Messenger, Whatsapp } from "../../Svgs";
import { useNavigate } from "react-router-dom";

let limitError = `Maximum Chats Limit is Reached`;

const AddCustomer = ({
  addFormView,
  setAddFormView,
  customer,
  setToast,
  customerInfo,
  whatsapp,
  create,
  setCreate,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [uploadingImage, setUploadingImage] = useState("");
  const [error, setError] = useState("");
  const [states, setStates] = useState([]);
  const [addressStateCode, setAddressSateCode] = useState({});
  const defaultState = {
    first_name: "",
    last_name: "",
    email: "",
    nickname: "",
    phone: "",
    whatsapp: "",
    dialcode_mobile: "+91",
    dialcode_whatsapp: "+91",
    contact_type: "lead",
  };
  const [inputData, setInputData] = useState(defaultState);
  const [showAdditionalInfoWhatsapp, setShowAdditionalInfoWhatsapp] =
    useState(false);
  const countriesArray = useSelector((state) => state.persist.countries);
  const stateArray = useSelector((state) => state.persist.states);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  // let {  first_name,
  //   last_name, } = customer
  const allReduxCustomers = useSelector(
    (state) => state.customers.allCustomers
  );

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const inputFile = useRef();
  const navigate = useNavigate();

  const [dialcodeInfo, setDialcodeInfo] = useState({});

  useEffect(() => {
    let info = dialCodes.find((i) => i.label === customerInfo?.dialcode_mobile);
    let whatsappInfo = dialCodes.find(
      (i) => i.label === customerInfo?.dialcode_whatsapp
    );
    let payload = {};

    if (info) payload.phone = info;
    if (whatsappInfo) payload.whatsapp = whatsappInfo;

    if (info) setDialcodeInfo((state) => ({ ...state, ...payload }));
  }, [customerInfo?.dialcode_mobile, customerInfo?.dialcode_whatsapp]);

  useEffect(() => {
    if (isObjWithValues(customerInfo)) {
      if (customerInfo?.teamMember) {
        console.log(reduxTeamMembers);
        let teamMember = reduxTeamMembers?.find((member) =>
          member?.assignedChatCustomers?.find(
            (o) => o.value == customerInfo?.id
          )
        );
        setInputData((pre) => ({ ...pre, team_member: teamMember?.id || "" }));
      }
      // let phoneNumber;

      console.log(customer?.number, "<<< whatsappnumber");
      let phoneNumber;
      let dialcode_mobile;

      if (customer?.number) {
        let { phoneNumber: phone_number, countryCode } =
          phone(`+${customer?.number}`) || {};
        phoneNumber = phone_number?.replace(countryCode, "") || phone_number;
        dialcode_mobile = countryCode;
      }
      // if(custome)
      // if (
      //   customer?.number?.length === 12 &&
      //   customer?.number?.slice(0, 2) == 91
      // )
      //   phoneNumber = customer?.number?.slice(2);
      // else phoneNumber = customer?.number;
      if (whatsapp) {
        let data = {};
        console.log(customerInfo);
        if (isObjWithValues(customerInfo?.billing)) {
          setInputData({ ...customerInfo.billing });
          delete customerInfo.billing;
          // delete customerInfo.conversation;
        }
        if (isObjWithValues(customerInfo)) {
          // let
          setInputData((pre) => ({
            ...pre,
            ...customerInfo,
            id: customerInfo?.number,
            number: phoneNumber,
            dialcode_mobile: dialcode_mobile || "+91",
          }));
        }

        // setInputData(...(customerInfo || []), ...(isObjWithValues(customerInfo?.billing) ? customerInfo?.billing : []))
        return;
      }
      let obj = { ...customerInfo };
      let meta_keys = ["whatsapp"];
      let meta_data = customerInfo?.meta_data || [];
      for (const key of meta_keys) {
        let metaObj = meta_data.find((obj) => obj.key === key);
        if (metaObj) {
          obj = { ...obj, [metaObj.key]: metaObj.value };
        }
      }
      setInputData((pre) => ({
        ...pre,
        first_name: obj?.first_name || customer?.name || "",
        last_name: obj?.last_name || "",
        email: obj?.email || "",
        nickname: obj?.nickname || "",
        phone: obj?.billing?.phone || "",
        whatsapp: obj?.whatsapp || "",
      }));
    } else {
      setInputData(defaultState);
    }
    // for (const customerProperty of Object.keys(customerInfo || {})) {
    //   if (Object.keys(defaultState)?.includes(customerProperty)) {
    //     console.log({ [customerProperty]: customerInfo?.[customerProperty], });
    //     setInputData((pre) => ({
    //       ...pre,
    //       [customerProperty]: customerInfo?.[customerProperty],
    //     }));
    //   }
    // }
  }, [customerInfo, addFormView]);

  useEffect(() => {
    if (!isObjWithValues(customer)) return;
    // console.log("hi", customer?.name);
    let phoneNumber;
    let dialcode_mobile;

    if (customer?.number) {
      let { phoneNumber: phone_number, countryCode } =
        phone(`+${customer?.number}`) || {};
      phoneNumber = phone_number?.replace(countryCode, "") || customer?.number;
      dialcode_mobile = countryCode;
    }
    console.log(customer);
    // if (customer?.number?.length === 12 && customer?.number?.slice(0, 2) == 91)
    //   phoneNumber = customer?.number?.slice(2);
    // else phoneNumber = customer?.number;
    setInputData((pre) => ({
      ...pre,
      ...(customer?.billing || {}),
      first_name: customer?.name || customer?.first_name || "",
      last_name: customer?.last_name || "",
      email: customer?.email || "",
      nickname: "",
      phone: phoneNumber || "",
      whatsapp: phoneNumber || "",
      dialcode_mobile: dialcode_mobile || "+91",
      team_member:
        reduxTeamMembers?.find((member) =>
          member?.assignedChatCustomers?.find(
            (o) => o.value == customerInfo?.id
          )
        )?.id || "",

      // customer?.teamMember
    }));
    setImageURL(customer?.profile_pic || "");
  }, [customer, customerInfo, addFormView]);

  const addCustomer = async () => {
    console.log("add customer");
    // if (!inputData.email || !inputData.phone) return
    // if (!inputData.phone) return

    try {
      let error = "";
      if (!inputData.email && !inputData.phone && !inputData.whatsapp)
        error = "Please enter Email or Phone Number!";
      else if (inputData.email && !isEmailValid(inputData.email))
        error = "Please enter valid email";
      setLoading(true);
      let uploadObject = { ...inputData };
      if (!uploadObject.email) delete uploadObject.email;
      uploadObject = {
        ...uploadObject,
        dialcode_mobile: uploadObject.dialcode_mobile.replace("+", ""),
        dialcode_whatsapp: uploadObject.dialcode_whatsapp.replace("+", ""),
      };
      uploadObject = {
        ...uploadObject,
        avatar_url: imageURL || "",
        billing: {
          email: uploadObject.email,
          phone: uploadObject.phone,
          first_name: uploadObject.first_name,
          last_name: uploadObject.last_name,
        },
        meta_data: [
          { key: "whatsapp", value: uploadObject.whatsapp },
          {
            key: "dialcode_mobile",
            value: uploadObject.dialcode_mobile,
          },
          {
            key: "dialcode_whatsapp",
            value: uploadObject.dialcode_whatsapp,
          },
          {
            key: "contact_type",
            value: uploadObject.contact_type,
          },
        ],
      };
      if (!uploadObject.nickname)
        uploadObject.nickname = `${inputData.first_name || ""} ${
          inputData.last_name || ""
        }`;

      if (error) {
        setError(error);
        setLoading(false);
        return;
      }
      if (customer) {
        let { platform, id } = customer || {};
        uploadObject.meta_data.push({ key: `${platform}_id`, value: id });
      }
      let settingdata = {};
      if (isArrayWithValues(customerInfo?.merge_profiles?.profiles)) {
        uploadObject.meta_data = [
          ...(uploadObject.meta_data || []),
          ...customerInfo?.merge_profiles?.profiles.map((profile) => ({
            key: `${profile?.platform}_id`,
            value: profile?.id,
          })),
        ];

        settingdata = {
          [customerInfo?.merge_profiles?.id]: {
            ...customerInfo?.merge_profiles,
            // crm_id :
          },
        };
      }
      if (create) {
        let id = `${Date.now()}_${getRandomString(5)}`;
        settingdata = {
          [id]: {
            id,
            profiles: [
              {
                id: customer?.id,
                platform: customer?.platform,
              },
            ],
          },
        };
      }
      if (customer?.profile_pic || imageURL) {
        uploadObject.meta_data.push({
          key: `profile_url`,
          value: imageURL || customer?.profile_pic,
        });
      }
      delete uploadObject.phone;
      delete uploadObject.whatsapp;
      delete uploadObject.id;
      // if (customer?.customer_id) {
      //   let obj = uploadObject;
      //   for (const key in obj) {
      //     if (!obj[key]) delete obj[key];
      //   }
      //   const res = await updateCustomer(customer.customer_id, uploadObject);
      //   if (res?.data?.id)
      //     dispatch(
      //       setGlobalToast({
      //         show: true,
      //         message: `Customer ${inputData.first_name} ${inputData.last_name} updated successfully`,
      //         severity: "success",
      //       })
      //     );
      //   //   setToast((pre) => ({
      //   //     ...pre,
      //   //     show: true,
      //   //     message: `Customer ${inputData.first_name} ${inputData.last_name}
      //   // successfully`,
      //   //     severity: "success",
      //   //   }));
      //   if (res?.data?.id) dispatch(updateCustomerInRedux(res.data));
      // } else {

      // return
      const createRes = await createCustomer({
        // ...uploadObject
        value:
          inputData.email ||
          inputData.phone ||
          inputData.whatsapp ||
          unformatServerValue(
            `${inputData.first_name} ${inputData.last_name}`.trim()
          ),
        // password: "password",
        // ...uploadObject,
      });
      console.log(
        createRes?.already_exists,
        create,
        "<<<<<<<<<<<<<<< createRes?.already_exists && create"
      );
      if (
        createRes?.already_exists &&
        (create || !isObjWithValues(customerInfo))
      ) {
        setError("Customer is already created");
        setLoading(false);
        return;
      }
      // await AddWhatsapp();
      if (isObjWithValues(settingdata)) {
        let settingObj = Object.values(settingdata)[0];
        settingdata = {
          [settingObj?.id]: {
            ...settingObj,
            crm_id: createRes?.data,
          },
        };
      }
      console.log(createRes, "<<res");
      let res;
      if (createRes.success || createRes?.type === "success") {
        console.log(settingdata);
        if (isObjWithValues(settingdata)) {
          await dispatch(putMergeProfiles(settingdata));
        }
        res = await updateCustomer(createRes.data, uploadObject);
        dispatch(addCustomerStoreUsed());
        console.log(res, "updated customer");
        dispatch(addMultipleCustomerInRedux([res]));
        navigate(`/crm/${createRes.data}/#Info`);
        // dispatch(
        //   updateChatObject(customer, {
        //     payload: {
        //       customer_id: res.id,
        //     },
        //   })
        // );
      }
      if (!res) {
        setLoading(false);
        return setError("Internal Error, Please try again later!");
      }
      console.log(res, "UPDATE RES");
      // if (res?.code) {
      //   let { code } = res;
      //   let error = "";
      //   if (code === "registration-error-email-exists")
      //     error = "Email already exists!";

      //   if (!error)
      //     if (res.message) error = res.message.replace(/<\/?[^>]+(>|$)/g, "");
      //     else error = "Internl Error! Please try again later";
      //   setError(error);
      //   setLoading(false);
      //   return;
      // }

      if (isArrayWithValues(allReduxCustomers)) {
        dispatch(addCustomerInRedux(res));
      }
      console.log(
        getIsBackendMigrated("chats"),
        uploadObject,
        "<<getIsBackendMigrated(chats)"
      );
      if (getIsBackendMigrated("chats")) {
        let createArray = [];
        let merge_profilesObject = {};
        let dialcode_mobile = getMetaObject({
          meta_data: uploadObject?.meta_data,
          key: "dialcode_mobile",
        });
        let id = `${getTimeInMilliSeconds()}_${getRandomString(4)}`;

        let dialcode_whatsapp = getMetaObject({
          meta_data: uploadObject?.meta_data,
          key: "dialcode_whatsapp",
        });
        let whatsapp = getMetaObject({
          meta_data: uploadObject?.meta_data,
          key: "whatsapp",
        });

        if (dialcode_whatsapp && whatsapp) {
          let platform_id = `${dialcode_whatsapp}${whatsapp}`;
          merge_profilesObject[id] = {
            ...(merge_profilesObject[id] || {}),
            profiles: [
              ...(merge_profilesObject?.[id]?.profiles || []),
              {
                id: `${dialcode_whatsapp}${whatsapp}`,
                platform: "whatsapp",
              },
            ],
          };
          let payloadObject = {
            platform_id: platform_id,
            first_name: uploadObject?.first_name,
            last_name: uploadObject?.last_name,
            dialcode_whatsapp: dialcode_whatsapp,
            whatsapp: whatsapp,
            contact_type: uploadObject?.contact_type,
            email: uploadObject?.email,
          };
          createArray.push(payloadObject);
        }
        if (
          !(dialcode_whatsapp && whatsapp) &&
          uploadObject?.billing?.phone &&
          dialcode_mobile
        ) {
          let platform_id = `${dialcode_mobile}${uploadObject?.billing?.phone}`;
          merge_profilesObject[id] = {
            id,
            crm_id: createRes?.data,
            profiles: [
              {
                id: platform_id,
                platform: "whatsapp",
              },
            ],
          };

          let payloadObject = {
            platform_id: platform_id,
            first_name: uploadObject?.first_name,
            last_name: uploadObject?.last_name,
            dialcode_whatsapp: uploadObject?.billing?.phone,
            whatsapp: dialcode_mobile,
            contact_type: uploadObject?.contact_type,
            email: uploadObject?.email,
          };
          createArray.push(payloadObject);
        }

        if (isObjWithValues(merge_profilesObject))
          dispatch(putMergeProfiles(merge_profilesObject));
        if (isArrayWithValues(createArray))
          dispatch(
            bulkActionChats({
              data: {
                create: createArray,
              },
            })
          );
      }

      if (createRes?.data) {
        setInputData(defaultState);
        setAddFormView(false);
        setCreate && setCreate(false);
        dispatch(
          setGlobalToast({
            show: true,
            message: `Customer ${inputData.first_name} ${inputData.last_name} added successfully`,
            severity: "success",
          })
        );
      }
      dispatch(
        addUserActivity({
          event: "customer_add",
          event_info: {
            product_id: res?.data?.id,
            name: `${inputData.first_name} ${inputData.last_name}`,
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
    // }
    setLoading(false);
    // setAddFormView(false);
    // setCreate && setCreate(false);
  };
  useEffect(() => {
    if (isObjWithValues(stateArray)) {
      for (const key in stateArray) {
        if (key === inputData?.country) {
          setStates(stateArray[key]);
        }
      }
    }
  }, [inputData?.country]);
  useEffect(() => {
    if (customer?.profile_pic) setImageURL(customer?.profile_pic);
  }, [customer]);

  const inputArray = whatsapp
    ? [
        { label: "First Name", name: "first_name" },
        { label: "Last Name", name: "last_name" },
        // { label: "Address", name: "address_1", props: { xs: 12 } },
        // { label: "City", name: "city" },
        // { label: "State", name: "state" },
        // { label: "State", name: "state" },
        // { label: "Country", name: "country" },
      ]
    : [
        { label: "First Name", name: "first_name" },
        { label: "Last Name", name: "last_name" },
        { label: "Email", name: "email" },
        // { label: "Nick Name", name: "nickname" },
        // { label: "Mobile", name: "phone" },
        // { label: "WhatsApp", name: "whatsapp" },
      ];
  const uploadImage = (profile) => {
    setUploadingImage(true);
    const formData = new FormData();
    formData.append("file", profile, profile.name);

    const website = getWebsite();
    const token = getToken();
    if (website && token)
      axios
        .post(`${website}/wp-json/wp/v2/media`, formData, {
          headers: {
            accept: "application/json",
            Authorization: `Basic ${token}`,
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((res) => {
          console.log(res.data);
          let { data } = res;
          if (data.source_url) setImageURL(data.source_url);
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally((e) => setUploadingImage(false));
  };

  // useEffect(() => {
  //   if (whatsapp && !isArrayWithValues(allReduxConversations)) {
  //     dispatch(getConversations());
  //   }
  // }, [whatsapp]);
  useEffect(() => {
    if (isArrayWithValues(allReduxConversations)) {
      if (allReduxConversations?.length >= chatsLimit) {
        return setError(limitError);
      }
    }
  }, [allReduxConversations]);

  const inputChangeHandler = (e, newvalue) => {
    if (error) setError("");
    let { name, value } = e.target;

    if (name === "phone")
      value = value?.substring(0, dialcodeInfo?.phone?.max || 13);
    if (name === "whatsapp")
      value = value?.substring(0, dialcodeInfo?.whatsapp?.max || 13);

    setInputData((pre) => ({
      ...pre,
      [`${name}`]: typeof newvalue !== "object" && newvalue ? newvalue : value,
    }));
  };

  const AddWhatsapp = async () => {
    let error = "";
    if (
      // !inputData.email &&
      !inputData.phone ||
      // !inputData.whatsapp &&
      !inputData.first_name
      // &&
      // !inputData.last_name
    )
      error = "Please enter name and Phone Number!";
    // else if (inputData.email && !isEmailValid(inputData.email))
    //   error = "Please enter valid email";

    setLoading(true);
    console.log("add whatsapp");

    if (error) {
      setError(error);
      setLoading(false);
      return;
    }
    setLoading(true);
    let billingArray = [
      "address_1",
      "address_2",
      "city",
      "postcode",
      "country",
      "state",
    ];
    let {
      first_name,
      last_name,
      dialcode_mobile,
      phone,
      contact_type,
      email,
      company_name,
    } = inputData;

    let billing = {};
    for (const key of Object.keys(inputData)) {
      if (billingArray.includes(key) && inputData[key]) {
        billing[key] = inputData[key];
      }
    }
    if (inputData?.team_member) {
      // let newTeamMemberArr = [];

      let team_member = [...(reduxTeamMembers || [])]?.map((obj) => {
        if (obj?.id == inputData?.team_member)
          obj.assignedChatCustomers = [
            ...(obj?.assignedChatCustomers || []),
            {
              label: `${first_name || ""} ${last_name || ""}`,
              value:
                customerInfo?.id ||
                `${dialcode_mobile.replace("+", "")}${phone}`,
              platform: customer?.platform || "whatsapp      ",
            },
          ];
        else
          obj.assignedChatCustomers = obj.assignedChatCustomers?.filter(
            (o) =>
              o.value != customerInfo?.id ||
              `${dialcode_mobile.replace("+", "")}${phone}`
          );
        return obj;
      });
      // let team_member = reduxTeamMembers?.map((obj) => ({
      //   ...obj,

      //   assignedChatCustomers: [
      //     ...(obj?.assignedChatCustomers || []),
      //     {
      //       label: `${first_name || ""} ${last_name || ""}`,
      //       value:
      //         customerInfo?.id || `${dialcode_mobile.replace("+", "")}${phone}`,
      //     },
      //   ],
      // }));

      await dispatch(
        addTeamMember(
          team_member,
          () => {
            // onClose();
            // setLoading(false);
          }
          // () =>
          //   dispatch(
          //     setGlobalToast({
          //       show: true,
          //       message: "Assigned chats succesfully",
          //       severity: "success",
          //     })
          //   )
        )
      );
    } else {
      console.log("else");

      let team_member = [...(reduxTeamMembers || [])]?.map((obj) => {
        // console.log(
        //   obj?.first_name,
        //   obj?.assignedChatCustomers,
        //   obj?.assignedChatCustomers?.filter((o) => o.value != customer?.id)
        // );
        return {
          ...obj,
          assignedChatCustomers: obj?.assignedChatCustomers?.filter(
            (o) => o.value != customer?.id
          ),
        };
      });

      await dispatch(
        addTeamMember(
          team_member,
          () => {
            // onClose();
            // setLoading(false);
          }
          // () =>
          // dispatch(
          //   setGlobalToast({
          //     show: true,
          //     message: "Assigned chats succesfully",
          //     severity: "success",
          //   })
          // )
        )
      );
    }

    let data = {
      // ...(customer || {}),
      id: customer?.id
        ? customer?.id
        : `${dialcode_mobile.replace("+", "")}${phone}`,
      // name: ,

      platform: customerInfo?.platform || customer?.platform || "whatsapp",
      status_updated: Date.now(),
      profile_pic: imageURL,
      dialcode_mobile: dialcode_mobile,
      last_message: Date.now(),
      contact_type,
      email,
      company_name,
      billing: isObjWithValues(billing) ? billing : null,
      conversation: customerInfo?.conversation || [],
    };
    // if (dialcode_mobile || phone) {
    data.number = `${dialcode_mobile.replace("+", "")}${phone}`;
    // }
    if (data?.platform === "whatsapp")
      data.name = `${first_name || ""} ${last_name || ""}`;
    else {
      data.first_name = first_name;
      data.last_name = last_name;
    }
    data.teamMember = "";

    // return
    if (customer?.id) {
      // data.id = customer?.number
      // data.number = customer?.nummber
      await dispatch(
        updateChatObject(data, {
          payload: {
            ...data,
            // label: [...new Set(arr)],
          },
        })
      );
      dispatch(
        setGlobalToast({
          show: true,
          message: "Contact  updated successfully!",
          severity: "success",
        })
      );
      // await dispatch(updateChatCustomer([{ ...data, platform: "whatsapp", data: { ...data } }]));
    } else {
      let conversations = await addConversation(data);
      await addEmptyConversations([data?.id]);

      if (isArrayWithValues(conversations)) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Contact  added successfully!",
            severity: "success",
          })
        );
        let obj = {
          ...(conversations[conversations.length - 1] || {}),
          conversation: [],
          platform: "whatsapp",
        };
        dispatch(addNewCustomerInChats(obj));
        console.log(conversations);
      } else if (
        conversations?.error &&
        conversations?.code === "duplicate_customer"
      ) {
        dispatch(
          setGlobalToast({
            show: true,
            message: conversations?.message || "Adding contact failed",
            severity: "error",
          })
        );
        setLoading(false);
        setCreate && setCreate(false);
        return;
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Adding contact failed",
            severity: "error",
          })
        );
      }
    }
    setInputData(defaultState);
    setLoading(false);
    setAddFormView(false);
    setCreate && setCreate(false);
  };
  return (
    <>
      <Dialog
        open={addFormView}
        onClose={() => {
          setAddFormView(false);
        }}
        maxWidth="xs"
        sx={isPhone ? {} : { ...dialogStyle }}
        fullScreen={isPhone}
        keepMounted={false}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h6" id="alert-dialog-title">
              {create
                ? "Add"
                : isObjWithValues(customerInfo)
                ? "Update"
                : "Add"}{" "}
              Customer
            </Typography>

            <Stack direction="row" spacing={3} alignItems={"center"}>
              {/* {!whatsapp && isOurWebsite() && (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/crm_bulk_add")}
                  size="small"
                >
                  Bulk Add
                </Button>
              )} */}
              <IconButton onClick={() => setAddFormView(false)}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {/* <Typography variant="h6">Profile</Typography> */}

          <input
            type="file"
            id="file"
            onChange={(e) => uploadImage(e.target.files[0])}
            ref={inputFile}
            style={{ display: "none" }}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {/* <FileUploader
            name="file"
            types={["JPG", "PNG"]}
            handleChange={(e) => console.log(e.target.files[0])}
            children={
              <>
              </>
            }
          /> */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Box sx={{ backgroundColor: "#fff", borderRadius: "50%" }}>
                  {customer?.platform === "facebook" ? (
                    <Messenger height={18} width={18} />
                  ) : customer?.platform === "instagram" ? (
                    <InstagramSvg height={20} width={20} />
                  ) : customer?.platform === "whatsapp" ? (
                    <Whatsapp height={18} width={18} />
                  ) : (
                    ""
                  )}
                </Box>
              }
            >
              <Button
                style={{
                  height: "7rem",
                  width: "7rem",
                  alignSelf: "center",
                  margin: "14px auto",
                  marginTop: "0px",
                  // display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "50%",
                  overflow: "hidden",
                  backgroundColor: "#ededed",
                  boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.2)",
                }}
                sx={{ p: 0 }}
                onClick={() => inputFile.current.click()}
              >
                <Box
                  style={{ alignSelf: "center", margin: "auto" }}
                  sx={{}}
                  color="GrayText"
                >
                  {!imageURL && !uploadingImage ? (
                    <>
                      <AddPhotoAlternateOutlined
                        style={{ height: "26px", width: "26px" }}
                      />
                      <Typography
                        variant="body2"
                        fontSize={11}
                        fontWeight="bold"
                      >
                        Upload photo
                      </Typography>
                    </>
                  ) : null}
                  {imageURL && !uploadingImage ? (
                    <img
                      src={
                        imageURL
                        // "https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                      }
                      style={{
                        height: "8rem",
                        width: "8rem",
                        // zIndex: -10,
                        // position: "relative",
                      }}
                    />
                  ) : null}
                  {uploadingImage ? (
                    <Box sx={{}}>
                      <CircularProgress size="18px" />
                      <Typography
                        variant="body2"
                        fontSize={12}
                        color="primary"
                        fontWeight="bold"
                      >
                        Uploading photo
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Button>
            </Badge>
            {/* <Typography variant="body2" color="text.secondary" fontSize={12}>
              Allowed *.jpeg, *.jpg, *.png
            </Typography>
            <Typography variant="body2" color="text.secondary" fontSize={12}>
              max size of 2 MB
            </Typography> */}
          </div>
          <Grid container spacing={3}>
            {inputArray.map((input) => (
              <Grid
                item
                xs={6}
                key={input.name}
                sx={{ alignItems: "center" }}
                {...(input?.props || {})}
              >
                <Label>{input.label}</Label>
                <TextField
                  size="small"
                  fullWidth
                  name={input.name}
                  // placeholder={input.label}
                  onChange={inputChangeHandler}
                  value={inputData[`${input.name}`] || ""}
                />
              </Grid>
            ))}
            {!whatsapp ? (
              <Grid item xs={12}>
                <Label>Contact type</Label>
                <TextField
                  size="small"
                  // label="Adress Line 1"
                  name="contact_type"
                  fullWidth
                  onChange={inputChangeHandler}
                  value={inputData?.contact_type || ""}
                  select
                >
                  <MenuItem value="customer">Customer</MenuItem>
                  <MenuItem value="lead">Lead</MenuItem>
                </TextField>
              </Grid>
            ) : null}
            <Grid item xs={12} sx={{ alignItems: "center" }}>
              <Grid container alignItems="center">
                <Label>Mobile</Label>
                <Grid container spacing={2}>
                  <Grid item xs={3.5}>
                    <Select
                      size="small"
                      disabled={customerInfo?.billing?.dialcode_mobile}
                      fullWidth
                      // value={inputData.dialcode_mobile}
                      value={`${
                        typeof inputData.dialcode_mobile === "string"
                          ? inputData.dialcode_mobile
                          : inputData?.dialcode_mobile?.value
                      }`}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInputData((pre) => ({
                          ...pre,
                          dialcode_mobile: `+${e.target.value?.value}`,
                        }));
                      }}
                      renderValue={(value) => `${inputData.dialcode_mobile}`}
                      // renderValue={(value) => `${typeof value === "string" ? value : inputData.dialcode_mobile}`}
                    >
                      <ListSubheader>Common Dial Codes</ListSubheader>
                      {commonDialCodes.map((i) => {
                        return (
                          <MenuItem key={i.value} value={i}>
                            {i.label}
                          </MenuItem>
                        );
                      })}
                      <ListSubheader>Dial Codes</ListSubheader>
                      {dialCodes.map((i) => (
                        <MenuItem value={i}>{i.label}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextField
                      size="small"
                      name="phone"
                      fullWidth
                      type="number"
                      // placeholder={"Mobile"}
                      disabled={customerInfo?.billing?.phone}
                      onChange={inputChangeHandler}
                      value={inputData.phone}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {whatsapp ? (
              <>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Email</Label>
                  <TextField
                    size="small"
                    // label="Adress Line 1"
                    name="email"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.email || ""}
                  />
                </Grid>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Company name</Label>
                  <TextField
                    size="small"
                    // label="Adress Line 2"
                    name="company_name"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.company_name || ""}
                    // onChange={(e) => {
                    //   onEditCustomer({
                    //     billing: {
                    //       ...customerInfo.billing,
                    //       address_2: e.target.value,
                    //     },
                    //   });
                    // }}
                    // value={customerInfo.billing.address_2 || ""}
                  />
                </Grid>

                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Contact type</Label>
                  <TextField
                    size="small"
                    // label="Adress Line 1"
                    name="contact_type"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.contact_type || ""}
                    select
                  >
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="lead">Lead</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Team member assign</Label>
                  <TextField
                    size="small"
                    select
                    // label="Adress Line 1"
                    name="team_member"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.team_member || ""}
                  >
                    <MenuItem value="">None</MenuItem>
                    {isArrayWithValues(reduxTeamMembers) &&
                      reduxTeamMembers?.map((obj) => (
                        <MenuItem value={obj?.id}>
                          {obj?.first_name} {obj?.last_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    startIcon={
                      showAdditionalInfoWhatsapp ? <Remove /> : <Add />
                    }
                    onClick={() =>
                      setShowAdditionalInfoWhatsapp((state) => !state)
                    }
                  >
                    Additional Info
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={showAdditionalInfoWhatsapp}>
                    <Grid container spacing={3}>
                      <Grid item xs={isPhone ? 12 : 6}>
                        <Label>Address Line 1</Label>
                        <TextField
                          size="small"
                          // label="Adress Line 1"
                          name="address_1"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData?.address_1 || ""}
                        />
                      </Grid>
                      <Grid item xs={isPhone ? 12 : 6}>
                        <Label>Address Line 2</Label>
                        <TextField
                          size="small"
                          // label="Adress Line 2"
                          name="address_2"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData?.address_2 || ""}
                          // onChange={(e) => {
                          //   onEditCustomer({
                          //     billing: {
                          //       ...customerInfo.billing,
                          //       address_2: e.target.value,
                          //     },
                          //   });
                          // }}
                          // value={customerInfo.billing.address_2 || ""}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>City</Label>
                        <TextField
                          size="small"
                          // label="City"
                          name="city"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData?.city || ""}
                          // onChange={(e) => {
                          //   onEditCustomer({
                          //     billing: {
                          //       ...customerInfo.billing,
                          //       city: e.target.value,
                          //     },
                          //   });
                          // }}
                          // value={customerInfo.billing.city || ""}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>Post Code</Label>
                        <TextField
                          size="small"
                          // label="Post Code"
                          name="postcode"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData?.postcode || ""}
                          // onChange={(e) => {
                          //   onEditCustomer({
                          //     billing: {
                          //       ...customerInfo.billing,
                          //       postcode: e.target.value,
                          //     },
                          //   });
                          // }}
                          // value={customerInfo.billing.postcode || ""}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>Country</Label>
                        <Autocomplete
                          id="combo-box-demo"
                          options={countriesArray || []}
                          size="small"
                          name="country"
                          onChange={(e, newInputValue) => {
                            setAddressSateCode((pre) => ({
                              ...pre,
                              country: newInputValue?.label,
                            }));
                            inputChangeHandler(e, newInputValue?.code);
                          }}
                          value={
                            addressStateCode?.country ||
                            inputData?.country ||
                            ""
                          }
                          // value={country}
                          // onChange={(event, newInputValue) => {
                          //   setCountry(newInputValue.label);
                          //   setCountryCode(newInputValue.code);
                          //   onEditCustomer({
                          //     billing: {
                          //       ...customerInfo.billing,
                          //       country: newInputValue.code,
                          //     },
                          //   });
                          // }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField name="country" {...params} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Label>State</Label>
                        <Autocomplete
                          size="small"
                          id="combo-box-demo"
                          name="state"
                          options={states || []}
                          value={
                            addressStateCode?.state || inputData?.state || ""
                          }
                          onChange={(e, newInputValue) => {
                            setAddressSateCode((pre) => ({
                              ...pre,
                              state: newInputValue?.label,
                            }));
                            inputChangeHandler(e, newInputValue?.code);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField name="state" {...params} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </>
            ) : null}
            {!whatsapp && (
              <>
                <Grid item xs={12} sx={{ alignItems: "center" }}>
                  <Grid container alignItems="center">
                    <Label>whatsapp</Label>
                    <Grid container spacing={2}>
                      <Grid item xs={3.5}>
                        <Select
                          size="small"
                          fullWidth
                          value={inputData.dialcode_whatsapp}
                          onChange={(e) => {
                            setInputData((pre) => ({
                              ...pre,
                              dialcode_whatsapp: `+${e.target.value}`,
                            }));
                          }}
                          renderValue={(value) =>
                            `${inputData.dialcode_whatsapp}`
                          }
                        >
                          <ListSubheader>Common Dial Codes</ListSubheader>
                          {commonDialCodes.map((i) => {
                            return (
                              <MenuItem key={i.value} value={i.value}>
                                {i.label}
                              </MenuItem>
                            );
                          })}
                          <ListSubheader>Dial Codes</ListSubheader>
                          {dialCodes.map((i) => (
                            <MenuItem value={i.value}>{i.label}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={8.5}>
                        <TextField
                          size="small"
                          name="whatsapp"
                          // placeholder="WhatsApp"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData.whatsapp}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Grid item xs={12} sx={{ alignItems: "center" }}>
              <Label>{"Unique ID"}</Label>
              <TextField
                size="small"
                fullWidth
                name={"unique_id"}
                // placeholder={input.label}
                onChange={inputChangeHandler}
                value={inputData[`unique_id`]}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <Collapse in={Boolean(error)}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              mt: 1,
              color: "error.main",
              textAlign: "center",
              margin: "0px 20px",
            }}
          >
            {error}
          </Typography>
        </Collapse>
        <DialogActions>
          <StackRow>
            <Button
              onClick={() => {
                setAddFormView(false);
                setInputData(defaultState);
                setImageURL("");
                setLoading(false);
                setCreate && setCreate(false);
              }}
              size="large"
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={error === limitError}
              onClick={
                create ? addCustomer : whatsapp ? AddWhatsapp : addCustomer
              }
              size="large"
            >
              {create ? "Add" : isObjWithValues(customer) ? "Update" : "Add"}
            </LoadingButton>
          </StackRow>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AddCustomer);

export const createCustomer = async (data) => {
  let website = getWebsite();
  let token = getToken();
  let headers = {
    Authorization: `Basic ${token}`,
  };
  let reqOptions = {
    url: `${website}/wp-json/store/v1/customer/create`,
    method: "POST",
    headers,
    data,
  };
  try {
    const response = await axios.request(reqOptions);
    return response.data;
  } catch (error) {
    error = getAxiosError(error);
    return error;
  }
};

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;
