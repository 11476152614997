import { Typography, TextField, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { filterStyles } from "../styles/filterStyles";

const styles = filterStyles

const FilterHeader = ({ handleClose, handleCheck, title, setTitle, titleError, }) => {

    return ( 
        <Box sx={styles.topCard}>
        <Box sx={styles.innerCard}>
          <Box sx={styles.userHeader}>
            <FilterAltOutlinedIcon />
            <Typography variant="span" style={styles.headerTitle}>
              FILTER
            </Typography>
          </Box>
          <Box style={styles.headerIconsMainDiv}>
            <IconButton style={styles.headerIconsDiv} onClick={handleClose}>
              <CloseIcon style={styles.headerIcons} />
            </IconButton>
            <IconButton style={styles.headerIconsDiv} onClick={handleCheck}>
              <CheckIcon style={styles.headerIcons} />
            </IconButton>
          </Box>
        </Box>

        <TextField
          placeholder="Type block title"
          size="small"
          style={styles.titleTextField}
          onChange={(e) => setTitle(e.target.value)}
          error={titleError}
          value={title}
          required
          inputProps={{
            maxLength: 40
          }}
        />
      </Box>
    );
}
 
export default FilterHeader;