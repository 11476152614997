import { useEffect, useRef, useState } from "react";
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
  DynamicDataSheetGrid,
} from "react-datasheet-grid";
import * as XLSX from "xlsx";
// Import the style only once in your app!
import "react-datasheet-grid/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertDataToDatasheetColumn,
  getDefaultCustomerObject,
  getDropdownData,
  validateCreatedUpdatedProducts,
  validateProducts,
  //   columns as allCRMColumns,
  getColumns,
  validateCustomers,
  getCustomerKeysMapping,
} from "./utils";
import "../index.css";
import {
  ResponsiveStackRow,
  addFileAws3,
  backendApiCall,
  backendApiEndpoints,
  formatDate,
  getAdminName,
  getAuthToken,
  getEmail,
  getId,
  getMetaObject,
  getMetaValueFromProduct,
  getRandomString,
  getStoreId,
  getStoreName,
  getTenantId,
  getTimeInMilliSeconds,
  getToken,
  getWebSocketUrl,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isObjectEqual,
  isOurWebsite,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";
import { createSpreadSheetData } from "./helper";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  getProductObjectFromSheetArray,
  updateCustomersFromSheet,
} from "./helper/updateData";
import { LoadingButton } from "@mui/lab";
import {
  ArrowBackIos,
  ErrorOutline,
  Sync,
  ViewWeekOutlined,
} from "@material-ui/icons";
import UpdateDialog from "../helper/UpdateDialog";
import { fetchTaxonomies } from "../../../redux/actions/productActions";
import ColumnsPopover, {
  defaultSelectedColumns,
} from "../helper/ColumnsPopover";
import ErrorsPopover from "../helper/ErrosPopover";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { editDataSheet } from "../../../redux/actions/datasheetActions";
import { getIp, sidebarExtraWidth } from "../../../App";
import {
  findIncomeBracket,
  removeFalsyValuesFromObject,
} from "../../../utils/js";
import { fetchCustomers } from "../../../redux/actions/crmActions";
import { setPersistData } from "../../../redux/actions/persistActions";
import {
  bulkActionChats,
  putMergeProfiles,
} from "../../../redux/actions/chatActions";
import ImportDialog from "../product/components/ImportDialog";
import ExportDialog from "../product/components/ExportDialog";
import axios from "axios";
import { UAParser } from "ua-parser-js";
import JweroSyncHistory from "../../products/components/JweroSyncHistory";
import {
  ArrowBackIosNew,
  ArrowDownward,
  ArrowUpward,
  History,
} from "@mui/icons-material";
import { EDIT_SETTINGS } from "../../../redux/actions/settingActions";
import {
  originalCustomerHeader,
  validateSheetHeaders,
} from "../helper/validateSheetHeaders";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const CRMDataSheet = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const updateDialogRef = useRef();
  const columnsPopoverRef = useRef();
  const errorsPopoverRef = useRef();
  const [loadingText, setLoadingText] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [backupRestored, setBackupRestored] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [showSync, setShowSync] = useState(false);
  const inputRef = useRef(null);
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const jweroSheetSync = useSelector(
    (state) => state?.settings?.jweroSheetSync
  );
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const storePlan = useSelector((state) => state.user.store_plan);
  const storeUsed = useSelector((state) => state.user.store_used);
  const selectedCRMColumns = useSelector(
    (state) => state.persist.selectedCRMColumns
  );

  const [cellhasError, setCellHasError] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    // [`group_name_${getStoreId()}`]: true,
    // ...defaultSelectedColumns.crm,
    ...(selectedCRMColumns || {}),
  });
  const selectedEditCustomers = useSelector(
    (state) => state.datasheet.customers_edit
  );
  const products_backup = useSelector(
    (state) => state.datasheet.products_backup
  );
  const create_customers_sheet_backup = useSelector(
    (state) => state.datasheet.create_customers_sheet_backup
  );
  const edit_customers_sheet_backup = useSelector(
    (state) => state.datasheet.edit_customers_sheet_backup
  );
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);

  const [defaultCustomerObject, setDefaultCustomerObject] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const location = useLocation();
  const datasheetRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [remainingProducts, setRemainingProducts] = useState(0);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [CRMCustomFields, setCRMCustomFields] = useState([]);
  const [dropdowns, setDropdowns] = useState([]);
  const [exporting, setExporting] = useState(false);
  const occupationsArray = useSelector((state) => state.persist.occupations);
  const countries = useSelector((state) => state.persist.countries);
  const states = useSelector((state) => state.persist.states);
  let branches = useSelector((state) => state.settings.branch);
  let showErrorTimeout,
    backupTimeout = useRef(),
    savingTimeout = useRef();
  const allCustomerGroup = useSelector((state) => state.customers.crmGrouping);
  const productDefaults = useSelector(
    (state) => state.settings.productDefaults
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [saving, setSaving] = useState(false);
  const closeSync = () => {
    setShowSync(false);
  };
  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    setCRMCustomFields(Object.values(custom_fields?.crm));
  }, [custom_fields]);

  useEffect(() => {
    if (backupRestored) return;
    if (
      isUpdate &&
      isArrayWithValues(edit_customers_sheet_backup) &&
      !isArrayWithValues(selectedEditCustomers)
    ) {
      setData(edit_customers_sheet_backup);
      setBackupRestored(true);
    } else if (isArrayWithValues(create_customers_sheet_backup)) {
      setData(create_customers_sheet_backup);
      setBackupRestored(true);
    }
  }, [
    location.pathname,
    create_customers_sheet_backup,
    edit_customers_sheet_backup,
    isUpdate,
  ]);

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());

    const handleBeforeUnload = (event) => {
      // if (isDev()) return;
      event.preventDefault();

      event.returnValue = ""; // Chrome requires this
      let key = isUpdate
        ? "edit_customers_sheet_backup"
        : "create_customers_sheet_backup";
      dispatch(editDataSheet({ [key]: null }));
      setIsDataSaved(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // window.removeEventListener("popstate", handleBeforeUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // if (!reduxOrnateProducts) dispatch(fetchOrnateProducts());
    if (
      isObjWithValues(selectedCRMColumns)
      // &&
      // Object.keys(selectedColumns).length !==
      //   Object.keys(selectedCRMColumns)?.length
    )
      setSelectedColumns(selectedCRMColumns);
    // console.log("run useEffect :", selectedCRMColumns);
  }, [isObjWithValues(selectedCRMColumns)]);

  useEffect(() => {
    dispatch(setPersistData({ selectedCRMColumns: selectedColumns }));
  }, [selectedColumns]);

  useEffect(() => {
    if (backupTimeout.current) clearTimeout(backupTimeout.current);
    backupTimeout.current = setTimeout(() => {
      let key = isUpdate
        ? "edit_customers_sheet_backup"
        : "create_customers_sheet_backup";
      dispatch(editDataSheet({ [key]: data }));
      setSaving(true);
      if (savingTimeout.current) clearTimeout(savingTimeout.current);
      savingTimeout.current = setTimeout(() => {
        setSaving(false);
      }, 1500);
    }, 5000);

    return () => {
      if (savingTimeout.current) clearTimeout(savingTimeout.current);
      if (backupTimeout.current) clearTimeout(backupTimeout.current);
    };
  }, [data]);
  // useEffect(() => {
  //   if (backupTimeout) clearTimeout(backupTimeout);
  //   backupTimeout = setTimeout(() => {
  //     dispatch(editDataSheet({ products_backup: data }));
  //   }, 5000);
  // }, [data]);

  useEffect(() => {
    let { add_products, catalogs } = storePlan || {};
    let { products, catalogs: catalogs_created } = storeUsed || {};
    setRemainingProducts(
      validateNumber(add_products) - validateNumber(products)
    );
    // if (products && add_products) setAddLimitReached(products >= add_products);
    // if (catalogs && catalogs_created)
    //   setCatalogsLimitReached(catalogs_created >= catalogs);
  }, [storePlan, storeUsed]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());
    // if (!reduxOrnateProducts) dispatch(fetchOrnateProducts());
  }, []);

  useEffect(() => {
    if (location.pathname?.includes("crm_bulk_add")) {
      let defaultObj = getDefaultCustomerObject({});
      if (defaultObj) {
        setDefaultCustomerObject(defaultObj);
        if (
          remainingProducts &&
          !isArrayWithValues(create_customers_sheet_backup)
        )
          setData(new Array(50000).fill(defaultObj));
      }
    }
  }, [productDefaults, taxonomies, remainingProducts]);
  useEffect(() => {
    let dropdowns = getDropdownData({
      professions: occupationsArray,
      countries: countries,
      branches,
      allCustomerGroup,
    });
    setDropdowns(dropdowns);
    setColumns(
      convertDataToDatasheetColumn({
        dropdowns,
        selectedColumns,
        disableSKU: isUpdate,
        custom_fields,
      })
    );
  }, [
    productSettings,
    masterPricing,
    taxonomies,
    branches,
    allCustomerGroup,
    selectedColumns,
  ]);

  useEffect(() => {
    setIsUpdate(location.pathname?.includes("crm_bulk_edit"));
    if (
      isArrayWithValues(selectedEditCustomers) &&
      location.pathname?.includes("crm_bulk_edit")
    ) {
      setData(
        createSpreadSheetData({
          customers: selectedEditCustomers,
          states,
          allCustomerGroup,
          CRMCustomFields,
        })
      );
    }
  }, [selectedEditCustomers]);

  const onUpdate = async () => {
    // let _data = isDev() ? talwarsData : data;
    let _data = data;

    let filteredData = _data?.filter(
      (i) =>
        !isObjectEqual(i, defaultCustomerObject) &&
        isObjWithValues(removeFalsyValuesFromObject(i))
    );

    if (!isArrayWithValues(filteredData)) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `No details added`,
          severity: "error",
        })
      );
    }
    let invalidCustomers = validateCustomers({
      columns,
      customers: filteredData,
      states,
      defaultCustomerObject,
      CRMCustomFields,
      countries: countries,
    });

    setErrors(invalidCustomers);
    // if (!isDev())
    if (isArrayWithValues(invalidCustomers))
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Please resolve issues before ${
            isUpdate ? "updating" : "creating"
          } customers`,
          severity: "error",
        })
      );
    // }
    if (!isArrayWithValues(filteredData))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "No customers to create",
          severity: "error",
        })
      );
    let customersToUpdate = [];
    try {
      filteredData?.forEach((customer) => {
        let obj = getProductObjectFromSheetArray({
          customer,
          states,
          countries,
          CRMCustomFields,
          dropdowns: dropdowns,
        });

        customersToUpdate.push(obj);
      });
    } catch (error) {
      console.log(error);
      return { error: true };
    }
    if (isOurWebsite() || true) {
      setLoading(true);
      let time = formatDate(new Date(), "DD/MM/YYYY HH:mm")?.replaceAll(
        "/",
        "-"
      );
      const jsonString = JSON.stringify(customersToUpdate);
      const blob = new Blob([jsonString], {
        type: "application/json",
        name: `CRM-SYNC-${time}.json`,
      });
      blob.name = `CRM-SYNC-${time}.json`;

      let fileRes = await addFileAws3({
        params: {
          path: "jwero_sheets",
        },
        image: blob,
      });

      // return setLoading(false);
      try {
        let { data: res } = await axios({
          url: `${getWebSocketUrl()}/import/json/customers`,
          headers: {
            Authorization: `Basic ${getToken()}`,
          },
          method: "POST",
          data: {
            // json: customersToUpdate,
            file_path: fileRes?.file_path,
            website: getWebsite(),
            // should_map_image: should_map,
            ip: await getIp(),
            ua: new UAParser().getUA(),
            isAdmin: isAdmin,
            by: getId(),
            newAuthToken: getAuthToken(),
          },
        });
        if (res?.success) {
          setLoading(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Sync started Successfully!",
              severity: "success",
            })
          );
          dispatch(
            editDataSheet({
              edit_customers_sheet_backup: null,
              create_customers_sheet_backup: null,
            })
          );

          dispatch({
            type: EDIT_SETTINGS,

            payload: {
              jweroSheetSync: {
                ...(jweroSheetSync || {}),
                ...(res?.data || {}),
              },
            },
          });
          setData([]);
          dispatch(editDataSheet({ create_customers_sheet_backup: null }));

          navigate("/crm#open");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }

      return;
    }
    updateDialogRef.current?.open();

    try {
      if (!isDev())
        await backendApiCall({
          endpoint: `${backendApiEndpoints.settings}/crm_sheet_sample_data`,
          method: "PUT",
          data: {
            data: {
              [getTimeInMilliSeconds()]: {
                sheet_data: _data,
              },
            },
          },
        });
    } catch (error) {}
    let {
      updatedProducts,
      error,
      // invalidCustomers: productsWithErrors,
      invalidProducts: productsWithErrors,
      updatedCustomers,
    } = await updateCustomersFromSheet({
      customers: filteredData,
      setLoading,
      setProgress,
      allCustomersWithId: selectedEditCustomers,
      setLoadingText,
      isUpdate,
      states,
      CRMCustomFields,
    });
    try {
      if (!isDev())
        await backendApiCall({
          endpoint: `${backendApiEndpoints.settings}/crm_sheet_sample_data`,
          method: "PUT",
          data: {
            data: {
              [getTimeInMilliSeconds()]: {
                updating_data: filteredData,
                updated_data: updatedCustomers,
                errors: productsWithErrors,
                sheet_data: data,
              },
            },
          },
        });
    } catch (error) {}
    let createChatsArray = [];
    let merge_profilesObject = {};
    let actionKey = isUpdate ? "update" : "create";
    // let actionKey = "create";
    /// create in chats
    if (isUpdate) {
      updatedCustomers?.forEach((customer) => {
        let whatsapp_id = getMetaValueFromProduct(customer, "whatsapp_id");

        let contact_type = getMetaObject({
          meta_data: customer?.meta_data,
          key: "contact_type",
        });
        let company_name = getMetaObject({
          meta_data: customer?.meta_data,
          key: "company_name",
        });
        if (whatsapp_id) {
          let { first_name, last_name, email } = customer;
          let chatObj = {
            id: whatsapp_id,
            first_name,
            last_name,
            email,
            contact_type,
            company_name,
          };
          createChatsArray.push(chatObj);
        }
      });
    } else {
      updatedCustomers?.forEach((customer) => {
        let platform_id = "";
        let dialcode_mobile = getMetaObject({
          meta_data: customer?.meta_data,
          key: "dialcode_mobile",
        });
        let dialcode_whatsapp = getMetaObject({
          meta_data: customer?.meta_data,
          key: "dialcode_whatsapp",
        });
        let whatsapp = getMetaObject({
          meta_data: customer?.meta_data,
          key: "whatsapp",
        });
        let contact_type = getMetaObject({
          meta_data: customer?.meta_data,
          key: "contact_type",
        });
        let company_name = getMetaObject({
          meta_data: customer?.meta_data,
          key: "company_name",
        });

        let chatPayload = {
          platform_id,
          first_name: customer?.first_name,
          last_name: customer?.last_name,
          // dialcode_whatsapp: customer?.billing?.phone,
          // whatsapp: dialcode_mobile,
          contact_type,
          company_name,
          email: customer?.email,
        };
        if (whatsapp && dialcode_whatsapp) {
          platform_id = `${dialcode_whatsapp}${whatsapp}`;
          chatPayload.dialcode_whatsapp = dialcode_whatsapp;
          chatPayload.whatsapp = whatsapp;
        }
        if (
          !(whatsapp && dialcode_whatsapp) &&
          dialcode_mobile &&
          customer?.billing?.phone
        ) {
          platform_id = `${dialcode_mobile}${customer?.billing?.phone}`;
          chatPayload.dialcode_whatsapp = dialcode_mobile;
          chatPayload.whatsapp = customer?.billing?.phone;
        }
        if (platform_id) {
          chatPayload.platform_id = platform_id;
          let id = `${getTimeInMilliSeconds()}_${getRandomString(4)}`;
          merge_profilesObject[id] = {
            id,
            crm_id: customer?.id,
            profiles: [
              {
                id: platform_id,
                platform: "whatsapp",
              },
            ],
          };
          createChatsArray.push(chatPayload);
        }
      });
    }
    if (isArrayWithValues(createChatsArray)) {
      dispatch(
        bulkActionChats({
          data: {
            ["create"]: createChatsArray,
            // [actionKey]: createChatsArray,
          },
          params: {
            is_platform_id: true,
          },
        })
      );
    }
    // create merge profile in setting
    if (isObjWithValues(merge_profilesObject)) {
      dispatch(putMergeProfiles(merge_profilesObject));
    }

    updateDialogRef.current?.close();
    if (error) {
      setLoading(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to ${isUpdate ? "update" : "create"} customers`,
          severity: "error",
        })
      );
    }
    if (isArrayWithValues(productsWithErrors)) {
      let invalidCustomersData = _data?.filter((i) =>
        productsWithErrors?.find((j) => j.sku === i.sku)
      );
      setData(invalidCustomersData);
      let errors = validateCreatedUpdatedProducts({
        products: productsWithErrors,
        columns,
        isUpdate,
      });
      if (errors) setErrors(errors);
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Customers has been ${
            isUpdate ? "updated" : "created"
          } successfully`,
          severity: "success",
        })
      );
      let key = isUpdate
        ? "edit_customers_sheet_backup"
        : "create_customers_sheet_backup";
      dispatch(editDataSheet({ [key]: null }));
      dispatch(
        fetchCustomers(
          {
            page: 1,
            per_page: 100,
            orderby: "registered_date",
            order: "desc",
          },
          true
        )
      );
      // navigate("/crm");
    }
  };

  const navigateError = (obj) => {
    datasheetRef.current?.setActiveCell({
      col: obj.column,
      row: obj.row,
    });
    setCellHasError(true);
    errorsPopoverRef.current?.close();
    if (obj.message)
      dispatch(
        setGlobalToast({
          show: true,
          message: obj.message,
          severity: "info",
        })
      );
    if (showErrorTimeout) clearTimeout(showErrorTimeout);
    showErrorTimeout = setTimeout(() => {
      setCellHasError(false);
    }, 2000);
  };

  const onChangeFile = (event) => {
    // const file = event.target.files[0];
    // console.log(file, "<<file");
    const file = event.target.files[0];
    if (file) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Importing Data...",
          // severity: "success",
        })
      );
      setOpenImport(false);
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];

          const csvArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const firstRow = csvArray[0];
          if (
            !validateSheetHeaders({
              original: originalCustomerHeader,
              sheetHeaders: firstRow,
            })
          ) {
            return window.alert("This is not valid Sheet !");
          }
          dispatch(
            setGlobalToast({
              show: true,
              message: "Importing Data...",
              // severity: "success",
            })
          );

          const excelData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName]
          );
          let customerData = [];
          let customer_keys_mapping = getCustomerKeysMapping(getStoreId());

          excelData?.forEach((obj) => {
            let customer = { ...obj };

            let properCustomer = {};
            Object.entries(customer)?.forEach(([key, value]) => {
              let _key = Object.keys(customer_keys_mapping)?.find(
                (k) => customer_keys_mapping[k]?.column_name === key
              );

              if (_key === "dialcode_mobile" || _key === "dialcode_whatsapp")
                properCustomer[_key] = value?.toString();
              else if (
                _key === `group_name_${getStoreId()}` &&
                typeof value === "string"
              ) {
                properCustomer[_key] = value?.split(",");
              } else if (_key === "annual_income" && validateNumber(value)) {
                properCustomer[_key] = findIncomeBracket(value);
              } else if (dropdowns?.[_key] && value) {
                let array = dropdowns?.[_key];
                array?.forEach((i) => {
                  let { value: dropdownValue } = i;
                  if (
                    typeof value === "string" &&
                    value?.toLowerCase() === dropdownValue.toLowerCase()
                  )
                    properCustomer[_key] = dropdownValue;
                });
                // let obj =
                // properCustomer[_key] = findIncomeBracket(value);
              } else properCustomer[_key] = value;

              if (value && !properCustomer[_key]) properCustomer[_key] = value;
              // console.log(properCustomer?.["group_name_27"], "<properCustomer");
            });

            customerData.push(properCustomer);
          });
          let defaultObj = getDefaultCustomerObject({});
          let length = customerData?.length;
          let remainingRows = 50000 - length;
          let newArrTotal = customerData.concat(
            new Array(remainingRows)?.fill(defaultObj)
          );
          setData(newArrTotal);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Imported file successfully",
              severity: "success",
            })
          );
          // setJsonData(excelData);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
      }
    }
    inputRef.current.value = "";
  };

  const onCloseImport = () => {
    setOpenImport(false);
  };
  const onCloseExport = () => {
    setOpenExport(false);
  };

  const onDownloadTemplate = ({ data, fileName, fileType }) => {
    let header = {};
    columns?.forEach((obj) => {
      header[obj?.label] = "";
    });
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadCsv = () => {
    let customer_keys_mapping = getCustomerKeysMapping(getStoreId());
    let arr = Object.keys(customer_keys_mapping)?.map((key) => ({
      label: customer_keys_mapping?.[key]?.column_name,
      value: key,
    }));
    onDownloadTemplate({
      data: [
        arr?.map((o) => o?.label)?.join(","),
        arr?.map((o) => "")?.join(","),
      ]?.join("\n"),
      fileName: "Jwero Customer Sheet.csv",
      fileType: "text/csv",
    });
  };
  const onExport = async () => {
    setExporting(true);
    try {
      let { data: res } = await axios({
        url: `${getWebSocketUrl()}/sheets/customers`,
        method: "POST",
        headers: {
          Authorization: `Basic ${getToken()}`,
        },
        data: {
          store_id: getStoreId(),
          website: getWebsite(),
          newAuthToken: getAuthToken(),
          tenantId: getTenantId(),
          email: getEmail(),
          storeName: getStoreName(),
          userName: getAdminName(),
          ip: await getIp(),
          ua: new UAParser().getUA(),
          isAdmin: isAdmin,
          by: getId(),
        },
      });
      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Sheet Exporting started, please check your email",
            severity: "success",
          })
        );
        if (isObjWithValues(res?.data))
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              jweroSheetSync: {
                ...(jweroSheetSync || {}),
                ...(res?.data || {}),
              },
            },
          });
        navigate("/crm#open");
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Sheet Exporting Failed",
          severity: "error",
        })
      );
    } finally {
      setExporting(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
        pr: 3,
        pl: 1,
      }}
    >
      <JweroSyncHistory
        type={"customer"}
        showSync={showSync}
        closeSync={closeSync}
      />
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept="text/csv"
        onChange={onChangeFile}
      />
      {/* <Prompt
        when={!isDataSaved}
        message="Are you sure you want to leave? Your changes will be lost."
      /> */}
      {/* <Box sx={{ mx: 2, justifyContent: "space-between",  }}>
      </Box> */}
      {/* <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
        pb={3}
      >
        <Typography sx={{ ...primaryLabelLarge }}>
          {!isUpdate ? "Create" : "Edit"} customers
        </Typography>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Collapse in={isArrayWithValues(errors)}>
            <IconButton
              variant="text"
              onClick={(e) => errorsPopoverRef?.current?.open(e)}
              // startIcon={<ViewWeekOutlined />}
              color="error"
              // size="small"
            >
              <Badge badgeContent={4} color="error" variant="dot">
                <ErrorOutline />
              </Badge>
            </IconButton>
          </Collapse>

          <Button
            variant="text"
            onClick={(e) => columnsPopoverRef?.current?.open(e)}
            startIcon={<ViewWeekOutlined />}
          >
            Columns
          </Button>
          <LoadingButton
            sx={{}}
            variant="contained"
            onClick={onUpdate}
            // disabled={loading}
            loading={loading}
            // startIcon={
            //   loading ? (
            //     <CircularProgress style={{ height: "15px", width: "15px" }} />
            //   ) : (
            //     <Sync style={{ height: "17px", width: "17px" }} />
            //   )
            // }
          >
            {isUpdate ? "Update" : "Create"} customers
          </LoadingButton>
        </Stack>
      </Stack> */}
      <ResponsiveStackRow
        firstElement={
          <Stack direction={"row"} alignItems={"start"} gap={3}>
            {/* <Button
              startIcon={<ArrowBackIosNew />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button> */}
            <IconButton
              onClick={() => {
                navigate(-1);
                navigate("/crm");
              }}
            >
              <ArrowBackIosNew />
            </IconButton>

            <Box>
              <Typography sx={{ ...primaryLabelLarge, fontSize: "20px" }}>
                {/* {!isUpdate ? "Create" : "Edit"} products */}
                Jwero Sheets
                {/* (Beta) */}
              </Typography>
              <Typography
                sx={{
                  ...secondaryLabelSmall,
                  mt: 0.2,
                  // textAlign: "right",
                  color: "primary",
                }}
              >
                {/* {!isUpdate ? "Create" : "Edit"} customers */}
              </Typography>
            </Box>
            <Button
              sx={{ color: saving ? "primary.main" : "text.secondary" }}
              endIcon={<Sync />}
              disabled={saving}
            >
              {saving ? "Auto Saving" : "Auto Saved"}
            </Button>
          </Stack>
        }
        secondElement={
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <IconButton
              variant="text"
              onClick={(e) => errorsPopoverRef?.current?.open(e)}
              // startIcon={<ViewWeekOutlined />}
              // color={isArrayWithValues(errors) ? "secondary" : "error"}
              sx={{
                color: isArrayWithValues(errors)
                  ? "secondary.main"
                  : "error.main",
              }}
              disabled={!isArrayWithValues(errors)}
              // size="small"
            >
              <Badge
                badgeContent={isArrayWithValues(errors) ? 1 : 0}
                color="error"
                variant="dot"
              >
                <ErrorOutline />
              </Badge>
            </IconButton>
            {/* </Fade> */}

            {(isOurWebsite() || true) && (
              <IconButton onClick={() => setShowSync(true)}>
                <History />
              </IconButton>
            )}

            <Button
              variant="text"
              onClick={(e) => columnsPopoverRef?.current?.open(e)}
              startIcon={<ViewWeekOutlined />}
              sx={{
                backgroundColor: "#ffffff",
                padding: "6px 18px",
              }}
            >
              Columns
            </Button>
            {(isOurWebsite() || true) && (
              <>
                <Button
                  // variant="contained"
                  onClick={() => {
                    // inputRef?.current?.click();
                    setOpenImport(true);
                  }}
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: "6px 18px",
                  }}
                  startIcon={<ArrowDownward />}
                >
                  Import
                </Button>

                <LoadingButton
                  loading={exporting}
                  // sx={{
                  //   // backgroundColor: "text.secondary",
                  //   backgroundColor: LoadingButtonColor,
                  // }}
                  startIcon={<ArrowUpward />}
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: "6px 18px",
                  }}
                  onClick={() => onExport()}
                >
                  Export
                </LoadingButton>
              </>
            )}

            {/* <Fade in={isArrayWithValues(errors)}> */}

            <LoadingButton
              sx={{}}
              variant="contained"
              onClick={onUpdate}
              // disabled={loading}
              loading={loading}

              // startIcon={
              //   loading ? (
              //     <CircularProgress style={{ height: "15px", width: "15px" }} />
              //   ) : (
              //     <Sync style={{ height: "17px", width: "17px" }} />
              //   )
              // }
            >
              {/* {loading ? `${Math.round(progress)}%` : "Update products"} */}
              {/* {isUpdate ? "Update" : "Create"} customers */}
              Sync Customer
            </LoadingButton>
          </Stack>
        }
        containerStyle={{ padding: "0px" }}
      />
      <DynamicDataSheetGrid
        // <DataSheetGrid
        ref={datasheetRef}
        // rowHeight={(props) => {
        //   return { top: 40 }; // custom function
        //   // return validateNumber(rowData?.length || 0) + 40;
        // }}
        // rowHeight={60}
        height={
          windowSize.innerHeight
          //  - 200
        }
        value={data}
        onChange={(data, operations) => {
          // console.log(data, operations);
          setData(data);
        }}
        columns={columns}
        style={{
          "--dsg-selection-border-color": cellhasError
            ? "tomato"
            : "rgb(69,128,230)",
        }}
        // disableContextMenu
        addRowsComponent={(props) =>
          // <AddRowsComponent remainingRows={remainingProducts} {...props} />
          null
        }
      />
      <ErrorsPopover
        navigateError={navigateError}
        ref={errorsPopoverRef}
        errors={errors}
      />
      <UpdateDialog
        loadingText={loadingText}
        ref={updateDialogRef}
        progress={progress}
        title="Syncing customers"
      />
      <ColumnsPopover
        selectedColumns={selectedColumns}
        setSelectedColumns={(payload) =>
          setSelectedColumns((state) => ({ ...state, ...payload }))
        }
        ref={columnsPopoverRef}
        allColumns={getColumns(getStoreId(), custom_fields)}
        type="crm"
      />
      <ImportDialog
        open={openImport}
        handleClose={onCloseImport}
        onAddFile={() => {
          inputRef?.current?.click();
        }}
        heading={"Import Products by CSV"}
        downloadCsv={downloadCsv}
      />
      <ExportDialog open={openExport} handleClose={onCloseExport} />
    </Box>
  );
};

export default CRMDataSheet;

const AddRowsComponent = ({ addRows, remaingRows }) => {
  let [temp, setTemp] = useState("");
  return (
    <Box sx={{ bgcolor: "#fafafa", p: 2 }}>
      <Stack spacing={3} direction={"row"} alignItems={"center"}>
        <TextField
          size="small"
          value={temp}
          onChange={(e) => setTemp(e.target.value)}
          sx={{ maxWidth: 100 }}
        />
        <Button
          variant="outlined"
          onClick={() => (!temp ? null : addRows(validateNumber(temp)))}
          disabled={temp > remaingRows}
        >
          Add Rows
        </Button>
      </Stack>
    </Box>
  );
};
