import { GoToStepNode } from "../../utils/GoToStepNode";

export const createGoToStepNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.filter((node) => node.id === nodeId);
  const axis = {
    x: currNode[0].position.x,
    y: currNode[0].position.y,
  };
  GoToStepNode(dispatch, axis, nodeId);
};
