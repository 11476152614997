import { useState, useRef } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { useEffect } from "react";
import phoneImg from "../assets/mobile-svg.svg";
import SelectAttributes from "../helper/SelectAttributes";

const TemplatePreview = ({ currentSelectedTemplate }) => {
  const smsArr = currentSelectedTemplate?.template_data?.data;
  const [currentSms, setCurrentSms] = useState(null);
  const [smsText, setSmsText] = useState(null);
  const [attributeField, setAttributeField] = useState(null);
  const [selectAttributeAnchor, setSelectAttributeAnchor] = useState(null);
  const anchorAttributeRef = useRef();
  const handleOpenattributeField = (e) => {
    setSelectAttributeAnchor(e.currentTarget);
  };

  const traverseAndFindAttributes = (text) => {
    const pattern = /##.*?##/g;
    const matches = [...text.matchAll(pattern)];

    const resultArray = matches.map((match, i) => ({
      index: i + 1,
      value: "",
    }));
    setAttributeField(resultArray);
  };
  function replaceAttributes(text, attributeObj) {
    const words = text.split(/\s+/); 
    let currentIndex = 1;

    for (let i = 0; i < words.length; i++) {
      if (words[i].startsWith("##")) {
        const capturedText = words[i].slice(2); 
        const attribute = attributeObj.find(
          (attr) => attr.index === currentIndex
        );

        if (attribute && attribute.value !== "") {
          words[i] = attribute.value + words[i].slice(capturedText.length + 2);
          currentIndex++;
        }
      }
    }

    return words.join(" ");
  }

  const handleVariable = (e, index) => {
    console.log("CCCC");
    const text = e.target.value;
    console.log(text);
    // setAttributeField((prevFields) =>
    //   prevFields.map((field) =>
    //     field.index === index ? { ...field, value: text } : field
    //   )
    // );
    const updatedAttr = attributeField.map((field) =>
      field.index === index ? { ...field, value: text } : field
    );

    setAttributeField(updatedAttr);

    console.log(updatedAttr, smsText?.original);
    const updatedText = replaceAttributes(smsText?.original, updatedAttr);

    console.log("updatedText", updatedText);
    setSmsText((prev) => ({
      ...prev,
      current: updatedText,
    }));
  };

  useEffect(() => {
    const filteredSms = smsArr.find((item) => item.active_status === "1");
    const textData = filteredSms?.template_data;
    setCurrentSms(filteredSms);
    setSmsText({ original: textData, current: textData });
    traverseAndFindAttributes(textData);
  }, [currentSelectedTemplate, smsArr]);

  return (
    <Box
      ref={anchorAttributeRef}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "600px",
        padding: "5px",
        backgroundImage: `url(${phoneImg})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        // '::before': {
        //   content: '""',
        //   position: "absolute",
        //   top: "0",
        //   left: "0",
        //   right: "0",
        //   bottom: "0",
        //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        //   zIndex: -1,
        // },
      }}
    >
      {currentSms && (
        <Box
          onClick={handleOpenattributeField}
          sx={{
            borderRadius: "15px",
            margin: "10px",
            padding: "10px",
            backgroundColor: "#fff",
            width: "47%",
            "&:hover": {
              cursor: "pointer",
              userSelect: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "cemter",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: 600 }}>
              {currentSms?.template_name}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "10px" }}>
              now
            </Typography>
          </Box>
          <Typography variant="span" sx={{ fontSize: "11px" }}>
            {smsText?.current}
          </Typography>
        </Box>
      )}
      <SelectAttributes
        selectAttributeAnchor={selectAttributeAnchor}
        setSelectAttributeAnchor={setSelectAttributeAnchor}
        attributeField={attributeField}
        setAttributeField={setAttributeField}
        handleVariable={handleVariable}
      />
    </Box>
  );
};

export default TemplatePreview;
