import {
  Add,
  ContentCopy,
  ContentPaste,
  HelpOutline,
  Widgets,
  YouTube,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  TextField,
  Alert,
  Collapse,
  DialogActions,
  FormGroup,
  Skeleton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  a11yProps,
  cartTitlePadding,
  dialogStyle,
  EmptyState,
  formatDate,
  getAxiosError,
  getComparator,
  getDateAfterHours,
  getProductPrice,
  getRandomInt,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  primaryLabel,
  reduceLargeText,
  secondaryLabel,
  setBrokenImage,
  stableSort,
  StackRow,
  TabPanel,
  validateNumber,
} from "../../helper";
import {
  catalogCardStyle,
  catalogDateFormat,
  catalogUserDefaultAvatar,
  IconValue,
  MostViewedProductsGraphOptions,
  recentRequestHeadCells,
  SectionLabel,
  skeletonSpacing,
  TableLabel,
  TableSecondaryLabel,
  TopCustomersGraphOptions,
  topCustomersHeadCells,
  TotalCard,
} from "./CatalogHelper";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { fetchCatalogs } from "../../redux/actions/productActions";
import {
  Favorite,
  FavoriteBorder,
  Share,
  ShareOutlined,
  Visibility,
  VisibilityOutlined,
} from "@material-ui/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CatalogsRequests, { CatalogsRequestsTable } from "./CatalogRequests";
import CatalogsSent, { CatalogsSentTable } from "./CatalogsSent";
import SelectProducts from "../components/SelectProducts";
import styled from "styled-components";
import { currencySymbols } from "../pricing/Gold";
import { LoadingButton } from "@mui/lab";
import { fetchCustomers } from "../products/AllProducts";
import axios from "axios";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../redux/actions/helperActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import ReactApexChart from "react-apexcharts";
import {
  EmptyContactsGraph,
  EmptyGraphSvg,
  InstagramSvg,
  MetaSvg,
  Whatsapp,
} from "../../Svgs";
import { DashboardTotalCard } from "../dashboards/Default";
import DataNotFound from "../components/DataNotFound";

import "swiper/css"; // core Swiper
import "swiper/css/pagination";
import PopperTooltip from "../components/PopperTooltip";
import { tutorialData } from "../../App";
import InfoTooltip from "../components/InfoTooltip";
import { ResponsiveTabaleRow } from "../components/helper";

const demoProfile = [
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn0C4lPDZ-CdkIO0mmgk9bMi5Ss49u0E7e9w&usqp=CAU",
  "https://www.citrix.com/blogs/wp-content/upload/2018/03/slack_compressed-e1521621363404-360x360.jpg",
  "https://www.bnl.gov/today/body_pics/2017/06/stephanhruszkewycz-hr.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1VvqwlDjUJ6i1fiQpWJgvnEPFjdksQEde-KEjCx66skt39oib1QtroMgS8W-_Ihu8P6Q&usqp=CAU",
];

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Catalogs() {
  const reduxCatalogs = useSelector((state) => state.product.allCatalogs);
  const fetchedAllCatalogs = useSelector(
    (state) => state.product.fetchedAllCatalogs
  );
  const fetchingCatalogs = useSelector(
    (state) => state.product.fetchingCatalogs
  );
  const catalogs = useSelector((state) => state.product.catalogs);
  const catalogExtra = useSelector((state) => state.product.catalogExtra);

  const access = useSelector((state) => state.user.access);
  const catalogsAccess = useSelector((state) => state.user.catalogsAccess);
  const allVisitors = useSelector((state) => state.visitor.allOnlineVisitors);
  const live_catalogs = useSelector((state) => state.visitor.live_catalogs);
  const catalogTopGraphs = useSelector(
    (state) => state.product.catalogTopGraphs
  );

  // let catalogMostViewedProduct = useSelector(
  //   (state) => state.product.catalogMostViewedProduct
  // );
  // let fetchingCatalogMostViewedProducts = useSelector(
  //   (state) => state.product.fetchingCatalogMostViewedProducts
  // );

  const [productAccess, setProductAccess] = useState([]);
  const [productSelectionOpen, setProductSelectionOpen] = useState(false);
  const [catalogForm, setCatalogForm] = useState({});
  const [showCatalogForm, setShowCatalogForm] = useState(false);
  const [showCatalogShare, setShowCatalogShare] = useState(false);
  const [selectedTab, setSelectedTab] = useState("dashboard");

  const closeCatalogShare = () => setShowCatalogShare(false);
  const tabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const primaryColor = theme?.palette?.primary?.main;
  const location = useLocation();

  const closeProductSelection = () => setProductSelectionOpen(false);
  const openProductSelection = () => setProductSelectionOpen(true);
  const openCatalogForm = () => setShowCatalogForm(true);
  const closeCatalogForm = () => setShowCatalogForm(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (isArrayWithValues(access?.product)) setProductAccess(access.product);
  }, [access]);

  useEffect(() => {
    if (!fetchedAllCatalogs) dispatch(fetchCatalogs());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxCatalogs) && !fetchingCatalogs) {
      let { hash } = location;
      if (hash === "#sent") setSelectedTab("sent");
      if (hash === "#requests") setSelectedTab("request");
      window.history.pushState("", document.title, window.location.pathname);
    }
  }, [reduxCatalogs, fetchingCatalogs, location?.hash]);

  const onProductSelect = (products) => {
    setSelectedProducts(products);
    closeProductSelection();
    openCatalogForm();
  };

  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  if (fetchingCatalogs)
    return (
      <Box sx={{ gap: skeletonSpacing }}>
        <Stack
          direction={"row"}
          spacing={5}
          justifyContent="flex-end"
          sx={{ my: skeletonSpacing }}
        >
          {Array.from(new Array(3)).map((i) => (
            <Skeleton
              sx={{ borderRadius: "8px" }}
              width={"80px"}
              height={"35px"}
            />
          ))}
        </Stack>
        <Grid container spacing={3}>
          {Array.from(new Array(4)).map((i) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Skeleton
                sx={{
                  borderRadius: "15px",
                  "-webkit-transform": "scale(1)",
                  transform: "scale(1)",
                  mb: skeletonSpacing,
                }}
                width={"100%"}
                height={"240px"}
              />
            </Grid>
          ))}
        </Grid>
        <div>
          <Skeleton
            sx={{
              borderRadius: "15px",
              "-webkit-transform": "scale(1)",
              transform: "scale(1)",
              mb: skeletonSpacing,
            }}
            width={"100%"}
            height={400}
          />
        </div>
        <Grid container spacing={3}>
          {Array.from(new Array(3)).map((i) => (
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton
                sx={{
                  borderRadius: "15px",
                  "-webkit-transform": "scale(1)",
                  transform: "scale(1)",
                  mb: skeletonSpacing,
                }}
                width={"100%"}
                height={"520px"}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  return (
    <Box sx={{ columnGap: "14px", px: 2 }}>
      {!isArrayWithValues(reduxCatalogs) ? (
        <DataNotFound
          illustration={<img src="/gifs/catalogs.gif" />}
          subtitle={
            "Catalogs is an online collection of product from which customers can make purchase decision."
          }
          title={"Let’s create your first catalog."}
          createButton={
            <Button
              variant="contained"
              onClick={openProductSelection}
              // size={"small"}
              sx={{ mt: 3 }}
            >
              {isIpad ? "Create" : "Create catalogs"}
            </Button>
          }
        />
      ) : (
        <>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            mb={4}
            flexWrap="wrap"
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              gap={"12px"}
            >
              {/* <Widgets style={{ height: "34px", width: "34px" }} /> */}
              <Box sx={{ ml: 2 }}>
                <Typography variant="h3" gutterBottom display="inline">
                  Catalogs{" "}
                  <IconButton
                    onClick={() =>
                      dispatch(
                        setGlobalTutorial({
                          show: true,
                          url: tutorialData?.catalogues_overview?.link,
                          title: tutorialData?.catalogues_overview?.title,
                        })
                      )
                    }
                  >
                    <YouTube />
                  </IconButton>
                </Typography>
                <Breadcrumbs aria-label="Breadcrumb" mt={1}>
                  <Link component={NavLink} to="/">
                    Dashboard
                  </Link>
                  {/* <Link component={NavLink} to="/">
                Products
              </Link> */}
                  <Typography>Catalogs</Typography>
                </Breadcrumbs>
                {/* <Typography variant="h5" sx={{ fontSize: "24px" }}>
              Catalogs
            </Typography> */}
                {/* <Typography
              variant="h5"
              sx={{ fontSize: "14px", color: "text.secondary" }}
            >
              Dashboard
            </Typography> */}
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              spacing={3}
            >
              {/* <Button
                variant="outlined"
                onClick={() => navigate("sent")}
                // size={"small"}
              >
                {isIpad ? "Sent catalogs" : "View sent catalogs"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("requests")}
                // size={"small"}
              >
                {isIpad ? "Request catalogs" : "View request catalogs"}
              </Button> */}
              <Button
                variant="contained"
                onClick={openProductSelection}
                // size={"small"}
                startIcon={<Add />}
              >
                {isIpad ? "Create" : "Create catalogs"}
              </Button>
            </Stack>
          </Stack>
          <Box sx={{ mb: 4 }}>
            <Tabs
              value={selectedTab}
              onChange={tabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Dashboard" {...a11yProps(0)} value="dashboard" />
              <Tab label="Catalogs Sent" {...a11yProps(1)} value="sent" />
              <Tab
                label="Catalogs Requested"
                {...a11yProps(2)}
                value="request"
              />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={"dashboard"}>
            <CatalogDashboard />
          </TabPanel>
          <TabPanel value={selectedTab} index={"sent"}>
            <CatalogsSent />
          </TabPanel>
          <TabPanel value={selectedTab} index={"request"}>
            <CatalogsRequests />
          </TabPanel>
        </>
      )}
      <Dialog
        open={productSelectionOpen}
        onClose={closeProductSelection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <SelectProducts
          onClose={closeProductSelection}
          onSelect={onProductSelect}
          onlyImage
          title={"Select Products for Catalog"}
        />
      </Dialog>
      <Dialog
        open={isObjWithValues(showCatalogShare)}
        onClose={closeCatalogShare}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <ShareCatalogDialog
          onClose={closeCatalogShare}
          catalog={showCatalogShare}
        />
      </Dialog>
      {/* <Dialog
        open={showCatalogForm}
        onClose={() => setShowCatalogForm(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      ></Dialog> */}
      <CreateCatalog
        show={showCatalogForm}
        onHide={closeCatalogForm}
        selectedProducts={selectedProducts}
        afterCreateCatalog={(catalog) => setShowCatalogShare(catalog)}
        // form={catalogForm}
        // setForm={editCatalogForm}
        // onCreateCatalog={onCreateCatalog}
        // creatingCatalog={creatingCatalog}
        // catalogError={catalogError}
        // closeCatalogForm={closeCatalogForm}
      />
    </Box>
  );
}

export default Catalogs;

export const ShareCatalogDialog = ({ onClose, catalog: showCatalogShare }) => {
  const dispatch = useDispatch();
  const onCopyLink = async () => {
    await navigator?.clipboard?.writeText(showCatalogShare?.link);
    if (navigator?.clipboard?.writeText)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Catalog link copied to clipboard",
          severity: "success",
        })
      );
    else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't copy!",
          severity: "error",
        })
      );
  };

  const onLinkShare = (channel) => {
    let text = `Hi there!  We have sent you a jewelry catalog ${
      showCatalogShare?.title
    }. It's full of stunning pieces that you're
    \n sure to love! However, please note\n 
    that the link will expire in ${
      showCatalogShare?.isPublic
        ? validateNumber(showCatalogShare?.expiry) * 24
        : validateNumber(showCatalogShare?.expiry)
    } hours, so make sure to check it out soon.\n
     Happy browsing.
     ${showCatalogShare?.link}
     \n\n
    Team ${getStoreName()},\n
    powered by Jwero Catalog`;
    if (channel === "whatsapp")
      window.open(
        `https://api.whatsapp.com/send?&text=${showCatalogShare?.link}`,
        "_blank"
      );
    if (channel === "facebook")
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${showCatalogShare?.link}`,
        "_blank"
      );
  };
  return (
    <>
      <DialogTitle>
        Share public catalog{" "}
        {/* {showCatalogShare.title ? `(${showCatalogShare.title})` : ""} */}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Catalog Name: <strong>{showCatalogShare?.title}</strong> (
          {showCatalogShare?.show_price ? "With" : "Without"} pricing)
        </Typography>
        <Typography sx={{ mt: 2, fontSize: "14px" }}>
          Validity up to:{" "}
          <strong>
            {formatDate(
              getDateAfterHours(
                showCatalogShare?.isPublic
                  ? validateNumber(showCatalogShare?.expiry) * 24
                  : validateNumber(showCatalogShare?.expiry)
              ),
              "dd:mm:yyyy hh:mm am/pm"
            )}
            {/* {showCatalogShare.expiry}{" "}
              {showCatalogShare?.isPublic ? "days" : "hours"} */}
          </strong>
        </Typography>
        <TextField
          size="large"
          fullWidth
          value={showCatalogShare?.link || ""}
          sx={{ mt: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Copy ">
                  <Button
                    startIcon={
                      <ContentCopy style={{ height: "18px", width: "18px" }} />
                    }
                    onClick={onCopyLink}
                  >
                    Copy
                  </Button>
                  {/* <IconButton onClick={onCopyLink}>
                      <ContentCopy style={{ height: "18px", width: "18px" }} />
                    </IconButton> */}
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ fontSize: "12px", color: "text.secondary", my: 2 }}>
          Share on social media
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={2}
          mt={2}
          flexWrap="wrap"
        >
          <Button
            startIcon={<Whatsapp />}
            variant="outlined"
            onClick={() => onLinkShare("whatsapp")}
          >
            Whatsapp
          </Button>
          <Button
            startIcon={<MetaSvg />}
            variant="outlined"
            onClick={() => onLinkShare("facebook")}
          >
            Facebook
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>
    </>
  );
};

const CatalogDashboard = ({}) => {
  const catalogs = useSelector((state) => state.product.catalogs);
  const catalogExtra = useSelector((state) => state.product.catalogExtra);

  const catalogTopGraphs = useSelector(
    (state) => state.product.catalogTopGraphs
  );
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Catalog created"
            value={catalogs?.length}
            onClick={() => navigate("/catalogs/sent")}
            values={[
              {
                title: "Public Catalog",
                value: catalogExtra?.totalPublicSent,
                // prefix: currencySymbols[currency],
                info: ["Share catalog link via Whatsapp, Email etc."],
              },
              {
                title: "Private Catalogs",
                value: catalogExtra?.totalPrivateSent,
                info: [
                  "Send a curated catalog to one or more specific contacts",
                ],

                // suffix: "g",
              },
            ]}
            graphData={catalogTopGraphs?.sent}
            points={["Total nos of catalogs created, Public and Private"]}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Sent to contacts"
            value={catalogExtra?.totalSentToCustomers || 0}
            onClick={() => navigate("/catalogs/requests")}
            values={[
              {
                title: "Customers",
                value: 0,
                // prefix: currencySymbols[currency],
              },
              {
                title: "Leads",
                value: catalogExtra?.totalSentToCustomers || 0,
                // suffix: "g",
              },
            ]}
            graphData={catalogTopGraphs?.sent}
            points={[
              "Total nos of Contacts (Customers & Leads) whom Catalogs are sent.",
            ]}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Response received"
            onClick={() => navigate("/catalogs/requests")}
            value={catalogExtra?.totalResponse}
            values={[
              {
                title: "Catalog requests",
                value: catalogExtra?.totalRequests,
                // prefix: currencySymbols[currency],
              },
              {
                title: "Unique products requested",
                value: catalogExtra?.totalUniqueProductsRequested,
                info: [
                  "Unique Product requested - a product selected by multiple contacts, will be counted as 1",
                ],
                // suffix: "g",
              },
            ]}
            graphData={catalogTopGraphs?.request}
            points={[
              "Total nos of responses you received against the Catalogs you sent",
            ]}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Products Sent"
            value={catalogExtra?.totalProducts}
            points={[
              "Total Nos of product you shared via catalog. A product  shared on multiple catalogs will be counted as 1",
            ]}
            values={[
              // {
              //   title: "Products requested",
              //   value: catalogExtra?.totalUniqueProductsRequested || 0,
              //   // prefix: currencySymbols[currency],
              // },
              {
                title: "No response products",
                value:
                  validateNumber(catalogExtra?.totalProducts) -
                    validateNumber(catalogExtra?.totalUniqueProductsRequested) >
                  0
                    ? validateNumber(catalogExtra?.totalProducts) -
                      validateNumber(catalogExtra?.totalUniqueProductsRequested)
                    : 0,
                info: [
                  "No response products - Non performing products, which never bought or selected by contacts.",
                ],
                // suffix: "g",
              },
              {
                title: "Avg products per catalog",
                value:
                  validateNumber(
                    Number(catalogExtra?.avgProductsPerCatalog).toFixed(2)
                  ) || 0,
                // suffix: "g",
              },
            ]}
            hideNavigate
            graphData={catalogTopGraphs?.productsSent}
          />
        </Grid>
      </Grid>
      {/* <RecentCatalogRequest /> */}
      <CatalogsSentTable />
      <CatalogsRequestsTable />
      <MostViewedProducts />
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MostViewedProductsGraph />
          </Grid>
          <Grid item xs={12} md={6}>
            <TopCustomersGraph />
          </Grid>
        </Grid>
      </Box>
      <TopCustomers />
    </>
  );
};

export const CreateCatalog = ({
  show,
  onHide,
  selectedProducts,
  afterCreateCatalog,
  formValue,
  customers,
}) => {
  const [allCustomers, setAllCustomers] = useState(customers || []);
  const [customerSearching, setCustomerSearching] = useState([]);
  const [form, setCatalogForm] = useState(formValue || { isPublic: true });
  const [catalogError, setCatalogError] = useState("");
  const [creatingCatalog, setCreatingCatalog] = useState(false);

  const dispatch = useDispatch();

  const setForm = (payload) => {
    if (catalogError) setCatalogError(false);
    setCatalogForm((state) => ({ ...state, ...payload }));
  };
  console.log(allCustomers, form?.customers);

  let customerSearchTimeout;

  useEffect(
    // if(!isArrayWithValues(allCustomers)){

    // }
    () =>
      !isArrayWithValues(allCustomers) &&
      fetchCustomers("", (customers) => setUpdatedCustomers(customers)),
    []
  );

  const setUpdatedCustomers = (customers) => {
    if (isArrayWithValues(customers)) {
      let arr = [];
      for (let i = 0; i < customers.length; i++) {
        arr.push({
          label: `${customers?.[i]?.first_name} ${customers?.[i]?.last_name}`,
          value: customers[i].id,
        });
      }
      setAllCustomers(arr);
    }
  };

  const onCustomerSearch = async (value, setState, setLoading) => {
    if (!value || (value && value.length < 2)) return;
    if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

    customerSearchTimeout = setTimeout(async () => {
      setCustomerSearching(true);
      await fetchCustomers(value, setState);
      setCustomerSearching(false);
    }, 500);
  };

  const onCreateCatalog = async () => {
    try {
      let { title, customers, expiry, content, show_price, isPublic } = form;
      // console.log(form);
      // return;
      if (!title) return setCatalogError("Please enter title");
      if (!expiry) return setCatalogError("Please enter expiry");
      if (!isPublic)
        if (expiry > 72 || expiry < 1)
          return setCatalogError(
            "Expiry should be minimun 1 to maximum 72 hours"
          );
      if (isPublic)
        if (expiry > 15 || expiry < 1)
          return setCatalogError(
            "Expiry should be minimun 1 to maximum 15 days"
          );
      if (!isPublic) {
        if (!isArrayWithValues(customers))
          return setCatalogError("Please select atleast one customer");
      }
      // console.log(customers);
      // return;

      setCatalogError("");
      let obj = {
        title,
        content,
        show_price,
        expiry: isPublic ? validateNumber(expiry) * 24 : expiry,
        products: isArrayWithValues(selectedProducts)
          ? selectedProducts.map((i) => i.id).join(",")
          : "",
      };
      if (isArrayWithValues(customers) && !form.isPublic)
        obj.customers = customers.map((i) => i.value && i.value);
      else obj.customers = [];
      if (form.isPublic) {
        delete obj.customers;
        // delete obj.expiry;
      }
      console.log(JSON.stringify(obj, null, 2));
      // return;
      setCreatingCatalog(true);
      // axios
      //   .post(
      //     "https://www.tiarabytj.com/wp-json/store/v1/catalogs?type=add_catalogue",
      //     JSON.stringify({ obj }),
      //     {
      //       headers: {
      //         Authorization:
      //           "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )
      let website = getWebsite();
      let token = getToken();
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/catalogs?type=add_catalogue`,
        headers: {
          Authorization: `Basic ${token}`,
          // "Content-Type": "application/json",
        },
        method: "POST",
        data: { data: { ...obj } },
      });

      try {
        if (
          data.success ||
          data?.data?.includes("whatsapp is not integrated")
        ) {
          // setCatalogDialog(false);
          // dispatch(
          //   addUserActivity({
          //     event: "catalog_add",
          //     event_info: {
          //       product_id: data?.data?.id,
          //       name: `${obj?.title}`,
          //       ...obj,
          //     },
          //   })
          // );
          if (isArrayWithValues(data.data) && form.isPublic)
            afterCreateCatalog &&
              afterCreateCatalog({ ...(data.data[0] || {}), ...form });
          afterCreateCatalog &&
            afterCreateCatalog({ ...(data.data[0] || {}), ...form });
          onHide();
          dispatch(fetchCatalogs());
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: "Catalog added successfully!",
            })
          );
          // navigate("/catalogs");
        } else {
          dispatch(
            setGlobalToast({
              show: true,
              severity: "error",
              message: "Couldn't add Catalog, try again!",
            })
          );
        }
      } catch (e) {
        console.log(getAxiosError(e));
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add Catalog, try again!",
          })
        );
      }
    } catch (error) {
      console.log(error);
      console.log(getAxiosError(error));
      dispatch(
        setGlobalToast({
          show: true,
          severity: "error",
          message: "Couldn't add Catalog, try again!",
        })
      );
    }
    setCreatingCatalog(false);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={() => onHide()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          Send {form.isPublic ? "Public" : "Private"} catalog
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <FormControl>
                  <Typography>Catalog Type </Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.isPublic}
                    onChange={(e) =>
                      // console.log(
                      //   typeof e.target.value,
                      //   e.target.value,
                      //   Boolean(e.target.value)
                      // )
                      setForm({
                        isPublic: e.target.value == "true" ? true : false,
                      })
                    }
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          Private{" "}
                          <PopperTooltip
                            points={[
                              "Create a catalog and share its link to any contact via Whatsapp, Facebook, or other channels etc",
                            ]}
                          >
                            <HelpOutline
                              fontSize="small"
                              style={{
                                opacity: "0.5",
                                height: "16px",
                                width: "16px",
                              }}
                              color="text.secondary"
                            />
                          </PopperTooltip>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                          }}
                        >
                          Public{" "}
                          <PopperTooltip
                            points={[
                              "Create a catalog and share it to the contacts which are listed in your CRM.  Catalog is shared as link on Whatsapp",
                            ]}
                          >
                            <HelpOutline
                              fontSize="small"
                              style={{
                                opacity: "0.5",
                                height: "16px",
                                width: "16px",
                              }}
                              color="text.secondary"
                            />
                          </PopperTooltip>
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.isPublic}
                        onChange={(e) =>
                          setForm({
                            isPublic: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Make this catalog public"
                  />
                </FormGroup> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Catalog title</Typography>
                <TextField
                  value={form.title}
                  onChange={(e) => setForm({ title: e.target.value })}
                  fullWidth
                  size="small"
                  id="outlined"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Expiry (in {form.isPublic ? "days" : "hours"})
                </Typography>
                <TextField
                  value={form.expiry}
                  onChange={(e) => setForm({ expiry: e.target.value })}
                  fullWidth
                  size="small"
                  id="outlined"
                  variant="outlined"
                  // label="Gemstone Pieces"
                  helperText={
                    form.isPublic
                      ? "Between 1 to 15 days"
                      : `Between 1 to 72 hours`
                  }
                  type="number"
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <Collapse in={!form.isPublic}>
                  <Typography>Select customer(s)</Typography>
                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={allCustomers}
                    size="small"
                    // sx={{ width: 300 }}
                    value={form?.customers}
                    loading={customerSearching}
                    noOptionsText="No Customers"
                    openOnFocus
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="UP SELL"
                        onChange={(e) =>
                          onCustomerSearch(e.target.value, setUpdatedCustomers)
                        }
                      />
                    )}
                    onChange={(e, value) => setForm({ customers: value })}
                  />
                </Collapse>
              </Grid>
              {/* <Grid
              item
              xs={12}
              // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
            >
              <Typography>Select product(s)</Typography>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={catalogProducts}
                size="small"
                // sx={{ width: 300 }}
                value={form.products}
                loading={fetchingCatalogProducts}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="UP SELL"
                    onChange={(e) => onProductSearch(e.target.value)}
                  />
                )}
                onChange={(e, value) => setForm({ products: value })}
              />
            </Grid> */}
              <Grid
                item
                xs={12}
                // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <Typography>Description</Typography>
                <TextField
                  value={form.content}
                  onChange={(e) => setForm({ content: e.target.value })}
                  fullWidth
                  size="small"
                  variant="outlined"
                  // label="Gemstone Pieces"
                  // type="number"
                  id="outlined-textarea"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.show_price || false}
                        onChange={(e) =>
                          setForm({
                            show_price: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Show price"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Collapse in={catalogError ? true : false}>
          <Alert variant="outlined" severity="error" sx={{ marginTop: "10px" }}>
            {catalogError}
          </Alert>
        </Collapse>

        <DialogActions sx={{ marginRight: "15px" }}>
          <Button onClick={() => onHide()}>Cancel</Button>
          <LoadingButton
            loading={creatingCatalog}
            variant="outlined"
            onClick={() => onCreateCatalog()}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

function RecentCatalogRequest() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([{}, {}, {}, {}]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <SectionLabel>Recent catalog request received</SectionLabel>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, ...catalogCardStyle }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            display: "none",
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Recent catalog request received
            </Typography>
          )}

          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell> */}
                {recentRequestHeadCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    {headCell.hasSubheader && (
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography sx={{ fontSize: "10px" }}>
                          Contacts responded
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          {" "}
                          Unique products
                        </Typography>
                      </Stack>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {/* <TableRow sx={{}}>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={5}
                ></TableCell>
                <TableCell
                  align={"left"}
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Contacts responded
                </TableCell>
                <TableCell
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Unique products
                </TableCell>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  colSpan={1}

                  //   sortDirection={false}
                ></TableCell>
              </TableRow> */}
            </TableHead>
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      //   role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                      >
                        <Stack direction="row" alignItems={"center"}>
                          <img
                            src="/static/img/woocommerce_placeholder.png"
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "50%",
                            }}
                          />
                          <Box sx={{ ml: 1.5 }}>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              Test catalog
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems={"center"}
                              spacing={3}
                              mt={1}
                            >
                              <IconValue
                                icon={
                                  <Favorite
                                    style={{
                                      height: "13px",
                                      width: "13px",
                                      fill: "#bb6666",
                                    }}
                                  />
                                }
                                text={"43"}
                              />
                              <IconValue
                                icon={
                                  <Visibility
                                    style={{
                                      height: "15px",
                                      width: "15px",
                                      fill: "#818181",
                                    }}
                                  />
                                }
                                text={"23"}
                              />
                              <IconValue
                                icon={
                                  <Share
                                    style={{
                                      height: "13px",
                                      width: "13px",
                                      fill: "#35557f",
                                    }}
                                  />
                                }
                                text={"13"}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Box>
                          <TableLabel>Rahul more (lead)</TableLabel>
                          <TableSecondaryLabel>
                            rahul.tanika@gmail.com
                          </TableSecondaryLabel>
                          <TableSecondaryLabel>9834273492</TableSecondaryLabel>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {formatDate(new Date(), catalogDateFormat)}
                      </TableCell>
                      <TableCell align="right">
                        <TableLabel>12/30</TableLabel>
                        <TableSecondaryLabel>40%</TableSecondaryLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSecondaryLabel>In stock: 3</TableSecondaryLabel>
                        <TableSecondaryLabel>
                          Make to order: 1
                        </TableSecondaryLabel>
                      </TableCell>
                      <TableCell align="left">
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <TableLabel>23</TableLabel>
                            <TableSecondaryLabel>
                              Total product
                              <br /> qty : <strong>40</strong>
                            </TableSecondaryLabel>
                            {/* <TableLabel>40</TableLabel> */}
                          </Box>
                          <Box>
                            <TableLabel>23</TableLabel>
                            <TableSecondaryLabel>
                              Avg qty/
                              <br /> contact: <strong>40</strong>
                            </TableSecondaryLabel>
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <TableSecondaryLabel>In process</TableSecondaryLabel>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const MostViewedProducts = () => {
  let catalogMostViewedProduct = useSelector(
    (state) => state.product.catalogMostViewedProduct
  );

  let fetchingCatalogMostViewedProducts = useSelector(
    (state) => state.product.fetchingCatalogMostViewedProducts
  );
  return (
    <Box sx={{ mt: 4 }}>
      {fetchingCatalogMostViewedProducts ||
      isArrayWithValues(catalogMostViewedProduct) ? (
        <Box sx={{}}>
          <SectionLabel>Most viewed products</SectionLabel>
          {fetchingCatalogMostViewedProducts &&
            !isArrayWithValues(catalogMostViewedProduct) && (
              <Box sx={{ my: 2 }}>
                <Grid container spacing={4} sx={{}}>
                  {Array.from(new Array(4)).map((i, index) => (
                    <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                      <CatalogProductCardSkeleton />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          <Box sx={{ my: 2 }}>
            <Grid container spacing={4} sx={{}}>
              {isArrayWithValues(catalogMostViewedProduct) &&
                catalogMostViewedProduct.map((i, index) => (
                  <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                    <CatalogProductCard product={i} />
                  </Grid>
                ))}
            </Grid>
          </Box>
          {/* <Stack
        direction={"row"}
        sx={{
          maxWidth: "100%",
          overflow: "auto",
          display: "flex",
          gap: "12px",
          mt: 3,
        }}
      >
        {isArrayWithValues(catalogMostViewedProduct) &&
          catalogMostViewedProduct.map((i) => {
            return <CatalogProductCard product={i} />;
          })}
      </Stack> */}
        </Box>
      ) : null}
    </Box>
  );
};

const MostViewedProductsGraph = () => {
  const [categories, setCategories] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogRequests, setCatalogRequests] = useState();
  const [series, setSeries] = useState([]);
  let catalogMostViewedProduct = useSelector(
    (state) => state.product.catalogMostViewedProduct
  );
  let fetchingCatalogProducts = useSelector(
    (state) => state.product.fetchingCatalogProducts
  );
  const catalog_requests = useSelector(
    (state) => state.product.catalog_requests
  );

  const _catalogs = useSelector((state) => state.product.catalogs);

  useEffect(() => {
    if (isArrayWithValues(_catalogs)) setCatalogs([..._catalogs]);
  }, [_catalogs]);
  useEffect(() => {
    if (isArrayWithValues(catalog_requests))
      setCatalogRequests([...catalog_requests]);
  }, [catalog_requests]);

  useEffect(() => {
    if (!isArrayWithValues(catalogMostViewedProduct)) return;
    let series = [];
    let categories = [];
    let totalProductSent = [];
    let totalProductReceived = [];
    let totalProductViews = [];
    for (let product of catalogMostViewedProduct) {
      let productsSent = 0;
      let productsReceived = 0;
      let productsView = 0;
      let { id, sku } = product;
      categories.push(sku);
      if (isArrayWithValues(catalogs))
        catalogs.map((i) => {
          if (i.productsArray.includes(`${id}`)) productsSent++;
        });

      if (isArrayWithValues(catalogRequests)) {
        catalogRequests.map((i) => {
          if (i?.products?.includes(`${id}`)) productsReceived++;
        });
        catalogRequests.map((i) => {
          if (
            i?.activities?.find(
              (j) => j.type == "product" && j.product_id == `${id}`
            )
          )
            productsView++;
        });
      }

      totalProductSent.push(productsSent);
      totalProductReceived.push(productsReceived);
      totalProductViews.push(productsView);
    }
    series.push(
      { name: "Sent", data: totalProductSent },
      { name: "Received", data: totalProductReceived },
      { name: "Views", data: totalProductViews }
    );
    setSeries(series);
    setCategories(categories);
  }, [catalogMostViewedProduct, catalogs, catalogRequests]);
  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <SectionLabel sx={{ mb: 2 }}>Most viewed products</SectionLabel>
        {!fetchingCatalogProducts && !isArrayWithValues(series) && (
          <EmptyState
            icon={<EmptyGraphSvg />}
            text="Your most viewed products will appear here"
          />
        )}
        {!fetchingCatalogProducts && isArrayWithValues(series) && (
          <ReactApexChart
            options={{
              ...MostViewedProductsGraphOptions,
              xaxis: {
                categories,
                lines: {
                  show: false,
                },
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            }}
            series={series}
            type="bar"
            height={350}
          />
        )}
      </CardContent>
    </Card>
  );
};
const TopCustomersGraph = () => {
  const [categories, setCategories] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogRequests, setCatalogRequests] = useState();
  const [series, setSeries] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  let catalogMostViewedProduct = useSelector(
    (state) => state.product.catalogMostViewedProduct
  );
  let fetchingCatalogProducts = useSelector(
    (state) => state.product.fetchingCatalogProducts
  );
  let topCatalogCustomers = useSelector(
    (state) => state.product.topCatalogCustomers
  );
  const catalog_requests = useSelector(
    (state) => state.product.catalog_requests
  );

  const _catalogs = useSelector((state) => state.product.catalogs);

  useEffect(() => {
    if (isArrayWithValues(_catalogs)) setCatalogs([..._catalogs]);
  }, [_catalogs]);

  useEffect(() => {
    if (!isArrayWithValues(topCatalogCustomers)) return;
    setTopCustomers([...topCatalogCustomers]);
  }, [topCatalogCustomers]);

  useEffect(() => {
    if (isArrayWithValues(catalog_requests))
      setCatalogRequests([...catalog_requests]);
  }, [catalog_requests]);

  useEffect(() => {
    if (!isArrayWithValues(topCustomers)) return;
    let series = [];
    let categories = [];
    let totalCatalogSent = [];
    let totalRequestReceived = [];
    let totalProductsSent = [];
    let totalProductsReceived = [];
    let data = [];
    for (let customer of topCustomers) {
      let { id, first_name, last_name } = customer;
      let catalogSent = 0;
      let requestReceived = 0;
      let productsSent = 0;
      let productsReceived = 0;
      if (isArrayWithValues(catalogs))
        catalogs.map((i) => {
          if (i?.customer?.includes(`${id}`)) {
            catalogSent++;
            productsSent += validateNumber(i.products);
          }
        });
      if (isArrayWithValues(catalogRequests)) {
        catalogRequests.map((i) => {
          if (i?.customer_id == id) {
            requestReceived++;
            if (typeof i.products === "string")
              productsReceived += validateNumber(
                i?.products?.split(",")?.length
              );
            else if (isArrayWithValues(i.products))
              productsReceived += validateNumber(i?.products?.length);
          }
        });
        // let productsSent = 0;
        // let productsReceived = 0;
        // let productsView = 0;
        // let { id, first_name } = product;
        // categories.push(first_name);
        // if (isArrayWithValues(catalogs))
        //   catalogs.map((i) => {
        //     if (i.productsArray.includes(`${id}`)) productsSent++;
        //   });

        // if (isArrayWithValues(catalogRequests)) {
        //   catalogRequests.map((i) => {
        //     if (i?.products?.includes(`${id}`)) productsReceived++;
        //   });
        //   catalogRequests.map((i) => {
        //     if (
        //       i?.activities?.find(
        //         (j) => j.type == "product" && j.product_id == `${id}`
        //       )
        //     )
        //       productsView++;
        //   });
      }

      let total =
        catalogSent + requestReceived + productsSent + productsReceived;

      data.push({
        category: reduceLargeText(`${first_name || ""} ${last_name || ""}`, 10),
        catalogSent,
        requestReceived,
        productsSent,
        productsReceived,
        // productsView,
        total,
      });
    }
    data = data.sort((a, b) => b.total - a.total).slice(0, 5);
    data.map((i) => {
      categories.push(i.category);
      totalCatalogSent.push(i.catalogSent);
      totalRequestReceived.push(i.requestReceived);
      totalProductsSent.push(i.productsSent);
      totalProductsReceived.push(i.productsReceived);
      // totalProductViews.push(i.productsView);
    });
    series.push(
      { name: "Catalogs Sent", data: totalCatalogSent },
      { name: "Requests Received", data: totalRequestReceived },
      { name: "Products Sent", data: totalProductsSent },
      { name: "Products Selected", data: totalProductsReceived }
      // { name: "Views", data: totalProductViews }
    );
    setSeries(series);
    setCategories(categories);
  }, [topCustomers, catalogs, catalogRequests]);

  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems={"center"}
          style={{ ...cartTitlePadding, mb: 2 }}
          spacing={1}
        >
          <SectionLabel>Top Contacts - View & Requests</SectionLabel>
          <InfoTooltip title="Contacts who viewed most products & responded, are displays on top" />
        </Stack>
        {!fetchingCatalogProducts && !isArrayWithValues(series) && (
          <EmptyState
            icon={<EmptyContactsGraph />}
            text="Your top contacts from catalog will appear here"
          />
        )}
        {!fetchingCatalogProducts && isArrayWithValues(series) && (
          <ReactApexChart
            options={{
              ...TopCustomersGraphOptions,
              xaxis: {
                categories,
                labels: {
                  formatter: function (val) {
                    return val ? reduceLargeText(val, 10) : "";
                  },
                },
              },
            }}
            series={series}
            type="bar"
            height={350}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const CatalogProductCardSkeleton = () => {
  return (
    <Box
      sx={{
        // ml: 2,
        width: "100%",
        borderRadius: "15px",
        backgroundColor: "#efefef99",
        // maxWidth: "300%",
        overflow: "hidden",
        height: "310px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          mt: 0.5,
          height: "205px",
          // "-webkit-transform": "scale(1)",
          // transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="rectangular"
        sx={{
          mt: 3,
          ml: 2,
          maxWidth: "70%",
          height: "20px",
          // "-webkit-transform": "scale(1)",
          // transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          mt: 2,
          ml: 2,
          maxWidth: "50%",
          height: "20px",
          "-webkit-transform": "scale(1)",
          transform: "scale(1)",
        }}
      />
    </Box>
  );
};

export const CatalogProductCard = ({
  product,
  masterPricing,
  businessSettings,
  tax,
}) => {
  let [info, setInfo] = useState({});

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );

  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { meta_data } = product;
    let priceBreakup = getProductPrice(
      product,
      masterPricing,
      businessSettings,
      allDiamondGroups,
      tax
    );
    console.log(priceBreakup, "<<<< price breakup");
    for (let metaObj of meta_data) product[metaObj.key] = metaObj.value;
    let obj = { ...product, priceBreakup };
    setInfo(obj);
  }, [product]);
  // console.log(info?.collections);

  return (
    <Card sx={{ maxWidth: "300px", height: "100%", p: 0, ...catalogCardStyle }}>
      <CardContent sx={{ p: 0 }}>
        {isArrayWithValues(product?.images) ? (
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            // grabCursor
            pagination={{
              // dynamicBullets: false,
              enabled: true,
              clickable: true,
            }}
            modules={[Pagination]}
            // modules={[EffectFade]}
            style={{
              maxWidth: "100%",
              // width: "100%",
              overflowX: "hidden",
            }}
            // fadeEffect={""}
            // effect="fade"
            // direction="vertical"
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {product?.images
              // .filter((e, i) => i <= 1)
              .map((i) => {
                return (
                  <SwiperSlide
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      // maxWidth: "160px",
                      // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                    }}
                  >
                    <img
                      src={i.src}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        // height: "200px",
                        // width: "100%",
                        userSelect: "none",
                        pointerEvents: "none",
                        // width: "100%",
                        // width: "160px",
                        // maxWidth: "160px",
                        // alignSelf: "flex-start",
                        // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                        borderRadius: "8px",
                        // marginTop: "14px",
                        objectFit: "contain",
                        margin: "0px auto",
                        // marginTop: "8px",
                        textAlign: "center",
                        // flex: 1,
                      }}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <img
            src="/static/img/woocommerce_placeholder.png"
            // v,l
            style={{
              height: "100%",
              width: "100%",
              //   height: "50px",
              //   width: "50px",
              //   borderRadius: "50%",
              mt: 2,
            }}
          />
        )}
        <Box sx={{ px: 1.5 }}>
          <Stack
            direction="row"
            alignItems={"center"}
            // justifyContent="space-between"
            spacing={4}
            px={1.2}
            mt={1}
          >
            <IconValue
              icon={
                <Favorite
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#bb6666",
                  }}
                />
              }
              text={info?.likes?.length || 0}
              textStyle={{ fontSize: "15px" }}
            />
            <IconValue
              icon={
                <Visibility
                  style={{
                    height: "17px",
                    width: "17px",
                    fill: "#818181",
                  }}
                />
              }
              text={info?.woodmart_history_of_visits?.length || 0}
              textStyle={{ fontSize: "15px" }}
            />
            {/* <IconValue
              icon={
                <Share
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#35557f",
                  }}
                />
              }
              text={"13"}
              textStyle={{ fontSize: "15px" }}
            /> */}
          </Stack>
          <Typography sx={{ fontSize: "14px", fontWeight: "500", mt: 2 }}>
            {info.sku}
          </Typography>
          <TableSecondaryLabel>
            {info?.collections?.[0]?.name}
          </TableSecondaryLabel>
          <Typography sx={{ fontSize: "14px", fontWeight: "500", mt: 2 }}>
            {currencySymbols[businessSettings?.default_currency] || ""}
            {info?.priceBreakup?.price}
          </Typography>

          {/* <Grid container style={{ marginTop: "6px" }}>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Sent</TableLabel>
                <TableSecondaryLabel>40</TableSecondaryLabel>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Request</TableLabel>
                <TableSecondaryLabel>50</TableSecondaryLabel>
              </Box>
            </Grid>
          </Grid> */}
          {/* <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent={"space-evenly"}
          >
            <Box>
              <TableLabel>Sent</TableLabel>
              <TableSecondaryLabel>40</TableSecondaryLabel>
              {/* <TableLabel>40</TableLabel> 
            </Box>
            <Box>
              <TableLabel>Request</TableLabel>
              <TableSecondaryLabel>50</TableSecondaryLabel>
            </Box>
          </Stack> */}
        </Box>
      </CardContent>
    </Card>
  );
};

function TopCustomers() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([{}, {}, {}, {}]);

  let topCatalogCustomers = useSelector(
    (state) => state.product.topCatalogCustomers
  );

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!isArrayWithValues(topCatalogCustomers)) return setRows([]);
    setRows(topCatalogCustomers);
  }, [topCatalogCustomers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onCustomerImageBroke = (e) => {
    e.currentTarget.src = catalogUserDefaultAvatar;
  };
  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <Stack direction={"row"} alignItems="center" spacing={2}>
        <SectionLabel>Top Contacts</SectionLabel>
        <InfoTooltip
          title={
            "Contacts who viewed most products & responded, are displays on top"
          }
        />
      </Stack>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, ...catalogCardStyle }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            display: "none",
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Recent catalog request received
            </Typography>
          )}

          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <TableHead>
              {!isIpad && (
                <TableRow>
                  {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell> */}
                  {topCustomersHeadCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      // padding={headCell.disablePadding ? "none" : "normal"}
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      {headCell.hasSubheader && (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Contacts responded
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            {" "}
                            Unique products
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              {/* <TableRow sx={{}}>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={5}
                ></TableCell>
                <TableCell
                  align={"left"}
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Contacts responded
                </TableCell>
                <TableCell
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Unique products
                </TableCell>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  colSpan={1}

                  //   sortDirection={false}
                ></TableCell>
              </TableRow> */}
            </TableHead>
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      {isIpad ? (
                        <>
                          <ResponsiveTabaleRow
                            src={
                              row.profile_url ||
                              row.avatar_url ||
                              catalogUserDefaultAvatar
                            }
                            title={`${row.first_name || ""} ${
                              row.last_name || ""
                            }`}
                            subTitle={
                              <>
                                <StackRow spacing={1}>
                                  <Typography style={{ ...secondaryLabel }}>
                                    Catalogs Sent:
                                  </Typography>
                                  <Typography
                                    style={{ ...secondaryLabel, ml: 2 }}
                                  >
                                    <strong>{row?.totalSent || 0}</strong>{" "}
                                    (Requested:{" "}
                                    <strong>{row?.totalRequests || 0})</strong>
                                  </Typography>
                                </StackRow>
                                <StackRow spacing={1}>
                                  <Typography style={{ ...secondaryLabel }}>
                                    Products Sent:
                                  </Typography>
                                  <Typography
                                    style={{ ...secondaryLabel, ml: 2 }}
                                  >
                                    <strong>
                                      {row?.totalProductsSent || 0}
                                    </strong>{" "}
                                    (Requested:{" "}
                                    <strong>
                                      {row?.totalProductsRequest || 0}
                                    </strong>
                                    )
                                  </Typography>
                                </StackRow>
                              </>
                            }
                          />
                        </>
                      ) : (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          //   role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          onClick={() => {
                            window.open(
                              `${isDev() ? "http" : "https"}://${
                                window.location.host
                              }/crm/${row?.id}#catalogs`,
                              "_blank"
                            );
                          }}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Stack direction="row" alignItems={"center"}>
                              <img
                                // src={demoProfile[getRandomInt(demoProfile.length)]}
                                src={
                                  row.profile_url ||
                                  row.avatar_url ||
                                  catalogUserDefaultAvatar
                                }
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  objectFit: "contain",
                                }}
                                onError={onCustomerImageBroke}
                              />
                              <Box sx={{ ml: 1.5 }}>
                                <TableLabel
                                // sx={{ fontSize: "13px", fontWeight: "600" }}
                                >
                                  {row.first_name || ""} {row.last_name || ""}
                                </TableLabel>
                                <TableSecondaryLabel>
                                  Customer
                                </TableSecondaryLabel>
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <TableLabel>{row?.totalSent || 0}</TableLabel>
                          </TableCell>
                          <TableCell align="right">
                            <TableLabel>{row?.totalRequests || 0}</TableLabel>
                            <TableSecondaryLabel>
                              {row?.totalRequestsPercentage || 0}%
                            </TableSecondaryLabel>
                          </TableCell>
                          <TableCell align="right">
                            <TableLabel>
                              {row?.totalProductsRequest || 0}
                            </TableLabel>
                            <TableSecondaryLabel>
                              {row?.totalProductsRequestPercentage || 0}%
                            </TableSecondaryLabel>
                          </TableCell>
                          <TableCell align="right">
                            <TableLabel>
                              {row?.totalProductsSent || 0}
                            </TableLabel>
                          </TableCell>
                          <TableCell align="right">
                            <TableLabel>0</TableLabel>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
