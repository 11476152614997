import { default as React, memo } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { InfoOutlined, Search } from "@material-ui/icons";
import { columnTooltips, products_keys_mapping } from "../product/utils";
import {
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
} from "../../../helper";
import { useSelector } from "react-redux";
import { arePropsEqual } from "../../../utils/js";

const _ColumnsPopover = React.forwardRef(
  (
    {
      // selectedColumns: parentSelectColumns,
      // , setSelectedColumns,
      type = "products",
      allColumns = [],
      setSelectedColumns: setParentSelectedColumns,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [columns, setColumns] = React.useState(allColumns);
    const [search, setSearch] = React.useState("");
    const [selectedColumns, _setSelectedColumns] = React.useState({});
    const selectedProductColumns = useSelector(
      (state) => state.persist.selectedProductColumns
    );

    const selectedCRMColumns = useSelector(
      (state) => state.persist.selectedCRMColumns
    );
    const selectedChatsColumns = useSelector(
      (state) => state.persist.selectedChatsColumns
    );
    const custom_fields = useSelector(
      (state) => state?.settings?.custom_fields
    );

    const setSelectedColumns = (payload) => {
      _setSelectedColumns((state) => ({ ...state, ...payload }));
    };

    React.useEffect(() => {
      setTimeout(() => {
        setParentSelectedColumns(selectedColumns);
      }, 0);
    }, [selectedColumns]);

    // // React.useEffect(() => {
    // //   setTimeout(() => {
    // //     if (
    // //       Object.keys(parentSelectColumns)?.length !==
    // //       Object.keys(selectedColumns)?.length
    // //     )
    // //       setSelectedColumns(selectedColumns);
    // //   }, 0);
    // // }, [parentSelectColumns]);

    React.useEffect(() => {
      if (type === "products")
        if (!isObjWithValues(selectedProductColumns))
          _setSelectedColumns({ ...defaultSelectedColumns?.[type] });
        else _setSelectedColumns({ ...selectedProductColumns });
      if (type === "crm") {
        if (!isObjWithValues(selectedCRMColumns))
          _setSelectedColumns({ ...defaultSelectedColumns?.[type] });
        else _setSelectedColumns({ ...selectedCRMColumns });
      }
      if (type === "chats") {
        if (!isObjWithValues(selectedChatsColumns))
          _setSelectedColumns({ ...defaultSelectedColumns?.[type] });
        else _setSelectedColumns({ ...selectedChatsColumns });
      }
      if (type === "crm") {
        let values = {};
        Object.values(custom_fields?.crm || {})?.map((i) => {
          values[i.value] = true;
        });
        _setSelectedColumns((state) => ({ ...state, ...values }));
      }
    }, [type, custom_fields]);

    React.useEffect(() => {
      if (search) {
        setColumns(
          allColumns.filter((i) =>
            JSON.stringify(i)?.toLowerCase()?.includes(search?.toLowerCase())
          )
        );
      } else setColumns(allColumns);
    }, [search]);

    React.useEffect(() => {
      setColumns(allColumns);
    }, [allColumns]);

    React.useImperativeHandle(ref, () => {
      return {
        open(e) {
          handleClick(e);
        },
      };
    });

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const onSelectAll = () => {
      let obj = {};
      allColumns.forEach((i) => {
        if (i.child)
          i.child.forEach((i) => {
            obj[i.value] = true;
          });
        else obj[i.value] = true;
      });
      setSelectedColumns(obj);
    };
    const onDisSelectAll = () => {
      let obj = {};
      allColumns.forEach((i) => {
        if (!i.mandatory && !i.disabled)
          if (i.child)
            i.child.forEach((i) => {
              obj[i.value] = false;
            });
          else obj[i.value] = false;
      });
      setSelectedColumns(obj);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          maxHeight: "400px",
          // boxShadow:
          //   "0rem 0.25rem 1.125rem -0.125rem #1f212414,0rem 0.75rem 1.125rem -0.125rem #1f212426",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}

        <Box sx={{ px: 3, py: 4, borderRadius: 10 }}>
          <Typography sx={{ ...secondaryLabelSmall }}>
            Filter Columns
          </Typography>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
            mt={1}
          >
            <Button size="small" onClick={onSelectAll}>
              Select all
            </Button>
            <Button size="small" onClick={onDisSelectAll}>
              Deselect all
            </Button>
          </Stack>
          {columns.map((i) => {
            if (i.child)
              return (
                <WithChildCheckboxes
                  key={i.value}
                  values={i.child}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                  label={i.label}
                  type={type}
                />
              );
            return (
              <CheckboxWrapper
                data={i}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                type={type}
              />
            );
          })}
        </Box>
      </Popover>
    );
  }
);
const ColumnsPopover = memo(_ColumnsPopover, (a, b) => arePropsEqual(a, b));

const CheckboxWrapper = React.memo(
  ({ data, selectedColumns, setSelectedColumns, type }) => {
    return (
      <FormGroup sx={{}} key={data.value}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={Boolean(selectedColumns?.[data.value])}
              disabled={data?.mandatory || data?.disabled}
            />
          }
          onChange={(event) =>
            setSelectedColumns({ [data.value]: event.target.checked })
          }
          mandatory={data?.mandatory || data?.disabled}
          label={
            <Stack direction={"row"} spacing={4} alignItems={"center"}>
              {data.label}
              {columnTooltips?.[type]?.[data.value] ? (
                <Tooltip
                  title={
                    <Typography style={{ fontSize: "13px" }}>
                      {columnTooltips?.[type]?.[data.value]}
                    </Typography>
                  }
                >
                  <InfoOutlined
                    style={{ marginLeft: "4px", height: "16px", width: "16px" }}
                  />
                </Tooltip>
              ) : null}
            </Stack>
          }
          sx={{ ...secondaryLabel }}
        />
      </FormGroup>
    );
  },
  (p, n) => {
    let value = p.data?.value;
    if (p.selectedColumns?.[value] !== n.selectedColumns?.[value]) {
      return false;
    }
    return true;
  }
);

const WithChildCheckboxes = React.memo(
  ({ values = [], setSelectedColumns, selectedColumns, label, type }) => {
    const [allchecked, setAllChecked] = React.useState(false);
    const [indeterminateChecked, setIndeterminateChecked] =
      React.useState(false);

    React.useEffect(() => {
      setAllChecked(
        values.map((i) => i.value)?.every((i) => Boolean(selectedColumns?.[i]))
      );
      setIndeterminateChecked(
        values.map((i) => i.value)?.some((i) => Boolean(selectedColumns?.[i]))
      );
    }, [selectedColumns, values]);

    const onParentPress = (e) => {
      let allValuesObj = {};
      values?.forEach((i) => (allValuesObj[i.value] = e.target.checked));
      setSelectedColumns({ ...allValuesObj });
      if (e.target.checked) {
        setAllChecked(true);
        setIndeterminateChecked(false);
      } else {
        setAllChecked(false);
        setIndeterminateChecked(true);
      }
    };

    return (
      <Box>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              checked={allchecked}
              indeterminate={indeterminateChecked}
              onChange={onParentPress}
              size="small"
            />
          }
          sx={{ ...secondaryLabel }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {values.map((i) => {
            return (
              <FormControlLabel
                label={
                  <Stack direction={"row"} spacing={4} alignItems={"center"}>
                    {i.label}
                    {columnTooltips?.[type]?.[i.value] ? (
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "13px" }}>
                            {columnTooltips?.[type]?.[i.value]}
                          </Typography>
                        }
                      >
                        <InfoOutlined
                          style={{
                            marginLeft: "4px",
                            height: "16px",
                            width: "16px",
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </Stack>
                }
                key={i.value}
                control={
                  <Checkbox
                    checked={Boolean(selectedColumns?.[i.value])}
                    onChange={(event) =>
                      setSelectedColumns({ [i.value]: event.target.checked })
                    }
                    size="small"
                  />
                }
                sx={{ ...secondaryLabel }}
              />
            );
          })}
        </Box>
      </Box>
    );
  }
);

export default ColumnsPopover;

const defaultSelectedColumns = {
  products: {
    sku: true,
    name: true,
    description: true,
    stock_status: true,
    stock_quantity: true,
    collections: true,
    status: true,
    categories: true,
    gold_gross: true,
    metal_types: true,
    gold_net: true,
  },
  crm: {
    first_name: true,
    last_name: true,
    email: true,
    contact_type: true,
    dialcode_mobile: true,
    phone: true,
    dialcode_whatsapp: true,
    whatsapp: true,
    address_1: true,
    address_2: true,
    city: true,
    postcode: true,
    state: true,
    country: true,
    gender: true,
    tags: true,
  },
  chats: {
    first_name: true,
    last_name: true,
    email: true,
    contact_type: true,
    dialcode_whatsapp: true,
    whatsapp: true,
    broadcast: true,
    tags: true,
  },
};
export { defaultSelectedColumns };
