import * as actions from "../../../../../../redux/actions/flowsActions";

export const onAddProductCatalog = async (dispatch, axis, id) => {
  const newNodeId = Date.now().toString();

  const newNode = {
    id: newNodeId,
    type: "product-catalog",
    data: { label: "Product catalog" },
    position: axis,
    allowJoinSide: "left",
    toJoinSide: "right",
    fieldData: [],
    userConnected: 0,
    isUser: false,
  };

  const edge = {
    id: Date.now(),
    source: id,
    target: newNodeId,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
  };

  await dispatch({
    type: actions.CURRENT_NODE_ID,
    payload: { nodeId: newNodeId },
  });
  dispatch({ type: actions.ADD_NODE, payload: { newNode, nodeId: id } });
  dispatch({ type: actions.ADD_EDGE, payload: edge });
  dispatch({
    type: actions.EDIT_ELEMENT_IN_REDUX,
    payload: { openProductCatalogSidebar: true },
  });
};
