export const userNodeStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeTitleBox: {
    // position: "relative",
    // top: "15px",
    // left: "13px",
    color: "#797b8c",
    textAlign: "center",
    // paddingRight: "35px",
  },
  nodeTitle: {
    // position: "relative",
    // right: "7px",
    fontWeight: 600,
    fontSize: "12px",
  },
  nodeBody: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "30px",
    minWidth: "30px",
    fontSize: "16px",
    // color: "#384350",
    backgroundColor: "#446DF6",
    borderRadius: "4px",
    transform: "rotate(45deg)",
    border: "0.7px solid #e2e5e9",
    marginBottom: "30px",
  },

  addIcon: {
    // fontSize: "10px",
    height: "20px",
    width: "20px",
    color: "black",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "11px",
    left: "0px",
    "&:hover": {
      color: "blue",
    },
  },
  personIcon: {
    fontSize: "20px",
    transform: "rotate(315deg)",
    marginLeft: "5px",
    color: "#ffffff",
    position: "relative",
    right: "5px",
    top: "-2px",
  },
  leftHandle: {
    background: "#566d89",
    opacity: "0",
    position: "relative",
    left: "-2px",
    bottom: "11px",
  },
  rightHandle: {
    background: "#566d89",
    opacity: "0",
    position: "relative",
    right: "-2px",
    bottom: "11px",
  },
};
