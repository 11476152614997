import { useState, useEffect } from "react";
import { Handle, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Fade, Tooltip } from "@mui/material";
import * as actions from "../../../../../../redux/actions/flowsActions";
import TelegramIcon from "@mui/icons-material/Telegram";
import NodeOptions from "../helper/NodeOptions";
import AddNodesButton from "../helper/AddNodesButton";
import NodePopover from "../helper/NodePopover";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import { botStyles } from "../styles/botStyles";
import "../styles/node.css";
import "../styles/node-title.css";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import { createUserNode } from "../helper/add-nodes/createUserNode";

const styles = botStyles;

const CustomBot = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const mode = useSelector((state) => state.flow?.mode);
  const currentNode = nodes.find((node) => node.id === nodeId);
  const title = currentNode?.fieldData?.title;
  const userConnected = currentNode?.userConnected;
  const fieldsData = currentNode?.fieldData?.fieldsData;
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [openNodePopover, setOpenNodePopover] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClick = (event) => {
    setOpenNodePopover(true);
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeAddPopover: true },
    });
  };

  const handleClose = () => {
    setOpenNodePopover(false);
    setAnchorEl(null);

    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        nodeAddPopover: false,
        actionsMenu: false,
      },
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  styles.nodeBody.backgroundColor = mode === "dark" ? "#e2e5e9" : "#f5f5f5";

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.OPEN_SIDEBAR_BOT });
  };

  useEffect(() => {
    const validTypes = ["quick-reply", "buttons"];
    if (fieldsData) {
      const quickReplyButtons = fieldsData.filter((buttonObj) =>
        validTypes.includes(buttonObj?.type)
      );
      const allButtons = quickReplyButtons.flatMap((obj) => obj?.buttons);
      const sendButtons = allButtons.filter(
        (btn) => btn.type === "send_message" && !btn?.userInputAdded
      );

      console.log(sendButtons);
      const processButtonsSequentially = async () => {
        let index = userConnected;
        for (const btns of sendButtons) {
          console.log(btns);
          btns.userInputAdded = true;
          console.log(btns);
          const btnsObj = { flag: true, buttonData: btns, count: index };
          index = index + 1;
          await createUserNode({ dispatch, nodes, nodeId, btnsObj });
        }
      };
      processButtonsSequentially();
    }
  }, [fieldsData]);

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ textAlign: "center" }}>
        {isHovered && !isDragging ? (
          <div style={{ paddingLeft: "25px" }}>
            <NodeOptions nodeId={nodeId} nodeType="bot" />
          </div>
        ) : (
          <div style={styles.blockTitleDiv}>
            <Typography variant="span" sx={styles.blockTitleText}>
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>

      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        {isHovered && isDragging && errorMessage !== null ? (
          <Tooltip
            arrow
            title={errorMessage}
            placement="top"
            open={true}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  padding: "10px",
                },
              },
            }}
          >
            <Box
              sx={{ ...styles.nodeBody, backgroundColor: "#494a49" }}
              onClick={handleMain}
            >
              <IconButton sx={styles.buttonIcon}>
                <TelegramIcon style={styles.icon} />
              </IconButton>
              <Typography variant="span" style={styles.titleName}>
                Bot Response
              </Typography>
              <Handle
                type="source"
                position="right"
                style={styles.rightHandle}
              />
              <Handle
                type="source"
                position="right"
                style={styles.rightHandle}
              />
            </Box>
          </Tooltip>
        ) : (
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <IconButton sx={styles.buttonIcon}>
              <TelegramIcon style={styles.icon} />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              Bot Response
            </Typography>
            <Handle type="source" position="right" style={styles.rightHandle} />
          </Box>
        )}
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
        <Fade in={openNodePopover} timeout={3000}>
          <div>
            <NodePopover
              nodeId={nodeId}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setOpenNodePopover={setOpenNodePopover}
            />
          </div>
        </Fade>
        <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
        />
      </Box>
    </Box>
  );
};

export default CustomBot;
