import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { actionStyles } from "./styles/actionsStyles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CategoryIcon from "@mui/icons-material/Category";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import * as actions from "../../../../../redux/actions/flowsActions";
import { Draggable } from "react-beautiful-dnd";
import TelegramIcon from "@mui/icons-material/Telegram";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

const styles = actionStyles;

const ActionsBox = () => {
  const dispatch = useDispatch();
  const whatsappIntegrated = useSelector(
    (state) => state.flow?.integration
  )?.whatsappIntegrated;
  const isDragging = useSelector((state) => state.flow?.isDragging);

  const handleClose = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { actionsMenu: false },
    });
  };

  const interactionsNode = [
    {
      id: "01",
      label: "Bot Response",
      value: "bot",
      icon: (
        <TelegramIcon sx={{ ...styles.nodeIcon, backgroundColor: "#f0f0f0" }} />
      ),
      bgColor: "#dcdcdc",
    },
    {
      id: "02",
      label: "User Input",
      value: "user",
      icon: (
        <PersonIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#446DF6", color: "#fff" }}
        />
      ),
    },
    {
      id: "05",
      label: "Questions",
      value: "questions",
      icon: (
        <QuestionMarkIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#ffad5c" }}
        />
      ),
    },
    {
      id: "08",
      label: "Live agent",
      value: "live-agent",
      icon: (
        <SupportAgentIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#008aff",
            color: "#F0F0F0",
          }}
        />
      ),
    },
  ];

  const actionsNode = [
    {
      id: "06",
      label: "Filter",
      value: "filter",
      icon: (
        <FilterAltOutlinedIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#686ad3", color: "#fff" }}
        />
      ),
    },
    {
      id: "07",
      label: "Go to step",
      value: "go-to-step",
      icon: (
        <SwapCallsSharpIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#ffdc66" }}
        />
      ),
    },
    {
      id: "09",
      label: "Subscribe",
      value: "subscribe",
      icon: (
        <BookmarkAddIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#9dca80" }}
        />
      ),
    },
    {
      id: "10",
      label: "Unsubscribe",
      value: "unsubscribe",
      icon: (
        <BookmarkRemoveIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#FB4B54",
            color: "#ffffff",
          }}
        />
      ),
    },
    {
      id: "14",
      label: "Delay",
      value: "delay",
      icon: (
        <HourglassBottomIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#bae8e8" }}
        />
      ),
    },
  ];

  const channelsNode = [
    {
      id: "03",
      label: "Whatsapp template",
      value: "whatsapp-template",
      icon: (
        <WhatsAppIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#25D366",
          }}
        />
      ),
    },
    {
      id: "04",
      label: "Product catalog",
      value: "product-catalog",
      icon: (
        <CategoryIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#00b3fa",
          }}
        />
      ),
    },
    {
      id: "11",
      label: "Email",
      value: "email",
      icon: (
        <EmailIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#20A4F3",
            color: "#f0f0f0",
          }}
        />
      ),
    },
    {
      id: "12",
      label: "Sms",
      value: "sms",
      icon: (
        <SmsIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#98E4FF",
            color: "#0F0808",
          }}
        />
      ),
    },
    {
      id: "13",
      label: "Push notification",
      value: "push-notification",
      icon: (
        <NotificationsActiveIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#86CB92" }}
        />
      ),
    },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.header}>
        <Typography sx={styles.label} variant="span">
          Interactions
        </Typography>
        <IconButton sx={styles.headerIcon} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={styles.nodeBox}>
        {interactionsNode &&
          interactionsNode.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(provided, snapshot) => (
                <Box
                  key={action.id}
                  sx={{
                    ...styles.node,
                    visibility:
                      !isDragging || snapshot.isDragging ? "visible" : "hidden",
                  }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {action?.icon}
                  <Typography sx={styles.nodeLabel} variant="span">
                    {action.label}
                  </Typography>
                </Box>
              )}
            </Draggable>
          ))}
      </Box>

      <Typography
        sx={{
          ...styles.label,
          position: "relative",
          right: "120px",
          marginBottom: "10px",
        }}
        variant="span"
      >
        Actions
      </Typography>
      <Box sx={styles.nodeBox}>
        {actionsNode &&
          actionsNode.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(provided, snapshot) => (
                <Box
                  key={action.id}
                  sx={{
                    ...styles.node,
                    visibility:
                      !isDragging || snapshot.isDragging ? "visible" : "hidden",
                  }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {action?.icon}
                  <Typography sx={styles.nodeLabel} variant="span">
                    {action.label}
                  </Typography>
                </Box>
              )}
            </Draggable>
          ))}
      </Box>

      <Typography
        sx={{
          ...styles.label,
          position: "relative",
          right: "120px",
          marginBottom: "10px",
        }}
        variant="span"
      >
        Channels
      </Typography>

      <Box sx={styles.nodeBox}>
        {channelsNode &&
          channelsNode.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(provided, snapshot) =>
                !whatsappIntegrated && action.id === "03" ? (
                  <Tooltip
                    title="WhatsApp not integrated!"
                    placement="bottom"
                    key={action.id}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          paddingTop: "7px",
                          backgroundColor: "#ff4545",
                          color: "white",
                          height: "20px",
                          fontSize: "14px",
                          textAlign: "center",
                        },
                      },
                    }}
                  >
                    <Box
                      key={action.id}
                      sx={{
                        ...styles.node,
                        visibility:
                          !isDragging || snapshot.isDragging
                            ? "visible"
                            : "hidden",
                        opacity:
                          action.id === "03" && !whatsappIntegrated ? 0.3 : 1,
                        "&:hover": {
                          cursor:
                            action.id === "03" && !whatsappIntegrated
                              ? "not-allowed"
                              : "pointer",
                        },
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {action?.icon}
                      <Typography sx={styles.nodeLabel} variant="span">
                        {action.label}
                      </Typography>
                    </Box>
                  </Tooltip>
                ) : (
                  <Box
                    key={action.id}
                    sx={{
                      ...styles.node,
                      visibility:
                        !isDragging || snapshot.isDragging
                          ? "visible"
                          : "hidden",
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {action?.icon}
                    <Typography sx={styles.nodeLabel} variant="span">
                      {action.label}
                    </Typography>
                  </Box>
                )
              }
            </Draggable>
          ))}
      </Box>
    </Box>
  );
};

export default ActionsBox;
