export const fallbackNodeStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeBody: {
    padding: "0px 10px 0px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "30px",
    fontSize: "16px",
    color: "#ffffff",
    backgroundColor: "#797b8c",
    borderRadius: "20px",
    "&:hover": {
      cursor: "not-allowed",
    },
    // zIndex: 1,
    marginBottom: "30px",
  },
  titleName: {
    margin: 0,
    padding: 0,
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 550,
  },
  addIcon: {
    fontSize: "14px",
    height: "20px",
    width: "20px",
    color: "black",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "13px",
  },
};
