import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  InputAdornment,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { whatsappTemplateStyles } from "./styles/whatsappTemplateStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import Header from "./components/Header";
import TemplateOptions from "./components/TemplateOptions";
import PreviewTemplate from "./components/PreviewTemplate";
import { createTemplateForBackend } from "./utils/createTemplateForBackend";

const styles = whatsappTemplateStyles;

const SidebarWhatsappTemplate = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
    id: null,
  });
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const whatsappTemplates = useSelector(
    (state) => state.flow?.whatsappTemplates
  );
  const [currentTemplateState, setCurrentTemplateState] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
    setPreviewTemplate(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (title) {
      const templateData = createTemplateForBackend({
        id: templateDetails.id,
        templates: whatsappTemplates,
        currentTemplate: currentTemplateState,
      });
      const submittedUserData = {
        title,
        fieldsData: templateDetails,
        currentSavedState: currentTemplateState,
        isUser: false,
        templateData,
      };
      dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: false });
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);
    const node = currentNode?.fieldData;
    if (node?.title && initialLoad) {
      setInitialLoad(false);
      setTitle(node.title);
      setTemplateDetails(node.fieldsData);
      setCurrentTemplateState(node.currentSavedState);
      setPreviewTemplate(true);
    } else {
      const currentTemplatePreview = whatsappTemplates?.find(
        (template) => template.id + "" === templateDetails.id + ""
      );
      setCurrentTemplateState((state) => ({ ...currentTemplatePreview }));
    }
  }, [userId, nodes, templateDetails?.id, initialLoad]);

  return (
    <Card style={styles.customCard}>
      <form>
        <Header
          handleClose={handleClose}
          handleCheck={handleCheck}
          setTitle={setTitle}
          titleError={titleError}
          title={title}
        />

        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Select Whatsapp Templates
            </Typography>
            <TextField
              size="small"
              sx={styles.optionsInput}
              onClick={handleClickPopover}
              placeholder="Choose template"
              value={templateDetails.name || ""}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TemplateOptions
            setPreviewTemplate={setPreviewTemplate}
            optionsAnchorEl={optionsAnchorEl}
            setOptionsAnchorEl={setOptionsAnchorEl}
            setTemplateDetails={setTemplateDetails}
          />
          {previewTemplate && (
            <Typography
              variant="span"
              style={{ ...styles.labels, marginTop: "10px" }}
            >
              Preview
            </Typography>
          )}
          {previewTemplate && (
            <PreviewTemplate
              templateDetails={templateDetails}
              currentTemplateState={currentTemplateState}
              setCurrentTemplateState={setCurrentTemplateState}
            />
          )}
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarWhatsappTemplate;
