/* eslint-disable react-hooks/exhaustive-deps */
import { Add, MoreVert, Search, Close } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { cloneElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  formatDate,
  formatServerValue,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isTimeNHoursBefore,
  jweroColorPalette,
  secondaryLabel,
  sectionLabel,
  StackRow,
  StyledTableRow,
  TabPanel,
  validateNumber,
} from "../../../helper";
import {
  getCampaignHistory,
  updateCampaignAnalytics,
} from "../../../redux/actions/chatActions";
import {
  deleteCampaign,
  getSegmentList,
} from "../../../redux/actions/crmActions";
import {
  SET_HELPER_DATA,
  setGlobalToast,
} from "../../../redux/actions/helperActions";
import {
  CampaignIconSvg,
  EmailCampaignSvg,
  PushNotificationSvg,
  SMSSvg,
  Whatsapp,
  WhatsappSvg,
} from "../../../Svgs";
import EmailBuilder from "../../campaign/components/EmailBuilder";
import CustomChip from "../../components/CustomChip";
import {
  EmailTemplateList,
  PredefinedEmailTemplate,
} from "../../email/EmailList";

import {
  CreateSegment,
  SelectSemgment as SelectSegment,
  startCampaignFromSocket,
} from "./EmailTemplate";
import { DashboardTotalCard } from "../../dashboards/Default";
import ReactApexChart from "react-apexcharts";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { campaignChannels } from "../../flowBuilder/SideBar";
import Flowlist from "../../flowBuilder/Flowlist";
import { getFlowsHistories } from "../../../redux/actions/campaignActions";

export const campaignChannelIcon = {
  campaign: <CampaignIconSvg />,
  email: <EmailCampaignSvg />,
  whatsapp: <WhatsappSvg />,
  sms: <SMSSvg />,
  push_notification: <PushNotificationSvg />,
};

const CampaignHistory = ({ setOpenEmailCampaign, onClose, isPhone }) => {
  const campaignHistory = useSelector((state) => state.chats.campaignHistory);
  const gettingCampaignHistory = useSelector(
    (state) => state.chats.gettingCampaignHistory
  );
  const campaignAnalytics = useSelector(
    (state) => state?.chats?.campaignAnalytics
  );
  const campaignHistoryFetched = useSelector(
    (state) => state.chats.campaignHistoryFetched
  );
  const campaignFlowHistory = useSelector(
    (state) => state.campaign.campaignFlowHistory
  );
  const totalAudience = useSelector((state) => state.campaign.totalAudience);
  const totalMessages = useSelector((state) => state.campaign.totalMessages);

  const openRateEmail = useSelector((state) => state.campaign.openRateEmail);
  const topIdealTime = useSelector((state) => state.campaign.topIdealTime);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  const [campaignType, setcampaignType] = useState("email");
  const [openCampaignOptions, setOpenCampaignOptions] = useState(false);
  const [dialogContentPage, setDialogContentPage] = useState("");
  const [selectCampaignType, setSelectedCampaignType] = useState("all");
  const [isEditSegment, setIsEditSegment] = useState({});
  const [emailBuilderPage, setImageBuilderPage] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const onCloseOptions = () => {
    setOpenCampaignOptions(false);
  };

  isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    let { hash, pathname } = location || {};
    // console.log(location);
    if (hash) {
      setcampaignType(hash?.replace("#", ""));
      navigate(pathname);
    }

    return () => {};
  }, [location]);

  useEffect(() => {
    if (!isObjWithValues(campaignHistory) && !gettingCampaignHistory)
      dispatch(getCampaignHistory());
    // if (!isObjWithValues(campaignFlowHistory)) dispatch(getFlowsHistories());
  }, []);

  useEffect(() => {
    if (isObjWithValues(campaignHistory)) {
      setRows(
        Object.values(campaignHistory || {})
          .sort(
            (a, b) =>
              new Date(b?.start_time).getTime() -
              new Date(a?.start_time).getTime()
          )
          .filter((i) => i.is_campaign)
      );
      dispatch(
        updateCampaignAnalytics(
          Object.values(campaignHistory || {})?.sort(
            (a, b) =>
              new Date(b?.start_time).getTime() -
              new Date(a?.start_time).getTime()
          )
        )
      );
    }
  }, [campaignHistory]);

  const onEditSegment = (segmentObj) => {
    console.log(segmentObj);
    setDialogContentPage("editSegment");
    setIsEditSegment(segmentObj);
  };

  useEffect(() => {
    if (isObjWithValues(emailBuilderPage)) {
      setDialogContentPage("updateTemplate");
      dispatch({
        type: SET_HELPER_DATA,
        payload: {
          campaignHistoryMaxWidth: "lg",
        },
      });
    } else {
      setDialogContentPage("");
      dispatch({
        type: SET_HELPER_DATA,
        payload: {
          campaignHistoryMaxWidth: "md",
        },
      });
    }
  }, [emailBuilderPage]);
  useEffect(() => {
    if (dialogContentPage === "createTemplate") {
      dispatch({
        type: SET_HELPER_DATA,
        payload: {
          campaignHistoryMaxWidth: "lg",
        },
      });
    }
  }, [dialogContentPage]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: jweroColorPalette,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",

        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors:
        // jweroColorPalette
        ["transparent"],
    },
    xaxis: {
      categories: campaignAnalytics?.topCampaign?.name || [],
    },
    yaxis: {
      title: {
        text: "Open rate",
      },
    },
    fill: {
      opacity: 1,
    },
  };

  let doneSetup = ["email", "whatsapp", "sms", "push_notification"];

  const onSearch = (e) => {
    setSearchTerm(e.target.value || "");
    let value = e?.target?.value;
    // if (page) setPage(0);
    // if (searchTimeOut) clearTimeout(searchTimeOut);
    // searchTimeOut = setTimeout(async () => {
    if (value && isObjWithValues(campaignHistory)) {
      let searched = Object.values(campaignHistory || {})
        .filter((obj) => {
          if (
            obj?.campaign_info?.name
              ?.toLowerCase()
              ?.includes(e?.target?.value?.toLowerCase())
          )
            return obj;
        })
        ?.filter((i) => {
          if (doneSetup?.includes(selectCampaignType))
            return (
              i.is_campaign &&
              i?.campaign_info?.campaign_type === selectCampaignType
            );
          else return i.is_campaign;
        })
        ?.sort(
          (a, b) =>
            new Date(b?.start_time).getTime() -
            new Date(a?.start_time).getTime()
        );
      // await sleep(200);
      setRows(searched);
    } else {
      setRows(
        Object.values(campaignHistory || {})
          .sort(
            (a, b) =>
              new Date(b?.start_time).getTime() -
              new Date(a?.start_time).getTime()
          )
          ?.filter((i) => {
            if (doneSetup?.includes(selectCampaignType))
              return (
                i.is_campaign &&
                i?.campaign_info?.campaign_type === selectCampaignType
              );
            else return i.is_campaign;
          })
      );
    }
    // }, 500);
  };

  const onChangeTab = (val) => {
    setSelectedCampaignType(val);
    if (doneSetup?.includes(val)) {
      let arr = Object.values(campaignHistory || {})
        .sort(
          (a, b) =>
            new Date(b?.start_time).getTime() -
            new Date(a?.start_time).getTime()
        )
        .filter(
          (i) => i.is_campaign && i?.campaign_info?.campaign_type === val
        );
      setRows(arr);
    } else
      setRows(
        Object.values(campaignHistory || {})
          .sort(
            (a, b) =>
              new Date(b?.start_time).getTime() -
              new Date(a?.start_time).getTime()
          )
          .filter((i) => i.is_campaign)
      );
  };

  return (
    <>
      <TabPanel value={""} index={dialogContentPage}>
        <div>
          <>
            <Stack sx={{ px: 3 }}>
              <StackRow justifyContent={"space-between"}>
                <Typography
                  variant="h3"
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <CampaignIconSvg color="action" /> Campaigns
                </Typography>
                {campaignType === "email_list" ? (
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => {
                      setDialogContentPage("createTemplate");
                      // onClose();
                      // setOpenEmailCampaign("email");
                    }}
                  >
                    {isPhone ? "Create" : "Create Template"}
                  </Button>
                ) : campaignType === "my_segment" ? (
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => {
                      setDialogContentPage("createSegment");
                      // onClose();
                      // setOpenEmailCampaign("email");
                    }}
                  >
                    {isPhone ? "Create" : "Create Segment"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    // endIcon={<ArrowDropDownOutlined />}
                    onClick={(e) => {
                      onClose && onClose();
                      setOpenCampaignOptions(true);
                      // handleClick(e);
                      // setOpenEmailCampaign("email");
                    }}
                  >
                    {isPhone ? "Create" : "New campaign"}
                  </Button>
                )}
              </StackRow>
              <Breadcrumbs aria-label="Breadcrumb" mt={1} px={2}>
                <Link component={NavLink} to="/">
                  Dashboard
                </Link>
                {/* {breadcrumbs?.map((obj) => {
                  if (obj?.link)
                    return (
                      <Link component={NavLink} to={obj?.link}>
                        {obj?.title}
                      </Link>
                    );
                  return <Typography>{obj.title}</Typography>;
                })} */}
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Marketing Automation
                </Typography>
              </Breadcrumbs>
            </Stack>

            {
              <DialogContent sx={{ py: 0, p: isPhone && 0 }}>
                {/* {campaignType === "email" && <Grid container spacing={3} sx={{ mt: 3, mb: 4 }}> */}
                <Collapse in={campaignType === "email"}>
                  <Grid container spacing={3} sx={{ mt: 3, mb: 4 }}>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <DashboardTotalCard
                        title={"Audience Reached"}
                        hideNavigate
                        // points={tooltipPoints?.channels}
                        value={totalAudience?.total || 0}
                        values={
                          Object.keys(totalAudience || {})
                            ?.filter((k) => k !== "total")
                            ?.map((k) => {
                              return {
                                title: campaignChannelIcon?.[k],
                                value: totalAudience?.[k],
                              };
                            })

                          //
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <DashboardTotalCard
                        title={"Total Messages"}
                        value={totalMessages?.total || 0}
                        values={
                          Object.keys(totalMessages || {})
                            ?.filter((k) => k !== "total")
                            ?.map((k) => {
                              return {
                                title: campaignChannelIcon?.[k],
                                // title: formatServerValue(k?.split("_")?.[0] || ""),
                                value: totalMessages?.[k],
                              };
                            })

                          //
                        }
                        // title={"Campaigns Created"}
                        hideNavigate
                        // points={tooltipPoints?.channels}
                        // value={campaignAnalytics?.totalCampaigns || 0}
                        // values={[
                        //   {
                        //     title: "Email",
                        //     value:
                        //       campaignAnalytics?.campaignLength?.email || 0,
                        //     // prefix: currencySymbols[currency],
                        //   },
                        //   {
                        //     title: "Whatsapp",
                        //     value:
                        //       campaignAnalytics?.campaignLength?.whatsapp || 0,
                        //     // prefix: currencySymbols[currency],
                        //   },
                        //   {
                        //     title: "SMS",
                        //     value: campaignAnalytics?.campaignLength?.sms || 0,
                        //     // prefix: currencySymbols[currency],
                        //   },
                        //   {
                        //     title: "Push ",
                        //     value:
                        //       campaignAnalytics?.campaignLength
                        //         ?.push_notification || 0,
                        //     // prefix: currencySymbols[currency],
                        //   },
                        // ]}
                        // graphData={conversationExtra?.conversationsByYear}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <DashboardTotalCard
                        title="Avg Campaign open rate"
                        // points={tooltipPoints?.contacts}
                        value={`${openRateEmail?.avgOpenRate || 0}%`}
                        values={[
                          {
                            title: "Avg Reopen rate",
                            value: `${openRateEmail?.avgReopenedRate || 0} %`,
                            // prefix: "%",
                          },
                          {
                            title: "Avg unopened rate",
                            value: `${openRateEmail?.avgUnopenedRate || 0}%`,
                            // prefix: "%",
                          },
                        ]}
                        hideNavigate
                        // graphData={conversationExtra?.conversationsStartedInaYear}
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <DashboardTotalCard
                        hideNavigate
                        title="Ideal open time"
                        hideGraph={true}
                        valueProps={{ fontSize: "20px", minHeight: "40px" }}
                        // points={tooltipPoints?.media}
                        value={topIdealTime?.[0]?.periodString || ""}
                        values={[
                          {
                            title: topIdealTime?.[1]?.periodString || "",
                            value:
                              `${topIdealTime?.[1]?.percentage || ""}%` || 0,
                            // prefix: currencySymbols[currency],
                          },
                          {
                            title: topIdealTime?.[2]?.periodString || "",
                            value:
                              `${topIdealTime?.[2]?.percentage || ""}%` || 0,
                            // prefix: currencySymbols[currency],
                          },
                        ]}
                        // graphData={conversationExtra?.allMediaInYear}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
                <Paper sx={{ p: 5, borderRadius: "16px", mt: 3 }}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ flexWrap: "wrap", rowGap: 1.5, mb: 3 }}
                  >
                    <Tabs
                      value={campaignType}
                      onChange={(e, newValue) => setcampaignType(newValue)}
                      TabIndicatorProps={{ style: { display: "none" } }}
                      variant="scrollable"
                      scrollButtons={false}
                    >
                      <Tab
                        value={"email"}
                        label={"Recent Campaigns"}
                        sx={{ fontSize: 18 }}
                      >
                        Email
                      </Tab>
                      <Tab
                        value={"email_list"}
                        label={"Templates"}
                        sx={{ fontSize: 18 }}
                      >
                        Template
                      </Tab>
                      <Tab
                        value={"my_segment"}
                        label={"Segments"}
                        sx={{ fontSize: 18 }}
                      />
                      <Tab
                        value={"flows"}
                        label={"Flows"}
                        sx={{ fontSize: 18 }}
                      />

                      {/* <Tab value={"whatsapp"} label={"Whatsapp"}>Whatsapp</Tab> */}
                    </Tabs>
                  </Stack>
                  <TabPanel value="email" index={campaignType}>
                    <>
                      <StackRow
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          borderBottom: 2,
                          borderBottomColor: "divider",
                          width: "100%",
                          flexWrap: "wrap",
                          rowGap: 2,
                        }}
                      >
                        <Tabs
                          value={selectCampaignType}
                          onChange={
                            (e, newValue) => onChangeTab(newValue)
                            // setSelectedCampaignType(newValue)
                          }
                          variant="scrollable"
                          scrollButtons={false}
                        >
                          <Tab value={"all"} label={"All"} />
                          <Tab
                            value={"email"}
                            label={"Email"}
                            iconPosition="start"
                            icon={<EmailCampaignSvg />}
                          />
                          <Tab
                            value={"whatsapp"}
                            label={"Whatsapp"}
                            iconPosition="start"
                            icon={<Whatsapp />}
                          />
                          <Tab
                            value={"sms"}
                            label={"SMS"}
                            iconPosition="start"
                            icon={<SMSSvg />}
                          />
                          <Tab
                            value={"push_notification"}
                            label={"Push Notification"}
                            iconPosition="start"
                            icon={
                              <PushNotificationSvg />
                              // <img
                              //   src="/static/img/brands/onesignal-circle.png"
                              //   width="23px"
                              //   height="23px"
                              //   alt="onesignal"
                              // />
                            }
                          />
                        </Tabs>
                        <TextField
                          sx={{ justifySelf: "end" }}
                          fullWidth={isPhone}
                          size="small"
                          placeholder="Search"
                          onChange={(e) => onSearch(e)}
                          value={searchTerm}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            ),
                            endAdornment: searchTerm && (
                              <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  onSearch({
                                    target: { value: "" },
                                  })
                                }
                              >
                                <Close />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </StackRow>
                      <EmailCampaignList
                        rows={rows}
                        fetched={campaignHistoryFetched}
                        fetching={gettingCampaignHistory}
                      />

                      {/* <div hidden={selectCampaignType !== "all"}>
                        <EmailCampaignList
                          rows={rows}
                          fetched={campaignHistoryFetched}
                          fetching={gettingCampaignHistory}
                        />
                      </div>
                      <div hidden={selectCampaignType !== "email"}>
                        <EmailCampaignList
                          rows={rows}
                          fetched={campaignHistoryFetched}
                          fetching={gettingCampaignHistory}
                        />
                      </div>
                      <div hidden={selectCampaignType !== "whatsapp"}>
                        <EmailCampaignList
                          rows={rows}
                          fetched={campaignHistoryFetched}
                          fetching={gettingCampaignHistory}
                        />
                      </div>
                      <div hidden={selectCampaignType !== "sms"}>
                        <EmailCampaignList
                          rows={rows}
                          fetched={campaignHistoryFetched}
                          fetching={gettingCampaignHistory}
                        />
                      </div>
                      <div hidden={selectCampaignType !== "push_notification"}>
                        <EmailCampaignList
                          rows={rows}
                          fetched={campaignHistoryFetched}
                          fetching={gettingCampaignHistory}
                        />
                      </div> */}
                    </>
                    {/* )} */}
                  </TabPanel>
                  <TabPanel value="email_list" index={campaignType}>
                    {/* campaignType */}
                    <AllTemplates
                      emailBuilderPage={emailBuilderPage}
                      setImageBuilderPage={setImageBuilderPage}
                      setDialogContentPage={setDialogContentPage}

                      // setCreateTemplate={setCreateTemplate}
                    />
                  </TabPanel>

                  <TabPanel value="my_segment" index={campaignType}>
                    {/* campaignType */}
                    <MySegment onEditSegment={onEditSegment} />
                  </TabPanel>
                  <TabPanel value="flows" index={campaignType}>
                    <Flowlist />
                  </TabPanel>
                </Paper>

                <Grid container sx={{ mt: 3 }} spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TopChannels />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ActiveCustomer campaignAnalytics={campaignAnalytics} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper sx={{ p: 6, borderRadius: "16px" }}>
                      <Typography sx={{ ...sectionLabel }}>
                        Top Campaigns
                      </Typography>

                      {campaignAnalytics?.topCampaign?.data?.find(
                        (num) => num > 0
                      ) ? (
                        <ReactApexChart
                          options={options}
                          series={campaignAnalytics?.topCampaign?.series || []}
                          type="bar"
                          height={350}
                        />
                      ) : (
                        <GraphEmptyState />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TopSegments campaignAnalytics={campaignAnalytics} />
                  </Grid>
                </Grid>
              </DialogContent>
            }
          </>
        </div>
      </TabPanel>
      <TabPanel value={"updateTemplate"} index={dialogContentPage}>
        {/* {dialogContentPage === "updateTemplate" && ( */}
        <div>
          <EmailBuilder
            isEdit={emailBuilderPage}
            setIsEdit={setImageBuilderPage}
            goBack={() => setImageBuilderPage(false)}
          />
        </div>
        {/* )} */}
      </TabPanel>
      <TabPanel value={"createTemplate"} index={dialogContentPage}>
        {/* {dialogContentPage === "createTemplate" && ( */}
        <div>
          <EmailBuilder
            // isEdit={emailBuilderPage}
            // setIsEdit={setImageBuilderPage}
            goBack={() => setDialogContentPage("")}
          />
        </div>
        {/* )} */}
      </TabPanel>
      <TabPanel value={"editSegment"} index={dialogContentPage}>
        <CreateSegment
          setSelectedPage={setDialogContentPage}
          isEdit={isEditSegment}
        />
      </TabPanel>
      <TabPanel value={"createSegment"} index={dialogContentPage}>
        <CreateSegment
          setSelectedPage={setDialogContentPage}
          // isEdit={isEditSegment}
        />
      </TabPanel>
      <TabPanel value={"predefined"} index={dialogContentPage}>
        <PredefinedEmailTemplate
          setIsEdit={setImageBuilderPage}
          setSelectedPage={setDialogContentPage}
          // isEdit={isEditSegment}
        />
      </TabPanel>

      <Drawer
        anchor={isPhone ? "bottom" : "right"}
        open={openCampaignOptions}
        sx={{
          ".MuiDrawer-paperAnchorRight": {
            // borderTopLeftRadius: 8,
            // borderTopRightRadius: 8,
            mt: "15vh",
            mr: "3vh",
            borderRadius: 4,
            height: "80vh",
            // minHeight: "50vh",
            width: "35vw",
          },
          ".MuiDrawer-paperAnchorBottom": {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            // mt:"15vh",
            // mr:"2vh",
            // borderRadius: 8,
            minHeight: "50vh",
            // width: "35vw",
          },
        }}
        onClose={onCloseOptions}
      >
        {/* <Card> */}
        <CardContent

        // sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 3, }}
        >
          <Stack
            gap={3}
            alignItems={"center"}
            sx={{ border: 1, borderRadius: 4, borderColor: "divider", p: 5 }}
          >
            <Typography variant="h6" textAlign={"center"}>
              Multi-channel Campaign
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={3}
            >
              {campaignChannels?.map((o) => o.icon)}
            </Stack>
            <Typography variant="subtitle1" textAlign={"center"}>
              Create an advanced flow with multiple channels and conditions
            </Typography>
            {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
              Coming Soon
            </Typography> */}
            {/* {isDev() && ( */}
            <Button
              variant="outlined"
              onClick={() => {
                // if (isDev()) {
                navigate(`/campaign-flow`);
                onCloseOptions();
                // }
              }}
            >
              Create Campaign
            </Button>
            {/* )} */}
          </Stack>
        </CardContent>
        <Typography variant="subtitle1" textAlign={"center"} sx={{ my: 2 }}>
          OR
        </Typography>

        <Typography variant="h6" textAlign={"center"} sx={{ mb: 1.5 }}>
          Create Single Channel Campaign
        </Typography>
        <Stack
          flexWrap={"wrap"}
          gap={3}
          rowGap={3}
          direction={"row"}
          justifyContent={"center"}
        >
          {campaignChannels?.map((o) => {
            // let IconComponent = (props) => {
            //   return { ...(o.icon || {}, props) };
            // };
            return (
              <Card
                key={o?.label}
                sx={{
                  width: "30%",
                  border: 1,
                  borderColor: "divider",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => {
                  setOpenEmailCampaign(o.type);
                  onCloseOptions();
                }}
              >
                <CardContent>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                  >
                    {cloneElement(o.icon, {
                      // style: {
                      height: 40,
                      width: 40,
                      // },
                    })}
                    {/* {} */}
                    {/* <IconComponent height={30} width={30} /> */}
                    <Typography variant="subtitle2" textAlign={"center"}>
                      {" "}
                      {o?.label} Campaign
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            );
          })}
        </Stack>
        {/* </Card> */}

        {/* {list(anchor)} */}
      </Drawer>
    </>
  );
};

export default CampaignHistory;

const TopSegments = ({ campaignAnalytics }) => {
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    colors: jweroColorPalette,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,

        borderRadius: 12, // adjust the value as per your requirement
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories:
        campaignAnalytics?.topSegment?.ids?.map((id) => [
          customerSegments?.[id]?.filterForm?.segment_name || id,
        ]) || [],
      labels: {
        style: {
          colors: jweroColorPalette,
          fontSize: "12px",
          borderRadius: "8px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Campagin Sent",
      },
    },
  };
  return (
    <Paper sx={{ p: 6, borderRadius: "16px" }}>
      <Typography sx={{ ...sectionLabel }}>Top Segment</Typography>

      {campaignAnalytics?.topSegment?.data?.find((num) => num > 0) ? (
        <ReactApexChart
          options={options}
          series={[
            {
              name: "Segment",
              data: campaignAnalytics?.topSegment?.data || [],
            },
          ]}
          type="bar"
          height={350}
        />
      ) : (
        <GraphEmptyState />
      )}
    </Paper>
  );
};

const TopChannels = () => {
  const topChannelsData = useSelector(
    (state) => state.campaign.topChannelsData
  );
  const series = topChannelsData?.data || [];
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    colors: jweroColorPalette,
    labels: topChannelsData?.categories || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <Paper sx={{ p: 6, borderRadius: "16px" }}>
        <Typography sx={{ ...sectionLabel }}>Top Channels</Typography>

        {isArrayWithValues(topChannelsData?.data) ? (
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width={"87%"}
          />
        ) : (
          <GraphEmptyState />
        )}
      </Paper>
    </>
  );
};
const ActiveCustomer = () => {
  const topCustomerData = useSelector(
    (state) => state.campaign.topCustomerData
  );

  const series = [
    {
      data: topCustomerData?.data || [],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: jweroColorPalette,
    // colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        borderRadius: 12, // adjust the value as per your requirement
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: topCustomerData?.categories || [],
      //   [
      //   ["Whatsapp"],
      //   ["Email"],
      //   ["SMS"],
      //   ["Push", "Notification"],

      // ]
      labels: {
        style: {
          // colors: ,
          colors: jweroColorPalette,
          fontSize: "12px",
        },
      },
    },
  };
  return (
    <>
      <Paper sx={{ p: 6, borderRadius: "16px" }}>
        <Typography sx={{ ...sectionLabel }}>
          Top 10 Active Customers
        </Typography>

        {isArrayWithValues(topCustomerData?.data) ? (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        ) : (
          <GraphEmptyState />
        )}
      </Paper>
    </>
  );
};

const MySegment = ({ onEditSegment }) => {
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  return (
    <>
      <SelectSegment
        customerSegments={customerSegments}
        hideSelect={true}
        tableView={true}
        onEditSegment={onEditSegment}
      />
    </>
  );
};
export const getCategoryNameColor = (category) => {
  switch (category) {
    case "marketing":
      return "#4FD53A";

    case "promotional":
      return "#D2099A";

    case "tansactional":
    case "greetings":
      return "#2E5CC0";

    default:
      break;
  }
};

function getTotalLength(obj = {}) {
  // Use Object.values() to get an array of all the object's values (arrays)
  const arrays = Object.values(obj);

  // Use reduce() to accumulate the total length of all arrays
  const totalLength = arrays.reduce((acc, arr) => acc + arr.length, 0);

  return totalLength;
}

const Row = ({ row, index, customerSegments, hideAction }) => {
  let [totalSent, setTotalSent] = useState(0);
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showResume, setShowResume] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let { sent_info, total_customers, id, updated_on, status } = row;
    if (!isObjWithValues(sent_info)) return setTotalSent(0);
    let total = validateNumber(total_customers),
      success = 0;
    // console.log(sent_info?.limit_reached, "sent_info?.limit_reached");
    // if (isArrayWithValues(sent_info?.limit_reached))
    setShowResume(isTimeNHoursBefore(updated_on, 1) && status === "Completed");

    Object.keys(sent_info).map((i) => {
      // total += sent_info[i]?.length || 0;
      if (i === "success") success = sent_info[i]?.length;
    });
    setTotalSent(validateNumber(Math.round((success / total) * 100)));
  }, [row]);

  const resumeCampaign = async (id) => {
    await startCampaignFromSocket({ id });
    dispatch(
      setGlobalToast({
        show: true,
        message: "Campaign has been resumed",
        severity: "success",
      })
    );
  };

  return (
    <TableRow key={row?.campaign_info?.id}>
      <TableCell padding="checkbox" align="left">
        <Typography sx={{ ml: 3, fontWeight: "600" }}>{index + 1}</Typography>
      </TableCell>

      <TableCell component="th" scope="row">
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
        >
          {row?.campaign_info?.name}
        </Typography>
        <Typography sx={{ ...secondaryLabel }}>
          {row.campaign_info?.template_name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "13px",
            whiteSpace: "nowrap",
            color: getCategoryNameColor(row?.campaign_info?.category || ""),
          }}
        >
          {formatServerValue(row?.campaign_info?.category || "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          title={formatServerValue(row?.campaign_info?.campaign_type || "")}
        >
          <Box sx={{ cursor: "pointer" }}>
            {campaignChannelIcon?.[row?.campaign_info?.campaign_type]}
          </Box>
        </Tooltip>
      </TableCell>

      <TableCell component="th" scope="row">
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
        >
          {row.start_time
            ? formatDate(new Date(row.start_time), "dd mm yyyy")
            : ""}
        </Typography>
        <Typography sx={{ fontSize: "13px", whiteSpace: "nowrap" }}>
          {row.start_time
            ? formatDate(new Date(row.start_time), "hh:mm am/pm")
            : ""}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          variant="h6"
          sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
        >
          {
            customerSegments?.[row?.campaign_info?.segment_id]?.filterForm
              ?.segment_name
          }
        </Typography>
        <Typography sx={{ fontSize: "13px", whiteSpace: "nowrap" }}>
          {row?.total_customers ? `${row?.total_customers} contacts` : "-"}
          {/* {row?.total_customers} contacts */}
        </Typography>
      </TableCell>
      <TableCell>
        <CustomChip
          label={
            row?.campaign_info?.draft
              ? "Draft"
              : row?.status
              ? row?.status
              : row?.end_time
              ? "Completed"
              : "In Process"
          }
          type={
            row?.campaign_info?.draft
              ? "Draft"
              : row.status === "Processing"
              ? "pending"
              : row?.end_time
              ? "completed"
              : "pending"
          }
          //   color={row?.end_time ? "success" : "warning"}
          size="small"
          isDarkTheme={isDarkTheme}
        />
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        {row?.sent_info ? (
          <>
            <Typography
              variant="h6"
              sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
            >
              {row?.sent_info?.success?.length} Contacts
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              width={"100%"}
              gap={3}
              rowGap={2}
            >
              <Typography sx={{ fontSize: "13px", whiteSpace: "nowrap" }}>
                {totalSent}%
              </Typography>
              <LinearProgress
                sx={{ flexGrow: 1, borderRadius: "5px" }}
                variant="determinate"
                value={Number(totalSent)}
                color={"success"}
              />
              {/* </Stack> */}
            </Stack>
          </>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        {row?.campaign_info?.campaign_type === "email" ? (
          <>
            <Typography>
              Opened : {Object.keys(row?.opened || {})?.length}
            </Typography>
            <Typography>
              Re-Opened : {Object.keys(row?.reopened || {})?.length}
            </Typography>
          </>
        ) : (
          <Typography>N/A</Typography>
        )}
      </TableCell>
      {hideAction ? null : (
        <TableCell>
          <IconButton
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      )}

      <CampaignOption
        anchorEl={anchorEl}
        handleClose={handleClose}
        row={row}
        resumeCampaign={resumeCampaign}
        showResume={showResume}
      />
    </TableRow>
  );
};

export const EmailCampaignList = ({
  rows = [],
  fetched,
  fetching,
  hideAction,
}) => {
  const customerSegments = useSelector((state) => state.chats.customerSegments);

  // const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    if (!isObjWithValues(customerSegments)) dispatch(getSegmentList());
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {fetching ? (
        <Box
          sx={{
            my: "28vh",
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      {fetched && !fetching && !isArrayWithValues(rows) ? (
        // &&
        // !isArrayWithValues(
        //   rows?.filter((obj) => obj?.campaign_info?.campaign_type === "email")
        // )
        <EmptyState
          icon={
            <CampaignIconSvg
              // color="text.secondary"
              height={140}
              width={140}
              strokeColor={"#d7d7d7"}
              strokeWidth={1.4}
            />
          }
          text="No campaigns found"
        />
      ) : null}
      {fetched && !fetching && isArrayWithValues(rows) && (
        // isArrayWithValues(
        //   rows?.filter((obj) => obj?.campaign_info?.campaign_type === "email")
        // ) &&
        <TableContainer sx={{ mt: 2 }}>
          <Table sx={{}} aria-label="custom pagination table">
            <TableHead>
              <StyledTableRow>
                <TableCell>Sr.</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Campaign name
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Channel</TableCell>

                {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                    Template name
                  </TableCell> */}

                {/* <TableCell>Category</TableCell> */}
                <TableCell>Sent on</TableCell>

                {/* <TableCell>Segment title</TableCell> */}
                <TableCell>
                  Sent to
                  {/* <Typography sx={{ ...secondaryLabel }}>
                      (Segment title)
                    </Typography> */}
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Delivered to</TableCell>
                <TableCell>Views</TableCell>
                {hideAction ? null : <TableCell>Action</TableCell>}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows
                    // .filter(
                    //   (obj) => obj?.campaign_info?.campaign_type === "email"
                    //   // campaignType
                    // )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row, index) => {
                return (
                  <Row
                    key={index}
                    row={row}
                    index={index}
                    hideAction={hideAction}
                    customerSegments={customerSegments}
                  />
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {rows?.length > 5 || true ? (
              <TableFooter>
                <TableRow>
                  {hideAction ? null : (
                    <TablePagination
                      rowsPerPageOptions={[5, { label: "All", value: -1 }]}
                      // colSpan={3}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      // ActionsComponent={TablePaginationActions}
                    />
                  )}
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const AllTemplates = ({
  setImageBuilderPage,
  emailBuilderPage = { emailBuilderPage },
  setDialogContentPage,
  // setCreateTemplate
}) => {
  return (
    <>
      <EmailTemplateList
        action={true}
        hideHeading
        isEdit={emailBuilderPage}
        setIsEdit={setImageBuilderPage}
        setSelectedPage={setDialogContentPage}
        // setCreateTemplate={setCreateTemplate}
      />
    </>
  );
};

const CampaignOption = ({
  anchorEl,
  handleClose,
  row,
  resumeCampaign: _resumeCampaign,
  showResume,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  ///delete
  const [anchorElDelete, setAnchorElDelete] = React.useState(null);
  // const openDelete = Boolean(anchorEl);
  const handleClickDelete = (event) => {
    setAnchorElDelete(anchorEl);
    handleClose();
  };
  const resumeCampaign = async (id) => {
    await _resumeCampaign(id);
    handleClose();
  };
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  const onDeleteClick = () => {
    dispatch(
      deleteCampaign({
        obj: row,
        setLoading: setDeleteLoading,
        onSuccess: handleCloseDelete,
      })
    );
  };
  return (
    <>
      <DeletePopover
        anchorEl={anchorElDelete}
        handleClose={handleCloseDelete}
        onDelete={onDeleteClick}
        deleteLoading={deleteLoading}
        title={`Are you sure you want to delete Campaign ${
          row?.campaign_info?.name || ""
        }`}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,

          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            // mt: 1.5,
            // borderRadius: "16px",
            // width: 300,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              borderRadius: 8,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Clone</MenuItem> */}
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
        {(showResume || isDev()) && (
          <MenuItem onClick={() => resumeCampaign(row?.id)}>
            Resume Campaign
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate(`${row?.id}`)}>View</MenuItem>
      </Menu>
    </>
  );
};

const DeletePopover = ({
  anchorEl,
  handleClose,
  title,
  onDelete,
  deleteLoading,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,

        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "16px",
          width: 300,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            borderRadius: 8,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <DialogContent>
        <Typography variant="h6">
          {title}
          {/* Are you sure you want to delete Segment{" "} */}
          {/* {deleteTemplateObj?.filterForm?.segment_name} */}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={deleteLoading}
          color="error"
          onClick={() => {
            onDelete && onDelete();
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Popover>
  );
};

const GraphEmptyState = (props) => {
  return (
    <>
      <EmptyState text={"No Data Found"} {...props} />
    </>
  );
};
