import { useSelector } from "react-redux";
import { Popover, Box, Typography } from "@mui/material";
import { optionPopoverStyles } from "../styles/optionPopoverStyles";

const styles = optionPopoverStyles;

const TemplateOptions = ({
  setPreviewTemplate,
  optionsAnchorEl,
  setOptionsAnchorEl,
  setTemplateDetails,
}) => {
  const open = Boolean(optionsAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const whatsappTemplates = useSelector(
    (state) => state.flow?.whatsappTemplates
  );

  const handleClosePopover = () => {
    setOptionsAnchorEl(null);
  };

  const handleOptionClick = (type, id) => {
    setPreviewTemplate(true);
    setTemplateDetails({
      name: type,
      id,
    });
    handleClosePopover();
  };

  return (
    <Popover
      sx={{ marginTop: "4px" }}
      id={id}
      open={open}
      anchorEl={optionsAnchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={styles.outerBox}>
        {whatsappTemplates?.length > 0 &&
          whatsappTemplates?.map((template) => (
            <Box
              sx={styles.innerBox}
              key={whatsappTemplates?.id}
              onClick={() =>
                handleOptionClick(
                  `${template?.name.toUpperCase()} - ${template.category.toUpperCase()}`,
                  template.id
                )
              }
            >
              <Typography variant="span" sx={styles.blockName}>
                {`${template?.name.toUpperCase()}`}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};

export default TemplateOptions;
