import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { isObject } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  allEmptyCurrency,
  dialogStyle,
  duplicateArrayOfObjects,
  getRandomInt,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isObjectEqual,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabelSmall,
} from "../../helper";
import { currencySymbols } from "./Gold";
import {
  AmazonSvg,
  EbaySvg,
  GoogleShop,
  ShopifySvg,
  WooCommerceSvg,
} from "../../Svgs";
import CustomLabelSwitch from "../components/CustomLabelSwitch";
import SmallSwitch from "../components/SmallSwitch";

const colorTypes = [
  "default",
  "primary",
  "secondary",
  "success",
  "warning",
  "info",
];

const defaultAddForm = {
  collections: [],
  categories: [],
  subcategories: [],
  tags: [],
  marketplaces: [],
  cost_components: [],
  erp: [],
  title: "",
  margin: "",
  website: true,
  instore: false,
  apply_all_collections: false,
  apply_all_categories: false,
  apply_all_sub_categories: false,
  apply_all_tags: false,
};

const allMarketPlaces = [
  {
    label: "Amazon",
    Logo: AmazonSvg,
    custom_value: "add_to_amazon",
    value: "amazon",
  },
  {
    label: "Google Merchants",
    Logo: GoogleShop,
    custom_value: "allow_for_google_merchants",
    value: "google_shopping",
  },
  {
    label: "Shopify",
    Logo: ShopifySvg,
    custom_value: "add_to_shopify",
    value: "shopify",
  },
  {
    label: "Ebay",
    Logo: EbaySvg,
    custom_value: "add_to_ebay",
    value: "ebay",
  },
  {
    label: "WooCommerce",
    Logo: WooCommerceSvg,
    custom_value: "add_to_woocommerce",
    value: "woocommerce",
  },
];
const allERP = [
  {
    label: "Ornate",
    value: "ornate",
  },
  {
    label: "Jemisys",
    value: "jemisys",
  },
];
const allCostComponents = [
  {
    label: "Diamond",
    value: "diamond",
  },
  {
    label: "Gemstone",
    value: "gemstone",
  },
  {
    label: "Labour",
    value: "labour",
  },
];

const Margin = forwardRef((props, ref) => {
  let { isDefaultCurrency, defaultCurrency, selectedCurrency } = props;
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allProductTags, setAllProductTags] = useState([]);
  const [addForm, setAddForm] = useState(defaultAddForm);
  const [marginPricing, setMarginPricing] = useState(allEmptyCurrency);
  const [allCurrency, setAllCurrency] = useState([]);
  const [editId, setEditId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [error, setError] = useState(false);
  const [isProductCombinationDuplicate, setIsProductCombinationDuplicate] =
    useState(false);

  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);

  const [integratedMarketplaces, setIntegratedMarketplaces] = useState([]);

  const reduxWooCommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );
  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const reduxEbayIntegration = useSelector(
    (state) => state.settings.ebayIntegration
  );
  const reduxAmazonIntegration = useSelector(
    (state) => state.settings.amazonIntegration
  );
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  useEffect(() => {
    checkMarketplaceIntegration(reduxWooCommerceIntegration, "woocommerce");
  }, [reduxWooCommerceIntegration]);
  useEffect(() => {
    checkMarketplaceIntegration(reduxShopifyIntegration, "shopify");
  }, [reduxShopifyIntegration]);

  useEffect(() => {
    checkMarketplaceIntegration(reduxEbayIntegration, "ebay");
  }, [reduxEbayIntegration]);
  useEffect(() => {
    checkMarketplaceIntegration(reduxAmazonIntegration, "amazon");
  }, [reduxAmazonIntegration]);
  useEffect(() => {
    checkMarketplaceIntegration(reduxGoogleIntegration, "google_shopping");

    if (isObjWithValues(reduxGoogleIntegration)) {
      for (let obj of Object.values(reduxGoogleIntegration)) {
        let { connected } = obj;
        if (connected?.includes("google_shopping")) {
          // integratedMarketPlaces = [...integratedMarketPlaces, mName];
          setIntegratedMarketplaces((state) => [...state, "google_shopping"]);
          break;
        }
      }
    }
  }, [reduxGoogleIntegration]);

  useEffect(() => {
    checkDuplicateCombination();
  }, [addForm]);

  const checkDuplicateCombination = () => {
    let { collections, categories, subcategories, tags } = addForm;
    let allMargins = marginPricing[default_currency];
    console.log(allMargins, addForm);
    if (!isArrayWithValues(allMargins)) return;
    for (let combination of allMargins) {
      let {
        collections: _collections,
        categories: _categories,
        subcategories: _subcategories,
        tags: _tags,
        id,
      } = combination;
      let isProductDuplicate;
      // =
      //   duplicateArrayOfObjects(_collections, collections, "value") &&
      //   duplicateArrayOfObjects(_categories, categories, "value") &&
      //   duplicateArrayOfObjects(_subcategories, subcategories, "value") &&
      //   duplicateArrayOfObjects(_tags, tags, "value");

      if (
        (_collections?.length === 0 && collections?.length === 0) ||
        _collections?.find((i) => collections?.find((j) => j.value === i.value))
      )
        if (
          (_categories?.length === 0 && categories?.length === 0) ||
          _categories?.find((i) => categories?.find((j) => j.value === i.value))
        )
          if (
            (_subcategories?.length === 0 && subcategories?.length === 0) ||
            _subcategories?.find((i) =>
              subcategories?.find((j) => j.value === i.value)
            )
          )
            if (
              (_tags?.length === 0 && tags?.length === 0) ||
              _tags?.find((i) => tags?.find((j) => j.value === i.value))
            )
              isProductDuplicate = true;

      if (id === editId) isProductDuplicate = false;
      if (
        isProductDuplicate &&
        !isArrayWithValues(collections) &&
        !isArrayWithValues(categories) &&
        !isArrayWithValues(subcategories) &&
        !isArrayWithValues(tags)
      )
        isProductDuplicate = false;
      // let duplicateCollections = duplicateArrayOfObjects(
      //   _collections,
      //   collections,
      //   "value"
      // );
      // let duplicateCategories = duplicateArrayOfObjects(
      //   _categories,
      //   categories,
      //   "value"
      // );
      // let duplicateSubCategories = duplicateArrayOfObjects(
      //   _subcategories,
      //   subcategories,
      //   "value"
      // );
      // let duplicateTags = duplicateArrayOfObjects(_tags, tags, "value");
      console.log(isProductDuplicate, "<<<<< duplicate");
      setIsProductCombinationDuplicate(isProductDuplicate);
    }
  };

  // Checking marketplace integration status
  const checkMarketplaceIntegration = (mRedux, mName) => {
    if (isObjWithValues(mRedux) && mRedux.integrated) {
      if (!integratedMarketplaces.includes(mName)) {
        setIntegratedMarketplaces((prevArray) =>
          [...prevArray, mName]?.filter(
            (i) => !checkValueWasUsed(i, "marketplaces")
          )
        );
      }
    } else {
      setIntegratedMarketplaces((prevArray) =>
        prevArray.filter(
          (str) => str !== mName && !checkValueWasUsed(str, "marketplaces")
        )
      );
    }
  };
  const closeDelete = () => setShowDelete(false);
  const openDelete = (id) => {
    setShowDelete(id);
  };

  useImperativeHandle(ref, () => ({
    getMarginPricing() {
      return marginPricing;
    },
  }));

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.margin_pricing)
    )
      setMarginPricing(masterPricing.margin_pricing);
  }, [masterPricing]);

  const editAddForm = (payload) => {
    if (error) setError("");
    setAddForm((state) => ({ ...state, ...payload }));
  };
  const closeAddForm = () => {
    setEditId(null);
    setAddForm(defaultAddForm);
    setAddFormOpen(false);
  };
  const openAddForm = () => {
    setAddForm(defaultAddForm);
    setAddFormOpen(true);
  };

  useEffect(() => {
    if (isObjWithValues(taxonomies)) {
      let { categories, collections } = taxonomies;
      if (isArrayWithValues(collections)) {
        setAllCollections(collections);
      }
      if (isArrayWithValues(categories)) setAllCategories(categories);
      if (isArrayWithValues(taxonomies["sub-categories"]))
        setAllSubCategories(taxonomies["sub-categories"]);
      if (isArrayWithValues(taxonomies["tags"]))
        setAllProductTags(taxonomies["tags"]);
    }
  }, [taxonomies, marginPricing]);
  useEffect(() => {
    if (props.productSettings) {
      if (isArrayWithValues(props.productSettings.currency))
        setAllCurrency(props.productSettings.currency);
    }
  }, [props.productSettings]);
  /**
   * Check if value is already used in combinations
   */
  const checkValueWasUsed = (value, key) => {
    if (!editId) {
      let found = false;

      for (let obj of marginPricing?.[defaultCurrency]) {
        if (obj?.[key]?.find((j) => j?.value == value)) {
          found = true;
          break;
        }
      }
      return found;
    }
    return true;
  };

  const onAddCombination = () => {
    let {
      title,
      cost_components,
      margin,
      collections,
      categories,
      subcategories,
      tags,

      apply_all_collections,
      apply_all_categories,
      apply_all_sub_categories,
      apply_all_tags,
    } = addForm;
    let addedCombinations = marginPricing[default_currency];
    let isDuplicate = false;
    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...marginPricing[allCurrency[i].value]];
      for (let combination of array) {
        let { id, title, ...otherData } = combination;
        let { id: _id, title: _title, ...objectToCheck } = addForm;
        if (isObjectEqual(otherData, objectToCheck)) isDuplicate = true;
      }
    }
    if (isDuplicate)
      return setError("Margin pricing with same values is already added");

    if (
      !isArrayWithValues(collections) &&
      !isArrayWithValues(categories) &&
      !isArrayWithValues(subcategories) &&
      !isArrayWithValues(tags) &&
      !apply_all_collections &&
      !apply_all_categories &&
      !apply_all_sub_categories &&
      !apply_all_tags
    )
      return setError("Please select atleast one margin for product");

    if (!title) return setError("Please enter title");
    else if (addedCombinations?.find((i) => i.title === title))
      return setError("Title is already used, please enter different title");
    else if (!isArrayWithValues(cost_components))
      return setError("Please select atleast one Cost Component");
    else if (!margin) return setError("Please select Margin");
    let obj = {};
    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...marginPricing[allCurrency[i].value]];
      array.push({
        id: Math.floor(Date.now() / 1000),
        ...addForm,
        // minimum_making:
        //   defaultCurrency === allCurrency[i].value
        //     ? addForm.minimum_making
        //     : "",
        // per_gram:
        //   defaultCurrency === allCurrency[i].value ? addForm.per_gram : "",
      });
      obj[allCurrency[i].value] = array;
    }
    let { onMasterUpdate } = props;
    onMasterUpdate({
      margin_pricing: obj,
      setLoading,
      callback: () => {
        setAddFormOpen(false);
        setAddForm(defaultAddForm);
      },
      skipMasterUpdateHook: true,
    });
  };

  const onUpdateCombination = () => {
    let isDuplicate = false;

    let {
      title,
      cost_components,
      margin,
      collections,
      categories,
      subcategories,
      tags,

      apply_all_collections,
      apply_all_categories,
      apply_all_sub_categories,
      apply_all_tags,
    } = addForm;
    if (
      !isArrayWithValues(collections) &&
      !isArrayWithValues(categories) &&
      !isArrayWithValues(subcategories) &&
      !isArrayWithValues(tags) &&
      !apply_all_collections &&
      !apply_all_categories &&
      !apply_all_sub_categories &&
      !apply_all_tags
    )
      return setError("Please select atleast one margin for product");

    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...marginPricing[allCurrency[i].value]];
      for (let combination of array) {
        let { id, title, ...otherData } = combination;
        let { id: _id, title: _title, ...objectToCheck } = addForm;
        if (_id !== id && isObjectEqual(otherData, objectToCheck))
          isDuplicate = true;
      }
    }
    if (isDuplicate)
      return setError("Margin pricing with same values is already added");
    let updatedLabourPricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedLabourPricing[allCurrency[i].value] = marginPricing[
            `${allCurrency[i].value}`
          ].map((obj) => (obj.id === editId ? addForm : obj));
        else
          updatedLabourPricing[allCurrency[i].value] = marginPricing[
            `${allCurrency[i].value}`
          ].map((obj) =>
            obj.id === editId
              ? {
                  ...addForm,
                }
              : obj
          );
      }
    } else
      updatedLabourPricing = {
        ...marginPricing,
        [selectedCurrency]: marginPricing[selectedCurrency].map((i) =>
          i.id === editId
            ? {
                ...i,
                per_gram: addForm.per_gram,
                minimum_making: addForm.minimum_making,
                wastage: addForm.wastage,
              }
            : i
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedLabourPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedLabourPricing)[i]
        )
      )
        filteredObj[Object.keys(updatedLabourPricing)[i]] =
          updatedLabourPricing[Object.keys(updatedLabourPricing)[i]];
    // let updatedLabourPricing = [...marginPricing];
    // updatedLabourPricing = updatedLabourPricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    let { onMasterUpdate } = props;
    onMasterUpdate({
      margin_pricing: filteredObj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };
  const onDeleteCombination = () => {
    let id = showDelete;
    let updatedLabourPricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedLabourPricing[allCurrency[i].value] = marginPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
        else
          updatedLabourPricing[allCurrency[i].value] = marginPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
      }
    } else
      updatedLabourPricing = {
        ...marginPricing,
        [selectedCurrency]: marginPricing[selectedCurrency].filter(
          (i) => i.id === id
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedLabourPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedLabourPricing)[i]
        )
      )
        filteredObj[Object.keys(updatedLabourPricing)[i]] =
          updatedLabourPricing[Object.keys(updatedLabourPricing)[i]];
    // let updatedLabourPricing = [...marginPricing];
    // updatedLabourPricing = updatedLabourPricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    let { onMasterUpdate } = props;
    onMasterUpdate({
      margin_pricing: filteredObj,
      setLoading,
      callback: () => setShowDelete(false),
      skipMasterUpdateHook: true,
    });
  };

  const onEditPress = (obj) => {
    setEditId(obj.id);
    setAddForm({ ...obj });
    setAddFormOpen(true);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box>
      {/* <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": { paddingBottom: "16px" },
          }}
        >
        </CardContent>
      </Card> */}
      <Stack
        alignItems={"center"}
        justifyContent="flex-end"
        direction="row"
        // p={2}
        // border="0.5px solid #d1d1d1 "
        // borderRadius={"6px"}
      >
        {/* <Typography>How do you want to set price?</Typography> */}

        <Button
          size="small"
          onClick={!isDefaultCurrency ? null : openAddForm}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          disabled={!isDefaultCurrency}
        >
          Add New Combination
        </Button>
      </Stack>
      {isIpad && (
        <Box>
          {isArrayWithValues(marginPricing[selectedCurrency]) &&
            marginPricing[selectedCurrency].map((row) => (
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Box key={row.id}>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h6">{row.title}</Typography>
                      <IconButton size="small" onClick={() => onEditPress(row)}>
                        <Edit />
                      </IconButton>
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      Collections
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.collections) &&
                        row.collections.map((collection, i) => {
                          return (
                            <Typography>
                              {collection.label}
                              {i !== row.collections.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      Categories
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.categories) &&
                        row.categories.map((category, i) => {
                          return (
                            <Typography>
                              {category.label}
                              {i !== row.categories.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      MarketPlaces
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.marketplaces) &&
                        row.marketplaces.map((category, i) => {
                          return (
                            <Typography>
                              {category.label}
                              {i !== row.marketplaces.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      ERP
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      sx={{
                        border: "0.4px solid #bababa",
                        p: 1,
                        py: 2,
                        mt: 1,
                        borderRadius: "8px",
                      }}
                    >
                      {isArrayWithValues(row.erp) &&
                        row.erp.map((category, i) => {
                          return (
                            <Typography>
                              {category.label}
                              {i !== row.erp.length - 1 ? `, ` : ""}
                            </Typography>
                          );
                        })}
                    </Stack>
                    <Typography fontSize="14px" mt={2}>
                      Margin: {row.margin}
                    </Typography>
                    {/* <Typography fontSize="14px" mt={2} mb={1}>
                      Info :
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Wastage: </strong>{" "}
                          {currencySymbols[selectedCurrency]}
                          {row.wastage}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Per gram: </strong>
                          {currencySymbols[selectedCurrency]}
                          {row.per_gram}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body1">
                          <strong>Min Making: </strong>
                          {currencySymbols[selectedCurrency]}
                          {row.minimum_making}{" "}
                        </Typography>
                      </Grid>
                    </Grid> */}
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Box>
      )}
      {!isIpad && (
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,
            borderRadius: "10px",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="left">Collections</TableCell>
                <TableCell align="left">Categories</TableCell>
                {/* <TableCell align="left">MarketPlaces</TableCell> */}
                <TableCell align="left">ERP</TableCell>
                <TableCell align="left">Margin</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArrayWithValues(marginPricing[selectedCurrency]) &&
                marginPricing[selectedCurrency].map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => onEditPress(row)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="left">
                      <Stack>
                        {isArrayWithValues(row.collections) &&
                          row.collections.map((collection) => {
                            return (
                              <Stack
                                my={1}
                                direction="row"
                                alignItems={"center"}
                              >
                                <Typography>{collection.label}</Typography>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {isArrayWithValues(row.categories) &&
                        row.categories.map((category) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{category.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    {/* <TableCell align="left">
                      {isArrayWithValues(row.marketplaces) &&
                        row.marketplaces.map((marketplace) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{marketplace.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell> */}
                    <TableCell align="left">
                      {isArrayWithValues(row.erp) &&
                        row.erp.map((erp) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{erp.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row?.margin}</Typography>
                    </TableCell>
                    {/* <TableCell align="left">
                      <Box>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Wastage: {row.wastage}%
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Per gram:{" "}
                            {`${currencySymbols[selectedCurrency]} ${row.per_gram}`}{" "}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography variant="body1">
                            Min Making:
                            {`${currencySymbols[selectedCurrency]} ${row.minimum_making}`}{" "}
                          </Typography>
                        </Stack>
                      </Box>
                    </TableCell> */}
                    <TableCell align="left">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          openDelete(row.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Delete Margin combination?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be done
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addFormOpen}
        onClose={closeAddForm}
        maxWidth="md"
        fullWidth
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>{editId ? "Update" : "Add"} Combination</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                variant="standard"
                value={addForm.title}
                onChange={(e) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ title: e.target.value })
                }
                sx={{ mt: 0.4 }}
                size="small"
                label="Title"
                fullWidth
                disabled={!isDefaultCurrency}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.cost_components}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCostComponents}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Margin to be applied on"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ cost_components: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.margin}
                onChange={(e) => editAddForm({ margin: e.target.value })}
                size="small"
                label="Margin"
                fullWidth
                type="number"
                sx={{ mt: 1 }}
                variant="standard"
                inputProps={{ maxLength: 2 }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                InputProps={{
                  // endAdornment: (
                  //   <InputAdornment position="end">On Weight</InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                // disabled={!isDefaultCurrency}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid item xs={12}>
              <Typography sx={{ ...primaryLabel, fontSize: "13px" }}>
                Margin for Product
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.collections}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCollections}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Collections"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ collections: value })
                }
                disabled={!isDefaultCurrency || addForm.apply_all_collections}
              />
              <SmallLabelSwitch
                // tooltip={"All collections will be automatically added"}
                label={"Apply margin to all Collections"}
                checked={addForm.apply_all_collections}
                onChange={(e) => editAddForm({ apply_all_collections: e })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.categories}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCategories}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ categories: value })
                }
                disabled={!isDefaultCurrency || addForm.apply_all_categories}
              />

              <SmallLabelSwitch
                // tooltip={"All categories will be automatically added"}
                label={"Apply margin to all Categories"}
                checked={addForm.apply_all_categories}
                onChange={(e) => editAddForm({ apply_all_categories: e })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.subcategories}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allSubCategories}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub Categories"
                    variant="standard"
                  />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ subcategories: value })
                }
                disabled={
                  !isDefaultCurrency || addForm.apply_all_sub_categories
                }
              />

              <SmallLabelSwitch
                // tooltip={"All Sub categories will be automatically added"}
                label={"Apply margin to all Sub Categories"}
                checked={addForm.apply_all_sub_categories}
                onChange={(e) => editAddForm({ apply_all_sub_categories: e })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.tags}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allProductTags}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ tags: value })
                }
                disabled={!isDefaultCurrency || addForm.apply_all_tags}
              />

              <SmallLabelSwitch
                // tooltip={"All Sub categories will be automatically added"}
                label={"Apply margin to all Tags"}
                checked={addForm.apply_all_tags}
                onChange={(e) => editAddForm({ apply_all_tags: e })}
              />
            </Grid>
          </Grid>
          <Collapse in={isProductCombinationDuplicate}>
            <Grid item xs={12}>
              <Alert severity="error" sx={{ mt: 3 }}>
                Product combination already selected
              </Alert>
            </Grid>
          </Collapse>
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid item xs={12}>
              <SectionTitle title="Apply Margin on Product Imported via" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.erp}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allERP}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="ERP" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ erp: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid item xs={12}>
              <Typography sx={{ ...primaryLabel, fontSize: "13px" }}>
                Enable margin for
              </Typography>
            </Grid>
            {/* {isDev() && (
            )} */}
            <Grid item xs={12} md={6}>
              <ShowIconsList
                icons={allMarketPlaces?.filter((i) =>
                  integratedMarketplaces?.includes(i.value)
                )}
                value={addForm.marketplaces}
                onClick={(value) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ marketplaces: value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ maxWidth: 130 }}>
                <CustomLabelSwitch
                  label={"Website"}
                  labelProps={{ sx: { ...primaryLabel } }}
                  checked={addForm.website}
                  onChange={(e) => editAddForm({ website: e })}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <CustomLabelSwitch
                label={"Sales App"}
                labelProps={{ sx: { ...primaryLabel } }}
                checked={addForm.instore}
                onChange={(e) => editAddForm({ instore: e })}
              />
            </Grid> */}

            {/* 
          </Grid>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} md={6}>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Labour calculation from?
                </Typography>
                <FormControl size="small" disabled={!isDefaultCurrency}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.labour_from}
                    // label="Age"
                    onChange={(e) =>
                      !isDefaultCurrency
                        ? null
                        : editAddForm({ labour_from: e.target.value })
                    }
                  >
                    <MenuItem value={"gross"}>Gross</MenuItem>
                    <MenuItem value={"net"}>Net</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.wastage}
                onChange={(e) =>
                  // !isDefaultCurrency
                  //   ? null
                  //   :
                  editAddForm({ wastage: e.target.value })
                }
                size="small"
                label="Labour Wastage?"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">On Weight</InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                // disabled={!isDefaultCurrency}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.per_gram}
                onChange={(e) => editAddForm({ per_gram: e.target.value })}
                size="small"
                label="Per gram"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">On Labour</InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.minimum_making}
                onChange={(e) =>
                  editAddForm({ minimum_making: e.target.value })
                }
                size="small"
                label="Min Making"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                  // startAdornment: (
                  //   <InputAdornment position="start">₹</InputAdornment>
                  // ),
                }}
              ></TextField>
            </Grid> */}
          </Grid>
        </DialogContent>

        <Collapse in={Boolean(error)}>
          <Grid item xs={12}>
            <Alert severity="error" sx={{ mt: 3, mx: 3 }}>
              {error}
            </Alert>
          </Grid>
        </Collapse>
        <DialogActions>
          <Button onClick={closeAddForm}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={editId ? onUpdateCombination : onAddCombination}
            disabled={isProductCombinationDuplicate}
          >
            {editId ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default Margin;

const SmallLabelSwitch = ({ checked, onChange, label, tooltip }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      mt={1}
      justifyContent={"flex-end"}
      alignItems={"center"}
    >
      <Tooltip title={tooltip}>
        <Typography sx={{ ...secondaryLabelSmall, mr: 2 }}>{label}</Typography>
      </Tooltip>
      <SmallSwitch usePrimaryColor checked={checked} onChange={onChange} />
    </Stack>
  );
};

const ShowIconsList = ({ icons, value, onClick }) => {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={2}>
      {icons?.map((obj) => {
        let Icon = obj?.Logo;
        let selected = value?.find((i) => i.value === obj.value);
        return (
          <Tooltip title={obj.label}>
            <Box
              sx={{
                p: 2,
                pb: 1.4,
                border: selected ? "1px solid #000" : "1px solid #d7d7d7",
                opacity: selected ? 1 : 0.6,
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                onClick([
                  ...new Set(
                    !selected
                      ? [...value, obj].filter(Boolean)
                      : value?.filter((i) => i.value !== obj.value)
                  ),
                ])
              }
            >
              <Icon
                style={{
                  height: "28px",
                  width: "28px",
                }}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

const SectionTitle = ({ title }) => {
  return (
    <Typography sx={{ ...primaryLabel, fontSize: "13px" }}>{title}</Typography>
  );
};
