import { onAddBot } from "../../utils/BotNode";

export const createBotNode = ({
  dispatch,
  nodes,
  nodeId,
  currentAddHandle,
  handlesObj,
}) => {
  console.log(nodes, nodeId);
  const currNode = nodes.find((node) => node.id === nodeId);
  const type = currNode.type;
  console.log("handlesObj", currentAddHandle, handlesObj, typeof handlesObj, typeof currentAddHandle);

  let axis = {
    x: currNode.position.x,
    y: currNode.position.y,
  };

  const getAxis = ({
    x = axis.x,
    y = axis.y,
    handleNum,
    totalHandles = handlesObj.length,
  }) => {
    const distance = 50;
    console.log(x, y);

    if (handleNum <= totalHandles / 2) {
      return {
        x: x + 200,
        y: y - distance * (totalHandles / 2 - handleNum + 1),
      };
    } else {
      return { x: x + 200, y: y + distance * (handleNum - totalHandles / 2) };
    }
  };

  if (handlesObj) {
    const index = handlesObj.findIndex(
      (item) => item?.value == currentAddHandle
    );
    
    console.log("INDEX _________________________",index + 1)
    axis = getAxis({ handleNum: index + 1 });
  } else {
    if (type === "user") {
      axis.x = currNode.position.x + 160;
      axis.y = currNode.position.y + 10;
    } else if (type === "filter") {
      axis.x = currNode.position.x + 200;
      axis.y = currNode.position.y;
    } else {
      axis.x = currNode.position.x + 200;
      axis.y = currNode.position.y;
    }
  }

  onAddBot(dispatch, axis, nodeId, currentAddHandle);
};
