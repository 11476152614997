import { useEffect, useState } from "react";
import { Handle, useNodeId } from "reactflow";
import NodePopover from "../helper/NodePopover";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { userNodeStyles } from "../styles/userNodeStyles";
import { useDispatch, useSelector } from "react-redux";
import NodeOptions from "../helper/NodeOptions";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../styles/node.css";
import AddNodesButton from "../helper/AddNodesButton";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";

const styles = userNodeStyles;

const CustomUser = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const nodeId = useNodeId();
  const currentNode = nodes.find((node) => node.id === nodeId);
  const title = currentNode?.fieldData?.title;
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.OPEN_SIDEBAR_USER });
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ paddingBottom: "10px", position: "relative", right: "45px" }}
      >
        {isHovered && !isDragging ? (
          <NodeOptions nodeId={nodeId} />
        ) : (
          <div
            style={{
              textAlign: "center",
              maxWidth: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#566e8a",
              }}
            >
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>

      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        {isHovered && isDragging && errorMessage !== null ? (
          <Tooltip
            arrow
            title={errorMessage}
            placement="top"
            open={true}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  padding: "10px",
                },
              },
            }}
          >
            <Box
              sx={{ ...styles.nodeBody, backgroundColor: "#494a49" }}
              onClick={handleMain}
            >
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <PersonIcon style={styles.personIcon} />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                "&:hover": { backgroundColor: "#446DF6" },
              }}
            >
              <PersonIcon style={styles.personIcon} />
            </IconButton>
          </Box>
        )}

        <Handle type="source" position="right" style={styles.rightHandle} />
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <AddNodesButton handleClick={handleClick} isHovered={isHovered} />
      </Box>
    </Box>
  );
};

export default CustomUser;
