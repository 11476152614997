import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import { questionStyles } from "../styles/questionStyles";
import NodeOptions from "../helper/NodeOptions";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = questionStyles;

const Question = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [isHovered, setIsHovered] = useState(false);

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_QUESTION, payload: true });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ paddingBottom: "10px" }}>
        {isHovered ? (
          <div style={{ paddingLeft: "25px" }}>
            <NodeOptions nodeId={nodeId} nodeType="question" />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              maxWidth: "115px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            <Typography
              variant="span"
              sx={{
                paddingLeft: "10px",
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#566e8a",
              }}
            >
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>

      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        <Box sx={styles.nodeBody} onClick={handleMain}>
          <IconButton sx={{ margin: 0, padding: 0 }}>
            <QuestionMarkIcon sx={styles.nodeIcon} />
          </IconButton>
          <Typography variant="span" style={styles.titleName}>
            Question
          </Typography>
          <Handle type="source" position="right" style={styles.rightHandle} />
        </Box>
      </Box>
    </Box>
  );
};

export default Question;
