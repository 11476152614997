import { Delete } from "@material-ui/icons";
import { Add, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Collapse,
  Alert,
  Tab,
  Tabs,
  DialogContentText,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  allEmptyCurrency,
  ArrayMerge,
  deepObjectComparison,
  dialogStyle,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { addUserActivity } from "../../redux/actions/hubActions";
import { StackRow } from "../components/helper";
import { diamondTypes } from "../settings/MasterSettings";
import DiamondGroup from "./DiamondGroup";
import { currencySymbols } from "./Gold";
import { setHelperData } from "../../redux/actions/helperActions";

const defaultAddForm = {
  clarities: [],
  colors: [],
  shapes: [],
  sieves: [],
  sieve_from: "",
  sieve_to: "",
  sieve_type: "",
  cuts: [],
  types: [],
  rate: "",
  title: "",
};

const defaultTabs = [
  // { label: "Gold", value: "gold" },
  // { label: "Silver", value: "silver" },
  // { label: "Platinum", value: "platinum" },
  { label: "Combinations", value: "diamond_combination" },
  { label: "Diamond Group", value: "diamond_group" },
];

const Diamond = forwardRef((props, ref) => {
  let { isDefaultCurrency, defaultCurrency, selectedCurrency } = props;
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [addGroupFormOpen, setAddGroupFormOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [allColors, setAllColors] = useState([]);
  const [allClarities, setAllClarities] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allShapes, setAllShapes] = useState([]);
  const [allShapesFromGroup, setAllShapesFromGroup] = useState([]);
  const [allCuts, setAllCuts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState(null);
  const [addForm, setAddForm] = useState(defaultAddForm);
  const [allSizes, setAllSizes] = useState([]);
  // const [selectedCurrency, setSelectedCurrency] = useState("");
  const [alert, setAlert] = useState({ show: false });
  const [allCurrency, setAllCurrency] = useState([]);
  const [diamondPricing, setDiamondPricing] = useState(allEmptyCurrency);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );

  const closeDelete = () => setShowDelete(false);

  const openDelete = (id) => {
    setShowDelete(id);
  };

  useEffect(() => {
    if (isObjWithValues(props.productSettings))
      if (isArrayWithValues(props.productSettings.currency)) {
        let { currency } = props.productSettings;
        // setSelectedCurrency(currency[0].value);
        setAllCurrency(props.productSettings.currency);
      }
  }, [props.productSettings]);

  useEffect(() => {
    if (isArrayWithValues(allShapes) && isObjWithValues(allDiamondGroups))
      setAllShapesFromGroup(
        allShapes.filter((i) =>
          Object.values(allDiamondGroups).find((obj) => obj.shape === i.value)
        )
      );
  }, [allDiamondGroups, allShapes]);

  // useEffect(() => {
  //   if (!isObjWithValues(allDiamondGroups)) {
  //     dispatch(getDiamondGroups());
  //   }
  // }, []);

  useImperativeHandle(ref, () => ({
    getDiamondPricing() {
      return diamondPricing;
    },
  }));

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.diamond_pricing)
    )
      setDiamondPricing((state) => ({
        ...allEmptyCurrency,
        ...masterPricing.diamond_pricing,
      }));
  }, [masterPricing]);

  const openAddForm = () => {
    let title = "";
    if (isArrayWithValues(allCurrency) && isObjWithValues(diamondPricing))
      if (isArrayWithValues(diamondPricing[allCurrency[0].value]))
        title = `Diamond Combination #${
          diamondPricing[allCurrency[0].value].length + 1
        }`;
      else title = "Diamond Combinatino #1";
    setAddForm({ ...defaultAddForm, title });
    setEditId(null);
    setAddFormOpen(true);
  };
  const closeAddForm = () => {
    setEditId(null);
    setAddForm(defaultAddForm);
    setAddFormOpen(false);
  };
  const closeGroupAddForm = () => {
    setAddGroupFormOpen(false);
  };
  const openGroupAddForm = () => {
    setAddGroupFormOpen(true);
  };

  const editAddForm = (payload) =>
    setAddForm((state) => ({ ...state, ...payload }));

  useEffect(() => {
    if (isObjWithValues(props.productSettings)) {
      let { productSettings } = props;
      if (isArrayWithValues(productSettings.diamond_colors)) {
        let { diamond_colors } = productSettings;
        let diamondColors = diamond_colors.map((i) => i.value);
        let extraColors = [
          { label: "PINK", value: "PINK" },
          { label: "BLUE", value: "BLUE" },
          { label: "YELLOW", value: "YELLOW" },
        ];
        let allColors = getColorPermutations(diamondColors);

        if (isArrayWithValues(allColors)) {
          allColors = allColors.map((i) => ({ label: i, value: i }));
          for (let color of extraColors) {
            let obj = diamond_colors?.find((i) => i.value === color.value);
            if (obj) allColors.push(obj);
          }
          setAllColors(allColors);
        }
      }
      if (isArrayWithValues(productSettings.diamond_clarities)) {
        let { diamond_clarities } = productSettings;
        let diamondClarities = diamond_clarities.map((i) => i.value);
        let allClarities = diamondClarities;
        // let allClarities = getArrayCombination(diamondClarities);
        if (allClarities) {
          allClarities = allClarities.map((i) => ({
            label: i,
            value: i,
          }));
          setAllClarities(allClarities);
        }
      }
      if (isArrayWithValues(productSettings.diamond_types)) {
        let { diamond_types } = productSettings;
        if (isArrayWithValues(diamond_types)) setAllTypes(diamond_types);
      }
      if (isArrayWithValues(productSettings.diamond_shapes)) {
        let { diamond_shapes } = productSettings;
        if (isArrayWithValues(diamond_shapes)) setAllShapes(diamond_shapes);
      }
      if (isArrayWithValues(productSettings.diamond_cuts)) {
        let { diamond_cuts } = productSettings;
        if (isArrayWithValues(diamond_cuts)) setAllCuts(diamond_cuts);
      }
    }
  }, [props.productSettings]);

  useEffect(() => {
    let { shapes } = addForm;
    if (isArrayWithValues(shapes) && isObjWithValues(allDiamondGroups)) {
      let array = Object.values(allDiamondGroups);
      let shapeArray = array
        .map((i) =>
          shapes.find((s) => s.value === i.shape)
            ? { label: i.title, value: i.id }
            : ""
        )
        .filter(Boolean);
      setAllSizes(shapeArray);
      // let array = [];
      // shapes.map(
      //   (i) => (array = array.concat(allDiamondSizes[i.value]).filter(Boolean))
      // );
      // array = array.concat(allShapesSizes);
      // if (shapes.length === 1 && shapes[0].value === "round")
      //   array = array.filter((i) => i.value !== "1/10");
      // setAllSizes(array);
    }
  }, [addForm.shapes]);
  // useEffect(() => {
  //   let { shapes } = addForm;
  //   if (isArrayWithValues(shapes)) {
  //     let array = [];
  //     shapes.map((i) => (array = array.concat(allDiamondSizes[i.value])));
  //     array = array.concat(allShapesSizes);
  //     if (shapes.length === 1 && shapes[0].value === "round")
  //       array = array.filter((i) => i.value !== "1/10");
  //     setAllSizes(array);
  //   }
  // }, [addForm.shapes]);

  useEffect(() => {
    checkDuplicateCombinatins();
  }, [addForm.sieves]);

  const checkDuplicateCombinatins = () => {
    let { clarities, shapes, colors, sieves, types } = addForm;
    if (
      isArrayWithValues(clarities) &&
      isArrayWithValues(shapes) &&
      isArrayWithValues(colors) &&
      isArrayWithValues(sieves) &&
      isArrayWithValues(allCurrency)
    ) {
      for (let h = 0; h < types.length; h++) {
        for (let i = 0; i < clarities.length; i++) {
          for (let j = 0; j < colors.length; j++) {
            for (let k = 0; k < shapes.length; k++) {
              for (let l = 0; l < sieves.length; l++) {
                let isDuplicateCombination = checkDiamondCombination(
                  [...diamondPricing[allCurrency[0].value]],
                  clarities[i].value,
                  colors[j].value,
                  shapes[k].value,
                  sieves[l].value,
                  types[h].value
                );
                if (
                  isDuplicateCombination &&
                  isDuplicateCombination.id !== addForm.id
                )
                  return setAlert((state) => ({
                    ...state,
                    show: true,
                    message: "Below Combination(s) already exist",
                    severity: "error",
                  }));
              }
            }
          }
        }
      }

      setAlert((state) => ({
        ...state,
        show: false,
      }));
    }
  };

  // useEffect(() => {
  //   if (alert.show) setAlert((state) => ({ ...state, show: false }));
  // }, [addForm]);

  const onAddCombination = () => {
    let { onMasterUpdate } = props;
    let obj = {};
    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...diamondPricing[allCurrency[i].value]];
      array.push({
        id: Math.floor(Date.now() / 1000),
        ...addForm,
        rate: defaultCurrency === allCurrency[i].value ? addForm.rate : "",
      });
      obj[allCurrency[i].value] = array;
    }
    // return;
    // let updatedObj = {
    //   ...diamondPricing,
    //   [selectedCurrency]: diamondPricing[selectedCurrency].concat({
    //     id: Math.floor(Date.now() / 1000),
    //     ...addForm,
    //   }),
    // };
    // //send this if want to remove unselected currency
    // let filteredObj = {};
    // for (let i = 0; i < Object.keys(updatedObj).length; i++)
    //   if (allCurrency.find((j) => j.value === Object.keys(updatedObj)[i]))
    //     filteredObj[Object.keys(updatedObj)[i]] =
    //       updatedObj[Object.keys(updatedObj)[i]];
    console.log(obj);

    dispatch(
      setHelperData({
        changeInCombination: true,
      })
    );
    // return;
    dispatch(
      addUserActivity({
        event: `diamond_pricing_add`,
        event_info: {
          // product_id: data?.data?.id,
          name: addForm?.title,
          rate: addForm?.rate,
        },
      })
    );
    onMasterUpdate({
      diamond_pricing: obj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };

  const onDeleteCombination = () => {
    let id = showDelete;
    let updatedDiamondPricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedDiamondPricing[allCurrency[i].value] = diamondPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
        else
          updatedDiamondPricing[allCurrency[i].value] = diamondPricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
      }
    } else
      updatedDiamondPricing = {
        ...diamondPricing,
        [selectedCurrency]: diamondPricing[selectedCurrency].filter(
          (i) => i.id === id
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedDiamondPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedDiamondPricing)[i]
        )
      )
        filteredObj[Object.keys(updatedDiamondPricing)[i]] =
          updatedDiamondPricing[Object.keys(updatedDiamondPricing)[i]];
    // let updatedDiamondPricing = [...labourPricing];
    // updatedDiamondPricing = updatedDiamondPricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    let { onMasterUpdate } = props;
    onMasterUpdate({
      diamond_pricing: filteredObj,
      setLoading,
      callback: () => {
        setLoading(false);
        closeDelete();
      },
      skipMasterUpdateHook: true,
    });
  };

  const onUpdateCombination = () => {
    if (checkDuplicateCombinatins()) return;
    let previousObj = diamondPricing[selectedCurrency]?.find(
      (o) => o?.id == editId
    );
    let updatedDiamondPricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedDiamondPricing[allCurrency[i].value] = diamondPricing[
            `${allCurrency[i].value}`
          ].map((obj) => (obj.id === editId ? addForm : obj));
        else
          updatedDiamondPricing[allCurrency[i].value] = diamondPricing[
            `${allCurrency[i].value}`
          ].map((obj) =>
            obj.id === editId ? { ...addForm, rate: obj.rate } : obj
          );
      }
    } else
      updatedDiamondPricing = {
        ...diamondPricing,
        [selectedCurrency]: diamondPricing[selectedCurrency].map((i) =>
          i.id === editId ? { ...i, rate: addForm.rate } : i
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedDiamondPricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedDiamondPricing)[i]
        )
      ) {
        filteredObj[Object.keys(updatedDiamondPricing)[i]] =
          updatedDiamondPricing[Object.keys(updatedDiamondPricing)[i]];
      }

    let { onMasterUpdate } = props;
    onMasterUpdate({
      diamond_pricing: filteredObj,
      setLoading,
      callback: () => {
        setAddFormOpen(false);
        setEditId(null);
        setAddForm(defaultAddForm);
      },
      skipMasterUpdateHook: true,
    });
    console.log(addForm, previousObj);
    if (!deepObjectComparison(addForm, previousObj))
      dispatch(
        setHelperData({
          changeInCombination: true,
        })
      );

    dispatch(
      addUserActivity({
        event: `diamond_pricing_update`,
        event_info: {
          // product_id: data?.data?.id,
          name: addForm?.title,
          rate: addForm?.rate,
        },
      })
    );
  };

  const onEditPress = (obj) => {
    setEditId(obj.id);
    setAddForm({ ...obj });
    setAddFormOpen(true);
  };
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  return (
    <Box>
      <StyledTabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        aria-label="styled tabs example"
        variant="scrollable"
      >
        {defaultTabs.map((i) => (
          <StyledTab label={i.label} />
        ))}
        {/* <StyledTab label="Grouping" />
        <StyledTab label="Size Masters" />
        <StyledTab label="Team members" />
        <StyledTab label="Product Shortcuts" /> */}
        {/* <StyledTab label="Masters" /> */}
      </StyledTabs>
      <Box
        sx={{
          display: selectedTab === 0 ? "block" : "none",
        }}
      >
        <>
          <Stack
            alignItems={"center"}
            justifyContent="flex-end"
            direction="row"
            // p={2}
            // border="0.5px solid #d1d1d1 "
            // borderRadius={"6px"}
          >
            <Button
              size="small"
              onClick={isDefaultCurrency ? openAddForm : null}
              startIcon={<Add />}
              sx={{ mr: 2 }}
              disabled={!isDefaultCurrency}
            >
              Add New Combination
            </Button>

            {/* <FormControl size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCurrency}
            // label="Age"
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {allCurrency.map((i) => {
              return (
                <MenuItem
                  value={i.value}
                >{`${i.label} (${i.symbol})`}</MenuItem>
              );
            })}
          </Select>
        </FormControl> */}
          </Stack>
          {isIpad && (
            <Box>
              {diamondPricing[selectedCurrency] &&
                diamondPricing[selectedCurrency].map((row) => (
                  <Card key={row.id} sx={{ mt: 3 }}>
                    <CardContent>
                      <Box>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography variant="h6">{row.title}</Typography>
                          <IconButton
                            size="small"
                            onClick={() => onEditPress(row)}
                          >
                            <Edit />
                          </IconButton>
                        </Stack>
                        <Typography fontSize="14px" mt={2} mb={1}>
                          Type :
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.types) &&
                            row.types.map((type, i) => {
                              return (
                                <Typography>
                                  {type.label}
                                  {i !== row.types.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                        <Grid container columnSpacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography fontSize="14px" mt={2}>
                              Clarities
                            </Typography>
                            <Stack
                              direction="row"
                              flexWrap={"wrap"}
                              sx={{
                                border: "0.4px solid #bababa",
                                p: 1,
                                py: 2,
                                mt: 1,
                                borderRadius: "8px",
                              }}
                            >
                              {isArrayWithValues(row.clarities) &&
                                row.clarities.map((clarity, i) => {
                                  return (
                                    <Typography>
                                      {clarity.label}
                                      {i !== row.clarities.length - 1
                                        ? `, `
                                        : ""}
                                    </Typography>
                                  );
                                })}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography fontSize="14px" mt={2}>
                              Colors
                            </Typography>
                            <Stack
                              direction="row"
                              flexWrap={"wrap"}
                              sx={{
                                border: "0.4px solid #bababa",
                                p: 1,
                                py: 2,
                                mt: 1,
                                borderRadius: "8px",
                              }}
                            >
                              {isArrayWithValues(row.colors) &&
                                row.colors.map((color, i) => {
                                  return (
                                    <Typography>
                                      {color.label}
                                      {i !== row.colors.length - 1 ? `, ` : ""}
                                    </Typography>
                                  );
                                })}
                            </Stack>
                          </Grid>
                        </Grid>
                        <Typography fontSize="14px" mt={2} mb={1}>
                          Shapes :
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.shapes) &&
                            row.shapes.map((shape, i) => {
                              return (
                                <Typography>
                                  {shape.label}
                                  {i !== row.shapes.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                        <Typography fontSize="14px" mt={2} mb={1}>
                          Sizes :
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.sieves) &&
                            row.sieves.map((size, i) => {
                              return (
                                <Typography>
                                  {size.label}
                                  {i !== row.sieves.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                        <Typography fontSize="14px" mt={2} mb={1}>
                          Cut :
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.cuts) &&
                            row.cuts.map((cut, i) => {
                              return (
                                <Typography>
                                  {cut.label}
                                  {i !== row.cuts.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                        <Typography
                          variant="h6"
                          textAlign={"right"}
                          fontSize="14px"
                          mt={3}
                        >
                          Rate:{" "}
                          {`${currencySymbols[selectedCurrency]} ${row.rate}`}
                        </Typography>
                        {/* <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="body1">
                        <strong>Wastage: </strong>₹{row.wastage}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="body1">
                        <strong>Per gram: </strong>₹{row.per_gram}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="body1">
                        <strong>Min Making: </strong>₹{row.minimum_making}{" "}
                      </Typography>
                    </Grid>
                  </Grid> */}
                      </Box>
                    </CardContent>
                  </Card>
                ))}
            </Box>
          )}

          {!isIpad && (
            <TableContainer
              component={Paper}
              sx={{
                mt: 3,
                borderRadius: "10px",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Clarity</TableCell>
                    <TableCell align="left">Colors</TableCell>
                    <TableCell align="left">Shapes</TableCell>
                    <TableCell align="left">Sizes</TableCell>
                    <TableCell align="left">Cuts</TableCell>
                    <TableCell align="left">Rate</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {diamondPricing[selectedCurrency] &&
                    diamondPricing[selectedCurrency].map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => onEditPress(row)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell align="left">
                          <Typography>{row.title}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {isArrayWithValues(row.types) &&
                            row.types.map((type) => {
                              return (
                                <Stack
                                  my={1}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <Typography>{type.label}</Typography>
                                </Stack>
                              );
                            })}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {isArrayWithValues(row.clarities) &&
                            row.clarities.map((clarity) => {
                              return (
                                <Stack
                                  my={1}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <Typography>{clarity.label}</Typography>
                                </Stack>
                              );
                            })}
                        </TableCell>
                        <TableCell align="left">
                          <Stack>
                            {isArrayWithValues(row.colors) &&
                              row.colors.map((color) => {
                                return (
                                  <Stack
                                    my={1}
                                    direction="row"
                                    alignItems={"center"}
                                  >
                                    <Typography>{color.label}</Typography>
                                  </Stack>
                                );
                              })}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {isArrayWithValues(row.shapes) &&
                            row.shapes.map((shape) => {
                              return (
                                <Stack
                                  my={1}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <Typography>{shape.label}</Typography>
                                </Stack>
                              );
                            })}
                        </TableCell>
                        <TableCell align="left">
                          {isArrayWithValues(row.sieves) &&
                            row.sieves.map((size) => {
                              return (
                                <Stack
                                  my={1}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <Typography>{size.label || ""}</Typography>
                                </Stack>
                              );
                            })}
                        </TableCell>
                        <TableCell align="left">
                          {isArrayWithValues(row.cuts) &&
                            row.cuts.map((cut) => {
                              return (
                                <Stack
                                  my={1}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <Typography>{cut.label}</Typography>
                                </Stack>
                              );
                            })}
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {currencySymbols[selectedCurrency]} {row.rate}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              openDelete(row.id);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                        {/* <TableCell align="left">{row.protein}</TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      </Box>

      <Box
        sx={{
          display: selectedTab === 1 ? "block" : "none",
        }}
      >
        <DiamondGroup allShapes={allShapes} />
        {/* <>
          <Stack
            alignItems={"center"}
            justifyContent="flex-end"
            direction="row"
            // p={2}
            // border="0.5px solid #d1d1d1 "
            // borderRadius={"6px"}
          >
            <Button
              size="small"
              onClick={isDefaultCurrency ? openGroupAddForm : null}
              startIcon={<Add />}
              sx={{ mr: 2 }}
              disabled={!isDefaultCurrency}
            >
              Add New Groups
            </Button>
          </Stack>
        </> */}
      </Box>
      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          Delete Diamond combination?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be done
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={addGroupFormOpen}
        onClose={closeGroupAddForm}
        sx={dialogStyle}
      >
        <DialogTitle>{"Add"} Combination</DialogTitle>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={addFormOpen}
        onClose={closeAddForm}
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>{editId ? "Update" : "Add"} Combination</DialogTitle>

        <DialogContent>
          <Collapse in={alert.show}>
            <Alert severity={alert.severity}>{alert.message}</Alert>
          </Collapse>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="standard"
                value={addForm.title}
                onChange={(e) =>
                  isDefaultCurrency
                    ? editAddForm({ title: e.target.value })
                    : null
                }
                label="Title"
                autoFocus
                fullWidth
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.types}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allTypes}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Types" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ types: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.clarities}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                // filterOptions={(options) => options}
                options={allClarities}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Clarities" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ clarities: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.colors}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allColors}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Colors" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ colors: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.shapes}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allShapesFromGroup}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Shapes" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ shapes: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Stack direction="row" alignItems={"center"} spacing={2}>
                <Autocomplete
                  // multiple
                  // disablePortal
                  openOnFocus
                  fullWidth
                  // disableCloseOnSelect
                  filterSelectedOptions
                  value={addForm.sieve_from}
                  id="combo-box-demo"
                  getOptionLabel={(option) =>
                    option.label || option.sieve || option || ""
                  }
                  options={allSizes}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sieve From"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) =>
                    !isDefaultCurrency
                      ? null
                      : editAddForm({ sieve_from: value })
                  }
                  disabled={!isDefaultCurrency}
                />
                <Autocomplete
                  // multiple
                  // disablePortal
                  openOnFocus
                  fullWidth
                  // disableCloseOnSelect
                  filterSelectedOptions
                  value={addForm.sieve_to}
                  id="combo-box-demo"
                  getOptionLabel={(option) =>
                    option.label || option.sieve || option || ""
                  }
                  options={allSizes}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sieve To"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) =>
                    !isDefaultCurrency
                      ? null
                      : editAddForm({ sieve_to: value.sieve })
                  }
                  disabled={!isDefaultCurrency}
                />
              </Stack> */}
              <Autocomplete
                multiple
                // disablePortal

                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.sieves}
                id="combo-box-demo"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option?.label || ""
                }
                options={allSizes}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sizes" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ sieves: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.cuts}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allCuts}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Cuts" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ cuts: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="standard"
                value={addForm.rate}
                onChange={(e) => editAddForm({ rate: e.target.value })}
                label="Rate"
                fullWidth
                type={"number"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <StackRow>
            <Button onClick={closeAddForm}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={editId ? onUpdateCombination : onAddCombination}
              disabled={
                !isArrayWithValues(addForm.clarities) ||
                !isArrayWithValues(addForm.colors) ||
                !isArrayWithValues(addForm.shapes) ||
                !isArrayWithValues(addForm.sieves) ||
                alert.show ||
                !addForm.title
              }
            >
              {editId ? "Update" : "Add"}
            </LoadingButton>
          </StackRow>
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export default Diamond;

const getLabelValueFromArray = (array) => {
  if (isArrayWithValues(array)) {
    let temp = [];
    for (let i in array) temp.push({ label: array[i], value: array[i] });
    return temp;
  }
};

const getColorPermutations = (colors) => {
  if (isArrayWithValues(colors)) {
    const allColors = ["D", "E", "F", "G", "H", "I", "J", "K"];
    // const allColors = ["I", "K", "J", "G", "F", "E", "H", "D", "L", "M"];
    const colorsToRemove = allColors.filter((el) => !colors.includes(el));
    const array = [];

    for (let i = 0; i < allColors.length; i++) {
      let firstvalue = allColors[i];
      let secondValue = allColors[i + 1] || false;
      let thirdValue = allColors[i + 2] || false;
      array.push(firstvalue);
      if (secondValue) array.push(`${firstvalue}${secondValue}`);
      if (secondValue && thirdValue)
        array.push(`${firstvalue}${secondValue}${thirdValue}`);
    }
    let result = [];
    for (let i = 0; i < array.length; i++) {
      let add = true;
      for (let j = 0; j < colorsToRemove.length; j++) {
        if (array[i].includes(colorsToRemove[j])) add = false;
      }
      if (add && !result.includes(array[i])) result.push(array[i]);
    }
    return result;
  }
};

const getArrayCombination = (array) => {
  if (isArrayWithValues(array)) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      result.push(array[i]);
      let index = i;
      while (array[index + 1]) {
        result.push(`${array[i]}/${array[index + 1]}`);
        index++;
      }
    }
    return result;
  }
};

export const checkDiamondCombination = (
  diamondPricings,
  clarity,
  color,
  shape,
  size,
  type
) => {
  if (!diamondPricings) return 0;
  // let clarity = quality.split("-")[0];
  // let color = quality.split("-")[1];

  if (!isArrayWithValues(diamondPricings)) return;
  let length = diamondPricings.length;
  for (let i = 0; i < length; i++) {
    let { clarities, types } = diamondPricings[i];
    if (types.find((i) => i.value === type)) {
      if (clarities.find((i) => i.value === clarity)) {
        let { colors } = diamondPricings[i];
        if (colors.find((i) => i.value === color)) {
          let { shapes } = diamondPricings[i];
          if (shapes.find((i) => i.value === shape)) {
            let { sieves } = diamondPricings[i];
            if (sieves.find((i) => i.value === size)) {
              return diamondPricings[i];
              // if (!cut && !isArrayWithValues(cuts))
              //   return diamondPricings[i].rate;
              // if (cuts.find((i) => i.value === cut))
              //   return diamondPricings[i].rate;
            }
          }
        }
      }
    }
  }
  return false;
};

// export const diamondSives = {
//   round: [
//     { sieve: "+0000", size: "0.80", wt: "0.0035" },
//     { sieve: "+000", size: "0.90", wt: "0.0040" },
//     { sieve: "+95", size: "1.00", wt: "0.0045" },
//     { sieve: "+00", size: "1.05", wt: "0.0050" },
//     { sieve: "+105", size: "1.05", wt: "0.0055" },
//     { sieve: "+0", size: "1.10", wt: "0.0063" },
//     { sieve: "+112.5", size: "1.10", wt: "0.0069" },
//     { sieve: "+1", size: "1.15", wt: "0.0074" },
//     { sieve: "+1.25", size: "1.15", wt: "0.0078" },
//     { sieve: "+1.5", size: "1.20", wt: "0.0083" },
//     { sieve: "+1.75", size: "1.20", wt: "0.0087" },
//     { sieve: "+2", size: "1.25", wt: "0.0091" },
//     { sieve: "+2.25", size: "1.25", wt: "0.0096" },
//     { sieve: "+2.5", size: "1.30", wt: "0.0103" },
//     { sieve: "+2.75", size: "1.30", wt: "0.0108" },
//     { sieve: "+3", size: "1.35", wt: "0.0115" },
//     { sieve: "+3.25", size: "1.35", wt: "0.0125" },
//     { sieve: "+3.5", size: "1.40", wt: "0.0135" },
//     { sieve: "+3.75", size: "1.40", wt: "0.0140" },
//     { sieve: "+4", size: "1.45", wt: "0.0145" },
//     { sieve: "+4.25", size: "1.45", wt: "0.0154" },
//     { sieve: "+4.5", size: "1.50", wt: "0.0159" },
//     { sieve: "+4.75", size: "1.50", wt: "0.0165" },
//     { sieve: "+5", size: "1.55", wt: "0.0172" },
//     { sieve: "+5.25", size: "1.55", wt: "0.0188" },
//     { sieve: "+5.5", size: "1.60", wt: "0.0195" },
//     { sieve: "+5.75", size: "1.60", wt: "0.0210" },
//     { sieve: "+6", size: "1.70", wt: "0.0215" },
//     { sieve: "+6.25", size: "1.70", wt: "0.0240" },
//     { sieve: "+6.5", size: "1.80", wt: "0.0267" },
//     { sieve: "+6.75", size: "1.80", wt: "0.0280" },
//     { sieve: "+7", size: "1.90", wt: "0.0305" },
//     { sieve: "+7.25", size: "1.90", wt: "0.0320" },
//     { sieve: "+7.5", size: "2.00", wt: "0.0347" },
//     { sieve: "+7.75", size: "2.00", wt: "0.0380" },
//     { sieve: "+8", size: "2.10", wt: "0.0417" },
//     { sieve: "+8.25", size: "2.10", wt: "0.0430" },
//     { sieve: "+8.5", size: "2.2", wt: "0.0464" },
//     { sieve: "+8.75", size: "2.2", wt: "0.0480" },
//     { sieve: "+9", size: "2.3", wt: "0.0540" },
//     { sieve: "+9.25", size: "2.3", wt: "0.0550" },
//     { sieve: "+9.5", size: "2.4", wt: "0.0618" },
//     { sieve: "+9.75", size: "2.4", wt: "0.0630" },
//     { sieve: "+10", size: "2.5", wt: "0.0655" },
//     { sieve: "+10.25", size: "2.5", wt: "0.0690" },
//     { sieve: "+10.5", size: "2.6", wt: "0.0726" },
//     { sieve: "+10.75", size: "2.6", wt: "0.0745" },
//     { sieve: "+11", size: "2.7", wt: "0.0800" },
//     { sieve: "+11.25", size: "2.7", wt: "0.0850" },
//     { sieve: "+11.5", size: "2.8", wt: "0.0910" },
//     { sieve: "+11.75", size: "2.8", wt: "0.0950" },
//     { sieve: "+12", size: "2.9", wt: "0.1020" },
//     { sieve: "+12.25", size: "2.9", wt: "0.1083" },
//     { sieve: "+12.5", size: "3.0", wt: "0.1110" },
//     { sieve: "+12.75", size: "3.0", wt: "0.1150" },
//     { sieve: "+13", size: "3.1", wt: "0.1200" },
//     { sieve: "+13.25", size: "3.1", wt: "0.1290" },
//     { sieve: "+13.5", size: "3.2", wt: "0.1340" },
//     { sieve: "+13.75", size: "3.2", wt: "0.1417" },
//     { sieve: "+14", size: "3.3", wt: "0.1470" },
//     { sieve: "+14.25", size: "3.3", wt: "0.1533" },
//     { sieve: "+14.5", size: "3.4", wt: "0.1580" },
//     { sieve: "+14.75", size: "3.4", wt: "0.1646" },
//     { sieve: "+15", size: "3.5", wt: "0.1680" },
//     { sieve: "+15.5", size: "3.6", wt: "0.1820" },
//     { sieve: "+16", size: "3.7", wt: "0.1989" },
//     { sieve: "+16.5", size: "3.8", wt: "0.2060" },
//     { sieve: "+17", size: "3.9", wt: "0.2300" },
//     { sieve: "+17.5", size: "4.0", wt: "0.2460" },
//     { sieve: "+18", size: "4.1", wt: "0.2550" },
//     { sieve: "+18.5", size: "4.2", wt: "0.2650" },
//     { sieve: "+19", size: "4.3", wt: "0.3000" },
//     { sieve: "+19.5", size: "4.4", wt: "0.3200" },
//     { sieve: "+20", size: "4.5", wt: "0.3400" },
//   ],
// };

// const diamondShapes = [
//   { label: "Round", value: "round" },
//   { label: "Pear", value: "pear" },
//   { label: "Princess", value: "princess" },
//   { label: "Marquise", value: "marquise" },
//   { label: "Oval", value: "oval" },
//   // { label: "Radiant", value: "radiant" },
//   { label: "Emerald", value: "emerald" },
//   { label: "Heart", value: "heart" },
//   // { label: "Cushion", value: "cushion" },
//   // { label: "Asscher", value: "asscher" },
//   { label: "Tapper Baguette", value: "tapper_baguette" },
// ];

// let allShapesSizes = [
//   { label: "1/10", value: "1/10" },
//   { label: "1/6", value: "1/6" },
//   { label: "1/5", value: "1/5" },
//   { label: "1/4", value: "1/4" },
//   { label: "1/3", value: "1/3" },
//   { label: "1/2", value: "1/2" },
//   { label: "3/8", value: "3/8" },
// ];
export const allDiamondSizes = {
  round: [
    { label: "+0-2", value: "+0-2" },
    { label: "+0-11", value: "+0-11" },
    { label: "+2-6.5", value: "+2-6.5" },
    { label: "+0-6", value: "+0-6" },
    { label: "+6.5-9.5", value: "+6.5-9.5" },
    { label: "+6.5-11", value: "+6.5-11" },
    { label: "+0-9.5", value: "+0-9.5" },
    { label: "+9.5-11", value: "+9.5-11" },
    { label: "+11-11.50", value: "+11-11.50" },
    { label: "+14-14.50", value: "+14-14.50" },
  ],
  marquise: [
    { label: "-7 MQ", value: "-7 MQ" },
    { label: "+7 MQ", value: "+7 MQ" },
  ],
  oval: [
    { label: "-7 OV", value: "-7 OV" },
    { label: "+7 OV", value: "+7 OV" },
  ],
  pear: [
    { label: "+7 PE", value: "+7 PE" },
    { label: "-7 PE", value: "-7 PE" },
  ],
  princess: [
    { label: "-7 PRN", value: "-7 PRN" },
    { label: "+7 PRN", value: "+7 PRN" },
  ],
  tapper_bugget: [
    { label: "-3 TB", value: "-3 TB" },
    { label: "+3-4 TB", value: "+3-4 TB" },
    { label: "+4 TB", value: "+4 TB" },
  ],
  heart: [{ label: "+7 HRT", value: "+7 HRT" }],
  emerald: [{ label: "+7 EM", value: "+7 EM" }],
};

const diamondSizes = [
  { label: "-7 MQ", value: "-7 MQ" },
  { label: "-3 TB", value: "-3 TB" },
  { label: "+3-4 TB", value: "+3-4 TB" },
  { label: "+0-2", value: "+0-2" },
  { label: "+2-6.5", value: "+2-6.5" },
  { label: "+0-6", value: "+0-6" },
  { label: "+6.5-9.5", value: "+6.5-9.5" },
  { label: "+6.5-11", value: "+6.5-11" },
  { label: "+0-9.5", value: "+0-9.5" },
  { label: "+9.5-11", value: "+9.5-11" },
  { label: "+0-11", value: "+0-11" },
  { label: "1/10", value: "1/10" },
  { label: "-7 PRN", value: "-7 PRN" },
  { label: "+7 PRN", value: "+7 PRN" },
  { label: "-7 PE", value: "-7 PE" },
  { label: "+7 MQ", value: "+7 MQ" },
  { label: "-7 OV", value: "-7 OV" },
  { label: "1", value: "1" },
  { label: "1.25", value: "1.25" },
  { label: "4/5", value: "4/5" },
];
// const diamondSizes = [
//   "-7 MQ",
//   "-3 TB",
//   "+3-4 TB",
//   "+0-2",
//   "+2-6.5",
//   "+0-6",
//   "+6.5-9.5",
//   "+6.5-11",
//   "+0-9.5",
//   "+9.5-11",
//   "+0-11",
//   "1/10",
//   "-7 PRN",
//   "-7 PE",
//   "+7 PRN",
//   "+7 MQ",
//   "-7 OV",
//   "1",
//   "1.25",
//   "4/5",
// ];

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  marginBottom: "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: "#635ee7",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "5px 8px",
    minWidth: "50px",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    "&.Mui-selected": {
      color: theme.palette.text.primary,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
// hallmark , certificate, rodiam
