import {
  StartNode,
  BotNode,
  UserNode,
  DefaultFallback,
  UserAttachmentNode,
  QuestionNode,
  SuccessNode,
  FailureNode,
  FilterNode,
  SubscribeNode,
  UnsubscribeNode,
  GoToStepNode,
  WhatsappTemplateNode,
  LiveAgentNode,
  ProductCatalogNode,
  EmailNode,
  SmsNode,
  PushNotificationNode,
  DelayNode,
} from "../custom-nodes/index";

export const types = {
  start: StartNode,
  bot: BotNode,
  user: UserNode,
  fallback: DefaultFallback,
  "user-attachment": UserAttachmentNode,
  question: QuestionNode,
  success: SuccessNode,
  failure: FailureNode,
  filter: FilterNode,
  subscribe: SubscribeNode,
  unsubscribe: UnsubscribeNode,
  "go-to-step": GoToStepNode,
  "whatsapp-template": WhatsappTemplateNode,
  "live-agent": LiveAgentNode,
  "product-catalog": ProductCatalogNode,
  email: EmailNode,
  sms: SmsNode,
  "push-notification": PushNotificationNode,
  delay: DelayNode,
};
