import {
  Avatar,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Stack,
  Collapse,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_ORDER, getAllOrders } from "../../../redux/actions/orderActions";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  formatDate,
  isArrayWithValues,
  formatServerValue,
  getWebsite,
  getToken,
  sectionLabel,
  StyledTableRow,
  a11yProps,
  isObjWithValues,
  globalCardStyle,
  globalColors,
} from "../../../helper";
import axios from "axios";
import { OrdersIcon, OrdersSvg } from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";
import { Add } from "@mui/icons-material";
import ReactApexChart from "react-apexcharts";

const defaultParams = {
  per_page: 5,
  page: 1,
};

export const allPaymentStatus = [
  { label: "Pending", value: "pending" },
  { label: "Processing", value: "processing" },
  { label: "On Hold", value: "on-hold" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Failed", value: "failed" },
  { label: "Refunded", value: "refunded" },
];

const tabs = [
  {
    label: "All Orders",
    value: "all_orders",
  },
  {
    label: "Digital gold",
    value: "digigold",
  },
  {
    label: "Website",
    value: "website",
  },
  { label: "App", value: "app" },
  { label: "Instore", value: "instore" },
  { label: "Whatsapp", value: "whatsapp" },
];

const tabKyes = {
  0: "recent",
  1: "wc-pending",
  2: "wc-processing",
  3: "wc-cancelled",
  4: "wc-failed",
  5: "wc-completed",
  5: "wc-on-hold",
  6: "wc-refunded",
};
const tabKyes2 = {
  0: "recent",
  1: "pulled",
  2: "liked",
  3: "cancelled",
  4: "failed",
  5: "completed",
  5: "on-hold",
  6: "refunded",
};

function RecentProductActivity() {
  // const [selectedTab, setSelectedTab] = useState("all_orders");
  const [series, setSeries] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState(defaultParams);
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const [noOrders, setNoOrders] = useState(false);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );

  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);

  useEffect(() => {
    if (!isObjWithValues(designbankProductsAnalytics)) return;
    let { pulledHistory } = designbankProductsAnalytics;
    if (!isObjWithValues(pulledHistory)) return;
    let data = [];
    for (let date in pulledHistory)
      data.push([new Date(date).getTime(), pulledHistory[date]]);

    setSeries(data);
  }, [designbankProductsAnalytics, allSuppliers]);

  console.log(designbankProductsAnalytics);

  // useEffect(() => {
  //   if (!isObjWithValues(dashboardDetails)) return;
  //   let { orders } = dashboardDetails;
  //   if (isArrayWithValues(orders?.orders?.[tabKyes[selectedTab]]))
  //     setRows(orders?.orders?.[tabKyes[selectedTab]]);
  //   else setRows([]);
  // }, [selectedTab]);

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { orders } = dashboardDetails;
    setNoOrders(!isArrayWithValues(orders?.orders?.recent));
    if (isArrayWithValues(orders?.orders?.recent))
      setRows(orders?.orders?.recent);
  }, [dashboardDetails]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allOrders = useSelector((state) => state.orders.dashboardOrders);
  // const fetchingOrders = useSelector((state) => state.orders.fetchingOrders);

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const fetchedDashboardOrders = useSelector(
    (state) => state.orders.fetchedDashboardOrders
  );
  const onScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      console.log("LOAD MORE DATA");
      //loadmore data
    }
  };

  // useEffect(() => {
  //   if (Array.isArray(allOrders)) setRows(allOrders.slice(0, 5));
  // }, [allOrders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography sx={{ ...sectionLabel }}>Your pulled activity</Typography>
        <ReactApexChart
          options={options}
          series={[
            {
              data: series,
            },
          ]}
          type="area"
          height={300}
        />
      </CardContent>
    </Card>
  );
  return (
    <Box sx={{ borderRadius: "20px" }}>
      {/* <Stack
            direction="row"
            sx={{
              backgroundColor: "Background",
              padding: "10px",
              borderBottomColor: "#e8e8e8",
              borderBottomWidth: 0.2,
              borderBottomStyle: "solid",
            }}
            spacing={4}
          >
            {tabs.map((i) => {
              return (
                <Button
                  variant={"text"}
                  // variant={selectedTab !== i.value ? "outlined" : "contained"}
                  sx={{
                    backgroundColor:
                      selectedTab === i.value ? "#e6e6e6" : "transparent",
                    color: "#000",
                    // color: selectedTab === i.value ? "primary.main" : "#000",
                    "&:hover": {
                      backgroundColor:
                        selectedTab === i.value ? "#e6e6e6" : "transparent",
                    },
                  }}
                  onClick={() => setSelectedTab(i.value)}
                >
                  {i.label}
                </Button>
              );
            })}
          </Stack> */}

      <Box sx={{ width: "100%", borderRadius: "20px" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            pl: 2,
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <Typography sx={{ ...sectionLabel }}>
            Your recent product activities
          </Typography>
          {/* <EnhancedTableToolbar
              numSelected={selected.length}
              params={params}
              setParams={setParams}
            /> */}

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ mt: 2 }}
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Pulled" {...a11yProps(1)} />
              <Tab label="Liked" {...a11yProps(2)} />
              {/* <Tab label="Cancelled" {...a11yProps(3)} />
              <Tab label="Failed" {...a11yProps(4)} />
              <Tab label="Completed" {...a11yProps(5)} />
              <Tab label="On Hold" {...a11yProps(6)} />
              <Tab label="Refund" {...a11yProps(7)} /> */}
            </Tabs>
          </Box>
          {true && (
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                {isArrayWithValues(rows) && (
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                )}
                {!fetchingDashboardDetails && !isArrayWithValues(rows) ? (
                  <Box sx={{ margin: "auto", height: "100%" }}>
                    <NoTasks
                      text={
                        <Box sx={{ textAlign: "center" }}>
                          {noOrders ? (
                            <Typography>
                              New orders will arrive soon...
                            </Typography>
                          ) : (
                            <Typography>
                              No products {tabKyes2[selectedTab]}
                            </Typography>
                          )}
                          {/* <Button
                              variant="outlined"
                              sx={{ mt: 3 }}
                              size="small"
                              startIcon={<Add />}
                              onClick={() => navigate("/products")}
                            >
                              Add Product
                            </Button> */}
                        </Box>
                      }
                      icon={
                        <OrdersIcon style={{ height: "70px", width: "70px" }} />
                      }
                    />
                  </Box>
                ) : // <Box sx={{ py: 4 }}>
                //   <Stack
                //     direction="row"
                //     justifyContent={"center"}
                //     sx={{ margin: "14px auto", alignItems: "center" }}
                //   >
                //     <OrdersSvg height={200} width={200} />
                //   </Stack>
                //   <Typography
                //     sx={{
                //       fontSize: "14px",
                //       color: "text.secondary",
                //       mt: 5,
                //       textAlign: "center",
                //     }}
                //   >
                //     No Recent Orders found
                //   </Typography>
                // </Box>
                null}
                <TableBody>
                  {fetchingDashboardDetails && !isArrayWithValues(rows) && (
                    <>
                      {Array(5)
                        .fill(null)
                        .map((item, index) => (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              py: 2,
                              cursor: "pointer",
                            }}
                          >
                            {/* <TableCell padding="checkbox">
                                <IconButton>
                                  <Skeleton
                                    variant="rectangular"
                                    sx={{
                                      width: "18px",
                                      height: "18px",
                                      borderRadius: "3px",
                                      ml: 1,
                                    }}
                                  />
                                </IconButton>
                              </TableCell> */}
                            <TableCell component="th" scope="row">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "100%" }}
                              />
                            </TableCell>

                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          //   <TableRow key={index}>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //   </TableRow>
                        ))}
                    </>
                  )}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let name =
                        row.billing &&
                        `${row.billing.first_name || ""} ${
                          row.billing.last_name
                        }`;

                      let date = formatDate(
                        new Date(row.date_created),
                        "dd:mm:yyyy hh:mm am/pm"
                      );
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          sx={{ py: 2, cursor: "pointer" }}
                          tabIndex={-1}
                          aria-checked={isItemSelected}
                          key={row.name}
                          selected={isItemSelected}
                          style={{ padding: "10px" }}
                          onClick={() => navigate(`/orders/${row.id}`)}
                        >
                          {/* <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                onClick={(event) => handleClick(event, row.id)}
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell> */}
                          <TableCell
                            sx={{ borderBottom: 0 }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Stack sx={{ my: 3 }}>
                              <Typography fontSize="15px" fontWeight={"600"}>
                                #{row.id}
                              </Typography>
                              <Typography mt={1} fontSize="13px">
                                {date}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 0 }}>
                            <Typography fontSize="15px" fontWeight={"600"}>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              borderBottom: 0,
                            }}
                          >
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={row.status}
                              // color="warning"
                              sx={{
                                color: statusFontColor(row.status),
                                border: isDarkTheme
                                  ? "0.7px solid"
                                  : "0px solid",
                                // borderColor: statusFontColor(row.status),
                                backgroundColor: isDarkTheme
                                  ? "transparent"
                                  : getStatusBackgroundColor(row.status),
                              }}
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              borderBottom: 0,
                            }}
                          >
                            {row.currency_symbol} {row.total}
                          </TableCell>
                          <TableCell sx={{ borderBottom: 0 }} align="right">
                            {row.payment_method}
                          </TableCell>
                          {/* <TableCell align="right">{row.carbs}</TableCell> */}
                        </StyledTableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
          {/* <Stack justifyContent={"flex-end"}>
              <Button
                variant="text"
                endIcon={<ArrowForwardIosIcon style={{ fontSize: "15px" }} />}
                sx={{ m: 2, marginLeft: "auto" }}
                onClick={() => navigate("/orders")}
              >
                See More
              </Button>
            </Stack> */}
        </Paper>
        {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          /> */}
      </Box>
    </Box>
  );
}

export default RecentProductActivity;

const options = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: globalColors,
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

export const getStatusBackgroundColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "completed" || "active":
      return "#e0f6ed";
    case "pending":
      return "#f4f3e6";
    case "processing":
      return "#EAECFB";
    case "failed":
      return "#F8D1DA";
    case "refunded":
      return "#F8D1DA";
    case "cancelled":
      return "#f2e5e8";
    case "on-hold":
      return "#AAEDF9";
    default:
      return "#EAECFB";
    // case "completed":
    //   return "#f7e0d2";
    // case "pending":
    //   return "#f7e0d2";
    // default:
    //   return "#e8e8e8";
  }
};

export const statusFontColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "completed" || "active":
      return "#1aae6f";
    case "pending":
      return "#93ad05";
    case "processing":
      return "#5570bc";
    case "failed":
      return "#ED3833";
    case "refunded":
      return "#ED3833";
    case "cancelled":
      return "#910905";
    case "on-hold":
      return "#46ADCA";
    default:
      return "#043b69";
  }
};

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

// const rows = [
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Donut", 452, 25.0, 51, 4.9),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Honeycomb", 408, 3.2, 87, 6.5),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Jelly Bean", 375, 0.0, 94, 0.0),
//   createData("KitKat", 518, 26.0, 65, 7.0),
//   createData("Lollipop", 392, 0.2, 98, 0.0),
//   createData("Marshmallow", 318, 0, 81, 2.0),
//   createData("Nougat", 360, 19.0, 9, 37.0),
//   createData("Oreo", 437, 18.0, 63, 4.0),
// ];

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order",
    numeric: false,
    disablePadding: true,
    label: "Order",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "method",
    numeric: true,
    disablePadding: false,
    label: "Method",
  },
  //   {
  //     id: "action",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Action",
  //   },
  // {
  //   id: "protein",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Protein (g)",
  // },
];
const EnhancedTableToolbar = (props) => {
  const { numSelected, params, setParams } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const filterOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterClose = () => {
    setAnchorEl(null);
  };

  const onFilterPress = (value) => {
    setParams((state) => ({ ...state, status: value }));

    filterClose();
    dispatch(
      getAllOrders({ ...params, status: value, ...defaultParams }, true)
    );
  };

  const onFilterClear = () => {
    let { status, ...otherParams } = params;
    setParams((state) => ({ ...otherParams }));
    dispatch(getAllOrders({ ...otherParams, ...defaultParams }, true));
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Recent Orders
          </Typography>
        )}

        {/* {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton
                onClick={filterClick}
                aria-controls={filterOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpen ? "true" : undefined}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )} */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={filterOpen}
          onClose={filterClose}
          // onClick={(e) => onFilterPress(e.target.value)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {allPaymentStatus.map((i) => (
            <MenuItem value={i.value} onClick={() => onFilterPress(i.value)}>
              {i.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <Collapse in={Boolean(params.status)}>
        <Stack justifyContent="space-between" alignItems="flex-start" px={2}>
          <Chip
            label={params.status ? formatServerValue(params.status) : ""}
            onDelete={onFilterClear}
          />
        </Stack>
      </Collapse>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            sx={{ color: "text.secondary", fontSize: "15px" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
