export const attributePopoverStyles = {
  outerBox: {
    padding: "5px 9px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
    width: "250px",
    marginTop: "10px",
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 10px",
    paddingBottom: "6px",
    paddingTop: "6px",
    borderRadius: "4px",
    fontFamily: "Inter",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  mainTitle: {
    color: "#9ba6b3",
    fontSize: "15px",
    fontWeight: 600,
  },
  innerBoxTitle: {
    color: "#22272d",
    fontWeight: 600,
    fontSize: 16,
  },
};
