import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Popover,
  Autocomplete,
  TextField,
} from "@mui/material";
import AttributePopover from "./AttributesPopover";

const TextComponent = ({
  currentTemplatePreview,
  currentTemplateState,
  setCurrentTemplateState,
  component,
  index,
}) => {
  const globalAttributes = useSelector((state) => state.flow?.globalAttributes);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [optionSelected, setOptionSelected] = useState("");
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);
  const [currentText, setCurrentText] = useState(component.text);
  // const options = component.example.header_text;
  const options = globalAttributes;

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  function setDynamicValues(template, dynamicValues) {
    let result = template;

    if (Array.isArray(dynamicValues)) {
      dynamicValues.forEach((value, index) => {
        const placeholder = new RegExp(`{{${index + 1}}}`, "g");
        result = result.replace(placeholder, value);
      });
    } else if (typeof dynamicValues === "string") {
      const placeholder = "{{1}}"; // for a single string value
      result = result.replace(placeholder, dynamicValues);
    }

    return result;
  }

  const handleVariables = (e, value) => {
    const updatedText = setDynamicValues(currentText, value);
    setCurrentText(updatedText);
    setOptionSelected(value);
    const updatedComponents = [...currentTemplatePreview.components];
    updatedComponents[index] = {
      ...updatedComponents[index],
      variables_value: value,
    };

    setCurrentTemplateState((prev) => ({
      ...prev,
      components: updatedComponents,
    }));
    setAttributeAnchorEl(null);
  };

  const handleClick = (event) => {
    if (!options) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (component?.variables_value) {
      setOptionSelected(component?.variables_value);
    }
  }, [component?.variables_value]);

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Inter",
          }}
        >
          {currentText}
        </Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box sx={{ height: "200px", width: "300px", padding: "15px" }}>
          <Typography
            variant="span"
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "20px",
              color: "#22272d",
            }}
          >
            Select Attributes
          </Typography>
          <TextField value={optionSelected} onClick={handleAttributePopover} />
          <AttributePopover
            handleVariables={handleVariables}
            attributeAnchorEl={attributeAnchorEl}
            setAttributeAnchorEl={setAttributeAnchorEl}
            optionSelected={optionSelected}
          />
        </Box>
      </Popover>
    </>
  );
};

export default TextComponent;
