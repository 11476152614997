export const chatbotHeaderStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    backgroundColor: "rgb(255, 255, 255)",
    borderLeft: "0.5px solid rgb(234, 238, 243)",
    borderRight: "0.5px solid rgb(234, 238, 243)",
    borderTop: "0.5px solid rgb(234, 238, 243)",
  },
  cardDiv: {
    padding: "10px 0px 10px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardContentOuterDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  botLogo: {
    width: 30,
    height: 30,
    backgroundColor: "rgb(234, 238, 243)",
    borderRadius: "50%",
    padding: "7px",
    marginLeft: "20px",
    position: "relative",
    right: "13px",
  },
  headerTextField: {
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: "55px",
  },
  botNameText: {
    color: "rgb(0, 0, 0)",
    fontSize: "19px",
    fontFamily: "Inter, Roboto, sans-serif",
    fontWeight: "600",
  },
  botStatusText: {
    color: "rgb(155, 166, 179)",
    fontSize: "12px",
    fontFamily: "Inter, Roboto, sans-serif",
    position: "relative",
    bottom: "4px",
    maxWidth: "150px",
    overflowWrap: "break-word",
  },
  headerIconsDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
  },
  headerIcon: {
    color: "rgb(94, 97, 101)",
    fontSize: "24px",
    fontWeight: "bold",
  },
  popoverDiv: {
    fontFamily: "Inter, Roboto, sans-serif",
    display: "flex",
    borderRadius: "15px",
    flexDirection: "column",
    // height: "180px",
    width: "200px",
    // alignItems: "space-around",
    justifyContent: "center",
  },
  optionsDiv: {
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between",
    cursor: "pointer",
    borderRadius: "7px",
  },
  iconButtons: {
    color: "black",
    marginLeft: "15px",
    marginRight: "10px",
    fontWeight: "600",
    transition: "transform 0.3s",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  liveAgentIcon: {
    color: "green",
    fontSize: "10px",
    position: "relative",
    left: "52px",
    bottom: "-3px",
    zIndex: 99999,
  },
  optionsTextField: {
    fontSize: "16px",
    fontWeight: "600",
  },
  imgDiv: {
    display: "flex",
  },
};
