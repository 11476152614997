const url = "https://websockets.tanika.tech/custom_axios";

const headers = new Headers();
headers.append("authority", "websockets.tanika.tech");
headers.append("accept", "application/json, text/plain, */*");
headers.append("Content-Type", "application/json");

export const fetchSmsTemplatesData = async () => {
  const payload = {
    payload: {
      url: "https://control.msg91.com/api/v5/flows",
      params: {
        pluginsource: 73,
      },
      headers: {
        authkey: "309226AXQUfgVYOI5dfcab34P1",
      },
    },
  };

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
    compress: true,
  };

  try {
    const response = await fetch(url, requestOptions);
    const res = await response.json();
    console.log("Payload:", res?.data);
    return res?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSmsTemplates = async () => {
  const response = await fetchSmsTemplatesData();
  const flows = response?.data;

  const templates = await Promise.all(
    flows.map(async (flow) => {
      const templatePayload = {
        payload: {
          url: "https://control.msg91.com/api/v5/sms/getTemplateVersions",
          params: {
            template_id: flow.flow_id,
            pluginsource: 73,
          },
          headers: {
            authkey: "309226AXQUfgVYOI5dfcab34P1",
          },
        },
      };

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(templatePayload),
        compress: true,
      };

      try {
        const response = await fetch(url, requestOptions);
        const templateData = await response.json();

        return {
          flow_name: flow?.flow_name,
          template_data: templateData?.data,
        };
      } catch (error) {
        console.error(`Error fetching template for ${flow.flow_name}:`, error);
        return null;
      }
    })
  );

  console.log("All Templates:", templates.filter(Boolean));
  return templates.filter(Boolean);
};
