export const userAttachmentNodeStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Inter",
    marginRight: "20px",
    paddingLeft: "20px",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeBody: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "45px",
    minWidth: "45px",
    fontSize: "16px",
    color: "#384350",
    backgroundColor: "#22304a",
    borderRadius: "4px",
    transform: "rotate(45deg)",
    border: "0.7px solid #e2e5e9",
    marginBottom: "30px",
  },

  addIcon: {
    fontSize: "14px",
    height: "20px",
    width: "20px",
    color: "black",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "13px",
    left: "12px",
    "&:hover": {
      color: "blue",
    },
  },
};
