import { useState } from "react";
import { Typography, IconButton, CardMedia, CardContent, Box } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { botStyles } from "../styles/botStyles";
import PropTypes from "prop-types";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import { uploadDataToAwsBucket } from "../utils/uploadDataToAwsBucket";
import { bytesToMegabytes } from "../utils/bytesToMegabytes";
import BounceLoader from "react-spinners/BounceLoader";
import CircularProgress from "@mui/material/CircularProgress";

const styles = botStyles;

const BotImageField = ({ botData, setBotData, data, index, fileInputRefs }) => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileSelect = async (e, data) => {
    setUploadingFile(true);
    const uploadedFile = e.target.files[0];
    const imageSize = bytesToMegabytes(uploadedFile?.size);

    if (imageSize > 5) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const id = data.id;
    const imageURL = res.data.source_url;

    setBotData((imageFields) => {
      return imageFields.map((field) => {
        if (field.id === id) {
          return { ...field, file: imageURL };
        }
        return field;
      });
    });
    setUploadingFile(false);
  };

  const handleButtonClick = (id) => {
    if (fileInputRefs.current[id]) {
      fileInputRefs.current[id].current.click();
    }
  };

  return (
    <Box key={data.id} style={{ marginBottom: "10px", display: "flex" }}>
      <DeleteAndSwap index={index} setBotData={setBotData} botData={botData} />

      {!data?.file ? (
        <CardContent style={{...styles.imageCard, width: "300px"}}>
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            style={{ display: "none" }}
            ref={fileInputRefs.current[data.id]}
            onChange={(e) => handleFileSelect(e, data)}
          />

          {uploadingFile ? (
            <>
              <CircularProgress />
              <Typography variant="span">Uploading, please wait...</Typography>
            </>
          ) : (
            <>
              <IconButton onClick={() => handleButtonClick(data.id)}>
                <PhotoCameraIcon style={{ fontSize: "50px" }} />
              </IconButton>
              <Typography sx={{ fontSize: "12px" }}>Upload Image </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                upto 5Mb allowed
              </Typography>
            </>
          )}
        </CardContent>
      ) : (
        <CardContent style={styles.imageCard}>
          <CardMedia
            style={styles.imageComponent}
            component="img"
            sx={{  height: "150px", objectFit: "contain" }}
            image={data.file}
            alt="Selected Image"
          />
        </CardContent>
      )}
    </Box>
  );
};

BotImageField.propTypes = {
  setBotData: PropTypes.func.isRequired,
  botData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fileInputRefs: PropTypes.object.isRequired,
};

export default BotImageField;
