import { onAddEmail } from "../../utils/EmailNode";

export const createEmailNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);

  const axis = {
    x: currNode.position.x + 220,
    y: currNode.position.y - 11.5,
  };

  onAddEmail(dispatch, axis, nodeId);
};
