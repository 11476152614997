import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import { questionReplies } from "../styles/questionReplies";
import * as actions from "../../../../../../redux/actions/flowsActions";
import AddIcon from "@mui/icons-material/Add";
import AddNodesButton from "../helper/AddNodesButton";
import CloseIcon from "@mui/icons-material/Close";
import NodePopover from "../helper/NodePopover";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = questionReplies;

const Failure = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="span"
            sx={{
              fontSize: "13px",
              alignItems: "center",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            {title || "‎"}
          </Typography>
        </div>
      </div>

      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        <Box sx={styles.nodeBody} onClick={handleMain} className="failureNode">
          <IconButton
            sx={{
              ...styles.iconButton,
              backgroundColor: "#dc3d33",
              "&:hover": {
                backgroundColor: "#dc3d33",
              },
            }}
          >
            <CloseIcon sx={styles.nodeIcon} />
          </IconButton>
          <Typography variant="span" sx={styles.titleName}>
            Failure
          </Typography>
          <Handle type="source" position="right" style={styles.rightHandle} />
        </Box>
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
        />
      </Box>
    </Box>
  );
};

export default Failure;
