export const initialEdges = {
  startEdge: {
    id: Date.now(),
    source: "0001",
    target: "0002",
    style: {
      strokeWidth: 2.2,
      stroke: '#566e8a',
    },
  },

  startBot: {
    id: Date.now() + 1,
    source: "0001",
    target: "0004",
    style: {
      strokeWidth: 2.2,
      stroke: '#566e8a',
    },
  },

  defaultBot: {
    id: Date.now() + 2,
    source: "0002",
    target: "0003",
    style: {
      strokeWidth: 2.2,
      stroke: '#566e8a',
    },
  },
};
