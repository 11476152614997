import { useState, useEffect } from "react";
import { Button, Popover, Typography, Box, TextField } from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiTelInput } from "mui-tel-input";
import PropTypes from "prop-types";
import { buttonPopoverStyles } from "../sidebar-bot/styles/buttonPopoverStyles";
import GoToBlockPopover from "../helper/GoToBlockPopover";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const styles = buttonPopoverStyles;

const ButtonPopover = ({
  fieldId,
  buttonId,
  fieldType,
  button,
  title,
  botData,
  setBotData,
  currentIndex,
  cardId,
}) => {
  const [value, setValue] = useState("+91");
  const [anchorEl, setAnchorEl] = useState(null);
  const [goToBlockPopover, setGoToBlockPopover] = useState(null);
  const [isDeleteIconVisible, setDeleteIconVisible] = useState(false);
  const currentButtons = botData.find((data) => data.id === fieldId);

  let currentButtonLength;
  if (fieldType !== "gallery") {
    currentButtonLength = currentButtons?.buttons.length;
  } else {
    const currentCard = currentButtons?.cards.find(
      (card) => card?.id === cardId
    );
    currentButtonLength = currentCard?.buttons.length;
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(
      (key) => object[key].toLowerCase() === value.toLowerCase()
    );
  };

  const [formData, setFormData] = useState({
    title: "Button",
    type: "send_message",
    url: "",
    number: "",
    block: "",
    go_to_block: "",
  });

  const open = Boolean(anchorEl);
  const id = open ? "form-popover" : undefined;

  const buttonsFieldTypes = [
    "Send Message",
    "Open URL",
    "Go To Block",
    "Phone Number",
  ];

  const buttonLabelValueMatch = {
    send_message: "Send Message",
    open_url: "Open URL",
    go_to_block: "Go To Block",
    phone_number: "Phone Number",
  };

  const quickRepliesFieldTypes = ["Send Message", "Go to Block"];
  const buttonTypes =
    fieldType === "quick-reply" ? quickRepliesFieldTypes : buttonsFieldTypes;

  const handleClickPopover = (e) => {
    setGoToBlockPopover(e.currentTarget);
  };

  const handlePhone = (newValue) => {
    setValue(newValue);
    setFormData((prevData) => ({
      ...prevData,
      number: newValue,
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const saveData = (formData, id) => {
    const btnData = {
      title: formData?.title,
      type: formData?.type,
      url: formData?.url,
      number: formData?.number,
      block: formData?.block,
      go_to_block: formData?.go_to_block,
    };

    setBotData((fields) => {
      return fields.map((field) => {
        if (field?.buttons) {
          const updatedButtons = field?.buttons.map((button) => {
            if (button?.id === id) {
              return { ...button, ...btnData };
            }
            return button;
          });
          return { ...field, buttons: updatedButtons };
        }
        return field;
      });
    });
  };

  const saveDataGallery = (formData, buttonId) => {
    const btnData = {
      title: formData?.title,
      type: formData?.type,
      url: formData?.url,
      number: formData?.number,
      block: formData?.block,
      go_to_block: formData?.go_to_block,
    };

    setBotData((fields) => {
      return fields.map((field) => {
        if (field?.type === "gallery") {
          const updatedCards = field?.cards.map((card) => {
            const updatedButtons = card?.buttons.map((button) => {
              if (button.id === buttonId) {
                return { ...button, ...btnData };
              }
              return button;
            });

            return { ...card, buttons: updatedButtons };
          });

          return { ...field, cards: updatedCards };
        }
        return field;
      });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fieldType === "gallery") {
      saveDataGallery(formData, buttonId);
    } else {
      saveData(formData, buttonId);
    }
    handleClose();
  };

  const handleMouseEnter = () => {
    setDeleteIconVisible(true);
  };

  const handleMouseLeave = () => {
    setDeleteIconVisible(false);
  };

  const handleDelete = () => {
    console.log("clicked", cardId, fieldType, fieldId);

    if (fieldType === "gallery") {
      console.log(fieldId);
      const currentField = botData?.map((item) => {
        if (item.id === fieldId) {
          const updatedCards = item.cards.map((card) => {
            if (card.id === cardId) {
              const updatedButtons = card.buttons.filter(
                (button) => button.id !== buttonId
              );
              return { ...card, buttons: updatedButtons };
            }
            return card;
          });

          return { ...item, cards: updatedCards };
        }
        return item;
      });
      setBotData(currentField);
      return;
    }

    const updatedBotData = botData.map((item) => {
      if (item.id === fieldId) {
        return {
          ...item,
          buttons: item.buttons.filter((button) => button.id !== buttonId),
        };
      }

      return item;
    });
    setBotData(updatedBotData);
  };

  const buttonsFieldButton = (
    <Box
      sx={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        variant="standard"
        style={styles.buttonsFieldButton}
        onClick={handleClick}
        fullWidth
      >
        {title}
      </Button>
      {isDeleteIconVisible && currentButtonLength > 1 && (
        <DeleteOutlineIcon
          sx={{
            ...styles.deleteIcon,
            top: "4px",
            right: "-11px",
            fontSize: "20px",
          }}
          onClick={handleDelete}
        />
      )}
    </Box>
  );

  const quickReplyButton = (
    <Box
      sx={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        variant="outlined"
        onClick={handleClick}
        style={styles.quickReplyButton}
      >
        {title}
      </Button>
      {isDeleteIconVisible && currentButtonLength > 1 && (
        <DeleteOutlineIcon sx={styles.deleteIcon} onClick={handleDelete} />
      )}
    </Box>
  );

  const galleryFieldButton = (
    <Box
      sx={{ position: "relative", zIndex: 99999 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        onClick={handleClick}
        style={{
          ...styles.galleryFieldButton,
          "&:hover": {
            backgroundColor: "#000",
          },
          borderBottom:
            currentIndex === currentButtonLength - 1
              ? "none"
              : "1.5px solid rgb(205 195 195)",
          borderRadius:
            currentIndex === currentButtonLength - 1 ? "15px" : "0px",
        }}
        fullWidth
      >
        {title}
      </Button>
      {isDeleteIconVisible && currentButtonLength > 1 && (
        <DeleteOutlineIcon
          sx={{ ...styles.deleteIcon, right: "-11px", top: "5px" }}
          onClick={handleDelete}
        />
      )}
    </Box>
  );

  useEffect(() => {
    setFormData(button);
  }, [button]);

  return (
    <>
      {(() => {
        if (fieldType === "buttons") {
          return buttonsFieldButton;
        } else if (fieldType === "gallery") {
          return galleryFieldButton;
        } else if (fieldType === "quick-reply") {
          return quickReplyButton;
        }
      })()}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: { width: "350px" },
        }}
      >
        <Box p={2} style={{ fontFamily: "Inter, Roboto, sans-serif" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SegmentIcon
              sx={{
                position: "relative",
                bottom: "7px",
              }}
            />
            <Typography
              variant="h6"
              style={{
                marginBottom: "15px",
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              General
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Typography
              sx={{
                marginBottom: "14px",
                fontSize: "14px",
                fontFamily: "Inter",
              }}
              variant="span"
            >
              Button Title
            </Typography>
            <TextField
              style={{ marginBottom: "15px" }}
              variant="outlined"
              fullWidth
              value={formData?.title}
              onChange={handleChange}
              size="small"
              name="title"
              InputProps={{
                style: { fontFamily: "Inter", fontSize: "14px" },
              }}
              inputProps={{ maxLength: 30 }}
            />
            <Typography
              variant="span"
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Button Type
            </Typography>
            <Autocomplete
              style={{ marginBottom: "15px" }}
              options={buttonTypes}
              fullWidth
              value={buttonLabelValueMatch[formData?.type] || ""}
              onChange={(event, newValue) => {
                const label = getKeyByValue(buttonLabelValueMatch, newValue);
                setFormData((prevData) => ({
                  ...prevData,
                  type: label,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  name="type"
                  InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: "Inter", fontSize: "14px" },
                  }}
                  value={buttonLabelValueMatch[formData?.type]}
                  onChange={handleChange}
                />
              )}
            />
            {formData?.type == "phone_number" && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                  variant="span"
                >
                  Phone number
                </Typography>
                <MuiTelInput
                  style={{
                    marginBottom: "10px",
                    fontFamily: "Inter",
                    fontSize: "14px",
                  }}
                  value={value}
                  onChange={handlePhone}
                  size="small"
                  placeholder="Enter phone number"
                  fullWidth
                />
              </>
            )}

            {formData?.type == "open_url" && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    marginBottom: "15px",
                  }}
                  variant="span"
                >
                  Website Address
                </Typography>
                <TextField
                  inputProps={{
                    maxLength: 40,
                  }}
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    marginBottom: "15px",
                  }}
                  variant="outlined"
                  placeholder="Enter website"
                  onChange={handleChange}
                  size="small"
                  value={formData?.url}
                  name="url"
                  fullWidth
                  required
                />
              </>
            )}

            {formData?.type == "go_to_block" && (
              <>
                <Typography style={{ marginBottom: "15px" }} variant="span">
                  Go to
                </Typography>
                <TextField
                  inputProps={{
                    maxLength: 40,
                  }}
                  InputProps={{
                    style: { fontFamily: "Inter", fontSize: "14px" },
                  }}
                  size="small"
                  style={{ marginBottom: "15px" }}
                  placeholder="Select value"
                  fullWidth
                  value={formData?.block || ""}
                  onClick={handleClickPopover}
                />
                <GoToBlockPopover
                  goToBlockPopover={goToBlockPopover}
                  setFormData={setFormData}
                  formData={formData}
                  setGoToBlockPopover={setGoToBlockPopover}
                />
              </>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                width: "100%",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "14px",
                backgroundColor: "#008aff",
              }}
            >
              Save settings
            </Button>
          </form>
        </Box>
      </Popover>
    </>
  );
};

ButtonPopover.propTypes = {
  botData: PropTypes.array.isRequired,
  setBotData: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  button: PropTypes.array.isRequired,
  buttonId: PropTypes.string.isRequired,
};

export default ButtonPopover;
