export const delayStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  nodeInitial: {
    position: "relative",
    top: "20px",
    left: "10px",
    color: "#797b8c",
    textAlign: "center",
    paddingRight: "35px",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeBody: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
    height: "30px",
    padding: "0 10px 0 10px",
    fontSize: "16px",
    color: "#363636",
    backgroundColor: "#bae8e8",
    borderRadius: "20px",
    marginBottom: "30px",
  },
  titleName: {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 550,
  },
  iconButton: {
    marginLeft: "3px",
  },
  nodeIcon: {
    height: "16px",
    width: "16px",
    color: "#072541",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#98E4FF",
    },
  },
  addIcon: {
    fontSize: "14px",
    height: "20px",
    width: "20px",
    color: "#22304a",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "13px",
    "&:hover": {
      color: "blue",
    },
  },
  leftHandle: {
    background: "#566d89",
    opacity: "0",
    top: "-10px",
    left: "1px",
    position: "relative",
  },
  rightHandle: {
    background: "#566d89",
    opacity: "0",
    top: "4px",
    left: "19px",
    position: "relative",
  },
};
