export const userMessageStyles = {
  mainDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    marginLeft: "7px",
    marginBottom: "-3px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  chatHeaderText: {
    marginRight: "15px",
    fontSize: "13px",
  },
  chatFieldDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: "4px",
  },
  chatTextField: {
    padding: "15px",
    background: "rgb(0, 68, 233)",
    color: "rgb(255, 255, 255)",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "15px",
    display: "inline-block",
    fontFamily: "Inter, Roboto, sans-serif",
  },
  chatFooterDiv: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  chatFooterText: {
    fontFamily: "Inter, Roboto, sans-serif",
    fontSize: "13px",
    marginTop: "3px",
    marginRight: "10px",
    marginBottom: "20px",
  },
};
