import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallFromSocket,
  getAxiosError,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  liveSocketLinkWithoutProtocol,
  nodeWebsite,
  primaryLabel,
  secondaryLabel,
  settingsEndpoint,
  sleep,
  StackRow,
  TabPanel,
} from "../../../../../helper";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { editSettings } from "../../../../../redux/actions/settingActions";
import AllCustomer from "./AllCustomer";
import { OpenInNew } from "@mui/icons-material";
const tabs = [
  // {
  //     label: "Overview",
  //     value: "overview",
  // },
  // {
  //     label: "Mapping",
  //     value: "mapping",
  // },
  {
    label: "Sync",
    value: "Sync",
  },
  { label: "About", value: "about" },
  // { label: "Activity", value: "history" },
];
const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("Sync");
  const [settings, setSettings] = useState({});
  const shopifySettingsFetched = useSelector(
    (state) => state.settings.shopifySettingsFetched
  );
  const [showProductsSettings, setShowProductSettings] = useState(true);
  const [showOrdersSettings, setShowOrdersettings] = useState(true);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    let onlyCRM = user_products?.length === 1 && user_products.includes("crm");
    setShowProductSettings(!onlyCRM);
    setShowOrdersettings(!onlyCRM);
  }, [user_products]);

  const fetchShopifySettings = async () => {
    dispatch(
      editSettings({
        fetchingShopifySettings: true,
      })
    );
    let settings = await settingsEndpoint({
      endpoint: "shopify_settings",
    });
    if (settings) {
      setSettings(settings);
      dispatch(
        editSettings({
          shopifySettingsFetched: true,
          fetchingShopifySettings: false,
        })
      );
    }
  };

  useEffect(() => {
    if (!shopifySettingsFetched) fetchShopifySettings();
  }, []);
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"Sync"}>
        <Sync
          settings={settings}
          showProductsSettings={showProductsSettings}
          showOrdersSettings={showOrdersSettings}
        />
      </TabPanel>
      <TabPanel index={selectedTab} value={"about"}>
        <About
          settings={settings}
          showProductsSettings={showProductsSettings}
          showOrdersSettings={showOrdersSettings}
        />
      </TabPanel>
    </Box>
  );
};

export default Settings;

const webhooks = isDev()
  ? {
      customers_create: ["customers/create"],
      customers_update: ["customers/update"],
      customers_delete: ["customers/delete"],
      products_create: ["products/create"],
      products_update: ["products/update"],
      products_delete: ["products/delete"],
      orders: [
        "orders/create",
        "orders/cancelled",
        "orders/delete",
        "orders/edited",
        "orders/fulfilled",
        "orders/paid",
        "orders/partially_fulfilled",
        "orders/updated",
      ],
    }
  : {
      customers_create: ["customers/create"],
      customers_update: ["customers/update"],
      customers_delete: ["customers/delete"],
      // products_create: ["products/create"],
      // products_update: ["products/update"],
      // products_delete: ["products/delete"],
      orders: [
        "orders/create",
        "orders/cancelled",
        "orders/delete",
        "orders/edited",
        "orders/fulfilled",
        "orders/paid",
        "orders/partially_fulfilled",
        "orders/updated",
      ],
    };

const createShopifyWebhook = async ({
  shop,
  access_token,
  topic,
  store_id,
}) => {
  try {
    const response = await apiCallFromSocket({
      method: "POST",
      url: `https://${shop}/admin/api/2023-01/webhooks.json`,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Access-Token": access_token,
      },
      data: {
        webhook: {
          topic,
          // address: `${website}/wc-api/ecommerce`,
          address: `https://${liveSocketLinkWithoutProtocol}/shopify/${store_id}`,
          format: "json",
        },
      },
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
const getShopifyWebhooks = async ({ shop, access_token }) => {
  try {
    const response = await apiCallFromSocket({
      method: "GET",
      url: `https://${shop}/admin/api/2023-01/webhooks.json`,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Access-Token": access_token,
      },
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
const deleteShopifyWebhook = async ({ id, shop, access_token }) => {
  try {
    const response = await apiCallFromSocket({
      method: "DELETE",
      url: `https://${shop}/admin/api/2023-01/webhooks/${id}.json`,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Access-Token": access_token,
      },
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};

const About = () => {
  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  return (
    <Box sx={{ p: 4 }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ ...secondaryLabel }}>Store Owner</Typography>
        <Typography sx={{ ...primaryLabel }}>
          {reduxShopifyIntegration?.shopInfo?.shop_owner}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
      >
        <Typography sx={{ ...secondaryLabel }}>Store Domain</Typography>
        <Stack
          direction="row"
          onClick={() => {
            window.open(
              `https://${reduxShopifyIntegration?.shopInfo?.domain}`,
              "_blank"
            );
          }}
          alignItems="center"
          spacing={2}
          sx={{
            "&:hover": { color: "primary.main" },
            cursor: "pointer",
          }}
        >
          <Typography sx={{ ...primaryLabel }}>
            {reduxShopifyIntegration?.shopInfo?.domain}
          </Typography>
          <OpenInNew style={{ height: "17px", width: "17px" }} />
        </Stack>
      </Stack>
    </Box>
  );
};

const Sync = ({ settings, showProductsSettings, showOrdersSettings }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState("");

  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const reduxShopifySettings = useSelector(
    (state) => state.settings.shopifySettings
  );
  const fetchingShopifySync = useSelector(
    (state) => state.settings.fetchingShopifySync
  );
  const fetchedShopifySync = useSelector(
    (state) => state.settings.fetchedShopifySync
  );
  const fetchingShopifySettings = useSelector(
    (state) => state.settings.fetchingShopifySettings
  );

  useEffect(() => {
    if (
      isObjWithValues(reduxShopifyIntegration) &&
      reduxShopifyIntegration.integrated &&
      !fetchedShopifySync
    )
      setupWebhooks();
  }, [reduxShopifyIntegration]);

  const setupWebhooks = async () => {
    let obj = {};
    dispatch(
      editSettings({
        fetchingShopifySync: true,
      })
    );
    let getWebhooksRes = await getShopifyWebhooks({
      access_token: reduxShopifyIntegration.access_token,
      shop: reduxShopifyIntegration.shop,
    });
    if (getWebhooksRes?.data) {
      let allWebhooks = getWebhooksRes?.data?.data?.webhooks;
      for (let webhookTopic in webhooks) {
        obj[webhookTopic] = {
          enabled: webhooks[webhookTopic].every((topic) =>
            allWebhooks?.find((i) => i.topic === topic)
          ),
          webhooks: allWebhooks?.filter((i) =>
            webhooks[webhookTopic].includes(i.topic)
          ),
        };
      }
      // obj.customers = {
      //   enabled: webhooks.customers.every((topic) =>
      //     allWebhooks?.find((i) => i.topic === topic)
      //   ),
      //   webhooks: allWebhooks?.filter((i) =>
      //     webhooks.customers.includes(i.topic)
      //   ),
      // };
      // obj.orders = {
      //   enabled: webhooks.orders.every((topic) =>
      //     allWebhooks?.find((i) => i.topic === topic)
      //   ),
      //   webhooks: allWebhooks?.filter((i) => webhooks.orders.includes(i.topic)),
      // };
    }
    console.log(obj);
    let settings = await settingsEndpoint({
      method: "PUT",
      data: {
        sync: obj,
      },
      endpoint: "shopify_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          shopifySettings: settings,
          fetchedShopifySync: true,
        })
      );
    }
    dispatch(
      editSettings({
        fetchingShopifySync: false,
      })
    );
  };

  useEffect(() => {
    if (isObjWithValues(settings) && settings.sync) setForm(settings.sync);
  }, [settings]);

  useEffect(() => {
    if (isObjWithValues(reduxShopifySettings))
      setForm(reduxShopifySettings?.sync);
  }, [reduxShopifySettings]);
  const store_id = useSelector((state) => state.user.store_id);
  const dispatch = useDispatch();

  const editForm = (label, value, update = true) => {
    setForm((state) => ({
      ...state,
      [label]: { ...state[label], enabled: value },
    }));
    if (update) {
      if (value) subscribeShopifyTopic(label, value);
      else unsubscribeShopifyTopic(label, value);
    }
  };

  const subscribeShopifyTopic = async (label) => {
    let events = webhooks[label];
    let webhooksRes = [];
    setLoading(label);
    try {
      let allWebhooks = [];
      let getWebhooksRes = await getShopifyWebhooks({
        access_token: reduxShopifyIntegration.access_token,
        shop: reduxShopifyIntegration.shop,
      });
      if (getWebhooksRes?.data) {
        allWebhooks = getWebhooksRes?.data?.data?.webhooks;
      }
      for await (let event of events) {
        let res = await createShopifyWebhook({
          store_id,
          shop: reduxShopifyIntegration.shop,
          access_token: reduxShopifyIntegration.access_token,
          topic: event,
        });
        let { data, success } = res?.data || {};
        console.log(data, success);
        if (success) {
          if (data.webhook) webhooksRes.push(data.webhook);
        }
        if (
          !success &&
          data?.errors?.address?.[0]?.includes("topic has already been ")
        ) {
          let webhook = allWebhooks?.find((i) => i.topic === event);
          webhooksRes.push(webhook);
        }
        if (
          !success &&
          !data?.errors?.address?.[0]?.includes("topic has already been ")
        ) {
          await unsubscribeShopifyTopic(label, webhooks);
          return dispatch(
            setGlobalToast({
              show: true,
              message: "Enabling sync failed",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      console.log(error, "<<<<");
    }
    setLoading("");
    let settings = await settingsEndpoint({
      method: "PUT",
      data: {
        sync: {
          ...form,
          [label]: { ...form[label], enabled: true, webhooks: webhooksRes },
        },
      },
      endpoint: "shopify_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          shopifySettings: settings,
        })
      );
    }
  };
  const unsubscribeShopifyTopic = async (label, _webhooks) => {
    let webhooks = _webhooks || form[label]?.webhooks;
    setLoading(label);
    if (!isArrayWithValues(webhooks)) {
      let getWebhooksRes = await getShopifyWebhooks({
        access_token: reduxShopifyIntegration.access_token,
        shop: reduxShopifyIntegration.shop,
      });
      if (getWebhooksRes?.data) {
        webhooks = getWebhooksRes?.data?.data?.webhooks;
      }
    }
    try {
      if (isArrayWithValues(webhooks)) {
        for await (let webhook of webhooks) {
          let res = await deleteShopifyWebhook({
            shop: reduxShopifyIntegration.shop,
            access_token: reduxShopifyIntegration.access_token,
            id: webhook.id,
          });
          console.log(res);
        }
      }
    } catch (error) {
      console.log(error, "<<<<");
    }
    setLoading("");

    let settings = await settingsEndpoint({
      method: "PUT",
      data: { sync: { ...form, [label]: { enabled: false, webhooks: [] } } },
      endpoint: "shopify_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          shopifySettings: settings,
        })
      );
    }
  };

  console.log(fetchingShopifySync, fetchingShopifySettings);
  return (
    <Box>
      {/* <Typography
        variant="h6"
        color={"text.secondary"}
        sx={{ textAlign: "left", mt: 4, ml: 4 }}
      >
        Customer changes
      </Typography> */}
      <SwitchRow
        title={"Customer Create"}
        subtitle="Sync when customer gets created"
        loading={loading}
        forceLoading={fetchingShopifySync || fetchingShopifySettings}
        editForm={editForm}
        form={form}
        value={"customers_create"}
      />
      <SwitchRow
        title={"Customer update"}
        subtitle="Sync when customer gets updated"
        loading={loading}
        forceLoading={fetchingShopifySync || fetchingShopifySettings}
        editForm={editForm}
        form={form}
        value={"customers_update"}
      />
      {showOrdersSettings && (
        <>
          <SwitchRow
            title={"Customer Deleted"}
            subtitle="Sync when customer gets deleted"
            loading={loading}
            forceLoading={fetchingShopifySync || fetchingShopifySettings}
            editForm={editForm}
            form={form}
            value={"customers_delete"}
          />
          <SwitchRow
            title={"Customer Order"}
            loading={loading}
            forceLoading={fetchingShopifySync || fetchingShopifySettings}
            editForm={editForm}
            form={form}
            value={"orders"}
            subtitle="Sync customer order when it cancelled, created, deleted, edited, paid, updated"
          />
        </>
      )}
      {/* <Typography
        variant="h6"
        color={"text.secondary"}
        sx={{ textAlign: "left", mt: 6, ml: 4 }}
      >
        Product changes
      </Typography> */}
      {isDev() && (
        <>
          <SwitchRow
            title={"Product Create"}
            subtitle="Sync when product gets created"
            loading={loading}
            forceLoading={fetchingShopifySync || fetchingShopifySettings}
            editForm={editForm}
            form={form}
            value={"products_create"}
          />
          <SwitchRow
            title={"Product Update"}
            subtitle="Sync when product gets updated"
            loading={loading}
            forceLoading={fetchingShopifySync || fetchingShopifySettings}
            editForm={editForm}
            form={form}
            value={"products_update"}
          />
          <SwitchRow
            title={"Product Delete"}
            subtitle="Sync when product gets deleted"
            loading={loading}
            forceLoading={fetchingShopifySync || fetchingShopifySettings}
            editForm={editForm}
            form={form}
            value={"products_delete"}
          />
        </>
      )}
    </Box>
  );
};

const SwitchRow = ({
  form = {},
  title,
  subtitle,
  editForm,
  value,
  loading,
  forceLoading,
}) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Box sx={{ px: 4 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          {title}
        </Typography>
        <Switch
          disabled={loading === value || forceLoading}
          onChange={(e) => {
            editForm(value, e);
          }}
          checked={form[value]?.enabled}
          style={{
            border: `1px solid ${
              form[value]?.enabled ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form[value]?.enabled ? backgroundColor : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
