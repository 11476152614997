import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { textFieldStyles } from "../utils/chatbotFieldsStyles";

const styles = textFieldStyles

const BotTextMessage = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "4px",
      }}
    >
      <Typography
        variant="body1"
        style={styles.textField}
      >
        {text}
      </Typography>
    </div>
  );
};

BotTextMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BotTextMessage;
