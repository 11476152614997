export const actionStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "320px",
    maxHeight: "600px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 10px 105px 10px",
    position: "absolute",
    left: "10px",
    top: "70px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    userSelect: "none",
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerIcon: {
    paddingRight: "10px",
    // positon: "relative",
    // right: "15px",
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    marginLeft: "20px",
  },
  nodeBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "10px 20px 10px 20px",
    flexWrap: "wrap",
  },
  node: {
    // flexBasis: "48%",
    margin: "1%",
    // padding: "0 5px 0 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "10px",
    padding: "5px 0 5px  5px",
    cursor: "grab",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  nodeIcon: {
    backgroundColor: "#dcdcdc",
    padding: "10px",
    fontSize: "24px",
    borderRadius: "25%",
  },
  nodeLabel: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 14,
    marginLeft: "10px",
    wordBreak: "break-word",
    width: "80px",
  },
};
