import { Box, IconButton, Typography, TextField } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { productCatalogStyles } from "../styles/productCatalogStyles";

const styles = productCatalogStyles;

const Header = ({ handleClose, handleCheck, titleError, title, setTitle }) => {
  return (
    <Box sx={styles.topCard}>
      <Box sx={styles.innerCard}>
        <Box sx={styles.userHeader}>
          <CategoryIcon />
          <Typography variant="span" style={styles.headerTitle}>
            Product Catalog
          </Typography>
        </Box>
        <Box sx={styles.headerIconsMainDiv}>
          <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
            <CloseIcon sx={styles.headerIcons} />
          </IconButton>
          <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
            <CheckIcon sx={styles.headerIcons} />
          </IconButton>
        </Box>
      </Box>

      <TextField
        placeholder="Type block title"
        size="small"
        style={styles.titleTextField}
        onChange={(e) => setTitle(e.target.value)}
        error={titleError}
        value={title}
        required
      />
    </Box>
  );
};

export default Header;
