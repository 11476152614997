import { Add, Delete, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Slide,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  containsText,
  deepObjectComparison,
  dialogStyle,
  formatServerValue,
  getRandomString,
  getTotalDecimal,
  isArrayWithValues,
  isInRange,
  isObjWithValues,
  isRangeWithinRange,
  primaryLabel,
  sleep,
  validateNumber,
} from "../../helper";
import {
  setGlobalToast,
  setHelperData,
} from "../../redux/actions/helperActions";
import { addUserActivity } from "../../redux/actions/hubActions";
import {
  addDiamondGroup,
  deleteDiamondGroup,
} from "../../redux/actions/settingActions";
import { currencySymbols } from "./Gold";

const defaultState = {
  title: "",
  shape: "",
  from: "",
  to: "",
};

function DiamondGroup({ allShapes }) {
  const [addForm, setAddForm] = useState(defaultState);
  const [showAdd, setShowAdd] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weightsDropdown, setWeightsDropdown] = useState([]);
  const [toWeightsDropdown, setToWeightsDropdown] = useState([]);
  const [error, setError] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const dispatch = useDispatch();

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const [fromSearch, setFromSearch] = useState("");
  const [toSearch, setToSearch] = useState("");

  // useEffect(() => {
  //   if (!isObjWithValues(allDiamondGroups)) {
  //     dispatch(getDiamondGroups());
  //   }
  // }, []);

  useEffect(() => {
    if (addForm.shape) {
      let allWeights = [...(allDiamondWeights[addForm.shape] || [])];
      if (!isArrayWithValues(allWeights) && !isArrayWithValues(allGroups))
        return setWeightsDropdown([]);
      if (!addForm.id) {
        for (let group of allGroups) {
          let { shape, from, to } = group;
          if (shape !== addForm.shape) continue;
          let array = [...allWeights];
          let startIndex = array.findIndex((i) => {
            return Number(i.wt) == from;
          });
          let endIndex = array.findIndex((i) => Number(i.wt) == to);
          if (startIndex >= 0 && endIndex >= 0) {
            let deleted = allWeights.splice(startIndex, endIndex - startIndex);
          }
        }
      }
      if (addForm.from) {
        const startIndex = 0;
        let endIndex = allWeights.findIndex((i) => {
          return Number(i.wt) == addForm.from;
        });
        let tempWeights = [...allWeights];
        tempWeights.splice(startIndex, endIndex - startIndex + 1);
        setToWeightsDropdown(tempWeights);
      }
      setWeightsDropdown(allWeights);
    } else setWeightsDropdown([]);
  }, [addForm.shape, addForm.from]);

  useEffect(() => {
    if (isObjWithValues(allDiamondGroups))
      setAllGroups(Object.values(allDiamondGroups));
  }, [allDiamondGroups]);

  const openAdd = () => setShowAdd(true);
  const closeAdd = () => {
    setShowAdd(false);
    if (error) setError("");
    if (addForm.id) setAddForm(defaultState);
  };

  const editAddForm = (payload) => {
    if (error) setError("");
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const onEditPress = async (obj) => {
    setAddForm(obj);
    setShowAdd(true);
  };
  function addObject(arr, newObj) {
    for (let obj of arr) {
      if (obj.shape === newObj.shape) {
        if (
          (isInRange(newObj.from, obj.from, obj.to) ||
            isInRange(newObj.to, obj.from, obj.to) ||
            (newObj.from < obj.from && obj.to < newObj.to)) &&
          obj.id != newObj.id
        )
          return {
            shouldAdd: false,
            id: obj.id,
            message: `The new diamond group overlaps with existing diamond group "${obj.title}" (Range: ${obj.from} - ${obj.to}). Please enter a different range.`,
          };
      }
    }
    return { shouldAdd: true };
  }
  const onAddDimaond = () => {
    setLoading(true);
    let obj = {};
    let isUpdate = addForm.id;
    let { from, to } = addForm;
    if (from >= to) {
      setLoading(false);

      return setError(
        "The 'from' value is greater than the 'to' value. Please enter a valid range."
      );
    }
    if (getTotalDecimal(from) > 3 || getTotalDecimal(to) > 3) {
      setLoading(false);

      return setError(
        "The 'from' and 'to' values should not have more than 3 decimal places."
      );
    }
    // if (from >= to) return setError("To should be greater than From!");

    if (isUpdate) {
      let array = allDiamondWeights[addForm.shape];
      let fromObj = array.find((i) => i.wt == addForm.from) || {
        wt: addForm.from,
      };
      let toObj = array.find((i) => i.wt == addForm.to) || { wt: addForm.to };
      obj = { [addForm.id]: { ...addForm, fromObj, toObj } };

      let validateObj = addObject(allGroups, obj[addForm.id]);
      if (!validateObj.shouldAdd && validateObj.id != addForm.id) {
        setLoading(false);

        return setError(validateObj.message);
      }
      console.log(obj[addForm.id], addForm);
      if (!deepObjectComparison(obj[addForm.id], addForm))
        dispatch(
          setHelperData({
            changeInCombination: true,
          })
        );
      // if( obj[addForm.id] ,  ){
      //   dispatch(
      //     setHelperData({
      //       changeInCombination: false,
      //     })
      //   );
      // }
    } else {
      let array = allDiamondWeights[addForm.shape];
      let fromObj = array.find((i) => i.wt == addForm.from) || {
        wt: addForm.from,
      };
      let toObj = array.find((i) => i.wt == addForm.to) || { wt: addForm.to };
      let time = Math.floor(Date.now() / 1000);
      let id = `${time}_${getRandomString(5)}`;

      obj = {
        [id]: {
          id,
          fromObj,
          toObj,
          ...addForm,
        },
      };
      let validateObj = addObject(allGroups, obj[id]);
      if (!validateObj.shouldAdd) {
        setLoading(false);

        return setError(validateObj.message);
      }
      dispatch(
        setHelperData({
          changeInCombination: true,
        })
      );
    }
    dispatch(
      addDiamondGroup(
        obj,
        () => {
          setShowAdd(false);
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: `Diamond group ${
                !isUpdate ? "added" : "updated"
              } successfully`,
            })
          );
          setAddForm(defaultState);
          setLoading(false);
        },
        () => {
          dispatch(
            setGlobalToast({
              show: true,
              severity: "error",
              message: `Couldn't ${
                !isUpdate ? "add" : "update"
              } Diamond group!`,
            })
          );
        }
      )
    );
  };

  const onDeleteCombination = async () => {
    dispatch(
      deleteDiamondGroup(showDelete, setDeleteLoading, () => {
        setShowDelete(false);
      })
    );
  };

  const openDelete = (id) => {
    setShowDelete(id);
  };

  const closeDelete = () => setShowDelete(false);

  const globalPopupStyle = useSelector(
    (state) => state.helper.globalPopupStyle
  );
  return (
    <Box>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast((state) => ({ ...state, show: false }))}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Stack
        alignItems={"center"}
        justifyContent="flex-end"
        direction="row"
        // p={2}
        // border="0.5px solid #d1d1d1 "
        // borderRadius={"6px"}
      >
        <Button
          onClick={true ? openAdd : null}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          //   disabled={!isDefaultCurrency}
        >
          Add New Groups
        </Button>
      </Stack>

      <TableContainer
        component={Paper}
        sx={{
          mt: 3,
          borderRadius: "10px",
          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="left">Shape</TableCell>
              <TableCell align="left">From</TableCell>
              <TableCell align="left">To</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allGroups.map((row) => (
              <TableRow
                key={row.id}
                hover
                onClick={() => onEditPress(row)}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell align="left">
                  <Typography>{row.title}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>{formatServerValue(row.shape)}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Stack direction="row" alignItems={"center"}>
                    <Typography sx={{ fontWeight: "600" }}>
                      {isObjWithValues(row.fromObj) &&
                        row?.fromObj?.wt &&
                        `${row?.fromObj?.wt} ct.`}
                    </Typography>
                    <Typography>
                      {isObjWithValues(row.fromObj) &&
                        row?.fromObj?.size &&
                        ` (${row?.fromObj?.size} mm)`}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  <Stack direction="row" alignItems={"center"}>
                    <Typography sx={{ fontWeight: "600" }}>
                      {isObjWithValues(row.toObj) &&
                        row?.toObj?.wt &&
                        `${row?.toObj?.wt} ct.`}
                    </Typography>
                    <Typography>
                      {isObjWithValues(row.toObj) &&
                        row?.toObj?.size &&
                        ` (${row?.toObj?.size} mm)`}
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell align="left">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      openDelete(row.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showAdd}
        onClose={closeAdd}
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
        {...globalPopupStyle}
      >
        <DialogTitle>{addForm.id ? "Update" : "Add"} Combination</DialogTitle>
        <DialogContent>
          <Grid container spacing={4} mt={2}>
            <Grid sx={{ mb: 2 }} item xs={12} md={12}>
              <TextField
                variant="standard"
                value={addForm.title}
                onChange={(e) => editAddForm({ title: e.target.value })}
                label="Title"
                autoFocus
                fullWidth
                // disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Shapes</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Shapes"
                  value={addForm.shape}
                  onChange={(e) => editAddForm({ shape: e.target.value })}
                >
                  {isArrayWithValues(allShapes) &&
                    allShapes.map((i) => {
                      return (
                        <MenuItem value={i.value}>{`${i.label}`}</MenuItem>
                      );
                    })}
                  {/* {allDiamondWeights.round.slice(0, 7).map((i) => {
                    return <MenuItem>{`${i.size}mm (${i.wt} ct.)`}</MenuItem>;
                  })} */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">From</InputLabel>
              </FormControl> */}
              <TextField
                value={addForm.from}
                onChange={(e) => editAddForm({ from: e.target.value })}
                type="number"
                label="From (In Ct.)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={addForm.to}
                onChange={(e) => editAddForm({ to: e.target.value })}
                type="number"
                label="To (In Ct.)"
                fullWidth
              />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">To</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="To"
                  value={addForm.to}
                  onChange={(e) => editAddForm({ to: e.target.value })}
                  MenuProps={{ sx: { maxHeight: "300px" }, autoFocus: false }}
                >
                  {isArrayWithValues(toWeightsDropdown) &&
                    toWeightsDropdown.map((i) => {
                      return (
                        <MenuItem value={i.wt}>{`${i.wt} ct. ${
                          i.sieve ? `(${i.sieve}) ` : ""
                        } ${i.size ? `(${i.size} mm) ` : ""}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl> */}
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">From</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="From"
                  value={addForm.from}
                  onChange={(e) => editAddForm({ from: e.target.value })}
                  MenuProps={{ sx: { maxHeight: "300px" }, autoFocus: false }}
                >
                  {isArrayWithValues(weightsDropdown) &&
                    weightsDropdown.map((i) => {
                      return (
                        <MenuItem value={i.wt}>{`${i.wt} ct. ${
                          i.sieve ? `(${i.sieve}) ` : ""
                        } ${i.size ? `(${i.size} mm) ` : ""}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">To</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="To"
                  value={addForm.to}
                  onChange={(e) => editAddForm({ to: e.target.value })}
                  MenuProps={{ sx: { maxHeight: "300px" }, autoFocus: false }}
                >
                  {isArrayWithValues(toWeightsDropdown) &&
                    toWeightsDropdown.map((i) => {
                      return (
                        <MenuItem value={i.wt}>{`${i.wt} ct. ${
                          i.sieve ? `(${i.sieve}) ` : ""
                        } ${i.size ? `(${i.size} mm) ` : ""}`}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
          <Collapse in={Boolean(error)}>
            <Typography
              sx={{
                ...primaryLabel,
                color: "error.main",
                textAlign: "center",
                mt: 3,
              }}
            >
              {error}
            </Typography>
          </Collapse>
          <Stack
            mt={5}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button onClick={closeAdd}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={onAddDimaond}
              variant="contained"
              ml={2}
            >
              {addForm.id ? "Update" : "Add"} Group
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          Delete Diamond combination?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be done
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DiamondGroup;

// { value: "round", label: "Round" },
// { value: "princess", label: "Princess" },
// { value: "marquise", label: "Marquise" },
// { value: "pear", label: "Pear" },
// { value: "oval", label: "Oval" },
// { value: "tapered_baguette", label: "Tapered Baguette" }, ***
// { value: "heart", label: "Heart" },
// // { value: "bugget", label: "Bugget" },
// { value: "cushion", label: "Cushion" },
// { value: "radiant", label: "Radiant" },
// { value: "asscher", label: "Asscher" },
// { value: "trilliant", label: "Trilliant" },
// { value: "lozenge", label: "Lozenge" },  ***
// { value: "emerald", label: "Emerald" },
// { value: "halfmoon", label: "Half Moon" }, ***
// { value: "triangle", label: "Triangle" }, ***

const taperBaguetteSieves = [
  { wt: "0.01" },
  { wt: "0.015" },
  { wt: "0.02" },
  { wt: "0.025" },
  { wt: "0.03" },
  { wt: "0.035" },
  { wt: "0.04" },
  { wt: "0.045" },
  { wt: "0.05" },
  { wt: "0.055" },
  { wt: "0.06" },
  { wt: "0.065" },
  { wt: "0.07" },
  { wt: "0.08" },
  { wt: "0.09" },
  { wt: "0.10" },
  { wt: "0.11" },
  { wt: "0.12" },
  { wt: "0.13" },
  { wt: "0.14" },
  { wt: "0.15" },
  { wt: "0.16" },
  { wt: "0.17" },
  { wt: "0.18" },
  { wt: "0.19" },
  { wt: "0.20" },
  { wt: 0.21 },
  { wt: 0.22 },
  { wt: 0.23 },
  { wt: 0.24 },
  { wt: 0.25 },
  { wt: 0.26 },
  { wt: 0.27 },
  { wt: 0.28 },
  { wt: 0.29 },
  { wt: 0.3 },
  { wt: 0.31 },
  { wt: 0.32 },
  { wt: 0.33 },
  { wt: 0.34 },
  { wt: 0.35 },
  { wt: 0.36 },
  { wt: 0.37 },
  { wt: 0.38 },
  { wt: 0.39 },
  { wt: 0.4 },
  { wt: 0.41 },
  { wt: 0.42 },
  { wt: 0.43 },
  { wt: 0.44 },
  { wt: 0.45 },
  { wt: 0.46 },
  { wt: 0.47 },
  { wt: 0.48 },
  { wt: 0.49 },
  { wt: 0.5 },
  { wt: 0.51 },
  { wt: 0.52 },
  { wt: 0.53 },
  { wt: 0.54 },
  { wt: 0.55 },
  { wt: 0.56 },
  { wt: 0.57 },
  { wt: 0.58 },
  { wt: 0.59 },
  { wt: 0.6 },
  { wt: 0.61 },
  { wt: 0.62 },
  { wt: 0.63 },
  { wt: 0.64 },
  { wt: 0.65 },
  { wt: 0.66 },
  { wt: 0.67 },
  { wt: 0.68 },
  { wt: 0.69 },
  { wt: 0.7 },
  { wt: 0.71 },
  { wt: 0.72 },
  { wt: 0.73 },
  { wt: 0.74 },
  { wt: 0.75 },
  { wt: 0.76 },
  { wt: 0.77 },
  { wt: 0.78 },
  { wt: 0.79 },
  { wt: 0.8 },
  { wt: 0.81 },
  { wt: 0.82 },
  { wt: 0.83 },
  { wt: 0.84 },
  { wt: 0.85 },
  { wt: 0.86 },
  { wt: 0.87 },
  { wt: 0.88 },
  { wt: 0.89 },
  { wt: 0.9 },
  { wt: 0.91 },
  { wt: 0.92 },
  { wt: 0.93 },
  { wt: 0.94 },
  { wt: 0.95 },
  { wt: 0.96 },
  { wt: 0.97 },
  { wt: 0.98 },
  { wt: 0.99 },
  { wt: 1 },
  { wt: 1.01 },
  { wt: 1.02 },
  { wt: 1.03 },
  { wt: 1.04 },
  { wt: 1.05 },
  { wt: 1.06 },
  { wt: 1.07 },
  { wt: 1.08 },
  { wt: 1.09 },
  { wt: "1.10" },
  { wt: "1.20" },
  { wt: "1.25" },
  { wt: "1.29" },
  { wt: "1.30" },
  { wt: "1.35" },
  { wt: "1.40" },
  { wt: "1.50" },
  { wt: "1.55 " },
  { wt: "1.60 " },
  { wt: "1.70" },
  { wt: "1.80" },
  { wt: "1.90" },
  { wt: "2.00" },
  { wt: "2.01" },
  { wt: "2.02" },
  { wt: "2.03" },
  { wt: "2.04" },
  { wt: "2.05" },
  { wt: "2.06" },
  { wt: "2.07" },
  { wt: "2.08" },
  { wt: "2.09" },
  { wt: "2.10" },
  { wt: "2.20" },
  { wt: "2.30" },
  { wt: "2.40" },
  { wt: "2.50" },
  { wt: "2.58" },
  { wt: "2.60" },
  { wt: "2.70" },
  { wt: "2.80" },
  { wt: "2.90" },
  { wt: "3.00" },
  { wt: "3.08" },
  { wt: "3.25" },
  { wt: "3.50" },
  { wt: "3.75" },
  { wt: "3.90" },
  { wt: "4.00" },
  { wt: "4.05" },
  { wt: "4.25" },
  { wt: "4.50" },
  { wt: "4.75" },
  { wt: "5.00" },
  { wt: "5.05" },
  { wt: "5.25" },
  { wt: "5.50" },
  { wt: "5.75" },
  { wt: "6.00" },
  { wt: "6.05" },
  { wt: "6.25" },
  { wt: "6.50" },
  { wt: "6.75" },
  { wt: "7.00" },
  { wt: "7.05" },
  { wt: "7.25" },
  { wt: "7.50" },
  { wt: "7.75" },
  { wt: "8.00" },
  { wt: "8.05" },
];

const halfMoonSieves = [
  { wt: "0.01" },
  { wt: "0.02" },
  { wt: "0.03" },
  { wt: "0.04" },
  { wt: "0.05" },
  { wt: "0.06" },
  { wt: "0.07" },
  { wt: "0.08" },
  { wt: "0.09" },
  { wt: "0.10" },
  { wt: "0.11" },
  { wt: "0.12" },
  { wt: "0.13" },
  { wt: "0.14" },
  { wt: "0.15" },
  { wt: "0.16" },
  { wt: "0.17" },
  { wt: "0.18" },
  { wt: "0.19" },
  { wt: "0.20" },
  { wt: 0.21 },
  { wt: 0.22 },
  { wt: 0.23 },
  { wt: 0.24 },
  { wt: 0.25 },
  { wt: 0.26 },
  { wt: 0.27 },
  { wt: 0.28 },
  { wt: 0.29 },
  { wt: 0.3 },
  { wt: 0.31 },
  { wt: 0.32 },
  { wt: 0.33 },
  { wt: 0.34 },
  { wt: 0.35 },
  { wt: 0.36 },
  { wt: 0.37 },
  { wt: 0.38 },
  { wt: 0.39 },
  { wt: 0.4 },
  { wt: 0.41 },
  { wt: 0.42 },
  { wt: 0.43 },
  { wt: 0.44 },
  { wt: 0.45 },
  { wt: 0.46 },
  { wt: 0.47 },
  { wt: 0.48 },
  { wt: 0.49 },
  { wt: 0.5 },
  { wt: 0.51 },
  { wt: 0.52 },
  { wt: 0.53 },
  { wt: 0.54 },
  { wt: 0.55 },
  { wt: 0.56 },
  { wt: 0.57 },
  { wt: 0.58 },
  { wt: 0.59 },
  { wt: 0.6 },
  { wt: 0.61 },
  { wt: 0.62 },
  { wt: 0.63 },
  { wt: 0.64 },
  { wt: 0.65 },
  { wt: 0.66 },
  { wt: 0.67 },
  { wt: 0.68 },
  { wt: 0.69 },
  { wt: 0.7 },
  { wt: 0.71 },
  { wt: 0.72 },
  { wt: 0.73 },
  { wt: 0.74 },
  { wt: 0.75 },
  { wt: 0.76 },
  { wt: 0.77 },
  { wt: 0.78 },
  { wt: 0.79 },
  { wt: 0.8 },
  { wt: 0.81 },
  { wt: 0.82 },
  { wt: 0.83 },
  { wt: 0.84 },
  { wt: 0.85 },
  { wt: 0.86 },
  { wt: 0.87 },
  { wt: 0.88 },
  { wt: 0.89 },
  { wt: 0.9 },
  { wt: 0.91 },
  { wt: 0.92 },
  { wt: 0.93 },
  { wt: 0.94 },
  { wt: 0.95 },
  { wt: 0.96 },
  { wt: 0.97 },
  { wt: 0.98 },
  { wt: 0.99 },
  { wt: 1 },
  { wt: 1.01 },
  { wt: 1.02 },
  { wt: 1.03 },
  { wt: 1.04 },
  { wt: 1.05 },
  { wt: 1.06 },
  { wt: 1.07 },
  { wt: 1.08 },
  { wt: 1.09 },
  { wt: "1.10" },
  { wt: "1.20" },
  { wt: "1.25" },
  { wt: "1.29" },
  { wt: "1.30" },
  { wt: "1.35" },
  { wt: "1.40" },
  { wt: "1.50" },
  { wt: "1.55 " },
  { wt: "1.60 " },
  { wt: "1.70" },
  { wt: "1.80" },
  { wt: "1.90" },
  { wt: "2.00" },
  { wt: "2.01" },
  { wt: "2.02" },
  { wt: "2.03" },
  { wt: "2.04" },
  { wt: "2.05" },
  { wt: "2.06" },
  { wt: "2.07" },
  { wt: "2.08" },
  { wt: "2.09" },
  { wt: "2.10" },
  { wt: "2.20" },
  { wt: "2.30" },
  { wt: "2.40" },
  { wt: "2.50" },
  { wt: "2.58" },
  { wt: "2.60" },
  { wt: "2.70" },
  { wt: "2.80" },
  { wt: "2.90" },
  { wt: "3.00" },
  { wt: "3.08" },
  { wt: "3.25" },
  { wt: "3.50" },
  { wt: "3.75" },
  { wt: "3.90" },
  { wt: "4.00" },
  { wt: "4.05" },
  { wt: "4.25" },
  { wt: "4.50" },
  { wt: "4.75" },
  { wt: "5.00" },
  { wt: "5.05" },
  { wt: "5.25" },
  { wt: "5.50" },
  { wt: "5.75" },
  { wt: "6.00" },
  { wt: "6.05" },
  { wt: "6.25" },
  { wt: "6.50" },
  { wt: "6.75" },
  { wt: "7.00" },
  { wt: "7.05" },
  { wt: "7.25" },
  { wt: "7.50" },
  { wt: "7.75" },
  { wt: "8.00" },
  { wt: "8.05" },
  // { wt: "5.50" },
  // { wt: "6.00" },
  // { wt: "6.50" },
  // { wt: "7.00" },
  // { wt: "7.50" },
  // { wt: "8.00" },
];

const roundSieves = [
  { wt: "0.001", size: "0.6", sieve: "+00000-0000" },
  { wt: "0.002", size: "0.7", sieve: "+00000-0000" },
  { wt: "0.003", size: "0.7", sieve: "+0000-000" },
  { wt: "0.004", size: "0.8", sieve: "+000-00" },
  { wt: "0.005", size: "0.9", sieve: "+00-0" },
  { wt: "0.006", size: "1.10", sieve: "+0-1" },
  { wt: "0.007", size: "1.15", sieve: "+1-1.5" },
  { wt: "0.008", size: "1.20", sieve: "+1.5-2" },
  { wt: "0.009", size: "1.25", sieve: "+2-2.5" },
  { wt: "0.01", size: "1.30", sieve: "+2.5-3" },
  { wt: "0.011", size: "1.35", sieve: "+3-3.5" },
  { wt: "0.012", size: "1.40", sieve: "+3.5-4" },
  { wt: "0.013", size: "1.45", sieve: "+4-4.5" },
  { wt: "0.014", size: "1.50", sieve: "+4.5-5" },
  { wt: "0.016", size: "1.55", sieve: "+5-5.5" },
  { wt: "0.018", size: "1.60", sieve: "+5.5-6" },
  { wt: "0.02", size: "1.70", sieve: "+6-6.5" },
  { wt: "0.025", size: "1.80", sieve: "+6.5-7" },
  { wt: "0.03", size: "1.90", sieve: "+7-7.5" },
  { wt: "0.035", size: "2.00", sieve: "+7.5-8" },
  { wt: "0.04", size: "2.10", sieve: "+8-8.5" },
  { wt: "0.044", size: "2.20", sieve: "+8.5-9" },
  { wt: "0.05", size: "2.30", sieve: "+9-9.5" },
  { wt: "0.06", size: "2.40", sieve: "+9.5-10" },
  { wt: "0.07", size: "2.50", sieve: "+10-10.5" },
  { wt: "0.075", size: "2.60", sieve: "+10.5-11" },
  { wt: "0.08", size: "2.70", sieve: "+11-11.5" },
  { wt: "0.083", size: "2.70", sieve: "+11-11.5" },
  { wt: "0.09", size: "2.80", sieve: "+11.5-12" },
  { wt: "0.095", size: "2.80", sieve: "+11.5-12" },
  { wt: "0.10", size: "3.00", sieve: "+12-12.5" },
  { wt: "0.11", size: "3.05", sieve: "+12.5-13" },
  { wt: "0.12", size: "3.10", sieve: "+13-13.5" },
  { wt: "0.13", size: "3.20", sieve: "+13.5-14" },
  { wt: "0.14", size: "3.20", sieve: "+14-14.5" },
  { wt: "0.15", size: "3.30", sieve: "+14.5-15" },
  { wt: "0.16", size: "3.30", sieve: "+14.5-15" },
  { wt: "0.17", size: "3.50", sieve: "+15-15.5" },
  { wt: "0.18", size: "3.50", sieve: "+15-15.5" },
  { wt: "0.19", size: "3.60", sieve: "+15.50-15.75" },
  { wt: "0.20", size: "3.70", sieve: "15.75-16.50" },
  { wt: "0.21", size: "3.80", sieve: "" },
  { wt: "0.22", size: "3.80", sieve: "" },
  { wt: "0.23", size: "3.90", sieve: "" },
  { wt: "0.24", size: "3.90", sieve: "" },
  { wt: "0.25", size: "4.00", sieve: "" },
  { wt: "0.26", size: "4.00", sieve: "" },
  { wt: "0.27", size: "4.10", sieve: "" },
  { wt: "0.28", size: "4.20", sieve: "" },
  { wt: "0.29", size: "4.30", sieve: "" },
  { wt: "0.30", size: "4.40", sieve: "" },
  { wt: "0.31", size: "4.40", sieve: "" },
  { wt: "0.32", size: "4.40", sieve: "" },
  { wt: "0.33", size: "4.45", sieve: "" },
  { wt: "0.34", size: "4.45", sieve: "" },
  { wt: "0.35", size: "4.50", sieve: "" },
  { wt: "0.36", size: "4.50", sieve: "" },
  { wt: "0.37", size: "4.50", sieve: "" },
  { wt: "0.38", size: "4.50", sieve: "" },
  { wt: "0.39", size: "4.60", sieve: "" },
  { wt: "0.40", size: "4.60", sieve: "" },
  { wt: "0.41", size: "4.60", sieve: "" },
  { wt: "0.42", size: "4.70", sieve: "" },
  { wt: "0.43", size: "4.70", sieve: "" },
  { wt: "0.44", size: "4.70", sieve: "" },
  { wt: "0.45", size: "4.80", sieve: "" },
  { wt: "0.46", size: "4.90", sieve: "" },
  { wt: "0.47", size: "4.90", sieve: "" },
  { wt: "0.48", size: "4.90", sieve: "" },
  { wt: "0.49", size: "5.00", sieve: "" },
  { wt: "0.50", size: "5.10", sieve: "" },
  { wt: "0.51", size: "5.10", sieve: "" },
  { wt: "0.52", size: "5.10", sieve: "" },
  { wt: "0.53", size: "5.10", sieve: "" },
  { wt: "0.54", size: "5.20", sieve: "" },
  { wt: "0.55", size: "5.20", sieve: "" },
  { wt: "0.56", size: "5.20", sieve: "" },
  { wt: "0.57", size: "5.20", sieve: "" },
  { wt: "0.58", size: "5.30", sieve: "" },
  { wt: "0.59", size: "5.30", sieve: "" },
  { wt: "0.60", size: "5.40", sieve: "" },
  { wt: "0.61", size: "5.40", sieve: "" },
  { wt: "0.62", size: "5.40", sieve: "" },
  { wt: "0.63", size: "5.50", sieve: "" },
  { wt: "0.64", size: "5.50", sieve: "" },
  { wt: "0.65", size: "5.50", sieve: "" },
  { wt: "0.66", size: "5.50", sieve: "" },
  { wt: "0.67", size: "5.60", sieve: "" },
  { wt: "0.68", size: "5.60", sieve: "" },
  { wt: "0.69", size: "5.60", sieve: "" },
  { wt: "0.70", size: "5.70", sieve: "" },
  { wt: "0.71", size: "5.70", sieve: "" },
  { wt: "0.72", size: "5.70", sieve: "" },
  { wt: "0.73", size: "5.80", sieve: "" },
  { wt: "0.74", size: "5.80", sieve: "" },
  { wt: "0.75", size: "5.80", sieve: "" },
  { wt: "0.76", size: "5.80", sieve: "" },
  { wt: "0.77", size: "5.80", sieve: "" },
  { wt: "0.78", size: "5.80", sieve: "" },
  { wt: "0.79", size: "5.90", sieve: "" },
  { wt: "0.80", size: "6.00", sieve: "" },
  { wt: "0.81", size: "6.00", sieve: "" },
  { wt: "0.82", size: "6.00", sieve: "" },
  { wt: "0.83", size: "6.00", sieve: "" },
  { wt: "0.84", size: "6.10", sieve: "" },
  { wt: "0.85", size: "6.10", sieve: "" },
  { wt: "0.86", size: "6.10", sieve: "" },
  { wt: "0.87", size: "6.10", sieve: "" },
  { wt: "0.88", size: "6.10", sieve: "" },
  { wt: "0.89", size: "6.10", sieve: "" },
  { wt: "0.90", size: "6.10", sieve: "" },
  { wt: "0.91", size: "6.10", sieve: "" },
  { wt: "0.92", size: "6.20", sieve: "" },
  { wt: "0.93", size: "6.20", sieve: "" },
  { wt: "0.94", size: "6.20", sieve: "" },
  { wt: "0.95", size: "6.20", sieve: "" },
  { wt: "0.96", size: "6.30", sieve: "" },
  { wt: "0.97", size: "6.30", sieve: "" },
  { wt: "0.98", size: "6.30", sieve: "" },
  { wt: "0.99", size: "6.30", sieve: "" },
  { wt: "1.00", size: "6.30", sieve: "" },
  { wt: "1.01", size: "6.40", sieve: "" },
  { wt: "1.02", size: "6.40", sieve: "" },
  { wt: "1.03", size: "6.40", sieve: "" },
  { wt: "1.04", size: "6.50", sieve: "" },
  { wt: "1.05", size: "6.50", sieve: "" },
  { wt: "1.06", size: "6.50", sieve: "" },
  { wt: "1.07", size: "6.60", sieve: "" },
  { wt: "1.08", size: "6.60", sieve: "" },
  { wt: "1.09", size: "6.60", sieve: "" },
  { wt: "1.10", size: "6.60", sieve: "" },
  { wt: "1.20", size: "6.60", sieve: "" },
  { wt: "1.25", size: "6.75", sieve: "" },
  { wt: "1.29", size: "6.80", sieve: "" },
  { wt: "1.30", size: "6.90", sieve: "" },
  { wt: "1.35", size: "7.00", sieve: "" },
  { wt: "1.40", size: "7.10", sieve: "" },
  { wt: "1.50", size: "7.20", sieve: "" },
  { wt: "1.55", size: "7.30", sieve: "" },
  { wt: "1.60", size: "7.40", sieve: "" },
  { wt: "1.70", size: "7.50", sieve: "" },
  { wt: "1.80", size: "7.80", sieve: "" },
  { wt: "1.90", size: "7.90", sieve: "" },
  { wt: "2.00", size: "8.10", sieve: "" },
  { wt: "2.01", size: "8.10", sieve: "" },
  { wt: "2.02", size: "8.10", sieve: "" },
  { wt: "2.03", size: "8.10", sieve: "" },
  { wt: "2.04", size: "8.20", sieve: "" },
  { wt: "2.05", size: "8.20", sieve: "" },
  { wt: "2.06", size: "8.20", sieve: "" },
  { wt: "2.07", size: "8.20", sieve: "" },
  { wt: "2.08", size: "8.20", sieve: "" },
  { wt: "2.09", size: "8.30", sieve: "" },
  { wt: "2.10", size: "8.30", sieve: "" },
  { wt: "2.20", size: "8.30", sieve: "" },
  { wt: "2.30", size: "8.40", sieve: "" },
  { wt: "2.40", size: "8.50", sieve: "" },
  { wt: "2.50", size: "8.60", sieve: "" },
  { wt: "2.58", size: "8.65", sieve: "" },
  { wt: "2.60", size: "8.70", sieve: "" },
  { wt: "2.70", size: "8.90", sieve: "" },
  { wt: "2.80", size: "9.00", sieve: "" },
  { wt: "2.90", size: "9.10", sieve: "" },
  { wt: "3.00", size: "9.20", sieve: "" },
  { wt: "3.08", size: "9.30", sieve: "" },
  { wt: "3.25", size: "9.40", sieve: "" },
  { wt: "3.50", size: "9.70", sieve: "" },
  { wt: "3.75", size: "9.80", sieve: "" },
  { wt: "3.90", size: "10", sieve: "" },
  { wt: "4.00", size: "10.10", sieve: "" },
  { wt: "4.05", size: "10.20", sieve: "" },
  { wt: "4.25", size: "10.30", sieve: "" },
  { wt: "4.50", size: "10.50", sieve: "" },
  { wt: "4.75", size: "10.80", sieve: "" },
  { wt: "5.00", size: "11.10", sieve: "" },
  { wt: "5.05", size: "" },
  { wt: "5.25", size: "" },
  { wt: "5.50", size: "" },
  { wt: "5.75", size: "" },
  { wt: "6.00", size: "" },
  { wt: "6.05", size: "" },
  { wt: "6.25", size: "" },
  { wt: "6.50", size: "" },
  { wt: "6.75", size: "" },
  { wt: "7.00", size: "" },
  { wt: "7.05", size: "" },
  { wt: "7.25", size: "" },
  { wt: "7.50", size: "" },
  { wt: "7.75", size: "" },
  { wt: "8.00", size: "" },
  { wt: "8.05", size: "" },
  // { wt: "5.50", size: "", sieve: "" },
  // { wt: "6.00", size: "", sieve: "" },
  // { wt: "6.50", size: "", sieve: "" },
  // { wt: "7.00", size: "", sieve: "" },
  // { wt: "7.50", size: "", sieve: "" },
  // { wt: "8.00", size: "", sieve: "" },

  // { size: "0.8", wt: "0.0025" },
  // { size: "1", wt: "0.005" },
  // { size: "1.1", wt: "0.0067" },
  // { size: "1.2", wt: "0.009" },
  // { size: "1.25", wt: "0.01" },
  // { size: "1.3", wt: "0.01" },
  // { size: "1.5", wt: "0.015" },
  // { size: "1.75", wt: "0.02" },
  // { size: "1.8", wt: "0.025" },
  // { size: "2", wt: "0.03" },
  // { size: "2.2", wt: "0.04" },
  // { size: "2.5", wt: "0.06" },
  // { size: "2.75", wt: "0.08" },
  // { size: "3", wt: "0.10" },
  // { size: "3.25", wt: "0.14" },
  // { size: "3.5", wt: "0.17" },
  // { size: "3.75", wt: "0.21" },
  // { size: "4", wt: "0.25" },
  // { size: "4.25", wt: "0.28" },
  // { size: "4.5", wt: "0.36" },
  // { size: "4.75", wt: "0.44" },
  // { size: "5.0", wt: "0.50" },
  // { size: "5.25", wt: "0.56" },
  // { size: "5.5", wt: "0.66" },
  // { size: "5.75", wt: "0.75" },
  // { size: "6", wt: "0.84" },
  // { size: "6.25", wt: "0.93" },
  // { size: "6.5", wt: "1.00" },
  // { size: "6.8", wt: "1.25" },
  // { size: "7", wt: "1.30" },
  // { size: "7.3", wt: "1.50" },
  // { size: "7.5", wt: "1.67" },
  // { size: "7.75", wt: "1.75" },
  // { size: "8", wt: "2.00" },
  // { size: "8.25", wt: "2.11" },
  // { size: "8.5", wt: "2.43" },
  // { size: "8.7", wt: "2.50" },
  // { size: "9", wt: "2.75" },
  // { size: "9.1", wt: "3.00" },
  // { size: "9.5", wt: "3.35" },
  // { size: "9.75", wt: "3.61" },
  // { size: "10", wt: "3.87" },
  // { size: "10.25", wt: "4.16" },
  // { size: "10.5", wt: "4.41" },
  // { size: "10.75", wt: "4.57" },
  // { size: "11", wt: "4.91" },
  // { size: "11.25", wt: "5.49" },
  // { size: "11.5", wt: "5.85" },
  // { size: "12", wt: "6.84" },
  // { size: "12.25", wt: "7.26" },
  // { size: "12.5", wt: "7.36" },
  // { size: "12.75", wt: "7.52" },
  // { size: "13", wt: "8.51" },
  // { size: "13.5", wt: "9.53" },
  // { size: "14", wt: "10.49" },
  // { size: "15", wt: "12.89" },
  // { size: "16", wt: "16.06" },
];

const princessSieves = [
  { wt: "0.01" },
  { wt: "0.015" },
  { wt: "0.02" },
  { wt: "0.025" },
  { wt: "0.03" },
  { wt: "0.035" },
  { wt: "0.04", size: "1.90" },
  { wt: "0.05", size: "2.00" },
  { wt: "0.06", size: "2.20" },
  { wt: "0.07", size: "2.30" },
  { wt: "0.08", size: "2.40" },
  { wt: "0.09", size: "2.50" },
  { wt: "0.10", size: "2.55" },
  { wt: "0.11", size: "2.60" },
  { wt: "0.12", size: "2.70" },
  { wt: "0.13", size: "2.80" },
  { wt: "0.14", size: "2.90" },
  { wt: "0.15", size: "2.90" },
  { wt: "0.16", size: "3.00" },
  { wt: "0.17", size: "3.00" },
  { wt: "0.18", size: "3.10" },
  { wt: "0.19", size: "3.10" },
  { wt: "0.20", size: "3.20" },
  { wt: 0.21, size: "3.20" },
  { wt: 0.22, size: "3.30" },
  { wt: 0.23, size: "3.30" },
  { wt: 0.24, size: "3.40" },
  { wt: 0.25, size: "3.50" },
  { wt: 0.26, size: "3.50" },
  { wt: 0.27, size: "3.60" },
  { wt: 0.28, size: "3.60" },
  { wt: 0.29, size: "3.65" },
  { wt: 0.3, size: "3.70" },
  { wt: 0.31, size: "3.70" },
  { wt: 0.32, size: "3.70" },
  { wt: 0.33, size: "3.80" },
  { wt: 0.34, size: "3.80" },
  { wt: 0.35, size: "3.80" },
  { wt: 0.36, size: "3.90" },
  { wt: 0.37, size: "3.90" },
  { wt: 0.38, size: "4.00" },
  { wt: 0.39, size: "4.00" },
  { wt: 0.4, size: "4.00" },
  { wt: 0.41, size: "4.10" },
  { wt: 0.42, size: "4.10" },
  { wt: 0.43, size: "4.20" },
  { wt: 0.44, size: "4.20" },
  { wt: 0.45, size: "4.30" },
  { wt: 0.46, size: "4.30" },
  { wt: 0.47, size: "4.30" },
  { wt: 0.48, size: "4.40" },
  { wt: 0.49, size: "4.40" },
  { wt: 0.5, size: "4.40" },
  { wt: 0.51, size: "4.40" },
  { wt: 0.52, size: "4.40" },
  { wt: 0.53, size: "4.40" },
  { wt: 0.54, size: "4.40" },
  { wt: 0.55, size: "4.50" },
  { wt: 0.56, size: "4.50" },
  { wt: 0.57, size: "4.50" },
  { wt: 0.58, size: "4.60" },
  { wt: 0.59, size: "4.60" },
  { wt: 0.6, size: "4.60" },
  { wt: 0.61, size: "4.70" },
  { wt: 0.62, size: "4.70" },
  { wt: 0.63, size: "4.70" },
  { wt: 0.64, size: "4.80" },
  { wt: 0.65, size: "4.80" },
  { wt: 0.66, size: "4.80" },
  { wt: 0.67, size: "4.80" },
  { wt: 0.68, size: "4.80" },
  { wt: 0.69, size: "4.80" },
  { wt: 0.7, size: "4.90" },
  { wt: 0.71, size: "4.90" },
  { wt: 0.72, size: "4.90" },
  { wt: 0.73, size: "5.00" },
  { wt: 0.74, size: "5.00" },
  { wt: 0.75, size: "5.00" },
  { wt: 0.76, size: "5.00" },
  { wt: 0.77, size: "5.00" },
  { wt: 0.78, size: "5.00" },
  { wt: 0.79, size: "5.10" },
  { wt: 0.8, size: "5.10" },
  { wt: 0.81, size: "5.10" },
  { wt: 0.82, size: "5.10" },
  { wt: 0.83, size: "5.10" },
  { wt: 0.84, size: "5.10" },
  { wt: 0.85, size: "5.20" },
  { wt: 0.86, size: "5.20" },
  { wt: 0.87, size: "5.20" },
  { wt: 0.88, size: "5.20" },
  { wt: 0.89, size: "5.30" },
  { wt: 0.9, size: "5.30" },
  { wt: 0.91, size: "5.30" },
  { wt: 0.92, size: "5.30" },
  { wt: 0.93, size: "5.30" },
  { wt: 0.94, size: "5.30" },
  { wt: 0.95, size: "5.50" },
  { wt: 0.96, size: "5.40" },
  { wt: 0.97, size: "5.40" },
  { wt: 0.98, size: "5.40" },
  { wt: 0.99, size: "5.50" },
  { wt: 1, size: "5.50" },
  { wt: 1.01, size: "5.50" },
  { wt: 1.02, size: "5.50" },
  { wt: 1.03, size: "5.50" },
  { wt: 1.04, size: "5.50" },
  { wt: 1.05, size: "5.50" },
  { wt: 1.06, size: "5.60" },
  { wt: 1.07, size: "5.60" },
  { wt: 1.08, size: "5.60" },
  { wt: 1.09, size: "5.60" },
  { wt: "1.10", size: "5.60" },
  { wt: "1.20", size: "5.80" },
  { wt: "1.25", size: "5.90" },
  { wt: "1.29", size: "5.90" },
  { wt: "1.30", size: "6.00" },
  { wt: "1.35", size: "6.00" },
  { wt: "1.40", size: "6.00" },
  { wt: "1.50", size: "6.20" },
  { wt: "1.55", size: "6.30" },
  { wt: "1.60", size: "6.50" },
  { wt: "1.70", size: "6.80" },
  { wt: "1.80", size: "6.90" },
  { wt: "1.90", size: "6.90" },
  { wt: "2.00", size: "" },
  { wt: "2.01", size: "" },
  { wt: "2.02", size: "" },
  { wt: "2.03", size: "" },
  { wt: "2.04", size: "" },
  { wt: "2.05", size: "" },
  { wt: "2.06", size: "" },
  { wt: "2.07", size: "" },
  { wt: "2.08", size: "" },
  { wt: "2.09", size: "" },
  { wt: "2.10", size: "" },
  { wt: "2.20", size: "" },
  { wt: "2.30", size: "" },
  { wt: "2.40", size: "" },
  { wt: "2.50", size: "" },
  { wt: "2.58", size: "" },
  { wt: "2.60", size: "" },
  { wt: "2.70", size: "" },
  { wt: "2.80", size: "" },
  { wt: "2.90", size: "" },
  { wt: "3.00", size: "" },
  { wt: "3.08", size: "" },
  { wt: "3.25", size: "" },
  { wt: "3.50", size: "" },
  { wt: "3.75", size: "" },
  { wt: "3.90", size: "" },
  { wt: "4.00", size: "" },
  { wt: "4.05", size: "" },
  { wt: "4.25", size: "" },
  { wt: "4.50", size: "" },
  { wt: "4.75", size: "" },
  { wt: "5.00", size: "" },
  { wt: "5.05", size: "" },
  { wt: "5.25", size: "" },
  { wt: "5.50", size: "" },
  { wt: "5.75", size: "" },
  { wt: "6.00", size: "" },
  { wt: "6.05", size: "" },
  { wt: "6.25", size: "" },
  { wt: "6.50", size: "" },
  { wt: "6.75", size: "" },
  { wt: "7.00", size: "" },
  { wt: "7.05", size: "" },
  { wt: "7.25", size: "" },
  { wt: "7.50", size: "" },
  { wt: "7.75", size: "" },
  { wt: "8.00", size: "" },
  { wt: "8.05", size: "" },
  // { size: "1.5", wt: "0.015" },
  // { size: "1.75", wt: "0.03" },
  // { size: "2", wt: "0.06" },
  // { size: "2.25", wt: "0.08" },
  // { size: "2.5", wt: "0.10" },
  // { size: "2.75", wt: "0.13" },
  // { size: "3", wt: "0.18" },
  // { size: "3.25", wt: "0.26" },
  // { size: "3.5", wt: "0.29" },
  // { size: "3.75", wt: "0.31" },
  // { size: "4", wt: "0.39" },
  // { size: "4.25", wt: "0.44" },
  // { size: "4.5", wt: "0.50" },
  // { size: "4.75", wt: "0.64" },
  // { size: "5", wt: "0.75" },
  // { size: "5.25", wt: "0.90" },
  // { size: "5.5", wt: "1.00" },
  // { size: "5.75", wt: "1.11" },
  // { size: "6", wt: "1.25" },
  // { size: "6.25", wt: "1.39" },
  // { size: "6.5", wt: "1.50" },
  // { size: "6.75", wt: "1.75" },
  // { size: "7", wt: "2.00" },
  // { size: "7.25", wt: "2.01" },
  // { size: "7.5", wt: "2.50" },
  // { size: "7.75", wt: "2.74" },
  // { size: "8", wt: "3.00" },
  // { size: "8.25", wt: "3.24" },
  // { size: "8.5", wt: "3.67" },
  // { size: "8.75", wt: "4.10" },
  // { size: "9", wt: "4.12" },
  // { size: "9.5", wt: "5.09" },
  // { size: "9.75", wt: "5.36" },
  // { size: "10", wt: "5.62" },
  // { size: "10.25", wt: "5.76" },
  // { size: "10.5", wt: "5.99" },
  // { size: "11", wt: "7.44" },
  // { size: "11.5", wt: "9.44" },
  // { size: "12", wt: "9.52" },
];

export const allDiamondWeights = {
  baguette: taperBaguetteSieves,
  bullet_baguette: taperBaguetteSieves,
  tapered_baguette: taperBaguetteSieves,
  halfmoon: halfMoonSieves,
  triangle: halfMoonSieves,
  round: roundSieves,
  princess: princessSieves,
  marquise: taperBaguetteSieves,
  fancy: taperBaguetteSieves,
  oval: taperBaguetteSieves,
  pear: taperBaguetteSieves,
  cushion: halfMoonSieves,
  heart: halfMoonSieves,
  asscher: halfMoonSieves,
  radiant: halfMoonSieves,
  emerald: halfMoonSieves,
  lozenge: halfMoonSieves,
  trilliant: halfMoonSieves,
  trillion: halfMoonSieves,
  trapezoid: halfMoonSieves,
};

// emerald: [
//   { wt: "0.10", size: "3x2" },
//   { wt: "0.12", size: "3.5x2" },
//   { wt: "0.15", size: "4x2" },
//   { wt: "0.20", size: "4x3" },
//   { wt: "0.29", size: "5x3" },
//   { wt: "0.46", size: "5.5x3.5" },
//   { wt: "0.50", size: "6x4" },
//   { wt: "0.75", size: "6.5x4.5" },
//   { wt: "0.88", size: "6.7x4.5" },
//   { wt: "1.00", size: "7x5" },
//   { wt: "1.25", size: "7.3x5.3" },
//   { wt: "1.50", size: "7.5x5.5" },
//   { wt: "1.75", size: "8x6" },
//   { wt: "2.00", size: "8.5x6.5" },
//   { wt: "2.50", size: "9x7" },
//   { wt: "3.00", size: "10x7.5" },
//   { wt: "3.21", size: "9.5x7.5" },
//   { wt: "3.79", size: "10x8" },
//   { wt: "5.21", size: "11x9" },
//   { wt: "5.34", size: "12x8" },
//   { wt: "6.00", size: "12x10" },
//   { wt: "6.25", size: "14x8" },
//   { wt: "6.38", size: "13x9" },
//   { wt: "8.48", size: "14x10" },
//   { wt: "9.13", size: "13x11" },
//   { wt: "11.26", size: "14x12" },
//   { wt: "14.22", size: "16x12" },
// ],

// radiant: [
//   { wt: "0.31", size: "5x3" },
//   { wt: "0.34", size: "4.5x3.5" },
//   { wt: "0.41", size: "6x3" },
//   { wt: "0.50", size: "6x4" },
//   { wt: "0.75", size: "6.5x4.5" },
//   { wt: "1.00", size: "7x5" },
//   { wt: "1.25", size: "7.3x5.5" },
//   { wt: "1.50", size: "7.5x5.8" },
//   { wt: "2.00", size: "8x6" },
//   { wt: "2.25", size: "8.5x6.5" },
//   { wt: "2.50", size: "9x7" },
//   { wt: "3.00", size: "9.5x7.5" },
//   { wt: "3.31", size: "10x7" },
//   { wt: "3.50", size: "10x8" },
//   { wt: "5.05", size: "11x9" },
//   { wt: "6.06", size: "12x9" },
//   { wt: "6.45", size: "11.5x10" },
//   { wt: "6.54", size: "13x9" },
//   { wt: "6.79", size: "12x10" },
//   { wt: "8.47", size: "14x10" },
//   { wt: "9.33", size: "13x11" },
//   { wt: "10.19", size: "13.5x11.5" },
//   { wt: "11.48", size: "15x11" },
//   { wt: "12.14", size: "14x12" },
//   { wt: "14.22", size: "16x12" },
// ],

// cushion: [
//   { size: "4.2", wt: "0.40" },
//   { size: "4.9", wt: "0.50" },
//   { size: "5.25", wt: "0.75" },
//   { size: "5.5", wt: "1.00" },
//   { size: "6", wt: "1.25" },
//   { size: "6.5", wt: "1.50" },
//   { size: "7", wt: "2.00" },
//   { size: "7.5", wt: "2.50" },
//   { size: "8", wt: "3.00" },
//   { size: "8.5", wt: "3.50" },
//   { size: "9", wt: "4.12" },
//   { size: "9.5", wt: "5.09" },
//   { size: "10", wt: "5.62" },
//   { size: "11", wt: "7.44" },
//   { size: "12", wt: "9.52" },
//   { size: "13", wt: "12.66" },
// ],
// heart: [
//   { wt: "0.18", size: "3.5" },
//   { wt: "0.25", size: "4" },
//   { wt: "0.28", size: "4.25" },
//   { wt: "0.34", size: "4.5" },
//   { wt: "0.38", size: "4.75" },
//   { wt: "0.50", size: "5" },
//   { wt: "0.61", size: "5.5" },
//   { wt: "0.75", size: "6" },
//   { wt: "0.83", size: "6.25" },
//   { wt: "1.00", size: "6.5" },
//   { wt: "1.25", size: "7" },
//   { wt: "1.50", size: "7.5" },
//   { wt: "2.00", size: "8" },
//   { wt: "2.50", size: "8.5" },
//   { wt: "3.00", size: "9" },
//   { wt: "3.50", size: "10" },
//   { wt: "4.41", size: "11" },
//   { wt: "5.66", size: "12" },
//   { wt: "7.88", size: "13" },
//   { wt: "9.38", size: "14" },
//   { wt: "10.79", size: "15" },
//   { wt: "13.27", size: "16" },
//   { wt: "15.33", size: "18" },
// ],
// asscher: [
//   { wt: "0.39", size: "4" },
//   { wt: "0.50", size: "4.5" },
//   { wt: "0.75", size: "5" },
//   { wt: "1.00", size: "5.5" },
//   { wt: "1.25", size: "6" },
//   { wt: "1.50", size: "6.5" },
//   { wt: "2.00", size: "7" },
//   { wt: "2.50", size: "7.5" },
//   { wt: "3.00", size: "8" },
//   { wt: "3.50", size: "8.5" },
//   { wt: "4.12", size: "9" },
//   { wt: "5.09", size: "9.5" },
//   { wt: "5.62", size: "10" },
//   { wt: "7.44", size: "11" },
//   { wt: "9.52", size: "12" },
//   { wt: "12.66", size: "13" },
// ],
// pear: [
//   { wt: "0.13", size: "3x2" },
//   { wt: "0.18", size: "4x2.5" },
//   { wt: "0.21", size: "4x3" },
//   { wt: "0.25", size: "5x3" },
//   { wt: "0.35", size: "5x4" },
//   { wt: "0.50", size: "6x4" },
//   { wt: "0.60", size: "6.5x4.5" },
//   { wt: "0.75", size: "7x5" },
//   { wt: "0.85", size: "7.5x5.5" },
//   { wt: "1.00", size: "7.7x5.7" },
//   { wt: "1.25", size: "8x6" },
//   { wt: "1.50", size: "8.5x6.5" },
//   { wt: "1.71", size: "10x6" },
//   { wt: "1.80", size: "8.7x6.7" },
//   { wt: "2.00", size: "9x7" },
//   { wt: "2.50", size: "10x8" },
//   { wt: "2.61", size: "11x7.50" },
//   { wt: "2.70", size: "11x8" },
//   { wt: "3.00", size: "12x8" },
//   { wt: "3.12", size: "12x7" },
//   { wt: "3.40", size: "13x8" },
//   { wt: "3.44", size: "12x9" },
//   { wt: "4.00", size: "14x8" },
//   { wt: "4.11", size: "13x9" },
//   { wt: "4.25", size: "14x9" },
//   { wt: "5.06", size: "15x9" },
//   { wt: "5.41", size: "14x10" },
//   { wt: "5.65", size: "13x11" },
//   { wt: "5.75", size: "15x10" },
//   { wt: "5.86", size: "16x9" },
//   { wt: "6.27", size: "16x10" },
//   { wt: "6.46", size: "17x10" },
//   { wt: "7.36", size: "15x11" },
//   { wt: "8.14", size: "18x11" },
//   { wt: "8.99", size: "16x12" },
//   { wt: "9.35", size: "18x12" },
//   { wt: "10.21", size: "18x13" },
// ],

// marquise: [
//   { wt: "0.025", size: "3x1.5" },
//   { wt: "0.065", size: "3.5x1.75" },
//   { wt: "0.07", size: "3.5x2" },
//   { wt: "0.10", size: "4x2" },
//   { wt: "0.11", size: "3.75x1.75" },
//   { wt: "0.12", size: "4.25x2.25" },
//   { wt: "0.14", size: "5x2.5" },
//   { wt: "0.16", size: "5.5x2.75" },
//   { wt: "0.18", size: "5.5x3" },
//   { wt: "0.20", size: "5x3" },
//   { wt: "0.23", size: "6.5x3" },
//   { wt: "0.25", size: "6x3" },
//   { wt: "0.30", size: "7x3" },
//   { wt: "0.33", size: "7.5x3.5" },
//   { wt: "0.34", size: "7x4" },
//   { wt: "0.50", size: "8x4" },
//   { wt: "0.70", size: "8.75x4.25" },
//   { wt: "0.75", size: "9x4.5" },
//   { wt: "0.85", size: "9.5x4.7" },
//   { wt: "1.00", size: "10x5" },
//   { wt: "1.25", size: "11x5.5" },
//   { wt: "1.33", size: "11.5x6" },
//   { wt: "1.50", size: "12x6" },
//   { wt: "2.00", size: "13x6.5" },
//   { wt: "2.50", size: "14x7" },
//   { wt: "3.00", size: "15x7" },
//   { wt: "3.00", size: "14x8" },
//   { wt: "3.25", size: "15x7.5" },
//   { wt: "3.44", size: "15x8" },
//   { wt: "3.86", size: "16x8" },
//   { wt: "4.00", size: "16.5x8.25" },
//   { wt: "4.88", size: "17x8.5" },
//   { wt: "5.50", size: "17.5x10" },
//   { wt: "7.08", size: "20x8" },
//   { wt: "7.94", size: "20x10" },
//   { wt: "9.50", size: "20x11" },
// ],
// oval: [
//   { wt: "0.10", size: "3.6x2.7" },
//   { wt: "0.15", size: "4x3" },
//   { wt: "0.20", size: "4.5x3.5" },
//   { wt: "0.25", size: "5x3.5" },
//   { wt: "0.33", size: "5.5x3.5" },
//   { wt: "0.35", size: "5x4" },
//   { wt: "0.50", size: "6x4" },
//   { wt: "0.61", size: "6x5" },
//   { wt: "0.65", size: "6.5x4.5" },
//   { wt: "0.75", size: "7x5" },
//   { wt: "1.00", size: "7.7x5.7" },
//   { wt: "1.25", size: "8x6" },
//   { wt: "1.41", size: "9x6" },
//   { wt: "1.50", size: "8.5x6.5" },
//   { wt: "2.00", size: "9x7" },
//   { wt: "2.50", size: "10x8" },
//   { wt: "2.88", size: "10.5x8.5" },
//   { wt: "3.00", size: "12x8" },
//   { wt: "3.85", size: "11x9" },
//   { wt: "5.05", size: "12x10" },
//   { wt: "5.81", size: "14x10" },
//   { wt: "6.05", size: "13x11" },
//   { wt: "8.21", size: "14x12" },
//   { wt: "8.76", size: "15x12" },
//   { wt: "9.32", size: "16x12" },
//   { wt: "11.88", size: "16x14" },
//   { wt: "12.86", size: "18x13" },
//   { wt: "14.96", size: "20x15" },
// ],
