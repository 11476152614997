import { onAddQuestion } from "../../utils/QuestionNode";

export const createQuestionNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  const type = currNode?.type;

  const axis = {
    x: currNode.position.x,
    y: currNode.position.y,
  };

  if (type === "user") {
    axis.x = axis.x + 160;
    axis.y = axis.y + 10;
  }

  onAddQuestion(dispatch, axis, nodeId);
};
