import { isDev, isObjWithValues } from "../../../../helper";
// import STS from "aws-sdk/clients/sts";
import AWSSignature from "./AWSSignature";
import moment from "moment";

var aswSignatureInstance = new AWSSignature();

// const sandbox = {
//   authHost: "https://api.sandbox.amazon.com",
//   spApiHost: "https://sandbox.sellingpartnerapi",
//   // jwelpixAppID: amzn1.sp.solution.e2834b40-d225-4772-89c8-de51f4720247
//   OAuthUrl:
//     "https://sellercentral.amazon.in/apps/authorize/consent?application_id=amzn1.sp.solution.57e6923a-76bb-4ce6-8834-0e89cc414459&version=beta",
//   redirectURI: "https://jwelpix.com/amazon/success",
//   clientID: "amzn1.application-oa2-client.5d7efdd3f7f849c3915a161237b2380c",
//   clientSecret:
//     "4a7a8858c288a0bc136644f7733663e9e8f605e7c6d852e37a10b8a2d0e4e5ca",
//   assumeRoleARN: "arn:aws:iam::073983177533:role/SellingPartnerAPI",
// };
const sandbox = {
  authHost: "https://api.sandbox.amazon.com",
  spApiHost: "https://sandbox.sellingpartnerapi",
  // jwelpixAppID: amzn1.sp.solution.e2834b40-d225-4772-89c8-de51f4720247
  OAuthUrl:
    "https://sellercentral.amazon.in/apps/authorize/consent?application_id=amzn1.sp.solution.57e6923a-76bb-4ce6-8834-0e89cc414459&version=beta",
  redirectURI: "https://app.jwero.ai/integrations",
  clientID: "amzn1.application-oa2-client.5d7efdd3f7f849c3915a161237b2380c",
  clientSecret:
    "4a7a8858c288a0bc136644f7733663e9e8f605e7c6d852e37a10b8a2d0e4e5ca",
  assumeRoleARN: "arn:aws:iam::073983177533:role/SellingPartnerAPI",
};

const production = {
  authHost: "https://api.amazon.com",
  spApiHost: "https://sellingpartnerapi",
  OAuthUrl:
    "https://sellercentral.amazon.in/apps/authorize/consent?application_id=amzn1.sp.solution.57e6923a-76bb-4ce6-8834-0e89cc414459",
  redirectURI: "https://jwelpix.com/amazon/success",
  clientID: "amzn1.application-oa2-client.5d7efdd3f7f849c3915a161237b2380c",
  clientSecret:
    "4a7a8858c288a0bc136644f7733663e9e8f605e7c6d852e37a10b8a2d0e4e5ca",
  assumeRoleARN: "arn:aws:iam::073983177533:role/SellingPartnerAPI",
};

// Change Accordingly
const isProductionMode = false;

const AmazonSP = isProductionMode ? production : sandbox;

export {
  AmazonSP,
  generateAwsSts,
  generateAwsS3,
  generateAwsSignatureExecuteApi,
  getXAmzDate,
};

// AWS S3 Bucket Host
const awsS3BucketPath =
  "https://jwelpix-images-bucket.s3.ap-south-1.amazonaws.com";

// AWS IAM Services
const _AwsSTSCredentials = {
  accessKeyId: "AKIARCON5WM65S6EL55C",
  secretAccessKey: "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",
  awsRegion: "us-east-1",
  serviceName: "sts",
  assumeRoleArn: "arn:aws:iam::073983177533:role/SellingPartnerAPI",
};

const generateAwsSts = async () => {
  try {
    // let stsInstance = new STS({
    //   credentials: {
    //     accessKeyId: _AwsSTSCredentials.accessKeyId,
    //     secretAccessKey: _AwsSTSCredentials.secretAccessKey,
    //   },
    //   region: _AwsSTSCredentials.awsRegion,
    // });
    // return await stsInstance
    //   .assumeRole(
    //     {
    //       RoleArn: _AwsSTSCredentials.assumeRoleArn,
    //       RoleSessionName: _AwsSTSCredentials.assumeRoleArn.split("/")[1],
    //       DurationSeconds: 3600,
    //     },
    //     (stsError, stsData) => stsData || false
    //   )
    //   .promise();
  } catch (StsError) {
    console.log(StsError);
    return false;
  }
};

const generateAwsS3 = async () => {};

const getXAmzDate = () => {
  const dateTimeString = new Date().toISOString();
  return moment(dateTimeString)
    .toISOString()
    .replace(/[:\-]|\.\d{3}/g, "");
};

const generateAwsSignatureExecuteApi = async ({
  path,
  method,
  serviceName,
  sessionToken,
  accessToken,
  xAmzDate,
  regionName,
  headers,
  body,
  accessKeyId,
  secretAccessKey,
}) => {
  try {
    var options = {
      path,
      method,
      service: serviceName,
      headers: {
        ...headers,
        host: "sandbox.sellingpartnerapi-eu.amazon.com",
      },
      region: regionName,
      body: body || "",
      credentials: {
        AccessKeyId: accessKeyId,
        SecretKey: secretAccessKey,
      },
    };

    aswSignatureInstance.setParams(options);
    return aswSignatureInstance.getAuthorizationHeader();
  } catch (signatureError) {
    console.log("signatureError", signatureError);
    return false;
  }

  // var options = {
  //   path: path,
  //   method: method,
  //   service: serviceName,
  //   headers: headers || {
  //     "X-Amz-Security-Token": sessionToken,
  //     "x-amz-access-token": accessToken,
  //     "X-Amz-Date": xAmzDate,
  //     host: "sandbox.sellingpartnerapi-eu.amazon.com",
  //   },
  //   region: regionName,
  //   body: body || "",
  //   credentials: {
  //     AccessKeyId: accessKeyId,
  //     SecretKey: secretAccessKey,
  //   },
  // };
};
