import { UnsubscribeNode } from "../../utils/UnsubscribeNode";

export const createUnsubscribeNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  const type = currNode?.type;

  const axis = {
    x: currNode.position.x + 200,
    y: currNode.position.y,
  };

  if (type === "user") {
    axis.y = axis.y + 10;
  }
  UnsubscribeNode(dispatch, axis, nodeId);
};
