import { backendApiCall } from "../../../../../helper";
import * as actions from "../../../../../redux/actions/flowsActions";

export const fetchGlobalAttributes = async (dispatch) => {
  const res = await backendApiCall({ endpoint: `settings/custom_fields` });
  const attributesObj = res?.data?.crm || {};
  const globalAttributes = Object.keys(attributesObj).map((attributeId) => {
    const attribute = attributesObj[attributeId];
    return attribute.title;
  });
  dispatch({
    type: actions.EDIT_ELEMENT_IN_REDUX,
    payload: { globalAttributes },
  });
};
