import { onAddUser } from "../../utils/UserNode";
import * as actions from "../../../../../../../redux/actions/flowsActions";

export const createUserNode = async ({ dispatch, nodes, nodeId, btnsObj }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  let currCount = btnsObj?.count || currNode?.userConnected;

  const type = currNode?.type;

  const axis = {
    x: currNode?.position.x,
    y: currNode?.position.y,
  };

  if (type === "start") {
    axis.x = currNode?.position?.x + 240;
    axis.y = currNode?.position?.y - 120 - currCount * 120;
  } else {
    axis.x = currNode.position.x + 240;
    if (currCount === 0) {
      axis.y = currNode.position.y - 10;
    } else if (currCount === 1) {
      axis.y = currNode?.position?.y + 70;
    } else if (currCount === 2) {
      axis.y = currNode?.position?.y - 90;
    } else {
      axis.y = currNode?.position?.y - 85 * (currCount - 1);
    }
  }
  onAddUser(dispatch, axis, nodeId, btnsObj);
  let updateCount = btnsObj?.count + 1 || currNode?.userConnected + 1;

  dispatch({
    type: actions.UPDATE_USER_CONNECTED,
    payload: { id: nodeId, count: updateCount },
  });
};
