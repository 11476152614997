import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { goToStepStyles } from "./styles/goToStepStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import BlockOptions from "./components/BlockOptions";

const styles = goToStepStyles;

const SidebarGoToStep = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [block, setBlock] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_GO_TO_STEP, payload: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        redirect: data,
        optionValue: block,
      };
      dispatch({ type: actions.SIDEBAR_GO_TO_STEP, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.optionValue) {
        setBlock(node?.optionValue);
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <SwapCallsSharpIcon sx={{ transform: "rotate(89deg)" }} />
              <Typography variant="span" style={styles.headerTitle}>
                Go to Step
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            value={title}
            required
          ></TextField>
        </Box>
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Block
            </Typography>
            <TextField
              size="small"
              sx={styles.optionsInput}
              onClick={handleClickPopover}
              placeholder="Choose target block"
              value={block}
              fullWidth
              // disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <BlockOptions
            setData={setData}
            optionsAnchorEl={optionsAnchorEl}
            setOptionsAnchorEl={setOptionsAnchorEl}
            setBlock={setBlock}
          />
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarGoToStep;
