import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { SettingsRow } from "..";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import {
  DefaultLabelSmall,
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import { Add, Cancel, Circle, Close, CloseOutlined } from "@mui/icons-material";
import CustomCheckbox from "../../../../../helper/utility/CustomCheckbox";
import { PREDEFINED_CHAT_USER_ATTRIBUTES } from "../../../../../constants/chat_user";

const WebChatWelcomeFormConfigure = forwardRef(
  ({ onWelcomeFormSubmit }, ref) => {
    const [open, setOpen] = React.useState(false);
    const [form, setForm] = React.useState({
      enabled: false,
      attributes: [],
      // bot_tagline: "",
      // type: "round",
      // icon_color: predefinedColors?.[0],
    });
    const editForm = (payload) =>
      setForm((state) => ({ ...state, ...payload }));

    React.useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
      setupForm(form) {
        setForm(form || {});
      },
    }));

    const handleClose = () => {
      setOpen(false);
      // setAnchorEl(null);
    };

    const onSave = () => {
      onWelcomeFormSubmit(form);
      handleClose();
    };

    const onAttributeCreate = (obj) => {
      let attributes = [...(form.attributes || [])];
      attributes.push({ ...obj, key: obj?.value });
      editForm({ attributes });
    };
    const onAttributeRemove = (obj) => {
      let attributes = [...(form.attributes || [])];
      attributes = attributes?.filter((i) => i.key !== obj.key);
      editForm({ attributes });
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Configure your Welcome Form
        </DialogTitle>
        <DialogContent>
          <SettingsRow
            title="Enable welcome form"
            // subtitle={"Time when user agent is available to chat"}
            type="switch"
            value={form?.enabled || ""}
            onChange={(e) => editForm({ enabled: e })}
          />
          <Box
            sx={{
              opacity: form?.enabled ? 1 : 0.6,
              pointerEvents: form?.enabled ? "unset" : "none",
            }}
          >
            <HorizontalStack
              sx={{ justifyContent: "space-between", my: 2, mt: 3 }}
            >
              <DefaultLabelSmall>Attributes to ask</DefaultLabelSmall>
              <AddFormPopover onCreate={onAttributeCreate} />
            </HorizontalStack>
            {form?.attributes?.map((attribute) => {
              return (
                <HorizontalStack
                  sx={{
                    justifyContent: "space-between",
                    my: 2,
                    border: "0.5px solid grey",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                  key={attribute?.key}
                >
                  <PrimaryLabelSmall>{attribute?.label}</PrimaryLabelSmall>
                  <Button
                    sx={{
                      visibility: attribute?.required ? "visible" : "hidden",
                    }}
                    startIcon={
                      <Circle
                        color="success"
                        style={{ height: 10, width: 10 }}
                      />
                    }
                    color="success"
                    size="small"
                  >
                    Required
                  </Button>

                  <Cancel
                    color="error"
                    style={{
                      height: 18,
                      width: 18,
                      position: "absolute",
                      top: -8,
                      right: -8,
                      cursor: "pointer",
                    }}
                    onClick={() => onAttributeRemove(attribute)}
                  />
                </HorizontalStack>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={onSave} autoFocus variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default WebChatWelcomeFormConfigure;

const AddFormPopover = forwardRef(({ onCreate, attributes }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [form, setForm] = useState({});

  const editForm = (payload) => {
    setForm((state) => ({ ...state, ...payload }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const onSubmit = () => {
    let obj = PREDEFINED_CHAT_USER_ATTRIBUTES?.find(
      (i) => i.value === form.key
    );
    let payload = {
      label: obj?.label,
      ...form,
    };
    onCreate(payload);
    handleClose();
    setForm({});
  };
  return (
    <div>
      <Button startIcon={<Add />} size="small" onClick={handleClick}>
        Add attribute
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 3, width: "260px" }}>
          <PrimaryLabelSmall sx={{ mb: 3 }}>
            Add New Attribute
          </PrimaryLabelSmall>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Attribute</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.key}
              label="Attribute"
              onChange={(e) => editForm({ key: e.target.value })}
              fullWidth
              size="small"
              sx={{ mt: 2 }}
            >
              {PREDEFINED_CHAT_USER_ATTRIBUTES?.filter(
                (i) =>
                  // i.value !== form?.key &&
                  !attributes?.find((j) => j?.key === i?.value)
              )?.map((i) => {
                return (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <CustomCheckbox
            value={form.required}
            onChange={(e) => editForm({ required: e })}
            label="Required"
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            startIcon={<Add />}
            sx={{ mt: 2, mb: 3, float: "right" }}
            onClick={onSubmit}
            disabled={!form.key}
          >
            Create
          </Button>
        </Box>
      </Popover>
    </div>
  );
});
