import axios from "axios";
// import axios from "../../utils/axios";
import {
  getAxiosError,
  isArrayWithValues,
  isObjWithValues,
  getWebsite,
  getToken,
  getWooCommerce,
  validateNumber,
  isArray,
  sortObjectByValues,
  getSupplierProductBatch,
  formatDate,
  mainWebsite,
  apikey,
  // url,
} from "../../helper";
import * as qs from "qs";

export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_PRODUCT_SETTINGS = "SET_PRODUCT_SETTINGS";
export const SET_PRODUCT_TAXONOMIES = "SET_PRODUCT_TAXONOMIES";
export const APPEND_PRODUCT_DATA = "APPEND_PRODUCT_DATA";
export const APPEND_MEDIA_LIBRARY = "APPEND_MEDIA_LIBRARY";
export const EMPTY_MEDIA_LIBRARY = "EMPTY_MEDIA_LIBRARY";
export const REPLACE_MEDIA_LIBRARY = "REPLACE_MEDIA_LIBRARY";
export const CREATE_QUOTATION = "CREATE_QUOTATION";

export const setProductData = (payload) => ({
  type: SET_PRODUCT_DATA,
  payload,
});

export const fetchAllProducts = (
  params = {},
  wipeData,
  _clearCache,
  setToast
) => {
  // if (clearCache) params.random_key = Math.random();
  // if (isObjWithValues(params)) {
  //   params["custom_filter[0][key]"] = "_visibility";
  //   params["custom_filter[0][value]"] = "";
  //   params["custom_filter[0][compare]"] = "NOT EXISTS";
  // }

  return async (dispatch) => {
    const website = await getWebsite();
    const token = await getToken();
    if (wipeData)
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { allProducts: [] },
      });
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { noProductsFound: false, fetchingProducts: true },
    });
    const req = {
      url: `${website}/wp-json/wc/v3/products`,
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        ...params,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    };
    if (website && token)
      axios(req)
        // WooCommerce.get("products", { ...params, signal })
        .then((res) => {
          console.log(res, "<<res>>");
          // console.log(res.headers, "PRODUCTS RES");
          if (isArrayWithValues(res.data)) {
            return dispatch({
              type: SET_PRODUCT_DATA,
              payload: {
                allProducts: res.data,
                totalProductSearch: res?.headers?.["x-wp-total"],
              },
            });
          } else
            return dispatch({
              type: SET_PRODUCT_DATA,
              payload: {
                noProductsFound: true,
                allProducts: [],
                totalProductSearch: 0,
              },
            });
        })
        .catch((e) => {
          e = getAxiosError(e);
          if (e.message && e.message === "Network Error")
            setToast &&
              setToast({
                show: true,
                severity: "error",
                message: "Network error! Please check internet",
              });
          // network error
          console.log(e, "products error");
        })
        .finally(() => {
          console.log(JSON.stringify(params), "params after");

          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { fetchingProducts: false },
          });
        });
  };
};

const defaultPaymentMethods = [
  // { label: "Cash", value: "cash" },
  { label: "Point of Sale (POS)", value: "pos" },
];

export const paymentMethods = () => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { noPaymentMethodFound: false, fetchingPaymentMethod: true },
    });
    const req = {
      url: `${website}/wp-json/wc/v3/payment_gateways`,
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        // "Content-Type": "application/json",
      },
      // params,
    };
    axios(req)
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          res = res.data.filter(
            (i) => i.enabled || i.id
            // (i) => i.id === "cashfree_autocollect" || i.id === "razorpay"
          );
          const filterPaymentData = res.map((item) => ({
            label: item.title || item.method_title,
            value: item.id,
          }));
          // setPaymentGetWaysArray(res);
          if (isArrayWithValues(filterPaymentData)) {
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: {
                PaymentMethod: defaultPaymentMethods.concat(filterPaymentData),
                fetchingPaymentMethod: false,
              },
            });
            dispatch(
              setupPaymentMethods(
                defaultPaymentMethods.concat(filterPaymentData)
              )
            );
          }
        }
      })
      .catch((e) => {
        console.log(e);
        e = getAxiosError(e);
      });
  };
};

export const setupPaymentMethods = (methods) => {
  return (dispatch, getState) => {
    let state = getState();
    let cashfreeSettings = state.settings?.woocommerceCashfree_settings;
    let ccAvanueSettings = state.settings?.woocommerceCcavenueSettings;
    let razorpaySettings = state.settings?.woocommerceRazorpaySettings;
    let _paymentMethods = state.product?.PaymentMethod;
    let paymentMethods = methods || _paymentMethods;
    if (cashfreeSettings?.enabled !== "yes")
      paymentMethods = paymentMethods?.filter(
        (i) => !i?.value?.includes("cashfree")
      );
    if (ccAvanueSettings?.enabled !== "yes")
      paymentMethods = paymentMethods?.filter(
        (i) => !i?.value?.includes("ccavenue")
      );
    if (razorpaySettings?.enabled !== "yes")
      paymentMethods = paymentMethods?.filter(
        (i) => !i?.value?.includes("razorpay")
      );
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        PaymentMethod: paymentMethods,
      },
    });
  };
};

export const appendAllProducts = (params, wipeData) => {
  return async (dispatch) => {
    console.log(params);
    let website = getWebsite();
    let token = getToken();
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        fetchedAllProducts: false,
        fetchingProducts: true,
        noProductsFound: false,
      },
    });
    if (wipeData)
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { allProducts: [] },
      });
    // const controller = new AbortController();
    // const signal = controller.signal;
    // const WooCommerce = await getWooCommerce();
    // if (WooCommerce)
    //   WooCommerce.get("products", { ...params, signal })

    const req = {
      url: `${website}/wp-json/wc/v3/products`,
      method: "GET",
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
      params,
    };
    if (website && token)
      axios(req)
        .then((response) => {
          let res = response.data;
          if (Array.isArray(res)) {
            if (res.length === 0)
              dispatch({
                type: SET_PRODUCT_DATA,
                payload: { noProductsFound: true },
              });
            if (res.length !== params.per_page)
              dispatch({
                type: SET_PRODUCT_DATA,
                payload: {
                  fetchedAllProducts: true,
                  totalProductSearch: response?.headers?.["x-wp-total"],
                },
              });
            if (res.length > 0)
              dispatch({
                type: APPEND_PRODUCT_DATA,
                payload: res,
              });
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: {
                totalProductSearch: response?.headers?.["x-wp-total"],
              },
            });
          }
        })
        .finally(() => {
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { fetchingProducts: false },
          });
        });
  };
};

export const fetchAdminTaxonomies = (signOut) => {
  // console.log("fetching taxonomies");
  return async (dispatch, getState) => {
    let state = getState();
    let unregistered_user = state.user.unregistered_user;
    // const controller = new AbortController();
    // const signal = controller.signal;
    let token = getToken();

    if (!token && isObjWithValues(unregistered_user))
      token = unregistered_user.token;
    if (token) {
      axios({
        url: `${mainWebsite}/wp-json/store/v1/taxonomies`,
        method: "GET",
        headers: {
          // Authorization: `Basic ${token}`,
          // signal,
          apikey,
        },
      })
        .then((res) => {
          console.log("res_taxo", res);
          if (res.data) {
            dispatch({
              type: SET_PRODUCT_TAXONOMIES,
              payload: { ...res.data },
            });
          }
        })
        .catch((e) => {
          getAxiosError(e);
        });
    } else signOut && signOut();
  };
};
export const fetchTaxonomies = (signOut) => {
  // console.log("fetching taxonomies");
  return async (dispatch) => {
    // const controller = new AbortController();
    // const signal = controller.signal;
    let website = getWebsite();
    let token = getToken();
    if (website && token)
      axios({
        url: `${website}/wp-json/store/v1/taxonomies`,
        method: "GET",
        headers: {
          Authorization: `Basic ${token}`,
          // signal,
        },
      }).then((res) => {
        if (res.data) {
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { taxonomies: { ...res.data } },
          });
        }
      });
    else signOut && signOut();
  };
};

// export const fetchMasters = (signOut) => {
//   return (dispatch) => {
//     let website = getWebsite();
//     let token = getToken();
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/store/v1/settings/master_pricing`,
//         method: "GET",
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       }).then((res) => {
//         if (res.data) {
//           let { data } = res;
//           if (data.success)
//             dispatch({
//               type: SET_PRODUCT_DATA,
//               payload: { masters: data.data },
//             });
//         }
//       });
//     else signOut && signOut();
//   };
// };

export const fetchDashboardCatalogsRequests = (_params, clearData) => {
  return (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return;
    // const controller = new AbortController();
    // const signal = controller.signal;
    const reqOptions = {
      // signal,
      url: `${website}/wp-json/store/v1/catalogs`,
      // method: "GET",
      headers: { Authorization: `Basic ${token}` },
    };
    if (clearData)
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { dashboardCatalogRequests: [] },
      });
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { fetchingDashboardCatalogRequests: true },
    });
    axios(reqOptions)
      .then(async (res) => {
        let { data } = res;
        if (data && data.success && data.data) {
          if (Array.isArray(data.data)) {
            let allRequests = [];
            for (let catalog of data.data) {
              let { catalogRequests, products } = catalog;
              if (isArrayWithValues(catalogRequests) && products) {
                allRequests = allRequests.concat(
                  catalogRequests.map((i) => ({ ...i, catalog }))
                );
              }
            }
            allRequests = allRequests
              .sort((a, b) => {
                let aTime = new Date(a.catalogue_request_timing).getTime();
                let bTime = new Date(b.catalogue_request_timing).getTime();
                return aTime - bTime;
              })
              .slice(0, 5);

            let customers = allRequests
              .map((i) => i.customer_id)
              .filter(Boolean)
              .slice(0, 5);

            let fetchedCustomers = await fetchCustomers({
              include: customers.join(),
            });
            let updatedRequests = [];
            if (isArrayWithValues(fetchedCustomers))
              for (let request of allRequests) {
                let { customer_id } = request;
                let obj = { ...request };
                let customer = fetchedCustomers.find(
                  (i) => `${i.id}` === `${customer_id}`
                );
                obj.customerDetail = customer;
                updatedRequests.push(obj);
              }
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: {
                dashboardCatalogRequests: updatedRequests,
                fetchedDashboardCatalogRequests: true,
              },
            });
            // if (data.data.length > 0) {
            //   dispatch({
            //     type: SET_PRODUCT_DATA,
            //     payload: { allCatalogs: data.data },
            //   });
            //   dispatch(setupCatalogs(data.data, true));
            //   dispatch(setupCatalogRequests(data.data, true));
            // } else if (data.data.length === 0)
            //   dispatch({
            //     type: SET_PRODUCT_DATA,
            //     payload: { noCatalogFound: true },
            //   });
          }
        }
      })
      .catch((e) => console.log(getAxiosError(e), e, "Catalog error"))
      .finally(() => {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { fetchingDashboardCatalogRequests: false },
        });
      });
  };
};
export const fetchCatalogs = (_params, clearData) => {
  return (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return;
    const reqOptions = {
      // signal,
      url: `${website}/wp-json/store/v1/catalogs`,
      // method: "GET",
      headers: { Authorization: `Basic ${token}` },
    };
    if (clearData)
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { allCatalogs: [] },
      });
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { fetchingCatalogs: true },
    });
    axios(reqOptions)
      .then((res) => {
        let { data } = res;
        if (data && data.success && data.data) {
          if (Array.isArray(data.data)) {
            if (data.data.length > 0) {
              dispatch({
                type: SET_PRODUCT_DATA,
                payload: { allCatalogs: data.data },
              });
              dispatch(setupCatalogs(data.data, true));
              dispatch(setupCatalogRequests(data.data, true));
            } else if (data.data.length === 0)
              dispatch({
                type: SET_PRODUCT_DATA,
                payload: { noCatalogFound: true },
              });
          }
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { fetchedAllCatalogs: true },
          });
        }
      })
      .catch((e) => console.log(getAxiosError(e), e, "Catalog error"))
      .finally(() => {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { fetchingCatalogs: false },
        });
      });
  };
};

export const setupCatalogsTopGraphs = (catalogs) => {
  return (dispatch) => {
    let monthsObj = {};
    for (let i = 0; i <= 11; i++) {
      let currentDate = new Date();
      let _date = new Date(currentDate.setMonth(currentDate.getMonth() - i));
      monthsObj[`a${formatDate(_date, "mm-yyyy")}`] = 0;
    }

    let totalSent = { ...monthsObj };
    let totalRequest = { ...monthsObj };
    let totalProductsSent = { ...monthsObj };
    if (!isArrayWithValues(catalogs)) return;
    for (let catalog of catalogs) {
      let { catalogRequests, created_at, products } = catalog;
      let sentMonth = formatDate(new Date(created_at), "mm-yyyy");
      if (totalSent.hasOwnProperty(`a${sentMonth}`)) {
        totalSent[`a${sentMonth}`] += 1;
      }
      if (totalProductsSent.hasOwnProperty(`a${sentMonth}`)) {
        totalProductsSent[`a${sentMonth}`] += validateNumber(products);
      }
      if (isArrayWithValues(catalogRequests))
        for (let request of catalogRequests) {
          let { catalogue_request_timing } = request;
          let requestMonth = formatDate(
            new Date(catalogue_request_timing),
            "mm-yyyy"
          );
          // if (totalProductsSent.hasOwnProperty(`a${requestMonth}`)) {
          //   if (typeof products === "string")
          //     totalProductsSent[`a${requestMonth}`] +=
          //       products?.split(",")?.length;
          //   else if (isArrayWithValues(products))
          //     totalProductsSent[`a${requestMonth}`] += products.length;
          // }
          if (totalRequest.hasOwnProperty(`a${requestMonth}`))
            totalRequest[`a${requestMonth}`] += 1;
        }
    }
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        catalogTopGraphs: {
          sent: Object.values(totalSent).reverse(),
          request: Object.values(totalRequest).reverse(),
          productsSent: Object.values(totalProductsSent).reverse(),
        },
      },
    });
  };
};

// export const setupCatalogEventAnalytics = () => {
//   return (dispatch, getState) => {
//     let state = getState();
//     let allEvents = state.visitor.allOnlineVisitors;
//     let catalogs = state.product.catalogs;
//     if (!isArrayWithValues(allEvents) && !isArrayWithValues(catalogs)) return;
//     let totalSecondsSpentOnCatalog = 0;
//     let totalViews = [];
//     let totalImpressions = 0;

//     for (let visitor in allEvents) {
//       if (!isArrayWithValues(allEvents[visitor])) continue;
//       for (let event of allEvents[visitor]) {
//         let { event_id, type, end_time, time, user_id } = event;
//         for (let catalog of catalogs) {
//           let {}
//         }
//         // console.log(event_id, catalog?.id, event_id == catalog?.id);
//         // if (event_id == catalog?.id) {
//         //   if (type === "catalog") {
//         //     totalImpressions += catalogWatchImpression;
//         //     totalViews.push(user_id);
//         //     totalImpressions += 1;
//         //     if (end_time && time)
//         //       totalSecondsSpentOnCatalog += validateNumber(
//         //         validateNumber(end_time) - validateNumber(time)
//         //       );
//         //   }
//         //   if (type === "catalog_product") {
//         //     totalImpressions += catalogProductWatchImpression;
//         //   }
//         // }
//       }
//     }

//     console.log(totalViews);
//   };
// };

export const setupCatalogs = (catalogs, fetchCatalogProducts) => {
  return (dispatch, getState) => {
    let state = getState();
    let all_catalogs = state.product.catalogs;
    if (!catalogs) catalogs = all_catalogs;
    let catalogMostViewedProductsFetched =
      state.product.catalogMostViewedProductsFetched;
    // let allEvents = state.visitor.allOnlineVisitors;
    // console.log(allEvents, "<<<< all events, asldjfals");
    if (!isArrayWithValues(catalogs)) return;
    let _catalogs = [];
    let catalogExtra = {
      totalSent: catalogs?.length || 0,
      totalPublicSent: 0,
      totalPrivateSent: 0,
      totalSentToCustomers: 0,
      totalViews: 0,
      totalRequests: 0,
      totalProducts: 0,
      avgProductsPerCatalog: 0,
      avgProductsRequestsPerCatalog: 0,
      totalUniqueProductsRequested: 0,
      totalResponse: 0,
      totalProductsSent: 0,
      totalProductsSentUnique: 0,
      totalRequestedProducts: 0,
    };
    let totalProductsSentIds = [];
    let productViews = {};
    let catalogSentTimes = {};
    let catalogRequestTimes = {};
    for (let catalog of catalogs) {
      let obj = {
        ...catalog,
        productsArray: [],
        productsArrayRequested: [],
        uniqueProductsRequested: 0,
        uniqueProductsRequestedPercent: 0,
        totalRequests: 0,
        totalRequestsPercent: 0,
        totalProductRequest: 0,
        totalProductRequestPerContact: 0,
        totalSentToCustomers: 0,
        totalViews: 0,
        totalCustomers: 0,
        totalLeads: 0,
      };

      let {
        catalogRequests,
        productDetails,
        customer,
        visitor_info,
        allCustomerDetails,
      } = obj;

      if (isArrayWithValues(allCustomerDetails)) {
        for (let customer of allCustomerDetails) {
          let { meta_data } = customer;
          let contactTypeObj = meta_data?.find((i) => i.key === "contact_type");
          if (contactTypeObj) {
            if (contactTypeObj?.value === "customer") {
              obj.totalCustomers += 1;
            } else obj.totalLeads += 1;
          } else obj.totalLeads += 1;
        }
      }

      if (obj.is_public) catalogExtra.totalPublicSent++;
      else catalogExtra.totalPrivateSent++;
      let sentDate = formatDate(new Date(obj.created_at), "mm-dd-yyyy");
      let allEvents = visitor_info || {};

      if (!catalogSentTimes[sentDate]) catalogSentTimes[sentDate] = 0;
      catalogSentTimes[sentDate] += 1;

      for (let visitor in allEvents) {
        if (!isArrayWithValues(allEvents[visitor])) continue;
        for (let event of allEvents[visitor]) {
          let { event_id, type, end_time, time, product_id } = event;
          if (type === "catalog" && `${event_id}` === `${catalog?.id}`)
            obj.totalViews += 1;
          if (type === "catalog_product" && time && end_time) {
            if (!productViews[product_id]) productViews[product_id] = 0;
            productViews[product_id] +=
              validateNumber(end_time) - validateNumber(time);
          }
        }
      }
      // if (isArrayWithValues(obj.customer))
      //   catalogExtra.totalSent = obj?.customer?.length;

      obj.totalSentToCustomers = customer?.length || 0;
      catalogExtra.totalSentToCustomers += customer?.length || 0;
      if (obj.products) {
        catalogExtra.totalProducts += validateNumber(obj.products);
        let productsArray = obj?.productsData?.split(",");
        if (productsArray) {
          obj.productsArray = obj.productsArray.concat(productsArray);
          totalProductsSentIds = totalProductsSentIds.concat(productsArray);
        }
      }
      //settings analytics related to catalog requests
      if (isArrayWithValues(catalogRequests)) {
        // obj.totalViews = catalogRequests?.filter((i) => !i.products)?.length;
        catalogExtra.totalViews += validateNumber(Math.round(obj.totalViews));
        obj.totalRequests = catalogRequests?.filter((i) => i.products)?.length;
        catalogExtra.totalRequests += validateNumber(
          Math.round(obj.totalRequests)
        );
        catalogExtra.totalResponse += validateNumber(catalogRequests?.length);
        catalogRequests?.forEach((i) => {
          let { products } = i || {};
          let ids = [];
          if (typeof products === "string")
            ids = products?.split(",")?.filter(Boolean);
          else if (isArray(products)) ids = products;

          if (isArray(ids))
            obj.productsArrayRequested = obj.productsArrayRequested.concat(ids);
          // if (isArrayWithValues(activities))
          //   activities.map((i) => {
          //     if (i.type == "product") {
          //       if (!productViews[i.product_id]) productViews[i.product_id] = 0;
          //       productViews[i.product_id] += validateNumber(
          //         Number(i.end) - Number(i.start)
          //       );
          //     }
          //   });
        });
        if (isArrayWithValues(obj.productsArrayRequested)) {
          obj.totalProductRequest = obj.productsArrayRequested.length;
          obj.totalProductRequestPerContact = Math.round(
            validateNumber(
              obj.productsArrayRequested.length / obj.totalRequests
            )
          );
          obj.productsArrayRequested =
            obj.productsArrayRequested?.filter(Boolean);
          let length = obj.productsArrayRequested.length;
          obj.productsArrayRequested = [...new Set(obj.productsArrayRequested)];
          obj.uniqueProductsRequested = obj.productsArrayRequested.length;
          obj.uniqueProductsRequestedPercent = Math.round(
            validateNumber(length / obj.productsArrayRequested.length) * 100
          );
          catalogExtra.totalUniqueProductsRequested += validateNumber(
            obj.uniqueProductsRequested
          );
        }
      }
      //settings analytics related to catalog fetched products
      if (isArrayWithValues(productDetails)) {
        obj = {
          ...obj,
          totalInStock: 0,
          totalMakeToOrder: 0,
          totalInStockPercent: 0,
          totalMakeToOrderPercent: 0,
          totalProductsInRequests: 0,
          totalInStockInRequests: 0,
          totalInStockInRequestsPercent: 0,
          totalMakeToOrderInRequests: 0,
          totalMakeToOrderInRequestsPercent: 0,
        };
        let totalRequestedProducts = [];
        let totalRequestedProductsInStock = 0;
        if (isArrayWithValues(catalogRequests)) {
          for (let request of catalogRequests) {
            let { products, catalogue_request_timing } = request;
            catalogue_request_timing =
              `${catalogue_request_timing}`?.length > 10
                ? catalogue_request_timing
                : validateNumber(catalogue_request_timing) * 1000;
            let requestDate = formatDate(
              new Date(catalogue_request_timing),
              "mm-dd-yyyy"
            );
            if (!catalogRequestTimes[requestDate])
              catalogRequestTimes[requestDate] = 0;
            catalogRequestTimes[requestDate] += 1;

            let ids;
            if (typeof products === "string")
              ids = products?.split(",")?.filter(Boolean);
            else if (isArrayWithValues(products)) ids = products;
            if (isArrayWithValues(ids)) {
              totalRequestedProducts = totalRequestedProducts.concat(ids);
            }
          }
        }
        let _totalRequestedProducts = [...new Set(totalRequestedProducts)];
        catalogExtra.totalRequestedProducts += _totalRequestedProducts.length;

        if (isArray(totalRequestedProducts)) {
          for (let id of totalRequestedProducts) {
            let productObj = productDetails.find((i) => `${i.id}` === `${id}`);
            if (productObj && productObj.stock_status === "instock")
              totalRequestedProductsInStock += 1;
          }
          obj.totalProductsInRequests = totalRequestedProducts.length;
          obj.totalInStockInRequests = totalRequestedProductsInStock;
          obj.totalInStockInRequestsPercent = Math.round(
            validateNumber(
              totalRequestedProductsInStock / totalRequestedProducts.length
            ) * 100
          );
          obj.totalMakeToOrderInRequests = validateNumber(
            totalRequestedProducts.length - totalRequestedProductsInStock
          );
          obj.totalMakeToOrderInRequestsPercent = Math.round(
            validateNumber(
              obj.totalMakeToOrderInRequests / totalRequestedProducts.length
            ) * 100
          );
        }
        for (let product of productDetails) {
          let { stock_status } = product;
          if (stock_status === "instock") {
            obj.totalInStock += 1;
          }
          if (stock_status === "onbackorder") obj.totalMakeToOrder += 1;
        }
        if (obj.totalInStock)
          obj.totalInStockPercent = Math.round(
            validateNumber(productDetails.length / obj.totalInStock) * 100
          );
        if (obj.totalMakeToOrderPercent)
          obj.totalMakeToOrderPercent = Math.round(
            validateNumber(
              productDetails.length / obj.totalMakeToOrderPercent
            ) * 100
          );
      }
      _catalogs.push(obj);
    }
    productViews = sortObjectByValues(productViews);
    if (isObjWithValues(productViews) && !catalogMostViewedProductsFetched)
      dispatch(fetchCatalogMostViewedProducts(Object.keys(productViews)));
    catalogExtra.totalProductsSent = [...totalProductsSentIds].length || 0;
    catalogExtra.totalProductsSentUnique =
      [...new Set(totalProductsSentIds)]?.length || 0;
    if (catalogExtra.totalProducts && isArrayWithValues(_catalogs))
      catalogExtra.avgProductsPerCatalog = Math.round(
        validateNumber(catalogExtra.totalProducts) /
          validateNumber(_catalogs.length)
      );
    if (catalogExtra.totalRequestedProducts && isArrayWithValues(_catalogs))
      catalogExtra.avgProductsRequestsPerCatalog = validateNumber(
        Number(
          validateNumber(catalogExtra.totalRequestedProducts) /
            validateNumber(_catalogs.length)
        ).toFixed(2)
      );

    let sentArrayGraph = [];
    let requestArrayGraph = [];
    if (isObjWithValues(catalogSentTimes))
      for (let date in catalogSentTimes)
        sentArrayGraph.push([new Date(date).getTime(), catalogSentTimes[date]]);
    if (isObjWithValues(catalogRequestTimes))
      for (let date in catalogRequestTimes)
        requestArrayGraph.push([
          new Date(date).getTime(),
          catalogRequestTimes[date],
        ]);
    catalogExtra.sentArrayGraph = sentArrayGraph.sort((a, b) => a[0] - b[0]);
    catalogExtra.requestArrayGraph = requestArrayGraph.sort(
      (a, b) => a[0] - b[0]
    );
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { catalogs: _catalogs, catalogExtra },
    });
    dispatch(setupCatalogsTopGraphs(_catalogs));
    if (fetchCatalogProducts) dispatch(setupCatalogProducts(_catalogs));
  };
};

export const addVisitorInCatalog = (obj) => {
  return (dispatch, getState) => {
    let state = getState();
    let catalogs = state.product.catalogs;
    let catalog = catalogs?.find((i) => `${i?.id}` === `${obj?.event_id}`);
    if (catalog && obj?.type === "catalog") {
      let { visitor_info, totalViews } = catalog;
      if (!visitor_info) visitor_info = {};
      if (isArrayWithValues(visitor_info?.[obj.user_id]))
        visitor_info[obj.user_id]?.push(obj);
      else visitor_info[obj.user_id] = [obj];
      if (validateNumber(totalViews)) totalViews += 1;
      else totalViews = 1;

      let updatedCatalogs = catalogs.map((i) =>
        `${i.id}` === `${obj?.event_id}`
          ? { ...i, visitor_info, totalViews }
          : i
      );

      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { catalogs: updatedCatalogs },
      });
    }
  };
};

export const fetchCatalogMostViewedProducts = (productIds) => {
  return async (dispatch) => {
    // console.log(catalogs, "<<<< Catalog");
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let page = 1;
      let per_page = 5;
      let idsToFetch = [...new Set(productIds)];
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingCatalogMostViewedProducts: true },
      });
      let allProducts = await fetchProducts({
        page,
        per_page,
        include: idsToFetch.splice(0, per_page).join(),
      });

      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          fetchingCatalogMostViewedProducts: false,
          catalogMostViewedProductsFetched: true,
          catalogMostViewedProduct: allProducts,
        },
      });
    }
  };
};
const setupCatalogProducts = (catalogs, _reduxKey) => {
  return async (dispatch) => {
    if (!isArrayWithValues(catalogs)) return;
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let productIds = [];
      catalogs?.forEach((catalog) => {
        let idsArray = catalog?.productsData?.split(",");
        if (idsArray) productIds = productIds.concat(idsArray);
      });
      let per_page = 100;
      let rounds = Math.round(productIds.length / per_page) || 1;
      let page = 1;
      let allProducts = [];
      let idsToFetch = [...new Set(productIds)];
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingCatalogProducts: true },
      });
      // eslint-disable-next-line no-unused-vars
      for await (let _i of Array.from(new Array(rounds))) {
        let products = await fetchProducts({
          page,
          per_page,
          include: idsToFetch.splice(0, per_page).join(),
        });
        if (isArrayWithValues(products))
          allProducts = allProducts.concat(products);
        if (!isArrayWithValues(idsToFetch)) break;
      }
      let updatedCatalogs = [];
      for (let catalog of catalogs) {
        let idsArray = catalog?.productsData?.split(",");
        if (isArrayWithValues(idsArray)) {
          let productDetails = [];
          for (let id of idsArray) {
            let productObj = allProducts.find((i) => `${i.id}` === `${id}`);
            if (productObj) productDetails.push(productObj);
          }
          catalog.productDetails = productDetails;
          updatedCatalogs.push(catalog);
        }
      }
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingCatalogProducts: false },
      });
      dispatch(setupCatalogs(updatedCatalogs));
      dispatch(setupCatalogRequests(updatedCatalogs));
      // dispatch({
      //   type: SET_PRODUCT_DATA,
      //   payload: { catalogs: obj },
      // });
    }
  };
};

const fetchProducts = async (params) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let { data } = await axios({
      url: `${website}/wp-json/wc/v3/products`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params,
    });
    if (isArray(data)) return data;
  } catch (error) {
    return false;
  }
};
const fetchCustomers = async (params) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let { data } = await axios({
      url: `${website}/wp-json/wc/v3/customers`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params,
    });
    if (isArray(data)) return data;
  } catch (error) {
    return false;
  }
};

export const setupCatalogRequests = (catalogs, fetchCustomerDetails) => {
  return (dispatch, getState) => {
    let state = getState();
    const currentRequests = state.product.catalog_requests;
    if (!isArrayWithValues(catalogs)) return;
    let requests = [];
    for (let catalog of catalogs) {
      let { catalogRequests, visitor_info, ...otherCatalogDetails } = catalog;
      let allProducts = [...(catalog.productDetails || [])];
      if (isArrayWithValues(catalogRequests)) {
        let updatedRequests = [...catalogRequests];

        // for (let visitor in allEvents) {
        //   if (!isArrayWithValues(allEvents[visitor])) continue;
        //   for (let event of allEvents[visitor]) {
        //     let { event_id, type, end_time, time, user_id, product_id } = event;
        //     if (type === "catalog" && event_id == catalog?.id){

        //     }

        //   }
        // }

        updatedRequests = updatedRequests.map((i) => {
          let { products, customer_id } = i;
          let obj = { ...i, totalInStock: 0, totalMakeToOrder: 0 };
          let time_spent = 0;
          if (
            isObjWithValues(visitor_info) &&
            isArrayWithValues(visitor_info?.[customer_id])
          ) {
            for (let obj of visitor_info?.[customer_id]) {
              let { type, time, end_time } = obj;
              if (time && end_time && type === "catalog") {
                time_spent += validateNumber(
                  validateNumber(end_time) - validateNumber(time)
                );
              }
            }
          }
          obj.time_spent = time_spent;
          if (isArrayWithValues(allProducts) && isArrayWithValues(products)) {
            for (let id of products) {
              let productObj = allProducts.find((i) => `${i.id}` === `${id}`);
              if (productObj && productObj.stock_status === "instock")
                obj.totalInStock += 1;
              if (productObj && productObj.stock_status === "onbackorder")
                obj.totalMakeToOrder += 1;
            }
          }
          if (obj.products) {
            if (typeof products == "string")
              obj.products = obj?.products?.replace("[", "")?.replace("]", "");
            let ids;
            if (typeof products == "string")
              ids = obj?.products?.split(",")?.filter(Boolean);
            else if (isArrayWithValues(obj?.products)) ids = obj?.products;
            obj.productsLength = ids?.length;
          }
          obj.catalog = otherCatalogDetails;
          if (isArrayWithValues(currentRequests)) {
            let currentRequest = currentRequests.find(
              (i) => `${i.request_id}` === `${obj.request_id}`
            );
            if (currentRequest) obj = { ...currentRequest, ...obj };
          }
          return obj;
        });
        requests = requests.concat(updatedRequests);
      }
    }
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { catalog_requests: requests },
    });
    if (fetchCustomerDetails) dispatch(setupCatalogRequstCustomers(catalogs));
  };
};

const setupCatalogRequstCustomers = (catalogs) => {
  return async (dispatch) => {
    if (!isArrayWithValues(catalogs)) return;
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let customerIds = [];
      catalogs?.forEach((catalog) => {
        let { catalogRequests } = catalog;
        if (isArrayWithValues(catalogRequests))
          customerIds = customerIds.concat(
            catalogRequests?.map((i) => i.customer_id)?.filter(Boolean)
          );
        // let idsArray = catalog?.productsData?.split(",");
        // if (idsArray) customerIds = customerIds.concat(idsArray);
      });
      let per_page = 100;
      let rounds =
        Math.round(validateNumber(customerIds?.length) / per_page) || 1;
      let page = 1;
      let allCustomers = [];
      let idsToFetch = [...new Set(customerIds)];
      // eslint-disable-next-line no-empty-pattern
      for await (let temIndex of Array.from(new Array(rounds))) {
        let customers = await fetchCustomers({
          page,
          per_page,
          include: idsToFetch.splice(0, per_page).join(),
        });
        if (isArrayWithValues(customers))
          allCustomers = allCustomers.concat(customers);
        if (!isArrayWithValues(idsToFetch)) break;
      }
      let updatedCatalogs = [];
      let customerTotalRequest = {};
      for (let catalog of catalogs) {
        let { catalogRequests } = catalog;
        let _catalog = { ...catalog };
        if (isArrayWithValues(catalogRequests)) {
          let _catalogRequest = [];
          for (let request of catalogRequests) {
            let { customer_id, products } = request;

            if (customer_id) {
              if (!customerTotalRequest[customer_id])
                customerTotalRequest[customer_id] = 0;
              customerTotalRequest[customer_id] += 1;
            }

            let _request = { ...request };
            if (typeof products == "string")
              _request.totalSelectedProducts = products
                ?.split(",")
                ?.filter(Boolean)?.length;
            else _request.totalSelectedProducts = products?.length;
            let customerObj = allCustomers.find(
              (i) => `${i.id}` === `${customer_id}`
            );

            if (customerObj) _request.customerDetail = customerObj;
            _catalogRequest.push(_request);
          }
          _catalog.catalogRequests = _catalogRequest;
          updatedCatalogs.push(_catalog);
        }
        // let { id } = catalog;
        // let idsArray = catalog?.productsData?.split(",");
        // if (isArrayWithValues(idsArray)) {
        //   let productDetails = [];
        //   for (let id of idsArray) {
        //     let productObj = allCustomers.find((i) => i.id == id);
        //     if (productObj) productDetails.push(productObj);
        //   }
        //   catalog.productDetails = productDetails;
        //   updatedCatalogs.push(catalog);
        // }
      }
      let _updatedAllCustomers = [];
      for (let _customer of allCustomers) {
        let customerObj = {
          ..._customer,
          totalRequests: 0,
          totalRequestsPercentage: 0,
          totalVisits: 0,
          totalSent: 0,
          totalProductsSent: 0,
          totalProductsRequest: 0,
          totalProductsRequestPercentage: 0,
        };
        let { id, meta_data } = customerObj;

        if (isArrayWithValues(meta_data)) {
          for (let obj of meta_data) customerObj[obj.key] = obj.value;
        }
        for (let catalog of updatedCatalogs) {
          let { catalogRequests, customer, products } = catalog;
          if (isArrayWithValues(customer) && customer.includes(id)) {
            customerObj.totalSent += 1;
            customerObj.totalProductsSent += validateNumber(products);
          }
          if (isArrayWithValues(catalogRequests)) {
            for (let request of catalogRequests) {
              let { customer_id, products } = request;
              if (`${customer_id}` === `${id}`) {
                if (products) {
                  if (typeof products == "string")
                    products = products.replace("[", "").replace("]", "");
                  customerObj.totalRequests += 1;
                  if (typeof products == "string")
                    customerObj.totalProductsRequest += validateNumber(
                      products?.split(",").filter(Boolean)?.length
                    );
                  else
                    customerObj.totalProductsRequest += validateNumber(
                      products?.length
                    );
                }
                customerObj.totalVisits += 1;
              }
            }
          }
        }
        if (customerObj.totalSent && customerObj.totalRequests)
          customerObj.totalRequestsPercentage = validateNumber(
            Math.round(
              (validateNumber(customerObj.totalRequests) /
                validateNumber(customerObj.totalSent)) *
                100
            )
          );
        if (customerObj.totalProductsSent && customerObj.totalProductsRequest)
          customerObj.totalProductsRequestPercentage = validateNumber(
            Math.round(
              (validateNumber(customerObj.totalProductsRequest) /
                validateNumber(customerObj.totalProductsSent)) *
                100
            )
          );
        _updatedAllCustomers.push(customerObj);
      }
      customerTotalRequest = sortObjectByValues(customerTotalRequest);
      let topCustomers = [];
      if (isObjWithValues(customerTotalRequest)) {
        for (let id in customerTotalRequest) {
          let customer = _updatedAllCustomers?.find(
            (i) => `${i.id}` === `${id}`
          );
          if (customer) topCustomers.push(customer);
        }
      }
      // console.log(
      //   customerTotalRequest,
      //   _updatedAllCustomers,
      //   "<<<< top customers"
      // );
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { topCatalogCustomers: topCustomers },
      });
      dispatch(setupCatalogRequests(updatedCatalogs));
      // dispatch({
      //   type: SET_PRODUCT_DATA,
      //   payload: { catalogs: obj },
      // });
    }
  };
};

export const fetchAllCustomerOfCatalog = (id) => {
  return async (dispatch, getState) => {
    let state = getState();
    let catalogs = state.product.catalogs;
    let allCatalogs = [...(catalogs || [])];
    let currentCatalog = allCatalogs?.find((i) => `${i.id}` === `${id}`);
    if (!isObjWithValues(currentCatalog)) return;
    let { customer } = currentCatalog;
    let per_page = 100;
    let rounds = Math.round(validateNumber(customer?.length) / per_page) || 1;
    let page = 1;
    let allCustomers = [];
    let idsToFetch = [...new Set(customer)];
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { fetchingAllCustomersOfCatalog: true },
    });
    if (isArrayWithValues(idsToFetch))
      // eslint-disable-next-line no-unused-vars
      for await (let _i of Array.from(new Array(rounds))) {
        let customers = await fetchCustomers({
          page,
          per_page,
          include: idsToFetch.splice(0, per_page).join(),
        });
        if (isArrayWithValues(customers))
          allCustomers = allCustomers.concat(customers);
      }
    let _allCustomers = [];
    for (let customer of allCustomers) {
      let obj = { ...customer, totalRequests: 0, totalProductsRequested: 0 };
      let { catalogRequests } = currentCatalog;
      if (isArrayWithValues(catalogRequests)) {
        for (let request of catalogRequests) {
          let { customer_id, products } = request;
          if (`${obj.id}` === `${customer_id}`) {
            obj.totalRequests += 1;
            if (typeof products == "string")
              obj.totalProductsRequested += validateNumber(
                products?.split(",")?.filter(Boolean)?.length
              );
            else obj.totalProductsRequested += validateNumber(products?.length);
          }
        }
      }
      // obj.totalRequests = catalogRequests.filter(
      //   (i) => i.customer_id == obj.id
      // ).length;
      _allCustomers.push(obj);
    }
    currentCatalog.allCustomerDetails = _allCustomers;
    currentCatalog.allCustomersFetched = true;
    let _catalogs = catalogs.map((i) =>
      `${i.id}` === `${id}` ? currentCatalog : i
    );
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { catalogs: _catalogs, fetchingAllCustomersOfCatalog: false },
    });
  };
};
export const fetchCustomerOfCatalogRequest = (id) => {
  return async (dispatch, getState) => {
    let state = getState();
    let catalog_requests = state.product.catalog_requests;
    let allCatalogsRequests = [...(catalog_requests || [])];
    let currentCatalogRequest = allCatalogsRequests?.find(
      (i) => `${i.request_id}` === `${id}`
    );
    if (!isObjWithValues(currentCatalogRequest)) return;
    let { customer_id } = currentCatalogRequest;
    if (!customer_id) customer_id = [5116, 5232, 22067, 6197];
    let per_page = 100;
    // let rounds = Math.round(customer_id.length / per_page) || 1;
    let page = 1;
    let idsToFetch = [...new Set(customer_id)];
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { fetchingCustomersOfCatalogRequest: true },
    });
    let allCustomers = await fetchCustomers({
      page,
      per_page,
      include: idsToFetch.splice(0, per_page).join(),
    });
    // if (isArrayWithValues(customers))
    //   allCustomers = allCustomers.concat(customers);

    // let _allCustomers = [];

    // for (let customer of allCustomers) {
    //   let obj = { ...customer, totalRequests: 0, totalProductsRequested: 0 };
    //   let { catalogRequests } = currentCatalogRequest;
    //   if (isArrayWithValues(catalogRequests)) {
    //     for (let request of catalogRequests) {
    //       let { customer_id, products } = request;
    //       if (obj.id == customer_id) {
    //         obj.totalRequests += 1;
    //         obj.totalProductsRequested += validateNumber(
    //           products?.split(",")?.filter(Boolean)?.length
    //         );
    //       }
    //     }
    //   }
    //   // obj.totalRequests = catalogRequests.filter(
    //   //   (i) => i.customer_id == obj.id
    //   // ).length;
    //   _allCustomers.push(obj);
    // }
    currentCatalogRequest.customerDetail = allCustomers?.[0];
    currentCatalogRequest.customerDetailFetched = true;
    let _catalogRequests = allCatalogsRequests.map((i) =>
      `${i.request_id}` === `${id}` ? currentCatalogRequest : i
    );
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        catalog_requests: _catalogRequests,
        fetchingCustomersOfCatalogRequest: false,
      },
    });
  };
};

export const addCatalogLive = (obj) => {
  return (dispatch, getState) => {
    try {
      let state = getState();
      let live_customers = [...(state.product.live_customers || [])];

      let addedCustomer = live_customers.find(
        (i) => `${i.id}` === `${obj.request_id}`
      );

      if (!addedCustomer) {
        live_customers.push({
          id: obj.id,
          type: "catalog",
          customer_id: obj.customer_id || "",
          ua: obj.ua,
          ip: obj.ip,
          catalog_id: obj.post_id,
        });
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { live_customers: live_customers },
        });
      }

      if (obj.customer_id) {
        let allIds = state.product.catalog_live_ids || [];

        if (!allIds?.includes(obj.customer_id) && obj.online)
          allIds.push(obj.customer_id);
        if (allIds?.includes(obj.customer_id) && !obj.online)
          allIds = allIds.filter((i) => `${i}` !== `${obj.customer_id}`);
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { catalog_live_ids: allIds },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};
export const updateProductInRedux = (product) => {
  return (dispatch, getState) => {
    try {
      if (product.id) {
        let state = getState();
        let allProducts = state.product.allProducts;
        if (isArrayWithValues(allProducts)) {
          allProducts = allProducts.map((i) =>
            i.id === product.id ? product : i
          );
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { allProducts },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMultipleProductsInRedux = (products) => {
  return (dispatch, getState) => {
    try {
      if (isArrayWithValues(products)) {
        let state = getState();
        let allProducts = state.product.allProducts;
        if (isArrayWithValues(allProducts)) {
          let updatedAllProducts = [];
          for (let i = 0; i < allProducts.length; i++) {
            let id = allProducts[i].id;
            let updatedProduct = products.find((i) => i.id === id && !i?.error);
            if (updatedProduct) updatedAllProducts.push(updatedProduct);
            else updatedAllProducts.push(allProducts[i]);
          }
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { allProducts: updatedAllProducts },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteMultipleProductsInRedux = (products) => {
  return (dispatch, getState) => {
    try {
      if (isArrayWithValues(products)) {
        let state = getState();
        let allProducts = state.product.allProducts;
        if (isArrayWithValues(allProducts)) {
          let updatedAllProducts = [];
          for (let i = 0; i < allProducts.length; i++) {
            let id = allProducts[i].id;
            let updatedProduct = products.find((i) => i.id === id);
            if (!updatedProduct) updatedAllProducts.push(allProducts[i]);
          }
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { allProducts: updatedAllProducts },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getMediaLibrary = (params, replaceData) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingMedia: true },
      });
      // if (replaceData)
      //   dispatch({
      //     type: EMPTY_MEDIA_LIBRARY,
      //   });
      let website = getWebsite();
      let token = getToken();
      if (website && token)
        axios({
          url: `${website}/wp-json/wp/v2/media`,
          method: "GET",
          headers: {
            Authorization: `Basic ${token}`,
          },
          params,
        })
          .then((res) => {
            // console.log(res.data);
            try {
              if (res.data) {
                if (replaceData)
                  dispatch({
                    type: REPLACE_MEDIA_LIBRARY,
                    payload: res.data,
                  });
                else
                  dispatch({
                    type: APPEND_MEDIA_LIBRARY,
                    payload: res.data,
                  });
              }
            } catch (error) {
              console.log(error);
            }
          })
          .catch((e) => console.log(getAxiosError(e)))
          .finally(() => {
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: { fetchingMedia: false },
            });
          });
    } catch (error) {
      console.log(error);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingMedia: false },
      });
    }
  };
};
export const deleteMediaInRedux = (id) => {
  return (dispatch, getState) => {
    let state = getState();
    let allMedia = [].concat([...state.product.media]);
    if (isArrayWithValues(allMedia)) {
      allMedia = allMedia.filter((i) => i.id !== id);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { media: allMedia },
      });
    }
  };
};

export const deleteProductInRedux = (id) => {
  return (dispatch, getState) => {
    try {
      if (id) {
        let state = getState();
        let allProducts = state.product.allProducts;
        if (isArrayWithValues(allProducts)) {
          allProducts = allProducts.filter((i) => i.id !== id);
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { allProducts },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const addProductInRedux = (product) => {
  return (dispatch, getState) => {
    try {
      if (isObjWithValues(product)) {
        let state = getState();

        let allProducts = state.product.allProducts;
        const array = [{ ...product }].concat([...allProducts]);
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: {
            allProducts: array,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};
export const addMultipleProductsInRedux = (products) => {
  return (dispatch, getState) => {
    try {
      if (isArrayWithValues(products)) {
        let state = getState();

        let allProducts = state.product.allProducts;
        const array = [...products].concat([...allProducts]);
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: {
            allProducts: array,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllSku = () => {
  return async (dispatch) => {
    let allSku = [];
    // let array = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    allSku = await getProducts({
      per_page: 100,
      page: 1,
      _fields: "id, sku, images",
    });
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        allSku,
      },
    });
  };
};

export const updateAllSku = (obj) => {
  return (dispatch, getState) => {
    const state = getState();
    let allSku = [...state.product.allSku];
    if (isArrayWithValues(allSku)) {
      allSku = allSku.map((i) => (i.id === obj.id ? obj : i));
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          allSku,
        },
      });
    }
  };
};

const getProducts = async (params) => {
  let WooCommerce = await getWooCommerce();
  if (WooCommerce) {
    return WooCommerce.get("products", { ...params }).then((res) => {
      // console.log(res);
      return res.data;
    });
  }
};

// export const getProductSettings = () => {
//   return async (dispatch) => {
//     dispatch({
//       type: SET_PRODUCT_DATA,
//       payload: { fetchingProductSettings: true },
//     });
//     const website = getWebsite();
//     const token = getToken();
//     if (website && token) {
//       const controller = new AbortController();
//       const signal = controller.signal;
//       let res = await axios({
//         url: `${website}/wp-json/store/v1/settings/business_settings`,
//         headers: {
//           Authorization: `Basic ${token}`,
//           // signal,
//         },
//       });
//       let { data } = res;
//       if (data.success && isObjWithValues(data.data)) {
//         dispatch({ type: SET_PRODUCT_SETTINGS, payload: { ...data.data } });
//         // setSettingsFromRedux({ ...data.data });
//         // updateSettingsInRedux({ ...data.data });
//       }

//       dispatch({
//         type: SET_PRODUCT_DATA,
//         payload: { fetchingProductSettings: false },
//       });
//     }
//   };
// };

export const getAllQuotation = () => {
  return async (dispatch) => {
    const website = await getWebsite();
    const token = await getToken();

    if (website && token) {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { fetchingQuotations: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/quotations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          let { data } = res;
          if (data.success)
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: { quotations: data.data },
            });
        })
        .finally(() => {
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { fetchingQuotations: false },
          });
        });
    }
  };
};
export const updateQuotation = (quotation, onSuccess, onError) => {
  return async (dispatch) => {
    const website = await getWebsite();
    const token = await getToken();

    if (website && token) {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { updatingQuotation: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/quotations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: { type: "edit", data: quotation, id: quotation.id },
        method: "POST",
      })
        .then((res) => {
          // console.log(res.data);
          let { data } = res;
          if (data.success) {
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: { quotations: data.data },
            });
            onSuccess && onSuccess();
          }
        })
        .catch(() => {
          onError && onError();
        })
        .finally(() => {
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { updatingQuotation: false },
          });
        });
    }
  };
};

export const deleteQuotationInRedux = (id) => {
  return (dispatch, getState) => {
    let state = getState();
    let quotations = state.product.quotations;
    if (isArrayWithValues(quotations)) {
      let updatedQuotations = quotations.filter((i) => i.id !== id);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { quotations: updatedQuotations },
      });
    }
  };
};

export const createQuotation = (data, onSuccess, onError) => {
  return async (dispatch) => {
    const website = await getWebsite();
    const token = await getToken();

    // const state = getState();
    // const allProducts = [...state.product.allProducts];
    // let params = new URLSearchParams(data);
    // const formData = new FormData();
    // formData.append("data[title]", data.title);
    // formData.append("data[customers]", data.customer);
    // formData.append("data[customers]", data.customer);
    // formData.append("data[otp_enabled]", data.otp_enabled);

    // customer: "3";
    // otp_enabled: false;
    // products: "5019,5020,4887,4885";
    // title: "Test";
    // return;
    if (website && token) {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { creatingQuotation: true },
      });

      axios({
        url: `${website}/wp-json/store/v1/quotations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: { data: { ...data }, type: "add" },
        method: "POST",
      })
        .then((res) => {
          if (res.data.success && res.data.data) {
            dispatch({ type: CREATE_QUOTATION, payload: res.data.data });
            // let { products: _products } = data;
            // let products = _products.split(",");
            // if (isArrayWithValues(products) && isArrayWithValues(allProducts)) {
            //   let selectedProducts = allProducts.filter((i) =>
            //     products.includes(`${i.id}`)
            //   );
            //   let sizes = {};
            //   for (let product of selectedProducts) {
            //     let size = getMetaValueFromProduct(product, "subcatsetting");
            //     if (isArrayWithValues(size)) {
            //       let obj = {
            //         id: Date.now(),
            //         quantity: 1,
            //         size: size[0],
            //         color: getMetaValueFromProduct(product, "metal_color"),
            //       };
            //       sizes[product.id] = [{ ...obj }];
            //     }
            //   }
            //   if (isObjWithValues(sizes)) {
            //     let quotation = res.data.data;
            //     let {
            //       customer,
            //       products,
            //       title,
            //       id: quotationId,
            //       product_comments,
            //       comments,
            //     } = quotation;

            //     let obj = {
            //       type: "edit",
            //       id: quotationId,
            //       data: {
            //         title,
            //         products,
            //         customer,
            //         comments,
            //         product_comments,
            //         customize: { sizes },
            //       },
            //     };
            //   }
            // }
            onSuccess && onSuccess(res.data.data);
          }
        })
        .catch((e) => {
          console.log(getAxiosError(e));
          onError && onError();
        })
        .finally(() => {
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { creatingQuotation: false },
          });
        });
    }
  };
};

export const updateProductFilter = (obj) => {
  return (_dispatch) => {
    const website = getWebsite();
    const token = getToken();
    if (website && token) {
      axios({
        method: "PUT",
        url: `${website}/wp-json/store/v1/settings/product_filters`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: { data: obj },
      })
        .then((res) => {
          console.log(res.data);
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        });
    }
  };
};

export const fetchDigiPlans = () => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: { fetching_digigold_plans: true },
        });
        let res = await axios({
          url: `${website}/wp-json/wc/v3/products`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            "custom_filter[0][key]": "_visibility",
            "custom_filter[0][value]": "hidden",
            per_page: 100,
          },
          // data: {
          //   custom_filter: [
          //     {
          //       key: "_visibility",
          //       value: "hidden",
          //     },
          //   ],
          // },
          method: "GET",
        });
        if (isArrayWithValues(res.data))
          dispatch({
            type: SET_PRODUCT_DATA,
            payload: { digigold_plans: res.data },
          });
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          fetching_digigold_plans: false,
          fetched_digigold_plans: true,
        },
      });
    }
  };
};
export const fetchTopSellingProducts = () => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/reports/top_sellers`,
          headers: {
            authorization: `Basic ${token}`,
          },
          params: {
            period: "last_month",
          },
        });
        console.log(data, "<<<<,");
        if (isArrayWithValues(data)) {
          let { data: productsData } = await axios({
            url: `${website}/wp-json/wc/v3/products`,
            headers: {
              authorization: `Basic ${token}`,
            },
            params: {
              include: data.map((i) => i.product_id).join(),
            },
          });
          console.log(productsData, "<<<<,");

          if (isArrayWithValues(productsData)) {
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: { topSellingProducts: productsData },
            });
            dispatch({
              type: SET_PRODUCT_DATA,
              payload: { fetchedTopSellingProducts: true },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const updateDigiPlanInRedux = (updateObj) => {
  return (dispatch, getState) => {
    let state = getState();
    let digigold_plans = state.product.digigold_plans;
    if (isArrayWithValues(digigold_plans)) {
      let array = [...digigold_plans];
      array = array.map((i) =>
        `${i.id}` === `${updateObj.id}` ? updateObj : i
      );
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { digigold_plans: array },
      });
    }
  };
};
export const deleteDigiPlanInRedux = (id) => {
  return (dispatch, getState) => {
    let state = getState();
    let digigold_plans = state.product.digigold_plans;
    if (isArrayWithValues(digigold_plans)) {
      let array = [...digigold_plans];
      array = array.filter((i) => `${i.id}` !== `${id}`);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { digigold_plans: array },
      });
    }
  };
};
export const addDigiPlanInRedux = (updateObj) => {
  return (dispatch, getState) => {
    let state = getState();
    let digigold_plans = state.product.digigold_plans;
    if (isArrayWithValues(digigold_plans)) {
      let array = [...digigold_plans];
      array.unshift(updateObj);
      // array = array.map((i) => (i.id == updateObj.id ? updateObj : i));
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { digigold_plans: array },
      });
    }
  };
};

export const getDesignBankProduct = (params) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: { fetchingDesignbankProduct: true },
    });
    let state = getState();
    let suppliers = state.supplier.allSuppliers;
    // let filter = state.products.designbankfilters
    let allProducts = params?.products || [];
    let page = params?.page - 1;
    let filteredArray = [];
    console.log(params);
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        fetchingDesignbankProduct: true,
        designbankfilters: isObjWithValues(params?.filter)
          ? params?.filter
          : {},
        designbankSearch: {
          search: params?.search || "",
          searchBy: params?.searchBy || "search",
        },
      },
    });
    // console.log(allProducts);
    if (params?.id) {
      allProducts = allProducts.filter(
        (obj) => validateNumber(obj?.supplier_id) === validateNumber(params?.id)
      );
      console.log(allProducts, "<< redux products");
    }
    if (!isArrayWithValues(allProducts) && params?.id)
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          designbankSupplierProduct: [],
          fetchingDesignbankProduct: false,
        },
      });
    if (isObjWithValues(params?.filter)) {
      console.log(params?.filter);
      for (const productObj of allProducts) {
        let validFilter = true;
        for (const key in params?.filter) {
          if (Object.keys(productObj).includes(key)) {
            if (!params?.filter?.[key].includes(productObj?.[key]))
              validFilter = false;
          }
        }
        if (validFilter) filteredArray.push(productObj);
      }
    }
    console.log(filteredArray, "filterd");
    let allProductsOrder = isObjWithValues(params?.filter)
      ? filteredArray
      : allProducts;
    if (params?.search) {
      if (params?.searchBy !== "sku")
        allProductsOrder = allProductsOrder.filter((obj) =>
          obj?.name?.toLowerCase()?.includes(params?.search?.toLowerCase())
        );
      else
        allProductsOrder = allProductsOrder.filter((obj) =>
          obj?.sku?.toLowerCase()?.includes(params?.search?.toLowerCase())
        );
    }
    console.log(allProductsOrder);
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        designbankProductsLength: allProductsOrder.length,
      },
    });

    let ids = allProductsOrder.slice(
      page * params?.per_page,
      page * params?.per_page + params?.per_page
    );

    let fetchObj = {};
    for (const iterator of ids) {
      let idArr = fetchObj?.[iterator?.supplier_id] || [];
      fetchObj = {
        ...fetchObj,
        [iterator?.supplier_id]: [...idArr, iterator?.id],
      };
    }
    let fetchProduct = [];

    console.log(fetchObj);
    for await (const supplierId of Object.keys(fetchObj)) {
      let supplier = suppliers.find((obj) => obj?.id === supplierId);
      console.log(fetchObj?.[supplierId]?.join(","));
      console.log(supplier, "< redux");
      if (supplier?.key && supplier?.secret) {
        try {
          let res = await getSupplierProductBatch(
            supplier,
            fetchObj?.[supplierId]
          );

          console.log(res);
          if (isArrayWithValues(res?.data))
            fetchProduct = [
              ...fetchProduct,
              ...res?.data.map((obj) => {
                let changeMetakeys = [
                  "colorstone_from",
                  "diamond_from",
                  "labourType",
                ];
                obj?.meta_data?.map((metaObj) =>
                  changeMetakeys?.includes(metaObj?.key)
                    ? (metaObj.value = "master")
                    : metaObj
                );
                return {
                  ...obj,
                  supplier_id: supplierId,
                  supplier_name: supplier?.name,
                  logo: supplier?.details?.store_logo,
                  store_color: supplier?.details?.store_color,
                };
              }),
            ];
        } catch (error) {
          console.log(getAxiosError(error));
        }
      }
    }
    console.log(fetchProduct);
    if (params?.id) {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          designbankSupplierProduct: fetchProduct,
          fetchingDesignbankProduct: false,
        },
      });
    } else {
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: {
          designbankProducts: fetchProduct,
          fetchingDesignbankProduct: false,
        },
      });
    }
  };
};

export const onSelectDesignBankProduct = (prodcutObj) => {
  return (dispatch, getState) => {
    let state = getState();
    let designBankSelectedProducts =
      state.product.designBankSelectedProducts || [];
    if (designBankSelectedProducts?.find((prod) => prod.id === prodcutObj?.id))
      designBankSelectedProducts = designBankSelectedProducts?.filter(
        (product) => product?.id !== prodcutObj?.id
      );
    else {
      designBankSelectedProducts = [...designBankSelectedProducts, prodcutObj];
      // designBankSelectedProducts.push(prodcutObj);
    }
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        designBankSelectedProducts: designBankSelectedProducts,
      },
    });
  };
};

export const fetchQuotationProducts = ({ quotation }) => {
  return async (dispatch, getState) => {
    let { products } = quotation;
    let state = getState();
    let quotations = state?.product?.quotations;
    if (!isArrayWithValues(products) && !isArrayWithValues(quotations)) {
      return;
    }
    let batchLength = Math.round(products?.length / 100) || 1;
    let allProducts = [];
    let productIds = [...(products || [])];
    // eslint-disable-next-line no-unused-vars
    for await (let _i of Array.from(new Array(batchLength)).fill(1)) {
      let productsFetched = await fetchProducts({
        include: productIds?.splice(0, 100)?.join(),
      });
      if (isArrayWithValues(productsFetched))
        allProducts = allProducts.concat(productsFetched);
    }
    quotation.productDetails = allProducts;
    quotation.productsFetched = true;

    quotations = quotations?.map((i) =>
      `${i?.id}` === `${quotation?.id}` ? quotation : i
    );

    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        quotations: quotations,
      },
    });
  };
};

export const replaceQuotationInRedux = (quotation) => {
  return async (dispatch, getState) => {
    let state = getState();
    let allQuotations = state.product.quotations || [];
    allQuotations = [...allQuotations]?.map((i) =>
      `${i.id}` === `${quotation.id}` ? quotation : i
    );
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        quotations: allQuotations,
      },
    });
  };
};

// export const addToGoogleShop =  () => {
//   return async(dispatch , getState)=>{

//   let state = getState()
//   let website = getWebsite();
//   let token = getToken();
//   if (!website && !token) return false;
//     let googleIntegration = state.settings.googleIntegration
//     let merchantId = getIntegrationObj(googleIntegration, "google_shopping")?.obj?.google_shopping.find(obj => obj?.selected_merchant_id)?.selected_merchant_id
//     console.log(merchantId);

//   // let allowNumber = 0;
//   // let selectedproductLenght = selectedProducts.length;

//   let postObjArray = selectedProducts.map((i, index) => {
//     let obj = reduxAllProducts.find((ob) => ob.id === i);
//     let {
//       name,
//       id,
//       description,
//       permalink,
//       sku,
//       images,
//       price,
//       sale_price,
//       stock_status,
//       meta_data,
//     } = obj;
//     // let allow_merchants = meta_data.find(
//     //   (obj) => obj.key === "allow_for_google_merchants"
//     // );

//     // if (Number(allow_merchants?.value)) {
//     //   allowNumber = allowNumber + 1;
//     // }
//     let imageLink = images.find((obj) => obj?.src);
//     let additionalImageLinks = images.filter((obj, index) => index !== 0);
//     let linkArrImages = additionalImageLinks.map((obj) => obj?.src);
//     let productObj = {
//       title: name,
//       description,
//       link: permalink,
//       offerId: sku,
//       // imageLink: imageLink?.src,
//       channel: "online",
//       price: {
//         currency: reduxProductSettings.default_currency,
//         value: price,
//       },

//       availability:
//         stock_status === "outofstock" ? "out of stock" : "in stock",
//       additionalImageLinks: linkArrImages,
//       contentLanguage: "en",
//       targetCountry: shipToCountries[0],
//     };
//     if (sale_price) {
//       productObj = {
//         ...productObj,
//         salePrice: {
//           currency: reduxProductSettings.default_currency,
//           value: sale_price,
//         },
//       };
//     }
//     if (imageLink?.src) {
//       productObj = {
//         ...productObj,
//         imageLink: imageLink?.src,
//       };
//     }
//     let postObj = {
//       batchId: index + 1,
//       merchantId: merchantId,
//       method: "insert",
//       productId: id,
//       product: productObj,
//     };

//     return postObj;
//   });
//   /// sending products to google shop
//   console.log({
//     entries: postObjArray,
//   });

//   try {
//     let googleRes =  await postIntegrationData({
//       app:"google_shopping" ,
//       endpoint: "/products/batch",
//       data: { entries: postObjArray }
//     })
//     console.log(googleRes);
//   } catch (error) {
//     dispatch(
//       setGlobalToast({
//         show: true,
//         severity: "error",
//         message: "Updating multiple products failed, Try again!",
//       })
//     );
//   }
//   }
// };
