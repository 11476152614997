import { createBotNode } from "./add-nodes/createBotNode";
import { createUserNode } from "./add-nodes/createUserNode";
import { createWhatsappTemplateNode } from "./add-nodes/createWhatsappTemplateNode";
import { createProductCatalogNode } from "./add-nodes/createProductCatalogNode";
import { createQuestionNode } from "./add-nodes/createQuestionNode";
import { createFilterNode } from "./add-nodes/createFilterNode";
import { createGoToStepNode } from "./add-nodes/createGoToStepNode";
import { createLiveAgentNode } from "./add-nodes/createLiveAgentNode";
import { createSubscribeNode } from "./add-nodes/createSubscribeNode";
import { createUnsubscribeNode } from "./add-nodes/createUnsubscribeNode";
import { createEmailNode } from "./add-nodes/createEmailNode";
import { createSmsNode } from "./add-nodes/createSmsNode";
import { createPushNotification } from "./add-nodes/createPushNotification";
import { createDelayNode } from "./add-nodes/createDelayNode";

const nodeCreationFunctions = {
  bot: createBotNode,
  user: createUserNode,
  "whatsapp-template": createWhatsappTemplateNode,
  "product-catalog": createProductCatalogNode,
  question: createQuestionNode,
  filter: createFilterNode,
  "go-to-step": createGoToStepNode,
  "live-agent": createLiveAgentNode,
  subscribe: createSubscribeNode,
  unsubscribe: createUnsubscribeNode,
  email: createEmailNode,
  sms: createSmsNode,
  "push-notification": createPushNotification,
  delay: createDelayNode,
};

export const addNode = ({ dispatch, nodes, currentNode, addNodeType }) => {
  console.log(
    // nodes,
    currentNode
    // addNodeType
  );

  const id = currentNode?.id;

  const createNodeFunction = nodeCreationFunctions[addNodeType];
  if (addNodeType === "bot") {
    createNodeFunction({ dispatch, nodes, nodeId: id });
  } else {
    createNodeFunction(dispatch, nodes, id);
  }
};
