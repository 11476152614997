import { Chip } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { getStatusBackgroundColor, statusFontColor } from "../orders/Orders";

function CustomChip({ label, status, type, sx, ...props }) {
  let theme = useTheme();
  let isDarkTheme = theme.palette && theme.palette.mode === "dark";
  return (
    <Chip
      size="small"
      mr={1}
      mb={1}
      label={label || status}
      // color="warning"
      sx={{
        color: statusFontColor(type),
        border: isDarkTheme ? "0.7px solid" : "0px solid",

        fontWeight: "500",
        // borderColor: statusFontColor(type),
        backgroundColor: isDarkTheme
          ? "transparent"
          : getStatusBackgroundColor(type),
        ...sx,
      }}
      variant="outlined"
      {...props}
    />
  );
}

export default CustomChip;
