import { useState } from "react";
import { catalogBox } from "../styles/catalogBox";
import { Box, TextField, Button } from "@mui/material";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ProductPopover from "../helper/ProductPopover";
import DeleteAndSwap from "../../components/DeleteAndSwap";

const styles = catalogBox;

const CatalogBox = ({
  catalogs,
  catalog,
  handleTextChange,
  selectedCatalog,
  setSelectedCatalog,
  index,
}) => {
  const [openProductPopover, setOpenProductPopover] = useState(null);

  const handleClick = (e) => {
    setOpenProductPopover(e.currentTarget);
  };
  const handleClose = () => {
    setOpenProductPopover(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <DeleteAndSwap setBotData={setSelectedCatalog} index={index} />
      <Box sx={styles.container}>
        <TextField
          multiline
          sx={styles.textField}
          rows={3}
          placeholder={`Enter title for ${catalog?.label}`}
          // value={}
          value={catalog?.title || ""}
          onChange={(e) => handleTextChange(e, catalog)}
        />
        <Button
          variant="standard"
          style={styles.button}
          onClick={handleClick}
          startIcon={<ReplyAllIcon />}
        >
          Select {catalog?.label}
        </Button>
      </Box>
      <ProductPopover
        openProductPopover={openProductPopover}
        setOpenProductPopover={setOpenProductPopover}
        handleClose={handleClose}
        catalogs={catalogs}
        catalog={catalog}
      />
    </Box>
  );
};

export default CatalogBox;
// catalogs[catalog]
