import { useEffect, useState } from "react";
import { Box, TextField, Popover, Typography, Button } from "@mui/material";
import GoToBlockTemplate from "./GoToBlockTemplate";
import { buttonsFieldStyles } from "../styles/buttonsFieldStyles";

const styles = buttonsFieldStyles;

const ButtonsField = ({
  component,
  currentTemplatePreview,
  currentTemplateState,
  setCurrentTemplateState,
  index,
  button,
  buttonIndex,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [goToBlockPopover, setGoToBlockPopover] = useState(null);
  const [block, setBlock] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickGoToBlock = (e) => {
    setGoToBlockPopover(e.currentTarget);
  };

  const handleClosePopover = () => {
    setGoToBlockPopover(null);
  };

  const handleOptionClick = (id, block) => {
    const newBlock = { id, name: block };
    setBlock(newBlock);

    const updatedComponents = [...(currentTemplateState?.components || [])];

    const btns = updatedComponents[index].buttons;
    btns[buttonIndex] = {
      ...btns[buttonIndex],
      go_to_block: newBlock,
    };

    updatedComponents[index] = {
      ...updatedComponents[index],
      buttons: btns,
    };
    setCurrentTemplateState((prevData) => ({
      ...prevData,
      components: updatedComponents,
    }));

    // setBlock((prevBlock) => ({
    //   ...prevBlock,
    //   name: btns[buttonIndex].go_to_block,
    // }));
    handleClosePopover();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (button?.go_to_block) {
      setBlock(button?.go_to_block);
    }
  }, []);

  return (
    <>
      <Button variant="standard" onClick={handleClick} sx={styles.button}>
        {button.text}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box sx={styles.popoverBox}>
          <Typography variant="h6" sx={styles.textField}>
            Go To Block
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Go to block"
            fullWidth
            value={block?.name}
            onClick={handleClickGoToBlock}
          />
          <GoToBlockTemplate
            setBlock={setBlock}
            goToBlockPopover={goToBlockPopover}
            setGoToBlockPopover={setGoToBlockPopover}
            handleOptionClick={handleOptionClick}
            handleClosePopover={handleClosePopover}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ButtonsField;
