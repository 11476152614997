import { useState, useRef } from "react";
import { TextField, Box, IconButton, InputAdornment } from "@mui/material";
import { botTextFieldStyles } from "../styles/botTextFieldStyles";
import PropTypes from "prop-types";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DataObjectIcon from "@mui/icons-material/DataObject";
import TextFieldAttributes from "../helper/TextFieldAttributes";
import EmojiPopover from "../../chatbot/helper/EmojiPopover";
import DeleteAndSwap from "../../components/DeleteAndSwap";

const styles = botTextFieldStyles;

const BotTextField = ({ setBotData, data, index, botData }) => {
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);

  const handleEmojiPicker = (e) => {
    setAnchorEl(inputRef.current);
  };

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  const handleAttributeSelect = (value) => {
    const cursor = inputRef.current.querySelector("textarea").selectionStart;
    const newValue =
      data.response.slice(0, cursor) +
      ` {{${value}}} ` +
      data.response.slice(cursor);

    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === data.id) {
          return { ...field, response: newValue };
        }
        return field;
      });
    });
    setAttributeAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    const emojiText = emoji.native;
    const cursor = inputRef.current.querySelector("textarea").selectionStart;

    const newValue =
      data.response.slice(0, cursor) + emojiText + data.response.slice(cursor);

    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === data.id) {
          return { ...field, response: newValue };
        }
        return field;
      });
    });
    setAnchorEl(false);
  };

  const handleBotResponse = (text, data) => {
    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === data.id) {
          return { ...field, response: text };
        }
        return field;
      });
    });
  };

  return (
    <>
      <Box key={data.id} sx={styles.mainContainer}>
        <DeleteAndSwap setBotData={setBotData} index={index} />
        <Box sx={styles.textFieldBox}>
          <TextField
            ref={inputRef}
            multiline
            sx={styles.textField}
            placeholder="Enter Bot Response"
            value={data.response}
            onChange={(e) => {
              handleBotResponse(e.target.value, data);
            }}
            fullWidth
            inputProps={{
              maxLength: 256,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleAttributePopover}
                  >
                    <DataObjectIcon sx={styles.icon} />
                  </IconButton>
                  <TextFieldAttributes
                    attributeAnchorEl={attributeAnchorEl}
                    setAttributeAnchorEl={setAttributeAnchorEl}
                    handleAttributeSelect={handleAttributeSelect}
                    data={data}
                  />

                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleEmojiPicker}
                  >
                    <InsertEmoticonIcon sx={styles.icon} />
                  </IconButton>
                  <EmojiPopover
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    handleEmojiSelect={handleEmojiSelect}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};

BotTextField.propTypes = {
  botData: PropTypes.object.isRequired,
  setBotData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default BotTextField;
