import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import NodeOptions from "../helper/NodeOptions";
import NodePopover from "../helper/NodePopover";
import AddNodesButton from "../helper/AddNodesButton";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import { unsubscribeStyles } from "../styles/unsubscribeStyles";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = unsubscribeStyles;

const Unsubscribe = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        {isHovered && !isDragging ? (
          <div style={{ paddingLeft: "17px" }}>
            <NodeOptions nodeId={nodeId} nodeType="bot" />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Typography
              variant="span"
              sx={{
                fontSize: "13px",
                alignItems: "center",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#566e8a",
              }}
            >
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>

      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        {isHovered && isDragging && errorMessage !== null ? (
          <Tooltip
            arrow
            title={errorMessage}
            placement="top"
            open={true}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  padding: "10px",
                },
              },
            }}
          >
            <Box sx={styles.nodeBody} onClick={handleMain}>
              <IconButton sx={styles.iconButton}>
                <BookmarkRemoveIcon sx={styles.nodeIcon} />
              </IconButton>
              <Typography variant="span" style={styles.titleName}>
                Unsubscribe
              </Typography>
              <Handle
                type="source"
                position="right"
                style={styles.rightHandle}
              />
            </Box>
          </Tooltip>
        ) : (
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <IconButton sx={styles.iconButton}>
              <BookmarkRemoveIcon sx={styles.nodeIcon} />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              Unsubscribe
            </Typography>
            <Handle type="source" position="right" style={styles.rightHandle} />
          </Box>
        )}

        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
        />
      </Box>
    </Box>
  );
};

export default Unsubscribe;
