import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  backendApiCall,
  backendApiEndpoints,
  formatServerValue,
} from "../../../../helper";
import {
  formatDate,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import {
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomChip from "../../../components/CustomChip";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../helper/utility/Labels";
import KeywordActions from "./components/keyword_actions";
import { NotFound, WebchatSvg, WhatsappSvg } from "../../../../Svgs";
import CustomConfirmationDialog from "../../../../helper/utility/CustomConfirmationDialog";
import {
  deleteFlowInRedux,
  editElementInRedux,
  updateFlowInRedux,
} from "../../../../redux/actions/flowsActions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";

export default function FlowsList() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState("keyword_actions");
  const confirmationRef = React.useRef();
  const [confirmationProps, setConfirmationProps] = React.useState({});
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const keywordActionsRef = React.useRef();

  const flowsList = useSelector((state) => state.flow?.flowsList);
  const flowsListLoading = useSelector((state) => state.flow?.flowsListLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setRows(flowsList || []);
  }, [flowsList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    return;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );
  const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const onOpenConfirmation = () => {
    confirmationRef?.current?.open();
  };
  const onCloseConfirmation = () => {
    confirmationRef?.current?.close();
  };

  const onEditPress = async (row) => {
    if (row?.predefined) {
      let updatedRow = { ...row };
      delete updatedRow?.id;
      delete updatedRow?.predefined;
      delete updatedRow?.created_at;
      delete updatedRow?.updated_at;
      let response = await backendApiCall({
        endpoint: `flow/`,
        method: "POST",
        data: updatedRow,
      });
      if (response?.data?.hasOwnProperty("id")) {
        dispatch(editElementInRedux({ flowsList: [response?.data] }));
        navigate(`/flows/${response?.data?.id}`);
      }
    } else navigate(`/flows/${row?.id}`);
  };

  const onDeletePress = (item) => {
    setConfirmationProps({
      title: `Delete flow ${item?.title}?`,
      subtitle: "This action cannot be undone",
      submitButtonProps: {
        label: "Delete",
        onClick: () => onDelete(item),
        loading: deleteLoading,
        color: "error",
      },
    });
    onOpenConfirmation();
  };

  const onDelete = async (item) => {
    try {
      setDeleteLoading(true);
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.flow}/${item?.id}`,
        method: "DELETE",
      });
      if (res?.success) {
        dispatch(deleteFlowInRedux({ flow_id: item?.id }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Flow has been deleted",
            severity: "success",
          })
        );
        onCloseConfirmation();
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Unable to delete flow",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error, "<<<< delete error");
    } finally {
      setDeleteLoading(false);
    }
  };

  const createKeywordActionPress = () => {
    console.log("createKeywordActionPress");
    keywordActionsRef?.current?.onCreateKeywordActionPress();
  };

  if (!flowsListLoading && !isArrayWithValues(flowsList))
    return (
      <EmptyState
        heading={"No Flows found"}
        icon={<NotFound height={200} width={200} />}
        button={
          <Button
            variant="contained"
            color="primary"
            size={"small"}
            startIcon={<Add />}
            sx={{ ml: 2 }}
            onClick={() => navigate("/flows/create")}
          >
            Create Flow
          </Button>
        }
      />
    );
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          createKeywordActionPress={createKeywordActionPress}
          flowsList={flowsList}
        />
        <FlowsListComponent
          flowsListLoading={flowsListLoading}
          rows={rows}
          handleClick={handleClick}
          onEditPress={onEditPress}
          onDeletePress={onDeletePress}
          emptyRows={emptyRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSelectAllClick={handleSelectAllClick}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          selected={selected}
          isSelected={isSelected}
          dense={dense}
          visibleRows={visibleRows}
        />
        {/* {selectedTab === "flows" ? (
        ) : selectedTab === "keyword_actions" ? (
          <KeywordActions ref={keywordActionsRef} />
        ) : null} */}
        <CustomConfirmationDialog
          ref={confirmationRef}
          {...confirmationProps}
        />
      </Paper>
    </Box>
  );
}

const FlowsListComponent = ({
  flowsListLoading,
  rows,
  handleClick,
  onEditPress,
  emptyRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectAllClick,
  handleRequestSort,
  order,
  orderBy,
  selected,
  isSelected,
  onDeletePress,
  dense,
  visibleRows,
}) => {
  return (
    <Box>
      {flowsListLoading ? (
        <FlowListLoader />
      ) : (
        <>
          <TableContainer>
            <Table
              // sx={{ minWidth: 750 }}P

              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows?.map((row, index) => {
                  return (
                    <FlowRow
                      row={row}
                      onEditPress={onEditPress}
                      onDeletePress={onDeletePress}
                      key={row?.id}
                    />
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

const FlowRow = ({ row, onEditPress, onDeletePress }) => {
  const [statusChecked, setStatusChecked] = React.useState(false);
  const [updatingStatus, setUpdatingStatus] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setStatusChecked(row?.status === "publish");
  }, []);

  const onStatusChange = (value) => {
    setStatusChecked(value);
    onStatusUpdate(value);
  };

  const onStatusUpdate = async (checked) => {
    try {
      setUpdatingStatus(true);
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.flow}/${row?.id}`,
        method: "PUT",
        data: { status: checked ? "publish" : "draft" },
      });
      if (res?.success && isObjWithValues(res?.data)) {
        dispatch(updateFlowInRedux({ flow: res?.data }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status has been updated",
            severity: "success",
          })
        );
      }
    } catch (error) {
    } finally {
      setUpdatingStatus(false);
    }
  };
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      // sx={{ cursor: "pointer" }}
    >
      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
      <TableCell
        component="th"
        scope="row"
        //   padding="none"
        align="left"
        onClick={() => onEditPress(row)}
        sx={{ cursor: "pointer" }}
      >
        <PrimaryLabel>{row.title}</PrimaryLabel>
      </TableCell>

      <TableCell align="right">
        <PrimaryLabelSmall sx={{ fontSize: "17px" }}>
          {validateNumber(row?.meta_data?.flow_trigger_count)}
        </PrimaryLabelSmall>
      </TableCell>
      <TableCell align="right" sx={{ gap: 2 }}>
        {row?.channels?.whatsapp && <WhatsappSvg />}
        {row?.channels?.webchat && <WebchatSvg />}
        {/* <WebchatSvg /> */}
        {/* <CustomChip
                          type="pending"
                          label={formatServerValue(row.type)}
                        >
                        </CustomChip> */}
        {/* {formatServerValue(row.type)} */}
      </TableCell>
      <TableCell align="right">
        <SecondaryLabel>
          {formatDate(new Date(row.created_at), "DD MMM, YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall sx={{ mt: 0.5 }}>
          {formatDate(new Date(row.created_at), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell>
      {/* <TableCell align="right">
        <SecondaryLabel>
          {formatDate(new Date(row.updated_at), "DD MMM, YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall sx={{ mt: 0.5 }}>
          {formatDate(new Date(row.updated_at), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell> */}
      <TableCell align="right">
        <Switch
          disabled={updatingStatus}
          checked={statusChecked}
          onChange={(e) => onStatusChange(e.target.checked)}
        />
      </TableCell>
      <TableCell align="right">
        <FlowRowActions
          onEdit={() => onEditPress(row)}
          onDelete={() => onDeletePress && onDeletePress(row)}
        />
        {/* <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
          <IconButton
            onClick={() => onEditPress(row?.id)}
            size="small"
            // sx={{ backgroundColor: "text.secondary" }}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => onDeletePress && onDeletePress(row)}
          >
            <Delete />
          </IconButton>
        </Stack> */}
      </TableCell>
    </TableRow>
  );
};

function FlowRowActions({ onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditPress = () => {
    onEdit();
    handleClose();
  };
  const onDeletePress = () => {
    onDelete();
    handleClose();
  };

  return (
    <div>
      <IconButton
        // onClick={() => onEditPress(row?.id)}
        size="small"
        // sx={{ backgroundColor: "text.secondary" }}
        onClick={handleClick}
        // onClick={() => onEditPress(row)}
      >
        <MoreVert />
      </IconButton>
      {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onEditPress}>Edit</MenuItem>
        <MenuItem onClick={onDeletePress}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

const FlowListLoader = () => {
  return (
    <Stack spacing={2} direction="column">
      {Array.from(new Array(8)).map((_, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{ padding: "10px", border: "0px solid #e0e0e0" }}
          spacing={3}
        >
          <Skeleton variant="rectangular" width={20} height={20} />
          <Skeleton variant="text" width="25%" height={"35px"} />
          <Skeleton variant="text" width="25%" height={"35px"} />
          <Skeleton variant="text" width="25%" height={"35px"} />
          <Skeleton variant="text" width="25%" height={"35px"} />
          {/* <Skeleton variant="rectangular" width={30} height={30} />
          <Skeleton variant="rectangular" width={30} height={30} /> */}
        </Stack>
      ))}
    </Stack>
  );
};

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Flow title",
  },
  {
    id: "triggers",
    numeric: true,
    disablePadding: false,
    label: "Triggers Count",
  },
  {
    id: "channels",
    numeric: true,
    disablePadding: false,
    label: "Channels",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    selectedTab,
    setSelectedTab,
    flowsList,
    createKeywordActionPress,
  } = props;
  const navigate = useNavigate();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
        justifyContent: "space-between",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        // <Stack
        //   direction={"row"}
        //   alignItems={"center"}
        //   justifyContent={"space-between"}
        //   sx={{ flexWrap: "wrap", rowGap: 1.5, mb: 3, flex: 1 }}
        // >
        //   <Tabs
        //     value={selectedTab}
        //     onChange={(e, newValue) => setSelectedTab(newValue)}
        //     TabIndicatorProps={{ style: { display: "none" } }}
        //     variant="scrollable"
        //     scrollButtons={false}
        //   >
        //     <Tab value={"flows"} label={"Flows"} sx={{ fontSize: 18 }} />
        //     <Tab
        //       value={"keyword_actions"}
        //       label={"Keyword actions"}
        //       sx={{ fontSize: 18 }}
        //     />
        //     {/* <Tab
        //       value={"my_segment"}
        //       label={"Segments"}
        //       sx={{ fontSize: 18 }}
        //     />
        //     <Tab value={"flows"} label={"Flows"} sx={{ fontSize: 18 }} /> */}

        //     {/* <Tab value={"whatsapp"} label={"Whatsapp"}>Whatsapp</Tab> */}
        //   </Tabs>
        // </Stack>
        <Typography
          // sx={{ flex: "1 1 100%" }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Flows List
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        size={"medium"}
        startIcon={<Add />}
        sx={{ ml: 2 }}
        onClick={() => navigate("/flows/create")}
        disabled={flowsList?.length >= 10}
      >
        Create Flow
      </Button>
      {/* {selectedTab === "flows" ? (
      ) : (
        <Button
          variant="contained"
          color="primary"
          size={"medium"}
          startIcon={<Add />}
          sx={{ ml: 2 }}
          onClick={createKeywordActionPress}
        >
          Create Keyword action
        </Button>
      )} */}
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
