import { CheckCircle, Close } from "@material-ui/icons";
import {
  Add,
  AutoStoriesOutlined,
  ConnectingAirportsOutlined,
  Groups,
  LanguageOutlined,
  Share,
  Upload,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  dialCodes,
  dialogStyle,
  formatDate,
  getAdminName,
  getAxiosError,
  getId,
  getRandomInt,
  getRandomString,
  getShortRefLink,
  // getShortRefLink,
  getShortRefLinkSupplier,
  getStoreEmail,
  getStoreId,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  validateEmail,
  validateNumber,
} from "../../helper";
import {
  updateCRMInvite,
  updateCRMStatus,
} from "../../redux/actions/crmActions";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { getProductGroups } from "../../redux/actions/settingActions";
import {
  getSuppliers,
  SUPPLIER_EDIT,
} from "../../redux/actions/supplierActions";
import { commonDialCodes } from "../crm/Add";
import { UnderConstructionComponent } from "../design_bank/DesignBank";
import { FormLabel } from "../settings/Email";

function SupplierConnect() {
  const [connectedSupplier, setConnectedSupplier] = useState([]);
  const [invitedSuppliers, setInvitedSuppliers] = useState([]);
  const [requestedSuppliers, setRequestedSuppliers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [productGroups, setProductGroups] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [sortBy, setSortBy] = useState("accepted");

  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (isObjWithValues(user) && user.store_used)
      setTotalProducts(user.store_used.products);
  }, [user]);

  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );
  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );
  // const reduxSupplier = useSelector((state) => state.supplier.allSuppliers);
  const reduxSupplier = useSelector((state) => state.customers.crmInvites);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!isArrayWithValues(reduxSupplier)) dispatch(getSuppliers());
  // }, []);
  useEffect(() => {
    if (isArrayWithValues(reduxSupplier)) {
      setConnectedSupplier(
        reduxSupplier.filter((obj) => obj?.status === "connected")
      );
      setInvitedSuppliers(
        reduxSupplier.filter((obj) => obj?.status === "invited")
      );
      setRequestedSuppliers(
        reduxSupplier.filter((obj) => obj?.status === "requested")
      );
    }
  }, [reduxSupplier]);

  useEffect(() => {
    if (isArrayWithValues(reduxProductGroups))
      setProductGroups(reduxProductGroups);
  }, [reduxProductGroups]);

  useEffect(() => {
    // if (!isArrayWithValues(retailerInvites)) dispatch(getRetailerInvites());
    if (!isArrayWithValues(reduxProductGroups)) dispatch(getProductGroups());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(retailerInvites)) {
      let temp = async () => {
        let customers = await fetchCustomers({
          include: retailerInvites.map((i) => i.retailer_id_in_supplier),
        });
        if (isArrayWithValues(customers)) {
          let invitedSuppliers = [];
          let connectedSupplier = [];
          let retailerActivityOptions = [];
          for (let customer of customers) {
            let { meta_data, id, first_name, last_name } = customer;
            if (!isArrayWithValues(meta_data)) continue;
            let invite_time = retailerInvites.find(
              (i) => i.retailer_id_in_supplier == id
            );
            invite_time = invite_time ? invite_time.id : "";
            let companyNameObj = meta_data.find(
              (i) => i.key === "company_name"
            );
            let connectStatusObj = meta_data.find(
              (i) => i.key === "connect_status"
            );
            let allProductAccessObj = meta_data.find(
              (i) => i.key === "all_product_access"
            );
            let productGroupsObj = meta_data.find(
              (i) => i.key === "product_groups"
            );

            let company_name,
              connect_status,
              company_name_array = [],
              all_product_access,
              product_groups;
            if (companyNameObj) company_name = companyNameObj.value;
            if (connectStatusObj) connect_status = connectStatusObj.value;
            if (allProductAccessObj)
              all_product_access = allProductAccessObj.value;
            if (productGroupsObj) product_groups = productGroupsObj.value;
            if (typeof company_name === "string")
              company_name_array = company_name.split(" ");
            if (connect_status === "invited")
              invitedSuppliers.push({
                company_name_array,
                connect_status,
                company_name,
                invite_time,
                first_name,
                last_name,
                id,
              });
            if (connect_status === "connected") {
              retailerActivityOptions.push(`retailer_activity_${id}`);
              connectedSupplier.push({
                all_product_access,
                company_name_array,
                connect_status,
                product_groups,
                company_name,
                invite_time,
                first_name,
                last_name,
                id,
              });
            }
          }
          // setInvitedSuppliers(invitedSuppliers);
          if (isArrayWithValues(retailerActivityOptions)) {
            let activities = await fetchRetailerActivity(
              retailerActivityOptions.join()
            );
            if (isObjWithValues(activities)) {
              let object = {};
              for (let id in activities) {
                let obj = connectedSupplier.find((i) => i.id == id);
                obj.activities = activities[id];
                object[id] = obj;
              }
              // setConnectedSupplier(Object.values(object));
            }
          }
        }
      };
      temp();
    }
  }, [retailerInvites]);

  useEffect(() => {
    if (
      isArrayWithValues(productGroups) &&
      isArrayWithValues(connectedSupplier)
    ) {
      let Suppliers = [...connectedSupplier];
      for (let retailer of Suppliers) {
        let { all_product_access, product_groups } = retailer;
        if (all_product_access) retailer.total_product_access = totalProducts;
        if (!all_product_access && isArrayWithValues(product_groups)) {
          let total_product_access = 0;
          for (let group of productGroups) {
            let { id, product_ids } = group;
            if (product_groups.includes(id))
              total_product_access = total_product_access + product_ids.length;
          }
          retailer.total_product_access = total_product_access;
        }
      }
      // setConnectedSupplier(Suppliers);
    }
  }, [connectedSupplier, productGroups]);

  const onStatusChange = (obj, status) => {
    console.log(obj);
    // return;
    dispatch(updateCRMStatus({ obj, status }));
  };

  return (
    <>
      <Box>
        <Stack>
          <Typography variant="h3" gutterBottom display="inline">
            Supplier Connect
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
            <Typography>Connect</Typography>
          </Breadcrumbs>
        </Stack>
        {/* <UnderConstructionComponent /> */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          mt={8}
        >
          <FormControl size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortBy}
              // label="Age"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value={"invited"}>Invite Sent</MenuItem>
              <MenuItem value={"accepted"}>Invite accepted</MenuItem>
              <MenuItem value={"requested"}>Invite requests</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowForm(true)}
          >
            Add supplier
          </Button>
        </Stack>
        <Grid container spacing={4} mt={1}>
          {sortBy === "accepted" &&
            connectedSupplier.map((i) => {
              return (
                <Grid item xs={12} sm={12} lg={6} key={i.id}>
                  <InviteAcceptedCard retailer={i} />
                </Grid>
              );
            })}
          {sortBy === "invited" &&
            invitedSuppliers.map((i) => {
              return (
                <Grid item xs={12} sm={12} lg={6} key={i.id}>
                  <InviteSentCard retailer={i} />
                </Grid>
              );
            })}
          {sortBy === "requested" &&
            requestedSuppliers.map((i) => {
              return (
                <Grid item xs={12} sm={12} lg={6} key={i.id}>
                  <InviteRequestCard
                    retailer={i}
                    onStatusChange={onStatusChange}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Dialog
        open={showForm}
        onClose={() => setShowForm(false)}
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>Add retailer</DialogTitle>
        <InviteForm handleClose={() => setShowForm(false)} />
      </Dialog>
    </>
  );
}

export default SupplierConnect;

export const fetchCustomers = async (params, setState) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (res.data) return res.data;
    } catch (error) {
      console.log(getAxiosError(error), "Fetching customers error");
    }
  }
};

const InviteSentCard = ({ retailer }) => {
  let {
    first_name,
    last_name,
    company_name,
    invite_time,
    company_name_array,
    time,
  } = retailer;
  const [inviteTime, setInviteTime] = useState("");
  useEffect(() => {
    let { uid } = retailer;
    if (uid) {
      let time = uid.split("_")?.[0];
      console.log(
        uid,
        time,
        formatDate(new Date(validateNumber(time)), "dd:mm:yyyy hh:mm am/pm"),
        "<<<"
      );
      setInviteTime(
        formatDate(new Date(validateNumber(time)), "dd:mm:yyyy hh:mm am/pm")
      );
    }
  }, [retailer]);
  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        // boxShadow: "1px 1px 3px 0.1px rgba(158,158,158,0.5)",
        py: 2.4,
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "16px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Stack direction="row" alignItems={"center"}>
              {/* <img
                src={brands[getRandomInt(9)]}
                style={{ height: "40px", width: "80px" }}
              /> */}

              <TempLogo array={company_name_array} />
              <Stack ml={2}>
                <Typography variant="h6">{company_name}</Typography>
                <Typography variant="body2">
                  {first_name || ""} {last_name || ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs item />
          <Grid item sx={{}}>
            <Stack ml={2}>
              <Typography
                variant="body2"
                sx={{ fontSize: "13px", color: "primary" }}
              >
                Status: Invite sent
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "12px", color: "text.secondary" }}
              >
                Date: {inviteTime}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
const InviteRequestCard = ({ retailer, onStatusChange }) => {
  let {
    first_name,
    last_name,
    company_name,
    invite_time,
    company_name_array,
    time,
  } = retailer;
  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        // boxShadow: "1px 1px 3px 0.1px rgba(158,158,158,0.5)",
        py: 2.4,
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "16px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Stack direction="row" alignItems={"center"}>
              <img
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcIY2dRFHyHcCeXakSHPGST7BAt0dmj5_Jzg&usqp=CAU"
                }
                style={{ height: "40px", width: "80px" }}
              />

              {/* <TempLogo array={company_name_array} /> */}
              <Stack ml={2}>
                <Typography variant="h6" sx={{ ...primaryLabel }}>
                  {company_name || "Givanchy"}
                </Typography>
                <Typography variant="body2">
                  {first_name || ""} {last_name || ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs item />
          <Grid item sx={{}}>
            <Stack ml={2} direction="row" alignItems={"center"} spacing={1}>
              {/* <IconButton onClick={() => onStatusChange(retailer, "connected")}>
                <CheckCircle />
              </IconButton> */}
              <LoadingButton
                variant="contained"
                onClick={() => onStatusChange(retailer, "connected")}
              >
                Accept
                {/* <CheckCircle /> */}
              </LoadingButton>
              <IconButton>
                <Close />
              </IconButton>
              {/* <Typography
                variant="body2"
                sx={{ fontSize: "13px", color: "primary" }}
              >
                Status: Invite sent
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "12px", color: "text.secondary" }}
              >
                Date:{" "}
                {time && formatDate(new Date(time), "dd:mm:yyyy hh:mm am/pm")}
              </Typography> */}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const InviteAcceptedCard = ({ retailer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let [totalShares, setTotalShares] = useState(0);
  const [totalRemoveFromWebite, setTotalRemovedFromWebsite] = useState(0);
  let [totalPulled, setTotalPulled] = useState(0);
  let [totalAddedToWebsite, setTotalAddedToWebsite] = useState(0);
  const [totalInCatalogs, setTotalInCatalogs] = useState(0);

  useEffect(() => {
    let { activities } = retailer;
    if (activities) {
      if (isArrayWithValues(activities.pulled))
        setTotalPulled(activities.pulled.length);
      if (isArrayWithValues(activities.added_to_website))
        setTotalAddedToWebsite(activities.added_to_website.length);
      if (isArrayWithValues(activities.removed_from_websites))
        setTotalRemovedFromWebsite(activities.removed_from_websites.length);
      if (isArrayWithValues(activities.catalogs))
        setTotalInCatalogs(activities.catalogs.length);
      if (isArrayWithValues(activities.social_media))
        setTotalShares(activities.social_media.length);
    }
  }, [retailer]);
  let {
    name,
    retailer_name,
    first_name,
    last_name,
    company_name,
    invite_time,
    company_name_array,
    total_product_access,
  } = retailer;
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent sx={{ margin: "20px" }}>
        <Grid container spacing={2}>
          <Grid item>
            <Stack direction="row" alignItems={"center"}>
              {/* <img
                src={brands[getRandomInt(9)]}
                style={{ height: "40px", width: "80px" }}
              /> */}
              {/* <TempLogo array={company_name_array} /> */}
              <Stack ml={0}>
                <Typography variant="h6">
                  {company_name || ""}
                  {isMobile && (
                    <Chip
                      size="small"
                      label="Connected"
                      color="success"
                      sx={{
                        border: isDarkTheme ? "0.7px solid" : "0px",
                        backgroundColor: isDarkTheme
                          ? "transparent"
                          : "#edfaee",
                        ml: 1,
                      }}
                      variant="outlined"
                    />
                  )}
                </Typography>
                <Typography variant="body2">
                  {/* {first_name || ""} {last_name || ""} */}
                  {retailer_name || ""}
                </Typography>
                {isMobile && (
                  <Typography
                    variant="body2"
                    mt={1}
                    sx={{ fontSize: "12px", color: "text.secondary" }}
                  >
                    Date:{" "}
                    {invite_time &&
                      formatDate(
                        new Date(invite_time),
                        "dd:mm:yyyy hh:mm am/pm"
                      )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid xs item />
          {!isMobile && (
            <Grid item sx={{}}>
              <Stack ml={2}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "13px", color: "primary" }}
                >
                  Status: Connected
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", color: "text.secondary" }}
                >
                  Date:{" "}
                  {invite_time &&
                    formatDate(new Date(invite_time), "dd:mm:yyyy hh:mm am/pm")}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid container style={{ marginTop: "14px" }} spacing={3}>
          <Grid item xs={6}>
            <Stack direction="row">
              <Typography>Product Access</Typography>
            </Stack>
            <Divider sx={{ mt: 2, mb: 1.5 }} />
            <Typography fontSize={"25px"}>{total_product_access}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack
              justifyContent={"space-between"}
              alignItems="center"
              direction="row"
            >
              <Typography>
                {isMobile ? "Retailer Inventory" : "Product Added to Inventory"}
              </Typography>
              {/* <Upload /> */}
            </Stack>
            <Divider sx={{ mt: 2, mb: 1.5 }} />
            <Typography fontSize={"25px"}>{totalPulled}</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "14px" }} spacing={3}>
          <Grid item md={4}>
            <Temp
              icon={<LanguageOutlined />}
              title={isMobile || true ? "Website" : "Added In Website"}
              value={totalAddedToWebsite}
            />
          </Grid>
          <Grid item md={4}>
            <Temp
              icon={<Share />}
              title={isMobile || true ? "Shares" : "Social Media Shares"}
              value={totalAddedToWebsite}
            />
          </Grid>
          <Grid item md={4}>
            <Temp
              icon={<AutoStoriesOutlined />}
              title={isMobile || true ? "Catalogs" : "Added in Catalogs"}
              value={totalInCatalogs}
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ mt: 8 }}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent={"space-around"}
          >
            {isMobile && (
              <Grid item xs={12}>
                <ReactApexChart
                  options={getRedialOptions({
                    label: "Label 2",
                    percentage: 15,
                    trackBackground: "#629f2f",
                  })}
                  series={[35]}
                  type="radialBar"
                  height={220}
                  // minWidth={"0%"}
                />
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <ReactApexChart
                options={getRedialOptions({
                  label: "Label 1",
                  percentage: 135,
                  trackBackground: "rgba(89, 102, 147, 1)",
                })}
                series={[35]}
                type="radialBar"
                height={180}
                // minWidth={"0%"}
              />
            </Grid>
            {!isMobile && (
              <Grid item xs={6} md={4}>
                <ReactApexChart
                  options={getRedialOptions({
                    label: "Label 2",
                    percentage: 15,
                    trackBackground: "#629f2f",
                  })}
                  series={[35]}
                  type="radialBar"
                  height={210}
                  // minWidth={"0%"}
                />
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <ReactApexChart
                options={getRedialOptions({
                  label: "Label 3",
                  percentage: 215,
                  trackBackground: "rgba(89, 102, 147, 1)",
                })}
                series={[65]}
                type="radialBar"
                height={180}
                // minWidth={"0%"}
              />
            </Grid>
          </Grid>
        </Box> */}
        {/* <Stack direction="row"></Stack> */}
      </CardContent>
    </Card>
  );
};

const Temp = ({ icon, title, value }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      // justifyContent={"center"}
      justifyContent={"space-between"}
      sx={{
        border: "0.4px solid",
        padding: "5px 8px",
        borderRadius: "7px",
        paddingRight: "18px",
      }}
    >
      <Box sx={{ ml: 3 }}>
        <Typography fontSize={"20px"}>{value}</Typography>
        <Typography fontSize={"11px"} color="text.secondary">
          {title}
        </Typography>
      </Box>
      {icon}
    </Stack>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{ borderRadius: "10px" }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" component="div" color="text.secondary">
          {`${Math.round(props.label)}`}
        </Typography>
      </Box>
    </Box>
  );
}

const getRedialOptions = ({ label, percentage, trackBackground }) => {
  return {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
      width: "100%",
      animations: { enabled: false },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#ededed",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: Boolean(label),
            color: "#888",
            fontSize: "14px",
          },
          value: {
            formatter: (val) => percentage || 0,
            offsetY: 10,
            color: "#111",
            fontSize: "28px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: [`${trackBackground || "#21AC28"}`],
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#21AC28"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [label],
  };
};

const TempLogo = ({ array }) => {
  array = isArrayWithValues(array)
    ? array.length < 2
      ? ["Company", "Logo"]
      : array
    : ["Company", "Logo"];
  return (
    <Box
      sx={{
        textAlign: "center",
        // backgroundColor: "#dce8fa",
        py: 1.4,
        px: 2,
        borderRadius: "5px",
        minWidth: "70px",
      }}
    >
      <Typography
        fontSize={"13px"}
        fontWeight="600"
        sx={{
          transform: "scale(1,1.2)",
          color: "primary",
          fontFamily: "Monaco",
        }}
      >
        {array[0] || ""}
      </Typography>
      <Typography sx={{ mt: 0, color: "secondary" }} fontSize={"12px"}>
        {array[1] || ""}
      </Typography>
    </Box>
  );
};

const fetchRetailerActivity = async (options) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings`,
        params: { options },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (res.data.success && isObjWithValues(res.data.data)) {
        let obj = {};
        for (let i in res.data.data) {
          let id = i.replace("retailer_activity_", "");
          obj[id] = res.data.data[i];
        }
        return obj;
      } else return {};
    } catch (error) {
      console.log(getAxiosError(error));
      return {};
    }
  }
};

const InviteForm = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    whatsapp: "",
    whatsapp_dial: "91",
  });
  const [hasError, setHasError] = useState({});
  const [loading, setLoading] = useState(false);
  const store_id = useSelector((state) => state.user.store_id);
  const dispatch = useDispatch();
  async function sendInvitationSupplier(id) {
    try {
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/store/v1/invite`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { suppliers: id },
        method: "POST",
      });

      return res.data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
  async function addSupplierInvite(obj) {
    try {
      let website = await getWebsite();
      const token = await getToken();
      if (website && token) {
        let res = await axios({
          url: `${website}/wp-json/store/v1/supplier/connect`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: obj,
          method: "POST",
        });
        // console.log(res.data, 'supplier invite response ');
        return res.data;
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
  // async function onSubmit() {
  //   // let { fetchAllSuppliers } = this.props;
  //   setLoading(true);
  //   let { first_name, last_name, email } = formData;
  //   if (!first_name) {
  //     setHasError({
  //       name: "first_name",
  //       text: "Please Enter first name",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!last_name) {
  //     setHasError({
  //       name: "last_name",
  //       text: "Please Enter last name",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!email) {
  //     setHasError({
  //       name: "email",
  //       text: "Please Enter email",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!validateEmail(email)) {
  //     setHasError({
  //       name: "email",
  //       text: "Please Enter valid email",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   // this.setState({ loading: true });
  //   let supplier_obj = { ...formData };
  //   // delete supplier_obj.loading;
  //   let user_id = getId();
  //   let userEmail = getStoreEmail();
  //   let userName = getAdminName();
  //   let retailer_obj = {
  //     email: userEmail,
  //   };
  //   if (userName) {
  //     let names = userName.split(" ");
  //     if (isArrayWithValues(names) && names.length > 1) {
  //       retailer_obj.first_name = names[0];
  //       retailer_obj.last_name = names[names.length - 1];
  //     } else if (isArrayWithValues(names)) retailer_obj.first_name = names[0];
  //   }
  //   // console.log(retailer_obj);
  //   // return;

  //   // console.log(user_id, userEmail, userName);

  //   let uid = getRandomString(14);
  //   let supplier_uid = getRandomString(14);
  //   let refObj = {
  //     by: user_id,
  //     type: "retailer",
  //     uid,
  //     supplierUniqueID: supplier_uid,
  //     retailer_obj,
  //   };
  //   // await sleep(1000);
  //   let refLink = await getShortRefLinkSupplier(refObj);
  //   console.log(refLink);
  //   if (!refLink) {
  //     setLoading(false);
  //     setHasError({
  //       name: "link",
  //       text: "Can't able to generate referral link ! try it later",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   let supplier_connect_obj = {
  //     status: "invited",
  //     referral_link: refLink,
  //     retailerUniqueID: uid,
  //     supplierUniqueID: supplier_uid,
  //     info: {
  //       ...supplier_obj,
  //     },
  //   };
  //   console.log(JSON.stringify(supplier_connect_obj, null, 2));
  //   return;
  //   let addInviteData = await addSupplierInvite(supplier_connect_obj);
  //   let inviteData = await sendInvitationSupplier(supplier_uid);
  //   // await fetchAllSuppliers();
  //   dispatch(
  //     setGlobalToast({
  //       show: true,
  //       message: "Supplier has been invited successfully",
  //       severity: "success",
  //     })
  //   );
  //   handleClose();
  //   // this.setState({ loading: false }, () => {
  //   //     Toast.show({
  //   //         type: 'success',
  //   //         text1: 'Success',
  //   //         text2: 'Supplier has been invited successfully',
  //   //     });
  //   //     this.props.navigation.navigate('SupplierInvite');
  //   // });

  //   console.log(addInviteData, "ADd supplier invite");
  //   if (addInviteData?.success && isArrayWithValues(addInviteData.data)) {
  //     dispatch({
  //       type: SUPPLIER_EDIT,
  //       payload: {
  //         // fetchingSuppliers: false,
  //         allSuppliers: addInviteData.data,
  //       },
  //     });
  //   }
  //   console.log(inviteData, "supplier invite res");
  //   // if(isArrayWithValues())
  //   setLoading(false);
  // }
  async function onSubmit() {
    // let { fetchAllSuppliers } = this.props;
    setLoading(true);
    let { first_name, last_name, email } = formData;
    if (!first_name) {
      setHasError({
        name: "first_name",
        text: "Please Enter first name",
      });
      setLoading(false);
      return;
    }
    if (!last_name) {
      setHasError({
        name: "last_name",
        text: "Please Enter last name",
      });
      setLoading(false);
      return;
    }
    if (!email) {
      setHasError({
        name: "email",
        text: "Please Enter email",
      });
      setLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      setHasError({
        name: "email",
        text: "Please Enter valid email",
      });
      setLoading(false);
      return;
    }
    // this.setState({ loading: true });
    // delete supplier_obj.loading;
    let user_id = getId();
    let userEmail = getStoreEmail();
    let userName = getAdminName();
    let refObj = {
      email: userEmail,
    };
    let company_name = getStoreName();
    if (userName) {
      let names = userName.split(" ");
      if (isArrayWithValues(names) && names.length > 1) {
        refObj.first_name = names[0];
        refObj.last_name = names[names.length - 1];
      } else if (isArrayWithValues(names)) refObj.first_name = names[0];
    }
    let id = `${new Date().getTime()}_${getRandomString(6)}`;
    let formId = id;

    // console.log(retailer_obj);
    // return;

    // console.log(user_id, userEmail, userName);

    let supplier_uid = getRandomString(14);
    refObj = {
      ...refObj,
      uid: formId,
      url: getWebsite(),
      company_name: getStoreName(),

      // ...formData,
    };
    // await sleep(1000);
    let refLink = await getShortRefLink(refObj);
    console.log(refLink);
    if (!refLink) {
      setLoading(false);
      setHasError({
        name: "link",
        text: "Can't able to generate referral link! try it later",
      });
      setLoading(false);
      return;
    }
    let retailer_obj = {
      status: "invited",
      referral_link: refLink,
      uid: formId,
      ...formData,
    };
    // console.log(JSON.stringify(retailer_obj, null, 2));
    console.log(refObj, retailer_obj);
    // return;
    dispatch(
      updateCRMInvite({
        obj: retailer_obj,
        setLoading,
        onSuccess: () => {
          handleClose();
        },
      })
    );
  }
  const onEditForm = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  return (
    <>
      <DialogContent>
        <Box>
          <Grid container spacing={3} rowSpacing={3}>
            <Grid item xs={6}>
              <FormLabel>First Name</FormLabel>
              <TextField
                fullWidth
                placeholder="First Name"
                value={formData?.first_name || ""}
                onChange={(e) => {
                  onEditForm({ first_name: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "first_name"}
              />
              {hasError?.name === "first_name" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Last Name</FormLabel>
              <TextField
                fullWidth
                placeholder="last Name"
                value={formData?.last_name || ""}
                onChange={(e) => {
                  onEditForm({ last_name: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "last_name"}
              />
              {hasError?.name === "last_name" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Company Name</FormLabel>
              <TextField
                fullWidth
                placeholder="Company name"
                value={formData?.company_name || ""}
                onChange={(e) => {
                  onEditForm({ company_name: e.target.value });
                }}
                // error={hasError?.name === "last_name"}
              />
              {/* {hasError?.name === "last_name" && <FormHelperText>{hasError?.text}</FormHelperText>} */}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Email</FormLabel>
              <TextField
                fullWidth
                placeholder="Email"
                value={formData?.email || ""}
                onChange={(e) => {
                  onEditForm({ email: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "email"}
              />
              {hasError?.name === "email" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Whatsapp</FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Select
                    // size="small"
                    fullWidth
                    value={formData.whatsapp_dial}
                    onChange={(e) =>
                      onEditForm({ whatsapp_dial: e.target.value?.value })
                    }
                    renderValue={(value) => `${formData.whatsapp_dial}`}
                  >
                    <ListSubheader>Common Dial Codes</ListSubheader>
                    {commonDialCodes.map((i) => {
                      return (
                        <MenuItem key={i.value} value={i}>
                          {i.label}
                        </MenuItem>
                      );
                    })}
                    <ListSubheader>Dial Codes</ListSubheader>
                    {dialCodes.map((i) => (
                      <MenuItem value={i}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs>
                  <TextField
                    value={formData?.whatsapp || ""}
                    fullWidth
                    placeholder="Whatsapp"
                    onChange={(e) => onEditForm({ whatsapp: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>

            {hasError?.name === "link" && (
              <Typography sx={{ color: "#FF0000", mt: 2 }}>
                {hasError?.text}
              </Typography>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onSubmit}>
          Add
        </LoadingButton>
      </DialogActions>
    </>
  );
};
