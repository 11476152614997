import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, TextField, Popover, Typography } from "@mui/material";
import AttributePopover from "./AttributesPopover";

function countPlaceholders(text) {
  const placeholderRegex = /\{\{(\d+)\}\}/g;
  const matches = text.match(placeholderRegex);
  return matches ? matches.length : 0;
}

const BodyField = ({
  currentTemplateState,
  setCurrentTemplateState,
  component,
  index,
}) => {
  const globalAttributes = useSelector((state) => state.flow?.globalAttributes);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const options = globalAttributes;
  const [optionSelected, setOptionSelected] = useState({});
  const [currentText1, setCurrentText1] = useState(component.text);
  const [attributesCount, setAttributesCount] = useState(0);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState({
    id: null,
    openAnchor: null,
  });

  const handleAttributePopover = (e, index) => {
    setAttributeAnchorEl({
      id: index,
      openAnchor: e.currentTarget,
    });
  };

  function replaceAtIndex(currentText, index, value) {
    let regex = /\{\{[^}]+\}\}/g;
    let matches = currentText.match(regex);

    if (matches && index < matches.length) {
      let matchIndex = currentText.indexOf(matches[index]);
      currentText =
        currentText.substring(0, matchIndex) +
        `{{${value}}}` +
        currentText.substring(matchIndex + matches[index].length);
    }
    return currentText;
  }

  const handleAttributesOptions = (idx, value) => {
    setOptionSelected((prev) => {
      const updatedOptions = {
        ...prev,
        [idx]: value,
      };
      const updatedText = replaceAtIndex(currentText1, idx, value);
      setCurrentText1(updatedText);
      const updatedComponents = [...currentTemplateState.components];
      updatedComponents[index] = {
        ...updatedComponents[index],
        selected_attributes: optionSelected,
        updated_text: updatedText,
      };

      setCurrentTemplateState((prevData) => ({
        ...prevData,
        components: updatedComponents,
      }));
      return updatedOptions;
    });
    setAttributeAnchorEl(null);
  };

  const handleClick = (event) => {
    if (!options) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentTemplateState?.components[index]?.selected_attributes) {
      setOptionSelected(
        currentTemplateState?.components[index]?.selected_attributes
      );
      setCurrentText1(currentTemplateState?.components[index]?.updated_text);
    }
  }, [currentTemplateState?.components[index]?.selected_attributes]);

  useEffect(() => {
    setCurrentText1(component.text);
    const count = countPlaceholders(component.text);
    setAttributesCount(count);
  }, [component.text]);

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{ padding: "10px", borderBottom: "1px solid black" }}
      >
        <Box>
          <Typography variant="span" style={{ whiteSpace: "pre-wrap" }}>
            {currentText1}
          </Typography>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box sx={{ height: "200px", width: "300px", padding: "15px" }}>
          <Typography
            variant="span"
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "20px",
              color: "#22272d",
            }}
          >
            Select attributes
          </Typography>
          {Array.from({ length: attributesCount }).map((_, index) => (
            <TextField
              key={index}
              sx={{ marginTop: "10px" }}
              placeholder={`Select value for {{${index + 1}}}`}
              value={optionSelected[index]}
              onClick={(e) => handleAttributePopover(e, index)}
            />
          ))}
          <AttributePopover
            handleAttributesOptions={handleAttributesOptions}
            attributeAnchorEl={attributeAnchorEl}
            setAttributeAnchorEl={setAttributeAnchorEl}
            optionSelected={optionSelected}
          />
        </Box>
      </Popover>
    </>
  );
};

export default BodyField;
