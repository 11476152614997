export const productPopoverStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
    // width: "150px",
    padding: "20px 70px 20px 15px",
  },
  productsLabel: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#fafbfc",
    },
  },
};
