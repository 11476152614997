import { useSelector } from "react-redux";
import { Popover, Box, Typography, IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TelegramIcon from "@mui/icons-material/Telegram";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { goToBlockPopover } from "../../styles/goToBlockPopover";

const styles = goToBlockPopover;

const GoToBlockTemplate = ({
  goToBlockPopover,
  handleOptionClick,
  handleClosePopover,
}) => {
  const open = Boolean(goToBlockPopover);
  const id = open ? "simple-popover" : undefined;
  const nodes = useSelector((state) => state.flow?.nodes);

  const defaultTitles = {
    start: {
      name: "Start",
      number: 0,
      icon: HomeIcon,
    },
    bot: {
      name: "Bot",
      number: 0,
      icon: TelegramIcon,
    },
    user: {
      name: "User",
      number: 0,
      icon: PersonIcon,
    },
    question: {
      name: "Question",
      number: 0,
      icon: QuestionMarkIcon,
    },
    filter: {
      name: "Filter",
      number: 0,
      icon: FilterAltOutlinedIcon,
    },
    fallback: {
      name: "Default fallback",
      number: 0,
      icon: ReplyAllIcon,
    },
    liveAgent: {
      name: "Live Agent",
      number: 0,
      icon: SupportAgentIcon,
    },
  };
  return (
    <Popover
      sx={{ marginTop: "4px" }}
      id={id}
      open={open}
      anchorEl={goToBlockPopover}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={styles.outerBox}>
        {nodes.length > 0 &&
          nodes.map((node) => {
            const typeInfo = defaultTitles[node.type];
            if (!typeInfo) {
              return;
            }
            const title =
              node.title ||
              node.fieldData.title ||
              defaultTitles[node.type].name +
                " " +
                defaultTitles[node.type].number;
            const IconComponent = defaultTitles[node.type]?.icon;
            defaultTitles[node.type].number =
              defaultTitles[node.type].number + 1;
            return (
              <Box
                sx={styles.innerBox}
                key={node.id}
                onClick={() => handleOptionClick(node.id, title)}
              >
                <IconButton
                  sx={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#dcdcdc",
                    marginRight: "24px",
                  }}
                >
                  <IconComponent sx={{ fontSize: "15px" }} />
                </IconButton>
                <Typography variant="span" sx={styles.blockName}>
                  {title + " " + defaultTitles[node.type].number}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Popover>
  );
};

export default GoToBlockTemplate;
