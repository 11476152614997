import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Label } from "../../../customers/components/Info";
import {
  apiCallFromSocket,
  dialogStyle,
  formatServerValue,
  getInvalidKeys,
  isObjWithValues,
  settingsEndpoint,
} from "../../../../helper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import { UnicommerceSvg } from "../../../../Svgs";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";

const fields = [
  {
    label: "Tenant",
    valueKey: "tenant",
    placeholder: "Enter your Tenant Name",
  },
  {
    label: "Username",
    valueKey: "username",
    placeholder: "Enter your  Username",
  },
  {
    label: "Password",
    valueKey: "password",
    placeholder: "Enter your Password",
    eye: true,
  },
];

let UNICOMMERCE_CLIENT_ID = "my-trusted-client";
function Integration() {
  const [formState, setFormState] = useState({});
  const [error, setError] = useState({});
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [revoking, setRevoking] = useState(false);
  const [hide, setHide] = useState({ password: true });
  const unicommerce_integration = useSelector(
    (state) => state.settings?.unicommerce_integration
  );
  const closeRevoke = () => {
    setRevokeOpen(false);
  };
  const dispatch = useDispatch();
  const onEditForm = (payload) => {
    setError({});
    setFormState((pre) => ({ ...pre, ...payload }));
  };
  const onRevoke = async () => {
    setRevoking(true);
    try {
      let res = await settingsEndpoint({
        endpoint: "unicommerce_integration",
        method: "DELETE",
        params: { force_delete: 1 },
      });
      console.log(res);
      // if (res?.success) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Revoked successfully!",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          unicommerce_integration: {},
        },
      });
      closeRevoke();
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setRevoking(false);
    }
  };
  const onSubmit = async () => {
    let errorObject = {};
    errorObject = getInvalidKeys(
      formState,
      fields?.map((o) => o?.valueKey)
    );
    setError(errorObject);
    if (isObjWithValues(errorObject)) return;
    // ?grant_type=password&client_id=my-trusted-client&username=abc@xyz.com&password=uni@1234
    setLoading(true);
    try {
      let { data: axiosData } = await apiCallFromSocket({
        url: `https://${formState?.tenant}.unicommerce.com/oauth/token`,
        params: {
          grant_type: "password",
          client_id: UNICOMMERCE_CLIENT_ID,
          ...formState,
        },
      });

      let data = axiosData?.data;
      console.log(data);
      if (data?.access_token) {
        let res = await settingsEndpoint({
          data: {
            ...data,
            integrated_time: Date.now(),
          },
          endpoint: `unicommerce_integration`,
          method: "POST",
        });
        if (res) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              unicommerce_integration: res,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: "Integrate successfully!",
              severity: "success",
            })
          );
        }
        console.log(res);
        setOpenForm(false);
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Can't Integrate",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Can't Integrate",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onClose = useCallback(() => {
    setOpenForm(false);
    setFormState({});
  }, []);

  return (
    <Box sx={{ px: 2 }}>
      <Box sx={{ px: { xs: 0, md: 3 } }}>
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            mt={6}
            sx={{ gap: "30px" }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <UnicommerceSvg height={25} width={25} />
                Unicommerce
              </Typography>
            </Box>
            <LoadingButton
              // loading={fetching}
              size="small"
              onClick={() =>
                isObjWithValues(unicommerce_integration)
                  ? setRevokeOpen(true)
                  : setOpenForm(true)
              }
              // onClick={() => (integrated ? openRevoke() : openForm())}
              variant={"contained"}
              color={
                isObjWithValues(unicommerce_integration) ? "error" : "primary"
              }
            >
              {isObjWithValues(unicommerce_integration)
                ? "Revoke"
                : "Integrate"}
            </LoadingButton>

            {/* {integratedApp.includes(obj.value) && (
            <LoadingButton
              loading={revoking}
              variant="outlined"
              color="error"
              size="small"
              onClick={() => onRevokeGoogle(obj.value)}
            >
              Revoke
            </LoadingButton>
          )} */}
          </Stack>
          {isObjWithValues(unicommerce_integration) && (
            <Typography
              variant="h6"
              sx={{ mt: 1, fontSize: "13px", fontWeight: "bold" }}
            >
              {/* Connected Account: {connectedAccount.ip_port} (
              {connectedAccount.username}) */}
            </Typography>
          )}
          <Typography
            sx={{
              mt: 4,
              color: "text.secondary",
              fontSize: "13px",
              maxWidth: "800px",
            }}
          >
            It is our keen interest to simplify integration of various
            E-commerce enabling solutions with our proprietary order and
            warehouse management SaaS software.
          </Typography>
        </>
      </Box>
      <Dialog
        open={revokeOpen}
        onClose={closeRevoke}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle variant="h5" id="alert-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            Revoke Unicommerce Integration?
            <IconButton onClick={closeRevoke}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRevoke}>Cancel</Button>
          <LoadingButton
            onClick={onRevoke}
            variant="contained"
            loading={revoking}
            color="error"
          >
            Revoke
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {openForm && (
        <Dialog open={openForm} onClose={onClose} maxWidth={"xs"} fullWidth>
          <DialogTitle>Unicommerce</DialogTitle>
          <DialogContent>
            {/* <Grid container>
              <Grid item xs={12} lg={4}> */}
            {fields?.map((field) => {
              let { label, valueKey, placeholder, eye } = field;
              return (
                <Box sx={{ py: 2 }}>
                  <Label>{label}</Label>
                  <TextField
                    value={formState?.[valueKey] || ""}
                    onChange={(e) => {
                      onEditForm({ [valueKey]: e.target.value });
                    }}
                    error={error?.[valueKey]}
                    size="small"
                    fullWidth
                    placeholder={placeholder}
                    helperText={
                      error?.[valueKey] &&
                      `${formatServerValue(valueKey)} is Required`
                    }
                    type={hide?.[valueKey] ? "password" : "text"}
                    InputProps={
                      eye
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setHide((pre) => ({
                                      ...pre,
                                      [valueKey]: !pre?.[valueKey],
                                    }));
                                  }}
                                >
                                  {hide?.[valueKey] ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : null
                    }
                  />
                </Box>
              );
            })}
            {/* <Stack direction={"row"} justifyContent={"flex-end"}></Stack>
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={onSubmit}
            >
              Integrate
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default Integration;
