import * as actions from "../../../../../../redux/actions/flowsActions";

export const onAddQuestion = async (dispatch, axis, id, currNode) => {
  let yAxis = axis.y;
  if (currNode == "user") {
    yAxis = yAxis + 7;
  }

  const questionId = Date.now().toString();
  const questionNode = {
    id: questionId,
    type: "question",
    data: { label: "Question" },
    position: {
      x: axis.x + 180,
      y: yAxis,
    },
    allowJoinSide: "left",
    toJoinSide: "right",
    fieldData: [],
    userConnected: 0,
    isUser: false,
  };

  const questionEdge = {
    id: questionId,
    source: id,
    target: questionNode.id,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
  };

  const successId = Date.now().toString() + "1";
  const successNode = {
    id: successId,
    type: "success",
    data: { label: "Success" },
    position: {
      x: questionNode.position.x + 180,
      y: questionNode.position.y - 55,
    },
    allowJoinSide: "left",
    toJoinSide: "right",
    fieldData: [],
    userConnected: 0,
    isUser: false,
  };

  const successEdge = {
    id: successId,
    source: questionNode.id,
    target: successId,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
  };

  const failureId = Date.now().toString() + "2";
  const failureNode = {
    id: failureId,
    type: "failure",
    data: { label: "Failure" },
    position: {
      x: questionNode.position.x + 180,
      y: questionNode.position.y + 55,
    },
    allowJoinSide: "left",
    toJoinSide: "right",
    fieldData: [],
    userConnected: 0,
    isUser: false,
  };

  const failureEdge = {
    id: failureId,
    source: questionNode.id,
    target: failureId,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
  };

  await dispatch({
    type: actions.CURRENT_NODE_ID,
    payload: { nodeId: questionNode.id },
  });

  async function addNode(node, edge) {
    dispatch({
      type: actions.ADD_NODE,
      payload: { newNode: node, nodeId: node.id },
    });
    dispatch({ type: actions.ADD_EDGE, payload: edge });

    if (node.type == "question") {
      await dispatch({
        type: actions.CURRENT_NODE_ID,
        payload: { nodeId: node.id },
      });
      dispatch({ type: actions.SIDEBAR_QUESTION, payload: true });
    }
  }

  addNode(questionNode, questionEdge);
  addNode(successNode, successEdge);
  addNode(failureNode, failureEdge);
};
