import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import BounceLoader from "react-spinners/BounceLoader";
import { botStyles } from "../styles/botStyles";
import * as actions from "../../../../../../redux/actions/flowsActions";

const styles = botStyles;

const AddNodesButton = ({ handleClick, isHovered, nodeId }) => {
  const isDragging = useSelector((state) => state.flow?.isDragging);

  return (
    <div
      className="addButton"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <AddIcon sx={styles.addIcon} onClick={handleClick} />
      <div
        style={{
          position: "relative",
          bottom: "13px",
          right: "50px",
          visibility: isHovered && isDragging ? "visible" : "hidden",
        }}
      >
        <BounceLoader color="#3185FC" speedMultiplier="0.8" size="80px" />
      </div>
    </div>
  );
};

export default AddNodesButton;
