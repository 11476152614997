import * as actions from "../../../../../../redux/actions/flowsActions";

export const LiveAgentNode = async (dispatch, axis, id) => {
  const newNodeId = Date.now().toString();

  const newNode = {
    id: newNodeId,
    type: "live-agent",
    data: { label: "Live agent" },
    position: {
      x: axis.x + 180,
      y: axis.y,
    },
    fieldData: {
      title: "Live Agent",
      fieldsData: [
        {
          id: "01",
          type: "text",
          response: "No agents available now.",
          isUser: false,
        },
        {
          id: "02",
          type: "text",
          response: "Please enter your phone number",
          isUser: false,
        },
      ],
    },
  };

  const edge = {
    id: Date.now(),
    source: id,
    target: newNodeId,
    style: {
      strokeWidth: 2.2,
      stroke: "#566e8a",
    },
  };

  await dispatch({
    type: actions.CURRENT_NODE_ID,
    payload: { nodeId: newNodeId },
  });
  dispatch({ type: actions.ADD_NODE, payload: { newNode, nodeId: id } });
  dispatch({ type: actions.ADD_EDGE, payload: edge });
};
