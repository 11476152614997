import thunk from "redux-thunk";
import productReducers from "./reducers/productReducers";
import counterReducer from "./slices/counter";
import crmReducers from "./reducers/crmReducers";
import userReducers from "./reducers/userReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import settingReducers from "./reducers/settingReducers";
import visitorReducers from "./reducers/visitorReducers";
import socketReducers from "./reducers/socketReducers";
import { default as _persistReducer } from "./reducers/persistReducer";
import hubReducer from "./reducers/hubReducer";
import chats from "./reducers/chatReducers";
import orderReducer from "./reducers/orderReducer";
import pagesReducer from "./reducers/pagesReducers";
import helperReducer from "./reducers/helperReducer";
import supplierReducers from "./reducers/supplierReducers";
import datasheetReducer from "./reducers/datasheetReducer";
import campaignReducers from "./reducers/campaignReducers";
import flowReducer from "./reducers/flowReducer";
// import persistdata from "./reducer/persistReducer";

const appReducer = combineReducers({
  counter: counterReducer,
  product: productReducers,
  customers: crmReducers,
  settings: settingReducers,
  user: userReducers,
  visitor: visitorReducers,
  socket: socketReducers,
  persist: _persistReducer,
  hub: hubReducer,
  chats: chats,
  helper: helperReducer,
  orders: orderReducer,
  pages: pagesReducer,
  supplier: supplierReducers,
  campaign: campaignReducers,
  datasheet: datasheetReducer,
  flow: flowReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  // whitelist: isDev() ? ["product", "persist", "crm", "customers"] : ["persist"],
  whitelist: ["persist", "user", "datasheet"],
  // whitelist: ["product", "crm"],
  // blacklist: ["product", "visitor", "crm"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export let store = createStore(persistedReducer, applyMiddleware(thunk));
export let persistor = persistStore(store);

// export const store = configureStore(
//   {
//     reducer: {
//       counter: counterReducer,
//       product: productReducers,
//       crm: crmReducers,
//     },
//   },
//   null,
//   [thunk]
// );
