import { useSelector } from "react-redux";
import { Popover, Box, Typography } from "@mui/material";
import { attributePopoverStyles } from "../styles/attributePopoverStyles";
import { preDefinedAttributesTextField } from "../utils/preDefinedAttribuesTextField";

const styles = attributePopoverStyles;

const TextFieldAttributes = ({
  attributeAnchorEl,
  setAttributeAnchorEl,
  handleAttributeSelect,
  data,
}) => {
  const open = Boolean(attributeAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const globalAttributes = useSelector(
    (state) => state?.flow?.globalAttributes
  );

  const handleClose = () => {
    setAttributeAnchorEl(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={attributeAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box
          sx={{
            ...styles.innerBox,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <Typography variant="h6" sx={styles.mainTitle}>
            PRE-DEFINED ATTRIBUTES
          </Typography>
        </Box>
        {preDefinedAttributesTextField.length > 0 &&
          preDefinedAttributesTextField.map((attribute, index) => (
            <Box
              key={index}
              sx={styles.innerBox}
              onClick={() => handleAttributeSelect(attribute, data)}
            >
              <Typography variant="span" sx={styles.innerBoxTitle}>
                {attribute}
              </Typography>
            </Box>
          ))}
        {globalAttributes.length > 0 && (
          <Typography
            variant="h6"
            sx={{ ...styles.mainTitle, marginTop: "10px", marginBottom: "6px" }}
          >
            GLOBAL ATTRIBUTES
          </Typography>
        )}
        {globalAttributes.length > 0 &&
          globalAttributes.map((attribute, index) => (
            <Box
              key={index}
              sx={styles.innerBox}
              onClick={() => handleAttributeSelect(attribute, data)}
            >
              <Typography variant="span" sx={styles.innerBoxTitle}>
                {attribute}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};

export default TextFieldAttributes;
