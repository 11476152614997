import {
  FileUploadOutlined,
  FilterListOutlined,
  FormatListBulletedOutlined,
  GridViewOutlined,
  Search,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatServerValue, isArrayWithValues, TabPanel } from "../../helper";
import { getMediaLibrary } from "../../redux/actions/productActions";
import ImageTable from "./ImageTable";
import Thumbnail from "./Thumbnail";
import UploadPage from "./UploadPage";
import ImageGrid from "./ImageGrid";
import { MediaLibraryContext } from "../NewMediaLibrary";
const ITEMS_PER_PAGE = 10;
let defaultParams = { per_page: 100, page: 1, search: "" };
const ImagesSection = ({ selectedLibrary, setViewImage }) => {
  // const [medias, setMedias] = useState([]);

  // const [params, setParams] = useState(defaultParams);

  const [selectedView, setSelectedView] = useState("grid");
  const { onSelect, selected, renderedData } = useContext(MediaLibraryContext);
  // //
  // const [renderedData, setRenderedData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [isLoading, setIsLoading] = useState(true);
  // const dispatch = useDispatch();

  // ///
  // const containerRef = useRef(null);

  // // const reduxMedia = useSelector((state) => state.product.media);
  // const reduxMedia = useSelector((state) => state?.helper?.media);
  // const reduxFetchingMedia = useSelector(
  //     (state) => state.product.fetchingMedia
  // );

  // useEffect(() => {
  //     if (!isArrayWithValues(reduxMedia)) dispatch(getMediaLibrary(params));
  //     // if (isArrayWithValues(alreadySelectedImages))
  //     //     setSelectedImages(alreadySelectedImages);
  //     // if (!isArrayWithValues(reduxAllSku)) dispatch(getAllSku());
  // }, []);

  // useEffect(() => {
  //     if (Array.isArray(reduxMedia)){
  //         setMedias(reduxMedia);
  //         setRenderedData(reduxMedia)
  //     }
  // }, [reduxMedia]);

  // const handleScroll = () => {
  //     console.log("scroll");
  //     const container = containerRef.current;
  //     const scrollTop = container.scrollTop;
  //     const scrollHeight = container.scrollHeight;
  //     const clientHeight = container.clientHeight;

  //     if (scrollTop + clientHeight >= scrollHeight - 50) {
  //         // When the user reaches the bottom of the page, load the next page of items
  //         setCurrentPage((prevPage) => prevPage + 1);
  //     }
  // };
  // useEffect(() => {
  //     const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  //     const endIndex = startIndex + ITEMS_PER_PAGE;
  //     const newItems = medias.slice(startIndex, endIndex);

  //     setRenderedData((prevData) => prevData.concat(newItems));
  // }, [currentPage, medias]);
  // useEffect(() => {
  //     if (renderedData.length > ITEMS_PER_PAGE * 2) {
  //         setRenderedData((prevData) => prevData.slice(ITEMS_PER_PAGE));
  //     }
  // }, [renderedData]);
  // useEffect(() => {
  //     const container = containerRef.current;
  //     container.addEventListener('scroll', handleScroll);
  //     return () => {
  //         container.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);
  return (
    <>
      <Stack
        component={Paper}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems="center"
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
          padding: "6px 24px",
          position: "sticky",
          top: 0,
          zIndex: 10,
          borderRadius: 0,
        }}
      >
        <Typography variant="h6">
          {formatServerValue(selectedLibrary)}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <TextField
            size="small"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <IconButton
            onClick={() => setSelectedView("grid")}
            sx={{
              borderRadius: "20%",
              backgroundColor:
                selectedView === "grid" ? "rgba(0,0,0,0.05)" : "",
            }}
          >
            <GridViewOutlined />
          </IconButton>
          <IconButton
            onClick={() => setSelectedView("list")}
            sx={{
              borderRadius: "20%",
              backgroundColor:
                selectedView === "list" ? "rgba(0,0,0,0.05)" : "",
            }}
          >
            <FormatListBulletedOutlined />
          </IconButton>
          {/* <Button
                        endIcon={<FilterListOutlined />}
                        sx={{ color: "text.secondary" }}
                    >
                        Sort by
                    </Button> */}

          <IconButton
            onClick={() => setSelectedView("upload")}
            sx={{
              borderRadius: "20%",
              backgroundColor:
                selectedView === "upload" ? "rgba(0,0,0,0.05)" : "",
            }}
          >
            <FileUploadOutlined />
          </IconButton>
        </Stack>
      </Stack>

      <TabPanel value={"grid"} index={selectedView}>
        <ImageGrid
          // handleScroll={handleScroll}
          // containerRef={containerRef}
          onSelect={onSelect}
          selected={selected}
          medias={renderedData}
          setViewImage={setViewImage}
        />
      </TabPanel>
      <TabPanel value={"list"} index={selectedView}>
        <ImageTable
          onSelect={onSelect}
          selected={selected}
          medias={renderedData}
          setViewImage={setViewImage}
        />
      </TabPanel>
      <TabPanel value={"upload"} index={selectedView}>
        <UploadPage setSelectedView={setSelectedView} />
        {/* <ImageTable onSelect={onSelect} selected={selected} medias={medias} setViewImage={setViewImage} /> */}
      </TabPanel>
    </>
  );
};

export default ImagesSection;
