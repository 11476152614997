export const botFieldStyles = {
  mainDiv: {
    height: "445px",
    width: "240px",
    backgroundColor: "white",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px 10px 10px",
    userSelect: "none",
  },
  title: {
    fontSize: "17px",
    fontFamily: "Inter, Roboto, sans-serif",
    marginBottom: "20px",
    transition: "opcaity .3s",
    fontWeight: 550,
  },
  cardComponents: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  coverComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: "10px",
    fontFamily: "Inter, Roboto, sans-serif",
  },
  iconCompnentsDiv: {
    height: "63px",
    width: "83px",
    transition: "opacity .3s",
    border: "0.5px solid #919ead",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      border: "1px solid #008aff",
      backgroundColor: "white",
    },
  },

  iconComponent: {
    fontSize: "30px",
    marginBottom: "15px",
    position: "relative",
    top: "7px",
    color: "#59687b",
    "&:hover": {
      color: "#008aff",
    },
  },
  iconText: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "2px",
    color: "#59687b",
  },
};
