import { useState } from "react";
import { Handle, useNodeId } from "reactflow";
import { Box, IconButton, Typography } from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AddIcon from "@mui/icons-material/Add";
import { userAttachmentNodeStyles } from "../styles/userAttachmentNodeStyles";
import { useDispatch, useSelector } from "react-redux";
import NodeOptions from "../helper/NodeOptions";
import NodePopover from "../helper/NodePopover";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../styles/node.css";

const styles = userAttachmentNodeStyles;

const UserAttachment = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const nodeId = useNodeId();
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_USER_ATTACHMENT, payload: true });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          paddingBottom: "15px",
          position: "relative",
          right: "10px",
        }}
      >
        {isHovered ? (
          <NodeOptions nodeId={nodeId} nodeType="bot" />
        ) : (
          <div
            style={{
              textAlign: "center",
              maxWidth: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontSize: "13px",
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#566e8a",
              }}
            >
              {title || "‎"}
            </Typography>
          </div>
        )}
      </div>
      <Box sx={styles.bottomRow}>
        <Handle
          type="target"
          position="left"
          style={{
            background: "#566d89",
            opacity: "0",
            position: "relative",
            left: "-10px",
            bottom: "11px",
          }}
        />

        <Box sx={styles.nodeBody} onClick={handleMain}>
          <IconButton sx={{ margin: 0, padding: 0 }}>
            <AttachFileOutlinedIcon
              style={{
                fontSize: "25px",
                transform: "rotate(0deg)",
                marginLeft: "5px",
                color: "#ffffff",
                position: "relative",
                right: "4px",
                bottom: "0px",
              }}
            />
          </IconButton>
        </Box>
        <Handle
          type="source"
          position="right"
          style={{
            background: "#566d89",
            opacity: "0",
            position: "relative",
            left: "9px",
            bottom: "11px",
          }}
        />
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />

        <span onClick={handleClick} className="addButton">
          <AddIcon sx={styles.addIcon} />
        </span>
      </Box>
    </Box>
  );
};

export default UserAttachment;
