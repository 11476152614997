import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  TextField,
  IconButton,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import ButtonPopover from "../../components/ButtonPopover";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import "../../chatbot/public/css/tabs-border.css";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import { getRandomId } from "../../utils";
import { bytesToMegabytes } from "../utils/bytesToMegabytes";
import { uploadDataToAwsBucket } from "../utils/uploadDataToAwsBucket";
import BounceLoader from "react-spinners/BounceLoader";
import { galleryStyles } from "../styles/galleryStyles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { register } from "swiper/element/bundle";
import "../styles/swiper.css";

register();

const styles = galleryStyles;

const BotGalleryField = ({
  botData,
  setBotData,
  data,
  fileInputRefs,
  index,
  handleButtonClick,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const handleDelete = (index) => {
    setBotData((prevDropFields) => {
      const updatedFields = [...prevDropFields];
      updatedFields.splice(index, 1);
      setHoveredCardIndex(null); // Reset the hovered card index
      return updatedFields;
    });
  };

  const handleFileSelectGallery = async (e, card) => {
    const uploadedFile = e.target.files[0];
    const imageSize = bytesToMegabytes(uploadedFile?.size);

    if (imageSize > 5) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const id = card.id;
    const imageURL = res.data.source_url;

    setBotData((galleryField) => {
      return galleryField.map((field) => {
        const updatedCards = field?.cards.map((card) => {
          if (card.id === id) {
            return { ...card, file: imageURL };
          }
          return card;
        });
        return { ...field, cards: updatedCards };
      });
    });
  };

  const addGalleryCard = (e, id) => {
    console.log("addGalleryCard");
    e.stopPropagation();

    const newButtonObj = {
      id: getRandomId(),
      title: "Button",
      type: "send_message",
      url: "",
      number: "",
      block: "",
    };
    const newCard = {
      id: getRandomId(),
      description: "",
      title: "",
      buttons: [newButtonObj],
    };

    setBotData((fields) => {
      return fields.map((field) => {
        if (field.id === id) {
          const updatedField = {
            ...field,
            cards: [...field.cards, newCard],
          };
          fileInputRefs.current[newCard.id] = React.createRef();
          return updatedField;
        }
        return field;
      });
    });
    console.log("CREATED");
  };

  const handleGalleryResponse = (value, card, valueField) => {
    const cardId = card.id;

    setBotData((fields) => {
      return fields.map((field) => {
        const updatedCards = field.cards.map((galleryCard) => {
          if (galleryCard.id === cardId) {
            if (valueField === "title") {
              return {
                ...galleryCard,
                title: value,
              };
            } else if (valueField === "description") {
              return {
                ...galleryCard,
                description: value,
              };
            }
          }
          return galleryCard;
        });

        return { ...field, cards: updatedCards };
      });
    });
  };

  const AddButtonClickGallery = (e, id) => {
    e.stopPropagation();
    console.log("AddButtonClickGallery");
    const newButtonObj = {
      id: getRandomId(),
      title: "Button",
      type: "send_message",
      url: "",
      number: "",
      block: "",
    };
    setBotData((prevBotData) => {
      return prevBotData.map((field) => {
        const updatedCards = field?.cards.map((card) => {
          if (card.id === id) {
            return {
              ...card,
              buttons: [...card.buttons, newButtonObj],
            };
          }
          return card;
        });

        return {
          ...field,
          cards: updatedCards,
        };
      });
    });
  };

  const swiperElRef = useRef(null);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("swiperprogress", (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress);
    });

    swiperElRef.current.addEventListener("swiperslidechange", (e) => {
      console.log("slide changed");
    });
  }, [botData]);

  return (
    // <Box key={data.id} sx={styles.mainContainer}>
    //   <DeleteAndSwap index={index} setBotData={setBotData} botData={botData} />
    // <Box sx={styles.cardsMainContainer}>
    // <Box sx={{ display: "flex", flexDirection: "row" }}>
    <swiper-container
      ref={swiperElRef}
      slides-per-view="1.7"
      navigation="true"
      pagination="true"
    >
      {data?.cards.map((card, index) => (
        <swiper-slide key={card?.id}>
          <Box sx={styles.cardsComponents}>
            <Box sx={styles.cardsDetailsBox}>
              {!card?.file ? (
                <CardContent sx={styles.galleryImgCard} key={Date.now()}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRefs.current[card.id]}
                    onChange={(e) => handleFileSelectGallery(e, card)}
                  />

                  {uploadingFile ? (
                    <>
                      <BounceLoader color="#36d7b7" />
                      <Typography variant="span">
                        Uploading, please wait...
                      </Typography>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleButtonClick(card.id)}>
                        <PhotoCameraIcon style={{ fontSize: "50px" }} />
                      </IconButton>
                      <Typography variant="span">Upload Image</Typography>
                    </>
                  )}
                </CardContent>
              ) : (
                <CardContent style={{ padding: 0 }}>
                  <CardMedia
                    sx={styles.cardMedia}
                    component="img"
                    image={card.file}
                    alt="Selected Image"
                  />
                </CardContent>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent style={styles.galleryBottom}>
                  <Box sx={styles.cardsTitleTextField}>
                    <TextField
                      multiline
                      variant="standard"
                      rows={1.5}
                      placeholder="Type card title"
                      size="normal"
                      value={card.title}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontWeight: 600,
                          fontFamily: "Inter",
                          fontSize: "16px",
                        },
                      }}
                      inputProps={{
                        maxLength: 30,
                      }}
                      onChange={(e) => {
                        const title = e.target.value;
                        const fieldType = "title";
                        handleGalleryResponse(title, card, fieldType);
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box sx={styles.cardsDescriptionField}>
                    <TextField
                      multiline
                      variant="standard"
                      id="outlined-multiline-static"
                      rows={2.5}
                      placeholder="Type card description"
                      value={card.description}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        maxLength: 256,
                        style: {
                          fontFamily: "Inter",
                          fontSize: "16px",
                        },
                      }}
                      onChange={(e) => {
                        const description = e.target.value;
                        const fieldType = "description";
                        handleGalleryResponse(description, card, fieldType);
                      }}
                    />
                  </Box>
                  {card?.buttons.map((button, index) => (
                    <ButtonPopover
                      fieldId={data?.id}
                      cardId={card?.id}
                      key={button?.id}
                      buttonId={button?.id}
                      fieldType={data?.type}
                      title={button?.title}
                      botData={botData}
                      setBotData={setBotData}
                      button={button}
                      currentIndex={index}
                    />
                  ))}
                </CardContent>
              </Box>
              {hoveredCardIndex === index && (
                <DeleteOutlineIcon
                  sx={styles.deleteIcon}
                  onClick={() => handleDelete(index)}
                />
              )}
            </Box>
            <Box sx={styles.buttonBox}>
              {card?.buttons.length < 5 && (
                <>
                  {[...Array(5)].map((_, index) => (
                    <Box key={index} sx={styles.boxLineDesign} />
                  ))}
                  <IconButton
                    sx={styles.iconButton}
                    onClick={(e) => {
                      AddButtonClickGallery(e, card?.id, data?.type);
                    }}
                  >
                    <AddIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
          <Box sx={styles.buttonsContainer}>
            <IconButton
              onClick={(e) => addGalleryCard(e, data?.id)}
              sx={styles.iconAddCardButton}
            >
              <AddIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
        </swiper-slide>
      ))}
    </swiper-container>
    // </Box>
  );
};

BotGalleryField.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  botData: PropTypes.array.isRequired,
  setBotData: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  fileInputRefs: PropTypes.object.isRequired,
};

export default BotGalleryField;
