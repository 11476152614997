/* eslint-disable react-hooks/exhaustive-deps */
import { East, } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,

  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../../../components/navbar/NavbarNotificationsDropdown";
import {
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  secondaryLabel,
  sectionLabel,
} from "../../../helper";
import {
  BellCircle,
  InstagramSvg,
  Messenger,
  NotificationIcon,
  OpenMailIcon,
  ProductReview,
  UserCatalogsSvg,
  UserDigigoldSvg,
  UserOrdersSvg,
  Whatsapp,
} from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";
import { ReopenMailIcon } from "../../../Svgs";

const NotificationsDashboard = ({ length }) => {
  const navigate = useNavigate();
  const [allNotifications, setAllNotifications] = useState([]);
  const notifications = useSelector((state) => state.settings.notifications);
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isArrayWithValues(notifications))
      setAllNotifications([...notifications.slice(0, length || 6)]);
  }, [notifications]);

  const onClick = (obj) => {
    let { customer_id, type } = obj;
    if (notificationTypes.chat.includes(type)) navigate(`/chats`);
    else if (notificationTypes.crm.includes(type))
      navigate(`/crm/${customer_id}#chat`);
    else if (notificationTypes.catalog.includes(type))
      navigate(`/catalogs/${customer_id}`);
    else if (notificationTypes.catalogRequest.includes(type))
      navigate(`/catalogs/requests/${customer_id}`);
    else if (notificationTypes.order.includes(type))
      navigate(`/orders/${customer_id}`);
    else if (notificationTypes.digigold.includes(type)) navigate(`/digigold`);
  };
  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ mb: 5 }}
        >
          <Typography sx={{ ...sectionLabel }}>Notifications</Typography>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/notifications")}
          >
            <Typography sx={{ ...secondaryLabel, color: "primary" }}>
              Notification history
            </Typography>
            <East
              style={{ height: "15px", width: "15px", marginLeft: "5px" }}
            />
          </Stack>
        </Stack>
        {fetchingAllSettings && !isArrayWithValues(allNotifications) ? (
          <Stack direction="row" justifyContent={"center"} my={15}>
            <CircularProgress />
          </Stack>
        ) : null}

        {!fetchingAllSettings && !isArrayWithValues(allNotifications) ? (
          <Box sx={{ margin: "auto" }}>
            <NoTasks
              text="Notifications not received yet"
              icon={
                <BellCircle
                  style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                  fillCircle="#c4c4c4"
                />
              }
            />
          </Box>
        ) : null}

        {allNotifications.map((obj) => {
          let timeObj = getRelativeTimeDifference(
            Number(obj.time * 1000),
            Number(new Date().getTime())
          );
          return (
            <Stack
              direction="row"
              alignItems={"center"}
              width="100%"
              sx={{ my: 5, cursor: "pointer" }}
              onClick={() => onClick(obj)}
            >
              {getNotificationIconByType(obj.type)}
              <Stack flex={"100%"} ml={2}>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                  flexWrap={isIpad ? "wrap" : "nowrap"}
                >
                  <Typography
                    variant="h6"
                    dangerouslySetInnerHTML={{
                      __html: obj.message,
                    }}
                    sx={{ margin: 0, padding: 0, ...primaryLabel }}
                  >
                    {/* {obj.message} */}
                  </Typography>
                  {/* <Typography variant="h6">{obj.title}</Typography> */}
                  {isObjWithValues(timeObj) && (
                    <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                      {timeObj.value} {timeObj.label} ago
                    </Typography>
                  )}
                </Stack>
                {/* <div dangerouslySetInnerHTML={{__html:obj.message}} /> */}
              </Stack>
            </Stack>
            // <Card
            //   sx={{ cursor: obj.id ? "pointer" : "default" }}
            //   onClick={() => onClick(obj)}
            // >
            //   <CardContent>

            //   </CardContent>
            // </Card>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default NotificationsDashboard;

export const getNotificationIconByType = (type) => {

  switch (type) {
    case "whatsapp_msg_notify":
      return <Whatsapp />;
    case "whatsapp_message":
      return <Whatsapp />;
    case "catalog_request":
      return <UserCatalogsSvg />;
    case "new_order":
      return <UserOrdersSvg />;
    case "new_digigold":
      return <UserDigigoldSvg />;
    case "instagram":
      return <InstagramSvg />;
    case "facebook":
      return <Messenger />;
    case "email_reopened":
      return <ReopenMailIcon />;
    case "email_opened":
      return <OpenMailIcon />;
    case "new_review":
      return <ProductReview />;

    default:
      return <NotificationIcon />;
  }
};
