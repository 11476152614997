import React, { useState } from "react";
import { Badge, Box, Button, Stack } from "@mui/material";

const AllTabs = [
  { label: "Campaigns", value: "campaigns" },
  { label: "Workflows", value: "workflows", badge: "New" },
];
const TopTabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          // backgroundColor: "Background",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
        spacing={4}
      >
        {AllTabs?.length > 1 &&
          AllTabs?.map((i) => {
            return (
              <>
                <Box sx={{ position: "relative" }}>
                  <Button
                    key={i?.label}
                    variant={"text"}
                    sx={{
                      backgroundColor:
                        selectedTab === i.value ? "#e6e6e6" : "transparent",
                      color: "#000",

                      "&:hover": {
                        backgroundColor:
                          selectedTab === i.value ? "#e6e6e6" : "transparent",
                      },
                    }}
                    style={{ minWidth: "auto" }}
                    onClick={() => setSelectedTab(i.value)}
                  >
                    {i.label}
                  </Button>
                  {i?.badge && (
                    <Badge
                      sx={{
                        backgroundColor: "#ea2e2e",
                        borderRadius: "6px",
                        fontSize: "9px",
                        color: "#fff",
                        fontWeight: "bold",
                        padding: "2px 4px",
                        position: "absolute",
                        right: "-20px",
                        top: "-3px",
                        zIndex: 1,
                      }}
                    >
                      {i?.badge}
                    </Badge>
                  )}
                </Box>
              </>
            );
          })}
      </Stack>
    </Box>
  );
};

export default TopTabs;
