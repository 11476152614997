/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AmazonSvg,
  EbaySvg,
  Facebook,
  Google as GoogleLogo,
  GoogleSheet,
  IntegrationIllustration,
  Linkedin,
  MetaSvg,
  Msg91Svg,
  RazorpayLogo,
  ShopifySvg,
  UnicommerceSvg,
  WebchatSvg,
  Whatsapp,
  WooCommerceSvg,
} from "../../Svgs";
import PropTypes from "prop-types";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Clock, Settings } from "react-feather";
import { useTheme } from "@mui/styles";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import axios from "axios";
import {
  getAxiosError,
  getGlobalRedirectURL,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isOurWebsite,
} from "../../helper";
import { EDIT_SETTINGS } from "../../redux/actions/settingActions";
import { useDispatch, useSelector } from "react-redux";

import {
  ArrowBackIos,
  FilterAltOutlined,
  PowerSettingsNew,
  Search,
  SmsOutlined,
} from "@mui/icons-material";

import "reactjs-crontab/dist/index.css";

import Meta from "./components/meta/Index";
import Google from "./components/google/Index";
import Ornate from "./components/ornate";
import TryBeforeYouBut from "./components/trybeforeyouBuy/Index";
import Camweara from "./components/camwera/Index";
import Onesignal from "./components/oneSignal";
import Razorpay from "./components/razorpay/Razorpay";
import Cashfree from "./components/cashfree/Cashfree";
import CCAvenue from "./components/ccavenue/CCAvenue";
import EaseBuzz from "./components/easeBuzz/EaseBuzz";
import styled from "styled-components";
import PayU from "./components/payu/PayU";
import Ebay from "./components/ebay";
import Amazon from "./components/amazon";
import WooCommerce from "./components/woocommerce";
// import ReactTour from "reactour";
import Shopify from "./components/shopify";
import { useTour } from "@reactour/tour";
import InfoTooltip from "../components/InfoTooltip";
import Mirrar from "./components/mirrar";
import Jemisys from "./components/jemisys";
import { onAuthorizeShopify } from "./components/shopify/ShopifyIntegration";
import Exotel from "./components/exotel";
import Sms from "./components/sms";
import Paysharp from "./components/paysharp";
import Msg91 from "./components/msg91";
import Unicommerce from "./components/unicommerce";
import LoyltyRewardz from "./components/loyltyRewardz";
import Webchat from "./components/webchat";
import MLoyal from "./components/mloyal";
// import ReactTour from "reactour";

export const client_id_global =
  isDev() && false ? "707712269839079" : "327230355043966"; // host
export const client_secret_global =
  isDev() && false
    ? "30a91d6a2600f7ac35bc7c0d83400c10"
    : "76d337d9f173435352c77aadcabeec9d"; // host
export const redirect_url_global = getGlobalRedirectURL();

// export const redirect_url_global = "http://localhost:3000" // local
// export const client_id_global = "707712269839079" // local
// export const client_secret_global = "30a91d6a2600f7ac35bc7c0d83400c10" // local

export const FormLabel = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
`;

function Integrations() {
  const integrationsAccess = useSelector(
    (state) => state.user.integrationsAccess
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { hash } = location;

  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const reduxIntegrationAttributes = useSelector(
    (state) => state.settings.integration_attributes
  );

  useEffect(async () => {
    let params_string = location.search;
    // let url = new URL(params_string);
    // if (hash) setSelectedTab(hash.slice(1))
    if (user_products?.length === 1 && user_products?.includes("ornate"))
      setSelectedTab("Ornate");
    else setSelectedTab(tabs["Marketing"][0].label);
    // if (isIpad) {
    if (hash) setSelectedTab(hash.slice(1));
    // }
    let state = new URLSearchParams(params_string).get("state");
    let shop = new URLSearchParams(params_string).get("shop");
    let hmac = new URLSearchParams(params_string).get("hmac");
    // if (!state) return;
    const wooComUserid = new URLSearchParams(params_string).get("user_id");

    if (hmac && shop && !state) {
      setSelectedTab("Shopify");
      onAuthorizeShopify(shop);
    }
    if (wooComUserid && new URLSearchParams(params_string).get("success")) {
      setSelectedTab("WooCommerce");
    }

    if (
      state?.includes("google") ||
      state?.includes("tag") ||
      state?.includes("firebase")
    ) {
      setSelectedTab("Google");
    }

    if (state?.includes("ebay")) setSelectedTab("Ebay");

    if (state?.includes("amazon")) setSelectedTab("Amazon");
    if (state?.includes("shopify")) setSelectedTab("Shopify");

    let app = new URLSearchParams(params_string).get("app");
    if (!app) return;
    if (app?.includes("facebook")) {
      console.log("reDirect");
      setSelectedTab("Meta");
    }
  }, []);

  useEffect(async () => {
    const website = getWebsite();
    const authToken = getToken();
    if (
      reduxIntegrationAttributes === undefined ||
      !reduxIntegrationAttributes?.hasOwnProperty("jwero")
    ) {
      try {
        const response = await axios({
          method: "GET",
          url: `${website}/wp-json/wc/v3/products?status=publish&page=1&per_page=1&status=publish`,
          headers: {
            Authorization: `Basic ${authToken}`,
          },
        });
        const data = response.data;
        if (response.status == 200) {
          const product = data[0];
          const attributes = Object.keys(product)
            .filter((filterItem) => jweroProductKeys.includes(filterItem))
            .map((item, index) => ({
              [item]: typeof product[item],
            }));

          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integration_attributes: {
                ...reduxIntegrationAttributes,
                jwero: attributes,
              },
            },
          });
        }
      } catch (err) {
        console.debug(err);
      }
    }
  }, [reduxIntegrationAttributes]);

  const tabs = {
    Marketing: [
      {
        label: "Google",
        icon: <GoogleLogo />,
        keywords: "Marketing,google,tags,tagmanager,siteverification,firebase",
        props: {},
        // user_products: ["catalog"],
      },
      {
        label: "Meta",
        icon: <MetaSvg />,
        keywords: "facebook,Meta,marketing,chats",
        props: {},
        // user_products: ["chats"],
      },
      // { label: "FB/Insta Shop", icon: <InstagramSvg />  ,  category:"Marketing"},
      {
        label: "Linkedin",
        icon: <Linkedin />,
        keywords: "Marketing,linkedin",
        props: { disabled: true, chip: true },
      },
      {
        label: "Exotel",

        icon: (
          <img
            src="/static/img/brands/exotelLogo.png"
            width="25px"
            height="25px"
            alt="exotel"
          />
        ),
        keywords: "Marketing,Exotel",
        props: { disabled: false, chip: false },
      },
      {
        label: "Onesignal",
        icon: (
          <img
            src="/static/img/brands/onesignal-circle.png"
            width="25px"
            height="25px"
            alt="onesignal"
          />
        ),
        props: {},
        keywords: "Marketing,onesignal",
      },
      {
        label: "SMS",
        icon: <SmsOutlined />,
        props: { disabled: false, chip: false },
        keywords: "Marketing,onesignal",
      },
      {
        label: "MSG91",
        icon: <Msg91Svg height={"30px"} width={"30px"} />,
        keywords: "MSG91",
        props: { disabled: false, chip: false },
      },
      {
        label: "Webchat",
        icon: <WebchatSvg height={"30px"} width={"30px"} />,
        keywords: "Webchat",
        props: { disabled: false, chip: false },
      },
    ],
    Payments: [
      {
        label: "Cashfree",
        icon: (
          <img
            src="/static/img/brands/cashfree.png"
            width="35px"
            height="25px"
            style={{ marginLeft: "-10px" }}
            marginLeft="-10px"
            alt="cashfree"
          />
        ),
        keywords: "Cashfree",
        props: {},
      },
      {
        label: "Razorpay",
        icon: <RazorpayLogo height={25} width={25} />,
        keywords: "Razorpay",
        props: {},
      },

      {
        label: "PayU",
        icon: (
          <img
            src="/static/img/brands/payu.png"
            width="25px"
            height="25px"
            alt="PayU"
          />
        ),
        keywords: "PayU",
        props: {},
      },
      {
        label: "EaseBuzz",
        icon: (
          <img
            src="/static/img/brands/easebuzz.png"
            width="25px"
            height="25px"
            alt="EaseBuzz"
          />
        ),
        keywords: "EaseBuzz",
        props: {},
      },
      {
        label: "CCAvenue",
        icon: (
          <img
            src="/static/img/brands/ccavenue.png"
            width="25px"
            height="25px"
            alt="CCAvenue"
          />
        ),
        keywords: "CCAvenue",
        props: {},
      },
      {
        label: "Paysharp",
        icon: (
          <img
            src="/static/img/brands/paysharp-icon.png"
            width="25px"
            height="25px"
            alt="Paysharp"
          />
        ),
        keywords: "Paysharp",
        props: {},
      },
      isDev() && {
        label: "Loylty Rewardz",
        icon: (
          <img
            src="/static/img/brands/loylty-rewardz.webp"
            width="25px"
            height="25px"
            alt="Loylty Rewardz"
          />
        ),
        keywords: "Loylty Rewardz",
        props: {},
      },
      isDev() && {
        label: "MLoyal",
        icon: (
          <img
            src="/static/img/brands/mloyal.webp"
            width="25px"
            height="22px"
            alt="MLoyal"
          />
        ),
        keywords: "MLoyal",
        props: {},
      },
    ],
    // Communication: [
    //   {
    //     label: "Gupshup",
    //     icon: (
    //       <img
    //         src="/static/img/brands/gupshup.png"
    //         width="25px"
    //         height="25px"
    //       />
    //     ),
    //     keywords: "communication,gupsup",
    //   },
    // ],
    "ERP's": [
      {
        label: "Ornate",
        icon: (
          <img
            src="/static/img/brands/ornate.png"
            width="20px"
            height="20px"
            alt="Ornate"
          />
        ),
        keywords: "erp,ornate",
        props: {},
      },
      {
        label: "JEMiSys",
        icon: (
          <img
            src="/static/img/brands/jemisysicon.webp"
            width="20px"
            height="20px"
            alt="JEMiSys"
          />
        ),
        keywords: "jemisys,ornate",
        props: {},
      },
    ],
    "Virtual try on": [
      {
        label: "Tryb4ubuy",
        icon: (
          <img
            src="/static/img/brands/tryb4ubuy.png"
            width="20px"
            height="20px"
            alt="Tryb4ubuy"
          />
        ),
        keywords: "Tryb4ubuy,virtualtryon",
        props: {},
      },
      {
        label: "Mirrar",
        icon: (
          <img
            src="/static/img/brands/mirrarLogo.png"
            width="20px"
            height="20px"
            alt="Mirrar"
          />
        ),
        keywords: "Mirrar,virtualtryon",
        props: {},
      },
      {
        label: "Camweara",
        icon: (
          <img
            src="/static/img/brands/camweara.png"
            width="20px"
            height="20px"
            alt="Camweara"
          />
        ),

        keywords: "Camwera,virtualtryon",
        props: {},
      },
    ],
    Logistics: [
      {
        label: "Blue Dart",
        icon: (
          <img
            src="/static/img/brands/bluedart.png"
            width="25px"
            height="25px"
            alt="Blue Dart"
          />
        ),
        keywords: "Logistics,bluedart",
        props: { disabled: true, chip: true },
      },
      {
        label: "BVC",
        icon: (
          <img
            src="/static/img/brands/bvc.png"
            width="25px"
            height="25px"
            alt="BVC"
          />
        ),
        keywords: "Logistics,bvc",
        props: { disabled: true, chip: true },
      },
    ],
    "Market places": [
      {
        label: "Amazon",
        icon: <AmazonSvg height={25} width={25} />,
        keywords: "Market places, amazon",
        props: {
          // disabled: !isDev() && !isOurWebsite(["18"]),
          // chip: !isOurWebsite({ store_ids: ["18"] }),
        },
      },
      {
        label: "Ebay",
        icon: <EbaySvg height={25} width={25} />,
        keywords: "Market places, ebay",
        props: { disabled: !isDev() && !isOurWebsite(), chip: true },
      },
      // {
      //   label: "ONDC",
      //   icon: (
      //     <img src="/static/img/brands/ongc.png" width="25px" height="100%" />
      //   ),
      //   keywords: "Market places, ondc",
      //   props: { disabled: true, chip: true },
      // },
      {
        label: "WooCommerce",
        icon: <WooCommerceSvg height={25} width={25} />,
        keywords: "Store, woocommerce",
        props: { disabled: !isDev() && !isOurWebsite(), chip: true },
      },
      {
        label: "Shopify",
        icon: <ShopifySvg height={25} width={25} />,
        keywords: "Store, shopify",
        props: { disabled: !isDev() && !isOurWebsite(), chip: true },
        // props: { disabled: !isDev() && !isOurWebsite(), chip: !isDev() },
      },
      {
        label: "Unicommerce",
        icon: <UnicommerceSvg height={25} width={25} />,
        keywords: "Store, unicommerce",
        props: { disabled: !isDev() && !isOurWebsite(), chip: true },
        // props: { disabled: !isDev() && !isOurWebsite(), chip: !isDev() },
      },
    ],
    // Automation: [
    //   {
    //     label: "Zapier",
    //     icon: <ZapierSvg height={25} width={25} />,
    //     keywords: "Automation,zapier",
    //     props: { disabled: true, chip: true },
    //   },
    // ],
  };
  const [selectedTab, setSelectedTab] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, setCurrentStep } = useTour();

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const integrationsByProduct = useSelector(
    (state) => state.user.integrationsByProduct
  );

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onfilterChange = (category) => {
    setFilteredCategory(category);
    if (category) {
      setSelectedTab(tabs[category][0].label);
    } else {
      setSelectedTab(tabs["Marketing"][0].label);
    }
    setAnchorEl(false);
  };

  if (!integrationsAccess && !isAdmin)
    return (
      <Box>
        <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
          <IntegrationIllustration />
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {/* <SettingsIllustration /> */}
          <Typography variant="h6" mt={2}>
            You don't have access to make changes in Integrations!
          </Typography>
        </Stack>
      </Box>
    );
  return (
    <React.Fragment>
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={4000}
        // onClose={() => setToast((state) => ({ ...state, show: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={toast.severity || "success"} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      <Helmet title="Integrations" />

      <Stack
        direction={"row"}
        alignItems="center"
        // justifyContent={"space-between"}
        sx={{ flex: 1, padding: isMobile ? "6px" : "12px" }}
        spacing={1}
      >
        <Typography variant="h3" display="inline">
          {isIpad && hash ? (
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => navigate("/integrations")}
            >
              <ArrowBackIos />
            </IconButton>
          ) : null}
          Integrations
        </Typography>
        <InfoTooltip
          title="Connect your platform to other 3rd party tools for smooth
            functioning and to manage one or more services from a single
            dashboard"
        />
      </Stack>

      <Box
        sx={{
          flexGrow: 1,
          // bgcolor: "background.paper",
          display: "flex",
          height: "calc(100vh - 200px)",
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            className="scrollbar-hidden"
            sx={
              isIpad
                ? {
                    display: hash ? "none" : "block",
                  }
                : {}
            }
          >
            <Card
              className="scrollbar-hidden"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: "calc(100vh - 150px)",
                overflow: "scroll",
              }}
            >
              <Stack
                direction={"row"}
                style={{
                  padding: "20px",
                  position: "sticky",
                  top: "0px",
                  backgroundColor: "#fff",
                  zIndex: 5,
                }}
                alignItems={"center"}
                backgroundColor={"#fff"}
                className="step-1"
              >
                <TextField
                  fullWidth
                  onChange={onSearch}
                  // label="Search"
                  value={searchTerm}
                  variant="standard"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />{" "}
                <IconButton
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Badge
                    invisible={!filteredCategory}
                    variant="dot"
                    color="secondary"
                    overlap="circular"
                  >
                    <FilterAltOutlined />
                  </Badge>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => onfilterChange(null)}>All</MenuItem>
                  {Object.keys(tabs).map((category) => {
                    if (
                      !isArrayWithValues(
                        tabs?.[category]?.filter((o) => !o?.props?.chip)
                      )
                    )
                      return <></>;
                    return (
                      <MenuItem onClick={() => onfilterChange(category)}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Stack>
              <Tabs
                orientation="vertical"
                value={selectedTab}
                variant="scrollable"
                scrollButtons={false}
                // onChange={tabChange}
                // aria-label="Vertical tabs example"
                sx={{
                  // borderRight: 1,
                  // borderColor: "divider",
                  width: "100%",
                  mb: 3,
                  // height: "100%",
                  // height: "calc(100vh- 310px)"
                }}
              >
                {Object.keys(tabs).map((cat, index) => {
                  // const category = filteredCategory ? filteredCategory : cat;
                  let category = cat;
                  let hideCategory = false;
                  if (filteredCategory) {
                    if (category !== filteredCategory) return;
                  }
                  if (!isOurWebsite()) {
                    if (
                      !isArrayWithValues(
                        tabs[category]?.filter((o) => !o.props?.chip)
                      )
                    ) {
                      return;
                    }
                  }
                  if (searchTerm) {
                    if (
                      tabs[category].filter((obj) =>
                        obj.keywords
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ).length <= 0
                    )
                      return;
                  }
                  if (
                    isArrayWithValues(user_products) &&
                    !isArrayWithValues(
                      user_products
                        .map((i) => {
                          return integrationsByProduct[i]?.find((i) =>
                            tabs[cat]?.map((i) => i.label)?.find((j) => j == i)
                          );
                        })
                        .filter(Boolean)
                    )
                  )
                    return null;
                  return [
                    <Tab
                      disabled
                      label={
                        // category
                        <Typography variant="button">{category}</Typography>
                      }
                      sx={{
                        alignItems: "flex-start",
                        margin: "0px 15px",
                        // height: "30px"
                        minHeight: "30px",
                        fontSize: "22px",
                        padding: "0 16px",
                        marginTop: "10px",
                        display: hideCategory ? "none" : "flex",
                        // borderTop: "0.5px solid",
                        // borderColor: "divider"
                      }}
                    />,
                    tabs[category].map((tab, tabIndex) => {
                      // let category = cat;

                      if (filteredCategory) {
                        if (category !== filteredCategory) return;
                      }
                      if (searchTerm) {
                        if (
                          !tab.keywords
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                          return;
                      }
                      if (!isOurWebsite()) {
                        if (tab?.props?.chip) {
                          return null;
                        }
                      }

                      if (
                        isArrayWithValues(user_products) &&
                        !Object.keys(integrationsByProduct).find(
                          (i) =>
                            user_products.includes(i) &&
                            integrationsByProduct[i].includes(tab.label)
                        )
                      )
                        return null;

                      return (
                        <Tab
                          className={`integration-tab-${tabIndex}`}
                          key={tab.label}
                          label={
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              justifyContent={"space-between"}
                              sx={{ width: "100%" }}
                            >
                              <Typography
                                sx={{ fontWeight: 500, fontSize: "1rem" }}
                              >
                                {tab?.label}
                              </Typography>{" "}
                              {/* {tab?.props.chip ? (
                                <Chip
                                  sx={{
                                    fontSize: "10px",
                                    color: "rgb(122 122 122 / 87%)",
                                  }}
                                  size="small"
                                  label={
                                    tab?.props?.lock ? "Unlock" : "Coming soon"
                                  }
                                />
                              ) : null} */}
                            </Stack>
                          }
                          value={tab.label}
                          tabIndex={tab.label}
                          onClick={() => {
                            if (isOpen) setCurrentStep((state) => state + 1);
                            setSelectedTab(tab.label);
                            if (isIpad) navigate(`/integrations#${tab.label}`);
                            console.log(tab.label);
                          }}
                          icon={tab.icon}
                          {...tab?.props}
                          // value={tab.label}
                          // {...a11yProps(tab.label)}
                          iconPosition="start"
                          sx={{
                            justifyContent: "flex-start",
                            gap: "8px",
                            margin: "0px 15px",
                            minHeight: "50px",
                            fontSize: "16px",
                            display: tab?.props?.hide ? "none" : "flex",
                          }}
                        />
                      );
                    }),
                  ];
                })}
              </Tabs>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={
              isIpad
                ? {
                    display: hash ? "block" : "none",
                  }
                : {}
            }
            // sx={{
            //   display: showIntegrations ? "block" : "none",
            // }}
          >
            <Card
              className="scrollbar-hidden"
              sx={{
                height: "calc(100vh - 150px)",
                overflow: "scroll",
              }}
            >
              {/* <TabPanel value={selectedTab} index={"Gupshup"}>
                <IntegrationView
                  title="Gupshup"
                  // logo={<Whatsapp width="40px" height="40px" />}
                  logo={
                    <img
                      src="/static/img/brands/gupshup.png"
                      width="40px"
                      height="40px"
                    />
                  }
                  onSwitchChange={(e) => toggleGupshup(e)}
                  selected={gupshupSwitch}
                  showConfigure={showConfigure}
                  configurePress={configurePress}
                  loading={fetchingIntegrations || gupshupLoading}
                  revokeClick={revokeGupshup}
                  revoking={revokingGupshup}
                  showSwitch={Boolean(gupshupForm.key)}
                  onSave={() =>
                    updateGupshupWithoutEnable(
                      gupshupForm.name,
                      gupshupForm.key,
                      setGupshupLoading,
                      setToast
                    )
                  }
                  revokePress={() => setRevokeDialog("gupshup")}
                  integrationView={
                    <GupshupConfiguration
                      toggleConfigure={toggleConfigure}
                      gupshupForm={gupshupForm}
                      onEditGupshupForm={onEditGupshupForm}
                      fetchingIntegrations={fetchingIntegrations}
                      setToast={setToast}
                    />
                  }
                />
              </TabPanel> */}
              <TabPanel value={selectedTab} index={"Onesignal"}>
                <Onesignal />
              </TabPanel>
              <TabPanel value={selectedTab} index={"CCAvenue"}>
                <CCAvenue />
              </TabPanel>
              <TabPanel value={selectedTab} index={"EaseBuzz"}>
                <EaseBuzz />
              </TabPanel>

              <TabPanel value={selectedTab} index={"Razorpay"}>
                <Razorpay />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Cashfree"}>
                <Cashfree />
              </TabPanel>
              <TabPanel value={selectedTab} index={"PayU"}>
                <PayU />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Google"}>
                <Google />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Meta"}>
                <Meta />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Mirrar"}>
                <Mirrar />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Webchat"}>
                <Webchat />
              </TabPanel>

              <TabPanel value={selectedTab} index={"JEMiSys"}>
                <Jemisys />
              </TabPanel>
              <TabPanel value={selectedTab} index={"SMS"}>
                <Sms />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Unicommerce"}>
                <Unicommerce />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Ornate"}>
                <Ornate />
                {/* <IntegrationView
                  title="Facebook"
                  logo={<Facebook width="40px" height="40px" />}
                  // onSwitchChange={(e) => setGoogleSwitch(e)}
                  // selected={googleSwitch}
                  configurePress={configurePress}
                  revokeClick={revokeFacebook}
                  revoking={revokingFacebook}
                  // // commingSoon
                  // googleForm={googleForm}
                  // sheetClick={googleSheetClick}
                  showSwitch={Boolean(facebookForm.access_token)}
                  // showSheet={googleForm.access_token}
                  loading={fetchingIntegrations}
                  revokePress={() => setRevokeDialog("facebook")}
                  integrationView={
                    <FacebookConfiguration
                      toggleConfigure={toggleConfigure}
                      // googleForm={googleForm}
                      facebookForm={facebookForm}
                    />
                  }
                /> */}
              </TabPanel>
              <TabPanel value={selectedTab} index={"Tryb4ubuy"}>
                <TryBeforeYouBut />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Camweara"}>
                <Camweara />
              </TabPanel>
              <TabPanel value={selectedTab} index={"WooCommerce"}>
                <WooCommerce />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Shopify"}>
                <Shopify />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Ebay"}>
                <Ebay />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Amazon"}>
                <Amazon />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Exotel"}>
                <Exotel />
              </TabPanel>
              <TabPanel value={selectedTab} index={"Paysharp"}>
                <Paysharp />
              </TabPanel>
              <TabPanel value={selectedTab} index={"MSG91"}>
                <Msg91 />
              </TabPanel>
              {isDev() && (
                <TabPanel value={selectedTab} index={"Loylty Rewardz"}>
                  <LoyltyRewardz />
                </TabPanel>
              )}
              {isDev() && (
                <TabPanel value={selectedTab} index={"MLoyal"}>
                  <MLoyal />
                </TabPanel>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      {/* <ReactTour
        steps={getIntegrationSteps(user_products)}
        isOpen={showTour}
        startAt={0}
        onRequestClose={() => {
          setShowTour(false);
        }}
        // ={customStyles}
      /> */}
    </React.Fragment>
  );
}

export const getFacebookIntegrationInRedux = async (dispatch) => {
  let website = await getWebsite();
  let token = await getToken();
  axios({
    url: `${website}/wp-json/store/v1/settings/facebook_integration`,
    headers: {
      Authorization: `Basic ${token}`,
    },
  })
    .then((res) => {
      let { data } = res;
      console.log(data, "<<< ");
      if (data.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            facebookIntegration: data.data,
          },
        });
      }
    })
    .catch((e) => console.log(getAxiosError(e)));
};
const getWhatsappIntegrationIntoRedux = async (dispatch) => {
  let website = await getWebsite();
  let token = await getToken();
  axios({
    url: `${website}/wp-json/store/v1/settings/whatsapp_integration`,
    headers: {
      Authorization: `Basic ${token}`,
    },
  })
    .then((res) => {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            whatsappIntegration: data.data,
          },
        });
      }
    })
    .catch((e) => console.log(e));
};

const GupshupConfiguration = ({
  toggleConfigure,
  gupshupForm,
  onEditGupshupForm,
  fetchingIntegrations,
  setToast,
}) => {
  const [updating, setUpdating] = useState(false);
  let { key, name } = gupshupForm;
  const onAdd = () => {
    updateGupshupWithoutEnable(name, key, setUpdating, setToast, () =>
      toggleConfigure(false)
    );
  };
  return (
    <>
      {" "}
      <Typography variant="h6" fontSize={"13px"} mt={3}>
        Gupshup Key
      </Typography>
      <TextField
        value={key}
        onChange={(e) => onEditGupshupForm({ key: e.target.value })}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mt: 2 }}
        disabled={fetchingIntegrations}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <LockRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="h6" fontSize={"13px"} mt={3}>
        Gupshup App Name
      </Typography>
      <TextField
        value={name}
        onChange={(e) => onEditGupshupForm({ name: e.target.value })}
        variant="outlined"
        size="small"
        sx={{ mt: 2 }}
        fullWidth
        disabled={fetchingIntegrations}
      />
      {/* <DialogActions>
        <Button onClick={() => toggleConfigure(false)}>Cancel</Button>
        <LoadingButton loading={updating} onClick={onAdd}>
          Save
        </LoadingButton>
      </DialogActions> */}
    </>
  );
};
const GoogleConfiguration = ({ toggleConfigure, googleForm }) => {
  const clientId =
    "448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com";
  const scope =
    "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.profile";
  const redirect_url = `http://localhost:3000/integrations`;
  let authUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const googlePress = () =>
    window.open(
      `${authUrl}?client_id=${clientId}&redirect_uri=${redirect_url}&response_type=code&scope=${scope}&access_type=offline`,
      "_self"
    );
  //https://accounts.google.com/o/oauth2/v2/auth?client_id=448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com&redirect_uri=http://localhost:3000/integrations&response_type=code&scope=https://www.googleapis.com/auth/spreadsheets&access_type=offline
  return (
    <>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Button
          // variant="contained"
          sx={{ border: "1px solid #e8e8e8" }}
          startIcon={<Google />}
          size="large"
          onClick={googlePress}
          disabled={googleForm.access_token}
        >
          Sign In with Google
        </Button>
        {googleForm.access_token ? (
          <Typography sx={{ color: "success.main" }} variant="h6">
            connected
          </Typography>
        ) : (
          <Typography variant="h6">Not connected</Typography>
        )}
      </Stack>
      {/* <DialogActions>
        <Button onClick={() => toggleConfigure(false)}>Cancel</Button>
      </DialogActions> */}
    </>
  );
};
const FacebookConfiguration = ({ toggleConfigure, facebookForm }) => {
  const clientId = "707712269839079";
  // const clientId = "327230355043966";
  // const clientId = "327230355043966";
  const scope =
    "pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,public_profile,instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_read_user_content,pages_manage_engagement";
  // const redirect_url = `https://retailer.tanika.tech/integrations/?app=facebook`;
  // const redirect_url = `http://localhost/integrations`;
  const redirect_url = `${redirect_url_global}/integrations?app=facebook&response_type=code&scope=${scope}#meta`;
  // const redirect_url = `https://3867-116-73-246-36.ngrok-free.app/integrations/`;
  let authUrl = "https://www.facebook.com/v8.0/dialog/oauth";
  let link = `${authUrl}?client_id=${client_id_global}&redirect_uri=${redirect_url}`;
  // let link = `${authUrl}?client_id=${clientId}&redirect_uri=${redirect_url}&response_type=code&scope=${scope}`;
  const googlePress = () =>
    window.open(
      link,
      // "https://www.facebook.com/v8.0/dialog/oauth?client_id=707712269839079&redirect_uri=http://localhost/tjc/retailer/integrations/?app=facebook&response_type=code&scope=pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,public_profile,instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_read_user_content,pages_manage_engagement",
      // `${authUrl}?client_id=${clientId}&redirect_uri=${redirect_url}&response_type=code&scope=${scope}&access_type=offline`,
      "_self"
    );
  //www.facebook.com/v8.0/dialog/oauth?client_id=327230355043966&redirect_uri=https://retailer.tanika.tech/integrations/?app=facebook&response_type=code&scope=pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,public_profile,instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_read_user_content,pages_manage_engagement

  const responseFacebook = (response) => {
    console.log(response);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        {/* <FacebookLogin
            appId={clientId}
            autoLoad={true}
            fields="name,email,picture"
            scope={scope}
            callback={responseFacebook}
            // icon="fa-facebook"
            redirectUri={`https://retailer.tanika.tech/integrations/?app=facebook`}
            size="small"
          /> */}
        <Button
          // variant="contained"
          sx={{ border: "1px solid #e8e8e8" }}
          startIcon={<Facebook />}
          size="large"
          onClick={googlePress}
          disabled={facebookForm && facebookForm.access_token}
        >
          Sign In with Facebook
        </Button>
        {facebookForm && facebookForm.access_token ? (
          <Typography sx={{ color: "success.main" }} variant="h6">
            connected
          </Typography>
        ) : (
          <Typography variant="h6">Not Connected</Typography>
        )}
      </Stack>
      {/* <DialogActions>
        <Button onClick={() => toggleConfigure(false)}>Cancel</Button>
      </DialogActions> */}
    </>
  );
};
const WhatsappConfiguration = ({ toggleConfigure, whatsappForm }) => {
  const clientId = "707712269839079";
  const scope =
    "whatsapp_business_messaging,whatsapp_business_management,pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,public_profile,instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_read_user_content,pages_manage_engagement";
  const redirect_url = `http://localhost:3000/integrations?app=whatsapp&response_type=code&scope=${scope}`;
  let authUrl = "https://www.facebook.com/v8.0/dialog/oauth";
  let link = `${authUrl}?client_id=${clientId}&redirect_uri=${redirect_url}`;

  const onAuth = () => window.open(link, "_self");

  return (
    <>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          {/* <FacebookLogin
            appId={clientId}
            autoLoad={true}
            fields="name,email,picture"
            scope={scope}
            callback={responseFacebook}
            // icon="fa-facebook"
            redirectUri={`https://retailer.tanika.tech/integrations/?app=facebook`}
            size="small"
          /> */}
          <Button
            // variant="contained"
            sx={{ border: "1px solid #e8e8e8" }}
            startIcon={<Whatsapp />}
            size="large"
            onClick={onAuth}
            disabled={whatsappForm.access_token}
          >
            Authenticate Whatsapp
          </Button>
          {whatsappForm.access_token ? (
            <Typography sx={{ color: "success.main" }} variant="h6">
              connected
            </Typography>
          ) : (
            <Typography variant="h6">Not Connected</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleConfigure(false)}>Cancel</Button>
        {/* <Button>Add</Button> */}
      </DialogActions>
    </>
  );
};

const IntegrationCard = ({
  title,
  logo,
  selected,
  onSwitchChange,
  configurePress,
  commingSoon,
  revokeClick,
  revoking,
  sheetClick,
  showSheet,
  toggleGupshup,
  showSwitch,
  loading,
}) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Card
      sx={{
        boxShadow: " 0 4px 4px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        "&:hover": { boxShadow: "0 8px 8px 0 rgba(0,0,0,0.2)" },
      }}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Tabs>
          <Tab label="Overview" />
          <Tab label="How to do" />
          <Tab label="Details" />
        </Tabs>

        <Stack direction={"row"} alignItems="center">
          {logo}
          <Typography variant="h3" color="text.secondary" ml={3}>
            {title}
          </Typography>
          {showSwitch && (
            <Switch
              onChange={(e) => onSwitchChange && onSwitchChange(e)}
              // onClick={onChange}
              // disabled={disabled}
              // checkedChildren="Checked"
              // unCheckedChildren="Unchecked"
              disabled={commingSoon || loading}
              checked={selected}
              style={
                selected
                  ? {
                      marginLeft: "auto",
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                  : {
                      marginLeft: "auto",
                    }
              }
            />
          )}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          mt={4}
        >
          <Stack
            direction={"row"}
            // justifyContent="flex"
            alignItems={"center"}
          >
            <IconButton
              onClick={() => revokeClick()}
              disabled={revoking || loading || !showSwitch}
              color="error"
            >
              <PowerSettingsNew />
            </IconButton>
            {sheetClick && showSheet && (
              <IconButton onClick={sheetClick} disabled={revoking || loading}>
                <GoogleSheet />
              </IconButton>
            )}
          </Stack>
          <Button
            sx={{ color: "text.secondary" }}
            startIcon={
              commingSoon ? (
                <Clock style={{ width: "15px" }} />
              ) : (
                <Settings style={{ width: "15px" }} />
              )
            }
            onClick={() => configurePress(true, title)}
            disabled={commingSoon || loading}
          >
            {commingSoon ? "Comming soon" : `Configure`}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Integrations;

const getGupshup = async (setLoading) => {
  setLoading(true);
  const website = getWebsite();
  const token = getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/store/v1/apps/?apps=gupshup`,
      headers: { Authorization: `Basic ${token}` },
      // method: "POST",
      // data: { gupshup_app_name: name, gupshup_api_key: key },
    })
      .then((res) => {
        console.log(res.data);
        let { data } = res;
        if (data.success) {
          if (isArrayWithValues(data.data)) {
            if (data.data[0].gupshup) return data.data[0].gupshup;
          }
        }
      })
      .catch((e) => console.log(getAxiosError(e)))
      .finally(() => {
        setLoading(false);
      });
};
const updateGupshup = async (
  name,
  key,
  enabled,
  setLoading,
  setToast,
  closeDialog
) => {
  setLoading(true);
  const website = getWebsite();
  const token = getToken();
  console.log(name, key, enabled);
  if (website && token)
    axios({
      url: `${website}/wp-json/store/v1/apps/?apps=gupshup&enabled=${Boolean(
        enabled
      )}`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      // params: {
      //   // gupshup_app_name: name,
      //   // gupshup_api_key: key,
      //   enabled: Boolean(!enabled),
      // },
    })
      .then((res) => {
        let { data } = res;
        console.log(data);
        if (data.success) {
          setToast({
            show: true,
            severity: "success",
            message: "Gupshup Integrated successfully.",
          });
          closeDialog && closeDialog();
        } else
          setToast({
            show: true,
            severity: "error",
            message:
              data.data || "Couldn't Integrate Gupshup, Please try again!.",
          });
      })
      .catch((e) => {
        console.log(JSON.stringify(getAxiosError(e), null, 2));
        setToast({
          show: true,
          severity: "error",
          message: "Couldn't Integrate Gupshup, Please try again later!.",
        });
      })
      .finally(() => setLoading(false));
};
const updateGupshupWithoutEnable = async (
  name,
  key,
  setLoading,
  setToast,
  closeDialog
) => {
  setLoading(true);
  const website = getWebsite();
  const token = getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/store/v1/apps/?apps=gupshup`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: {
        gupshup_app_name: name,
        gupshup_api_key: key,
      },
    })
      .then((res) => {
        let { data } = res;
        if (data.success) {
          setToast({
            show: true,
            severity: "success",
            message: "Gupshup Integrated successfully.",
          });
          closeDialog && closeDialog();
        } else
          setToast({
            show: true,
            severity: "error",
            message:
              data.data || "Couldn't Integrate Gupshup, Please try again!.",
          });
      })
      .catch((e) => {
        console.log(JSON.stringify(getAxiosError(e), null, 2));
        setToast({
          show: true,
          severity: "error",
          message: "Couldn't Integrate Gupshup, Please try again later!.",
        });
      })
      .finally(() => setLoading(false));
};
const updateGupshupEnable = async (
  enabled,
  setLoading,
  setToast,
  closeDialog
) => {
  setLoading(true);
  const website = getWebsite();
  const token = getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/store/v1/apps/?apps=gupshup`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: {
        enabled: Boolean(enabled),
      },
    })
      .then((res) => {
        let { data } = res;
        if (!data.success)
          setToast({
            show: true,
            severity: "error",
            message: data.data || "Couldn't Update Gupshup, Please try again!.",
          });
      })
      .catch((e) => {
        console.log(JSON.stringify(getAxiosError(e), null, 2));
        setToast({
          show: true,
          severity: "error",
          message: "Couldn't Update Gupshup, Please try again later!.",
        });
      })
      .finally(() => setLoading(false));
};
const updateGoogle = async (
  access_token,
  refresh_token,
  spreadsheetId,
  enabled
) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    const res = await axios({
      url: `${website}/wp-json/store/v1/apps/?apps=google`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: {
        google_access_token: access_token,
        google_refresh_token_token: refresh_token,
        google_spreadsheetId: spreadsheetId,
        enabled: Boolean(enabled),
      },
    });
    return res;
  }
};
const updateGoogleEnabled = async (enabled) => {
  console.log(Boolean(enabled), "Value ");
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    const res = await axios({
      url: `${website}/wp-json/store/v1/apps/?apps=google`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      params: { enabled: Boolean(enabled) },
    });
    return res.data;
  }
};
const getUserData = async (access_token) => {
  const res = await axios({
    url: `https://www.googleapis.com/oauth2/v3/userinfo`,
    params: { access_token },
    // headers: { Authorization: `Basic ${token}` },
    // method: "POST",
    // data: { gupshup_app_name: name, gupshup_api_key: key },
  });
  return res;
};
const getSpreadSheetData = async (access_token) => {
  console.log("access token", access_token);
  const res = await axios({
    url: `https://sheets.googleapis.com/v4/spreadsheets`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data: { properties: { title: "Tanika - Product Sheet" } },
    method: "POST",
  });
  return res;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const jweroProductKeys = [
  "name",
  "type",
  "status",
  "featured",
  "catalog_visibility",
  "description",
  "short_description",
  "sku",
  "price",
  "regular_price",
  "sale_price",
  "on_sale",
  "purchasable",
  "virtual",
  "stock_quantity",
  "backorders_allowed",
  "low_stock_amount",
  "sold_individually",
  "weight",
  "dimensions",
  "shipping_required",
  "shipping_taxable",
  "reviews_allowed",
  "categories",
  "tags",
  "images",
  "attributes",
  "variations",
  "price_html",
  "meta_data",
  "stock_status",
  "subcategory",
  "collections",
];

// function onEdit(e) {
//   console.log(e);
//   let headerNames = [
//     "Diamond Quality",
//     "Diamond Shapes",
//     "Diamond Sieve",
//     "Diamond Cut",
//     "Gemstone Quality",
//     "Gemstone Shape",
//     "Gemstone Type",
//   ];
//   let currentSpreadSheet = SpreadsheetApp.getActiveSpreadsheet();
//   let currentSheetName = currentSpreadSheet.getActiveSheet().getName();
//   let currentActiveCell = currentSpreadSheet.getActiveCell();
//   let headerName = currentSpreadSheet
//     .getRange(`${rows[currentActiveCell.getColumn() - 1]}1`)
//     .getValue();
//   if (headerNames.includes(headerName)) {
//     let value = e.value;
//     let oldValue = e.oldValue;
//     if (!oldValue) currentActiveCell.setValue(value);
//     else if (value && oldValue)
//       currentActiveCell.setValue(`${value}, ${oldValue}`);
//     else currentActiveCell.setValue("");
//   }
// }
// const rows = [
//   "A",
//   "B",
//   "C",
//   "D",
//   "E",
//   "F",
//   "G",
//   "H",
//   "I",
//   "J",
//   "K",
//   "L",
//   "M",
//   "N",
//   "O",
//   "P",
//   "Q",
//   "R",
//   "S",
//   "T",
//   "U",
//   "V",
//   "W",
//   "X",
//   "Y",
//   "Z",
//   "AA",
//   "AB",
//   "AC",
//   "AD",
//   "AE",
//   "AF",
//   "AG",
//   "AH",
//   "AI",
//   "AJ",
//   "AK",
//   "AL",
//   "AM",
//   "AN",
//   "AO",
//   "AP",
//   "AQ",
//   "AR",
//   "AS",
//   "AT",
//   "AU",
//   "AV",
//   "AW",
//   "AX",
//   "AY",
//   "AZ",
//   "BA",
//   "BB",
//   "BC",
//   "BD",
//   "BE",
//   "BF",
//   "BG",
//   "BH",
//   "BI",
//   "BJ",
//   "BK",
//   "BL",
//   "BM",
//   "BN",
//   "BO",
//   "BP",
//   "BQ",
//   "BR",
//   "BS",
//   "BT",
//   "BU",
//   "BV",
//   "BW",
//   "BX",
//   "BY",
//   "BZ",
// ];
