import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  DefaultLabel,
  DefaultLabelSmall,
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelExtraSmall,
} from "../../../../helper/utility/Labels";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";
import { isArrayWithValues, isObjWithValues } from "jwero-javascript-utils";
import { useDispatch, useSelector } from "react-redux";
import WebChatIconConfigure from "./components/WebChatIconConfigure";
import {
  addUpdateNestedSettings,
  fetchAndUpdateSingleSettings,
} from "../../../../redux/actions/settingActions";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../../../redux/actions/helperActions";
import {
  DesktopTimePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import WebChatWelcomeFormConfigure from "./components/WebChatWelcomeFormConfigure";
import Switch from "rc-switch";
import CustomSwitch from "../../../components/CustomeSwitch";

const FlowSettings = () => {
  const [form, setForm] = useState({
    default_flow: {},
  });
  const flowsList = useSelector((state) => state.flow?.flowsList);
  const flowSettings = useSelector((state) => state.settings?.flowSettings);
  const flowSettingsLoading = useSelector(
    (state) => state.settings?.flowSettingsLoading
  );
  const [flowsOptions, setFlowsOptions] = useState([]);
  const webchatIconConfigure = useRef();
  const webchatWelcomFormConfigure = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isObjWithValues(flowSettings)) {
      setForm(flowSettings);
      webchatIconConfigure?.current?.setupForm(
        flowSettings?.web_icon_customization
      );
      webchatWelcomFormConfigure?.current?.setupForm(
        flowSettings?.welcome_form
      );
    }
  }, [flowSettings]);

  // useEffect(() => {
  //   if (isObjWithValues(form?.welcome_form)) onSaveSettings();
  // }, [form?.welcome_form]);

  useEffect(() => {
    if (!isArrayWithValues(flowsList)) return;
    let array = [];
    array?.push({ label: "None", value: "" });
    array = array.concat(
      flowsList
        ?.filter((i) => i.status === "publish")
        ?.map((i) => ({ label: i.title, value: i.id }))
    );
    setFlowsOptions(array);
  }, []);

  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));

  const webIconCustomizePress = () => {
    webchatIconConfigure?.current?.setupForm(
      flowSettings?.web_icon_customization
    );
    webchatIconConfigure?.current?.open();
  };
  const welcomeFormEditPress = () => {
    webchatWelcomFormConfigure?.current?.setupForm(flowSettings?.welcome_form);
    webchatWelcomFormConfigure?.current?.open();
  };
  const onSaveSettings = async () => {
    let obj = { ...form };
    // delete
    dispatch(
      fetchAndUpdateSingleSettings({
        reduxKey: "flowSettings",
        setting_name: "flow_settings",
        loadingKey: "flowSettingsLoading",
        payload: obj,
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Settings has been updated",
              severity: "success",
            })
          );
        },
      })
    );
  };

  const onConfigureSave = (form) => {
    editForm({ web_icon_customization: form });
  };

  const onWelcomeFormSubmit = (form) => {
    editForm({ welcome_form: form });
  };

  return (
    <Box>
      <ToolbarComponent
        loading={flowSettingsLoading}
        onSaveSettings={onSaveSettings}
      />
      <Box sx={{ mt: 2, px: 3 }}>
        <SecondaryLabel>Web Chat bot</SecondaryLabel>
        <Box sx={{ mt: 3 }}>
          <SettingsRow
            title="Enable Web Chat Icon"
            subtitle={
              "Adjust your settings to enable or disable the chatbot feature. Disabling will remove the icon from the website."
            }
            type="switch"
            value={form?.enable_webchat_icon || ""}
            onChange={(e) => editForm({ enable_webchat_icon: e })}
          />
          <SettingsRow
            title="Customize Web Chat Icon"
            subtitle={"Customize you web chat icon as per your preferences"}
            type="button"
            buttonProps={{
              label: "Configure web chat icon",
              onClick: () => webIconCustomizePress(),
            }}
          />
          <SettingsRow
            title="Welcome Form"
            subtitle={
              "Customize a welcome form to ask questions before using the chatbot"
            }
            type="button"
            buttonProps={{
              label: "Configure welcome form",
              onClick: () => welcomeFormEditPress(),
            }}
          />
          <SettingsRow
            title="Default Flow"
            subtitle={"When user messages anything this flow will start"}
            options={flowsOptions}
            type="dropdown"
            value={form?.default_flow?.webchat || ""}
            onChange={(e) =>
              editForm({
                default_flow: {
                  ...form?.default_flow,
                  webchat: form?.default_flow?.webchat === e ? "" : e,
                },
              })
            }
          />
        </Box>
        <Divider sx={{ mt: 3 }} />
        <SecondaryLabel sx={{ mt: 3 }}>Whatsapp bot</SecondaryLabel>
        <Box sx={{ mt: 3 }}>
          <SettingsRow
            title="Default Flow"
            subtitle={"When user messages anything this flow will start"}
            options={flowsOptions}
            type="dropdown"
            value={form?.default_flow?.whatsapp || ""}
            onChange={(e) => {
              editForm({
                default_flow: {
                  ...form?.default_flow,
                  whatsapp: e,
                },
              });
            }}
          />
        </Box>
        <Divider sx={{ mt: 3 }} />
        <SecondaryLabel sx={{ mt: 3 }}>Other Details</SecondaryLabel>
        <Box sx={{ mt: 3 }}>
          <SettingsRow
            title="Agent available from"
            subtitle={"Time when user agent is available to chat"}
            options={flowsOptions}
            type="time"
            value={form?.agent_available_from || ""}
            onChange={(e) => editForm({ agent_available_from: e })}
          />
          <SettingsRow
            title="Agent available from"
            subtitle={"Time when user agent will not available to chat"}
            options={flowsOptions}
            type="time"
            value={form?.agent_available_till || ""}
            onChange={(e) => editForm({ agent_available_till: e })}
          />
        </Box>
      </Box>
      <WebChatIconConfigure
        ref={webchatIconConfigure}
        onConfigureSave={onConfigureSave}
      />
      <WebChatWelcomeFormConfigure
        ref={webchatWelcomFormConfigure}
        onWelcomeFormSubmit={onWelcomeFormSubmit}
      />
    </Box>
  );
};

export default FlowSettings;

export const SettingsRow = ({
  title,
  subtitle,
  options = [],
  type,
  multiple,
  value,
  onChange,
  label,
  buttonProps,
}) => {
  return (
    <HorizontalStack
      spacing={2}
      sx={{ mt: 3 }}
      style={{ justifyContent: "space-between" }}
    >
      <Box>
        <DefaultLabelSmall>{title}</DefaultLabelSmall>
        <SecondaryLabelExtraSmall sx={{ mt: 1 }}>
          {subtitle}
        </SecondaryLabelExtraSmall>
      </Box>
      {type === "dropdown" && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          size="small"
          sx={{ maxWidth: 180 }}
        >
          {options.map((i) => {
            return (
              <MenuItem value={i.value} key={i.value}>
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
        // <Autocomplete
        //   disablePortal
        //   id="combo-box-demo"
        //   options={options}
        //   size="small"
        //   sx={{ maxWidth: 180 }}
        //   value={value}
        //   onChange={(e, v) => onChange(v)}
        //   fullWidth
        //   renderInput={(params) => <TextField {...params} label={label} />}
        // />
      )}
      {type === "button" && (
        <Button variant="outlined" size="small" {...buttonProps}>
          {buttonProps?.label}
        </Button>
      )}
      {type === "switch" && (
        <CustomSwitch checked={value} onChange={(e) => onChange(e)} />
      )}
      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            // disableFuture
            // label="First Purchase"
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                // {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1, maxWidth: 180 }}
                error={false}
                size="small"
              />
            )}
            inputFormat=""
          />
        </LocalizationProvider>
      )}
    </HorizontalStack>
  );
};

const ToolbarComponent = ({ onSaveSettings, loading }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
        justifyContent: "space-between",
      }}
    >
      <Typography
        // sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Configuration
      </Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={onSaveSettings}
      >
        Save changes
      </LoadingButton>
    </Toolbar>
  );
};
