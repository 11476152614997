import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { userMessageStyles } from "../utils/userMessageStyles";
import PropTypes from "prop-types";

const styles = userMessageStyles;

const UserMessages = ({ message }) => {

  const web_chat_customization = useSelector(
    (state) => state.flow?.chatbotCustomization
  );
  const bubbleColor = web_chat_customization?.icon_color
  return (
    <>
      {/* <div style={styles.mainDiv}>
        <Typography style={styles.chatHeaderText}>You</Typography>
      </div> */}
      <div style={styles.chatFieldDiv}>
        <Typography variant="body1" style={{...styles.chatTextField, backgroundColor: bubbleColor}}>
          {message.text}
        </Typography>
      </div>
      <div style={styles.chatFooterDiv}>
        <Typography style={styles.chatFooterText} variant="span">
          Read
        </Typography>
      </div>
    </>
  );
};

UserMessages.propTypes = {
  message: PropTypes.object.isRequired,
};

export default UserMessages;
