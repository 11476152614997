import axios from "axios";

const downloadPDF = async ({ link: _link }) => {
  try {
    const response = await axios.get(_link, {
      responseType: "blob", // Important to set this for binary data
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "filename.pdf"); // Provide desired file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return true;
  } catch (error) {
    console.error("Error downloading the file.", error);
  }
};
const openPDF = async ({ link }) => {
  try {
    const response = await axios.get(link, {
      responseType: "blob", // Important to set this for binary data
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    window.open(url);
    return true;
  } catch (error) {
    console.error("Error opening the file.", error);
  }
};
export { downloadPDF, openPDF };
