export const nodePopoverStyles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    padding: "5px 0px 5px 0px",
    marginTop: "5px",
    overflowY: "auto",
    minHeight: "300px",
    maxHeight: "500px",
    fontFamily: "Inter",
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "7px 50px 7px 15px",
    "&:hover": {
      backgroundColor: "#e3e3e3!important",
      cursor: "pointer",
    },
    marginBottom: "8px",
  },
  iconButton: {
    backgroundColor: "#e2e5e9",
    borderRadius: "50%",
    marginRight: "15px",
    height: "26px",
    width: "26px",
    color: "#22304a",
  },
  icon: {
    fontSize: "17px",
    fontWeight: 600,
  },
  textField: {
    fontSize: "15px",
    "&:hover": {
      color: "blue",
    },
  },
};
