export const smsStyles = {
  mainContainer: {
    paddingLeft: "17px",
    marginBottom: "7px",
    fontFamily: "Inter",
  },
  titleBox: {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#566e8a",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
  },
  titleText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#566e8a",
  },
  nodeBox: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeBody: {
    flex: 1,
    margin: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "60px",
    width: "80px",
    padding: "0 10px 0 8px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: "#98E4FF",
    borderRadius: "10px",
  },
  nodeInnerBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
  },
  handleBox: {
    display: "flex",
    flexDirection: "column",
    height: "60px",
    alignItems: "flex-end",
    position: "relative",
    justifyContent: "space-evenly",
    right: "40px",
  },
  handleInnerBox: {
    display: "flex",
    flexDirection: "row",
  },
  titleName: {
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 550,
  },
  nodeIcon: {
    fontSize: "16px",
    color: "#000",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#98E4FF",
    },
  },
  addIcon: {
    fontSize: "8px",
    color: "#22304a",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    cursor: "pointer",
    zIndex: "9999",
    transition: "visibility 0s, color 0.2s",
    "&:hover": {
      color: "blue",
      cursor: "pointer",
    },
  },
  leftHandle: {
    background: "#566d89",
    position: "relative",
    top: "30px",
    left: "5px",
    opacity: "0",
  },
  rightHandle: {
    background: "#566d89",
    opacity: "0",
    left: "-5px",
    top: "4px",
    position: "relative",
  },
  handleText: {
    fontSize: "7px",
    color: "#384350",
    fontFamily: "Inter",
    marginBottom: "2px",
  },
};
