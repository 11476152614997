/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { useTheme as muiUseTheme } from "@mui/material/styles";
import jssPreset from "@mui/styles/jssPreset";
import "./i18n";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import {
  Base64,
  checkAdvanceAccess,
  devLog,
  formatServerValue,
  getAxiosError,
  getFullName,
  getId,
  getIsBackendMigrated,
  getMainWebsite,
  getRandomString,
  getRelativeTimeDifference,
  getTenantId,
  getTimeInMilliSeconds,
  getToken,
  getUserProduct,
  getWebsite,
  hasAccessInPlan,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isOurWebsite,
  liveSocketLink,
  liveSocketLinkWithoutProtocol,
  nodeWebsiteWithoutProtocol,
  primaryLabelLarge,
  putTourHistory,
  secondaryLabel,
  setItem,
  settingsEndpoint,
  settingsEndpointWithoutCreds,
  setUpRoutes,
  sleep,
  unformatServerValue,
  validateNumber,
  websocketLink,
} from "./helper";
import firebase from "./firebase.js";
import { UAParser } from "ua-parser-js";
import axios from "axios";
import {
  deleteGoogleIntegrationFromRedux,
  fetchAllNewSettings,
  fetchAllSettings,
  updateGoogleAccessTokenInRedux,
} from "./redux/actions/settingActions";
import {
  addVisitor,
  getMoreVisitors,
  getPresetVisitors,
  setupLiveCatalogs,
} from "./redux/actions/visitorActions";
import { io } from "socket.io-client";
import { setMainSocket } from "./redux/actions/socketActions";

import {
  SET_PERSIST_DATA,
  getDropdownData,
  setPersistData,
} from "./redux/actions/persistActions";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  setGlobalPopup,
  setGlobalSnackbar,
  setGlobalToast,
  setHelperData,
  SET_HELPER_DATA,
} from "./redux/actions/helperActions";
import {
  addResponseInChat,
  deleteChatUserInRedux,
  getConversations,
  handleSocialMessage,
  setUpConversations,
  setUpConversationsAccess,
  setupUserTasks,
  updateCampaignHistory,
  updateChatUserInRedux,
} from "./redux/actions/chatActions";
import { Call, Cancel, CheckCircle, Info } from "@mui/icons-material";
import { InstagramSvg, Messenger, WebchatSvg, Whatsapp } from "./Svgs";
import {
  addCatalogLive,
  replaceQuotationInRedux,
  setupCatalogs,
} from "./redux/actions/productActions";

import useAuth from "./hooks/useAuth";
import TutorialVideo from "./pages/components/TutorialVideo";
import PlanExpiryWarning from "./pages/components/PlanExpiryWarning";
import PlanUpgraded from "./pages/components/PlanUpgraded";
import { Mixpanel } from "./mixpanel";
import { drawerWidth } from "./layouts/Dashboard";
import DeviceLoginExceed from "./pages/components/DeviceLoginExceed";
import GetStartedDialog from "./pages/components/GetStartedDialog";
import OnBoardingDialog, {
  starterPageContent,
} from "./pages/components/OnBoardingDialog";

import { useTour } from "@reactour/tour";
import {
  addTourHistory,
  appendIntegrations,
} from "./redux/actions/userActions";
import { fetchCustomer } from "./pages/crm/View";
import SupplierBasicProfileDialog from "./pages/components/SupplierBasicProfileDialog";
import { Check, Close } from "@material-ui/icons";
import * as flowActions from "../src/redux/actions/flowsActions";
import { PrimaryLabel, PrimaryLabelSmall } from "./helper/utility/Labels.js";
import { LoadingButton } from "@mui/lab";
import { assignTeamMember, updateChatUser } from "./helper/api/chat_user.js";
import { createUserActivity } from "./helper/api/userActivity.js";
import IncomingRequestToast from "./components/webchat/IncomingRequestToast.js";

// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const REFRESH_TYPE = 3600 * 1000;

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

export const tutorialData = {
  master_pricing: {
    link: "https://jweroacademy.trainn.co/share/1kJ87wxElnfhf1DTuJWSXg/embed?autoplay=false",
    title: "Powerful Master Selection⚙️",
  },
  metal_pricing: {
    link: "https://jweroacademy.trainn.co/share/5SeJ0WM7EsgmtwJ8Xo7tZQ/embed?autoplay=false",
    title: "Metal Pricing Set-Up",
  },
  Labour_rates_combination: {
    link: "https://retailconnect.trainn.co/share/AP1bah2n5LK5bL5WMtBWUA/embed?autoplay=false",
    title: "Labour Rates Combination Set-Up",
  },
  diamond_combination: {
    link: "https://retailconnect.trainn.co/share/YCP83KdKdQHd1Ek8TyvmKA/embed?autoplay=false",
    title: "Diamond Combinations Settings",
  },
  shipping_settings: {
    link: "https://retailconnect.trainn.co/share/d6vg6NB38vjNIo1hGxo5Pw/embed?autoplay=false",
    title: "Shipping Settings ",
  },
  tax_settings: {
    link: "https://retailconnect.trainn.co/share/sflXeC720DWlFekIQoOEaQ/embed?autoplay=false",
    title: "Setting Up Tax.",
  },
  upload_product: {
    link: "https://jweroacademy.trainn.co/share/S3nS58OoWt9SZC2FeMyBlA/embed?autoplay=false",
    title: "Upload Products Manually.",
  },
  create_catalog: {
    link: "https://retailconnect.trainn.co/share/MJO9NjR3c7wXVU5u71X5Lg/embed?autoplay=false",
    title: "How to Create Catalog.",
  },
  catalogues_overview: {
    link: `https://jweroacademy.trainn.co/share/bDxq5di7png7f2Y1jrOYNQ/embed?autoplay=false`,
    // link: `https://jweroacademy.trainn.co/share/5gRbYZcqZJ7nzCEzmOrBng/embed?autoplay=false`,
    title: "Catalogues Overview ",
  },
  size_Masters: {
    link: `https://jweroacademy.trainn.co/share/T8ooS6uh8sfE0ZzL0qHKjA/embed?autoplay=false`,
    title: "Set Up Size Masters",
  },
  update_metal_rates_from_dashboard: {
    link: "https://jweroacademy.trainn.co/share/sWhjdh51hDCILfxipa0i3g/embed?autoplay=false",
    title: "Update Metal rates From Dashboard",
  },
  teamMember: {
    link: `https://jweroacademy.trainn.co/share/voSIO3ELRyfkouG0HWWUvQ/embed?autoplay=false`,
    title: "Add Team Members and Assign Them Module Access",
  },
  ornate: {
    link: `https://jweroacademy.trainn.co/share/Tyaqt3naHX5OPj0LzZ3ZYQ/embed?autoplay=false`,
    title: "Ornate Integartions",
  },
  chats: {
    link: `https://jweroacademy.trainn.co/share/6Ni267ZtPIEDGcAAFn6ccQ/embed?autoplay=false`,
    title: "Chats tutorial",
  },
  config_whatsapp: {
    link: "https://jweroacademy.trainn.co/share/7VqsqVWEYwhiP8xbmcM5CQ/embed?autoplay=false",
    title: "Setup WhatsApp",
  },
};

export const planRoutes = {
  necessary: [
    "notifications",
    "",
    "auth/sign-in",
    "auth/register",
    "auth",
    "profile",
    "migration",
    "user_tasks",
    "*",
    "/",
    "pricing",
    "spreadsheet_history",
    "crm_bulk_add",
    "crm_bulk_edit",
    "flows",
  ],

  chats: ["dashboard", "chats", "settings", "integrations", "user_activities"],
  chats_pro: [
    "dashboard",
    "chats",
    "settings",
    "integrations",
    "user_activities",
  ],
  whatsapp: [
    "dashboard",
    "chats",
    "settings",
    "integrations",
    "user_activities",
  ],
  catalog: [
    "dashboard",
    "products",
    "crm",
    "catalogs",
    "settings",
    "integrations",
    "visitors_info",
    "user_activities",
    "campaign",
  ],
  "e-commerce": [
    "dashboard",
    "products",
    "crm",
    "orders",
    "frontend",
    "settings",
    "integrations",
    "visitors_info",
    "campaign",
  ],
  crm: ["dashboard", "crm", "settings", "integrations", "user_activities"],
  ornate: ["dashboard", "products", "integrations"],
  products: ["dashboard", "products"],
  digital_gold: ["digigold"],
};

export const planTabs = {
  chats: ["Dashboard", "Chats", "Settings", "Integrations"],
  chats_pro: ["Dashboard", "Chats", "Settings", "Integrations"],
  whatsapp: ["Dashboard", "Chats", "Settings", "Integrations", "Activities"],
  catalog: [
    "Dashboard",
    "Products",
    "CRM",
    "Catalogs",
    "Settings",
    "Activities",
    "Integrations",
    // "Campaign",
    "Visitor Info",
  ],
  "e-commerce": [
    "Dashboard",
    "Products",
    "CRM",
    "Orders",
    "Frontend",
    "Integrations",
    "Settings",
    // "Campaign",
    "Visitor Info",
  ],
  crm: [
    "Dashboard",
    "CRM",
    "Integrations",
    "Activities",
    "Settings",
    // "Campaign",
    "Visitor Info",
  ],
  ornate: ["Dashboard", "Products", "Integrations"],
  products: ["Dashboard", "Products"],
  digital_gold: ["Digigold"],
};

export const settingsTabsByProduct = {
  catalog: [
    { label: "Master" },
    { label: "Pricing" },
    { label: "Custom Fields" },
    { label: "Product" },
    { label: "Profile" },
    { label: "Team Members" },
    { label: "Email" },
  ],
  chats: [
    { label: "Master" },
    { label: "Custom Fields" },
    { label: "Team Members" },
    { label: "Profile" },
    { label: "Pricing" },
  ],
  chats_pro: [
    { label: "Master" },
    { label: "Custom Fields" },
    { label: "Team Members" },
    { label: "Profile" },
    { label: "Pricing" },
  ],
  whatsapp: [
    // { label: "Profile" },
    // { label: "Master" },
    { label: "Pricing" },
    { label: "Custom Fields" },
    { label: "Team Members" },
  ],
  "e-commerce": [
    { label: "Master" },
    { label: "Pricing" },
    { label: "Custom Fields" },
    { label: "Product" },
    { label: "Team Members" },
    { label: "Shipping" },
    { label: "Coupons" },
    { label: "Tax" },
    { label: "Email" },
    { label: "SEO" },
    { label: "Profile" },
  ],
  products: [
    { label: "Master" },
    { label: "Custom Fields" },
    { label: "Pricing" },
    { label: "Product" },
  ],
  crm: [
    { label: "Team Members" },
    { label: "Custom Fields" },
    { label: "Email" },
    { label: "Profile" },
  ],
};

export const integrationsByProduct = {
  catalog: ["Google", "Meta"],
  chats: ["Google", "Meta"],
  chats_pro: ["Google", "Meta"],
  whatsapp: ["Google", "Meta"],
  "e-commerce": [
    "Google",
    "Meta",
    "Onesignal",
    "Blue Dart",
    "BVC",
    "Tryb4ubuy",
    "Mirrar",
    // "Ornate",
    "JEMiSys",
    "Camweara",
    "Cashfree",
    "Razorpay",
    "PayU",
    "EaseBuzz",
    "CCAvenue",
  ],
  crm: [
    "Google",
    // "Meta"
  ],
  ornate: ["Ornate"],
};

export const googleIntegrationsByProduct = {
  catalog: ["google_sheets", "google_analytics"],
  chats: [
    "google_calendar",
    // "tag_manager",
    "google_sheets",
  ],
  chats_pro: [
    "google_calendar",
    // "tag_manager",
    "google_sheets",
  ],
  whatsapp: [
    "google_calendar",
    // "tag_manager",
    "google_sheets",
  ],
  "e-commerce": [
    "google_sheets",
    "google_analytics",
    "google_siteverification",
    "firebase",
    "tag_manager",
  ],
  crm: [
    "google_calendar",
    // "google_business",
    "google_sheets",
    // "google_analytics",
  ],
};
export const metaIntegrationsByProduct = {
  catalog: ["whatsapp"],
  chats: ["facebook", "whatsapp", "instagram"],
  chats_pro: ["facebook", "whatsapp", "instagram", "commerce"],
  whatsapp: ["whatsapp"],
  "e-commerce": ["commerce"],
};

export const sidebarExtraWidth = drawerWidth;
const TourContentComponent = ({
  title,
  contentArray = [],
  subtitle,
  subtitle2,
}) => {
  return (
    <Box>
      <Typography sx={{ ...primaryLabelLarge, fontSize: "17px" }}>
        {title}
      </Typography>
      <Typography sx={{ ...secondaryLabel, mt: 2, fontSize: "15px" }}>
        {subtitle}
      </Typography>
      {subtitle2 && (
        <Typography sx={{ ...secondaryLabel, mt: 2, fontSize: "15px" }}>
          {subtitle2}
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        {contentArray.map((i) => (
          <Stack direction={"row"} alignItems="flex-start" spacing={2}>
            <Box
              sx={{
                backgroundColor: "#85858599",
                minHeight: "6px",
                minWidth: "6px",
                borderRadius: "50%",
                marginTop: "6px",
              }}
            ></Box>
            <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
              {" "}
              {i}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

const chatTourContent = {
  chats: {
    title: "Chats",
    subtitle: "Manage all your conversations from here:",
    contentArray: [
      "View channel wise (whatsapp, facebook etc) chats.",
      "Send templates or reply to chats.",
      "Create broadcast list & send messages.",
      "Create view and assign activities.",
    ],
  },
  integrations: {
    title: "Integrations",
    subtitle: "Manage all your conversations from here:",
    contentArray: [
      // "View channel wise (whatsapp, facebook etc) chats.",
      // "Send templates or reply to chats.",
      // "Create broadcast list & send messages.",
      // "Create view and assign activities.",
      "Connect your platform to other tools and systems for smooth management:",
      "Integrate with Google products for enhanced functionality",
    ],
  },

  settings: {
    title: "Settings",
    subtitle:
      "Configure and manage your platform settings for best performance:",
    contentArray: [
      "Add, remove, and manage team members for efficient collaboration",
      "Configure other platform settings to customize your experience",
    ],
  },
};
const whatsappTourContent = {
  chats: {
    title: "Chats",
    subtitle: "Manage all your conversations from here:",
    contentArray: [
      // "View channel wise (whatsapp, facebook etc) chats.",
      "Send templates or reply to chats.",
      "Create broadcast list & send messages.",
      "Create view and assign activities.",
    ],
  },
  integrations: {
    title: "Integrations",
    // subtitle: "Manage all your conversations from here:",
    contentArray: [
      "Connect your platform to other tools and systems for smooth management:",
      "Integrate with Google products for enhanced functionality",
    ],
  },

  settings: {
    title: "Settings",
    subtitle:
      "Configure and manage your platform settings for best performance:",
    contentArray: [
      "Add, remove, and manage team members for efficient collaboration",
      "Configure other platform settings to customize your experience",
    ],
  },
};
const crmTourContent = {
  dashboard: {
    title: "Dashboard",
    // subtitle:
    //   "The main page, showing all important statistics, events of this module. The data on this page gets updated automatically based on activities done.",
    subtitle:
      "Manage all your customer’s interactions from one screen to provide them personalize services.",
    contentArray: [
      "Manage contacts",
      "Mark a Contact as Customer or Lead",
      "View detailed analysis on multiple parameters",
    ],
  },
  integrations: {
    title: "Integrations",
    subtitle: "Manage all your conversations from here:",
    contentArray: [
      "View channel wise (whatsapp, facebook etc) chats.",
      "Send templates or reply to chats.",
      "Create broadcast list & send messages.",
      "Create view and assign activities.",
    ],
  },

  settings: {
    title: "Settings",
    subtitle:
      "Configure and manage your platform settings for best performance:",
    contentArray: [
      "Add, remove, and manage team members for efficient collaboration",
      "Configure other platform settings to customize your experience",
    ],
  },
};
const catalogTourContent = {
  dashboard: {
    title: "Dashboard",
    // subtitle:
    //   "The main page, showing all important statistics, events of this module. \n The data on this page gets updated automatically based on activities done.",
    // contentArray: [
    //   "Live Tracking, from the moment a contact opens the catalog ",
    //   "Create Public & Private Catalog and share them via Whatsapp and other channels",
    //   "View Catalog sent and their Response (Request) received",
    //   "Multi-layered product pricing",
    //   "Track Products & Contacts performance ",
    //   "Advance analytics",
    //   "Hassle-free photography ",
    //   "Notification & Alers",
    // ],
    contentArray: [
      "The main page, showing all important statistics, events of this module.",
      "The data on this page gets updated automatically based on activities done.",
    ],
  },
  crm: {
    title: "CRM",
    subtitle:
      "Manage all your customer interactions from one screen and provide them personalise services:",
    contentArray: [
      "Add & manage contact’s profile",
      "Monitor all activities of a contact on one page",
      "View detailed analytics of customer interactions with catalogs and products ",
      "Detailed statics on Catalog & Product view, Selection, Order",
    ],
  },

  integrations: {
    title: "Integrations",
    // subtitle:
    //   "Connect your platform to other tools and systems for smooth management:",
    contentArray: [
      "Connect your platform to other tools and systems for smooth management:",
      "Integrate with Google products for enhanced functionality",
      "Connect with ERP systems such as Ornate for streamlined product management",
      "Integrate payment systems for seamless transactions",
      "Connect to multiple marketplaces such as Amazon, Ebay for expanded reach and sales",
    ],
  },
  settings: {
    title: "Settings",
    subtitle:
      "Control & Manage the preferences, performance and make the working smooth:",
    contentArray: [
      "Set Product Pricing based on Metal, Diamond, Gemstone, Labour etc",
      "Assign Added to Cart product as Sold",
      "Pre-assign fields for a super fast product upload",
      "Manage Your Company Profile ",
    ],
  },
};

const tourContent = {
  dashboard: {
    title: "Dashboard",
    // subtitle:
    //   "The main page, showing all important statistics, events of this module. The data on this page gets updated automatically based on activities done.",
    subtitle:
      "The main page, showing all important statistics, events of this module.",
    subtitle2:
      "The data on this page gets updated automatically based on activities done.",
  },

  products: {
    title: "Products",
    subtitle: "Manage all your jewelry products from here:",
    contentArray: [
      "Add new products to your Product Line",
      "Edit existing product information, such as description, images etc.",
      "Delete products that are no longer needed",
      "Manage Stock status, Offer & Discounts etc.",
    ],
  },
  chats: {
    title: "Chats",
    subtitle: "Manage all your conversations from here:",
    contentArray: [
      "View channel wise (whatsapp, facebook etc) chats",
      "Create & send template messages or reply to chats",
      " Manage all business chats from a single whatsapp number",
      "Manage Contact & bulk actions",
      "View multiple contact's chat from a single business on one single screen",
      "Create broadcast list & send bulk marketing & Promotional messages",
    ],
  },
  crm: {
    title: "CRM",
    subtitle:
      "Manage all your customer interactions from one screen and provide them personalize services:",
    contentArray: [
      "Add & manage contact profile",
      "Communicate with via multiple channels including WA, Social",
      "View detailed analytics of customer interactions such as Website or App visit sessions, Device they used etc",
      "Detailed statics on Catalog & Product view, Abandon cart, Orders",
    ],
  },
  design_bank: {
    title: "Design Bank",
    subtitle: "Get Ready to Sell Inventory without any investment:",
    contentArray: [
      "First Sell then purchase",
      "Invite your suppliers and Pull their stocks to your product-line to sell",
      "View multiple suppliers stock together with your pricing, filter them and select the best for sell",
      "Instantly publish the inventory to your website or app",

      "No more hassles of design, production, investment, photography, listing etc",
    ],
  },
  catalogs: {
    title: "Catalogs",
    subtitle: "Manage all your catalogs from here:",
    contentArray: [
      "Create, recreate, Time-bound, private and public catalogs",
      "Live tracking of catalog, moment they open ",
      "View the response for your catalogs and see who sent them",
      "Get detailed analytics on Catalog, Contact and Product performance",
      "Optimize your offering by viewing un-active contacts and non performing products",
    ],
  },
  orders: {
    title: "Orders",
    subtitle: "Manage all your orders from here:",
    contentArray: [
      "View all orders with their respective status such Cancelled, Pending, Delivered etc",
      "Update the status of orders as they progress",
      "Add offline orders to track them together",
      "Sends notifications, alerts to customer automatically",
    ],
  },
  digital_gold: {
    title: "Digital Gold",
    subtitle:
      "Go from Local to National and Serve your Contacts across the country",
    contentArray: [
      "Manage your Monthly Saving Schemes, hassle-free",
      "Create multiple Fixed & Flexible plans linked with Rewards & Penalty",
      "Get detailed analytics on digital gold plan performance including Missed payment alerts",
      "View plan wise payment & customer information",
      "Accept offline payments across branches",
      "Assign Agents or Referral to a customer",
      "Manage multiple plans, thousands of customers without any accounting hassles",
    ],
  },
  chats: {
    title: "Chats",
    subtitle: "Manage all your customer interactions from here:",
    contentArray: [
      "Communicate with customers through social media channels",
      "Send bulk WhatsApp messages using customizable templates",
      "Create broadcast lists to send messages to multiple customers at once",
      "Create customer-related tasks and automatically add them to Google Calendar",
      "Send quick messages to customers",
      "Assign tasks to team members for follow-up and resolution",
    ],
  },
  social: {
    title: "Social Media",
    subtitle:
      "Share a product or a Post on multiple Social channel, all at once:",
    contentArray: [
      "One click share on Facebook, Instgram, LinkedIn & Google",
      "Track reach or Reply a comment, all from a single dashboard",
      "Schedule a Post",
    ],
  },
  frontend: {
    title: "Frontend",
    subtitle:
      "Complete control to manage your website's Content, Design, now in your hand:",
    contentArray: [
      "Create, update, and delete blog posts",
      "Manage website banners to showcase promotions or featured products",
      "Moderate and manage customer reviews for your website",
    ],
  },
  integrations: {
    title: "Integrations",
    subtitle:
      "Connect your platform to other tools and systems for smooth management:",
    contentArray: [
      "Integrate different social media platforms for seamless cross-posting",
      "Integrate with Google products for enhanced functionality",
      "Connect with ERP systems such as Ornate for streamlined product management",
      "Integrate payment systems for seamless transactions",
      "Connect to multiple marketplaces such as Amazon, Ebay for expanded reach and sales",
    ],
  },
  quotations: {
    title: "Quotations",
    subtitle: "Create Product Quotation within seconds:",
    contentArray: [
      "Convert a Catalog into a quotation at one click",
      "Use it at Office, In Exhibition or at Clients place",
      "Your sales team can create & send quotation themselves on the move",
      "Allow recipients to edit and customize products within the quotation",
      "Make and view comments on quotations for efficient communication",
      "Confirm and finalize quotations to secure sales",
    ],
  },
  settings: {
    title: "Settings",
    subtitle:
      "Configure and manage your platform settings for best performance:",
    contentArray: [
      "Update metal prices to keep product pricing accurate, across the channels",
      "Manage master pricing to control margins and profits for Metal, Diamonds, Gemstones, Labour",
      "Add, remove, and manage team members for efficient collaboration",
      "Configure other platform settings to customize your experience",
    ],
  },
  profile: {
    title: "Profile",
    subtitle: "Manage your profile and account information:",
    contentArray: [
      "Update and maintain your personal profile information",
      "Monitor and manage your team members' activities",
      "View and manage your Jwero plan information",
      "Access and configure other user settings",
    ],
  },
  notification: {
    title: "Notification",
    subtitle: "Receive notifications on important events and activities",
  },
  visit_store: {
    title: "Visit Store",
    subtitle: "You can visit your online store from here",
  },
  integrate_meta_1: {
    title: "Click here to Integrate Meta",
    subtitle: "Step 1 out of 5",
  },
  integrate_meta_2: {
    title: "Select meta to integrate social media channels.",
    subtitle: "Step 2 out of 5",
  },
  integrate_meta_3: {
    title:
      "Select integrations to access social media channels you want to use.",
    subtitle: "Step 3 out of 5",
  },
  integrate_meta_4: {
    title: "Select any channel you want to integrate.",
    subtitle:
      "Please ensure that you already have a verified business page to integrate.",
  },
  integrate_meta_5: {
    title:
      "Continue with facebook, you’ll be redirected to facebook login page, enter your social media details there to integrate.",

    subtitle: "Step 5 out of 5",
  },
  integrate_meta_6: {
    title:
      "Click on settings to select page info and complete integration process",
    // subtitle: "Step 6 out of 8",
  },
  integrate_meta_7: {
    title: "Select facebook page and click on update",
    // subtitle: "Step 7 out of 8",
  },
  integrate_meta_8: {
    title: "Select whatsapp verified number and click on update",
    // subtitle: "Step 8 out of 8",
  },
};

export const metaIntegrationSteps2 = [
  {
    selector: ".Meta-settings-tab",
    content: <TourContentComponent {...tourContent.integrate_meta_6} />,
  },
  {
    selector: ".facebook_page_select",
    content: <TourContentComponent {...tourContent.integrate_meta_7} />,
  },
  {
    selector: ".whatsapp_page_select",
    content: <TourContentComponent {...tourContent.integrate_meta_8} />,
  },
];
export const metaIntegrationSteps = [
  {
    selector: ".sidebar_integrations",
    content: <TourContentComponent {...tourContent.integrate_meta_1} />,
  },
  {
    selector: ".integration-tab-1",
    content: <TourContentComponent {...tourContent.integrate_meta_2} />,
  },
  {
    selector: ".Meta-integration-tab",
    content: <TourContentComponent {...tourContent.integrate_meta_3} />,
  },
  {
    selector: ".Meta-integrations-list",
    content: <TourContentComponent {...tourContent.integrate_meta_4} />,
  },
  {
    selector: ".Meta-integrations-button",
    content: <TourContentComponent {...tourContent.integrate_meta_5} />,
  },
];

export const getAllTourSteps = (user_products = []) => {
  let updatedTourContent = { ...tourContent };
  if (user_products.includes("chats"))
    updatedTourContent = { ...updatedTourContent, ...chatTourContent };
  if (user_products.includes("whatsapp"))
    updatedTourContent = { ...updatedTourContent, ...whatsappTourContent };
  if (user_products.includes("catalog"))
    updatedTourContent = { ...updatedTourContent, ...catalogTourContent };
  return [
    {
      selector: ".sidebar_dashboard",
      content: <TourContentComponent {...updatedTourContent.dashboard} />,
      value: "dashboard",
    },
    {
      selector: ".sidebar_products",
      content: <TourContentComponent {...updatedTourContent.products} />,
      value: "products",
    },
    {
      selector: ".sidebar_crm",
      content: <TourContentComponent {...updatedTourContent.crm} />,
      value: "crm",
    },
    {
      selector: ".sidebar_design_bank",
      content: <TourContentComponent {...updatedTourContent.design_bank} />,
      value: "design_bank",
    },
    {
      selector: ".sidebar_catalogs",
      content: <TourContentComponent {...updatedTourContent.catalogs} />,
      value: "catalogs",
    },
    {
      selector: ".sidebar_orders",
      content: <TourContentComponent {...updatedTourContent.orders} />,
      value: "orders",
    },
    {
      selector: ".sidebar_digigold",
      content: <TourContentComponent {...updatedTourContent.digital_gold} />,
      value: "digital_gold",
    },
    {
      selector: ".sidebar_quotations",
      content: <TourContentComponent {...updatedTourContent.quotations} />,
      value: "quotations",
    },
    {
      selector: ".sidebar_chats",
      content: <TourContentComponent {...updatedTourContent.chats} />,
      value: "chats",
    },
    {
      selector: ".sidebar_social",
      content: <TourContentComponent {...updatedTourContent.social} />,
      value: "social",
    },
    {
      selector: ".sidebar_frontend",
      content: <TourContentComponent {...updatedTourContent.frontend} />,
      value: "frontend",
    },
    {
      selector: ".sidebar_integrations",
      content: <TourContentComponent {...updatedTourContent.integrations} />,
      value: "integrations",
    },
    {
      selector: ".sidebar_settings",
      content: <TourContentComponent {...updatedTourContent.settings} />,
      value: "settings",
    },
    {
      selector: ".profile_icon",
      content: <TourContentComponent {...updatedTourContent.profile} />,
      value: "profile",
    },
    {
      selector: ".notification_icon",
      content: <TourContentComponent {...updatedTourContent.notification} />,
      value: "notification",
    },
  ];
};
export const multipleUserProduct = getUserProduct()?.length > 1;

function App() {
  const [allRoutes, setAllRoutes] = useState(routes);
  const content = useRoutes(allRoutes);
  const { theme } = useTheme();
  const [isOnline, setIsOnline] = useState(true);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const access = useSelector((state) => state.user.access);
  const advanced_access = useSelector((state) => state.user.advanced_access);
  const [integrations, setIntegrations] = useState([]);
  const [googleTokenTimeout, setGoogleTokenTimeout] = useState(0);
  const [tokenWasExpired, setTokenWasExpired] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [webSocketConnected, setWebSocketConnected] = useState(false);
  const [sendingTokenStarted, setSendingTokenStarted] = useState(false);
  const [connectSupplierLoading, setConnectSupplierLoading] = useState(false);
  const [helmetValue, setHelmetValue] = useState({
    titleTemplate: "Jwero - Building Jewelry Rocketships",
    defaultTitle: "Jwero - Building Jewelry Rocketships",
  });
  // const [onBoardingDialogState, setOnBoardingDialogState] = useState(true);
  const [lastEvent, setLastEvent] = useState({});
  const [tourSteps, setTourSteps] = useState([]);
  const [incomingCall, setIncomingCall] = useState([
    // { from: "9998060495", to: "77890" },
    // { from: "09876454", to: "77890" },
  ]);
  const [acceptingWebchatRequest, setAcceptingWebchatRequest] = useState({});
  const [incomingWebchatRequest, setIncomingWebchatRequest] = useState([
    // {
    //   user_info: {
    //     user_id: 1911,
    //     platform_id: "Sukck74HYSSl",
    //     id: 1911,
    //     first_name: "Rahul",
    //     last_name: "More",
    //     name: null,
    //     status: null,
    //     unread: 8,
    //     meta_data: {
    //       agent_can_respond: 0,
    //       assigned_team_member: [
    //         {
    //           id: 60,
    //         },
    //         {
    //           id: 80,
    //         },
    //         {
    //           id: 120,
    //         },
    //         {
    //           id: 161,
    //         },
    //       ],
    //     },
    //   },
    // },
    // { from: "9998060495", to: "77890" },
    // { from: "09876454", to: "77890" },
  ]);
  const { setSteps, steps, currentStep } = useTour();
  let usersTasks = useSelector((state) => state.chats.usersTasks);
  const reduxIntegrations = useSelector((state) => state.settings.integrations);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const mainUserDetails = useSelector((state) => state?.user?.chatsCreds);
  const settings = useSelector((state) => state.settings);
  const onBoardingDialogState = useSelector(
    (state) => state.helper.onBoardingDialogState
  );
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const fetchingConversations = useSelector(
    (state) => state.chats.fetchingConversations
  );
  const allOnlineVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );

  const globalToast = useSelector((state) => state.helper.globalToast);
  const globalSnackbar = useSelector((state) => state.helper.globalSnackbar);

  let store_details = useSelector((state) => state.user.store_details);

  let store_plan = useSelector((state) => state.user.store_plan);
  let isPlanExpired = useSelector((state) => state.user.isPlanExpired);
  let user_details = useSelector((state) => state.user);

  const referenceId = useSelector((state) => state.persist.referenceId);
  const fcm_token_persist = useSelector(
    (state) => state.persist.fcm_token_persist
  );
  const fcm_token_added = useSelector((state) => state.persist.fcm_token_added);
  const expiryWarningShowed = useSelector(
    (state) => state.user.expiryWarningShowed
  );
  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const user_type = useSelector((state) => state.user.user_type);
  const all_products_access = useSelector(
    (state) => state?.store_plan?.all_products_access
  );
  const subuserEmail = useSelector((state) => state.user?.email);
  const user = useSelector((state) => state.user);
  const mergeProfiles = useSelector((state) => state.chats.mergeProfiles);
  const { signOut } = useAuth();
  const onTourEnd = useSelector((state) => state.helper.onTourEnd);
  const currentTour = useSelector((state) => state.helper.currentTour);
  const referenceObj = useSelector((state) => state.helper.referObj);
  const occupationsArray = useSelector((state) => state.persist.occupations);
  const webinarRegister = useSelector(
    (state) => state.persist?.webinarRegister
  );

  useEffect(() => {
    if (!isArrayWithValues(occupationsArray)) dispatch(getDropdownData());
  }, []);

  // let zohoLogo = document.getElementById("zsiq_float");

  // useEffect(() => {
  //   if (!zohoLogo) return;
  //   zohoLogo.style.display = "none";
  //   // else {
  //   //   zohoLogo.style.display = "none";
  //   // }
  //   // if (!store_details) {
  //   //   zohoLogo.style.display = "none";
  //   // } else {
  //   //   zohoLogo.style.display = "block";
  //   // }
  // }, [zohoLogo]);
  const [fethcingSettings, setFetchingSettings] = useState(false);

  const expiryDialogWarning = useRef();
  const planUpgradedRef = useRef();
  const deviceLoginExceedRef = useRef();
  const supplierBasicProfileRef = useRef();

  // let customerInAudio = new Audio("/static/sound/pure_bell.mp3");

  // customerInAudio.muted = true;
  // customerOutAudio.muted = true;

  useEffect(() => {
    if (lastEvent?.type?.includes("catalog"))
      dispatch(setupCatalogs(false, false));
    dispatch(setupLiveCatalogs());
  }, [allOnlineVisitors]);
  useEffect(() => {
    dispatch(setupUserTasks(usersTasks));
  }, [usersTasks, reduxTeamMembers]);

  useEffect(() => {
    if (steps?.length === currentStep) {
      let historyObj = {};
      if (isArrayWithValues(user_products)) {
        for (const iterator of user_products || []) {
          historyObj = { ...historyObj, [`${iterator}_tour`]: true };
        }
      } else {
        historyObj = { dashboard_tour: true };
      }
      if (currentTour) historyObj = { ...historyObj, [currentTour]: true };
      putTourHistory(historyObj);
      dispatch(addTourHistory(historyObj));

      dispatch({
        type: SET_HELPER_DATA,
        currentTour: false,
      });
      // user_products
      setTimeout(() => {
        typeof onTourEnd === "function" && dispatch(onTourEnd());
      }, 1000);
    }
  }, [currentStep]);

  useEffect(() => {
    let supplierExtraSteps = [".sidebar_quotations"];
    let retailerExtraSteps = [".sidebar_digigold", ".sidebar_frontend"];
    const chatSteps = [
      ".sidebar_dashboard",
      ".sidebar_chats",
      ".sidebar_integrations",
      ".sidebar_settings",
    ];
    const catalogSteps = [
      ".sidebar_dashboard",
      ".sidebar_products",
      ".sidebar_crm",
      ".sidebar_catalogs",
      ".sidebar_integrations",
      ".sidebar_settings",
    ];
    const eCommerceSteps = [
      ".sidebar_dashboard",
      ".sidebar_products",
      ".sidebar_crm",
      ".sidebar_orders",
      ".sidebar_frontend",
      ".sidebar_integrations",
      ".sidebar_settings",
    ];
    const crmSteps = [
      ".sidebar_dashboard",
      ".sidebar_crm",
      ".sidebar_integrations",
      ".sidebar_settings",
    ];

    const commonSteps = [".profile_icon", ".notification_icon"];

    let steps = getAllTourSteps(user_products);
    let allTourSteps = getAllTourSteps(user_products);
    if (all_products_access || !isArrayWithValues(user_products)) {
      if (user_type === "supplier") {
        steps = steps.filter((i) => !retailerExtraSteps.includes(i.selector));
      } else
        steps = steps.filter((i) => !supplierExtraSteps.includes(i.selector));
    }
    if (isArrayWithValues(user_products)) {
      let tempSteps = [];
      if (user_products.includes("chats"))
        tempSteps = tempSteps.concat(chatSteps);
      if (user_products.includes("whatsapp"))
        tempSteps = tempSteps.concat(chatSteps);
      if (user_products.includes("catalog"))
        tempSteps = tempSteps.concat(catalogSteps);
      if (user_products.includes("e-commerce"))
        tempSteps = tempSteps.concat(eCommerceSteps);
      if (user_products.includes("crm")) tempSteps = tempSteps.concat(crmSteps);
      tempSteps = [...new Set(tempSteps)];
      steps = steps.filter((i) => tempSteps.includes(i.selector));
    }
    steps = steps.concat(
      allTourSteps.filter((i) => commonSteps.includes(i.selector))
    );
    dispatch({
      type: SET_HELPER_DATA,
      payload: {
        dashboard_tour: steps,
      },
    });
    // setHelperData({
    //   dashboard_tour: steps
    // })
    setSteps(steps);
    setTourSteps(steps);
  }, [user_type, user_products]);

  let googleIntervalRef;
  const socketRef = createRef();
  const websocketRef = createRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const muiTheme = muiUseTheme();
  const isIpad = useMediaQuery(muiTheme.breakpoints.down("md"));
  const globalSnackbarClose = () => {
    dispatch(setGlobalSnackbar({ show: false, ...globalSnackbar }));
  };

  useEffect(async () => {
    if (!isDev()) console.log = () => {};
    if (document.getElementById("zsiq_float")) {
      if (document.getElementById("zsiq_float").style)
        document.getElementById("zsiq_float").style.display = "none";
    }
    if (document.getElementById("zsiqbtn")) {
      if (document.getElementById("zsiqbtn").style)
        document.getElementById("zsiqbtn").style.display = "none";
    }
  }, [
    document.getElementById("zsiq_float"),
    document.getElementById("zsiqbtn"),
  ]);

  useMemo(() => {
    if (isArrayWithValues(allReduxConversations)) {
      dispatch(setUpConversations(allReduxConversations));
    }
  }, [mergeProfiles]);

  useEffect(() => {
    let { hash } = location;
    if (hash?.includes("chat_tour")) {
      setOnBoardingDialogState(true);
    }

    if (!isObjWithValues(store_plan)) return;
    // if (isDev()) return;
    if (expiryWarningShowed && !isPlanExpired) return;
    let { expiry } = store_plan;
    if (
      !expiry ||
      location.pathname === "/pricing" ||
      location.pathname === "/auth/sign-in" ||
      location.pathname === "/auth/regsiter"
    )
      return;
    let expiry_date = new Date(expiry).getTime();
    let date = new Date().getTime();
    let isExpired = expiry_date <= date;
    expiryDialogWarning?.current?.setExpiry(isExpired);
    if (isExpired) {
      expiryDialogWarning?.current?.openExpiryWarning(true);
    }
    dispatch({
      type: "SET_USER",
      payload: {
        isPlanExpired: isExpired,
      },
    });
    let timeRemainingInSeconds = (expiry_date - date) / 1000;
    if (!isExpired && timeRemainingInSeconds < 259200) {
      let dateObj = getRelativeTimeDifference(
        new Date(),
        new Date(expiry_date)
      );
      if (dateObj)
        expiryDialogWarning?.current?.setExpiryTime(
          `${dateObj.value} ${dateObj.label}`
        );
      expiryDialogWarning?.current?.openExpiryWarning(true);
    }
    //  else expiryDialogWarning?.current?.openExpiryWarning(false);
  }, [store_plan, location]);

  useEffect(() => {
    if (
      isObjWithValues(user_details) &&
      user_details.id &&
      user_details.username
    ) {
      // let user_products = user_details?.store_plan?.user_products;
      if (user_products?.length === 1 && user_products?.includes("chats")) {
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            onlyChats: true,
          },
        });
      }
      for (const product of user_products || []) {
        if (user_details?.tour_history?.[`${product}_tour`])
          dispatch({
            type: SET_HELPER_DATA,
            payload: {
              restrictedTour: false,
            },
          });
        break;
      }

      // if ()
      if (
        user_products?.length === 1 &&
        Object.keys(starterPageContent || {})
          ?.join("")
          ?.includes(
            user_products?.map((str) =>
              str === "catalog" ? "catalogs" : str
            ) || ""
          )
      ) {
        if (
          user_details?.tour_history?.[
            `${
              user_products?.[0] === "catalog" ? "catalogs" : user_products?.[0]
            }_introduction`
          ]
        ) {
          // return
        } else {
          dispatch({
            type: SET_HELPER_DATA,
            payload: {
              onBoardingDialogState: true,
              featureProduct:
                user_products?.[0] === "catalog"
                  ? "catalogs"
                  : user_products?.[0],
            },
          });
        }
      }
    }
  }, [user_details?.store_plan?.user_products]);
  useEffect(() => {
    let params_string = location.search;
    let token = new URLSearchParams(params_string).get("firebase_push_id");
    let hostedpage_id = new URLSearchParams(params_string).get("hostedpage_id");
    if (token && store_details && store_details.store_url) {
      sendTokenToBackend(token);
      setSendingTokenStarted(true);
    }
    if (token && (!store_details || !store_details.store_url)) {
      dispatch(
        setPersistData({
          fcm_token_persist: token,
          fcm_token_added: false,
        })
      );
    }
    if (hostedpage_id) setupUpgrade(hostedpage_id);
  }, [location]);
  useMemo(() => {
    dispatch(getMoreVisitors({ filter: "live" }));
  }, []);
  const onCallClose = (obj, index) => {
    try {
      customerInAudio.stop();
    } catch (error) {}
    try {
      customerInAudio.pause();
    } catch (error) {}
    setIncomingCall((pre, index) => {
      return pre.filter((o) => o.from !== obj?.from);
    });
  };
  const setupUpgrade = async (hostedpage_id) => {
    await getZohoUpgradeInfo(hostedpage_id);
    // let settings = await fetchMasterSettingsByOptions([
    //   "zoho_refresh_token",
    //   "zoho_client_id",
    //   "zoho_client_secret",
    // ]);

    // let zoho_access_token = await getZohoAccessToken({
    //   zoho_refresh_token:
    //     "1000.9e9e2deb64872cbc5860362aaae0fe55.ea58061179153937bcb4a75736efff8a",
    //   zoho_client_id: "1000.W7KZBYS3A5W6OTZEPHMW2C1BIXSN2O",
    //   zoho_client_secret: "0a5e0bda128f22764f93b1f37352c20b2f099389d0",
    // });
    // let zohoInfo = await getZohoUpgradeInfo(hostedpage_id, zoho_access_token);
  };

  const getZohoUpgradeInfo = async (hostedpage_id) => {
    let website = getWebsite();
    let token = getToken();
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/subscription`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          id: hostedpage_id,
        },
        method: "POST",
      });
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const getZohoAccessToken = async ({
    zoho_refresh_token,
    zoho_client_id,
    zoho_client_secret,
  }) => {
    try {
      let { data } = await axios({
        url: "https://accounts.zoho.in/oauth/v2/token",
        data: {
          zoho_refresh_token,
          zoho_client_id,
          zoho_client_secret,
          grant_type: "refresh_token",
        },
      });
      console.log(data);
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  useEffect(() => {
    if (
      store_details &&
      store_details.store_url &&
      fcm_token_persist &&
      !fcm_token_added
    ) {
      sendTokenToBackend(fcm_token_persist);
      setSendingTokenStarted(true);
      dispatch(
        setPersistData({
          fcm_token_persist: "",
          fcm_token_added: true,
        })
      );
    }
  }, [store_details]);

  useEffect(() => {
    if (isIpad) {
      dispatch(
        setGlobalPopup({
          fullScreen: true,
          sx: {},
        })
      );
    } else {
      dispatch(setGlobalPopup({}));
    }
  }, [isIpad]);

  useEffect(() => {
    // dispatch({
    //   type: SET_PERSIST_DATA,
    //   payload: {
    //     webinarRegister: null,
    //   },
    // });

    if (!allSettingsFetched && !fetchingAllSettings) {
      dispatch(fetchAllSettings({ signout: signOut }));
      if (getIsBackendMigrated("chats"))
        dispatch(fetchAllNewSettings({ signout: signOut }));
      dispatch(getPresetVisitors());
      getSignInData();
      setFetchingSettings(true);
      //usetiful setup when user is loggedin
    } else setFetchingSettings(false);
  }, [user_details.id, user_details.username, fetchingAllSettings]);

  useEffect(() => {
    let viewTime = validateNumber(webinarRegister?.time);
    if (viewTime) {
      const currentTime = new Date().getTime();

      // Calculate the difference
      const timeDifference = currentTime - viewTime;

      // 3 hours in milliseconds
      const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;

      if (timeDifference >= threeHoursInMilliseconds) {
        dispatch({
          type: SET_PERSIST_DATA,
          payload: {
            webinarRegister: null,
          },
        });
      }
    }
    return () => {};
  }, [user_details.id]);

  useEffect(() => {
    if (!user_details.id) return;
    if (!isObjWithValues(referenceObj)) return;
    supplierBasicProfileRef?.current?.show(referenceObj);
    dispatch(setHelperData({ referObj: {} }));
  }, [referenceObj, user_details.id]);

  useEffect(() => {
    if (user_details.store_id && user_details.id) {
      Mixpanel.identify(
        `${unformatServerValue(user_details?.store_details?.store_name)}-${
          user_details.store_id
        }-${user_details.id}`
      );
      Mixpanel.people.set({
        user_id: user_details.id,
        store_id: user_details.store_id,
        name: user_details.username,
        store_name: user_details?.store_details?.store_name,
        $email: user_details?.store_details?.store_email,
        user_type: user_details?.user_type,
        admin: user_details.isAdmin,
      });
    }
  }, [user_details?.id]);

  // useEffect(() => {
  //   // dispatch(fetchTaxonomies());
  //   getSignInData();
  // }, []);
  useEffect(() => {
    // dispatch(setPersistData({ referenceId: "" }));
    // checkGoogleTokenExpiry();

    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    let { search, pathname } = location;

    if (search) {
      let params = {};
      let regex = /[?&]([^=#]+)=([^&#]*)/g,
        match;
      while ((match = regex.exec(search))) {
        params[match[1]] = match[2];
      }
      let encoded = params.e?.replaceAll("%3D", "")?.replaceAll("=", "");

      if (encoded) {
        checkReferral(encoded);
        navigate(pathname);
      }
    }

    return () => {
      window.removeEventListener("online", null);
      window.removeEventListener("offline", null);
    };
  }, []);

  const checkReferral = async (encoded) => {
    let decoded = Base64.atob(encoded);
    let params = {};
    try {
      params = JSON.parse(decoded);
    } catch (error) {
      console.log(error);
    }
    // let regex = /[?&]([^=#]+)=([^&#]*)/g,
    //   match;
    // let temp = 0;
    // while ((match = regex.exec("&" + decoded))) {
    //   console.log(match, "<<<< ", temp);
    //   params[match[1]] = match[2];
    //   temp++;
    // }
    // {type: skj, by: kdsj@gmail.com} //params

    if (params.uid) dispatch(setHelperData({ referObj: params }));
    return;
    if (!referenceId && params.type && params.by) {
      let ref_id = getRandomString(14);
      let obj = {
        type: params.type,
        by: params.by,
        time: Math.round(Date.now() / 1000),
        device_id: ref_id,
      };
      // try {
      //   let res = await axios({
      //     url: `https://app.tanika.tech/wp-json/store/v1/settings/referral`,
      //     method: "PUT",
      //     data: { data: { [obj.device_id]: obj } },
      //     headers: {
      //       apikey: Base64.btoa(
      //         "ck_6723528dc4ec4dab4b959db2fc9533e5bdeda01e:cs_63bd0845e82795b7ac50cec00e0e0e97a072425d"
      //       ),
      //       "Content-Type": "application/json",
      //     },
      //   });
      //   dispatch(setPersistData({ referenceId: ref_id }));

      // } catch (error) {

      // }
    }
  };

  const getSignInData = async () => {
    const website = await getWebsite();
    const token = await getToken();
    let mainWebsite = getMainWebsite();
    if (website && token) {
      let { data } = await axios({
        url: `${mainWebsite}/wp-json/store/v1/details`,
        headers: { Authorization: `Basic ${token}` },
      });
      if (data?.data?.store_plan?.disabled) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message:
        //       "Account is temporary disabled! Kindly contact our customer support at care@jwero.ai",
        //   })
        // );
        // await sleep(500);
        signOut();
      }
      if (data?.data?.appcredentials) {
        let user = data.success ? data?.data : data;
        if (isDev() && isOurWebsite()) {
          // user.backend_migrated = {};
          // user.backend_migrated.chats = true;
        }
        // if (isDev()) user.isAdmin = true;
        // if (isDev()) user.store_plan.user_products = null;
        // if (user?.store_plan?.user_products && isDev()) delete user.store_plan.user_products
        // if (isDev()) user.store_plan.user_products = ["crm", "chats"];
        // if (isDev()) user.store_plan.user_products = ["whatsapp"];
        // if (isDev()) user.store_plan.user_products = ["crm"];
        // if (isDev()) user.store_plan.user_products = ["chats"];
        // if (isDev()) user.store_plan.user_products = ["chats"];
        // if (isDev()) user.store_plan.user_products = ["catalog"];
        // if (isDev()) user.store_plan.user_products = ["e-commerce"];
        // if (isDev()) user.user_type = "supplier";
        // let access_token = encryptStorage.getItem("accessToken");
        // if (isObjWithValues(user?.dashboard_sessions)) {
        //   let dashboardSessions = Object.values(
        //     user?.dashboard_sessions
        //   ).filter((i) => i.user_id == user.id);
        //   let currentSession = dashboardSessions.find(
        //     (i) => i.access_token === access_token
        //   );
        //   dashboardSessions = dashboardSessions.filter((i) => !i.terminated);
        //   if (currentSession && currentSession?.terminated) {
        //     await deleteDashboardSession(currentSession.id);
        //     dispatch(
        //       setGlobalToast({
        //         show: true,
        //         message: "Session Ended",
        //         severity: "error",
        //       })
        //     );
        //     signOut && signOut();
        //     return;
        //   }
        //   let devicesLimit = user?.store_plan?.devices || 3;
        //   if (dashboardSessions.length >= devicesLimit) {
        //     if (!currentSession) deviceLoginExceedRef.current?.open();
        //   } else if (!currentSession) await addDashboardSession();
        // } else {
        //   await addDashboardSession();
        // }
        // console.log(await checkAdvanceAccess(user.access?.advanced_access));
        console.log(user);
        let userAdminData = {};
        // if (user.isAdmin) {
        userAdminData = await fetchCustomer(user?.id);

        let { meta_data } = userAdminData || {};
        const metaKeys = [
          "dialcode_mobile",
          "dialcode_whatsapp",
          "whatsapp",
          "mobile_no",
          "customer_facebook",
          "customer_instagram",
          "customer_twitter",
          "customer_linkedin",
          "customer_youtube",
          "customer_pinterest",
          "no_of_employees",
          "annual_turnover",
          "association_member",
          "brand_images",
        ];
        if (isArrayWithValues(meta_data))
          for (let obj of meta_data) {
            if (metaKeys.includes(obj.key) && obj.value)
              userAdminData[obj.key] = obj.value;
          }
        // }

        dispatch(appendIntegrations(user?.store_plan?.add_ons?.integrations));

        // if (user?.access?.email?.includes("tanikatech") || isDev())
        //   user.isAdmin = true;
        dispatch({
          type: "SET_USER",
          payload: {
            token,
            ...user,
            ...user.access,
            user_type: user.user_type,
            has_quotation_access: user.user_type == "supplier",
            _access: user.access,
            user_fetched: true,
            userAdminData,
          },
        });
        setItem({
          user: JSON.stringify({
            token,
            ...user,
            ...user.access,
            user_type: user.user_type,
            has_quotation_access: user.user_type == "supplier",
            _access: user.access,
          }),
        });
      }
    }
  };

  useEffect(() => {
    if (sendingTokenStarted) return;
    try {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          if (token && store_details && store_details.store_url) {
            sendTokenToBackend(token);
            setSendingTokenStarted(true);
          }
        })
        .catch((error) => {
          console.log(error, "ERROR GETTING TOKEN");
        });
      // console.log(firebase.messaging.isSupported(), "<<<< firebase supported");
      // if (firebase.messaging.isSupported()) {
      // }
    } catch (error) {
      console.log(error, "Fcm token error");
    }
  }, [store_details]);

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    permissions
      .then((stream) => {
        // window.localStream = stream;
        // window.localAudio.srcObject = stream;
        // window.localAudio.autoplay = true;
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
      });
  };

  useEffect(() => {
    setupAccess();
    dispatch(setUpConversationsAccess());
  }, [reduxTeamMembers]);
  useEffect(() => {
    dispatch(setUpConversationsAccess());
  }, [fetchingConversations]);

  const setupAccess = async () => {
    if (isArrayWithValues(reduxTeamMembers)) {
      let obj = reduxTeamMembers.find((i) => i.email === subuserEmail);
      if (obj) {
        dispatch({
          type: "SET_USER",
          payload: {
            ...obj,
          },
        });
        let error = await checkAdvanceAccess(obj);
        if (error?.unauthenticated && !isDev()) {
          setTimeout(() => {
            dispatch(
              setGlobalToast({
                show: true,
                severity: "error",
                message: error.message,
                timeout: 10000,
              })
            );
          }, 300);
          signOut();
        }
      }
    }
  };

  // const checkAdvanceAccess = async (obj) => {
  //   let advanced_access = obj?.advanced_access;
  //   if (advanced_access?.start_day && advanced_access?.end_day) {
  //     let currentDay = new Date().getDay();
  //     let startIndex = daysArray.findIndex(
  //       (i) => i.value === advanced_access?.start_day
  //     );
  //     let endIndex = daysArray.findIndex(
  //       (i) => i.value === advanced_access?.end_day
  //     );
  //     if (startIndex > currentDay || currentDay > endIndex) {
  //       return {
  //         unauthenticated: true,
  //         message:
  //           advanced_access?.start_day === advanced_access?.end_day
  //             ? `Only able to login on ${advanced_access?.start_day}`
  //             : `Only able to login between ${capitalizeFirstLetter(
  //                 advanced_access?.start_day
  //               )} to ${capitalizeFirstLetter(advanced_access?.end_day)}`,
  //       };
  //     }
  //   }
  //   if (advanced_access?.start_login_time && advanced_access?.end_login_time) {
  //     let startTime = formatDate(
  //       new Date(advanced_access?.start_login_time),
  //       "hh:mm:ss"
  //     );
  //     let endTime = formatDate(
  //       new Date(advanced_access?.end_login_time),
  //       "hh:mm:ss"
  //     );
  //     return { unauthenticated: true };
  //   }
  //   if (advanced_access?.ip) {
  //     let currentIp = await getIp();
  //     if (advanced_access?.ip.includes("-")) {
  //       // let =
  //     } else if (currentIp !== advanced_access?.ip)
  //       return {
  //         unauthenticated: true,
  //         message: `Cannot login to this IP Address`,
  //       };
  //   }
  // };

  useEffect(() => {
    // if (tokenWasExpired && isArrayWithValues(integrations)) {
    //   refreshGoogleTokenFunction(true);
    //   setTokenWasExpired(false);
    // }
    // setTimeout(() => {
  }, [integrations, tokenWasExpired]);

  useEffect(() => {
    // clearInterval(googleIntervalRef);
    // if (googleTokenTimeout) {
    //   if (googleIntervalRef) clearInterval(googleIntervalRef);
    //   googleIntervalRef = setInterval(async () => {
    //     refreshGoogleTokenFunction(false);
    //   }, googleTokenTimeout);
    // }
    // return () => clearInterval(googleIntervalRef);
  }, [googleTokenTimeout]);

  useEffect(() => {
    if (isArrayWithValues(reduxIntegrations))
      setIntegrations(reduxIntegrations);
  }, [reduxIntegrations]);

  const refreshGoogleTokenFunction = async (setInterval) => {
    if (isArrayWithValues(integrations)) {
      let google = integrations.find((i) => i.google);
      google = (isObjWithValues(google) && google.google) || {};
      if (isObjWithValues(google) && google.google_access_token) {
        try {
          let res = await refreshGoogleToken(google.google_refresh_token_token);
          let { data } = res;
          let { google_refresh_token_token, productSheet } = google;
          let googleRes = await updateGoogle(
            data.access_token,
            google_refresh_token_token,
            productSheet.google_spreadsheetId
          );

          if (data.access_token) {
            dispatch(updateGoogleAccessTokenInRedux(data.access_token));
          }
        } catch (error) {
          let { google_refresh_token_token, google_access_token } = google;
          if (google_refresh_token_token && google_access_token) {
            try {
              try {
                let res = await revokeGoogle(
                  google_access_token,
                  google_refresh_token_token
                );
                console.log(res, "revoke from google response");
              } catch (error) {
                console.log(getAxiosError(error));
              }
              // let res = { data: true };
              let res2 = await deleteGoogleIntegration();
              if (res2.success) dispatch(deleteGoogleIntegrationFromRedux());
              // if (res.data) {
              // }
            } catch (error) {
              console.log(getAxiosError(error));
            }
          }
        }
      } else clearInterval(googleIntervalRef);
      if (setInterval || googleTokenTimeout !== REFRESH_TYPE)
        setGoogleTokenTimeout(REFRESH_TYPE);
      // console.log(setInterval, "setting interval");
    }
  };

  // useEffect(() => {
  //   if (isObjWithValues(access)) {
  //     let { product, crm } = access;
  //     let array = [...routes];
  //     if (isArrayWithValues(product)) {
  //       let productRoute = array.find((i) => i.path === "products");
  //       // console.log(productRoute && productRoute.children);
  //       let childrenRoutes = productRoute && productRoute.children;
  //       if (isArrayWithValues(childrenRoutes)) {
  //         if (!product.includes("add"))
  //           childrenRoutes = childrenRoutes.filter((i) => i.path !== "add");
  //       }
  //       productRoute.children = childrenRoutes;
  //       array = array.map((i) => (i.path === "products" ? productRoute : i));
  //     }
  //     if (isArrayWithValues(crm)) {
  //       let customerRoute = array.find((i) => i.path === "customers");
  //       // console.log(productRoute && productRoute.children);
  //       let childrenRoutes = customerRoute && customerRoute.children;
  //       if (isArrayWithValues(childrenRoutes)) {
  //         if (!crm.includes("add"))
  //           childrenRoutes = childrenRoutes.filter((i) => i.path !== "add");
  //       }
  //       customerRoute.children = childrenRoutes;
  //       array = array.map((i) => (i.path === "customers" ? customerRoute : i));
  //     }
  //     setAllRoutes(array);
  //   }
  // }, [access]);

  useEffect(() => {
    let tempRoutes = setUpRoutes(user_products);
    if (!hasAccessInPlan({ access: "campaign" }))
      tempRoutes = tempRoutes?.filter((obj) => obj?.path !== "campaign");
    setAllRoutes(tempRoutes);
  }, [user_products, user, getIsBackendMigrated("chats")]);

  useEffect(() => {
    // if(isObjWithValues(advanced_access)){
    //   let {}
    // }
  }, [advanced_access]);

  const checkGoogleTokenExpiry = async () => {
    let res = await fetchIntegrations();
    if (res) {
      let { data } = res;
      if (isArrayWithValues(data.data)) {
        dispatch({
          type: "EDIT_SETTINGS",
          payload: { integrations: data.data },
        });
        let obj = data.data.find((i) => i.google);
        let google = (isObjWithValues(obj) && obj.google) || {};
        if (isObjWithValues(google) && google.google_access_token) {
          try {
            let res = await getGoogleTokenExpire(google.google_access_token);
            let { data } = res;
            setGoogleTokenTimeout(Number(data.expires_in) * 1000);
          } catch (error) {
            error = getAxiosError(error);
            if (error.error) setTokenWasExpired(true);
          }
        }
      }
    }
  };
  useEffect(async () => {
    return () => {
      if (socketRef.current && socketRef.current.close) {
        console.log("Closing socket");
        setSocketConnected(false);
        socketRef.current.close();
        // dispatch(setMainSocket(null));
      }
      if (websocketRef.current && websocketRef.current.close) {
        console.log("Closing socket");
        setSocketConnected(false);
        websocketRef.current.close();
        // dispatch(setMainSocket(null));
      }
    };
    // var sound = new Howl({
    //   src: ["/static/sound/customer_in.mp3"],
    // });
  }, []);
  let customerInAudio = new Audio("/static/sound/ringtone.mp3");
  useEffect(() => {
    let storeUniqueValue = getTenantId();
    try {
      let socketLink = liveSocketLink;
      console.log(
        storeUniqueValue,
        socketConnected,
        socketLink,
        socketRef?.current,
        !socketRef?.current?.connected,
        "<<<<<<<<<<<<<<< socket info"
      );
      if (storeUniqueValue && !socketConnected) {
        socketRef.current = io(socketLink);
        if (socketRef.current && !socketRef.current?.connected) {
          socketRef.current.on("connect", () => {
            console.log(
              "Successfully connected On Reactjs",
              `testbot_messages_${storeUniqueValue}`,
              `new_update_${storeUniqueValue}`,
              socketLink
            );
          });
          socketRef.current.on(
            `testbot_messages_${storeUniqueValue}`,
            (data) => {
              dispatch({
                type: flowActions.TEST_BOT_TYPING_INDICATOR,
                payload: true,
              });
              dispatch({
                type: flowActions.EDIT_ELEMENT_IN_REDUX,
                payload: {
                  chatbotMessageResponse: data.last_node_details,
                },
              });
              if (data.bot_response)
                data.bot_response.forEach((node) => {
                  setTimeout(() => {
                    dispatch({
                      type: flowActions.TEST_BOT_ADD_MESSAGES,
                      payload: node,
                    });
                    dispatch({
                      type: flowActions.TEST_BOT_TYPING_INDICATOR,
                      payload: false,
                    });
                  }, [2000]);
                });
            }
          );

          socketRef.current.on(`new_update_${storeUniqueValue}`, (event) => {
            console.log(event, "<<<< NEW EVENT");
            // if (event?.type === "MEMBER_UPDATE") {
            //   dispatch(
            //     updateSubUserRedux({
            //       data: event?.payload,
            //       id: event?.payload?.id,
            //     })
            //   );
            // }
            if (event?.type === "CONTACT_UPDATE") {
              try {
                if (
                  !isAdmin &&
                  event?.payload?.meta_data &&
                  !event?.payload?.meta_data?.assigned_team_member?.find(
                    (i) => i.id + "" === mainUserDetails?.id + ""
                  )
                ) {
                  dispatch(deleteChatUserInRedux({ user: event?.payload }));
                  setIncomingWebchatRequest((pre) => {
                    return pre?.filter(
                      (i) => i?.user_info?.id + "" !== event?.payload?.id + ""
                    );
                  });
                  // dispatch({
                  //   type: REMOVE_INCOMING_CHAT,
                  //   payload: event?.payload,
                  // });
                } else
                  dispatch(updateChatUserInRedux({ user: event?.payload }));
              } catch (error) {
                console.log(error);
              }
              // console.log(
              //   !isAdmin,
              //   event?.payload?.meta_data,
              //   JSON.stringify(event?.payload?.meta_data?.assigned_team_member),
              //   event?.payload?.meta_data?.assigned_team_member?.find(
              //     (i) => i.id === userId
              //   ),
              //   Platform.OS
              // );
            }
          });
          socketRef.current.on(`response_${storeUniqueValue}`, (data) => {
            console.log("Response messages received: ", data);
            dispatch({
              type: flowActions.TEST_BOT_TYPING_INDICATOR,
              payload: true,
            });
            dispatch({
              type: flowActions.EDIT_ELEMENT_IN_REDUX,
              payload: {
                chatbotMessageResponse: data?.last_node_details,
              },
            });
            if (data.bot_response)
              data.bot_response.forEach((node) => {
                setTimeout(() => {
                  dispatch({
                    type: flowActions.TEST_BOT_ADD_MESSAGES,
                    payload: node,
                  });
                  dispatch({
                    type: flowActions.TEST_BOT_TYPING_INDICATOR,
                    payload: false,
                  });
                }, [2000]);
              });
          });

          console.log("Listening no", `visitor_${storeUniqueValue}`);
          // socketRef.current.on(`visitor_${storeUniqueValue}`, (message) => {
          socketRef.current.on(`visitor_${storeUniqueValue}`, (message) => {
            console.log(message, "payload");

            setLastEvent(message);
            if (message?.type === "NEW_WEB_CHAT") {
              setIncomingWebchatRequest((pre) => {
                return !pre?.find((i) => i?.user?.id === message?.payload)
                  ? [...(pre || [])]?.concat(message?.payload)
                  : pre;
              });
            }
            if (message.type == "campaing_info") {
              dispatch(updateCampaignHistory(message));
            } else if (
              getIsBackendMigrated("chats") &&
              // isDev() &&
              isObjWithValues(message) &&
              (message.payload || message?.mainPayload)
            ) {
              dispatch(handleSocialMessage({ payloadObject: message }));
            } else if (message.type === "update_quotation") {
              dispatch(replaceQuotationInRedux(message.payload));
            } else if (
              message.type === "incoming_call" &&
              message?.payload?.status
            ) {
              // customerInAudio.play();

              if (message?.payload?.status === "free")
                setIncomingCall((pre) =>
                  pre?.filter((o) => o?.from !== message.payload?.from)
                );
              else
                setIncomingCall((pre) => {
                  if (pre?.find((obj) => obj?.from === message?.payload?.from))
                    return pre?.map((o) =>
                      o.from === message?.payload?.from
                        ? { ...(o || {}), ...(message?.payload || {}) }
                        : o
                    );
                  else return [...pre, message?.payload];
                });
            } else if (message.type === "caller_info") {
              if (message?.payload?.status === "free")
                setIncomingCall((pre) =>
                  pre?.filter((o) => o?.from !== message.payload?.from)
                );
              else {
                setIncomingCall((pre) => {
                  // if (pre?.find((obj) => obj?.from === message?.payload?.from))
                  return pre?.map((o) =>
                    o.from === message?.payload?.from
                      ? { ...(o || {}), ...(message?.payload || {}) }
                      : o
                  );
                  // else return [...pre, message?.payload];
                });
              }
            } else if (
              !getIsBackendMigrated("chats") &&
              // !isDev() &&
              isObjWithValues(message) &&
              message.payload
            ) {
              dispatch(
                addResponseInChat(
                  message.payload,
                  message?.basicInfo?.id,
                  message.basicInfo,
                  true
                )
              );
            } else {
              // dispatch(addVisitor(message));
              // let obj = getSocketNotification(message);
              // if (obj) {
              //   dispatch(setGlobalToast(obj));
              // }
            }
            // if (message.online) {
            //   // customerInAudio.play();
            // } else {
            //   dispatch(removeVisitor(message.username));
            //   // customerOutAudio.play();
            // }
          });
          // socketRef.current.on(
          //   `visitor_oAeqLavq0AQi_Sukck74HYSSl`,
          //   (message) => {
          //     console.log(message, "payload");

          //     setLastEvent(message);
          //     if (message?.type === "NEW_WEB_CHAT") {
          //       setIncomingWebchatRequest((pre) => {
          //         return !pre?.find((i) => i?.user?.id === message?.payload)
          //           ? [...(pre || [])]?.concat(message?.payload)
          //           : pre;
          //       });
          //     }
          //     if (message.type == "campaing_info") {
          //       dispatch(updateCampaignHistory(message));
          //     } else if (
          //       getIsBackendMigrated("chats") &&
          //       // isDev() &&
          //       isObjWithValues(message) &&
          //       message.payload
          //     ) {
          //       dispatch(handleSocialMessage({ payloadObject: message }));
          //     } else if (message.type === "update_quotation") {
          //       dispatch(replaceQuotationInRedux(message.payload));
          //     } else if (
          //       message.type === "incoming_call" &&
          //       message?.payload?.status
          //     ) {
          //       // customerInAudio.play();

          //       if (message?.payload?.status === "free")
          //         setIncomingCall((pre) =>
          //           pre?.filter((o) => o?.from !== message.payload?.from)
          //         );
          //       else
          //         setIncomingCall((pre) => {
          //           if (
          //             pre?.find((obj) => obj?.from === message?.payload?.from)
          //           )
          //             return pre?.map((o) =>
          //               o.from === message?.payload?.from
          //                 ? { ...(o || {}), ...(message?.payload || {}) }
          //                 : o
          //             );
          //           else return [...pre, message?.payload];
          //         });
          //     } else if (message.type === "caller_info") {
          //       if (message?.payload?.status === "free")
          //         setIncomingCall((pre) =>
          //           pre?.filter((o) => o?.from !== message.payload?.from)
          //         );
          //       else {
          //         setIncomingCall((pre) => {
          //           // if (pre?.find((obj) => obj?.from === message?.payload?.from))
          //           return pre?.map((o) =>
          //             o.from === message?.payload?.from
          //               ? { ...(o || {}), ...(message?.payload || {}) }
          //               : o
          //           );
          //           // else return [...pre, message?.payload];
          //         });
          //       }
          //     } else if (
          //       !getIsBackendMigrated("chats") &&
          //       // !isDev() &&
          //       isObjWithValues(message) &&
          //       message.payload
          //     ) {
          //       dispatch(
          //         addResponseInChat(
          //           message.payload,
          //           message?.basicInfo?.id,
          //           message.basicInfo,
          //           true
          //         )
          //       );
          //     } else {
          //       // dispatch(addVisitor(message));
          //       // let obj = getSocketNotification(message);
          //       // if (obj) {
          //       //   dispatch(setGlobalToast(obj));
          //       // }
          //     }
          //     // if (message.online) {
          //     //   // customerInAudio.play();
          //     // } else {
          //     //   dispatch(removeVisitor(message.username));
          //     //   // customerOutAudio.play();
          //     // }
          //   }
          // );
          // socketRef.current.on(`catalog_active_${website}`, (message) => {
          //   console.log(message, "payload");
          //   if (isObjWithValues(message)) {
          //     dispatch(addCatalogLive(message));
          //   }
          // });

          setSocketConnected(true);
          dispatch(setMainSocket(socketRef.current));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [store_details, user?.id]);

  useEffect(() => {
    return () => {
      let storeUniqueValue = getTenantId();

      socketRef.current?.off(`connect`);
      socketRef.current?.off("testbot_messages");
      socketRef.current?.off("response");
      socketRef.current?.off(`visitor_${storeUniqueValue}`);
    };
  }, []);

  useEffect(() => {
    let storeUniqueValue = store_details && store_details.store_url;
    let website = getWebsite();
    try {
      // let storeUniqueValue = getIsBackendMigrated("chats")
      //   ? user.id
      //   : store_details && store_details.store_url;

      if (storeUniqueValue && !webSocketConnected) {
        websocketRef.current = io(`${"wss"}://${websocketLink}/`);
        // let store_id = getStoreId();

        // let _access_token = encryptStorage.getItem("accessToken");
        // let tokenLength = _access_token?.length || 0;
        // let login_token = _access_token?.substring(
        //   tokenLength - 14,
        //   tokenLength
        // );

        // checkPermissions();
        // socketRef.current.emit("update_suppliers", { update: true });
        // socketRef.current.emit("landed_on_dashboard", {
        //   website,
        //   store_id,
        //   login_token,
        // });

        if (websocketRef.current && !websocketRef.current.connected) {
          websocketRef.current.on("connect", () => {
            console.log(
              `Successfully connected websocket On Reactjs ${"wss"}://${websocketLink}/`
            );
            console.log(
              `${isDev() ? "ws" : "wss"}://${nodeWebsiteWithoutProtocol}/`
            );
          });
          websocketRef.current.on(`update_settings_${storeUniqueValue}`, () => {
            dispatch(fetchAllSettings({ signout: signOut }));
            dispatch(getConversations());
          });
          // websocketRef.current.on("visitor", (msg) => {
          //   console.log(msg);
          // });
          websocketRef.current.on(
            `jwero_notification_${storeUniqueValue}`,
            (message) => {
              console.log(message, "<<<< notificatin 2");
              let obj = getSocketNotification(message);
              dispatch(setGlobalToast(obj));
            }
          );

          websocketRef.current.on(`visitor_${storeUniqueValue}`, (message) => {
            console.log(message, "payload");

            setLastEvent(message);
            if (message.type == "campaing_info") {
              dispatch(updateCampaignHistory(message));
            } else if (
              getIsBackendMigrated("chats") &&
              // isDev() &&
              isObjWithValues(message) &&
              message.payload
            ) {
              // dispatch(handleSocialMessage({ payloadObject: message }));
            } else if (message.type === "update_quotation") {
              dispatch(replaceQuotationInRedux(message.payload));
            } else if (
              message.type === "incoming_call" &&
              message?.payload?.status
            ) {
              // customerInAudio.play();

              if (message?.payload?.status === "free")
                setIncomingCall((pre) =>
                  pre?.filter((o) => o?.from !== message.payload?.from)
                );
              else
                setIncomingCall((pre) => {
                  if (pre?.find((obj) => obj?.from === message?.payload?.from))
                    return pre?.map((o) =>
                      o.from === message?.payload?.from
                        ? { ...(o || {}), ...(message?.payload || {}) }
                        : o
                    );
                  else return [...pre, message?.payload];
                });
            } else if (message.type === "caller_info") {
              if (message?.payload?.status === "free")
                setIncomingCall((pre) =>
                  pre?.filter((o) => o?.from !== message.payload?.from)
                );
              else {
                setIncomingCall((pre) => {
                  // if (pre?.find((obj) => obj?.from === message?.payload?.from))
                  return pre?.map((o) =>
                    o.from === message?.payload?.from
                      ? { ...(o || {}), ...(message?.payload || {}) }
                      : o
                  );
                  // else return [...pre, message?.payload];
                });
              }
            } else if (
              !getIsBackendMigrated("chats") &&
              // !isDev() &&
              isObjWithValues(message) &&
              message.payload
            ) {
              console.log(message, "<<message");
              dispatch(
                addResponseInChat(
                  message.payload,
                  message?.basicInfo?.id,
                  message.basicInfo,
                  true
                )
              );
            } else {
              console.log("ADDING VISITOR");
              dispatch(addVisitor(message));
              let obj = getSocketNotification(message);
              if (obj) {
                dispatch(setGlobalToast(obj));
              }
            }
            // if (message.online) {
            //   // customerInAudio.play();
            // } else {
            //   dispatch(removeVisitor(message.username));
            //   // customerOutAudio.play();
            // }
          });
          websocketRef.current.on(`catalog_active_${website}`, (message) => {
            console.log(message, "payload");
            if (isObjWithValues(message)) {
              dispatch(addCatalogLive(message));
            }
          });

          setWebSocketConnected(true);
          dispatch(setMainSocket(websocketRef.current));
        }
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      websocketRef.current?.off(`update_settings_${storeUniqueValue}`);
      websocketRef.current?.off(`jwero_notification_${storeUniqueValue}`);
      websocketRef.current?.off(`visitor_${storeUniqueValue}`);
      websocketRef.current?.off(`catalog_active_${website}`);
    };
  }, [store_details, user?.id]);

  const getSocketNotification = (obj) => {
    if (obj.type === "catalog") {
      return {
        show: true,
        message: Boolean(obj.user_name?.trim())
          ? `${obj.user_name || "someone"}${
              obj?.location?.city ? ` from ${obj?.location?.city}` : ""
            } has opened your catalog`
          : "Someone has opened your catalog",
        link: `/catalogs/${obj.event_id}`,
        severity: "success",
      };
    } else if (obj.type === "catalog_request") {
      return {
        show: true,
        // message: Boolean(obj.user_name?.trim())
        //   ? `${obj.user_name || "Someone"}${
        //       obj?.location?.city ? ` from ${obj?.location?.city}` : ""
        //     } has sent you a catalog request`
        //   : "Someone has sent you a catalog request",
        message: obj.message,
        link: `/catalogs/requests/${obj.id}`,
        severity: "success",
      };
    } else if (obj.type === "quotation") {
      return {
        show: true,
        message: Boolean(obj.user_name?.trim())
          ? `${obj.user_name || "someone"} has opened your quotation`
          : "Someone has opened your quotation",
        link: `/quotations/${obj.event_id}`,
        severity: "success",
      };
    }
  };

  // let steps = [
  //   {
  //     content: <h2>Let's begin our journey!</h2>,
  //     locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
  //     placement: 'center',
  //     target: '#himanshu',
  //   }
  // ]
  useMemo(() => {
    let virtual_try = [
      { settingName: "trybeforeyoubuyIntegration", key: "key" },
      { settingName: "camwearaIntegration", key: "key" },
      { settingName: "mirrARIntegration", key: "key" },
    ];
    for (const tryOnObj of virtual_try) {
      if (settings?.[tryOnObj?.settingName]?.key) {
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            virtualTryIntegrated: true,
          },
        });
        break;
      } else {
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            virtualTryIntegrated: false,
          },
        });
      }
    }
  }, [settings]);

  const setOnBoardingDialogState = (value) => {
    dispatch({
      type: SET_HELPER_DATA,
      payload: { onBoardingDialogState: value },
    });
  };
  useEffect(() => {
    let { pathname } = location || {};
    // console.log(pathname?.split("/"));
    const isModuleShortForm = ["crm"];
    let moduleName = pathname?.split("/")?.[1];
    if (moduleName) {
      setHelmetValue((pre) => ({
        ...pre,
        titleTemplate: `Jwero - ${
          isModuleShortForm.includes(moduleName)
            ? moduleName?.toUpperCase()
            : formatServerValue(moduleName)
        }`,
        defaultTitle: `Jwero - ${
          isModuleShortForm.includes(moduleName)
            ? moduleName?.toUpperCase()
            : formatServerValue(moduleName)
        }`,
      }));
    }
    // ({
    //   ...pre,
    //   titleTemplate: `Jwero - Crm`,
    //   defaultTitle: `Jwero - Crm`
    // })
    // if (pathname?.includes("crm")) {
    //   setHelmetValue(pre => {
    //     // if()

    //   }
    //   )
    // }
  }, [location]);

  const connectSupplierWithRetailer = async (obj) => {
    setConnectSupplierLoading(true);
    try {
      let retailerCrmInvites = await settingsEndpoint({
        endpoint: "crm_invites",
      });
      let supplierCrmInvites = await settingsEndpointWithoutCreds({
        endpoint: "crm_invites",
        website: obj.url,
      });
      let objInRetailer = Object.values(retailerCrmInvites || {}).find(
        (i) => i.uid == obj.uid
      );
      let supplierInvite = Object.values(supplierCrmInvites || {}).find(
        (i) => i.uid == obj.uid
      );
      if (!isObjWithValues(supplierInvite)) throw new Error();
      let other_details = {
        company_name: store_details?.store_name,
      };
      let username = user?.username;
      if (username) {
        let names = username.split(" ");
        if (isArrayWithValues(names) && names.length > 1) {
          other_details.first_name = names[0];
          other_details.last_name = names[names.length - 1];
        } else if (isArrayWithValues(names))
          other_details.first_name = names[0];
      }
      delete obj.ref_link;
      let { all_product_access, product_groups, access } = supplierInvite;
      await settingsEndpoint({
        endpoint: "crm_invites",
        method: "PUT",
        data: {
          [obj.uid]: {
            ...obj,
            status: "connected",
            all_product_access,
            product_groups,
            access,
          },
        },
      });
      await settingsEndpointWithoutCreds({
        endpoint: "crm_invites",
        website: obj.url,
        method: "PUT",
        data: {
          [supplierInvite.uid]: {
            ...supplierInvite,
            other_details,
            store_id: getId(),
            status: "connected",
          },
        },
      });
      // console.log(
      //   {
      //     ...supplierInvite,
      //     other_details,
      //     store_id: getId(),
      //     status: "connected",
      //   },
      //   {
      //     ...obj,
      //     status: "connected",
      //     all_product_access,
      //     product_groups,
      //   },
      //   supplierInvite
      // );
    } catch (error) {}
    setConnectSupplierLoading(false);
  };

  const onWebchatReuqestClose = (obj) => {
    try {
      setIncomingWebchatRequest((pre) => {
        return pre?.filter((i) => i?.user_info?.id !== obj?.user_info?.id);
      });
    } catch (error) {
    } finally {
    }
  };
  const onWebchatReuqestAccept = async (obj) => {
    let chat_user_id;
    let user = obj?.user_info;
    try {
      setAcceptingWebchatRequest((state) => ({
        ...state,
        [user?.id]: true,
      }));
      let sub_users = isAdmin ? [] : [{ id: user_details?.id }];
      await assignTeamMember({
        customers: [{ id: user?.id }],
        sub_users,
        params: { remove: isAdmin, replace: true },
      });

      let updateUserResponse = await updateChatUser({
        payload: { id: user?.id, meta_data: { agent_can_respond: true } },
      });

      if (updateUserResponse?.success) {
        dispatch(updateChatUserInRedux({ user: updateUserResponse?.data }));
        chat_user_id = user?.id;
      } else throw new Error();
      await createUserActivity({
        payload: {
          type: "live_agent",
          created_by: { type: "bot" },
          sub_type: "connected",
          reference_type: "contact",
          reference_id: user?.id + "",
          reference: {
            first_name: user?.first_name,
            last_name: user?.last_name,
          },
          type_id: `${user_details?.id || ""}`,
        },
      });
      navigate(`/chats/${user?.id}`);
      setIncomingWebchatRequest((pre) => {
        return pre?.filter((i) => i?.user_info?.id !== obj?.user_info?.id);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setAcceptingWebchatRequest((state) => ({
        ...state,
        [user?.id]: false,
      }));
    }
  };

  return (
    // <TourProvider
    //   steps={steps}

    // >

    <HelmetProvider>
      <Helmet
        {...helmetValue}
        // titleTemplate="Jwero - Building Jewelry Rocketships"
        // defaultTitle="Jwero - Building Jewelry Rocketships"
      />
      <div id="chatbot-container"></div>

      {content}

      {/* <Snackbar
        // open={obj?.from}
        open={isArrayWithValues(incomingCall)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      // sx={{ top: "50px" }}
      // autoHideDuration={6000}
      // onClose={handleClose}
      // message="Note archived"
      // action={action}
      >
        <SnackbarContent
          sx={{
            height: "130px",
            width: "600px",
            borderRadius: "50px",
            backgroundColor: "rgb(49,49,50, 0.5)",
            width: "100%"
            // backgroundColor: "background.paper",
            // color: "text.primary"
          }}
          message={incomingCall?.map(obj =>

            <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%", gap: 8 }}>
              <Stack direction={"row"} alignItems={"center"}>
                <div className="content-center">
                  <div className="pulse">
                    <Call style={{ color: "#fff" }} />
                    <i class="fas fa-phone fa-2x"></i>
                  </div>
                </div>
              </Stack>
              <Stack rowGap={2}>
                <Typography variant="h5" color={"background.paper"}>
                  Incomming Call from {obj?.from}
                </Typography>
                <Button variant="contained" sx={{ borderRadius: "16px" }}>
                  Ignore
                </Button>
              </Stack>
             

            </Stack>
          )



          }
        ></SnackbarContent>
      </Snackbar> */}
      {/* <Stack sx={{ position: "absolute", bottom: 24, right: 24 }} spacing={2}> */}

      <Snackbar
        // key={obj?.from}
        // open={obj?.from}
        open={isArrayWithValues(incomingCall)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
        transitionDuration={900}
        // sx={{
        //   "	.MuiSnackbar-root": {

        //   }
        // }}
        // sx={{ top: `${(i + 1) * 50}px` }}
        // autoHideDuration={6000}
        // onClose={handleClose}
        // message="Note archived"
        // action={action}
      >
        <Stack
          gap={2}
          direction={"column"}
          sx={{ flexDirection: "column", rowGap: 3 }}
        >
          {incomingCall?.map((obj, i) => (
            <SnackbarContent
              key={obj?.from}
              sx={{
                // height: "130px",
                width: "400px",
                borderRadius: "25px",
                backgroundColor: "#E0E5F0",
                padding: "8px 4px",
                position: "relative",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                color: "#575050",
                // width: "100%",
                // backgroundColor: "background.paper",
                // color: "text.primary"
              }}
              message={
                <Stack gap={2}>
                  {/* <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                        color: "#51F228",
                      }}
                    >
                      {" "}
                      <PhoneCallback /> Incoming call from{" "}
                    </Typography>
                    <IconButton onClick={() => onCallClose(obj)} sx={{ color: "#51F228" }}>
                      <Close style={{ color: "#51F228" }} />
                    </IconButton>
                  </Stack> */}
                  {/* <IconButton
                    onClick={() => onCallClose(obj)}
                    sx={{
                      color: "#51F228",
                      position: "absolute",
                      right: "-8px",
                      top: "-10px",
                      backgroundColor: "rgb(49,49,50, 0.9)",
                      ":hover": {
                        backgroundColor: "rgb(49,49,50, 0.9)",
                      }
                    }}
                  >
                    <Close style={{ color: "#fff" }} />
                  </IconButton> */}
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%", gap: 4 }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <div className="content-center">
                        <div className="pulse">
                          <Call style={{ color: "#fff" }} />
                        </div>
                      </div>
                    </Stack>
                    <Stack rowGap={2}>
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                      >
                        <Stack>
                          <Typography
                            variant="h4"
                            // color={"background.paper"}
                            // sx={{ fontWeight: "450" }}
                          >
                            {getFullName(obj?.info)?.trim() || "Unknown"}
                          </Typography>
                          <Typography
                            variant="h5"
                            // color={"background.paper"}
                            // sx={{ fontWeight: "400" }}
                          >
                            {obj?.from}
                          </Typography>
                        </Stack>
                        <Button
                          color="error"
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "rgba(211,47,47,0.04)",
                          }}
                          onClick={() => onCallClose(obj)}
                        >
                          Got it!
                        </Button>
                      </Stack>

                      {/* <Button variant="contained" sx={{ borderRadius: "16px" }}>
                        Ignore
                      </Button> */}
                      <Typography
                        variant="h6"
                        // color={"background.paper"}
                        // sx={{ fontWeight: "400", }}
                      >
                        Receiving on :{" "}
                        {`${getFullName(
                          reduxTeamMembers?.find((o) =>
                            obj?.to?.includes(o?.phone)
                          ) || {}
                        )}`?.trim() || obj?.to}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              }
            ></SnackbarContent>
          ))}
        </Stack>
      </Snackbar>
      <Snackbar
        // key={obj?.from}
        // open={obj?.from}
        open={isArrayWithValues(incomingWebchatRequest)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
        transitionDuration={900}
        // sx={{
        //   "	.MuiSnackbar-root": {

        //   }
        // }}
        // sx={{ top: `${(i + 1) * 50}px` }}
        // autoHideDuration={6000}
        // onClose={handleClose}
        // message="Note archived"
        // action={action}
      >
        <Stack
          gap={2}
          direction={"column"}
          sx={{ flexDirection: "column", rowGap: 3 }}
        >
          {incomingWebchatRequest?.map((obj, i) => (
            <IncomingRequestToast
              key={i}
              onWebchatReuqestAccept={onWebchatReuqestAccept}
              acceptingWebchatRequest={acceptingWebchatRequest}
              onWebchatReuqestClose={onWebchatReuqestClose}
              obj={obj}
            />
            // <SnackbarContent
            //   key={obj?.user_info?.id}
            //   sx={{
            //     // height: "130px",
            //     width: "400px",
            //     borderRadius: "25px",
            //     backgroundColor: "primary.main",
            //     padding: "8px 8px",
            //     position: "relative",
            //     boxShadow:
            //       "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            //     color: "#575050",
            //     // width: "100%",
            //     // backgroundColor: "background.paper",
            //     // color: "text.primary"
            //   }}
            //   message={
            //     <Stack gap={2}>
            //       {/* <Stack
            //         direction={"row"}
            //         alignItems={"center"}
            //         justifyContent={"space-between"}
            //         sx={{ width: "100%" }}
            //       >
            //         <Typography
            //           variant="h4"
            //           sx={{
            //             display: "flex",
            //             alignItems: "center",
            //             gap: 3,
            //             color: "#51F228",
            //           }}
            //         >
            //           {" "}
            //           <PhoneCallback /> Incoming call from{" "}
            //         </Typography>
            //         <IconButton onClick={() => onCallClose(obj)} sx={{ color: "#51F228" }}>
            //           <Close style={{ color: "#51F228" }} />
            //         </IconButton>
            //       </Stack> */}
            //       {/* <IconButton
            //         onClick={() => onWebchatReuqestClose(obj)}
            //         sx={{
            //           color: "#51F228",
            //           position: "absolute",
            //           right: "-4px",
            //           top: "-6px",
            //           backgroundColor: "rgb(49,49,50, 0.9)",
            //           ":hover": {
            //             backgroundColor: "rgb(49,49,50, 0.9)",
            //           },
            //           height: 20,
            //           width: 20,
            //         }}
            //       >
            //         <Close style={{ color: "#fff" }} />
            //       </IconButton> */}
            //       <Stack
            //         direction={"row"}
            //         justifyContent={"space-between"}
            //         sx={{ width: "100%", gap: 4 }}
            //       >
            //         <Stack direction={"row"} alignItems={"center"}>
            //           {/* <div className="content-center">
            //             <div className="pulse">
            //             </div>
            //           </div> */}
            //           <WebchatSvg
            //             style={{ color: "#fff" }}
            //             width={30}
            //             height={30}
            //           />
            //         </Stack>
            //         <Stack rowGap={2}>
            //           <Stack
            //             direction={"row"}
            //             alignItems={"center"}
            //             justifyContent={"space-between"}
            //             spacing={3}
            //           >
            //             <Stack>
            //               <Typography
            //                 variant="h4"
            //                 sx={{ color: "#fff" }}
            //                 // color={"background.paper"}
            //                 // sx={{ fontWeight: "450" }}
            //               >
            //                 {getFullName(obj?.user_info)?.trim() || "Unknown"}
            //               </Typography>
            //               <PrimaryLabelSmall sx={{ color: "#fff" }}>
            //                 New Web chat request
            //               </PrimaryLabelSmall>
            //             </Stack>
            //           </Stack>
            //           <Stack sx={{ flex: 1 }} direction={"row"} spacing={2}>
            //             {/* <ButtonGroup
            //                 orientation="vertical"
            //                 aria-label="vertical outlined button group"
            //               >
            //               </ButtonGroup> */}
            //             <LoadingButton
            //               color="success"
            //               sx={{
            //                 // position: "absolute",
            //                 // top: "10px",
            //                 // right: "10px",
            //                 whiteSpace: "nowrap",
            //                 backgroundColor: "#67BD86",
            //               }}
            //               loading={
            //                 acceptingWebchatRequest?.[obj?.user_info?.id]
            //               }
            //               // size="small"
            //               variant="contained"
            //               startIcon={<Check />}
            //               fullWidth
            //               onClick={() => onWebchatReuqestAccept(obj)}
            //             >
            //               Accept Request
            //             </LoadingButton>
            //             <Button
            //               color="error"
            //               sx={{
            //                 // position: "absolute",
            //                 // top: "10px",
            //                 // right: "10px",
            //                 backgroundColor: "#E04F55",
            //               }}
            //               // onClick={() => onCallClose(obj)}
            //               variant="contained"
            //               startIcon={<Close />}
            //               size="small"
            //               fullWidth
            //             >
            //               Ignore
            //             </Button>
            //           </Stack>

            //           {/* <Button variant="contained" sx={{ borderRadius: "16px" }}>
            //             Ignore
            //           </Button> */}
            //           {/* <Typography
            //             variant="h6"
            //             // color={"background.paper"}
            //             // sx={{ fontWeight: "400", }}
            //           >
            //             New Web chat request
            //           </Typography> */}
            //         </Stack>
            //       </Stack>
            //     </Stack>
            //   }
            // ></SnackbarContent>
          ))}
        </Stack>
      </Snackbar>
      {/* ))} */}
      {/* </Stack> */}

      {/* <Dialog
        open={true}
        // onClose={handleClose}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Subscribe
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
          // onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
          // onClick={handleClose}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog> */}
      <PlanExpiryWarning ref={expiryDialogWarning} />
      <PlanUpgraded ref={planUpgradedRef} />
      <DeviceLoginExceed ref={deviceLoginExceedRef} />
      <OnBoardingDialog
        open={onBoardingDialogState}
        setOpen={setOnBoardingDialogState}
      />
      <SupplierBasicProfileDialog
        ref={supplierBasicProfileRef}
        onAccept={connectSupplierWithRetailer}
        loading={connectSupplierLoading}
      />
      {multipleUserProduct && <GetStartedDialog />}
      <Snackbar
        anchorOrigin={{
          vertical: globalSnackbar.vertical || "top",
          horizontal: globalSnackbar.horizontal || "right",
        }}
        open={globalSnackbar.show}
        onClose={globalSnackbarClose}
        message={globalSnackbar.message}
        // key={vertical + horizontal}
      />
      <Snackbar
        open={globalToast.show}
        autoHideDuration={globalToast.timeout || 5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Slide}
        // style={{ marginRight: "2vw" }}
        message={
          <Stack direction="row" spacing={1.5} alignItems={"center"}>
            {globalToast.severity === "error" ? (
              <Cancel color="error" />
            ) : globalToast.severity === "info" ? (
              <Info color="info" />
            ) : globalToast.severity === "whatsapp" ? (
              <Whatsapp />
            ) : globalToast.severity === "facebook" ? (
              <Messenger />
            ) : globalToast.severity === "instagram" ? (
              <InstagramSvg />
            ) : (
              <CheckCircle color="success" />
            )}
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                {globalToast.message || "Success"}
              </Typography>
              {/* <Button
                color={"success"}
                size="small"
                onClick={() => {
                  navigate(globalToast.link);
                  dispatch(setGlobalToast({ ...globalToast, show: false }));
                }}
                sx={{ mt: 1, marginLeft: "auto" }}
              >
                Open
              </Button> */}
            </Box>
          </Stack>
        }
        action={
          globalToast.link ? (
            <Button
              color={"success"}
              size="small"
              onClick={() => {
                globalToast.link && navigate(globalToast.link);
                dispatch(setGlobalToast({ ...globalToast, show: false }));
              }}
            >
              Open
            </Button>
          ) : null
        }
        onClose={() =>
          dispatch(
            setGlobalToast({ ...globalToast, show: false, timeout: 5000 })
          )
        }
      >
        {/* <Alert severity={globalToast.severity} sx={{ width: "100%" }}>
          {globalToast.message}
        </Alert> */}
      </Snackbar>

      <TutorialVideo />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!isOnline}
        onClose={() => setIsOnline(false)}
      >
        <Alert severity="error">No Internet Connection</Alert>
      </Snackbar>
      {/* {!webinarRegister?.view && user_details?.id ? (
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "#fff",
            borderRadius: 3,
            zIndex: 999999,
            // boxShadow:
            //   "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <Stack justifyContent={"flex-end"} direction={"row"}>
            <IconButton
              onClick={() => {
                dispatch({
                  type: SET_PERSIST_DATA,
                  payload: {
                    webinarRegister: {
                      view: true,
                      time: getTimeInMilliSeconds(),
                    },
                  },
                });
              }}
            >
              <Close />{" "}
            </IconButton>
          </Stack>
          <iframe
            width="468px"
            height="263px"
            frameborder="0"
            allowfullscreen
            src="https://showtime.zoho.in/embedsession#sessionId=6732000000204005&zaid=50001481993&utm_st_source=embed&type=1"
          ></iframe>
        </Box>
      ) : null} */}
    </HelmetProvider>

    // </TourProvider>
  );
}

export default App;

const getGoogleTokenExpire = async (access_token) => {
  let res = await axios({
    method: "POST",
    url: `https://oauth2.googleapis.com/tokeninfo`,
    // params: { id_token: `${access_token}` },
    // url: "https://www.googleapis.com/oauth2/v1/tokeninfo",
    headers: { Authorization: `Bearer ${access_token}` },
  });
  return res;
};

const refreshGoogleToken = async (refresh_token) => {
  let res = await axios({
    method: "POST",
    url: `https://accounts.google.com/o/oauth2/token`,
    data: {
      refresh_token,
      client_id:
        "448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com",
      client_secret: "28QrjjfHZljyTaBGE2sNHzZG",
      grant_type: "refresh_token",
    },
  });
  return res;
};

const updateGoogle = async (access_token, refresh_token, spreadsheetId) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    const res = await axios({
      url: `${website}/wp-json/store/v1/apps/?apps=google`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: {
        google_access_token: access_token,
        google_refresh_token_token: refresh_token,
        google_spreadsheetId: spreadsheetId,
      },
    });
    return res;
  }
};
export const deleteGoogleIntegration = async () => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    const res = await axios({
      url: `${website}/wp-json/store/v1/apps/?apps=google`,
      headers: { Authorization: `Basic ${token}` },
      method: "DELETE",
      // data: {
      //   google_access_token: access_token,
      //   google_refresh_token_token: refresh_token,
      //   google_spreadsheetId: spreadsheetId,
      // },
    });
    return res.data;
  }
};

export const revokeGoogle = async (
  google_access_token,
  google_refresh_token_token
) => {
  try {
    let res = await axios({
      url: "https://accounts.google.com/o/oauth2/revoke",
      method: "POST",
      headers: {
        Authorization: `Bearer ${google_access_token}`,
        "Content-Type": "application/json",
      },
      data: { token: google_refresh_token_token },
    });
    return res.data;
  } catch (error) {
    devLog(getAxiosError(error), "google revoke error");
  }
};

const sendTokenToBackend = (token) => {
  console.log(token, ` Token`);
  let website = getWebsite();
  let tokenAuth = getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/store/v1/settings/fcm_devices`,
      method: "GET",
      headers: {
        Authorization: `Basic ${tokenAuth}`,
      },
      // data: [],
    }).then(async (res) => {
      let { data } = res;
      let array = [];
      let allTokens = data.data || [];
      let tokenFound = true;
      let ip = await getIp();

      if (Array.isArray(allTokens)) {
        if (!allTokens.find((i) => i.token === token)) {
          tokenFound = false;
          allTokens.unshift({
            token: token,
            ip,
            ua: new UAParser().getUA(),
            time: new Date().getTime(),
          });
        }
        array = allTokens;
      }

      if (!tokenFound)
        axios({
          url: `${website}/wp-json/store/v1/settings`,
          method: "POST",
          headers: {
            Authorization: `Basic ${tokenAuth}`,
          },
          data: {
            fcm_devices: array,
          },
        })
          .then((res) => console.log(res.data, "POST DATA"))
          .catch((e) => console.log(e));
    });
};

export const getIp = async () => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  } catch (error) {
    return "";
  }
};

export const fetchIntegrations = async () => {
  const token = getToken();
  const website = getWebsite();
  if (token && website) {
    let res = await axios({
      url: `${website}/wp-json/store/v1/apps/?apps=google,gupshup`,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    return res;
  }
};

// const getZohoUpgradeInfo = async (hostedpage_id, token) => {
//   try {
//     let { data } = await axios({
//       url: "https://accounts.zoho.in/oauth/v2/token",
//       headers: {
//         Authorization: `Zoho-oauthtoken ${token}`,
//         "X-com-zoho-subscriptions-organizationid": hostedpage_id,
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     });
//     console.log(data);
//   } catch (error) {
//     console.log(getAxiosError(error));
//     return false;
//   }
// };

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }
