import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import DocLayout from "./layouts/Doc";
// import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
// import Accordion from "./pages/components/Accordion";
// import Alerts from "./pages/components/Alerts";
// import Avatars from "./pages/components/Avatars";
// import Badges from "./pages/components/Badges";
// import Buttons from "./pages/components/Buttons";
// import Cards from "./pages/components/Cards";
// import Chips from "./pages/components/Chips";
// import Dialogs from "./pages/components/Dialogs";
// import Lists from "./pages/components/Lists";
// import Menus from "./pages/components/Menus";
// import Pagination from "./pages/components/Pagination";
// import Progress from "./pages/components/Progress";
// import Snackbars from "./pages/components/Snackbars";
// import Tooltips from "./pages/components/Tooltips";

// // Form components
// import SelectionCtrls from "./pages/forms/SelectionControls";
// import Selects from "./pages/forms/Selects";
// import TextFields from "./pages/forms/TextFields";

// // Icon components
// import MaterialIcons from "./pages/icons/MaterialIcons";

// // Page components
// import Blank from "./pages/pages/Blank";
// import InvoiceDetails from "./pages/pages/InvoiceDetails";
// import InvoiceList from "./pages/pages/InvoiceList";
// import Orders from "./pages/pages/Orders";
// import Pricing from "./pages/pages/Pricing";
// import { default as TemplateSettings } from "./pages/pages/Settings";
// import Projects from "./pages/pages/Projects";

// // Table components
// import SimpleTable from "./pages/tables/SimpleTable";
// import AdvancedTable from "./pages/tables/AdvancedTable";

// // Documentation
// import Welcome from "./pages/docs/Welcome";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import Deployment from "./pages/docs/Deployment";
// import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
// import Support from "./pages/docs/Support";
// import Changelog from "./pages/docs/Changelog";

// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
// import AllProducts from "./pages/products/AllProducts";
// const AllProducts = async(() => import("./pages/products/AllProducts"));

// import AddProduct from "./pages/products/AddProduct";
import Settings from "./pages/settings_new/Settings";
import DeprecatedSettings from "./pages/settings/Settings";
// import CRM from "./pages/crm/CRM";
// import { default as CustomerView } from "./pages/crm/View";
import { default as AddCustomer } from "./pages/crm/Add";
// import Catalogs from "./pages/catalogs/Catalogs";
// import { default as CatalogRequests } from "./pages/catalogs/Requests";
import { default as CatalogRequests } from "./pages/catalogs/CatalogRequests";
import RequestView from "./pages/catalogs/RequestView";
import Integrations from "./pages/integrations/Integrations";
import Onboarding from "./pages/auth/Onboarding";
import Registration from "./pages/auth/Registration";
import Notifications from "./pages/pages/Notifications";
// import CatalogView from "./pages/catalogs/CatalogView";
import SpreadsheetHistory from "./pages/pages/SpreadsheetHistory";
import RetailerConnect from "./pages/pages/RetailerConnect";
import Pricing from "./pages/pricing";
// import VisitorsOverview from "./pages/visitors/VisitorsOverview";
// import VisitorsDetails from "./pages/visitors/VisitorsDetails";
import Quotations from "./pages/quotation/Quotations";
import QuotationView from "./pages/quotation/QuotationView";
import Hub from "./pages/hub";
import DesignBank from "./pages/design_bank";
import MemberRegister from "./pages/auth/MemberRegister";
import Orders from "./pages/orders/Orders";
import Order from "./pages/orders/Order";
import Plans from "./pages/digital_gold/Plans";
import Frontend from "./pages/frontend/Frontend";
import Migration from "./pages/mirgration";
import { Navigate } from "react-router-dom";
import Users from "./pages/digital_gold/Users";
import User from "./pages/digital_gold/User";
import Digigold from "./pages/digital_gold";
import OverdueUsers from "./pages/digital_gold/OverdueUsers";
import CatalogsSent from "./pages/catalogs/CatalogsSent";
import SupplierConnect from "./pages/pages/SupplierConnect";
// import CatalogView2 from "./pages/catalogs/CatalogView2";
// import CatalogRequestView from "./pages/catalogs/CatalogRequestView";
import { getIsBackendMigrated, isDev } from "./helper";
import BasicSettings from "./pages/pages/BasicSettings";
import PricingTable from "./pages/pricingTable/PricingTable";
import AllSuppliers from "./pages/design_bank/Suppliers";
import ProfilePage from "./pages/design_bank/ProfilePage";
import NewSupplier from "./pages/design_bank/NewSupplier";
import DesignbankNotifications from "./pages/design_bank/Notifications";
// import ZohoPricing from "./pages/components/Pricing";
import NewVisitors from "./pages/newVisitors";
import UserTasks from "./pages/user_tasks";
import QuotationViewNew from "./pages/quotation/QuotationViewNew";
import Registration3 from "./pages/auth/Registration3";
// import Registration2 from "./pages/auth/Registration2";
import CampaignFlowBuilder from "./pages/flowBuilder";
import CampaignFlow from "./pages/flowBuilder/CampaignFlow";
import DataSheet from "./pages/datasheet";
import CampaignView from "./pages/campaign/CampaignView";
import ProductDataSheet from "./pages/datasheet/product";
import CRMDataSheet from "./pages/datasheet/crm";
import ChatsDataSheet from "./pages/datasheet/chats";
import Flows from "./pages/flows";
import AddFlow from "./pages/flows/pages/addFlow";
import MarketingAutomation from "./pages/marketing_automation";

// Dashboard components
// const CatalogView2 = asyncComponent(
//   () => import("./pages/catalogs/CatalogView2"),
//   100,
//   <Loader />
// );
const CatalogView2 = async(() => import("./pages/catalogs/CatalogView2"));
const CatalogRequestView = async(() =>
  import("./pages/catalogs/CatalogRequestView")
);
const CatalogView = async(() => import("./pages/catalogs/CatalogView"));
const Catalogs = async(() => import("./pages/catalogs"));
// const Catalogs = async(() => import("./pages/catalogs/Catalogs"));
// const CRM = async(() => import("./pages/crm/CRM"));
const Customers = async(() => import("./pages/customers/Customers"));
const AllProducts = async(() => import("./pages/products/AllProducts"), 50);
const DeprecatedChat = async(() => import("./pages/chat"), 50);
const Chat = async(() => import("./pages/chat_new"), 50);
const AddProduct = async(() => import("./pages/products/AddProduct"), 400);
const Default = async(() => import("./pages/dashboards/Default"), 50);
const V2Dashboard = async(() => import("./pages/dashboards/V2"), 50);
const Campaign = async(() => import("./pages/campaign"), 50);
// const Analytics = async(() => import("./pages/dashboards/Analytics"));
// const SaaS = async(() => import("./pages/dashboards/SaaS"));

// // Form components
// const Pickers = async(() => import("./pages/forms/Pickers"));
// const Editors = async(() => import("./pages/forms/Editors"));
// const Formik = async(() => import("./pages/forms/Formik"));

// // Icon components
// const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
// const Tasks = async(() => import("./pages/pages/Tasks"));
// const Calendar = async(() => import("./pages/pages/Calendar"));

// // Table components
// const DataGrid = async(() => import("./pages/tables/DataGrid"));

// // Chart components
// const Chartjs = async(() => import("./pages/charts/Chartjs"));

// // Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

let isIos = false;

// if (ua.name && ua.name.toLowerCase() === "ios") isIos = true;

const routes = [
  // {
  //   path: "/",
  //   element: <PresentationLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "pricing",
    // element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <PricingTable />,
      },
    ],
  },
  {
    path: "user_activities",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <UserTasks />
            {/* <Default /> */}
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "campaign",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Campaign />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            <CampaignView />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "campaign-flow",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <CampaignFlow />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            <CampaignFlow />
          </AuthGuard>
        ),
        children: [
          {
            path: "report",
            element: (
              <AuthGuard>
                <CampaignFlow />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Navigate to="/dashboard" />
            {/* <Default /> */}
          </AuthGuard>
        ),
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: isIos ? (
          <AuthGuard>
            <AllProducts />
          </AuthGuard>
        ) : (
          <AuthGuard>
            {!getIsBackendMigrated("chats") ? <Default /> : <V2Dashboard />}
          </AuthGuard>
        ),
      },
      // {
      //   path: "default",
      //   element: <Default />,
      // },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
    ],
  },

  // {
  //   path: "pages",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "profile",
  //       element: <Profile />,
  //     },
  //     {
  //       path: "settings",
  //       element: <TemplateSettings />,
  //     },
  //     {
  //       path: "pricing",
  //       element: <Pricing />,
  //     },
  //     {
  //       path: "chat",
  //       element: <Chat />,
  //     },
  //     {
  //       path: "blank",
  //       element: <Blank />,
  //     },
  //   ],
  // },
  {
    path: "products",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            {/* <DashboardLayout hideSideBar> */}
            <AllProducts />
            {/* </DashboardLayout> */}
          </AuthGuard>
        ),
      },
      {
        path: "add",
        element: (
          <AuthGuard>
            {/* <DashboardLayout> */}
            <AddProduct />
            {/* </DashboardLayout> */}
          </AuthGuard>
        ),
      },
      {
        path: "edit/:id",
        element: (
          <AuthGuard>
            {/* <DashboardLayout> */}
            <AddProduct />
            {/* </DashboardLayout> */}
          </AuthGuard>
        ),
      },
      {
        path: "bulk_edit",
        element: (
          <AuthGuard>
            {/* <DashboardLayout hideSideBar={true}> */}
            <ProductDataSheet />
            {/* </DashboardLayout> */}
          </AuthGuard>
        ),
      },
      {
        path: "bulk_add",
        element: (
          <AuthGuard>
            {/* <DashboardLayout hideSideBar={true}> */}
            <ProductDataSheet />
            {/* </DashboardLayout> */}
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            {getIsBackendMigrated("chats") ? (
              <Settings />
            ) : (
              <DeprecatedSettings />
            )}
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "basic_settings",
    // element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <BasicSettings />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "chats",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        new: getIsBackendMigrated("chats"),
        element: (
          <AuthGuard>
            {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
            {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />}
          </AuthGuard>
        ),
      },
      {
        path: "sheet",
        element: (
          <AuthGuard>
            {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
            <ChatsDataSheet />
            {/* {isDev() ? <Chat /> : <DeprecatedChat />} */}
          </AuthGuard>
        ),
      },
      {
        path: "sheet_update",
        element: (
          <AuthGuard>
            {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
            <ChatsDataSheet />
            {/* {isDev() ? <Chat /> : <DeprecatedChat />} */}
          </AuthGuard>
        ),
      },

      {
        path: ":id",
        element: (
          <AuthGuard>
            {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />}
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "notifications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Notifications />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "campaignFlowBuilder",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <CampaignFlowBuilder />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "spreadsheet_history",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <SpreadsheetHistory />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "supplier_connect",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <SupplierConnect />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "retailer_connect",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <RetailerConnect />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "integrations",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Integrations />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "masters",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Pricing />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "visitors_info",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <NewVisitors />
            {/* {isDev() ? <NewVisitors /> : <VisitorsOverview />} */}
          </AuthGuard>
        ),
      },
      {
        path: "details",
        element: (
          <AuthGuard>
            <NewVisitors />
            {/* {isDev() ? <NewVisitors /> : <VisitorsDetails />} */}
          </AuthGuard>
        ),
      },
      {
        path: "details/:id",
        element: (
          <AuthGuard>
            <NewVisitors />
            {/* {isDev() ? <NewVisitors /> : <VisitorsDetails />} */}
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: "quotations",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Quotations />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            {isDev() ? <QuotationViewNew /> : <QuotationView />}
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "hub",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Hub />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "ekdam-random",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Campaign />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "design-bank",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <DesignBank isUnderContruction={!isDev()} />
          </AuthGuard>
        ),
      },
      {
        path: "notifications",
        element: (
          <AuthGuard>
            <DesignbankNotifications />
          </AuthGuard>
        ),
      },
      {
        path: "suppliers",
        element: (
          <AuthGuard>
            <AllSuppliers isUnderContruction={!isDev()} />
          </AuthGuard>
        ),
      },
      {
        path: "suppliers/:id",
        element: (
          <AuthGuard>
            <ProfilePage />
          </AuthGuard>
        ),
      },
      {
        path: "new_suppliers/:id",
        element: (
          <AuthGuard>
            <NewSupplier />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Orders />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            <Order />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "digigold",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Digigold />
          </AuthGuard>
        ),
      },
      {
        path: "plans",
        element: (
          <AuthGuard>
            <Plans />
          </AuthGuard>
        ),
      },
      {
        path: "users",
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: "overdue_users",
        element: (
          <AuthGuard>
            <OverdueUsers />
          </AuthGuard>
        ),
      },
      // {
      //   path: ":id",
      //   element: (
      //     <AuthGuard>
      //       <User />
      //     </AuthGuard>
      //   ),
      // },
    ],
  },
  {
    path: "digigold/users",
    element: <DashboardLayout />,
    children: [
      {
        path: ":id",
        element: (
          <AuthGuard>
            <User />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "migration",
    // element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Migration />
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: "frontend",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Frontend />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "crm",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Customers />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            <Customers />
          </AuthGuard>
        ),
      },
      // {
      //   path: "add",
      //   element: (
      //     <AuthGuard>
      //       <AddCustomer />
      //     </AuthGuard>
      //   ),
      // },
    ],
  },
  {
    path: "crm_bulk_add",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <CRMDataSheet />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "crm_bulk_edit",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <CRMDataSheet />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "flows",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Flows />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "flows/create",
    element: <DashboardLayout hideSideBar noPadding />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <AddFlow />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "flows/:id",
    element: <DashboardLayout hideSideBar noPadding />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <AddFlow />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "catalogs",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Catalogs />
          </AuthGuard>
        ),
      },
      {
        path: ":id",
        element: (
          <AuthGuard>
            <CatalogView2 />
          </AuthGuard>
        ),
      },
      {
        path: "sent",
        element: (
          <AuthGuard>
            <CatalogsSent />
          </AuthGuard>
        ),
      },
      {
        path: "view",
        element: (
          <AuthGuard>
            <CatalogView />
          </AuthGuard>
        ),
      },
      {
        path: "requests",
        element: (
          <AuthGuard>
            <CatalogRequests />
          </AuthGuard>
        ),
      },
      {
        path: "requests/:id",
        element: (
          <AuthGuard>
            <CatalogRequestView />
          </AuthGuard>
        ),
      },
      {
        path: "requests/view",
        element: (
          <AuthGuard>
            <RequestView />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "profile",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Profile />
          </AuthGuard>
        ),
      },
      {
        path: "requests",
        element: (
          <AuthGuard>
            <CatalogRequests />
          </AuthGuard>
        ),
      },
      {
        path: "requests/view",
        element: (
          <AuthGuard>
            <RequestView />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "marketing_automation",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <MarketingAutomation />
          </AuthGuard>
        ),
      },
    ],
  },
  // {
  //   path: "projects",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Projects />,
  //     },
  //   ],
  // },
  // {
  //   path: "invoices",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <InvoiceList />,
  //     },
  //     {
  //       path: "detail",
  //       element: <InvoiceDetails />,
  //     },
  //   ],
  // },
  // {
  //   path: "orders",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Orders />,
  //     },
  //   ],
  // },
  // {
  //   path: "tasks",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Tasks />,
  //     },
  //   ],
  // },
  // {
  //   path: "calendar",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Calendar />,
  //     },
  //   ],
  // },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      // {
      //   path: "sign-in",
      //   element: <SignIn />,
      // },
      // {
      //   path: "sign-up",
      //   element: <SignUp />,
      // },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  // {
  //   path: "auth/sign-up",
  //   element: <SignUp />,
  //   // element: <DashboardLayout />,
  //   // children: [{ path: "", element: <SignUp /> }],
  // },
  {
    path: "auth/register",
    element: !isDev() ? <Registration3 /> : <Registration />,
    // element: <DashboardLayout />,
    // children: [{ path: "", element: <SignUp /> }],
  },
  {
    path: "auth/sign-in",
    element: <SignIn />,
  },
  {
    path: "auth/sign-up",
    element: <SignUp />,
  },
  {
    path: "onboarding",
    element: <Onboarding />,
  },
  {
    path: "auth/member_register",
    element: <MemberRegister />,
  },
  // {
  //   path: "components",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "accordion",
  //       element: <Accordion />,
  //     },
  //     {
  //       path: "alerts",
  //       element: <Alerts />,
  //     },
  //     {
  //       path: "avatars",
  //       element: <Avatars />,
  //     },
  //     {
  //       path: "badges",
  //       element: <Badges />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "chips",
  //       element: <Chips />,
  //     },
  //     {
  //       path: "dialogs",
  //       element: <Dialogs />,
  //     },
  //     {
  //       path: "lists",
  //       element: <Lists />,
  //     },
  //     {
  //       path: "menus",
  //       element: <Menus />,
  //     },
  //     {
  //       path: "pagination",
  //       element: <Pagination />,
  //     },
  //     {
  //       path: "progress",
  //       element: <Progress />,
  //     },
  //     {
  //       path: "snackbars",
  //       element: <Snackbars />,
  //     },
  //     {
  //       path: "tooltips",
  //       element: <Tooltips />,
  //     },
  //   ],
  // },
  // {
  //   path: "forms",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "pickers",
  //       element: <Pickers />,
  //     },
  //     {
  //       path: "selection-controls",
  //       element: <SelectionCtrls />,
  //     },
  //     {
  //       path: "selects",
  //       element: <Selects />,
  //     },
  //     {
  //       path: "text-fields",
  //       element: <TextFields />,
  //     },
  //     {
  //       path: "editors",
  //       element: <Editors />,
  //     },
  //     {
  //       path: "formik",
  //       element: <Formik />,
  //     },
  //   ],
  // },
  // {
  //   path: "tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "simple-table",
  //       element: <SimpleTable />,
  //     },
  //     {
  //       path: "advanced-table",
  //       element: <AdvancedTable />,
  //     },
  //     {
  //       path: "data-grid",
  //       element: <DataGrid />,
  //     },
  //   ],
  // },
  // {
  //   path: "icons",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "material-icons",
  //       element: <MaterialIcons />,
  //     },
  //     {
  //       path: "feather-icons",
  //       element: <FeatherIcons />,
  //     },
  //   ],
  // },
  // {
  //   path: "charts",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Chartjs />,
  //     },
  //   ],
  // },
  // {
  //   path: "maps",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "google-maps",
  //       element: <GoogleMaps />,
  //     },
  //     {
  //       path: "vector-maps",
  //       element: <VectorMaps />,
  //     },
  //   ],
  // },
  // {
  //   path: "documentation",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "welcome",
  //       element: <Welcome />,
  //     },
  //     {
  //       path: "getting-started",
  //       element: <GettingStarted />,
  //     },
  //     {
  //       path: "routing",
  //       element: <Routing />,
  //     },
  //     {
  //       path: "auth/auth0",
  //       element: <Auth0 />,
  //     },
  //     {
  //       path: "auth/cognito",
  //       element: <Cognito />,
  //     },
  //     {
  //       path: "auth/firebase",
  //       element: <Firebase />,
  //     },
  //     {
  //       path: "auth/jwt",
  //       element: <JWT />,
  //     },
  //     {
  //       path: "guards",
  //       element: <Guards />,
  //     },
  //     {
  //       path: "environment-variables",
  //       element: <EnvironmentVariables />,
  //     },
  //     {
  //       path: "deployment",
  //       element: <Deployment />,
  //     },
  //     {
  //       path: "theming",
  //       element: <Theming />,
  //     },
  //     {
  //       path: "api-calls",
  //       element: <APICalls />,
  //     },
  //     {
  //       path: "redux",
  //       element: <Redux />,
  //     },
  //     {
  //       path: "internationalization",
  //       element: <Internationalization />,
  //     },
  //     {
  //       path: "eslint-and-prettier",
  //       element: <ESLintAndPrettier />,
  //     },
  //     {
  //       path: "migrating-to-next-js",
  //       element: <MigratingToNextJS />,
  //     },
  //     {
  //       path: "support",
  //       element: <Support />,
  //     },
  //   ],
  // },
  // {
  //   path: "changelog",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;

export const getUpdatedRoutes = () => {
  // const routes = [
  //   // {
  //   //   path: "/",
  //   //   element: <PresentationLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Landing />,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     path: "pricing",
  //     // element: <PresentationLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: <PricingTable />,
  //       },
  //     ],
  //   },
  //   {
  //     path: "user_activities",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <UserTasks />
  //             {/* <Default /> */}
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "campaign",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Campaign />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <CampaignView />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "campaign-flow",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <CampaignFlow />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <CampaignFlow />
  //           </AuthGuard>
  //         ),
  //         children: [
  //           {
  //             path: "report",
  //             element: (
  //               <AuthGuard>
  //                 <CampaignFlow />
  //               </AuthGuard>
  //             ),
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     path: "/",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Navigate to="/dashboard" />
  //             {/* <Default /> */}
  //           </AuthGuard>
  //         ),
  //       },
  //       // {
  //       //   path: "analytics",
  //       //   element: <Analytics />,
  //       // },
  //       // {
  //       //   path: "saas",
  //       //   element: <SaaS />,
  //       // },
  //     ],
  //   },
  //   {
  //     path: "dashboard",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: isIos ? (
  //           <AuthGuard>
  //             <AllProducts />
  //           </AuthGuard>
  //         ) : (
  //           <AuthGuard>
  //             {!getIsBackendMigrated("chats") ? <Default /> : <V2Dashboard />}
  //           </AuthGuard>
  //         ),
  //       },
  //       // {
  //       //   path: "default",
  //       //   element: <Default />,
  //       // },
  //       // {
  //       //   path: "analytics",
  //       //   element: <Analytics />,
  //       // },
  //       // {
  //       //   path: "saas",
  //       //   element: <SaaS />,
  //       // },
  //     ],
  //   },

  //   // {
  //   //   path: "pages",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "profile",
  //   //       element: <Profile />,
  //   //     },
  //   //     {
  //   //       path: "settings",
  //   //       element: <TemplateSettings />,
  //   //     },
  //   //     {
  //   //       path: "pricing",
  //   //       element: <Pricing />,
  //   //     },
  //   //     {
  //   //       path: "chat",
  //   //       element: <Chat />,
  //   //     },
  //   //     {
  //   //       path: "blank",
  //   //       element: <Blank />,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     path: "products",
  //     // element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout>
  //               <AllProducts />
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "add",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout>
  //               <AddProduct />
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "edit/:id",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout>
  //               <AddProduct />
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "bulk_edit",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout hideSideBar={true}>
  //               <ProductDataSheet />
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "bulk_add",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout hideSideBar={true}>
  //               <ProductDataSheet />
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "settings",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             {getIsBackendMigrated("chats") ? (
  //               <Settings />
  //             ) : (
  //               <DeprecatedSettings />
  //             )}
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "basic_settings",
  //     // element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <BasicSettings />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "chats",
  //     // element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         new: getIsBackendMigrated("chats"),
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout>
  //               {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
  //               {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />}
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "sheet",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout hideSideBar={true}>
  //               <ChatsDataSheet />
  //             </DashboardLayout>
  //             {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
  //             {/* {isDev() ? <Chat /> : <DeprecatedChat />} */}
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "sheet_update",
  //         element: (
  //           <AuthGuard>
  //             {/* {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />} */}
  //             <DashboardLayout hideSideBar={true}>
  //               <ChatsDataSheet />
  //             </DashboardLayout>
  //             {/* {isDev() ? <Chat /> : <DeprecatedChat />} */}
  //           </AuthGuard>
  //         ),
  //       },

  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <DashboardLayout>
  //               {getIsBackendMigrated("chats") ? <Chat /> : <DeprecatedChat />}
  //             </DashboardLayout>
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "notifications",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Notifications />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "campaignFlowBuilder",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <CampaignFlowBuilder />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "spreadsheet_history",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <SpreadsheetHistory />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "supplier_connect",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <SupplierConnect />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "retailer_connect",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <RetailerConnect />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "integrations",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Integrations />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "masters",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Pricing />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "visitors_info",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <NewVisitors />
  //             {/* {isDev() ? <NewVisitors /> : <VisitorsOverview />} */}
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "details",
  //         element: (
  //           <AuthGuard>
  //             <NewVisitors />
  //             {/* {isDev() ? <NewVisitors /> : <VisitorsDetails />} */}
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "details/:id",
  //         element: (
  //           <AuthGuard>
  //             <NewVisitors />
  //             {/* {isDev() ? <NewVisitors /> : <VisitorsDetails />} */}
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },

  //   {
  //     path: "quotations",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Quotations />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             {isDev() ? <QuotationViewNew /> : <QuotationView />}
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "hub",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Hub />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "ekdam-random",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Campaign />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "design-bank",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <DesignBank isUnderContruction={!isDev()} />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "notifications",
  //         element: (
  //           <AuthGuard>
  //             <DesignbankNotifications />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "suppliers",
  //         element: (
  //           <AuthGuard>
  //             <AllSuppliers isUnderContruction={!isDev()} />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "suppliers/:id",
  //         element: (
  //           <AuthGuard>
  //             <ProfilePage />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "new_suppliers/:id",
  //         element: (
  //           <AuthGuard>
  //             <NewSupplier />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "orders",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Orders />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <Order />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "digigold",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Digigold />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "plans",
  //         element: (
  //           <AuthGuard>
  //             <Plans />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "users",
  //         element: (
  //           <AuthGuard>
  //             <Users />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "overdue_users",
  //         element: (
  //           <AuthGuard>
  //             <OverdueUsers />
  //           </AuthGuard>
  //         ),
  //       },
  //       // {
  //       //   path: ":id",
  //       //   element: (
  //       //     <AuthGuard>
  //       //       <User />
  //       //     </AuthGuard>
  //       //   ),
  //       // },
  //     ],
  //   },
  //   {
  //     path: "digigold/users",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <User />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "migration",
  //     // element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Migration />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },

  //   {
  //     path: "frontend",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Frontend />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "crm",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Customers />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <Customers />
  //           </AuthGuard>
  //         ),
  //       },
  //       // {
  //       //   path: "add",
  //       //   element: (
  //       //     <AuthGuard>
  //       //       <AddCustomer />
  //       //     </AuthGuard>
  //       //   ),
  //       // },
  //     ],
  //   },
  //   {
  //     path: "crm_bulk_add",
  //     element: <DashboardLayout hideSideBar={true} />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <CRMDataSheet />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "crm_bulk_edit",
  //     element: <DashboardLayout hideSideBar={true} />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <CRMDataSheet />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "catalogs",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Catalogs />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: ":id",
  //         element: (
  //           <AuthGuard>
  //             <CatalogView2 />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "sent",
  //         element: (
  //           <AuthGuard>
  //             <CatalogsSent />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "view",
  //         element: (
  //           <AuthGuard>
  //             <CatalogView />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "requests",
  //         element: (
  //           <AuthGuard>
  //             <CatalogRequests />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "requests/:id",
  //         element: (
  //           <AuthGuard>
  //             <CatalogRequestView />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "requests/view",
  //         element: (
  //           <AuthGuard>
  //             <RequestView />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     path: "profile",
  //     element: <DashboardLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: (
  //           <AuthGuard>
  //             <Profile />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "requests",
  //         element: (
  //           <AuthGuard>
  //             <CatalogRequests />
  //           </AuthGuard>
  //         ),
  //       },
  //       {
  //         path: "requests/view",
  //         element: (
  //           <AuthGuard>
  //             <RequestView />
  //           </AuthGuard>
  //         ),
  //       },
  //     ],
  //   },
  //   // {
  //   //   path: "projects",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Projects />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "invoices",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <InvoiceList />,
  //   //     },
  //   //     {
  //   //       path: "detail",
  //   //       element: <InvoiceDetails />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "orders",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Orders />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "tasks",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Tasks />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "calendar",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Calendar />,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     path: "auth",
  //     element: <AuthLayout />,
  //     children: [
  //       // {
  //       //   path: "sign-in",
  //       //   element: <SignIn />,
  //       // },
  //       // {
  //       //   path: "sign-up",
  //       //   element: <SignUp />,
  //       // },
  //       {
  //         path: "reset-password",
  //         element: <ResetPassword />,
  //       },
  //       {
  //         path: "404",
  //         element: <Page404 />,
  //       },
  //       {
  //         path: "500",
  //         element: <Page500 />,
  //       },
  //     ],
  //   },
  //   // {
  //   //   path: "auth/sign-up",
  //   //   element: <SignUp />,
  //   //   // element: <DashboardLayout />,
  //   //   // children: [{ path: "", element: <SignUp /> }],
  //   // },
  //   {
  //     path: "auth/register",
  //     element: !isDev() ? <Registration3 /> : <Registration />,
  //     // element: <DashboardLayout />,
  //     // children: [{ path: "", element: <SignUp /> }],
  //   },
  //   {
  //     path: "auth/sign-in",
  //     element: <SignIn />,
  //   },
  //   {
  //     path: "auth/sign-up",
  //     element: <SignUp />,
  //   },
  //   {
  //     path: "onboarding",
  //     element: <Onboarding />,
  //   },
  //   {
  //     path: "auth/member_register",
  //     element: <MemberRegister />,
  //   },
  //   // {
  //   //   path: "components",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "accordion",
  //   //       element: <Accordion />,
  //   //     },
  //   //     {
  //   //       path: "alerts",
  //   //       element: <Alerts />,
  //   //     },
  //   //     {
  //   //       path: "avatars",
  //   //       element: <Avatars />,
  //   //     },
  //   //     {
  //   //       path: "badges",
  //   //       element: <Badges />,
  //   //     },
  //   //     {
  //   //       path: "buttons",
  //   //       element: <Buttons />,
  //   //     },
  //   //     {
  //   //       path: "cards",
  //   //       element: <Cards />,
  //   //     },
  //   //     {
  //   //       path: "chips",
  //   //       element: <Chips />,
  //   //     },
  //   //     {
  //   //       path: "dialogs",
  //   //       element: <Dialogs />,
  //   //     },
  //   //     {
  //   //       path: "lists",
  //   //       element: <Lists />,
  //   //     },
  //   //     {
  //   //       path: "menus",
  //   //       element: <Menus />,
  //   //     },
  //   //     {
  //   //       path: "pagination",
  //   //       element: <Pagination />,
  //   //     },
  //   //     {
  //   //       path: "progress",
  //   //       element: <Progress />,
  //   //     },
  //   //     {
  //   //       path: "snackbars",
  //   //       element: <Snackbars />,
  //   //     },
  //   //     {
  //   //       path: "tooltips",
  //   //       element: <Tooltips />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "forms",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "pickers",
  //   //       element: <Pickers />,
  //   //     },
  //   //     {
  //   //       path: "selection-controls",
  //   //       element: <SelectionCtrls />,
  //   //     },
  //   //     {
  //   //       path: "selects",
  //   //       element: <Selects />,
  //   //     },
  //   //     {
  //   //       path: "text-fields",
  //   //       element: <TextFields />,
  //   //     },
  //   //     {
  //   //       path: "editors",
  //   //       element: <Editors />,
  //   //     },
  //   //     {
  //   //       path: "formik",
  //   //       element: <Formik />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "tables",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "simple-table",
  //   //       element: <SimpleTable />,
  //   //     },
  //   //     {
  //   //       path: "advanced-table",
  //   //       element: <AdvancedTable />,
  //   //     },
  //   //     {
  //   //       path: "data-grid",
  //   //       element: <DataGrid />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "icons",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "material-icons",
  //   //       element: <MaterialIcons />,
  //   //     },
  //   //     {
  //   //       path: "feather-icons",
  //   //       element: <FeatherIcons />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "charts",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Chartjs />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "maps",
  //   //   element: <DashboardLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "google-maps",
  //   //       element: <GoogleMaps />,
  //   //     },
  //   //     {
  //   //       path: "vector-maps",
  //   //       element: <VectorMaps />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "documentation",
  //   //   element: <DocLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "welcome",
  //   //       element: <Welcome />,
  //   //     },
  //   //     {
  //   //       path: "getting-started",
  //   //       element: <GettingStarted />,
  //   //     },
  //   //     {
  //   //       path: "routing",
  //   //       element: <Routing />,
  //   //     },
  //   //     {
  //   //       path: "auth/auth0",
  //   //       element: <Auth0 />,
  //   //     },
  //   //     {
  //   //       path: "auth/cognito",
  //   //       element: <Cognito />,
  //   //     },
  //   //     {
  //   //       path: "auth/firebase",
  //   //       element: <Firebase />,
  //   //     },
  //   //     {
  //   //       path: "auth/jwt",
  //   //       element: <JWT />,
  //   //     },
  //   //     {
  //   //       path: "guards",
  //   //       element: <Guards />,
  //   //     },
  //   //     {
  //   //       path: "environment-variables",
  //   //       element: <EnvironmentVariables />,
  //   //     },
  //   //     {
  //   //       path: "deployment",
  //   //       element: <Deployment />,
  //   //     },
  //   //     {
  //   //       path: "theming",
  //   //       element: <Theming />,
  //   //     },
  //   //     {
  //   //       path: "api-calls",
  //   //       element: <APICalls />,
  //   //     },
  //   //     {
  //   //       path: "redux",
  //   //       element: <Redux />,
  //   //     },
  //   //     {
  //   //       path: "internationalization",
  //   //       element: <Internationalization />,
  //   //     },
  //   //     {
  //   //       path: "eslint-and-prettier",
  //   //       element: <ESLintAndPrettier />,
  //   //     },
  //   //     {
  //   //       path: "migrating-to-next-js",
  //   //       element: <MigratingToNextJS />,
  //   //     },
  //   //     {
  //   //       path: "support",
  //   //       element: <Support />,
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   path: "changelog",
  //   //   element: <DocLayout />,
  //   //   children: [
  //   //     {
  //   //       path: "",
  //   //       element: <Changelog />,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     path: "private",
  //     element: (
  //       <AuthGuard>
  //         <DashboardLayout />
  //       </AuthGuard>
  //     ),
  //     children: [
  //       {
  //         path: "",
  //         element: <ProtectedPage />,
  //       },
  //     ],
  //   },
  //   {
  //     path: "*",
  //     element: <AuthLayout />,
  //     children: [
  //       {
  //         path: "*",
  //         element: <Page404 />,
  //       },
  //     ],
  //   },
  // ];
  return routes;
};
