import { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import UploadFile from "../helper/const UploadFile";
import FileDisplayCard from "../helper/FileDisplayCard";
import { bytesToMegabytes } from "../../sidebar-bot/utils/bytesToMegabytes";
import { uploadDataToAwsBucket } from "../../sidebar-bot/utils/uploadDataToAwsBucket";
import BodyField from "../helper/BodyField";
import ButtonsField from "../helper/ButtonsField";
import TextComponent from "../helper/TextComponent";

const PreviewTemplate = ({
  templateDetails,
  currentTemplateState,
  setCurrentTemplateState,
}) => {
  const fileInputRef = useRef(null);
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = async (e, index, fileType) => {
    const uploadedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const fileURL = res.data.source_url;
    const updatedComponents = [...currentTemplateState?.components];

    updatedComponents[index] = {
      ...updatedComponents[index],
      file_url: {
        type: fileType,
        url: fileURL,
      },
    };

    setCurrentTemplateState((prevData) => ({
      ...prevData,
      components: updatedComponents,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "20px",
        paddingBottom: "20px",
        width: "75%",
      }}
    >
      {currentTemplateState &&
        currentTemplateState?.components?.length > 0 &&
        currentTemplateState?.components?.map((component, index) => (
          <>
            {component?.type === "HEADER" &&
              (component?.format === "TEXT" ? (
                <TextComponent
                  key={`text-${index}`}
                  component={component}
                  index={index}
                  // currentTemplatePreview={currentTemplatePreview}
                  currentTemplateState={currentTemplateState}
                  setCurrentTemplateState={setCurrentTemplateState}
                />
              ) : !component?.file_url ? (
                <UploadFile
                  componentType={component.format}
                  handleIconClick={handleIconClick}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                  component={component}
                  index={index}
                  // componentType={component.format}
                  // handleIconClick={handleIconClick}
                  // fileInputRef={fileInputRef}
                  // handleFileSelect={handleFileSelect}
                  // index={index}
                />
              ) : (
                <FileDisplayCard
                  componentType={component.format}
                  handleIconClick={handleIconClick}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                  component={component}
                  index={index}
                />
              ))}

            {component?.type === "BODY" && (
              <BodyField
                key={`body-${index}`}
                component={component}
                index={index}
                // currentTemplatePreview={currentTemplatePreview}
                currentTemplateState={currentTemplateState}
                setCurrentTemplateState={setCurrentTemplateState}
              />
            )}

            {component?.type === "FOOTER" && (
              <span
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontFamily: "Inter",
                  fontSize: "14",
                  fontWeight: "600",
                  marginLeft: "8px",
                }}
              >
                {component.text}
              </span>
            )}

            {component?.type === "BUTTONS" &&
              component?.buttons.map((button, buttonIndex) => (
                <ButtonsField
                  component={component}
                  index={index}
                  buttonIndex={buttonIndex}
                  key={buttonIndex}
                  button={button}
                  // currentTemplatePreview={currentTemplatePreview}
                  currentTemplateState={currentTemplateState}
                  setCurrentTemplateState={setCurrentTemplateState}
                />
              ))}
          </>
        ))}
    </Box>
  );
};

export default PreviewTemplate;
