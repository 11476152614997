import { backendApiCall } from "../../../../../helper";
import * as actions from "../../../../../redux/actions/flowsActions";

export const setIntegrations = async (dispatch) => {
  const res = await backendApiCall({ endpoint: `settings/meta_integration` });

  const whatsappData = res?.data;
  let integrationSettings = {
    whatsappIntegrated: false,
    productCatalogIntegrated: false,
  };

  Object.keys(whatsappData).forEach((key) => {
    const value = whatsappData[key];
    const connectedChannels = value?.connected;

    if (connectedChannels.includes("whatsapp")) {
      integrationSettings.whatsappIntegrated = true;
    }

    if (connectedChannels.includes("commerce")) {
      integrationSettings.productCatalogIntegrated = true;
    }
  });
  // const integrationObj = res?.response?.data?.[1701310541829];
  // const whatsappIntegrated = integrationObj?.selected_whatsapp_phone_id;
  // console.log(whatsappIntegrated)
  // const productCatalogIntegrated = integrationObj?.select_commerce_catalogs;
  // let integrationSettings = {
  //   whatsappIntegrated: !!whatsappIntegrated,
  //   productCatalogIntegrated: !!productCatalogIntegrated,
  // };

  dispatch({
    type: actions.EDIT_ELEMENT_IN_REDUX,
    payload: { integration: integrationSettings },
  });
};
