import { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, IconButton, Popover } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import { chatbotHeaderStyles } from "../styles/chatbotHeaderStyles";
import ChangeNamePopover from "./ChangeNamePopover";
import ChangeIconPopover from "./ChangeIconPopover";

const styles = chatbotHeaderStyles;

const TopSectionPopover = ({
  anchorEl,
  setAnchorEl,
  handleReset,
  handlePopoverClose,
}) => {
  const botName = useSelector((state) => state.flow?.chatbotStyles)?.botName;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [changeNameAnchor, setChangeNameAnchor] = useState(null);
  const [iconChangeAnchor, setIconChangeAnchor] = useState(null);

  const changeBotName = async (e) => {
    e.stopPropagation();
    setChangeNameAnchor(e.currentTarget);
    // setAnchorEl(null);
  };

  const changeIcon = async (e) => {
    e.stopPropagation();
    setIconChangeAnchor(e.currentTarget);
    // setAnchorEl(null);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={styles.popoverDiv}>
          <div
            onClick={handleReset}
            className="popoverOptionDiv"
            style={styles.optionsDiv}
          >
            <IconButton sx={styles.iconButtons}>
              <SyncIcon />
            </IconButton>
            <Typography sx={styles.optionsTextField} variant="span">
              Reset chat
            </Typography>
          </div>
          <div
            style={styles.optionsDiv}
            className="popoverOptionDiv"
            onClick={changeBotName}
          >
            <IconButton sx={styles.iconButtons}>
              <CreateIcon />
            </IconButton>
            <Typography sx={styles.optionsTextField} variant="span">
              Change name
            </Typography>
          </div>
          <div
            style={styles.optionsDiv}
            className="popoverOptionDiv"
            onClick={changeIcon}
          >
            <IconButton sx={styles.iconButtons}>
              <CreateIcon />
            </IconButton>
            <Typography sx={styles.optionsTextField} variant="span">
              Change Icon
            </Typography>
          </div>
          <div
            style={styles.optionsDiv}
            className="popoverOptionDiv"
            // onClick={handleReset}
          >
            <IconButton onClick={handleReset} sx={styles.iconButtons}>
              <PersonIcon />
            </IconButton>
            <Typography sx={styles.optionsTextField} variant="span">
              Live agent
            </Typography>
          </div>
        </div>
      </Popover>
      <ChangeNamePopover
        changeNameAnchor={changeNameAnchor}
        setChangeNameAnchor={setChangeNameAnchor}
        setAnchorEl={setAnchorEl}
      />
      <ChangeIconPopover
        iconChangeAnchor={iconChangeAnchor}
        setIconChangeAnchor={setIconChangeAnchor}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default TopSectionPopover;
