import { Laptop, PhoneIphone } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  circularProgressClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popover,
  Stack,
  styled,
  TableRow,
  Typography,
} from "@mui/material";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { UAParser } from "ua-parser-js";
import { getIp, planRoutes } from "./App";
import { currencySymbols } from "./pages/pricing/Gold";
import * as qs from "qs";
import {
  getAllLabourMasterPricing,
  getDiamondRate,
  getGemstoneRate,
} from "./pages/products/AddProduct";
import { daysArray } from "./pages/settings/roles/RolesList";
import {
  Android,
  Apple,
  InstagramSvg,
  Messenger,
  WhatsappSvg,
  Windows,
} from "./Svgs";
import { isValidDate } from "./utils/dateFunctions";
import routes, { getUpdatedRoutes } from "./routes";
import { editSettings } from "./redux/actions/settingActions";

import { useTheme } from "@mui/system";
import Switch from "rc-switch";
import { sendMessage } from "./pages/chat_new/apiFunctions";

const jweroColorPalette = ["#0012b9", "#012687", "#616ada", "#f6b11c"];
const getJweroColorPalette = () => ["#012687", "#0012b9", "#616ada", "#f6b11c"];
export const encryptStorage = new EncryptStorage("secret-key");

const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const getTimeInSeconds = () => Math.floor(new Date().getTime() / 1000);
const getTimeInMilliSeconds = () => Math.floor(new Date().getTime());

function isTimeNHoursBefore(timeInMillis, n) {
  // Convert timeInMillis to Date object
  const targetTime = new Date(timeInMillis);

  // Calculate the current time in milliseconds
  const currentTime = Date.now();

  // Calculate the time difference in hours
  const timeDifferenceHours = (currentTime - targetTime) / (1000 * 60 * 60);

  // Check if the time difference is less than n
  return timeDifferenceHours >= n;
}
function getDateAfterHours(hours) {
  const millisecondsInHour = 3600000;
  const futureTime = Date.now() + hours * millisecondsInHour;
  return new Date(futureTime);
}
let msg91Params = {
  pluginsource: 73,
};
const hasAccessInPlan = ({ access }) => {
  try {
    let user = encryptStorage.getItem("user");
    if (access === "campaign") {
      let hasCampaignAccess =
        user && user.store_plan && typeof user.store_plan.campaign === "boolean"
          ? user.store_plan.campaign
          : true;
      return hasCampaignAccess;
    }
  } catch (error) {}
};
const getWebsite = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.store_details && user.store_details.store_url)
      return (
        user.store_details.store_url ||
        (isDev() && false ? "https://tiarabytj.com" : "")
      );
    else return false || (isDev() && false ? "https://tiarabytj.com" : "");
  } catch (error) {}
};
const getUserType = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.user_type) return user.user_type;
    else return false;
  } catch (error) {}
};
const getUserProduct = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user?.store_plan?.user_products) return user?.store_plan?.user_products;
    else return false;
  } catch (error) {}
};
const getStoreEmail = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.store_details && user.store_details.store_email)
      return user.store_details.store_email;
    else return false;
  } catch (error) {}
};
const getToken = () => {
  try {
    let user = encryptStorage.getItem("user");

    if (user && user?.token) return user?.token;
    return null;
  } catch (error) {}
};

const getAuthToken = () => {
  try {
    let user = encryptStorage.getItem("user");

    if (user && user.chatsCreds && user?.chatsCreds?.newToken)
      return user?.chatsCreds?.newToken;
    else return null;
  } catch (error) {}
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ik1QMTV5VGg3cWxCSyIsInBpZCI6MjA5LCJpYXQiOjE2OTI1MzgwMDYsImV4cCI6MTcwMTE3ODAwNn0.iCr7g3MPcwJktdtHlmW2Vo11NEnAxcF6qEirroqRMRI";
};
const getTenantId = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.chatsCreds && user?.chatsCreds?.tenant_id)
      return user?.chatsCreds?.tenant_id;
    else return null;
  } catch (error) {}
};

const getStoreId = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.store_details && user.store_id) return user.store_id;
    else return false;
  } catch (error) {}
};

const isOurWebsite = (props) => {
  let { store_ids = [] } = props || {};
  let ourWebsite = [
    "https://tiarabytj.com",
    "https://akshaysjewellery.jwero.com",
  ];
  let website = getWebsite();
  let store_id = getStoreId();
  return (
    ourWebsite?.join("")?.includes(website) ||
    store_ids?.includes(`${store_id}`)
  );
};

const isEarlyAccess = (props) => {
  // let { store_ids = [] } = props || {};
  let accessTo = [
    "https://tiarabytj.com",

    "https://swarna.jwero.com",
    "https://swarna.staging.tempurl.host",

    "https://jpearls.jwero.com",
    "https://jpearls.staging.tempurl.host",
  ];

  let website = getWebsite();
  // let store_id = getStoreId();
  return accessTo?.join("")?.includes(website);
};

const allStockStatus = [
  { label: "In Stock", value: "instock", type: "completed" },
  { label: "Out of Stock", value: "outofstock", type: "pending" },
  { label: "Make to Order", value: "onbackorder", type: "processing" },
];

/* The above code is declaring a constant variable named `localLink` and assigning it a string value of
"https://jewelingo.staging.tempurl.host". This code is written in JavaScript. */
// const localLink = "https://www.jwero.com";
const localLink = "https://jewelingo.staging.tempurl.host";
// const localLink = "https://swarna.jwero.com";
// const localLink = "https://jpearls.staging.tempurl.host";
// const localLink = "https://swarna.staging.tempurl.host";
// const localLink = "https://jpearls.jwero.com";
// const localLink = "https://jewelingo.staging.tempurl.host";

// LIVE LINK SHOULDN'T CHANGE
// const liveLink = "https://retailer.jewelingo.staging.wpmudev.host";
// const liveLink = "https://www.jwero.com";
const liveLink = "https://jewelingo.staging.tempurl.host";
// const liveLink = "https://swarna.staging.tempurl.host";
const liveSupplierLink = "https://app.tanika.tech";

// const backendDoamin = "https://websockets.staging.tiarabytj.com";

// let localIPAddress = "192.168.0.29";
/* The above code is declaring a variable named `localIPAddress` and assigning it the value
"192.168.0.29". */
let localIPAddress = "localhost";
// let localIPAddress = "192.168.0.18";
// let localIPAddress = "192.168.184.251";
// let localIPAddress = "192.168.176.251";
// let localIPAddress = "192.168.1.12";
// let localIPAddress = "192.168.16.251";
// let localIPAddress = "localhost";
// let localIPAddress = "192.168.16.251";
// let localIPAddress = "192.168.16.251";
// let liveBackendDomain = "https://websockets.staging.tiarabytj.com";
let liveBackendDomain = "https://api.jwero.com";
// let liveBackendDomain =
//   "https://tanikatech:tanikatech@jewelingo.staging.tempurl.host";
// let liveBackendDomain = "https://jwero-backend-api-f7ze6.ondigitalocean.app";
// "192.168.195.251";
const localHostLink = `http://${localIPAddress}:3000`;

const backendDoamin =
  isDev() && false ? `http://${localIPAddress}:8080` : liveBackendDomain;

// const nodeWebsite = "https://websockets.tanika.tech";
// let localSocketLink = "192.168.21.251:8080";
let localSocketLink = `${localIPAddress}:8080`;
let liveSocketLinkWithoutProtocol = isDev()
  ? // ? "api.jwero.com" // "websockets.staging.tiarabytj.com"
    localIPAddress // "websockets.staging.tiarabytj.com"
  : "api.jwero.com";
let liveSocketLink = isDev()
  ? `ws://${localIPAddress}:8080/`
  : "wss://api.jwero.com/";
let websocketLink = isDev()
  ? "websockets.tanika.tech" // "websockets.staging.tiarabytj.com"
  : "websockets.tanika.tech";

const websocket_doamin = isDev()
  ? `http://${localIPAddress}:8082`
  : "https://websockets.tanika.tech";

const getWebSocketUrl = () => {
  if (isDev()) return `http://${localIPAddress}:8082`;
  // return "https://websockets.tanika.tech";
  return "https://websockets.staging.jwero.com";
};

// const catalogQuotationLink = `https://jwero-catalog-quotation-sw4aj.ondigitalocean.app`;
const catalogQuotationLink = `https://catalog.jwero.com`;
const nodeWebsite = isDev()
  ? websocketLink
  : // `http://${localSocketLink}`
    // "http://192.168.1.12:8080"
    "https://websockets.tanika.tech";

const nodeWebsiteWithoutProtocol = isDev()
  ? localSocketLink
  : // "http://192.168.1.12:8080"
    websocketLink;

const domain = "https://app.jwero.ai";

let mainSocketEvents = [
  "design_bank",
  "catalog",
  "quotation",
  "website",
  "sales_app",
];
let productViewsEvent = [
  "catalog_product",
  "quotation_product",
  "website_product",
  "sales_app_product",
];
/**
 * The function converts a hexadecimal color code to its corresponding RGBA color code with a specified
 * alpha value.
 * @param hex - The `hex` parameter is a string representing a hexadecimal color value. It should be in
 * the format "#RRGGBB" where RR, GG, and BB are two-digit hexadecimal values representing the red,
 * green, and blue components of the color respectively.
 * @param alpha - The alpha parameter in the hexToRgba function represents the opacity or transparency
 * of the color. It is a value between 0 and 1, where 0 is completely transparent and 1 is completely
 * opaque.
 * @returns a string in the format "rgba(r, g, b, alpha)" where r, g, and b are the red, green, and
 * blue values respectively, and alpha is the alpha value specified as an argument to the function.
 */
function hexToRgba(hex, alpha) {
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

/**
 * The function `callSocket` is an asynchronous function that makes a WebSocket request to a specified
 * endpoint with optional parameters, using the specified method and authorization token.
 */
const callSocket = async ({ endpoint, method = "GET", ...other }) => {
  try {
    let res = await axios({
      url: `${getWebSocketUrl()}/${endpoint}`,
      headers: {
        Authorization: `Basic ${getToken()}`,
      },
      method: method,
      ...other,
    });
    return res;
  } catch (error) {
    console.log(error);
  } finally {
  }
};

const globalCardStyle = {
  borderRadius: "10px",
};
const getSkuFromFbId = (str) => str?.split("_")?.[0];
const replaceWithBr = (string) =>
  typeof string === "string" ? string.replace(/\n/g, "<br />") : string;

const getNumberFromString = (str) => {
  if (typeof str !== "string" || !str) return str;
  var num = str.replace(/[^0-9]/g, "");
  return parseInt(num, 10);
};
const getFullName = (obj) => {
  return `${obj?.first_name || ""} ${obj?.last_name || ""} ${obj?.name || ""}`;
};

const getInitials = (fullName) => {
  if (!fullName) return;
  var initials =
    fullName
      .match(/(\b\S)?/g)
      .join("")
      .toUpperCase() || [];
  return initials;
};

const randomVisitorName = (user_id) => {
  return `Visitor ${user_id?.replace("random_", "")}`;
};

const getOrders = async ({ params }) => {
  try {
    let website = getWebsite();
    let token = getToken();
    let res = await axios({
      url: `${website}/wp-json/wc/v3/orders`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params,
    });
    return res?.data;
  } catch (error) {
    return null;
  }
};

function wrapLinksWithTag(text) {
  // Regular expression pattern to match URLs
  var pattern = /(https?:\/\/[^\s]+)/g;

  // Replace URLs with wrapped HTML anchor tags
  var replacedText = text.replace(
    pattern,
    '<a href="$&" target="_blank">$&</a>'
  );

  return replacedText;
}

const formatWhatsappMessage = (message) => {
  if (/(?:\*|_|~|--|```)/.test(message)) {
    return message
      .replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g, "<b>$1</b>")
      .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g, "<i>$1</i>")
      .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g, "<s>$1</s>")
      .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, "<u>$1</u>")
      .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g, "<code>$1</code>")
      .replace(/\n/g, "<br>");
  } else {
    return typeof message === "string"
      ? message?.replace(/\n/g, "<br>")
      : message;
  }

  // let formattedMessage = message;
  // if (/\*\*\*(.+?)\*\*\*/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /\*\*\*(.+?)\*\*\*/g,
  //     "<strong><em>$1</em></strong>"
  //   );
  // }
  // if (/\*\*(.+?)\*\*/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /\*\*(.+?)\*\*/g,
  //     "<strong>$1</strong>"
  //   );
  // }
  // if (/\*(.+?)\*/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(/\*(.+?)\*/g, "<em>$1</em>");
  // }
  // if (/__(.+?)__/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /__(.+?)__/g,
  //     "<strong>$1</strong>"
  //   );
  // }
  // if (/_([^_].+?[^_])_/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /_([^_].+?[^_])_/g,
  //     "<em>$1</em>"
  //   );
  // }
  // if (/~(.+?)~/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /~(.+?)~/g,
  //     "<strike>$1</strike>"
  //   );
  // }
  // if (/```(.+?)```/g.test(formattedMessage)) {
  //   formattedMessage = formattedMessage.replace(
  //     /```(.+?)```/g,
  //     "<code>$1</code>"
  //   );
  // }
  // return formattedMessage;
  // if (typeof string === "string") {
  //   let output = "";
  //   if (string.includes(`\n`)) output = string.replace(/\n/g, "<br />");
  //   if (string.includes("*")) {
  //     let length = string.length;
  //     let i;
  //     let tagToAdd = "<strong>";
  //     for (i = 0; i < length; i++) {
  //       let char = string[i];
  //       if (char == "*") {
  //         output = `${output}${tagToAdd}`;
  //         if (tagToAdd === "<strong>") tagToAdd = "</strong>";
  //         else tagToAdd = "<strong>";
  //       }
  //       output = `${output}${tagToAdd}`;
  //     }
  //   } else output = string;
  //   if (!string.includes(`\n`) && !string.includes("*")) output = string;
  //   return output;
  // }
  // return string;
  // if (typeof string === "string") {
  //   let output = string;
  //   output = output.replace(/\n/g, "<br />");
  //   output = output.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
  //   output = output.replace(/_(.*?)_/g, "<em>$1</em>");
  //   output = output.replace(/~(.*?)~/g, "<u>$1</u>");
  //   return output;
  // }
  // return string;
};

// typeof string === "string"
//   ? string.replace(/\n/g, "<br />").replace(/\*/g, "<br />")
//   : string;

function convertToIndianNumberFormat(number) {
  // Perform input validation
  if (isNaN(number)) {
    return 0;
  }

  // Convert number to Indian numbering system format
  const formattedNumber = number.toLocaleString("en-IN");
  return formattedNumber;
}

const removeWhiteSpaces = (string) =>
  typeof string === "string" ? string.replace(/\s/g, "").trim() : string;

const sectionLabel = {
  fontSize: "20px",
  fontWeight: "800",
};

const primaryLabel = {
  fontSize: "14px",
  fontWeight: "500",
};
const primaryLabelSmall = {
  fontSize: "12px",
  fontWeight: "500",
};
const primaryLabelLarge = {
  fontSize: "17px",
  fontWeight: "500",
};

const secondaryLabel = {
  fontSize: "13px",
  color: "text.secondary",
};
const secondaryLabelSmall = {
  fontSize: "11px",
  color: "text.secondary",
};

const emptyCurrencies = {
  INR: [],
  USD: [],
  EUR: [],
  GBP: [],
  AUD: [],
  AED: [],
  SGD: [],
  SAR: [],
  ZAR: [],
  CAD: [],
  JPY: [],
  CNY: [],
  VND: [],
  THB: [],
};
const setUpRoutes = (user_products) => {
  let routeObject = { ...planRoutes };
  let allRoutesArray = getUpdatedRoutes();
  let arr = [];
  let routerArray = [];
  if (isArrayWithValues(user_products)) {
    let filterRoutes = user_products
      ?.map((key) => {
        return routeObject[key];
      })
      ?.flat()
      ?.concat(routeObject?.necessary);
    routerArray = getUpdatedRoutes()?.filter((obj) =>
      filterRoutes?.includes(obj?.path)
    );
  } else {
    routerArray = getUpdatedRoutes()?.filter((i) =>
      isOurWebsite() ? true : i.path !== "design-bank" && i.path !== "flows"
    );
  }

  // return routerArray;
  return isDev() ? getUpdatedRoutes() : routerArray;
};
const devLog = (...value) => {
  if (isDev()) console.log(...value);
};
const apikey = "eca2c452-753f-4d18-ad23-c76ceec5f3a6";

const fetchMasterSettingsByOptions = async (options) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${mainWebsite}/wp-json/store/v1/settings/?options=${options?.join()}`,
        // headers: { Authorization: `Basic ${token}` },
        headers: { apikey },
        method: "GET",
      });
      if (data) return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};

const fetchSettingsByOptions = async (options) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/?options=${options?.join()}`,
        headers: { Authorization: `Basic ${token}` },
        method: "GET",
      });
      if (data) return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};
const onCallMsg91 = async ({ authkey, flow, caller_id, client_number }) => {
  let res = await apiCallFromSocket(
    {
      url: "https://control.msg91.com/api/v5/voice/call/",
      headers: {
        authkey,
      },
      params: {
        ...msg91Params,
      },
      method: "POST",
      data: {
        flow,
        caller_id,
        client_number,
      },
    },
    "https://websockets.tanika.tech"
  );
};

const createGoogleCalendarEvent = async (event) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/google_calendar`,
        headers: { Authorization: `Basic ${token}` },
        method: "POST",
        data: { payload: event },
      });
      if (data) return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};

const getGoogleEventObjByEvent = (obj) => {
  let event = {};
  if (!isObjWithValues(obj)) return;
  let { date, title, description, remind_label, remind_value, show_remind } =
    obj;
  if (show_remind)
    event.reminders = {
      useDefault: false,
      overrides: [
        {
          method: "email",
          minutes:
            validateNumber(remind_value) * (remind_label == "hours" ? 60 : 1),
        },
        {
          method: "popup",
          minutes:
            validateNumber(remind_value) * (remind_label == "hours" ? 60 : 1),
        },
      ],
    };

  event.summary = formatServerValue(title || "");
  event.description = description;
  event.start = {
    dateTime: new Date(date),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  event.end = {
    dateTime: new Date(date),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return event;
};

const getNameFromPlatform = (obj) => {
  if (!isObjWithValues(obj)) return "";
  let { first_name, last_name, id } = obj;
  // if (getIsBackendMigrated("chats"))
  //   return `${first_name || ""} ${last_name || ""}`;
  let { platform } = obj;
  obj = { ...obj, ...(obj?.meta_data?.[`${platform}_info`] || {}) };

  let name = "";
  if (platform === "whatsapp")
    name =
      `${first_name || ""} ${last_name || ""}`?.trim() ||
      `${obj.name || obj?.number || ""}`?.trim() ||
      "";
  else if (platform === "facebook")
    name = `${obj.first_name || ""} ${obj.last_name || ""}`?.trim() || "";
  else if (platform === "webchat")
    name = `${obj.first_name || ""} ${obj.last_name || ""}`?.trim() || "";
  else if (platform === "instagram")
    name = `${obj.username || ""}`?.trim() || "";
  return name.trim() || `Contact ${id || ""}` || "";
};

const getLastMessageFromObj = (obj, platform) => {
  let messageText = "";
  if (platform == "whatsapp") {
    let { message } = obj || {};
    if (message?.type == "interactive")
      messageText = `${message?.interactive?.header?.text || ""} ${
        message?.interactive?.body?.text || ""
      }`;
    if (message?.type == "text") messageText = `${message?.text?.body || ""}`;
  } else if (platform == "facebook" || platform === "instagram") {
    let { message } = obj;
    if (message?.text) messageText = message?.text;
  }
  return { messageText };
};

// const sortObjectByValues = (obj, desc) =>
//   isObjWithValues(obj) &&
//   Object.entries(obj)
//     .sort(([, a], [, b]) => (desc ? b - a : a - b))
//     .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
const sortObjectByValues = (obj, asc) =>
  Object.entries(obj)
    .sort((a, b) => (asc ? b[1] - a[1] : a[1] - b[1]))
    .reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});

const sortObjectByValuesLength = (obj) => {
  let sortedKeys = Object.keys(obj).sort(
    (a, b) => obj[b].length - obj[a].length
  );

  let sortedObj = {};
  sortedKeys.forEach(function (key) {
    sortedObj[key] = obj[key];
  });
  return sortedObj;
};

const sumupElementsAfterIndex = (arr, index) => {
  // create a new array that contains all elements after the 3rd index
  let slicedArr = arr.slice(index);

  // use the reduce method to sum up the elements in the new array
  let sum = slicedArr.reduce((a, b) => a + b, 0);

  // create the final array and display it
  let finalArr = arr.slice(0, index).concat(sum);
  return finalArr;
};

const updateProductsInGoogle = async (merchantId) => {
  try {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let { data } = await axios({
        url: `https://websockets.tanika.tech/update_google_products`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: { website, merchantId },
        method: "POST",
      });
      console.log(data);
    }
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

function numDifferentiation(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " Lac";
  }
  return val;
}

const createCustomCustomer = async (data, multiple) => {
  let website = getWebsite();
  let token = getToken();
  let headers = {
    Authorization: `Basic ${token}`,
  };
  let reqOptions = {
    url: `${website}/wp-json/store/v1/customer/create`,
    method: "POST",
    headers,
    data,
    params: { multiple },
  };
  try {
    const { data } = await axios.request(reqOptions);
    return data;
  } catch (error) {
    error = getAxiosError(error);
    return error;
  }
};

const setupJweroLegacyProduct = (product) => {
  if (!isObjWithValues(product)) return product;

  let {
    metal_type,
    metal_types,
    metal_color,
    gold_kt,
    gold_gross,
    gold_net,
    gold_rate,
    silver_gross,
    silver_net,
    platinium_gross,
    platinium_net,
    variants,
    silver_purity,
    platinium_purity,
    hallmarked,
    huid,
    subcategory,
    categories,
    collections,
    labourType,
    labour_from,
    labour_per_gram,
    wastage_percent,
    making_from,
    minimum_making,
    diamond,
    colorstone_details,
    diamond_from,
    colorstone_from,
    extra_charges,
    onlyColorstone,
    onlyDiamond,
    custom_discounts,
    labour_pricing_title,
    minimum_labour,
    per_gram,
    labour: _labour_per_gram,
    custom_wastage_from,
  } = product;

  if (labourType === "individual") labourType = "customize";
  if (!labourType) labourType = "master";
  if (diamond_from === "individual") diamond_from = "customize";
  if (colorstone_from === "individual") colorstone_from = "customize";
  if (minimum_labour) minimum_making = minimum_labour;
  if (!diamond_from) diamond_from = "master";
  if (!colorstone_from) colorstone_from = "master";

  if (isArrayWithValues(metal_types))
    metal_types = metal_types?.map((i) => i.toLowerCase());

  if (metal_type && !isArrayWithValues(metal_types)) {
    if (metal_type !== "multi") metal_types = [metal_type?.toLowerCase()];
    else {
      let array = [];
      if (gold_gross) array.push("gold");
      if (silver_gross) array.push("silver");
      if (platinium_gross) array.push("platinium");
      metal_types = array;
    }
  }
  if (!metal_types || !isArrayWithValues(metal_types)) metal_types = ["gold"];
  return {
    ...product,
    labourType,
    diamond_from,
    colorstone_from,
    minimum_making,
    diamond_from,
    colorstone_from,
    metal_types,
  };
};
function checkTruthy(value) {
  if (!value) {
    // check for falsy values
    return false;
  } else if (Array.isArray(value) && value.length === 0) {
    // check for empty array
    return false;
  } else if (typeof value === "object" && Object.keys(value).length === 0) {
    // check for empty object
    return false;
  } else {
    return true;
  }
}

function getProductPrice(
  productObj,
  masterPricing,
  productSettings,
  allDiamondGroups,
  tax,
  promotion_stepper
) {
  // if (isObjWithValues(productObj)) return {};
  if (
    !isObjWithValues(productObj) ||
    !isObjWithValues(productSettings) ||
    !isObjWithValues(masterPricing)
  )
    return {};

  let { labour_pricing, diamond_pricing, gemstone_pricing, margin_pricing } =
    masterPricing;

  let product = { ...productObj };
  let { meta_data } = product;
  if (!meta_data) meta_data = [];
  for (const obj of meta_data)
    if (!product.hasOwnProperty(obj.key) || !checkTruthy(product?.[obj.key]))
      product[obj.key] = obj.value;

  product = setupJweroLegacyProduct(product);

  let { default_currency, net_weight } = productSettings;

  if (net_weight === "auto") {
    if (product.gold_gross)
      product.gold_net = getNetWeight(product.gold_gross, product);
    if (product.silver_gross)
      product.silver_net = getNetWeight(product.silver_gross, product);
    if (product.platinium_gross)
      product.platinium_net = getNetWeight(product.platinium_gross, product);
  }
  let {
    metal_type,
    metal_types,
    metal_color,
    gold_kt,
    gold_gross,
    gold_net,
    gold_rate,
    silver_gross,
    silver_net,
    platinium_gross,
    platinium_net,
    variants,
    silver_purity,
    platinium_purity,
    hallmarked,
    huid,
    subcategory,
    categories,
    collections,
    labourType,
    labour_from,
    labour_per_gram,
    wastage_percent,
    making_from,
    minimum_making,
    diamond,
    diamond_customize,
    colorstone_details,
    colorstone_details_customize,
    diamond_from,
    colorstone_from,
    extra_charges,
    onlyColorstone,
    onlyDiamond,
    custom_discounts,
    labour_pricing_title,
    minimum_labour,
    per_gram,
    labour: _labour_per_gram,
    custom_wastage_from,
    manual_price,
  } = product;

  if (!making_from) making_from = "labour";
  if (!labour_per_gram && per_gram) labour_per_gram = per_gram;
  if (!labour_per_gram && _labour_per_gram) labour_per_gram = _labour_per_gram;

  let metaData = {};
  if (labourType === "individual") labourType = "customize";
  if (!labourType) labourType = "master";
  if (diamond_from === "individual") diamond_from = "customize";
  if (colorstone_from === "individual") colorstone_from = "customize";
  if (minimum_labour) minimum_making = minimum_labour;
  if (!diamond_from) diamond_from = "master";
  if (!colorstone_from) colorstone_from = "master";

  // if (isObjWithValues(diamond)) diamond = Object.values(diamond);
  // if (isObjWithValues(colorstone_details))
  //   colorstone_details = Object.values(colorstone_details);

  let diamondPricings = diamond_pricing?.[default_currency];
  let gemstonePricing = gemstone_pricing?.[default_currency];
  let marginPricing = margin_pricing?.[default_currency];

  const calculateTotalLabourPrice = () => {
    let totalWeight = 0;
    let { variants } = product;
    let totalMetalRate = 0;
    let { labour_from } = product;
    if (!labour_from) labour_from = "gross";
    let labourMaster = getAllLabourMasterPricing;
    let totalLabourPrice = 0;
    let labourBreakup = {};

    // console.log(labour_from, "<<<<< labour_from");

    if (labourType === "master") {
      if (
        isArrayWithValues(labour_pricing?.[default_currency]) &&
        labour_pricing?.[default_currency].find(
          (i) => i.id == labour_pricing_title
        )
      ) {
        let obj = labour_pricing?.[default_currency].find(
          (i) => i.id == labour_pricing_title
        );
        let { labour_from: master_labour_from } = obj;
        if (master_labour_from) labour_from = master_labour_from;
      }
    }
    if (metal_type && !isArrayWithValues(metal_types)) {
      if (metal_type !== "multi") metal_types = [metal_type?.toLowerCase()];
      else {
        let array = [];
        if (gold_gross) array.push("gold");
        if (silver_gross) array.push("silver");
        if (platinium_gross) array.push("platinium");
        metal_types = array;
      }
    }

    let totalWeights = { gross: 0, net: 0 };
    let froms = ["gross", "net"];
    let metals = ["gold", "silver", "platinium"];
    let purityKey = {
      gold: "gold_kt",
      silver: "silver_purity",
      platinium: "platinium_purity",
    };
    let pricingKey = {
      gold: "gold_pricing",
      silver: "silver_pricing",
      platinium: "platinum_pricing",
    };

    for (let from of froms)
      for (let metal of metals) {
        if (metal_types?.includes(metal)) {
          if (isArrayWithValues(variants) && false)
            variants.map(
              (i) => (totalWeight += validateNumber(i[`${metal}_${from}`]))
            );
          else {
            totalWeights[from] += validateNumber(product?.[`${metal}_${from}`]);
            // totalWeight += validateNumber(product[`${metal}_${from}`]);
          }
          let pricing = masterPricing?.[pricingKey[metal]];
          let { type } = pricing || {};
          if (
            pricing?.[default_currency] &&
            pricing?.[default_currency]?.[type] &&
            pricing?.[default_currency]?.[type]?.[product?.[purityKey[metal]]]
          ) {
            totalMetalRate += validateNumber(
              pricing[default_currency][type][product?.[purityKey[metal]]].rate
            );
          }
        }
      }

    //dividing by 2 because it was calculated twice for froms
    totalMetalRate = Math.round(totalMetalRate / 2);
    totalWeight = totalWeights[labour_from];

    // if (metal_types?.includes("gold")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`gold_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`gold_${labour_from}`]);
    //   let { type } = gold_pricing;
    //   if (
    //     gold_pricing[default_currency] &&
    //     gold_pricing[default_currency][type] &&
    //     gold_pricing[default_currency][type][gold_kt]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       gold_pricing[default_currency][type][gold_kt].rate
    //     );
    //   }
    // }
    // if (metal_types?.includes("silver")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`silver_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`silver_${labour_from}`]);
    //   let { type } = silver_pricing;
    //   if (
    //     silver_pricing[default_currency] &&
    //     silver_pricing[default_currency][type] &&
    //     silver_pricing[default_currency][type][silver_purity]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       silver_pricing[default_currency][type][silver_purity].rate
    //     );
    //   }
    // }
    // if (metal_types?.includes("platinium")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`platinium_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`platinium_${labour_from}`]);
    //   let { type } = platinum_pricing;
    //   if (
    //     platinum_pricing[default_currency] &&
    //     platinum_pricing[default_currency][type] &&
    //     platinum_pricing[default_currency][type][platinium_purity]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       platinum_pricing[default_currency][type][platinium_purity].rate
    //     );
    //   }
    // }

    let currentObj = {
      labour_from: 0,
      per_gram: 0,
      wastage: 0,
      minimum_making: 0,
    };
    if (labourType === "master") {
      // let allLabourPricing = getAllLabourMasterPricing(
      //   labour_pricing[default_currency],
      //   collections,
      //   categories,
      //   subcategory
      // );
      let allLabourPricing = labour_pricing[default_currency];
      let temp_collections = collections
        ?.map((i) => i.label || i.name)
        .filter(Boolean);
      let temp_categories = categories
        ?.map((i) => i.label || i.name)
        .filter(Boolean);
      let temp_subcategories = isArrayWithValues(subcategory)
        ? subcategory?.map((i) => i.label || i.name)?.filter(Boolean)
        : [];
      let labourMasterPricings = getAllLabourMasterPricing(
        labour_pricing[default_currency],
        temp_collections,
        temp_categories,
        temp_subcategories
      );
      // console.log(product.labour_pricing_title, labour_pricing_title, allLabourPricing, "labour");
      let obj =
        isArrayWithValues(labourMasterPricings) && labourMasterPricings[0];
      if (
        obj
        // isArrayWithValues(allLabourPricing) &&
        // allLabourPricing.find((i) => i.id == product.labour_pricing_title)
      ) {
        // let obj = allLabourPricing.find(
        //   (i) => i.id == product.labour_pricing_title
        // );
        // setTotalLabour(Math.round(labour));
        let { labour_from, per_gram, wastage, minimum_making } = obj;
        // currentObj = { labour_from, per_gram, wastage, minimum_making };

        // let { labour_from, per_gram, wastage, minimum_making } = currentObj;
        // if (!labour_from) {
        //   return 0;
        // }
        let totalWeight = totalWeights[labour_from] || totalWeights.gross;
        wastage = wastage || 0;
        per_gram = per_gram || 0;

        let total = totalWeight * validateNumber(per_gram);
        // total += total * (wastage / 100);
        let labour = totalWeight * validateNumber(per_gram),
          wastageTemp =
            totalWeight * validateNumber(totalMetalRate) * (wastage / 100);
        total += wastageTemp;
        labourBreakup = {
          labour: Math.round(labour),
          wastage: Math.round(wastageTemp),
        };
        // setLabourBreakdown({
        //   labour: Math.round(labour),
        //   wastage: Math.round(wastageTemp),
        // });
        // total += total * (wastage / 100);

        //         let totalRate = totalWeight * validateNumber(totalMetalRate);
        // total +=
        // let totalWastage = totalWeight * validateNumber(per_gram);
        // totalWastage += totalWastage * (wastage / 100);
        // onEditProduct({
        //   price_breakup: {
        //     ...price_breakup,
        //     master: { ...price_breakup.master, labour: Math.round(total) },
        //   },
        // });
        if (
          minimum_making &&
          validateNumber(minimum_making) > Math.round(total)
        )
          totalLabourPrice = Math.round(
            validateNumber(minimum_making) + validateNumber(wastageTemp)
          );
        else totalLabourPrice = Math.round(total);
      }
    } else if (labourType === "customize") {
      // currentObj = {
      //   labour_from: product.labour_from,
      //   per_gram: validateNumber(product.labour_per_gram),
      //   wastage: validateNumber(product.wastage_percent),
      //   minimum_making: validateNumber(product.minimum_making),
      // };

      // let { } =
      //   product;
      // if (
      //   !labour_from ||
      //   !labour_per_gram ||
      //   !wastage_percent
      // )
      //   return 0;

      let totalMetalWeightPercentage =
        (totalWeights[custom_wastage_from] || totalWeight) *
        (validateNumber(wastage_percent) / 100);
      // totalWeight * (validateNumber(wastage_percent) / 100);

      let total_labour = Math.round(
        totalMetalRate * totalMetalWeightPercentage
      );

      // let total_labour = Math.round(
      //   totalMetalRate * (validateNumber(wastage_percent) / 100)
      // );
      let total_per_gram = 0;
      if (making_from === "labour") {
        total_per_gram = Math.round(labour_per_gram * totalWeight) || 0;
      } else if (
        making_from == "labour-wastage" ||
        making_from == "labour_wastage"
      ) {
        let _total_weight = totalWeights[custom_wastage_from] || totalWeight;
        let wastage = _total_weight + _total_weight * (wastage_percent / 100);
        total_per_gram = Math.round(labour_per_gram * wastage) || 0;
      }
      // onEditProduct({ total_per_gram, total_labour });
      totalLabourPrice =
        Math.round(total_per_gram) > validateNumber(minimum_making)
          ? Math.round(total_labour + total_per_gram)
          : validateNumber(
              validateNumber(Math.round(minimum_making)) +
                validateNumber(Math.round(total_labour))
            );
    } else totalLabourPrice = 0;

    return { total: totalLabourPrice, labourBreakup };
  };

  const calculateTotalMetalPrice = () => {
    let types = productSettings.types || [];
    types =
      types.filter((i) => ["gold", "silver", "platinium"].includes(i.value)) ||
      [];
    let metalArray = [...types];
    let total = 0;
    let goldTotal = 0;
    let silverTotal = 0;
    let platinumTotal = 0;
    let metalRates = {
      gold: {},
      silver: {},
      platinum: {},
    };

    // if (isArrayWithValues(metalArray) && isObjWithValues(masters)) {
    let { gold_pricing, silver_pricing, platinum_pricing } = masterPricing;
    let { variants } = product;
    if (
      metalArray.find((i) => i.value === "gold") &&
      gold_pricing[default_currency] &&
      metal_types?.includes("gold")
    ) {
      let goldRates = gold_pricing[default_currency][gold_pricing.type];
      if (goldRates) {
        let goldRate = goldRates[gold_kt] || 0;
        metalRates.gold[gold_kt] = goldRate?.rate || 0;
        if (goldRate)
          if (isArrayWithValues(variants) && false)
            variants.map(
              (i) =>
                (total +=
                  validateNumber(goldRate.rate) *
                  validateNumber(i[`gold_${gold_pricing.from}`]))
            );
          else {
            total +=
              validateNumber(goldRate.rate) *
              validateNumber(product[`gold_${gold_pricing.from}`]);
            goldTotal =
              validateNumber(goldRate.rate) *
              validateNumber(product[`gold_${gold_pricing.from}`]);
          }
      }
    }
    if (
      metalArray.find((i) => i.value === "silver") &&
      silver_pricing[default_currency] &&
      metal_types?.includes("silver")
    ) {
      let silverRates = silver_pricing[default_currency][silver_pricing.type];
      if (silverRates) {
        let silverRate = silverRates[silver_purity] || 0;
        metalRates.silver[silver_purity] = silverRate?.rate || 0;

        if (silverRate)
          if (isArrayWithValues(variants))
            variants.map(
              (i) =>
                (total +=
                  validateNumber(silverRate.rate) *
                  validateNumber(i[`silver_${silver_pricing.from}`]))
            );
          else {
            total +=
              validateNumber(silverRate.rate) *
              validateNumber(product[`silver_${silver_pricing.from}`]);
            silverTotal =
              validateNumber(silverRate.rate) *
              validateNumber(product[`silver_${silver_pricing.from}`]);
          }
      }
    }
    if (
      metalArray.find((i) => i.value === "platinium") &&
      platinum_pricing[default_currency] &&
      metal_types?.includes("platinium")
    ) {
      let platinumRates =
        platinum_pricing[default_currency][platinum_pricing.type];
      if (platinumRates) {
        let platinumRate = platinumRates[platinium_purity] || 0;
        metalRates.platinum[platinium_purity] = platinumRate?.rate || 0;
        if (platinumRate)
          if (isArrayWithValues(variants))
            variants.map(
              (i) =>
                (total +=
                  validateNumber(platinumRate.rate) *
                  validateNumber(i[`platinium_${platinum_pricing.from}`]))
            );
          else {
            total +=
              validateNumber(platinumRate.rate) *
              validateNumber(product[`platinium_${platinum_pricing.from}`]);
            platinumTotal =
              validateNumber(platinumRate.rate) *
              validateNumber(product[`platinium_${platinum_pricing.from}`]);
          }
      }
    }
    return {
      total: Math.round(total),
      metalBreakup: {
        gold: Math.round(goldTotal),
        silver: Math.round(silverTotal),
        platinum: Math.round(platinumTotal),
      },
      metalRates,
    };
  };
  const calculateTotalDiamond = () => {
    // let obj = {};
    // // let obj = { ...diamond };
    // if (!obj) return 0;

    let details = [];
    if (isArrayWithValues(diamond)) details = diamond;
    else if (isObjWithValues(diamond)) details = Object.values(diamond);
    else if (
      isArrayWithValues(diamond_customize) &&
      (diamond_from === "customize" || diamond_from === "individual")
    )
      details = diamond_customize;
    else if (
      isObjWithValues(diamond_customize) &&
      (diamond_from === "customize" || diamond_from === "individual")
    )
      details = Object.values(diamond_customize);
    else return { total: 0 };
    let total = 0;
    const diamondBreakup = {};
    const diamondRates = {};
    if (isArrayWithValues(details)) {
      for (let i = 0; i < details.length; i++) {
        let rate = 0;
        if (diamond_from === "customize" || diamond_from === "individual") {
          rate = validateNumber(details[i].diamond_rate);
          diamondBreakup[i + 1] = validateNumber(rate);
          diamondRates[i + 1] = validateNumber(rate);
        } else {
          let {
            diamond_quality,
            diamond_shape,
            // diamond_sieve,
            diamond_cut,
            diamond_type,
            diamond_weight,
            diamond_pieces,
          } = details[i];
          let diamond_sieve;
          if (isObjWithValues(allDiamondGroups)) {
            let netWeight = Number(
              Number(diamond_weight) / Number(diamond_pieces)
            );
            let diamondGroup = Object.values(allDiamondGroups);
            // console.log(diamondGroup);
            let obj = diamondGroup.find((i) => {
              return (
                i.shape === diamond_shape &&
                netWeight >= Number(i.from) &&
                netWeight <= Number(i.to)
              );
            });
            if (!obj)
              obj = diamondGroup.find((i) => {
                let netWeightFixed2 = Number(netWeight).toFixed(2);
                return (
                  i.shape === diamond_shape &&
                  netWeightFixed2 >= Number(i.from) &&
                  netWeightFixed2 <= Number(i.to)
                );
              });
            diamond_sieve = obj?.id;
          }

          rate =
            getDiamondRate(
              diamondPricings,
              diamond_type,
              diamond_quality,
              diamond_shape,
              diamond_sieve,
              diamond_cut
            ) || 0;
        }
        total += rate * validateNumber(details[i].diamond_weight);

        diamondRates[i + 1] = validateNumber(rate);
        diamondBreakup[i + 1] = validateNumber(
          Math.round(rate * validateNumber(details[i].diamond_weight))
        );
      }
    }
    return { total: Math.round(total), diamondBreakup, diamondRates };
  };
  const calculateTotalGemstone = () => {
    // let obj = { ...colorstone_details };
    // let details = Object.values(obj);

    let details = [];
    if (isArrayWithValues(colorstone_details)) details = colorstone_details;
    else if (isObjWithValues(colorstone_details))
      details = Object.values(colorstone_details);
    else if (
      isArrayWithValues(colorstone_details_customize) &&
      (colorstone_from === "customize" || colorstone_from === "individual")
    )
      details = colorstone_details_customize;
    else if (
      isObjWithValues(colorstone_details_customize) &&
      (colorstone_from === "customize" || colorstone_from === "individual")
    )
      details = Object.values(colorstone_details_customize);
    else return { total: 0 };

    let total = 0;
    let gemstoneBreakup = {},
      gemstoneRates = {};
    if (isArrayWithValues(details)) {
      for (let i = 0; i < details.length; i++) {
        let rate = 0;
        if (
          colorstone_from === "customize" ||
          colorstone_from === "individual"
        ) {
          rate = validateNumber(details[i].colorstone_rate);
          gemstoneBreakup[i + 1] = validateNumber(rate);
          gemstoneRates[i + 1] = validateNumber(rate);
        } else {
          let {
            colorstone_quality,
            colorstone_type,
            colorstone_shape,
            colorstone_size,
          } = details[i];
          rate =
            getGemstoneRate(
              gemstonePricing,
              colorstone_quality,
              colorstone_type,
              colorstone_shape,
              colorstone_size
            ) || 0;
        }
        if (
          colorstone_from === "customize" ||
          colorstone_from === "individual"
        ) {
          total += validateNumber(rate);

          gemstoneBreakup[i + 1] = validateNumber(rate);
          gemstoneRates[i + 1] = validateNumber(rate);
        } else {
          total += rate * validateNumber(details[i].colorstone_weight);
          gemstoneBreakup[i + 1] = validateNumber(
            Math.round(rate * validateNumber(details[i].colorstone_weight))
          );
          gemstoneRates[i + 1] = validateNumber(rate);
        }
      }
    }
    return { total: Math.round(total), gemstoneBreakup, gemstoneRates };
    // onEditProduct({
    //   price_breakup: {
    //     ...price_breakup,
    //     [colorstone_from]: {
    //       ...price_breakup[colorstone_from],
    //       colorstone: Math.round(total),
    //     },
    //   },
    // });
    // if (isObjWithValues(masters) && isObjWithValues(productSettings)) {
    //   let { default_currency } = productSettings;
    //   let { gemstone_pricing } = masters;
    //   if (gemstone_pricing[default_currency]) {
    //   }
    // }
  };

  let { total: labour, labourBreakup } = calculateTotalLabourPrice();
  let metalObj = calculateTotalMetalPrice();
  let metal = metalObj.total;

  let extraCharges = 0;

  if (isArrayWithValues(extra_charges)) {
    // extra_charges = Object.values(extra_charges);
    extra_charges?.map(
      (i) => (extraCharges += validateNumber(i.extra_charge_value))
    );
  }
  if (isObjWithValues(extra_charges)) {
    extra_charges = Object.values(extra_charges);
    extra_charges?.map(
      (i) => (extraCharges += validateNumber(i.extra_charge_value))
    );
  }

  let {
      total: diamond_total,
      diamondBreakup,
      diamondRates,
    } = calculateTotalDiamond() || {},
    {
      total: gemstone_total,
      gemstoneBreakup,
      gemstoneRates,
    } = calculateTotalGemstone();

  if (onlyDiamond) {
    metal = 0;
    gemstone_total = 0;
    labour = 0;
  } else if (onlyColorstone) {
    metal = 0;
    diamond_total = 0;
    labour = 0;
  }
  let discount = { labour: 0, metal: 0, diamond: 0, gemstone: 0, total: 0 };

  let priceBreakup = {
    master: { metal, extraCharges },
    metalBreakup: metalObj.metalBreakup,
    metalRates: metalObj.metalRates,
    diamondBreakup,
    diamondRates,
    gemstoneBreakup,
    gemstoneRates,
    labourBreakup,
  };

  priceBreakup = {
    ...priceBreakup,
    [diamond_from]: { ...priceBreakup[diamond_from], diamond: diamond_total },
  };
  priceBreakup = {
    ...priceBreakup,
    [labourType]: { ...priceBreakup[labourType], labour },
  };
  priceBreakup = {
    ...priceBreakup,
    [colorstone_from]: {
      ...priceBreakup[colorstone_from],
      colorstone: gemstone_total,
      gemstone: gemstone_total,
    },
  };

  let isERPProduct = product?.ornate_unique_id || product?.jemisys_product;
  if (marginPricing) {
    let ERPName = product?.ornate_unique_id
      ? "ornate"
      : product?.jemisys_product
      ? "jemisys"
      : null;
    let margin = getMarginRate({
      product,
      margin_pricing: marginPricing,
      margin_on: { erp: [ERPName] },
    });

    if (isObjWithValues(margin))
      priceBreakup = addMarginInPriceBreakup({
        priceBreakup,
        product,
        marginCombination: margin,
      });
  }

  if (isObjWithValues(custom_discounts)) {
    if (custom_discounts.on_sale_metal) {
      let { type, value } = custom_discounts.on_sale_metal;
      if (value)
        if (type == "by_percentage")
          discount.metal += validateNumber(
            Number(metal) * (validateNumber(value) / 100)
          );
        else if (type == "by_amount")
          discount.metal += validateNumber(Math.round(value));
    }
    if (custom_discounts.on_sale_making) {
      let { type, value } = custom_discounts.on_sale_making;
      if (value)
        if (type == "by_percentage")
          discount.labour += validateNumber(
            Number(labour) * (validateNumber(value) / 100)
          );
        else if (type == "by_amount")
          discount.labour += validateNumber(Math.round(value));
    }
    if (custom_discounts.on_sale_diamond) {
      let { type, value } = custom_discounts.on_sale_diamond;
      if (value)
        if (type == "by_percentage")
          discount.diamond += validateNumber(
            Number(diamond_total) * (validateNumber(value) / 100)
          );
        else if (type == "by_amount")
          discount.diamond += validateNumber(Math.round(value));
    }
    if (custom_discounts.on_sale_colorstone) {
      let { type, value } = custom_discounts.on_sale_colorstone;
      if (value)
        if (type == "by_percentage")
          discount.gemstone += validateNumber(
            Number(gemstone_total) * (validateNumber(value) / 100)
          );
        else if (type == "by_amount")
          discount.gemstone += validateNumber(Math.round(value));
    }
    if (custom_discounts.on_total_sale) {
      let { type, value } = custom_discounts.on_total_sale;
      if (value) {
        if (type == "by_percentage")
          discount.total +=
            Number(getTotalProductPrice(product, priceBreakup)) *
            (validateNumber(value) / 100);
        else if (type == "by_amount") discount.total += Number(value);
      }
    }
  }
  // Object.values(discount).map(
  //   (i) => (discountTotal += Math.round(validateNumber(i)))
  // );
  // discount.total = discountTotal;
  // let totalPrice = Math.round(labour + metal + gemstone_total + diamond_total);
  // console.log(labour, metal, gemstone_total, diamond_total, discount);

  let totalDiscount = 0;
  Object.values(discount).map(
    (i) => (totalDiscount += validateNumber(Math.round(i)))
  );
  // console.log(discount);
  // console.log(totalDiscount);
  let totalTax = 0;

  let totalPrice = getTotalProductPrice(product, priceBreakup);

  let priceWithoutTax = totalPrice;

  let priceWithoutTaxAndDiscount = totalPrice - totalDiscount;
  if (totalPrice && tax) {
    totalTax = Math.round(
      (priceWithoutTaxAndDiscount * validateNumber(tax)) / 100
    );
    totalPrice += totalTax;
  }

  if (isObjWithValues(promotion_stepper) && promotion_stepper?.enable) {
    let {
      price,
      tax: newTax,
      priceDifference,
    } = addPromotionStepper({
      ...promotion_stepper,
      price: totalPrice,
      priceWithoutTax: priceWithoutTax,
      tax: totalTax,
      taxRate: tax,
    });
    totalPrice = price;
    totalTax = newTax;
    if (priceDifference && validateNumber(priceBreakup?.[labourType]?.labour))
      priceBreakup[labourType].labour = Math.round(
        priceBreakup?.[labourType]?.labour + priceDifference
      );
  }

  let priceObj = {
    ...priceBreakup,
    discount: { ...discount },
    totalDiscount,
    totalPrice,
    tax: totalTax,
    has_manual_price: false,
    // price: totalDiscountPrice,
  };

  priceObj.priceWithoutTax = Math.round(
    validateNumber(priceObj.price) - validateNumber(priceObj.totalTax)
  );
  // priceObj.price = Math.round(totalPrice - totalDiscount);
  priceObj.price = Math.round(
    priceWithoutTax - totalDiscount + validateNumber(priceObj.tax)
  );

  // totalDiscountPrice += Math.round(
  //   (totalDiscountPrice * validateNumber(tax)) / 100
  // );
  if (validateNumber(product.manual_price) || productSettings?.hide_pricing) {
    priceObj.totalTax = Math.round(
      validateNumber(
        (Math.round(product.manual_price) * validateNumber(tax)) / 100
      )
    );

    priceObj.totalDiscount = 0;
    if (isObjWithValues(custom_discounts) && custom_discounts?.on_total_sale) {
      let { type, value } = custom_discounts?.on_total_sale;
      if (value) {
        if (type == "by_percentage")
          priceObj.totalDiscount =
            validateNumber(Math.round(product.manual_price)) *
            (validateNumber(value) / 100);
        else if (type == "by_amount") priceObj.totalDiscount = Number(value);
      }
    }

    priceObj.priceWithoutTaxWithDiscount = Math.round(
      product.manual_price - priceObj.totalDiscount
    );

    priceObj.price = Math.round(
      validateNumber(
        Math.round(priceObj.priceWithoutTaxWithDiscount) + priceObj.totalTax
      )
    );
    priceObj.totalPrice = validateNumber(Math.round(product.manual_price));
    priceObj.has_manual_price = true;
  }
  // console.log(priceObj);
  return priceObj;
}
function addPromotionStepper(obj) {
  let {
    min = 499,
    max = 999,
    medium = 750,
    taxRate = 3,
    priceWithoutTax,
    price,
  } = obj;

  let lastThreeDigits = price % 1000;
  let stepperPrice = 0;
  if (lastThreeDigits >= medium) {
    stepperPrice = Math.floor(price / 1000) * 1000 + max;
  } else {
    stepperPrice = Math.floor(price / 1000) * 1000 + min;
  }
  let priceDifference = stepperPrice - price;
  let priceDifferenceWithoutTax = priceDifference / (1 + taxRate / 100);
  let newPrice = priceWithoutTax + priceDifferenceWithoutTax;
  let newTax = (newPrice * taxRate) / 100;
  let finalPrice = newPrice + newTax;

  return {
    price: Math.round(stepperPrice),
    tax: Math.round(newTax),
    priceDifference: priceDifferenceWithoutTax,
  };
}

// function addPromotionStepper(obj) {
//   let {
//     min = 499,
//     max = 999,
//     medium = 750,
//     taxRate = 3,
//     priceWithoutTax,
//     price,
//   } = obj;

//   let lastThreeDigits = price % 1000;
//   let stepperPrice = 0;
//   if (lastThreeDigits >= medium) {
//     stepperPrice = Math.floor(price / 1000) * 1000 + max;
//   } else {
//     stepperPrice = Math.floor(price / 1000) * 1000 + min;
//   }
//   let priceDifference = stepperPrice - price;
//   let priceDifferenceWithoutTax =
//     priceDifference - (priceDifference * taxRate) / 100;
//   let newPrice = priceWithoutTax + priceDifferenceWithoutTax;
//   let newTax = (newPrice * taxRate) / 100;
//   let finalPrice = newPrice + newTax;

//   console.log(min, max, medium, price, priceWithoutTax, ",<<<<1");

//   console.log(
//     priceDifferenceWithoutTax,
//     priceDifference,
//     newPrice,
//     finalPrice,
//     stepperPrice
//   );
//   return { price: Math.round(finalPrice), tax: Math.round(newTax) };
// }

// function addPromotionStepper({
//   min = 499,
//   max = 999,
//   medium = 750,
//   taxRate = 3,
//   priceWithoutTax,
//   price,
// }) {
//   let lastThreeDigits = price % 1000;
//   let stepperPrice = 0;
//   if (lastThreeDigits > medium) {
//     stepperPrice = Math.floor(price / 1000) * 1000 + max;
//   } else {
//     stepperPrice = Math.floor(price / 1000) * 1000 + min;
//   }
//   let priceDifference = stepperPrice - price;
//   let priceDifferenceWithtoutTax =
//     priceDifference - (priceDifference * taxRate) / 100;
//   let newPrice = priceWithoutTax + priceDifferenceWithtoutTax;
//   let newTax = (newPrice * taxRate) / 100;
//   let finalPrice = Math.round(newPrice + newTax);

//   console.log(
//     priceDifferenceWithtoutTax,
//     priceDifference,
//     newPrice,
//     finalPrice
//   );
//   return { price: finalPrice, tax: Math.round(newTax) };
// }

const addMarginInPriceBreakup = ({
  priceBreakup = {},
  product = {},
  marginCombination = {},
}) => {
  let { meta_data } = product;

  if (isArrayWithValues(meta_data))
    for (let obj of meta_data) product[obj?.key] = obj?.value;

  let { colorstone_from, diamond_from } = product;
  if (diamond_from === "individual") diamond_from = "customize";
  if (colorstone_from === "individual") colorstone_from = "customize";
  if (!diamond_from) diamond_from = "master";
  if (!colorstone_from) colorstone_from = "master";

  let { cost_components, margin } = marginCombination;
  let updatedPriceBreakup = { ...priceBreakup };
  if (!updatedPriceBreakup?.erpMargin) updatedPriceBreakup.erpMargin = {};
  let fromObj = {
    diamond: "diamond_from",
    gemstone: "colorstone_from",
    labour: "labourType",
  };
  const temp = {
    gemstone: "colorstone",
    diamond: "diamond",
    labour: "labour",
  };
  for (let cost of cost_components) {
    cost = cost?.value;
    let from = product?.[fromObj[cost]] || "master";
    if (from === "individual") from = "customize";
    let value = priceBreakup[from]?.[temp[cost]];
    if (!value) continue;
    if (!updatedPriceBreakup.erpMargin[cost])
      updatedPriceBreakup.erpMargin[cost] = {};
    updatedPriceBreakup.erpMargin[cost].profit = value;
    updatedPriceBreakup.erpMargin[cost].margin = margin;
    value = validateNumber(value);
    let profit = (value * validateNumber(margin)) / 100;
    value = Math.round(value + profit);
    updatedPriceBreakup[from][temp[cost]] = value;
  }
  return updatedPriceBreakup;
};

function getPromotionStepperPrice(obj = {}, price) {
  let { min, max, medium } = obj;

  let lastThreeDigits = price % 1000;
  let stepperPrice = 0;
  if (lastThreeDigits > medium) {
    stepperPrice = Math.floor(price / 1000) * 1000 + max;
  } else {
    stepperPrice = Math.floor(price / 1000) * 1000 + min;
  }

  return { price: Math.round(stepperPrice) };
}

function calculateDiscountFromPriceChange(
  originalPrice,
  originalDiscount,
  newPrice
) {
  const originalDiscountPercentage = (originalDiscount / originalPrice) * 100;
  const newDiscount = (newPrice * originalDiscountPercentage) / 100;
  return Math.round(newDiscount);
}

const getMarginRate = ({
  product = {},
  margin_pricing = [],
  margin_on = {},
}) => {
  let { collections, categories, subcategory, tags } = product;
  for (let combination of margin_pricing) {
    let {
      collections: _collections,
      categories: _categories,
      subcategories: _subcategories,
      tags: _tags,
      apply_all_categories,
      apply_all_collections,
      apply_all_sub_categories,
      apply_all_tags,
    } = combination;
    // if there are collections it should have combinations collections and if there aren't it should be true
    /* The above code is checking if certain conditions are met before returning a combination object.
    It checks if the arrays `collections`, `categories`, `subcategory`, and `tags` contain values
    that exist in their respective arrays `_collections`, `_categories`, `_subcategories`, and
    `_tags`. If these arrays are empty or `apply_all_*` is true, the condition is considered true. */

    if (
      ((isArrayWithValues(collections) &&
        _collections?.some((i) =>
          collections?.find((j) => j?.value == i?.value || j?.slug == i?.value)
        )) ||
        apply_all_collections ||
        !isArrayWithValues(_collections)) &&
      ((isArrayWithValues(categories) &&
        _categories?.some((i) =>
          categories?.find((j) => j?.value == i?.value || j?.slug == i?.value)
        )) ||
        apply_all_categories ||
        !isArrayWithValues(_categories)) &&
      ((isArrayWithValues(subcategory) &&
        _subcategories?.some((i) =>
          subcategory?.find((j) => j?.value == i?.value || j?.slug == i?.value)
        )) ||
        apply_all_sub_categories ||
        !isArrayWithValues(_subcategories)) &&
      ((isArrayWithValues(tags) &&
        _tags?.some((i) =>
          tags?.find((j) => j?.value == i?.value || j?.slug == i?.value)
        )) ||
        apply_all_tags ||
        !isArrayWithValues(_tags))
    ) {
      for (let marginkey in margin_on) {
        let values = margin_on?.[marginkey];
        if (
          values?.every((i) =>
            combination?.[marginkey]?.find((j) => j?.value === i)
          )
        ) {
          return combination;
        }
      }
      return combination;
    }
  }
};

export const getNetWeight = (gross, product) => {
  if (!gross || !product) return gross || "";
  let {
    diamond,
    colorstone_details,
    colorstone_details_customize,
    diamond_customize,
    colorstone_from,
    diamond_from,
  } = product;
  let totalWeight = 0;
  let diamondArray = [];
  let gemstoneArray = [];

  if (isArrayWithValues(colorstone_details)) gemstoneArray = colorstone_details;
  else if (isObjWithValues(colorstone_details))
    gemstoneArray = Object.values(colorstone_details);
  else if (
    isArrayWithValues(colorstone_details_customize) &&
    (colorstone_from === "customize" || colorstone_from === "individual")
  )
    gemstoneArray = colorstone_details_customize;
  else if (
    isObjWithValues(colorstone_details_customize) &&
    (colorstone_from === "customize" || colorstone_from === "individual")
  )
    gemstoneArray = Object.values(colorstone_details_customize);

  if (isArrayWithValues(diamond)) diamondArray = diamond;
  else if (isObjWithValues(diamond)) diamondArray = Object.values(diamond);
  else if (
    isArrayWithValues(diamond_customize) &&
    (diamond_from === "customize" || diamond_from === "individual")
  )
    diamondArray = diamond_customize;
  else if (
    isObjWithValues(diamond_customize) &&
    (diamond_from === "customize" || diamond_from === "individual")
  )
    diamondArray = Object.values(diamond_customize);

  // if (isObjWithValues(colorstone_details))
  gemstoneArray.forEach((i) => {
    if (!isNaN(Number(i.colorstone_weight)))
      totalWeight += Number(i.colorstone_weight);
  });
  // if (isObjWithValues(diamond))
  diamondArray.forEach((i) => {
    if (!isNaN(Number(i.diamond_weight)))
      totalWeight += Number(i.diamond_weight);
  });
  if (isNumber(gross)) {
    let totalNet = Number((gross - totalWeight / 5).toFixed(3));
    if (totalNet <= 0) return gross;
    return totalNet;
  } else return gross || "";
};

const getTotalProductPrice = (product, priceBreakup) => {
  let total = 0;
  let { master, customize } = { ...priceBreakup };
  if (master) {
    if (!product.colorstone_from || product.colorstone_from === "master")
      total += validateNumber(master.colorstone);
    if (!product.diamond_from || product.diamond_from === "master")
      total += validateNumber(master.diamond);

    total += validateNumber(master.labour);
    // console.log(master.labour, "labour");
    total += validateNumber(master.metal);
    total += validateNumber(master.extraCharges);
  }
  if (customize) {
    if (
      product.labourType === "customize" ||
      product.labourType === "individual"
    )
      total += validateNumber(customize.labour);
    if (
      product.colorstone_from === "customize" ||
      product.colorstone_from === "individual"
    )
      total += validateNumber(customize.colorstone);
    if (
      product.diamond_from === "customize" ||
      product.diamond_from === "individual"
    )
      total += validateNumber(customize.diamond);
  }

  return Math.round(total);
  // onEditProduct({ price: total });
};
function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}
const fetchCustomerByEmail = async (email) => {
  try {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params: { search: email },
        headers: { Authorization: `Basic ${token}` },
      });
      if (isArrayWithValues(data)) return data;
    }
  } catch (error) {
    return false;
  }
};

const getIsAdmin = () => {
  return false;
};

const reduceLargeText = (string, length) =>
  typeof string === "string"
    ? length
      ? string.length <= length
        ? string
        : `${string.substring(0, length)}...`
      : `${string.substring(0, 10)}...`
    : string;

const getIntegrationObj = (reduxIntegration, app) => {
  let obj = {};
  let key = "";
  for (const value in reduxIntegration) {
    if (reduxIntegration?.[value]?.connected?.includes(app)) {
      key = value;
      obj = { ...(reduxIntegration[value] || {}) };
    }
  }
  return {
    obj,
    key,
  };
};
const getValueFormIntegrations = ({
  integrations = {},
  key,
  keys,
  platform,
}) => {
  for (let integration of Object.values(integrations)) {
    let { connected } = integration;
    if (key && connected.includes(platform)) return integration?.[key];
    if (keys) {
      let temp = integration;
      for (let key of keys) {
        temp = temp?.[key];
      }
      return temp;
    }
  }
};
const getPricingdiff = (oldObj, newobj) => {
  if (!isObjWithValues(oldObj) || !isObjWithValues(newobj)) return {};
  let result = {};
  for (var key in newobj) {
    if (JSON.stringify(oldObj[key]) !== JSON.stringify(newobj[key])) {
      result[key] = {
        new: newobj[key]?.rate,
        old: oldObj[key]?.rate,
      };
    }
  }
  return result;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const setupDigigoldUsers = (customers) => {
  let array = [];
  if (isObjWithValues(customers)) {
    let metaKeys = [
      "nominee_name",
      "nominee_relation",
      "digigold_proof_type",
      "digigold_proof_id",
      "profile_url",
      "digiReferredFrom",
    ];
    let digiKeys = ["total", "plans", "grams"];
    for (let customerId of Object.keys(customers)) {
      let customer = customers[customerId] || {};
      customer.id = customerId;
      let meta_data = [];
      let digigold_details = {};
      for (let key of metaKeys)
        if (customer[key])
          meta_data.push({
            key,
            value: customer[key],
          });
      for (let key of digiKeys)
        if (customer[key]) digigold_details[key] = customer[key];
      customer.meta_data = meta_data;
      customer.digigold_details = digigold_details;

      array.push(customer);
    }
  }
  return array;
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { justifyContent: "left" },
  };
}

function isValidIPAddress(ipaddress) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ipaddress
  );
}

function getDateString(date) {
  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return formatDate(new Date(date), "hh:mm");
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    return formatDate(new Date(date), "dd/mm/yyyy");
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "-webkit-fill-available" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, width: "-webkit-fill-available" }}>{children}</Box>
      )}
    </div>
  );
}
const mainWebsite = isDev()
  ? localLink
  : window.location.host.includes("jwero")
  ? liveLink
  : localLink;

const getMainWebsite = (type) => {
  if (type === "supplier")
    return isDev()
      ? localLink
      : window.location.host.includes("jwero")
      ? liveSupplierLink
      : localLink;
  else
    return isDev()
      ? localLink
      : window.location.host.includes("jwero")
      ? liveLink
      : localLink;
};

const getMedia = async (id) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  const res = await axios({
    url: `${website}/wp-json/wp/v2/media/${id}`,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });

  if (isObjWithValues(res.data) && res.data?.id == id) {
    return res.data;
  }
};

const getMasterPricingHistoryObj = (masterPricing, currency) => {
  let historyObj = {};
  if (isObjWithValues(masterPricing)) {
    let { gold_pricing, silver_pricing, platinum_pricing } = masterPricing;
    if (isObjWithValues(gold_pricing?.[currency])) {
      let { type } = gold_pricing;
      historyObj.gold_pricing = gold_pricing[currency][type];
      if (historyObj.gold_pricing[gold_pricing[currency].default])
        historyObj.gold_pricing[gold_pricing[currency].default].default = true;
    }
    if (isObjWithValues(silver_pricing?.[currency])) {
      let { type } = silver_pricing;
      historyObj.silver_pricing = silver_pricing[currency][type];
      if (historyObj.silver_pricing[silver_pricing[currency].default])
        historyObj.silver_pricing[
          silver_pricing[currency].default
        ].default = true;
    }
    if (isObjWithValues(platinum_pricing?.[currency])) {
      let { type } = platinum_pricing;
      historyObj.platinum_pricing = platinum_pricing[currency][type];
      if (historyObj.platinum_pricing[platinum_pricing[currency].default])
        historyObj.platinum_pricing[
          platinum_pricing[currency].default
        ].default = true;
    }

    historyObj.currency = currency;
    historyObj.currency_symbol = currencySymbols[currency];
    historyObj.time = new Date().getTime();
  }
  return historyObj;
};

const replaceTemplateVariable = (obj, customerInfo) => {
  let variableObj = getTemplateVariableObjFromCustomer(customerInfo);
  if (isArrayWithValues(obj.components)) {
    let components = [];
    for (let component of obj.components) {
      if (isArrayWithValues(component.parameters)) {
        let parameters = [];
        for (let parameter of component.parameters) {
          let { text } = parameter;
          // if (!variableObj[text]) {
          //   errorFound = true;
          //   break;
          // }
          let variableText = text
            ? text.includes("{") && text.includes("}")
              ? variableObj[text]
              : ""
            : text;
          parameters.push({ ...parameter, text: variableText });
        }
        components.push({ ...component, parameters });
      }
    }
    if (isArrayWithValues(components)) obj = { ...obj, components };
    // payload = {
    //   ...obj,
    //   template: { ...obj, components },
    // };
    // payload.template.components = components;
  }
  return obj;
};

const deletePermission = async (userId, scope, access_token) => {
  try {
    let res = await axios({
      url: `https://graph.facebook.com/v15.0/${userId}/permissions`,
      method: "DELETE",
      params: {
        access_token,
        permission: scope,
      },
    });
    return res.data.success;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const getCustomer = async (id) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  let headersList = {
    Authorization: `Basic ${token}`,
  };

  let reqOptions = {
    url: `${website}/wp-json/wc/v3/customers/${id}?random_key`,
    method: "GET",
    // params: "abc",
    headers: headersList,
  };
  try {
    const response = await axios.request(reqOptions);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const updateCustomer = async (id, data) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  let headersList = {
    Authorization: `Basic ${token}`,
  };
  let reqOptions = {
    url: `${website}/wp-json/wc/v3/customers/${id}`,
    method: "PUT",
    headers: headersList,
    data: data,
  };
  try {
    const response = await axios.request(reqOptions);
    console.log(response.data, "sucess");

    return response.data;
  } catch (error) {
    console.log(getAxiosError(error));
    return { success: true, error: getAxiosError(error) };
    // return false;
  }
};

const updateProduct = async (id, data) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  let headersList = {
    Authorization: `Basic ${token}`,
  };
  let reqOptions = {
    url: `${website}/wp-json/wc/v3/products/${id}`,
    method: "PUT",
    headers: headersList,
    data: data,
  };
  try {
    const response = await axios.request(reqOptions);
    return response.data;
  } catch (error) {
    console.log(getAxiosError(error));
    return false;
  }
};

const saveDatainDataBase = (endpoint, data) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let res = axios({
        url: `${website}/wp-json/store/v1/settings/${endpoint}`,
        headers: { Authorization: `Basic ${token}` },
        method: "POST",
        data: {
          setting: endpoint,
          data: data,
        },
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};

const putTourHistory = async (data) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/tour_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          setting: "tour_history",
          data,
        },
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
const getShortRefLink = async (obj) => {
  try {
    let key = "AIzaSyCAhLpj70tjKt-v4xlMNDaXU1vRVJaswpo";
    let domainUriPrefix = "https://tanikatech.page.link";
    let res = await axios({
      method: "POST",
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix,
          link: `https://app.jwero.ai/dashboard?e=${Base64.btoa(
            // `type=supplier&by=${email}`
            JSON.stringify({ ...obj })
          )}`,
          androidInfo: {
            androidPackageName: "com.tanika_admin",
          },
          iosInfo: {
            iosBundleId: "com.tanikatech.tanikaadmin",
          },
        },
      },
    });
    console.log(res.data);
    if (isObjWithValues(res.data) && res.data.shortLink)
      return res.data.shortLink;
    else return false;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const getShortRefLinkSupplier = async (obj) => {
  try {
    let encoded = Base64.btoa(JSON.stringify(obj)).replaceAll("=", "");
    let key = "AIzaSyDGV52PRoxWKiMpXbmI-Aq289o08p32s34";
    let domainUriPrefix = "https://supplierconnect.page.link";
    let res = await axios({
      method: "POST",
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix,
          // link: `http://localhost:3000/?e=${encoded}`,
          link: `https://app.jwero.ai/?e=${encoded}`,
          androidInfo: {
            androidPackageName: "com.tanikatech.supplierconnect",
          },
          iosInfo: {
            iosBundleId: "com.tanikatech.supplierconnect",
          },
        },
      },
    });
    console.log(res.data);
    if (isObjWithValues(res.data) && res.data.shortLink)
      return res.data.shortLink;
    else return false;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const getisiOS = () => {
  let ua = new UAParser().getOS();
  if (ua.name && ua.name.toLowerCase() === "ios") return true;
  return false;
};

const getSupplierProduct = async (supplier, id) => {
  try {
    let getProduct = await axios({
      url: `${supplier?.url}/wp-json/wc/v3/products/${id}`,
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      params: {
        consumer_key: supplier?.key,
        consumer_secret: supplier?.secret,
      },
    });

    return getProduct?.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const getWooCommerceCustomersHelper = async ({
  authorised_store,
  consumer_key,
  consumer_secret,
  params = {},
}) => {
  try {
    let res = await axios({
      url: `${authorised_store}/wp-json/wc/v3/customers`,
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      params: {
        consumer_key,
        consumer_secret,
        ...params,
      },
    });

    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const getSupplierProductBatch = async (supplier, idArr) => {
  try {
    let res = await axios({
      url: `${supplier?.url}/wp-json/wc/v3/products/`,
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      params: {
        include: idArr?.join(","),
        per_page: 100,
        consumer_key: supplier?.key,
        consumer_secret: supplier?.secret,
      },
    });
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const createBatchProducts = async ({ data }) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products/batch`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data,
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
const filterCondition = (oldArray, value) => {
  if (!oldArray.find((obj) => obj?.value === value) && value) return true;
  else return false;
};

const batchUpdateCustomer = async (data) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let res = await axios({
      url: `${website}/wp-json/wc/v3/customers/batch`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: { update: data },
    });
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const batchCreateCustomer = async (data) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let res = await axios({
      url: `${website}/wp-json/wc/v3/customers/batch`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: { create: data },
    });
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const updateSupplierProductBatch = async (supplier, data) => {
  try {
    let res = await axios({
      url: `${supplier?.url}/wp-json/wc/v3/products/batch`,
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      params: {
        consumer_key: supplier?.key,
        consumer_secret: supplier?.secret,
      },

      method: "PUT",
      data: { update: data },
    });
    return res?.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const updateSupplierProduct = async (supplier, id, data) => {
  try {
    let res = await axios({
      url: `${supplier?.url}/wp-json/wc/v3/products/${id}`,
      params: {
        consumer_key: supplier?.key,
        consumer_secret: supplier?.secret,
      },
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      method: "PUT",
      data: data,
    });
    console.log(res);
    return res?.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const getSupplierInfo = (allSuppliers, id) => {
  let supplier = allSuppliers.find((obj) => obj?.id === id);
  return isObjWithValues(supplier) ? supplier : false;
};
const addSuppliersettings = async (supplier, data) => {
  const key = `${Date.now()}`;
  let storeId = getStoreId();
  console.log(
    supplier?.url,
    `Basic ${Base64.btoa(`${supplier?.key}:${supplier?.secret}`)}`
  );
  try {
    let res = await axios({
      url: `${supplier?.url}/wp-json/store/v1/settings/product_pull_history`,
      // headers: {
      //   Authorization: `Basic ${Base64.btoa(
      //     `${supplier?.key}:${supplier?.secret}`
      //   )}`,
      // },
      method: "PUT",
      params: {
        consumer_key: supplier?.key,
        consumer_secret: supplier?.secret,
      },
      data: {
        settings: `product_pull_history`,
        data: {
          ...data,
        },
      },
    });
    console.log(res);
    return res?.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const commerceUpdateApi = async () => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/channel/commerce`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
      });
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
const getMetaObject = ({ meta_data = [], key = "" }) => {
  let metaObject = meta_data?.find((obj) => obj?.key === key);
  return metaObject?.value;
};
const isProductIncomplete = (product) => {
  let incompleteResons = [];
  let { meta_data, collections, sku, name, stock_status, stock_quantity } =
    product;
  if (!sku) incompleteResons.push("No SKU found.");
  if (!name) incompleteResons.push("No Product Title found.");
  // if (stock_status === "instock" && !stock_quantity)
  //   incompleteResons.push("Product is Instock but no stock quantity added.");
  let isDiamondProduct =
    isArrayWithValues(collections) &&
    collections.find((i) => i?.slug && i?.slug?.includes("diamond"));
  let metal_types = getMetaValueFromProduct(product, "metal_types");
  if (isDiamondProduct) {
    let diamond = getMetaValueFromProduct(product, "diamond");
    if (!diamond)
      incompleteResons.push("Diamond Jewellery but no diamond added.");
    if (isObjWithValues(diamond)) {
      let diamondArr = Object.values(diamond);
      let everyDiamondHasWeight = true;
      let everyDiamondHasPieces = true;
      diamondArr.map((i) => {
        if (!i.diamond_weight) everyDiamondHasWeight = false;
        if (!i.diamond_pieces) everyDiamondHasPieces = false;
      });
      if (!everyDiamondHasWeight)
        incompleteResons.push(
          "Diamond Jewellery but not every diamond has weight."
        );
      if (!everyDiamondHasPieces)
        incompleteResons.push(
          "Diamond Jewellery but not every diamond has Pieces."
        );
    }
  }
  // if (isArrayWithValues(metal_types)) {
  //   if (metal_types.includes("gold")) {
  //     let gold_gross = getMetaValueFromProduct(product, "gold_gross");
  //     let gold_net = getMetaValueFromProduct(product, "gold_net");
  //     if (!gold_gross || !gold_net)
  //       incompleteResons.push(
  //         "Gold Product but gold gross/net weight not added."
  //       );
  //   }
  //   if (metal_types.includes("silver")) {
  //     let silver_gross = getMetaValueFromProduct(product, "silver_gross");
  //     let silver_net = getMetaValueFromProduct(product, "silver_net");
  //     if (!silver_gross || !silver_net)
  //       incompleteResons.push(
  //         "Silver Product but silver gross/net weight not added."
  //       );
  //   }
  //   if (metal_types.includes("platinium")) {
  //     let platinium_gross = getMetaValueFromProduct(product, "platinium_gross");
  //     let platinium_net = getMetaValueFromProduct(product, "platinium_net");
  //     if (!platinium_gross || !platinium_net)
  //       incompleteResons.push(
  //         "Platinium Product but platinium gross/net weight not added."
  //       );
  //   }
  // }
  return incompleteResons;
};

const getMetaValueFromProduct = (product, key) => {
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return false;
  let obj = meta_data.find((i) => i.key === key);
  if (!obj) return false;
  return obj.value;
};

const formatServerValue = (str) =>
  str &&
  typeof str === "string" &&
  str
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .split(" ")
    .map((i) => capitalizeFirstLetter(i))
    .join(" ");

// const unformatServerValue = (str) =>
//   str
//     .split(" ")
//     .map((i) => i.toLowerCase())
//     .join("_");

const unformatServerValue = (inputString) => {
  if (!inputString) return inputString;
  const unformattedString = inputString.replace(/\s+/g, " "); // Replace multiple spaces with a single space
  const lowercasedString = unformattedString.toLowerCase();
  const finalString = lowercasedString.split(" ").join("_"); // Replace spaces with underscores
  return finalString;
};

const getLightenColor = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

function getRandomDarkColor() {
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
}
function getRandomLightColor() {
  let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
  return color;
}

function arrayMove(arr, fromIndex, toIndex) {
  // let element = arr[fromIndex];
  // arr.splice(fromIndex, 1);
  // arr.splice(toIndex, 0, element);
  // return arr;
  let newArr = [...arr];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
}

function getRandomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getAxiosError = (error) =>
  (error && error.response && error.response.data) || error;
const isArray = (arr) => arr && Array.isArray(arr);
const isObjWithValues = (obj) =>
  obj && Object.keys(obj).length > 0 && !isArray(obj);

const isNumber = (no) => !isNaN(Number(no));

const isArrayWithValues = (arr) => arr && Array.isArray(arr) && arr.length > 0;

const validateNumber = (num) => {
  if (typeof num === "undefined" || num === null) {
    return 0;
  } else if (typeof num === "string" && num.trim() === "") {
    return 0;
  } else {
    const parsedNum = Number(num);
    if (isNaN(parsedNum) || !isFinite(parsedNum)) {
      return 0;
    } else {
      return parsedNum;
    }
  }
};

const getRandomInt = (max) => Math.floor(Math.random() * max);
const getRandomId = () => `${getTimeInSeconds()}_${getRandomString(8)}`;

const insertInString = (str, index, value) =>
  str.substr(0, index) + value + str.substr(index);

const getRandomRangeNumbers = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const containsText = (text, searchText) =>
  text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;

const capitalizeFirstLetter = (string) =>
  string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : string;

const formatAMPM = (UNIX_timestamp) => {
  var date = new Date(UNIX_timestamp);

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var secends = date.getSeconds();

  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  return strTime;
};
const dialogStyle = {
  ".MuiDialog-paper": {
    padding: "9px 7px",
    borderRadius: "30px",
  },
};
/**
 * Safely get object properties.
 * @param {*} prop The property of the object to retrieve
 * @param {*} defaultVal The value returned if the property value does not exist
 * @returns If property of object exists it is returned,
 *          else the default value is returned.
 * @example
 * var myObj = {a : {b : 'c'} };
 * var value;
 *
 * value = getSafe(myObj.a.b,'No Value'); //returns c
 * value = getSafe(myObj.a.x,'No Value'); //returns 'No Value'
 *
 * if (getSafe(myObj.a.x, false)){
 *   console.log('Found')
 * } else {
 *  console.log('Not Found')
 * }; //logs 'Not Found'
 *
 * if(value = getSafe(myObj.a.b, false)){
 *  console.log('New Value is', value); //logs 'New Value is c'
 * }
 */
function getSafe(prop, defaultVal) {
  return (function (fn, defaultVal) {
    try {
      if (fn() === undefined) {
        return defaultVal;
      } else {
        return fn();
      }
    } catch (e) {
      return defaultVal;
    }
  })(function () {
    return prop;
  }, defaultVal);
}

export const getShippingLocation = async (id) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  const req = {
    url: `${website}/wp-json/wc/v3/shipping/zones/${id}/locations`,
    headers: {
      Authorization: `Basic ${token}`,
    },
    params: {
      ...ramdomParams,
    },
    method: "GET",
  };
  try {
    const res = await axios(req);
    return res.data;
  } catch (error) {
    getAxiosError(error);
  }
};
export const getShippingMethod = async (id) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  const req = {
    url: `${website}/wp-json/wc/v3/shipping/zones/${id}/methods`,
    headers: {
      Authorization: `Basic ${token}`,
    },
    params: {
      ...ramdomParams,
    },
    method: "GET",
  };
  try {
    const res = await axios(req);
    return res.data;
  } catch (error) {
    getAxiosError(error);
  }
};
const deprecated_getChannelData = async (app, endpoint, method) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  console.log(`${website}/wp-json/store/v1/channel/${app}`, endpoint);
  try {
    let res = await axios({
      url: `${website}/wp-json/store/v1/channel/${app}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: method ? method : "GET",
      params: {
        endpoint: endpoint,
      },
      method: method ? method : "GET",
    });
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const getChannelData = async (app, endpoint, method) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  console.log(`${website}/wp-json/store/v1/channel/${app}`, endpoint);
  try {
    let res = await axios({
      url: `${website}/wp-json/store/v1/channel/${app}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: method ? method : "GET",
      params: {
        endpoint: endpoint,
      },
      method: method ? method : "GET",
    });
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const StyledTableRow = styled(TableRow)(
  ({ theme, backgroundColor, order }) => ({
    [`&:nth-of-type(${order || "odd"})`]: {
      backgroundColor: backgroundColor || theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  })
);

const getTaskType = (type, style) => {
  switch (type) {
    case "task":
      break;

    default:
      break;
  }
};
const postIntegrationData = async (obj) => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  let { app, endpoint, data } = obj;
  let res = await axios({
    url: `${website}/wp-json/store/v1/channel/${app}`,
    method: "GET",
    params: {
      endpoint: endpoint,
    },
    method: obj?.method ? obj?.method : "POST",
    data,
  });
  return res;
};
export const replaceVariable = (currentText, replace, whole_text) => {
  let firstIndex = whole_text.indexOf(replace);
  let firstString = whole_text.substring(0, firstIndex);
  whole_text = whole_text.replace(replace, "");
  let lastString = whole_text.substring(firstIndex, whole_text.length);
  return `${firstString} ${currentText} ${lastString}`;
};

const getGlobalRedirectURL = () => {
  if (isDev()) return "http://localhost:3000";
  else {
    let currentWebsite = window.location.host;
    if (currentWebsite.includes("jwero")) return "https://app.jwero.ai";
    else return "https://staging.retailer.tanika.tech";
  }
};
function isDateInRange(date, start, end) {
  return date.getTime() <= end.getTime() && date.getTime() >= start.getTime();
  // return date >= start && date <= end;
}
function isDateInThisWeek(date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}
function isDateInThisMonth(date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 30);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}
function convertToMilliseconds(unixTime) {
  const length = unixTime?.toString()?.length;

  if (length >= 10 || length <= 13) {
    return unixTime * (length === 10 ? 1000 : 1);
  } else {
    return "";
    // throw new Error('Invalid Unix epoch time. The time must be either 10 or 12 digits long.');
  }
}
let formatDate = (dateObj, format) => {
  let date_ob = dateObj;
  if (!isValidDate(dateObj)) return "";
  if (!date_ob) return;
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // adjust 0 before single digit date
  let date = ("0" + date_ob.getDate()).slice(-2);

  // current month
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // current year
  let year = date_ob.getFullYear();

  // current hours
  let hours = date_ob.getHours();

  // current minutes
  let minutes = date_ob.getMinutes();
  let seconds = date_ob.getSeconds();
  // minutes = minutes.length === 1 ? `0${minutes}` : minutes
  // prints date & time in YYYY-MM-DD HH:MM format
  if (format === "relative_date")
    return (
      date + ", " + months[date_ob.getMonth()] + " " + date_ob.getUTCFullYear()
    );
  if (format === "dd/mm/yyyy")
    return `${date}/${month}/${date_ob.getUTCFullYear()}`;
  if (format === "yyyy-mm-dd")
    return `${date_ob.getUTCFullYear()}-${month}-${date}`;
  if (format === "dd-mm-yyyy")
    return `${date}-${month}-${date_ob.getUTCFullYear()}`;
  if (format === "mm-yyyy") return `${month}-${date_ob.getUTCFullYear()}`;
  if (format === "mm-dd-yyyy")
    return `${month}-${date}-${date_ob.getUTCFullYear()}`;
  if (format === "dd mm yyyy")
    return `${date} ${months[month - 1]} ${date_ob.getUTCFullYear()}`;

  if (format === "dd:mm:yyyy hh:mm") {
    return `${date_ob.getUTCMonth()}`
      ? `${
          months[date_ob.getUTCMonth()]
        } ${date} ${date_ob.getUTCFullYear()}  ${
          hours.toString().length === 1 ? `0${hours}` : hours
        }:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`
      : "";
  }
  if (format === "hh:mm") {
    return `${date_ob.getUTCMonth()}`
      ? `${hours.toString().length === 1 ? `0${hours}` : hours}:${
          minutes.toString().length === 1 ? `0${minutes}` : minutes
        }`
      : "";
  }
  if (format === "hh:mm:ss") {
    return `${date_ob.getUTCMonth()}`
      ? `${hours.toString().length === 1 ? `0${hours}` : hours}:${
          minutes.toString().length === 1 ? `0${minutes}` : minutes
        }:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`
      : "";
  }
  if (format === "dd:mm:yyyy hh:mm am/pm") {
    var sAMPM = "AM";

    var iHourCheck = parseInt(hours);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      hours = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      hours = "12";
    }
    return `${date_ob.getUTCMonth()}` || true
      ? `${
          months[date_ob.getUTCMonth()]
        } ${date} ${date_ob.getUTCFullYear()}  ${
          hours.toString().length === 1 ? `0${hours}` : hours
        }:${minutes.toString().length === 1 ? `0${minutes}` : minutes} ${sAMPM}`
      : "";
  }
  if (format === "hh:mm am/pm") {
    var sAMPM = "AM";

    var iHourCheck = parseInt(hours);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      hours = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      hours = "12";
    }
    return `${date_ob.getUTCMonth()}`
      ? `${hours.toString().length === 1 ? `0${hours}` : hours}:${
          minutes.toString().length === 1 ? `0${minutes}` : minutes
        } ${sAMPM}`
      : "";
  }

  return `${year}-${month}-${date.length === 1 ? `0${date}` : date} ${hours}:${
    minutes.length === 1 ? `0${minutes}` : minutes
  }`;
};
function formatDateDynamic(date, format, options) {
  let { useUTC = false } = options || {};
  const monthFullNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthsNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Added day names
  const dayFullNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const daysNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Use date or UTC date based on the useUTC parameter
  const year = useUTC ? date.getUTCFullYear() : date.getFullYear();
  const month = useUTC ? date.getUTCMonth() + 1 : date.getMonth() + 1;
  const day = useUTC ? date.getUTCDate() : date.getDate();
  const dayOfWeek = useUTC ? date.getUTCDay() : date.getDay(); // Added this for day of week
  const hours24 = useUTC ? date.getUTCHours() : date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const minutes = useUTC ? date.getUTCMinutes() : date.getMinutes();
  const seconds = useUTC ? date.getUTCSeconds() : date.getSeconds();
  const ampm = hours24 < 12 ? "AM" : "PM"; // Determine AM/PM

  const formatObj = {
    YYYY: year,
    MM: month < 10 ? "0" + month : month,
    M: month,
    MMMM: monthFullNames[month - 1],
    MMM: monthsNames[month - 1],
    DD: day < 10 ? "0" + day : day,
    D: day,
    HH: hours24 < 10 ? "0" + hours24 : hours24,
    H: hours24,
    hh: hours12 < 10 ? "0" + hours12 : hours12,
    h: hours12,
    mm: minutes < 10 ? "0" + minutes : minutes,
    m: minutes,
    ss: seconds < 10 ? "0" + seconds : seconds,
    s: seconds,
    A: ampm,
    P: ampm.toLowerCase(),
    dddd: dayFullNames[dayOfWeek], // Full day name
    ddd: daysNames[dayOfWeek], // Abbreviated day name
  };

  return format.replace(
    /YYYY|MMMM|MMM|MM|M|DD|D|HH|H|hh|h|mm|m|ss|s|A|P|dddd|ddd/g,
    (match) => formatObj[match]
  );
}
function formatTime(seconds) {
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  if (seconds >= year) {
    const value = Math.floor(seconds / year);
    return { label: "years", value };
  } else if (seconds >= month) {
    const value = Math.floor(seconds / month);
    return { label: "months", value };
  } else if (seconds >= week) {
    const value = Math.floor(seconds / week);
    return { label: "weeks", value };
  } else if (seconds >= day) {
    const value = Math.floor(seconds / day);
    return { label: "days", value };
  } else if (seconds >= hour) {
    const value = Math.floor(seconds / hour);
    return { label: "hours", value };
  } else if (seconds >= minute) {
    const value = Math.floor(seconds / minute);
    return { label: "minutes", value };
  } else {
    return { label: "seconds", value: Math.floor(seconds) };
  }
}

function findMostOpenedTimePeriod(openedTimes) {
  // Convert the Unix timestamps to Date objects
  const dates = openedTimes.map((timestamp) => new Date(timestamp * 1000));
  // Create a map to count the number of openings for each hour of the day
  const hourCounts = new Map();
  for (const date of dates) {
    // Adjust the UTC hours by the local time zone offset
    const hour = (date.getUTCHours() + date.getTimezoneOffset() / 60 + 24) % 24;
    const count = hourCounts.get(hour) || 0;
    hourCounts.set(hour, count + 1);
  }
  // Find the hours with the most openings
  const sortedHours = [...hourCounts.entries()]
    .sort((a, b) => b[1] - a[1])
    .map(([hour, count]) => ({ hour, count }));
  // Calculate the total number of openings
  const totalOpenings = sortedHours.reduce((sum, { count }) => sum + count, 0);
  // Create an array of time period objects
  const timePeriods = sortedHours.map(({ hour, count }) => {
    const start = new Date(0);
    // Adjust the UTC hours by the local time zone offset
    start.setUTCHours((hour - start.getTimezoneOffset() / 60 + 24) % 24);
    const end = new Date(0);
    // Adjust the UTC hours by the local time zone offset
    end.setUTCHours((hour + 1 - end.getTimezoneOffset() / 60 + 24) % 24);
    const periodString = `${formatDate(start, "hh:mm am/pm")} - ${formatDate(
      end,
      "hh:mm am/pm"
    )}`;
    const percentage = Math.round((count / totalOpenings) * 100);
    return { periodString, count, percentage };
  });
  // Return the top three time periods
  const topThree = timePeriods.slice(0, 3);
  return topThree;
}

function formatMessageTime(date) {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = diff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;
  const years = months / 12;

  if (seconds < 60) {
    return "just now";
  } else if (minutes < 60) {
    return Math.round(minutes) + " min ago";
  } else if (hours < 24) {
    return Math.round(hours) + " hours ago";
  } else if (days < 30) {
    return Math.round(days) + " days ago";
  } else if (months < 12) {
    return Math.round(months) + " months ago";
  } else {
    return Math.round(years) + " years ago";
  }
}

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const Base64 = {
  btoa: (input = "") => {
    let str = input;
    let output = "";

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = "="), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }

      block = (block << 8) | charCode;
    }

    return output;
  },

  atob: (input = "") => {
    let str = input.replace(/=+$/, "");
    let output = "";

    if (str.length % 4 == 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  },
};

const checkBetweenTime = (startTime, endTime) => {
  let currentDate = getTimezoneTime();
  // const marketOpen = 9 * 60 + 15; // minutes
  // const marketClosed = 15 * 60 + 30; // minutes
  // var now = getTimezoneTime();
  // var currentTime = now.getHours() * 60 + now.getMinutes(); // Minutes since Midnight

  // return currentTime > marketOpen && currentTime < marketClosed;

  let startDate = new Date(currentDate.getTime());
  startDate.setHours(startTime.split(":")[0]);
  startDate.setMinutes(startTime.split(":")[1]);
  startDate.setSeconds(startTime.split(":")[2]);

  let endDate = new Date(currentDate.getTime());
  endDate.setHours(endTime.split(":")[0]);
  endDate.setMinutes(endTime.split(":")[1]);
  endDate.setSeconds(endTime.split(":")[2]);

  return startDate < currentDate && endDate > currentDate;
};

const getTimezoneTime = (date) => {
  let timezone = new Date().getTimezoneOffset() / -60;
  var d = date ? new Date(date) : new Date();
  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
  var nd = new Date(utc + 3600000 * timezone);
  return nd;
};

function ArrayMerge(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? ArrayMerge(toFlatten) : toFlatten
    );
  }, []);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validateWeight = (weight, toFixed) =>
  parseFloat(weight).toFixed(toFixed || 3);

const fetchProducts = async (
  params,
  setState,
  setLoading,
  setNoMoreProducts,
  extra
) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const website = getWebsite();
  const token = getToken();
  // if (!params.search) params.search = "";
  if (website && token) {
    if (setLoading) setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/wc/v3/products`,
      params: {
        // _fields: "id, name, sku, images,price, categories, permalink",
        signal,
        ...params,
      },

      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (res.data) setState && setState(res.data);
    if (
      (isArrayWithValues(res.data) && res.data.length < params.per_page) ||
      res.data.length === 0
    )
      setNoMoreProducts && setNoMoreProducts(true);
    else setNoMoreProducts && setNoMoreProducts(false);
    if (setLoading) setLoading(false);
    return res.data;
  }
};
export const getComunication = async (id, platform, setState, setLoading) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    if (setLoading) setLoading(true);

    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/social/conversation/${id}`,
        params: { platform },

        // params: {
        //   // _fields: "id, name, sku, images,price, categories, permalink",
        //   signal,
        //   ...params,
        // },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
    }

    // if (res.data) setState && setState(res.data);
    // if (setLoading) setLoading(false);
  }
};
const fetchCustomers = async (params, setState, setLoading) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    if (setLoading) setLoading(true);
    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params: {
          // _fields: "id, name, sku, images,price, categories, permalink",
          signal,
          ...params,
        },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(res.data);
      if (res.data) setState && setState(res.data);
    } catch (error) {}
    if (setLoading) setLoading(false);
  }
};

const fetchJweroCustomers = async ({ params }) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params: {
          signal,
          ...params,
        },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      return data;
    } catch (error) {
      return false;
    }
  }
};

const setItem = (payload) => {
  if (isObjWithValues(payload)) {
    let keys = Object.keys(payload);
    for (let i = 0; i < keys.length; i++) {
      encryptStorage.setItem(keys[i], payload[keys[i]]);
    }
  }
};

const getItem = (string) => {
  try {
    return encryptStorage.getItem(string);
  } catch (error) {}
};
const removeItem = (string) => {
  return encryptStorage.removeItem(string);
};

const getId = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.id) return user.id;
    else return false;
  } catch (error) {}
};
const getMigrationInfo = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.migration_info) return user.migration_info;
    else return false;
  } catch (error) {}
};

const getStoreName = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.store_details && user.store_details.store_name)
      return user.store_details.store_name;
    else return false;
  } catch (error) {}
};
const getEmail = () => {
  try {
    let user = encryptStorage.getItem("user");
    console.log(user);
    return user?.store_details?.store_email || false;
  } catch (error) {}
};
const getAdminName = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.store_details && user.username) return user.username;
    else return false;
  } catch (error) {}
};
const getStorePlan = () => {
  let user = encryptStorage.getItem("user");
  if (user && user.store_plan) return user.store_plan;
  else return false;
};
const getIsBackendMigrated = (key) => {
  try {
    let user = encryptStorage.getItem("user");

    if (user && user.backend_migrated) return user.backend_migrated?.[key];
    else return false;
  } catch (error) {}
};
const getStoreUserId = () => {
  try {
    let user = encryptStorage.getItem("user");
    if (user && user.user) return user.user;
    else return false;
  } catch (error) {}
};

export const getWooCommerce = async () => {
  let website = getWebsite();
  let user = getItem("user");
  if (user && user.appcredentials) {
    let credentials = user.appcredentials;
    return new WooCommerceRestApi({
      url: website,
      consumerKey: credentials.key,
      consumerSecret: credentials.secret,
      ssl: true,
      wpAPI: true, // Enable the WP REST API integration
      version: "wc/v3", // WooCommerce WP REST API version
      queryStringAuth: true,
    });
  }
};

const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

const getRemainingTime = (expiry_date, diffBy = ":") => {
  let timeInSeconds = expiry_date - Math.floor(Date.now() / 1000);
  let d = Number(timeInSeconds);
  if (d < 0) {
    return `00 ${diffBy} 00 ${diffBy} 00`;
  }
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  let hours = h > 0 ? (h.toString().length === 1 ? `0${h}` : h) : "";
  let minutes = m > 0 ? (m.toString().length === 1 ? `0${m}` : m) : "";
  let seconds = s > 0 ? (s.toString().length === 1 ? `0${s}` : s) : "";
  let formatedTime = `${hours ? hours : "00"} ${diffBy} ${
    minutes ? minutes : "00"
  } ${diffBy} ${seconds ? seconds : "00"}`;
  return formatedTime;
};

const ipInfoTokens = ["77e5f6c217111f", "0b3c863ba4c830", "a130c6a769e7ee"];

function mergeConversation(customer, allReduxConversations) {
  // let customer = {
  //   id: "nrvflijnm",
  //   name: "merge",
  //   platform: "whatsapp",
  //   ids: ["919998060495", "919967283656", "919167707645"],
  //   // conversation :
  // };
  let names = [];

  let conversationArray = [];

  for (const id of customer?.ids || []) {
    let customer = allReduxConversations?.find((obj) => obj.id === id);
    let color = getRandomDarkColor();
    names.push(
      `${customer?.first_name || ""} ${customer?.last_name || ""} ${
        customer?.name || ""
      }`
    );
    if (customer && isArrayWithValues(customer?.conversation)) {
      conversationArray = [
        ...conversationArray,
        ...customer?.conversation?.map((obj) => {
          obj.from = `${customer?.first_name || ""} ${
            customer?.last_name || ""
          } ${customer?.name || ""}`;
          obj.number = customer?.number;

          if (!obj?.is_sender) {
            obj.to = `${customer?.first_name || ""} ${
              customer?.last_name || ""
            } ${customer?.name || ""}`;
          }
          obj.color = color;

          return obj;
        }),
      ];
    }
  }

  customer.names = names;

  customer.conversation = conversationArray.sort(
    (a, b) => validateNumber(a.time) - validateNumber(b.time)
  );
  return customer;
}

const fetchIPInfo = async (ip, withObj) => {
  try {
    // let key = "77e5f6c217111f"; // exceed
    let key = "0b3c863ba4c830"; // akshay github
    // let key = "a130c6a769e7ee"; // himanshu google not exceed

    let res = await axios({
      url: `https://ipinfo.io/${ip}?token=${key}`,
    });
    if (withObj && isObjWithValues(res.data)) {
      let { geoplugin_city, geoplugin_regionCode, geoplugin_countryCode } =
        res.data;
      return {
        city: geoplugin_city,
        state: geoplugin_regionCode,
        country: geoplugin_countryCode,
      };
    }
    return res.data;
  } catch (error) {
    // if(error.code == 429){
    //   let currentToken = localStorage.getItem("ip_info_token");

    //   localStorage.setItem("ip_info_token", ipInfoTokens[])
    // }
    return false;
  }
  // let key = `64655540-8e4e-11ec-b0c0-85ea73579d48`;

  // headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  // headers.append("Access-Control-Allow-Credentials", "true");
  // let res = await fetch(`https://api.freegeoip.app/json/${ip}?apikey=${key}`, {
  //   method: "GET",
  //   headers: {
  //     "Access-Control-Allow-Credentials": "true",
  //   },
  // });

  // let res = await axios({
  //   // method: "GET",
  //   url: `https://api.freegeoip.app/json/${ip}?apikey=${key}`,
  // });
  // let res = await axios({
  //   // method: "GET",
  //   url: `http://www.geoplugin.net/json.gp?ip=${ip}`,
  // });
  // return res.data;
};

function getRelativeTimeDifference(previous, current, short) {
  if (!current) current = new Date();
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return {
      value: Math.round(elapsed / 1000),
      label: short
        ? "sec"
        : Math.round(elapsed / 1000) == 1
        ? "second"
        : "seconds",
    };
  } else if (elapsed < msPerHour) {
    return {
      value: Math.round(elapsed / msPerMinute),
      label: short
        ? "min"
        : Math.round(elapsed / msPerMinute) == 1
        ? "minute"
        : "minutes",
    };
  } else if (elapsed < msPerDay) {
    return {
      value: Math.round(elapsed / msPerHour),
      label: Math.round(elapsed / msPerHour) == 1 ? "hour" : "hours",
    };
  } else if (elapsed < msPerMonth) {
    return {
      value: Math.round(elapsed / msPerDay),
      label: Math.round(elapsed / msPerDay) == 1 ? "day" : "days",
    };
  } else if (elapsed < msPerYear) {
    return {
      value: Math.round(elapsed / msPerMonth),
      label: short
        ? "Mon"
        : Math.round(elapsed / msPerMonth) == 1
        ? "month"
        : "months",
    };
  } else {
    return {
      value: Math.round(elapsed / msPerYear),
      label: Math.round(elapsed / msPerYear) == 1 ? "year" : "years",
    };
  }
}
const reverseArrayIfRequired = (array, key) => {
  if (isArrayWithValues(array) && array.length >= 2) {
    let oldTime = array[0][key];
    let newTime = array[1][key];
    if (oldTime < newTime) return array.reverse();
  }
  return array;
};

const isEmailValid = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let calculateTotalDiamondWeight = (obj) => {
  let diamonds = Object.values(obj.value);
  let total_diamond_weight = 0;
  if (!isArrayWithValues(diamonds)) return 0;
  for (let i of diamonds) {
    total_diamond_weight += validateNumber(
      parseFloat(i.diamond_weight).toFixed(3)
    );
  }
  return Number(parseFloat(total_diamond_weight).toFixed(3));
};

let calculateTotalGemstoneWeight = (obj) => {
  let colorstone = Object.values(obj.value);
  let total_gemstone_weight = 0;
  if (!isArrayWithValues(colorstone)) return 0;
  for (let i of colorstone) {
    total_gemstone_weight += validateNumber(
      parseFloat(i.colorstone_weight).toFixed(3)
    );
  }
  return Number(parseFloat(total_gemstone_weight).toFixed(3));
};

let getGenderByFirstName = (name) => {
  if (typeof name === "string" && name.length >= 3) {
    let endForMen = ["sh", "y", "l", "yur"];
    let endForWomen = ["ka", "vi", "i", "ya", ""];
  }
};

const createFilterObjFromProduct = (product) => {
  let {
    id,
    sku,
    name,
    meta_data,
    collections,
    category,
    stock_status,
    stock_quantity,
  } = product;
  // let supplier_id = getStoreUserId();
  // let supplier_name = getStoreName();
  if (isArrayWithValues(isProductIncomplete(product))) {
    console.log(isProductIncomplete(product), sku, id);
    // return;
  }
  let filterObj = {
    id,
    sku,
    name,
    stock_status,
    stock_quantity,
  };
  if (isArrayWithValues(collections))
    filterObj["collections"] = collections
      ?.map((i) => i.slug || i.value || "")
      ?.join();
  if (isArrayWithValues(category)) filterObj["categories"] = category[0].slug;
  if (isArrayWithValues(meta_data)) {
    let length = meta_data.length;
    for (let i = 0; i < length; i++) {
      let obj = meta_data[i];
      if (obj.key === "gold_kt" && obj.value) filterObj["gold_kt"] = obj.value;
      if (obj.key === "gold_gross" && obj.value)
        filterObj["gold_gross"] = obj.value;
      if (obj.key === "diamond_lab" && obj.value)
        filterObj["diamond_lab"] = obj.value;
      // if (obj.key === "total_diamond_weight" && obj.value)
      if (obj.key === "diamond" && isObjWithValues(obj.value)) {
        let diamonds = Object.values(obj.value);
        filterObj["diamond_quality"] = diamonds
          ?.map((i) => i.diamond_quality || "")
          ?.join();
        filterObj["diamond_weight"] = 0;
        diamonds?.map(
          (i) =>
            (filterObj["diamond_weight"] += validateNumber(i.diamond_weight))
        );
        filterObj["diamond_shape"] = diamonds
          ?.map((i) => i.diamond_shape || "")
          ?.join();
        filterObj["diamond_pieces"] = diamonds
          ?.map((i) => i.diamond_pieces || "")
          ?.join();
      }
    }
  }
  return filterObj;
};

const createCustomer = async (data, id = "") => {
  let website = getWebsite();
  let token = getToken();
  if (!website && !token) return false;
  let headersList = {
    Authorization: `Basic ${token}`,
  };
  let reqOptions = {
    url: `${website}/wp-json/wc/v3/customers/${id}`,
    method: "POST",
    headers: headersList,
    data: data,
  };
  try {
    const response = await axios.request(reqOptions);
    return response;
  } catch (error) {
    error = getAxiosError(error);
    return error;
  }
};

let getTemplateVariableObjFromCustomer = (customer) => {
  if (!isObjWithValues(customer)) return;
  let { first_name, last_name, billing, name } = customer;
  billing = billing || {};
  return {
    "{First Name}": first_name || name,
    "{Last Name}": last_name || name,
    "{Fullname}": `${first_name || ""} ${last_name || ""}`.trim() || name,
    "{City}": billing.city || "",
    "{Address}": `${billing.address_1 ? `${billing.address_1},` : ""} ${
      billing.address_2 ? `${billing.address_2},` : ""
    } ${billing.city ? `${billing.city},` : ""} ${
      billing.state ? `${billing.state},` : ""
    } ${billing.country ? `${billing.country},` : ""} ${
      billing.postcode ? `${billing.postcode},` : ""
    }`.trim(),
    [`{OTP}`]: generateOTP(6),
  };
};

function generateOTP(length) {
  let otp = "";
  const characters = "0123456789"; // Only numeric characters
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    otp += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return otp;
}

export const sendInvitationRetailer = async (id) => {
  let website = await getWebsite();
  let token = await getToken();
  let res = await axios({
    url: `${website}/wp-json/store/v1/invite`,
    headers: {
      Authorization: `Basic ${token}`,
    },
    params: { retailers: id },
    method: "POST",
  });
  console.log(res.data, id, "invitation res");
  return res.data;
};

const deprecated_getConversationById = async (params, id) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/social/conversation/${id}`,
      headers: { Authorization: `Basic ${token}` },
      params,
    });
    return data;
  }
  return false;
};
const getConversationById = async (params, id) => {
  if (!getIsBackendMigrated("chats"))
    return deprecated_getConversationById(params);
  let token = getAuthToken();
  if (token) {
    let { data } = await axios({
      url: `${backendDoamin}/chat/users${id || "/"}${id || ""}`,
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
    return data;
  }
  return false;
};

const goldPurities = [
  { label: "24", value: "999" },
  { label: "22", value: "916" },
  { label: "18", value: "750" },
  { label: "14", value: "583" },
  { label: "9", value: "375" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const getUnixFromString = (string) => {
  string = insertInString(string, 4, "-");
  string = insertInString(string, 7, "-");
  if (string && isValidDate(new Date(string)))
    return new Date(string).getTime();
  else return false;
};
const getCustomerTotal = async ({
  endpoint,
  method = "GET",
  data,
  params,
  setLoading,
}) => {
  for (const paramsKey of Object.keys(params || {})) {
    if (!params?.[paramsKey]) delete params[paramsKey];
  }
  setLoading && setLoading(true);
  let website = getWebsite();
  let token = getToken();
  try {
    let requestObj = {
      url: `${website}/wp-json/wc/v3/customers`,
      headers: { Authorization: `Basic ${token}` },
      params: { ...params, per_page: 1, page: 1 },
      method,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    };
    if (data) requestObj.data = { data, type: endpoint };
    let { data: res, headers } = await axios(requestObj);
    setLoading && setLoading(false);
    return headers?.["x-wp-total"];
  } catch (error) {
    console.log(error, "settings endpoint error");
    setLoading && setLoading(false);
    return 0;
  }
  setLoading && setLoading(false);
};
const getCustomerWidthFilter = async ({
  endpoint,
  method = "GET",
  data,
  params,
  setLoading,
}) => {
  // for (const paramsKey of Object.keys(params || {})) {
  //   if (!params?.[paramsKey]) delete params[paramsKey];
  // }
  console.log(params);
  setLoading && setLoading(true);
  let website = getWebsite();
  let token = getToken();
  try {
    let requestObj = {
      url: `${website}/wp-json/wc/v3/customers`,
      headers: { Authorization: `Basic ${token}` },
      params: { per_page: 100, page: 1, ...params },
      method,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    };
    if (data) requestObj.data = { data, type: endpoint };
    let res = await axios(requestObj);
    let { headers } = res || {};
    setLoading && setLoading(false);
    console.log(headers?.["x-wp-total"]);
    return res;
    // return headers?.["x-wp-total"];
  } catch (error) {
    console.log(error, "settings endpoint error");
    setLoading && setLoading(false);
    return 0;
  }
  setLoading && setLoading(false);
};

const dialCodes = [
  {
    label: "Afghanistan 🇦🇫 (+93)",
    value: "93",
    mask: "999 999 9999",
    min: 10,
    max: 10,
  },
  {
    label: "Åland Islands 🇦🇽 (+358)",
    value: "358",
    mask: "999 9999999",
    min: 5,
    max: 12,
  },
  {
    label: "Albania 🇦🇱 (+355)",
    value: "355",
    mask: "999 99 9999",
    min: 9,
    max: 10,
  },
  {
    label: "Algeria 🇩🇿 (+213)",
    value: "213",
    mask: "9999 99 99 99",
    min: 10,
    max: 10,
  },
  {
    label: "American Samoa 🇦🇸 (+1684)",
    value: "1684",
    mask: "(999) 999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Andorra 🇦🇩 (+376)",
    value: "376",
    mask: "999 999",
    min: 6,
    max: 12,
  },
  {
    label: "Angola 🇦🇴 (+244)",
    value: "244",
    mask: "999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Anguilla 🇦🇮 (+1264)",
    value: "1264",
    mask: "(999) 999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Antarctica 🇦🇶 (+672)",
    value: "672",
    mask: "99 999999",
    min: 6,
    max: 7,
  },
  {
    label: "Antigua and Barbuda 🇦🇬 (+1268)",
    value: "1268",
    mask: "(999) 999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Argentina 🇦🇷 (+54)",
    value: "54",
    mask: "999 99-9999-9999",
    min: 11,
    max: 13,
  },
  {
    label: "Armenia 🇦🇲 (+374)",
    value: "374",
    mask: "99 999999",
    min: 8,
    max: 8,
  },
  {
    label: "Aruba 🇦🇼 (+297)",
    value: "297",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Australia 🇦🇺 (+61)",
    value: "61",
    mask: "9999 999 999",
    min: 8,
    max: 10,
  },
  {
    label: "Austria 🇦🇹 (+43)",
    value: "43",
    mask: "9999 9999999",
    min: 10,
    max: 13,
  },
  {
    label: "Azerbaijan 🇦🇿 (+994)",
    value: "994",
    mask: "999 999 999",
    min: 8,
    max: 9,
  },
  {
    label: "Bahamas 🇧🇸 (+1242)",
    value: "1242",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Bahrain 🇧🇭 (+973)",
    value: "973",
    mask: "9999 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Bangladesh 🇧🇩 (+880)",
    value: "880",
    mask: "99 9999 99999",
    min: 10,
    max: 11,
  },
  {
    label: "Barbados 🇧🇧 (+1246)",
    value: "1246",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Belarus 🇧🇾 (+375)",
    value: "375",
    mask: "99 999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Belgium 🇧🇪 (+32)",
    value: "32",
    mask: "99 99 99 99 99",
    min: 9,
    max: 10,
  },
  {
    label: "Belize 🇧🇿 (+501)",
    value: "501",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Benin 🇧🇯 (+229)",
    value: "229",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Bermuda 🇧🇲 (+1441)",
    value: "1441",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Bhutan 🇧🇹 (+975)",
    value: "975",
    mask: "99 99 999 999",
    min: 8,
    max: 9,
  },
  {
    label: "Bolivia, Plurinational State of bolivia 🇧🇴 (+591)",
    value: "591",
    mask: "999 999 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Bosnia and Herzegovina 🇧🇦 (+387)",
    value: "387",
    mask: "99 999 999",
    min: 8,
    max: 8,
  },
  {
    label: "Botswana 🇧🇼 (+267)",
    value: "267",
    mask: "9 99 999 999",
    min: 7,
    max: 8,
  },
  {
    label: "Bouvet Island 🇧🇻 (+47)",
    value: "47",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Brazil 🇧🇷 (+55)",
    value: "55",
    mask: "(99) 99999-9999",
    min: 10,
    max: 11,
  },
  {
    label: "British Indian Ocean Territory 🇮🇴 (+246)",
    value: "246",
    mask: "9 999 999",
    min: 7,
    max: 7,
  },
  {
    label: "Brunei Darussalam 🇧🇳 (+673)",
    value: "673",
    mask: "9 999 999",
    min: 7,
    max: 7,
  },
  {
    label: "Bulgaria 🇧🇬 (+359)",
    value: "359",
    mask: "9 999 9999",
    min: 8,
    max: 9,
  },
  {
    label: "Burkina Faso 🇧🇫 (+226)",
    value: "226",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Burundi 🇧🇮 (+257)",
    value: "257",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Cambodia 🇰🇭 (+855)",
    value: "855",
    mask: "999 999 999",
    min: 8,
    max: 9,
  },
  {
    label: "Cameroon 🇨🇲 (+237)",
    value: "237",
    mask: "9 99 99 99 99",
    min: 8,
    max: 9,
  },
  {
    label: "Canada 🇨🇦 (+1)",
    value: "1",
    mask: "(999) 999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Cape Verde 🇨🇻 (+238)",
    value: "238",
    mask: "9 999 999",
    min: 7,
    max: 7,
  },
  {
    label: "Cayman Islands 🇰🇾 (+345)",
    value: "345",
    mask: "999-9999",
    min: 7,
    max: 7,
  },
  {
    label: "Central African Republic 🇨🇫 (+236)",
    value: "236",
    mask: "9 99 99 99",
    min: 7,
    max: 7,
  },
  {
    label: "Chad 🇹🇩 (+235)",
    value: "235",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Chile 🇨🇱 (+56)",
    value: "56",
    mask: "9 9999 9999",
    min: 8,
    max: 9,
  },
  {
    label: "China 🇨🇳 (+86)",
    value: "86",
    mask: "999 9999 9999",
    min: 11,
    max: 11,
  },
  // {
  //   label: "Christmas Island 🇨🇽 (+61)",
  //   value: "61",
  //   mask: "99 9999 9999",
  //   min: 9,
  //   max: 9,
  // },
  // {
  //   label: "Cocos 🇨🇨 (Keeling) Islands (+61)",
  //   value: "61",
  //   mask: "99 9999 9999",
  //   min: 9,
  //   max: 9,
  // },
  {
    label: "Colombia 🇨🇴 (+57)",
    value: "57",
    mask: "9 9999999",
    min: 10,
    max: 10,
  },
  {
    label: "Comoros 🇰🇲 (+269)",
    value: "269",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Congo 🇨🇬 (+242)",
    value: "242",
    mask: "999 99 999",
    min: 7,
    max: 9,
  },
  {
    label: "Congo, The Democratic Republic of the Congo 🇨🇩 (+243)",
    value: "243",
    mask: "999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Cook Islands 🇨🇰 (+682)",
    value: "682",
    mask: "99 999",
    min: 5,
    max: 7,
  },
  {
    label: "Costa Rica 🇨🇷 (+506)",
    value: "506",
    mask: "9 999 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Cote d'Ivoire 🇨🇮 (+225)",
    value: "225",
    mask: "99 99 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Croatia 🇭🇷 (+385)",
    value: "385",
    mask: "99 999 9999",
    min: 8,
    max: 10,
  },
  {
    label: "Cuba 🇨🇺 (+53)",
    value: "53",
    mask: "9 999 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Cyprus 🇨🇾 (+357)",
    value: "357",
    mask: "99 999999",
    min: 8,
    max: 8,
  },
  {
    label: "Czech Republic 🇨🇿 (+420)",
    value: "420",
    mask: "999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Denmark 🇩🇰 (+45)",
    value: "45",
    mask: "99 99 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Djibouti 🇩🇯 (+253)",
    value: "253",
    mask: "99 99 99 99",
    min: 7,
    max: 8,
  },
  {
    label: "Dominica 🇩🇲 (+1767)",
    value: "1767",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Dominican Republic 🇩🇴 (+1849)",
    value: "1849",
    mask: "999 999 9999",
    min: 10,
    max: 10,
  },
  {
    label: "Ecuador 🇪🇨 (+593)",
    value: "593",
    mask: "999 999 999",
  },
  {
    label: "Egypt 🇪🇬 (+20)",
    value: "20",
    mask: "99 99 99 99 99",
  },
  {
    label: "El Salvador 🇸🇻 (+503)",
    value: "503",
    mask: "9999 9999",
  },
  {
    label: "Equatorial Guinea 🇬🇶 (+240)",
    value: "240",
    mask: "99 999 999",
  },
  {
    label: "Eritrea 🇪🇷 (+291)",
    value: "291",
    mask: "99 99 99 99",
  },
  {
    label: "Estonia 🇪🇪 (+372)",
    value: "372",
    mask: "9999 9999",
  },
  {
    label: "Ethiopia 🇪🇹 (+251)",
    value: "251",
    mask: "99 999 9999",
  },
  {
    label: "Falkland Islands 🇫🇰 (Malvinas) (+500)",
    value: "500",
    mask: "9999",
  },
  {
    label: "Faroe Islands 🇫🇴 (+298)",
    value: "298",
    mask: "999 999",
  },
  {
    label: "Fiji 🇫🇯 (+679)",
    value: "679",
    mask: "999 9999",
  },
  {
    label: "Finland 🇫🇮 (+358)",
    value: "358",
    mask: "999 999 9999",
  },
  {
    label: "France 🇫🇷 (+33)",
    value: "33",
    mask: "99 99 99 99 99",
  },
  {
    label: "French Guiana 🇬🇫 (+594)",
    value: "594",
    mask: "999 99 99",
  },
  {
    label: "French Polynesia 🇵🇫 (+689)",
    value: "689",
    mask: "99 99 99",
  },
  // {
  //   label: "French Southern Territories 🇹🇫 (+262)",
  //   value: "262",
  //   mask: "999 99 99 99",
  // },
  {
    label: "Gabon 🇬🇦 (+241)",
    value: "241",
    mask: "99 99 99 99",
  },
  {
    label: "Gambia 🇬🇲 (+220)",
    value: "220",
    mask: "999 9999",
  },
  {
    label: "Georgia 🇬🇪 (+995)",
    value: "995",
    mask: "999 99 99 99",
  },
  {
    label: "Germany 🇩🇪 (+49)",
    value: "49",
    mask: "99999999999",
    min: 11,
    max: 11,
  },
  {
    label: "Ghana 🇬🇭 (+233)",
    value: "233",
    mask: "999 999 9999",
    min: 10,
    max: 10,
  },
  {
    label: "Gibraltar 🇬🇮 (+350)",
    value: "350",
    mask: "999 999",
    min: 6,
    max: 6,
  },
  {
    label: "Greece 🇬🇷 (+30)",
    value: "30",
    mask: "99 999 99999",
    min: 11,
    max: 11,
  },
  {
    label: "Greenland 🇬🇱 (+299)",
    value: "299",
    mask: "999 999",
    min: 6,
    max: 6,
  },
  {
    label: "Grenada 🇬🇩 (+1473)",
    value: "1473",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "Guadeloupe 🇬🇵 (+590)",
    value: "590",
    mask: "999 99 99",
    min: 8,
    max: 8,
  },
  {
    label: "Guam 🇬🇺 (+1671)",
    value: "1671",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Guatemala 🇬🇹 (+502)",
    value: "502",
    mask: "9999-9999",
    min: 8,
    max: 8,
  },
  // {
  //   label: "Guernsey 🇬🇬 (+44)",
  //   value: "44",
  //   mask: "99999 999999",
  //   min: 12,
  //   max: 12,
  // },
  {
    label: "Guinea 🇬🇳 (+224)",
    value: "224",
    mask: "999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Guinea-Bissau 🇬🇼 (+245)",
    value: "245",
    mask: "999 999 999",
    min: 9,
    max: 9,
  },
  {
    label: "Guyana 🇬🇾 (+592)",
    value: "592",
    mask: "999-9999",
    min: 7,
    max: 7,
  },
  {
    label: "Haiti 🇭🇹 (+509)",
    value: "509",
    mask: "99 99 9999",
    min: 9,
    max: 9,
  },
  // {
  //   label: "Heard Island and Mcdonald Islands 🇭🇲 (+672)",
  //   value: "672",
  //   mask: "999 99 999",
  //   min: 6,
  //   max: 9,
  // },
  {
    label: "Holy See 🇻🇦 (Vatican City State) (+379)",
    value: "379",
    mask: "999 999 999",
    min: 3,
    max: 9,
  },
  {
    label: "Honduras 🇭🇳 (+504)",
    value: "504",
    mask: "9999 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Hong Kong 🇭🇰 (+852)",
    value: "852",
    mask: "9999 9999",
    min: 8,
    max: 8,
  },
  {
    label: "Hungary 🇭🇺 (+36)",
    value: "36",
    mask: "99 999 9999",
    min: 9,
    max: 9,
  },
  {
    label: "Iceland 🇮🇸 (+354)",
    value: "354",
    mask: "999 9999",
    min: 7,
    max: 7,
  },
  {
    label: "India 🇮🇳 (+91)",
    value: "91",
    mask: "99999 99999",
    min: 10,
    max: 10,
  },
  {
    label: "Indonesia 🇮🇩 (+62)",
    value: "62",
    mask: "999 999 999 9999",
    min: 12,
    max: 14,
  },
  {
    label: "Iran, Islamic Republic of Persian Gulf 🇮🇷 (+98)",
    value: "98",
    mask: "99 9999 9999",
    min: 10,
    max: 10,
  },
  {
    label: "Iraq 🇮🇶 (+964)",
    value: "964",
    mask: "99 999 9999",
    min: 9,
    max: 9,
  },
  {
    label: "Ireland 🇮🇪 (+353)",
    value: "353",
    mask: "999 999 9999",
    min: 10,
    max: 10,
  },
  // {
  //   label: "Isle of Man 🇮🇲 (+44)",
  //   value: "44",
  //   mask: "99999 999999",
  //   min: 11,
  //   max: 11,
  // },
  {
    label: "Israel 🇮🇱 (+972)",
    value: "972",
    mask: "99-999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Italy 🇮🇹 (+39)",
    value: "39",
    mask: "99 999 999 999",
    min: 12,
    max: 12,
  },
  {
    label: "Jamaica 🇯🇲 (+1876)",
    value: "1876",
    mask: "(999) 999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Japan 🇯🇵 (+81)",
    value: "81",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  // {
  //   label: "Jersey 🇯🇪 (+44)",
  //   value: "44",
  //   mask: "999-999-9999",
  //   min: 10,
  //   max: 10,
  // },
  {
    label: "Jordan 🇯🇴 (+962)",
    value: "962",
    mask: "07-999-9999",
    min: 7,
    max: 9,
  },
  {
    label: "Kazakhstan 🇰🇿 (+7)",
    value: "7",
    mask: "7-7999-99999",
    min: 7,
    max: 11,
  },
  {
    label: "Kenya 🇰🇪 (+254)",
    value: "254",
    mask: "07-123-4567",
    min: 7,
    max: 10,
  },
  {
    label: "Kiribati 🇰🇮 (+686)",
    value: "686",
    mask: "7999-9999",
    min: 7,
    max: 9,
  },
  {
    label: "Korea, Democratic People's Republic of Korea 🇰🇵 (+850)",
    value: "850",
    mask: "0123-4567",
    min: 7,
    max: 9,
  },
  {
    label: "Korea, Republic of South Korea 🇰🇷 (+82)",
    value: "82",
    mask: "010-9999-9999",
    min: 10,
    max: 11,
  },
  {
    label: "Kosovo 🇽🇰 (+383)",
    value: "383",
    mask: "044-9999-999",
    min: 7,
    max: 10,
  },
  {
    label: "Kuwait 🇰🇼 (+965)",
    value: "965",
    mask: "965-9999-9999",
    min: 10,
    max: 11,
  },
  {
    label: "Kyrgyzstan 🇰🇬 (+996)",
    value: "996",
    mask: "0555-99999",
    min: 7,
    max: 10,
  },
  {
    label: "Laos 🇱🇦 (+856)",
    value: "856",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Latvia 🇱🇻 (+371)",
    value: "371",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Lebanon 🇱🇧 (+961)",
    value: "961",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Lesotho 🇱🇸 (+266)",
    value: "266",
    mask: "266-999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Liberia 🇱🇷 (+231)",
    value: "231",
    mask: "231-999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Libyan Arab Jamahiriya 🇱🇾 (+218)",
    value: "218",
    mask: "218-999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Liechtenstein 🇱🇮 (+423)",
    value: "423",
    mask: "423-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Lithuania 🇱🇹 (+370)",
    value: "370",
    mask: "370-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Luxembourg 🇱🇺 (+352)",
    value: "352",
    mask: "9999-999",
    min: 7,
    max: 9,
  },
  {
    label: "Macao 🇲🇴 (+853)",
    value: "853",
    mask: "999-999",
    min: 8,
    max: 8,
  },
  {
    label: "Macedonia 🇲🇰 (+389)",
    value: "389",
    mask: "999-999",
    min: 8,
    max: 8,
  },
  {
    label: "Madagascar 🇲🇬 (+261)",
    value: "261",
    mask: "999-999-99",
    min: 9,
    max: 10,
  },
  {
    label: "Malawi 🇲🇼 (+265)",
    value: "265",
    mask: "999-999-99",
    min: 9,
    max: 10,
  },
  {
    label: "Malaysia 🇲🇾 (+60)",
    value: "60",
    mask: "9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Maldives 🇲🇻 (+960)",
    value: "960",
    mask: "999-999",
    min: 7,
    max: 8,
  },
  {
    label: "Mali 🇲🇱 (+223)",
    value: "223",
    mask: "999-99",
    min: 7,
    max: 8,
  },
  {
    label: "Malta 🇲🇹 (+356)",
    value: "356",
    mask: "999-9999",
    min: 8,
    max: 8,
  },
  {
    label: "Marshall Islands 🇲🇭 (+692)",
    value: "692",
    mask: "999-999",
    min: 7,
    max: 7,
  },
  {
    label: "Martinique 🇲🇶 (+596)",
    value: "596",
    mask: "999-999",
    min: 7,
    max: 7,
  },
  {
    label: "Mauritania 🇲🇷 (+222)",
    value: "222",
    mask: "99-99-99-99",
    min: 8,
    max: 8,
  },
  {
    label: "Mauritius 🇲🇺 (+230)",
    value: "230",
    mask: "99-99-99-99",
    min: 8,
    max: 8,
  },
  // {
  //   label: "Mayotte 🇾🇹 (+262)",
  //   value: "262",
  //   mask: "99-99-99",
  //   min: 7,
  //   max: 7,
  // },
  {
    label: "Mexico 🇲🇽 (+52)",
    value: "52",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Micronesia, Federated States of Micronesia 🇫🇲 (+691)",
    value: "691",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Moldova 🇲🇩 (+373)",
    value: "373",
    mask: "373-999-999-99",
    min: 9,
    max: 12,
  },
  {
    label: "Monaco 🇲🇨 (+377)",
    value: "377",
    mask: "377-99-99-99",
    min: 8,
    max: 10,
  },
  {
    label: "Mongolia 🇲🇳 (+976)",
    value: "976",
    mask: "976-999-999",
    min: 9,
    max: 10,
  },
  {
    label: "Montenegro 🇲🇪 (+382)",
    value: "382",
    mask: "382-999-999",
    min: 9,
    max: 10,
  },
  {
    label: "Montserrat 🇲🇸 (+1664)",
    value: "1664",
    mask: "1664-999-9999",
    min: 10,
    max: 11,
  },
  {
    label: "Morocco 🇲🇦 (+212)",
    value: "212",
    mask: "212-99-99-99-99",
    min: 9,
    max: 12,
  },
  {
    label: "Mozambique 🇲🇿 (+258)",
    value: "258",
    mask: "258-99-99-99",
    min: 9,
    max: 10,
  },
  {
    label: "Myanmar 🇲🇲 (+95)",
    value: "95",
    mask: "95-999-999-99",
    min: 9,
    max: 10,
  },
  {
    label: "Namibia 🇳🇦 (+264)",
    value: "264",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Nauru 🇳🇷 (+674)",
    value: "674",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Nepal 🇳🇵 (+977)",
    value: "977",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Netherlands 🇳🇱 (+31)",
    value: "31",
    mask: "06-9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "New Caledonia 🇳🇨 (+687)",
    value: "687",
    mask: "99-999-999",
    min: 5,
    max: 7,
  },
  {
    label: "New Zealand 🇳🇿 (+64)",
    value: "64",
    mask: "021-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Nicaragua 🇳🇮 (+505)",
    value: "505",
    mask: "8888-8888",
    min: 8,
    max: 8,
  },
  {
    label: "Niger 🇳🇪 (+227)",
    value: "227",
    mask: "99-99-99-99",
    min: 7,
    max: 7,
  },
  {
    label: "Nigeria 🇳🇬 (+234)",
    value: "234",
    mask: "070-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Niue 🇳🇺 (+683)",
    value: "683",
    mask: "999-999",
    min: 6,
    max: 7,
  },
  // {
  //   label: "Norfolk Island 🇳🇫 (+672)",
  //   value: "672",
  //   mask: "3-3999-999",
  //   min: 7,
  //   max: 7,
  // },
  {
    label: "Northern Mariana Islands 🇲🇵 (+1670)",
    value: "1670",
    mask: "670-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Norway 🇳🇴 (+47)",
    value: "47",
    mask: "47-9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Oman 🇴🇲 (+968)",
    value: "968",
    mask: "968-9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Pakistan 🇵🇰 (+92)",
    value: "92",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Palau 🇵🇼 (+680)",
    value: "680",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Palestinian Territory, Occupied 🇵🇸 (+970)",
    value: "970",
    mask: "9999",
    min: 9,
    max: 9,
  },
  {
    label: "Panama 🇵🇦 (+507)",
    value: "507",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Papua New Guinea 🇵🇬 (+675)",
    value: "675",
    mask: "999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Paraguay 🇵🇾 (+595)",
    value: "595",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Peru 🇵🇪 (+51)",
    value: "51",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Philippines 🇵🇭 (+63)",
    value: "63",
    mask: "999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Pitcairn 🇵🇳 (+64)",
    value: "64",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Poland 🇵🇱 (+48)",
    value: "48",
    mask: "999-999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Portugal 🇵🇹 (+351)",
    value: "351",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Puerto Rico 🇵🇷 (+1939)",
    value: "1939",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Qatar 🇶🇦 (+974)",
    value: "974",
    mask: "999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Romania 🇷🇴 (+40)",
    value: "40",
    mask: "999-999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Russia 🇷🇺 (+7)",
    value: "7",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Rwanda 🇷🇼 (+250)",
    value: "250",
    mask: "999-9999",
    min: 9,
  },
  {
    label: "Reunion 🇷🇪 (+262)",
    value: "262",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saint Barthelemy 🇧🇱 (+590)",
    value: "590",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha 🇸🇭 (+290)",
    value: "290",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saint Kitts and Nevis 🇰🇳 (+1869)",
    value: "1869",
    mask: "9999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Saint Lucia 🇱🇨 (+1758)",
    value: "1758",
    mask: "9999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Saint Martin 🇲🇫 (+590)",
    value: "590",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saint Pierre and Miquelon 🇵🇲 (+508)",
    value: "508",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saint Vincent and the Grenadines 🇻🇨 (+1784)",
    value: "1784",
    mask: "9999-9999",
    min: 11,
    max: 11,
  },
  {
    label: "Samoa 🇼🇸 (+685)",
    value: "685",
    mask: "999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "San Marino 🇸🇲 (+378)",
    value: "378",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Sao Tome and Principe 🇸🇹 (+239)",
    value: "239",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Saudi Arabia 🇸🇦 (+966)",
    value: "966",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Senegal 🇸🇳 (+221)",
    value: "221",
    mask: "9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Serbia 🇷🇸 (+381)",
    value: "381",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Seychelles 🇸🇨 (+248)",
    value: "248",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Sierra Leone 🇸🇱 (+232)",
    value: "232",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Singapore 🇸🇬 (+65)",
    value: "65",
    mask: "9999-9999",
    min: 8,
    max: 8,
  },
  {
    label: "Slovakia 🇸🇰 (+421)",
    value: "421",
    mask: "999-999-999",
    min: 9,
    max: 9,
  },
  {
    label: "Slovenia 🇸🇮 (+386)",
    value: "386",
    mask: "999-999-999",
    min: 8,
    max: 8,
  },
  {
    label: "Solomon Islands 🇸🇧 (+677)",
    value: "677",
    mask: "999-999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "Somalia 🇸🇴 (+252)",
    value: "252",
    mask: "999-999-999",
    min: 10,
    max: 10,
  },
  {
    label: "South Africa 🇿🇦 (+27)",
    value: "27",
    mask: "999-999-9999",
    min: 9,
    max: 9,
  },
  {
    label: "South Sudan 🇸🇸 (+211)",
    value: "211",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "South Georgia and the South Sandwich Islands 🇬🇸 (+500)",
    value: "500",
    mask: "9999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Spain 🇪🇸 (+34)",
    value: "34",
    mask: "999-999-999",
    min: 9,
    max: 9,
  },
  {
    label: "Sri Lanka 🇱🇰 (+94)",
    value: "94",
    mask: "999-999-999",
    min: 10,
    max: 10,
  },
  {
    label: "Sudan 🇸🇩 (+249)",
    value: "249",
    mask: "999-999-999",
    min: 10,
    max: 10,
  },
  {
    label: "Suriname 🇸🇷 (+597)",
    value: "597",
    mask: "999-999-999",
    min: 10,
    max: 10,
  },
  {
    label: "Svalbard and Jan Mayen 🇸🇯 (+47)",
    value: "47",
    mask: "999-999-999",
    min: 8,
    max: 8,
  },
  {
    label: "Swaziland 🇸🇿 (+268)",
    value: "268",
    mask: "999-999-999",
    min: 9,
    max: 9,
  },
  {
    label: "Sweden 🇸🇪 (+46)",
    value: "46",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Switzerland 🇨🇭 (+41)",
    value: "41",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Syrian Arab Republic 🇸🇾 (+963)",
    value: "963",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Taiwan 🇹🇼 (+886)",
    value: "886",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tajikistan 🇹🇯 (+992)",
    value: "992",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tanzania, United Republic of Tanzania 🇹🇿 (+255)",
    value: "255",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Thailand 🇹🇭 (+66)",
    value: "66",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Timor-Leste 🇹🇱 (+670)",
    value: "670",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Togo 🇹🇬 (+228)",
    value: "228",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tokelau 🇹🇰 (+690)",
    value: "690",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tonga 🇹🇴 (+676)",
    value: "676",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Trinidad and Tobago 🇹🇹 (+1868)",
    value: "1868",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tunisia 🇹🇳 (+216)",
    value: "216",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Turkey 🇹🇷 (+90)",
    value: "90",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Turkmenistan 🇹🇲 (+993)",
    value: "993",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Turks and Caicos Islands 🇹🇨 (+1649)",
    value: "1649",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Tuvalu 🇹🇻 (+688)",
    value: "688",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Uganda 🇺🇬 (+256)",
    value: "256",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Ukraine 🇺🇦 (+380)",
    value: "380",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "United Arab Emirates 🇦🇪 (+971)",
    value: "971",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "United Kingdom 🇬🇧 (+44)",
    value: "44",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "United States 🇺🇸 (+1)",
    value: "1",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Uruguay 🇺🇾 (+598)",
    value: "598",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Uzbekistan 🇺🇿 (+998)",
    value: "998",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Vanuatu 🇻🇺 (+678)",
    value: "678",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Venezuela, Bolivarian Republic of Venezuela 🇻🇪 (+58)",
    value: "58",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Vietnam 🇻🇳 (+84)",
    value: "84",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Virgin Islands, British 🇻🇬 (+1284)",
    value: "1284",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Virgin Islands, U.S. 🇻🇮 (+1340)",
    value: "1340",
    mask: "999-999-9999",
    min: 10,
    max: 10,
  },
  {
    label: "Wallis and Futuna 🇼🇫 (+681)",
    value: "681",
    mask: "9999",
    min: 6,
    max: 6,
  },
  {
    label: "Yemen 🇾🇪 (+967)",
    value: "967",
    mask: "9999",
    min: 9,
    max: 9,
  },
  {
    label: "Zambia 🇿🇲 (+260)",
    value: "260",
    mask: "9999",
    min: 10,
    max: 10,
  },
  {
    label: "Zimbabwe 🇿🇼 (+263)",
    value: "263",
    mask: "9999",
    min: 10,
    max: 10,
  },
];
const currencyNames = {
  AED: "United Arab Emirates Dirham",
  AFN: "Afghan Afghani",
  ALL: "Albanian Lek",
  AMD: "Armenian Dram",
  ANG: "Netherlands Antillean Guilder",
  AOA: "Angolan Kwanza",
  ARS: "Argentine Peso",
  AUD: "Australian Dollar",
  AWG: "Aruban Florin",
  AZN: "Azerbaijani Manat",
  BAM: "Bosnia-Herzegovina Convertible Mark",
  BBD: "Barbadian Dollar",
  BDT: "Bangladeshi Taka",
  BGN: "Bulgarian Lev",
  BHD: "Bahraini Dinar",
  BIF: "Burundian Franc",
  BMD: "Bermudan Dollar",
  BND: "Brunei Dollar",
  BOB: "Bolivian Boliviano",
  BRL: "Brazilian Real",
  BSD: "Bahamian Dollar",
  BTC: "Bitcoin",
  BTN: "Bhutanese Ngultrum",
  BWP: "Botswanan Pula",
  BYN: "Belarusian Ruble",
  BZD: "Belize Dollar",
  CAD: "Canadian Dollar",
  CDF: "Congolese Franc",
  CHF: "Swiss Franc",
  CLF: "Chilean Unit of Account (UF)",
  CLP: "Chilean Peso",
  CNH: "Chinese Yuan (Offshore)",
  CNY: "Chinese Yuan",
  COP: "Colombian Peso",
  CRC: "Costa Rican Colón",
  CUC: "Cuban Convertible Peso",
  CUP: "Cuban Peso",
  CVE: "Cape Verdean Escudo",
  CZK: "Czech Republic Koruna",
  DJF: "Djiboutian Franc",
  DKK: "Danish Krone",
  DOP: "Dominican Peso",
  DZD: "Algerian Dinar",
  EGP: "Egyptian Pound",
  ERN: "Eritrean Nakfa",
  ETB: "Ethiopian Birr",
  EUR: "Euro",
  FJD: "Fijian Dollar",
  FKP: "Falkland Islands Pound",
  GBP: "British Pound Sterling",
  GEL: "Georgian Lari",
  GGP: "Guernsey Pound",
  GHS: "Ghanaian Cedi",
  GIP: "Gibraltar Pound",
  GMD: "Gambian Dalasi",
  GNF: "Guinean Franc",
  GTQ: "Guatemalan Quetzal",
  GYD: "Guyanaese Dollar",
  HKD: "Hong Kong Dollar",
  HNL: "Honduran Lempira",
  HRK: "Croatian Kuna",
  HTG: "Haitian Gourde",
  HUF: "Hungarian Forint",
  IDR: "Indonesian Rupiah",
  ILS: "Israeli New Sheqel",
  IMP: "Manx pound",
  INR: "Indian Rupee",
  IQD: "Iraqi Dinar",
  IRR: "Iranian Rial",
  ISK: "Icelandic Króna",
  JEP: "Jersey Pound",
  JMD: "Jamaican Dollar",
  JOD: "Jordanian Dinar",
  JPY: "Japanese Yen",
  KES: "Kenyan Shilling",
  KGS: "Kyrgystani Som",
  KHR: "Cambodian Riel",
  KMF: "Comorian Franc",
  KPW: "North Korean Won",
  KRW: "South Korean Won",
  KWD: "Kuwaiti Dinar",
  KYD: "Cayman Islands Dollar",
  KZT: "Kazakhstani Tenge",
  LAK: "Laotian Kip",
  LBP: "Lebanese Pound",
  LKR: "Sri Lankan Rupee",
  LRD: "Liberian Dollar",
  LSL: "Lesotho Loti",
  LYD: "Libyan Dinar",
  MAD: "Moroccan Dirham",
  MDL: "Moldovan Leu",
  MGA: "Malagasy Ariary",
  MKD: "Macedonian Denar",
  MMK: "Myanma Kyat",
  MNT: "Mongolian Tugrik",
  MOP: "Macanese Pataca",
  MRU: "Mauritanian Ouguiya",
  MUR: "Mauritian Rupee",
  MVR: "Maldivian Rufiyaa",
  MWK: "Malawian Kwacha",
  MXN: "Mexican Peso",
  MYR: "Malaysian Ringgit",
  MZN: "Mozambican Metical",
  NAD: "Namibian Dollar",
  NGN: "Nigerian Naira",
  NIO: "Nicaraguan Córdoba",
  NOK: "Norwegian Krone",
  NPR: "Nepalese Rupee",
  NZD: "New Zealand Dollar",
  OMR: "Omani Rial",
  PAB: "Panamanian Balboa",
  PEN: "Peruvian Nuevo Sol",
  PGK: "Papua New Guinean Kina",
  PHP: "Philippine Peso",
  PKR: "Pakistani Rupee",
  PLN: "Polish Zloty",
  PYG: "Paraguayan Guarani",
  QAR: "Qatari Rial",
  RON: "Romanian Leu",
  RSD: "Serbian Dinar",
  RUB: "Russian Ruble",
  RWF: "Rwandan Franc",
  SAR: "Saudi Riyal",
  SBD: "Solomon Islands Dollar",
  SCR: "Seychellois Rupee",
  SDG: "Sudanese Pound",
  SEK: "Swedish Krona",
  SGD: "Singapore Dollar",
  SHP: "Saint Helena Pound",
  SLL: "Sierra Leonean Leone",
  SOS: "Somali Shilling",
  SRD: "Surinamese Dollar",
  SSP: "South Sudanese Pound",
  STD: "São Tomé and Príncipe Dobra (pre-2018)",
  STN: "São Tomé and Príncipe Dobra",
  SVC: "Salvadoran Colón",
  SYP: "Syrian Pound",
  SZL: "Swazi Lilangeni",
  THB: "Thai Baht",
  TJS: "Tajikistani Somoni",
  TMT: "Turkmenistani Manat",
  TND: "Tunisian Dinar",
  TOP: "Tongan Pa'anga",
  TRY: "Turkish Lira",
  TTD: "Trinidad and Tobago Dollar",
  TWD: "New Taiwan Dollar",
  TZS: "Tanzanian Shilling",
  UAH: "Ukrainian Hryvnia",
  UGX: "Ugandan Shilling",
  USD: "United States Dollar",
  UYU: "Uruguayan Peso",
  UZS: "Uzbekistan Som",
  VEF: "Venezuelan Bolívar Fuerte (Old)",
  VES: "Venezuelan Bolívar Soberano",
  VND: "Vietnamese Dong",
  VUV: "Vanuatu Vatu",
  WST: "Samoan Tala",
  XAF: "CFA Franc BEAC",
  XAG: "Silver Ounce",
  XAU: "Gold Ounce",
  XCD: "East Caribbean Dollar",
  XDR: "Special Drawing Rights",
  XOF: "CFA Franc BCEAO",
  XPD: "Palladium Ounce",
  XPF: "CFP Franc",
  XPT: "Platinum Ounce",
  YER: "Yemeni Rial",
  ZAR: "South African Rand",
  ZMW: "Zambian Kwacha",
  ZWL: "Zimbabwean Dollar",
};

const allEmptyCurrency = {
  INR: [],
  USD: [],
  EUR: [],
  GBP: [],
  AUD: [],
  AED: [],
  SGD: [],
  SAR: [],
  ZAR: [],
  CAD: [],
  JPY: [],
  CNY: [],
  VND: [],
  THB: [],
  KWD: [],
};

const dialCodesObj = {
  "Afghanistan 🇦🇫 (+93)": "93",
  "Åland Islands 🇦🇽 (+358)": "358",
  "Albania 🇦🇱 (+355)": "355",
  "Algeria 🇩🇿 (+213)": "213",
  "American Samoa 🇦🇸 (+1684)": "1684",
  "Andorra 🇦🇩 (+376)": "376",
  "Angola 🇦🇴 (+244)": "244",
  "Anguilla 🇦🇮 (+1264)": "1264",
  "Antarctica 🇦🇶 (+672)": "672",
  "Antigua and Barbuda 🇦🇬 (+1268)": "1268",
  "Argentina 🇦🇷 (+54)": "54",
  "Armenia 🇦🇲 (+374)": "374",
  "Aruba 🇦🇼 (+297)": "297",
  "Australia 🇦🇺 (+61)": "61",
  "Austria 🇦🇹 (+43)": "43",
  "Azerbaijan 🇦🇿 (+994)": "994",
  "Bahamas 🇧🇸 (+1242)": "1242",
  "Bahrain 🇧🇭 (+973)": "973",
  "Bangladesh 🇧🇩 (+880)": "880",
  "Barbados 🇧🇧 (+1246)": "1246",
  "Belarus 🇧🇾 (+375)": "375",
  "Belgium 🇧🇪 (+32)": "32",
  "Belize 🇧🇿 (+501)": "501",
  "Benin 🇧🇯 (+229)": "229",
  "Bermuda 🇧🇲 (+1441)": "1441",
  "Bhutan 🇧🇹 (+975)": "975",
  "Bolivia, Plurinational State of bolivia 🇧🇴 (+591)": "591",
  "Bosnia and Herzegovina 🇧🇦 (+387)": "387",
  "Botswana 🇧🇼 (+267)": "267",
  "Bouvet Island 🇧🇻 (+47)": "47",
  "Brazil 🇧🇷 (+55)": "55",
  "British Indian Ocean Territory 🇮🇴 (+246)": "246",
  "Brunei Darussalam 🇧🇳 (+673)": "673",
  "Bulgaria 🇧🇬 (+359)": "359",
  "Burkina Faso 🇧🇫 (+226)": "226",
  "Burundi 🇧🇮 (+257)": "257",
  "Cambodia 🇰🇭 (+855)": "855",
  "Cameroon 🇨🇲 (+237)": "237",
  "Canada 🇨🇦 (+1)": "1",
  "Cape Verde 🇨🇻 (+238)": "238",
  "Cayman Islands 🇰🇾 (+345)": "345",
  "Central African Republic 🇨🇫 (+236)": "236",
  "Chad 🇹🇩 (+235)": "235",
  "Chile 🇨🇱 (+56)": "56",
  "China 🇨🇳 (+86)": "86",
  "Christmas Island 🇨🇽 (+61)": "61",
  "Cocos 🇨🇨 (Keeling) Islands (+61)": "61",
  "Colombia 🇨🇴 (+57)": "57",
  "Comoros 🇰🇲 (+269)": "269",
  "Congo 🇨🇬 (+242)": "242",
  "Congo, The Democratic Republic of the Congo 🇨🇩 (+243)": "243",
  "Cook Islands 🇨🇰 (+682)": "682",
  "Costa Rica 🇨🇷 (+506)": "506",
  "Cote d'Ivoire 🇨🇮 (+225)": "225",
  "Croatia 🇭🇷 (+385)": "385",
  "Cuba 🇨🇺 (+53)": "53",
  "Cyprus 🇨🇾 (+357)": "357",
  "Czech Republic 🇨🇿 (+420)": "420",
  "Denmark 🇩🇰 (+45)": "45",
  "Djibouti 🇩🇯 (+253)": "253",
  "Dominica 🇩🇲 (+1767)": "1767",
  "Dominican Republic 🇩🇴 (+1849)": "1849",
  "Ecuador 🇪🇨 (+593)": "593",
  "Egypt 🇪🇬 (+20)": "20",
  "El Salvador 🇸🇻 (+503)": "503",
  "Equatorial Guinea 🇬🇶 (+240)": "240",
  "Eritrea 🇪🇷 (+291)": "291",
  "Estonia 🇪🇪 (+372)": "372",
  "Ethiopia 🇪🇹 (+251)": "251",
  "Falkland Islands 🇫🇰 (Malvinas) (+500)": "500",
  "Faroe Islands 🇫🇴 (+298)": "298",
  "Fiji 🇫🇯 (+679)": "679",
  "Finland 🇫🇮 (+358)": "358",
  "France 🇫🇷 (+33)": "33",
  "French Guiana 🇬🇫 (+594)": "594",
  "French Polynesia 🇵🇫 (+689)": "689",
  "French Southern Territories 🇹🇫 (+262)": "262",
  "Gabon 🇬🇦 (+241)": "241",
  "Gambia 🇬🇲 (+220)": "220",
  "Georgia 🇬🇪 (+995)": "995",
  "Germany 🇩🇪 (+49)": "49",
  "Ghana 🇬🇭 (+233)": "233",
  "Gibraltar 🇬🇮 (+350)": "350",
  "Greece 🇬🇷 (+30)": "30",
  "Greenland 🇬🇱 (+299)": "299",
  "Grenada 🇬🇩 (+1473)": "1473",
  "Guadeloupe 🇬🇵 (+590)": "590",
  "Guam 🇬🇺 (+1671)": "1671",
  "Guatemala 🇬🇹 (+502)": "502",
  "Guernsey 🇬🇬 (+44)": "44",
  "Guinea 🇬🇳 (+224)": "224",
  "Guinea-Bissau 🇬🇼 (+245)": "245",
  "Guyana 🇬🇾 (+592)": "592",
  "Haiti 🇭🇹 (+509)": "509",
  "Heard Island and Mcdonald Islands 🇭🇲 (+672)": "672",
  "Holy See 🇻🇦 (Vatican City State) (+379)": "379",
  "Honduras 🇭🇳 (+504)": "504",
  "Hong Kong 🇭🇰 (+852)": "852",
  "Hungary 🇭🇺 (+36)": "36",
  "Iceland 🇮🇸 (+354)": "354",
  "India 🇮🇳 (+91)": "91",
  "Indonesia 🇮🇩 (+62)": "62",
  "Iran, Islamic Republic of Persian Gulf 🇮🇷 (+98)": "98",
  "Iraq 🇮🇶 (+964)": "964",
  "Ireland 🇮🇪 (+353)": "353",
  "Isle of Man 🇮🇲 (+44)": "44",
  "Israel 🇮🇱 (+972)": "972",
  "Italy 🇮🇹 (+39)": "39",
  "Jamaica 🇯🇲 (+1876)": "1876",
  "Japan 🇯🇵 (+81)": "81",
  "Jersey 🇯🇪 (+44)": "44",
  "Jordan 🇯🇴 (+962)": "962",
  "Kazakhstan 🇰🇿 (+7)": "7",
  "Kenya 🇰🇪 (+254)": "254",
  "Kiribati 🇰🇮 (+686)": "686",
  "Korea, Democratic People's Republic of Korea 🇰🇵 (+850)": "850",
  "Korea, Republic of South Korea 🇰🇷 (+82)": "82",
  "Kosovo 🇽🇰 (+383)": "383",
  "Kuwait 🇰🇼 (+965)": "965",
  "Kyrgyzstan 🇰🇬 (+996)": "996",
  "Laos 🇱🇦 (+856)": "856",
  "Latvia 🇱🇻 (+371)": "371",
  "Lebanon 🇱🇧 (+961)": "961",
  "Lesotho 🇱🇸 (+266)": "266",
  "Liberia 🇱🇷 (+231)": "231",
  "Libyan Arab Jamahiriya 🇱🇾 (+218)": "218",
  "Liechtenstein 🇱🇮 (+423)": "423",
  "Lithuania 🇱🇹 (+370)": "370",
  "Luxembourg 🇱🇺 (+352)": "352",
  "Macao 🇲🇴 (+853)": "853",
  "Macedonia 🇲🇰 (+389)": "389",
  "Madagascar 🇲🇬 (+261)": "261",
  "Malawi 🇲🇼 (+265)": "265",
  "Malaysia 🇲🇾 (+60)": "60",
  "Maldives 🇲🇻 (+960)": "960",
  "Mali 🇲🇱 (+223)": "223",
  "Malta 🇲🇹 (+356)": "356",
  "Marshall Islands 🇲🇭 (+692)": "692",
  "Martinique 🇲🇶 (+596)": "596",
  "Mauritania 🇲🇷 (+222)": "222",
  "Mauritius 🇲🇺 (+230)": "230",
  "Mayotte 🇾🇹 (+262)": "262",
  "Mexico 🇲🇽 (+52)": "52",
  "Micronesia, Federated States of Micronesia 🇫🇲 (+691)": "691",
  "Moldova 🇲🇩 (+373)": "373",
  "Monaco 🇲🇨 (+377)": "377",
  "Mongolia 🇲🇳 (+976)": "976",
  "Montenegro 🇲🇪 (+382)": "382",
  "Montserrat 🇲🇸 (+1664)": "1664",
  "Morocco 🇲🇦 (+212)": "212",
  "Mozambique 🇲🇿 (+258)": "258",
  "Myanmar 🇲🇲 (+95)": "95",
  "Namibia 🇳🇦 (+264)": "264",
  "Nauru 🇳🇷 (+674)": "674",
  "Nepal 🇳🇵 (+977)": "977",
  "Netherlands 🇳🇱 (+31)": "31",
  "New Caledonia 🇳🇨 (+687)": "687",
  "New Zealand 🇳🇿 (+64)": "64",
  "Nicaragua 🇳🇮 (+505)": "505",
  "Niger 🇳🇪 (+227)": "227",
  "Nigeria 🇳🇬 (+234)": "234",
  "Niue 🇳🇺 (+683)": "683",
  "Norfolk Island 🇳🇫 (+672)": "672",
  "Northern Mariana Islands 🇲🇵 (+1670)": "1670",
  "Norway 🇳🇴 (+47)": "47",
  "Oman 🇴🇲 (+968)": "968",
  "Pakistan 🇵🇰 (+92)": "92",
  "Palau 🇵🇼 (+680)": "680",
  "Palestinian Territory, Occupied 🇵🇸 (+970)": "970",
  "Panama 🇵🇦 (+507)": "507",
  "Papua New Guinea 🇵🇬 (+675)": "675",
  "Paraguay 🇵🇾 (+595)": "595",
  "Peru 🇵🇪 (+51)": "51",
  "Philippines 🇵🇭 (+63)": "63",
  "Pitcairn 🇵🇳 (+64)": "64",
  "Poland 🇵🇱 (+48)": "48",
  "Portugal 🇵🇹 (+351)": "351",
  "Puerto Rico 🇵🇷 (+1939)": "1939",
  "Qatar 🇶🇦 (+974)": "974",
  "Romania 🇷🇴 (+40)": "40",
  "Russia 🇷🇺 (+7)": "7",
  "Rwanda 🇷🇼 (+250)": "250",
  "Reunion 🇷🇪 (+262)": "262",
  "Saint Barthelemy 🇧🇱 (+590)": "590",
  "Saint Helena, Ascension and Tristan Da Cunha 🇸🇭 (+290)": "290",
  "Saint Kitts and Nevis 🇰🇳 (+1869)": "1869",
  "Saint Lucia 🇱🇨 (+1758)": "1758",
  "Saint Martin 🇲🇫 (+590)": "590",
  "Saint Pierre and Miquelon 🇵🇲 (+508)": "508",
  "Saint Vincent and the Grenadines 🇻🇨 (+1784)": "1784",
  "Samoa 🇼🇸 (+685)": "685",
  "San Marino 🇸🇲 (+378)": "378",
  "Sao Tome and Principe 🇸🇹 (+239)": "239",
  "Saudi Arabia 🇸🇦 (+966)": "966",
  "Senegal 🇸🇳 (+221)": "221",
  "Serbia 🇷🇸 (+381)": "381",
  "Seychelles 🇸🇨 (+248)": "248",
  "Sierra Leone 🇸🇱 (+232)": "232",
  "Singapore 🇸🇬 (+65)": "65",
  "Slovakia 🇸🇰 (+421)": "421",
  "Slovenia 🇸🇮 (+386)": "386",
  "Solomon Islands 🇸🇧 (+677)": "677",
  "Somalia 🇸🇴 (+252)": "252",
  "South Africa 🇿🇦 (+27)": "27",
  "South Sudan 🇸🇸 (+211)": "211",
  "South Georgia and the South Sandwich Islands 🇬🇸 (+500)": "500",
  "Spain 🇪🇸 (+34)": "34",
  "Sri Lanka 🇱🇰 (+94)": "94",
  "Sudan 🇸🇩 (+249)": "249",
  "Suriname 🇸🇷 (+597)": "597",
  "Svalbard and Jan Mayen 🇸🇯 (+47)": "47",
  "Swaziland 🇸🇿 (+268)": "268",
  "Sweden 🇸🇪 (+46)": "46",
  "Switzerland 🇨🇭 (+41)": "41",
  "Syrian Arab Republic 🇸🇾 (+963)": "963",
  "Taiwan 🇹🇼 (+886)": "886",
  "Tajikistan 🇹🇯 (+992)": "992",
  "Tanzania, United Republic of Tanzania 🇹🇿 (+255)": "255",
  "Thailand 🇹🇭 (+66)": "66",
  "Timor-Leste 🇹🇱 (+670)": "670",
  "Togo 🇹🇬 (+228)": "228",
  "Tokelau 🇹🇰 (+690)": "690",
  "Tonga 🇹🇴 (+676)": "676",
  "Trinidad and Tobago 🇹🇹 (+1868)": "1868",
  "Tunisia 🇹🇳 (+216)": "216",
  "Turkey 🇹🇷 (+90)": "90",
  "Turkmenistan 🇹🇲 (+993)": "993",
  "Turks and Caicos Islands 🇹🇨 (+1649)": "1649",
  "Tuvalu 🇹🇻 (+688)": "688",
  "Uganda 🇺🇬 (+256)": "256",
  "Ukraine 🇺🇦 (+380)": "380",
  "United Arab Emirates 🇦🇪 (+971)": "971",
  "United Kingdom 🇬🇧 (+44)": "44",
  "United States 🇺🇸 (+1)": "1",
  "Uruguay 🇺🇾 (+598)": "598",
  "Uzbekistan 🇺🇿 (+998)": "998",
  "Vanuatu 🇻🇺 (+678)": "678",
  "Venezuela, Bolivarian Republic of Venezuela 🇻🇪 (+58)": "58",
  "Vietnam 🇻🇳 (+84)": "84",
  "Virgin Islands, British 🇻🇬 (+1284)": "1284",
  "Virgin Islands, U.S. 🇻🇮 (+1340)": "1340",
  "Wallis and Futuna 🇼🇫 (+681)": "681",
  "Yemen 🇾🇪 (+967)": "967",
  "Zambia 🇿🇲 (+260)": "260",
  "Zimbabwe 🇿🇼 (+263)": "263",
};

const updateGoogleProductPrice = async (website, token, merchantId) => {
  //fetch  all products meta key allow_for_google_merchants
  //fetch curreny
  let products = [];
  // fetch settings
  let googlePostRes = async (data) => {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/channel/google_shopping`,
        headers: { Authorization: `Basic ${token}` },
        method: "POST",
        params: {
          endpoint: "/products/batch",
        },
        data,
      });
      return res?.data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  let payload = products.map((product, index) => {
    let {
      name,
      id,
      description,
      permalink,
      sku,
      images,
      price,
      sale_price,
      stock_status,
      meta_data,
    } = product || {};
    let imageLink = images.find((obj) => obj?.src);
    let additionalImageLinks = images.filter((obj, index) => index !== 0);
    let productObj = {
      title: name,
      description,
      link: permalink,
      offerId: sku,
      // imageLink: imageLink?.src,
      channel: "online",
      price: {
        // currency: reduxProductSettings.default_currency,
        // value: price,
      },
      availability: stock_status === "outofstock" ? "out of stock" : "in stock",
      additionalImageLinks,
      contentLanguage: "en",
      // targetCountry: shipToCountries[0],
    };
    if (sale_price) {
      productObj = {
        ...productObj,
        salePrice: {
          // currency: reduxProductSettings.default_currency,
          // value: sale_price,
        },
      };
    }
    if (imageLink?.src) {
      productObj = {
        ...productObj,
        imageLink: imageLink?.src,
      };
    }
    let postObj = {
      batchId: index + 1,
      merchantId: merchantId,
      method: "insert",
      productId: id,
      product: productObj,
    };
    return postObj;
  });

  //posting in google
  await googlePostRes({ entries: payload });
};
const goldName = {
  999.99: "24KT (999.99)",
  999: "24KT (999)",
  995: "24KT (995)",
  916: "22KT (916)",
  750: "18KT (750)",
  583: "14KT (583)",
  375: "9KT (375)",
};
const silverName = {
  650: "650",
  925: "Sterling (925)",
  995: "Fine (995)",
  999: "Fine (999)",
};
const platinumName = {
  850: "850",
  900: "900",
  950: "950",
};

const workingCrm = isDev();
const sendSingleTemplate = async (payload) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    if (getIsBackendMigrated("chats")) {
      let data = await sendMessage({
        payload,
        platform: "whatsapp",
      });
      return data;
    }

    try {
      // await sleep(3000);
      const { data } = await axios({
        url: `${website}/wp-json/store/v1/social/message`,
        method: "POST",
        data: {
          platform: "whatsapp",
          payload,
        },
      });
      console.log(data);
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
function deepObjectComparison(obj1, obj2) {
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) || Array.isArray(obj2)) {
    if (
      !Array.isArray(obj1) ||
      !Array.isArray(obj2) ||
      obj1.length !== obj2.length
    ) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepObjectComparison(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepObjectComparison(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
const getVisitorsInfo = async (id) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let analyticsRes = await axios({
      url: `${website}/wp-json/store/v1/visitor_info`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: {
        id: id,
      },
    });
    if (isArrayWithValues(analyticsRes?.data?.data))
      return analyticsRes?.data?.data;
    console.log(analyticsRes?.data?.data);
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const globalColors = ["#4c77a9", "#EE9A3A", "#5388D8", "#EA7369"];
const globalColorsLight = [
  "#a0b9ff",
  "#68669a",
  "#86a6ff",
  "#5f7ee1",
  // "#48CAE4", "#90E0EF", "#ADE8F4", "#CAF0F8"
];

/**
 * The function `validateUpdatePricing` checks if the rate value for each enabled purity in the pricing
 * object is greater than zero.
 * @param pricing_obj - The `pricing_obj` parameter is an object that contains pricing information. It
 * has the following structure:
 * @param defaultCurrency - The `defaultCurrency` parameter is the default currency for pricing. It is
 * used to access the pricing object for that specific currency.
 * @returns the key of the first purity in the pricing object that has a rate less than or equal to 0.
 */
const validateUpdatePricing = (pricing_obj, defaultCurrency) => {
  console.log("call", isObjWithValues(pricing_obj), defaultCurrency);
  let pricingCurrencyObj = pricing_obj[defaultCurrency];
  let enabledPurities = pricingCurrencyObj.enabled_purities;
  let enabledPuritiesArray = Object.keys(enabledPurities ?? {}).filter(
    (key) => enabledPurities[key]
  );
  let priceObjectMain = pricingCurrencyObj[pricing_obj?.type];
  console.log(enabledPuritiesArray);
  for (const key in priceObjectMain) {
    // console.log(key);

    if (enabledPuritiesArray.includes(key)) {
      console.log(validateNumber(priceObjectMain[key].rate));
      if (validateNumber(priceObjectMain[key].rate) <= 0) return key;
    }
  }
};

const convertArrayToPercentage = (arr) => {
  // get the sum of all elements
  let sum = arr.reduce((a, b) => a + b, 0);

  // divide each element by the sum, and multiply by 100
  let percentageArr = arr.map((element) =>
    validateNumber((element / sum) * 100)
  );

  return percentageArr;
};

const addConnectionActivity = async (data) => {
  try {
    let res = await axios({
      url: `${nodeWebsite}/connectionActivities`,
      method: "POST",
      data: {
        website: mainWebsite,
        data,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

const addDashboardSession = async () => {
  let website = await getWebsite();
  let token = await getToken();
  let access_token = encryptStorage.getItem("accessToken");
  let ip = await getIp();
  let location = await fetchIPInfo(ip);
  let ua = new UAParser().getUA();
  let id = `${new Date().getTime()}_${getRandomString(6)}`;
  let user_id = await getId();

  try {
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/settings/dashboard_sessions`,
      headers: { Authorization: `Basic ${token}` },
      method: "PUT",
      data: {
        type: "dashboard_sessions",
        data: {
          [id]: {
            id,
            ua,
            location,
            ip,
            access_token,
            user_id,
            time: new Date().getTime(),
          },
        },
      },
    });
  } catch (error) {
    console.log(getAxiosError(error), "");
  }
};
const deleteDashboardSession = async (id) => {
  let website = await getWebsite();
  let token = await getToken();
  try {
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/settings/dashboard_sessions`,
      headers: { Authorization: `Basic ${token}` },
      method: "DELETE",
      data: { id },
    });
    return data;
  } catch (error) {
    console.log(getAxiosError(error), "");
  }
};
const terminateDashboardSession = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  try {
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/settings/dashboard_sessions`,
      headers: { Authorization: `Basic ${token}` },
      method: "PUT",
      data: { type: "dashboard_sessions", data: { [obj.id]: obj } },
    });
    return data;
  } catch (error) {
    console.log(getAxiosError(error), "");
  }
};

function getUAInfo(userAgent) {
  var parser = new UAParser(userAgent);
  var result = parser.getResult();
  return {
    os_name: result.os.name,
    os_version: result.os.version,
    device_model: result.device.model,
    device_type: result.device.type,
    device_vendor: result.device.vendor,
    browser_name: result.browser.name,
    browser_version: result.browser.version,
    browser_major: result.browser.major,
    browser_engine: result.engine.name,
    cpu_architecture: result.cpu.architecture,
    device_memory: result.deviceMemory,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    screen_color_depth: window.screen.colorDepth,
  };
}

const json2Xml = (json) => {
  let xml = "";

  for (const [key, value] of Object.entries(json)) {
    if (typeof value === "object" && !Array.isArray(value)) {
      xml += `<${key}>${json2Xml(value)}</${key}>`;
    } else if (Array.isArray(value)) {
      for (const item of value) {
        xml += `<${key}>${json2Xml(item)}</${key}>`;
      }
    } else {
      xml += `<${key}>${value}</${key}>`;
    }
  }

  return xml;
};

const xml2Json = (xml) => {
  let obj = {};

  if (xml.nodeType == 1) {
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    obj = xml.nodeValue;
  }

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xml2Json(item);
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          const old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xml2Json(item));
      }
    }
  }
  return obj;
};

const paramsToJson = (params) => {
  return JSON.parse(
    '{"' + decodeURI(params?.replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  );
};

const getUsernameFromEvent = (obj) => {
  if (isObjWithValues(obj))
    return obj.user_name?.trim() || obj?.user_id || "(no name)";
  return "(no name)";
};
const getLocationFromEvent = (obj) => {
  if (isObjWithValues(obj?.location)) {
    let _country, _city, _region;
    let { city, region, country } = obj.location;
    _city = city;
    _region = region;
    _country = country;
    return `${_city ? _city + ", " : ""}${_region ? _region + ", " : ""}${
      _country || ""
    }`;
  }
  return "(no location)";
};

const getPlatformIconFromUA = (ua, style = {}) => {
  let os = new UAParser(ua).getOS();

  return os && os.name === "Mac OS" ? (
    <Apple style={{ color: "#666666", ...style }} />
  ) : os.name === "Windows" ? (
    <Windows style={{ color: "#666666", ...style }} />
  ) : os.name === "Android" ? (
    <Android style={{ color: "#666666", ...style }} />
  ) : null;
};
const getDeviceIconFromUA = (ua, style) => {
  let os = new UAParser(ua).getOS();
  return os && (os.name === "Mac OS" || os.name === "Windows") ? (
    <Laptop style={{ ...style }} />
  ) : (
    <PhoneIphone style={{ ...style }} />
  );
};
const getFlagFromCountryCode = (code, style) => {
  let url = `https://flagcdn.com/w40/${code?.toLowerCase()}.png`;

  return (
    <img
      src={url}
      style={{
        // borderRadius: "50%",
        height: "15px",
        width: "22px",
        borderRadius: "10%",
      }}
    />
  );
};
const getDeviceFromUserAgent = (userAgent) => {
  let device = "Unknown";
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  } else if (/iPad/i.test(userAgent)) {
    return "iPad";
  } else {
    return "Laptop";
  }
  // if (userAgent.includes("iPhone")) {
  //   device = "iPhone";
  // } else if (userAgent.includes("iPad")) {
  //   device = "iPad";
  // } else if (userAgent.includes("Android")) {
  //   device = "Android";
  // } else if (userAgent.includes("Windows")) {
  //   device = "Windows";
  // } else if (userAgent.includes("Macintosh")) {
  //   device = "Mac";
  // }

  return device;
};
const getPlatformFromUserAgent = (userAgent) => {
  if (/(android)/i.test(userAgent)) {
    return "Android";
  } else if (/(iphone|ipad|ipod)/i.test(userAgent)) {
    return "iOS";
  } else if (/(windows phone)/i.test(userAgent)) {
    return "Windows Phone";
  } else if (/(blackberry)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (/(webos)/i.test(userAgent)) {
    return "webOS";
  } else if (/(linux)/i.test(userAgent)) {
    return "Linux";
  } else if (/(mac os x)/i.test(userAgent)) {
    return "macOS";
  } else {
    return "Unknown";
  }
};
const StackRow = ({ children, ...props }) => {
  return (
    <Stack direction={"row"} alignItems="center" {...props}>
      {children}
    </Stack>
  );
};
const ResponsiveStackRow = ({
  firstElement,
  secondElement,
  containerStyle = {},
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent={"space-between"}
      sx={{ padding: "16px 10px", width: "100%", ...containerStyle }}
      flexWrap="wrap"
    >
      <Stack direction={"row"} alignItems="center" sx={{ my: 2 }} spacing={2}>
        {firstElement}
      </Stack>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"flex-end"}
        spacing={1}
        pr={1}
        flex={1}
      >
        {secondElement}
      </Stack>
    </Stack>
  );
};

function isInRange(num, min, max) {
  return num >= min && num <= max;
}
function getTotalDecimal(value) {
  if (Math.floor(value) === value) return 0;
  return value?.toString()?.split(".")?.[1]?.length || 0;
}
function isRangeWithinRange(range1, range2) {
  return range1[0] >= range2[0] && range1[1] <= range2[1];
}

const deprecated_getAllConversations = async () => {
  let website = getWebsite();
  let token = getToken();

  try {
    const res = await axios({
      url: `${website}/wp-json/store/v1/social/conversations`,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
const getAllConversations = async () => {
  let token = getToken();
  if (!getIsBackendMigrated("chats")) return deprecated_getAllConversations();
  try {
    const res = await axios({
      url: `${backendDoamin}/chat/users`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const numberFromPhoneNumber = (phoneNumberString) =>
  phoneNumberString?.replaceAll(/\D/g, "");
const ramdomParams = {
  abc: Math.random(),
};

const apiCallFromSocket = async (data, website) => {
  try {
    let res = await axios({
      url: `${getWebSocketUrl()}/custom_axios`,
      method: "POST",
      data: {
        payload: data,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

const jweroProductKeys = [
  "name",
  "type",
  "status",
  "featured",
  "catalog_visibility",
  "description",
  "short_description",
  "sku",
  "regular_price",
  "sale_price",
  "virtual",
  "sold_individually",
  "weight",
  "dimensions",
  "reviews_allowed",
  "categories",
  "tags",
  "images",
  "attributes",
  "meta_data",
  "stock_status",
  "subcategory",
];

const shopifyProductKeys = [
  "title",
  "body_html",
  "product_type",
  "status",
  "images",
  "tags",
  "options",
  "variants",
];

const getShopifyData = async ({
  shop,
  access_token,
  params = {},
  endpoint,
  link,
}) => {
  try {
    let url = link
      ? link
      : `https://${shop}/admin/api/2023-01/${endpoint || "customers"}.json`;
    let res = await apiCallFromSocket({
      url,
      method: `GET`,
      headers: {
        "X-Shopify-Access-Token": access_token,
      },
      params: {
        limit: 250,
        ...params,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

// const shopifyProductKeys = [
//   "title",
//   "body_html",
//   "product_type",
//   "status",
//   "images",
//   "tags",
//   "options",
//   "variants",
// ];

const getCustomers = async (param = {}) => {
  try {
    let website = getWebsite();
    let token = getToken();

    const res = await axios({
      url: `${website}/wp-json/wc/v3/customers`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      ...param,
    });

    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

const cartTitlePadding = { px: 2, pt: 3, pb: 2 };

function isIPInRange(ip, start, end) {
  var startIp = ipToNumber(start);
  var endIp = ipToNumber(end);
  var ipNumber = ipToNumber(ip);

  return ipNumber >= startIp && ipNumber <= endIp;
}

function ipToNumber(ip) {
  var parts = ip.split(".");
  var sum = 0;
  for (var i = 0; i < 4; i++) {
    sum += parseInt(parts[i]) * Math.pow(256, 3 - i);
  }
  return sum;
}
const checkAdvanceAccess = async ({
  advanced_access = {},
  dashboard_access,
}) => {
  if (dashboard_access === false)
    return {
      unauthenticated: true,
      message: `Access denied. Unable to log in to dashboard.`,
    };
  if (advanced_access?.ip) {
    let currentIp = await getIp();
    if (advanced_access?.ip.includes("-")) {
      let array = advanced_access?.ip?.split("-");
      if (!isIPInRange(currentIp, array?.[0], array?.[1]))
        return {
          unauthenticated: true,
          message: `Cannot login to this IP Address.`,
        };
    } else if (currentIp !== advanced_access?.ip)
      return {
        unauthenticated: true,
        message: `Cannot login to this IP Address`,
      };
  }
  if (advanced_access?.start_day && advanced_access?.end_day) {
    let currentDay = new Date().getDay();
    let startIndex = daysArray.findIndex(
      (i) => i.value === advanced_access?.start_day
    );
    let endIndex = daysArray.findIndex(
      (i) => i.value === advanced_access?.end_day
    );
    if (startIndex > currentDay || currentDay > endIndex) {
      return {
        unauthenticated: true,
        message: `Only able to login between ${capitalizeFirstLetter(
          advanced_access?.start_day
        )} to ${capitalizeFirstLetter(advanced_access?.end_day)}`,
      };
    }
  }
  if (advanced_access?.start_login_time && advanced_access?.end_login_time) {
    if (
      !checkBetweenTime(
        formatDate(new Date(advanced_access?.start_login_time), "hh:mm:ss"),
        formatDate(new Date(advanced_access?.end_login_time), "hh:mm:ss")
      )
    )
      return {
        unauthenticated: true,
        message: `Only able to login between ${formatDate(
          new Date(advanced_access?.start_login_time),
          "hh:mm am/pm"
        )} to ${formatDate(
          new Date(advanced_access?.end_login_time),
          "hh:mm am/pm"
        )}`,
      };
  }
  return { unauthenticated: false };
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        color={"success"}
        sx={{
          // position: 'absolute',
          // left: 0,

          [`& .${circularProgressClasses?.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        style={{
          height: "50px",
          width: "50px",
        }}
      />
      {/* <ReactApexChart
        options={options}
        series={[40]}
        type="radialBar"
        height={100}
      /> */}

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          fontWeight={"800"}
          component="div"
          color="text.secondary"
        >
          {`${props.label || props.value}`}
        </Typography>
      </Box>
    </Box>
  );
}

const options = {
  chart: {
    height: 350,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "70%",
      },
    },
  },
  labels: ["Cricket"],
};

const EmptyState = ({ icon, text, button, heading, sx = {}, subtitle }) => {
  return (
    <Box
      sx={{
        my: 18,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        ...sx,
      }}
    >
      <Box sx={{ gap: "14px", height: "100%" }}>
        <Box
          sx={{
            margin: "auto",
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
          }}
        >
          {icon}
        </Box>
        <Typography
          sx={{
            fontSize: "15px",
            color: "text.secondary",
            mt: 4,
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
        <Typography
          variant="h4"
          textAlign={"center"}
          color={"text.secondary"}
          sx={{ mt: 2 }}
        >
          {heading}
        </Typography>
        {subtitle && (
          <Typography
            sx={{
              fontSize: "15px",
              color: "text.secondary",
              mt: 4,
              textAlign: "center",
            }}
          >
            {subtitle}
          </Typography>
        )}

        <Box
          sx={{
            margin: "auto",
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
            mt: 6,
          }}
        >
          {button ? button : null}
        </Box>
      </Box>
    </Box>
  );
};

const getSocialIcon = (type, style) => {
  switch (type) {
    case "whatsapp":
      return <WhatsappSvg style={style} height={20} width={20} />;

    case "facebook":
      return <Messenger style={style} height={16} width={16} />;

    case "instagram":
      return <InstagramSvg style={style} height={16} width={16} />;

    default:
      break;
  }
};
function includesEmoji(str) {
  // Use a regular expression to match any Unicode character in the "Emoji" category
  const emojiRegex = /[\p{Emoji}]/gu;

  // Use the RegExp.test() method to check if the string contains any emoji characters
  return emojiRegex.test(str);
}
function containsEmoji(text) {
  // const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}]/u;
  const emojiRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return (
    emojiRegex.test(text) &&
    text?.length <= 2 &&
    text.match(/^[a-zA-Z0-9]+$/) === null
  );
}
function returnMostOccurring(arr) {
  const obj = {};
  arr?.forEach((item) => {
    if (!obj[item]) obj[item] = 1;
    else obj[item]++;
  });

  const res = Object.keys(obj)
    .filter((a) => a?.trim())
    .sort((a, b) => b[1] - a[1]);
  return res.shift();
}
const isEmailIsIntegrated = (obj) => {
  let checkKeys = ["encryption", "host", "password", "port", "username"];
  for (const key of checkKeys) {
    if (!obj?.[key]?.trim()) return false;
  }
  return true;
};

const createObjFromMapping = ({
  mapping: array = [],
  payload: destobj = {},
  inverse = false,
}) => {
  const getPropertyValue = (obj, keyArray) => {
    let value;
    let temp;

    let { key, data_type, array_obj_key, index } = keyArray[0];

    if (data_type === "array") {
      let array = obj?.[key];
      value = array;
      if (keyArray[1]) {
        let { key, data_type, array_obj_key, array_obj_value, object_key } =
          keyArray[1];
        if (!data_type || data_type === "string") {
          if (index) {
            value = array?.[index]?.[key];
            // if (value) value = value[keyArray[0]?.array_obj_value];
          } else {
            value = array?.find((i) => i?.[keyArray[0]?.array_obj_key] == key);
            if (value) value = value[keyArray[0]?.array_obj_value];
          }
        }
        if (data_type === "object") {
          value = array?.find((i) => i[keyArray[0]?.array_obj_key] == key);
          if (value) value = value[keyArray[0]?.array_obj_value][object_key];
        }
      }
    } else if (data_type === "object") {
      let object = obj?.[key];
      value = object;
      if (keyArray[1]) {
        let { key, data_type, array_obj_key } = keyArray[1];
        if (data_type === "object") {
          value = object[key];
        }
        if (!data_type || data_type === "string") {
          value = object?.[key];
          if (value) value = value;
        }
        if (keyArray[2]) {
          let { key, data_type, array_obj_key } = keyArray[2];

          if (!data_type) {
            value = value?.[key];
          }
        }
      }
    } else if (!data_type || data_type === "string") {
      let string = obj?.[key];
      value = string;
    }

    return value;
  };

  const isObjWithValues = (obj) =>
    obj && Object.keys(obj).length > 0 && !Array.isArray(obj);

  let product = {};
  for (let i of array) {
    let value, keysArray;
    if (inverse) {
      value = getPropertyValue(destobj, i.jwero_key);
      keysArray = i.key;
    } else {
      value = getPropertyValue(destobj, i.key);
      keysArray = i.jwero_key;
    }
    let { key, data_type, array_obj_key, key_to_map, array_map, index } =
      keysArray[0];
    if (data_type === "array") {
      if (!Array.isArray(product[key])) product[key] = [];
      if (Array.isArray(value) && data_type === "array" && array_map) {
        let newArray = [];
        for (let i of value) {
          let obj = {};
          for (let map of array_map) {
            let { from, to } = map;
            obj[to] = i?.[from];
          }
          newArray.push(obj);
        }

        product[key] = newArray;
      } else {
        if (!keysArray[1]) {
          product[key] = value;
        }
      }

      if (keysArray[1]) {
        let { key, data_type, array_obj_key, object_key } = keysArray[1];
        if (!data_type || data_type === "string") {
          let objectToAdd = { [keysArray[0]?.array_obj_key]: key };
          if (!keysArray[2]) {
            objectToAdd[keysArray[0]?.array_obj_value] = value;
          }
          if (keysArray[0].hasOwnProperty("index")) {
            let obj = product[keysArray[0]?.key][index];
            obj = { ...obj, ...objectToAdd };
            product[keysArray[0]?.key][index] = obj;
          } else product[keysArray[0]?.key].push(objectToAdd);
        } else if (data_type === "object") {
          let addedArray = product[keysArray[0].key];
          let addedObj =
            addedArray?.find((i) => i[keysArray[0].array_obj_key] == key) || {};
          addedObj[keysArray[0].array_obj_key] = key;
          addedObj[keysArray[0].array_obj_value] = {
            [object_key]: value,
          };
          addedArray.push(addedObj);
          product[keysArray[0].key] = addedArray;
        }
      }
    } else if (data_type === "object") {
      if (!isObjWithValues(product?.[key])) product[key] = {};

      if (keysArray[1]) {
        let { key, data_type, array_obj_key } = keysArray[1];
        if (!data_type || data_type === "string") {
          if (!keysArray[2]) product[keysArray[0].key][key] = value;
        }
        if (data_type === "object") {
          if (keysArray[2]) {
            let { data_type } = keysArray[2];
            if (data_type === "string") {
              if (!product[keysArray[0].key][key])
                product[keysArray[0].key][key] = {};
              product[keysArray[0].key][key][keysArray[2].key] = value;
            }
          }
        }
      }
    } else {
      if (Array.isArray(value) && key_to_map && data_type === "string") {
        product[key] = value.map((i) => i[key_to_map]).join();
      } else product[key] = value;
    }
  }
  return product;
};

const setupAttribute = (attribute) => {
  // let arr = [];
  // for (const key of Object.keys(attribute)) {
  //   if (isObjWithValues(attribute?.[key])) {
  //     let obj = {
  //       jwero_key: [],
  //       key: [],
  //     };
  //     // console.log(attribute?.[key]?.their_key);
  //     obj.jwero_key = [
  //       ...(attribute?.[key]?.jwero_key || []),
  //       {
  //         key,
  //         data_type: "string",
  //       },
  //     ];
  //     console.log(attribute?.[key]?.their_key);
  //     obj.key = [
  //       ...(attribute?.[key]?.their_key || []),

  //       {
  //         key: attribute?.[key]?.key,
  //         data_type: attribute?.[key]?.data_type || "string",
  //       },
  //     ];
  //     arr.push(obj);
  //   }
  // }
  let mappingArray = [];
  for (const singleObject of attribute) {
    let mapObject = {
      jwero_key: [],
      key: [],
    };
    mapObject.jwero_key = [
      ...(singleObject?.jwero_key?.jwero_key || []),
      {
        key: singleObject?.jwero_key?.key,
        data_type: singleObject?.jwero_key?.type || "string",
      },
    ];
    mapObject.key = [
      ...(singleObject?.their_key?.their_key || []),
      {
        key: singleObject?.their_key?.key,
        data_type: singleObject?.their_key?.type || "string",
      },
    ];
    mappingArray.push(mapObject);
  }
  console.log(mappingArray);
  return mappingArray;
};

const getCRMList = async ({ fields, setLoading, params }) => {
  let website = getWebsite();
  let token = getToken();
  if (website && token) {
    setLoading && setLoading(true);
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/list/crm`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          fields,
          ...params,
        },
      });
      console.log(res?.data?.success);
      {
        setLoading && setLoading(false);
        return res?.data?.data;
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
  setLoading && setLoading(false);
};
const getDropDownValueFromObj = ({ obj, channel, from }) => {
  let igonreKeys = [
    "id",
    "date_created",
    "date_created_gmt",
    "date_modified",
    "date_modified_gmt",
  ];
  // let checkKeys = [
  //     "billing",
  //     "shipping",
  //     "metafield",
  //     "addresses",
  //     "default_address",
  //     "email_marketing_consent",
  //     "metafield",
  //     "sms_marketing_consent",
  // ];

  // let notIgoreArray = ["meta_data"];
  let woocommerce = "woocommerce";
  function isBooleanStringOrNumber(value) {
    return (
      typeof value === "boolean" ||
      typeof value === "string" ||
      typeof value === "number"
      // isArray(value)
    );
  }
  let droupDownArray = [];
  let customerObject = isObjWithValues(obj) ? obj : {};
  for (const key of Object.keys(customerObject)) {
    // console.log(key);
    if (igonreKeys?.includes(key)) continue;
    if (isBooleanStringOrNumber(customerObject?.[key])) {
      let pushObj = { key, type: "string" };
      if (from) {
        pushObj.from = from;
        pushObj.their_key = [
          {
            key: from,
            data_type: "object",
          },
        ];
      }
      droupDownArray.push(pushObj);

      continue;
    }
    if (isArrayWithValues(customerObject?.[key])) {
      console.log({ ...(customerObject?.[key] || []) });
      let array = customerObject?.[key]?.map((data) =>
        getDropDownValueFromObj({
          obj: data,
          channel,
          from: key,
        })
      );

      droupDownArray = [...droupDownArray, ...(array || [])];
    }
    if (isObjWithValues(customerObject?.[key])) {
      let array = getDropDownValueFromObj({
        obj: customerObject?.[key],
        channel,
        from: key,
      });
      droupDownArray = [...droupDownArray, ...(array || [])];
      continue;
    }
    if (key === "meta_data") {
      let array = customerObject?.[key]?.map((obj) => ({
        key: obj?.key,
        from: "meta_data",
        type: typeof obj?.value || "string",

        their_key: [
          {
            key: "meta_data",
            data_type: "array",
            array_obj_key: "key",
            array_obj_value: "value",
          },
        ],
      }));
      droupDownArray = [...droupDownArray, ...(array || [])];
    }

    // if (
    //     notIgoreArray?.at(obj?.key) &&
    //     isArrayWithValues(customerObject?.[key])
    // ) {
    //     if (channel === woocommerce) {
    //         if (key === "meta_data") {
    //             let array = customerObject?.[key]?.map((obj) => ({
    //                 key: obj?.key,
    //                 from: "meta_data",
    //                 their_key: [{
    //                     key: "meta_data",
    //                     data_type: 'array',
    //                 }]
    //             }));
    //             droupDownArray = [...droupDownArray, ...(array || [])];
    //         }
    //     }
    //     continue;
    // }
  }
  return droupDownArray;
};

const deprecated_settingsEndpoint = async ({
  endpoint,
  method = "GET",
  data,
  params,
  useApiKey,
}) => {
  let website = getWebsite();
  let token = getToken();
  try {
    let requestObj = {
      url: `${website}/wp-json/store/v1/settings/${endpoint}`,
      params,
      method,
    };

    if (token) requestObj.headers = { Authorization: `Basic ${token}` };
    if (!token) requestObj.headers = { apikey };

    if (data) requestObj.data = { data, type: endpoint };
    let { data: res } = await axios(requestObj);
    return res?.data;
  } catch (error) {
    console.log(error, "settings endpoint error");
    return { success: false };
  }
};

const addFileAws3 = async ({ image, params }) => {
  try {
    image = new File([image], image.name);
    const formData = new FormData();
    formData.append("file", image, image.name);
    console.log(params);
    let res = await backendApiCall({
      endpoint: `${backendApiEndpoints?.media}`,
      method: "POST",
      data: formData,
      params,
    });
    return res?.data;
  } catch (error) {}
};

const settingsEndpoint = async ({
  endpoint,
  method = "GET",
  data,
  params,
  useApiKey,
}) => {
  // if (!getIsBackendMigrated("chats"))
  if (true)
    return await deprecated_settingsEndpoint({
      endpoint,
      method,
      data,
      params,
      useApiKey,
    });

  let token = getToken();
  try {
    let requestObj = {
      url: `${backendDoamin}/settings/${endpoint}`,
      params,
      method,
    };

    if (token) requestObj.headers = { Authorization: `Bearer ${token}` };
    if (!token) requestObj.headers = { apikey };

    if (data) requestObj.data = { data, type: endpoint };
    let { data: res } = await axios(requestObj);
    return res?.data;
  } catch (error) {
    console.log(error, "settings endpoint error");
    return { success: false };
  }
};
function checkSamePlatform(arr) {
  if (arr?.length === 0) {
    return "all";
  }

  const platform = arr?.[0]?.platform;

  for (let i = 1; i < arr?.length; i++) {
    if (arr?.[i]?.platform !== platform) {
      return false;
    }
  }

  return platform;
}
const backendApiCall = async ({
  endpoint,
  method = "GET",
  data,
  params,
  token: _token,
  headers,
  extractDataFromResponse,
}) => {
  let token = getAuthToken();
  try {
    let requestObj = {
      url: `${backendDoamin}/${endpoint}`,
      params,
      method,
    };

    if (token || _token)
      requestObj.headers = { Authorization: `Bearer ${_token || token}` };
    // if (headers) requestObj.headers = headers

    if (data) requestObj.data = data;
    let { data: res } = await axios(requestObj);
    return extractDataFromResponse ? res?.data : res;
  } catch (error) {
    let err = getAxiosError(error);
    return { success: false, error: true, ...(err || {}) };
  }
};
const websocketApiCall = async ({
  endpoint,
  method = "GET",
  data,
  params,
  token: _token,
  headers,
  extractDataFromResponse,
}) => {
  let token = getToken();
  try {
    let requestObj = {
      url: `${websocket_doamin}/${endpoint}`,
      params,
      method,
    };

    if (token || _token)
      requestObj.headers = { Authorization: `Basic ${_token || token}` };
    else requestObj.headers = { apikey };

    // if (headers) requestObj.headers = headers

    if (data) requestObj.data = data;
    let { data: res } = await axios(requestObj);
    return extractDataFromResponse ? res?.data : res;
  } catch (error) {
    let err = getAxiosError(error);
    console.log(getAxiosError(error), "backend endpoint error");
    return { success: false, error: true, ...(err || {}) };
  }
};

const settingsEndpointWithoutCreds = async ({
  endpoint,
  method = "GET",
  data,
  params,
  website,
}) => {
  try {
    let requestObj = {
      url: `${website}/wp-json/store/v1/settings/${endpoint}`,
      params,
      method,
    };

    requestObj.headers = { apikey };

    if (data) requestObj.data = { data, type: endpoint };
    let { data: res } = await axios(requestObj);
    return res?.data;
  } catch (error) {
    console.log(error, "settings endpoint error");
    return { success: false };
  }
};

const shopifyProductsMappingArray = [
  {
    jwero_key: [
      {
        key: "images",
        data_type: "array",
        array_obj_key: "src",
        array_obj_value: "src",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
    key: [
      {
        key: "images",
        data_type: "array",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "title",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "tags",
        data_type: "array",
        array_obj_key: "name",
        array_obj_value: "name",
      },
    ],
    key: [
      {
        key: "tags",
        data_type: "string",
        key_to_map: "name",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "description",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "body_html",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "sku",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "sku",
        array_obj_value: "sku",
        index: 0,
      },

      {
        key: "sku",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "price",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "price",
        array_obj_value: "price",
        index: 0,
      },

      {
        key: "price",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "totalPrice",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "compare_at_price",
        array_obj_value: "compare_at_price",
        index: 0,
      },
      {
        key: "compare_at_price",
        data_type: "string",
      },
    ],
  },
];
const defaultWoocommerceCustomerMapping = [
  {
    jwero_key: [
      {
        key: "first_name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "first_name",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "last_name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "last_name",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "email",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "email",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "phone",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "phone",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "state",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "state",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_1",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_1",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_2",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_2",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "city",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "city",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "postcode",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "postcode",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "country",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "country",
        data_type: "string",
      },
    ],
  },
  // {
  //   jwero_key: [
  //     {
  //       key: "meta_data",
  //       data_type: "array",
  //       array_obj_key: "key",
  //       array_obj_value: "value",
  //     },
  //     {
  //       key: "total_purchase",
  //       data_type: "string",
  //     },
  //   ],
  //   key: [
  //     {
  //       key: "total_spent",
  //       data_type: "string",
  //     },
  //   ],
  // },
];
const defaultShopifyCustomerMapping = [
  {
    jwero_key: [
      {
        key: "first_name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "first_name",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "last_name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "last_name",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "email",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "email",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "phone",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "phone",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "state",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "country_code",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_1",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "address1",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "address_Line_2",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "address2",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "city",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "city",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "postcode",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "zip",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "billing",
        data_type: "object",
      },
      {
        key: "country",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "default_address",
        data_type: "object",
      },
      {
        key: "country",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
      },
      {
        key: "total_purchase",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "total_spent",
        data_type: "string",
      },
    ],
  },
];

const ebayProductMapping = [
  {
    key: [{ key: "MessageID", data_type: "string" }],
    jwero_key: [
      {
        key: "id",
        data_type: "string",
      },
    ],
  },

  {
    key: [
      { key: "Item", data_type: "object" },
      { key: "Title", data_type: "string" },
    ],
    jwero_key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
  },
  {
    key: [
      { key: "Item", data_type: "object" },
      { key: "Description", data_type: "string" },
    ],
    jwero_key: [
      {
        key: "short_description",
        data_type: "string",
      },
    ],
  },
  {
    key: [
      { key: "Item", data_type: "object" },
      { key: "Quantity", data_type: "string" },
    ],
    jwero_key: [
      {
        key: "stock_quantity",
        data_type: "string",
      },
    ],
  },

  {
    key: [
      { key: "Item", data_type: "object" },
      { key: "PictureDetails", data_type: "object" },
      { key: "PictureURL", data_type: "string" },
    ],
    jwero_key: [
      {
        key: "images",
        data_type: "array",
        array_obj_key: "src",
        array_obj_value: "src",
      },
      {
        key: "src",
        data_type: "string",
      },
    ],
  },
  {
    key: [
      { key: "Item", data_type: "object" },
      { key: "DispatchTimeMax", data_type: "string" },
    ],
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
      },
      {
        key: "product_shipping",
        data_type: "object",
        object_key: "min",
      },
    ],
  },
];

const textFeildRadius = {
  ".MuiInputBase-root": {
    borderRadius: "10px",
  },
};
const dashboardCardSx = { borderRadius: "15px", height: "100%" };

const DynamicPopover = ({ id, open, anchorEl, handleClose, content }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,

        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "16px",
          width: 300,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            borderRadius: 8,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {content}
    </Popover>
  );
};

const ConfimPopup = ({
  open,
  handleClose,
  heading,
  subtitle,
  button,
  content,
  closeLabel,
  dialogProps = {},
  ...other
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose && handleClose()}
      aria-labelledby="responsive-dialog-title"
      sx={{ ...dialogStyle }}
      {...dialogProps}
    >
      {heading ? (
        <DialogTitle id="responsive-dialog-title">{heading}</DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose && handleClose()}>
          {closeLabel || "Cancel"}
        </Button>
        {button}
      </DialogActions>
    </Dialog>
  );
};

let amazonMarketplaceIds = [
  {
    country: "Canada",
    marketplace_id: "A2EUQ1WTGCTBG2",
    country_code: "CA",
    endpoint: "https://sellingpartnerapi-na.amazon.com",
    region: "us-east-1",
  },
  {
    country: "United States of America",
    marketplace_id: "ATVPDKIKX0DER",
    country_code: "US",
    endpoint: "https://sellingpartnerapi-na.amazon.com",
    region: "us-east-1",
  },
  {
    country: "Mexico",
    marketplace_id: "A1AM78C64UM0Y8",
    country_code: "MX",
    endpoint: "https://sellingpartnerapi-na.amazon.com",
    region: "us-east-1",
  },
  {
    country: "Brazil",
    marketplace_id: "A2Q3Y263D00KWC",
    country_code: "BR",
    endpoint: "https://sellingpartnerapi-na.amazon.com",
    region: "us-east-1",
  },
  {
    country: "Spain",
    marketplace_id: "A1RKKUPIHCS9HS",
    country_code: "ES",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "United Kingdom",
    marketplace_id: "A1F83G8C2ARO7P",
    country_code: "UK",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "France",
    marketplace_id: "A13V1IB3VIYZZH",
    country_code: "FR",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Belgium",
    marketplace_id: "AMEN7PMS3EDWL",
    country_code: "BE",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Netherlands",
    marketplace_id: "A1805IZSGTT6HS",
    country_code: "NL",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Germany",
    marketplace_id: "A1PA6795UKMFR9",
    country_code: "DE",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Italy",
    marketplace_id: "APJ6JRA9NG5V4",
    country_code: "IT",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Sweden",
    marketplace_id: "A2NODRKZP88ZB9",
    country_code: "SE",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Poland",
    marketplace_id: "A1C3SOZRARQ6R3",
    country_code: "PL",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Egypt",
    marketplace_id: "ARBP9OOSHTCHU",
    country_code: "EG",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },

  {
    country: "Turkey",
    marketplace_id: "A33AVAJ2PDY3EV",
    country_code: "TR",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Saudi Arabia",
    marketplace_id: "A17E79C6D8DWNP",
    country_code: "SA",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "United Arab Emirates",
    marketplace_id: "A2VIGQ35RCS4UG",
    country_code: "AE",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "India",
    marketplace_id: "A21TJRUUN4KGV",
    country_code: "IN",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Singapore",
    marketplace_id: "A19VAU5U5O7RUS",
    country_code: "SG",
    endpoint: "https://sellingpartnerapi-fe.amazon.com",
    region: "us-west-2",
  },
  {
    country: "Australia",
    marketplace_id: "A39IBJ37TRP1C6",
    country_code: "AU",
    endpoint: "https://sellingpartnerapi-fe.amazon.com",
    region: "us-west-2",
  },
  {
    country: "Japan",
    marketplace_id: "A1VC38T7YXB528",
    country_code: "JP",
    endpoint: "https://sellingpartnerapi-fe.amazon.com",
    region: "us-west-2",
  },
  {
    country: "Spain",
    marketplace_id: "A1RKKUPIHCS9HS",
    country_code: "ES",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "United Kingdom",
    marketplace_id: "A1F83G8C2ARO7P",
    country_code: "UK",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "France",
    marketplace_id: "A13V1IB3VIYZZH",
    country_code: "FR",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
  {
    country: "Belgium",
    marketplace_id: "AMEN7PMS3EDWL",
    country_code: "BE",
    endpoint: "https://sellingpartnerapi-eu.amazon.com",
    region: "eu-west-1",
  },
];

function withinLast24Hours(time) {
  // Get the current time in milliseconds
  const now = Date.now();

  // Calculate the difference between the current time and the given time
  const diff = now - time;

  // Check if the difference is less than or equal to 24 hours (in milliseconds)
  const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
  return diff <= twentyFourHoursInMs;
}

const getTodaysEmailSent = (historyObj) => {
  if (!isObjWithValues(historyObj)) return 0;
  let todaysLength = 0;
  for (let obj of Object.values(historyObj)) {
    let { start_time, sent_info } = obj;
    let isWithin24Hours = withinLast24Hours(start_time);
    if (isWithin24Hours) todaysLength += sent_info?.success?.length || 0;
  }
  return todaysLength;
};

const addApiCalls = async ({ name, calls, dispatch }) => {
  let res = await settingsEndpoint({
    endpoint: "api_calls",
  });
  let total = res?.total || 0;
  total = total + calls;
  let totalNameCalls = res?.[`total_${name}`] || 0;
  let updateRes = await settingsEndpoint({
    endpoint: "api_calls",
    method: "PUT",
    data: {
      [`${name}_${getTimeInMilliSeconds()}`]: {
        calls,
      },
      total: total,
      [`total_${name}`]: totalNameCalls + calls,
    },
  });
  if (dispatch)
    dispatch(
      editSettings({
        apiCalls: updateRes,
      })
    );
};

const campaignEmailLength = 500;

const getAllCRMFields = async ({ fields }) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/list/crm`,
        params: { fields },
        // headers: { Authorization: `Basic ${token}` },
        headers: { apikey },
      });
      if (data?.success) return data?.data;
    } catch (error) {
      return false;
    }
  }
};

const updateJweroBatchProducts = async ({ params, payload }) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token)
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products/batch`,
        method: "POST",
        headers: { Authorization: `basic ${token}` },
        params,
        data: payload,
      });
      return data;
    } catch (e) {
      console.log(getAxiosError(e), "batch products error");
      return getAxiosError(e);
    }
};
const updateJweroBatchCustomers = async ({ params, payload }) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token)
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/customers/batch`,
        method: "POST",
        headers: { Authorization: `basic ${token}` },
        params,
        data: payload,
      });
      return data;
    } catch (e) {
      console.log(getAxiosError(e), "batch customers error");
      return getAxiosError(e);
    }
};
const hasProduct = (product, user_products) => user_products?.includes(product);
const hasOnlyProduct = (product, user_products) =>
  user_products?.includes(product) && user_products?.length === 1;

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num;
  }
}

const LiveDot = () => {
  return (
    <Box
      sx={{
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#007e10",
      }}
    />
  );
};

const SwitchRow = ({
  form = {},
  title,
  subtitle,
  editForm,
  value,
  loading,
  forceLoading,
}) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Box sx={{ px: 4 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          {title}
        </Typography>
        <Switch
          disabled={loading === value || forceLoading}
          onChange={(e) => {
            editForm({ [value]: e });
          }}
          checked={form[value]}
          style={{
            border: `1px solid ${form[value] ? backgroundColor : "#e8e8e8"}`,
            backgroundColor: form[value] ? backgroundColor : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

const removeDuplicatesFromArray = (array, uniqueLabel) =>
  array.filter((obj, index, self) => {
    return (
      index === self.findIndex((t) => t?.[uniqueLabel] === obj?.[uniqueLabel])
    );
  });

const createOrder = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/orders`,
        method: "POST",
        data: obj,
        headers: { Authorization: `Basic ${token}` },
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};

const sendSmsMsg91 = async (data, authkey) => {
  let res = await apiCallFromSocket({
    url: "https://control.msg91.com/api/v5/flow/",
    method: "POST",
    params: {
      ...msg91Params,
    },
    headers: {
      authkey,
    },
    data,
  });
  return res;
};

const exotelCall = async ({
  sid,
  api_key,
  api_token,
  subdomain,
  from,
  to,
  userId,
  CallerId,
}) => {
  // let sendObj = new URLSearchParams({
  //     From: "09464626007",
  //     To: "09998060495",
  //     Body: "Jwero testing"
  // })
  let bodyObj = {
    From: from,
    // From: "+919998060495",
    To: to,
    CallerId: CallerId,
    StatusCallback: `${nodeWebsite}/webhooks/exotel/${getStoreId()}-${userId}`,
    StatusCallbackContentType: "application/json",
    "StatusCallbackEvents[0]": "terminal",
  };
  let res = await apiCallFromSocket({
    // url: `https://${api_key}:${api_token}${subdomain}/v1/Accounts/${sid}/Calls/connect`,
    // url: `https://api.exotel.com/v1/Accounts/${sid}/Sms/send.json`,
    url: `https://api.exotel.com/v1/Accounts/jwero1/Calls/connect.json`,
    method: "POST",
    headers: {
      Authorization: `Basic ${btoa(api_key + ":" + api_token)}`,
    },
    data: qs.stringify(bodyObj),
  });
  return res;
};

const getSignalMsgTemplate = async (id, auth_key) => {
  try {
    let res = await apiCallFromSocket({
      url: `https://control.msg91.com/api/v5/sms/getTemplateVersions`,
      params: {
        template_id: id,

        ...msg91Params,
      },
      headers: {
        authkey: auth_key,
      },
    });
    let template = (res?.data?.data?.data || [])?.find((o) => o?.DLT_ID);

    return template;
  } catch (error) {
    console.log(error);
  }
};
const exotelSms = async ({
  sid,
  api_key,
  api_token,

  from,
  to,
  body,
  userId,
}) => {
  // let sendObj = new URLSearchParams({
  //     From: "09464626007",
  //     To: "09998060495",
  //     Body: "Jwero testing"
  // })
  let bodyObj = {
    From: from || "EXOSMS",
    // From: "+919998060495",
    To: to,

    Body: body,
    StatusCallback: `${nodeWebsite}/webhooks/exotel/${getStoreId()}-${userId}`,
  };
  let res = await apiCallFromSocket({
    url: `https://api.exotel.com/v1/Accounts/${sid}/Sms/send.json`,

    method: "POST",
    headers: {
      Authorization: `Basic ${btoa(api_key + ":" + api_token)}`,
    },
    data: qs.stringify(bodyObj),
  });
  console.log(res);
  return res;
};

const allMetalColors = [
  { label: "White", value: "white" },
  { label: "Yellow", value: "yellow" },
  { label: "Rose", value: "rose" },
  { label: "Two Tone", value: "two-tone" },
];

const allMetalPurities = [
  { label: "24KT (999.99)", value: "999.99" },
  { label: "24KT (999)", value: "999" },
  { label: "24KT (995)", value: "995" },
  { label: "22KT (916)", value: "916" },
  { label: "18KT (750)", value: "750" },
  { label: "14KT (583)", value: "583" },
  { label: "9KT (375)", value: "375" },
];

const allDiamondPurities = [
  { label: "Lab Grown", value: "lab_grown" },
  { label: "Natural", value: "natural" },
];

const checkCustomizations = (obj) => {
  if (!isObjWithValues(obj)) return;
  let { diamond, color, kt, diamond_purities, purities, colors } = obj;
  return {
    diamond: diamond == "1",
    color: color == "1",
    kt: kt == "1",
    diamondTypes: allDiamondPurities.filter((i) =>
      diamond_purities?.includes(i.value)
    ),
    metalPurities: allMetalPurities.filter((i) => purities?.includes(i.value)),
    metalColors: allMetalColors.filter((i) => colors?.includes(i.value)),
  };
};
function duplicateArrayOfObjects(arr1, arr2, key) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let arr1Copy = [...arr1];
  let arr2Copy = [...arr2];

  arr1Copy.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];

    if (keyA < keyB) {
      return -1;
    } else if (keyA > keyB) {
      return 1;
    } else {
      return 0;
    }
  });

  arr2Copy.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];

    if (keyA < keyB) {
      return -1;
    } else if (keyA > keyB) {
      return 1;
    } else {
      return 0;
    }
  });

  for (let i = 0; i < arr1Copy.length; i++) {
    if (JSON.stringify(arr1Copy[i]) !== JSON.stringify(arr2Copy[i])) {
      return false;
    }
  }

  return true;
}

const formatCallDuration = (sec) => {
  let duration = validateNumber(sec); // Duration in seconds
  let minutes = Math.floor(duration / 60);
  let seconds = duration % 60;

  let formattedDuration =
    ("00" + minutes).slice(-2) + ":" + ("00" + seconds).slice(-2);
  return formattedDuration;
};

const getLocalIP = () => {
  return new Promise((resolve, reject) => {
    window.RTCPeerConnection =
      window.RTCPeerConnection ||
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection;
    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel("");
    pc.createOffer(
      (sdp) => {
        pc.setLocalDescription(sdp);
      },
      (error) => {
        reject(error);
      }
    );
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;
        const ipAddress = ipRegex.exec(event.candidate.candidate)[0];
        resolve(ipAddress);
        pc.onicecandidate = () => {};
      }
    };
  });
};
const sendExotelSms = async ({
  sid,
  api_key,
  api_token,
  dataArray,
  sender_id,
}) => {
  try {
    let dataString = qs.stringify(
      {
        From: sender_id,
        Messages: dataArray,
      },
      { encode: false }
    );

    // console.log(dataString);
    // return;
    let res = await apiCallFromSocket(
      {
        // url: `https://api.exotel.com/v1/Accounts/${sid}/Sms/send.json`,
        url: `https://${api_key}:${api_token}@api.exotel.com/v1/Accounts/${sid}/Sms/bulksend`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        data: dataString,
      },
      "https://websockets.tanika.tech"
    );
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
    return { error: getAxiosError(error) };
  }
};

function removeTrailingSlash(url) {
  if (url?.endsWith("/")) {
    return url?.slice(0, -1);
  }
  return url;
}

const getMarketplaceIdFromCountry = (country) => {
  const marketplaceIds = [
    {
      country: "Spain",
      marketplace_id: "A1RKKUPIHCS9HS",
      country_code: "ES",
    },
    {
      country: "United Kingdom",
      marketplace_id: "A1F83G8C2ARO7P",
      country_code: "UK",
    },
    {
      country: "France",
      marketplace_id: "A13V1IB3VIYZZH",
      country_code: "FR",
    },
    {
      country: "Belgium",
      marketplace_id: "AMEN7PMS3EDWL",
      country_code: "BE",
    },
    {
      country: "Netherlands",
      marketplace_id: "A1805IZSGTT6HS",
      country_code: "NL",
    },
    {
      country: "Germany",
      marketplace_id: "A1PA6795UKMFR9",
      country_code: "DE",
    },
    {
      country: "Italy",
      marketplace_id: "APJ6JRA9NG5V4",
      country_code: "IT",
    },
    {
      country: "Sweden",
      marketplace_id: "A2NODRKZP88ZB9",
      country_code: "SE",
    },
    {
      country: "Poland",
      marketplace_id: "A1C3SOZRARQ6R3",
      country_code: "PL",
    },
    {
      country: "Egypt",
      marketplace_id: "ARBP9OOSHTCHU",
      country_code: "EG",
    },
    {
      country: "Turkey",
      marketplace_id: "A33AVAJ2PDY3EV",
      country_code: "TR",
    },
    {
      country: "Saudi Arabia",
      marketplace_id: "A17E79C6D8DWNP",
      country_code: "SA",
    },
    {
      country: "United Arab Emirates",
      marketplace_id: "A2VIGQ35RCS4UG",
      country_code: "AE",
    },
    {
      country: "India",
      marketplace_id: "A21TJRUUN4KGV",
      country_code: "IN",
    },
    {
      country: "Canada",
      marketplace_id: "A2EUQ1WTGCTBG2",
      country_code: "CA",
    },
    {
      country: "United States of America",
      marketplace_id: "ATVPDKIKX0DER",
      country_code: "US",
    },
    {
      country: "Mexico",
      marketplace_id: "A1AM78C64UM0Y8",
      country_code: "MX",
    },
    {
      country: "Brazil",
      marketplace_id: "A2Q3Y263D00KWC",
      country_code: "BR",
    },
    {
      country: "Singapore",
      marketplace_id: "A19VAU5U5O7RUS",
      country_code: "SG",
    },
    {
      country: "Australia",
      marketplace_id: "A39IBJ37TRP1C6",
      country_code: "AU",
    },
    {
      country: "Japan",
      marketplace_id: "A1VC38T7YXB528",
      country_code: "JP",
    },
  ];
  return marketplaceIds?.find(
    (i) => i.country?.toLowerCase() === country?.toLowerCase()
  )?.marketplace_id;
};

const backendApiEndpoints = {
  createUser: "users",
  settings: "settings",
  media: "media",
  uploadMedia: "media/upload",
  dashboard: "dashboard/chats",
  user_tasks: "user_tasks",
  social: "social",
  flow: "flow",
  // chat_bot: ""
};

function removeExtension(fileName) {
  return fileName.replace(/\.[^/.]+$/, "");
}

function addMonthsToDate(date, months) {
  // Clone the input date to avoid modifying the original date
  var result = new Date(date);

  // Get the current month and year
  var currentMonth = result.getMonth();
  var currentYear = result.getFullYear();

  // Calculate the new month and year
  var newMonth = (currentMonth + months) % 12;
  var yearsToAdd = Math.floor((currentMonth + months) / 12);
  var newYear = currentYear + yearsToAdd;

  // Set the new month and year
  result.setMonth(newMonth);
  result.setFullYear(newYear);

  return result;
}

function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let item of arr1) {
    let itemExistsInArr2 = arr2.some((arr2Item) =>
      isObjectEqual(item, arr2Item)
    );
    if (!itemExistsInArr2) return false;
  }

  return true;
}

function isObjectEqual(obj1, obj2) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (let key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (!arraysAreEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else if (
        typeof obj1[key] === "object" &&
        obj1[key] !== null &&
        typeof obj2[key] === "object" &&
        obj2[key] !== null
      ) {
        if (!isObjectEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}

const imageExtensions = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "ico",
];
const videoExtensions = [
  "mp4",
  "avi",
  "mov",
  "wmv",
  "flv",
  "mkv",
  "m4v",
  "webm",
  "mpeg",
  "3gp",
];
const documentExtensions = [
  "doc",
  "docx",
  "pdf",
  "txt",
  "rtf",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "csv",
];
const audioExtensions = ["mp3", "wav", "ogg", "aac", "flac", "wma", "m4a"];
function getInvalidKeys(obj, requiredKeys) {
  const invalidKeys = {};
  for (const key of requiredKeys || []) {
    if (!obj?.[key]) invalidKeys[key] = `${formatServerValue(key)} required`;
    if (key === "email" && obj.email && !isEmailValid(obj?.[key]))
      invalidKeys[key] = `Enter valid email address`;
  }

  return invalidKeys;
}
let getMediaType = (media) => {
  let fileType = media?.file_type || media?.type || media?.mime;
  if (!fileType) return "";

  // return imageExtensions?.find((i) => fileType?.includes(i))
  //   ? "image"
  //   : videoExtensions?.find((i) => fileType?.includes(i))
  //   ? "video"
  //   : documentExtensions?.find((i) => fileType?.includes(i))
  //   ? "document"
  //   : "";
  if (imageExtensions?.find((i) => fileType?.includes(i))) {
    return "image";
  } else if (videoExtensions?.find((i) => fileType?.includes(i))) {
    return "video";
  } else if (audioExtensions?.find((i) => fileType?.includes(i))) {
    return "audio";
  } else if (documentExtensions?.find((i) => fileType?.includes(i))) {
    return "document";
  } else {
    return "document";
  }
};

export {
  duplicateArrayOfObjects,
  checkCustomizations,
  createOrder,
  removeDuplicatesFromArray,
  SwitchRow,
  formatNumber,
  updateJweroBatchProducts,
  addApiCalls,
  convertArrayToPercentage,
  ArrayMerge,
  getAdminName,
  Base64,
  arrayMove,
  blobToFile,
  calculateTotalDiamondWeight,
  calculateTotalGemstoneWeight,
  capitalizeFirstLetter,
  createFilterObjFromProduct,
  getTemplateVariableObjFromCustomer,
  currencyNames,
  fetchIPInfo,
  fetchProducts,
  fetchCustomers,
  formatAMPM,
  formatDate,
  formatServerValue,
  getAxiosError,
  getItem,
  getLightenColor,
  getRandomDarkColor,
  getRandomInt,
  getRandomString,
  getRandomRangeNumbers,
  getRelativeTimeDifference,
  getRemainingTime,
  getSafe,
  getStoreName,
  getStoreUserId,
  getToken,
  getWebsite,
  goldPurities,
  getStoreId,
  isArrayWithValues,
  isEmailValid,
  isNumber,
  isObjWithValues,
  removeItem,
  reverseArrayIfRequired,
  setItem,
  sleep,
  getId,
  unformatServerValue,
  validateEmail,
  validateNumber,
  validateWeight,
  isProductIncomplete,
  getisiOS,
  getMetaValueFromProduct,
  getShortRefLink,
  dialCodes,
  getCustomer,
  createCustomer,
  updateCustomer,
  isDateInThisWeek,
  // url,
  dialogStyle,
  deletePermission,
  TabPanel,
  a11yProps,
  isDev,
  getComparator,
  stableSort,
  descendingComparator,
  dialCodesObj,
  updateProduct,
  insertInString,
  getUnixFromString,
  isArray,
  getStorePlan,
  replaceTemplateVariable,
  getMedia,
  getConversationById,
  getMigrationInfo,
  mainWebsite,
  getStoreEmail,
  isValidIPAddress,
  checkBetweenTime,
  getTimezoneTime,
  getChannelData,
  postIntegrationData,
  containsText,
  getIsAdmin,
  setupDigigoldUsers,
  isDateInThisMonth,
  getIntegrationObj,
  getGlobalRedirectURL,
  getMasterPricingHistoryObj,
  getRandomLightColor,
  getPricingdiff,
  fetchCustomerByEmail,
  getMonthDifference,
  reduceLargeText,
  getProductPrice,
  createCustomCustomer,
  numDifferentiation,
  getShortRefLinkSupplier,
  updateSupplierProduct,
  getSupplierProduct,
  commerceUpdateApi,
  updateProductsInGoogle,
  saveDatainDataBase,
  sortObjectByValues,
  sortObjectByValuesLength,
  sumupElementsAfterIndex,
  getSupplierInfo,
  addSuppliersettings,
  getSupplierProductBatch,
  updateSupplierProductBatch,
  goldName,
  silverName,
  platinumName,
  mainSocketEvents,
  devLog,
  getNameFromPlatform,
  getLastMessageFromObj,
  getGoogleEventObjByEvent,
  createGoogleCalendarEvent,
  getMainWebsite,
  fetchSettingsByOptions,
  fetchMasterSettingsByOptions,
  apikey,
  emptyCurrencies,
  jweroColorPalette,
  getJweroColorPalette,
  sectionLabel,
  primaryLabel,
  secondaryLabel,
  secondaryLabelSmall,
  StyledTableRow,
  removeWhiteSpaces,
  replaceWithBr,
  formatWhatsappMessage,
  domain,
  workingCrm,
  getUserType,
  getUserProduct,
  sendSingleTemplate,
  StyledBadge,
  getVisitorsInfo,
  globalCardStyle,
  globalColors,
  globalColorsLight,
  productViewsEvent,
  getSkuFromFbId,
  validateUpdatePricing,
  getNumberFromString,
  getTotalProductPrice,
  getInitials,
  filterCondition,
  primaryLabelLarge,
  primaryLabelSmall,
  isDateInRange,
  getDateString,
  allEmptyCurrency,
  nodeWebsite,
  addConnectionActivity,
  addDashboardSession,
  getUAInfo,
  deleteDashboardSession,
  terminateDashboardSession,
  json2Xml,
  xml2Json,
  paramsToJson,
  getUsernameFromEvent,
  getLocationFromEvent,
  getPlatformIconFromUA,
  getDeviceIconFromUA,
  getDeviceFromUserAgent,
  getPlatformFromUserAgent,
  mergeConversation,
  putTourHistory,
  ResponsiveStackRow,
  isInRange,
  getTotalDecimal,
  isRangeWithinRange,
  getFullName,
  getAllConversations,
  numberFromPhoneNumber,
  ramdomParams,
  getWooCommerceCustomersHelper,
  apiCallFromSocket,
  randomVisitorName,
  getShopifyData,
  getTaskType,
  jweroProductKeys,
  shopifyProductKeys,
  getCustomers,
  batchUpdateCustomer,
  convertToIndianNumberFormat,
  cartTitlePadding,
  isIPInRange,
  checkAdvanceAccess,
  EmptyState,
  returnMostOccurring,
  StackRow,
  getSocialIcon,
  containsEmoji,
  isEmailIsIntegrated,
  isOurWebsite,
  isEarlyAccess,
  getCustomerTotal,
  createObjFromMapping,
  setupAttribute,
  getDropDownValueFromObj,
  settingsEndpoint,
  batchCreateCustomer,
  getCustomerWidthFilter,
  defaultShopifyCustomerMapping,
  shopifyProductsMappingArray,
  getCRMList,
  defaultWoocommerceCustomerMapping,
  setUpRoutes,
  ebayProductMapping,
  hexToRgba,
  textFeildRadius,
  dashboardCardSx,
  CircularProgressWithLabel,
  createBatchProducts,
  amazonMarketplaceIds,
  getTimeInSeconds,
  getTimeInMilliSeconds,
  nodeWebsiteWithoutProtocol,
  fetchJweroCustomers,
  getTodaysEmailSent,
  campaignEmailLength,
  getAllCRMFields,
  liveSocketLinkWithoutProtocol,
  getFlagFromCountryCode,
  hasProduct,
  hasOnlyProduct,
  addMarginInPriceBreakup,
  getMarginRate,
  liveLink,
  allStockStatus,
  getDateAfterHours,
  includesEmoji,
  formatTime,
  setupJweroLegacyProduct,
  LiveDot,
  exotelCall,
  getRandomId,
  formatMessageTime,
  settingsEndpointWithoutCreds,
  formatCallDuration,
  ConfimPopup,
  localHostLink,
  exotelSms,
  findMostOpenedTimePeriod,
  DynamicPopover,
  getPromotionStepperPrice,
  calculateDiscountFromPriceChange,
  sendSmsMsg91,
  getSignalMsgTemplate,
  getLocalIP,
  convertToMilliseconds,
  onCallMsg91,
  removeTrailingSlash,
  wrapLinksWithTag,
  sendExotelSms,
  getMarketplaceIdFromCountry,
  getIsBackendMigrated,
  backendDoamin,
  backendApiCall,
  backendApiEndpoints,
  addFileAws3,
  removeExtension,
  addMonthsToDate,
  msg91Params,
  isObjectEqual,
  updateJweroBatchCustomers,
  getAuthToken,
  checkSamePlatform,
  getTenantId,
  hasAccessInPlan,
  imageExtensions,
  videoExtensions,
  documentExtensions,
  getMediaType,
  getInvalidKeys,
  getMetaObject,
  catalogQuotationLink,
  getOrders,
  getWebSocketUrl,
  getEmail,
  getValueFormIntegrations,
  formatDateDynamic,
  callSocket,
  deepObjectComparison,
  websocket_doamin,
  websocketApiCall,
  websocketLink,
  liveSocketLink,
  isTimeNHoursBefore,
};
