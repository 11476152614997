/* eslint-disable import/no-anonymous-default-export */
import { isArray, isArrayWithValues } from "../../helper";
import { APPEND_INTEGRATION } from "../actions/userActions";

const inititalState = {
  user: {},
  notifications: [],
  integrationsByProduct: {
    catalog: [
      "Google",
      "Meta",

      "Ornate",
      "JEMiSys",

      "Cashfree",
      "Razorpay",
      "PayU",
      "EaseBuzz",
      "CCAvenue",
    ],
    chats: ["Google", "Meta"],
    chats_pro: ["Google", "Meta"],
    whatsapp: ["Google", "Meta"],
    "e-commerce": [
      "Google",
      "Meta",
      "Onesignal",
      "Blue Dart",
      "BVC",
      "Ornate",
      "JEMiSys",
      "Tryb4ubuy",
      "Camweara",
      "Mirrar",

      "Cashfree",
      "Razorpay",
      "PayU",
      "EaseBuzz",
      "CCAvenue",

      "Amazon",
      "Ebay",
      "WooCommerce",
      "Shopify",
    ],
    crm: [
      "Google",
      // "Meta"
    ],
    ornate: ["Ornate"],
  },
};

const defaultIntegrations = {
  catalog: [
    "Google",
    "Meta",

    "Ornate",
    "JEMiSys",

    "Cashfree",
    "Razorpay",
    "PayU",
    "EaseBuzz",
    "CCAvenue",
  ],
  chats: ["Google", "Meta"],
  chats_pro: ["Google", "Meta"],
  whatsapp: ["Google", "Meta"],
  "e-commerce": [
    "Google",
    "Meta",

    "Onesignal",

    "Blue Dart",
    "BVC",

    "Ornate",
    "JEMiSys",

    "Tryb4ubuy",
    "Camweara",
    "Mirrar",

    "Cashfree",
    "Razorpay",
    "PayU",
    "EaseBuzz",
    "CCAvenue",

    "Amazon",
    "Ebay",
    "WooCommerce",
    "Shopify",
  ],
  crm: [
    "Google",
    "Cashfree",
    "Razorpay",
    // "PayU",
    // "EaseBuzz",
    // "CCAvenue",
    "WooCommerce",
    "Shopify",
    // "Meta"
  ],
  ornate: ["Ornate"],
};

const reducer = (state = inititalState, { type, payload }) => {
  switch (type) {
    case "SET_USER":
      let obj = { ...state, ...payload };
      // if (payload && payload.access && payload.access.access)
      //   obj.access = payload.access.access;
      // if (payload && payload.access && payload.access.assigned_customers)
      //   obj.assigned_customers = payload.access.assigned_customers;
      // if (payload && payload.access && payload.access.customerIncluded)
      //   obj.customerIncluded = payload.access.customerIncluded;
      // if (!payload) {
      //   obj.access = null;
      //   obj.assigned_customers = null;
      //   obj.customerIncluded = null;
      //   obj.user = null;
      // }
      return { ...state, user: payload, ...obj };
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: payload };
    case "USER_LOGOUT":
      return { ...state, user: {}, notifications: [] };
    case APPEND_INTEGRATION:
      if (isArrayWithValues(payload)) {
        let { user_products } = state?.store_plan || {};
        let currentState = { ...(state.integrationsByProduct || {}) };
        if (
          isArrayWithValues(payload) &&
          isArray(currentState[user_products?.[0]])
        ) {
          currentState[user_products?.[0]] =
            currentState[user_products?.[0]].concat(payload);
          currentState[user_products?.[0]] = [
            ...new Set(currentState[user_products?.[0]]),
          ];
        }
        return { ...state, integrationsByProduct: currentState };
      } else return { ...state, integrationsByProduct: defaultIntegrations };
    default:
      return state;
  }
};
export default reducer;
