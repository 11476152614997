import { onAddFilter } from "../../utils/FilterNode";
import * as actions from "../../../../../../../redux/actions/flowsActions";

export const createFilterNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  let currCount = currNode?.userConnected;

  const type = currNode?.type;

  const axis = {
    x: currNode.position.x + 200,
    y: currNode.position.y,
  };

  if (currCount === 0) {
    axis.y = currNode.position.y - 1;
  } else if (currCount === 1) {
    axis.y = currNode?.position?.y + 70;
  } else if (currCount === 2) {
    axis.y = currNode?.position?.y - 90;
  } else {
    axis.y = currNode?.position?.y - 85 * (currCount - 1);
  }

  if (type === "user") {
    axis.y = axis.y + 10;
  }

  onAddFilter(dispatch, axis, nodeId);

  dispatch({
    type: actions.UPDATE_USER_CONNECTED,
    payload: { id: nodeId, count: currCount + 1 },
  });
};
