export const getComponents = ({ template }) => {
  const result = [];

  const fileType = {
    VIDEO: "video",
    IMAGE: "image",
    DOCUMENT: "document",
  };

  for (const component of template.components) {
    const processedComponent = {
      type: component?.type,
      parameters: [],
    };

    switch (component?.type) {
      case "HEADER":
        processedComponent.parameters.push({
          type: fileType[component.format],
          [fileType[component.format]]: {
            link:
              component?.file_url?.url ||
              component?.example?.header_handle?.[0],
          },
          text: " ",
        });
        break;

      case "BODY":
        if (
          component?.example?.body_text &&
          component?.example?.body_text.length > 0
        ) {
          component?.example?.body_text[0].forEach((text) => {
            processedComponent?.parameters.push({
              type: "text",
              text: text,
            });
          });
        }
        break;

      // case "FOOTER":
      //     processedComponent.parameters.push({
      //         type: "text",
      //         text: component.text
      //     });
      //     break;

      // case "BUTTONS":
      //     if (component.buttons && component.buttons.length > 0) {
      //         processedComponent.parameters = component.buttons.map((button) => {
      //             switch (button.type) {
      //                 case "URL":
      //                     return {
      //                         type: "url",
      //                         text: button.text,
      //                         url: button.url
      //                     };
      //                 case "PHONE_NUMBER":
      //                     return {
      //                         type: "phone_number",
      //                         text: button.text,
      //                         phone_number: button.phone_number
      //                     };
      //                 case "QUICK_REPLY":
      //                     return {
      //                         type: "quick_reply",
      //                         text: button.text
      //                     };
      //                 default:
      //                     return null;
      //             }
      //         }).filter(Boolean);
      //     }
      //     break;

      default:
        continue;
    }

    result.push(processedComponent);
  }

  return result;
};

export const createTemplateForBackend = ({
  id,
  templates,
  currentTemplate,
}) => {
  const currentUnchangeTemplate = templates.find(
    (template) => template.id + "" === id + ""
  );
  const createdComponents = getComponents({ template: currentTemplate });
  const data = {
    platform: "whatsapp",
    payload: {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "918097534684",
      type: "template",
      template: {
        name: currentUnchangeTemplate.name,
        language: {
          code: currentUnchangeTemplate.language,
        },
        components: createdComponents,
      },
    },
    meta_data: {
      template: currentUnchangeTemplate,
    },
  };

  return data;
};
