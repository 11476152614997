import { onAddSms } from "../../utils/SmsNode";

export const createSmsNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);

  const axis = {
    x: currNode.position.x + 180,
    y: currNode.position.y - 1,
  };

  onAddSms(dispatch, axis, nodeId);
};
