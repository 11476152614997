import { useState, useEffect, useCallback } from "react";
import ReactFlow, { Controls, Background } from "reactflow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { types } from "./helper/nodeTypes";
import { styles } from "./helper/flowStyles";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Fade, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PanelComponent from "./components/PanelComponent";
import SidebarBot from "./sidebar-bot/index";
import SidebarUser from "./sidebar-user/index";
import SidebarUserAttachment from "./sidebar-user-attachment/index";
import SidebarQuestion from "./sidebar-questions";
import SidebarFilter from "./sidebar-filter";
import SidebarGoToStep from "./sidebar-go-to-step";
import SidebarWhatsappTemplate from "./sidebar-whatsapp-template";
// import Loader from "../../components/Loader";
// import LoadingScreenTemplate from "../../components/LoadingScreenTemplate";
import { fetchGlobalAttributes } from "./helper/fetchGlobalAttributes";
import SidebarProductCatalog from "./sidebar-product-catalog";
import SidebarEmail from "./sidebar-email";
import SidebarPushNotification from "./sidebar-push-notification";
import SidebarSms from "./sidebar-sms";
import ActionsBox from "./actions-sidebar";
import { setIntegrations } from "./helper/setIntegrations";
import { fetchElements } from "./helper/FetchElements";
import { useParams } from "react-router-dom";
import { droppableNodeObj } from "./helper/droppableNodeObj";
import {
  nodeCanBeAdded,
  nodeCanBeAddedErrorMessage,
} from "./custom-nodes/helper/nodeCanBeAdded";
import { addNode } from "./custom-nodes/helper/addNode";
import * as actions from "../../../../redux/actions/flowsActions";
import "reactflow/dist/style.css";
import "./public/css/main.css";
import SidebarDelay from "./sidebar-delay";
import { fetchSingleSettings } from "../../../../redux/actions/settingActions";

const Flow = () => {
  const defaultViewport = { x: 200, y: 400, zoom: 1 };
  const nodeTypes = types;
  const { id } = useParams();
  const dispatch = useDispatch();
  const actionsMenu = useSelector((state) => state.flow?.actionsMenu);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const nodeDraggedToId = useSelector((state) => state.flow?.nodeDraggedToId);
  const sidebarOpenUser = useSelector((state) => state.flow?.openUserSidebar);
  const sidebarOpenBot = useSelector((state) => state.flow?.openBotSidebar);
  const [viewport, setViewport] = useState(defaultViewport);
  const sidebarOpenUserAttachment = useSelector(
    (state) => state.flow?.openUserAttachmentSidebar
  );
  const sidebarOpenQuestion = useSelector(
    (state) => state.flow?.openQuestionSidebar
  );
  const sidebarOpenFilter = useSelector(
    (state) => state.flow?.openFilterSidebar
  );
  const sidebarOpenGoToStep = useSelector(
    (state) => state.flow?.openGoToStepSidebar
  );
  const sidebarOpenWhatsappTemplate = useSelector(
    (state) => state.flow?.openWhatsappTemplateSidebar
  );
  const sidebarOpenProductCatalog = useSelector(
    (state) => state.flow?.openProductCatalogSidebar
  );
  const sidebarOpenEmail = useSelector((state) => state.flow?.openEmailSidebar);
  const sidebarOpenSms = useSelector((state) => state.flow?.openSmsSidebar);
  const sidebarOpenNotification = useSelector(
    (state) => state.flow?.openNotificationSidebar
  );
  const sidebarOpenDelay = useSelector((state) => state.flow?.openDelaySidebar);
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const title = useSelector((state) => state.flow?.title);
  const loading = useSelector((state) => state.flow?.loading);
  const mode = useSelector((state) => state.flow?.mode);
  const nodeAddPopover = useSelector((state) => state.flow?.nodeAddPopover);
  nodeAddPopover;
  const [botData, setBotData] = useState([]);
  const [dragCancel, setDragCancel] = useState(false);
  const [checkSidebarOpen, setSidebarOpen] = useState(false);
  const flowStyles = {
    ...styles,
    backgroundColor: mode === "dark" ? "#22304a" : "#e2e7ed",
  };

  const bgColor =
    mode === "dark" ? "rgba(220, 220, 220, 1)" : "rgba(0,0,0, 0.7)";

  const titleClass = mode === "dark" ? "title-input" : "title-light";
  const flowSettings = useSelector((state) => state.settings?.flowSettings);
  useEffect(() => {
    if (!flowSettings)
      dispatch(
        fetchSingleSettings({
          setting_name: "flow_settings",
          reduxKey: "flowSettings",
          loadingKey: "fetchingFlowKeywords",
        })
      );
  }, [flowSettings]);
  const onNodesChange = useCallback(
    (changes) => {
      if (changes[0].id != "01") {
        dispatch({ type: actions.ONCHANGE_NODES, payload: changes });
      }
    },
    [dispatch]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      if (changes.type !== "remove") {
        dispatch({ type: actions.ONCHANGE_EDGES, payload: changes });
      }
    },
    [dispatch]
  );

  const onConnect = (params) => {
    console.log(params);
  };

  const handleTitle = (e) => {
    dispatch({ type: actions.SET_TITLE, payload: e.target.value });
  };

  const resetHandleState = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        isDragging: false,
        dragginNodeId: null,
        dragginNodeType: null,
        dragginErrorMessage: null,
        nodeDraggedToId: null,
        actionsMenu: false,
      },
    });
  };

  const handleOnDragEnd = (result) => {
    const id = result?.draggableId;
    const nodeAdded = droppableNodeObj.find((node) => node.id === id);
    const nodeDraggedTo = nodes.find((node) => node.id === nodeDraggedToId);
    const nodeType = nodeAdded?.value;

    if (!nodeAdded || !nodeDraggedTo || !id || !nodeType) {
      resetHandleState();
      return;
    }

    if (dragCancel) {
      resetHandleState();
      return;
    }

    const flag = nodeCanBeAdded({
      edges,
      currentNode: nodeDraggedTo,
      nodeAddedType: nodeType,
    });
    if (flag) {
      addNode({
        dispatch: dispatch,
        nodes,
        currentNode: nodeDraggedTo,
        addNodeType: nodeType,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { actionsMenu: false },
      });
    }

    resetHandleState();
    return;
  };

  const handleOnDragStart = (result) => {
    const id = result?.draggableId;
    const nodeAdded = droppableNodeObj.find((node) => node.id === id);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        isDragging: true,
        dragginNodeType: nodeAdded?.value,
      },
    });
  };

  const handleOnDragUpdate = (result) => {
    if (!result.destination) return;

    const id = result?.draggableId;
    const nodeAdded = droppableNodeObj.find((node) => node.id === id);
    const nodeDraggedTo = nodes.find((node) => node.id === nodeDraggedToId);
    const nodeType = nodeAdded.value;

    const flag = nodeCanBeAddedErrorMessage({
      edges,
      currentNode: nodeDraggedTo,
      nodeAddedType: nodeType,
    });

    if (flag !== "allowed") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: {
          dragginErrorMessage: {
            id: nodeDraggedTo?.id,
            message: flag,
          },
        },
      });
    }
  };

  const handleDragCancel = () => {
    setDragCancel(true);
  };

  useEffect(() => {
    dispatch({ type: actions.EDIT_ELEMENT_IN_REDUX, loaderProgress: 0 });
    fetchElements(dispatch, id);
    fetchGlobalAttributes(dispatch);
    setIntegrations(dispatch);
  }, [dispatch, id]);

  const ifSidebarOpen = useCallback(() => {
    return (
      sidebarOpenUser ||
      sidebarOpenBot ||
      sidebarOpenUserAttachment ||
      sidebarOpenQuestion ||
      sidebarOpenFilter ||
      sidebarOpenGoToStep ||
      sidebarOpenWhatsappTemplate ||
      sidebarOpenProductCatalog ||
      sidebarOpenEmail ||
      sidebarOpenSms ||
      sidebarOpenNotification ||
      sidebarOpenDelay
    );
  }, [
    sidebarOpenUser,
    sidebarOpenBot,
    sidebarOpenUserAttachment,
    sidebarOpenQuestion,
    sidebarOpenFilter,
    sidebarOpenGoToStep,
    sidebarOpenWhatsappTemplate,
    sidebarOpenProductCatalog,
    sidebarOpenEmail,
    sidebarOpenSms,
    sidebarOpenNotification,
    sidebarOpenDelay,
  ]);

  useEffect(() => {
    setSidebarOpen(ifSidebarOpen());
  }, [ifSidebarOpen]);

  return (
    <>
      {/* <Loader />
      {loading && <LoadingScreenTemplate />} */}

      {(!loading || true) && (
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
          onDragUpdate={handleOnDragUpdate}
        >
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="main-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div id="editableDiv">
                  <input
                    type="text"
                    id={titleClass}
                    onChange={handleTitle}
                    value={title}
                    maxLength={40}
                  />
                </div>

                <div
                  className={`${checkSidebarOpen ? "blur-content" : ""}`}
                  style={flowStyles}
                >
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    defaultViewport={viewport}
                    nodesDraggable={true}
                    fitView
                    proOptions={{ hideAttribution: true }}
                  >
                    <Background
                      color={bgColor}
                      size="2"
                      style={{ opacity: 0.1 }}
                      variant="dots"
                    />
                    <Controls />
                    <PanelComponent id={id} />
                  </ReactFlow>
                </div>
                {actionsMenu && !checkSidebarOpen && !nodeAddPopover && (
                  <Fade in={actionsMenu}>
                    <div
                      style={{ visibility: isDragging ? "hidden" : "visible" }}
                    >
                      <ActionsBox />
                    </div>
                  </Fade>
                )}
                {isDragging && (
                  <IconButton
                    onMouseEnter={handleDragCancel}
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "43%",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ebebeb",
                      paddingBottom: "10px 10px 15px 10px",
                      color: "#333333",
                      "&:hover": {
                        transition: "0.3s ease",
                        backgroundColor: "#fc7474",
                        height: "130px",
                        width: "130px",
                        color: "#ebebeb",
                      },
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "60px" }} />
                    <Typography
                      variant="span"
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#000",
                      }}
                    >
                      Cancel
                    </Typography>
                  </IconButton>
                )}
                {sidebarOpenUser && (
                  <Fade in={sidebarOpenUser}>
                    <div className="sidebar-user">
                      <SidebarUser />
                    </div>
                  </Fade>
                )}
                {sidebarOpenBot && (
                  <>
                    {/* <Loader /> */}
                    <Fade in={sidebarOpenBot}>
                      <div className="sidebar-bot">
                        <SidebarBot botData={botData} setBotData={setBotData} />
                      </div>
                    </Fade>
                  </>
                )}
                {sidebarOpenUserAttachment && (
                  <Fade in={sidebarOpenUserAttachment}>
                    <div className="sidebar-user-attachment">
                      <SidebarUserAttachment
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenQuestion && (
                  <Fade in={sidebarOpenQuestion}>
                    <div className="sidebar-question">
                      <SidebarQuestion
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenFilter && (
                  <Fade in={sidebarOpenFilter}>
                    <div className="sidebar-filter">
                      <SidebarFilter
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenGoToStep && (
                  <Fade in={sidebarOpenGoToStep}>
                    <div className="sidebar-go-to-step">
                      <SidebarGoToStep
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenWhatsappTemplate && (
                  <Fade in={sidebarOpenWhatsappTemplate}>
                    <div className="sidebar-whatsapp-template">
                      <SidebarWhatsappTemplate
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenProductCatalog && (
                  <Fade in={sidebarOpenProductCatalog}>
                    <div className="sidebar-product-catalog">
                      <SidebarProductCatalog
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenEmail && (
                  <Fade in={sidebarOpenEmail}>
                    <div className="sidebar-product-catalog">
                      <SidebarEmail botData={botData} setBotData={setBotData} />
                    </div>
                  </Fade>
                )}
                {sidebarOpenSms && (
                  <Fade in={sidebarOpenSms}>
                    <div className="sidebar-product-catalog">
                      <SidebarSms botData={botData} setBotData={setBotData} />
                    </div>
                  </Fade>
                )}
                {sidebarOpenNotification && (
                  <Fade in={sidebarOpenNotification}>
                    <div className="sidebar-product-catalog">
                      <SidebarPushNotification
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </div>
                  </Fade>
                )}
                {sidebarOpenDelay && (
                  <Fade in={sidebarOpenDelay}>
                    <div className="sidebar-product-catalog">
                      <SidebarDelay botData={botData} setBotData={setBotData} />
                    </div>
                  </Fade>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default Flow;
