import { Typography, TextField, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { whatsappTemplateStyles } from "../styles/whatsappTemplateStyles";

const styles = whatsappTemplateStyles;

const Header = ({ handleClose, handleCheck, setTitle, titleError, title }) => {
  return (
    <Box sx={styles.topCard}>
      <Box sx={styles.innerCard}>
        <Box sx={styles.userHeader}>
          <WhatsAppIcon />
          <Typography variant="span" style={styles.headerTitle}>
            Whatsapp Template
          </Typography>
        </Box>
        <Box sx={styles.headerIconsMainDiv}>
          <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
            <CloseIcon sx={styles.headerIcons} />
          </IconButton>
          <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
            <CheckIcon sx={styles.headerIcons} />
          </IconButton>
        </Box>
      </Box>

      <TextField
        placeholder="Type block title"
        size="small"
        style={styles.titleTextField}
        onChange={(e) => setTitle(e.target.value)}
        error={titleError}
        value={title}
        required
      />
    </Box>
  );
};

export default Header;
