import { Typography } from "@mui/material";

const PrimaryLabel = ({ children, ...props }) => {
  return (
    <Typography variant="h5" {...props}>
      {children}
    </Typography>
  );
};
const PrimaryLabelSmall = (props) => {
  let { children } = props;
  return (
    <Typography variant="h6" {...props} sx={{ fontSize: 13, ...props?.sx }}>
      {children}
    </Typography>
  );
};
const DefaultLabel = ({ children }) => {
  return <Typography variant="h6">{children}</Typography>;
};

const DefaultLabelSmall = (props) => {
  let { children } = props;
  return (
    <Typography {...props} sx={{ fontSize: 14, ...props?.sx }} variant="h6">
      {children}
    </Typography>
  );
};
const SecondaryLabel = ({ children, ...props }) => {
  return (
    <Typography
      variant="h6"
      {...props}
      sx={{ fontSize: 14, color: "text.secondary", ...props.sx }}
    >
      {children}
    </Typography>
  );
};
const SecondaryLabelSmall = ({ children, ...props }) => {
  return (
    <Typography
      // variant="subtitle2"
      {...props}
      sx={{ fontSize: 13, color: "text.secondary", ...props?.sx }}
    >
      {children}
    </Typography>
  );
};
const SecondaryLabelExtraSmall = ({ children, ...props }) => {
  return (
    <Typography
      // variant="subtitle2"
      {...props}
      sx={{ fontSize: 12, color: "#0000005e", ...props.sx }}
    >
      {children}
    </Typography>
  );
};
const ErrorLabelExtraSmall = ({ children, ...props }) => {
  return (
    <Typography
      // variant="subtitle2"
      {...props}
      sx={{ fontSize: 12, color: "error.main", ...props?.sx }}
    >
      {children}
    </Typography>
  );
};

export {
  DefaultLabel,
  PrimaryLabel,
  SecondaryLabelSmall,
  SecondaryLabel,
  PrimaryLabelSmall,
  SecondaryLabelExtraSmall,
  DefaultLabelSmall,
  ErrorLabelExtraSmall,
};
