import { SubscribeNode } from "../../utils/SubscribeNode";

export const createSubscribeNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  const type = currNode?.type;

  const axis = {
    x: currNode.position.x + 200,
    y: currNode.position.y,
  };

  if (type === "user") {
    axis.y = axis.y + 10;
  }

  SubscribeNode(dispatch, axis, nodeId);
};
