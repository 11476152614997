import React, { useState, useEffect, useMemo } from "react";
import { Handle, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NodeOptions from "../helper/NodeOptions";
import NodePopover from "../helper/NodePopover";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { whatsappTemplateStyles } from "../styles/whatsappTemplateStyles";
import "../styles/node.css";
import "../styles/node-title.css";
import { fetchWhatsappTemplates } from "../../sidebar-whatsapp-template/helper/fetchWhatsappTemplates";
import AddNodesButton from "../helper/AddNodesButton";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import AddIcon from "@mui/icons-material/Add";
import { nodeAddedOnHandle } from "../utils/nodeAddedOnHandle";

const styles = whatsappTemplateStyles;

function toSnakeCase(inputText) {
  const snakeCaseText = inputText.replace(/[\W\s]+/g, "_");
  return snakeCaseText.toLowerCase();
}

const WhatsappTemplate = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  console.log("title", title);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentAddHandle, setCurrentAddhandle] = useState(null);
  const [showAddIcons, setShowAddIcons] = useState(false);
  const currNode = nodes.find((node) => node?.id == nodeId);
  const [prevSavedState, setPrevSavedState] = useState(null);
  const staticHandles = [
    { label: "Sent", value: "whatsapp_sent_handle", type: "sent" },
    { label: "Failed", value: "whatsapp_failed_handle", type: "failed" },
  ];
  // const [handlesObj, setHandlesObj] = useState(null);

  const handleClick = (e, handlePosition) => {
    e.stopPropagation();
    // const flag = nodeAddedOnHandle({
    //   edges: edges,
    //   currentNodeId: nodeId,
    //   handleId: handlePosition,
    // });
    // if (flag) {
    //   console.log("GOING AHEAD")
    //   return;
    // }

    setCurrentAddhandle(handlePosition);
    setAnchorEl(e.currentTarget);
    setIsHovered(false);
    console.log("CLICKED HANDLE NODE", handlePosition);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMain = (e) => {
    // e.stopPropagation();
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: true });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });
    if (isDragging) {
      const currentNode = nodes.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    fetchWhatsappTemplates(dispatch);
  }, [dispatch]);

  const handlesObj = useMemo(() => {
    const data = currNode?.fieldData;

    if (data) {
      const currentSavedState = data?.currentSavedState;

      // if (prevSavedState === currentSavedState) {
      //   console.log("SAME STATE");
      //   return handlesObj; // Return the previous state to avoid unnecessary recalculation
      // }

      // setPrevSavedState(currentSavedState);

      if (currentSavedState) {
        const components = currentSavedState?.components;
        const traverseButtonComponent = components.find((comp) => {
          if (comp?.type === "BUTTONS") {
            return comp;
          }
        });
        if (!traverseButtonComponent) {
          return [...staticHandles];
        }
        const buttonComponent = traverseButtonComponent?.buttons;
        const newButtonsHandles = (buttonComponent ?? []).map((btnComp) => {
          const text = btnComp?.text;
          return {
            label: text,
            value: `whatsapp_${toSnakeCase(text)}_handle`,
            type: "whatsapp_quick_reply",
          };
        });

        const updatedHandles = [...staticHandles, ...newButtonsHandles];
        // setShowAddIcons(true)
        return updatedHandles;
      }
    }

    return null;
  }, [currNode?.fieldData, dispatch, prevSavedState, staticHandles]);

  // useEffect(() => {
  //   console.log("Effect is running with nodeId:", nodeId);
  //   const data = currNode?.fieldData;

  //   if (data) {
  //     const currentSavedState = data?.currentSavedState;

  //     if (prevSavedState == currentSavedState) {
  //       console.log("SAME STATE");
  //       return;
  //     }

  //     setPrevSavedState(currentSavedState);
  //     if (currentSavedState) {
  //       console.log(currentSavedState);
  //       const components = currentSavedState?.components;
  //       console.log("components", components);
  //       const traverseButtonComponent = components.find((comp) => {
  //         if (comp?.type === "BUTTONS") {
  //           return comp;
  //         }
  //       });
  //       const buttonComponent = traverseButtonComponent?.buttons;
  //       const newButtonsHandles = (buttonComponent ?? []).map((btnComp) => {
  //         const text = btnComp?.text;
  //         return {
  //           label: text,
  //           value: `whatsapp_${toSnakeCase(text)}_handle`,
  //         };
  //       })

  //       const updatedHandles = [...staticHandles, ...newButtonsHandles];

  //       setHandlesObj(updatedHandles);
  //       setShowAddIcons(true);
  //     }
  //   }
  // }, [currNode?.fieldData, dispatch]);

  return (
    <Box
      className="nodeContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ paddingBottom: "30px", paddingLeft: "30px" }}
    >
      <Box
        sx={{
          ...styles.mainContainer,
          width: title === undefined ? "80" : "120px",
        }}
      >
        {isHovered && !isDragging ? (
          <Box sx={{ position: "relative", right: "5px" }}>
            <NodeOptions nodeId={nodeId} nodeType="filter" />
          </Box>
        ) : (
          <Box sx={styles.titleBox}>
            <Typography variant="span" sx={styles.titleText}>
              {title || "‎"}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={styles.nodeBox}>
        <Handle type="target" position="left" style={styles.leftHandle} />

        {!title ? (
          <Box
            sx={{ ...styles.nodeBody, width: "80px", justifyContent: "center" }}
            onClick={handleMain}
          >
            <Box sx={{ ...styles.nodeInnerBox, marginRight: 0 }}>
              <WhatsAppIcon sx={styles.nodeIcon} />
              <Typography variant="body2" sx={styles.titleName}>
                Whatsapp
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <Box sx={styles.nodeInnerBox}>
              <WhatsAppIcon sx={styles.nodeIcon} />
              <Typography variant="body2" sx={styles.titleName}>
                Whatsapp
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={styles.handleBox}>
                {handlesObj &&
                  handlesObj.length > 0 &&
                  handlesObj.map((handle, index) => {
                    return (
                      <Box key={index} sx={styles.handleInnerBox}>
                        <Typography variant="body2" sx={styles.handleText}>
                          {handle?.label}
                        </Typography>
                        <AddIcon
                          sx={styles.addIcon}
                          onClick={(e) => handleClick(e, handle?.value)}
                        />
                        <Handle
                          type="source"
                          position="right"
                          id={handle?.value}
                          style={styles.rightHandle}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        )}

        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          currentAddHandle={currentAddHandle}
          handlesObj={handlesObj}
        />
      </Box>
    </Box>
  );
};

export default React.memo(WhatsappTemplate);
