import { useState } from "react";
import { Handle, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NodePopover from "../helper/NodePopover";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { fallbackNodeStyles } from "../styles/fallbackStyles";
import "../styles/node.css";

const styles = fallbackNodeStyles;

const CustomDefaultFallback = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodes = useSelector((state) => state.flow?.nodes);
  const nodeId = useNodeId();
  const title = nodes?.find((node) => node?.id === nodeId)?.title;
  const [isHovered, setIsHovered] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMain = () => {
    setOpenTooltip(!openTooltip);
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setOpenTooltip(false);
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ paddingBottom: "10px", position: "relative", right: "10px" }}
      >
        <Typography
          variant="span"
          sx={{
            fontSize: "13px",
            alignItems: "center",
            fontFamily: "Inter",
            fontWeight: 600,
            color: "#566e8a",
          }}
        >
          {title || "‎"}
        </Typography>
        {/* )} */}
      </div>

      <Box sx={styles.bottomRow}>
        <Handle
          type="target"
          position="left"
          style={{
            background: "#566d89",
            position: "relative",
            opacity: "0",
            left: "4px",
            bottom: "11px",
          }}
        />
        <Tooltip
          arrow
          title="Default fallback can't be edited."
          open={openTooltip}
          placement="left"
          componentsProps={{
            tooltip: {
              sx: {
                width: "180px",
                backgroundColor: "#797b8c",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "Inter",
                padding: "8px",
                fontWeight: 550,
              },
            },
            arrow: {
              sx: {
                color: "#797b8c",
              },
            },
          }}
        >
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                "&:hover": {
                  backgroundColor: "#797b8c",
                },
              }}
            >
              <ReplyAllIcon
                style={{
                  fontSize: "18px",
                  marginLeft: "5px",
                  color: "#ffffff",
                  marginRight: "5px",
                }}
              />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              Default fallback
            </Typography>
          </Box>
        </Tooltip>

        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <span onClick={handleClick} className="addButton">
          <AddIcon sx={styles.addIcon} />
        </span>
      </Box>
      <Handle
        type="source"
        position="right"
        style={{
          background: "#566d89",
          opacity: 0,
          position: "relative",
          left: "57px",
          top: "-45px",
        }}
      />
    </Box>
  );
};

export default CustomDefaultFallback;
