import axios from "axios";
import {
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { setGlobalToast } from "./helperActions";

export const EDIT_PAGES = "EDIT_PAGES";

export const getReviews = (params) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingReviews: true,
        },
      });
      let res = await axios({
        // url: `${website}/wp-json/wp/v2/comments`,
        url: `${website}/wp-json/wc/v3/products/reviews`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          status: "all",
        },
      });
      console.log(res.data);
      if (isArrayWithValues(res.data)) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allReviews: res.data,
          },
        });
      }
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingReviews: false,
        },
      });
    }
  };
};
export const getReplies = (params) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingReplies: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/wp/v2/comments`,
        // url: `${website}/wp-json/wc/v3/products/reviews`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          status: "all",
          type: "comment"
        },
      });
      console.log(res.data);
      if (isArrayWithValues(res.data)) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allReplies: res.data,
          },
        });
      }
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingReplies: false,
        },
      });
    }
  };
};

export const updateReview = (id, data) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products/reviews/${id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: data,
      });
      console.log(res.data);
      if (isObjWithValues(res.data) && res?.data?.id) {
        let state = getState();
        let reviews = state.pages.allReviews;
        let newAllReviews = reviews.map((obj) =>
          obj.id === id ? res.data : obj
        );
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allReviews: newAllReviews,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status updated",
            severity: "success",
          })
        );
      }
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingReviews: false,
        },
      });
    }
  };
};

export const deleteReview = (id) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products/reviews/${id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },

        method: "DELETE",
      });
      console.log(res.data);
      if (isObjWithValues(res.data) && res?.data?.deleted) {
        let state = getState();
        let reviews = state.pages.allReviews;
        let newAllReviews = reviews.filter((obj) => obj.id !== id);
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allReviews: newAllReviews,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: "Delete Successfully",
            severity: "success",
          })
        );
      }
    }
  };
};

export const getRandomBanner = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    try {
      if (website && token) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            bannerShop: [],
            fetchingBannerShop: true
          }
        });
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/banner_shop`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            force: true,
          },
        });
        console.log(res);
        if (res?.data?.type === "success") {
          dispatch({
            type: EDIT_PAGES,
            payload: {
              bannerShop: res?.data?.data,
              fetchingBannerShop: false
            },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: EDIT_PAGES,
        payload: {

          fetchingBannerShop: false
        },
      });
    }
    dispatch({
      type: EDIT_PAGES,
      payload: {

        fetchingBannerShop: false
      },
    });
  };
};
export const deleteBlogCategory = (category) => {
  return async (dispatch, getState) => {


    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wp/v2/categories/${category?.id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },

        method: "DELETE",
      });
      console.log(res.data);
      if (isObjWithValues(res.data) && res?.data?.deleted) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Delete Successfully",
            severity: "success",
          })
        );
        let state = getState()
        let allCategories = state.pages.blogsCategories
        let filtered = allCategories.filter(obj => obj.id !== category.id)
        dispatch({
          type: EDIT_PAGES,
          payload: {
            blogsCategories: filtered,
          },
        });
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     message: "Delete Failed!",
        //     severity: "error",
        //   })
        // );
        // dispatch({
        //   type: EDIT_PAGES,
        //   payload: {
        //     blogsCategories: allCategories.push(category),
        //   },
        // });
      }
    }
  }
}
export const getBlogs = (params) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingBlogs: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/wp/v2/posts`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          status: "publish,draft",
        },
      });
      console.log(res.data);
      if (isArrayWithValues(res.data)) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allBlogs: res.data,
          },
        });
      }
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingBlogs: false,
        },
      });
    }
  };
};

export const addBloginRedux = (blog) => {
  return (dispatch, getState) => {
    const state = getState();
    // let chats = state.chats.conversations;
    let preBlogs = state.pages.allBlogs || [];
    preBlogs.unshift(blog);
    dispatch({
      type: EDIT_PAGES,
      payload: {
        allBlogs: preBlogs,
      },
    });
  };
};
export const replaceBloginRedux = (blog) => {
  return (dispatch, getState) => {
    const state = getState();
    // let chats = state.chats.conversations;
    let preBlogs = state.pages.allBlogs || [];
    let newArr = preBlogs.map((obj) => (obj.id === blog.id ? blog : obj));

    dispatch({
      type: EDIT_PAGES,
      payload: {
        allBlogs: newArr,
      },
    });
  };
};
export const getBlogsCategories = (params) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingBlogsCategories: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/wp/v2/categories`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        // params:{}
      });
      console.log(res.data);
      if (isArrayWithValues(res.data)) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            blogsCategories: res.data,
          },
        });
      }
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingBlogsCategories: false,
        },
      });
    }
  };
};

export const addCategoryinRedux = (Category) => {
  return (dispatch, getState) => {
    const state = getState();
    // let chats = state.chats.conversations;
    let preCategoreis = state.pages.blogsCategories || [];
    preCategoreis.unshift(Category);
    dispatch({
      type: EDIT_PAGES,
      payload: {
        allBlogs: preCategoreis,
      },
    });
  };
};

export const deleteBlogOnRedux = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    let preBlogs = state.pages.allBlogs || [];
    const filtered = preBlogs.filter((obj) => obj.id !== id);
    dispatch({
      type: EDIT_PAGES,
      payload: {
        allBlogs: filtered,
      },
    });
  };
};

export const getAllSilder = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingSlider: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/wp/v2/slider`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { abc: "abc" }
      });
      dispatch({
        type: EDIT_PAGES,
        payload: {
          fetchingSlider: false,
        },
      });
      console.log(res.data);
      if (isArrayWithValues(res.data)) {
        dispatch({
          type: EDIT_PAGES,
          payload: {
            allSlider: res.data,
          },
        });
      }

    }
  };
};

export const deleteBannerFromRedux = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    let preSliders = state.pages.allSlider || [];
    const filtered = preSliders.filter((obj) => obj.id !== id);
    dispatch({
      type: EDIT_PAGES,
      payload: {
        allSlider: filtered,
      },
    });
  };
}

export const replaceSlidinRedux = (slid) => {
  return (dispatch, getState) => {
    const state = getState();
    // let chats = state.chats.conversations;
    let preAllSlider = state.pages.allSlider || [];
    let newArr;
    if (preAllSlider?.find(o => o.id === slid.id)) newArr = preAllSlider.map((obj) => (obj.id === slid.id ? slid : obj));
    else newArr = [...newArr, slid]

    dispatch({
      type: EDIT_PAGES,
      payload: {
        allSlider: newArr,
      },
    });
  };
}
// export const addSlidinRedux = (slid) => {
//   return (dispatch, getState) => {
//     const state = getState();
//     // let chats = state.chats.conversations;
//     let preAllSlider = state.pages.allSlider || [];
//     let newArr = [...preAllSlider, slid]

//     dispatch({
//       type: EDIT_PAGES,
//       payload: {
//         allSlider: newArr,
//       },
//     });
//   };
// }