export async function uploadDataToAwsBucket({ formData }) {

  const url = "https://api.jwero.com/media/?path=test_bot";

    const requestOptions = {
        method: "POST",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9BZXFMYXZxMEFRaSIsInBpZCI6NjAsImlhdCI6MTcwMTg2OTU0NSwiZXhwIjoxNzA0NDYxNTQ1fQ.zthehY2FMNWkrSlEQjsWr3XQmmamdooVM4UnrHv0RuY",
          Cookie:
            "__cf_bm=y9OZv7GMEfLH1hCV1T7p.UZFy82WjxJdbQ.wbN3ame0-1697615396-0-AZjYQoiz2LTRSyXiDRxuWB7tl0Zd+3Spv5vOWiT3fnqs1rAyKEnGQx1PwwZVF2rXzWLTQp5fDns5YlsTmBWXlnc=",
        },
        body: formData,

    }

    const res = await fetch(url, requestOptions)
    const json = await res.json()

    return json
}