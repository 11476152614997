import {
  Autocomplete,
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import {
  ErrorLabelExtraSmall,
  PrimaryLabelSmall,
  SecondaryLabelSmall,
} from "./Labels";
import HorizontalStack from "./HorizontalStack";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  InfoOutlined,
} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

let arrayTypes = ["checkbox"];
const CustomField = ({
  label,
  onChange = () => {},
  options = [],
  value: _value = "",
  textFieldProps,
  type = "text",
  labelRight,
  sx = {},
  error,
  helperText,
  backgroundColor,
  tooltip,
}) => {
  let mainBackgroundColor = backgroundColor || "#f7f7f7";
  let value = _value;
  // || (arrayTypes?.includes(type) ? [] : "");
  return (
    <Box sx={sx}>
      <HorizontalStack justifyContent="space-between">
        <HorizontalStack justifyContent="space-between" sx={{ gap: 2 }}>
          <SecondaryLabelSmall>{label}</SecondaryLabelSmall>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoOutlined style={{ height: "14px", width: "14px" }} />
            </Tooltip>
          )}
        </HorizontalStack>

        {labelRight && labelRight}
      </HorizontalStack>
      {type === "text" && (
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          size="small"
          sx={{ mt: 1 }}
          fullWidth
          InputProps={{ style: { backgroundColor: mainBackgroundColor } }}
          error={error}
          {...textFieldProps}
          // helperText={helperText || textFieldProps?.helperText}
        />
      )}
      {type === "number" && (
        <TextField
          // {...textFieldProps}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          InputProps={{ style: { backgroundColor: mainBackgroundColor } }}
          value={value}
          type="number"
          {...textFieldProps}
          // helperText={helperText || textFieldProps?.helperText}
        />
      )}
      {type === "dropdown" && (
        <Select
          value={value || ""}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          SelectDisplayProps={{
            style: { backgroundColor: mainBackgroundColor },
          }}
          placeholder="hello"
          {...textFieldProps}
        >
          {options?.map((i) => {
            return <MenuItem value={i.value}>{i.label}</MenuItem>;
          })}
        </Select>
      )}
      {type === "date" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            // disableFuture
            // label="First Purchase"
            {...textFieldProps}
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1 }}
                error={false}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
        </LocalizationProvider>
      )}
      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            // disableFuture
            // label="First Purchase"
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1 }}
                error={false}
              />
            )}
            inputFormat=""
          />
        </LocalizationProvider>
      )}
      {type === "checkbox" && (
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          value={value || []}
          onChange={(a, e) => onChange(e)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          {...textFieldProps}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...textFieldProps} {...params} sx={{ mt: 1 }} />
          )}
        />
      )}
      {type === "radio" && (
        <Select
          size="small"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          SelectDisplayProps={{
            style: { backgroundColor: mainBackgroundColor },
          }}
          placeholder="hello"
          renderValue={(selected) => selected}
          {...textFieldProps}
        >
          {options?.map((i) => {
            return (
              <MenuItem key={i.value} value={i.value}>
                <Radio
                  checked={i.value === value}
                  onChange={(e) => onChange(e.target.value)}
                  value="a"
                />
                <ListItemText primary={i.label} />
              </MenuItem>
            );
          })}
        </Select>
      )}
      {helperText && (
        <SecondaryLabelSmall
          sx={{ color: error ? "error.main" : "text.secondary" }}
        >
          {helperText}
        </SecondaryLabelSmall>
      )}
    </Box>
  );
};

export default CustomField;
