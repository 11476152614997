import { onAddUserAttachment } from "../../utils/UserAttachmentNode";
import * as actions from "../../../../../../../redux/actions/flowsActions";

export const createUserAttachmentNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  const currCount = currNode.userConnected;

  let yPosition;
  if (currNode.currCount === null) {
    yPosition = currNode.position.y - 13;
  } else {
    yPosition = currNode.position.y - 13 - currCount * 120;
  }

  const axis = {
    x: currNode.position.x + 222,
    y: yPosition,
  };
  console.log(currNode.position.y - 13) - currCount * 120;
  console.log(axis);

  onAddUserAttachment(dispatch, axis, nodeId, currCount);
  dispatch({
    type: actions.UPDATE_USER_CONNECTED,
    payload: { id: nodeId, count: currCount + 1 },
  });
};
