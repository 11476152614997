import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  allStockStatus,
  apiCallFromSocket,
  fetchProducts,
  formatDate,
  getAxiosError,
  getNumberFromString,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "../../helper";
import { EDIT_ORDER, getOrder } from "../../redux/actions/orderActions";
import { isValidDate } from "../../utils/dateFunctions";
import {
  allPaymentStatus,
  getStatusBackgroundColor,
  statusFontColor,
} from "./Orders";
import { updateCustomer } from "../crm/View";
import {
  Add,
  ArrowBackIosNew,
  Cancel,
  CheckCircle,
  CheckCircleOutlined,
  Close,
  Create,
  Delete,
  HighlightOff,
  LocationOn,
} from "@mui/icons-material";
import axios from "axios";
import Switch from "rc-switch";
import { getDropdownData } from "../../redux/actions/persistActions";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { Edit } from "@material-ui/icons";
import { Label } from "../customers/components/Info";
import CustomChip from "../components/CustomChip";
import { PrimaryLabelSmall, SecondaryLabel } from "../../helper/utility/Labels";

let arr = ["digi_gold", "whatsapp", "website", "app", "instore"];

const defaultState = {
  dateCreated: "",
};

function Order() {
  const [order, setOrder] = useState(defaultState);
  const [notes, setNotes] = useState([]);
  const [cartProducts, setCardProducts] = useState([]);
  const [fetchingNotes, setFetchingNotes] = useState(false);
  const [products, setProducts] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changingPaymentStatus, setChangingPaymentStatus] = useState(false);
  const isStatusOpen = Boolean(anchorEl);
  const [productsLoading, setProductsLoading] = useState(false);

  const statusOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const statusClose = () => {
    setAnchorEl(null);
  };

  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  let orderView = useSelector((state) => state.orders.orderView);
  let allOrders = useSelector((state) => state.orders.allOrders);
  let fetchingOrder = useSelector((state) => state.orders.fetchingOrder);

  useEffect(() => {
    // if (order.dateCreated) return;
    let pieces = location.pathname.split("/");
    let orderId = pieces[pieces.length - 1];
    let orderObj =
      isArrayWithValues(allOrders) && allOrders.find((i) => i.id == orderId);
    if (orderObj) {
      setupOrder(orderObj);
      fetchNotes(orderObj.id);
    } else if (orderId) {
      dispatch(getOrder(orderId));
    }
  }, []);

  useEffect(() => {
    if (isObjWithValues(orderView)) {
      // following will stop setting up order as it already setted up

      let pieces = location.pathname.split("/");
      let orderId = pieces[pieces.length - 1];
      if (orderView?.id != orderId) return;
      if (order.dateCreated) return;
      setupOrder(orderView);
    }
  }, [orderView]);
  const getStockStatus = async () => {
    let dataRes = await fetchProducts({
      include: order?.line_items?.map((o) => o?.product_id)?.join(","),
      _fields: "id, stock_status",
    });
    setProducts(dataRes);
    console.log(dataRes, "<<dataRes");
  };
  useEffect(() => {
    console.log(order?.line_items, "<<order?.line_items");
    // let {data}
    if (isArrayWithValues(order?.line_items)) getStockStatus();
    return () => {};
  }, [order?.line_items?.length]);

  const setupOrder = (orderView) => {
    let obj = { ...orderView };
    let { date_created, line_items } = orderView;
    let dateCreated =
      isValidDate(new Date(date_created)) &&
      formatDate(new Date(date_created), "dd:mm:yyyy hh:mm am/pm");
    obj.dateCreated = dateCreated;
    let delivery_date = orderView?.meta_data?.find(
      (i) => i?.key === "delivery_date"
    );
    if (delivery_date) obj.delivery_date = delivery_date?.value;
    if (isArrayWithValues(line_items)) {
      let index = 0;
      for (let product of line_items) {
        let { meta_data } = product;
        if (!isArrayWithValues(meta_data)) continue;
        let priceBreakupObj = meta_data.find(
          (i) => i.key === "order_price_breakup"
        );
        if (priceBreakupObj) {
          let priceBreakupTemp = priceBreakupObj.value;
          if (isObjWithValues(priceBreakupTemp.breakup)) {
            let priceBreakup = {};
            for (let key in priceBreakupTemp.breakup) {
              let value = priceBreakupTemp.breakup[key].replace(
                /<\/?[^>]+(>|$)/g,
                ""
              );
              if (!value) continue;
              let semicolonIndex = value.indexOf(";");
              value = value.substring(semicolonIndex + 1, value.length);
              priceBreakup[key] = value;
            }
            obj.priceBreakup = priceBreakup;
            line_items[index] = { ...line_items[index], priceBreakup };
          }
        }

        index++;
      }
      obj.line_items = line_items;
    }

    setOrder(obj);
    if (!isArrayWithValues(notes)) fetchNotes(obj.id);
    // if (!isArrayWithValues(cartProducts) && !productsLoading)
    //   fetchOrderProducts(line_items.map((i) => i.id).join());
  };

  // const fetchOrderProducts = async (ids) => {
  //   let website = await getWebsite();
  //   let token = await getToken();
  //   setProductsLoading(true);
  //   if (website && token) {
  //     let { data } = await axios({
  //       url: `${website}/wp-json/wc/v3/products/${ids}`,
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //     });
  //     console.log(data);
  //   }
  //   setProductsLoading(false);
  // };

  const fetchNotes = async (_id) => {
    let { id } = order;
    if (!id && !_id) return;
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setFetchingNotes(true);
      let res = await axios({
        url: `${website}/wp-json/wc/v3/orders/${id || _id}/notes?alsdkfj`,
        // params: params ? params : {},
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (isArrayWithValues(res.data)) setNotes(res.data);
      setFetchingNotes(false);
    }
  };

  const onPaymentStatusChange = async (status) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setChangingPaymentStatus(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders/${order.id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { status, added_by_user: true },
        });
        console.log(res.data);
        if (isObjWithValues(res.data)) setupOrder(res.data);
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setChangingPaymentStatus(false);
    }
  };

  if (fetchingOrder)
    return (
      <Box
        sx={{
          width: "300px",
          margin: "auto",
          marginTop: "40vh",
          textAlign: "center",
        }}
      >
        <LinearProgress />
        <Typography variant="h6" color="text.secondary" mt={3}>
          Fetching Order
        </Typography>
      </Box>
    );

  return (
    <Box>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNew />}
        onClick={() => navigate(-1)}
      >
        All Orders
      </Button>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction={"row"} alignItems="center">
            <Typography fontSize="18px" fontWeight={"600"}>
              Order Id: {order.id}
            </Typography>
            <Typography fontSize="18px" fontWeight={"600"} ml={2}>
              <Chip
                size="small"
                label={order.status}
                // color="warning"
                sx={{
                  color: statusFontColor(order.status),
                  border: isDarkTheme ? "0.7px solid" : "0px solid",
                  // borderColor: statusFontColor(order.status),
                  backgroundColor: isDarkTheme
                    ? "transparent"
                    : getStatusBackgroundColor(order.status),
                }}
                variant="outlined"
                onClick={statusOpen}
                onDelete={statusOpen}
                deleteIcon={
                  changingPaymentStatus ? (
                    <CircularProgress
                      style={{ height: "15px", width: "15px" }}
                    />
                  ) : (
                    <Create />
                  )
                }
              />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isStatusOpen}
                onClose={statusClose}
                onClick={statusClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 2.2,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {allPaymentStatus.map((i, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => onPaymentStatusChange(i.value)}
                      selected={i.value === order.status}
                    >
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={3}
            justifyContent={"space-between"}
          >
            <Typography
              mt={2}
              fontSize="14px"
              color="text.secondary"
              fontWeight={"500"}
            >
              Date created: {order.dateCreated} {"  "}
            </Typography>
            {/* {console.log(order?.delivery_date )} */}
            {order?.delivery_date && (
              <Typography
                mt={2}
                fontSize="17px"
                // color="text.secondary"
                fontWeight={"500"}
                color="info.main"
              >
                Delivery Date:{" "}
                {formatDate(
                  new Date(validateNumber(order?.delivery_date)),
                  "dd/mm/yyyy"
                )}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography mb={2} fontSize="18px" fontWeight={"600"}>
                Products
              </Typography>
              {isArrayWithValues(order.line_items) &&
                order.line_items.map((i, index) => {
                  let stockStatus = products?.find(
                    (o) => o?.id == i?.product_id
                  );
                  if (stockStatus) i.stock_status = stockStatus?.stock_status;

                  return (
                    <ProductRow
                      product={i}
                      index={index}
                      currency_symbol={order.currency_symbol}
                    />
                  );
                })}
            </CardContent>
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <SummaryView order={order} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomerView order={order} setOrder={setOrder} />
          <Notes
            notes={notes}
            order={order}
            fetchingNotes={fetchingNotes}
            setNotes={setNotes}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Order;

const ProductRow = ({ product, index, currency_symbol }) => {
  let [info, setInfo] = useState({});
  let [metalInfo, setMetalInfo] = useState("");
  let [diamondInfo, setDiamondInfo] = useState("");
  const [gemstoneInfo, setGemstoneInfo] = useState("");

  useEffect(() => {
    let { meta_data, priceBreakup } = product;
    let obj = {};
    if (isArrayWithValues(meta_data)) {
      let metalColor =
        meta_data.find((i) => i.key === "Metal Color") &&
        meta_data.find((i) => i.key === "Metal Color").value;
      let goldWeight =
        meta_data.find((i) => i.key === "Gold Weight") &&
        meta_data.find((i) => i.key === "Gold Weight").value;
      let diamond_wt =
        meta_data.find((i) => i.key === "Diamond Weight") &&
        meta_data.find((i) => i.key === "Diamond Weight").value;
      let gold_purity =
        meta_data.find((i) => i.key === "Gold Purity") &&
        meta_data.find((i) => i.key === "Gold Purity").value;
      let data =
        meta_data.find((i) => i.key === "order_price_breakup") &&
        meta_data.find((i) => i.key === "order_price_breakup").value;
      let size_setting =
        meta_data.find((i) => i.key === "Size Setting") &&
        meta_data.find((i) => i.key === "Size Setting").value;
      // console.log(data);
      obj = { ...data };
      obj.size_setting = size_setting;
      if (metalColor || goldWeight)
        setMetalInfo(
          `${metalColor || ""} - ${goldWeight || ""} (${gold_purity || ""})`
        );
      if (diamond_wt) setDiamondInfo(`${diamond_wt || ""}`);
      setInfo(obj);
    }
  }, [product]);

  let { image, name, sku, priceBreakup, price, quantity, total, stock_status } =
    product;
  let statusChip = allStockStatus?.find((i) => i.value === stock_status);
  return (
    <>
      {index ? <Divider /> : ""}
      <Stack direction={"row"} my={2} alignItems="center">
        <img
          src={image.src || "/static/img/woocommerce_placeholder.png"}
          style={{
            height: "50px",
            width: "50px",
            borderRadius: "7px",
            border: "0.4px solid #e8e8e8",
          }}
        />
        <Stack sx={{ ml: 3, width: "100%", flex: 1 }}>
          <Stack direction={"row"} alignItems={"center"} gap={3}>
            <Typography fontSize={"15px"} fontWeight="600">
              {name}
            </Typography>
            <CustomChip
              status={statusChip?.label}
              type={statusChip?.type}
              size="small"
            />
          </Stack>
          {sku ? (
            <Typography
              mt={0.5}
              color="text.secondary"
              fontSize={"13px"}
              fontWeight="400"
              mb={1}
            >
              sku: {sku}
            </Typography>
          ) : null}
          {/* <Stack
            direction={"row"}
            sx={{ maxWidth: "200px" }}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography variant="h6" fontSize="12px">
                Metal
              </Typography>
              <Typography variant="h6" color="text.secondary" fontSize="12px">
                {metalInfo}
              </Typography>
            </Box>
            {priceBreakup && (
              <Box>
                <Typography variant="h6" fontSize="13px">
                  {currency_symbol || ""} {priceBreakup.metal}
                </Typography>
              </Box>
            )}
          </Stack> */}
          <ProductRowBreakup
            title="Metal"
            info={metalInfo}
            currency_symbol={currency_symbol}
            amount={priceBreakup ? priceBreakup.metal : ""}
          />
          <ProductRowBreakup
            title="Diamond"
            info={diamondInfo}
            currency_symbol={currency_symbol}
            amount={priceBreakup ? priceBreakup.diamond : ""}
          />
          <ProductRowBreakup
            title="Gemstone"
            info={gemstoneInfo}
            currency_symbol={currency_symbol}
            amount={priceBreakup ? priceBreakup.gemstone : ""}
          />
          <ProductRowBreakup
            title="Size Setting"
            info={""}
            currency_symbol={""}
            amount={info.size_setting}
          />
          {/* <RenderLabelValue label="Metal" value={`22KT / 3.2gram`} /> */}
        </Stack>
        <Stack justifyContent={"flex-end"}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontSize="15px" noWrap>
              {currency_symbol} {price}
            </Typography>
            <Close
              style={{ margin: "0px 10px", height: "15px", width: "15px" }}
            />
            <Typography variant="h6" fontSize="13px" noWrap>
              {quantity}
            </Typography>
          </Stack>

          {priceBreakup?.tax ? (
            <>
              <Typography variant="h6" mt={1} fontSize="15px" noWrap>
                {currency_symbol} {total || "0"} x {quantity}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginLeft: "auto" }}
                mt={1}
                fontSize="12px"
                noWrap
              >
                (Tax: {currency_symbol} {priceBreakup ? priceBreakup?.tax : "0"}
                )
              </Typography>
            </>
          ) : null}

          {/* <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontSize="15px" noWrap>
              {currency_symbol} {total}
            </Typography>
            <Close
              style={{
                margin: "0px 10px",
                height: "15px",
                width: "15px",
                transform: "rotate(45deg)",
              }}
            />
            <Typography variant="h6" fontSize="15px" noWrap>
              {quantity}
            </Typography>
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

const ProductRowBreakup = ({ title, info, currency_symbol, amount }) => {
  // console.log(amount, "amount, <<<<<<");
  const [rate, setRate] = useState("");
  useEffect(() => {
    console.log(info, amount, "<<< ");
    if (info && amount) {
      let weight = getNumberFromString(info);
      let onlyAmount = getNumberFromString(amount);
      console.log(weight, onlyAmount, "<<< ");
      // if (validateNumber(weight) && validateNumber(onlyAmount))
      //   setRate(
      //     validateNumber(
      //       parseInt(validateNumber(onlyAmount) / validateNumber(weight))
      //     )
      //   );
    }
  }, [info, amount]);
  if (!amount || amount?.trim() === "0" || amount === "0") return null;
  return (
    <Stack
      direction={"row"}
      sx={{ maxWidth: "250px" }}
      justifyContent="space-between"
      alignItems={"center"}
      mt={2}
      spacing={3}
    >
      <Box>
        <Typography variant="h6" fontSize="12px">
          {title}
        </Typography>
        <Typography variant="h6" color="text.secondary" fontSize="12px">
          {info}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" fontSize="13px" sx={{ whiteSpace: "nowrap" }}>
          {currency_symbol || ""} {amount || "0"}{" "}
        </Typography>
        {rate && (
          <Typography
            // variant="caption"
            style={{ fontSize: "11px", color: "text.secondary" }}
          >
            @ {currency_symbol || ""}
            {rate}
          </Typography>
        )}
        {/* <Typography variant="h6">{metalInfo}</Typography> */}
      </Box>
    </Stack>
  );
};

const SummaryView = ({ order }) => {
  const RenderLabelValue = ({ label, value, currency }) => {
    return (
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        mt={2}
      >
        <Typography
          variant="h6"
          fontSize="13px"
          fontWeight={"500"}
          color="text.secondary"
        >
          {label}
        </Typography>
        <Typography variant="h6" fontSize="14px" fontWeight={"600"}>
          {currency ? `${currency} ` : ""}
          {value}
        </Typography>
      </Stack>
    );
  };
  let { currency_symbol, shipping_total, total_tax, total, date_paid } = order;
  let discount_total = validateNumber(order?.discount_total);
  let coupon_applied = order?.coupon_lines?.map((coupon) =>
    coupon?.code?.toUpperCase()
  );
  return (
    <Box>
      {date_paid && (
        <Stack mb={4} direction={"row"} alignItems="center">
          <CheckCircleOutlined
            color="success"
            style={{ height: "24px", width: "24px" }}
          />
          <Typography variant="h4" ml={2}>
            Paid
          </Typography>
          {"  "}
          <PrimaryLabelSmall>
            {"  "}-{order?.payment_method_title}
          </PrimaryLabelSmall>
        </Stack>
      )}
      <RenderLabelValue
        label={"Subtotal"}
        value={validateNumber(
          Number(total) - Number(total_tax) - validateNumber(shipping_total)
        )}
        currency={currency_symbol}
      />
      <RenderLabelValue
        label={"Shipping"}
        value={shipping_total}
        currency={currency_symbol}
      />
      <RenderLabelValue
        label={"Tax"}
        value={total_tax}
        currency={currency_symbol}
      />
      {discount_total ? (
        <RenderLabelValue
          label={`Discount ${coupon_applied ? `(Coupon Applied)` : ""}`}
          value={discount_total}
          currency={`-${currency_symbol}`}
        />
      ) : null}
      <Divider sx={{ mt: 2, mb: 2.5 }} />
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        mt={1.5}
      >
        <Typography
          variant="h6"
          fontSize="15px"
          fontWeight={"600"}
          color="text.secondary"
        >
          Total
        </Typography>
        <Typography variant="h6" fontSize="16px" fontWeight={"bold"}>
          {currency_symbol ? `${currency_symbol} ` : ""}
          {total}
        </Typography>
      </Stack>
    </Box>
  );
};

const CustomerView = ({ order, setOrder }) => {
  let [isShippingBillingAddressSame, setIsShppingBillingAddressSame] =
    useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});
  const [billing, setBilling] = useState({});
  const [shipping, setShipping] = useState({});
  const [states, setStates] = useState([]);
  const allCountries = useSelector((state) => state.persist.countries);
  const allStates = useSelector((state) => state.persist.states);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  }, []);

  useEffect(() => {
    if (isObjWithValues(order)) {
      let { billing, shipping } = order;
      if (isObjWithValues(billing) && isObjWithValues(shipping)) {
        let arr = [
          "address_1",
          "address_2",
          "city",
          "state",
          "country",
          "postcode",
        ];
        let isAddSame = true;

        for (let i = 0; i < arr.length; i++) {
          if (billing[arr[i]] !== shipping[arr[i]]) {
            isAddSame = false;
            break;
          }
        }
        setIsShppingBillingAddressSame(isAddSame);
      }
    }
  }, [order]);

  useEffect(() => {
    if (isObjWithValues(order)) {
      let { billing } = order;
      let obj = { ...billing };
      if (isObjWithValues(billing)) {
        if (isArrayWithValues(allCountries)) {
          let countryObj = allCountries.find((i) => i.code === billing.country);
          if (countryObj) obj.country = countryObj.label;
        }
        if (isObjWithValues(allStates)) {
          let allState = allStates[billing.country || "IN"];
          if (isArrayWithValues(allState)) {
            let stateObj = allState.find((i) => i.code === billing.state);
            if (stateObj) obj.state = stateObj.label;
          }
        }
      }
      setBilling(obj);
      let { shipping } = order;
      let shippingObj = { ...shipping };
      if (isObjWithValues(shipping)) {
        if (isArrayWithValues(allCountries)) {
          let countryObj = allCountries.find(
            (i) => i.code === shipping.country
          );
          if (countryObj) shippingObj.country = countryObj.label;
        }
        if (isObjWithValues(allStates)) {
          let allState = allStates[shipping.country];
          if (isArrayWithValues(allState)) {
            let stateObj = allState.find((i) => i.code === shipping.state);
            if (stateObj) shippingObj.state = stateObj.label;
          }
        }
      }
      setShipping(shippingObj);
    }
  }, [order, allCountries, allStates]);

  useEffect(() => {
    if (isObjWithValues(allStates)) {
      console.log(inputData?.country);
      for (const key in allStates) {
        if (key === inputData?.country?.value) {
          setStates(
            allStates[key]?.map((obj) => ({
              label: obj?.label,
              value: obj?.code,
            }))
          );
        }
      }
    }
  }, [inputData?.country?.value]);
  const handleClose = () => {
    setOpenEdit(false);
    setInputData({});
  };
  const inputChangeHandler = (e, newvalue) => {
    // if (error) setError("");
    let { name, value } = e.target;
    console.log({ [`${name}`]: newvalue || value });
    setInputData((pre) => ({
      ...pre,
      [`${name}`]: newvalue || value,
      // typeof newvalue !== "object" && newvalue ? newvalue : value,
    }));
  };
  const onSubmit = async () => {
    let shippingObj = { ...inputData };

    shippingObj.country = shippingObj?.country?.value;
    shippingObj.state = shippingObj?.state?.value;
    console.log(shippingObj);
    setLoading(true);
    const website = getWebsite();
    const token = getToken();
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/orders/${order?.id}`,
        method: "PUT",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          shipping: shippingObj,
        },
      });
      console.log(data);
      if (data?.id) {
        setOrder(data);
        dispatch(
          setGlobalToast({
            show: true,
            message: "Order updated Successfully!",
            severity: "success",
          })
        );
        setOpenEdit(false);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Internal Sever Error!",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mb={2}
          >
            <Typography
              variant="h5"
              //
              fontSize="15px"
            >
              Customer
            </Typography>
            <Avatar size="small" style={{ height: "30px", width: "30px" }} />
          </Stack>
          <Typography
            variant="h6"
            // fontSize="13px"
          >
            {billing &&
              `${billing.first_name || ""} ${billing.last_name || ""}`}
          </Typography>
          <Typography
            variant="h6"
            mt={1}
            // fontSize="12px"
            color={"text.secondary"}
          >
            {billing &&
              `${billing.city ? `${billing.city},` : "" || ""} ${
                billing.state ? `${billing.state},` : "" || ""
              } ${billing.country || ""}`}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mb={2}
          >
            <Typography variant="h6" fontSize="15px">
              Contact Information
            </Typography>
            {/* <Avatar size="small" style={{ height: "30px", width: "30px" }} /> */}
          </Stack>
          <Typography variant="h6" color="info.main" fontSize="13px">
            {billing && `${billing.email || ""}`}
          </Typography>
          <Typography variant="h6" mt={1} fontSize="12px">
            {billing && `${billing.phone || ""}`}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <Typography mb={2} variant="h6" fontSize="15px">
            {/* {isShippingBillingAddressSame ? "Shipping/Billing" : "Shipping"}{" "} */}
            Shipping Address{" "}
            <IconButton
              size="small"
              onClick={() => {
                setOpenEdit(true);
                setInputData(shipping);
              }}
            >
              <Edit />{" "}
            </IconButton>
          </Typography>
          <Stack
            direction={"row"}
            // justifyContent="space-between"
            alignItems={"center"}
          >
            <LocationOn style={{ height: "15px", width: "15px" }} />
            <Typography ml={1} variant="h6" color="info.main" fontSize="13px">
              {isObjWithValues(shipping) &&
                `${shipping.address_2 ? `${shipping.address_2},` : "" || ""} ${
                  shipping.address_1 ? `${shipping.address_1},` : "" || ""
                } ${shipping.city ? `${shipping.city},` : "" || ""} ${
                  shipping.state ? `${shipping.state},` : "" || ""
                } ${shipping.country ? `${shipping.country},` : "" || ""} ${
                  shipping.postcode || ""
                }`}
            </Typography>
          </Stack>
        </CardContent>

        {/* {!isShippingBillingAddressSame ||
        (true && ( */}
        <>
          <Divider />
          <CardContent>
            <Typography mb={2} variant="h6" fontSize="15px">
              Billing Address
            </Typography>
            <Stack
              direction={"row"}
              // justifyContent="space-between"
              alignItems={"center"}
            >
              <LocationOn style={{ height: "15px", width: "15px" }} />
              <Typography ml={1} variant="h6" color="info.main" fontSize="13px">
                {isObjWithValues(billing) &&
                  `${billing.address_2 ? `${billing.address_2},` : "" || ""} ${
                    billing.address_1 ? `${billing.address_1},` : "" || ""
                  } ${billing.city ? `${billing.city},` : "" || ""} ${
                    billing.state ? `${billing.state},` : "" || ""
                  } ${billing.country ? `${billing.country},` : "" || ""} ${
                    billing.postcode || ""
                  }`}
              </Typography>
            </Stack>
          </CardContent>
        </>
        {/* ))} */}
      </Card>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Shipping Address
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Label>Address Line 1</Label>
              <TextField
                size="small"
                // label="Adress Line 1"
                name="address_1"
                fullWidth
                onChange={inputChangeHandler}
                value={inputData?.address_1 || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Address Line 2</Label>
              <TextField
                size="small"
                // label="Adress Line 2"
                name="address_2"
                fullWidth
                onChange={inputChangeHandler}
                value={inputData?.address_2 || ""}
                // onChange={(e) => {
                //   onEditCustomer({
                //     billing: {
                //       ...chatInfo.billing,
                //       address_2: e.target.value,
                //     },
                //   });
                // }}
                // value={chatInfo.billing.address_2 || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>City</Label>
              <TextField
                size="small"
                // label="City"
                name="city"
                fullWidth
                onChange={inputChangeHandler}
                value={inputData?.city || ""}
                // onChange={(e) => {
                //   onEditCustomer({
                //     billing: {
                //       ...chatInfo.billing,
                //       city: e.target.value,
                //     },
                //   });
                // }}
                // value={chatInfo.billing.city || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Post Code</Label>
              <TextField
                size="small"
                // label="Post Code"
                name="postcode"
                fullWidth
                onChange={inputChangeHandler}
                value={inputData?.postcode || ""}
                // onChange={(e) => {
                //   onEditCustomer({
                //     billing: {
                //       ...chatInfo.billing,
                //       postcode: e.target.value,
                //     },
                //   });
                // }}
                // value={chatInfo.billing.postcode || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Country</Label>
              <Autocomplete
                id="combo-box-demo"
                options={
                  allCountries?.map((obj) => ({
                    label: obj?.label,
                    value: obj?.code,
                  })) || []
                }
                size="small"
                name="country"
                // blurOnSelect={true}
                // clearOnBlur={"touch"}
                // autoSelect={true}
                // autoComplete={true}
                onChange={(e, newInputValue) => {
                  // setAddressSateCode((pre) => ({
                  //   ...pre,
                  //   country: newInputValue?.label,
                  // }));
                  // e.target.name = "country";
                  // console.log(newInputValue);
                  // inputChangeHandler(e, newInputValue);
                  setInputData((pre) => ({
                    ...pre,
                    country: newInputValue,
                    // typeof newvalue !== "object" && newvalue ? newvalue : value,
                  }));
                }}
                value={
                  // addressStateCode?.country ||
                  inputData?.country || ""
                }
                // value={country}
                // onChange={(event, newInputValue) => {
                //   setCountry(newInputValue.label);
                //   setCountryCode(newInputValue.code);
                //   onEditCustomer({
                //     billing: {
                //       ...chatInfo.billing,
                //       country: newInputValue.code,
                //     },
                //   });
                // }}
                fullWidth
                renderInput={(params) => (
                  <TextField name="country" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>State</Label>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                name="state"
                options={states || []}
                value={inputData?.state || ""}
                onChange={(e, newInputValue) => {
                  // setAddressSateCode((pre) => ({
                  //   ...pre,
                  //   state: newInputValue?.label,
                  // }));
                  // inputChangeHandler(e, newInputValue);
                  setInputData((pre) => ({
                    ...pre,
                    state: newInputValue,
                    // typeof newvalue !== "object" && newvalue ? newvalue : value,
                  }));
                }}
                fullWidth
                renderInput={(params) => <TextField name="state" {...params} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onSubmit}
            autoFocus
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Notes = ({
  notes,
  order,
  customer,
  fetchingCustomer,
  fetchingNotes,
  setNotes,
}) => {
  const id = customer && customer.id;
  // console.log(isArrayWithValues)
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [showDelete, setShowDelete] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [form, setForm] = useState({
    title: "",
    note: "",
    id: "",
    customer_note: false,
  });
  const [dialogType, setDialogType] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  // console.log(theme);
  useEffect(() => {
    if (isObjWithValues(customer))
      setCustomerName(`${customer.first_name} ${customer.last_name}`);
  }, [customer]);

  const changeForm = (label, value) => {
    setForm({ ...form, [label]: value });
  };

  const onEditPress = (obj) => {
    setForm({ ...obj });
    setDialogType("edit");
    setShowDialog(true);
  };
  const onAddPress = () => {
    setForm({ title: "", note: "", id: "" });
    setDialogType("add");
    setShowDialog(true);
  };
  const onDeletePress = (obj) => {
    setForm({ ...obj });
    setShowDelete(true);
  };
  const onAddNote = async () => {
    if (!form.note) {
      return dispatch(
        setGlobalToast({
          show: true,
          severity: "error",
          message: "Please enter note",
        })
      );

      // setToast({
      //   show: true,
      //   severity: "error",
      //   message: "Please enter note",
      // });
    }
    setLoading(true);
    const website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders/${order.id}/notes`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: {
            note: form.note,
            customer_note: form.customer_note,
            added_by_user: true,
          },
        });
        if (isObjWithValues(res.data))
          setNotes((state) => [{ ...res.data }, ...state]);
        console.log(res.data);
      } catch (error) {}
    }
    setLoading(false);
    setShowDialog(false);
  };

  const onDeleteNote = async () => {
    setLoading(true);

    const website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders/${order.id}/notes/${form.id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          params: { force: true },
        });
        if (isObjWithValues(res.data))
          setNotes((state) => state.filter((i) => i.id !== res.data.id));
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }

    setLoading(false);
    setShowDelete(false);
    // if (isObjWithValues(updatedCustomer) && setCustomer)
    //   setCustomer(updatedCustomer);
  };
  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" ml={1} fontWeight="bold">
              {customerName && `${customerName}'s`} Notes
            </Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={() => onAddPress(true)}
            >
              New
            </Button>
          </Stack>
          <Box sx={{ height: "10px" }} />
          {isArrayWithValues(notes) &&
            notes.map((note) => (
              <NoteView
                note={note}
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
              />
            ))}
          {!isArrayWithValues(notes) && !fetchingNotes && (
            <Typography color="text.secondary" my={3} textAlign={"center"}>
              No Notes added yet.
            </Typography>
          )}
          {!isArrayWithValues(notes) && fetchingNotes && (
            <Typography color="text.secondary" my={3} textAlign={"center"}>
              Fetching Notes...
            </Typography>
          )}
        </CardContent>
      </Card>
      <div>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Add Note</DialogTitle>
          <DialogContent>
            {/* <Typography variant="h6">Title</Typography> */}
            {/* <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="email"
              fullWidth
              variant="standard"
              value={form.title}
              onChange={(e) => changeForm("title", e.target.value)}
            /> */}
            {/* <Typography variant="h6">Description</Typography> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Note"
              type="email"
              fullWidth
              variant="standard"
              multiline
              rows={3}
              value={form.note}
              onChange={(e) => changeForm("note", e.target.value)}
            />
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              mt={2}
            >
              <Typography variant="h6">Inform customer?</Typography>
              <Switch
                onChange={(e) => {
                  changeForm("customer_note", e);
                }}
                checked={form.customer_note}
                style={{
                  border: `1px solid ${
                    form.customer_note ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: form.customer_note
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <LoadingButton loading={loading} onClick={onAddNote}>
              Add Note
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showDelete}
          keepMounted
          onClose={() => setShowDelete(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h4">Delete following note?</Typography>
            <Box
              sx={{
                backgroundColor: "#e8e8e8",
                padding: "8px",
                mt: 2,
                borderRadius: "7px",
              }}
            >
              <Typography variant="h6" fontSize="13px">
                {form.note}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowDelete(false)}>Close</Button>
            <LoadingButton
              loading={loading}
              color="error"
              variant="contained"
              onClick={onDeleteNote}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const NoteView = (props) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const { title, note, customer_note } = props.note;
  const { onEditPress, onDeletePress, crmAccess, note: noteView } = props;
  return (
    <Card
      sx={{
        backgroundColor: "#fafafa",
        margin: "10px 0px",
        overflow: "visible",
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "5px" },
          position: "relative",
          overflow: "visible",
        }}
      >
        <Button
          variant="text"
          color="error"
          startIcon={<Cancel />}
          onClick={() => onDeletePress(props.note)}
          style={{
            position: "absolute",
            top: -10,
            right: -30,
            backgroundColor: "error.main",
            p: 0,
          }}
          // disabled={!crmAccess.includes("edit")}
        >
          {/* Delete */}
        </Button>
        {/* <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography> */}
        <Typography
          variant="body2"
          sx={{ flex: 1, width: "100%" }}
          fontWeight="bold"
        >
          {note}
        </Typography>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          mt={1.5}
        >
          <Typography fontSize="12px" color="info.main" fontWeight="500">
            {noteView.author === "WooCommerce"
              ? "Auto Genrated"
              : `Added by ${noteView.author || ""}`}
          </Typography>
          <Chip
            size="small"
            label={customer_note ? "Customer" : "Private"}
            // color="warning"
            style={{ fontSize: "10px", padding: "0px", margin: "0px" }}
            sx={{
              color: statusFontColor(customer_note ? "processing" : "on-hold"),
              border: isDarkTheme ? "0.7px solid" : "0px solid",
              // borderColor: statusFontColor(customer_note ? "Customer": "Private"),
              backgroundColor: isDarkTheme
                ? "transparent"
                : getStatusBackgroundColor(
                    customer_note ? "processing" : "on-hold"
                  ),
              p: 0,
            }}
            variant="outlined"
          />
        </Stack>
        <Typography fontSize={"11px"} color="text.secondary">
          {new Date(noteView.date_created) &&
            formatDate(
              new Date(noteView.date_created),
              "dd:mm:yyyy hh:mm am/pm"
            )}
        </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <Button
            variant="text"
            onClick={() => onEditPress(props.note)}
            startIcon={<ModeEditOutlineOutlinedIcon />}
            // disabled={!crmAccess.includes("edit")}
          >
            Edit
          </Button> */}
          {/* <Button
            variant="text"
            color="error"
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => onDeletePress(props.note)}
            // disabled={!crmAccess.includes("edit")}
          >
            Delete
          </Button> */}
        </div>
      </CardContent>
    </Card>
  );
};

const RenderLabelValue = ({ label, value }) => {
  return (
    <Stack direction="row" alignItems={"center"}>
      <Stack direction="row" alignItems={"center"}>
        <Typography fontSize="13px">{label}: </Typography>
        <Typography ml={2} fontSize={"13px"} fontWeight="500">
          {value}
        </Typography>
      </Stack>
      <Typography ml={2} fontSize={"13px"} fontWeight="500">
        {value}
      </Typography>
    </Stack>
  );
};
