import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPricingdiff,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { addUserActivity } from "../../redux/actions/hubActions";

const defaultPricing = {
  default: "",
  automatic: {
    375: { base: "", margin: "375", rate: "" },
    583: { base: "", margin: "583", rate: "" },
    750: { base: "", margin: "750", rate: "" },
    916: { base: "", margin: "916", rate: "" },
    995: { base: "", margin: "995", rate: "" },
    999: { base: "", margin: "999", rate: "" },
    999.99: { base: "", margin: "999.99", rate: "" },
  },
  manual: {
    375: { base: "", rate: "" },
    583: { base: "", rate: "" },
    750: { base: "", rate: "" },
    916: { base: "", rate: "" },
    995: { base: "", rate: "" },
    999: { base: "", rate: "" },
    999.99: { base: "", rate: "" },
  },
  enabled_purities: {
    375: true,
    583: true,
    750: true,
    916: true,
    995: true,
    999: true,
    999.99: true,
  },
};
const defaultPricingObj = {
  INR: defaultPricing,
  USD: defaultPricing,
  EUR: defaultPricing,
  GBP: defaultPricing,
  AUD: defaultPricing,
  AED: defaultPricing,
  SGD: defaultPricing,
  SAR: defaultPricing,
  ZAR: defaultPricing,
  CAD: defaultPricing,
  JPY: defaultPricing,
  CNY: defaultPricing,
  VND: defaultPricing,
  THB: defaultPricing,
  KWD: defaultPricing,
};

export const currencySymbols = {
  INR: "₹",
  USD: "$",
  EUR: "€",
  GBP: "£",
  AUD: "$",
  AED: "د.إ",
  SGD: "$",
  SAR: "SR",
  ZAR: "R",
  CAD: "$",
  JPY: "¥",
  CNY: "¥",
  VND: "₫",
  THB: "฿",
  KWD: "د.ك",
};

const featuresWebsite = [
  "https://tiarabytj.com",
  "https://natarajjewellery.com",
];
const Gold = forwardRef((props, ref) => {
  let { selectedCurrency: currency } = props;
  const [type, setType] = useState("automatic");
  const [from, setFrom] = useState("gross");
  const [goldPurities, setGoldPurities] = useState([]);
  const [pricing, setPricing] = useState(defaultPricingObj);
  const [allCurrency, setAllCurrency] = useState([]);
  const [oldGoldRate, setOldGoldRate] = useState("");
  let { changePurity, onPricngChange } = props;
  // console.log(getWebsite());
  // const [currency, selectedCurrency] = useState("");
  const dispatch = useDispatch();
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  useImperativeHandle(ref, () => ({
    getGoldPricing(updateDiff) {
      let obj = {};
      for (let i = 0; i < allCurrency.length; i++) {
        let currencyObj = pricing[allCurrency[i].value];
        let enabled_purities = {};
        for (let j = 0; j < goldPurities.length; j++) {
          enabled_purities[goldPurities[j].value] =
            currencyObj?.enabled_purities[goldPurities[j].value];
        }
        obj[allCurrency[i].value] = { ...currencyObj, enabled_purities };
      }
      let oldPricingObj = masterPricing?.gold_pricing;
      //     // let defaultPurity = oldPricingObj[default_currency]?.default;
      let defaultPurityAmount = oldPricingObj?.[currency]?.[type];
      let diffrenceObj = getPricingdiff(
        defaultPurityAmount,
        obj?.[currency]?.[type]
      );
      let event_info;
      if (isArrayWithValues(changePurity?.gold)) {
        for (const purity of changePurity?.gold) {
          let diffrenceObjpurity = diffrenceObj[purity] || {};
          event_info = { ...event_info, [purity]: diffrenceObjpurity };
        }
      }
      if (
        isObjWithValues(diffrenceObj) &&
        isObjWithValues(event_info) &&
        isArrayWithValues(changePurity?.gold)
      ) {
        updateDiff &&
          updateDiff({
            gold: event_info,
          });
        dispatch(
          addUserActivity({
            event: `pricing_update`,
            currency,
            event_info: {
              gold: event_info,
            },
          })
        );
      }
      let returnObj = { ...obj, from, type };
      if (featuresWebsite.includes(getWebsite()) && oldGoldRate)
        returnObj.old_gold_rate = oldGoldRate;
      return returnObj;
    },
  }));

  useEffect(() => {
    if (isObjWithValues(props.productSettings))
      if (isArrayWithValues(props.productSettings.currency)) {
        let { currency } = props.productSettings;
        // if (isArrayWithValues(currency)) {
        //   if (currency.find((i) => i.value === "INR")) selectedCurrency("INR");
        //   else selectedCurrency(currency[0].value);
        // }
        setAllCurrency(currency);
      }
  }, [props.productSettings]);

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.gold_pricing)
    ) {
      setPricing((state) => ({
        ...defaultPricingObj,
        ...masterPricing.gold_pricing,
      }));
      let { from, type } = masterPricing.gold_pricing;
      setFrom(from);
      setType(type);
      setOldGoldRate(masterPricing?.gold_pricing?.old_gold_rate || "");
    }
  }, [masterPricing]);

  useEffect(() => {
    if (isArrayWithValues(props.goldPurities))
      setGoldPurities(props.goldPurities);
  }, [props.goldPurities]);

  const onDefaultChange = (value) => {
    let obj = { ...pricing[currency][type][value] };
    if (obj) {
      obj.margin = Number(value);
      obj.rate = obj.base;
    }
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [value]: obj },
        default: state[currency].default === value ? "" : value,
      },
    }));
  };

  const onBaseAmountChange = (purity, amount, dontChangeOtherPurities) => {
    let obj = { ...pricing[currency][type][purity] };
    obj.base = amount;
    obj.rate = amount;
    if (type === "automatic") {
      if (Number(obj.margin)) {
        obj.rate = Math.round(
          (Number(amount) * Number(obj.margin)) / Number(purity)
        );
      }
      if (!dontChangeOtherPurities) {
        let purities = Object.keys(pricing[currency]?.enabled_purities).filter(
          (i) => i !== purity
        );
        for (let i = 0; i < purities.length; i++) {
          let otherPurityAmount = Math.round(
            (Number(amount) * Number(purities[i])) / Number(obj.margin)
          );
          onBaseAmountChange(purities[i], otherPurityAmount, true);
        }
      }
    }

    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [purity]: obj },
      },
    }));
  };
  const onMarginChange = (purity, value) => {
    let obj = { ...pricing[currency][type][purity] };
    obj.margin = value;
    if (type === "automatic") {
      if (Number(obj.margin)) {
        obj.rate = Math.round(
          (Number(obj.base) * Number(value)) / Number(purity)
        );
      }
    }
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [purity]: obj },
      },
    }));
  };

  const onPurityChange = (obj) => {
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        // [type]: { ...state[currency][type], [purity]: obj },
        enabled_purities: {
          ...state[currency]?.enabled_purities,
          [obj.value]: !state[currency]?.enabled_purities[obj.value],
        },
      },
    }));
  };

  return (
    <Box>
      {/* {(onlyChats || user_products?.includes("whatsapp"))  ? null : ( */}
      {false ? null : (
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
              }}
            >
              <CardContent
                sx={{
                  "&:last-child": { paddingBottom: "16px" },
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent="space-between"
                  direction="row"
                  // p={2}
                  // border="0.5px solid #d1d1d1 "
                  // borderRadius={"6px"}
                >
                  <Typography>How do you want to set price?</Typography>
                  <FormControl size="small">
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      // label="Age"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <MenuItem value={"automatic"}>Automatic</MenuItem>
                      <MenuItem value={"manual"}>Manual</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
              }}
            >
              <CardContent
                sx={{
                  "&:last-child": { paddingBottom: "16px" },
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent="space-between"
                  direction="row"
                  // p={2}
                  // border="0.5px solid #d1d1d1 "
                  // borderRadius={"6px"}
                >
                  <Typography>Calculate gold pricing from?</Typography>
                  <FormControl size="small">
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={from}
                      // label="Age"
                      onChange={(e) => setFrom(e.target.value)}
                    >
                      <MenuItem value={"gross"}>Gross</MenuItem>
                      <MenuItem value={"net"}>Net</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {/* <Card>
        <CardContent></CardContent>
      </Card> */}
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        alignItems="center"
        style={{ marginTop: "13px" }}
      >
        <Grid item>
          <Stack direction="row" alignItems={"center"} flexWrap="wrap">
            {goldPurities.map((i) => {
              return (
                <PurityCard
                  key={i.value}
                  obj={i}
                  isSelected={pricing[currency]?.enabled_purities[i.value]}
                  onClick={onPurityChange}
                />
              );
            })}
            {/* <Card sx={{  border: "0.5px solid #ccc" }}> */}

            {/* </Card> */}
            {/* <Stack component={Card}>
           
              <TextField placeholder="Old Gold Rate" label="Old Gold Rate" />
            </Stack> */}
          </Stack>
          {/* <Typography variant="h5" mr={1}>
            Set Price
          </Typography> */}
        </Grid>
        <Grid item>
          <Stack direction={"row"} alignItems="center">
            {/* <FlagIndia style={{ borderRadius: "4px", marginRight: "10px" }} /> */}
            {/* <FormControl size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                // label="Age"
                onChange={(e) => selectedCurrency(e.target.value)}
              >
                {allCurrency.map((i) => {
                  return (
                    <MenuItem
                      value={i.value}
                    >{`${i.label} (${i.symbol})`}</MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
          </Stack>
        </Grid>
      </Grid>
      {featuresWebsite?.includes(getWebsite()) ? (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography fontWeight={"600"}>Old Gold Rate</Typography>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbols[currency]}
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: "#fff" }}
            placeholder="Old Gold Rate"
            size="small"
            value={oldGoldRate}
            onChange={(e) => {
              setOldGoldRate(e.target.value);
            }}
            type={"number"}
          />
        </Box>
      ) : null}
      <Grid container spacing={3} sx={{ marginTop: "14px" }}>
        {Array.isArray(goldPurities) &&
          goldPurities.map((purity) => {
            if (!pricing?.[currency]?.enabled_purities?.[purity?.value])
              return null;
            return (
              <Grid item xs={12} md={6} lg={4}>
                <PricingCard
                  title={purity.label}
                  purity={purity.value}
                  baseAmount={pricing[currency]?.[type]?.[purity.value]?.base}
                  rateAmount={pricing[currency]?.[type]?.[purity.value]?.rate}
                  margin={pricing?.[currency]?.[type]?.[purity?.value]?.margin}
                  onMarginChange={onMarginChange}
                  onBaseAmountChange={onBaseAmountChange}
                  defaultValue={pricing?.[currency]?.default}
                  onDefaultChange={onDefaultChange}
                  type={type}
                  currency={currency}
                  onPricngChange={onPricngChange}
                />
              </Grid>
            );
          })}
        {/* <Grid item xs={12} md={6} lg={4}>
          <PricingCard title="22 KT (999)" />
        </Grid> */}
      </Grid>
    </Box>
  );
});

export default Gold;

const PricingCard = ({
  title,
  purity,
  defaultValue,
  onDefaultChange,
  baseAmount,
  rateAmount,
  onBaseAmountChange,
  margin,
  onMarginChange,
  type,
  currency,
  onPricngChange,
}) => {
  return (
    <Card
      style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{title}</Typography>
          {/* <Fade in={type === "automatic"}>
          </Fade> */}
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                checked={defaultValue === purity}
                onChange={(e) => onDefaultChange(purity)}
              />
            }
            label="Set Default"
            labelPlacement="start"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={3}
        >
          <Typography variant="body2">Price</Typography>
          <TextField
            type="number"
            size="small"
            value={baseAmount}
            onChange={(e) => {
              onBaseAmountChange(purity, e.target.value);
              onPricngChange("gold", purity);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbols[currency]}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">/gram</InputAdornment>
              ),
            }}
          />
        </Stack>
        <Collapse in={type === "automatic"}>
          <Box sx={{ px: 3, pt: 1 }}>
            <Slider
              // aria-label="Restricted values"
              // defaultValue={20}
              // valueLabelFormat={valueLabelFormat}
              // getAriaValueText={valuetext}
              disabled={defaultValue === purity}
              value={margin}
              onChange={(e, value) => onMarginChange(purity, value)}
              valueLabelDisplay="on"
              step={null}
              sx={{ transform: "translateY(35px)" }}
              defaultValue={Number(purity)}
              // scale={() => " "}
              componentsProps={{ markLabel: { style: { display: "none" } } }}
              marks={marks?.[purity]}
              min={marks?.[purity]?.[0].value}
              max={marks?.[purity]?.[marks[purity].length - 1]?.value}
              aria-labelledby="non-linear-slider"
            />
          </Box>
        </Collapse>
        <Stack mt={3} justifyContent={"flex-end"} direction="row">
          <Typography
            variant="body1"
            sx={{ fontSize: "15px", fontWeight: 700 }}
          >
            Sell: {currencySymbols[currency]} {rateAmount || 0}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const PurityCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "3px 8px 3px 0px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            width={9}
            height={9}
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
          />
        ) : null}
        <Typography variant={"body2"}>{obj.label}</Typography>
      </CardContent>
    </Card>
  );
};

const marks = {
  999.99: [
    { label: "960", value: 960 },
    { label: "970", value: 970 },
    { label: "980", value: 980 },
    { label: "990", value: 990 },
    { label: "999", value: 999 },
    { label: "999.99", value: 999.99 },
    { label: "1000", value: 1000 },
  ],
  999: [
    { label: "960", value: 960 },
    { label: "970", value: 970 },
    { label: "980", value: 980 },
    { label: "990", value: 990 },
    { label: "999", value: 999 },
    { label: "1000", value: 1000 },
  ],
  995: [
    { label: "950", value: 950 },
    { label: "960", value: 960 },
    { label: "970", value: 970 },
    { label: "980", value: 980 },
    { label: "990", value: 990 },
    { label: "995", value: 995 },
    { label: "1000", value: 1000 },
  ],
  916: [
    { label: "860", value: 860 },
    { label: "870", value: 870 },
    { label: "880", value: 880 },
    { label: "890", value: 890 },
    { label: "900", value: 900 },
    { label: "910", value: 910 },
    { label: "916", value: 916 },
    { label: "920", value: 920 },
    { label: "925", value: 925 },
    { label: "930", value: 930 },
    { label: "935", value: 935 },
    { label: "940", value: 940 },
    { label: "945", value: 945 },
    { label: "950", value: 950 },
    { label: "955", value: 955 },
    { label: "960", value: 960 },
    { label: "965", value: 965 },
    { label: "970", value: 970 },
    { label: "975", value: 975 },
    { label: "980", value: 980 },
    { label: "985", value: 985 },
    { label: "990", value: 990 },
    { label: "995", value: 995 },
    { label: "1000", value: 1000 },
  ],
  750: [
    { label: "700", value: 700 },
    { label: "710", value: 710 },
    { label: "720", value: 720 },
    { label: "730", value: 730 },
    { label: "740", value: 740 },
    { label: "750", value: 750 },
    { label: "755", value: 755 },
    { label: "760", value: 760 },
    { label: "765", value: 765 },
    { label: "770", value: 770 },
    { label: "775", value: 775 },
    { label: "780", value: 780 },
    { label: "785", value: 785 },
    { label: "790", value: 790 },
    { label: "795", value: 795 },
    { label: "800", value: 800 },
    { label: "805", value: 805 },
    { label: "810", value: 810 },
    { label: "815", value: 815 },
    { label: "820", value: 820 },
    { label: "825", value: 825 },
    { label: "830", value: 830 },
    { label: "835", value: 835 },
    { label: "840", value: 840 },
    { label: "845", value: 845 },
    { label: "850", value: 850 },
  ],
  583: [
    { label: "530", value: 530 },
    { label: "540", value: 540 },
    { label: "550", value: 550 },
    { label: "560", value: 560 },
    { label: "570", value: 570 },
    { label: "580", value: 580 },
    { label: "583", value: 583 },
    { label: "585", value: 585 },
    { label: "590", value: 590 },
    { label: "595", value: 595 },
    { label: "600", value: 600 },
    { label: "605", value: 605 },
    { label: "610", value: 610 },
    { label: "615", value: 615 },
    { label: "620", value: 620 },
    { label: "625", value: 625 },
    { label: "630", value: 630 },
    { label: "635", value: 635 },
    { label: "640", value: 640 },
    { label: "645", value: 645 },
    { label: "650", value: 650 },
    { label: "655", value: 655 },
    { label: "660", value: 660 },
    { label: "665", value: 665 },
    { label: "670", value: 670 },
    { label: "675", value: 675 },
  ],
  375: [
    { label: "320", value: 320 },
    { label: "330", value: 330 },
    { label: "340", value: 340 },
    { label: "350", value: 350 },
    { label: "360", value: 360 },
    { label: "375", value: 375 },
    { label: "380", value: 380 },
    { label: "385", value: 385 },
    { label: "390", value: 390 },
    { label: "395", value: 395 },
    { label: "400", value: 400 },
    { label: "405", value: 405 },
    { label: "410", value: 410 },
    { label: "415", value: 415 },
    { label: "420", value: 420 },
    { label: "425", value: 425 },
    { label: "430", value: 430 },
    { label: "435", value: 435 },
    { label: "440", value: 440 },
    { label: "445", value: 445 },
    { label: "450", value: 450 },
    { label: "455", value: 455 },
    { label: "460", value: 460 },
    { label: "465", value: 465 },
    { label: "470", value: 470 },
  ],
};
