export const nodesOptionsStyles = {
  container: {
    // height: "140px",
    width: "185px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Inter",
    flexDirection: "column",
    padding: "6px 0 6px 0px",
  },
  editBox: {
    display: "flex",
    alignItems: "center",
    padding: "7px 10px 7px 10px",
    transition: "0.3s ease",
    "&:hover": {
      cursor: "pointer",
    },
  },
  editIcon: {
    fontSize: "20px",
    marginRight: "10px",
  },
  mainDiv: {
    margin: 0,
    padding: 0,
    display: "flex",
    width: "100px",
    height: "20px",
    backgroundColor: "#566e8a",
    borderRadius: "3px",
    // marginBottom: "7px",
    // marginLeft: "23px",
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    minWidth: "80px",
    borderRight: "0.8px solid grey",
    textAlign: "center",
  },
  titleField: {
    fontSize: "9px",
    fontWeight: "530",
    color: "#ffffff",
  },
  optionsIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: "13px",
    color: "white",
    position: "relative",
    right: "4px",
    top: "1px",
  },
};
