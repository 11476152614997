import {
  Add,
  AddPhotoAlternateOutlined,
  Close,
  Remove,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import {
  Autocomplete,
  Badge,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialCodes,
  dialogStyle,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  validateEmail,
} from "../../../helper";
import styled from "styled-components";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { StackRow } from "../../components/helper";
import phone from "phone";
import { InstagramSvg, Messenger, Whatsapp } from "../../../Svgs";
import { useNavigate } from "react-router-dom";
import { commonDialCodes } from "../../crm/Add";
import { createChatUser } from "../apiFunctions";
import { bulkActionChats } from "../../../redux/actions/chatActions";
import { CustomFieldView } from "../../customers/components/Info";

let limitError = `Maximum Chats Limit is Reached`;

const AddChats = ({
  addFormView,
  setAddFormView,
  chat,
  whatsapp = true,
  formData,
  onClose,
  disabledKeys = [],
  // this function has argument form State
  onSubmitFunction,
  submitLabel,
  heading,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [uploadingImage, setUploadingImage] = useState("");
  const [error, setError] = useState("");
  const [states, setStates] = useState([]);
  const [addressStateCode, setAddressSateCode] = useState({});
  const [CRMCustomFields, setCRMCustomFields] = useState([]);
  const defaultState = {
    // first_name: "",
    // last_name: "",
    // email: "",
    // nickname: "",
    // phone: "",
    // whatsapp: "",
    // dialcode_mobile: "91",
    dialcode_whatsapp: "91",
    // contact_type: "lead",
  };
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  console.log(custom_fields, "< custom_fields");
  const [inputData, setInputData] = useState(defaultState);
  const [showAdditionalInfoWhatsapp, setShowAdditionalInfoWhatsapp] =
    useState(false);
  const countriesArray = useSelector((state) => state.persist.countries);
  const stateArray = useSelector((state) => state.persist.states);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  //   const allReduxConversations = useSelector(
  //     (state) => state.chats.conversations
  //   );
  // let {  first_name,
  //   last_name, } = chat
  //   const allReduxCustomers = useSelector(
  //     (state) => state.chats.allCustomers
  //   );

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const inputFile = useRef();
  const navigate = useNavigate();
  const [dialcodeInfo, setDialcodeInfo] = useState({});
  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    setCRMCustomFields(Object.values(custom_fields?.crm));
  }, [custom_fields]);
  const onSubmit = async () => {
    // setLoading(true);
    // try {
    let meta_data = {};
    if (!inputData?.dialcode_whatsapp || !inputData?.whatsapp)
      return setError("Dialcode and Number is required.");
    let payload = {
      ...inputData,
      platform: "whatsapp",
      profile_pic: uploadingImage,
      platform_id: `${inputData?.dialcode_whatsapp || ""}${
        inputData?.whatsapp
      }`,
    };

    CRMCustomFields?.forEach((o) => {
      if (inputData?.[o?.value]) meta_data[o?.value] = inputData?.[o?.value];
    });
    if (isObjWithValues(meta_data)) payload.meta_data = meta_data;

    dispatch(
      bulkActionChats({
        data: {
          create: [payload],
        },
        setLoading,
        onSuccess: (res) => {
          console.log(res);
          let error = res?.find((o) => o?.error);
          if (error) {
            dispatch(
              setGlobalToast({
                show: true,
                message: error?.error?.replace("platform_id", "Number"),
                severity: "error",
              })
            );
          } else {
            setAddFormView(false);
            dispatch(
              setGlobalToast({
                show: true,
                message: "Chats Added Successfully",
                severity: "success",
              })
            );
          }
        },
      })
    );
    // let res = await createChatUser({
    //   payload,
    // });
    // console.log(res, "138");
    //   if (res?.success) {
    //     setAddFormView(false);

    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setLoading(false);
    // }
    // console.log(inputData);
  };
  const onEditCustomer = (payload) => {
    setInputData((pre) => ({ ...pre, ...payload }));
  };

  const submitCondition = async () => {
    if (inputData?.email && !validateEmail(inputData?.email))
      return setError("Email is not valid!");
    if (onSubmitFunction) {
      setLoading(true);
      await onSubmitFunction(inputData);
      setLoading(false);
    } else if (isObjWithValues(chat)) {
      onUpdate();
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    if (isObjWithValues(chat)) {
      setInputData(chat);
    }
    return () => {};
  }, [chat]);

  useEffect(() => {
    console.log(stateArray);

    if (isObjWithValues(stateArray)) {
      for (const key in stateArray) {
        if (key === inputData?.country) {
          setStates(stateArray[key]);
        }
      }
    }
  }, [inputData?.country]);
  useEffect(() => {
    if (chat?.profile_pic) setImageURL(chat?.profile_pic);
  }, [chat]);

  useEffect(() => {
    if (isObjWithValues(formData)) setInputData(formData);

    return () => {};
  }, [formData]);

  const inputArray =
    whatsapp || true
      ? [
          { label: "First Name", name: "first_name" },
          { label: "Last Name", name: "last_name" },
          // { label: "Address", name: "address_1", props: { xs: 12 } },
          // { label: "City", name: "city" },
          // { label: "State", name: "state" },
          // { label: "State", name: "state" },
          // { label: "Country", name: "country" },
        ]
      : [
          { label: "First Name", name: "first_name" },
          { label: "Last Name", name: "last_name" },
          { label: "Email", name: "email" },
          // { label: "Nick Name", name: "nickname" },
          // { label: "Mobile", name: "phone" },
          // { label: "WhatsApp", name: "whatsapp" },
        ];
  const uploadImage = (profile) => {
    setUploadingImage(true);
    const formData = new FormData();
    formData.append("file", profile, profile.name);

    const website = getWebsite();
    const token = getToken();
    if (website && token)
      axios
        .post(`${website}/wp-json/wp/v2/media`, formData, {
          headers: {
            accept: "application/json",
            Authorization: `Basic ${token}`,
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((res) => {
          console.log(res.data);
          let { data } = res;
          if (data.source_url) setImageURL(data.source_url);
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally((e) => setUploadingImage(false));
  };

  const inputChangeHandler = (e, newvalue) => {
    if (error) setError("");
    let { name, value } = e.target;

    if (name === "phone")
      value = value?.substring(0, dialcodeInfo?.phone?.max || 13);
    if (name === "whatsapp")
      value = value?.substring(0, dialcodeInfo?.whatsapp?.max || 13);

    setInputData((pre) => ({
      ...pre,
      [`${name}`]: typeof newvalue !== "object" && newvalue ? newvalue : value,
    }));
  };

  const onUpdate = () => {
    let updateKeys = [
      "first_name",
      "last_name",
      "email",
      "company_name",
      "contact_type",
      "dialcode_whatsapp",
      "whatsapp",
    ];
    let updateObj = {};
    Object.entries(inputData)?.forEach(([key, value]) => {
      if (updateKeys?.includes(key)) updateObj[key] = value;
    });
    dispatch(
      bulkActionChats({
        data: {
          update: [{ id: chat?.id, ...updateObj }],
        },
        setLoading,
        onSuccess: () => {
          setAddFormView(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Chats Update Successfully",
              severity: "success",
            })
          );
        },
      })
    );
  };

  return (
    <>
      <Dialog
        open={addFormView}
        onClose={() => {
          setAddFormView(false);
        }}
        maxWidth="xs"
        sx={isPhone ? {} : { ...dialogStyle }}
        fullScreen={isPhone}
        keepMounted={false}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h6" id="alert-dialog-title">
              {heading ? (
                heading
              ) : (
                <>
                  {isObjWithValues(chat) ? " Update " : " Add "}
                  Chats
                </>
              )}
            </Typography>

            <Stack direction="row" spacing={3} alignItems={"center"}>
              {/* {!whatsapp && isOurWebsite() && (
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/crm_bulk_add")}
                    size="small"
                  >
                    Bulk Add
                  </Button>
                )} */}
              <IconButton onClick={() => setAddFormView(false)}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {/* <Typography variant="h6">Profile</Typography> */}

          <input
            type="file"
            id="file"
            onChange={(e) => uploadImage(e.target.files[0])}
            ref={inputFile}
            style={{ display: "none" }}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {/* <FileUploader
              name="file"
              types={["JPG", "PNG"]}
              handleChange={(e) => console.log(e.target.files[0])}
              children={
                <>
                </>
              }
            /> */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Box sx={{ backgroundColor: "#fff", borderRadius: "50%" }}>
                  {chat?.platform === "facebook" ? (
                    <Messenger height={18} width={18} />
                  ) : chat?.platform === "instagram" ? (
                    <InstagramSvg height={20} width={20} />
                  ) : chat?.platform === "whatsapp" ? (
                    <Whatsapp height={18} width={18} />
                  ) : (
                    ""
                  )}
                </Box>
              }
            >
              <Button
                style={{
                  height: "7rem",
                  width: "7rem",
                  alignSelf: "center",
                  margin: "14px auto",
                  marginTop: "0px",
                  // display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "50%",
                  overflow: "hidden",
                  backgroundColor: "#ededed",
                  boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.2)",
                }}
                sx={{ p: 0 }}
                onClick={() => inputFile.current.click()}
              >
                <Box
                  style={{ alignSelf: "center", margin: "auto" }}
                  sx={{}}
                  color="GrayText"
                >
                  {!imageURL && !uploadingImage ? (
                    <>
                      <AddPhotoAlternateOutlined
                        style={{ height: "26px", width: "26px" }}
                      />
                      <Typography
                        variant="body2"
                        fontSize={11}
                        fontWeight="bold"
                      >
                        Upload photo
                      </Typography>
                    </>
                  ) : null}
                  {imageURL && !uploadingImage ? (
                    <img
                      src={
                        imageURL
                        // "https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                      }
                      style={{
                        height: "8rem",
                        width: "8rem",
                        // zIndex: -10,
                        // position: "relative",
                      }}
                    />
                  ) : null}
                  {uploadingImage ? (
                    <Box sx={{}}>
                      <CircularProgress size="18px" />
                      <Typography
                        variant="body2"
                        fontSize={12}
                        color="primary"
                        fontWeight="bold"
                      >
                        Uploading photo
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Button>
            </Badge>
            {/* <Typography variant="body2" color="text.secondary" fontSize={12}>
                Allowed *.jpeg, *.jpg, *.png
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                max size of 2 MB
              </Typography> */}
          </div>
          <Grid container spacing={3}>
            {inputArray.map((input) => (
              <Grid
                item
                xs={6}
                key={input.name}
                sx={{ alignItems: "center" }}
                {...(input?.props || {})}
              >
                <Label>{input.label}</Label>
                <TextField
                  disabled={disabledKeys?.includes(input.name)}
                  size="small"
                  fullWidth
                  name={input.name}
                  // placeholder={input.label}
                  onChange={inputChangeHandler}
                  value={inputData[`${input.name}`] || ""}
                />
              </Grid>
            ))}
            {!whatsapp ? (
              <Grid item xs={12}>
                <Label>Contact type</Label>
                <TextField
                  disabled={disabledKeys?.includes("contact_type")}
                  size="small"
                  // label="Adress Line 1"
                  name="contact_type"
                  fullWidth
                  onChange={inputChangeHandler}
                  value={inputData?.contact_type || ""}
                  select
                >
                  <MenuItem value="chat">Customer</MenuItem>
                  <MenuItem value="lead">Lead</MenuItem>
                </TextField>
              </Grid>
            ) : null}
            <Grid item xs={12} sx={{ alignItems: "center" }}>
              <Grid container alignItems="center">
                <Label>Mobile</Label>
                <Grid container spacing={2}>
                  <Grid item xs={3.5}>
                    <Select
                      size="small"
                      disabled={disabledKeys?.includes("dialcode_whatsapp")}
                      // disabled={chatInfo?.billing?.dialcode_mobile}
                      fullWidth
                      // value={inputData.dialcode_mobile}
                      // value={`+${
                      //   typeof inputData.dialcode_whatsapp === "string"
                      //     ? inputData.dialcode_whatsapp || ""
                      //     : inputData?.dialcode_whatsapp?.value || "91"
                      // }`}
                      value={inputData.dialcode_whatsapp}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInputData((pre) => ({
                          ...pre,
                          dialcode_whatsapp: `${e.target.value?.value}`,
                        }));
                      }}
                      renderValue={(value) => `${inputData.dialcode_whatsapp}`}
                      // renderValue={(value) => `${typeof value === "string" ? value : inputData.dialcode_mobile}`}
                    >
                      <ListSubheader>Common Dial Codes</ListSubheader>
                      {commonDialCodes.map((i) => {
                        return (
                          <MenuItem key={i.value} value={i}>
                            {i.label}
                          </MenuItem>
                        );
                      })}
                      <ListSubheader>Dial Codes</ListSubheader>
                      {dialCodes.map((i) => (
                        <MenuItem value={i}>{i.label}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextField
                      disabled={disabledKeys?.includes("whatsapp")}
                      size="small"
                      name="whatsapp"
                      fullWidth
                      type="number"
                      // placeholder={"Mobile"}
                      // disabled={chatInfo?.billing?.phone}
                      onChange={inputChangeHandler}
                      value={inputData.whatsapp}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {whatsapp ? (
              <>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Email</Label>
                  <TextField
                    disabled={disabledKeys?.includes("email")}
                    size="small"
                    // label="Adress Line 1"
                    name="email"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.email || ""}
                  />
                </Grid>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Company name</Label>
                  <TextField
                    disabled={disabledKeys?.includes("company_name")}
                    size="small"
                    // label="Adress Line 2"
                    name="company_name"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.company_name || ""}
                    // onChange={(e) => {
                    //   onEditCustomer({
                    //     billing: {
                    //       ...chatInfo.billing,
                    //       address_2: e.target.value,
                    //     },
                    //   });
                    // }}
                    // value={chatInfo.billing.address_2 || ""}
                  />
                </Grid>

                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Contact type</Label>
                  <TextField
                    disabled={disabledKeys?.includes("contact_type")}
                    size="small"
                    // label="Adress Line 1"
                    name="contact_type"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.contact_type || ""}
                    select
                  >
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="lead">Lead</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={isPhone ? 12 : 6}>
                  <Label>Team member assign</Label>
                  <TextField
                    size="small"
                    select
                    // label="Adress Line 1"
                    name="team_member"
                    fullWidth
                    onChange={inputChangeHandler}
                    value={inputData?.team_member || ""}
                  >
                    <MenuItem value="">None</MenuItem>
                    {isArrayWithValues(reduxTeamMembers) &&
                      reduxTeamMembers?.map((obj) => (
                        <MenuItem value={obj?.id}>
                          {obj?.first_name} {obj?.last_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {false && (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        startIcon={
                          showAdditionalInfoWhatsapp ? <Remove /> : <Add />
                        }
                        onClick={() =>
                          setShowAdditionalInfoWhatsapp((state) => !state)
                        }
                      >
                        Additional Info
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={showAdditionalInfoWhatsapp}>
                        <Grid container spacing={3}>
                          <Grid item xs={isPhone ? 12 : 6}>
                            <Label>Address Line 1</Label>
                            <TextField
                              size="small"
                              // label="Adress Line 1"
                              name="address_1"
                              fullWidth
                              onChange={inputChangeHandler}
                              value={inputData?.address_1 || ""}
                            />
                          </Grid>
                          <Grid item xs={isPhone ? 12 : 6}>
                            <Label>Address Line 2</Label>
                            <TextField
                              size="small"
                              // label="Adress Line 2"
                              name="address_2"
                              fullWidth
                              onChange={inputChangeHandler}
                              value={inputData?.address_2 || ""}
                              // onChange={(e) => {
                              //   onEditCustomer({
                              //     billing: {
                              //       ...chatInfo.billing,
                              //       address_2: e.target.value,
                              //     },
                              //   });
                              // }}
                              // value={chatInfo.billing.address_2 || ""}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Label>City</Label>
                            <TextField
                              size="small"
                              // label="City"
                              name="city"
                              fullWidth
                              onChange={inputChangeHandler}
                              value={inputData?.city || ""}
                              // onChange={(e) => {
                              //   onEditCustomer({
                              //     billing: {
                              //       ...chatInfo.billing,
                              //       city: e.target.value,
                              //     },
                              //   });
                              // }}
                              // value={chatInfo.billing.city || ""}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Label>Post Code</Label>
                            <TextField
                              size="small"
                              // label="Post Code"
                              name="postcode"
                              fullWidth
                              onChange={inputChangeHandler}
                              value={inputData?.postcode || ""}
                              // onChange={(e) => {
                              //   onEditCustomer({
                              //     billing: {
                              //       ...chatInfo.billing,
                              //       postcode: e.target.value,
                              //     },
                              //   });
                              // }}
                              // value={chatInfo.billing.postcode || ""}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Label>Country</Label>
                            <Autocomplete
                              id="combo-box-demo"
                              options={countriesArray || []}
                              size="small"
                              name="country"
                              onChange={(e, newInputValue) => {
                                // setAddressSateCode((pre) => ({
                                //   ...pre,
                                //   country: newInputValue?.label,
                                // }));
                                inputChangeHandler(e, newInputValue?.code);
                              }}
                              value={
                                // addressStateCode?.country ||
                                inputData?.country || ""
                              }
                              // value={country}
                              // onChange={(event, newInputValue) => {
                              //   setCountry(newInputValue.label);
                              //   setCountryCode(newInputValue.code);
                              //   onEditCustomer({
                              //     billing: {
                              //       ...chatInfo.billing,
                              //       country: newInputValue.code,
                              //     },
                              //   });
                              // }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField name="country" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Label>State</Label>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              name="state"
                              options={states || []}
                              value={
                                addressStateCode?.state ||
                                inputData?.state ||
                                ""
                              }
                              onChange={(e, newInputValue) => {
                                setAddressSateCode((pre) => ({
                                  ...pre,
                                  state: newInputValue?.label,
                                }));
                                inputChangeHandler(e, newInputValue?.code);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField name="state" {...params} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </>
                )}
              </>
            ) : null}
            {!whatsapp && (
              <>
                <Grid item xs={12} sx={{ alignItems: "center" }}>
                  <Grid container alignItems="center">
                    <Label>whatsapp</Label>
                    <Grid container spacing={2}>
                      <Grid item xs={3.5}>
                        <Select
                          size="small"
                          fullWidth
                          disabled={disabledKeys?.includes("dialcode_whatsapp")}
                          value={inputData.dialcode_whatsapp}
                          onChange={(e) => {
                            setInputData((pre) => ({
                              ...pre,
                              dialcode_whatsapp: `+${e.target.value}`,
                            }));
                          }}
                          renderValue={(value) =>
                            `${inputData.dialcode_whatsapp}`
                          }
                        >
                          <ListSubheader>Common Dial Codes</ListSubheader>
                          {commonDialCodes.map((i) => {
                            return (
                              <MenuItem key={i.value} value={i.value}>
                                {i.label}
                              </MenuItem>
                            );
                          })}
                          <ListSubheader>Dial Codes</ListSubheader>
                          {dialCodes.map((i) => (
                            <MenuItem value={i.value}>{i.label}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={8.5}>
                        <TextField
                          size="small"
                          name="whatsapp"
                          // placeholder="WhatsApp"
                          fullWidth
                          onChange={inputChangeHandler}
                          value={inputData.whatsapp}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Grid item xs={12} sx={{ alignItems: "center" }}>
                <Label>{"Unique ID"}</Label>
                <TextField
                  size="small"
                  fullWidth
                  name={"unique_id"}
                  // placeholder={input.label}
                  onChange={inputChangeHandler}
                  value={inputData[`unique_id`]}
                />
              </Grid> */}
            {CRMCustomFields?.map((i) => {
              return (
                <Grid item key={i.value} xs={12} md={6} id={`field_${i.value}`}>
                  <CustomFieldView
                    field={i}
                    onEditCustomer={onEditCustomer}
                    customerInfo={inputData}
                    textFieldProps={{
                      size: "small",
                    }}
                    // errors={errors}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Collapse in={Boolean(error)}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "500",
                mt: 1,
                color: "error.main",
                textAlign: "center",
                margin: "0px 20px",
              }}
            >
              {error}
            </Typography>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <StackRow>
            <Button
              onClick={() => {
                setAddFormView && setAddFormView(false);
                setInputData && setInputData(defaultState);
                setImageURL && setImageURL("");
                setLoading && setLoading(false);
                onClose && onClose();
                // setCreate && setCreate(false);
              }}
              size="large"
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={error === limitError}
              // onClick={isObjWithValues(chat) ? onUpdate : onSubmit}
              onClick={submitCondition}
              size="large"
            >
              {submitLabel ? (
                submitLabel
              ) : (
                <>{isObjWithValues(chat) ? "Update" : "Add"} Chats</>
              )}
            </LoadingButton>
          </StackRow>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(AddChats);

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;
