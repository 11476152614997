import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopTabs from "./components/TopTabs";
import { TabPanel, getItem, setItem } from "../../helper";
import Campaign from "../campaign";
import Flows from "../flows";

function MarketingAutomation() {
  const [selectedTab, setSelectedTab] = useState("campaigns");
  const [mount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
    setSelectedTab(getItem("marketing_automation_selected_tab") || "campaigns");
  }, []);

  useEffect(() => {
    if (mount) setItem({ marketing_automation_selected_tab: selectedTab });
  }, [selectedTab]);

  return (
    <Box>
      <TopTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <TabPanel value={selectedTab} index={"campaigns"}>
        <Campaign />
      </TabPanel>
      <TabPanel value={selectedTab} index={"workflows"}>
        <Flows />
      </TabPanel>
    </Box>
  );
}

export default MarketingAutomation;
