import {
  AccountBox,
  Add,
  AddCircleOutlineOutlined,
  ArrowBackIosNew,
  ChatOutlined,
  CheckCircle,
  Close,
  Delete,
  EmailOutlined,
  LocalOffer,
  NotificationsActive,
  NotificationsActiveOutlined,
  ReceiptLongOutlined,
  SendRounded,
  WhatsappOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchIPInfo,
  formatDate,
  getAxiosError,
  getRandomString,
  getRelativeTimeDifference,
  getToken,
  getWebsite,
  isArrayWithValues,
  isEmailValid,
  isObjWithValues,
} from "../../helper";

import UAParser from "ua-parser-js";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { default as CustomerCatalogs } from "./Catalogs";

import Chat from "../chat/components/ChatWindow";
import axios from "axios";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { Doughnut } from "react-chartjs-2";
import {
  Android,
  Apple,
  ChatIllustration,
  NotFound,
  Windows,
} from "../../Svgs";
import { textAlign, useTheme } from "@mui/system";
import ReactApexChart from "react-apexcharts";
import {
  fetchRetailerActivity,
  updateCustomerInRedux,
} from "../../redux/actions/crmActions";
import Pricing from "./crm_pricing/Pricing";
import { getProductGroups } from "../../redux/actions/settingActions";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import {
  addRetailerIdInInvite,
  AllAcceess,
  dialCodes,
  sendInvitationRetailer,
} from "./Add";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { getLabelFromType } from "../visitors/VisitorsDetails";
import Quotations from "../quotation/Quotations";
import { getAllQuotation } from "../../redux/actions/productActions";

const defaultCustomer = {
  first_name: "",
  last_name: "",
  email: "",
};

// const AllAcceess = [
//   { label: "Retailer App", value: "retailer_app", disabled: false },
//   { label: "Catalogs", value: "catalogs", disabled: false },
//   { label: "Website", value: "website", disabled: false },
//   { label: "Instore", value: "instore", disabled: false },
//   { label: "Social Media", value: "social_media", disabled: false },
//   { label: "Market Places", value: "market_places", disabled: true },
// ];

let defaultInfo = {
  first_name: "",
  last_name: "",
  email: "",
  full_name: "",
  company_name: "",
  gst: "",
  landline: "",
  whatsapp: "",
  whatsapp_alt: "",
  contact_1: "",
  contact_2: "",
  billing_mobile: "",
  customer_group: "",
  product_groups: [],
  access: [],
  label: "",
  currency: "",
  all_product_access: false,
  connect_status: "",
  job_role: "",
};

function View() {
  const topRef = useRef(null);
  let [info, setInfo] = useState({ ...defaultInfo });
  const [tabs, setTabs] = useState([]);
  const [customer, setCustomer] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [updatingCustomer, setUpdatingCustomer] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [isGupshupIntegrated, setIsGupshupIntegrated] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [crmAccess, setCrmAccess] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const access = useSelector((state) => state.user.access);
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const main_socket = useSelector((state) => state.socket.main_socket);

  const dispatch = useDispatch();

  const allCustomers = useSelector((state) => state.customers.allCustomers);
  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );
  const reduxIntegrations = useSelector((state) => state.settings.integrations);

  useEffect(() => {
    if (!isArrayWithValues(reduxQuotations)) dispatch(getAllQuotation());
    // if (!isArrayWithValues(retailerInvites)) dispatch(getRetailerInvites());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(allCustomers) && isObjWithValues(customer))
      setCustomer(allCustomers.find((i) => i.id === customer.id) || customer);
  }, [allCustomers, customer]);

  useEffect(() => {
    if (access && isArrayWithValues(access.crm)) {
      setCrmAccess(access.crm);
      let array = [];
      let tabs = access.crm;
      if (tabs.includes("edit")) array.push("Info");
      if (tabs.includes("catalogs")) array.push("Catalogs");
      if (tabs.includes("quotations")) array.push("Quotations");
      if (tabs.includes("chat") && isGupshupIntegrated) array.push("Chats");
      if (tabs.includes("analytics")) array.push("Analytics");
      if (tabs.includes("notes")) array.push("Notes");
      if (tabs.includes("pricing")) array.push("Pricing");
      if (tabs.includes("contacts")) array.push("Contacts");
      setTabs(array);
    }
  }, [access, isGupshupIntegrated]);

  useEffect(() => {
    if (isArrayWithValues(reduxIntegrations)) {
      let obj = reduxIntegrations.find((i) => i.gupshup);
      if (isObjWithValues(obj) && obj.gupshup) {
        let { gupshup } = obj;
        let { gupshup_app_name, gupshup_api_key, enabled } = gupshup;
        if (gupshup_api_key && enabled) setIsGupshupIntegrated(true);
        else setIsGupshupIntegrated(false);
      } else setIsGupshupIntegrated(false);
    }
  }, [reduxIntegrations]);

  useEffect(() => {
    // if (!productSettings || !isObjWithValues(productSettings))
    //   dispatch(getProductSettings());
  }, [productSettings]);

  React.useEffect(() => {
    let { hash } = location;
    if (hash && hash.includes("analytics"))
      setSelectedTab(tabs.indexOf("Analytics"));
    if (hash && hash.includes("contacts"))
      setSelectedTab(tabs.indexOf("Contacts"));
    if (hash && hash.includes("chats")) setSelectedTab(tabs.indexOf("Chats"));
  }, [tabs]);

  useEffect(async () => {
    if (location.hash === "#chat") setSelectedTab(2);
    if (location.pathname.includes("/customers")) {
      let pieces = location.pathname.split("/");
      let customer = getCustomerFromRedux(pieces[pieces.length - 1]);
      if (isObjWithValues(customer)) setCustomerView(customer);
      else {
        let customer = await fetchCustomer(
          pieces[pieces.length - 1],
          setCustomerLoading
        );
        setCustomerView(customer);
      }
    }
    setTimeout(() => scrollToTop(), 10);
  }, [location.pathname]);
  const scrollToTop = () => {
    const domNode = topRef.current;
    if (domNode) {
      domNode.scrollTop = 0;
    }
  };
  const setCustomerView = (customer) => {
    let obj = { ...customer };
    obj.full_name = `${obj.first_name} ${obj.last_name}`;
    setCustomer(obj);
  };
  const getCustomerFromRedux = (id) => {
    try {
      if (id) {
        if (isArrayWithValues(allCustomers)) {
          let customer = allCustomers.find((i) => i.id === Number(id));
          if (isObjWithValues(customer)) return customer;
          else return {};
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadImage = (profile) => {
    if (!profile) return;
    setUploadingImage(true);
    const formData = new FormData();
    formData.append("file", profile, profile.name);

    const website = getWebsite();
    const token = getToken();
    if (website && token)
      axios
        .post(`${website}/wp-json/wp/v2/media`, formData, {
          headers: {
            accept: "application/json",
            Authorization: `Basic ${token}`,
            "Content-Type": `multipart/form-data`,
          },
        })
        .then(async (res) => {
          let { data } = res;
          if (data.source_url) {
            setImageURL(data.source_url);
            let meta_data = [{ key: "profile_url", value: data.source_url }];
            await updateCustomer(customer.id, { meta_data });
            setUploadingImage(false);
          } else setUploadingImage(false);
        })
        .catch((e) => {
          console.log(getAxiosError(e));
          let error = getAxiosError(e);
          if (error.message)
            setToast({
              show: true,
              message: error.message,
              severity: "error",
            });
          setUploadingImage(false);
        });
    // .finally((e) => );
  };

  const onUpdateUser = async (obj) => {
    setUpdatingCustomer(true);
    // console.log(JSON.stringify(obj, null, 2));
    let { meta_data } = obj;
    if (Array.isArray(meta_data) && imageURL)
      meta_data.push({ key: "profile_url", value: imageURL });

    const res = await updateCustomer(customer.id, obj);
    main_socket.emit("update_suppliers", { update: true });
    console.log(main_socket);
    setUpdatingCustomer(false);
    if (res) {
      setCustomer(res);
      dispatch(updateCustomerInRedux(res));
      setToast({
        show: true,
        message: "User updated successfully!",
        severity: "success",
      });
    } else
      setToast({
        show: true,
        message: "Couldn't update user, please try again!",
        severity: "error",
      });
  };
  return (
    <div ref={topRef}>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNew />}
        onClick={() => navigate("/customers")}
      >
        Customers
      </Button>
      <Box sx={{ width: "100%", padding: "0px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
            aria-label="basic tabs example"
            sx={{
              overflowX: "scroll",
              display: "flex",
            }}
            className="scrollbar-hidden"
            variant="scrollable"
          >
            {tabs.map((i, index) => {
              return <Tab label={i} {...a11yProps(index)} />;
            })}
            {/* <Tab label="Info" {...a11yProps(0)} sx={{ p: 0 }} />
            <Tab label="Catalogs" {...a11yProps(1)} />
            {crmAccess.includes("chat") && (
              <Tab label="Chat" {...a11yProps(2)} />
            )}
            <Tab
              label="Analytics"
              {...a11yProps(crmAccess.includes("chat") ? 3 : 2)}
            />
            <Tab
              label="Notes"
              {...a11yProps(crmAccess.includes("chat") ? 4 : 3)}
            />
            <Tab
              label="Pricing"
              {...a11yProps(crmAccess.includes("chat") ? 5 : 4)}
            /> */}
          </Tabs>
        </Box>
        {tabs.includes("Info") && (
          <TabPanel
            value={selectedTab}
            style={{ padding: "0px", margin: "0px" }}
            index={tabs.indexOf("Info")}
          >
            <Grid container spacing={2} sx={{ p: 0 }} mt={2}>
              <Grid item xs={12} sm={4} sx={{ padding: 0 }}>
                <PrimaryCard
                  imageURL={imageURL}
                  customer={customer}
                  uploadImage={uploadImage}
                  uploadingImage={uploadingImage}
                />
                {/* <Box sx={{ height: "10px" }} /> */}
                {/* <Button
                  // variant="contained"
                  fullWidth
                  size="small"
                  endIcon={<NotificationsActive />}
                >
                  Send Invite
                </Button> */}
                <SessionCard customer={customer} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <BasicDetails
                  customer={customer}
                  onUpdateUser={onUpdateUser}
                  updatingCustomer={updatingCustomer}
                  setImageURL={setImageURL}
                  crmAccess={crmAccess}
                  isMobile={isMobile}
                  productSettings={productSettings}
                  info={info}
                  setInfo={setInfo}
                  setUpdatingCustomer={setUpdatingCustomer}
                />
                {/* <Notes
                notes={customer.notes}
                customer={customer}
                setCustomer={setCustomer}
                crmAccess={crmAccess}
              /> */}
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <PrimaryCard customer={customer} /> */}
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </TabPanel>
        )}
        {tabs.includes("Catalogs") && (
          <TabPanel value={selectedTab} index={tabs.indexOf("Catalogs")}>
            <CustomerCatalogs
              fetchingCustomer={customerLoading}
              customer={customer}
            />
          </TabPanel>
        )}
        {tabs.includes("Quotations") && (
          <TabPanel value={selectedTab} index={tabs.indexOf("Quotations")}>
            <Quotations
              fetchingCustomer={customerLoading}
              customer={customer}
              customerId={customer.id}
            />
          </TabPanel>
        )}
        {tabs.includes("Chats") && (
          <TabPanel value={selectedTab} index={tabs.indexOf("Chats")}>
            <Chat
              customer={customer}
              customerLoading={customerLoading}
              setCrmViewCustomer={setCustomer}
            />
          </TabPanel>
        )}
        {/* {crmAccess.includes("chat") && (
        )} */}
        {tabs.includes("Analytics") && (
          <TabPanel
            value={selectedTab}
            index={tabs.indexOf("Analytics")}
            // index={crmAccess.includes("chat") ? 3 : 2}
          >
            <Analytics customer={customer} customerId={customer.id} />
          </TabPanel>
        )}
        {tabs.includes("Notes") && (
          <TabPanel
            value={selectedTab}
            index={tabs.indexOf("Notes")}
            // index={crmAccess.includes("chat") ? 4 : 3}
          >
            <Notes
              notes={customer.notes}
              customer={customer}
              setCustomer={setCustomer}
              crmAccess={crmAccess}
              fetchingCustomer={customerLoading}
            />
          </TabPanel>
        )}
        {tabs.includes("Pricing") && (
          <TabPanel
            value={selectedTab}
            index={tabs.indexOf("Pricing")}
            // index={crmAccess.includes("chat") ? 5 : 4}
          >
            <Pricing
              customer={customer}
              customerCurrency={info.currency}
              setCustomer={setCustomer}
              crmAccess={crmAccess}
              fetchingCustomer={customerLoading}
            />
          </TabPanel>
        )}
        {tabs.includes("Contacts") && (
          <TabPanel
            value={selectedTab}
            index={tabs.indexOf("Contacts")}
            // index={crmAccess.includes("chat") ? 5 : 4}
          >
            <Contacts
              customer={customer}
              customerCurrency={info.currency}
              setCustomer={setCustomer}
            />
          </TabPanel>
        )}
      </Box>
    </div>
  );
}

export default View;

const PrimaryCard = ({ customer, imageURL, uploadImage, uploadingImage }) => {
  let { first_name, last_name, email, full_name } = customer;
  const inputFile = useRef(null);
  return (
    <Card sx={{ p: 0 }}>
      <CardContent sx={{ p: 0 }}>
        <input
          type="file"
          id="file"
          onChange={(e) => uploadImage(e.target.files[0])}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            margin: "10px 0px",
          }}
        >
          <Button
            style={{
              height: "8rem",
              width: "8rem",
              alignSelf: "center",
              margin: "14px auto",
              // display: "block",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              cursor: "pointer",
              borderRadius: "50%",
              overflow: "hidden",
              backgroundColor: "#ededed",
              boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.2)",
            }}
            sx={{ p: 0 }}
            onClick={() => inputFile.current.click()}
          >
            <Box
              style={{ alignSelf: "center", margin: "auto" }}
              sx={{}}
              color="GrayText"
            >
              {!imageURL && !uploadingImage ? (
                <>
                  <AddPhotoAlternateOutlinedIcon
                    style={{ height: "30px", width: "30px" }}
                  />
                  <Typography variant="body2" fontSize={12} fontWeight="bold">
                    Upload photo
                  </Typography>
                </>
              ) : null}
              {imageURL && !uploadingImage ? (
                <img
                  src={
                    imageURL
                    // "https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                  }
                  style={{
                    height: "8rem",
                    width: "8rem",
                    // zIndex: -10,
                    // position: "relative",
                  }}
                />
              ) : null}
              {uploadingImage ? (
                <Box sx={{}}>
                  <CircularProgress size="18px" />
                  <Typography
                    variant="body2"
                    fontSize={12}
                    color="primary"
                    fontWeight="bold"
                  >
                    Uploading photo
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Button>
          <Typography
            variant="body2"
            color="text.secondary"
            fontSize={12}
            mt={3}
          >
            Allowed *.jpeg, *.jpg, *.png
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontSize={12}
            mt={1}
          >
            max size of 3.1 MB
          </Typography>

          {/* <Avatar
            style={{
              height: "8rem",
              width: "8rem",
              alignSelf: "center",
              margin: "14px auto",
            }}
          ></Avatar> */}
          <div>
            <Typography variant="h4" mt={4}>
              {full_name}
            </Typography>
            {email ? (
              <Typography variant="body2" mt={2}>
                {email}
              </Typography>
            ) : null}
          </div>
        </div>
        {/* <div>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  // checked={catalogForm.public}
                  // onChange={(e) =>
                  //   changeCatalogForm({
                  //     public: e.target.checked,
                  //   })
                  // }
                  defaultChecked
                  color="primary"
                />
              }
              label="Verified"
              labelPlacement="start"
              style={{
                justifyContent: "space-between",
                padding: "14px 24px",
                marginLeft: "0px",
              }}
            />
          </FormGroup>
        </div> */}
      </CardContent>
    </Card>
  );
};

const SessionCard = ({ customer }) => {
  let [sessions, setSessions] = useState([]);
  let { sessions: customerSessions } = customer;
  let [location, setLocation] = useState("");
  let [browser, setBrowser] = useState("");
  let [OS, setOS] = useState("");
  let [loading, setLoading] = useState(true);
  let [noSessions, setNoSessions] = useState(false);
  useEffect(async () => {
    if (isObjWithValues(customerSessions)) {
      setNoSessions(false);
      let temp = 0;
      let arr = [];
      for (let session of Object.values(customerSessions)) {
        if (session) {
          let obj = {};
          let location = await fetchIPInfo(session.ip);
          temp++;
          let ua = new UAParser(session.ua).getResult();
          obj.browser = `${ua.browser.name} / `;
          obj.os = ua.os.name;
          if (isObjWithValues(location)) {
            let {
              geoplugin_city,
              geoplugin_regionCode,
              geoplugin_countryCode,
            } = location;
            obj.location = `${geoplugin_city ? geoplugin_city + ", " : ""}${
              geoplugin_regionCode ? geoplugin_regionCode + ", " : ""
            }${geoplugin_countryCode || ""}`;
          }
          arr.push(obj);
          if (Object.keys(customerSessions).length === temp) setLoading(false);
        }
      }
      setSessions(arr);
    } else {
      setLoading(false);
      setNoSessions(true);
    }
  }, [customer]);
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Sessions
        </Typography>
        {noSessions && !loading ? (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center", mt: 3 }}
          >
            No sessions
          </Typography>
        ) : null}
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "90px",
            }}
          >
            <CircularProgress sx={{ mt: 7 }} />
          </Box>
        ) : isArrayWithValues(sessions) ? (
          sessions.map((i) => (
            <Box sx={{ m: "14px 0px" }}>
              <Stack direction="row" alignItems={"center"}>
                <Box sx={{ mr: 2 }}>
                  {i.os === "Mac OS" || i.os === "iOS" ? (
                    <Apple />
                  ) : i.os === "Android" ? (
                    <Android />
                  ) : i.os === "Windows" ? (
                    <Windows />
                  ) : null}
                </Box>
                <Box>
                  <Typography variant="h6">
                    {i.browser} {i.os}
                  </Typography>
                  <Typography variant="body1">{i.location}</Typography>
                </Box>
              </Stack>
            </Box>
          ))
        ) : null}
      </CardContent>
    </Card>
  );
};

const BasicDetails = ({
  customer,
  onUpdateUser,
  updatingCustomer,
  setImageURL,
  crmAccess,
  isMobile,
  productSettings,
  info,
  setInfo,
  setUpdatingCustomer,
}) => {
  const [toast, setToast] = useState({ show: false });
  const [addLabelOpen, setAddLabelOpen] = useState(false);
  const [addLabel, setAddLabel] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [addLabelColor, setAddLabelColor] = useState(
    `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );
  const names = ["Group 1", "Group 2", "Group 3"];

  const {
    id,
    first_name,
    last_name,
    email,
    company_name,
    gst,
    whatsapp,
    whatsapp_alt,
    contact_1,
    contact_2,
    billing_mobile,
    product_groups,
    access,
    label,
    currency,
    connect_status,
    job_role,
    all_product_access,
  } = info;

  const [productGroups, setProductGroups] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );
  const labels = useSelector((state) => state.settings.customerLabels);
  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );

  useEffect(() => {
    if (isArrayWithValues(reduxProductGroups))
      setProductGroups(reduxProductGroups.filter((i) => i.enabled));
  }, [reduxProductGroups]);

  useEffect(() => {
    if (productSettings && isArrayWithValues(productSettings.currency))
      setAllCurrency(productSettings.currency);
  }, [productSettings]);

  useEffect(() => {
    let { first_name, last_name, email, meta_data, id } = customer;
    let obj = {
      id,
      first_name,
      last_name,
      email,
      full_name: `${first_name} ${last_name}`.trim(),
    };
    const metaKeys = [
      "company_name",
      "gst",
      "whatsapp",
      "whatsapp_alt",
      "billing_mobile",
      "contact_1",
      "contact_2",
      "customer_group",
      "profile_url",
      "access",
      "label",
      "product_groups",
      "currency",
      "connect_status",
      "all_product_access",
      "job_role",
    ];
    if (isArrayWithValues(meta_data)) {
      let length = meta_data.length;
      for (let i = 0; i < length; i++) {
        // console.log(metaObj.key && metaObj.key);
        let metaObj = meta_data[i];
        if (metaKeys.includes(metaObj.key)) obj[metaObj.key] = metaObj.value;
      }
    }
    if (obj.profile_url) setImageURL(obj.profile_url);
    setInfo({ ...defaultInfo, ...obj });
  }, [customer]);

  const changeInfo = (label, value) => {
    setInfo({ ...info, [label]: value });
  };

  const onUpdate = async ({ connect_status }) => {
    if (!crmAccess.includes("edit")) return;
    const { first_name, last_name, email } = info;
    let obj = {
      first_name,
      last_name,
      email,
    };
    if (!email) delete obj.email;
    const metaKeys = [
      "company_name",
      "gst",
      "whatsapp",
      "whatsapp_alt",
      "billing_mobile",
      "contact_1",
      "contact_2",
      "customer_group",
      "product_groups",
      "access",
      "label",
      // "connect_status",
      "currency",
      // "all_product_access",
      "job_role",
    ];
    let meta_data = [];
    for (let i = 0; i < metaKeys.length; i++) {
      // if (info[metaKeys[i]])
      meta_data.push({
        key: metaKeys[i],
        value: info[metaKeys[i]] || "",
      });
    }
    if (connect_status)
      meta_data.push({
        key: "connect_status",
        value: connect_status,
      });
    meta_data.push({
      key: "all_product_access",
      value: Boolean(all_product_access),
    });
    obj.meta_data = meta_data;
    // return;
    onUpdateUser({ ...obj });
  };

  const handleProductGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    return changeInfo(
      "product_groups",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onAccessClick = (value) => {
    if (!value) return;
    let array = [].concat(access);
    if (array.includes(value)) array = array.filter((i) => i !== value);
    else array.push(value);
    changeInfo("access", array);
  };

  const onLabelClick = (value) => changeInfo("label", value);

  const onCurrencyClick = (value) => changeInfo("currency", value);

  const onLabelAdd = () => {
    setAddLabelOpen(false);
    let obj = {
      label: addLabel,
      value: addLabel,
      color: addLabelColor,
    };
    // console.log([...labels, { ...obj }]);
    dispatch({
      type: "EDIT_SETTINGS",
      payload: { customerLabels: [...labels, { ...obj }] },
    });
  };

  const sendInvitation = async () => {
    try {
      setUpdatingCustomer(true);
      await sendInvitationRetailer(id);
      await addRetailerIdInInvite(retailerInvites);
      onUpdate({ connect_status: "invited" });
    } catch (error) {
      setToast({
        show: true,
        message: "Unable to send invitation, please try again later",
        severity: "error",
      });
    }
  };

  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card style={{}}>
        <CardContent>
          <Stack direction="row" justifyContent={"flex-end"}>
            {connect_status !== "invited" && (
              <LoadingButton
                // variant="contained"
                // fullWidth
                disabled={
                  (!all_product_access && !isArrayWithValues(product_groups)) ||
                  updatingCustomer
                }
                size="small"
                startIcon={<NotificationsActiveOutlined />}
                sx={{ mr: 2 }}
                onClick={sendInvitation}
              >
                Send Invite
              </LoadingButton>
            )}
            <LoadingButton
              loading={updatingCustomer}
              variant="contained"
              // size="small"
              sx={{ float: "right", margin: "7px 0px" }}
              onClick={onUpdate}
              disabled={!crmAccess.includes("edit")}
            >
              Update details
            </LoadingButton>
          </Stack>
          <Stack mt={3}>
            <Typography variant="h6">Access to</Typography>
          </Stack>
          <Stack direction={"row"} sx={{ flexWrap: "wrap", mt: 2 }}>
            {AllAcceess.map((item) => {
              return (
                <AccessCard
                  obj={item}
                  isMobile={isMobile}
                  isSelected={
                    Array.isArray(access) && access.includes(item.value)
                  }
                  onClick={onAccessClick}
                />
              );
            })}
          </Stack>
          {/* <Stack direction="row" alignItems={"center"} mt={3}>
            <Typography variant="h6">Labels</Typography>
            <IconButton onClick={() => setAddLabelOpen(true)} sx={{ p: 1 }}>
              <AddCircleOutlineOutlined sx={{}} />
            </IconButton>
          </Stack>
          <Stack direction={"row"} sx={{ flexWrap: "wrap", mt: 2 }}>
            {isArrayWithValues(labels) &&
              labels.map((item) => {
                return (
                  <AccessCard
                    obj={item}
                    isMobile={isMobile}
                    isSelected={
                      label === item.value
                      // Array.isArray(access) && access.includes(item.value)
                    }
                    onClick={onLabelClick}
                  />
                );
              })}
          </Stack> */}
          {/* <Stack mt={3}>
            <Typography variant="h6">Currency you deal In</Typography>
          </Stack>
          <Stack direction={"row"} sx={{ flexWrap: "wrap", mt: 2 }}>
            {allCurrency.map((item) => {
              return (
                <AccessCard
                  obj={item}
                  label={`${item.label} (${item.symbol})`}
                  isMobile={isMobile}
                  isSelected={currency === item.value}
                  onClick={onCurrencyClick}
                />
              );
            })}
          </Stack> */}
          <Stack mt={3}>
            <Typography variant="h6">Basic details</Typography>
          </Stack>
          <Grid container spacing={4} mt={2}>
            <Grid item xs={6}>
              <TextField
                label="First name"
                fullWidth
                onChange={(e) => changeInfo("first_name", e.target.value)}
                value={first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last name"
                fullWidth
                onChange={(e) => changeInfo("last_name", e.target.value)}
                value={last_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Company name"
                fullWidth
                onChange={(e) => changeInfo("company_name", e.target.value)}
                value={company_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="GST"
                fullWidth
                onChange={(e) => changeInfo("gst", e.target.value)}
                value={gst}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Job Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={job_role}
                  label="Job Role"
                  onChange={(e) => changeInfo("job_role", e.target.value)}
                >
                  {jobRoles.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Contact Info</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                fullWidth
                onChange={(e) => changeInfo("email", e.target.value)}
                value={email}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Whatsapp"
                fullWidth
                onChange={(e) => changeInfo("whatsapp", e.target.value)}
                value={whatsapp}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Landline"
                fullWidth
                onChange={(e) => changeInfo("billing_mobile", e.target.value)}
                value={billing_mobile}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact person 1"
                fullWidth
                onChange={(e) => changeInfo("contact_1", e.target.value)}
                value={contact_1}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact person 2"
                fullWidth
                onChange={(e) => changeInfo("contact_2", e.target.value)}
                value={contact_2}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Alternate whatsapp"
                fullWidth
                onChange={(e) => changeInfo("whatsapp_alt", e.target.value)}
                value={whatsapp_alt}
                type="number"
              />
            </Grid>
          </Grid>
          <Stack mt={3}>
            <Typography variant="h6">Product Groups</Typography>
          </Stack>
          <FormGroup
            sx={{ flex: 1, pt: 3 }}
            style={{
              padding: "0px",
              paddingTop: "13px",
              paddingRight: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={all_product_access}
                  onChange={(e) => changeInfo("all_product_access", e)}
                  style={
                    all_product_access
                      ? {
                          margin: "4px 8px",
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                          marginLeft: "auto",
                        }
                      : {
                          margin: "4px 8px",
                          marginLeft: "auto",
                        }
                  }
                />
              }
              label="All Products Access"
              labelPlacement="start"
            />
          </FormGroup>

          <Collapse in={!all_product_access}>
            <FormControl sx={{ mt: 3 }} fullWidth>
              <InputLabel id="demo-multiple-chip-label">
                Select groups
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // labelId="demo-multiple-chip-label"
                // id="demo-multiple-chip"
                multiple
                value={product_groups || []}
                onChange={handleProductGroupChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Select groups"
                  />
                }
                // renderValue={(selected) => (
                //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                //     {selected.map((value) => (
                //       <Chip key={value.id} label={value.title} />
                //     ))}
                //   </Box>
                // )}
                // MenuProps={MenuProps}
              >
                {productGroups.map((group) => {
                  return <MenuItem value={group.id}>{group.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Collapse>
        </CardContent>
        <Dialog
          maxWidth="xs"
          fullWidth
          open={addLabelOpen}
          onClose={() => setAddLabelOpen(false)}
        >
          <DialogTitle>Add Label</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Customer label"
              // type="email"
              fullWidth
              variant="standard"
              value={addLabel}
              onChange={(e) => setAddLabel(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 2 }}
                      onClick={() =>
                        setAddLabelColor(
                          `#${Math.floor(Math.random() * 16777215).toString(
                            16
                          )}`
                        )
                      }
                    >
                      <LocalOffer
                        style={{
                          height: "16px",
                          width: "16px",
                          color: addLabelColor,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddLabelOpen(false)}>Cancel</Button>
            <Button onClick={onLabelAdd}>Add</Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

const Notes = ({
  notes,
  customer,
  fetchingCustomer,
  setCustomer,
  crmAccess,
}) => {
  const id = customer && customer.id;
  // console.log(isArrayWithValues)
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [showDelete, setShowDelete] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [form, setForm] = useState({
    title: "",
    note: "",
    id: "",
  });
  const [dialogType, setDialogType] = useState("");
  const theme = useTheme();
  // console.log(theme);
  useEffect(() => {
    if (isObjWithValues(customer))
      setCustomerName(`${customer.first_name} ${customer.last_name}`);
  }, [customer]);

  const changeForm = (label, value) => {
    setForm({ ...form, [label]: value });
  };

  const onEditPress = (obj) => {
    setForm({ ...obj });
    setDialogType("edit");
    setShowDialog(true);
  };
  const onAddPress = () => {
    setForm({ title: "", note: "", id: "" });
    setDialogType("add");
    setShowDialog(true);
  };
  const onDeletePress = (obj) => {
    setForm({ ...obj });
    setShowDelete(true);
  };
  const onAddNote = async () => {
    if (!form.title)
      return setToast({
        show: true,
        severity: "error",
        message: "Please enter title",
      });
    setLoading(true);
    const obj = {
      additional_custom_data: {
        notes: {
          request: "add",
          data: {
            title: form.title,
            note: form.note,
          },
        },
      },
    };
    const updatedCustomer = await updateCustomer(customer.id, obj);
    setLoading(false);
    setShowDialog(false);
    if (isObjWithValues(updatedCustomer) && setCustomer)
      setCustomer(updatedCustomer);
  };
  const onEditNote = async () => {
    setLoading(true);
    const obj = {
      additional_custom_data: {
        notes: {
          request: "update",
          data: {
            id: form.id,
            title: form.title,
            note: form.note,
          },
        },
      },
    };
    const updatedCustomer = await updateCustomer(customer.id, obj);
    setLoading(false);
    setShowDialog(false);
    if (isObjWithValues(updatedCustomer) && setCustomer)
      setCustomer(updatedCustomer);
  };
  const onDeleteNote = async () => {
    setLoading(true);
    const obj = {
      additional_custom_data: {
        notes: {
          request: "delete",
          data: { id: form.id, title: form.title, note: form.note },
        },
      },
    };
    const updatedCustomer = await updateCustomer(customer.id, obj);
    setLoading(false);
    setShowDelete(false);
    if (isObjWithValues(updatedCustomer) && setCustomer)
      setCustomer(updatedCustomer);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" ml={1} fontWeight="bold">
              {customerName && `${customerName}'s`} Notes
            </Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={() => onAddPress(true)}
            >
              New
            </Button>
          </Stack>
          <Box sx={{ height: "10px" }} />
          {isArrayWithValues(notes) &&
            notes.map((note) => (
              <NoteView
                note={note}
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
                crmAccess={crmAccess}
              />
            ))}
          {!isArrayWithValues(notes) && !fetchingCustomer && (
            <Typography color="text.secondary" my={3} textAlign={"center"}>
              No Notes added yet.
            </Typography>
          )}
        </CardContent>
      </Card>
      <div>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="sm"
          fullWidth
          {...globalPopupStyle}
        >
          <DialogTitle>
            {dialogType === "add" ? "Add" : dialogType === "edit" ? "Edit" : ""}{" "}
            Note
          </DialogTitle>
          <DialogContent>
            {/* <Typography variant="h6">Title</Typography> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="email"
              fullWidth
              variant="standard"
              value={form.title}
              onChange={(e) => changeForm("title", e.target.value)}
            />
            {/* <Typography variant="h6">Description</Typography> */}
            <TextField
              margin="dense"
              id="name"
              label="Description"
              type="email"
              fullWidth
              variant="standard"
              multiline
              rows={3}
              value={form.note}
              onChange={(e) => changeForm("note", e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={
                dialogType === "add"
                  ? onAddNote
                  : dialogType === "edit"
                  ? onEditNote
                  : ""
              }
            >
              {dialogType === "add"
                ? "Add"
                : dialogType === "edit"
                ? "Edit"
                : ""}{" "}
              Note
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showDelete}
          keepMounted
          onClose={() => setShowDelete(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h4">Delete following note?</Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowDelete(false)}>Close</Button>
            <LoadingButton
              loading={loading}
              color="error"
              variant="contained"
              onClick={onDeleteNote}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const NoteView = (props) => {
  const { title, note } = props.note;
  const { onEditPress, onDeletePress, crmAccess } = props;
  return (
    <Card
      sx={{
        backgroundColor: "#fafafa",
        margin: "10px 0px",
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "5px" },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <div style={{ display: "flex", marginTop: "6px" }}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            Note:
          </Typography>
          <Typography variant="body2" fontWeight="bold" ml={1}>
            {note}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            onClick={() => onEditPress(props.note)}
            startIcon={<ModeEditOutlineOutlinedIcon />}
            disabled={!crmAccess.includes("edit")}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="error"
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => onDeletePress(props.note)}
            disabled={!crmAccess.includes("edit")}
          >
            Delete
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export const jobRoles = [
  { label: "Proprietor", value: "proprietor" },
  { label: "Partner", value: "partner" },
  { label: "Director", value: "director" },
  { label: "Store Manager", value: "store_manager" },
  { label: "Sales Person", value: "sales_person" },
  { label: "Counter Sales Person", value: "counter_sales_person" },
  { label: "Floor Manager", value: "floor_manager" },
  { label: "Purchase Executive", value: "purchase_executive" },
  { label: "Purchase Manager", value: "purchase_manager" },
  { label: "Purchase Manager - Gold", value: "purchase_manager_gold" },
  {
    label: "Purchase Manager - Diamond",
    value: "purchase_manager_diamond",
  },
  { label: "Account Manager", value: "account_manager" },
  { label: "Account Executive", value: "account_executive" },
];

const defaultContact = {
  first_name: "",
  last_name: "",
  email: "",
  job_role: "",
  whatsapp: "",
  whatsapp_dial: { label: "India 🇮🇳 (+91)", value: "91" },
  access: [],
};

const Contacts = ({ customer, setCustomer }) => {
  const [_showAddDialog, setShowAddDialog] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [addForm, setAddForm] = useState(defaultContact);
  const [contacts, setContacts] = useState([]);
  const [addingContact, setAddingContact] = useState(false);
  const [editId, setEditId] = useState("");
  const [alert, setAlert] = useState({ show: false });
  const [showDeleteContact, setShowDeleteContact] = useState(false);

  useEffect(() => {
    if (isObjWithValues(customer))
      setCustomerName(`${customer.first_name} ${customer.last_name}`);
    if (customer && isArrayWithValues(customer.meta_data)) {
      let { meta_data } = customer;
      let contactsObj = meta_data.find((i) => i.key === "contacts");
      if (contactsObj && isArrayWithValues(contactsObj.value))
        setContacts(contactsObj.value);
    }
  }, [customer]);

  const hideAddDialog = () => {
    setShowAddDialog(false);
    if (editId) setAddForm(defaultContact);
    setEditId(false);
    if (showDeleteContact) setShowDeleteContact(false);
  };
  const showAddDialog = () => setShowAddDialog(true);

  const editAddForm = (payload) => {
    if (alert.show) setAlert((state) => ({ ...state, show: false }));
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const onAddContact = async () => {
    let {
      first_name,
      last_name,
      email,
      whatsapp,
      whatsapp_dial,
      job_role,
      access,
    } = addForm;
    if (
      !first_name ||
      !last_name ||
      !email ||
      !job_role ||
      !whatsapp ||
      !isArrayWithValues(access)
    )
      return setAlert({
        show: true,
        message: "Please fill all fields!",
        severity: "error",
      });
    if (!isEmailValid(email))
      return setAlert({
        show: true,
        message: "Please enter valid email!",
        severity: "error",
      });
    if (whatsapp.length < 7)
      return setAlert({
        show: true,
        message: "Please enter valid Whatsapp No!",
        severity: "error",
      });

    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts.push({
      ...addForm,
      id: getRandomString(8),
      created: parseInt(Date.now() / 1000),
      whatsapp_dial: whatsapp_dial.value,
    });
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();
    if (res) setCustomer(res);
  };
  const onEditContact = async () => {
    let {
      first_name,
      last_name,
      email,
      whatsapp,
      whatsapp_dial,
      job_role,
      access,
    } = addForm;
    if (
      !first_name ||
      !last_name ||
      !email ||
      !whatsapp ||
      !job_role ||
      !isArrayWithValues(access)
    )
      return setAlert({
        show: true,
        message: "Please fill all fields!",
        severity: "error",
      });
    if (!isEmailValid(email))
      return setAlert({
        show: true,
        message: "Please enter valid email!",
        severity: "error",
      });
    if (whatsapp.length < 7)
      return setAlert({
        show: true,
        message: "Please enter valid Whatsapp No!",
        severity: "error",
      });

    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts = contacts.map((i) => {
      return i.id === editId
        ? {
            ...i,
            ...addForm,
            whatsapp_dial: whatsapp_dial.value,
          }
        : i;
    });
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();

    if (res) setCustomer(res);
  };

  const onDeleteContact = async () => {
    let { meta_data } = customer;
    let contactObj = meta_data.find((i) => i.key === "contacts");
    let contacts = contactObj
      ? Array.isArray(contactObj.value)
        ? contactObj.value
        : []
      : [];
    contacts = contacts.filter((i) => i.id !== editId);
    let obj = {
      meta_data: [
        {
          key: "contacts",
          value: contacts,
        },
      ],
    };
    setAddingContact(true);

    let res = await updateCustomer(customer.id, obj);
    setAddingContact(false);
    hideAddDialog();
    if (res) setCustomer(res);
  };

  const onEditPress = (contact) => {
    let whatsapp_dial = dialCodes.find((i) => i.value == contact.whatsapp_dial);
    setEditId(contact.id);
    setAddForm({ ...contact, whatsapp_dial });
    showAddDialog();
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h6" ml={1} fontWeight="bold">
              {customerName && `${customerName}'s`} Contacts
            </Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={showAddDialog}
            >
              New
            </Button>
          </Stack>

          {contacts.map((contact) => {
            return (
              <Stack
                direction="row"
                hover
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "rgba(150, 150, 150,0.1)" },
                }}
                my={1}
                py={4}
                pl={2}
                onClick={() => onEditPress(contact)}
              >
                <Stack alignItems={"center"} direction="row" mr={2}>
                  {/* <AccountBox /> */}
                  <Avatar sx={{ width: 34, height: 34 }} />
                </Stack>

                <Box>
                  <Stack direction={"row"} alignItems="center">
                    <Typography variant="h6" sx={{ my: 0, mr: 1 }}>
                      {contact.first_name} {contact.last_name}
                    </Typography>
                    {/* <ContactAccessIcon
                    tooltip="Whatsapp"
                    icon={
                      <WhatsappOutlined
                        style={{ height: "18px", width: "18px" }}
                      />
                    }
                  />
                  <ContactAccessIcon
                    tooltip="Email"
                    icon={
                      <EmailOutlined
                        style={{ height: "18px", width: "18px" }}
                      />
                    }
                  />
                  <ContactAccessIcon
                    tooltip="SMS"
                    icon={
                      <ChatOutlined style={{ height: "18px", width: "18px" }} />
                    }
                  /> */}
                  </Stack>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1, mr: 2 }}
                    fontSize="12px"
                  >
                    {formatDate(
                      new Date(+contact.created * 1000),
                      "dd:mm:yyyy hh:mm am/pm"
                    )}
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </CardContent>
      </Card>
      <Dialog
        open={_showAddDialog}
        onClose={hideAddDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            {editId ? "Edit Contact" : "Add New Contact"}
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              {editId && (
                <IconButton
                  onClick={() => setShowDeleteContact(true)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              )}
              <IconButton onClick={hideAddDialog}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Collapse in={alert.show}>
            <Alert severity={alert.severity}>{alert.message}</Alert>
          </Collapse>
          <Collapse in={showDeleteContact}>
            <Alert
              severity={"error"}
              action={
                <>
                  <Button color="error" size="small" onClick={onDeleteContact}>
                    DELETE
                  </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setShowDeleteContact(false)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </>
              }
            >
              Delete this contact?
            </Alert>
          </Collapse>
          <DialogContentText id="alert-dialog-description">
            <Stack direction="row" spacing={3} mt={3}>
              <TextField
                value={addForm.first_name}
                onChange={(e) => editAddForm({ first_name: e.target.value })}
                size="small"
                fullWidth
                label="First Name"
              />
              <TextField
                value={addForm.last_name}
                onChange={(e) => editAddForm({ last_name: e.target.value })}
                size="small"
                fullWidth
                label="Last Name"
              />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addForm.email}
                  onChange={(e) => editAddForm({ email: e.target.value })}
                  size="small"
                  fullWidth
                  label="Email*"
                  sx={{ mt: 3 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 3 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Job Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.job_role}
                    label="Job Role"
                    onChange={(e) => editAddForm({ job_role: e.target.value })}
                  >
                    {jobRoles.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={3} sx={{ mt: 3 }}>
              <FormControl sx={{ flex: 1, minWidth: "82px" }} size="small">
                {/* <InputLabel id="demo-simple-select-label">Dial</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addForm.whatsapp_dial}
                  // label="Dial"
                  // renderValue={}
                  onChange={(e) =>
                    editAddForm({ whatsapp_dial: e.target.value })
                  }
                  renderValue={(value) => `+${value.value}`}
                >
                  {dialCodes.map((i) => (
                    <MenuItem value={i}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={addForm.whatsapp}
                onChange={(e) => editAddForm({ whatsapp: e.target.value })}
                size="small"
                fullWidth
                label="Whatsapp*"
                type="number"
              />
            </Stack>
            <FormControl
              sx={{ flex: 1, minWidth: "82px", mt: 3 }}
              fullWidth
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Access</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.access}
                label="Access"
                multiple
                // renderValue={}
                onChange={(e) => editAddForm({ access: e.target.value })}
                // renderValue={(value) => `+${value.value}`}
              >
                {ContactAccess.map((i) => (
                  <MenuItem value={i.value}>{i.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideAddDialog} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={addingContact}
            onClick={editId ? onEditContact : onAddContact}
            autoFocus
          >
            {editId ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ContactAccessIcon = ({ icon, tooltip }) => {
  return (
    <Box sx={{ mx: 1.5, mt: 1 }}>
      <Tooltip title={tooltip}>{icon}</Tooltip>
    </Box>
  );
};

const ContactAccess = [
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "SMS", value: "SMS" },
];

const userData = [
  {
    online: false,
    id: "1656877313_yxvwMW",
    time: 1656877313,
    type: "design_bank_product",
    user_name: "Tiara By TJ",
    user_id: "1234",
    product_name: "MAF234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
  {
    online: false,
    id: "1656877313_yxeSMW",
    time: 1656875313,
    type: "design_bank",
    user_name: "Tiara By TJ",
    user_id: "1234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
  {
    online: false,
    id: "1656877313_yxveMW",
    time: 1656873313,
    type: "catalog_product",
    user_name: "Tiara By TJ",
    user_id: "1234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
  {
    online: false,
    id: "1656877313_yxvxMW",
    time: 1656833313,
    type: "catalog_product",
    product_name: "MAF234",
    user_name: "Tiara By TJ",
    user_id: "1234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
  {
    online: false,
    id: "1656877313_yxvxMW",
    time: 1656833313,
    type: "catalog_product",
    product_name: "MAF124",
    user_name: "Tiara By TJ",
    user_id: "1234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
  {
    online: false,
    id: "1656877313_yxvS2W",
    time: 1656833313,
    type: "catalog",
    user_name: "Tiara By TJ",
    user_id: "1234",
    ip: "49.32.253.145",
    ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
    from: "app",
  },
];

const Analytics = (props) => {
  const [whatsappData, setWhatsappData] = useState([0, 0, 0]);
  const [totalCatalogs, setTotalCatalogs] = useState(0);
  const [totalRequest, setTotalRequest] = useState(0);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [whatsappResponseRate, setWhatsappResponseRate] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [noWhatsappAnalytics, setNoWhatsappAnalytics] = useState(false);
  const [userTimeline, setUserTimeline] = useState([]);
  const theme = useTheme();
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [totalQuotationResponse, setTotalQuotationResponse] = useState(0);

  const dispatch = useDispatch();

  const allOnlineVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );

  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );
  const retailer_activities = useSelector(
    (state) => state.customers.retailer_activities
  );
  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );

  useEffect(() => {
    if (!isArrayWithValues(reduxProductGroups)) dispatch(getProductGroups());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(retailerInvites)) {
      let retailerActivityOptions = [];
      for (let obj of retailerInvites) {
        retailerActivityOptions.push(
          `retailer_activity_${obj.retailer_id_in_supplier}`
        );
      }
      dispatch(fetchRetailerActivity(retailerActivityOptions.join()));
    }
  }, [retailerInvites]);

  useEffect(() => {
    if (isObjWithValues(retailer_activities)) {
      console.log(retailer_activities);
    }
  }, [retailer_activities]);

  useEffect(() => {
    let { customerId } = props;
    if (customerId && isObjWithValues(allOnlineVisitors))
      if (allOnlineVisitors[customerId])
        setUserTimeline(allOnlineVisitors[customerId]);
  }, [allOnlineVisitors]);

  useEffect(() => {
    if (isArrayWithValues(reduxQuotations)) {
      let quotations = [...reduxQuotations];
      let { customerId } = props;
      quotations = quotations.filter(
        (i) => i.customer && i.customer.ID == customerId
      );
      let totalResponse = 0;
      quotations.map(
        (i) => isArrayWithValues(i.edit_history) && totalResponse++
      );
      setTotalQuotations(quotations.length);
      setTotalQuotationResponse(totalResponse);
    }
  }, [reduxQuotations]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    calculateMessages();
    let { customer } = props;
    setCustomerName(`${customer.first_name} ${customer.last_name}`);
    if (isArrayWithValues(customer.catalogs)) {
      let { catalogs } = customer;
      if (isArrayWithValues(catalogs)) {
        setTotalCatalogs(catalogs.length);
        let totalRequest = 0,
          totalTimeSpent = 0;
        for (let i = 0; i < catalogs.length; i++) {
          if (isArrayWithValues(catalogs[i].catalogRequests)) {
            let { catalogRequests } = catalogs[i];
            totalRequest += catalogRequests.length;
            for (let j = 0; j < catalogRequests.length; j++) {
              let { analytics } = catalogRequests[j];
              if (analytics && analytics.seconds) {
                totalTimeSpent += analytics.seconds;
              }
            }
          }
        }
        setTotalRequest(totalRequest);
        if (totalTimeSpent) {
          let obj = getRelativeTimeDifference(
            Number(new Date().getTime()),
            Number(new Date().getTime() + totalTimeSpent * 1000)
          );
          if (isObjWithValues(obj)) {
            setTotalTimeSpent(`${obj.value}${obj.label[0]}`);
          }
        }
      }
    }
  }, [props.customer]);
  const calculateMessages = () => {
    let { customer } = props;
    let { whatsapp_chat } = customer;
    if (isArrayWithValues(whatsapp_chat)) {
      let length = whatsapp_chat.length;
      let totalSend = 0,
        totalReceive = 0,
        totalImagesSent = 0;
      for (let i = 0; i < length; i++) {
        if (whatsapp_chat[i].sender) totalReceive++;
        if (whatsapp_chat[i].msg_text) totalSend++;
        if (whatsapp_chat[i].img_url) totalImagesSent++;
      }
      if (!totalSend && !totalReceive && !totalImagesSent)
        setNoWhatsappAnalytics(true);
      else setNoWhatsappAnalytics(false);
      setWhatsappData([totalSend, totalReceive, totalImagesSent]);
      totalSend = totalSend || 1;
      let responseRate = parseInt((totalReceive * 100) / totalSend);
      responseRate = responseRate > 100 ? 100 : responseRate;
      setWhatsappResponseRate(responseRate || 0);
      // setWhatsappData({
      //   labels: ["Message Sent", "Message Received", "Total Images"],
      //   datasets: [
      //     {
      //       label: "# of Votes",
      //       data: [totalSend, totalReceive, totalImagesSent],
      //       backgroundColor: [
      //         "rgba(255, 99, 132, 0.2)",
      //         "rgba(75, 192, 192, 0.2)",
      //         "rgba(54, 162, 235, 0.2)",
      //       ],
      //       borderColor: [
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(54, 162, 235, 1)",
      //       ],
      //       borderWidth: 1,
      //     },
      //   ],
      // });
    } else setNoWhatsappAnalytics(true);
  };

  const whatsappDataOptions = {
    chart: {
      // width: 380,
      type: "pie",
      animations: {
        enabled: true,
      },
    },
    labels: ["Message Sent", "Message Received", "Total Images"],
    legend: {
      position: "bottom",
    },
    colors: [
      "#EF4E83",
      "#EF7752",
      "#2590C2",
      // "rgba(255, 99, 132, 0.6)",
      // "rgba(75, 192, 192, 0.6)",
      // "rgba(54, 162, 235, 0.6)",
    ],
    responsive: [
      {
        // breakpoint: 480,
        options: {
          chart: {
            // width: 200,
          },
        },
      },
    ],
  };
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div>
      <Typography variant="h6" textAlign={"center"} mb={3} mt={3}>
        {customerName && `${customerName}'s`} Analytics
      </Typography>
      <Typography variant="h4" mb={3} mt={4}>
        Catalog Analytics
      </Typography>
      <Grid container sx={{ mt: 2 }} spacing={{ xs: 6, sm: 4, lg: 5 }}>
        <Grid item xs={6} sm={3} style={{ maxWidth: "250px" }}>
          <TotalCard
            // bg="#C8FACD"
            // iconBg="#B9EFC2"
            label="Catalogs Sent"
            value={totalCatalogs}
            bg="#D0F2FE"
            iconBg="#AFD5F2"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ maxWidth: "250px" }}>
          <TotalCard
            bg="#D0F2FE"
            iconBg="#AFD5F2"
            label="Catalogs Open"
            value={totalCatalogs ? totalCatalogs - 1 : totalCatalogs}
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ maxWidth: "250px" }}>
          <TotalCard
            // bg="#FFF7CC"
            // iconBg="#F5E7B5"
            label="Requests"
            value={totalRequest}
            bg="#D0F2FE"
            iconBg="#AFD5F2"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ maxWidth: "250px" }}>
          <TotalCard
            // bg="#FFE7D9"
            // iconBg="#F6CEC4"
            label="Watch Time"
            value={totalTimeSpent}
            bg="#D0F2FE"
            iconBg="#AFD5F2"
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Typography variant="h4" mb={3} mt={4}>
        Quotation Analytics
      </Typography>
      <Grid container sx={{ mt: 2 }} spacing={{ xs: 6, sm: 4, lg: 8 }}>
        <Grid item xs={6} sm={3}>
          <TotalCard
            bg="#f7e6f7"
            // iconBg="#B9EFC2"
            label="Quotations Sent"
            value={totalQuotations}
            // bg="#D0F2FE"
            iconBg="#AFD5F2"
            icon={
              <ReceiptLongOutlined
                sx={{ width: "44px", height: "44px", color: "text.secondary" }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TotalCard
            bg="#f7e6f7"
            iconBg="#AFD5F2"
            label="Quotations Response"
            value={totalQuotationResponse}
            icon={
              <ReceiptLongOutlined
                sx={{ width: "44px", height: "44px", color: "text.secondary" }}
              />
            }
          />
        </Grid>
        {/* <Grid item xs={6} sm={3}>
          <TotalCard
            bg="#f7e6f7"
            // iconBg="#F5E7B5"
            label="Requests"
            value={totalRequest}
            // bg="#D0F2FE"
            iconBg="#AFD5F2"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TotalCard
            bg="#f7e6f7"
            // iconBg="#F6CEC4"
            label="Watch Time"
            value={totalTimeSpent}
            // bg="#D0F2FE"
            iconBg="#AFD5F2"
          />
        </Grid> */}
      </Grid>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Grid
        container
        spacing={isLg ? 8 : 5}
        sx={{ margin: "auto", justifyContent: "flex-start" }}
      >
        <Grid item xs={12} sm={5} md={5}>
          <Typography variant="h4" mb={3}>
            Whatsapp Analytics
          </Typography>

          <Card
            sx={{
              borderRadius: "14px",
              mt: 2.5,
              boxShadow: "-1px 1px 3px 0px rgba(0,0,0,0.3)",
              background:
                "linear-gradient(90deg, rgba(223,224,223,1) 0%, rgba(198,216,210,1) 100%)",
            }}
          >
            <CardContent>
              <Typography variant="h2">{whatsappData[0]}</Typography>
              <Typography mt={2} color="text.secondary">
                Messages Send
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderRadius: "14px",
              mt: 2.5,
              boxShadow: "-0.5px 0.5px 1px 0px rgba(0,0,0,0.3)",
              background:
                "linear-gradient(90deg, rgba(223,224,223,1) 0%, rgba(198,216,210,1) 100%)",
            }}
          >
            <CardContent
              sx={{
                "&:padding-bottom": { paddingBottom: "16px" },
              }}
            >
              <Stack
                alignItems={"center"}
                direction="row"
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography variant="h2">{whatsappData[1]}</Typography>
                  <Typography mt={2} color="text.secondary">
                    Messages Received
                  </Typography>
                </Box>
                {/* <Box>
                  <SendRounded style={{ width: "40px", height: "40px" }} />
                </Box> */}
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderRadius: "14px",
              mt: 2.5,
              boxShadow: "-1px 1px 3px 0px rgba(0,0,0,0.3)",
              background:
                "linear-gradient(90deg, rgba(223,224,223,1) 0%, rgba(198,216,210,1) 100%)",
            }}
          >
            <CardContent>
              <Typography variant="h2">{whatsappResponseRate}%</Typography>
              <Typography mt={2} color="text.secondary">
                Response Rate
              </Typography>
            </CardContent>
          </Card>
          {/* {noWhatsappAnalytics ? (
            <Stack mt={10} alignItems="center" textAlign="center">
              <ChatIllustration
                primary="#e8e8e8"
                width={isMobile ? "280px" : "370px"}
                height={isMobile ? "180px" : "270px"}
              />
              <Typography
                variant="body2"
                fontSize="18px"
                mt={10}
                color="text.secondary"
              >
                No chat analytics yet!
              </Typography>
            </Stack>
          ) : (
            <ReactApexChart
              options={whatsappDataOptions}
              series={whatsappData}
              type="pie"
              animation={true}
              // width={380}
            />
          )} */}
          {/* <Doughnut data={whatsappData} style={{}} /> */}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h4" mb={3}>
            User Timeline
          </Typography>
          {!isArrayWithValues(userTimeline) && (
            <Box style={{ textAlign: "center", marginTop: "4vh" }}>
              <NotFound style={{ width: "250px", height: "200px" }} />
              <Typography variant="h6" fontSize="14px" color="text.secondary">
                User has no Timeline
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              // height: "100%",
              maxHeight: "420px",
              overflowY: "scroll",
              pt: 0,
            }}
            className="scrollbar-hidden"
          >
            {/* <VerticalTimeline
              layout="1-column"
              style={{ paddingTop: "0px" }}
              className="padding-top scrollbar-hidden"
            >
            </VerticalTimeline> */}
            <Timeline position="right" sx={{ justifyContent: "flex-start" }}>
              {userTimeline.map((i, index) => {
                let { type, time, product_name, catalog_name, quotation_name } =
                  i;
                let date = formatDate(
                  new Date(time * 1000),
                  "dd:mm:yyyy hh:mm am/pm"
                );
                let label = getLabelFromType(type);
                return (
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {date}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color={i.online ? "success" : "grey"} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack>
                        <Stack
                          direction={"row"}
                          alignItems="flex-end"
                          whiteSpace={"nowrap"}
                          overflow="hidden"
                          textOverflow={"ellipsis"}
                        >
                          <Typography>{label}</Typography>
                          <Typography fontSize={"11px"} ml={1}>
                            {catalog_name || quotation_name}
                          </Typography>
                        </Stack>
                        {product_name && (
                          <Typography
                            fontSize={"10px"}
                            color="text.secondary"
                            fontStyle={"italic"}
                          >
                            product: {product_name}{" "}
                          </Typography>
                        )}
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                  // <React.Fragment>
                  //   <Box flex={1} sx={{ margin: "14px 0px", px: 4 }}>
                  //     <Stack direction="row">
                  //       <Stack
                  //         direction={"row"}
                  //         justifyContent="space-between"
                  //         // alignItems={"center"}
                  //         mt={3}
                  //       >
                  //         <Typography
                  //           // color="text.secondary"
                  //           whiteSpace={"nowrap"}
                  //           mx={1}
                  //           mt={0.8}
                  //           fontStyle="italic"
                  //           color="#6e6656"
                  //         >
                  //           22 Feb 2021
                  //         </Typography>

                  //         <Box sx={{ mx: 2 }}>
                  //           <AutoStoriesOutlinedIcon
                  //             style={{
                  //               height: "25px",
                  //               width: "25px",
                  //               border: "1px solid #6e6656",
                  //               padding: "4px",
                  //               borderRadius: "50%",
                  //               mx: 2,
                  //               color: "#6e6656",
                  //             }}
                  //           />
                  //         </Box>
                  //       </Stack>
                  //       <Box
                  //         sx={{
                  //           padding: "20px 15px",
                  //           borderRadius: "14px",
                  //           borderTopLeftRadius: "0px",
                  //           background: "#e3e0da",
                  //           // backgroundColor: "background.paper",
                  //           width: "100%",
                  //           boxShadow: "-1px 2px 3px -1px rgba(0,0,0,0.25)",
                  //         }}
                  //       >
                  //         <Typography variant="h6">{label}</Typography>
                  //         <Typography color="text.secondary" mt={2}>
                  //           Test text
                  //         </Typography>
                  //       </Box>
                  //     </Stack>
                  //   </Box>
                  // </React.Fragment>
                  // <VerticalTimelineElement
                  //   className="vertical-timeline-element--work"
                  //   contentStyle={{
                  //     background: "#fff",
                  //     borderRadius: "14px",
                  //     padding: "18px",
                  //   }}
                  //   contentArrowStyle={{
                  //     borderRight: "7px solid  #EBEAEF",
                  //   }}
                  //   style={
                  //     index === 0
                  //       ? {
                  //           margin: "30px 0px",
                  //           marginTop: "0px",
                  //         }
                  //       : {
                  //           margin: "30px 0px",
                  //         }
                  //   }
                  //   // date="2010 - 2011"
                  //   iconStyle={iconStyle}
                  //   icon={<Typography>23 Feb 2022</Typography>}
                  // >
                  //   <h3 className="vertical-timeline-element-title">{label}</h3>
                  //   <span>{date}</span>
                  // </VerticalTimelineElement>
                );
              })}
            </Timeline>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const iconStyle = {
  // background: "rgb(33, 150, 243)",
  height: "18px",
  width: "18px",
  color: "#fff",
  marginTop: "6px",
  marginLeft: "6px",
  textAlign: "center",
};

const TotalCard = ({ bg, iconBg, label, value, icon }) => {
  let Element = icon;
  return (
    <Card
      sx={{
        backgroundColor: bg || "#c4e2ed",
        // backgroundColor: bg,
        borderRadius: "10%",
        maxWidth: "250px",
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            // backgroundColor: iconBg || "#c4e2ed",
            margin: "auto",
            width: "fit-content",
            padding: "20px 20px 0px 20px",
            borderRadius: "50%",
          }}
        >
          {icon ? (
            icon
          ) : (
            <AutoStoriesOutlinedIcon
              sx={{ width: "44px", height: "44px", color: "text.secondary" }}
            />
          )}
        </Box>
        <Typography variant="h6" color="text.secondary" mt={1}>
          {label}
        </Typography>

        <Typography variant="h1" fontSize="40px" fontWeight={"500"} mt={2.5}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

const AccessCard = ({ obj, isSelected, onClick, isMobile, label }) => {
  const padding = isMobile ? "7px" : "10px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && !obj.disabled && onClick(obj.value)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "14px",
            // background: colorsBackground[obj.value],
            // background: "linear-gradient(to right, #E1C16E,#edd595 )",
          }}
        /> */}
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <Stack direction="row" alignItems={"center"}>
          {obj.color && (
            <LocalOffer
              style={{
                height: "12px",
                width: "12px",
                // borderRadius: "50%",
                marginRight: "5px",
                color: obj.color,
              }}
            />
          )}
          <Typography
            variant={isMobile ? "body2" : "body2"}
            sx={obj.disabled ? { color: "text.secondary" } : {}}
          >
            {label ? label : obj.label}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const updateCustomer = async (id, data) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token)
    return axios({
      method: "POST",
      url: `${website}/wp-json/wc/v3/customers/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        ...data,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        return false;
      });
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const fetchCustomer = async (id, setLoading) => {
  if (!id) return;
  try {
    setLoading && setLoading(true);
    const website = getWebsite();
    const token = getToken();
    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers/${id}?abcd`,
        // url: `${website}/wp-json/wc/v3/customers/${id}`,
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "GET",
      });
      setLoading && setLoading(false);
      if (res.data && res.data.id) return res.data;
      else return {};
    }
  } catch (error) {
    return false;
  }
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
