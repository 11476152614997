/* eslint-disable react-hooks/exhaustive-deps */
import {
  Link,
  Breadcrumbs,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Divider,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Tabs,
  Tab,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  Radio,
  RadioGroup,
  InputAdornment,
  Chip,
  Icon,
  Snackbar,
  Slide,
  IconButton,
  Collapse,
  ImageList,
  ImageListItem,
  useMediaQuery,
  Stack,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormHelperText,
  DialogActions,
  ButtonGroup,
  FormGroup,
  Toolbar,
  AppBar as MuiAppBar,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import CountUp from "react-countup";

import {
  Add as AddIcon,
  Delete,
  Edit,
  Close,
  Cancel,
  Info,
  AddCircle,
  AddCircleOutline,
  ArrowBackIosNew,
  HighlightOffOutlined,
  CheckCircle,
  ErrorOutline,
  SubdirectoryArrowLeft,
  Upload,
  CalendarToday,
  YouTube,
  Storefront,
  Inventory2Outlined,
  InfoOutlined,
} from "@mui/icons-material";
import React, {
  createRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { CloudUpload, FileUpload } from "../../Svgs";
import styled from "styled-components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, useTheme } from "@mui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import PropTypes from "prop-types";
import ProductMediaLibrary from "../components/ProductMediaLibrary";
import {
  addProductInRedux,
  fetchAllProducts,
  fetchAdminTaxonomies,
  SET_PRODUCT_SETTINGS,
  updateProductInRedux,
  updateProductFilter,
  fetchTaxonomies,
} from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  DateTimePicker,
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import {
  addMarginInPriceBreakup,
  arrayMove,
  calculateDiscountFromPriceChange,
  calculateTotalDiamondWeight,
  calculateTotalGemstoneWeight,
  capitalizeFirstLetter,
  createFilterObjFromProduct,
  dialogStyle,
  formatDate,
  formatServerValue,
  getAxiosError,
  getItem,
  getMarginRate,
  getProductPrice,
  getPromotionStepperPrice,
  getStorePlan,
  getSupplierInfo,
  getTimeInSeconds,
  getToken,
  getTotalProductPrice,
  getWebsite,
  isDev,
  isNumber,
  isProductIncomplete,
  mainWebsite,
  secondaryLabelSmall,
  setItem,
  validateNumber,
  validateWeight,
} from "../../helper";
import useAuth from "../../hooks/useAuth";
import {
  addExtraCharge,
  getAllTaxes,
  getProductDefaultSettings,
  getProductGroups,
  updateProductGroups,
} from "../../redux/actions/settingActions";
import Switch from "rc-switch";
import "../../App.css";
import { currencySymbols, PurityCard } from "../pricing/Gold";
import SortableList, { SortableItem } from "react-easy-sort";
import { DateRangePicker } from "react-date-range";
import { getAnalytics } from "../dashboards/Default/GoogleAnalytics";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../redux/actions/helperActions";
import { addUserActivity } from "../../redux/actions/hubActions";
import { tutorialData } from "../../App";
import { getSuppliers } from "../../redux/actions/supplierActions";
import Loader from "../../components/Loader";
import { useTransition } from "react";
import ProfitMargin from "./components/ProfitMargin";
import ProductPriceBreakup from "./components/ProductPriceBreakup";
import { gemstoneColors } from "../pricing/Gemstone";
import { updateProductPricing } from "./helper";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const muiDropdownFilter = createFilterOptions();

const defaultProduct = {
  _woodmart_product_video: "",
  allLabourMasterPricing: [],
  app_access_only: false,
  barcode: "",
  categories: [],
  collections: [],
  colorstone_details: [],
  colorstone_details_customize: [],
  colorstone_from: "master",
  description: "",
  defaultCurrency: "",
  diamond_certified: "no",
  diamond_certificate_id: "",
  diamond_lab: "",
  diamond: [],
  diamond_customize: [],
  diamond_from: "master",
  dontRemoveVariantsOtherForSet: true, //shouldn't remove variants other that setEditProduct for edit
  extra_charges: [],
  gold_gross: "",
  gold_kt: "",
  gold_net: "",
  gold_rate: "",
  hallmarked: "no",
  huid: [],
  images: [],
  labour_per_gram: "",
  labour_from: "gross",
  labourMasterPricing: {},
  labourType: "master",
  making: "",
  making_from: "labour",
  metal_color: "yellow",
  metal_type: "gold",
  metal_types: ["gold"],
  minimum_making: "",
  name: "",
  platinium_gross: "",
  platinium_net: "",
  platinium_purity: "",
  product_groups: [],
  product_groups_temp: [],
  product_height: "",
  product_thickness: "",
  product_width: "",
  per_gram: "",
  price_breakup: {
    customize: {},
    master: {},
  },
  discount_breakup: {},
  price: 0,
  manual_price: 0,
  subcategory: [],
  selectedCrossSell: [],
  selectedGenders: [],
  selectedOcassions: [],
  selectedTags: [],
  selectedUpSell: [],
  seo_title: "Product",
  seo_description: "",
  keywordsArray: [],
  date_on_sale_from: new Date(),
  // date_on_sale_from_gmt: new Date(),
  date_on_sale_to: new Date(),
  // date_on_sale_to_gmt: new Date(),
  selectedLabourPricing: "",
  shipping_days: "",
  product_shipping: { min: "", max: "" },
  silver_gross: "",
  silver_net: "",
  silver_purity: "",
  stock_quantity: "",
  stock_status: "",
  subcatsetting: [],
  subcatsetting_temp: [], //subcatsetting_temp for setting subcatsetting after size masters loaded
  show_extra_charges_separately: false,
  wastage_from: "rate",
  wastage_percent: "",
  variants: [],
  variantType: "",
  noMetals: false,
  diamond_lab: "",
  virtual_try: false,
  virtualTryImage: [],
  try_on_image_src: "",
  total_labour: 0,
  camweara_show_tryon: false,
  total_per_gram: 0,
  onlyColorstone: false,
  onlyDiamond: false,
  seo_title: "",
  seo_description: "",
  seo_keywords: [],
  status: "",
  profit_margin: {
    enabled: false,
    cost_component: "diamond",
    value: "",
  },
  custom_wastage_from: "gross",
  discountOnProduct: false,
};

function AddProduct(props) {
  // const [isPending, startTransition] = useTransition({
  //   timeoutMs: 3000,
  // });
  const { signOut } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [product, setProduct] = useState(defaultProduct);
  const [creatingProduct, setCreatingProduct] = useState(false);
  const [showAddToast, setShowAddToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [masters, setMasters] = useState({});
  const [taxonomies, setTaxonomies] = useState({});
  const [titleError, setTitleError] = useState("");
  const [skuError, setSkuError] = useState("");
  const [productSettings, setProductSettings] = useState({});
  // const productSettings = useSelector((state) => state.settings.productSettings);
  const [diamondError, setDiamondError] = useState("");
  const [productAccess, setProductAccess] = useState([]);
  const [toast, setToast] = useState({ show: false });
  const [allProductGroups, setAllProductGroups] = useState([]);

  const access = useSelector((state) => state.user.access);
  const reduxMasters = useSelector((state) => state.settings.masterPricing);
  const reduxTaxonomies = useSelector((state) => state.product.taxonomies);

  const productToEdit = useSelector((state) => state.product.editProduct);
  const allProducts = useSelector((state) => state.product.allProducts);
  const trybeforeyoubuyIntegration = useSelector(
    (state) => state.settings.trybeforeyoubuyIntegration
  );
  const camwearaIntegration = useSelector(
    (state) => state.settings.camwearaIntegration
  );
  const mirrARIntegration = useSelector(
    (state) => state.settings.mirrARIntegration
  );
  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const sizeMasters = useSelector((state) => state.settings.sizeMastersNew);
  const reduxProductDefaults = useSelector(
    (state) => state.settings.productDefaults || {}
  );
  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );
  const reduxSupplier = useSelector((state) => state.supplier.allSuppliers);
  const promotionStepper = useSelector(
    (state) => state.settings.promotionStepper
  );
  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const sizeMastersNew = useSelector((state) => state.settings.sizeMastersNew);
  const allowed_customization = useSelector(
    (state) => state.settings.allowed_customization
  );
  const allTaxes = useSelector((state) => state.settings.allTaxes);

  const [priceBreakup, setPriceBreakup] = useState({
    master: {},
    customize: {},
  });
  const storePlan = useSelector((state) => state.user.store_plan);
  const storeUsed = useSelector((state) => state.user.store_used);
  const user_type = useSelector((state) => state.user.user_type);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [productTotalPrice, setProductTotalPrice] = useState(0);
  const [productTotalPriceWithoutTax, setProductTotalPriceWithoutTax] =
    useState(0);
  const [discountedTotalPrice, setDiscountedTotalPrice] = useState(0);
  const [selectedLabourPricing, setSelectedLabourPricing] = useState("");
  const [allLabourMasterPricing, setAllLabourMasterPricing] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [discountBreakup, setDiscountBreakup] = useState({});
  const [discount, setDiscount] = useState(0);
  const topRef = useRef(null);
  let location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const metalRef = useRef();
  const labourRef = useRef();
  const diamondRef = useRef();
  const gemstoneRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [addLimitReached, setAddLimitReached] = useState(false);
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const [currentTaxRate, setCurrentTaxRate] = useState();
  const [currentTax, setCurrentTax] = useState(0);
  const [currentManualTax, setCurrentManualTax] = useState(0);
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [canAddCollection, setCanAddCollection] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onEditProduct = (payload) => {
    setProduct((state) => ({ ...state, ...payload }));
    // setProduct({ ...product, ...payload });
  };

  useMemo(() => {
    setCanAddCollection(user_type === "retailer");
  }, [user_type]);

  // useEffect(() => {
  //   if (!isObjWithValues(allDiamondGroups)) {
  //     dispatch(getDiamondGroups());
  //   }
  // }, []);
  const summaryOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useMemo(() => {
    if (!isArrayWithValues(allTaxes)) return;
    setCurrentTaxRate(Math.max(...allTaxes.map((o) => validateNumber(o.rate))));
    // var list = {};
    // allTaxes.map((i) => (list[i.name] = validateNumber(i.rate)));
    // var keys = Object.keys(list);
    // var max = keys[0];
    // var i;

    // for (i = 1; i < keys.length; i++) {
    //   var value = keys[i];
    //   if (list[value] > list[max]) max = value;
    // }
  }, [allTaxes]);
  useMemo(() => {
    if (!isArrayWithValues(reduxSupplier)) dispatch(getSuppliers());
  }, []);

  useEffect(() => {
    if (product.manual_price) {
      let manual_price = validateNumber(product.manual_price);
      let tax = (manual_price * currentTaxRate) / 100;
      setCurrentManualTax(tax);
    } else setCurrentManualTax(0);
  }, [product.manual_price]);

  const fetchProduct = async (id) => {
    let website = await getWebsite();
    let token = await getToken();

    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products`,
        params: { include: id },
        headers: { Authorization: `Basic ${token}` },
      });
      return res.data;
    }
  };

  // useEffect(() => {
  //   onEditProduct({
  //     price_breakup: priceBreakup,
  //   });
  // }, [
  //   product.diamond,
  //   product.diamond_customize,
  //   product.colorstone_details,
  //   product.colorstone_details_customize,
  //   gemstoneRef.current,
  //   diamondRef.current,
  // ]);
  // useEffect(() => {
  //   onEditProduct({
  //     price_breakup: {
  //       ...price_breakup,
  //       [colorstone_from]: { ...price_breakup[colorstone_from], gemstone },
  //     },
  //   });
  // }, []);

  const getProductFromRedux = (id) => {
    try {
      if (id) {
        if (isArrayWithValues(allProducts)) {
          let product = allProducts.find((i) => i.id !== id);
          if (isObjWithValues(product)) return product;
          else return {};
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const getProductSettings = async () => {
  //   const website = getWebsite();
  //   const token = getToken();
  //   if (website && token) {
  //     let res = await axios({
  //       url: `${website}/wp-json/store/v1/settings/business_settings`,
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //     });
  //     let { data } = res;
  //     if (data.success && isObjWithValues(data.data))
  //       dispatch({
  //         type: SET_PRODUCT_SETTINGS,
  //         payload: { ...data.data },
  //       });
  //   }
  // };

  const setEditProduct = async (_product) => {
    await onEditProduct(defaultProduct);
    _product = productToEdit;
    if (!isObjWithValues(_product)) return;
    let {
      name,
      sku,
      status,
      stock_status,
      dimensions,
      images,
      collections,
      categories,
      tags,
      stock_quantity,
      meta_data,
      short_description,
      upsell_ids,
      cross_sell_ids,
      attributes,
      id,
      subcategory,
      date_on_sale_from,
      date_on_sale_to,
      permalink,
    } = _product;
    setUpsellProducts(upsell_ids, cross_sell_ids, onEditProduct);
    // setProductFilters(taxonomies);
    let obj = {
      id,
      name,
      sku,
      description: short_description,
      status,
      stock_status,
      dimensions,
      permalink,
      images: isArrayWithValues(images) ? images : [],
      collections: convertTaxonomies(collections),
      categories: convertTaxonomies(categories),
      subcategory: convertTaxonomies(subcategory),
      tags: convertTaxonomies(tags),
      selectedTags: convertTaxonomies(tags),
      stock_quantity,
      meta_data,
      date_on_sale_from: date_on_sale_from ? new Date(date_on_sale_from) : null,
      date_on_sale_to: date_on_sale_to ? new Date(date_on_sale_to) : null,
      showDiscount: Boolean(date_on_sale_from || date_on_sale_to),
    };

    if (isArrayWithValues(attributes)) {
      let genders = attributes.find((i) => i.name === "Gender");
      let productOcassions = attributes.find((i) => i.name === "Ocassions");
      if (isObjWithValues(genders)) obj.selectedGenders = genders.options;
      if (
        isObjWithValues(productOcassions) &&
        isObjWithValues(reduxTaxonomies)
      ) {
        let { options } = productOcassions;
        let { ocassions } = reduxTaxonomies;
        if (isArrayWithValues(options) && isArrayWithValues(ocassions)) {
          let array = [];
          for (let i = 0; i < ocassions.length; i++) {
            if (options.indexOf(ocassions[i].label) > -1)
              array.push(ocassions[i]);
          }
          obj.selectedOcassions = array;
        }
      }
    }
    let metaKeys = [
      "barcode",
      "colorstone_from",
      "custom_discounts",
      "diamond_certificate_id",
      "diamond_certified",
      "diamond_from",
      "diamond_lab",
      "gold_gross",
      "gold_kt",
      "gold_net",
      "hallmarked",
      "huid",
      "labourType",
      "labour_from",
      "custom_wastage_from",
      "making_from",
      "manual_price",
      "metal_color",
      "metal_type",
      "metal_types",
      "minimum_making",
      "per_gram",
      "platinium_gross",
      "platinium_net",
      "platinium_purity",
      "product_height",
      "product_shipping",
      "product_thickness",
      "shipping_days",
      "show_extra_charges_separately",
      "silver_gross",
      "silver_net",
      "silver_purity",
      "try_on_image_src",
      "variants",
      "wastage_from",
      "app_access_only",
      // "product_groups",
      "product_width",
      "labour",
      "_woodmart_product_video",
      "pull_source",
      "virtualTryImage",
      "virtual_try",
      "camweara_show_tryon",
      "try_on_image_src",
      "mirrAR_tryon",
      "ornate_unique_id",
      "jemisys_product",
    ];
    if (isArrayWithValues(meta_data)) {
      let seoObj = meta_data.find((i) => i.key === "seo");
      if (seoObj && isObjWithValues(seoObj.value)) {
        obj = { ...obj, ...seoObj.value };
        if (typeof obj.seo_keywords === "string")
          obj.seo_keywords = obj.seo_keywords
            .split(",")
            .map((i) => i?.trim())
            .filter(Boolean);
      }

      let labourType = meta_data.find((i) => i.key === "labourType");
      if (
        labourType &&
        (labourType.value === "customize" || labourType.value === "individual")
      ) {
        let labourKeys = ["wastage_percent", "labour_per_gram", "per_gram"];
        for (let metaObj of meta_data) {
          if (labourKeys.includes(metaObj.key) && metaObj.value) {
            obj[metaObj.key] = metaObj.value;
          }
        }
      }

      let labourPricingTitle = meta_data.find(
        (i) => i.key === "labour_pricing_title"
      );

      // let labourPricingTitle = meta_data.find(
      //   (i) => i.key === "labour_pricing_title"
      // );

      if (
        labourPricingTitle &&
        isArrayWithValues(obj.collections) &&
        isArrayWithValues(obj.categories)
      )
        setSelectedLabourPricing(labourPricingTitle.value);
      // obj.selectedLabourPricing = labourPricingTitle.value;

      //   metaKeys = metaKeys.concat([
      //     "labour_from",
      //     "wastage_percent",
      //     "labour_per_gram",
      //     "minimum_making",
      //     "wastage_from",
      //     "making_from",
      //   ]);
    }
    if (isArrayWithValues(meta_data)) {
      let length = meta_data.length;
      for (let i = 0; i < length; i++) {
        let metaObj = meta_data[i];
        if (metaKeys.includes(metaObj.key) && metaObj.value)
          obj[metaObj.key] = metaObj.value;
        if (metaObj.key === "diamond")
          if (isObjWithValues(metaObj.value))
            obj.diamond_temp = Object.values(metaObj.value);
        if (metaObj.key === "colorstone_details")
          if (isObjWithValues(metaObj.value))
            obj.colorstone_details_temp = Object.values(metaObj.value);
        if (metaObj.key === "subcatsetting")
          if (isArrayWithValues(metaObj.value))
            obj.subcatsetting_temp = metaObj.value;
        if (metaObj.key === "labourType" && metaObj.value === "individual")
          obj.labourType = "customize";
        if (metaObj.key === "diamond_from" && metaObj.value === "individual")
          obj.diamond_from = "customize";
        if (metaObj.key === "colorstone_from" && metaObj.value === "individual")
          obj.colorstone_from = "customize";
        if (metaObj.key === "labour" && validateNumber(metaObj.value))
          obj.per_gram = metaObj.value;
        if (metaObj.key === "minimum_labour")
          obj.minimum_making = metaObj.value;

        if (metaObj.key === "image_colors")
          if (isArrayWithValues(metaObj.value)) {
            let { images } = obj;
            let { value } = metaObj;
            let array = [];
            for (let i = 0; i < images.length; i++) {
              let image = images[i];
              let colorObj = value.find((obj) => obj.id === image.id);
              if (isObjWithValues(colorObj))
                array.push({ ...image, color: colorObj.color || "" });
              else array.push({ ...image });
            }
            obj.images = array;
          }
        if (metaObj.key === "extra_charges" && isObjWithValues(metaObj.value))
          obj.extra_charges = Object.values(metaObj.value).filter((i) =>
            isObjWithValues(i)
          );
      }
    }

    if (isArrayWithValues(obj?.huid) && obj?.pull_source) {
      obj.huidhasAlready = true;
    }
    if (obj?.pull_source) {
      let supplierId = obj?.pull_source?.split("_")[0];
      let supplierData = getSupplierInfo(reduxSupplier, supplierId);
      obj.supplierInfo = {
        name: supplierData?.name,
        supplier: supplierData?.supplier,
      };
    }
    if (!obj.labourType) obj.labourType = "master";

    if (
      isObjWithValues(obj.custom_discounts) &&
      obj.custom_discounts?.on_total_sale
    )
      obj.discountOnProduct = true;
    else obj.discountOnProduct = false;

    if (obj.diamond_temp) {
      if (obj.diamond_from) {
        if (obj.diamond_from === "master") obj.diamond = obj.diamond_temp;
        else if (
          obj.diamond_from === "customize" ||
          obj.diamond_from === "individual"
        )
          obj.diamond_customize = obj.diamond_temp;
      } else obj.diamond = obj.diamond_temp;
    }

    if (obj.colorstone_details_temp) {
      if (obj.colorstone_from) {
        if (
          obj.colorstone_from === "customize" ||
          obj.colorstone_from === "individual"
        )
          obj.colorstone_details_customize = obj.colorstone_details_temp;
        else if (obj.colorstone_from === "master")
          obj.colorstone_details = obj.colorstone_details_temp;
      } else obj.colorstone_details = obj.colorstone_details_temp;
    }
    let productGroupsObj = meta_data?.find((i) => i.key === "product_groups");
    if (productGroupsObj) obj.product_groups_temp = productGroupsObj.value;

    if (isArrayWithValues(obj.metal_types))
      obj.metal_types = obj.metal_types?.map((i) => i?.toLowerCase()?.trim());

    if (obj.gold_kt)
      metalRef.current &&
        metalRef.current.setPurityRateFirstTime &&
        metalRef.current.setPurityRateFirstTime("gold_kt", obj.gold_kt);
    if (obj.silver_purity)
      metalRef.current &&
        metalRef.current.setPurityRateFirstTime &&
        metalRef.current.setPurityRateFirstTime(
          "silver_purity",
          obj.silver_purity
        );
    if (obj.platinium_purity)
      metalRef.current &&
        metalRef.current.setPurityRateFirstTime &&
        metalRef.current.setPurityRateFirstTime(
          "platinium_purity",
          obj.platinium_purity
        );
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        delete obj[key];
      }
    });
    if (
      obj.per_gram &&
      (obj.labourType === "customize" || obj.labourType === "individual")
    )
      obj.labour_per_gram = obj.per_gram;
    obj.setEditProductDone = true;

    if (!obj.custom_wastage_from) obj.custom_wastage_from = obj.labour_from;
    // if (product?.gold_net) delete obj.gold_net;
    if (product?.gold_net) obj.gold_net = product?.gold_net;
    if (!obj.metal_types?.includes("gold")) obj.metal_color = "";

    console.log(obj, "<<<< edit product obje");
    onEditProduct(obj);
  };

  const setUpDiamondColorstone = (arr) => {
    let obj = {};
    if (isArrayWithValues(arr)) {
      for (let i = 0; i < arr.length; i++) {
        obj[i + 1] = arr[i];
      }
    }
    return obj;
  };

  const productFilters = [
    { label: "Collections", key: "collections" },
    { label: "Categories", key: "categories" },
    { label: "Gold Purity", key: "gold_kt" },
    { label: "Diamond Purity", key: "diamond_quality" },
    { label: "Diamond Labs", key: "diamond_lab" },
    { label: "Gold Weight", key: "gold_gross" },
    { label: "Diamond Weight", key: "total_diamond_weight" },
  ];

  const onAddProduct = () => {
    let meta_data = [];
    let metaKeys = [
      "huid",
      "shipping_days",
      "product_shipping",
      "metal_type",
      "metal_color",
      "gold_kt",
      "silver_purity",
      "platinium_purity",
      "labourType",
      "product_width",
      "product_height",
      "product_thickness",
      "_woodmart_product_video",
      "colorstone_from",
      "diamond_from",
      "app_access_only",
      "variants",
      "metal_types",
      "diamond_lab",
      "diamond_certified",
      "diamond_certificate_id",
      // "seo_title",
      // "seo_description",
      // "seo_keywords",
      // "hallmarked",
      // "custom_discounts",
      "show_extra_charges_separately",
      "manual_price",
      "virtualTryImage",
      "virtual_try",
      "camweara_show_tryon",
      "try_on_image_src",
      "mirrAR_tryon",
      "custom_wastage_from",
    ];

    let {
      variants,
      variantType,
      subcatsetting: tempSubcatSetting,
      labourMasterPricing,
      product_groups,
      hallmarked,
      huid,
      showDiscount,
      virtual_try,
      virtualTryImage,
      custom_discounts,
      discountOnProduct,
      labourType,
      seo_description,
      seo_keywords,
      seo_title,
      status,
      manual_price,
    } = product;

    meta_data.push({
      key: "seo",
      value: {
        seo_description,
        seo_title,
        seo_keywords: isArrayWithValues(seo_keywords)
          ? seo_keywords.join()
          : seo_keywords,
      },
    });
    meta_data.push({
      key: "last_price_update",
      value: getTimeInSeconds(),
    });

    meta_data.push({
      key: "product_groups",
      value: product_groups?.map((i) => i.id),
    });

    if (isObjWithValues(custom_discounts)) {
      if (discountOnProduct) {
        delete custom_discounts.on_sale_making;
        delete custom_discounts.on_sale_metal;
        delete custom_discounts.on_sale_diamond;
        delete custom_discounts.on_sale_colorstone;
      } else delete custom_discounts.on_total_sale;
      meta_data.push({
        key: "custom_discounts",
        value: custom_discounts,
      });
    } else
      meta_data.push({
        key: "custom_discounts",
        value: {},
      });
    if (virtual_try && isArrayWithValues(virtualTryImage)) {
      meta_data.push({
        key: "try_on_image_id",
        value: virtualTryImage[0].id,
      });
      meta_data.push({
        key: "try_on_image_src",
        value: virtualTryImage[0].source_url,
      });
    }
    // if (hallmarked === "yes") metaKeys.push("huid");
    if (isArrayWithValues(huid))
      meta_data.push({ key: "hallmarked", value: true });
    else meta_data.push({ key: "hallmarked", value: false });
    // if (product.labourType === "customize")
    //   metaKeys = metaKeys.concat([
    //     "labour_from",
    //     "wastage_percent",
    //     "labour_per_gram",
    //     "minimum_making",
    //     "wastage_from",
    //     "making_from",
    //   ]);

    // if (
    //   product.labourType === "master" &&
    //   isObjWithValues(labourMasterPricing)
    // ) {
    //   let {
    //     labour_from,
    //     wastage,
    //     wastage_from,
    //     per_gram,
    //     making_from,
    //     minimum,
    //   } = labourMasterPricing;
    //   meta_data.concat([
    //     { key: "labour_from", value: labour_from },
    //     { key: "wastage_from", value: wastage_from },
    //     { key: "making_from", value: making_from },
    //     // { key: "minimum_labour", value: minimum_labour },
    //     { key: "labour", value: minimum },
    //     { key: "wastage_percent", value: wastage },
    //     { key: "per_gram", value: per_gram },
    //   ]);
    // }
    if (isArrayWithValues(allLabourMasterPricing) && labourType === "master") {
      let pricingObj = allLabourMasterPricing.find(
        (i) => i.id == selectedLabourPricing
      );
      if (pricingObj) {
        let {
          labour_from,
          wastage,
          wastage_from,
          per_gram,
          making_from,
          minimum_making,
          title,
          id,
        } = pricingObj;
        meta_data = meta_data.concat([
          { key: "labour_from", value: labour_from },
          { key: "wastage_from", value: wastage_from },
          { key: "making_from", value: making_from },
          { key: "minimum_labour", value: minimum_making },
          // { key: "labour", value: minimum_making },
          { key: "wastage_percent", value: wastage },
          { key: "per_gram", value: per_gram },
          { key: "labour_pricing_title", value: id },
        ]);
      } else
        meta_data = meta_data.concat([
          { key: "labour_from", value: "" },
          { key: "wastage_from", value: "" },
          { key: "making_from", value: "" },
          { key: "minimum_labour", value: "" },
          // { key: "labour", value: "" },
          { key: "wastage_percent", value: "" },
          { key: "per_gram", value: "" },
          { key: "labour_pricing_title", value: "" },
        ]);
    } else if (labourType === "customize") {
      let {
        making_from,
        labour_per_gram,
        labour_from,
        wastage_percent,
        minimum_making,
        wastage_from,
      } = product;
      meta_data = meta_data.concat([
        { key: "labour_from", value: labour_from },
        { key: "wastage_from", value: wastage_from },
        { key: "making_from", value: making_from },
        { key: "minimum_labour", value: minimum_making },
        { key: "labour", value: "" },
        { key: "wastage_percent", value: wastage_percent },
        { key: "per_gram", value: labour_per_gram },
      ]);
    } else
      meta_data = meta_data.concat([
        { key: "labour_from", value: "" },
        { key: "wastage_from", value: "" },
        { key: "making_from", value: "" },
        { key: "minimum_labour", value: "" },
        // { key: "labour", value: "" },
        { key: "wastage_percent", value: "" },
        { key: "per_gram", value: "" },
        { key: "labour_pricing_title", value: "" },
      ]);
    // if (product.huid)
    //   meta_data.push({
    //     key: "hallmarked",
    //     value: "yes",
    //   });
    // else
    //   meta_data.push({
    //     key: "hallmarked",
    //     value: "no",
    //   });

    let subcatsetting;
    if (isArrayWithValues(tempSubcatSetting)) {
      subcatsetting = tempSubcatSetting.map((i) => i.size);
      if (!subcatsetting[0])
        subcatsetting = tempSubcatSetting.map((i) => i.value);
      meta_data.push({
        key: "subcatsetting",
        value: subcatsetting,
      });
    }
    if (isArrayWithValues(variants)) {
      let totalGross = 0;
      let totalNet = 0;
      const grossLabel = `${variantType}_gross`;
      const netLabel = `${variantType}_net`;
      let subcatsetting = [];
      for (let i in variants) {
        let obj = variants[i];
        totalGross += validateNumber(obj[grossLabel]);
        totalNet += validateNumber(obj[netLabel]);
        subcatsetting.push(variants[i].size);
      }
      meta_data.concat([
        {
          key: grossLabel,
          value: totalGross,
        },
        {
          key: netLabel,
          value: totalNet,
        },
      ]);
    } else {
      metaKeys = metaKeys.concat([
        "gold_gross",
        "gold_net",
        "silver_gross",
        "silver_net",
        "platinium_gross",
        "platinium_net",
      ]);
    }

    meta_data = meta_data.concat(
      metaKeys.map((i) => ({ key: i, value: product[i] }))
    );
    meta_data = meta_data.map((i) => {
      if (i.key === "labourType" && i.value === "customize")
        return { key: "labourType", value: "individual" };
      if (i.key === "diamond_from" && i.value === "customize")
        return { key: "diamond_from", value: "individual" };
      if (i.key === "colorstone_from" && i.value === "customize")
        return { key: "colorstone_from", value: "individual" };
      else return i;
    });

    let {
      selectedGenders,
      selectedOcassions,
      selectedTags,
      name,
      sku,
      description,
      collections,
      categories,
      stock_quantity,
      stock_status,
      diamond_from,
      diamond,
      diamond_customize,
      colorstone_from,
      colorstone_details,
      colorstone_details_customize,
      selectedUpSell,
      selectedCrossSell,
      id,
      extra_charges,
      subcategory,
      date_on_sale_from,
      date_on_sale_to,
      // labourMasterPricing,
    } = product;
    let images = [];

    if (isArrayWithValues(product.images)) {
      images = product.images.map((i) => ({
        id: i.id,
        src: i.source_url,
        color: i.color,
      }));
      meta_data.push({
        key: "image_colors",
        value: product.images.map((i) => ({
          id: i.id,
          color: i.color,
        })),
      });
    }
    // if (description.length > 60) return;
    // if (hallmarked === "yes" && !isArrayWithValues(huid)) return;
    // if (!huid.find((i) => i.length !== 6)) return;
    if (!name) return setTitleError("Title is required!");
    // if (name.length > 60) return setTitleError("Title max lenght is 60");
    if (!sku) return setSkuError("Product SKU is required!");
    if (
      isArrayWithValues(collections) &&
      collections.find((i) => i.value === "diamond-jewellery")
    )
      if (!isArrayWithValues(diamond) && !isArrayWithValues(diamond_customize))
        return setDiamondError("Please enter at least one diamond details");
    if (isArrayWithValues(diamond) || isArrayWithValues(diamond_customize)) {
      if (diamond_from === "master")
        meta_data.push({
          key: "diamond",
          value: setUpDiamondColorstone(diamond),
        });
      else if (diamond_from === "customize")
        meta_data.push({
          key: "diamond",
          value: setUpDiamondColorstone(diamond_customize),
        });
    }
    if (!isArrayWithValues(diamond) && !isArrayWithValues(diamond_customize))
      meta_data.push({
        key: "diamond",
        value: {},
      });
    if (
      isArrayWithValues(colorstone_details) ||
      isArrayWithValues(colorstone_details_customize)
    ) {
      if (colorstone_from === "master")
        meta_data.push({
          key: "colorstone_details",
          value: setUpDiamondColorstone(colorstone_details),
        });
      if (colorstone_from === "customize")
        meta_data.push({
          key: "colorstone_details",
          value: setUpDiamondColorstone(colorstone_details_customize),
        });
    }

    if (
      !isArrayWithValues(colorstone_details) &&
      !isArrayWithValues(colorstone_details_customize)
    )
      meta_data.push({
        key: "colorstone_details",
        value: {},
      });

    if (extra_charges) {
      meta_data.push({
        key: "extra_charges",
        value: setUpDiamondColorstone(extra_charges),
      });
    }

    if (isObjWithValues(meta_data.find((i) => i.key === "diamond"))) {
      let obj = meta_data.find((i) => i.key === "diamond");
      let totalWeight = calculateTotalDiamondWeight(obj);
      if (totalWeight)
        meta_data.push({
          key: "total_diamond_weight",
          value: totalWeight,
        });
    }

    if (
      isObjWithValues(meta_data.find((i) => i.key === "colorstone_details"))
    ) {
      let obj = meta_data.find((i) => i.key === "colorstone_details");
      let totalWeight = calculateTotalGemstoneWeight(obj);
      if (totalWeight)
        meta_data.push({
          key: "total_gemstone_weight",
          value: totalWeight,
        });
    }

    meta_data.push({
      key: "metal_type",
      value: "",
    });

    let obj = {
      name,
      sku,
      short_description: description,
      description,
      images,
      stock_status,
      status,
      // collections: collections.map((i) => i.id && i.id),
      // categories: categories.map((i) => i.id && { id: i.id }),
      category: categories.map((i) =>
        i.label && i.label.includes("Add Category")
          ? i.label.replaceAll('"', "").replace("Add Category", "").trim()
          : i.label
      ),
      collections: collections.map((i) =>
        i.label && i.label.includes("Add Collection")
          ? i.label.replaceAll('"', "").replace("Add Collection", "").trim()
          : i.label
      ),
      subcategory: subcategory.map((i) =>
        i.label && i.label.includes("Create Subcategory")
          ? i.label.replaceAll('"', "").replace("Create Subcategory", "").trim()
          : i.label
      ),
      // categories: categories.map((i) => i.label && i.label),
      meta_data,
    };

    if (showDiscount) {
      obj.date_on_sale_from = date_on_sale_from;
      obj.date_on_sale_to = date_on_sale_to;
    } else {
      obj.date_on_sale_from = null;
      obj.date_on_sale_to = null;
    }
    if (isArrayWithValues(selectedUpSell))
      obj.upsell_ids = selectedUpSell.map((i) =>
        i.value ? i.value : i.id ? id : false
      );
    if (isArrayWithValues(selectedCrossSell))
      obj.cross_sell_ids = selectedCrossSell.map((i) =>
        i.value ? i.value : i.id ? id : false
      );

    let attributes = [];
    if (isArrayWithValues(selectedOcassions)) {
      selectedOcassions = selectedOcassions.map((i) => i.label);
      attributes.push({
        name: "Ocassions",
        visible: true,
        options: selectedOcassions,
      });
    }
    if (isArrayWithValues(selectedGenders)) {
      attributes.push({
        name: "Gender",
        visible: true,
        options: selectedGenders,
      });
    }
    obj.attributes = attributes;

    //stock status
    if (!stock_status) {
      obj.stock_status = "instock";
    }
    if (stock_status === "onbackorder" || validateNumber(obj.stock_quantity)) {
      obj.backorders = "yes";
      obj.backorders_allowed = true;
      obj.backordered = true;
      obj.stock_quantity = 0;
    } else {
      obj.backorders = "no";
      obj.backorders_allowed = false;
      obj.backordered = false;
    }
    if (stock_status === "outofstock") obj.stock_quantity = null;
    if (stock_status === "instock") {
      // if (stock_quantity) {
      // }
      obj.stock_quantity = Number(stock_quantity) || 1;
      obj.manage_stock = true;
    }
    if (stock_status !== "instock") obj.manage_stock = false;
    // if (isArrayWithValues(selectedTags)) {
    // obj.tags = selectedTags.map((i) => i.id && { id: i.id });
    obj.tag = selectedTags?.map((i) =>
      // i.label &&
      i?.label?.includes("Add Product Tag")
        ? i.label.replaceAll('"', "").replace("Add Product Tag", "").trim()
        : typeof i === "string"
        ? i
        : i.label
    );
    // }
    if (!obj.status) obj.status = "draft";

    // if (isDev()) return;
    let isUpdate = isEdit && id;
    setCreatingProduct(true);
    const website = getWebsite();
    const token = getToken();
    if (website && token) {
      let url = `${website}/wp-json/wc/v3/products${isUpdate ? `/${id}` : ""}`;
      let method = isUpdate ? "PUT" : "POST";
      axios({
        url,
        method,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: obj,
      })
        .then((res) => {
          dispatch(
            addUserActivity({
              event: isUpdate ? "product_update" : "product_add",
              event_info: {
                product_id: res?.data?.id,
                name: res?.data?.name,
              },
            })
          );
          if (isUpdate) {
            dispatch(updateProductInRedux(res.data));
            dispatch(
              setGlobalToast({
                show: true,
                message: "Product updated successfully!",
                severity: "success",
              })
            );
          } else {
            dispatch(addProductInRedux(res.data));
            dispatch(
              setGlobalToast({
                show: true,
                message: "Product added successfully!",
                severity: "success",
              })
            );
            navigate("/products");
          }

          try {
            let product = res.data;
            if (user_type === "supplier") {
              let productFilter = createFilterObjFromProduct(product);
              dispatch(
                updateProductFilter({ [productFilter.id]: productFilter })
              );
            }

            let { meta_data, id: product_id } = product;
            let groupObj = meta_data.find((i) => i.key === "product_groups");
            let allProductGroups = [...(reduxProductGroups || [])];
            if (
              isArrayWithValues(allProductGroups) &&
              isObjWithValues(groupObj)
            ) {
              if (!Array.isArray(groupObj.value)) groupObj.value = [];
              let { value } = groupObj;
              allProductGroups = allProductGroups.map((i) => {
                let { product_ids, id } = i;
                if (!Array.isArray(product_ids)) product_ids = [];
                if (value.includes(id) && !product_ids.includes(product_id)) {
                  product_ids.push(product.id);
                }
                if (!value.includes(id) && product_ids.includes(product_id)) {
                  product_ids = product_ids.filter((i) => i !== product_id);
                }
                return { ...i, product_ids };
              });
              dispatch(
                updateProductGroups(allProductGroups, () => {
                  setCreatingProduct(false);
                })
              );
            } else setCreatingProduct(false);
            setCreatingProduct(false);
          } catch (error) {
            console.log(error);
          }

          updateProductPricing([res?.data?.id], {
            defaultSettings: {
              business_settings: reduxProductSettings,
              master_pricing: reduxMasters,
              promotion_stepper: promotionStepper,
              diamond_group: allDiamondGroups,
              sizeMastersNew,
              allowed_customization,
            },
            defaultAllTaxes: allTaxes,
          });
          // setEditProduct(res.data);
          // let productGroups =

          // onEditProduct(defaultProduct);
          // navigate("/products");
          // addProductInRedux
        })
        .catch((e) => {
          let error = getAxiosError(e);
          console.log(error);
          if (error.code === "product_invalid_sku") {
            dispatch(
              setGlobalToast({
                show: true,
                message: "SKU is already used",
                severity: "error",
              })
            );
            setSkuError("SKU is already used");
          } else setShowErrorToast(true);
          setCreatingProduct(false);
        })
        .finally(() => {
          // dispatch(
          //   fetchAllProducts({ page: 1, per_page: 50, random: Math.random() })
          // );
          // navigate("/products");
        });
    }
  };

  // const addProductsInProductGroups = async (product) => {
  //   let { meta_data, id: productId } = product;
  //   if (
  //     isArrayWithValues(meta_data) &&
  //     meta_data.find((i) => i.key === "product_groups")
  //   ) {
  //     let productGroups = meta_data.find((i) => i.key === "product_groups");
  //     productGroups = productGroups.value;
  //     let allProductGroupsTemp = [...allProductGroups];
  //     if (isArrayWithValues(productGroups)) {
  //       for (let i = 0; i < productGroups.length; i++) {
  //         let { id } = productGroups[i];
  //         for (let j = 0; j < allProductGroupsTemp.length; j++) {
  //           let { products, id: ID } = allProductGroupsTemp[j];
  //           let array = Array.isArray(products) ? products : [];
  //           if (id == ID && !array.includes(productId)) {
  //             array.push(productId);
  //           }
  //           if (id != ID && array.includes(productId)) {
  //             array = array.filter(i => i !== id);
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const calculateTotalMetal = () => {
    metalRef.current.calculateTotalMetal();
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // const handleScroll = useCallback(
  //   (e) => {
  //     var scrolled = document.scrollingElement.scrollTop;
  //     setCollapsed(true);
  //     if (scrolled >= 120) {
  //       scrollPosition(scrolled);
  //     } else {
  //       scrollPosition(scrolled);
  //     }
  //   },
  //   [setCollapsed, scrollPosition]
  // );
  const getoffset = (element) => {
    const elementRect = element?.getBoundingclientRect();
    return elementRect?.top;
  };

  const listenToScroll = () => {
    let heightToHideFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      collapsed && // to limit setting state only the first time
        setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };

  const getTotalWeight = (array, key) => {
    let weight = 0;
    if (isArrayWithValues(array))
      array.map((i) => (weight += validateNumber(i[key])));
    return validateNumber(Number(weight).toFixed(2));
  };
  const getTotalPieces = (array, key) => {
    let pieces = 0;
    if (isArrayWithValues(array))
      array.map((i) => (pieces += validateNumber(i[key])));
    return validateNumber(pieces);
  };

  const getExtraCharges = () => {
    let { extra_charges } = product;
    let total = 0;
    if (isArrayWithValues(extra_charges))
      extra_charges.map((i) => {
        total += validateNumber(i.extra_charge_value);
      });
    return total;
  };

  let {
    metal_color,
    diamond,
    colorstone_details,
    gold_gross,
    silver_gross,
    platinium_gross,
    price_breakup,
    diamond_from,
    colorstone_from,
  } = product;

  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  useMemo(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  useMemo(() => {
    let { add_products } = storePlan;
    let { products } = storeUsed;
    if (products && add_products) setAddLimitReached(products >= add_products);
  }, [storePlan, storeUsed]);

  // useMemo(() => {
  //   calculateTotalProductPrice();
  // }, [priceBreakup]);

  useMemo(() => {
    if (isArrayWithValues(reduxProductGroups))
      setAllProductGroups([...reduxProductGroups]);
  }, [reduxProductGroups]);

  useMemo(() => {
    let { product_groups_temp } = product;
    if (
      isArrayWithValues(allProductGroups) &&
      isArrayWithValues(product_groups_temp)
    ) {
      let array = [...allProductGroups].filter((i) =>
        product_groups_temp.includes(i.id)
      );
      onEditProduct({ product_groups: array });
    }
  }, [product.product_groups_temp, allProductGroups]);

  useEffect(() => {
    if (
      isObjWithValues(reduxProductDefaults) &&
      location.pathname.includes("/products/add")
    ) {
      let {
        collection,
        category,
        metal_type,
        metal_color,
        shipping_days,
        product_shipping,
        stock_status,
        product_group,
        diamond_lab,
        subcatsetting,
      } = reduxProductDefaults;
      let obj = { product_shipping };
      if (isObjWithValues(stock_status)) {
        obj.stock_status = stock_status.value;
        if (
          stock_status.value === "instock" ||
          stock_status.value === "onbackorder"
        )
          obj.stock_quantity = 1;
      }
      if (isObjWithValues(metal_color)) obj.metal_color = metal_color.value;
      if (isObjWithValues(diamond_lab)) obj.diamond_lab = diamond_lab.value;
      if (isObjWithValues(metal_type)) {
        obj.metal_type = metal_type.value;
        obj.metal_types = [`${metal_type.value}`];
      }
      if (isObjWithValues(collection)) obj.collections = [{ ...collection }];
      if (isObjWithValues(category)) obj.categories = [{ ...category }];
      if (isObjWithValues(subcatsetting))
        obj.subcatsetting_temp = [subcatsetting.value];
      onEditProduct({ ...obj });
      // setTimeout(() => onEditProduct({ ...obj }), 500);
    }
  }, [reduxProductDefaults]);

  useMemo(() => {
    let { gender } = reduxProductDefaults;
    if (
      location.pathname.includes("/products/add") &&
      isArrayWithValues(taxonomies.gender) &&
      isObjWithValues(gender)
    ) {
      let { gender: genders } = taxonomies;
      if (genders.find((i) => i.value === gender.value))
        onEditProduct({ selectedGenders: [gender.value] });
      else onEditProduct({ selectedGenders: [] });
    }
  }, [reduxProductDefaults, taxonomies]);

  useMemo(() => {
    let { subcatsetting, metal_types, variants } = product;
    let metal_type =
      metal_types.length === 1
        ? metal_types[0]
        : metal_types.length > 1 && "multi";
    if (
      Array.isArray(subcatsetting) &&
      isArrayWithValues(metal_types) &&
      isObjWithValues(subcatsetting[0]) &&
      Object.keys(subcatsetting[0]).includes("size")
    ) {
      let variantsArray = [...variants];
      if (metal_types.length === 1) {
        if (variants.length > subcatsetting.length)
          for (let i = 0; i < variantsArray.length; i++) {
            if (
              !subcatsetting.find((obj) => obj.size === variantsArray[i].size)
            ) {
              variantsArray = variantsArray.filter(
                (obj) => obj.size !== variantsArray[i].size
              );
              break;
            }
          }
        else
          for (let i = 0; i < subcatsetting.length; i++) {
            if (!variants.find((obj) => obj.size === subcatsetting[i].size))
              variantsArray.push({
                size: subcatsetting[i].size,
                [`${metal_types[0]}_gross`]: "",
                [`${metal_types[0]}_net`]: "",
              });
          }
        onEditProduct({
          // variants: variantsArray,
          variantType: metal_types[0],
          metal_type,
        });
      } else {
        if (!product.dontRemoveVariantsOtherForSet)
          onEditProduct({ variants: [], variantType: "", metal_type });
      }
    } else {
      if (!product.dontRemoveVariantsOtherForSet)
        onEditProduct({ variants: [], variantType: "", metal_type });
    }
    // if (metal_types.length === 1) onEditProduct({ metal_type: metal_types[0] });
    // if (metal_types.length > 1) onEditProduct({ metal_type: "multi" });
  }, [product.metal_types, product.subcatsetting]);

  useMemo(() => {
    if (isArrayWithValues(access?.product)) setProductAccess(access.product);
  }, [access]);

  useMemo(() => {
    if (!isObjWithValues(reduxTaxonomies)) dispatch(fetchTaxonomies(signOut));
    // if (!isObjWithValues(reduxMasters)) dispatch(getMasterPricings({}));
    setIsEdit(location.pathname.includes("/products/edit"));
    onEditProduct({
      isProductUpdate: location.pathname.includes("/products/edit"),
    });

    //eslint-disable-next-line
  }, []);

  useMemo(async () => {
    // if (!isObjWithValues(reduxProductSettings)) dispatch(getProductSettings());
    // if (!isObjWithValues(sizeMasters)) dispatch(getSizeMastsers());
    if (!isObjWithValues(reduxProductDefaults))
      dispatch(getProductDefaultSettings());
    if (!isArrayWithValues(allTaxes)) dispatch(getAllTaxes());
  }, []);
  useMemo(() => {
    if (isObjWithValues(reduxProductSettings))
      setProductSettings({ ...reduxProductSettings });
  }, [reduxProductSettings]);
  useMemo(() => {
    if (isObjWithValues(productSettings)) {
      let { currency, default_currency } = productSettings;
      if (isArrayWithValues(currency)) {
        if (default_currency) setDefaultCurrency(default_currency);
        else setDefaultCurrency(currency[0].value);
        //   onEditProduct({ defaultCurrency: default_currency });
        // else onEditProduct({ defaultCurrency: currency[0].value });
      }
    }
  }, [productSettings]);

  useMemo(() => {
    setTimeout(() => scrollToTop());
    // topRef.current.scrollIntoView &&
    //   topRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //   });
  }, []);

  useMemo(() => {
    if (reduxMasters && Object.keys(reduxMasters).length > 0)
      setMasters(reduxMasters);
  }, [reduxMasters]);
  useMemo(async () => {
    if (reduxTaxonomies && Object.keys(reduxTaxonomies).length > 0) {
      setTaxonomies(reduxTaxonomies);

      if (location.pathname.includes("/products/edit")) {
        let pieces = location.pathname.split("/");
        let product = getProductFromRedux(pieces[pieces.length - 1]);
        if (isObjWithValues(product)) setEditProduct(product);
        else if (isObjWithValues(productToEdit)) setEditProduct(productToEdit);
        else if (!product && !productToEdit) {
          // let product =
          let products = await fetchProduct(pieces[pieces.length - 1]);
          if (isArrayWithValues(products) && products[0])
            dispatch({
              type: "SET_PRODUCT_DATA",
              payload: { editProduct: products[0] },
            });
        }
      }
    }
  }, [reduxTaxonomies, reduxSupplier]);
  useMemo(async () => {
    if (location.pathname.includes("/products/edit")) {
      let pieces = location.pathname.split("/");
      let product = getProductFromRedux(pieces[pieces.length - 1]);
      if (isObjWithValues(product)) setEditProduct(product);
      else if (isObjWithValues(productToEdit)) setEditProduct(productToEdit);
      else if (!product && !productToEdit) {
        // let product =
        setFetchingProduct(true);
        let products = await fetchProduct(pieces[pieces.length - 1]);
        if (isArrayWithValues(products) && products[0])
          dispatch({
            type: "SET_PRODUCT_DATA",
            payload: { editProduct: products[0] },
          });
        setFetchingProduct(false);
      }
    }
  }, []);
  useMemo(() => {
    if (isObjWithValues(productToEdit) && productToEdit.id !== product.id)
      setEditProduct(productToEdit);
  }, [productToEdit, reduxSupplier]);

  const scrollToTop = () => {
    const domNode = topRef?.current;
    if (domNode) {
      domNode.scrollTop = 0;
    }
  };

  const calculateTotalProductPrice = () => {
    let total = 0;
    let { master, customize } = { ...priceBreakup };
    if (master) {
      if (product.colorstone_from === "master")
        total += validateNumber(master.colorstone);
      if (product.diamond_from === "master")
        total += validateNumber(master.diamond);
      total += validateNumber(master.labour);
      total += validateNumber(master.metal);
      total += validateNumber(master.extraCharges);
    }
    if (customize) {
      if (product.colorstone_from === "customize")
        total += validateNumber(customize.colorstone);
      if (product.diamond_from === "customize")
        total += validateNumber(customize.diamond);
    }

    // let shouldAddTax = true;
    // if (isObjWithValues(promotionStepper) && promotionStepper?.enable) {
    //   let { price: totalStepperPrice } = getPromotionStepperPrice(
    //     promotionStepper,
    //     total
    //   );

    //   if (totalStepperPrice) {
    //     let newDiscount = calculateDiscountFromPriceChange(
    //       Math.round(total),
    //       Math.round(discount),
    //       Math.round(totalStepperPrice)
    //     );

    //     if (newDiscount) discount = newDiscount;
    //     total = totalStepperPrice;
    //     totalTax = Math.round((total * validateNumber(tax)) / 100);
    //     shouldAddTax = false;
    //   }
    // }

    setProductTotalPriceWithoutTax(total);
    let discountPriceWithoutTax = total - discount;
    if (total) {
      let taxRate = currentTaxRate || 1;
      setCurrentTax(
        discount
          ? Math.round(
              (discountPriceWithoutTax * validateNumber(taxRate)) / 100
            )
          : Math.round((total * validateNumber(taxRate)) / 100)
      );
      total += Math.round((total * validateNumber(taxRate)) / 100);
      setDiscountedTotalPrice(
        Math.round(
          discountPriceWithoutTax +
            Math.round(
              (discountPriceWithoutTax * validateNumber(taxRate)) / 100
            )
        )
      );
    }
    setProductTotalPrice(total);
    // onEditProduct({ price: total });
  };

  useEffect(() => {
    let _priceBreakup =
      getProductPrice(
        product,
        reduxMasters,
        productSettings,
        allDiamondGroups,
        currentTaxRate,
        promotionStepper
      ) || {};
    setDiscountBreakup(_priceBreakup?.discount);
    setDiscount(_priceBreakup?.totalDiscount);
    setPriceBreakup({ ..._priceBreakup });
    setProductTotalPrice(_priceBreakup?.totalPrice);
    setDiscountedTotalPrice(_priceBreakup?.price);
    setProductTotalPriceWithoutTax(_priceBreakup?.priceWithoutTax);
    setCurrentTax(_priceBreakup?.tax);
    if (validateNumber(product.manual_price)) {
      onEditProduct({ discountOnProduct: true });
    }
    console.log(_priceBreakup);
    return;
    // no need to calculate here

    let labour = labourRef?.current?.calculateTotalLabour();
    let metal = metalRef?.current?.calculateTotalMetal();

    let {
      price_breakup,
      diamond_from,
      colorstone_from,
      extra_charges,
      onlyColorstone,
      onlyDiamond,
      custom_discounts,
      // profit_margin,
    } = product;
    let { margin_pricing } = masters;
    let marginPricing = margin_pricing?.[defaultCurrency];
    let extraCharges = 0;
    if (isArrayWithValues(extra_charges))
      extra_charges.map(
        (i) => (extraCharges += validateNumber(i.extra_charge_value))
      );

    let diamond = 0,
      gemstone = 0;
    if (diamondRef && diamondRef?.current) {
      diamond = diamondRef?.current?.getTotalDiamond();
    }
    if (gemstoneRef && gemstoneRef?.current)
      gemstone = gemstoneRef?.current?.getTotalGemstone();

    if (onlyDiamond) {
      metal = 0;
      gemstone = 0;
      labour = 0;
    } else if (onlyColorstone) {
      metal = 0;
      diamond = 0;
      labour = 0;
    }

    let priceBreakup = {
      ...price_breakup,
      master: { ...price_breakup.master, labour, metal, extraCharges },
    };

    priceBreakup = {
      ...priceBreakup,
      [diamond_from]: { ...priceBreakup[diamond_from], diamond },
    };
    priceBreakup = {
      ...priceBreakup,
      [colorstone_from]: {
        ...priceBreakup[colorstone_from],
        colorstone: gemstone,
      },
    };

    let isERPProduct = product?.ornate_unique_id || product?.jemisys_product;
    if (isERPProduct && marginPricing) {
      let ERPName = product?.ornate_unique_id
        ? "ornate"
        : product?.jemisys_product
        ? "jemisys"
        : null;
      let margin = getMarginRate({
        product,
        margin_pricing: marginPricing,
        margin_on: { erp: [ERPName] },
      });
      if (margin)
        priceBreakup = addMarginInPriceBreakup({
          priceBreakup,
          product,
          marginCombination: margin,
        });
    }

    // if (
    //   profit_margin?.enabled &&
    //   validateNumber(profit_margin?.value) &&
    //   profit_margin?.cost_component
    // ) {
    //   let { value, cost_component } = profit_margin;

    //   if (cost_component === "diamond") {
    //     let price = priceBreakup[diamond_from]?.diamond;
    //     let profitToAdd =
    //       // validateNumber(price) +
    //       validateNumber(price) * (validateNumber(value) / 100);
    //     priceBreakup[diamond_from].diamond =
    //       priceBreakup[diamond_from].diamond + Math.round(profitToAdd);
    //   }
    //   if (cost_component === "colorstone") {
    //     let price = priceBreakup[colorstone_from]?.colorstone;
    //     let profitToAdd =
    //       // validateNumber(price) +
    //       validateNumber(price) * (validateNumber(value) / 100);
    //     priceBreakup[colorstone_from].colorstone =
    //       priceBreakup[colorstone_from].colorstone + Math.round(profitToAdd);
    //   }
    //   if (cost_component === "labour") {
    //     let price = priceBreakup?.master?.labour;
    //     let profitToAdd =
    //       // validateNumber(price) +
    //       validateNumber(price) * (validateNumber(value) / 100);
    //     priceBreakup.master.labour =
    //       priceBreakup.master.labour + Math.round(profitToAdd);
    //   }
    // }

    let discount = { labour: 0, metal: 0, diamond: 0, gemstone: 0, total: 0 };
    if (isObjWithValues(custom_discounts)) {
      if (custom_discounts.on_sale_metal) {
        let { type, value } = custom_discounts.on_sale_metal;
        if (value)
          if (type == "by_percentage")
            discount.metal += Number(metal) * (validateNumber(value) / 100);
          else if (type == "by_amount") discount.metal += Number(value);
      }
      if (custom_discounts.on_sale_making) {
        let { type, value } = custom_discounts.on_sale_making;
        if (value)
          if (type == "by_percentage")
            discount.labour += Number(labour) * (validateNumber(value) / 100);
          else if (type == "by_amount") discount.labour += Number(value);
      }
      if (custom_discounts.on_sale_diamond) {
        let { type, value } = custom_discounts.on_sale_diamond;
        if (value)
          if (type == "by_percentage")
            discount.diamond += Number(diamond) * (validateNumber(value) / 100);
          else if (type == "by_amount") discount.diamond += Number(value);
      }
      if (custom_discounts.on_sale_colorstone) {
        let { type, value } = custom_discounts.on_sale_colorstone;
        if (value)
          if (type == "by_percentage")
            discount.gemstone +=
              Number(gemstone) * (validateNumber(value) / 100);
          else if (type == "by_amount") discount.gemstone += Number(value);
      }
      if (custom_discounts.on_total_sale) {
        let { type, value } = custom_discounts.on_total_sale;
        if (value) {
          if (type == "by_percentage")
            discount.total +=
              Number(getTotalProductPrice(product, priceBreakup)) *
              (validateNumber(value) / 100);
          else if (type == "by_amount") discount.total += Number(value);
        }
      }
    }
    let totalDiscount = 0;
    Object.values(discount).map((i) => (totalDiscount += i));
    setDiscountBreakup(discount);
    setDiscount(totalDiscount);
    setPriceBreakup({ ...priceBreakup });
    // startTransition(() => {
    // });
  }, [
    JSON.stringify(product.metal_types),
    product.gold_kt,
    product.gold_gross,
    product.gold_net,
    product.silver_purity,
    product.silver_gross,
    product.silver_net,
    product.platinium_purity,
    product.platinium_gross,
    product.platinium_net,
    product.variants,
    product.subcatsetting,
    product.diamond_from,
    product.colorstone_from,
    product.extra_charges,
    product.labourType,
    product.labour_from,
    product.making_from,
    product.minimum_making,
    product.labour_per_gram,
    product.wastage_percent,
    product.onlyColorstone,
    product.onlyDiamond,
    product.custom_discounts,
    product.profit_margin,
    product.custom_wastage_from,
    product.manual_price,

    JSON.stringify(product.diamond),
    JSON.stringify(product.diamond_customize),
    JSON.stringify(product.colorstone_details),
    JSON.stringify(product.colorstone_details_customize),

    selectedLabourPricing,
    allLabourMasterPricing,
    JSON.stringify(reduxMasters),
    JSON.stringify(reduxProductSettings),
  ]);
  if (fetchingProduct) return <Loader />;
  return (
    <div ref={topRef} style={{ paddingTop: "14px" }}>
      {/* <AppBar
        position="sticky"
        sx={{
          top: 64,
          // width: "calc(100vw- 100px)",
          // position: "relative",
          // right: "calc(-50vw + 50%)",
        }}
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}></Grid>
            <Grid item></Grid>
            <Grid item xs />
            <Grid item>
              <Stack
                direction="row"
                justifyContent={"flex-end"}
                alignItems="center"
                sx={{
                  backgroundColor: collapsed ? "blue" : "green",
                }}
              >
                <Typography variant="h4">
                  {currencySymbols[defaultCurrency] || ""} {productTotalPrice}
                </Typography>
                <LoadingButton
                  size="large"
                  onClick={onAddProduct}
                  loading={creatingProduct}
                  // loadingIndicator="Creating..."
                  variant="outlined"
                  disabled={isEdit ? !productAccess.includes("edit") : false}
                >
                  {isEdit ? scrollPosition : "Create"} Product
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> */}
      <Snackbar
        open={showAddToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginRight: "2vw" }}
        TransitionComponent={Slide}
        onClose={() => setShowAddToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Product {isEdit ? "updated" : "created"} successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginRight: "2vw" }}
        TransitionComponent={Slide}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setShowErrorToast(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Unable to {isEdit ? "update" : "create"} Product, Try again!
        </Alert>
      </Snackbar>
      {
        <>
          {" "}
          <Button
            variant="text"
            startIcon={<ArrowBackIosNew />}
            onClick={() => navigate("/products")}
          >
            All Products
          </Button>
          <Grid
            justifycontent="space-between"
            // mt={1.5}
            container
            // spacing={3}
            sx={{
              position: "sticky",
              top: isMobile ? "46px" : "63px",
              backgroundColor: "#fff",
              zIndex: 100,
            }}
            // style={{ backgroundColor: "#fff" }}
          >
            <Grid
              item
              xs={12}
              sx={
                {
                  // position: "-webkit-sticky",
                  // top: "64px",
                  // backgroundColor: "Background.main",
                }
              }
            >
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  padding: "10px 14px",
                }}
                id="test"
              >
                {!isMobile ? (
                  <Box>
                    <Typography variant="h3" gutterBottom display="inline">
                      {isEdit ? "Edit" : "Add"} Product{" "}
                      <IconButton
                        onClick={() =>
                          dispatch(
                            setGlobalTutorial({
                              show: true,
                              url: tutorialData?.upload_product?.link,
                              title: tutorialData?.upload_product?.title,
                            })
                          )
                        }
                      >
                        <YouTube />
                      </IconButton>
                    </Typography>
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                      <Link component={NavLink} to="/">
                        Dashboard
                      </Link>
                      <Link component={NavLink} to="/products">
                        Products
                      </Link>
                      <Typography>
                        {isEdit ? "Update" : "Add"} Product
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                ) : null}
                {true ? (
                  <Stack
                    direction="row"
                    justifyContent={"flex-end"}
                    alignItems="center"
                  >
                    <Stack sx={{ mr: 5 }}>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "600", mr: 2 }}
                        >
                          Publish
                        </Typography>
                        <Switch
                          onChange={(e) => {
                            onEditProduct({ status: e ? "publish" : "draft" });
                          }}
                          checked={product.status === "publish"}
                          style={{
                            border: `1px solid ${
                              product.status === "publish"
                                ? backgroundColor
                                : "#e8e8e8"
                            }`,
                            backgroundColor:
                              product.status === "publish"
                                ? backgroundColor
                                : "#e8e8e8",
                          }}
                        />
                        <IconButton
                          size="small"
                          onClick={() =>
                            window.open(product.permalink, "_blank")
                          }
                          sx={{
                            ml: 2,
                            visibility:
                              product.permalink && product.status === "publish"
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <Storefront />
                        </IconButton>
                      </Stack>
                      {!isMobile && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "text.secondary",
                            mt: 1,
                          }}
                        >
                          Product will be publish in store
                        </Typography>
                      )}
                    </Stack>
                    <IconButton
                      size="small"
                      mr={3}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <Info />
                    </IconButton>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                        // width: "400px",
                      }}
                      open={summaryOpen}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <ProductPriceBreakup
                        onMouseLeave={handlePopoverClose}
                        product={product}
                        currentTax={currentTax}
                        priceBreakup={priceBreakup}
                        defaultCurrency={defaultCurrency}
                        getTotalWeight={getTotalWeight}
                        getTotalPieces={getTotalPieces}
                        getExtraCharges={getExtraCharges}
                        productTotalPrice={productTotalPrice}
                      />
                      {/* <Box
                        onMouseLeave={handlePopoverClose}
                        sx={{
                          width: { md: "400px" },
                          px: 3,
                          py: 5,
                          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                        }}
                      >
                        <Typography
                          sx={{ p: 1, fontSize: "16px", fontWeight: "600" }}
                        >
                          Product Breakup
                        </Typography>
                        <Divider sx={{ mb: 3, mt: 1.5 }} />
                        <SummaryTitle title="Metal" />

                        <SummaryLabelValue
                          title={"Metal Gross Weight"}
                          value={Number(
                            Number(gold_gross) +
                              Number(silver_gross) +
                              Number(platinium_gross)
                          ).toFixed(3)}
                        />
                        <SummaryLabelValue
                          title={"Metal Color"}
                          value={metal_color}
                        />
                        {getTotalWeight(
                          colorstone_details,
                          "colorstone_weight"
                        ) ||
                        getTotalPieces(
                          colorstone_details,
                          "colorstone_pieces"
                        ) ? (
                          <SummaryTitle title="Gemstone Details" />
                        ) : null}
                        <SummaryLabelValue
                          title={"Gemstone Weight (cts.)"}
                          value={getTotalWeight(
                            colorstone_details,
                            "colorstone_weight"
                          )}
                          valueSuffix={"ct."}
                        />
                        <SummaryLabelValue
                          title={"Gemstone Pieces"}
                          value={getTotalPieces(
                            colorstone_details,
                            "colorstone_pieces"
                          )}
                          valueSuffix={"pcs."}
                        />
                        {getTotalWeight(diamond, "diamond_weight") ||
                        getTotalPieces(diamond, "diamond_pieces") ? (
                          <SummaryTitle title="Diamond Details" />
                        ) : null}
                        <SummaryLabelValue
                          title={"Diamond Weight (cts.)"}
                          value={getTotalWeight(diamond, "diamond_weight")}
                          valueSuffix={"ct."}
                        />
                        <SummaryLabelValue
                          title={"Diamond Pieces"}
                          value={getTotalPieces(diamond, "diamond_pieces")}
                          valueSuffix={"pcs."}
                        />
                        <SummaryTitle title="Price Breakup" />
                        <SummaryLabelValue
                          title={"Metal"}
                          value={validateNumber(priceBreakup?.master?.metal)}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <SummaryLabelValue
                          title={"Gemstone"}
                          value={validateNumber(
                            priceBreakup[colorstone_from]?.colorstone
                          )}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <SummaryLabelValue
                          title={"Diamond"}
                          value={validateNumber(
                            priceBreakup[diamond_from]?.diamond
                          )}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <SummaryLabelValue
                          title={"Labour"}
                          value={validateNumber(priceBreakup.master?.labour)}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <SummaryLabelValue
                          title={"Extra charges"}
                          value={getExtraCharges()}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <SummaryLabelValue
                          title={"GST/Tax"}
                          value={currentTax}
                          valuePrefix={currencySymbols[defaultCurrency]}
                        />
                        <Divider sx={{ my: 2.5 }} />
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          justifyContent="space-between"
                          sx={{ my: 2 }}
                        >
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            {"Total"}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            {`${
                              currencySymbols[defaultCurrency] || ""
                            } ${validateNumber(productTotalPrice)}`}
                          </Typography>
                        </Stack>
                      </Box> */}
                    </Popover>
                    {discount ? (
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "13px",
                          marginTop: "4px",
                          textDecoration: "line-through",
                        }}
                        mr={2}
                      >
                        {currencySymbols[defaultCurrency] || ""}{" "}
                        {productSettings?.hide_pricing
                          ? validateNumber(
                              validateNumber(product.manual_price) +
                                validateNumber(currentManualTax)
                            )
                          : productTotalPrice}
                      </Typography>
                    ) : null}
                    <Box sx={{ mr: 2 }}>
                      {!isMobile && (
                        <Typography variant="h4" mr={2}>
                          {currencySymbols[defaultCurrency] || ""}{" "}
                          {productSettings?.hide_pricing ||
                          Number(product.manual_price)
                            ? discount
                              ? Math.round(
                                  validateNumber(product.manual_price) -
                                    discount +
                                    validateNumber(currentManualTax)
                                )
                              : Math.round(
                                  validateNumber(product.manual_price) +
                                    validateNumber(currentManualTax)
                                )
                            : productTotalPrice
                            ? discount
                              ? Math.round(validateNumber(discountedTotalPrice))
                              : productTotalPrice
                            : 0}
                        </Typography>
                      )}
                      {!productSettings?.hide_pricing &&
                      Number(product.manual_price) ? (
                        <Typography
                          sx={{
                            fontSize: "11px",
                            color: "text.secondary",
                            mt: 1,
                          }}
                          mr={2}
                        >
                          Manual Price
                        </Typography>
                      ) : null}
                    </Box>
                    <LoadingButton
                      size="large"
                      onClick={onAddProduct}
                      loading={creatingProduct}
                      sx={{
                        whiteSpace: "nowrap",
                        padding: isMobile ? "8px 11px" : "8px 22px",
                      }}
                      // loadingIndicator="Creating..."
                      variant="contained"
                      disabled={
                        (isEdit
                          ? !productAccess.includes("edit") && !isAdmin
                          : false) || isEdit
                          ? false
                          : addLimitReached
                      }
                    >
                      {isEdit ? "Update" : "Create"} Product
                    </LoadingButton>
                  </Stack>
                ) : null}
              </div>
              {false ? (
                <Stack
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems="center"
                >
                  <Box>
                    {discount ? (
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "13px",
                          marginTop: "4px",
                          textDecoration: "line-through",
                        }}
                        mr={2}
                      >
                        {currencySymbols[defaultCurrency] || ""}{" "}
                        {product.manual_price || productTotalPrice}
                      </Typography>
                    ) : null}
                    <Typography variant="h4" mr={2}>
                      {currencySymbols[defaultCurrency] || ""}{" "}
                      {product.manual_price
                        ? discount
                          ? Math.round(
                              validateNumber(product.manual_price) - discount
                            )
                          : product.manual_price
                        : productTotalPrice
                        ? discount
                          ? Math.round(
                              validateNumber(productTotalPrice) - discount
                            )
                          : productTotalPrice
                        : 0}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "11px", color: "text.secondary", mt: 1 }}
                      mr={2}
                    >
                      Manual Price
                    </Typography>
                  </Box>

                  <LoadingButton
                    size="large"
                    onClick={onAddProduct}
                    loading={creatingProduct}
                    // loadingIndicator="Creating..."
                    variant="outlined"
                    disabled={isEdit ? !productAccess.includes("edit") : false}
                  >
                    {isEdit ? "Update" : "Create"} Product
                  </LoadingButton>
                </Stack>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isMobile && (
                <>
                  {" "}
                  <Typography
                    variant="h4"
                    mr={2}
                    sx={{ textAlign: "end", paddingRight: "24px" }}
                  >
                    {currencySymbols[defaultCurrency] || ""}{" "}
                    {Number(product.manual_price)
                      ? discount
                        ? Math.round(
                            validateNumber(product.manual_price) - discount
                          )
                        : product.manual_price
                      : productTotalPrice
                      ? discount
                        ? Math.round(
                            validateNumber(productTotalPrice) - discount
                          )
                        : productTotalPrice
                      : 0}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </>
      }
      <form>
        <Grid container spacing={5} mt={isMobile ? 2 : 3} mb={5}>
          {product?.supplierInfo?.name ? (
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                alignItems="center"
                gap="10px"
                justifyContent={"flex-end"}
                mr={4}
              >
                <Inventory2Outlined fontSize="small" color="success" />{" "}
                <Typography fontWeight={"600"} sx={{ color: "success.main" }}>
                  By {product?.supplierInfo?.name}
                </Typography>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={7}>
            <PrimaryDetails
              taxonomies={taxonomies}
              canAddCollection={canAddCollection}
              masters={masters}
              onEditProduct={onEditProduct}
              product={product}
              titleError={titleError}
              setTitleError={setTitleError}
              productSettings={productSettings}
              sizeMasters={sizeMasters}
              setAllLabourMasterPricing={setAllLabourMasterPricing}
              defaultCurrency={defaultCurrency}
              trybeforeyoubuyIntegration={trybeforeyoubuyIntegration}
              camwearaIntegration={camwearaIntegration}
              mirrARIntegration={mirrARIntegration}
              storePlan={storePlan}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <ProductDetails
              taxonomies={taxonomies}
              masters={masters}
              onEditProduct={onEditProduct}
              product={product}
              skuError={skuError}
              setSkuError={setSkuError}
              defaultCurrency={defaultCurrency}
              productSettings={productSettings}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={5} mb={5}>
          <Grid
            item
            xs={12}
            sx={{
              display:
                productSettings &&
                isArrayWithValues(productSettings.types) &&
                !product.onlyDiamond &&
                [...productSettings.types].find((i) =>
                  ["gemstone", "cz", "antique"]?.includes(i.value)
                )
                  ? "block"
                  : "none",
            }}
          >
            <GemstoneDetails
              masters={masters}
              onEditProduct={onEditProduct}
              product={product}
              productSettings={productSettings}
              ref={gemstoneRef}
              price_breakup={priceBreakup}
              defaultCurrency={defaultCurrency}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display:
                isArrayWithValues(product.diamond_customize) ||
                isArrayWithValues(product.diamond) ||
                (productSettings &&
                  isArrayWithValues(productSettings.types) &&
                  [...productSettings.types].find(
                    (i) => i.value === "diamond"
                  ) &&
                  !product.onlyColorstone &&
                  isArrayWithValues(product.collections) &&
                  product.collections.find(
                    (i) => i.value && i.value.includes("diamond")
                  ))
                  ? "block"
                  : "none",
            }}
          >
            <DiamondDetails
              ref={diamondRef}
              masters={masters}
              onEditProduct={onEditProduct}
              product={product}
              diamondError={diamondError}
              setDiamondError={setDiamondError}
              productSettings={productSettings}
              price_breakup={priceBreakup}
              defaultCurrency={defaultCurrency}
              allDiamondGroups={allDiamondGroups}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display:
                !product.noMetals &&
                !product.onlyColorstone &&
                !product.onlyDiamond
                  ? "block"
                  : "none",
            }}
          >
            <MetalDetails
              masters={masters}
              onEditProduct={onEditProduct}
              product={product}
              productSettings={productSettings}
              ref={metalRef}
              price_breakup={priceBreakup}
              defaultCurrency={defaultCurrency}
            />
          </Grid>
          {!productSettings?.hide_pricing ? (
            <>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  display:
                    !product.onlyColorstone && !product.onlyDiamond
                      ? "block"
                      : "none",
                }}
              >
                <LabourDetails
                  masters={masters}
                  onEditProduct={onEditProduct}
                  product={product}
                  calculateTotalMetal={calculateTotalMetal}
                  ref={labourRef}
                  price_breakup={priceBreakup}
                  selectedLabourPricing={selectedLabourPricing}
                  setSelectedLabourPricing={setSelectedLabourPricing}
                  allLabourMasterPricing={allLabourMasterPricing}
                  defaultCurrency={defaultCurrency}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={!product.onlyColorstone && !product.onlyDiamond ? 5 : 12}
              >
                <ExtraCharges
                  masters={masters}
                  onEditProduct={onEditProduct}
                  product={product}
                  calculateTotalMetal={calculateTotalMetal}
                  ref={labourRef}
                  price_breakup={priceBreakup}
                  selectedLabourPricing={selectedLabourPricing}
                  setSelectedLabourPricing={setSelectedLabourPricing}
                  allLabourMasterPricing={allLabourMasterPricing}
                  defaultCurrency={defaultCurrency}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid container spacing={3} mt={5} mb={5}>
          {/* <Grid item xs={12} md={6}>
            <ProductFilters
              taxonomies={taxonomies}
              onEditProduct={onEditProduct}
              product={product}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <ProductDescription
              onEditProduct={onEditProduct}
              product={product}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={5} mb={5}>
          <Grid item xs={12}>
            <AdditionalOptions
              onEditProduct={onEditProduct}
              product={product}
              defaultCurrency={defaultCurrency}
              onMouseLeave={handlePopoverClose}
              currentTax={currentTax}
              priceBreakup={priceBreakup}
              getTotalWeight={getTotalWeight}
              getTotalPieces={getTotalPieces}
              getExtraCharges={getExtraCharges}
              productTotalPrice={productTotalPrice}
              productSettings={productSettings}
              isEdit={isEdit}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AddProduct;

export const SummaryLabelValue = ({
  title,
  value,
  valuePrefix,
  valueSuffix,
  subtitle,
}) => {
  if (!value) return null;
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{ my: 2 }}
    >
      <Stack direction="row" alignItems={"flex-end"} spacing={2}>
        <Typography sx={{ fontSize: "13px" }}>{title}</Typography>
        {subtitle && (
          <Typography sx={{ ...secondaryLabelSmall, color: "success.main" }}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
        {valuePrefix || ""} {value} {valueSuffix || ""}
      </Typography>
    </Stack>
  );
};
export const SummaryTitle = ({ title }) => {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        borderRadius: "6px",
        my: 2,
      }}
    >
      <Typography sx={{ p: 1, fontSize: "14px", fontWeight: "600" }}>
        {title}
      </Typography>
    </Box>
  );
};

// const singleSizeMasters =
export const singleSizeMasters = {
  earrings: [
    { label: "Mumbai Screw", value: "Mumbai Screw" },
    { label: "South Screw", value: "South Screw" },
    { label: "Others", value: "Others" },
  ],
  necklaces: [
    { label: "Without Chain", value: "Without Chain" },
    { label: "With Chain", value: "With Chain" },
  ],
  pendants: [
    { label: "Without Chain", value: "Without Chain" },
    { label: "With Chain", value: "With Chain" },
  ],
  "nose-pins": [
    { label: "Screw", value: "Screw" },
    { label: "Wire Type", value: "Wire Type" },
  ],
};

export const colorsBackground = {
  white: "#EBEBEB",
  yellow: "#EAC97D",
  green: "#14903F",
  rose: "#FFCBB2",
  "two-tone":
    "linear-gradient(90deg, rgba(192,192,192,1) 0%, rgba(192,192,192,1) 20%, rgba(183,110,121,1) 71%, rgba(183,110,121,1) 100%)",
};

const PrimaryDetails = memo((props) => {
  let {
    product,
    onEditProduct,
    taxonomies,
    masters,
    titleError,
    productSettings,
    setTitleError,
    sizeMasters,
    setAllLabourMasterPricing,
    defaultCurrency,
    trybeforeyoubuyIntegration,
    camwearaIntegration,
    storePlan,
    canAddCollection,
    mirrARIntegration,
  } = props;
  let {
    name,
    subcategory,
    categories,
    collections,
    _woodmart_product_video,
    images,
    subcatsetting,
    subcatsetting_temp,
    metal_color,
    virtual_try,
    isProductUpdate,
    virtualTryImage,
    camweara_show_tryon,
    try_on_image_src,
    mirrAR_tryon,
  } = product;
  let { labour_pricing } = masters;
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [virtualTryLibraryOpen, setVirtualTryLibraryOpen] = useState(false);
  let theme = useTheme();
  const [allCollections, setCollections] = useState([]);
  const [allCategories, setCategories] = useState([]);
  const [allSubCategories, setSubCategories] = useState([]);
  const [singleSizeSettings, setSingleSizeSettings] = useState([]);
  const [sizeSettings, setSizeSettings] = useState([]);
  const [selectedSizeSettings, setSelectedSizeSettings] = useState([]);
  const [allSizeMasters, setAllSizeMasters] = useState({});
  const [defaultSizes, setDefaultSizes] = useState({});
  const [metalColors, setMetalColors] = useState(allMetalColors);
  const user_type = useSelector((state) => state.user.user_type);
  const dragRef = useRef();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const setProductImages = (array) => {
    toggleMediaLibrary(false);
    if (isArrayWithValues(array)) {
      let images = [];
      for (let i = 0; i < array.length; i++) {
        images.push({ ...array[i], color: metal_color || "" });
      }
      onEditProduct({ images });
    }
  };

  const setVirtualTryImage = (array) => {
    setVirtualTryLibraryOpen(false);
    if (isArrayWithValues(array)) {
      onEditProduct({
        virtualTryImage: array,
        try_on_image_src: array[0].source_url,
      });
    }
  };

  const removeProductImage = (id) => {
    if (product?.pull_source) return;
    let arr = [...images];
    arr = arr.filter((i) => i.id !== id);
    onEditProduct({ images: arr });
  };

  const onColorClick = (id, color) => {
    let array = [...images];
    array = array.map((i) => (i.id === id ? { ...i, color } : i));
    onEditProduct({ images: array });
  };

  const onImagesSort = (oldIndex, newIndex) => {
    let { images } = product;
    let sortedImages = arrayMove(images, oldIndex, newIndex);
    onEditProduct({ images: sortedImages });
  };

  useMemo(() => {
    if (isObjWithValues(sizeMasters)) {
      let obj = {};
      let defaultSizes = {};
      let keys = Object.keys(sizeMasters);

      let allSizeMasters = {};

      for (let key of keys) {
        let obj = sizeMasters[key];
        if (!obj.delete && isArrayWithValues(obj.sizes)) {
          allSizeMasters[key] = obj.sizes.map((i) => ({
            ...i,
            value: i.label,
          }));
          if (obj.default) {
            let defaultSize = obj.sizes.find((i) => i.label == obj.default);
            if (defaultSize)
              defaultSizes[key] = { ...defaultSize, value: defaultSize.label };
          }
        }
      }

      // for (let i = 0; i < keys.length; i++) {
      //   let key = keys[i];
      //   let value = sizeMasters[key];
      //   let trimmedKey = key.substring(0, key.lastIndexOf("_"));
      //   if (value.size)
      //     if (Array.isArray(obj[trimmedKey])) {
      //       if (value.enable)
      //         obj[trimmedKey].push({
      //           ...value,
      //           label: value.size,
      //           value: value.size,
      //         });
      //     } else
      //       obj[trimmedKey] = [
      //         { ...value, label: value.size, value: value.size },
      //       ];

      //   if (value.default_size) {
      //     let temp = sizeMasters[value.default_size];
      //     if (isObjWithValues(temp))
      //       defaultSizes[key] = { ...temp, label: temp.size, value: temp.size };
      //   }
      // }
      setDefaultSizes(defaultSizes);
      setAllSizeMasters(allSizeMasters);
    }
  }, [sizeMasters]);

  useMemo(() => {
    if (name.length > 60) {
      setTitleError("Title max lenght is 60");
    } else {
      setTitleError(false);
    }
  }, [name]);

  useMemo(() => {
    if (isArrayWithValues(productSettings.colors))
      setMetalColors(productSettings.colors);
  }, [productSettings]);

  useMemo(() => {
    if (user_type === "supplier" && isObjWithValues(productSettings)) {
      let { collections, categories } = productSettings;
      if (Array.isArray(categories) && categories.length > 0)
        setCategories(categories);
      if (collections && isArrayWithValues(collections))
        setCollections(collections);
    }
    if (isObjWithValues(productSettings)) {
      if (user_type === "retailer") {
        let { collections, categories } = taxonomies;
        if (Array.isArray(categories) && categories.length > 0)
          setCategories(categories);
        if (collections && isArrayWithValues(collections))
          setCollections(collections);
      }
      if (taxonomies["sub-categories"])
        setSubCategories(taxonomies["sub-categories"]);
    }
    // eslint - disable - next - line;
  }, [taxonomies, user_type, productSettings]);

  useEffect(() => {
    setTimeout(() => {
      if (!isArrayWithValues(subcatsetting))
        if (
          // isArrayWithValues(sizeSettings) &&
          isArrayWithValues(subcatsetting_temp) &&
          isArrayWithValues(categories)
        ) {
          let subcatsetting_main = [];
          for (let i in subcatsetting_temp) {
            let obj;
            obj = sizeSettings.find(
              (obj) => obj.value == subcatsetting_temp[i]
            );
            if (!obj)
              obj = singleSizeSettings.find(
                (obj) => obj.value == subcatsetting_temp[i]
              );
            if (obj) subcatsetting_main.push(obj);
          }
          if (
            isArrayWithValues(subcatsetting_main) &&
            !isArrayWithValues(subcatsetting)
          )
            onEditProduct({ subcatsetting: subcatsetting_main });
        }
    }, 500);
  }, [sizeSettings, subcatsetting_temp, categories, subcatsetting]);

  // useMemo(() => {
  //   if (titleError) setTitleError(false);
  // }, [name]);

  useMemo(() => {
    if (isArrayWithValues(sizeSettings)) {
      let { value } = categories[0];
      if (value && defaultSizes[value] && !isProductUpdate) {
        // setTimeout(() => {
        //   onEditProduct({ subcatsetting: [{ ...defaultSizes[value] }] });
        // }, 400);
      }
    }
  }, [sizeSettings, isProductUpdate]);

  useMemo(() => {
    if (
      Array.isArray(collections) &&
      Array.isArray(categories) &&
      isObjWithValues(labour_pricing)
    ) {
      let temp_collections = collections.map((i) => i.label).filter(Boolean);
      let temp_categories = categories.map((i) => i.label).filter(Boolean);
      let temp_subcategories = isArrayWithValues(subcategory)
        ? subcategory.map((i) => i.label)?.filter(Boolean)
        : [];
      let array = getAllLabourMasterPricing(
        labour_pricing[defaultCurrency],
        temp_collections,
        temp_categories,
        temp_subcategories
      );
      setAllLabourMasterPricing(array);
      onEditProduct({
        // allLabourMasterPricing: array,
        // labourMasterPricing: obj,
        subcatsetting: [],
      });
    } else onEditProduct({ subcatsetting: [], labourMasterPricing: {} });
    if (isArrayWithValues(categories)) {
      let { value } = categories[0];

      // onEditProduct({ subcatsetting: [] });
      if (allSizeMasters[value] && isObjWithValues(allSizeMasters)) {
        setSingleSizeSettings([]);
        setSizeSettings(allSizeMasters[value]);
        // if (isObjWithValues(defaultSizes)) {
        //   setTimeout(
        //     () => onEditProduct({ subcatsetting: [defaultSizes[value]] }),
        //     1500
        //   );
        // }
      } else if (singleSizeMasters[value]) {
        setSizeSettings([]);
        setSingleSizeSettings(singleSizeMasters[value]);
      } else {
        setSingleSizeSettings([]);
        setSizeSettings([]);
      }
    } else {
      setSingleSizeSettings([]);
      setSizeSettings([]);
    }
  }, [categories, collections, subcategory, JSON.stringify(masters)]);
  return (
    <>
      <Card mb={6}>
        <CardContent style={{}}>
          <Label>Product Title</Label>
          <TextField
            //   m={2}
            // autoFocus
            fullWidth
            id="outlined-required"
            // label="Title"
            value={name}
            error={titleError ? true : false}
            // helperText={titleError ? titleError : ""}
            helperText={
              <FormHelperText
                sx={{
                  display: "flex",
                  // textAlign: "end",
                  justifyContent: "space-between",
                }}
              >
                <span>{titleError ? titleError : ""}</span>
                <span>{`${name.length} / 60`}</span>
              </FormHelperText>
            }
            onChange={(e) => {
              onEditProduct({ name: e.target.value });
              if (e.target.value.length > 60)
                setTitleError("Title max lenght is 60");
            }}
          />
          <Label>Add Images</Label>
          {/* <FileUploader
          handleChange={fileDropped}
          name="file"
          types={["JPG", "PNG", "GIF"]}
          children={
          }
        /> */}
          <Card
            onClick={() => !product?.pull_source && toggleMediaLibrary(true)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
              },
            }}
          >
            <CardContent
              fullWidth
              id="outlined"
              style={{
                borderWidth: 2,
                borderStyle: "dashed",
                borderColor: "#bababa",
                alignItems: "center",
                display: "flex",
              }}
              hoverTitle=""
            >
              <Box
                sx={{
                  height: "80px",
                  width: " 80px",
                  backgroundColor: "primary.main",
                  borderRadius: "50%",

                  position: "relative",
                }}
              >
                <CloudUpload
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </Box>
              {/* <FileUpload
                height="80"
                width="80"
                fill={
                  theme &&
                  theme.palette &&
                  theme.palette.primary &&
                  theme.palette.primary.main
                }
              /> */}
              <div style={{ marginLeft: 5 }}>
                <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                <span style={{ margin: "0px" }}>
                  drop files here or drag through your machine
                </span>
              </div>
            </CardContent>
          </Card>
          <SortableList
            className="image-sort"
            // component={<SortableList  />}
            onSortEnd={onImagesSort}
            cols={4}
            sx={{ mt: 3 }}
            style={{
              userSelect: "none",
              marginTop: "15px",
              marginBottom: "20px",
              justifyContent: "flex-start",
            }}
          >
            {isArrayWithValues(images) && images.length > 0 ? (
              images.map((item) => {
                let src = item.source_url || item.src;
                let { color, id } = item;
                return (
                  <SortableItem key={id}>
                    <Box
                      sx={{
                        maxWidth: "120px",
                      }}
                    >
                      <ImageListItem
                        sx={{
                          margin: "3px",
                          borderColor: "rgba(0,0,0,1)",
                          // borderWidth: "1px",
                          border: "1px solid #E0E0D7",
                          // border: "1px solid #E0E0D7",
                          borderRadius: "6px",
                          padding: 0,
                          cursor: "grab",
                        }}
                        key={item.id}
                      >
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: "-12px",
                            top: "-12px",
                            zIndex: 2,
                            overflow: "hidden",
                          }}
                          onClick={() => removeProductImage(item.id)}
                          aria-label="delete"
                        >
                          <Cancel
                            size="small"
                            color="primary"
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "50%",
                            }}
                          />
                        </IconButton>
                        <img
                          src={src}
                          style={{
                            userSelect: "none",
                            pointerEvents: "none",
                            borderRadius: "6px",
                          }}
                          alt={item.id}
                          loading="lazy"
                        />
                      </ImageListItem>
                      <Stack direction="row" justifyContent={"center"}>
                        {metalColors.map((i) => {
                          return (
                            <Tooltip title={i.value}>
                              <IconButton
                                sx={{ p: 1.5 }}
                                onClick={() => onColorClick(id, i.value)}
                              >
                                <Box
                                  sx={{
                                    background: colorsBackground[i.value],
                                    height: "14px",
                                    width: "14px",
                                    borderRadius: "50%",
                                    border: `2px solid ${
                                      i.value === color ? "#000" : "transparent"
                                    }`,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          );
                        })}
                      </Stack>
                    </Box>
                  </SortableItem>
                );
              })
            ) : (
              <ImageListItem></ImageListItem>
            )}
          </SortableList>
          {isObjWithValues(camwearaIntegration) && camwearaIntegration.key && (
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Enable Camweara?</Label>
              <Switch
                onChange={(e) => {
                  onEditProduct({ camweara_show_tryon: e });
                }}
                checked={camweara_show_tryon}
                style={{
                  border: `1px solid ${
                    camweara_show_tryon ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: camweara_show_tryon
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          )}
          {isObjWithValues(trybeforeyoubuyIntegration) &&
            trybeforeyoubuyIntegration.key && (
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Label>Enable Virtual Try On?</Label>
                <Switch
                  onChange={(e) => {
                    onEditProduct({ virtual_try: e });
                  }}
                  checked={virtual_try || false}
                  style={{
                    border: `1px solid ${
                      virtual_try ? backgroundColor : "#e8e8e8"
                    }`,
                    backgroundColor: virtual_try ? backgroundColor : "#e8e8e8",
                  }}
                />
              </Stack>
            )}

          {isObjWithValues(mirrARIntegration) && mirrARIntegration.key && (
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Enable Mirrar?</Label>
              <Switch
                onChange={(e) => {
                  onEditProduct({ mirrAR_tryon: e });
                }}
                checked={mirrAR_tryon}
                style={{
                  border: `1px solid ${
                    mirrAR_tryon ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: mirrAR_tryon ? backgroundColor : "#e8e8e8",
                }}
              />
            </Stack>
          )}
          <Collapse in={virtual_try}>
            <Box sx={{ my: 3 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  {isArrayWithValues(virtualTryImage) ? (
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={try_on_image_src}
                        style={{
                          maxHeight: "160px",
                          width: "100%",
                          objectFit: "cover",
                          textAlign: "center",
                          borderRadius: "8px",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: "-12px",
                          top: "-12px",
                          zIndex: 2,
                          overflow: "hidden",
                        }}
                        onClick={() => onEditProduct({ virtualTryImage: [] })}
                        aria-label="delete"
                      >
                        <Cancel
                          size="small"
                          color="primary"
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Box>
                  ) : (
                    <Card
                      onClick={() => setVirtualTryLibraryOpen(true)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <CardContent
                        fullWidth
                        id="outlined"
                        style={{
                          // borderWidth: 2,
                          // borderStyle: "dashed",
                          // borderColor: "#bababa",
                          alignItems: "center",
                          display: "flex",
                        }}
                        hoverTitle=""
                      >
                        <Box
                          sx={{
                            height: "80px",
                            width: " 80px",
                            // backgroundColor: "primary.main",
                            borderRadius: "50%",

                            position: "relative",
                          }}
                        >
                          <Upload
                            color="rgba(0,0,0,0.3)"
                            style={{
                              height: "40px",
                              width: "40px",
                              // backgroundColor: "rgba(0,0,0,0.3)",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Box>
                        <div style={{ marginLeft: 5 }}>
                          <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                          <span style={{ margin: "0px" }}>
                            drop files here or drag through your machine
                          </span>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"16px"} fontWeight="600">
                    Instructions
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    <BulletText text={"Only PNG Images allowed"} />
                    <BulletText text={"Upload Images below 2 MB"} />
                    <BulletText
                      text={"Recommend Images Dimensions below 3000px"}
                    />
                    <BulletText
                      text={"Add Product Measurement in product details*"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          <Label>Add Video</Label>
          <TextField
            fullWidth
            id="outlined"
            label="Video link"
            value={_woodmart_product_video}
            onChange={(e) =>
              onEditProduct({ _woodmart_product_video: e.target.value })
            }
          />
          {/* <Divider style={{ marginTop: "12px" }} /> */}
          <Label>Product categories</Label>
          <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={12}>
              <div style={{}}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  // loading={true}
                  options={allCollections}
                  openOnFocus
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  // getOptionLabel={(option) => option.label || ""}
                  filterOptions={(options, params) => {
                    const filtered = muiDropdownFilter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.label
                    );
                    if (inputValue !== "" && !isExisting && canAddCollection) {
                      filtered.push({
                        inputValue,
                        value: inputValue,
                        label: `Add Collection "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                  }}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Collections" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>{option.label}</li>
                  )}
                  value={collections}
                  onChange={(e, value) => onEditProduct({ collections: value })}
                />
              </div>
            </Grid>
            <Grid item xs={6} mt={1}>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={allCategories}
                // sx={{ width: 300 }}
                openOnFocus
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                // getOptionLabel={(option) => option.label || ""}
                filterOptions={(options, params) => {
                  const filtered = muiDropdownFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting && canAddCollection) {
                    filtered.push({
                      inputValue,
                      value: inputValue,
                      label: `Add Category "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label || "";
                }}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.label || ""}</li>
                )}
                // getOptionLabel={(option) => option.label || ""}
                // renderInput={(params) => (
                //   <TextField {...params} label="Categories" />
                // )}
                value={categories}
                onChange={(e, value) => {
                  onEditProduct({ categories: value });
                }}
                // value={isArrayWithValues(categories) ? categories[0] : []}
                // onChange={(e, value) => {
                //   onEditProduct({ categories: [{ ...value }] });
                // }}
              />
            </Grid>
            <Grid item xs={6} mt={1}>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={allSubCategories}
                // sx={{ width: 300 }}
                openOnFocus
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                // getOptionLabel={(option) => option.label || ""}
                filterOptions={(options, params) => {
                  const filtered = muiDropdownFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting && canAddCollection) {
                    filtered.push({
                      inputValue,
                      value: inputValue,
                      label: `Create Subcategory "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label || "";
                }}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sub Category" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.label || ""}</li>
                )}
                // getOptionLabel={(option) => option.label || ""}
                // renderInput={(params) => (
                //   <TextField {...params} label="Sub-Categories" />
                // )}
                // value={isArrayWithValues(subcategory) ? subcategory[0] : []}
                // onChange={(e, value) => {
                //   onEditProduct({ subcategory: [{ ...value }] });
                // }}

                value={subcategory}
                onChange={(e, value) => {
                  onEditProduct({ subcategory: value });
                }}
              />
            </Grid>
            {isArrayWithValues(sizeSettings) ? (
              <Grid
                item
                xs={12}
                mt={1}
                sx={{
                  // maxHeight: isArrayWithValues(sizeSettings) ? "100%" : "0%",
                  // transform : isArrayWithValues(sizeSettings) ? "scale(1)" : "scale(0)",
                  overflow: "hidden",
                  transition: "all 0.7s",
                }}
              >
                {/* <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={sizeSettings}
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    (option ? option.label : "") || ""
                  }
                  openOnFocus
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Size Settings" />
                  )}
                  value={subcatsetting || []}
                  onChange={(e, value) => {
                    if (product.dontRemoveVariantsOtherForSet)
                      onEditProduct({ dontRemoveVariantsOtherForSet: false });
                    onEditProduct({ subcatsetting: value });
                  }}
                  onClick={() => {}}
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Size Settings
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subcatsetting}
                    label="Size Settings"
                    renderValue={(arr) => (arr[0] ? arr[0].label : "")}
                    onChange={(e) =>
                      onEditProduct({ subcatsetting: [{ ...e.target.value }] })
                    }
                    MenuProps={{
                      sx: { boxShadow: 3, maxHeight: 280 },
                    }}
                  >
                    {sizeSettings.map((i) => {
                      return <MenuItem value={i}>{i.label}</MenuItem>;
                    })}
                    {/* <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {isArrayWithValues(singleSizeSettings) ? (
              <Grid item xs={12} mt={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Size Settings
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subcatsetting}
                    label="Size Settings"
                    renderValue={(arr) => (arr[0] ? arr[0].label : "")}
                    onChange={(e) =>
                      onEditProduct({ subcatsetting: [{ ...e.target.value }] })
                    }
                  >
                    {singleSizeSettings.map((i) => {
                      return <MenuItem value={i}>{i.label}</MenuItem>;
                    })}
                    {/* <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  options={singleSizeSettings}
                  // getOptionLabel={(selected) => selected.label || ""}

                  openOnFocus
                  // sx={{ width: 300 }}

                  renderInput={(params) => (
                    <TextField {...params} label="Size Settings" />
                  )}
                  value={subcatsetting.label || ""}
                  onChange={(e, value) => {
                    onEditProduct({ subcatsetting: [{ ...value }] });
                  }}
                /> */}
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
        {/* <Dialog open={mediaLibraryOpen} onClose={() => toggleMediaLibrary(false)}>
      </Dialog> */}
      </Card>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={toggleMediaLibrary}
        setParentImages={setProductImages}
        selectedImages={images}
        limit={storePlan?.image_upload_limit}
        mediaType={"image"}
        hideUploadInfo={false}
      />
      <ProductMediaLibrary
        open={virtualTryLibraryOpen}
        onChange={setVirtualTryLibraryOpen}
        setParentImages={setVirtualTryImage}
        selectedImages={virtualTryImage}
        multiple={false}
        hideUploadInfo={false}
      />
    </>
  );
});

const ProductDetails = (props) => {
  let {
    onEditProduct,
    product,
    skuError,
    setSkuError,
    taxonomies,
    productSettings,
  } = props;
  let {
    barcode,
    stock_quantity,
    sku,
    stock_status,
    huid,
    shipping_days,
    product_shipping,
    product_width,
    product_height,
    product_thickness,
    product_groups,
    hallmarked,
    diamond_certified,
    diamond_lab,
    diamond_certificate_id,
    status,
    app_access_only,
  } = product;
  const [productGroups, setProductGroups] = useState([]);
  const [allDiamondLabs, setAllDiamondLabs] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const skuErrorRef = createRef();

  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );
  const reduxProductDefaults = useSelector(
    (state) => state.settings.productDefaults
  );

  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );

  const theme = useTheme();

  const stockStatusChange = (e) => {
    if (e.target.value === "instock" || e.target.value === "onbackorder")
      onEditProduct({ stock_status: e.target.value, stock_quantity: 1 });
    else onEditProduct({ stock_status: e.target.value });
  };
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const [huidError, setHuidError] = useState(false);

  const diamondLabOptions = [
    { label: "IGI", value: "IGI" },
    { label: "DHC", value: "DHC" },
    { label: "HKD", value: "HKD" },
    { label: "others", value: "Others" },
  ];
  useMemo(() => {
    if (isArrayWithValues(huid) && huid.find((item) => item.length !== 6)) {
      setHuidError(true);
    } else {
      setHuidError(false);
    }
  }, [huid]);
  useMemo(() => {
    if (
      // reduxProductSettings &&
      isArrayWithValues(reduxProductSettings?.diamond_labs)
    ) {
      setAllDiamondLabs(reduxProductSettings.diamond_labs);
    }
  }, [reduxProductSettings?.diamond_labs]);

  useMemo(() => {
    if (!isArrayWithValues(reduxProductGroups)) dispatch(getProductGroups());
  }, []);

  useMemo(() => {
    if (isArrayWithValues(reduxProductGroups))
      setProductGroups(reduxProductGroups.filter((i) => i.enabled));
  }, [reduxProductGroups]);
  useMemo(() => {
    if (
      isArrayWithValues(productGroups) &&
      isObjWithValues(reduxProductDefaults)
    ) {
      let { product_group } = reduxProductDefaults;
      if (
        isObjWithValues(product_group) &&
        location.pathname.includes("/products/add")
      ) {
        let obj = productGroups.find((i) => i.id === product_group.id);
        if (obj) onEditProduct({ product_groups: [product_group] });
      }
    }
  }, [productGroups, reduxProductDefaults]);

  useMemo(() => {
    if (skuError) setSkuError(false);
  }, [sku]);

  useMemo(() => {
    if (skuError) setSkuError(false);
  }, [sku]);

  // useEffect(() => {
  //   if (skuError)
  //     skuErrorRef.current.scrollIntoView &&
  //       skuErrorRef.current.scrollIntoView({ behavior: "smooth" });
  // }, [skuError]);
  // const theme = useTheme();
  // let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  return (
    <Card mb={6} mt={4}>
      <CardContent style={{}}>
        <Label>Product details</Label>
        <Grid container spacing={3.5} rowSpacing={4} mt={1} mb={3}>
          <Grid
            item
            xs={12}
            sm={
              stock_status === "instock" || stock_status === "onbackorder"
                ? 7
                : 12
            }
          >
            <FormControl m={2} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Stock status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stock_status}
                label="Stock Status"
                onChange={stockStatusChange}
                disabled={product?.pull_source}
              >
                <MenuItem value={"instock"} selected>
                  In stock
                </MenuItem>
                <MenuItem value={"outofstock"}>Out of Stock</MenuItem>
                <MenuItem value={"onbackorder"}>Make to Order</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {stock_status === "instock" || stock_status === "onbackorder" ? (
            <Grid item xs={12} sm={5}>
              <TextField
                //   m={2}
                ref={skuErrorRef}
                fullWidth
                id="outlined"
                label="Stock Quantity"
                type="number"
                disabled={stock_status !== "instock" || product?.pull_source}
                value={stock_status === "onbackorder" ? "1" : stock_quantity}
                onChange={(e) =>
                  onEditProduct({ stock_quantity: e.target.value })
                }
              />
            </Grid>
          ) : null}

          <Grid item xs={12} sm={6}>
            <TextField
              //   m={2}
              fullWidth
              id="outlined"
              //   label="Required"
              label="Min Shipping in Days"
              type="number"
              value={product_shipping.min}
              onChange={(e) =>
                onEditProduct({
                  product_shipping: {
                    ...product_shipping,
                    min: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              //   m={2}
              fullWidth
              id="outlined"
              //   label="Required"
              label="Max Shipping in Days"
              type="number"
              value={product_shipping.max}
              onChange={(e) =>
                onEditProduct({
                  product_shipping: {
                    ...product_shipping,
                    max: e.target.value,
                  },
                })
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl m={2} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Product Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Product Status"
                onChange={(e) => onEditProduct({ status: e.target.value })}
              >
                <MenuItem value={"publish"}>Publish</MenuItem>
                <MenuItem value={"draft"}> Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              style={{ marginBottom: "10px" }}
              label="SKU code/ Design No. *"
              error={skuError ? true : false}
              helperText={skuError ? skuError : ""}
              value={sku}
              onChange={(e) => onEditProduct({ sku: e.target.value })}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              //   m={2}
              fullWidth
              id="outlined"
              //   label="Required"
              label="SKU code/ Design No. *"
              error={Boolean(skuError)}
              value={sku}
              onChange={(e) => onEditProduct({ sku: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              //   m={2}
              fullWidth
              id="outlined"
              //   label="Required"
              label="Bar Code"
              // type="number"
              value={barcode}
              onChange={(e) => onEditProduct({ barcode: e.target.value })}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12} mb={1.5}>
          <LabelSm>HUID</LabelSm>
          <Autocomplete
            // style={{ marginBottom: "10px" }}
            multiple
            id="tags-filled"
            // size="small"
            options={[]}
            value={huid || []}
            disabled={product?.huidhasAlready && product?.pull_source}
            // defaultValue={[top100Films[13].title]}
            // error={product.huid.find(item => )}
            freeSolo
            renderTags={(value, getTagProps) =>
              isArrayWithValues(value)
                ? value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                : []
            }
            onChange={(event, newValue) => {
              onEditProduct({ huid: newValue });
            }}
            renderInput={(params) => (
              <TextField
                error={huidError}
                {...params}
                // variant="filled"
                // label="HUID"
                placeholder="Enter huid"
                helperText={
                  huidError
                    ? "HUID must be 6 digit"
                    : "Press hit enter to add huid"
                }
              />
            )}
          />
        </Grid>

        {productSettings &&
        isArrayWithValues(productSettings.types) &&
        [...productSettings.types].find((i) => i.value === "diamond") &&
        product.collections.find(
          (i) => i.label && i.value.includes("diamond")
        ) ? (
          <>
            {" "}
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Diamond Certified?</Label>
              <Switch
                onChange={(e) => {
                  onEditProduct({ diamond_certified: e ? "yes" : "no" });
                }}
                checked={diamond_certified === "yes"}
                style={{
                  border: `1px solid ${
                    diamond_certified === "yes" ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor:
                    diamond_certified === "yes" ? backgroundColor : "#e8e8e8",
                }}
              />
            </Stack>
            <Collapse in={diamond_certified === "yes"}>
              <Grid container sx={{ margin: "5px 0" }} spacing={1}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Lab</InputLabel>
                    <Select
                      id="demo-simple-select-label"
                      fullWidth
                      value={diamond_lab}
                      label="Lab"
                      onChange={(e) => {
                        onEditProduct({
                          diamond_lab: e.target.value,
                        });
                      }}
                    >
                      {allDiamondLabs.map((i) => (
                        <MenuItem value={i.value}>{i.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Certificate ID"
                    value={diamond_certificate_id}
                    onChange={(e) => {
                      onEditProduct({ diamond_certificate_id: e.target.value });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Collapse>
          </>
        ) : null}

        <Collapse>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Diamond Lab</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={diamond_lab}
              label="Diamond Lab"
              onChange={(e) => onEditProduct({ diamond_lab: e.target.value })}
            >
              {allDiamondLabs.map((i) => (
                <MenuItem value={i.value} key={i.value}>
                  {i.label}
                </MenuItem>
              ))}
              {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Collapse>
        <Divider />
        <Label>Product Measurement (MM.)</Label>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              id="outlined"
              label="Width"
              value={product_width}
              onChange={(e) => onEditProduct({ product_width: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              id="outlined"
              label="Height"
              value={product_height}
              onChange={(e) =>
                onEditProduct({ product_height: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              id="outlined"
              label="Thickness"
              value={product_thickness}
              onChange={(e) =>
                onEditProduct({ product_thickness: e.target.value })
              }
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 4 }} />
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          mt={3}
        >
          <Label style={{ margin: "0px 5px" }}>Product Group</Label>
          <IconButton>
            <AddCircleOutline
              onClick={() => navigate("/settings#product_grouping")}
            />
          </IconButton>
        </Stack>
        {isArrayWithValues && (
          <FormControl m={2} fullWidth sx={{ mt: 1 }}>
            {/* <InputLabel id="demo-simple-select-label">
              Product Groups
            </InputLabel> */}
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              // loading={true}
              options={productGroups}
              openOnFocus
              getOptionLabel={(option) => option.title || ""}
              // sx={{ width: 300 }}
              noOptionsText="No Product Groups Found"
              renderInput={(params) => (
                <TextField {...params} label="Product Groups" />
              )}
              value={product_groups}
              onChange={(e, value) => onEditProduct({ product_groups: value })}

              // onChange={(e, value) => onEditProduct({ collections: value })}
            />
          </FormControl>
        )}
        {/* <Stack
          direction="row"
          alignItems={"center"}
          mt={2}
          justifyContent={"space-between"}
        >
          <Stack direction="row" alignItems={"center"}>
            <Typography typography={"h6"}>App access only</Typography>
            <Tooltip title="Accessible only for retailer admin app. use it for exclusive products">
              <IconButton>
                <Info />
              </IconButton>
            </Tooltip>
          </Stack>
          <Switch
            checked={app_access_only}
            onChange={(e) => onEditProduct({ app_access_only: e })}
            style={{
              border: `1px solid ${
                app_access_only ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: app_access_only ? backgroundColor : "#e8e8e8",
            }}
          />
        </Stack> */}
        <ProductFilters
          taxonomies={taxonomies}
          onEditProduct={onEditProduct}
          product={product}
        />
      </CardContent>
    </Card>
  );
};

const GemstoneDetails = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = useState({});
  const [gemstonePricing, setGemstonePricing] = useState({});
  const {
    masters,
    onEditProduct,
    product,
    productSettings,
    price_breakup,
    defaultCurrency,
  } = props;
  let {
    colorstone_details,
    colorstone_details_customize,
    colorstone_from,
    onlyColorstone,
  } = product;
  const theme = useTheme();
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);

  useImperativeHandle(ref, () => ({
    getTotalGemstone() {
      const total = calculateTotalGemstone();
      return total;
    },
  }));

  // useEffect(() => {
  //   calculateTotalGemstone();
  // }, [
  //   JSON.stringify(colorstone_details),
  //   JSON.stringify(colorstone_details_customize),
  // ]);

  const calculateTotalGemstone = () => {
    let obj = {};
    if (colorstone_from === "master") obj = { ...colorstone_details };
    if (colorstone_from === "customize")
      obj = { ...colorstone_details_customize };
    let details = Object.values(obj);
    let total = 0;
    if (isArrayWithValues(details)) {
      for (let i = 0; i < details.length; i++) {
        let rate = 0;
        if (colorstone_from === "customize")
          rate = validateNumber(details[i].colorstone_rate);
        else {
          let {
            colorstone_quality,
            colorstone_type,
            colorstone_shape,
            colorstone_size,
          } = details[i];
          rate =
            getGemstoneRate(
              gemstonePricing,
              colorstone_quality,
              colorstone_type,
              colorstone_shape,
              colorstone_size
            ) || 0;
        }
        if (colorstone_from === "customize") total += validateNumber(rate);
        else total += rate * validateNumber(details[i].colorstone_weight);
      }
    }
    return Math.round(total);
    // onEditProduct({
    //   price_breakup: {
    //     ...price_breakup,
    //     [colorstone_from]: {
    //       ...price_breakup[colorstone_from],
    //       colorstone: Math.round(total),
    //     },
    //   },
    // });
    // if (isObjWithValues(masters) && isObjWithValues(productSettings)) {
    //   let { default_currency } = productSettings;
    //   let { gemstone_pricing } = masters;
    //   if (gemstone_pricing[default_currency]) {
    //   }
    // }
  };

  const onAdd = (obj) => {
    if (colorstone_from === "master") {
      let array = colorstone_details;
      if (Array.isArray(array)) {
        array.push(obj);
        onEditProduct({ colorstone_details: array });
      }
    }
    if (colorstone_from === "customize") {
      let array = colorstone_details_customize;
      if (Array.isArray(array)) {
        array.push(obj);
        onEditProduct({ colorstone_details_customize: array });
      }
    }
    setEditData({});
    // calculateTotalGemstone();
  };
  const onEdit = (obj, index) => {
    if (colorstone_from === "master") {
      let array = colorstone_details;
      if (Array.isArray(array)) {
        array[index] = obj;
        onEditProduct({ colorstone_details: array });
      }
    }
    if (colorstone_from === "customize") {
      let array = colorstone_details_customize;
      if (Array.isArray(array)) {
        array[index] = obj;
        onEditProduct({ colorstone_details_customize: array });
      }
    }
  };
  const onDelete = (index) => {
    if (colorstone_from === "master") {
      let array = colorstone_details;
      if (Array.isArray(array)) {
        array.splice(index, 1);
        onEditProduct({ colorstone_details: array });
      }
    }
    if (colorstone_from === "customize") {
      let array = colorstone_details_customize;
      if (Array.isArray(array)) {
        array.splice(index, 1);
        onEditProduct({ colorstone_details_customize: array });
      }
    }
    setOpen(false);
  };
  useMemo(() => {
    if (masters && Object.keys(masters).length > 0) {
      let { gemstone_pricing } = masters;
      if (defaultCurrency && gemstone_pricing)
        setGemstonePricing(gemstone_pricing?.[defaultCurrency]);
    }
  }, [masters, defaultCurrency]);

  useMemo(() => {
    let { colorstone_details } = product;
    let totalWeight = 0;
    let totalPieces = 0;
    if (!isArrayWithValues(colorstone_details)) return;
    colorstone_details = colorstone_details.map((i) => {
      let { colorstone_weight, colorstone_pieces } = i;
      totalWeight += Number(colorstone_weight);
      totalPieces += Number(colorstone_pieces);
    });
    setTotalWeight(validateWeight(totalWeight));
    setTotalPieces(totalPieces);
  }, [product.colorstone_details]);

  const Actions = ({ row, i }) => (
    <>
      <IconButton
        aria-label="edit"
        disabled={product?.pull_source}
        onClick={() => {
          setEditData({ ...row, index: i });
          setOpen(true);
        }}
      >
        <Edit size="small" />
      </IconButton>
    </>
  );

  return (
    <Card>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Label style={{ marginBottom: "3px" }}>Gemstone Details</Label>
            <Typography
              variant="h6"
              mt={0.5}
              ml={1}
              color="text.secondary"
              fontSize="12px"
              fontWeight={"normal"}
            >
              GP:{" "}
              <Typography variant="caption" fontWeight={"bold"}>
                {totalPieces}
              </Typography>
              {"  "}
              GW:{" "}
              <Typography variant="caption" fontWeight={"bold"}>
                {totalWeight}
              </Typography>
            </Typography>
          </Box>
          <Stack direction="row" alignItems={"center"}>
            {!productSettings?.hide_pricing && (
              <Typography variant="h6" mr={2}>
                {currencySymbols?.[defaultCurrency] || ""}{" "}
                {(price_breakup[colorstone_from] &&
                  price_breakup[colorstone_from].colorstone) ||
                  "0"}
              </Typography>
            )}
            <FormControl size="small">
              <Dropdown
                // label="Gemstone Type"
                data={[
                  { label: "Master", value: "master" },
                  { label: "Customize", value: "customize" },
                ]}
                onChange={(e) =>
                  onEditProduct({ colorstone_from: e.target.value })
                }
                selected={colorstone_from}
              />
            </FormControl>
          </Stack>
          {/* <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add
            <AddIcon />
          </Button> */}
        </div>
        {colorstone_from === "master" ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Quality</TableCell>
                    {!productSettings?.hide_pricing ? (
                      <>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Shape</TableCell>
                        <TableCell align="right">Size</TableCell>
                      </>
                    ) : null}
                    <TableCell align="right">Pieces</TableCell>
                    <TableCell align="right">Weight</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(colorstone_details)
                    ? colorstone_details.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {row.colorstone_quality}
                          </TableCell>
                          {!productSettings?.hide_pricing ? (
                            <>
                              <TableCell align="right">
                                {row.colorstone_type}
                              </TableCell>
                              <TableCell align="right">
                                {row.colorstone_shape}
                              </TableCell>
                              <TableCell align="right">
                                {row.colorstone_size}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell align="right">
                            {row.colorstone_pieces}
                          </TableCell>
                          <TableCell align="right">
                            {row.colorstone_weight}
                          </TableCell>
                          <TableCell align="center">
                            <Actions row={row} i={i} />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {Array.isArray(colorstone_details) &&
            colorstone_details.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  margin: "8px 0px",
                  color: "#bababa",
                }}
              >
                No Colorstone added
              </div>
            ) : null}
          </>
        ) : null}
        {colorstone_from === "customize" ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Quality</TableCell>
                    {!productSettings?.hide_pricing ? (
                      <>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Shape</TableCell>
                        <TableCell align="right">Size</TableCell>
                      </>
                    ) : null}
                    <TableCell align="right">Pieces</TableCell>
                    <TableCell align="right">Weight</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(colorstone_details_customize)
                    ? colorstone_details_customize.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {row.colorstone_quality}
                          </TableCell>
                          {!productSettings?.hide_pricing ? (
                            <>
                              <TableCell align="right">
                                {row.colorstone_type}
                              </TableCell>
                              <TableCell align="right">
                                {row.colorstone_shape}
                              </TableCell>
                              <TableCell align="right">
                                {row.colorstone_size}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell align="right">
                            {row.colorstone_pieces}
                          </TableCell>
                          <TableCell align="right">
                            {row.colorstone_weight}
                          </TableCell>
                          <TableCell align="right">
                            <Actions row={row} i={i} />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {Array.isArray(colorstone_details_customize) &&
            colorstone_details_customize.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  margin: "8px 0px",
                  color: "#bababa",
                }}
              >
                No Colorstone added
              </div>
            ) : null}
          </>
        ) : null}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyColorstone}
                  onChange={(e) =>
                    onEditProduct({
                      onlyColorstone: e.target.checked,
                    })
                  }
                />
              }
              label="Only Gemstone"
            />
          </FormGroup>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={product?.pull_source}
            onClick={() => {
              setEditData({});
              setOpen(true);
            }}
            style={{ float: "right", margin: "10px 4px" }}
            endIcon={<AddIcon />}
          >
            Add
            {/* <AddIcon /> */}
          </Button>
        </Stack>
      </CardContent>
      <AddGemstone
        open={open}
        onClose={() => {
          setEditData({});
          setOpen(false);
        }}
        masters={masters}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        toggleDialog={setOpen}
        editData={editData}
        colorstone_from={colorstone_from}
        product={product}
        defaultCurrency={defaultCurrency}
        productSettings={productSettings}
      />
    </Card>
  );
});

const DiamondDetails = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [diamondPricings, setDiamondPricings] = useState({});
  const [editData, setEditData] = useState({});
  const [diamond, setDiamond] = useState([]);
  const {
    masters,
    onEditProduct,
    product,
    diamondError,
    setDiamondError,
    productSettings,
    price_breakup,
    defaultCurrency,
    allDiamondGroups,
  } = props;
  let { diamond_customize, diamond_from, onlyDiamond } = product;
  const diamondErrorRef = useRef(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    let { diamond } = product;
    let totalWeight = 0;
    let totalPieces = 0;
    if (!isArrayWithValues(diamond)) return;
    diamond = diamond.map((i) => {
      let {
        diamond_quality,
        diamond_shape,
        // diamond_sieve,
        diamond_cut,
        diamond_weight,
        diamond_pieces,
        diamond_type,
      } = i;
      totalWeight += Number(diamond_weight);
      totalPieces += Number(diamond_pieces);

      let diamond_sieve;
      if (isObjWithValues(allDiamondGroups)) {
        let netWeight = Number(Number(diamond_weight) / Number(diamond_pieces));
        let diamondGroup = Object.values(allDiamondGroups);
        let obj = diamondGroup.find((i) => {
          return (
            i.shape === diamond_shape &&
            netWeight >= Number(i.from) &&
            netWeight <= Number(i.to)
          );
        });
        if (!obj)
          obj = diamondGroup.find((i) => {
            let netWeightFixed2 = Number(netWeight).toFixed(2);
            return (
              i.shape === diamond_shape &&
              netWeightFixed2 >= Number(i.from) &&
              netWeightFixed2 <= Number(i.to)
            );
          });
        diamond_sieve = obj?.id;
      }

      let rate = getDiamondRate(
        diamondPricings,
        diamond_type,
        diamond_quality,
        diamond_shape,
        diamond_sieve,
        diamond_cut
      );
      if (rate) return { ...i, current_rate: rate };
      else return i;
    });
    setDiamond(diamond);
    setTotalWeight(validateWeight(totalWeight));
    setTotalPieces(totalPieces);
  }, [product.diamond, diamondPricings, defaultCurrency]);

  useImperativeHandle(ref, () => ({
    getTotalDiamond() {
      let total = calculateTotalDiamond();
      return total;
    },
  }));

  useEffect(() => {
    if (masters && Object.keys(masters).length > 0) {
      let { diamond_pricing } = masters;
      if (diamond_pricing) {
        setDiamondPricings(diamond_pricing?.[defaultCurrency]);
      }
    }
  }, [masters, defaultCurrency]);
  // useEffect(() => {
  //   calculateTotalDiamond();
  // }, [JSON.stringify(diamond), JSON.stringify(diamond_customize)]);

  const calculateTotalDiamond = () => {
    let obj;
    let { diamond } = product;
    if (diamond_from === "master") if (diamond) obj = { ...diamond };

    if (diamond_from === "customize")
      if (diamond_customize) obj = { ...diamond_customize };

    if (!obj) return 0;

    let details = Object.values(obj);
    let total = 0;
    if (isArrayWithValues(details)) {
      for (let i = 0; i < details.length; i++) {
        let rate = 0;
        if (diamond_from === "customize")
          rate = validateNumber(details[i].diamond_rate);
        else {
          let {
            diamond_quality,
            diamond_shape,
            // diamond_sieve,
            diamond_cut,
            diamond_type,
            diamond_weight,
            diamond_pieces,
          } = details[i];

          let diamond_sieve;
          if (isObjWithValues(allDiamondGroups)) {
            let netWeight = Number(
              Number(diamond_weight) / Number(diamond_pieces)
            );
            let diamondGroup = Object.values(allDiamondGroups);
            let obj = diamondGroup.find((i) => {
              return (
                i.shape === diamond_shape &&
                netWeight >= Number(i.from) &&
                netWeight <= Number(i.to)
              );
            });
            if (!obj)
              obj = diamondGroup.find((i) => {
                let netWeightFixed2 = Number(netWeight).toFixed(2);
                return (
                  i.shape === diamond_shape &&
                  netWeightFixed2 >= Number(i.from) &&
                  netWeightFixed2 <= Number(i.to)
                );
              });
            diamond_sieve = obj?.id;
          }

          rate =
            getDiamondRate(
              diamondPricings,
              diamond_type || "natural",
              diamond_quality,
              diamond_shape,
              diamond_sieve,
              diamond_cut
            ) || 0;
        }
        total += rate * validateNumber(details[i].diamond_weight);
      }
    }
    return Math.round(total);
    // onEditProduct({
    //   price_breakup: {
    //     ...price_breakup,
    //     [diamond_from]: {
    //       ...price_breakup[diamond_from],
    //       diamond: Math.round(total),
    //     },
    //   },
    // });
  };
  const onAdd = (obj) => {
    if (diamond_from === "master") {
      let array = diamond;
      if (Array.isArray(array)) {
        array.push(obj);
        onEditProduct({ diamond: array });
      }
    } else if (diamond_from === "customize") {
      let array = diamond_customize;
      if (Array.isArray(array)) {
        array.push(obj);
        onEditProduct({ diamond_customize: array });
      }
    }
    setEditData({});
    setDiamondError("");
    // calculateTotalDiamond();
  };

  const onEdit = (data, index) => {
    if (diamond_from === "master") {
      let array = [...diamond];
      if (Array.isArray(array)) {
        array[index] = data;
        onEditProduct({ diamond: array });
      }
    } else if (diamond_from === "customize") {
      let array = [...diamond_customize];
      if (Array.isArray(array)) {
        array[index] = data;
        onEditProduct({ diamond_customize: array });
      }
    }
    setEditData({});
    setDiamondError("");
  };

  const onDelete = (index) => {
    if (diamond_from === "master") {
      let array = diamond;
      if (Array.isArray(array)) {
        array.splice(index, 1);
        onEditProduct({ diamond: array });
      }
    }
    if (diamond_from === "customize") {
      let array = diamond_customize;
      if (Array.isArray(array)) {
        array.splice(index, 1);
        onEditProduct({ diamond_customize: array });
      }
    }
    setOpen(false);
  };
  const Actions = ({ row, i }) => (
    <>
      <IconButton
        aria-label="edit"
        disabled={product?.pull_source && !isDev()}
        onClick={() => {
          setEditData({ ...row, index: i });
          setOpen(true);
        }}
      >
        <Edit size="small" />
      </IconButton>
      {/* <IconButton aria-label="delete">
        <Delete size="small" style={{ color: theme.palette.error.main }} />
      </IconButton> */}
    </>
  );

  useEffect(() => {
    if (diamondError)
      diamondErrorRef?.current?.scrollIntoView &&
        diamondErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [diamondError]);

  return (
    <Card>
      <CardContent ref={diamondErrorRef}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Label style={{ marginBottom: "3px" }}>
              Diamond Details
              {!productSettings?.hide_pricing &&
                `${currencySymbols?.[defaultCurrency] || ""} ${
                  (price_breakup[diamond_from] &&
                    price_breakup[diamond_from].diamond) ||
                  "0"
                }`}
            </Label>
            <Typography
              variant="h6"
              mt={0.5}
              ml={1}
              color="text.secondary"
              fontSize="12px"
              fontWeight={"normal"}
            >
              DP:{" "}
              <Typography variant="caption" fontWeight={"bold"}>
                {totalPieces}
              </Typography>
              {"  "}
              DW:{" "}
              <Typography variant="caption" fontWeight={"bold"}>
                {totalWeight}
              </Typography>
            </Typography>
          </Box>{" "}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            flex={1}
          >
            {!productSettings?.hide_pricing && (
              <Typography variant="h6" mr={2}>
                {currencySymbols?.[defaultCurrency] || ""}{" "}
                {(price_breakup[diamond_from] &&
                  price_breakup[diamond_from].diamond) ||
                  "0"}
              </Typography>
            )}
            <FormControl size="small">
              <Dropdown
                data={[
                  { label: "Master", value: "master" },
                  { label: "Customize", value: "customize" },
                ]}
                onChange={(e) =>
                  onEditProduct({ diamond_from: e.target.value })
                }
                selected={diamond_from}
              />
            </FormControl>
          </Stack>
        </div>
        {diamondError ? (
          <Alert severity="error" sx={{ margin: "10px 0px" }}>
            {diamondError}
          </Alert>
        ) : null}
        {diamond_from === "master" ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Quality</TableCell>
                    {!productSettings?.hide_pricing ? (
                      <>
                        <TableCell align="right">Shape</TableCell>
                        <TableCell align="right">Size</TableCell>
                      </>
                    ) : null}
                    <TableCell align="right">DP</TableCell>
                    <TableCell align="right">DW</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {diamond.map((row, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {row.diamond_quality}
                          <Typography fontSize="12px" color="text.secodary">
                            @{row.current_rate}
                          </Typography>
                        </TableCell>
                        {!productSettings?.hide_pricing ? (
                          <>
                            <TableCell align="right">
                              {row.diamond_shape}
                            </TableCell>
                            <TableCell align="right">
                              {getDiamondSizeLabel(
                                allDiamondGroups,
                                row.diamond_sieve
                              )}
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell align="right">
                          {row.diamond_pieces}
                        </TableCell>
                        <TableCell align="right">
                          {row.diamond_weight}
                        </TableCell>
                        <TableCell align="center">
                          <Actions row={row} i={i} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {Array.isArray(diamond) && diamond.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  margin: "8px 0px",
                  color: "#bababa",
                }}
              >
                No diamond added
              </div>
            ) : null}
          </>
        ) : null}
        {diamond_from === "customize" ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {!productSettings?.hide_pricing ? (
                      <>
                        <TableCell>Quality</TableCell>
                        <TableCell align="right">Shape</TableCell>
                        <TableCell align="right">Size</TableCell>
                      </>
                    ) : null}
                    <TableCell align="right">Weight</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(diamond_customize)
                    ? diamond_customize.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {!productSettings?.hide_pricing ? (
                            <>
                              <TableCell align="left">
                                {row.diamond_quality}
                              </TableCell>
                              <TableCell align="right">
                                {row.diamond_shape}
                              </TableCell>
                              <TableCell align="right">
                                {getDiamondSizeLabel(
                                  allDiamondGroups,
                                  row.diamond_sieve
                                )}
                              </TableCell>
                            </>
                          ) : null}
                          {/* <TableCell align="right">
                            {row.diamond_color}
                          </TableCell> */}
                          <TableCell align="right">
                            {row.diamond_weight}
                          </TableCell>
                          <TableCell align="center">
                            <Actions row={row} i={i} />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {Array.isArray(diamond_customize) &&
            diamond_customize.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  margin: "8px 0px",
                  color: "#bababa",
                }}
              >
                No diamond added
              </div>
            ) : null}
          </>
        ) : null}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyDiamond}
                  onChange={(e) =>
                    onEditProduct({
                      onlyDiamond: e.target.checked,
                    })
                  }
                />
              }
              label="Only Diamond"
            />
          </FormGroup>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={product?.pull_source}
            onClick={() => {
              setEditData({});
              setOpen(true);
            }}
            style={{ float: "right", margin: "10px 4px" }}
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
      </CardContent>
      <AddDiamond
        open={open}
        onClose={() => {
          setOpen(false);
          setEditData({});
        }}
        masters={masters}
        editData={editData}
        toggleDialog={setOpen}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        productSettings={productSettings}
        product={product}
        allDiamondGroups={allDiamondGroups}
        defaultCurrency={defaultCurrency}
      />
    </Card>
  );
});

const MetalDetails = forwardRef((props, ref) => {
  const [goldPurities, setGoldPurities] = useState([]);
  const [silverPurities, setSilverPurities] = useState([]);
  const [platinumPurities, setPlatinumPurities] = useState([]);
  const [metalTypes, setMetalTypes] = useState([]);
  const [metalColors, setMetalColors] = useState(allMetalColors);
  const reduxProductDefaults = useSelector(
    (state) => state.settings.productDefaults || {}
  );
  const [rates, setRates] = useState({
    gold: 0,
    silver: 0,
    platinum: 0,
  });

  let {
    masters,
    onEditProduct,
    product,
    productSettings,
    price_breakup,
    defaultCurrency,
  } = props;
  let {
    metal_type,
    metal_types,
    metal_color,
    gold_kt,
    gold_gross,
    gold_net,
    gold_rate,
    silver_gross,
    silver_net,
    platinium_gross,
    platinium_net,
    variants,
    silver_purity,
    platinium_purity,
    hallmarked,
    huid,
  } = product;

  const location = useLocation();
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [huidError, setHuidError] = useState(false);
  useEffect(() => {
    if (isArrayWithValues(huid) && huid.find((item) => item.length !== 6)) {
      setHuidError(true);
    } else {
      setHuidError(false);
    }
  }, [huid]);

  useEffect(() => {
    if (!isObjWithValues(masters)) return;
    let { gold_pricing, silver_pricing, platinum_pricing } = masters;
    let {} = product;
    let { default_currency } = productSettings;
    setRates((state) => ({
      ...state,

      gold: getMetalRate(
        gold_pricing,
        default_currency,
        product?.["gold_kt"] || gold_pricing?.[default_currency]?.default
      ),
      silver: getMetalRate(
        silver_pricing,
        default_currency,
        product?.["silver_purity"] ||
          silver_pricing?.[default_currency]?.default
      ),
      platinum: getMetalRate(
        platinum_pricing,
        default_currency,
        product?.["platinium_purity"] ||
          platinum_pricing?.[default_currency]?.default
      ),
    }));
  }, [
    masters,
    product?.["gold_kt"],
    product?.["silver_purity"],
    product?.["platinium_purity"],
  ]);

  useImperativeHandle(ref, () => ({
    calculateTotalMetal() {
      const total = calculateTotalMetalPrice();
      return total;
    },
    setPurityRateFirstTime(purityLabel, value) {
      onPurityChange(purityLabel, { target: { value } });
    },
  }));

  const getNetWeight = (gross) => {
    let {
      diamond,
      colorstone_details,
      diamond_customize,
      colorstone_details_customize,
    } = product;
    let totalWeight = 0;
    if (isArrayWithValues(colorstone_details))
      colorstone_details?.forEach((i) => {
        if (!isNaN(Number(i.colorstone_weight)) && Number(i.colorstone_weight))
          totalWeight += Number(i.colorstone_weight);
      });
    if (isArrayWithValues(diamond))
      diamond?.forEach((i) => {
        if (!isNaN(Number(i.diamond_weight)) && Number(i.diamond_weight))
          totalWeight += Number(i.diamond_weight);
      });
    if (isArrayWithValues(colorstone_details_customize))
      colorstone_details_customize.forEach((i) => {
        if (!isNaN(Number(i.colorstone_weight)) && Number(i.colorstone_weight))
          totalWeight += Number(i.colorstone_weight);
      });
    if (isArrayWithValues(diamond_customize))
      diamond_customize.forEach((i) => {
        if (!isNaN(Number(i.diamond_weight)) && Number(i.diamond_weight))
          totalWeight += Number(i.diamond_weight);
      });
    if (isNumber(gross)) {
      let totalNet = Number((gross - totalWeight / 5).toFixed(3));
      if (totalNet <= 0) return gross;
      return totalNet;
    } else return gross;
  };

  const getVariantsWithNetValue = (variants) => {
    if (isArrayWithValues(variants) && productSettings.net_weight === "auto") {
      let array = [];
      for (let i = 0; i < variants.length; i++) {
        let obj = variants[i];
        if (obj[`${metal_type}_gross`])
          obj[`${metal_type}_net`] = getNetWeight(obj[`${metal_type}_gross`]);
        array.push(obj);
      }
      return array;
      // onEditProduct({ variants: array });
    } else return variants;
  };

  // useEffect(() => {
  //   if (isArrayWithValues(variants) && productSettings.net_weight === "auto") {
  //     let array = [];
  //     for (let i = 0; i < variants.length; i++) {
  //       let obj = variants[i];
  //       if (obj[`${metal_type}_gross`])
  //         obj[`${metal_type}_net`] = getNetWeight(obj[`${metal_type}_gross`]);
  //       array.push(obj);
  //     }
  //     onEditProduct({ variants: array });
  //   }
  // }, [variants]);
  // useEffect(() => {
  //   calculateTotalMetalPrice();
  // }, [
  //   metal_types,
  //   gold_kt,
  //   gold_gross,
  //   gold_net,
  //   silver_purity,
  //   silver_gross,
  //   silver_net,
  //   platinium_purity,
  //   platinium_gross,
  //   platinium_net,
  //   price_breakup.master.labour,
  // ]);

  const calculateTotalMetalPrice = () => {
    let metalArray = [...metalTypes];
    let total = 0;
    if (isArrayWithValues(metalArray) && isObjWithValues(masters)) {
      let { gold_pricing, silver_pricing, platinum_pricing } = masters;

      let { variants } = product;
      if (
        metalArray.find((i) => i.value === "gold") &&
        gold_pricing?.[defaultCurrency] &&
        metal_types?.includes("gold")
      ) {
        let goldRates = gold_pricing?.[defaultCurrency][gold_pricing.type];
        if (goldRates) {
          let goldRate = goldRates[gold_kt];

          if (goldRate)
            if (isArrayWithValues(variants) && false)
              variants.map(
                (i) =>
                  (total +=
                    validateNumber(goldRate.rate) *
                    validateNumber(i[`gold_${gold_pricing.from}`]))
              );
            else
              total +=
                validateNumber(goldRate.rate) *
                validateNumber(product[`gold_${gold_pricing.from}`]);
        }
      }
      if (
        metalArray.find((i) => i.value === "silver") &&
        silver_pricing?.[defaultCurrency] &&
        metal_types?.includes("silver")
      ) {
        let silverRates =
          silver_pricing?.[defaultCurrency][silver_pricing.type];
        if (silverRates) {
          let silverRate = silverRates[silver_purity];
          if (silverRate)
            if (isArrayWithValues(variants) && false)
              variants.map(
                (i) =>
                  (total +=
                    validateNumber(silverRate.rate) *
                    validateNumber(i[`silver_${silver_pricing.from}`]))
              );
            else
              total +=
                validateNumber(silverRate.rate) *
                validateNumber(product[`silver_${silver_pricing.from}`]);
        }
      }
      if (
        metalArray.find((i) => i.value === "platinium") &&
        platinum_pricing?.[defaultCurrency] &&
        metal_types?.includes("platinium")
      ) {
        let platinumRates =
          platinum_pricing?.[defaultCurrency][platinum_pricing.type];
        if (platinumRates) {
          let platinumRate = platinumRates[platinium_purity];
          if (platinumRate)
            if (isArrayWithValues(variants) && false)
              variants.map(
                (i) =>
                  (total +=
                    validateNumber(platinumRate.rate) *
                    validateNumber(i[`platinium_${platinum_pricing.from}`]))
              );
            else
              total +=
                validateNumber(platinumRate.rate) *
                validateNumber(product[`platinium_${platinum_pricing.from}`]);
        }
      }
    }

    // onEditProduct({
    //   price_breakup: {
    //     ...price_breakup,
    //     master: { ...price_breakup.master, metal: Math.round(total) },
    //   },
    // });
    return Math.round(total);
  };

  useEffect(() => {
    if (productSettings && productSettings.net_weight === "auto")
      onEditProduct({ gold_net: getNetWeight(gold_gross) });
  }, [
    product.gold_gross,
    product.diamond,
    product.diamond_customize,
    product.colorstone_details,
    product.colorstone_details_customize,
    productSettings,
    masters,
  ]);

  useEffect(() => {
    if (productSettings && productSettings.net_weight === "auto")
      onEditProduct({ silver_net: getNetWeight(silver_gross) });
  }, [
    product.silver_gross,
    product.diamond,
    product.colorstone_details,
    productSettings,
    masters,
  ]);

  useEffect(() => {
    if (productSettings && productSettings.net_weight === "auto")
      onEditProduct({ platinium_net: getNetWeight(platinium_gross) });
  }, [
    product.platinium_gross,
    product.diamond,
    product.colorstone_details,
    productSettings,
    masters,
  ]);

  useEffect(() => {
    if (productSettings) {
      if (isArrayWithValues(productSettings.types)) {
        let types = productSettings.types;
        types = types.filter((i) =>
          ["gold", "silver", "platinium"].includes(i.value)
        );
        setMetalTypes(types);
        if (isArrayWithValues(types)) onEditProduct({ noMetals: false });
        else onEditProduct({ noMetals: true });
      }
      if (isArrayWithValues(productSettings.colors))
        setMetalColors(productSettings.colors);
      if (isArrayWithValues(productSettings.gold_purities)) {
        setGoldPurities(productSettings.gold_purities);
      }
      if (isArrayWithValues(productSettings.silver_purities)) {
        setSilverPurities(productSettings.silver_purities);
      }
      if (isArrayWithValues(productSettings.platinum_purities)) {
        setPlatinumPurities(productSettings.platinum_purities);
      }
    }
  }, [productSettings]);
  useEffect(() => {
    let shouldSetDefaultPurity =
      isObjWithValues(reduxProductDefaults) &&
      location.pathname.includes("/products/add");

    let { gold_kt, silver_purity, platinium_purity } = reduxProductDefaults;
    let { gold_purities, silver_purities, platinum_purities } = productSettings;
    let metalDefaultPurity = {};

    if (
      shouldSetDefaultPurity &&
      isObjWithValues(gold_kt) &&
      isArrayWithValues(gold_purities)
    ) {
      let obj = gold_purities.find((i) => i.value === gold_kt.value);
      if (obj) {
        metalDefaultPurity.gold_kt = obj.value;
        onPurityChange("gold_kt", { target: { value: obj.value } });
      }
    }

    if (
      shouldSetDefaultPurity &&
      isObjWithValues(silver_purity) &&
      isArrayWithValues(silver_purities)
    ) {
      let obj = silver_purities.find((i) => i.value === silver_purity.value);
      if (obj) {
        metalDefaultPurity.silver_purity = obj.value;
        onPurityChange("silver_purity", { target: { value: obj.value } });
      }
    }

    if (
      shouldSetDefaultPurity &&
      isObjWithValues(platinium_purity) &&
      isArrayWithValues(platinum_purities)
    ) {
      let obj = platinum_purities.find(
        (i) => i.value === platinium_purity.value
      );
      if (obj) {
        metalDefaultPurity.platinium_purity = obj.value;
        onPurityChange("platinium_purity", { target: { value: obj.value } });
      }
    }

    if (isObjWithValues(metalDefaultPurity))
      setTimeout(() => onEditProduct(metalDefaultPurity), 100);
  }, [
    goldPurities,
    silverPurities,
    platinumPurities,
    reduxProductDefaults,
    masters,
    productSettings,
  ]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (isObjWithValues(masters) && !isObjWithValues(productSettings)) {
  //       if (masters.gold_pricing) {
  //         let { gold_pricing, silver_pricing, platinum_pricing } = masters;
  //         if (
  //           gold_pricing &&
  //           gold_pricing.values &&
  //           isObjWithValues(gold_pricing.values)
  //         )
  //           setGoldPurities(createPuritiesFromValues(gold_pricing.values));
  //         if (
  //           silver_pricing &&
  //           silver_pricing.values &&
  //           isObjWithValues(silver_pricing.values)
  //         )
  //           setSilverPurities(createPuritiesFromValues(silver_pricing.values));
  //         if (
  //           platinum_pricing &&
  //           platinum_pricing.values &&
  //           isObjWithValues(platinum_pricing.values)
  //         )
  //           setPlatinumPurities(
  //             createPuritiesFromValues(platinum_pricing.values)
  //           );
  //       }
  //     }
  //   }, 3000);
  // }, [masters]);

  const createPuritiesFromValues = (obj) => {
    if (isObjWithValues(obj)) {
      let values = Object.values(obj);
      let keys = Object.keys(obj);
      let array = [];
      for (let i = 0; i < keys.length; i++) {
        array.push({ label: values[i], value: keys[i] });
      }
      return array;
    }
  };
  const onPurityChange = (label, e) => {
    // let { gold_pricing, silver_pricing, platinum_pricing } = masters;
    // let { default_currency } = productSettings;
    let value = product[label] === e.target.value ? "" : e.target.value;
    onEditProduct({
      [label]: product[label] === value ? "" : value,
    });
    // setRates((state) => ({
    //   ...state,
    //   [label === "gold_kt"
    //     ? "gold"
    //     : label === "silver_purity"
    //     ? "silver"
    //     : label === "platinium_purity" && "platinum"]: getMetalRate(
    //     label === "gold_kt"
    //       ? gold_pricing
    //       : label === "silver_purity"
    //       ? silver_pricing
    //       : label === "platinium_purity" && platinum_pricing,
    //     default_currency,
    //     value
    //   ),
    // }));
  };
  const getMetalRate = (pricing, defaultCurrency, value) => {
    if (!pricing) return 0;
    let { type } = pricing;
    if (!type || !defaultCurrency) return 0;

    if (
      pricing &&
      pricing?.[defaultCurrency] &&
      pricing?.[defaultCurrency][type] &&
      pricing?.[defaultCurrency][type][value]
    )
      return pricing?.[defaultCurrency][type][value].rate
        ? pricing?.[defaultCurrency][type][value].rate
        : 0;
    return 0;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Label>Metal</Label>
          {!productSettings?.hide_pricing ? (
            <Typography variant="h6" mr={2}>
              {currencySymbols?.[defaultCurrency] || ""}{" "}
              {(price_breakup["master"] && price_breakup["master"].metal) ||
                "0"}
            </Typography>
          ) : null}
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <LabelSm>Select Metal Type</LabelSm>
            <FormControl fullWidth size="small">
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                // // value={age}
                disabled={product?.pull_source}
                multiple={true}
                // label={label}
                value={metal_types || []}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => onEditProduct({ metal_types: e.target.value })}
              >
                {isArrayWithValues(metalTypes) &&
                  metalTypes.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
              </Select>
              {/* <Dropdown
                // label="Gemstone Type"
                multiple
                data={metalTypes}
                onChange={(e) => onEditProduct({ metal_type: e.target.value })}
                selected={metal_type}
              /> */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelSm>Select Metal Color</LabelSm>
            <Stack direction="row" justifyContent={"flex-start"}>
              {metalColors.map((i) => {
                return (
                  <Tooltip title={i.value}>
                    <IconButton
                      sx={{ p: 1.5 }}
                      onClick={(e) =>
                        !product?.pull_source &&
                        onEditProduct({
                          metal_color: metal_color === i.value ? "" : i.value,
                        })
                      }
                      // onClick={() => onColorClick(id, i.value)}
                    >
                      <Box
                        sx={{
                          background: colorsBackground[i.value],
                          height: "25px",
                          width: "25px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            i.value === metal_color ? "#000" : "transparent"
                          }`,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Stack>
            {/* <FormControl fullWidth size="small">
              <Dropdown
                // label="Gemstone Type"
                data={metalColors}
                onChange={(e) => onEditProduct({ metal_color: e.target.value })}
                selected={metal_color}
              />
            </FormControl> */}
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <LabelSm>HUID</LabelSm>
            <Autocomplete
              // style={{ marginBottom: "10px" }}
              multiple
              id="tags-filled"
              size="small"
              options={[]}
              value={huid || []}
              disabled={product?.huidhasAlready && product?.pull_source}
              // defaultValue={[top100Films[13].title]}
              // error={product.huid.find(item => )}
              freeSolo
              renderTags={(value, getTagProps) =>
                isArrayWithValues(value)
                  ? value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                  : []
              }
              onChange={(event, newValue) => {
                onEditProduct({ huid: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  error={huidError}
                  {...params}
                  // variant="filled"
                  // label="HUID"
                  placeholder="Press enter to add multiple"
                  helperText={huidError && "HUID must be 6 digit"}
                />
              )}
            />
          </Grid> */}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Label>Hallmark?</Label>
            <Switch
              onChange={(e) => {
                onEditProduct({ hallmarked: e ? "yes" : "no" });
              }}
              checked={hallmarked === "yes" ? true : false}
              style={{
                border: `1px solid ${
                  hallmarked === "yes" ? backgroundColor : "#e8e8e8"
                }`,
                backgroundColor:
                  hallmarked === "yes" ? backgroundColor : "#e8e8e8",
              }}
            />
          </Stack>
          <Collapse in={hallmarked === "yes"}>
            <Autocomplete
              style={{ marginBottom: "10px" }}
              multiple
              id="tags-filled"
              size="small"
              options={[]}
              // defaultValue={[top100Films[13].title]}
              // error={product.huid.find(item => )}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(event, newValue) => {
                onEditProduct({ huid: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  error={huidError}
                  {...params}
                  // variant="filled"
                  label="HUID"
                  placeholder="HUID"
                  helperText={huidError && "HUID must be 6 digit"}
                />
              )}
            />
          </Collapse>
        </Grid> */}
        {Array.isArray(metal_types) && metal_types.includes("gold") ? (
          <>
            <RenderMetal
              title="Gold Purity"
              purities={goldPurities}
              rates={rates}
              disabledPurity={product?.pull_source}
              Type="Gold"
              defaultCurrency={productSettings.default_currency}
              purityLabel="gold_kt"
              grossKey="gold_gross"
              netKey="gold_net"
              selectedPurity={gold_kt}
              onPurityChange={onPurityChange}
              gross={gold_gross}
              grossWeightDisable={product?.pull_source}
              onGrossChange={(e) =>
                onEditProduct({ gold_gross: e.target.value })
              }
              net={gold_net}
              onNetChange={(e) => onEditProduct({ gold_net: e.target.value })}
              rate={gold_rate}
              netWeightDisable={
                (productSettings && productSettings.net_weight === "auto") ||
                product?.pull_source
              }
              product={product}
              onEditProduct={onEditProduct}
              getVariantsWithNetValue={getVariantsWithNetValue}
              // onRateChange={(e) => onEditProduct({ gold_rate: e.target.value })}
            />
          </>
        ) : null}
        {Array.isArray(metal_types) && metal_types.includes("silver") ? (
          <RenderMetal
            title="Silver Purity"
            purities={silverPurities}
            Type="Silver"
            defaultCurrency={productSettings.default_currency}
            rates={rates}
            purityLabel="silver_purity"
            grossKey="silver_gross"
            netKey="silver_net"
            selectedPurity={silver_purity}
            onPurityChange={onPurityChange}
            gross={silver_gross}
            grossWeightDisable={product?.pull_source}
            onGrossChange={(e) =>
              onEditProduct({ silver_gross: e.target.value })
            }
            net={silver_net}
            netWeightDisable={
              (productSettings && productSettings.net_weight === "auto") ||
              product?.pull_source
            }
            onNetChange={(e) => onEditProduct({ silver_net: e.target.value })}
            product={product}
            onEditProduct={onEditProduct}
            getVariantsWithNetValue={getVariantsWithNetValue}
          />
        ) : null}
        {Array.isArray(metal_types) && metal_types.includes("platinium") ? (
          <RenderMetal
            title="Platinum Purity"
            purities={platinumPurities}
            rates={rates}
            defaultCurrency={productSettings.default_currency}
            purityLabel="platinium_purity"
            grossKey="platinium_gross"
            netKey="platinium_net"
            Type="Platinum"
            selectedPurity={platinium_purity}
            grossWeightDisable={product?.pull_source}
            onPurityChange={onPurityChange}
            gross={platinium_gross}
            onGrossChange={(e) =>
              onEditProduct({ platinium_gross: e.target.value })
            }
            net={platinium_net}
            onNetChange={(e) =>
              onEditProduct({ platinium_net: e.target.value })
            }
            netWeightDisable={
              (productSettings && productSettings.net_weight === "auto") ||
              product?.pull_source
            }
            product={product}
            onEditProduct={onEditProduct}
            getVariantsWithNetValue={getVariantsWithNetValue}
          />
        ) : null}
      </CardContent>
    </Card>
  );
});

const RenderMetal = (props) => {
  let {
    title,
    purities,
    Type,
    selectedPurity,
    onPurityChange,
    gross,
    onGrossChange,
    net,
    onNetChange,
    purityLabel,
    rates,
    onRateChange,
    netWeightDisable,
    grossWeightDisable,
    product,
    grossKey,
    netKey,
    onEditProduct,
    getVariantsWithNetValue,
    defaultCurrency,
    disabledPurity,
  } = props;

  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  let { variants, variantType } = product;
  const [selectedPurityLabel, setSelectedPurityLabel] = useState("");
  const variantWeightChange = (size, label, value) => {
    let array = [...variants];
    let obj = array.find((i) => i.size === size);
    obj[label] = value;
    array = array.map((i) => (i.size === size ? obj : i));
    let arrayWithNetValues = getVariantsWithNetValue(array);
    onEditProduct({ variants: arrayWithNetValues });
  };
  useEffect(() => {
    setSelectedPurityLabel(
      purities?.find((i) => i.value == selectedPurity)?.label || ""
    );
  }, [purities, selectedPurity]);

  return (
    <>
      <LabelSm style={{ marginBottom: "0px" }}>{title} </LabelSm>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedPurity}
        // onChange={(e) => onPurityChange(purityLabel, e)}
        unselectable="on"
      >
        {purities.length > 0
          ? purities.map((purity) => (
              <FormControlLabel
                unselectable="on"
                disabled={disabledPurity}
                value={purity.value || ""}
                control={
                  <Radio onClick={(e) => onPurityChange(purityLabel, e)} />
                }
                label={purity.label || ""}
              />
            ))
          : null}
      </RadioGroup>
      {!productSettings?.hide_pricing ? (
        <>
          <Collapse in={selectedPurityLabel}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <InfoOutlined
                sx={{ color: "info.main" }}
                style={{ height: "15px", width: "15px" }}
              />
              <Typography
                variant="caption"
                fontWeight={"500"}
                color="info.main"
                fontSize={"12px"}
                sx={{ mt: 1 }}
              >
                {Type} rate of {selectedPurityLabel}:{" "}
                {defaultCurrency && currencySymbols[defaultCurrency]}
                {rates[Type.toLowerCase()]}
              </Typography>
            </Stack>
          </Collapse>
        </>
      ) : null}

      {false ? (
        variants.map((variant, i) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={3} sm={2} md={2}>
                <Stack direction="row" alignItems={"center"}>
                  <Box>
                    <LabelSm>Size</LabelSm>
                    <TextField
                      fullWidth
                      id="outlined"
                      size="small"
                      type="number"
                      value={variant.size}
                      disabled
                      // onChange={onGrossChange}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <LabelSm>{Type} Gross Weight</LabelSm>
                <TextField
                  fullWidth
                  id="outlined"
                  size="small"
                  type="number"
                  value={variant[grossKey]}
                  disabled={grossWeightDisable}
                  onChange={(e) =>
                    variantWeightChange(variant.size, grossKey, e.target.value)
                  }
                  onWheel={(e) => e.target.blur()}
                />
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <LabelSm>{Type} Net Weight</LabelSm>
                <TextField
                  fullWidth
                  id="outlined"
                  size="small"
                  type="number"
                  value={variant[netKey]}
                  onChange={(e) =>
                    variantWeightChange(variant.size, netKey, e.target.value)
                  }
                  onWheel={(e) => e.target.blur()}
                  disabled={netWeightDisable}
                  placeholder={
                    netWeightDisable
                      ? "Net weight calculated automatically"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <LabelSm>{Type} Gross Weight</LabelSm>
            <TextField
              fullWidth
              id="outlined"
              size="small"
              type="number"
              value={gross}
              onChange={onGrossChange}
              onWheel={(e) => e.target.blur()}
              disabled={grossWeightDisable}
              // onKeyDown={(e) => e.target.blur()}
              // onKeyUp={(e) => e.target.blur()}
              // onKeyUp={(e) => {
              //   if (e.code === "ArrowUp" || e.code === "ArrowDown")
              //     return e.target.blur();
              //   // return e.preventDefault();
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LabelSm>{Type} Net Weight</LabelSm>
            <TextField
              fullWidth
              id="outlined"
              size="small"
              type="number"
              value={net}
              onChange={onNetChange}
              disabled={netWeightDisable}
              placeholder={
                netWeightDisable ? "Net weight calculated automatically" : ""
              }
              onWheel={(e) => e.target.blur()}
              // onKeyUp={(e) => e.preventDefault()}
              // onKeyPressCapture={(e) => {
              //   if (e.code === "ArrowUp" || e.code === "ArrowDown")
              //     return e.target.blur();
              // }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
  {
    /* <Grid item xs={6} sm={6} md={3}>
    <LabelSm>{Type} Base Rate</LabelSm>
    <span
      style={{ fontSize: 20, marginLeft: 5, verticalAlign: "center" }}
    >
      {rate}
    </span>
  </Grid>
  <Grid item xs={6} sm={6} md={3}>
    <LabelSm>{Type} Final Price</LabelSm>
    <span
      style={{ fontSize: 20, marginLeft: 5, verticalAlign: "center" }}
    >
      2000
    </span>
  </Grid> */
  }
};

const LabourDetails = forwardRef((props, ref) => {
  const {
    product,
    onEditProduct,
    calculateTotalMetal,
    price_breakup,
    selectedLabourPricing,
    setSelectedLabourPricing,
    allLabourMasterPricing: allLabourPricing,
    defaultCurrency,
  } = props;
  const [labourMasterPricing, setLabourMasterPricing] = useState({});
  const [allLabourMasterPricing, setAllLabourMasterPricing] = useState([]);
  const [allPricingDropdown, setAllPricingDropdown] = useState([]);
  const [totalLabour, setTotalLabour] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let [labourBreakdown, setLabourBreakdown] = useState({
    labour: 0,
    wastage: 0,
  });

  const masterPricing = useSelector((state) => state.settings.masterPricing);

  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );

  useImperativeHandle(ref, () => ({
    calculateTotalLabour() {
      const total = calculateTotalLabourPrice();
      return total;
    },
  }));

  let {
    labour_from,
    wastage_from,
    wastage_percent,
    making_from,
    minimum_making,
    labourType,
    labourMasterPricing: labourMaster,
    labour_per_gram,
    gold_gross,
    gold_net,
    silver_gross,
    silver_net,
    platinium_gross,
    platinium_net,
    metal_types,
    gold_kt,
    silver_purity,
    platinium_purity,
    total_labour,
    total_per_gram,
    custom_wastage_from,
  } = product;

  useEffect(() => {
    setLabourMasterPricing(labourMaster);
  }, [labourMaster]);
  useEffect(() => {
    setAllLabourMasterPricing(allLabourPricing);
    if (isArrayWithValues(allLabourPricing))
      setLabourMasterPricing(allLabourPricing[0]);
    else setLabourMasterPricing([]);
  }, [allLabourPricing]);
  // useEffect(() => {
  //   calculateTotalLabourPrice();
  // }, [
  //   selectedLabourPricing,
  //   metal_types,
  //   gold_gross,
  //   gold_net,
  //   silver_gross,
  //   silver_net,
  //   platinium_gross,
  //   platinium_net,
  //   gold_kt,
  //   silver_purity,
  //   platinium_purity,
  // ]);

  // useEffect(() => {
  //   calculateTotalMetal();
  // }, [
  //   selectedLabourPricing,
  //   metal_types,
  //   gold_gross,
  //   gold_net,
  //   silver_gross,
  //   silver_net,
  //   platinium_gross,
  //   platinium_net,
  //   gold_kt,
  //   silver_purity,
  //   platinium_purity,
  // ]);

  useEffect(() => {
    if (!isArrayWithValues(allLabourMasterPricing)) return;
    setSelectedLabourPricing(allLabourMasterPricing?.[0]?.id);

    // if (Array.isArray(allLabourMasterPricing)) {
    //   let array = [];
    //   for (let i = 0; i < allLabourMasterPricing.length; i++) {
    //     let { title, per_gram, wastage, labour_from, minimum_making, id } =
    //       allLabourMasterPricing[i];
    //     if (isMobile)
    //       array.push({
    //         label: (
    //           <Stack>
    //             <Typography fontSize={"15px"}>{title}</Typography>
    //             <Typography fontSize={"14px"}>
    //               Per Gram: {per_gram} ({labour_from}) - Min. Making:{" "}
    //               {minimum_making}
    //             </Typography>
    //             <Typography fontSize={"14px"}>wastage: {wastage}%</Typography>
    //           </Stack>
    //         ),
    //         value: id,
    //       });
    //     else
    //       array.push({
    //         label: (
    //           <Stack>
    //             <Typography fontSize={"15px"}>{title}</Typography>
    //             <Typography fontSize={"14px"}>
    //               Per Gram: {per_gram} ({labour_from}) - Min. Making:{" "}
    //               {minimum_making} - wastage: {wastage}%
    //             </Typography>
    //           </Stack>
    //         ),
    //         value: id,
    //       });
    //   }
    //   //   setAllPricingDropdown(array);
    //   //   // if (!selectedLabourPricing && array[0])
    //   if (array[0]) setSelectedLabourPricing(array[0].value);
    //   //   // onEditProduct({ selectedLabourPricing: array[0].value });
    //   //   else setSelectedLabourPricing("");
    //   //   // onEditProduct({ selectedLabourPricing: "" });
    // }
  }, [allLabourMasterPricing, isMobile]);

  // useEffect(() => {
  //   let { labour } = price_breakup.master;
  //   setTotalLabour(validateNumber(labour));
  // }, [price_breakup]);

  const calculateTotalLabourPrice = () => {
    let totalWeight = 0;
    if (!reduxProductSettings) return;
    let { default_currency } = reduxProductSettings;
    let { gold_pricing, silver_pricing, platinum_pricing } = masterPricing;
    let { variants } = product;
    let totalMetalRate = 0;

    let { labour_from } = product;
    if (!labour_from) labour_from = "gross";

    if (labourType === "master") {
      if (
        isArrayWithValues(allLabourPricing) &&
        allLabourPricing.find((i) => i.id == selectedLabourPricing)
      ) {
        let obj = allLabourPricing.find((i) => i.id == selectedLabourPricing);
        let { labour_from: master_labour_from } = obj;
        if (master_labour_from) labour_from = master_labour_from;
      }
    }
    let totalWeights = { gross: 0, net: 0 };
    let froms = ["gross", "net"];
    let metals = ["gold", "silver", "platinium"];
    let purityKey = {
      gold: "gold_kt",
      silver: "silver_purity",
      platinium: "platinium_purity",
    };
    let pricingKey = {
      gold: "gold_pricing",
      silver: "silver_pricing",
      platinium: "platinum_pricing",
    };

    for (let from of froms)
      for (let metal of metals) {
        if (metal_types.includes(metal)) {
          if (isArrayWithValues(variants) && false)
            variants.map(
              (i) => (totalWeight += validateNumber(i[`${metal}_${from}`]))
            );
          else {
            totalWeights[from] += validateNumber(product?.[`${metal}_${from}`]);
            // totalWeight += validateNumber(product[`${metal}_${from}`]);
          }
          let pricing = masterPricing?.[pricingKey[metal]];
          let { type } = pricing || {};
          if (
            pricing?.[default_currency] &&
            pricing?.[default_currency]?.[type] &&
            pricing?.[default_currency]?.[type]?.[product?.[purityKey[metal]]]
          ) {
            totalMetalRate += validateNumber(
              pricing[default_currency][type][product?.[purityKey[metal]]].rate
            );
          }
        }
      }

    //dividing by 2 because it was calculated twice for froms
    totalMetalRate = Math.round(totalMetalRate / 2);
    totalWeight = totalWeights[labour_from];

    // if (metal_types.includes("gold")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`gold_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`gold_${labour_from}`]);
    //   let { type } = gold_pricing || {};
    //   if (
    //     gold_pricing?.[default_currency] &&
    //     gold_pricing?.[default_currency]?.[type] &&
    //     gold_pricing?.[default_currency]?.[type]?.[gold_kt]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       gold_pricing[default_currency][type][gold_kt].rate
    //     );
    //   }
    // }

    // if (metal_types.includes("silver")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`silver_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`silver_${labour_from}`]);
    //   let { type } = silver_pricing || {};
    //   if (
    //     silver_pricing?.[default_currency] &&
    //     silver_pricing?.[default_currency]?.[type] &&
    //     silver_pricing?.[default_currency]?.[type]?.[silver_purity]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       silver_pricing[default_currency][type][silver_purity].rate
    //     );
    //   }
    // }
    // if (metal_types.includes("platinium")) {
    //   if (isArrayWithValues(variants) && false)
    //     variants.map(
    //       (i) => (totalWeight += validateNumber(i[`platinium_${labour_from}`]))
    //     );
    //   else totalWeight += validateNumber(product[`platinium_${labour_from}`]);
    //   let { type } = platinum_pricing || {};
    //   if (
    //     platinum_pricing?.[default_currency] &&
    //     platinum_pricing?.[default_currency]?.[type] &&
    //     platinum_pricing?.[default_currency]?.[type]?.[platinium_purity]
    //   ) {
    //     totalMetalRate += validateNumber(
    //       platinum_pricing[default_currency][type][platinium_purity].rate
    //     );
    //   }
    // }

    let currentObj = {
      labour_from: 0,
      per_gram: 0,
      wastage: 0,
      minimum_making: 0,
    };

    if (labourType === "master") {
      if (
        (isArrayWithValues(allLabourPricing) &&
          allLabourPricing.find((i) => i.id == selectedLabourPricing)) ||
        isObjWithValues(labourMasterPricing)
      ) {
        let obj =
          allLabourPricing.find((i) => i.id == selectedLabourPricing) ||
          labourMasterPricing;
        // setTotalLabour(Math.round(labour));
        let { labour_from, per_gram, wastage, minimum_making } = obj;
        // currentObj = { labour_from, per_gram, wastage, minimum_making };

        // let { labour_from, per_gram, wastage, minimum_making } = currentObj;
        wastage = wastage || 0;
        per_gram = per_gram || 0;

        let total = totalWeight * validateNumber(per_gram);
        // total += total * (wastage / 100);
        let labour = totalWeight * validateNumber(per_gram),
          wastageTemp =
            totalWeight * validateNumber(totalMetalRate) * (wastage / 100);
        total += wastageTemp;

        setLabourBreakdown({
          labour: Math.round(labour),
          wastage: Math.round(wastageTemp),
        });

        // total += total * (wastage / 100);

        //         let totalRate = totalWeight * validateNumber(totalMetalRate);
        // total +=
        // let totalWastage = totalWeight * validateNumber(per_gram);
        // totalWastage += totalWastage * (wastage / 100);
        // onEditProduct({
        //   price_breakup: {
        //     ...price_breakup,
        //     master: { ...price_breakup.master, labour: Math.round(total) },
        //   },
        // });
        if (
          minimum_making &&
          validateNumber(minimum_making) > Math.round(total)
        )
          return Math.round(
            validateNumber(minimum_making) + validateNumber(wastageTemp)
          );
        return Math.round(Math.round(total));
        // return Math.round(total_per_gram) > validateNumber(minimum_making)
        //   ? Math.round(total_labour + total_per_gram)
        //   : validateNumber(
        //       validateNumber(Math.round(minimum_making)) +
        //         validateNumber(Math.round(total_labour))
        //     );
      }
    } else if (labourType === "customize") {
      // currentObj = {
      //   labour_from: product.labour_from,
      //   per_gram: validateNumber(product.labour_per_gram),
      //   wastage: validateNumber(product.wastage_percent),
      //   minimum_making: validateNumber(product.minimum_making),
      // };

      let {
        labour_from,
        labour_per_gram,
        wastage_percent,
        making_from,
        custom_wastage_from,
      } = product;
      // if (
      //   !labour_from ||
      //   !labour_per_gram ||
      //   !wastage_percent
      // )
      //   return 0;
      let totalMetalWeightPercentage =
        (totalWeights[custom_wastage_from] || totalWeight) *
        (validateNumber(wastage_percent) / 100);
      // totalWeight * (validateNumber(wastage_percent) / 100);

      let total_labour = Math.round(
        totalMetalRate * totalMetalWeightPercentage
      );

      // let total_labour = Math.round(
      //   totalMetalRate * (validateNumber(wastage_percent) / 100)
      // );
      let total_per_gram = 0;
      if (making_from === "labour") {
        total_per_gram = Math.round(labour_per_gram * totalWeight) || 0;
      } else if (
        making_from == "labour-wastage" ||
        making_from == "labour_wastage"
      ) {
        let _total_weight = totalWeights[custom_wastage_from] || totalWeight;
        let wastage = _total_weight + _total_weight * (wastage_percent / 100);
        total_per_gram = Math.round(labour_per_gram * wastage) || 0;
      }

      onEditProduct({ total_per_gram, total_labour });
      return Math.round(total_per_gram) > validateNumber(minimum_making)
        ? Math.round(total_labour + total_per_gram)
        : validateNumber(
            validateNumber(Math.round(minimum_making)) +
              validateNumber(Math.round(total_labour))
          );
    } else return 0;

    // if (obj) onEditProduct();
    // return 0;

    return 0;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"flex-start"}
            >
              <Label style={{ margin: "0px 8px 0px 0px" }}>Labour</Label>

              <Typography variant="h6" mr={2}>
                {currencySymbols[defaultCurrency] || ""}
                {(price_breakup[labourType] &&
                  price_breakup[labourType].labour) ||
                  "0"}
              </Typography>
            </Stack>
            {labourType === "master" ? (
              <Typography
                variant="h6"
                fontWeight={"500px"}
                color="text.secondary"
                fontSize={"12px"}
              >
                Labour: {currencySymbols[defaultCurrency] || ""}
                {price_breakup?.labourBreakup?.labour} + wastage:{" "}
                {currencySymbols[defaultCurrency] || ""}
                {price_breakup?.labourBreakup?.wastage}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} md={8}>
            {/* <FormControl size="small" fullWidth>
              <Dropdown
                data={allPricingDropdown}
                onChange={
                  (e) => setSelectedLabourPricing(e.target.value)
                  // onEditProduct({ selectedLabourPricing: e.target.value })
                }
                selected={selectedLabourPricing}
              />
            </FormControl> */}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: 10,
              }}
            >
              <FormControl size="small">
                <Dropdown
                  data={[
                    { label: "Master", value: "master" },
                    { label: "Customize", value: "customize" },
                  ]}
                  onChange={(e) =>
                    onEditProduct({ labourType: e.target.value })
                  }
                  selected={labourType}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <FormControl size="small">
            <Dropdown
              data={[
                { label: "Master", value: "master" },
                { label: "Customize", value: "customize" },
              ]}
              onChange={(e) => onEditProduct({ labourType: e.target.value })}
              selected={labourType}
            />
          </FormControl>
        </div> */}
        {labourType === "master" ? (
          <Grid
            container
            style={{ marginTop: isMobile ? "3px" : "12px" }}
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Stack alignItems="center" direction={"row"}>
                <LabelLg sx={{ color: "text.secondary" }}>Wastage</LabelLg>
                {labourMasterPricing.wastage ? (
                  <span style={{ marginLeft: 4, fontSize: 12 }}>
                    <Chip
                      label={`${labourMasterPricing.wastage}%`}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </span>
                ) : null}
              </Stack>
              <div style={{ marginTop: "8px" }}>
                {/* <Alert
                  variant="outlined"
                  severity="info"
                  style={{ padding: "0px 6px", width: "fit-content" }}
                >
                  Wastage on rate
                </Alert> */}
                {labourMasterPricing.wastage_from ? (
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      marginLeft: 4,
                      fontWeight: "600",
                    }}
                  >
                    wastage on {labourMasterPricing.wastage_from}
                  </span>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Stack alignItems="center" direction={"row"}>
                <LabelLg sx={{ color: "text.secondary" }}>Labour</LabelLg>
                {/* <span style={{ marginLeft: 4, fontSize: 12 }}>
                </span> */}
                <Chip
                  label={`${
                    labourMasterPricing.per_gram
                      ? labourMasterPricing.per_gram
                      : 0
                  } Per gram`}
                  style={{ fontSize: "12px", ml: 2 }}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              </Stack>
              <div style={{ marginTop: "8px" }}>
                {labourMasterPricing.making_from === "labour" ? (
                  <span
                    style={{ fontSize: 12, marginLeft: 4, fontWeight: "600" }}
                  >
                    Wastage on labour
                  </span>
                ) : labourMasterPricing.making_from === "labour_wastage" ? (
                  <span
                    style={{ fontSize: 12, marginLeft: 4, fontWeight: "600" }}
                  >
                    Wastage on labour + wastage
                  </span>
                ) : null}
                {/* <Alert
                  variant="outlined"
                  severity="info"
                  style={{ padding: "0px 6px", width: "fit-content" }}
                >
                  Wastage on labour + wastage
                </Alert> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelLg sx={{ color: "text.secondary" }}>Minimum Labour</LabelLg>
              <LabelLg style={{ marginTop: "8px" }}>
                {currencySymbols[defaultCurrency]}{" "}
                {labourMasterPricing.minimum_making
                  ? labourMasterPricing.minimum_making
                  : null}
              </LabelLg>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelLg sx={{ color: "text.secondary" }}>
                Labour calculate on
              </LabelLg>
              <LabelLg style={{ marginTop: "8px" }}>
                {capitalizeFirstLetter(
                  labourMasterPricing?.labour_from || ""
                ) || ""}
              </LabelLg>
            </Grid>
          </Grid>
        ) : null}
        {labourType === "customize" ? (
          <>
            {/* <Alert variant="outlined" severity="info" size="small" sx={{}}>
              Choose Labour Options as per your requirements, leave empty if not
              needed.
            </Alert> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <LabelSm>Labour calculation from?</LabelSm>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={labour_from}
                onChange={(e) => onEditProduct({ labour_from: e.target.value })}
              >
                <FormControlLabel
                  value={"gross"}
                  control={<Radio size="small" />}
                  label={"Gross"}
                />
                <FormControlLabel
                  value={"net"}
                  control={<Radio size="small" />}
                  label={"Net"}
                />
              </RadioGroup>
            </div>

            <Grid container spacing={2} style={{ marginBottom: "14px" }}>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelSm style={{}}>Labour Wastage</LabelSm>
                  <LabelSm style={{}}>
                    {currencySymbols[defaultCurrency]} {total_labour}
                  </LabelSm>

                  {/* <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={wastage_from}
                    onChange={(e) =>
                      onEditProduct({ wastage_from: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value={"rate"}
                      control={<Radio size="small" />}
                      label={"Rate"}
                    />
                    <FormControlLabel
                      value={"weight"}
                      control={<Radio size="small" />}
                      label={"Weight"}
                    />
                  </RadioGroup> */}
                </div>
                <TextField
                  fullWidth
                  id="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        <FormControl
                          variant="standard"
                          sx={{
                            "&:hover": {
                              border: "0px solid transparent",
                              backgroundColor: "transparent",
                              borderRadius: "10px",
                            },
                            "&:focus": {
                              backgroundColor: "transparent",
                            },
                            // minWidth: minWidth || "120px",
                          }}
                          size="small"
                        >
                          {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                          <Select
                            disableUnderline
                            id="demo-multiple-checkbox"
                            // multiple
                            // open={open}
                            // input={<OutlinedInput  label={""} />}
                            // renderValue={(selected) => selected}
                            value={custom_wastage_from}
                            onChange={(e) =>
                              onEditProduct({
                                custom_wastage_from: e.target.value,
                              })
                            }
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ backgroundColor: "transparent" }}
                            SelectDisplayProps={{
                              style: { backgroundColor: "transparent" },
                            }}
                            sx={{
                              // margin: "4px 6px",
                              "&:focus": {
                                backgroundColor: "transparent",
                              },
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            size="small"
                          >
                            {[
                              { label: "From Gross", value: "gross" },
                              {
                                label: "From Net",
                                value: "net",
                              },
                            ].map((obj) => {
                              return (
                                <MenuItem key={obj.value} value={obj.value}>
                                  {obj.label}
                                  {/* <ListItemText primary={obj.label} /> */}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputAdornment>
                    ),
                    // endAdornment: (
                    //   <InputAdornment>
                    //     <FormControl
                    //       variant="standard"
                    //       sx={{
                    //         "&:hover": {
                    //           border: "0px solid transparent",
                    //           backgroundColor: "transparent",
                    //           borderRadius: "10px",
                    //         },
                    //         "&:focus": {
                    //           backgroundColor: "transparent",
                    //         },
                    //         // minWidth: minWidth || "120px",
                    //       }}
                    //       size="small"
                    //     >
                    //       {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                    //       <Select
                    //         disableUnderline
                    //         id="demo-multiple-checkbox"
                    //         // multiple
                    //         // open={open}
                    //         // input={<OutlinedInput  label={""} />}
                    //         // renderValue={(selected) => selected}
                    //         value={wastage_from}
                    //         onChange={(e) =>
                    //           onEditProduct({ wastage_from: e.target.value })
                    //         }
                    //         // onClose={handleClose}
                    //         // onOpen={handleOpen}
                    //         displayEmpty
                    //         inputProps={{ "aria-label": "Without label" }}
                    //         style={{ backgroundColor: "transparent" }}
                    //         SelectDisplayProps={{
                    //           style: { backgroundColor: "transparent" },
                    //         }}
                    //         sx={{
                    //           // margin: "4px 6px",
                    //           "&:focus": {
                    //             backgroundColor: "transparent",
                    //           },
                    //           "&:hover": {
                    //             backgroundColor: "transparent",
                    //           },
                    //         }}
                    //         size="small"
                    //       >
                    //         {[
                    //           { label: "On Rate", value: "rate" },
                    //           { label: "On Weight", value: "weight" },
                    //         ].map((obj) => {
                    //           return (
                    //             <MenuItem key={obj.value} value={obj.value}>
                    //               {obj.label}
                    //               {/* <ListItemText primary={obj.label} /> */}
                    //             </MenuItem>
                    //           );
                    //         })}
                    //       </Select>
                    //     </FormControl>
                    //   </InputAdornment>
                    // ),
                  }}
                  value={wastage_percent}
                  onChange={(e) =>
                    onEditProduct({ wastage_percent: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelSm>Per Gram</LabelSm>
                  <LabelSm style={{}}>
                    {currencySymbols[defaultCurrency]} {total_per_gram}
                  </LabelSm>
                  {/* <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={{ marginLeft: 6 }}
                    value={making_from}
                    onChange={(e) =>
                      onEditProduct({ making_from: e.target.value })
                    }
                  >
                    <FormControlLabel
                      label={"Labour"}
                      control={<Radio size="small" />}
                      value={"labour"}
                    />
                    <FormControlLabel
                      label={"Labour + Wastage"}
                      control={<Radio size="small" />}
                      value={"labour-wastage"}
                    />
                  </RadioGroup> */}
                </div>
                <TextField
                  fullWidth
                  id="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbols[defaultCurrency]}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        <FormControl
                          variant="standard"
                          sx={{
                            "&:hover": {
                              border: "0px solid transparent",
                              backgroundColor: "transparent",
                              borderRadius: "10px",
                            },
                            "&:focus": {
                              backgroundColor: "transparent",
                            },
                            // minWidth: minWidth || "120px",
                          }}
                          size="small"
                        >
                          {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                          <Select
                            disableUnderline
                            id="demo-multiple-checkbox"
                            // multiple
                            // open={open}
                            // input={<OutlinedInput  label={""} />}
                            // renderValue={(selected) => selected}
                            value={making_from}
                            onChange={(e) =>
                              onEditProduct({ making_from: e.target.value })
                            }
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ backgroundColor: "transparent" }}
                            SelectDisplayProps={{
                              style: { backgroundColor: "transparent" },
                            }}
                            sx={{
                              // margin: "4px 6px",
                              "&:focus": {
                                backgroundColor: "transparent",
                              },
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            size="small"
                          >
                            {[
                              { label: "Labour", value: "labour" },
                              {
                                label: "Labour + Wastage",
                                value: "labour_wastage",
                              },
                            ].map((obj) => {
                              return (
                                <MenuItem key={obj.value} value={obj.value}>
                                  {obj.label}
                                  {/* <ListItemText primary={obj.label} /> */}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </InputAdornment>
                    ),
                  }}
                  value={labour_per_gram}
                  onChange={(e) =>
                    onEditProduct({ labour_per_gram: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "14px" }}>
              <Grid item xs={6} sm={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelSm>Minimum making</LabelSm>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbols[defaultCurrency]}
                      </InputAdornment>
                    ),
                  }}
                  value={minimum_making}
                  onChange={(e) =>
                    onEditProduct({ minimum_making: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
});

function AddGemstone(props) {
  const [gemstonePricings, setGemstonePricing] = useState({});
  const [gemstoneQuality, setGemstoneQuality] = useState([]);
  const [gemstoneTypes, setGemstoneTypes] = useState([]);
  const [gemstoneShapes, setGemstoneShapes] = useState([]);
  const [gemstoneSizes, setGemstoneSizes] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedShape, setSelectedShape] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [pieces, setPieces] = useState("");
  const [weight, setWeight] = useState("");
  const [rate, setRate] = useState("");
  const [customzieRate, setCustomzeRate] = useState("");
  const [price, setPrice] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteAlert, setDeleteAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    onClose,
    selectedValue,
    open,
    masters,
    onAdd,
    onEdit,
    toggleDialog,
    colorstone_from,
    editData,
    onDelete,
    product,
    productSettings,
  } = props;

  const reduxProductDefaults = useSelector(
    (state) => state.settings.productDefaults || {}
  );

  const location = useLocation();

  useEffect(() => {
    let { selectedGemQuality } = reduxProductDefaults;
    if (
      isArrayWithValues(gemstoneQuality) &&
      selectedGemQuality &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (gemstoneQuality.find((i) => i.value === selectedGemQuality))
        onQualityChange({ target: { value: selectedGemQuality } });
  }, [gemstoneQuality, editData]);

  useEffect(() => {
    let { selectedGemType } = reduxProductDefaults;
    if (
      isArrayWithValues(gemstoneTypes) &&
      selectedGemType &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (gemstoneTypes.find((i) => i.value === selectedGemType))
        onTypeChange({ target: { value: selectedGemType } });
  }, [gemstoneTypes, editData]);

  useEffect(() => {
    let { selectedGemShape } = reduxProductDefaults;
    if (
      isArrayWithValues(gemstoneShapes) &&
      selectedGemShape &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (gemstoneShapes.find((i) => i.value === selectedGemShape))
        onShapeChange({ target: { value: selectedGemShape } });
  }, [gemstoneShapes, editData]);
  useEffect(() => {
    let { selectedGemSize } = reduxProductDefaults;
    if (
      isArrayWithValues(gemstoneSizes) &&
      selectedGemSize &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (gemstoneSizes.find((i) => i.value === selectedGemSize))
        onSizeChange({ target: { value: selectedGemSize } });
  }, [gemstoneSizes, editData]);

  const handleClose = () => {
    onClose(selectedValue);
  };
  useEffect(() => {
    if (masters && Object.keys(masters).length > 0) {
      let { gemstone_pricing } = masters;
      if (props.defaultCurrency && gemstone_pricing) {
        setGemstonePricing(gemstone_pricing[props.defaultCurrency]);
        setGemstoneQuality(
          getGemstoneQuality(gemstone_pricing[props.defaultCurrency])
        );
      }
    }
  }, [masters, props.defaultCurrency]);

  useEffect(() => {
    if (rate && weight) onWeightChange(weight);
  }, [rate]);

  useEffect(() => {
    if (isObjWithValues(editData)) setEditData(editData);
    else {
      setSelectedQuality("");
      setSelectedType("");
      setSelectedShape("");
      setSelectedSize("");
      setPieces("");
      setWeight("");
      setRate("");
      setPrice("");
    }
    if (isObjWithValues(editData)) setIsEdit(true);
    else setIsEdit(false);
  }, [JSON.stringify(editData)]);

  const setEditData = (editData) => {
    let {
      colorstone_quality,
      colorstone_type,
      colorstone_shape,
      colorstone_pieces,
      colorstone_weight,
      colorstone_rate,
    } = editData;
    if (colorstone_quality)
      onQualityChange({ target: { value: colorstone_quality } });
    if (colorstone_pieces) setPieces(colorstone_pieces);
    if (colorstone_weight) setWeight(colorstone_weight);
    if (colorstone_from === "customize" && colorstone_rate)
      setRate(colorstone_rate);
  };
  useEffect(() => {
    if (errorMessage) setErrorMessage("");
  }, [weight, pieces]);

  useEffect(() => {
    if (isArrayWithValues(gemstoneTypes))
      if (editData.colorstone_type)
        onTypeChange({ target: { value: editData.colorstone_type } });
  }, [gemstoneTypes]);

  useEffect(() => {
    if (isArrayWithValues(gemstoneShapes))
      if (editData.colorstone_shape)
        onShapeChange({ target: { value: editData.colorstone_shape } });
  }, [gemstoneShapes]);

  useEffect(() => {
    if (isArrayWithValues(gemstoneSizes))
      if (editData.colorstone_size)
        onSizeChange(
          { target: { value: editData.colorstone_size } },
          colorstone_from
        );
  }, [gemstoneSizes]);

  const onQualityChange = (e) => {
    setSelectedQuality(e.target.value);
    setGemstoneTypes(getGemstoneTypes(gemstonePricings, e.target.value));
  };

  const onTypeChange = (e) => {
    setSelectedType(e.target.value);
    setGemstoneShapes(
      getGemstoneShapes(gemstonePricings, selectedQuality, e.target.value)
    );
  };

  const onShapeChange = (e) => {
    setSelectedShape(e.target.value);
    setGemstoneSizes(
      getGemstoneSizes(
        gemstonePricings,
        selectedQuality,
        selectedType,
        e.target.value
      )
    );
  };

  const onSizeChange = (e, colorstoneFrom) => {
    setSelectedSize(e.target.value);
    if (colorstoneFrom !== "customize")
      setRate(
        getGemstoneRate(
          gemstonePricings,
          selectedQuality,
          selectedType,
          selectedShape,
          e.target.value
        )
      );
  };

  const onColorChange = (e, color) => {
    setSelectedColors(color);
  };

  const onWeightChange = (value) => {
    setWeight(value);
    value = Number(value);
    if (!isNaN(value) && !isNaN(Number(rate)))
      setPrice(Math.round(rate * value));
  };

  const onSubmit = () => {
    if (colorstone_from === "master") {
      if (!pieces) return setErrorMessage("Please enter pieces");
      if (!weight) return setErrorMessage("Please enter weight");
    }
    let obj = {};
    obj.colorstone_quality = selectedQuality;
    obj.colorstone_type = selectedType;
    obj.colorstone_shape = selectedShape;
    obj.colorstone_size = selectedSize;
    obj.colorstone_pieces = pieces;
    obj.colorstone_weight = weight;
    obj.colorstone_rate = rate;
    if (isEdit) onEdit(obj, editData.index);
    else onAdd(obj);
    toggleDialog(false);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
      sx={{ ...dialogStyle }}
      {...globalPopupStyle}
    >
      <DialogContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Label>{isEdit ? "Edit" : "Add"} Gemstone</Label>
          <IconButton onClick={() => toggleDialog(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Collapse in={showDeleteAlert}>
          <Alert
            severity="error"
            action={
              <>
                <Button
                  color="error"
                  size="small"
                  onClick={() => {
                    onDelete(editData.index);
                    setDeleteAlert(false);
                  }}
                >
                  DELETE
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDeleteAlert(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </>
            }
            onClose={() => setDeleteAlert(false)}
          >
            Delete this Gemstone?
          </Alert>
        </Collapse>
        <Collapse in={errorMessage ? true : false}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            onClose={() => setDeleteAlert(false)}
            style={{ marginTop: "7px" }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <DropdownLabel>Gemstone Quality</DropdownLabel>
            <FormControl fullWidth size="small">
              <Dropdown
                data={gemstoneQuality}
                onChange={onQualityChange}
                selected={selectedQuality}
              />
            </FormControl>
          </Grid>
          {!productSettings?.hide_pricing ? (
            <>
              <Grid item xs={12} sm={6}>
                <DropdownLabel>Gemstone Type</DropdownLabel>
                <FormControl fullWidth size="small">
                  <Dropdown
                    // label="Gemstone Type"
                    data={gemstoneTypes}
                    onChange={onTypeChange}
                    selected={selectedType}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DropdownLabel>Gemstone Shape</DropdownLabel>
                <FormControl fullWidth size="small">
                  <Dropdown
                    data={gemstoneShapes}
                    onChange={onShapeChange}
                    selected={selectedShape}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DropdownLabel>Gemstone Sizes</DropdownLabel>
                <FormControl fullWidth size="small">
                  <Dropdown
                    data={gemstoneSizes}
                    onChange={onSizeChange}
                    selected={selectedSize}
                  />
                </FormControl>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={6}>
            <DropdownLabel>Gemstone Pieces</DropdownLabel>
            <TextField
              value={pieces}
              onChange={(e) => setPieces(e.target.value)}
              fullWidth
              size="small"
              id="outlined"
              variant="outlined"
              // label="Gemstone Pieces"
              type="number"
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropdownLabel>Gemstone Weight in cts</DropdownLabel>
            <TextField
              value={weight}
              onChange={(e) => onWeightChange(e.target.value)}
              fullWidth
              size="small"
              id="outlined"
              // label="Gemstone Weight in cts"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ alignItems: "center" }}>
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              {colorstone_from === "customize" &&
              !productSettings?.hide_pricing ? (
                <>
                  <DropdownLabel>Gemstone Price (Lumpsum)</DropdownLabel>
                  <TextField
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    fullWidth
                    size="small"
                    id="outlined"
                    // label="Gemstone Weight in cts"
                    type="number"
                  />
                </>
              ) : null}
            </div>
            {/* {colorstone_from === "master" && false ? (
              <span
                style={{
                  margin: "auto 0px",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "0px 5px" }}>Rate: {rate || ""}</div>
                <div style={{ margin: "0px 5px" }}>Price: {price || ""}</div>
              </span>
            ) : null} */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropdownLabel>Gemstone Colors</DropdownLabel>
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={selectedColors}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={gemstoneColors}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                onChange={onColorChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* {colorstone_from === "customize" ? (
          <div style={{ marginTop: 8, display: "flex" }}>
            <h4 style={{ margin: "0px 5px" }}>
              Rate
              <span style={{ margin: "0px 3px", fontWeight: "400" }}>
                (Per ct.)
              </span>
              :{" "}
              {validateNumber(Math.round(Number(rate) / Number(weight))) || ""}
            </h4>
          </div>
        ) : null} */}
        {colorstone_from === "master" && !productSettings?.hide_pricing ? (
          <div style={{ marginTop: 8, display: "flex" }}>
            <h4 style={{ margin: "0px 5px" }}>Rate : {rate || ""}</h4>
            <h4 style={{ margin: "0px 5px" }}>Price: {price || ""}</h4>
          </div>
        ) : null}
        <div>
          <Button
            variant="contained"
            style={{ float: "right", marginRight: "5px" }}
            onClick={onSubmit}
          >
            {isEdit ? "Update" : "Add"}
          </Button>
          {isEdit ? (
            <Button
              variant="text"
              style={{ float: "right", marginRight: "5px" }}
              color="error"
              onClick={() => setDeleteAlert(true)}
            >
              Delete
            </Button>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function AddDiamond(props) {
  const [diamondTypes, setDiamondTypes] = useState({});
  const [diamondPricings, setDiamondPricings] = useState({});
  const [diamondQuality, setDiamondQuality] = useState([]);
  const [diamondShapes, setDiamondShapes] = useState([]);
  const [diamondSizes, setDiamondSizes] = useState([]);
  const [diamondCuts, setDiamondCuts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedQuality, setSelectedQuality] = useState("");
  const [selectedShape, setSelectedShape] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCut, setSelectedCut] = useState("");
  const [pieces, setPieces] = useState("");
  const [weight, setWeight] = useState("");
  const [rate, setRate] = useState("");
  const [price, setPrice] = useState("");
  const [showDeleteAlert, setDeleteAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    onClose,
    selectedValue,
    open,
    masters,
    onAdd,
    toggleDialog,
    editData,
    onEdit,
    onDelete,
    productSettings,
    product,
    defaultCurrency,
    allDiamondGroups,
  } = props;

  let { diamond_from } = product;
  const location = useLocation();

  const reduxProductDefaults = useSelector(
    (state) => state.settings.productDefaults || {}
  );

  useEffect(() => {
    if (isArrayWithValues(diamondSizes) && isObjWithValues(allDiamondGroups)) {
      if (weight && pieces) {
        let netWeight = Number(Number(weight) / Number(pieces));
        let diamondGroup = Object.values(allDiamondGroups);
        let obj = diamondGroup.find((i) => {
          return (
            i.shape === selectedShape &&
            netWeight >= Number(i.from) &&
            netWeight <= Number(i.to)
          );
        });
        if (!obj)
          obj = diamondGroup.find((i) => {
            let netWeightFixed2 = Number(netWeight).toFixed(2);
            return (
              i.shape === selectedShape &&
              netWeightFixed2 >= Number(i.from) &&
              netWeightFixed2 <= Number(i.to)
            );
          });
        if (obj) {
          onSizeChange({ target: { value: obj.id } });
          if (selectedCut) setSelectedCut({ target: { value: selectedCut } });
        } else {
          setSelectedSize("");
          setSelectedCut("");
          setRate("");
          setDiamondCuts([]);
        }
      }
    }
  }, [
    diamondSizes,
    weight,
    pieces,
    selectedShape,
    selectedQuality,
    selectedType,
    allDiamondGroups,
  ]);

  useEffect(() => {
    let { selectedDiamondType } = reduxProductDefaults;
    if (
      isArrayWithValues(diamondTypes) &&
      selectedDiamondType &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (diamondTypes.find((i) => i.value === selectedDiamondType))
        onTypeChange({ target: { value: selectedDiamondType } });
  }, [diamondTypes, editData]);
  useEffect(() => {
    let { selectedQuality } = reduxProductDefaults;
    if (
      isArrayWithValues(diamondQuality) &&
      selectedQuality &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (diamondQuality.find((i) => i.value === selectedQuality))
        onQualityChange({ target: { value: selectedQuality } });
  }, [diamondQuality, editData]);

  useEffect(() => {
    let { selectedShape } = reduxProductDefaults;
    if (
      isArrayWithValues(diamondShapes) &&
      selectedShape &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (diamondShapes.find((i) => i.label === selectedShape))
        onShapeChange({ target: { value: selectedShape } });
  }, [diamondShapes, editData]);

  useEffect(() => {
    let { selectedSize } = reduxProductDefaults;
    if (
      isArrayWithValues(diamondSizes) &&
      selectedSize &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (diamondSizes.find((i) => i.value === selectedSize))
        onSizeChange({ target: { value: selectedSize } });
  }, [diamondSizes, editData]);

  useEffect(() => {
    let { selectedCut } = reduxProductDefaults;
    if (
      isArrayWithValues(diamondCuts) &&
      selectedCut &&
      !isObjWithValues(editData)
      // location.pathname.includes("/products/add")
    )
      if (diamondCuts.find((i) => i.value === selectedCut))
        onCutChange({ target: { value: selectedCut } });
  }, [diamondCuts, editData]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  useEffect(() => {
    if (errorMessage) setErrorMessage("");
  }, [weight, pieces]);

  useEffect(() => {
    if (masters && Object.keys(masters).length > 0) {
      let { diamond_pricing } = masters;
      if (diamond_pricing) {
        setDiamondPricings(diamond_pricing[defaultCurrency]);
        setDiamondTypes(getDiamondTypes(diamond_pricing[defaultCurrency]));
        // setDiamondQuality(getDiamondQuality(diamond_pricing[defaultCurrency]));
      }
    }
  }, [masters, props.defaultCurrency]);

  useEffect(() => {
    if (rate && weight) onWeightChange(weight);
  }, [rate]);

  useEffect(() => {
    if (isObjWithValues(editData)) setEditData(editData);
    else {
      setSelectedType("");
      setSelectedQuality("");
      setSelectedShape("");
      setSelectedSize("");
      setSelectedCut("");
      setPieces("");
      setWeight("");
      setRate("");
      setPrice("");
    }

    if (isObjWithValues(editData)) setIsEdit(true);
    else setIsEdit(false);
  }, [JSON.stringify(editData)]);

  const setEditData = (editData) => {
    let {
      diamond_type,
      diamond_quality,
      diamond_pieces,
      diamond_weight,
      diamond_cut,
      diamond_rate,
    } = editData;
    if (diamond_type)
      onTypeChange({ target: { value: diamond_type || "natural" } });
    // if (diamond_quality)
    //   onQualityChange({ target: { value: diamond_quality } });
    if (diamond_pieces) setPieces(diamond_pieces);
    if (diamond_weight) setWeight(diamond_weight);
    if (diamond_rate && diamond_from === "customize") setRate(diamond_rate);
    // if (diamond_cut) setSelectedCut(diamond_cut);
  };

  useEffect(() => {
    if (isArrayWithValues(diamondQuality) && editData.diamond_quality)
      onQualityChange({ target: { value: editData.diamond_quality } });
  }, [diamondQuality]);
  useEffect(() => {
    if (isArrayWithValues(diamondShapes) && editData.diamond_shape)
      onShapeChange({ target: { value: editData.diamond_shape } });
  }, [diamondShapes]);

  useEffect(() => {
    if (isArrayWithValues(diamondSizes) && editData.diamond_sieve)
      onSizeChange({ target: { value: editData.diamond_sieve } });
  }, [diamondSizes]);

  useEffect(() => {
    if (isArrayWithValues(diamondCuts) && editData.diamond_cut)
      onCutChange({ target: { value: editData.diamond_cut } });
  }, [diamondCuts]);

  // useEffect(() => {
  //   if (selectedQuality && selectedShape && selectedSize && selectedCut)
  //     return setRate(
  //       getDiamondRate(
  //         diamondPricings,
  //         selectedQuality,
  //         selectedShape,
  //         selectedSize,
  //         selectedCut
  //       )
  //     );
  //   if (selectedQuality && selectedShape && selectedSize) {
  //     let cuts = getDiamondCuts(
  //       diamondPricings,
  //       selectedQuality,
  //       selectedShape,
  //       selectedSize
  //     );
  //     if (!isArrayWithValues(cuts)) {
  //       setRate(
  //         getDiamondRate(
  //           diamondPricings,
  //           selectedQuality,
  //           selectedShape,
  //           selectedSize,
  //           ""
  //         )
  //       );
  //     } else setDiamondCuts(cuts);
  //     return;
  //   }
  //   if (selectedQuality && selectedShape)
  //     return setDiamondSizes(
  //       getDiamondSizes(diamondPricings, selectedQuality, selectedShape)
  //     );
  //   if (selectedQuality)
  //     return setDiamondShapes(
  //       getDiamondShapes(diamondPricings, selectedQuality)
  //     );
  // }, [selectedQuality, selectedShape, selectedSize, selectedCut]);

  const onTypeChange = (e) => {
    setSelectedType(e.target.value);
    setDiamondQuality(getDiamondQuality(diamondPricings, e.target.value));
  };
  const onQualityChange = (e) => {
    setSelectedQuality(e.target.value);
    setDiamondShapes(
      getDiamondShapes(diamondPricings, selectedType, e.target.value)
    );
  };
  const onShapeChange = (e) => {
    setSelectedShape(e.target.value);
    setDiamondSizes(
      getDiamondSizes(
        diamondPricings,
        selectedType,
        selectedQuality,
        e.target.value
      )
    );
  };
  const onSizeChange = async (e) => {
    setSelectedSize(e.target.value);
    let cuts = getDiamondCuts(
      diamondPricings,
      selectedType,
      selectedQuality,
      selectedShape,
      e.target.value
    );
    if (diamond_from === "master") {
      let rate = getDiamondRate(
        diamondPricings,
        selectedType,
        selectedQuality,
        selectedShape,
        e.target.value,
        ""
      );
      if (rate) setRate(rate);
      else {
        setRate("");
        setPrice("");
      }
    }
    if (isArrayWithValues(cuts)) setDiamondCuts(cuts);
    // if (!isArrayWithValues(cuts)) {
    //   setRate(
    //     getDiamondRate(
    //       diamondPricings,
    //       selectedType,
    //       selectedQuality,
    //       selectedShape,
    //       e.target.value,
    //       ""
    //     )
    //   );
    //   setDiamondCuts([]);
    // } else setDiamondCuts(cuts);
  };

  const onCutChange = async (e) => {
    setSelectedCut(e.target.value);
    // if (isObjWithValues(editData) && diamond_from === "master")
    if (diamond_from === "master") {
      let rate = getDiamondRate(
        diamondPricings,
        selectedType,
        selectedQuality,
        selectedShape,
        selectedSize,
        e.target.value
      );
      setRate(rate);
    }
  };

  const onWeightChange = (value) => {
    setWeight(value);
    value = Number(value);
    if (!isNaN(value) && !isNaN(Number(rate)))
      setPrice(Math.round(rate * value));
    else setPrice("");
  };
  const onSubmit = () => {
    if (diamond_from === "master") {
      if (!pieces) return setErrorMessage("Please enter pieces");
      if (!weight) return setErrorMessage("Please enter weight");
    }
    let obj = {};
    obj.diamond_type = selectedType;
    obj.diamond_quality = selectedQuality;
    obj.diamond_shape = selectedShape;
    obj.diamond_sieve = selectedSize;
    obj.diamond_cut = selectedCut;
    obj.diamond_pieces = pieces;
    obj.diamond_weight = weight;
    obj.diamond_rate = rate;
    if (isEdit) onEdit(obj, editData.index);
    else onAdd(obj);
    toggleDialog(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ ...dialogStyle }}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Label>{isEdit ? "Edit" : "Add"} Diamond</Label>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Collapse in={showDeleteAlert}>
          <Alert
            severity="error"
            action={
              <>
                <Button
                  color="error"
                  size="small"
                  onClick={() => {
                    onDelete(editData.index);
                    setDeleteAlert(false);
                  }}
                >
                  DELETE
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDeleteAlert(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </>
            }
            onClose={() => setDeleteAlert(false)}
          >
            Delete this Diamond?
          </Alert>
        </Collapse>
        <Collapse in={errorMessage ? true : false}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            onClose={() => setDeleteAlert(false)}
            style={{ marginTop: "7px" }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={7} sm={6}>
            <DropdownLabel>Diamond Weight in cts</DropdownLabel>
            <TextField
              value={weight}
              onChange={(e) => onWeightChange(e.target.value)}
              fullWidth
              size="small"
              id="outlined"
              // label="Gemstone Weight in cts"
              type="number"
            />
          </Grid>
          <Grid item xs={5} sm={6}>
            <DropdownLabel>Diamond Pieces</DropdownLabel>
            <TextField
              value={pieces}
              onChange={(e) => setPieces(e.target.value)}
              fullWidth
              size="small"
              id="outlined"
              variant="outlined"
              // label="Gemstone Pieces"
              type="number"
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          {!productSettings?.hide_pricing ? (
            <Grid item xs={6} sm={12}>
              <DropdownLabel>Diamond Type</DropdownLabel>

              <FormControl fullWidth size="small">
                <Dropdown
                  data={diamondTypes}
                  onChange={onTypeChange}
                  selected={selectedType}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={6} sm={12}>
            <DropdownLabel>Diamond Quality</DropdownLabel>

            <FormControl fullWidth size="small">
              <Dropdown
                data={diamondQuality}
                onChange={onQualityChange}
                selected={selectedQuality}
              />
            </FormControl>
          </Grid>
          {!productSettings?.hide_pricing ? (
            <>
              <Grid item xs={12} sm={6}>
                <DropdownLabel>Diamond Shape</DropdownLabel>
                <FormControl fullWidth size="small">
                  <Dropdown
                    // label="Gemstone Type"
                    data={diamondShapes}
                    onChange={onShapeChange}
                    selected={selectedShape}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
            <DropdownLabel>Diamond Size</DropdownLabel>
            <FormControl fullWidth size="small">
              <Dropdown
                data={diamondSizes}
                onChange={onSizeChange}
                selected={selectedSize}
              />
            </FormControl>
          </Grid> */}
              <Grid item xs={12} sm={6}>
                <DropdownLabel>Diamond Cut</DropdownLabel>
                <FormControl fullWidth size="small">
                  <Dropdown
                    data={diamondCuts}
                    onChange={onCutChange}
                    selected={selectedCut}
                  />
                </FormControl>
              </Grid>

              {diamond_from === "customize" && (
                <Grid item xs={12} sm={6}>
                  <DropdownLabel>Diamond Rate</DropdownLabel>
                  <TextField
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    fullWidth
                    size="small"
                    id="outlined"
                    // label="Gemstone Weight in cts"
                    type="number"
                  />
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
        {!productSettings?.hide_pricing ? (
          <>
            <Stack direction="row" mt={3} ml={1}>
              <Typography variant="h5" fontSize={"13px"}>
                Auto Sieve:{" "}
                {getDiamondSizeLabel(allDiamondGroups, selectedSize)}
              </Typography>
            </Stack>
            <div style={{ marginTop: 8, display: "flex" }}>
              {diamond_from === "master" && (
                <h4 style={{ margin: "0px 5px" }}>Rate: {rate || ""}</h4>
              )}
              <h4 style={{ margin: "0px 5px" }}>Price: {price || ""}</h4>
            </div>
          </>
        ) : null}
        <Button
          variant="contained"
          style={{ float: "right", marginRight: "5px" }}
          onClick={onSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
        {isEdit ? (
          <Button
            variant="text"
            style={{ float: "right", marginRight: "5px" }}
            color="error"
            onClick={() => setDeleteAlert(true)}
          >
            Delete
          </Button>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

const Dropdown = (props) => {
  let { data, label, selected, onChange, multiple } = props;

  return (
    <Select
      // labelId="demo-simple-select-label"
      // id="demo-simple-select"
      // // value={age}
      // multiple={multiple ? true : false}
      label={label}
      // value={multiple ? [] : ""}
      value={selected || ""}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
    >
      {data &&
        Array.isArray(data) &&
        data.length > 0 &&
        data.map((i) => <MenuItem value={i.value}>{i.label}</MenuItem>)}
    </Select>
  );
};

const ExtraCharges = ({ masters, defaultCurrency, onEditProduct, product }) => {
  const [additionalCharges, setAdditionalCharges] = useState({});
  const [charges, setCharges] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { extra_charges, show_extra_charges_separately } = product;
  let [totalCharges, setTotalCharges] = useState(0);

  const showAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAnchor = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!masters) return;
    let { additional_pricing } = masters;
    let obj = {};
    if (isObjWithValues(additional_pricing))
      for (let i in additional_pricing)
        if (additional_pricing[i][defaultCurrency])
          obj[i] = additional_pricing[i][defaultCurrency];

    setAdditionalCharges(obj);
  }, [masters, defaultCurrency]);

  useEffect(() => {
    let array = [];
    if (isObjWithValues(additionalCharges))
      for (let i in additionalCharges)
        if (!extra_charges.find((charge) => charge.extra_charge_label === i))
          array.push({
            label: i,
            value: additionalCharges[i],
          });
    if (charges.length !== array.length) setCharges(array);
    if (isArrayWithValues(extra_charges)) {
      let total = 0;
      extra_charges.map((i) => {
        total += +i.extra_charge_value;
      });
      setTotalCharges(total);
    } else setTotalCharges(0);
  }, [additionalCharges, product.extra_charges, masters]);

  const onAddExtraCharge = (label, value) => {
    let array = [...extra_charges];
    array.push({
      extra_charge_label: label,
      extra_charge_value: value,
    });
    onEditProduct({ extra_charges: array });
    // setAnchorEl(null);
  };

  const onExtraChargeChange = (label, value) => {
    let array = [...extra_charges];
    array = array.map((i) =>
      i.extra_charge_label === label ? { ...i, extra_charge_value: value } : i
    );

    onEditProduct({ extra_charges: array });
  };

  const onRemoveExtraCharges = (label) => {
    let array = [...extra_charges];
    array = array.filter((i) => i.extra_charge_label !== label);
    onEditProduct({ extra_charges: array });
  };

  // /// adding extra charges
  const closeAddForm = () => setAddFormOpen(false);
  const openAddForm = () => setAddFormOpen(true);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [addLabel, setAddLabel] = useState("");
  const [addLabelValue, setAddLabelValue] = useState("");
  const [addLabelError, setAddLabelError] = useState("");

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const onAddLabelChange = (e) => {
    if (addLabelError) setAddLabelError("");
    setAddLabel(e.target.value);
  };
  const dispatch = useDispatch();

  const onAddLabel = () => {
    if (masters.additional_pricing[addLabel])
      return setAddLabelError(`${addLabel} is already added.`);

    dispatch(
      addExtraCharge(
        {
          ...masters.additional_pricing,
          [addLabel]: {
            [defaultCurrency]: addLabelValue,
          },
        },
        (value) => {
          setUpdating(value);
          setLoading && setLoading(value);
        }
        // callback,
        // setToast,
      )
    );
    onAddExtraCharge(addLabel, addLabelValue);
    setAddLabelError("");
    setAddFormOpen(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Card>
      <CardContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"flex-start"}
          >
            {/* <Label style={{ margin: "0px 8px 0px 0px" }}>Labour</Label> */}
            <Label style={{ margin: "0px 8px 0px 0px" }}>Extra Charges</Label>
            <Typography variant="h6" mr={2}>
              {currencySymbols[defaultCurrency] || ""} {totalCharges || 0}
            </Typography>
          </Stack>

          <Stack direction={"row"} sx={{ gap: "5px" }}>
            {/* <FormControl>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                sx={{ minWidth: "80px" }}
                // value={age}
                label="Age"
                // onChange={handleChange}
              >
                {charges.map((i) => {
                  return (
                    <MenuItem
                      value={i.value}
                      onClick={() => onAddExtraCharge(i.label, i.value)}
                    >
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            <Button
              size="small"
              variant="outlined"
              onClick={showAnchor}
              // onClick={openAddForm}
              // endIcon={<AddIcon />}
            >
              Select
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={closeAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <List>
                {charges.map((i) => {
                  return (
                    <ListItem
                      onClick={() => onAddExtraCharge(i.label, i.value)}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText primary={i.label} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
            <Button
              size="small"
              variant="outlined"
              // onClick={showAnchor}
              onClick={openAddForm}
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={3} mt={1.3}>
          {isArrayWithValues(extra_charges) &&
            extra_charges.map((i) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography mr={2}>
                    {formatServerValue(`${i.extra_charge_label}`)}:{" "}
                  </Typography>
                  <Stack direction={"row"} alignItems="center">
                    <TextField
                      value={i.extra_charge_value}
                      onChange={(e) =>
                        onExtraChargeChange(
                          i.extra_charge_label,
                          e.target.value
                        )
                      }
                      type="number"
                      size="small"
                      sx={{ fontSize: "13px", fontWeight: "500" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbols[defaultCurrency]
                              ? currencySymbols[defaultCurrency]
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <IconButton
                      onClick={() => onRemoveExtraCharges(i.extra_charge_label)}
                      color="error"
                    >
                      <HighlightOffOutlined />
                    </IconButton>
                  </Stack>
                </Grid>
              );
            })}
        </Grid>
        {isArrayWithValues(extra_charges) && false && (
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={show_extra_charges_separately}
                  onChange={(e) =>
                    onEditProduct({
                      show_extra_charges_separately: e.target.checked,
                    })
                  }
                />
              }
              label="Show Extra Charges Separately In Product Price Breakup"
            />
          </FormGroup>
        )}
      </CardContent>
      <Dialog
        open={addFormOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeAddForm}
        {...globalPopupStyle}
      >
        <DialogTitle>Add New</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            value={addLabel}
            onChange={onAddLabelChange}
            autoFocus
            margin="dense"
            id="name"
            label="New Label"
            fullWidth
            variant="standard"
            error={Boolean(addLabelError)}
            helperText={addLabelError}
          />
          <TextField
            value={addLabelValue}
            onChange={(e) => {
              setAddLabelValue(e.target.value);
            }}
            // autoFocus
            margin="dense"
            id="name"
            label="Value"
            fullWidth
            type="number"
            variant="standard"
            // error={Boolean(addLabelError)}
            helperText={addLabelError}
          />
          <DialogActions>
            <Button onClick={closeAddForm}>Cancel</Button>
            <Button onClick={onAddLabel} disabled={!Boolean(addLabel)}>
              Add
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

const ProductFilters = (props) => {
  let { product, onEditProduct, taxonomies } = props;
  let [selectedTags, setSelectedTags] = useState([]);
  let [selectedOcassions, setSelectedOcassions] = useState([]);
  let [selectedGenders, setSelectedGenders] = useState([]);
  let [allGenders, setAllGenders] = useState([]);
  let [allOcassions, setAllOcassions] = useState([]);
  let [allTags, setAllTags] = useState([]);
  useEffect(() => {
    if (isObjWithValues(taxonomies) && Array.isArray(taxonomies.gender)) {
      setAllGenders(taxonomies.gender);
    }
    if (isObjWithValues(taxonomies) && Array.isArray(taxonomies.ocassions)) {
      setAllOcassions(taxonomies.ocassions);
    }
    if (isObjWithValues(taxonomies) && Array.isArray(taxonomies.tags)) {
      setAllTags(taxonomies.tags);
    }
  }, [taxonomies]);
  useEffect(() => {
    let { selectedTags } = product;
    if (Array.isArray(selectedTags)) setSelectedTags(selectedTags);
  }, [product.selectedTags]);
  useEffect(() => {
    let { selectedOcassions } = product;
    if (Array.isArray(selectedOcassions))
      setSelectedOcassions(selectedOcassions);
  }, [product.selectedOcassions]);
  useEffect(() => {
    let { selectedGenders } = product;
    if (Array.isArray(selectedGenders)) setSelectedGenders(selectedGenders);
  }, [product.selectedGenders]);

  const onGenderChange = (e) => {
    let { value, checked } = e.target;
    let array = selectedGenders;
    if (Array.isArray(array))
      if (checked) array.push(value);
      else array = array.filter((i) => i !== value);
    onEditProduct({ selectedGenders: array });
  };

  return (
    // <Card>
    //   <CardContent>
    //     <Label>Product Filter</Label>
    //     <Divider />
    <>
      <Label style={{ fontSize: 13, marginBottom: 0 }}>Gender</Label>
      <div style={{ marginLeft: "10px" }}>
        {allGenders.length > 0
          ? allGenders
              .filter((i) => i.value && !i.value.includes("jewellery"))
              .map((obj) => (
                <FormControlLabel
                  value={obj.value || ""}
                  checked={selectedGenders.indexOf(obj.value) > -1}
                  control={<Checkbox />}
                  label={obj.label || ""}
                  labelPlacement="end"
                  onChange={onGenderChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              ))
          : null}
      </div>
      <div style={{ marginTop: 10 }}>
        <Autocomplete
          multiple
          // disablePortal
          openOnFocus
          disableCloseOnSelect
          filterSelectedOptions
          value={selectedOcassions}
          id="combo-box-demo"
          getOptionLabel={(option) => option.label || ""}
          options={allOcassions}
          // sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Ocassions" />}
          onChange={(e, value) => onEditProduct({ selectedOcassions: value })}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <Autocomplete
          multiple
          disablePortal
          disableCloseOnSelect
          filterSelectedOptions
          openOnFocus
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          // getOptionLabel={(option) => option.label || ""}
          filterOptions={(options, params) => {
            const filtered = muiDropdownFilter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                value: inputValue,
                label: `Add Product Tag "${inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.label || "";
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.label || ""}</li>
          )}
          id="combo-box-demo"
          options={allTags}
          value={selectedTags}
          // sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Product tags" />
          )}
          onChange={(e, value) => {
            // let valArr = value.map(obj =>
            //   // if()
            //   typeof obj === "string" ? {} : obj
            // )

            onEditProduct({ selectedTags: value });
          }}
        />
      </div>
      {/* </CardContent>
    </Card> */}
    </>
  );
};

const ProductDescription = (props) => {
  let { onEditProduct, product } = props;
  let { description } = product;

  return (
    <Card mb={6}>
      <CardContent>
        <Label>Product description</Label>
        <Box mt={3}>
          <QuillWrapper>
            <ReactQuill
              style={{
                border: description.length > 300 && "1px solid #FF0000",
              }}
              theme="snow"
              value={description}
              // onChange={setValue}
              placeholder="Type something.."
              onChange={(e) => onEditProduct({ description: e })}
            />
            <FormHelperText
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <sapn style={{ color: "#FF0000" }}>
                {description.length > 300
                  ? "Description max is 300 letter"
                  : ""}
              </sapn>{" "}
              <span>{`${description.length} / 300`}</span>
            </FormHelperText>
          </QuillWrapper>
        </Box>
      </CardContent>
    </Card>
  );
};
let allTabs = [
  { label: "Additional Options", value: "additional_product" },
  { label: "Matching Products", value: "matching_product" },
  { label: "Seo", value: "seo" },
  { label: "Analytics", value: "analytics" },
];
const tabsByProduct = {
  catalog: ["additional_product", "seo", "analytics"],
};
const AdditionalOptions = (props) => {
  let {
    product,
    onEditProduct,
    defaultCurrency,
    isEdit,
    handlePopoverClose,
    currentTax,
    priceBreakup,
    getTotalWeight,
    getTotalPieces,
    getExtraCharges,
    productTotalPrice,
    productSettings,
  } = props;
  let {
    date_on_sale_from,
    date_on_sale_to,
    price,
    discountOnProduct,
    keywordsArray,
    custom_discounts,
    seo_title,
    seo_description,
    seo_keywords,
    manual_price,
  } = product;

  const [selectedUpSell, setSelectedUpSell] = useState([]);
  const [upSellSearch, setUpSellSearch] = useState("");
  const [selectedCrossSell, setSelectedCrossSell] = useState([]);
  const [value, setValue] = React.useState("additional_product");
  const [upSellProducts, setUpSellProducts] = useState([]);
  const [crossSellProducts, setCrossSellProducts] = useState([]);
  // const [] = useState([]);
  const [upSellFetching, setUpSellFetching] = useState(false);
  const [crossSellFetching, setCrossSellFetching] = useState(false);
  const [discountsArray, setDiscountsArray] = useState(checkBoxArray);
  const [inputValue, setInputValue] = React.useState("");
  const [discountObj, setDiscountObj] = useState({});
  const [tabs, setTabs] = useState([]);
  const theme = useTheme();

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const all_products_access = useSelector(
    (state) => state.user?.store_plan?.all_products_access
  );

  useEffect(() => {
    let tabs = [...allTabs];
    if (
      isArrayWithValues(user_products) &&
      user_products?.length === 1 &&
      user_products?.includes("catalog")
    ) {
      tabs = tabs.filter((i) => i.value !== "matching_products");
    }
    setTabs(tabs);
  }, [user_products]);

  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isObjWithValues(custom_discounts)) {
      setDiscountObj(custom_discounts || {});
      let keys = Object.keys(custom_discounts || {});
      for (let key of keys) {
        let obj = checkBoxArray.find((i) => i.value == key);
        if (obj) {
          setDiscountsArray((state) =>
            state.map((i) => {
              if (i.value === key)
                return {
                  ...i,
                  enable: true,
                  type: custom_discounts[key]?.type,
                };
              else return i;
            })
          );
        }
      }
    }
  }, [custom_discounts]);
  useEffect(() => {
    onEditProduct({ custom_discounts: discountObj });
  }, [discountObj]);

  useEffect(() => {
    let { selectedUpSell, selectedCrossSell } = product;
    if (Array.isArray(selectedUpSell)) setSelectedUpSell(selectedUpSell);
    if (Array.isArray(selectedCrossSell))
      setSelectedCrossSell(selectedCrossSell);
  }, [product.selectedUpSell, product.selectedCrossSell]);

  let upsellTimeout, crossSellTimeout;
  const onUpSellChange = (value, setState) => {
    setUpSellSearch(value);
    if (!value || (value && value.length < 2)) return;
    if (upsellTimeout) clearTimeout(upsellTimeout);
    upsellTimeout = setTimeout(async () => {
      setUpSellFetching(true);
      await getUpsellProducts(value, setState);
      setUpSellFetching(false);
    }, 300);
  };
  const onCrossSellChange = (value, setState) => {
    if (!value || (value && value.length < 2)) return;
    if (crossSellTimeout) clearTimeout(crossSellTimeout);
    crossSellTimeout = setTimeout(async () => {
      setCrossSellFetching(true);
      await getUpsellProducts(value, setState);
      setCrossSellFetching(false);
    }, 500);
  };

  const updateUpSellProducts = (products) => {
    if (products && Array.isArray(products)) {
      let array = [];
      for (let i = 0; i < products.length; i++) {
        // if (products[i].sku) array.push(products[i].sku);
        let imageUrl = "";
        if (isArrayWithValues(products[i].images)) {
          let { images } = products[i];
          imageUrl = images[0].src;
        }
        array.push({
          label: products[i].sku,
          value: products[i].id,
          imageUrl,
        });
      }
      setUpSellProducts(array);
    }
  };
  const updateCrossSellProducts = (products) => {
    if (products && Array.isArray(products)) {
      let array = [];
      for (let i = 0; i < products.length; i++) {
        // if (products[i].sku) array.push(products[i].sku);let imageUrl = "";
        let imageUrl = "";
        if (isArrayWithValues(products[i].images)) {
          let { images } = products[i];
          imageUrl = images[0].src;
        }
        array.push({
          label: products[i].name,
          value: products[i].id,
          imageUrl,
        });
      }
      setCrossSellProducts(array);
    }
  };

  const onDiscountPress = (checkbox) => {
    setDiscountsArray((pre) =>
      pre.map((i) =>
        i.label === checkbox.label ? { ...i, enable: !checkbox.enable } : i
      )
    );
    let discountObjCopy = { ...discountObj };
    if (checkbox?.enable) {
      delete discountObjCopy[checkbox?.value];
      setDiscountObj({ ...discountObjCopy });
    }
  };

  const onDiscountOnProductPress = (value) => {
    onEditProduct({ discountOnProduct: value });
    if (value)
      setDiscountsArray((state) => state.map((i) => ({ ...i, enable: false })));
  };

  let website = getWebsite();

  return (
    <Card>
      <CardContent>
        <Label>Additional Options</Label>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{ borderWidth: 0 }}
              disableRipple
              // ScrollButtonComponent={null}
              TabIndicatorProps={{
                style: { height: 0, borderWidth: 0 },
              }}
              variant="scrollable"

              // TabIndicatorProps={{}}
            >
              {tabs?.map((i) => {
                return (
                  <Tab label={i.label} {...a11yProps(0)} value={i.value} />
                );
              })}
              {/* <Tab
                label="Additional Options"
                {...a11yProps(0)}
                value="additional_product"
              />

              <Tab
                label="Matching Products"
                {...a11yProps(1)}
                value="matching_product"
              />
              <Tab label="SEO" {...a11yProps(2)} value="seo" />
              {isEdit && (
                <Tab label="Analytics" {...a11yProps(3)} value="analytics" />
              )} */}
              {/* <Tab label="Profit Margin" {...a11yProps(4)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={"additional_product"}>
            <div style={{ marginTop: 10 }}>
              <Box>
                <Typography variant="h6" sx={{ marginBottom: 4 }}>
                  Manual Product Price
                </Typography>

                <TextField
                  label="Price"
                  value={manual_price}
                  onChange={(e) => {
                    onEditProduct({ manual_price: e.target.value });
                  }}
                  FormHelperTextProps={{ sx: { m: 0, mt: 1 } }}
                  type="number"
                  helperText={
                    <Stack direction="row" alignItems={"center"} spacing={1}>
                      <ErrorOutline
                        sx={{
                          color: "#FF0000",
                          height: "14px",
                          width: "14px",
                          margin: "auto",
                        }}
                      />{" "}
                      <Typography
                        sx={{
                          color: "#FF0000",
                          fontSize: "12px",
                        }}
                      >
                        Price added here will override automatic pricing
                      </Typography>
                    </Stack>
                  }
                />
                <Divider sx={{ my: 3 }} />

                <Typography variant="h6">
                  Promotion & Offers : Discounts / Sale
                </Typography>
                {!productSettings.hide_pricing ? (
                  <Stack
                    sx={{ marginTop: "20px", gap: "10px" }}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography>On Product Value</Typography>
                    <Switch
                      onChange={(e) => {
                        onDiscountOnProductPress(e);
                      }}
                      checked={discountOnProduct}
                      style={{
                        border: `1px solid ${
                          discountOnProduct ? backgroundColor : "#e8e8e8"
                        }`,
                        backgroundColor: discountOnProduct
                          ? backgroundColor
                          : "#e8e8e8",
                      }}
                      disabled={
                        productSettings.hide_pricing ||
                        validateNumber(product.manual_price)
                      }
                    />
                  </Stack>
                ) : null}

                {!discountOnProduct && (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    flexWrap="wrap"
                    sx={{ marginTop: "10px" }}
                  >
                    {discountsArray
                      .slice(1, discountsArray.length)
                      .map((checkbox) => {
                        return (
                          <PurityCard
                            isMobile={true}
                            key={checkbox.label}
                            obj={checkbox}
                            isSelected={checkbox.enable}
                            onClick={() => onDiscountPress(checkbox)}
                          />
                        );
                      })}
                  </Stack>
                )}
                {!discountOnProduct && (
                  <Grid
                    container
                    spacing={3}
                    rowSpacing={3}
                    sx={{ marginTop: "10px" }}
                  >
                    {discountsArray
                      .slice(1, discountsArray.length)
                      .map((item) => {
                        if (item.enable)
                          return (
                            <Grid item lg={4} xs={12}>
                              <DiscountCard
                                onEditProduct={onEditProduct}
                                setDiscountObj={setDiscountObj}
                                discountObj={discountObj}
                                currency={currencySymbols[defaultCurrency]}
                                obj={item}
                                setDiscountsArray={setDiscountsArray}
                              />
                              <Typography mx={2}>-</Typography>
                            </Grid>
                          );
                      })}
                  </Grid>
                )}
                {discountOnProduct && (
                  <Grid
                    container
                    spacing={3}
                    rowSpacing={3}
                    sx={{ marginTop: "10px" }}
                  >
                    {discountsArray.slice(0, 1).map((item) => {
                      return (
                        <Grid item lg={4} xs={12}>
                          <DiscountCard
                            onEditProduct={onEditProduct}
                            setDiscountObj={setDiscountObj}
                            discountObj={discountObj}
                            currency={currencySymbols[defaultCurrency]}
                            obj={item}
                            setDiscountsArray={setDiscountsArray}
                          />
                          <Typography mx={2}>-</Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}

                <Stack
                  sx={{ marginTop: "20px", gap: "10px" }}
                  direction="row"
                  alignItems="center"
                >
                  <Typography>SCHEDULE SALE / DISCOUNT</Typography>
                  <Switch
                    onChange={(e) => {
                      // setShowSale(e);
                      onEditProduct({ showDiscount: e });
                    }}
                    checked={product.showDiscount}
                    style={{
                      border: `1px solid ${
                        product.showDiscount ? backgroundColor : "#e8e8e8"
                      }`,
                      backgroundColor: product.showDiscount
                        ? backgroundColor
                        : "#e8e8e8",
                    }}
                  />
                </Stack>
                <Collapse in={product.showDiscount}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                      direction={"row"}
                      sx={{ gap: "2rem", marginTop: "20px" }}
                    >
                      <DateTimePicker
                        label="Sale start on"
                        value={date_on_sale_from}
                        onChange={(newValue) => {
                          onEditProduct({
                            date_on_sale_from: newValue,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDateTime={new Date()}
                      />
                      <DateTimePicker
                        label="Sale End on"
                        value={date_on_sale_to}
                        onChange={(newValue) => {
                          onEditProduct({
                            date_on_sale_to: newValue,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Collapse>
              </Box>
            </div>
          </TabPanel>

          <TabPanel value={value} index={"matching_product"}>
            <div style={{ marginTop: 10 }}>
              <Grid container spacing={3} style={{ alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    // disablePortal
                    openOnFocus
                    id="combo-box-demo"
                    options={upSellProducts}
                    // sx={{ width: 300 }}
                    noOptionsText={
                      upSellSearch.length > 3
                        ? !upSellFetching
                          ? "Didn't found any products!"
                          : "Loading..."
                        : "Type to Search"
                    }
                    loading={upSellFetching}
                    value={selectedUpSell}
                    onChange={(e, value) =>
                      onEditProduct({ selectedUpSell: value })
                    }
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="UP SELL"
                        onChange={(e) =>
                          onUpSellChange(e.target.value, updateUpSellProducts)
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={
                            option.imageUrl ||
                            "/static/img/woocommerce_placeholder.png"
                          }
                          // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          // alt={option.label}
                        />
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Alert
                    variant="outlined"
                    sx={{ borderWidth: 0 }}
                    severity="info"
                  >
                    Upsells are products which you recommend instead of the
                    currently viewed product, for example, products that are
                    more profitable or better quality or more expensive.
                  </Alert>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 10 }}>
              <Grid container spacing={3} style={{ alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    // disablePortal
                    openOnFocus
                    id="combo-box-demo"
                    options={crossSellProducts}
                    loading={crossSellFetching}
                    value={selectedCrossSell}
                    noOptionsText={
                      upSellSearch.length > 3
                        ? !upSellFetching
                          ? "Didn't found any products!"
                          : "Loading..."
                        : "Type to Search"
                    }
                    // sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="CROSS SELL"
                        onChange={(e) =>
                          onCrossSellChange(
                            e.target.value,
                            updateCrossSellProducts
                          )
                        }
                      />
                    )}
                    onChange={(e, value) =>
                      onEditProduct({ selectedCrossSell: value })
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={
                            option.imageUrl ||
                            "/static/img/woocommerce_placeholder.png"
                          }
                          // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          // alt={option.label}
                        />
                        {option.label}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Alert
                    variant="outlined"
                    sx={{ borderWidth: 0 }}
                    severity="info"
                  >
                    Cross-sells are products which you promote in the cart,
                    based on the current product.
                  </Alert>
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          <TabPanel value={value} index={"seo"}>
            <Box>
              <Grid container spacing={4} sx={{ marginTop: "10px" }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    // size="small"
                    fullWidth
                    value={seo_title}
                    onChange={(e) => {
                      onEditProduct({ seo_title: e.target.value });
                      // setSeo((pre) => ({ ...pre, seo_title: e.target.value }));
                    }}
                    label="Title"
                    defaultValue="Products"
                    helperText="Include your focus keywords. 50-65 characters recommended."
                    sx={{
                      "	.MuiFormHelperText-root": {
                        marginLeft: 0,
                      },
                      marginBottom: "20px",
                    }}
                  />
                  <TextField
                    // size="small"
                    fullWidth
                    label="Description"
                    value={seo_description}
                    onChange={(e) => {
                      onEditProduct({ seo_description: e.target.value });
                    }}
                    multiline
                    rows={4}
                    helperText="Recommended minimum of 135 characters, maximum 300."
                    sx={{
                      "	.MuiFormHelperText-root": {
                        marginLeft: 0,
                      },
                      marginBottom: "20px",
                    }}
                  />
                  <Autocomplete
                    options={[]}
                    value={seo_keywords || []}
                    // inputValue={inputValue}
                    multiple
                    freeSolo
                    onChange={(e, newValue) => {
                      onEditProduct({ seo_keywords: newValue });
                    }}
                    // inputValue={inputValue}
                    // onInputChange={(e, newValue) => {
                    //   inputValue(pre => e.target.value)
                    //   let oldArr = seo_keywords || []
                    //   if (newValue.includes(",")) {
                    //     onEditProduct({ seo_keywords: [...oldArr, newValue.replace(",", "")] })
                    //     setInputValue("")
                    //   }
                    // }}
                    // onInputChange={(event, newInputValue) => {
                    //   const options = newInputValue.split(",");
                    //   if (options.length > 1) {
                    //     onEditProduct({
                    //       seo_keywords: seo_keywords
                    //         .concat(options)
                    //         .map(x => x.trim())
                    //         .filter(x => x)
                    //     })
                    //     // setValue(
                    //     //   seo_keywords
                    //     //     .concat(options)
                    //     //     .map(x => x.trim())
                    //     //     .filter(x => x)
                    //     // );
                    //   }
                    //   else {
                    //     setInputValue(newInputValue);
                    //   }
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Keywords"
                        helperText="Tag should be separated by enter"
                        endAdornment={
                          <InputAdornment position="end">
                            <SubdirectoryArrowLeft />{" "}
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Google Preview</Typography>
                  <Box
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      padding: "20px 20px",
                      // margin: "10px 0",
                      // width: "80%",
                      borderRadius: "6px",
                      mt: 2,
                    }}
                  >
                    <Stack direction="row" alignItems={"center"}>
                      <Typography variant="h6" fontSize="13px" mr={1}>
                        {website}
                      </Typography>
                      <Typography>
                        {isArrayWithValues(product.categories)
                          ? ` › ${product.categories[0].label}`
                          : " › Products"}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#1a0dab",
                        fontSize: "20px",
                        mt: 1,
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {seo_title}
                    </Typography>
                    <Typography sx={{ color: "text.secondary", mt: 1 }}>
                      {seo_description}
                      {/* <Link href="#"></Link> */}
                      {/* Link */}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={"analytics"}>
            <ProductAnalytics />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6.5}>
                <ProfitMargin product={product} onChange={onEditProduct} />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <ProductPriceBreakup
                  onMouseLeave={handlePopoverClose}
                  product={product}
                  currentTax={currentTax}
                  priceBreakup={priceBreakup}
                  defaultCurrency={defaultCurrency}
                  getTotalWeight={getTotalWeight}
                  getTotalPieces={getTotalPieces}
                  getExtraCharges={getExtraCharges}
                  productTotalPrice={productTotalPrice}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};

const ProductAnalytics = () => {
  const [dateRanges, setDateRanges] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });
  const [selectedAccount, setSelectedAccount] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (reduxGoogleIntegration && isObjWithValues(reduxGoogleIntegration)) {
      for (let obj of Object.values(reduxGoogleIntegration)) {
        let { connected, google_analytics } = obj;
        if (
          isArrayWithValues(connected) &&
          connected.includes("google_analytics")
        ) {
          if (isArrayWithValues(google_analytics)) {
            let idObj = google_analytics.find((i) => i.selected_analytics_id);
            if (idObj) setSelectedAccount(idObj.selected_analytics_id);
          }
        }
      }
    }
  }, [reduxGoogleIntegration]);

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="flex-end"
        spacing={3}
      >
        {/* <CircularProgress
          style={{
            height: "16px",
            width: "16px",
            // display: fetchingAnalytics ? "block" : "none",
          }}
        /> */}

        <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
          <TextField
            size="small"
            label="Date"
            value={
              formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd") ==
              formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd")
                ? `${formatDate(
                    new Date(dateRanges.startDate),
                    "relative_date"
                  )}`
                : `${formatDate(
                    new Date(dateRanges.startDate),
                    "relative_date"
                  )} - ${formatDate(
                    new Date(dateRanges.endDate),
                    "relative_date"
                  )}`
            }
            sx={{
              cursor: "pointer",
              pointerEvents: "none",
              minWidth:
                formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd") ===
                formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd")
                  ? "200px"
                  : "270px",
              transition: "all 0.3s",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DatePicker
            dateRanges={dateRanges}
            setDateRanges={setDateRanges}
            onClose={handleClose}
          />
        </Popover>
      </Stack>
      <TopCards
        selectedAccount={selectedAccount}
        dateRanges={dateRanges}
        url={getWebsite()}
        // url="https://tiarabytj.com/product/gold-jewellery-bracelet-22-kt-yellow-gold-4/"
      />
    </Box>
  );
};

const TopCards = ({ selectedAccount, dateRanges, url }) => {
  const [data, setData] = useState({
    browser: 0,
    city: 0,
    country: 0,
    deviceCategory: 0,
    pageReferrer: 0,
    operatingSystem: 0,
  });
  const [fetchingAnalytics, setFetchingAnalytics] = useState(false);

  useEffect(() => {
    if (selectedAccount) getTopAnalytics();
  }, [dateRanges, selectedAccount]);

  const getTopAnalytics = async () => {
    setFetchingAnalytics(true);
    let payload = {
      endpoint: `/properties/${selectedAccount}:runReport`,
      payload: {
        dimensions: [
          { name: "browser" },
          { name: "city" },
          { name: "country" },
          { name: "deviceCategory" },
          { name: "pageReferrer" },
          { name: "operatingSystem" },
        ],
        metrics: [{ name: "screenPageViews" }],
        dateRanges: [
          {
            startDate: formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd"),
            endDate: formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd"),
          },
        ],
        limit: "1",
        dimensionFilter: {
          filter: {
            fieldName: "fullPageUrl",
            stringFilter: {
              matchType: "CONTAINS",
              value: url,
            },
          },
        },
      },
    };
    let response = await getAnalytics(payload);
    if (!isObjWithValues(response)) return setFetchingAnalytics(false);

    let { dimensionHeaders, rows } = response;
    let seriesObj = {};
    if (!isArrayWithValues(dimensionHeaders))
      return setFetchingAnalytics(false);
    // let seriesObj = dimensionHeaders.map((i) => ({ [i.name]: 0 }));
    let keys = dimensionHeaders.map((i) => i.name);
    if (
      isArrayWithValues(rows) &&
      isArrayWithValues(rows[0]?.dimensionValues)
    ) {
      let index = 0;
      for (let key of keys) {
        let value = rows[0]?.dimensionValues[index]?.value;
        if (key === "pageReferrer")
          value = value.replace(`${mainWebsite}/`, "/");
        seriesObj[key] = value;
        index++;
      }
    }
    setFetchingAnalytics(false);
    setData(seriesObj);
  };

  return (
    <Grid container spacing={3} sx={{ mt: 3 }}>
      {cardsData.map((i) => (
        <Grid item key={i.value} xs={4} md={3} lg={2}>
          <TopCard
            title={i.label}
            value={data[i.value]}
            fetching={fetchingAnalytics}
            fontSize="24px"
          />
        </Grid>
      ))}
    </Grid>
  );
};

const cardsData = [
  { label: "Top Country", value: "country" },
  { label: "Top City", value: "city" },
  { label: "Top Browser", value: "browser" },
  { label: "Top Device", value: "deviceCategory" },
  { label: "Top Referrer", value: "pageReferrer" },
  { label: "Top OS", value: "operatingSystem" },
];

const numberLabels = [
  "Total Visitors",
  "New Visitors",
  "Sessions",
  "Page Views",
  "Avarage Session",
  "Bounce Rate",
];
const FloatLabels = ["Avarage Session", "Bounce Rate"];

const TopCard = ({ title, value, fetching, fontSize }) => {
  return (
    <Card sx={{}}>
      <CardContent>
        <Typography
          sx={{ fontSize: "15px", fontWeight: "500", color: "text.secondary" }}
        >
          {title}
        </Typography>
        {numberLabels.includes(title) && value && !fetching ? (
          <CountUp
            end={value}
            duration={2}
            decimals={FloatLabels.includes(title) ? 2 : 0}
            style={{
              fontSize: fontSize || "34px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          />
        ) : (
          <Typography
            sx={{ fontSize: fontSize || "34px", fontWeight: "500", mt: 2 }}
          >
            {fetching ? "-" : value}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const DatePicker = ({ dateRanges, setDateRanges, onClose }) => {
  const [tempDateRanges, setTempDateRanges] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  useEffect(() => {
    if (isObjWithValues(dateRanges)) setTempDateRanges(dateRanges);
  }, [dateRanges]);
  const onSubmit = () => {
    setDateRanges({
      startDate: new Date(
        tempDateRanges.startDate.setDate(tempDateRanges.startDate.getDate() + 1)
      ),
      endDate: new Date(
        tempDateRanges.endDate.setDate(tempDateRanges.endDate.getDate() + 1)
      ),
    });
    onClose();
  };
  return (
    <Box sx={{ p: 3 }}>
      <DateRangePicker
        ranges={[
          {
            ...tempDateRanges,
            key: "selection",
          },
        ]}
        onChange={({ selection }) => {
          setTempDateRanges((state) => ({
            endDate: selection ? selection.endDate : new Date(),
            startDate: selection ? selection.startDate : new Date(),
          }));
        }}
      />
      <Stack justifyContent={"flex-end"} direction="row" sx={{ mt: 3 }}>
        <Button onClick={onSubmit} sx={{ float: "right" }} variant="contained">
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

const DiscountCard = ({
  onEditProduct,
  obj,
  setDiscountsArray,
  currency,
  setDiscountObj,
  discountObj,
}) => {
  useEffect(() => {
    // onEditProduct({ ...discountObj });
    setDiscountObj((pre) => ({
      ...pre,
      [obj.value]: {
        ...discountObj[obj.value],
        type: obj.type,
        // value: "",
      },
    }));
  }, []);

  const onTypeChange = (type) => {
    setDiscountsArray((pre) =>
      pre.map((i) => (i.label === obj.label ? { ...i, type } : i))
    );
    setDiscountObj((pre) => ({
      ...pre,
      [obj.value]: {
        ...discountObj[obj.value],
        type: type,
      },
    }));
  };
  useEffect(() => {
    onEditProduct({ custom_discounts: discountObj });
  }, [discountObj]);

  const valueEdit = (e) => {
    setDiscountObj((pre) => ({
      ...pre,
      [obj.value]: {
        ...discountObj[obj.value],
        value: e.target.value,
      },
    }));
  };
  return (
    <Card
      style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Stack sx={{ rowGap: "10px" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {obj.label === "On Product Value" ? "On Sale" : obj.label}
            </Typography>
            <ButtonGroup aria-label="outlined button group" size="small">
              <Button
                sx={{ fontSize: "10px" }}
                variant={
                  obj.type === "by_percentage" ? "contained" : "outlined"
                }
                onClick={() => {
                  onTypeChange("by_percentage");
                }}
              >
                By Percentage
              </Button>
              <Button
                sx={{ fontSize: "10px" }}
                variant={obj.type === "by_amount" ? "contained" : "outlined"}
                onClick={() => {
                  onTypeChange("by_amount");
                }}
              >
                By Amount
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <TextField
              size="small"
              fullWidth
              value={discountObj[obj.value]?.value}
              onChange={valueEdit}
              InputProps={{
                startAdornment:
                  obj.type === "by_amount" ? (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ) : null,
                endAdornment:
                  obj.type === "by_percentage" ? (
                    <InputAdornment position="end">%</InputAdornment>
                  ) : null,
              }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
//following are functions not component

const getLabourMasterPricing = (labourPricing, collections, categories) => {
  if (!isArrayWithValues(labourPricing)) return {};
  // let labourPricingArray = Object.values(labourPricing);
  let length = labourPricing.length;
  for (let i = 0; i < length; i++) {
    let { collections: allCollections } = labourPricing[i];
    if (
      isArrayWithValues(allCollections) &&
      collections.every((i) => allCollections.find((obj) => obj.label === i))
    ) {
      let { categories: allCategories } = labourPricing[i];
      if (
        isArrayWithValues(allCategories) &&
        allCategories.find((obj) => obj.label === categories[0])
        // categories.every((i) => allCategories.find((obj) => obj.label === i))
      )
        return labourPricing[i];
    }
  }
  return {};
};
export const getAllLabourMasterPricing = (
  labourPricing,
  collections = [],
  categories = [],
  subcategories = [],
  dontCheckSubcategories,
  allowEmptyCategories
) => {
  if (!isArrayWithValues(labourPricing)) return {};
  // let labourPricingArray = Object.values(labourPricing);
  let allLabourMasterPricing = [];
  let length = labourPricing.length;
  for (let i = 0; i < length; i++) {
    let {
      collections: allCollections,
      categories: allCategories,
      subcategories: allSubCategories,
    } = labourPricing[i];
    if (
      isArrayWithValues(allCollections) &&
      collections?.some((i) => allCollections.find((obj) => obj.label === i))
    )
      if (
        (isArrayWithValues(allCategories) &&
          allCategories?.find((obj) => obj.label === categories?.[0])) ||
        allowEmptyCategories
        // categories.every((i) => allCategories.find((obj) => obj.label === i))
      )
        if (
          !isArrayWithValues(allSubCategories) &&
          !isArrayWithValues(subcategories)
        )
          // if (!isArrayWithValues(allSubCategories))
          allLabourMasterPricing.push(labourPricing[i]);
        else if (
          isArrayWithValues(allSubCategories) &&
          allSubCategories?.find((obj) => obj.label === subcategories?.[0])
        )
          allLabourMasterPricing.push(labourPricing[i]);
        else if (dontCheckSubcategories)
          allLabourMasterPricing.push(labourPricing[i]);
  }
  if (!isArrayWithValues(allLabourMasterPricing) && !dontCheckSubcategories)
    allLabourMasterPricing = getAllLabourMasterPricing(
      labourPricing,
      collections,
      categories,
      subcategories,
      true
    );
  if (!isArrayWithValues(allLabourMasterPricing) && !allowEmptyCategories)
    allLabourMasterPricing = getAllLabourMasterPricing(
      labourPricing,
      collections,
      categories,
      subcategories,
      true,
      true
    );
  return allLabourMasterPricing;
};

export const getGemstoneQuality = (gemstonePricings) => {
  if (!isArrayWithValues(gemstonePricings)) return;
  let qualityDropdown = [];
  let length = gemstonePricings.length;
  for (let i = 0; i < length; i++) {
    let { qualities } = gemstonePricings[i];
    if (!qualities || (qualities && !qualities.length)) continue;
    for (let j = 0; j < qualities.length; j++) {
      if (qualityDropdown.find(({ value }) => value === qualities[j].value))
        continue;
      qualityDropdown.push(qualities[j]);
    }
  }
  return qualityDropdown;
  // setGemstoneQuality(qualityDropdown);
};

export const getGemstoneTypes = (gemstonePricings, quality) => {
  if (!isArrayWithValues(gemstonePricings)) return;
  let typesDropdown = [];
  let length = gemstonePricings.length;
  for (let i = 0; i < length; i++) {
    let { qualities } = gemstonePricings[i];
    if (!qualities || (qualities && !qualities.length)) continue;
    for (let j = 0; j < qualities.length; j++) {
      if (qualities.find((i) => i.value === quality)) {
        // if (gemstoneQuality[j] === quality) {
        let { types } = gemstonePricings[i];
        for (let k = 0; k < types.length; k++)
          if (!typesDropdown.find(({ value }) => value === types[k].value))
            typesDropdown.push(types[k]);
      }
    }
  }
  return typesDropdown;
};

export const getGemstoneShapes = (gemstonePricings, quality, type) => {
  if (!isArrayWithValues(gemstonePricings)) return;
  let shapesDropdown = [];
  let length = gemstonePricings.length;
  for (let i = 0; i < length; i++) {
    let { qualities } = gemstonePricings[i];
    if (!qualities || (qualities && !qualities.length)) continue;
    if (qualities.find((i) => i.value === quality)) {
      let { types } = gemstonePricings[i];
      if (types.find((i) => i.value === type)) {
        let { shapes } = gemstonePricings[i];
        for (let j = 0; j < shapes.length; j++)
          if (!shapesDropdown.find((i) => i.value === shapes[j].value))
            shapesDropdown.push(shapes[j]);
      }
    }
  }
  return shapesDropdown;
};

export const getGemstoneSizes = (gemstonePricings, quality, type, shape) => {
  if (!isArrayWithValues(gemstonePricings)) return;
  let sizesDropdown = [];
  let length = gemstonePricings.length;
  for (let i = 0; i < length; i++) {
    let { qualities } = gemstonePricings[i];
    if (!qualities || (qualities && !qualities.length)) continue;
    if (qualities.find((i) => i.value === quality)) {
      let { types } = gemstonePricings[i];
      if (types.find((i) => i.value === type)) {
        let { shapes } = gemstonePricings[i];
        if (shapes.find((i) => i.value === shape)) {
          let { sizes } = gemstonePricings[i];
          for (let j = 0; j < sizes.length; j++)
            if (!sizesDropdown.find((i) => i.value === sizes[j].value))
              sizesDropdown.push(sizes[j]);
        }
      }
    }
  }
  return sizesDropdown;
};

export const getGemstoneRate = (
  gemstonePricings,
  quality,
  type,
  shape,
  size
) => {
  if (!isArrayWithValues(gemstonePricings)) return;
  for (let i = 0; i < gemstonePricings.length; i++) {
    let { qualities, types, shapes, sizes } = gemstonePricings[i];
    if (qualities.find((i) => i.value === quality))
      if (types.find((i) => i.value === type))
        if (shapes.find((i) => i.value === shape))
          if (sizes.find((i) => i.value === size))
            return gemstonePricings[i].rate;
  }
  return "";
};

export const getDiamondTypes = (diamondPricings) => {
  if (!isArrayWithValues(diamondPricings)) return;
  let allTypes = [];

  for (let pricing of diamondPricings)
    if (isArrayWithValues(pricing.types))
      for (let type of pricing.types)
        if (!allTypes.find((i) => i.value == type.value)) allTypes.push(type);
  return allTypes;
};

export const getDiamondQuality = (diamondPricings, type) => {
  if (!isArrayWithValues(diamondPricings) || !type) return;
  let length = diamondPricings.length;
  let qualities = [];

  for (let pricing of diamondPricings) {
    let { types } = pricing;
    if (isArrayWithValues(types) && types.find((i) => i.value == type)) {
      let { clarities, colors } = pricing;
      clarities = clarities.map((i) => i.value);
      colors = colors.map((i) => i.value);

      for (let clarity of clarities) {
        for (let color of colors) {
          if (!qualities.find((obj) => obj.value === `${clarity}-${color}`))
            qualities.push({
              label: `${clarity}-${color}`,
              value: `${clarity}-${color}`,
            });
        }
      }
    }
  }

  return qualities;
};

export const getDiamondQualityWithoutType = (diamondPricings) => {
  if (!isArrayWithValues(diamondPricings)) return;
  let qualities = [];

  for (let pricing of diamondPricings) {
    let { types } = pricing;
    let { clarities, colors } = pricing;
    clarities = clarities?.map((i) => i.value);
    colors = colors?.map((i) => i.value) || [];

    for (let clarity of clarities) {
      for (let color of colors) {
        if (!qualities.find((obj) => obj.value === `${clarity}-${color}`))
          qualities.push({
            label: `${clarity}-${color}`,
            value: `${clarity}-${color}`,
          });
      }
    }
  }

  return qualities;
};

// export const getDiamondQuality = (diamondPricings) => {
//   if (!isArrayWithValues(diamondPricings)) return;
//   let length = diamondPricings.length;
//   let qualities = [];
//   for (let i = 0; i < length; i++) {
//     let { clarities, colors } = diamondPricings[i];
//     if (Array.isArray(clarities) && Array.isArray(colors)) {
//       clarities = clarities.map((i) => i.value);
//       colors = colors.map((i) => i.value);
//       for (let i = 0; i < clarities.length; i++) {
//         for (let j = 0; j < colors.length; j++) {
//           if (
//             !qualities.find(
//               (obj) => obj.value === `${clarities[i]}-${colors[j]}`
//             )
//           )
//             qualities.push({
//               label: `${clarities[i]}-${colors[j]}`,
//               value: `${clarities[i]}-${colors[j]}`,
//             });
//         }
//       }
//     }
//   }
//   return qualities;
// };

const combineClaritiesColors = (clarities, colors) => {
  if (Array.isArray(clarities) && Array.isArray(colors)) {
    clarities = clarities.map((i) => i.value);
    colors = colors.map((i) => i.value);
    let qualities = [];
    for (let i = 0; i < clarities.length; i++) {
      for (let j = 0; j < colors.length; j++) {
        qualities.push({
          label: `${clarities[i]}-${colors[j]}`,
          value: `${clarities[i]}-${colors[j]}`,
        });
      }
    }
    return qualities;
  }
  return [];
};

export const getDiamondShapes = (diamondPricings, type, quality) => {
  let clarity = quality.split("-")[0];
  let color = quality.split("-")[1];
  if (!isArrayWithValues(diamondPricings)) return;
  let shapesDropdown = [];
  // let diamondPricingsArray = Object.values(diamondPricings);
  let length = diamondPricings.length;
  for (let i = 0; i < length; i++) {
    let { clarities, types } = diamondPricings[i];
    if (
      !types ||
      (isArrayWithValues(types) && !types.find((i) => i.value == type))
    )
      continue;
    if (clarities.find((i) => i.value === clarity)) {
      let { colors } = diamondPricings[i];
      if (colors.find((i) => i.value === color)) {
        let { shapes } = diamondPricings[i];
        for (let j = 0; j < shapes.length; j++) {
          if (!shapesDropdown.find((i) => i.value === shapes[j].value))
            shapesDropdown.push(shapes[j]);
        }
      }
    }
  }
  return shapesDropdown;
};

// export const getDiamondShapes = (diamondPricings, quality) => {
//   let clarity = quality.split("-")[0];
//   let color = quality.split("-")[1];
//   if (!isArrayWithValues(diamondPricings)) return;
//   let shapesDropdown = [];
//   // let diamondPricingsArray = Object.values(diamondPricings);
//   let length = diamondPricings.length;
//   for (let i = 0; i < length; i++) {
//     let { clarities } = diamondPricings[i];
//     if (clarities.find((i) => i.value === clarity)) {
//       let { colors } = diamondPricings[i];
//       if (colors.find((i) => i.value === color)) {
//         let { shapes } = diamondPricings[i];
//         for (let j = 0; j < shapes.length; j++) {
//           if (!shapesDropdown.find((i) => i.value === shapes[j].value))
//             shapesDropdown.push(shapes[j]);
//         }
//       }
//     }
//   }
//   return shapesDropdown;
// };

export const getDiamondSizes = (diamondPricings, type, quality, shape) => {
  let clarity = quality.split("-")[0];
  let color = quality.split("-")[1];

  if (!isArrayWithValues(diamondPricings)) return;
  let sizesDropdown = [];
  let length = diamondPricings.length;
  for (let i = 0; i < length; i++) {
    let { clarities, types } = diamondPricings[i];
    if (
      !types ||
      (isArrayWithValues(types) && !types.find((i) => i.value == type))
    )
      continue;
    if (clarities.find((i) => i.value === clarity)) {
      let { colors } = diamondPricings[i];
      if (colors.find((i) => i.value === color)) {
        let { shapes } = diamondPricings[i];
        if (shapes.find((i) => i.value === shape)) {
          let { sieves } = diamondPricings[i];
          for (let j = 0; j < sieves.length; j++) {
            if (!sizesDropdown.find((i) => i.value === sieves[j].value))
              sizesDropdown.push(sieves[j]);
          }
        }
      }
    }
  }
  return sizesDropdown;
};

// export const getDiamondSizes = (diamondPricings, quality, shape) => {
//   let clarity = quality.split("-")[0];
//   let color = quality.split("-")[1];

//   if (!isArrayWithValues(diamondPricings)) return;
//   let sizesDropdown = [];
//   let length = diamondPricings.length;
//   for (let i = 0; i < length; i++) {
//     let { clarities } = diamondPricings[i];
//     if (clarities.find((i) => i.value === clarity)) {
//       let { colors } = diamondPricings[i];
//       if (colors.find((i) => i.value === color)) {
//         let { shapes } = diamondPricings[i];
//         if (shapes.find((i) => i.value === shape)) {
//           let { sieves } = diamondPricings[i];
//           for (let j = 0; j < sieves.length; j++) {
//             if (!sizesDropdown.find((i) => i.value === sieves[j].value))
//               sizesDropdown.push(sieves[j]);
//           }
//         }
//       }
//     }
//   }
//   return sizesDropdown;
// };

export const getDiamondCuts = (diamondPricings, type, quality, shape, size) => {
  let clarity = quality.split("-")[0];
  let color = quality.split("-")[1];

  if (!isArrayWithValues(diamondPricings)) return;
  let cutsDropdown = [];
  let length = diamondPricings.length;
  for (let i = 0; i < length; i++) {
    let { clarities, types } = diamondPricings[i];
    if (
      !types ||
      (isArrayWithValues(types) && !types.find((i) => i.value == type))
    )
      continue;
    if (clarities.find((i) => i.value === clarity)) {
      let { colors } = diamondPricings[i];
      if (colors.find((i) => i.value === color)) {
        let { shapes } = diamondPricings[i];
        if (shapes.find((i) => i.value === shape)) {
          let { sieves } = diamondPricings[i];
          if (sieves.find((i) => i.value === size)) {
            let { cuts } = diamondPricings[i];
            for (let j = 0; j < cuts?.length; j++)
              if (!cutsDropdown.find((i) => i.value === cuts?.[j].value))
                cutsDropdown.push(cuts[j]);
          }
        }
      }
    }
  }
  return cutsDropdown;
};

// export const getDiamondCuts = (diamondPricings, quality, shape, size) => {
//   let clarity = quality.split("-")[0];
//   let color = quality.split("-")[1];

//   if (!isArrayWithValues(diamondPricings)) return;
//   let cutsDropdown = [];
//   let length = diamondPricings.length;
//   for (let i = 0; i < length; i++) {
//     let { clarities } = diamondPricings[i];
//     if (clarities.find((i) => i.value === clarity)) {
//       let { colors } = diamondPricings[i];
//       if (colors.find((i) => i.value === color)) {
//         let { shapes } = diamondPricings[i];
//         if (shapes.find((i) => i.value === shape)) {
//           let { sieves } = diamondPricings[i];
//           if (sieves.find((i) => i.value === size)) {
//             let { cuts } = diamondPricings[i];
//             for (let j = 0; j < cuts.length; j++)
//               if (!cutsDropdown.find((i) => i.value === cuts[j].value))
//                 cutsDropdown.push(cuts[j]);
//           }
//         }
//       }
//     }
//   }
//   return cutsDropdown;
// };

export const getDiamondRate = (
  diamondPricings,
  type,
  quality,
  shape,
  size,
  cut
) => {
  if (!diamondPricings || !quality) return 0;
  let clarity = quality.split("-")[0];
  let color = quality.split("-")[1];

  if (!isArrayWithValues(diamondPricings)) return;
  let length = diamondPricings.length;
  for (let i = 0; i < length; i++) {
    let { types, clarities, colors, shapes, sieves, cuts } = diamondPricings[i];
    if (isArrayWithValues(types) && types.find((i) => i.value === type))
      if (clarities.find((i) => i.value === clarity)) {
        if (colors.find((i) => i.value === color)) {
          if (shapes.find((i) => i.value === shape)) {
            if (sieves.find((i) => i.value === size)) {
              // if (!cut && !isArrayWithValues(cuts))
              if (isArrayWithValues(cuts) && cuts.find((i) => i.value === cut))
                return diamondPricings[i].rate;
              if (!cut) return diamondPricings[i].rate;
            }
          }
        }
      }
  }
  return "";
};

// export const getDiamondRate = (diamondPricings, quality, shape, size, cut) => {
//   if (!diamondPricings || !quality) return 0;
//   let clarity = quality.split("-")[0];
//   let color = quality.split("-")[1];

//   if (!isArrayWithValues(diamondPricings)) return;
//   let length = diamondPricings.length;
//   for (let i = 0; i < length; i++) {
//     let { types, clarities, colors, shapes, sieves, cuts } = diamondPricings[i];
//     if (clarities.find((i) => i.value === clarity))
//       if (colors.find((i) => i.value === color))
//         if (shapes.find((i) => i.value === shape))
//           if (sieves.find((i) => i.value === size)) {
//             if (!cut && !isArrayWithValues(cuts))
//               return diamondPricings[i].rate;
//             if (cuts.find((i) => i.value === cut))
//               return diamondPricings[i].rate;
//           }
//   }
//   return "";
// };

// const getDiamondRate = (diamondPricings, quality, shape, size) => {
//   if (!isPricings(diamondPricings)) return;
//   let diamondPricingsArray = Object.values(diamondPricings);
//   let length = diamondPricingsArray.length;
//   //lopping diamonds details array
//   for (let i = length - 1; i >= 0; i--) {
//     let currentQuality = diamondPricingsArray[i].quality;
//     // looping through single details quality
//     if (currentQuality)
//       for (let j = 0; j < currentQuality.length; j++) {
//         if (currentQuality[j] === quality) {
//           let allShapes = diamondPricingsArray[i].shapes;
//           let shapesLength = allShapes.length;
//           //looping through single details shapes
//           for (let k = 0; k < shapesLength; k++) {
//             if (allShapes[k] === shape) {
//               if (!size) return diamondPricingsArray[i].rate;
//               let allSizes = diamondPricingsArray[i].sizes;
//               let sizeLength = allSizes.length;
//               if (!allSizes || sizeLength === 0)
//                 return diamondPricingsArray[i].rate;
//               //looping through sizes
//               for (let l = 0; l < sizeLength; l++) {
//                 if (allSizes[l] === size) {
//                   return diamondPricingsArray[i].rate;
//                 }
//               }
//             }
//           }
//         }
//       }
//   }
// };

export const getDiamondSizeLabel = (allGroups, id) =>
  allGroups ? (allGroups[id] ? allGroups[id].title : "") : "";

const getUpsellProducts = async (search, setState) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    let res = await axios({
      url: `${website}/wp-json/wc/v3/products`,
      params: { search, _fields: "id, name, sku, images" },
      headers: {
        Authorization: `Basic ${token}`,
      },
      // signal,
    });
    if (res.data) {
      setState(res.data);
      return true;
    }
  }
};

const setUpsellProducts = async (upSellIds, crossSellIds, setState) => {
  // set up and cross sell
  const controller = new AbortController();
  const signal = controller.signal;
  let allIds = [];
  if (isArrayWithValues(upSellIds)) allIds = allIds.concat(upSellIds);
  if (isArrayWithValues(crossSellIds)) allIds = allIds.concat(crossSellIds);
  if (allIds.length === 0) return;

  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products`,
        params: { include: allIds, _fields: "id, name, sku , images" },
        headers: {
          Authorization: `Basic ${token}`,
        },
        // signal,
      });
      if (res.data) {
        let { data } = res;
        if (isArrayWithValues(data)) {
          let selectedUpSell = [],
            selectedCrossSell = [];

          for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj.id = data[i].id;
            obj.label = data[i].sku;
            obj.value = data[i].id;
            if (isArrayWithValues(upSellIds) && upSellIds.includes(obj.id))
              selectedUpSell.push(obj);
            if (
              isArrayWithValues(crossSellIds) &&
              crossSellIds.includes(obj.id)
            )
              selectedCrossSell.push(obj);
          }
          setState({ selectedUpSell, selectedCrossSell });
        }
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};

const convertTaxonomies = (array) => {
  if (!isArrayWithValues(array)) return [];
  let length = array.length;
  let temp = [];
  for (let i = 0; i < length; i++) {
    let obj = array[i];
    let id = obj.id || obj.term_id;
    let label = obj.name || obj.label;
    let value = obj.slug || obj.value;
    temp.push({ id, label, value });
  }
  return temp;
};

const isPricings = (pricings) => {
  return pricings && Object.keys(pricings).length > 0;
};
const isObjWithValues = (obj) => {
  return obj && Object.keys(obj).length > 0;
};
const isArrayWithValues = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

const BulletText = ({ text }) => {
  return (
    <Stack direction={"row"} alignItems="center">
      <Box
        sx={{
          height: "3px",
          width: "3px",
          borderRadius: "2px",
          backgroundColor: "text.primary",
        }}
      />
      <Typography fontSize={"13px"} ml={1} fontWeight="400">
        {text}
      </Typography>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: "485px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    style: { height: 0 },
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const allMetalTypes = [
  { label: "Gold", value: "gold" },
  { label: "Silver", value: "silver" },
  { label: "Platinum", value: "platinium" },
  { label: "Multi", value: "multi" },
];
const allMetalColors = [
  { label: "White", value: "white" },
  { label: "Yellow", value: "yellow" },
  { label: "Rose", value: "rose" },
  { label: "Two Tone", value: "two-tone" },
];
const Label = styled(Typography)`
  margin: 10px 0px 6px 4px;
  font-size: 16px;
`;
// font-size: 15px;
const LabelSm = styled(Typography)`
  margin: 10px 0px 6px 4px;
  font-size: 12px;
`;
const LabelLg = styled(Typography)`
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
`;
const DropdownLabel = styled.span`
  margin: 6px 0px 14px 4px;
  font-weight: 13px;
`;

export const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const TableRoot = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    border-radius: 7px;
    overflow: hidden;
    /* border: 2px solid #ebebeb; */
  }

  td,
  th {
    text-align: left;
    padding: 8px 5px;
  }

  th {
    background-color: #f7f7f7;
    font-weight: 500;
  }
  td {
    font-size: 12px;
  }
`;

// function AddDiamond(props) {
//   const [diamondPricings, setDiamondPricings] = useState({});
//   const [diamondQuality, setDiamondQuality] = useState([]);
//   const [diamondShapes, setDiamondShapes] = useState([]);
//   const [diamondSizes, setDiamondSizes] = useState([]);
//   const [isEdit, setIsEdit] = useState(false);
//   const [selectedQuality, setSelectedQuality] = useState("");
//   const [selectedShape, setSelectedShape] = useState("");
//   const [selectedSize, setSelectedSize] = useState("");
//   const [pieces, setPieces] = useState("");
//   const [weight, setWeight] = useState("");
//   const [rate, setRate] = useState("");
//   const [price, setPrice] = useState("");
//   const [showDeleteAlert, setDeleteAlert] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const {
//     onClose,
//     selectedValue,
//     open,
//     masters,
//     onAdd,
//     toggleDialog,
//     editData,
//     onEdit,
//     onDelete,
//     productSettings,
//   } = props;
//   const location = useLocation();

//   const reduxProductDefaults = useSelector(
//     (state) => state.settings.productDefaults || {}
//   );

//   useEffect(() => {
//     let { selectedQuality } = reduxProductDefaults;
//     if (
//       isArrayWithValues(diamondQuality) &&
//       selectedQuality &&
//       location.pathname.includes("/products/add")
//     )
//       if (diamondQuality.find((i) => i.value === selectedQuality))
//         onQualityChange({ target: { value: selectedQuality } });
//   }, [diamondQuality]);

//   useEffect(() => {
//     let { selectedShape } = reduxProductDefaults;
//     if (
//       isArrayWithValues(diamondShapes) &&
//       selectedShape &&
//       location.pathname.includes("/products/add")
//     )
//       if (diamondShapes.find((i) => i.value === selectedShape))
//         onShapeChange({ target: { value: selectedShape } });
//   }, [diamondShapes]);

//   useEffect(() => {
//     let { selectedSize } = reduxProductDefaults;
//     if (
//       isArrayWithValues(diamondSizes) &&
//       selectedSize &&
//       location.pathname.includes("/products/add")
//     )
//       if (diamondSizes.find((i) => i.value === selectedSize))
//         onSizeChange({ target: { value: selectedSize } });
//   }, [diamondSizes]);

//   const handleClose = () => {
//     onClose(selectedValue);
//   };

//   useEffect(() => {
//     if (errorMessage) setErrorMessage("");
//   }, [weight, pieces]);

//   useEffect(() => {
//     if (masters && Object.keys(masters).length > 0) {
//       let { diamond_pricing } = masters;
//       setDiamondPricings(diamond_pricing);
//       setDiamondQuality(getDiamondQuality(diamond_pricing));
//     }
//   }, [masters]);

//   useEffect(() => {
//     if (rate && weight) onWeightChange(weight);
//   }, [rate]);

//   useEffect(() => {
//     if (isObjWithValues(editData)) setEditData(editData);
//     // else {
//     //   setSelectedQuality("");
//     //   setSelectedShape("");
//     //   setSelectedSize("");
//     //   setPieces("");
//     //   setWeight("");
//     //   setRate("");
//     //   setPrice("");
//     // }

//     if (isObjWithValues(editData)) setIsEdit(true);
//     else setIsEdit(false);
//   }, [editData]);

//   const setEditData = (editData) => {
//     let { diamond_quality, diamond_pieces, diamond_weight } = editData;
//     if (diamond_quality)
//       onQualityChange({ target: { value: diamond_quality } });
//     if (diamond_pieces) setPieces(diamond_pieces);
//     if (diamond_weight) setWeight(diamond_weight);
//   };

//   useEffect(() => {
//     if (isArrayWithValues(diamondShapes))
//       if (editData.diamond_shape)
//         onShapeChange({ target: { value: editData.diamond_shape } });
//   }, [diamondShapes]);
//   useEffect(() => {
//     if (isArrayWithValues(diamondSizes))
//       if (editData.diamond_sieve)
//         onSizeChange({ target: { value: editData.diamond_sieve } });
//   }, [diamondSizes]);

//   const onQualityChange = (e) => {
//     setSelectedQuality(e.target.value);
//     let shapes = getDiamondShapes(diamondPricings, e.target.value);
//     // if (isArrayWithValues(shapes) && shapes.find((i) => i.value === "round")) {
//     //   setSelectedShape("round");
//     // }
//     setDiamondShapes(shapes);
//   };
//   const onShapeChange = (e) => {
//     setSelectedShape(e.target.value);
//     setDiamondSizes(
//       getDiamondSizes(diamondPricings, selectedQuality, e.target.value)
//     );
//   };
//   const onSizeChange = async (e) => {
//     setSelectedSize(e.target.value);
//     setRate(
//       getDiamondRate(
//         diamondPricings,
//         selectedQuality,
//         selectedShape,
//         e.target.value
//       )
//     );
//   };

//   const onWeightChange = (value) => {
//     setWeight(value);
//     value = Number(value);
//     if (!isNaN(value) && !isNaN(Number(rate)))
//       setPrice(Math.round(rate * value));
//   };
//   const onSubmit = () => {
//     if (!pieces) return setErrorMessage("Please enter pieces");
//     if (!weight) return setErrorMessage("Please enter weight");
//     let obj = {};
//     obj.diamond_quality = selectedQuality;
//     obj.diamond_shape = selectedShape;
//     obj.diamond_sieve = selectedSize;
//     obj.diamond_pieces = pieces;
//     obj.diamond_weight = weight;
//     obj.diamond_rate = rate;
//     if (isEdit) onEdit(obj, editData.index);
//     else onAdd(obj);
//     toggleDialog(false);
//   };

//   return (
//     <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
//       <DialogContent>
//         <Stack
//           direction="row"
//           alignItems={"center"}
//           justifyContent={"space-between"}
//         >
//           <Label>{isEdit ? "Edit" : "Add"} Diamond</Label>
//           <IconButton onClick={() => toggleDialog(false)}>
//             <Close />
//           </IconButton>
//         </Stack>
//         <Collapse in={showDeleteAlert}>
//           <Alert
//             severity="error"
//             action={
//               <>
//                 <Button
//                   color="error"
//                   size="small"
//                   onClick={() => {
//                     onDelete(editData.index);
//                     setDeleteAlert(false);
//                   }}
//                 >
//                   DELETE
//                 </Button>
//                 <IconButton
//                   aria-label="close"
//                   color="inherit"
//                   size="small"
//                   onClick={() => {
//                     setDeleteAlert(false);
//                   }}
//                 >
//                   <Close fontSize="inherit" />
//                 </IconButton>
//               </>
//             }
//             onClose={() => setDeleteAlert(false)}
//           >
//             Delete this Diamond?
//           </Alert>
//         </Collapse>
//         <Collapse in={errorMessage ? true : false}>
//           <Alert
//             severity="error"
//             action={
//               <IconButton
//                 aria-label="close"
//                 color="inherit"
//                 size="small"
//                 onClick={() => {
//                   setErrorMessage("");
//                 }}
//               >
//                 <Close fontSize="inherit" />
//               </IconButton>
//             }
//             onClose={() => setDeleteAlert(false)}
//             style={{ marginTop: "7px" }}
//           >
//             {errorMessage}
//           </Alert>
//         </Collapse>
//         <Grid container spacing={3} mt={1}>
//           <Grid item xs={12} sm={6}>
//             <DropdownLabel>Diamond Quality</DropdownLabel>

//             <FormControl fullWidth size="small">
//               <Dropdown
//                 data={diamondQuality}
//                 onChange={onQualityChange}
//                 selected={selectedQuality}
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <DropdownLabel>Diamond Shape</DropdownLabel>
//             <FormControl fullWidth size="small">
//               <Dropdown
//                 // label="Gemstone Type"
//                 data={diamondShapes}
//                 onChange={onShapeChange}
//                 selected={selectedShape}
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <DropdownLabel>Diamond Size</DropdownLabel>
//             <FormControl fullWidth size="small">
//               <Dropdown
//                 data={diamondSizes}
//                 onChange={onSizeChange}
//                 selected={selectedSize}
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <DropdownLabel>Diamond Pieces</DropdownLabel>
//             <TextField
//               value={pieces}
//               onChange={(e) => setPieces(e.target.value)}
//               fullWidth
//               size="small"
//               id="outlined"
//               variant="outlined"
//               // label="Gemstone Pieces"
//               type="number"
//               inputProps={{ maxLength: 4 }}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <DropdownLabel>Diamond Weight in cts</DropdownLabel>
//             <TextField
//               value={weight}
//               onChange={(e) => onWeightChange(e.target.value)}
//               fullWidth
//               size="small"
//               id="outlined"
//               // label="Gemstone Weight in cts"
//               type="number"
//             />
//           </Grid>
//         </Grid>
//         <div style={{ marginTop: 8, display: "flex" }}>
//           <h4 style={{ margin: "0px 5px" }}>Rate: {rate || ""}</h4>
//           <h4 style={{ margin: "0px 5px" }}>Price: {price || ""}</h4>
//         </div>
//         <Button
//           variant="contained"
//           style={{ float: "right", marginRight: "5px" }}
//           onClick={onSubmit}
//         >
//           {isEdit ? "Update" : "Add"}
//         </Button>
//         {isEdit ? (
//           <Button
//             variant="text"
//             style={{ float: "right", marginRight: "5px" }}
//             color="error"
//             onClick={() => setDeleteAlert(true)}
//           >
//             Delete
//           </Button>
//         ) : null}
//       </DialogContent>
//     </Dialog>
//   );
// }

const checkBoxArray = [
  {
    label: "On Product Value",
    enable: false,
    type: "by_percentage",
    value: "on_total_sale",
  },
  {
    label: "On Labour / Making",
    enable: false,
    type: "by_percentage",
    value: "on_sale_making",
  },
  {
    label: "On Metal",
    enable: false,
    type: "by_percentage",
    value: "on_sale_metal",
  },
  {
    label: "On Diamond",
    enable: false,
    type: "by_percentage",
    value: "on_sale_diamond",
  },
  {
    label: "On Gemstone",
    enable: false,
    type: "by_percentage",
    value: "on_sale_colorstone",
  },
];
