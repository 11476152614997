import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Tooltip } from "@mui/material";
import { pushNotificationStyles } from "../styles/pushNotificationStyles";
import NodeOptions from "../helper/NodeOptions";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NodePopover from "../helper/NodePopover";
import AddNodesButton from "../helper/AddNodesButton";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import BounceLoader from "react-spinners/BounceLoader";
import AddIcon from "@mui/icons-material/Add";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = pushNotificationStyles;

const PushNotification = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const title = nodes?.find((node) => node.id === nodeId)?.fieldData?.title;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentAddHandle, setCurrentAddhandle] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openNotificationSidebar: true },
    });
  };

  return (
    <Box
      className="nodeContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ paddingBottom: "30px", paddingLeft: "30px" }}
    >
      <Box sx={styles.mainContainer}>
        {isHovered && !isDragging ? (
          <NodeOptions nodeId={nodeId} nodeType="filter" />
        ) : (
          <Box sx={styles.titleBox}>
            <Typography variant="span" sx={styles.titleText}>
              {title || "‎"}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={styles.nodeBox}>
        <Handle type="target" position="left" style={styles.leftHandle} />

        <Box sx={styles.nodeBody} onClick={handleMain}>
          <Box sx={styles.nodeInnerBox}>
            <NotificationsActiveIcon sx={styles.nodeIcon} />
            <Typography variant="span" style={styles.titleName}>
              Push Notification
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={styles.handleBox}>
            <Box sx={styles.handleInnerBox}>
              <Typography variant="span" sx={styles.handleText}>
                Reopened
              </Typography>
              <AddIcon
                sx={styles.addIcon}
                onClick={(e) => handleClick(e, "reopened-handle")}
              />
              <Handle
                type="source"
                position="right"
                id="reopened-handle"
                style={styles.rightHandle}
              />
            </Box>
            <Box sx={styles.handleInnerBox}>
              <Typography variant="span" sx={styles.handleText}>
                Opened
              </Typography>
              <AddIcon
                sx={styles.addIcon}
                onClick={(e) => handleClick(e, "opened-handle")}
              />
              <Handle
                type="source"
                position="right"
                id="opened-handle"
                style={styles.rightHandle}
              />
            </Box>
            <Box sx={styles.handleInnerBox}>
              <Typography variant="span" sx={styles.handleText}>
                Failed
              </Typography>
              <AddIcon
                sx={styles.addIcon}
                onClick={(e) => handleClick(e, "failed-handle")}
              />
              <Handle
                type="source"
                position="right"
                id="failed-handle"
                style={styles.rightHandle}
              />
            </Box>
          </Box>
          {/* <Box sx={{ position: "absolute", top: "17px", right: "-20px"}}>
          <BounceLoader color="#3185FC" speedMultiplier="0.8" size="80px" />
        </Box> */}
        </Box>
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          currentAddHandle={currentAddHandle}
        />
      </Box>
    </Box>
  );
};

export default PushNotification;
