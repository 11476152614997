import axios from "axios";
import {
  getAxiosError,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";

export const EDIT_ORDER = "EDIT_ORDER";

export const getAllOrders = (params, clear) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    let state = getState();
    let fetchedOrders = state.orders.allOrders;
    if (website && token) {
      dispatch({
        type: EDIT_ORDER,
        payload: {
          fetchingOrders: true,
        },
      });
      if (clear)
        dispatch({
          type: EDIT_ORDER,
          payload: {
            allOrders: [],
          },
        });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params,
        });
        let totalOrders = res?.headers?.["x-wp-total"];
        dispatch({
          type: EDIT_ORDER,
          payload: {
            noOrders:
              !isArrayWithValues(res.data) &&
              params?.page === 1 &&
              !params.status,
            totalOrders,
          },
        });
        if (isArrayWithValues(res.data)) {
          if (params.page === 1)
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allOrders: res.data,
              },
            });
          else
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allOrders: fetchedOrders.concat(res.data),
              },
            });
          if (res.data.length < params.per_page)
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allOrdersFetched: true,
              },
            });
          else
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allOrdersFetched: false,
              },
            });
        }
        dispatch({
          type: EDIT_ORDER,
          payload: {
            fetchingOrders: false,
          },
        });
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
};

export const getOrder = (id) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_ORDER,
        payload: {
          fetchingOrder: true,
        },
      });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders/${id}?random_key=${Math.random()}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (isObjWithValues(res.data)) {
          dispatch({
            type: EDIT_ORDER,
            payload: {
              orderView: res.data,
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_ORDER,
        payload: {
          fetchingOrder: false,
        },
      });
    }
  };
};

export const getDigigoldOrders = (params, clear) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    let state = getState();
    let fetchedOrders = state.orders.allOrders;
    if (website && token) {
      dispatch({
        type: EDIT_ORDER,
        payload: {
          fetchingDigiOrders: true,
        },
      });
      if (clear)
        dispatch({
          type: EDIT_ORDER,
          payload: {
            allOrders: [],
          },
        });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/orders`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params,
        });
        if (isArrayWithValues(res.data)) {
          if (params?.page === 1)
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allDigiOrders: res.data,
              },
            });
          else
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allDigiOrders: isArray(fetchedOrders)
                  ? fetchedOrders.concat(res.data)
                  : res.data,
              },
            });
          if (res.data.length < params?.per_page)
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allDigiOrdersFetched: true,
              },
            });
          else
            dispatch({
              type: EDIT_ORDER,
              payload: {
                allDigiOrdersFetched: false,
              },
            });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_ORDER,
        payload: {
          fetchingDigiOrders: false,
        },
      });
    }
  };
};

export const getCustomerOrders = (id, order_ids) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    let state = getState();
    let updatedDigiCustomers = state.customers.updatedDigiCustomers;
    if (!website && !token) return false;
    if (!isArrayWithValues(updatedDigiCustomers)) return false;
    dispatch({
      type: "SET_CRM_DATA",
      payload: { fetchingDigiCustomerOrders: true },
    });
    try {
      const { data } = await axios({
        url: `${website}/wp-json/wc/v3/orders`,
        params: {
          // include: order_ids.join(","),
          customer: id,
          // per_page: order_ids.length,
          // page: 1,
        },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (isArrayWithValues(data))
        updatedDigiCustomers = updatedDigiCustomers.map((i) => {
          if (`${i.id}` === `${id}`)
            return { ...i, ordersList: data, ordersFetched: true };
          else return i;
        });
      dispatch({
        type: "SET_CRM_DATA",
        payload: { updatedDigiCustomers },
      });
    } catch (error) {}
    dispatch({
      type: "SET_CRM_DATA",
      payload: { fetchingDigiCustomerOrders: false },
    });
  };
};
