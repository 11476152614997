import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, useNodeId } from "reactflow";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NodePopover from "../helper/NodePopover";
import * as actions from "../../../../../../redux/actions/flowsActions";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import { startNodeStyles } from "../styles/startNodeStyles";
import "../styles/node.css";

const styles = startNodeStyles;

const CustomStart = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodes = useSelector((state) => state.flow?.nodes);
  const nodeId = useNodeId();
  const title = nodes.find((it) => it.id == nodeId).title;
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleMain = () => {
    setOpenTooltip(!openTooltip);
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setOpenTooltip(false);
  };

  return (
    <Tooltip
      arrow
      title="It is the start point of the flow/story. It can't be edited."
      open={openTooltip}
      placement="left"
      componentsProps={{
        tooltip: {
          sx: {
            width: "180px",
            backgroundColor: "#797b8c",
            color: "#fff",
            fontSize: "14px",
            fontFamily: "Inter",
            padding: "8px",
            fontWeight: 550,
          },
        },
        arrow: {
          sx: {
            color: "#797b8c",
          },
        },
      }}
    >
      <Box
        className="nodeContainer"
        sx={styles.mainContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            paddingBottom: "5px",
            textAlign: "center",
            position: "relative",
            right:
              messageNodeIndicatorId === nodeId && !isMinimized
                ? "17px"
                : "-0.5px",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: "13px",
              alignItems: "center",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            {title}
          </Typography>
        </div>

        <Box sx={styles.bottomRow}>
          <Box
            sx={styles.nodeBody}
            onClick={handleMain}
            className="startButton"
          >
            <IconButton sx={styles.iconButton}>
              <HomeIcon sx={styles.icon} />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              Start point
            </Typography>
            <Handle type="source" position="right" style={styles.rightHandle} />
          </Box>
          {messageNodeIndicatorId === nodeId && !isMinimized && (
            <ShowChatIndicator />
          )}
          <NodePopover
            nodeId={nodeId}
            handleClick={handleClick}
            handleClose={handleClose}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};

export default CustomStart;
