import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Alert,
} from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { useDispatch, useSelector } from "react-redux";
import { delayStyles } from "./styles/delayStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import FormatOptions from "./components/FormatOptions";
import { capitalizeFirstLetter } from "jwero-javascript-utils";

const styles = delayStyles;

const SidebarDelay = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);
  const [errorMessage, setErrrorMessage] = useState(false);

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openDelaySidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (value < 0) {
      setErrrorMessage(true);
      return;
    }
    setErrrorMessage(false);
    setData((prev) => ({
      ...prev,
      time_value: value,
    }));
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        fieldsData: data,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openDelaySidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { dialogBox: true },
    });
  };

  useEffect(() => {
    const currentNode = nodes.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.fieldsData) {
        setData(node?.fieldsData);
      } else {
        setData({
          time_value: 1,
          time_format: "Minutes",
        });
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <HourglassBottomIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Delay
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            value={title}
            required
          />
        </Box>
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Set Delay
            </Typography>
            <Box sx={styles.setTimeBox}>
              <Box
                sx={{
                  ...styles.textFieldBox,
                  width: "120px",
                  marginRight: "10px",
                }}
              >
                <TextField
                  type="number"
                  value={data?.time_value}
                  sx={{ ...styles.textField, paddingLeft: "10px" }}
                  onChange={handleTimeChange}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Enter time"
                />
              </Box>
              <Box
                onClick={handleClickPopover}
                sx={{
                  ...styles.textFieldBox,
                  width: "120px",
                  cursor: "pointer",
                }}
              >
                <TextField
                  sx={styles.textField}
                  variant="standard"
                  value={
                    (data?.time_format &&
                      capitalizeFirstLetter(data?.time_format)) ||
                    ""
                  }
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginTop: "25px", color: "red" }}>
              Value Should be Positive only.
            </Alert>
          )}
          <FormatOptions
            setData={setData}
            optionsAnchorEl={optionsAnchorEl}
            setOptionsAnchorEl={setOptionsAnchorEl}
          />
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarDelay;
