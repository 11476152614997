import { backendApiCall } from "../../../../../../helper";
import { backendEndpoints } from "../../../../../chat_new/apiFunctions";

export const saveFlow = async (obj) => {
  await backendApiCall({
    endpoint: `${backendEndpoints.test_bot}`,
    method: "POST",
    data: obj,
  });
  return true;
};
