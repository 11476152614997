/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccessTimeOutlined,
  Add,
  ArrowBack,
  Delete,
  DescriptionOutlined,
  DraftsOutlined,
  Edit,
  EditOutlined,
  ErrorOutline,
  FilterAltOutlined,
  FormatListBulletedOutlined,
  GridViewOutlined,
  PersonOutlineOutlined,
  PreviewOutlined,
  RemoveRedEyeOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import EmailEditor from "react-email-editor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { sidebarExtraWidth } from "../../App";
import {
  EmptyState,
  formatDate,
  formatServerValue,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
  TabPanel,
  textFeildRadius,
  validateNumber,
} from "../../helper";
import { EDIT_CHATS, getEmailTemplate } from "../../redux/actions/chatActions";
import {
  getPredefinedEmailTemplate,
  setGlobalToast,
} from "../../redux/actions/helperActions";
import { EmailEmptyState, SendEmail } from "../../Svgs";
import EmailPreview from "../campaign/components/EmailPreview";
import {
  confirmPage,
  CustomeDialogTitle,
  openTempalte,
  templateListPage,
} from "../customers/components/EmailTemplate";
import { StyledTableRow } from "../digital_gold/Dashboard";
const deleteTemplateToast = (name) => `${name} template deleted successfully!`;
const EmailList = ({
  setSelectedPage,
  setOnSelectedtemplated,
  setDeleteTemplateTab,
  setIsEdit,
  setPreview,
  preview,
  setCreateTemplate,
}) => {
  //   const [selectedTempate, setSelectedTemplated] = useState(false);
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const getttingEamilTemplate = useSelector(
    (state) => state.chats.getttingEamilTemplate
  );
  const emailTemplateFetched = useSelector(
    (state) => state.chats.emailTemplateFetched
  );
  const emailEditorRef = useRef(null);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const [deleteId, setDeleteId] = useState(false);
  // const [preview, setPreview] = useState(false)

  const dispatch = useDispatch();
  const onReady = () => {
    // editor is ready
    emailEditorRef.current.editor.loadDesign(preview?.json_template);
    emailEditorRef.current.editor.showPreview("desktop");
    // emailEditorRef.current.editor.hidePreview();
  };
  useEffect(() => {
    if (!isObjWithValues(reduxEmailTemplates)) dispatch(getEmailTemplate());
  }, []);
  useMemo(() => {
    setDeleteTemplateTab && setDeleteTemplateTab(deleteId);
  }, [deleteId]);
  //   useMemo(() => {
  //     setOnSelectedtemplated && setOnSelectedtemplated(selectedTempate);
  //   }, [selectedTempate]);

  return (
    <>
      {
        <CustomeDialogTitle
          dialogTitle={"Select Template"}
          backButton={
            <IconButton
              onClick={() => {
                setSelectedPage("");
              }}
            >
              <ArrowBack />
            </IconButton>
          }
          buttons={
            <>
              <Button
                variant="outlined"
                onClick={() => setCreateTemplate(true)}
                startIcon={<Add />}
              >
                Create template
              </Button>
            </>
          }
        />
      }
      {/* <DialogTitle
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            
                <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >

                 
                </Typography>
               

            </DialogTitle> */}
      <DialogContent className="scrollbar-hidden">
        <Box className="scrollbar-hidden">
          {false ? (
            <DeleteConfirm
              deleteTemplateObj={deleteId}
              setDeleteId={setDeleteId}
            />
          ) : (
            <>
              {preview ? (
                <EmailEditor
                  ref={emailEditorRef}
                  // onLoad={onLoad}
                  onReady={onReady}
                  options={{
                    customCSS: {
                      ".gjs-pn-buttons .gjs-pn-btn.fa-eye-slash": {
                        display: "none",
                      },
                    },
                    // locale: {
                    //     poweredBy: "Himanshu",
                    // },
                  }}
                  minHeight={"75vh"}
                />
              ) : (
                <List className="scrollbar-hidden">
                  {getttingEamilTemplate ? (
                    new Array(5).fill("").map((str) => (
                      <ListItem>
                        <Card
                          sx={{
                            padding: 3,
                            // ":hover": !viewOnly
                            //   ? {
                            //       backgroundColor: "#f1f1f1",
                            //       cursor: "pointer",
                            //     }
                            //   : {},
                          }}
                        >
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "15vw" }}
                          />
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "10vw" }}
                            />
                            <Stack direction={"row"} sx={{ gap: "10px" }}>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "40px" }}
                              />{" "}
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "40px" }}
                              />
                            </Stack>
                          </Stack>
                        </Card>
                      </ListItem>
                    ))
                  ) : isObjWithValues(reduxEmailTemplates) &&
                    emailTemplateFetched ? (
                    Object.values(reduxEmailTemplates || {})?.map((o) => {
                      let member = reduxTeamMembers?.find(
                        (obj) => obj?.id === o.created_by
                      );

                      return (
                        <>
                          {/* <ListItemtem o={o} setOnSelectedtemplated={setOnSelectedtemplated} /> */}
                          <ListItem
                            alignItems="centre"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setOnSelectedtemplated &&
                              setOnSelectedtemplated(o)
                            }
                          >
                            <ListItemAvatar>
                              <DraftsOutlined />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  onClick={() =>
                                    setOnSelectedtemplated &&
                                    setOnSelectedtemplated(o)
                                  }
                                  variant="h6"
                                >
                                  {o.name}
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Stack direction={"row"}>
                                      <Typography
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        By{" "}
                                        {o.isAdmin
                                          ? "Admin"
                                          : `${member?.first_name || ""} ${
                                              member?.last_name || ""
                                            }`}{" "}
                                        | Created on{" "}
                                        {formatDate(
                                          new Date(o.created),
                                          "dd:mm:yyyy hh:mm am/pm"
                                        )}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      gap={1.5}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setPreview && setPreview(o);
                                        }}
                                        // color="error"
                                      >
                                        <RemoveRedEyeOutlined />
                                      </IconButton>
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setIsEdit(o);
                                        }}
                                        // color="error"
                                      >
                                        <Edit />
                                      </IconButton>
                                      {deleteId == o?.id ? (
                                        <CircularProgress
                                          color="error"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                      ) : (
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteId(o);
                                          }}
                                          color="error"
                                        >
                                          <Delete />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </Stack>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* </ListItemButton> */}
                          <Divider variant="inset" component="li" />
                        </>
                      );
                    })
                  ) : (
                    <EmptyState
                      icon={<EmailEmptyState />}
                      text="No email templates found"
                    />
                  )}
                </List>
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </>
  );
};

// const ListItemtem = ({ setOnSelectedtemplated, o, member }) => {
//     const imageRef = useRef(null);
//     console.log(o);
//     useEffect(() => {
//         // const parser = new DOMParser();
//         // const parsedHtml = parser.parseFromString(, 'text/html');
//         // const imageElement = parsedHtml.querySelector('img');
//         // if (imageElement) {
//         //     imageRef.current.src = imageElement.src;
//         //     imageRef.current.alt = imageElement.alt;
//         // }
//     }, [o]);
//     return <ListItem
//         alignItems="centre"
//         style={{ cursor: "pointer" }}
//         onClick={() =>
//             setOnSelectedtemplated &&
//             setOnSelectedtemplated(o)
//         }
//     >
//         <img ref={imageRef} />
//         <ListItemAvatar>
//             <DraftsOutlined />
//         </ListItemAvatar>
//         <ListItemText
//             primary={
//                 <Typography
//                     onClick={() =>
//                         setOnSelectedtemplated &&
//                         setOnSelectedtemplated(o)
//                     }
//                     variant="h6"
//                 >
//                     {o.name}
//                 </Typography>
//             }
//             secondary={
//                 <React.Fragment>
//                     <Stack
//                         direction={"row"}
//                         alignItems={"center"}
//                         justifyContent={"space-between"}
//                     >
//                         <Stack direction={"row"}>
//                             <Typography
//                                 sx={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                     cursor: "pointer",
//                                 }}
//                                 component="span"
//                                 variant="body2"
//                                 color="text.primary"
//                             >
//                                 By{" "}
//                                 {o.isAdmin
//                                     ? "Admin"
//                                     : `${member?.first_name || ""} ${member?.last_name || ""
//                                     }`}{" "}
//                                 | Created on{" "}
//                                 {formatDate(
//                                     new Date(o.created),
//                                     "dd:mm:yyyy hh:mm am/pm"
//                                 )}
//                             </Typography>
//                         </Stack>
//                         <Stack
//                             direction={"row"}
//                             alignItems={"center"}
//                             gap={1.5}
//                         >
//                             <IconButton
//                                 onClick={(e) => {
//                                     e.stopPropagation();
//                                     // setPreview(o);
//                                 }}
//                             // color="error"
//                             >
//                                 <RemoveRedEyeOutlined />
//                             </IconButton>
//                             <IconButton
//                                 onClick={(e) => {
//                                     e.stopPropagation();
//                                     // setIsEdit(o);
//                                 }}
//                             // color="error"
//                             >
//                                 <Edit />
//                             </IconButton>
//                             {/* {deleteId == o?.id ? (
//                                 <CircularProgress
//                                     color="error"
//                                     style={{
//                                         height: "15px",
//                                         width: "15px",
//                                     }}
//                                 />
//                             ) : (
//                                 <IconButton
//                                     onClick={(e) => {
//                                         e.stopPropagation();
//                                         setDeleteId(o);
//                                     }}
//                                     color="error"
//                                 >
//                                     <Delete />
//                                 </IconButton>
//                             )} */}
//                         </Stack>
//                     </Stack>
//                 </React.Fragment>
//             }
//         />
//     </ListItem>
// }

export default EmailList;

const DeleteConfirm = ({ deleteTemplateObj, setDeleteId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const deleteTemplate = async (id) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      // setDeleteId(id);
      setLoading(true);
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/email_marketing_templates`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "DELETE",
        data: {
          id: id,
        },
      });
      if (res?.data?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: deleteTemplateToast(deleteTemplateObj.name),
          })
        );
        setDeleteId(false);
        dispatch({
          type: EDIT_CHATS,
          payload: {
            emailTemplates: res?.data?.data,
          },
        });
      }
    }
    setDeleteId(false);
    setLoading(false);
  };
  return (
    <>
      <DialogContent>
        <Typography variant="h6">
          Are you sure you want to delete template {deleteTemplateObj?.name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteId(false)}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          color="error"
          onClick={() => {
            deleteTemplate(deleteTemplateObj?.id);
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export const EmailTemplateList = ({
  setSelectedPage,
  setCreateTemplate,
  setOnSelectedtemplated,
  setIsEdit,
  action,
  hideHeading,
  hidePredefined,
  isCampaign = true,
  preview,
  setPreview,
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [templateListPage, setTemplateListPage] = useState("");
  const [selectPreview, setSelectPreview] = useState({});
  const [templateList, setTemplateList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteTemplateObj, setDeleteTemplateObj] = useState(false);
  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [defaultHeading , setDefaultHeading] = useState(false)
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const getttingEamilTemplate = useSelector(
    (state) => state.chats.getttingEamilTemplate
  );
  const emailTemplateFetched = useSelector(
    (state) => state.chats.emailTemplateFetched
  );

  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteTemplateObj(false);
  };
  useEffect(() => {
    if (!isObjWithValues(reduxEmailTemplates)) dispatch(getEmailTemplate());
  }, []);
  useEffect(() => {
    if (isObjWithValues(preview)) setSelectPreview(preview);
    console.log(preview);
    if (isObjWithValues(preview)) {
      setTemplateListPage("preview");
    }
  }, [preview]);

  useEffect(() => {
    selectPreview && setPreview && setPreview(selectPreview);
  }, [selectPreview]);

  useEffect(() => {
    if (isObjWithValues(reduxEmailTemplates))
      setTemplateList([
        ...Object.values(reduxEmailTemplates || {}).sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        ),
        // ...Object.values(predefinedEmailTemplate || {})?.map((obj) => ({
        //   ...obj,
        //   predefined: true,
        // })),
      ]);
  }, [reduxEmailTemplates]);

  // useEffect(() => {
  //  if(isObjWithValues(predefinedEmailTemplate)){
  //   setTemplateList()
  //  }
  // }, [predefinedEmailTemplate])

  const onSearch = (e) => {
    setSearchTerm(e.target.value);

    let value = e?.target?.value;
    if (page) setPage(0);
    // if (searchTimeOut) clearTimeout(searchTimeOut);
    // searchTimeOut = setTimeout(async () => {
    if (value && isObjWithValues(reduxEmailTemplates)) {
      let searched = Object.values(reduxEmailTemplates || {}).filter((obj) => {
        if (obj?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()))
          return obj;
      });
      // await sleep(200);
      setTemplateList(searched);
    } else {
      setTemplateList(Object.values(reduxEmailTemplates || {}));
    }
  };

  console.log(reduxEmailTemplates, emailTemplateFetched, templateList);
  return (
    <>
      <TemplateDeletePopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        deleteTemplateObj={deleteTemplateObj}
      />
      <TabPanel
        value={""}
        index={templateListPage}
        className="scrollbar-hidden"
      >
        <>
          {hideHeading ? null : (
            <CustomeDialogTitle
              dialogTitle={"Select Template"}
              buttons={
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  gap={3}
                  // px={3}
                >
                  {!isPhone && !hidePredefined && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setSelectedPage
                          ? setSelectedPage("predefined")
                          : setTemplateListPage("predefined");
                      }}
                    >
                      Use Predefined template
                    </Button>
                  )}
                  <Box>
                    {setCreateTemplate ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedPage
                            ? setSelectedPage("predefined")
                            : setTemplateListPage("predefined");
                        }}
                      >
                        Use predefined templates
                      </Button>
                    ) : null}
                  </Box>
                  <TextField
                    size="small"
                    placeholder="Search template"
                    value={searchTerm}
                    sx={{ ...textFeildRadius }}
                    onChange={onSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              }
              backButton={
                isCampaign && (
                  <IconButton
                    onClick={() => {
                      setSelectedPage("");
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                )
              }
            />
          )}
          <DialogContent sx={{ p: 0 }} className="scrollbar-hidden">
            <Paper>
              {!getttingEamilTemplate &&
              isObjWithValues(reduxEmailTemplates) ? (
                <>
                  {hideHeading && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap={3}
                      // px={3}
                    >
                      <Typography variant="h5">
                        Templates{" "}
                        {templateList?.length
                          ? `(${templateList?.length})`
                          : null}
                      </Typography>
                      <Stack direction={"row"} alignItems={"center"} gap={3}>
                        {!isPhone && !hidePredefined && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedPage
                                ? setSelectedPage("predefined")
                                : setTemplateListPage("predefined");
                            }}
                          >
                            Use Predefined template
                          </Button>
                        )}
                        <Box>
                          {setCreateTemplate ? (
                            <Button
                              sx={{ ml: 2 }}
                              onClick={() => setCreateTemplate(true)}
                              startIcon={<Add />}
                              variant="contained"
                            >
                              Create
                            </Button>
                          ) : null}
                        </Box>
                        <TextField
                          size="small"
                          placeholder="Search template"
                          value={searchTerm}
                          sx={{ ...textFeildRadius }}
                          onChange={onSearch}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlined />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}

                  {isArrayWithValues(templateList) ? (
                    <>
                      <TableContainer sx={{ mt: 2 }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                Sr.
                              </TableCell>
                              <TableCell
                                colSpan={2}
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                Template
                              </TableCell>
                              <TableCell

                              // sx={{ whiteSpace: "nowrap" }}
                              >
                                Catagory
                              </TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                Created on
                              </TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                Created by
                              </TableCell>
                              {action && <TableCell></TableCell>}

                              {/* <TableCell>Sent to</TableCell>  */}
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {isObjWithValues(reduxEmailTemplates) &&
                            emailTemplateFetched
                              ? templateList
                                  ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row, index) => {
                                    let member =
                                      isArrayWithValues(reduxTeamMembers) &&
                                      reduxTeamMembers?.find(
                                        (obj) => obj?.id === row.created_by
                                      );

                                    return (
                                      <TableRow
                                        key={row.name}
                                        sx={{
                                          cursor: "pointer",
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        onClick={() => {
                                          if (action) {
                                            setTemplateListPage &&
                                              setTemplateListPage("preview");
                                            setSelectPreview &&
                                              setSelectPreview(row);

                                            return;
                                          }
                                          setTemplateListPage &&
                                            setTemplateListPage("preview");
                                          setSelectPreview &&
                                            setSelectPreview(row);
                                        }}
                                      >
                                        <TableCell
                                          padding="checkbox"
                                          align="left"
                                        >
                                          <Typography
                                            sx={{ ml: 3, fontWeight: "600" }}
                                          >
                                            {index + 1}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          colSpan={2}
                                          sx={{ minWidth: "200px" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            fontSize={"0.8rem"}
                                          >
                                            {row.name}
                                          </Typography>
                                          <Typography
                                            sx={{ ...secondaryLabel }}
                                          >
                                            Sub: {row.subject}
                                          </Typography>
                                        </TableCell>

                                        <TableCell>
                                          <Typography
                                            variant="h6"
                                            fontSize={"0.8rem"}
                                            sx={{ whiteSpace: "nowrap" }}
                                          >
                                            {formatServerValue(row?.category)}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            variant="h6"
                                            fontSize={"0.8rem"}
                                            sx={{ whiteSpace: "nowrap" }}
                                          >
                                            {" "}
                                            {formatDate(
                                              new Date(row.created),
                                              "relative_date"
                                            )}
                                          </Typography>
                                          <Typography
                                            sx={{ ...secondaryLabel }}
                                          >
                                            {" "}
                                            {formatDate(
                                              new Date(row.created),
                                              "hh:mm"
                                            )}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          {row.isAdmin
                                            ? "Admin"
                                            : `${member?.first_name || ""} ${
                                                member?.last_name || ""
                                              }`}
                                        </TableCell>

                                        {action && (
                                          <TableCell align="right">
                                            <Stack
                                              direction={"row"}
                                              alignItems={"center"}
                                              justifyContent={"flex-end"}
                                              gap={3}
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setTemplateListPage &&
                                                    setTemplateListPage(
                                                      "preview"
                                                    );
                                                  setSelectPreview &&
                                                    setSelectPreview(row);
                                                }}
                                              >
                                                <RemoveRedEyeOutlined />
                                              </IconButton>
                                              <IconButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setSelectedPage &&
                                                    setSelectedPage(
                                                      openTempalte
                                                    );
                                                  setIsEdit && setIsEdit(row);
                                                }}
                                              >
                                                <Edit />
                                              </IconButton>
                                              <IconButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleClick(e);
                                                  setDeleteTemplateObj(row);
                                                }}
                                              >
                                                <Delete color="error" />
                                              </IconButton>
                                            </Stack>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  })
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={Object.values(reduxEmailTemplates || {}).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <>
                      <EmptyState
                        icon={
                          <DescriptionOutlined
                            // color="text.secondary"
                            height={140}
                            width={140}
                            strokeColor={"#d7d7d7"}
                            strokeWidth={1.4}
                            style={{
                              height: "140px",
                              width: "140px",
                              color: "#d7d7d7",
                            }}
                          />
                        }
                        text="No Templates found"
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {getttingEamilTemplate ? (
                    <Box
                      sx={{
                        my: "28vh",
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <EmptyState
                        icon={
                          <DescriptionOutlined
                            // color="text.secondary"
                            height={140}
                            width={140}
                            strokeColor={"#d7d7d7"}
                            strokeWidth={1.4}
                            style={{
                              height: "140px",
                              width: "140px",
                              color: "#d7d7d7",
                            }}
                          />
                        }
                        text="No Templates found"
                      />
                    </>
                  )}
                </>
              )}
            </Paper>
          </DialogContent>
        </>
      </TabPanel>
      <TabPanel value={"preview"} index={templateListPage}>
        {hideHeading ? null : (
          <CustomeDialogTitle
            dialogTitle={"Preview"}
            backButton={
              <IconButton
                onClick={() => {
                  if (isCampaign) {
                    preview && setSelectedPage("");
                    setTemplateListPage && setTemplateListPage("");
                    setSelectPreview && setSelectPreview({});
                  } else {
                    // preview && setSelectedPage(templateListPage);
                    setTemplateListPage && setTemplateListPage("");
                    setSelectPreview && setSelectPreview({});
                  }
                }}
              >
                <ArrowBack />
              </IconButton>
            }
            buttons={
              <Stack direction={"row"} alignItems={"center"} gap={3}>
                {setIsEdit && (
                  <Button
                    color="error"
                    startIcon={<EditOutlined />}
                    onClick={() => {
                      setSelectedPage(openTempalte);
                      setIsEdit(selectPreview);
                    }}
                  >
                    Edit Template
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    if (isCampaign) setSelectedPage && setSelectedPage("");
                    else setSelectedPage && setSelectedPage(confirmPage);
                    setOnSelectedtemplated(selectPreview);
                  }}
                >
                  Select this template
                </Button>
              </Stack>
            }
          />
        )}
        <EmailPreview
          templateString={selectPreview?.html_template}
          showBack={hideHeading}
          onBackClick={() => {
            setTemplateListPage && setTemplateListPage("");
            setSelectPreview && setSelectPreview({});
          }}
        />
      </TabPanel>
      {/* <TabPanel value={"predefined"} index={templateListPage}>
        <PredefinedEmailTemplate setIsEdit={setIsEdit} setSelectedPage={setTemplateListPage} />
      </TabPanel> */}
    </>
  );
};

export const PredefinedEmailTemplate = ({
  setIsEdit,
  setSelectedPage,
  isCampaign,
  setPreview,
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  const predefinedEmailTemplate = useSelector(
    (state) => state?.helper?.predefinedEmailTemplate
  );
  const predefinedEmailTemplateFetching = useSelector(
    (state) => state?.helper?.predefinedEmailTemplateFetching
  );
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isObjWithValues(predefinedEmailTemplate))
      dispatch(getPredefinedEmailTemplate());
  }, []);
  return (
    <>
      <CustomeDialogTitle
        asBox
        backButton={
          <IconButton
            onClick={() => {
              isCampaign
                ? setSelectedPage("")
                : setSelectedPage(templateListPage);
              setPreview && setPreview(false);
            }}
          >
            <ArrowBack />
          </IconButton>
        }
        // breadcrumbs={[{ title: "Campaign", link: "/campaign" }, { title: "Templates" }]}
        dialogTitle={"Predefined Templates"}
      />
      <DialogContent
        className="scrollbar-hidden"
        sx={{
          height: "80vh",
          // `calc(100vw -${sidebarExtraWidth}px)`
        }}
      >
        {predefinedEmailTemplateFetching && (
          <>
            <Stack
              sx={{ height: "70vh" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Stack>
          </>
        )}
        {!predefinedEmailTemplateFetching && (
          <>
            <Tabs
              variant="scrollable"
              scrollButtons="false"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                maxWidth: !isIpad
                  ? `calc(100vw - ${sidebarExtraWidth + 100}px)`
                  : "100%",
              }}
              value={selectedTab}
              onChange={(e, newValue) => {
                setSelectedTab(newValue);
              }}
            >
              <Tab label={"ALL"} value={""} />
              {[
                ...new Set(
                  Object.values(predefinedEmailTemplate || {})?.map(
                    (obj) => obj?.collection
                  )
                ),
              ]?.map((collection) => (
                <Tab label={formatServerValue(collection)} value={collection} />
              ))}
            </Tabs>
            <div hidden={selectedTab}>
              <Grid container spacing={isIpad ? 4 : 12} rowSpacing={6} mt={2}>
                {[
                  ...new Set(
                    Object.values(predefinedEmailTemplate || {})?.map(
                      (obj) => obj?.collection
                    )
                  ),
                ]?.map((collection) => {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6" color={"text.secondary"}>
                          {collection}
                        </Typography>
                      </Grid>
                      {Object.values(predefinedEmailTemplate || {})
                        ?.filter((o) => o.collection === collection)
                        ?.map((obj) => {
                          console.log(obj);
                          return (
                            <Grid item xs={6} md={3}>
                              {/* <img src={obj?.thumbnail} height={"200px"} width={"auto"} /> */}
                              <TemplateThumbnail
                                obj={obj}
                                onClick={() => {
                                  setSelectedPage &&
                                    setSelectedPage(openTempalte);
                                  setIsEdit &&
                                    setIsEdit({ ...obj, create: true });
                                  // setDefaultHeading(true)
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </>
                  );
                })}
              </Grid>
            </div>
            <div hidden={!selectedTab}>
              <Grid container spacing={isIpad ? 4 : 12} rowSpacing={6} mt={2}>
                {Object.values(predefinedEmailTemplate || {})
                  ?.filter((o) => o.collection === selectedTab)
                  ?.map((obj) => {
                    console.log(obj);
                    return (
                      <Grid item xs={6} md={3}>
                        {/* <img src={obj?.thumbnail} height={"200px"} width={"auto"} /> */}
                        <TemplateThumbnail
                          obj={obj}
                          onClick={() => {
                            setSelectedPage && setSelectedPage(openTempalte);
                            setIsEdit && setIsEdit({ ...obj, create: true });
                            // setDefaultHeading(true)
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </>
        )}
      </DialogContent>
    </>
  );
};

const TemplateThumbnail = ({ obj, onClick }) => {
  let [hover, setHover] = useState(false);
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const mouseEnter = () => {
    setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };
  let { thumbnail } = obj || {};
  return (
    <Box
      sx={{
        borderRadius: "7px",
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
        backgroundColor: hover ? "#000" : "",
        // maxHeight: "200px",
      }}
      // onClick={() => postPress(data)}
      className={"shadow_card"}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <img
        src={thumbnail}
        style={{
          width: "100%",
          height: isIpad ? "300px" : "400px",
          overflow: "hidden",
          flex: 1,
          objectFit: "contain",

          opacity: hover ? 0.5 : 1,
          backgroundColor: "#fff",
          transition: "opacity 0.15s",
        }}
      />
      {hover && (
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Stack
            // direction="row"
            alignItems={"center"}
            gap={2}
            rowGap={3}
            textAlign="center"
            color="#fff"
            justifyContent="center"
          >
            <Typography variant="h6">{obj?.name}</Typography>
            <Button variant="contained" onClick={onClick}>
              Use Template
            </Button>
            {/* <FavoriteBorderOutlined
          color="#fff"
          style={{ marginRight: "4px" }}
        />
        {likesCount}
        <ChatBubbleOutlineOutlined
          style={{ marginLeft: "7px", marginRight: "4px" }}
        />
        {commentsCount} */}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

const TemplateDeletePopover = ({
  anchorEl,
  handleClose,
  deleteTemplateObj,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      // onClose={handleClose}

      PaperProps={{
        elevation: 0,

        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "16px",
          width: 300,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            borderRadius: 8,
          },
          // "&:before": {
          //     content: '""',
          //     display: "block",
          //     position: "absolute",
          //     top: 0,
          //     right: 14,
          //     width: 10,
          //     height: 10,
          //     bgcolor: "background.paper",
          //     transform: "translateY(-50%) rotate(45deg)",
          //     zIndex: 0,
          // },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <DeleteConfirm
        deleteTemplateObj={deleteTemplateObj}
        setDeleteId={handleClose}
      />
    </Popover>
  );
};
