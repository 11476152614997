import { useSelector } from "react-redux";
import {
  Popover,
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { preDefinedAttributesTextField } from "../../sidebar-bot/utils/preDefinedAttribuesTextField";

const SelectAttributes = ({
  selectAttributeAnchor,
  setSelectAttributeAnchor,
  attributeField,
  setAttributeField,
  handleVariable,
}) => {
  const open = Boolean(selectAttributeAnchor);
  const id = open ? "simple-popover" : undefined;

  const globalAttributes = useSelector(
    (state) => state?.flow?.globalAttributes
  );

  const handleClose = () => {
    setSelectAttributeAnchor(null);
  };
  console.log(attributeField);
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={selectAttributeAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box sx={{ width: "310px", padding: "25px 20px 10px 20px" }}>
        {attributeField &&
          attributeField.length > 0 &&
          attributeField.map((attr) => (
            <FormControl
              key={attr.index}
              sx={{ width: "200px", marginBottom: "20px" }}
            >
              <InputLabel>Var {attr.index}</InputLabel>
              <Select
                label="Select value"
                value={attr?.value || ""}
                onChange={(e) => handleVariable(e, attr.index)}
                sx={{ width: "300px", fontFamily: "Inter" }}
              >
                <InputLabel
                  sx={{
                    padding: "10px 5px 10px 10px",
                    fontWeight: 600,
                    color: "#000",
                    fontSize: "17px",
                  }}
                >
                  Global Attributes
                </InputLabel>
                {globalAttributes &&
                  globalAttributes.length > 0 &&
                  globalAttributes.map((globalAttr, index) => (
                    <MenuItem key={index} value={globalAttr}>
                      {globalAttr}
                    </MenuItem>
                  ))}
                <InputLabel
                  sx={{
                    padding: "10px 5px 10px 10px",
                    fontWeight: 600,
                    color: "#000",
                    fontSize: "17px",
                    marginTop: "10px",
                  }}
                >
                  Pre-defined Attributes
                </InputLabel>
                {preDefinedAttributesTextField &&
                  preDefinedAttributesTextField.length > 0 &&
                  preDefinedAttributesTextField.map((preAttr, index) => (
                    <MenuItem key={index} value={preAttr}>
                      {preAttr}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ))}
      </Box>
    </Popover>
  );
};

export default SelectAttributes;
