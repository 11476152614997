import {
  Cancel,
  Computer,
  DragIndicator,
  Smartphone,
  TabletAndroid,
} from "@material-ui/icons";
import { InsertLink } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  arrayMove,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  deleteBannerFromRedux,
  getAllSilder,
  replaceSlidinRedux,
} from "../../../redux/actions/pagesActions";
import ProductMediaLibrary from "../../components/ProductMediaLibrary";

const Banners = () => {
  const navArray = [
    { label: "Desktop" },
    { label: "Tablet" },
    { label: "Mobile" },
  ];
  const dispatch = useDispatch();
  const reduxAllSilder = useSelector((state) => state.pages.allSlider) || [];
  const reduxFetchingSilder = useSelector(
    (state) => state.pages.fetchingSlider
  );
  useEffect(() => {
    if (!isArrayWithValues(reduxAllSilder)) dispatch(getAllSilder());
  }, []);

  const [items, setItems] = React.useState([]);
  const [allSlider, setAllSlider] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isArrayWithValues(reduxAllSilder)) {
      console.log(reduxAllSilder);
      let filterObj = reduxAllSilder
        .sort((a, b) => a.menu_order - b.menu_order)
        ?.map((obj, index) => {
          let { id, title, menu_order, status, featured_media, metadata } = obj;
          let { bg_image_desktop, bg_image_tablet, bg_image_mobile } = metadata;
          let link = isArrayWithValues(metadata?.link) ? metadata?.link[0] : "";
          let newObj = {
            id,
            title: title?.rendered,
            menu_order,
            status,
            featured_media,
            metadata,
            bg_image_desktop,
            bg_image_tablet,
            bg_image_mobile,
            link,
          };
          return newObj;
        });
      let newObj = {};
      let index = 0;
      for (const iterator of filterObj) {
        newObj[index] = iterator;
        index = index + 1;
      }
      setAllSlider(newObj);
    }
  }, [reduxAllSilder]);

  useEffect(() => {
    setItems(
      Object.values(allSlider).map((obj, index) => (
        <BannerCard
          obj={{ ...obj, title: `Slider ${index + 1}` }}
          editSlider={editSlider}
          removeBanner={removeBanner}
        />
      ))
    );
  }, [allSlider]);
  console.log(allSlider);

  const onSortEnd = (oldIndex, newIndex) => {
    let newArr = arrayMove(items, oldIndex, newIndex);
    console.log(newArr);
    setItems(
      newArr.map((obj, index) => {
        editSlider({ [index]: { ...obj?.props?.obj, menu_order: index } });
        return (
          <BannerCard
            obj={{
              ...obj?.props?.obj,
              title: `Slider ${index + 1}`,
              menu_order: index,
            }}
            editSlider={editSlider}
            removeBanner={removeBanner}
          />
        );
      })
    );
  };
  const editSlider = (payload) => {
    setAllSlider((pre) => ({ ...pre, ...payload }));
  };
  const onAddBanner = () => {
    console.log(items.length, items);
    let newObj = {
      title: `Slider ${items.length + 1}`,
      menu_order: items.length,
      status: `publish`,
      featured_media: ``,
      metadata: {},
      bg_image_desktop: [],
      bg_image_tablet: [],
      bg_image_mobile: [],
      link: "",
    };
    editSlider({ [items.length]: newObj });
  };
  const removeBanner = (keyName) => {
    let payload = allSlider;
    delete payload[keyName];
    console.log(payload);
    setAllSlider({ ...payload });
  };
  console.log(allSlider);
  const onSave = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setSaving(true);
    let res;
    for await (const item of Object.values(allSlider)) {
      let postObj = {
        ...item,
        metadata: {
          bg_image_desktop: isArrayWithValues(item?.bg_image_desktop)
            ? item?.bg_image_desktop[0]
            : "",
          bg_image_tablet: isArrayWithValues(item?.bg_image_tablet)
            ? item?.bg_image_tablet[0]
            : "",
          bg_image_mobile: isArrayWithValues(item?.bg_image_mobile)
            ? item?.bg_image_mobile[0]
            : "",
          link: item?.link,
        },
      };
      delete postObj.bg_image_desktop;
      delete postObj.bg_image_tablet;
      delete postObj.bg_image_mobile;
      delete postObj.link;

      res = await axios({
        url: `${website}/wp-json/wp/v2/slider${item?.id ? `/${item?.id}` : ""}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          abc: 13,
        },
        method: "POST",
        data: {
          ...postObj,
          woodmart_slider: "Retail Slider",
        },
      });
      // setSaving(false);
      console.log(res);

      if (res?.data?.id) {
        dispatch(replaceSlidinRedux(res.data));
      } else {
        setGlobalToast({
          show: true,
          message: "Updated Failed",
          severity: "error",
        });
        return;
      }
    }
    setSaving(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Banners Updated",
        severity: "success",
      })
    );
  };

  return (
    <Box sx={{ margin: "10px", marginBottom: "60px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ mb: 4 }}
      >
        <Typography variant="h3">Banner Settings</Typography>
        <LoadingButton variant="contained" loading={saving} onClick={onSave}>
          Save
        </LoadingButton>
      </Stack>
      {reduxFetchingSilder ? (
        <Stack
          sx={{ height: "calc(100vh - 200px)", width: "100%" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fetching Banners ...
          </Typography>
          <LinearProgress sx={{ width: "50%" }} />
        </Stack>
      ) : null}
      <Stack spacing={6}>
        <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        >
          {items.map((item) => (
            <SortableItem key={item}>
              <div className="item" style={{ margin: "10px" }}>
                {item}
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ mt: 4, mr: 5 }}
      >
        {!reduxFetchingSilder && (
          <Button variant="contained" onClick={onAddBanner}>
            Add Banner
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Banners;

const BannerCard = ({ obj, editSlider, removeBanner }) => {
  const dispatch = useDispatch();
  const iconStyle = {
    height: "40px",
    width: "40px",
    // backgroundColor: "rgba(0,0,0,0.3)",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
  };

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const setBannerImages = (ar, id) => {
    if (id === "bg_image_desktop")
      editSlider({
        [obj.menu_order]: {
          ...obj,
          featured_media: ar[0]?.id,
          [id]: [ar[0]?.source_url],
        },
      });
    else {
      editSlider({ [obj.menu_order]: { ...obj, [id]: [ar[0]?.source_url] } });
    }
    console.log(ar);
    toggleMediaLibrary(false);
  };
  const giveAspect = (id) => {
    switch (id) {
      case "bg_image_desktop":
        // code block
        return { width: 1440, height: 600 };

      case "bg_image_tablet":
        // code block
        return { width: 1440, height: 600 };
        break;
      case "bg_image_mobile":
        // code block
        return { width: 865, height: 1280 };
        break;
      default:
      // code block
    }
  };
  const deleteBanner = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setDeleting(true);
    console.log(obj?.id);
    try {
      const res = await axios({
        url: `${website}/wp-json/wp/v2/slider/${obj?.id}`,
        method: "DELETE",
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },
      });
      console.log(res);
      if (res.data?.deleted) {
        dispatch(deleteBannerFromRedux(obj?.id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Banner Deleted!",
            severity: "success",
          })
        );
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setDeleting(false);
  };
  const onDeleteBanner = () => {
    if (obj?.id) deleteBanner();
    else {
      removeBanner(obj?.menu_order);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Banner Deleted!",
          severity: "success",
        })
      );
    }
  };
  const onRemoveImage = (id) => {
    editSlider({ [obj.menu_order]: { ...obj, [id]: [] } });
  };
  return (
    <>
      <Card className="shadow_card">
        <Box sx={{ my: 3, margin: "20px", cursor: "pointer" }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ mb: 2, mt: 2 }}
          >
            <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
              <DragIndicator />
              <Typography variant="h6">
                {/* {title} */}
                {obj?.title}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ gap: "10px" }}>
              <LoadingButton
                color="error"
                loading={deleting}
                onClick={onDeleteBanner}
              >
                Delete
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            sx={{ gap: "20px" }}
          >
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_desktop) &&
              obj?.bg_image_desktop[0] ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={obj?.bg_image_desktop[0]}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() => onRemoveImage("bg_image_desktop")}
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_desktop")}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={{
                      // borderWidth: 2,
                      // borderStyle: "dashed",
                      // borderColor: "#bababa",
                      alignItems: "center",
                      display: "flex",
                    }}
                    hoverTitle=""
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: " 100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",
                      }}
                    >
                      {" "}
                      <Stack justifyContent={"center"} alignItems="center">
                        <Computer color="rgba(0,0,0,0.3)" style={iconStyle} />
                        <Typography> (1440px X 600px)</Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Desktop
              </Typography>
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_tablet) &&
              obj?.bg_image_tablet[0] ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={obj?.bg_image_tablet[0]}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() => onRemoveImage("bg_image_tablet")}
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_tablet")}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={{
                      // borderWidth: 2,
                      // borderStyle: "dashed",
                      // borderColor: "#bababa",
                      alignItems: "center",
                      display: "flex",
                    }}
                    hoverTitle=""
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: " 100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",

                        // position: "relative",
                      }}
                    >
                      <Stack justifyContent={"center"} alignItems="center">
                        <TabletAndroid
                          color="rgba(0,0,0,0.3)"
                          style={iconStyle}
                        />
                        <Typography> (1440px X 600px)</Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Tablet
              </Typography>
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_mobile) &&
              obj?.bg_image_mobile[0] ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={obj?.bg_image_mobile[0]}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() => onRemoveImage("bg_image_mobile")}
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_mobile")}
                  sx={{
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={
                      {
                        // borderWidth: 2,
                        // borderStyle: "dashed",
                        // borderColor: "#bababa",
                      }
                    }
                    hoverTitle=""
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",

                        // position: "relative",
                      }}
                    >
                      <Stack justifyContent={"center"} alignItems="center">
                        <Smartphone color="rgba(0,0,0,0.3)" style={iconStyle} />
                        <Typography> (865px X 1280px)</Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Mobile
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Add Link
            </Typography>
            <TextField
              placeholder="Link"
              size="small"
              sx={{ width: isMobile ? "100%" : "45%" }}
              onChange={(e) =>
                editSlider({
                  [obj.menu_order]: {
                    ...obj,
                    link: e.target.value,
                  },
                })
              }
              value={obj?.link || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertLink />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>
        <ProductMediaLibrary
          open={mediaLibraryOpen}
          onChange={toggleMediaLibrary}
          setParentImages={setBannerImages}
          id={mediaLibraryOpen}
          multiple={false}
          aspect={giveAspect(mediaLibraryOpen)}
          // selectedImages={images}
          enable_crop={false}
        />
      </Card>
    </>
  );
};
