/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  formatDate,
  isArrayWithValues,
  isObjWithValues,
} from "../../../helper";
import { SmsOutlined } from "@mui/icons-material";
import { formatServerValue } from "../../../helper";
import { getCategoryNameColor } from "./CampaignHistory";
import { getCampaignHistory } from "../../../redux/actions/chatActions";

const SmsHistory = ({ id, crm, customerInfo }) => {
  const smsCustomerObject = useSelector(
    (state) => state.chats?.smsCustomerObject
  );
  const campaignHistory = useSelector((state) => state.chats?.campaignHistory);
  const getttingCampaignHistory = useSelector(
    (state) => state.chats.getttingCampaignHistory
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //     if (!isObjWithValues(campaignHistory)) dispatch(getCampaignHistory());
  // }, []);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (crm) setRows(smsCustomerObject?.[id] || []);
    else setRows(Object.values(smsCustomerObject || {})?.flat() || []);
    return () => {};
  }, [id, smsCustomerObject]);

  console.log(rows, smsCustomerObject);

  return (
    <>
      {getttingCampaignHistory ? (
        <Stack
          sx={{ height: "60vh" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          rowGap={3}
        >
          <CircularProgress />
          <Typography variant="h6">Getting logs...</Typography>
        </Stack>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  {crm ? null : <TableCell>Customer</TableCell>}
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isArrayWithValues(rows) ? (
                  rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2">
                          {row.template_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            color: getCategoryNameColor(row.category || ""),
                          }}
                        >
                          {formatServerValue(row.category || "")}
                        </Typography>
                      </TableCell>
                      {crm ? null : (
                        <TableCell>
                          <Typography variant="subtitle2">
                            {row?.first_name} {row?.last_name}
                          </Typography>
                          <Typography variant="subtitle2">
                            {row?.phone}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="subtitle2">
                          {row.campaign_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {formatDate(new Date(row.time), "dd:mm:yyyy hh:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {formatServerValue(row.status)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell colSpan={5}>
                      {/* <Typography>No Sms Template found!</Typography> */}
                      <EmptyState text={"No SMS Logs Found!"} />
                    </TableCell>
                  </TableRow>
                  // <TableRow
                  //     colSpan={5}
                  //     sx={{
                  //         display: "flex",
                  //         justifyContent: "center",
                  //         alignItems: "center",
                  //     }}
                  // >
                  //     {/* <Stack justifyContent={"center"} alignItems={"center"}> */}
                  //     <EmptyState
                  //         icon={
                  //             <SmsOutlined
                  //                 style={{ height: "70px", width: "70px" }}
                  //                 color={"action"}
                  //             />
                  //         }
                  //         text={"No SMS Found!"}
                  //     />
                  //     {/* </Stack> */}
                  // </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default SmsHistory;
