import { incomeArray } from "../../pages/customers/components/Info";

let removePlusDialcodeFromPhone = (phone, dialcode) => {
  let dialcodeFromPhone = "";
  if (phone?.includes("+"))
    dialcodeFromPhone = phone?.substring(1, dialcode?.length + 1);
  else dialcodeFromPhone = phone?.substring(0, dialcode?.length);

  if (phone?.includes("+") && dialcodeFromPhone === dialcode)
    return phone?.replace(phone?.substring(0, dialcode?.length + 1), "");
  return phone;
};
function getExcelColumnHeader(number) {
  let header = "";
  while (number > 0) {
    const remainder = (number - 1) % 26; // 0-based index for letters (A=0, B=1, ...)
    const charCode = 65 + remainder; // ASCII code for 'A' + remainder
    header = String.fromCharCode(charCode) + header;
    number = Math.floor((number - 1) / 26);
  }
  return header;
}

const arePropsEqual = (prev, next, props = []) => {
  let propsEqual = true;
  for (let prop of props) {
    if (prev?.[prop] !== next?.[prop]) {
      propsEqual = false;
      break;
    }
  }
  return propsEqual;
};

const deepClone = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  } else if (typeof obj === "object" && obj !== null) {
    const newObj = {};
    for (let key in obj) {
      newObj[key] = deepClone(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
};

const removeWhiteSpace = (value, options = {}, parentKey = "") => {
  if (typeof value === "string") {
    let result = value.trim();

    if (options.removeNewLine && !options.excludeNewLine.includes(parentKey)) {
      result = result.replace(/\n/g, "");
    }

    return result;
  }

  if (Array.isArray(value)) {
    return value.map((v) => removeWhiteSpace(v, options, parentKey));
  }

  if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((acc, key) => {
      acc[key] = removeWhiteSpace(value[key], options, key);
      return acc;
    }, {});
  }

  return value;
};

const removeFalsyValuesFromObject = (obj, options = {}) => {
  const clonedObj = deepClone(obj); // Deep clone the input object

  const filteredEntries = Object.entries(clonedObj)
    .map(([key, value]) => [key, removeWhiteSpace(value, options, key)])
    .filter(([_, value]) => Boolean(value));

  const filteredObj = Object.fromEntries(filteredEntries);

  return filteredObj;
};

const scrollToTagId = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView({ behavior: "smooth", block: "nearest" });
};

function extractNumbers(input) {
  // Validate if the input is a string
  if (typeof input !== "string") return input;

  // Use a regular expression to match all numbers and join them together
  return input.match(/\d+/g) ? input.match(/\d+/g).join("") : "";
}

function trimObjectStrings(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  for (let key in obj) {
    if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    } else if (typeof obj[key] === "object") {
      // If the value is another object, apply the function recursively
      trimObjectStrings(obj[key]);
    }
  }

  return obj;
}

function findIncomeBracket(num) {
  // Convert the number to Lakhs for easier comparison
  const numInLakhs = num / 100000;

  for (const range of incomeArray) {
    if (range.includes("Crore")) {
      let [start, end] = range.split(" - ").map((val) => {
        if (val.includes("Crore")) {
          return parseFloat(val) * 100; // Convert crores to lakhs
        }
        return parseFloat(val.split(" ")[0]); // Get the numeric part of the string
      });

      if (range === "2 Crore & above") {
        if (numInLakhs >= 200) return range;
      } else {
        if (numInLakhs >= start && numInLakhs < end) return range;
      }
    } else {
      let [start, end] = range.split(" - ").map((val) => parseFloat(val));
      if (numInLakhs >= start && numInLakhs < end) return range;
    }
  }
  return null; // Return null if no match found
}
function deepStringifyValues(obj) {
  if (typeof obj !== "object" || obj === null) return String(obj);

  if (Array.isArray(obj)) {
    return obj.map(deepStringifyValues);
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, deepStringifyValues(value)])
  );
}
export {
  removePlusDialcodeFromPhone,
  getExcelColumnHeader,
  arePropsEqual,
  removeFalsyValuesFromObject,
  scrollToTagId,
  extractNumbers,
  trimObjectStrings,
  findIncomeBracket,
  deepStringifyValues,
};
