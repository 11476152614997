import { LiveAgentNode } from "../../utils/LiveAgentNode";

export const createLiveAgentNode = ({ dispatch, nodes, nodeId }) => {
  const currNode = nodes.filter((node) => node.id === nodeId);
  const axis = {
    x: currNode[0].position.x,
    y: currNode[0].position.y,
  };
  LiveAgentNode(dispatch, axis, nodeId);
};
