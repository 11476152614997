import {
  ArrowBack,
  DesktopMacOutlined,
  PhoneIphoneOutlined,
  TabletMacOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";


const EmailPreview = ({ templateString, height, showBack, onBackClick }) => {
  const [previewWidth, setPreviewWidth] = useState("1024px");





  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"}>
        {showBack ? (
          <>
            <IconButton onClick={onBackClick}>
              <ArrowBack />
            </IconButton>
          </>
        ) : null}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={5}
          sx={{ my: 3, flexGrow: 1 }}
        >
          <IconButton
            onClick={() => setPreviewWidth("1024px")}
            sx={{
              borderRadius: "80%",
              backgroundColor:
                previewWidth === "1024px" ? "#eee" : "transparent",
            }}
          >
            <DesktopMacOutlined />
          </IconButton>
          <IconButton
            onClick={() => setPreviewWidth("768px")}
            sx={{
              borderRadius: "80%",
              backgroundColor:
                previewWidth === "768px" ? "#eee" : "transparent",
            }}
          >
            <TabletMacOutlined />
          </IconButton>
          <IconButton
            onClick={() => setPreviewWidth("414px")}
            sx={{
              borderRadius: "80%",
              backgroundColor:
                previewWidth === "414px" ? "#eee" : "transparent",
            }}
          >
            <PhoneIphoneOutlined />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={
          {
            // height: `calc(100vh - ${height || 300}px)`,
            // overflowY: "auto",
          }
        }
        id="iframe-container"
      // className="scrollbar-hidden"
      >
        {/* {imageURL && <img src={imageURL} alt="downloaded-image" />} */}
        <iframe
          srcDoc={templateString}
          width={previewWidth}
          height={"450vh"}
          className="scrollbar-hidden"

        />

      </Stack>
    </Box>
  );
};

export default EmailPreview;
