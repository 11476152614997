import { Popover, Box, Typography } from "@mui/material";

const OptionsPopover = ({
  templates,
  optionsAnchorEl,
  setOptionsAnchorEl,
  handleSelectedOption,
}) => {
  const open = Boolean(optionsAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setOptionsAnchorEl(false);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={optionsAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={{ width: "410px", padding: "5px 0px 5px 0px" }}>
        {templates &&
          templates.length > 0 &&
          templates.map((template, index) => (
            <Box
              onClick={() => handleSelectedOption(template?.flow_name)}
              key={index}
              sx={{
                padding: "7px 30px 7px 20px",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                },
              }}
            >
              <Typography
                variant="span"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {template?.flow_name}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};

export default OptionsPopover;
