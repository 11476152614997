import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import {
  ErrorLabelExtraSmall,
  PrimaryLabelSmall,
  SecondaryLabelSmall,
} from "../../../../../../../helper/utility/Labels";
import CustomChip from "../../../../../../components/CustomChip";
import HorizontalStack from "../../../../../../../helper/utility/HorizontalStack";
import { Add, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getRandomString, isArrayWithValues } from "jwero-javascript-utils";
import { addUpdateNestedSettings } from "../../../../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import { unformatServerValue } from "../../../../../../../helper";
// import { addNestedSettings } from "../../../../../../../redux/actions/settingActions";

const initialForm = {
  flow_id: "",
  keywords: [],
  match_method: "",
};
const filter = createFilterOptions();
const steps = ["Add Keywords", "Select Flow"];

const CreateKeywordAction = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [form, setForm] = React.useState({ ...initialForm });
  const [error, setError] = React.useState("");
  const reduxFlowsList = useSelector((state) => state.flow?.flowsList);
  const updatingNestedSettings = useSelector(
    (state) => state.settings?.updatingNestedSettings
  );
  const reduxFlowSettings = useSelector(
    (state) => state?.settings?.flowSettings
  );
  const [search, setSearch] = React.useState("");
  const [flowsList, setFlows] = React.useState([]);
  const { colors } = useTheme();
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const [allKeywords, setAllKeywords] = React.useState([]);

  React.useEffect(() => {
    if (isArrayWithValues(reduxFlowSettings?.keywords_flow_actions)) {
      setAllKeywords(reduxFlowSettings?.keywords_flow_actions);
    }
  }, [reduxFlowSettings]);

  React.useEffect(() => {
    let keywords = form?.keywords;
    let isUsed = allKeywords?.find(
      (i) =>
        i?.id !== form?.id &&
        i?.keywords
          ?.map((i) => unformatServerValue(i))
          ?.some((i) => keywords?.includes(unformatServerValue(i)))
    );
    let key = isUsed?.keywords?.find((i) => keywords?.includes(i));
    if (isUsed) setError(`"${key}" keyword is already used`);
    else setError("");
  }, [form?.keywords]);

  const editForm = (payload) => {
    if (error) setError(error);
    setForm((state) => ({ ...state, ...payload }));
  };

  React.useImperativeHandle(ref, () => {
    return {
      open() {
        setOpen(true);
        setForm({ ...initialForm });
        setIsEdit(false);
        setActiveStep(0);
      },
      close() {
        setOpen(false);
      },
      openEdit(obj) {
        setIsEdit(true);
        editForm(obj);
        setOpen(true);
      },
    };
  });

  React.useEffect(() => {
    setFlows(reduxFlowsList || []);
  }, [reduxFlowsList]);

  React.useEffect(() => {
    if (isArrayWithValues(reduxFlowsList))
      setFlows((state) =>
        reduxFlowsList?.filter((i) =>
          i?.title?.toLowerCase()?.includes(search?.toLowerCase())
        )
      );
  }, [search]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFlowSelect = (flow) => {
    editForm({ flow_id: flow?.id });
  };

  const onNextPress = () => {
    if (activeStep === 0) {
      if (!isArrayWithValues(form.keywords)) {
        setError("Please select atleast one keyword");
        return;
      }
      setActiveStep(1);
    }
    if (activeStep === 1) onSubmit();
  };

  const onBackPress = () => {
    if (activeStep === 0) handleClose();
    if (activeStep === 1) {
      setActiveStep(0);
    }
  };

  const onSubmit = () => {
    // dispatch(addNestedSettings({ object: form }));
    let object = { ...form };

    if (!isEdit) {
      object.id = getRandomString(8);
      object.created_on = new Date();
    } else {
      object.updated_on = new Date();
    }

    dispatch(
      addUpdateNestedSettings({
        object,
        setting_key: "keywords_flow_actions",
        setting_name: "flow_settings",
        redux_key: "flowSettings",
        onSuccess: () => {
          handleClose();
          setForm(initialForm);
          setIsEdit(false);
          setActiveStep(0);
        },
      })
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {isEdit ? "Edit Keyword Action" : "Create New Keyword Action"}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ px: 3, py: 2 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {activeStep === 0 ? (
              <FirstStep form={form} editForm={editForm} />
            ) : (
              <SecondStep
                onFlowSelect={onFlowSelect}
                setSearch={setSearch}
                search={search}
                flowsList={flowsList}
                form={form}
              />
            )}
          </Box>
        </DialogContent>
        {Boolean(error) && (
          <ErrorLabelExtraSmall sx={{ textAlign: "center" }}>
            {error}
          </ErrorLabelExtraSmall>
        )}
        <DialogActions>
          <Button onClick={onBackPress}>
            {activeStep > 0 ? "Back" : "Close"}
          </Button>
          <LoadingButton
            loading={updatingNestedSettings}
            variant="contained"
            onClick={onNextPress}
            autoFocus
          >
            Next
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default CreateKeywordAction;
//   {/* <CustomChip icon={<Add />} label="Add Keyword" /> */}

const FirstStep = ({ form, editForm }) => {
  return (
    <Box>
      <HorizontalStack sx={{ mt: 2 }}>
        <Autocomplete
          value={form?.keywords}
          onChange={(event, newValue) => {
            editForm({
              keywords: newValue?.map((i) => (i.inputValue ? i.inputValue : i)),
            });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add Keyword "${inputValue}"`,
              });
            }

            return filtered;
          }}
          multiple
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          fullWidth
          id="free-solo-with-text-demo"
          options={[]}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          // sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Enter keywords"
              fullWidth
            />
          )}
        />
      </HorizontalStack>

      <FormControl sx={{ mt: 4 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Message matching methods
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={form.match_method}
          onChange={(e) => editForm({ match_method: e.target.value })}
        >
          <FormControlLabel
            value="contains"
            control={<Radio />}
            label="Contains"
          />
          <FormControlLabel
            control={<Radio />}
            value="exact_match"
            label="Exact Match"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SecondStep = ({ form, onFlowSelect, setSearch, search, flowsList }) => {
  return (
    <Box>
      <TextField
        placeholder="Search flow"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        size="small"
        // variant="stande"
      />
      <Box sx={{ mt: 2 }}>
        {flowsList?.map((flow) => {
          let isSelected = flow?.id === form?.flow_id;
          return (
            <HorizontalStack
              key={flow?.id}
              sx={{
                cursor: "pointer",
                backgroundColor: isSelected ? "#fafafa" : "transparent",
              }}
              onClick={() => onFlowSelect(flow)}
            >
              <Radio checked={isSelected} />
              <PrimaryLabelSmall>{flow?.title}</PrimaryLabelSmall>
            </HorizontalStack>
          );
        })}
      </Box>
    </Box>
  );
};
