import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Popover,
  IconButton,
  TextField,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import PaletteIcon from "@mui/icons-material/Palette";
import { CheckCircleOutline, ModeComment } from "@mui/icons-material";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import {
  PrimaryLabelSmall,
  SecondaryLabel,
} from "../../../../../helper/utility/Labels";

const predefinedColors = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#ABB8C3",
  "#F78DA7",
];

const WebChatIconConfigure = React.forwardRef(({ onConfigureSave }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [form, setForm] = React.useState({
    bot_name: "",
    bot_tagline: "",
    type: "round",
    icon_color: predefinedColors?.[0],
  });
  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));

  React.useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
    setupForm(form) {
      setForm(form);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleColorChange = (newColor) => {
    editForm({ icon_color: newColor });
    handleClosePopover();
  };

  const handleIconTypeChange = (event) => {
    // setIconType(event.target.value);
    editForm({ type: event.target.value });
  };

  const handleClickColorPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const iconPreviewStyle = {
    width: 50,
    height: 50,
    borderRadius: form.type === "round" ? "50%" : "0%",
    // margin: "16px auto",
    backgroundColor: form.icon_color,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };

  const onSave = () => {
    onConfigureSave(form);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Customize your chat icon
      </DialogTitle>
      <DialogContent>
        <SecondaryLabel sx={{ textAlign: "center" }} gutterBottom>
          Preview
        </SecondaryLabel>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              height: 140,
              width: 140,
              borderRadius: "50%",
              backgroundColor: "#EEF0FF",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {form.type === "bar" ? (
              <Box
                style={{
                  height: 30,
                  width: 100,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  backgroundColor: form.icon_color,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <PrimaryLabelSmall
                  style={{ fontWeight: "bold", fontSize: 12, color: "#fff" }}
                >
                  Chat with us!
                </PrimaryLabelSmall>
              </Box>
            ) : form.type === "round" ? (
              <Box style={iconPreviewStyle}>
                <ModeComment style={{ color: "#fff" }} />
              </Box>
            ) : null}
          </Box>
        </Box>
        <TextField
          label="Bot Name"
          fullWidth
          value={form?.bot_name}
          onChange={(e) => editForm({ bot_name: e.target.value })}
          size="small"
          variant="standard"
          sx={{ mt: 1 }}
        />
        <TextField
          label="Bot Tagline"
          fullWidth
          value={form?.bot_tagline}
          onChange={(e) => editForm({ bot_tagline: e.target.value })}
          multiline
          minRows={2}
          size="small"
          sx={{ my: 1, mt: 2 }}
          variant="standard"
        />
        <Typography variant="subtitle1" gutterBottom>
          Select color
        </Typography>
        {/* <Box display="flex" justifyContent="center" alignItems="center" mb={2}> */}
        <HorizontalStack spacing={2}>
          <PaletteIcon
            onClick={handleClickColorPicker}
            style={{ cursor: "pointer" }}
          />
          {predefinedColors.map((presetColor) => {
            let isSelected = form.icon_color === presetColor;
            return (
              <Box
                key={presetColor}
                onClick={() => handleColorChange(presetColor)}
                sx={{
                  maxHeight: 30,
                  maxWidth: 30,
                  width: 30,
                  height: 30,
                  backgroundColor: presetColor,
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: isSelected ? "black" : "transparent",
                  borderStyle: "solid",
                }}
              />
            );
          })}
        </HorizontalStack>
        <Popover
          id="color-picker-popover"
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <HexColorPicker
            color={form.icon_color}
            onChange={(e) => editForm({ icon_color: e })}
          />
        </Popover>
        <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
          Type
        </Typography>
        <RadioGroup row value={form.type} onChange={handleIconTypeChange}>
          <FormControlLabel value="round" control={<Radio />} label="Round" />
          <FormControlLabel value="bar" control={<Radio />} label="Bar" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={onSave} autoFocus variant="outlined">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default WebChatIconConfigure;

// import * as React from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import { SecondaryLabel } from "../../../../../helper/utility/Labels";
// import { Box, useTheme } from "@mui/material";

// const WebChatIconConfigure = React.forwardRef((props, ref) => {
//   const [open, setOpen] = React.useState(false);
//   const { colors } = useTheme();

//   React.useImperativeHandle(ref, () => {
//     return {
//       open() {
//         setOpen(true);
//       },
//       close() {
//         setOpen(false);
//       },
//     };
//   });

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <React.Fragment>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
//           Customize your chat icon as you want
//         </DialogTitle>
//         <DialogContent>
//           <SecondaryLabel sx={{ textAlign: "center" }}>Preview</SecondaryLabel>
//           <Box sx={{ justifyContent: "center", alignItems: "center", my: 3 }}>
//             <Box
//               sx={{
//                 height: 200,
//                 width: 200,
//                 borderRadius: "50%",
//                 backgroundColor: "#EEF0FF",
//               }}
//             ></Box>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Close</Button>
//           <Button onClick={handleClose} autoFocus variant="outlined">
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </React.Fragment>
//   );
// });

// export default WebChatIconConfigure;
