/* eslint-disable default-case */
import {
  Apple,
  Cancel,
  Clear,
  Close,
  Download,
  FemaleSharp,
  Laptop,
  PhoneIphone,
  Refresh,
  Sync,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/system";
import axios from "axios";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  TabPanel,
  dialogStyle,
  getWebsite,
  getToken,
  getAxiosError,
  isObjWithValues,
  formatServerValue,
  isArrayWithValues,
  stableSort,
  getComparator,
  validateNumber,
  sleep,
  getRandomString,
  fetchIPInfo,
  formatDate,
  isDev,
  ResponsiveStackRow,
} from "../../../../helper";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../../../redux/actions/helperActions";
import { fetchTaxonomies } from "../../../../redux/actions/productActions";
import {
  editSettings,
  fetchOrnateHistory,
  fetchOrnateProducts,
  getOrnateProductsFromTanika,
} from "../../../../redux/actions/settingActions";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getIp, tutorialData } from "../../../../App";
import { UAParser } from "ua-parser-js";
import ReactApexChart from "react-apexcharts";
import { Windows } from "../../../../Svgs";
import { ListItem } from "../meta/MetaOverview";
import {
  getTanikaProductFromOrnate,
  updateTanikaBulkProducts,
} from "./ornateHelper";
import { Search, YouTube } from "@material-ui/icons";
let benefits = [
  "✅ Automatically convert out-of-stock products into make-to-order products",
  "✅ Automatic Stock Status updation",
  "✅ Enable the Hallmarked option for products imported from ornate to save time",
  "✅ Auto-Sync as per your requirements",
  "✅ Disable real-time sync for specific products such as title,collection,categories,tags,gender and occasions",
];
function Ornate() {
  const [selectedTab, setSelectedTab] = useState("overview");
  const [formOpen, setFormOpen] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [integrating, setIntegrating] = useState(false);
  const [addForm, setAddForm] = useState({
    ip_port: "",
    username: "",
    password: "",
    ftp_username: "",
    ftp_password: "",
    ftp_port: "",
    // ip_port: "27.116.41.176:98",
    // username: "admin",
    // password: "admin@123",
    // ftp_username: "tab",
    // ftp_password: "nx@123",
    // ftp_port: "21",
  });
  const [error, setError] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [revoking, setRevoking] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState({});
  const [ornateVerificationFailed, setOrnateVerificationFailed] =
    useState(false);
  const reduxOrnateIntegration = useSelector(
    (state) => state.settings.ornateIntegration
  );
  const reduxOrnateProducts = useSelector(
    (state) => state.settings.ornateProducts
  );
  const fetchingOrnateProducts = useSelector(
    (state) => state.settings.fetchingOrnateProducts
  );
  const ornateProductsInTanika = useSelector(
    (state) => state.settings.ornateProductsInTanika
  );
  const ornateProductsInTanikaFetched = useSelector(
    (state) => state.settings.ornateProductsInTanikaFetched
  );
  const reduxOrnateHistory = useSelector(
    (state) => state.settings.ornateHistory
  );
  const fetchingOrnateProductsInTanika = useSelector(
    (state) => state.settings.fetchingOrnateProductsInTanika
  );
  const ornateApiCalls = useSelector((state) => state.settings.ornateApiCalls);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const userInfo = useSelector((state) => state.user);

  const fetchingIntegration = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const fetchedOrnateProducts = useSelector(
    (state) => state.settings.fetchedOrnateProducts
  );

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());
    // if (!reduxOrnateProducts) dispatch(fetchOrnateProducts());
  }, []);
  useEffect(() => {
    if (!fetchedOrnateProducts) dispatch(fetchOrnateProducts());
  }, [reduxOrnateIntegration, fetchedOrnateProducts]);

  useEffect(() => {
    if (isObjWithValues(reduxOrnateIntegration)) {
      let { ornate_auth } = reduxOrnateIntegration;
      if (isObjWithValues(ornate_auth)) {
        setIsIntegrated(true);
        setConnectedAccount(ornate_auth);
        if (!reduxOrnateHistory)
          dispatch(fetchOrnateHistory(ornate_auth.ip_port));
      } else {
        setIsIntegrated(false);
        setConnectedAccount({});
      }
    }
  }, [reduxOrnateIntegration]);

  const dispatch = useDispatch();

  const closeForm = () => setFormOpen(false);
  const closeRevoke = () => setRevokeOpen(false);

  const openForm = () => setFormOpen(true);
  const openRevoke = () => setRevokeOpen(true);

  const editForm = (payload) => {
    if (error) setError(false);
    if (ornateVerificationFailed) setOrnateVerificationFailed(false);
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const verifyIntegration = async (ip_port, username, password) => {
    let website = await getWebsite();
    let token = await getToken();
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/ornate?endpoint=/OnlineJewelleryShopping/GenerateToken_API`,
        // url: `https://${ip}/OnlineJewelleryShopping/GenerateToken_API`,
        method: "POST",
        data: {
          payload: {
            ip_port,
            username,
            password,
          },
        },
        // data: {
        //   Token: [
        //     {
        //       UserName,
        //       Password,
        //     },
        //   ],
        // },
      });
      console.log(data);
      if (isObjWithValues(data)) return data.success;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const onIntegrate = async () => {
    let { ip_port, username, password, ftp_username, ftp_password, ftp_port } =
      addForm;
    if (
      !ip_port ||
      !username ||
      !password ||
      !ftp_username ||
      !ftp_password ||
      !ftp_port
    )
      return setError(true);
    let obj = {
      ornate_auth: {
        ip_port,
        username,
        password,
        ftp_username,
        ftp_password,
        ftp_port,
      },
    };
    setIntegrating(true);
    let ornateToken;
    for await (let temp of Array.from(new Array(4))) {
      ornateToken = await verifyIntegration(ip_port, username, password);
      console.log(ornateToken, "<<<<");
      if (ornateToken) break;
    }
    if (!ornateToken) {
      setIntegrating(false);
      return setOrnateVerificationFailed(true);
    }
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { type: "ornate_integration", data: obj },
        });
        console.log(data);
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateIntegration: data.data }));
          setFormOpen(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Ornate Integrated successfully",
              severity: "success",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setIntegrating(false);
    }
  };
  const onRevoke = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setRevoking(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { type: "ornate_integration", data: { ornate_auth: {} } },
        });
        console.log(data);
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateIntegration: data.data }));
          setRevokeOpen(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Ornate revoked successfully",
              severity: "success",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setRevoking(false);
    }
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Stack
        sx={{
          position: "sticky",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          //   padding: "12px",
          top: "0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: 3,
                ml: 3,
              }}
            >
              <img
                src="/static/img/brands/ornate.png"
                width="24px"
                height="24px"
              />
              Ornate
              <IconButton
                onClick={() =>
                  dispatch(
                    setGlobalTutorial({
                      show: true,
                      url: tutorialData?.ornate?.link,
                      title: tutorialData?.ornate?.title,
                    })
                  )
                }
              >
                <YouTube />
              </IconButton>
            </Typography>
          </Box>

          {false && (
            <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
              <CircularProgress size={"15px"} />{" "}
              <Typography>Fetching Integrations...</Typography>
            </Stack>
          )}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" /> */}
          {/* <Tab label="Details" value="details" /> */}
          <Tab label="Integration" value="integration" />
          {reduxOrnateIntegration &&
            isObjWithValues(reduxOrnateIntegration.ornate_auth) && (
              <Tab label="Settings" value="settings" />
            )}
        </Tabs>
      </Stack>
      <Box sx={{ p: 1.8 }}>
        <TabPanel value={selectedTab} index={"overview"}>
          <Box sx={{ padding: "16px" }}>
            <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
              Ornate an ERP Software jewellery management and Billing software
              that allows their customers to easily manage and track multiple
              products available at multiple branches.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              Benefits of Integrating Ornate
            </Typography>
            <ListItem arr={benefits} />
            <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
              By integrating Ornate, You can instantly automate your product
              upload,stock status updation,product sync and many more
              information instantly.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
              With just a single click publish your products from ornate to
              website in no-time. The level of simplicity will enable your
              jewellery business to focus more customers than your daily manual
              product chores.
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={"how_to_do"}>
          <Typography>How to do</Typography>
        </TabPanel>
        {/* <TabPanel value={selectedTab} index={"details"}>
        <Typography>Details</Typography>
      </TabPanel> */}
        <TabPanel value={selectedTab} index={"integration"}>
          <Integration
            //   client_id={client_id}
            //   redirect_url={redirect_url}
            //   authUrl={authUrl}
            connectedAccount={connectedAccount}
            fetching={fetchingIntegration}
            integrated={isIntegrated}
            openForm={openForm}
            openRevoke={openRevoke}
          />
        </TabPanel>
        <TabPanel
          value={selectedTab}
          sx={{
            display:
              reduxOrnateIntegration &&
              isObjWithValues(reduxOrnateIntegration.ornate_auth)
                ? "block"
                : "none",
          }}
          index={"settings"}
        >
          <Settings
            reduxOrnateIntegration={reduxOrnateIntegration}
            taxonomies={taxonomies}
            reduxOrnateProducts={reduxOrnateProducts}
            reduxOrnateHistory={reduxOrnateHistory}
            userInfo={userInfo}
            ornateProductsInTanika={ornateProductsInTanika}
            ornateProductsInTanikaFetched={ornateProductsInTanikaFetched}
            fetchingOrnateProducts={fetchingOrnateProducts}
            ornateApiCalls={ornateApiCalls}
            fetchedOrnateProducts={fetchedOrnateProducts}
            fetchingOrnateProductsInTanika={fetchingOrnateProductsInTanika}
          />
        </TabPanel>
      </Box>
      <Dialog
        open={formOpen}
        onClose={closeForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle variant="h5" id="alert-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            Ornate Integration
            <IconButton onClick={closeForm}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Label>IPAddress:Port</Label>
          <TextField
            value={addForm.ip_port}
            onChange={(e) => editForm({ ip_port: e.target.value })}
            fullWidth
            size="small"
            placeholder="For e.g. 103.10.234.244:100"
          />
          <Grid container spacing={4} rowSpacing={4}>
            <Grid item xs={6}>
              <Label>Ornate NX Username</Label>
              <TextField
                value={addForm.username}
                onChange={(e) => editForm({ username: e.target.value })}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Label>Ornate NX Password</Label>
              <TextField
                value={addForm.password}
                onChange={(e) => editForm({ password: e.target.value })}
                fullWidth
                size="small"
                type={"password"}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, mb: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Label>FTP Username</Label>
              <TextField
                value={addForm.ftp_username}
                onChange={(e) => editForm({ ftp_username: e.target.value })}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Label>FTP Password</Label>
              <TextField
                value={addForm.ftp_password}
                onChange={(e) => editForm({ ftp_password: e.target.value })}
                fullWidth
                size="small"
                type={"password"}
              />
            </Grid>
            <Grid item xs={4}>
              <Label>FTP Port</Label>
              <TextField
                value={addForm.ftp_port}
                onChange={(e) => editForm({ ftp_port: e.target.value })}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
          <Collapse in={error}>
            <Alert
              severity="error"
              variant="standard"
              style={{
                backgroundColor: "transparent",
                padding: "0px",
                marginTop: "7px",
              }}
            >
              All Fields required
            </Alert>
          </Collapse>
          <Collapse in={ornateVerificationFailed}>
            <Alert
              severity="error"
              variant="standard"
              style={{
                backgroundColor: "transparent",
                padding: "0px",
                marginTop: "7px",
              }}
            >
              Credentials are invalid!
            </Alert>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForm}>Cancel</Button>
          <LoadingButton loading={integrating} onClick={onIntegrate} autoFocus>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={revokeOpen}
        onClose={closeRevoke}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        sx={{ ...dialogStyle }}
      >
        <DialogTitle variant="h5" id="alert-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            Revoke Ornate Integration?
            <IconButton onClick={closeRevoke}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRevoke}>Cancel</Button>
          <LoadingButton onClick={onRevoke} loading={revoking} color="error">
            Revoke
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Ornate;

const Integration = ({
  openForm,
  fetching,
  openRevoke,
  connectedAccount,
  integrated,
}) => {
  return (
    <Box sx={{ px: { xs: 0, md: 3 } }}>
      <>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          mt={6}
          sx={{ gap: "30px" }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <img
                src="/static/img/brands/ornate.png"
                width="20px"
                height="20px"
              />
              Ornate
            </Typography>
          </Box>
          <LoadingButton
            loading={fetching}
            size="small"
            onClick={() => (integrated ? openRevoke() : openForm())}
            variant={"contained"}
            color={integrated ? "error" : "primary"}
          >
            {integrated ? "Revoke" : "Integrate"}
          </LoadingButton>

          {/* {integratedApp.includes(obj.value) && (
            <LoadingButton
              loading={revoking}
              variant="outlined"
              color="error"
              size="small"
              onClick={() => onRevokeGoogle(obj.value)}
            >
              Revoke
            </LoadingButton>
          )} */}
        </Stack>
        {isObjWithValues(connectedAccount) && (
          <Typography
            variant="h6"
            sx={{ mt: 1, fontSize: "13px", fontWeight: "bold" }}
          >
            Connected Account: {connectedAccount.ip_port} (
            {connectedAccount.username})
          </Typography>
        )}
        <Typography
          sx={{
            mt: 4,
            color: "text.secondary",
            fontSize: "13px",
            maxWidth: "800px",
          }}
        >
          ORNATE NX is the Next Generation Jewellery Store Management Software
          that enables business owners to manage back-end and front-end business
          operations through a system that doesn't require constant human
          involvement.
        </Typography>
      </>
    </Box>
  );
};

const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Mapping",
    value: "taxonomy",
  },
  {
    label: "Products",
    value: "products",
  },
  { label: "Settings", value: "settings" },
  { label: "Activity", value: "history" },
];

const temp = [
  { label: "Title", value: "title" },
  { label: "Collections", value: "collections" },
  { label: "Categories", value: "categories" },
  { label: "Gender", value: "gender" },
  { label: "Ocassions", value: "ocassions" },
  { label: "Tags", value: "tags" },
];

const Settings = ({
  reduxOrnateIntegration,
  taxonomies,
  reduxOrnateProducts,
  reduxOrnateHistory,
  userInfo,
  fetchingOrnateProducts,
  ornateProductsInTanika,
  ornateProductsInTanikaFetched,
  ornateApiCalls,
  fetchedOrnateProducts,
  fetchingOrnateProductsInTanika,
}) => {
  const [selectedTab, setSelectedTab] = useState("overview");

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <Box sx={{ display: selectedTab === "overview" ? "block" : "none" }}>
        <OrnateOverview
          reduxOrnateIntegration={reduxOrnateIntegration}
          taxonomies={taxonomies}
          reduxOrnateProducts={reduxOrnateProducts}
          userInfo={userInfo}
          ornateApiCalls={ornateApiCalls}
          ornateIntegration={reduxOrnateIntegration}
        />
      </Box>
      <Box sx={{ display: selectedTab === "taxonomy" ? "block" : "none" }}>
        <OrnateTaxonomy
          reduxOrnateIntegration={reduxOrnateIntegration}
          taxonomies={taxonomies}
        />
      </Box>
      <Box sx={{ display: selectedTab === "products" ? "block" : "none" }}>
        <OrnateProducts
          reduxOrnateIntegration={reduxOrnateIntegration}
          reduxOrnateProducts={reduxOrnateProducts}
          fetchingOrnateProducts={fetchingOrnateProducts}
          ornateProductsInTanika={ornateProductsInTanika}
          ornateProductsInTanikaFetched={ornateProductsInTanikaFetched}
          fetchedOrnateProducts={fetchedOrnateProducts}
          fetchingOrnateProductsInTanika={fetchingOrnateProductsInTanika}
        />
      </Box>
      <Box sx={{ display: selectedTab === "settings" ? "block" : "none" }}>
        <OrnateSettings reduxOrnateIntegration={reduxOrnateIntegration} />
      </Box>
      <Box sx={{ display: selectedTab === "history" ? "block" : "none" }}>
        <OrnateActivity reduxOrnateIntegration={reduxOrnateIntegration} />
      </Box>
    </Box>
  );
};

const OrnateOverview = ({
  reduxOrnateProducts,
  userInfo,
  ornateApiCalls,
  ornateIntegration,
}) => {
  const [data, setData] = useState([]);
  const [tanikaInventory, setTanikaInventory] = useState("");
  const [ornateInventory, setOrnateInventory] = useState(0);
  const [ornateImported, setOrnateImported] = useState(0);
  const [ornateCalls, setOrnateCalls] = useState(0);
  const reduxOrnateHistory = useSelector(
    (state) => state.settings.ornateHistory
  );

  useEffect(() => {
    if (isObjWithValues(reduxOrnateProducts)) {
      let { LabelDetail } = reduxOrnateProducts;
      if (isArrayWithValues(LabelDetail))
        setOrnateInventory(LabelDetail.length);
    }
  }, [reduxOrnateProducts]);
  useEffect(() => {
    if (isObjWithValues(ornateApiCalls)) {
      let total = 0;
      Object.values(ornateApiCalls).map((i) => (total += validateNumber(i)));
      setOrnateCalls(total);
    }
  }, [ornateApiCalls]);
  useEffect(() => {
    if (isObjWithValues(reduxOrnateHistory)) {
      let array = [];
      let totalProducts = 0;
      for (let historyObj of Object.values(reduxOrnateHistory)) {
        let { products, start } = historyObj;
        if (isArrayWithValues(products)) {
          array.push([Number(start) * 1000, products.length || 0]);
          totalProducts += products.length;
        }
      }
      setData(array);
      setOrnateImported(totalProducts);
      console.log(array);
    }
  }, [reduxOrnateHistory]);

  useEffect(() => {
    if (isObjWithValues(userInfo)) {
      let { store_used, store_plan } = userInfo;
      let string = "";
      if (isObjWithValues(store_used)) string = `${store_used.products}/`;
      if (isObjWithValues(store_plan))
        string = `${string}${store_plan.add_products || ""}`;
      setTanikaInventory(string);
    }
  }, [userInfo]);
  return (
    <Box sx={{ p: 3, pt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard title="Jwero Inventory" value={tanikaInventory} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard title="Ornate Inventory" value={ornateInventory} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard title="Imported" value={ornateImported} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard
            title="API Calls"
            value={ornateCalls}
            subtitle={
              ornateIntegration.last_sync_time
                ? formatDate(
                    new Date(ornateIntegration.last_sync_time),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : ""
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 6 }}>
        <ReactApexChart
          options={{
            ...importOptions,
            selection: "all",
            xaxis: {
              type: "datetime",
              min: isArrayWithValues(data)
                ? new Date(data[0][0]).getTime()
                : new Date("01 Oct 2022").getTime(),
              tickAmount: 6,
            },
          }}
          series={[{ name: "Imports", data }]}
          type="area"
          height={350}
        />
      </Box>
    </Box>
  );
};

const importOptions = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },

  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

export const AnalyticsCard = ({
  title,
  value,
  suffix,
  backgroundColor,
  subtitle,
}) => {
  return (
    <Card
      sx={{
        border: "0.5px solid #e5eef8",
        height: "100%",
        backgroundColor: backgroundColor || "#f8f8f8",
      }}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Typography variant="h5" fontSize="12px" fontWeight={"500"}>
          {title}
        </Typography>
        <Stack mt={2} alignItems="flex-end" direction={"row"} flexWrap="wrap">
          <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
            {value}
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            fontSize="16px"
            color="text.secondary"
          >
            {suffix || ""}
          </Typography>
        </Stack>
        {subtitle ? (
          <Typography fontSize="11px" color="text.secondary" mt={1}>
            {subtitle}
          </Typography>
        ) : null}
      </CardContent>
    </Card>
  );
};

const headCells = [
  {
    id: "sr",
    numeric: false,
    disablePadding: true,
    label: "Sr.",
  },
  {
    id: "label",
    numeric: false,
    disablePadding: true,
    label: "Label No",
  },
  {
    id: "product",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "last_sync",
    numeric: true,
    disablePadding: false,
    label: "Last Synced",
  },
  //   {
  //     id: "carbs",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Carbs (g)",
  //   },
  //   {
  //     id: "protein",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Protein (g)",
  //   },
];

const OrnateActivity = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  const reduxOrnateHistory = useSelector(
    (state) => state.settings.ornateHistory
  );

  useEffect(() => {
    if (isObjWithValues(reduxOrnateHistory))
      setRows(Object.values(reduxOrnateHistory).reverse());
  }, [reduxOrnateHistory]);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Sr.</TableCell>
              <TableCell>History</TableCell>
              <TableCell>Imported</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>IP</TableCell>
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              let { ua } = row;
              let os = new UAParser(ua).getOS();
              let browser = new UAParser(ua).getBrowser();
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox" align="left">
                    <Typography sx={{ ml: 3, fontWeight: "600" }}>
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography variant="h6" sx={{ fontSize: "13px" }}>
                      {row.start
                        ? formatDate(
                            new Date(row.start * 1000),
                            "dd:mm:yyyy hh:mm am/pm"
                          )
                        : ""}
                    </Typography>
                    {row.is_manual ? (
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "text.secondary",
                          mt: 1,
                        }}
                      >
                        Automatic
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {row?.imported || row?.products?.length || 0}
                  </TableCell>
                  {/* <TableCell>{row.totalProductError || 0}</TableCell> */}
                  <TableCell>{row?.updated || 0}</TableCell>
                  <TableCell padding="checkbox" sx={{ fontWeight: "600" }}>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      {os && os.name === "Mac OS" ? (
                        <Apple style={{ color: "#666666" }} />
                      ) : os.name === "Windows" ? (
                        <Windows style={{ color: "#666666" }} />
                      ) : null}
                      {os && (os.name === "Mac OS" || os.name === "Windows") ? (
                        <Laptop width="20px" height="20px" />
                      ) : (
                        <PhoneIphone />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {isObjWithValues(row.location)
                        ? `${row.location.city || ""} ${row.location.state} ${
                            row.location.country
                          }`
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {row.ip}
                    </Typography>
                  </TableCell>
                  {/* <TableCell  align="right">
                  {row.fat}
                </TableCell> */}
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

const OrnateProducts = ({
  reduxOrnateProducts,
  reduxOrnateIntegration,
  ornateProductsInTanika,
  ornateProductsInTanikaFetched,
  fetchingOrnateProducts,
  fetchedOrnateProducts,
  fetchingOrnateProductsInTanika,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [importOpen, setImportOpen] = useState(false);
  const [importForm, setImportForm] = useState({
    status: false,
    importImages: false,
  });
  const [importedSku, setImportedSku] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importingProgress, setImportingProgress] = useState(0);
  const openImport = () => setImportOpen(true);
  const closeImport = () => setImportOpen(false);

  const reduxOrnateHistory = useSelector(
    (state) => state.settings.ornateHistory
  );

  const [filterForm, setFilterForm] = useState({
    show: false,
    stone: "",
    sync_status: "",
    metal: "",
    search: "",
  });

  const editFilterForm = (payload) =>
    setFilterForm((state) => ({ ...state, ...payload }));

  const setupProducts = (LabelDetail, options) => {
    if (isArrayWithValues(LabelDetail) || options?.force_empty) {
      let array = [];
      for (let obj of LabelDetail) {
        let { ItemName, LabelNo, UniqueLabelID, TotalAmt } = obj;
        array.push({
          name: ItemName,
          id: validateNumber(UniqueLabelID)
            ? validateNumber(UniqueLabelID)
            : UniqueLabelID,
          label: LabelNo,
          TotalAmt: Number(TotalAmt),
        });
      }
      setRows(array);
    }
  };

  useEffect(() => {
    if (
      isObjWithValues(reduxOrnateProducts) &&
      !ornateProductsInTanikaFetched &&
      !fetchingOrnateProductsInTanika
    )
      dispatch(getOrnateProductsFromTanika());
    if (isObjWithValues(reduxOrnateProducts) && ornateProductsInTanikaFetched) {
      let { LabelDetail, StuddedDetail } = reduxOrnateProducts;
      let { stone, sync_status, metal, show, search } = filterForm;
      if (show || search) {
        let array = [];
        for (let obj of LabelDetail) {
          let { UniqueLabelID: id, ItemName, LabelNo } = obj;
          // if (
          //   (stone ? obj.MetalName === stone : false) &&
          //   (metal ? obj.MetalName === metal : false) &&
          //   (sync_status === "SYNC"
          //     ? importedSku.includes(obj.LabelNo)
          //     : false) &&
          //   (sync_status === "NOT_SYNC"
          //     ? !importedSku.includes(obj.LabelNo)
          //     : false)
          // ) {
          //   array.push(obj);
          // }
          let shouldAdd = true;
          if (
            search &&
            // !ItemName?.includes(search) &&
            !LabelNo?.toLowerCase()?.includes(search?.toLowerCase())
          )
            shouldAdd = false;
          if (stone) {
            if (
              stone === "diamond" &&
              !isArrayWithValues(
                StuddedDetail.filter(
                  (i) =>
                    i.UniqueLabelId == id &&
                    i.IsBase === "N" &&
                    i.MetalName === "DIAMOND"
                )
              )
            )
              shouldAdd = false;
            if (
              stone === "gemstone" &&
              !isArrayWithValues(
                StuddedDetail.filter(
                  (i) =>
                    Number(i.UniqueLabelId) == id &&
                    i.IsBase === "N" &&
                    i.MetalName === "STONE"
                )
              )
            )
              shouldAdd = false;
          }
          if (metal && obj.MetalName !== metal) {
            shouldAdd = Boolean(
              StuddedDetail.find(
                (i) =>
                  i.UniqueLabelId == id &&
                  i.IsBase === "N" &&
                  i.TradOrOtChg === "0" &&
                  i.MetalName === metal
              )
            );
          }
          if (sync_status === "SYNC" && !importedSku.includes(obj.LabelNo))
            shouldAdd = false;
          if (sync_status === "NOT_SYNC" && importedSku.includes(obj.LabelNo))
            shouldAdd = false;
          console.log(
            stone === "diamond" &&
              !isArrayWithValues(
                StuddedDetail.filter(
                  (i) =>
                    i.UniqueLabelId == id &&
                    i.IsBase === "N" &&
                    i.MetalName === "DIAMOND"
                )
              ),
            stone === "gemstone" &&
              !isArrayWithValues(
                StuddedDetail.filter(
                  (i) =>
                    Number(i.UniqueLabelId) == id &&
                    i.IsBase === "N" &&
                    i.MetalName === "STONE"
                )
              ),
            metal &&
              obj.MetalName !== metal &&
              StuddedDetail.find(
                (i) =>
                  i.UniqueLabelId == id &&
                  i.IsBase === "N" &&
                  i.TradOrOtChg === "0" &&
                  i.MetalName === metal
              )
          );
          if (shouldAdd) array.push(obj);
        }
        setupProducts(array, { force_empty: true });
      } else setupProducts(LabelDetail);
    }
  }, [filterForm, reduxOrnateProducts, fetchedOrnateProducts]);

  const dispatch = useDispatch();
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const editImportForm = (payload) =>
    setImportForm((state) => ({ ...state, ...payload }));

  // useEffect(() => {
  //   let array = [];
  //   if (isObjWithValues(reduxOrnateHistory)) {
  //     for (let obj of Object.values(reduxOrnateHistory)) {
  //       let { products } = obj;
  //       if (isArrayWithValues(products))
  //         array = array.concat(products.map((i) => i.sku));
  //     }
  //   }
  //   setImportedSku(array);
  // }, [reduxOrnateHistory]);
  useEffect(() => {
    let array = [];
    if (isArrayWithValues(ornateProductsInTanika)) {
      // array  =
      setImportedSku(ornateProductsInTanika.map((i) => i.sku));
      // for (let obj of Object.values(ornateProductsInTanika)) {
      //   let { sku } = obj;
      //   if (isArrayWithValues(products))
      //     array = array.concat(products.map((i) => i.sku));
      // }
    } else setImportedSku([]);
  }, [ornateProductsInTanika]);

  useEffect(() => {
    if (isObjWithValues(reduxOrnateProducts) && ornateProductsInTanikaFetched) {
      let { LabelDetail } = reduxOrnateProducts;
      setupProducts(LabelDetail);
    }
  }, [reduxOrnateProducts, ornateProductsInTanikaFetched]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.filter((i) => !importedSku.includes(i.label));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, obj) => {
    if (importedSku.includes(obj.label)) return;
    const selectedIndex = selected.findIndex((i) => i.id == obj.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, obj);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (obj) => selected.findIndex((i) => i.id == obj.id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const startImport = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (
      website &&
      token &&
      reduxOrnateIntegration &&
      reduxOrnateIntegration.ornate_auth
    ) {
      setImporting(true);
      let products = await getAllProducts();
      dispatch({
        type: "EDIT_SETTINGS",
        payload: {
          ornateProductsInTanika: [
            ...(ornateProductsInTanika || []),
            ...(products || []),
          ],
        },
      });
      let productsToCreate = [...products];
      // return;
      let historyObj = {
        id: getRandomString(8),
        start: Math.floor(Date.now() / 1000),
        ip: await getIp(),
        ua: new UAParser().getUA(),
        // ip:
      };
      let location = await fetchIPInfo(historyObj.ip);
      if (isObjWithValues(location)) {
        let { city, region, postal, country } = location;
        historyObj.location = {
          city,
          state: region,
          postcode: postal,
          country,
        };
      }
      let productSuccess = [],
        productError = 0;
      let length = parseInt(productsToCreate.length / 100) || 1;
      let percentToAdd = 100 / length;
      for (let i of new Array(length)) {
        let obj = {
          create: productsToCreate.splice(0, 100),
        };
        let responseObj = {};
        try {
          let { data: bulkCreateRes } = await axios({
            url: `${website}/wp-json/wc/v3/products/batch`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: obj,
            method: "POST",
          });
          responseObj = bulkCreateRes;
        } catch (e) {
          let error = getAxiosError(e);
          console.log(error);
          if (error) responseObj = error;
        }
        console.log(responseObj);
        if (responseObj && isArrayWithValues(responseObj.create))
          for (let obj of responseObj.create) {
            let { error, id, sku } = obj;
            if (error) productError++;
            else productSuccess.push({ id, sku });
          }
        await sleep(100);
        setImportingProgress((state) => Math.floor(state + percentToAdd));
      }
      historyObj.products = productSuccess;
      historyObj.imported = productSuccess?.length || 0;
      historyObj.totalProductError = productError;
      historyObj.end = Math.floor(Date.now() / 1000);
      if (!isDev())
        await addHistroyObj(
          { [historyObj.id]: historyObj },
          reduxOrnateIntegration.ornate_auth.ip_port
        );
      setImporting(false);
      setImportingProgress(0);
      closeImport();
      setSelected([]);
      dispatch(getOrnateProductsFromTanika());
      dispatch(
        setGlobalToast({
          show: true,
          message: "Products Imported successfully!",
          severity: "success",
        })
      );
    }
  };

  const addHistroyObj = async (obj, port) => {
    console.log(obj, port);
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_history_${port}`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: { type: `ornate_history_${port}`, data: obj },
        });
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateHistory: data.data }));
        }
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const getAllProducts = async () => {
    let array = [];
    for (let obj of selected) {
      let { id } = obj;
      let product = await getTanikaProductFromOrnateToImport(id, importForm);
      if (product) {
        array.push(product);
      }
    }
    return array;
  };

  const getTanikaProductFromOrnateToImport = async (id, options) => {
    let { LabelDetail, StuddedDetail } = reduxOrnateProducts;
    let { ornate_taxonomy } = reduxOrnateIntegration;
    let sku_from = "";
    if (isObjWithValues(ornate_taxonomy)) sku_from = ornate_taxonomy.sku_from;
    let productObj = {
      status: options.status ? "publish" : "draft",
      stock_status: "instock",
      stock_quantity: 1,
      manage_stock: true,
      backorders: "no",
      backorders_allowed: false,
      backordered: false,
    };
    let metaObj = {};
    let labelObj = LabelDetail.find((i) => i.UniqueLabelID == id);

    // let extra_charges = StuddedDetail.filter((i) => {
    //   if (
    //     i.UniqueLabelId == labelObj.UniqueLabelID &&
    //     i.IsBase === "N" &&
    //     !i.MetalName
    //   )
    //     return {
    //       extra_charge_label: i.StyleName,
    //       extra_charge_value: i.SalesAmount,
    //     };
    //   return false;
    // });
    let extra_charges = StuddedDetail.map((i) => {
      if (
        i.UniqueLabelId == labelObj.UniqueLabelID &&
        i.IsBase === "N" &&
        !i.MetalName
      )
        return {
          // extra_charge_label: i.StyleName,
          extra_charge_label: `${i.StyleName} (${i?.NetWt || ""} ${
            i?.SalesRate ? ` x @${i.SalesRate}` : ""
          })`,
          extra_charge_value: i.SalesAmount,
        };
      return false;
    }).filter(Boolean);
    if (isArrayWithValues(extra_charges))
      metaObj.extra_charges = { ...extra_charges };
    let diamondArray = StuddedDetail.filter(
      (i) =>
        i.UniqueLabelId == labelObj.UniqueLabelID &&
        i.IsBase === "N" &&
        i.MetalName === "DIAMOND"
    );
    let stoneArray = StuddedDetail.filter(
      (i) =>
        Number(i.UniqueLabelId) == labelObj.UniqueLabelID &&
        i.IsBase === "N" &&
        i.MetalName === "STONE"
    );
    let goldObj = StuddedDetail.find(
      (i) =>
        Number(i.UniqueLabelId) == labelObj.UniqueLabelID &&
        // i.IsBase === "N" &&
        // i.TradOrOtChg === "0" &&
        i.MetalName === "GOLD"
    );
    let silverObj = StuddedDetail.find(
      (i) =>
        i.UniqueLabelId == labelObj.UniqueLabelID &&
        // i.IsBase === "N" &&
        // i.TradOrOtChg === "0" &&
        i.MetalName === "SILVER"
    );
    let platinumObj = StuddedDetail.find(
      (i) =>
        i.UniqueLabelId == labelObj.UniqueLabelID &&
        // i.IsBase === "N" &&
        // i.TradOrOtChg === "0" &&
        i.MetalName === "PLATINUM"
    );

    let metalNames = ["DIAMOND", "STONE", "GOLD", "SILVER", "PLATINUM"];
    let brandObj = StuddedDetail.find(
      (i) =>
        i.UniqueLabelId == labelObj.UniqueLabelID &&
        // i.IsBase === "N" &&
        // i.TradOrOtChg === "0" &&
        // i.MetalName === "BRANDED"
        i.MetalName &&
        !metalNames.includes(i.MetalName)
    );

    if (!goldObj) goldObj = brandObj;
    if (brandObj) metaObj.manual_price = labelObj?.SalesPrice;

    if (isObjWithValues(labelObj)) {
      let {
        LabelNo,
        BarcodeNo,
        LabourOn,
        LabourRate,
        SalWastPer,
        SlsWastOnStr,
        LabourAmt,
        ItemName,
        LabourPer,
        HUID,
        GoldRate,
        LabourGoldRate,
      } = labelObj;
      if (HUID) metaObj.huid = [HUID];

      if (
        ornate_taxonomy?.manual_price &&
        labelObj?.[ornate_taxonomy?.manual_price] &&
        labelObj?.[ornate_taxonomy?.manual_price] !== "0"
      )
        metaObj.manual_price = labelObj?.[ornate_taxonomy?.manual_price];

      if (
        ornate_taxonomy?.collections &&
        labelObj?.[ornate_taxonomy?.collections] &&
        labelObj?.[ornate_taxonomy?.collections] !== "0"
      )
        productObj.collections = [labelObj?.[ornate_taxonomy?.collections]];
      if (
        ornate_taxonomy?.categories &&
        labelObj?.[ornate_taxonomy?.categories] &&
        labelObj?.[ornate_taxonomy?.categories] !== "0"
      )
        productObj.category = [labelObj?.[ornate_taxonomy?.categories]];
      if (
        ornate_taxonomy?.["sub-categories"] &&
        labelObj?.[ornate_taxonomy?.["sub-categories"]] &&
        labelObj?.[ornate_taxonomy?.["sub-categories"]] !== "0"
      )
        productObj["subcategory"] = [
          labelObj?.[ornate_taxonomy?.["sub-categories"]],
        ];
      let paths = [];
      for (let i of [1, 2, 3, 4, 5, 6])
        if (labelObj[`ImagePath${i}`]) paths.push(labelObj[`ImagePath${i}`]);

      if (isArrayWithValues(paths) && options.importImages)
        productObj.images = await getImages(paths);
      productObj.name = ItemName;
      productObj.sku = sku_from === "barcode" ? BarcodeNo : LabelNo;
      metaObj._sku = sku_from === "barcode" ? BarcodeNo : LabelNo;
      metaObj.ornate_unique_id = id;
      metaObj.labourType = "individual";
      metaObj.custom_wastage_from = SlsWastOnStr?.includes("Net")
        ? "net"
        : "gross";

      switch (LabourOn) {
        case `Net Wt %.`:
        case "Net Wt.":
        case `Gross Wt.`:
        case "Gross Wt. %":
          metaObj.labour_from = LabourOn.includes("Net") ? "net" : "gross";
          metaObj.making_from =
            LabourOn === "Net Wt." || LabourOn === "Gross Wt." ? "labour" : "";

          if (LabourPer) {
            if (GoldRate !== LabourGoldRate && LabourGoldRate && LabourRate) {
              metaObj.labour = LabourRate;
              metaObj.per_gram = LabourRate;
              metaObj.wastage_percent = "";
            } else {
              metaObj.wastage_percent = LabourPer;
              metaObj.labour = "";
              metaObj.per_gram = "";
              metaObj.minimum_labour = "";
            }
          } else {
            if (LabourRate) {
              metaObj.labour = LabourRate;
              metaObj.per_gram = LabourRate;
            }

            if (SalWastPer) {
              metaObj.wastage_from = "rate";
              metaObj.wastage_percent = SalWastPer;
            }
            if (!LabourRate || !SalWastPer) {
              metaObj.minimum_labour = LabourAmt;
            }
          }
          break;
        case "Pcs":
          metaObj.labour_from = "gross";
          metaObj.making_from = "";
          metaObj.wastage_from = "";
          metaObj.wastage_percent = "";
          metaObj.labour = "";
          metaObj.minimum_labour = Math.round(LabourAmt);
          break;
      }
    }
    let metals = [];
    const goldPurities = {
      23.99976: "999.99",
      23.976: "999",
      23.88: "995",
      // 24: "999",
      22: "916",
      18: "750",
      14: "583",
      9: "375",
    };
    if (isObjWithValues(goldObj)) {
      let { StyleGrossWt, NetWt, Purity } = goldObj;
      metaObj.gold_gross = StyleGrossWt;
      metaObj.gold_net = NetWt;

      let labelObjGoldPurity = labelObj?.[ornate_taxonomy?.gold_kt];
      if (
        labelObjGoldPurity &&
        labelObjGoldPurity !== "0" &&
        [
          "58.30",
          "75",
          "76",
          "91.67",
          "91.60",
          "99.50",
          "99.90",
          "99.99",
          "100",
        ]?.includes(labelObjGoldPurity) &&
        labelObjGoldPurity >= 91.0 &&
        labelObjGoldPurity <= 92.0
      )
        Purity = labelObjGoldPurity;

      if (Purity == "58.30") metaObj.gold_kt = 14;
      else if (Purity == "75" || Purity == "76") metaObj.gold_kt = 18;
      else if (
        (Purity >= 91.0 && Purity <= 92.0) ||
        Purity == "91.67" ||
        Purity == "91.60"
      )
        metaObj.gold_kt = 22;
      else if (Purity == "99.50") metaObj.gold_kt = 23.88;
      else if (Purity == "99.90") metaObj.gold_kt = 23.976;
      else if (Purity == "99.99" || Purity == "100") metaObj.gold_kt = 23.99976;

      metaObj.gold_kt = goldPurities[metaObj.gold_kt];
      metals.push("gold");
    }
    // if (isObjWithValues(goldObj)) {
    //   let { StyleGrossWt, NetWt, Purity } = goldObj;
    //   metaObj.gold_gross = StyleGrossWt;
    //   metaObj.gold_net = NetWt;
    //   if (Purity == "58.30") metaObj.gold_kt = 14;
    //   else if (Purity == "75") metaObj.gold_kt = 18;
    //   else if (
    //     (Purity >= 91.0 && Purity <= 92.0) ||
    //     Purity == "91.67" ||
    //     Purity == "91.60"
    //   )
    //     metaObj.gold_kt = 22;
    //   else if (Purity == "99.50") metaObj.gold_kt = 23.88;
    //   else if (Purity == "99.90") metaObj.gold_kt = 23.976;
    //   else if (Purity == "99.99") metaObj.gold_kt = 23.99976;
    //   metals.push("gold");
    // }
    if (isObjWithValues(silverObj)) {
      let { StyleGrossWt, NetWt, Purity } = silverObj;
      metaObj.silver_gross = StyleGrossWt || NetWt;
      metaObj.silver_net = NetWt;

      let labelObjSilverPurity = labelObj?.[ornate_taxonomy?.silver_purity];
      if (labelObjSilverPurity && labelObjSilverPurity !== "0")
        if (labelObjSilverPurity == 100) Purity = 999;

      if (Purity == 100) {
        metaObj.silver_purity = 999;
      } else {
        metaObj.silver_purity = 925;
      }
      metals.push("silver");
    }
    if (isObjWithValues(platinumObj)) {
      let { StyleGrossWt, NetWt, Purity } = platinumObj;
      metaObj.platinium_gross = StyleGrossWt || NetWt;
      metaObj.platinium_net = NetWt;

      let labelObjPlatiniumPurity =
        labelObj?.[ornate_taxonomy?.platinium_purity];
      if (labelObjPlatiniumPurity && labelObjPlatiniumPurity !== "0")
        if (labelObjPlatiniumPurity == 100) Purity = 950;

      if (Purity == 100) {
        metaObj.platinium_purity = 950;
      } else {
        metaObj.platinium_purity = 900;
      }
      metals.push("platinium");
    }
    if (metals.length > 1) metaObj.metal_type = "multi";
    else if (isArrayWithValues(metals)) {
      metaObj.metal_type = metals[0];
      metaObj.metal_types = metals;
    }

    if (isArrayWithValues(diamondArray)) {
      let diamondMetaObj = {};
      let index = 1;
      for (let diamond of diamondArray) {
        let { NetWt, Pcs, CutShape, Clarity, Colour, SizeID, SalesRate } =
          diamond;
        diamondMetaObj[`${index}`] = {
          diamond_shape: CutShape,
          diamond_size: SizeID,
          diamond_quality: "",
          diamond_type: "natural",
          diamond_weight: NetWt,
          diamond_pieces: Pcs,
          diamond_rate: Math.round(validateNumber(SalesRate)),
        };
        index++;
      }
      metaObj.diamond = diamondMetaObj;
      metaObj.diamond_from = "individual";
    }
    if (isArrayWithValues(stoneArray)) {
      let colorstoneMetaObj = {};
      let index = 1;
      for (let colorstone of stoneArray) {
        let { NetWt, Pcs, CutShape, Clarity, Colour, SizeID, SalesAmount } =
          colorstone;
        colorstoneMetaObj[`${index}`] = {
          colorstone_shape: CutShape,
          colorstone_size: SizeID,
          colorstone_quality: "",
          colorstone_weight: NetWt,
          colorstone_pieces: Pcs,
          colorstone_rate: Math.round(validateNumber(SalesAmount)),
        };
        index++;
      }
      metaObj.colorstone_details = colorstoneMetaObj;
      metaObj.colorstone_from = "individual";
    }
    let meta_data = [];
    for (let key of Object.keys(metaObj)) {
      meta_data.push({ key, value: metaObj[key] });
    }
    productObj.meta_data = meta_data;
    return productObj;
  };

  const getImages = async (paths) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/channel/ornate?endpoint=/get_images`,
          headers: { Authorization: `Basic ${token}` },
          data: {
            payload: paths,
          },
          method: "POST",
        });
        let array = [];
        console.log(data);
        if (isObjWithValues(data?.data))
          Object.values(data?.data).map((i) => array.push(i));
        return array;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };

  const getSyncDate = (sku) => {
    if (isArrayWithValues(ornateProductsInTanika)) {
      let obj = ornateProductsInTanika.find((i) => i.sku === sku);
      if (obj)
        return formatDate(
          new Date(obj.date_modified),
          "dd:mm:yyyy hh:mm am/pm"
        );
      return "";
    }
    // if (isObjWithValues(reduxOrnateHistory)) {
    //   for (let obj of Object.values(reduxOrnateHistory)) {
    //     let { products, start } = obj;
    //     if (isArrayWithValues(products) && products.find((i) => i.sku === sku))
    //       return formatDate(new Date(start * 1000), "dd:mm:yyyy hh:mm am/pm");
    //   }
    // }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          openImport={openImport}
          setFilterForm={setFilterForm}
          fetchingOrnateProducts={fetchingOrnateProducts}
          ornateProductsInTanika={ornateProductsInTanika}
          ornateProducts={reduxOrnateProducts}
          ornateIntegration={reduxOrnateIntegration}
          reduxOrnateIntegration={reduxOrnateIntegration}
          fetchingOrnateProductsInTanika={fetchingOrnateProductsInTanika}
          addHistroyObj={addHistroyObj}
          editFilter={editFilterForm}
        />
        <Collapse in={filterForm.show}>
          <Stack direction="row" alignItems={"center"} spacing={3}>
            <FormControl sx={{ minWidth: "140px" }} size="small">
              <InputLabel id="demo-simple-select-label">Metal Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Metal Type"
                value={filterForm.metal}
                onChange={(e) => editFilterForm({ metal: e.target.value })}
                endAdornment={
                  <IconButton
                    sx={{
                      display: filterForm.metal ? "block" : "none",
                      mr: 1.8,
                    }}
                    onClick={() => editFilterForm({ metal: "" })}
                    size={"small"}
                  >
                    <Clear
                      style={{
                        height: "18px",
                        width: "18px",
                        marginTop: "4px",
                      }}
                    />
                  </IconButton>
                }
              >
                <MenuItem value={"GOLD"}>Gold</MenuItem>
                <MenuItem value={"SILVER"}>Silver</MenuItem>
                <MenuItem value={"PLATINUM"}>Platinum</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "140px" }} size="small">
              <InputLabel id="demo-simple-select-label">Stone</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Stone"
                value={filterForm.stone}
                onChange={(e) => editFilterForm({ stone: e.target.value })}
                endAdornment={
                  <IconButton
                    sx={{
                      display: filterForm.stone ? "block" : "none",
                      mr: 1.8,
                    }}
                    onClick={() => editFilterForm({ stone: "" })}
                    size={"small"}
                  >
                    <Clear
                      style={{
                        height: "18px",
                        width: "18px",
                        marginTop: "4px",
                      }}
                    />
                  </IconButton>
                }
              >
                <MenuItem value={"diamond"}>Diamond</MenuItem>
                <MenuItem value={"gemstone"}>Gemstone</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "140px" }} size="small">
              <InputLabel id="demo-simple-select-label">Sync Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sync Status"
                value={filterForm.sync_status}
                onChange={(e) =>
                  editFilterForm({ sync_status: e.target.value })
                }
                endAdornment={
                  <IconButton
                    sx={{
                      display: filterForm.sync_status ? "block" : "none",
                      mr: 1.8,
                    }}
                    onClick={() => editFilterForm({ sync_status: "" })}
                    size={"small"}
                  >
                    <Clear
                      style={{
                        height: "18px",
                        width: "18px",
                        marginTop: "4px",
                      }}
                    />
                  </IconButton>
                }
              >
                <MenuItem value={"SYNC"}>Sync</MenuItem>
                <MenuItem value={"NOT_SYNC"}>Not Sync</MenuItem>
              </Select>
            </FormControl>
            <Button
              startIcon={<Cancel sx={{ height: "17px", width: "17px" }} />}
              sx={{ ml: 2 }}
              color="error"
              onClick={() =>
                setFilterForm((state) => ({
                  ...state,
                  metal: "",
                  stone: "",
                  sync_status: "",
                }))
              }
            >
              Clear
            </Button>
          </Stack>
        </Collapse>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={
                rows?.filter((i) => !importedSku.includes(i.label)).length
              }
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          size="small"
                          disabled={importedSku.includes(row.label)}
                        />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        align="left"
                        sx={{ fontWeight: "600" }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        {row.label}
                      </TableCell>
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          //   justifyContent="space-between"
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            {row.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          size="small"
                          // mb={1}
                          label={"Instock"}
                          // color="warning"
                          sx={{
                            color: "#1aae6f",
                            border: isDarkTheme ? "0.7px solid" : "0px solid",
                            fontSize: "12px",
                            ml: 1.5,
                            // borderColor: statusFontColor(row.status),
                            backgroundColor: isDarkTheme
                              ? "transparent"
                              : "#e0f6ed",
                          }}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: "600", fontSize: "13px" }}
                      >
                        {row.TotalAmt}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "12px" }}>
                        {getSyncDate(row.label)}
                      </TableCell>
                      {/* <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction={"row"} justifyContent="flex-start">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Paper>
      <Dialog
        open={importOpen}
        onClose={importing ? null : closeImport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle variant="h5" id="alert-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            Ornate Import
            <IconButton disabled={importing} onClick={closeImport}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 0 }}
          >
            <Typography variant="h5" fontSize="15px">
              Import Images?
            </Typography>
            <Switch
              onChange={(e) => {
                editImportForm({ importImages: e });
              }}
              disabled={importing}
              checked={importForm.importImages}
              style={{
                border: `1px solid ${
                  importForm.importImages ? backgroundColor : "#e8e8e8"
                }`,
                backgroundColor: importForm.importImages
                  ? backgroundColor
                  : "#e8e8e8",
              }}
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 3 }}
          >
            <Typography variant="h5" fontSize="15px">
              Publish Products on Website?
            </Typography>
            <Switch
              onChange={(e) => {
                editImportForm({ status: e });
              }}
              disabled={importing}
              checked={importForm.status}
              style={{
                border: `1px solid ${
                  importForm.status ? backgroundColor : "#e8e8e8"
                }`,
                backgroundColor: importForm.status
                  ? backgroundColor
                  : "#e8e8e8",
              }}
            />
          </Stack>
          {/* <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={importForm.importImages}
                  onChange={(e) =>
                    editImportForm({
                      importImages: !importForm.importImages,
                    })
                  }
                />
              }
              label="Publish products on website"
            />
          </FormGroup> */}
          {/* <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={importForm.status === "publish"}
                  onChange={(e) =>
                    editImportForm({
                      status:
                        importForm.status === "publish" ? "draft" : "publish",
                    })
                  }
                />
              }
              label="Publish products on website"
            />
          </FormGroup> */}
        </DialogContent>
        <DialogActions sx={{ px: 4, mb: 3 }}>
          {/* <Button onClick={closeImport}>Cancel</Button> */}
          {/* <Button variant="outlined" onClick={closeImport} fullWidth autoFocus>
            Start Import
          </Button> */}
          <Box sx={{ width: "100%" }}>
            <LoadingButton
              loading={importing}
              loadingPosition="start"
              // startIcon={<Box sx={{width: "20px"}} />}
              variant="outlined"
              onClick={startImport}
              fullWidth
            >
              {importing ? `${importingProgress}% imported...` : "Start Import"}
            </LoadingButton>
            <Collapse in={importing}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  color: "error.main",
                  textAlign: "center",
                  mt: 1,
                }}
              >
                Please dont refresh page while importing!
              </Typography>
            </Collapse>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            size="small"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    openImport,
    setFilterForm,
    fetchingOrnateProducts,
    ornateProductsInTanika,
    ornateProducts,
    ornateIntegration,
    reduxOrnateIntegration,
    addHistroyObj,
    editFilter,
    search,
    fetchingOrnateProductsInTanika,
  } = props;
  const [showHistory, setShowHistory] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [syncing, setSyncing] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const closeHistory = () => setShowHistory(false);

  const addOrnateApiCalls = async (calls) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_api_calls`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "ornate_api_calls",
            data: {
              [new Date().getTime()]: calls,
            },
          },
        });
        console.log(data);
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateApiCalls: data.data }));
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };

  const addLastSyncTime = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "ornate_integration",
            data: {
              last_sync_time: new Date().getTime(),
            },
          },
        });
        console.log(data);
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateIntegration: data.data }));
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };

  const onSyncPress = async () => {
    setSyncing(true);
    let website = await getWebsite();
    let token = await getToken();
    let oldOrnateData = ornateProducts;
    console.log(ornateIntegration);
    let { ornate_settings } = ornateIntegration || {};

    // const ornateProductsInTanika = await _getOrnateProductsFromTanika(
    //   website,
    //   token
    // );
    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      // ip:
      imported: 0,
      products: [],
    };
    let location = await fetchIPInfo(historyObj.ip);
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    // let ornateData = ornateProducts;
    let ornateData;
    let fetchTries = 3;
    for await (let i of Array.from(new Array(fetchTries))) {
      ornateData = await getDataFromOrnate(website, token);
      if (ornateData?.LabelDetail) break;
    }
    // let ornateData = oldOrnateData;
    if (!isDev()) await addOrnateApiCalls(ornateData?.LabelDetail?.length);
    // setSyncing(false);
    // return;
    let isFetched = false;
    isFetched = ornateData?.Status?.[0]?.Status === "Sucess";
    if (!isFetched) {
      // if (!ornateData?.Status?.[0]?.Status === "Sucess") {
      setSyncing(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Ornate sync failed, please try again!",
          severity: "error",
        })
      );
    }
    let tanikaData = await setDataToTanika(website, token, ornateData);
    console.log(tanikaData, "<<tanikaData>>");
    if (!tanikaData) {
      setSyncing(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Ornate sync failed, please try again!",
          severity: "error",
        })
      );
    }
    console.log("tanikaProductsToUpdate");
    let tanikaProductsToUpdate = [];
    if (isArrayWithValues(ornateProductsInTanika))
      for await (let productObj of ornateProductsInTanika) {
        let { meta_data, id } = productObj;
        if (!isArrayWithValues(meta_data)) meta_data = [];
        let ornate_id = meta_data.find((i) => i.key === "ornate_unique_id");
        ornate_id = ornate_id?.value;
        if (!ornate_id) ornate_id = productObj.sku;
        if (!ornate_id) continue;

        // if (isDev() && ornate_id !== "WA/13") continue;
        let product = await getTanikaProductFromOrnate(
          ornate_id,
          ornateProducts,
          {
            ornate_integration: ornateIntegration,
          }
        );
        if (product) tanikaProductsToUpdate.push({ ...product, id });
        else {
          let obj = {};
          if (ornate_settings.maketoorder) {
            obj = {
              id,
              stock_status: "onbackorder",
              manage_stock: false,
              backorders: "yes",
              backorders_allowed: true,
              backordered: true,
            };
          } else
            obj = {
              id,
              stock_status: "outofstock",
              manage_stock: false,
              backorders: "no",
              backorders_allowed: false,
              backordered: false,
            };
          tanikaProductsToUpdate.push({
            ...obj,
          });
        }
      }
    console.log(
      tanikaProductsToUpdate?.find(
        (obj) => obj?.sku === "86a/228451"
        // ?.includes("Necklaces") || obj?.id == 19949
      )
    );
    // tanikaProductsToUpdate

    // if (isDev()) return;
    if (isArrayWithValues(tanikaProductsToUpdate)) {
      let updateBatchLength =
        Math.round(tanikaProductsToUpdate.length / 100) || 1;
      let productsToUpdate = [...tanikaProductsToUpdate];
      for await (let temp of Array.from(new Array(updateBatchLength))) {
        await updateTanikaBulkProducts(
          website,
          token,
          productsToUpdate.splice(0, 100)
        );
      }
      historyObj.updated = tanikaProductsToUpdate.length;
    }
    historyObj.end = Math.floor(Date.now() / 1000);
    if (!isDev()) {
      await addHistroyObj(
        { [historyObj.id]: historyObj },
        reduxOrnateIntegration?.ornate_auth?.ip_port
      );
      await addLastSyncTime();
    }
    setSyncing(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Ornate Sync Success!",
        severity: "success",
      })
    );
  };

  const _getOrnateProductsFromTanika = async (website, token) => {
    try {
      let array = [];
      let page = 1;
      let per_page = 500;
      for (let i of Array(100)) {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products`,
          headers: { Authorization: `Basic ${token}` },
          // headers: { apikey: `${token}` },
          params: {
            per_page,
            page,
            "custom_filter[0][key]": "ornate_unique_id",
            "custom_filter[0][value]": "",
            "custom_filter[0][compare]": "LIKE",
            // "custom_filter[0][type]": "NUMERIC",
          },
        });
        if (isArrayWithValues(data)) {
          array = array.concat(data);
          if (data.length < per_page) break;
          await sleep(page < 10 ? page * 1000 : 3000);
        }
        page++;
      }
      return array;
    } catch (error) {
      console.log(getAxiosError(error), "fetching orante products from tanika");
    }
  };

  const getDataFromOrnate = async (website, token) => {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/ornate?endpoint=/OnlineJewelleryShopping/GetLabelData_API`,
        headers: { Authorization: `Basic ${token}` },
        method: "POST",
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  const setDataToTanika = async (website, token, payload) => {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/ornate_product_details`,
        headers: { Authorization: `Basic ${token}` },
        data: { type: "ornate_product_details", data: payload },
        method: "POST",
      });
      return true;
      // return data?.success || data?.type === "success";
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
      }}
    >
      <ResponsiveStackRow
        firstElement={
          numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "12px",
                color: "text.secondary",
                flex: "1 1 100%",
              }}
            >
              Last Sync:{" "}
              {ornateIntegration.last_sync_time
                ? formatDate(
                    new Date(ornateIntegration.last_sync_time),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : null}
            </Typography>
          )
        }
        secondElement={
          <Stack direction="row" alignItems={"center"}>
            <TextField
              variant="standard"
              // fullWidth
              placeholder="Search SKU "
              value={search}
              onChange={(e) => editFilter({ search: e.target.value })}
              size="small"
              sx={{ mr: 2, width: "200px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Search sx={{ mr: 1, mb: 1 }} />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              size="small"
              sx={{ whiteSpace: "nowrap" }}
              loading={fetchingOrnateProducts || fetchingOrnateProductsInTanika}
              onClick={() => dispatch(fetchOrnateProducts())}
            >
              Refresh
            </LoadingButton>
            <LoadingButton
              size="small"
              variant="outlined"
              sx={{ whiteSpace: "nowrap" }}
              onClick={onSyncPress}
              disabled={
                syncing ||
                fetchingOrnateProducts ||
                fetchingOrnateProductsInTanika
              }
              // loading={fetchingOrnateProducts || fetchingOrnateProductsInTanika}
              startIcon={<Sync className={syncing ? "infinite_rotate" : ""} />}
            >
              Sync
            </LoadingButton>

            {/* <IconButton
          className={syncing ? "infinite_rotate" : ""}
          onClick={onSyncPress}
          disabled={fetchingOrnateProducts || fetchingOrnateProductsInTanika}
        >
          <Sync />
        </IconButton> */}
            {numSelected > 0 ? (
              <Button
                size="small"
                variant="contained"
                startIcon={<Download sx={{ height: "18px", width: "18px" }} />}
                sx={{ ml: 2 }}
                onClick={openImport}
              >
                Import
              </Button>
            ) : (
              <Tooltip title="Filter list">
                <IconButton
                  onClick={() =>
                    setFilterForm((state) => ({ ...state, show: !state.show }))
                  }
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />

      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
      </Menu> */}
      <Dialog
        open={showHistory}
        onClose={closeHistory}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle variant="h5" id="alert-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            Ornate History
            <IconButton onClick={closeHistory}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <OrnateActivity />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={closeHistory}>Disagree</Button>
          <Button onClick={closeHistory} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const OrnateTaxonomy = ({
  taxonomies: _taxonomies,
  reduxOrnateIntegration,
}) => {
  const [form, setForm] = useState({
    sku_from: "",
  });
  const [updating, setUpdating] = useState(false);
  const [taxonomies, setTaxonomies] = useState({});
  const dispatch = useDispatch();
  const [ornateFields, setOrnateFields] = useState(OrnateNXFileds);

  // useEffect(() => {
  //   let selectedValues = Object.values(form);
  //   setOrnateFields(
  //     OrnateNXFileds?.filter((i) => !selectedValues?.includes(i.value))
  //   );
  // }, [form]);

  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));

  useEffect(() => {
    if (isObjWithValues(_taxonomies))
      setTaxonomies({
        ..._taxonomies,
        manual_price: [],
        gold_purity: [],
        silver_purity: [],
        platinium_purity: [],
      });
  }, [_taxonomies]);

  useEffect(() => {
    if (isObjWithValues(reduxOrnateIntegration)) {
      let { ornate_taxonomy } = reduxOrnateIntegration;
      if (isObjWithValues(ornate_taxonomy)) editForm(ornate_taxonomy);
    }
  }, [reduxOrnateIntegration]);

  const onUpdate = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setUpdating(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "ornate_integration",
            data: {
              ornate_taxonomy: {
                ...form,
                gold_kt: form?.gold_purity,
                gold_purity: undefined,
              },
            },
          },
        });
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateIntegration: data.data }));
          //   setRevokeOpen(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Ornate settings updated successfully",
              severity: "success",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setUpdating(false);
    }
  };

  return (
    <Box sx={{ px: { xs: 0, md: 3 }, mt: 3 }}>
      <Stack direction="row" justifyContent={"flex-end"} mt={4}>
        <LoadingButton
          size="small"
          loading={updating}
          onClick={onUpdate}
          variant="contained"
        >
          Update Settings
        </LoadingButton>
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3 }}
      >
        <Typography variant="h5" fontSize="15px">
          Map SKU With
        </Typography>
        <FormControl sx={{ minWidth: "200px" }} size="small">
          {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.sku_from}
            // label="Age"
            onChange={(e) => editForm({ sku_from: e.target.value })}
          >
            <MenuItem value={"label"}>Label No.</MenuItem>
            <MenuItem value={"barcode"}>Barcode No.</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Divider sx={{ my: 3 }} />
      {isObjWithValues(taxonomies) &&
        Object.keys(taxonomies).map((key) => {
          return (
            <>
              <Box sx={{ mt: 3, mb: 3 }}>
                <Typography variant="h5" fontSize="15px">
                  {formatServerValue(key)}
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Label>Field to be created in Ornate NX </Label>
                  <FormControl sx={{ minWidth: "100px" }} size="small">
                    {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={form[key] || ""}
                      // label="Age"
                      onChange={(e) => editForm({ [key]: e.target.value })}
                    >
                      {ornateFields.map((obj) => (
                        <MenuItem value={obj.value}>{obj.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Grid container spacing={2}>
                {isArrayWithValues(taxonomies[key]) &&
                  taxonomies[key].map((obj, index) => {
                    if (obj.label)
                      return (
                        <Grid item xs={6} sm={4} md={4}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              fontStyle: "italic",
                            }}
                          >
                            {/* {index + 1}. */}
                            {obj.label}
                          </Typography>
                        </Grid>
                      );
                  })}
              </Grid>
              <Divider sx={{ mt: 3 }} />
            </>
          );
        })}
    </Box>
  );
};

export const syncTimes = [
  { label: "Every half hour", value: "tanika_every_half_hour" },
  { label: "Every hour", value: "tanika_every_hour" },
  { label: "Every 6 hours", value: "tanika_6hour" },
  { label: "Every 12 hours", value: "tanika_12hour" },
  { label: "Daily", value: "tanika_daily" },
  { label: "Weekly", value: "tanika_weekly" },
  { label: "Every 15 days", value: "tanika_fifteendays" },
  { label: "Monthly", value: "tanika_monthly" },
];

const OrnateSettings = ({ reduxOrnateIntegration }) => {
  const [settingsForm, setSettingsForm] = useState({
    maketoorder: false,
    hallmarked: false,
    autosync_disable: [],
    sync_time: "",
  });
  const [updating, setUpdating] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const editForm = (payload) =>
    setSettingsForm((state) => ({ ...state, ...payload }));

  useEffect(() => {
    if (isObjWithValues(reduxOrnateIntegration)) {
      let { ornate_settings } = reduxOrnateIntegration;
      if (isObjWithValues(ornate_settings)) editForm(ornate_settings);
    }
  }, [reduxOrnateIntegration]);

  const handleDisableAutoSync = (event) => {
    const {
      target: { value },
    } = event;
    editForm({
      autosync_disable: typeof value === "string" ? value.split(",") : value,
    });
  };

  const onUpdate = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setUpdating(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "ornate_integration",
            data: { ornate_settings: settingsForm },
          },
        });
        console.log(data);
        if (data.success && isObjWithValues(data.data)) {
          dispatch(editSettings({ ornateIntegration: data.data }));
          //   setRevokeOpen(false);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Ornate settings updated successfully",
              severity: "success",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setUpdating(false);
    }
  };

  return (
    <Box sx={{ px: { xs: 0, md: 3 }, mt: 3 }}>
      <Stack direction="row" justifyContent={"flex-end"} mt={4}>
        <LoadingButton
          loading={updating}
          onClick={onUpdate}
          variant="contained"
          size="small"
        >
          Update Settings
        </LoadingButton>
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Sync Time
        </Typography>
        <FormControl size="small" sx={{ width: "180px" }}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={settingsForm.sync_time}
            onChange={(e) => editForm({ sync_time: e.target.value })}
          >
            {syncTimes.map((i) => {
              return <MenuItem value={i.value}>{i.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Stack>
      {/* <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        Enabling this option to yes will make outofstock products automatically
        to make-to-order
      </Typography> */}
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Make sold products to Make to Order
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ maketoorder: e });
          }}
          checked={settingsForm.maketoorder}
          style={{
            border: `1px solid ${
              settingsForm.maketoorder ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: settingsForm.maketoorder
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        Enabling this option to yes will make outofstock products automatically
        to make-to-order
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Enable Hallmarked ?
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ hallmarked: e });
          }}
          checked={settingsForm.hallmarked}
          style={{
            border: `1px solid ${
              settingsForm.hallmarked ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: settingsForm.hallmarked
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        Enabling this option to yes will make any product hallmarked as yes by
        default.
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 4 }}
      >
        <Box>
          <Typography variant="h5" fontSize="15px">
            Disable Auto-Sync from Ornate NX ?
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
          >
            Selected fields will not be auto-synced from ornate.
          </Typography>
        </Box>
        <FormControl size="small" sx={{ m: 1, width: 270 }}>
          {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={settingsForm.autosync_disable}
            onChange={handleDisableAutoSync}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
                {selected.map((value) => (
                  <Chip
                    size="small"
                    key={value}
                    label={formatServerValue(value)}
                  />
                ))}
              </Box>
            )}
            //   MenuProps={MenuProps}
          >
            {temp.map((obj) => (
              <MenuItem
                key={obj.value}
                value={obj.value}
                //   style={getStyles(obj, personobj, theme)}
              >
                {obj.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

const OrnateNXFileds = [
  { label: "USF1", value: "USF1" },
  { label: "USF2", value: "USF2" },
  { label: "USF3", value: "USF3" },
  { label: "USF4", value: "USF4" },
  { label: "USF5", value: "USF5" },
  { label: "USF6", value: "USF6" },
  { label: "USF7", value: "USF7" },
  { label: "USF8", value: "USF8" },
  { label: "USF9", value: "USF9" },
  { label: "USF10", value: "USF10" },
  { label: "USF11", value: "USF11" },
  { label: "USF12", value: "USF12" },
  { label: "USF13", value: "USF13" },
  { label: "USF14", value: "USF14" },
  { label: "USF15", value: "USF15" },
  { label: "USF16", value: "USF16" },
  { label: "USF17", value: "USF17" },
  { label: "USF18", value: "USF18" },
  { label: "USF19", value: "USF19" },
  { label: "USF20", value: "USF20" },
  { label: "USF21", value: "USF21" },
  { label: "USF22", value: "USF22" },
  { label: "USF23", value: "USF23" },
  { label: "USF24", value: "USF24" },
  { label: "USF25", value: "USF25" },
  { label: "Type", value: "type" },
];

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 8px;
`;
