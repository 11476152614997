import {
  Avatar,
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDate, getMediaType, validateNumber } from "../../helper";
const headCells = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "fileSize",
    // numeric: true,
    // disablePadding: false,
    label: "File Size",
  },
  {
    id: "dateUploaded",
    numeric: true,
    disablePadding: false,
    label: "Date Uploaded",
  },
  {
    id: "uploadedby",
    numeric: true,
    disablePadding: false,
    label: "Uploaded by",
  },
];

const ImageTable = ({ medias, setViewImage, onSelect, selected }) => {
  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  // onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell?.numeric ? "right" : "left"}
                  // padding={headCell?.disablePadding ? 'none' : 'normal'}
                  // sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                  // active={orderBy === headCell.id}
                  // direction={orderBy === headCell.id ? order : 'asc'}
                  // onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {/* {orderBy === headCell.id ? (
                                    <Box component="span" >
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null} */}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {medias.map((row, index) => {
              return (
                <TableRowList
                  key={index}
                  data={row}
                  setViewImage={setViewImage}
                  selected={selected}
                />
              );
            })}
            {/* {paddingHeight > 0 && (
                            <TableRow
                                style={{
                                    height: paddingHeight,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ImageTable;

const TableRowList = ({ data, setViewImage, onSelect, selected }) => {
  let { date } = data || {};
  console.log(data);
  let link = data?.source_url;
  let name = data?.file_name;
  let size = validateNumber(data?.size);
  let type = getMediaType(data);
  let previewLink = link;
  switch (type) {
    case "image":
      previewLink = link;
      break;
    case "video":
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vCLTuAiQsXzSXVUrh-CcrJELuFsiSBnCzQ&usqp=CAU";
      break;
    case "document":
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0iHXcwKuDP2PpsQvqkETZ7Q40pZpKUmOl6HFvRUtVLnqeBB60feXVfzz-9s6k_uvYKek&usqp=CAU";
      break;

    default:
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH-rFxLV6Tl1tfiqrM-vXnC2Il3VTORXmHU70t6p-rZIPlBJ8RQFs2LEOljagz1vb0s5U&usqp=CAU";
      break;
  }
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.name)}
      role="checkbox"
      // aria-checked={isItemSelected}
      // tabIndex={-1}
      key={name}
      // selected={isItemSelected}

      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          // checked={isItemSelected}
          // inputProps={{
          //     "aria-labelledby": labelId,
          // }}
          checked={selected?.some((item) => item?.id === data?.id)}
          onChange={() => onSelect && onSelect(data)}
        />
      </TableCell>
      <TableCell
        component="th"
        // id={labelId}
        scope="row"
        // padding="none"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          onClick={() => setViewImage && setViewImage(data)}
        >
          <Avatar src={previewLink} />
          <Stack>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              {name}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        {Number(parseFloat(size / 1024 / 1024).toFixed(2))} mb
      </TableCell>
      <TableCell>
        {formatDate(new Date(date), "dd:mm:yyyy hh:mm am/pm")}
      </TableCell>
      <TableCell></TableCell>
      {/* <TableCell >{row.protein}</TableCell> */}
    </TableRow>
  );
};
