import { initialNodes } from "../utils/initialNodes";
import { initialEdges } from "../utils/initialEdges";
import * as actions from "../../../../../redux/actions/flowsActions";
import { backendApiCall } from "../../../../../helper";
export const fetchElements = async (dispatch, id) => {
  const nodes = initialNodes;
  const edges = initialEdges;

  try {
    if (id) {
      const res = await backendApiCall({ endpoint: `flow/${id}` });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: {
          loading: false,
          flow_details: res?.data,
          flowChannels: res?.data?.channels,
        },
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { loaderProgress: 100 },
      });
      dispatch({
        type: actions.SET_NODES,
        payload: res?.data?.nodes,
      });
      dispatch({
        type: actions.SET_EDGES,
        payload: res?.data?.edges,
      });
      dispatch({
        type: actions.SET_TITLE,
        payload: res?.data?.title,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: res?.data?.theme_mode,
      });
    } else {
      const initialNodes = [
        nodes.startNode,
        nodes.defaultFallbackNode,
        nodes.BotNodeFallback,
        nodes.BotNode,
      ];
      const initialEdges = [edges.startEdge, edges.startBot, edges.defaultBot];

      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { nodes: initialNodes },
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { edges: initialEdges },
      });
      dispatch({ type: actions.SET_TITLE, payload: "Untitled flow " });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { loading: false },
      });
    }
  } catch (err) {
    console.log(err);
  }
};
