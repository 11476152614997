import axios from "axios";
import {
  apiCallFromSocket,
  backendApiCall,
  backendApiEndpoints,
  backendDoamin,
  capitalizeFirstLetter,
  createGoogleCalendarEvent,
  fetchJweroCustomers,
  findMostOpenedTimePeriod,
  formatDate,
  getAuthToken,
  getAxiosError,
  getConversationById,
  getCustomerTotal,
  getFullName,
  getGoogleEventObjByEvent,
  getIsBackendMigrated,
  getLastMessageFromObj,
  getNameFromPlatform,
  getRandomString,
  getSignalMsgTemplate,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  msg91Params,
  reduceLargeText,
  validateNumber,
} from "../../helper";
import {
  filterSubmitHandler,
  returnOptOutParams,
} from "../../pages/customers/components/EmailTemplate";
import { SET_CRM_DATA } from "./crmActions";
import { setGlobalToast } from "./helperActions";
import { addUserActivity } from "./hubActions";
import { setUpFlowHistory } from "./campaignActions";
import {
  backendEndpoints,
  bulkActionChatUser,
  getChatDashboard,
  getChatsUsers,
  readMessageFunction,
  settingApi,
} from "../../pages/chat_new/apiFunctions";
import { unformatChatUser } from "../../utils/chat_user";

export const EDIT_CHATS = "EDIT_CHATS";
export const EDIT_CHATS_CHILDREN = "EDIT_CHATS_CHILDREN";
export const SPREAD_PAYLOAD = "SPREAD_PAYLOAD";
export const EDIT_CHATS_CHILDREN_SPREAD = "EDIT_CHATS_CHILDREN_SPREAD";

export const editChats = (payload) => ({ type: SPREAD_PAYLOAD, payload });

export const getTempletes = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    // new Database token
    let authToken = getAuthToken();
    if (!website || !token) return false;
    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingTempletes: true,
      },
    });
    // console.log("redux call");
    try {
      let res;
      if (getIsBackendMigrated("chats")) {
        let requestObj = {
          endpoint: `${backendEndpoints?.template}`,
          params: {
            channel: "whatsapp",
          },
        };
        res = await backendApiCall(requestObj);
        if (res?.success) {
          // console.log(
          //   res?.data.filter(
          //     (obj) =>
          //       obj.category === "UTILITY" || obj.category === "MARKETING"
          //   ) || [],
          //   "<< res"
          // );
          dispatch({
            type: EDIT_CHATS,
            payload: {
              whatsappTempletes:
                res?.data.filter(
                  (obj) =>
                    obj.category === "UTILITY" || obj.category === "MARKETING"
                ) || [],
              fetchingTempletes: false,
            },
          });
        }
      } else {
        res = await axios({
          url: `${website}/wp-json/store/v1/social/whatsapp/templates`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (isArrayWithValues(res?.data?.data))
          dispatch({
            type: EDIT_CHATS,
            payload: {
              whatsappTempletes:
                res.data.data.filter(
                  (obj) =>
                    obj.category === "UTILITY" || obj.category === "MARKETING"
                ) || [],
              fetchingTempletes: false,
            },
          });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingTempletes: false,
      },
    });
  };
};

export const deprecated_get_conversations = (params) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    // let chats = state.chats.conversations || [];

    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingConversations: true,
      },
    });
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/conversations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params,
      });
      if (res.data.success) {
        // let convo = res.data.data?.map((obj) => {
        //   let mergeObj = Object.values(mergeProfiles || {}).find(
        //     (merge) =>
        //       isArrayWithValues(merge?.profiles) &&
        //       Boolean(
        //         merge?.profiles?.find((profile) => profile?.id === obj?.id)
        //       )
        //   );

        //   if (mergeObj) {
        //     obj.profiles = {};
        //     for (const idObj of mergeObj?.profiles || []) {
        //       obj.profiles[idObj?.platform] = idObj?.id;
        //     }
        //   }
        //   return obj;
        // });
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: res?.data?.data || [],
            fetchingConversations: false,
            conversationsFetched: true,
          },
        });
        // if (isArrayWithValues(res.data?.data))
        // console.log("res", [...res.data.data]);
        dispatch(setUpConversations([...res.data.data]));
      }
    } catch (error) {
      console.log(getAxiosError(error));
      dispatch({
        type: EDIT_CHATS,
        payload: {
          fetchingConversations: false,
        },
      });
    }
  };
};
export const getConversations = (params, clear) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    let state = getState();
    /// new database token
    let authToken = getAuthToken();
    const conversations = state.chats.conversations || [];

    // if (!state.user?.backend_migrated?.chats)
    // if (!getIsBackendMigrated("chats")) {
    //   dispatch(deprecated_get_conversations(params));
    //   return;
    // }

    if (!getIsBackendMigrated("chats")) {
      dispatch(deprecated_get_conversations(params));
      return;
    }

    // let chats = state.chats.conversations || [];

    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingConversations: true,
      },
    });

    try {
      // const data  = await axios({
      //   url: `${backendDoamin}/chat/users`,
      //   headers: {
      //     Authorization: `Bearer ${authToken}`,``
      //   },
      //   params,
      // });
      const data = await getChatsUsers({ params });
      if (data.success) {
        // let convo = res.data.data?.map((obj) => {
        //   let mergeObj = Object.values(mergeProfiles || {}).find(
        //     (merge) =>
        //       isArrayWithValues(merge?.profiles) &&
        //       Boolean(
        //         merge?.profiles?.find((profile) => profile?.id === obj?.id)
        //       )
        //   );

        //   if (mergeObj) {
        //     obj.profiles = {};
        //     for (const idObj of mergeObj?.profiles || []) {
        //       obj.profiles[idObj?.platform] = idObj?.id;
        //     }
        //   }
        //   return obj;
        // });
        let payload = {
          // conversations: data?.data || [],
          fetchingConversations: false,
          conversationsFetched: true,
          noMoreConversations: !isArrayWithValues(data?.data),
        };
        if (params?.page <= 1) payload.conversations = data?.data;
        else payload.conversations = [...conversations, ...(data?.data || [])];

        dispatch({
          type: EDIT_CHATS,
          payload,
        });

        dispatch(setUpConversations([...(payload.conversations || [])]));
      } else {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            fetchingConversations: false,
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
      dispatch({
        type: EDIT_CHATS,
        payload: {
          fetchingConversations: false,
        },
      });
    }
  };
};

export const setUpConversations = (conversations) => {
  // console.log(conversations);
  return (dispatch, getState) => {
    if (!isArrayWithValues(conversations)) return;
    const state = getState();
    let mergeProfiles = state.chats.mergeProfiles || {};
    let chatsGroupings = state.chats.chatsGroupings || {};
    let broadcastList = state.chats.broadcastList || {};
    let _conversationExtra = state.chats.conversationExtra;
    let recentConversations = getIsBackendMigrated("chats")
      ? []
      : conversations.slice(0, 20).map((i) => ({
          ...i,
          lastmessageObj: getLastMessageFromObj(
            i?.conversation?.[i?.conversation?.length - 1],
            i.platform
          ),
        }));

    let newContacts = [...conversations]
      .sort((a, b) => {
        return (
          a?.conversation?.[a?.conversation?.length - 1]?.time -
          b?.conversation?.[b?.conversation?.length - 1]?.time
        );
      })
      .slice(0, 5)
      .map((i) => ({
        ...i,
        joining_date: i?.conversation?.[i?.conversation?.length - 1]?.time,
        lastmessageObj: getLastMessageFromObj(i?.conversation?.[0], i.platform),
      }))
      .sort((a, b) => {
        return b?.joining_date - a?.joining_date;
      });

    let monthsObj = {};
    for (let i = 0; i <= 11; i++) {
      let currentDate = new Date();
      let _date = new Date(currentDate.setMonth(currentDate.getMonth() - i));
      monthsObj[`a${formatDate(_date, "mm-yyyy")}`] = 0;
    }
    let monthsObjWithArray = {};
    for (let i = 0; i <= 11; i++) {
      let currentDate = new Date();
      let _date = new Date(currentDate.setMonth(currentDate.getMonth() - i));
      monthsObjWithArray[`a${formatDate(_date, "mm-yyyy")}`] = [];
    }
    let followupContacts = [];
    let conversationsByDate = {};
    let conversationsByMonth = {};
    let conversationsInaYear = { ...monthsObj };
    let conversationsStartedInaYear = { ...monthsObjWithArray };
    let allMediaInYear = { ...monthsObj };
    let allConversationArray = [];
    let totalConversationsCount = {};
    let conversationExtra = {
      totalConversationsMedia: 0,
      totalWhatsappConversations: 0,
      totalFacebookConversations: 0,
      totalInstagramConversations: 0,
      totalWhatsappConversationsMedia: 0,
      totalFacebookConversationsMedia: 0,
      totalInstagramConversationsMedia: 0,
      totalChannels: 0,
      totalFollowUp: 0,
      totalUnseen: 0,
      totalTypeCustomers: 0,
      totalTypeLeads: 0,
      totalTypeUndefined: 0,
      whatsappMedia: {
        image: 0,
        video: 0,
        document: 0,
        audio: 0,
      },
    };

    let conversationArray = [];
    let mergeViewObj = {};

    // console.log(conversations);
    for (let conversationObj of conversations) {
      let { conversation, id, platform, status, contact_type } = {
        ...conversationObj,
      };
      if (contact_type == "customer") conversationExtra.totalTypeCustomers += 1;
      else if (contact_type == "lead") conversationExtra.totalTypeLeads += 1;
      else conversationExtra.totalTypeUndefined += 1;

      if (status === "follow_up") followupContacts.push(conversationObj);
      if (status === "follow_up") conversationExtra.totalFollowUp += 1;
      // if(conversationsStartedInaYear)
      let totalSent = 0;
      let totalReceived = 0;
      if (isArrayWithValues(conversation)) {
        let first_message = conversation[0] || {};
        let { time } = first_message;
        let date = formatDate(new Date(time), "mm-yyyy");
        if (conversationsStartedInaYear.hasOwnProperty(`a${date}`)) {
          if (
            !conversationsStartedInaYear[`a${date}`].includes(id) &&
            !allConversationArray.includes(id)
          ) {
            conversationsStartedInaYear[`a${date}`].push(id);
            allConversationArray.push(id);
          }
        }

        for (let obj of conversation) {
          if (!obj) continue;
          let { time, message, is_read, is_sender } = obj || {};

          if (is_sender) totalSent++;
          else totalReceived++;

          if (!is_read) conversationExtra.totalUnseen += 1;

          let date = formatDate(new Date(time), "mm-dd-yyyy");
          let month = formatDate(new Date(time), "mm-yyyy");

          if (["image", "video", "document", "audio"].includes(message?.type)) {
            conversationExtra.totalConversationsMedia += 1;
            conversationExtra.whatsappMedia[message?.type] += 1;
            conversationExtra[
              `total${capitalizeFirstLetter(platform)}ConversationsMedia`
            ] += 1;
            if (allMediaInYear.hasOwnProperty(`a${month}`))
              allMediaInYear[`a${month}`] += 1;
          }
          if (message?.attachments) {
            conversationExtra.totalConversationsMedia += 1;
            conversationExtra[
              `total${capitalizeFirstLetter(platform)}ConversationsMedia`
            ] += 1;
          }

          if (!conversationsByMonth[month]) conversationsByMonth[month] = [];
          if (!conversationsByMonth[month]?.includes(`${platform}_${id}`)) {
            conversationsByMonth[month].push(`${platform}_${id}`);
            if (conversationsInaYear.hasOwnProperty(`a${month}`)) {
              if (!conversationsInaYear[`a${month}`])
                conversationsInaYear[`a${month}`] = 0;
              conversationsInaYear[`a${month}`] += 1;
            }
          }

          if (!conversationsByDate[date]) conversationsByDate[date] = [];
          if (!conversationsByDate[date]?.includes(`${platform}_${id}`)) {
            conversationsByDate[date].push(`${platform}_${id}`);

            if (platform == "whatsapp")
              conversationExtra.totalWhatsappConversations += 1;
            if (platform == "facebook")
              conversationExtra.totalFacebookConversations += 1;
            if (platform == "instagram")
              conversationExtra.totalInstagramConversations += 1;
          }
          // if (!conversationsByDate[`${date}_${platform}_${id}`])
          //   conversationsByDate[`${date}_${platform}_${id}`] = 0;
          // conversationsByDate[`${date}_${platform}_${id}`] += 1;
        }
      }
      totalConversationsCount[id] = {
        sent: totalSent,
        received: totalReceived,
      };

      //add broadCastList
      if (isArrayWithValues(conversationObj?.label)) {
        let broadcastListArray =
          conversationObj?.label?.map((id) => {
            let broadcastObj = Object.values(broadcastList || {})?.find(
              (o) => o?.id === id
            );

            if (broadcastObj) return broadcastObj;
            else return false;
          }) || [];
        conversationObj.broadcastList = broadcastListArray?.filter(Boolean);
      }

      ///Map merge
      if (isObjWithValues(mergeProfiles)) {
        let mergeObj = Object.values(mergeProfiles || {}).find(
          (merge) =>
            isArrayWithValues(merge?.profiles) &&
            Boolean(
              merge?.profiles?.find(
                (profile) => profile?.id == conversationObj?.id
              )
            )
        );

        if (mergeObj) {
          conversationObj.profiles = {};
          for (const idObj of mergeObj?.profiles || []) {
            if (conversations?.find((o) => o.id === idObj?.id))
              conversationObj.profiles[idObj?.platform] = idObj?.id;
          }
          conversationObj.merge_profiles = mergeObj;
        }
      }

      /// make mergeView chats on Company name
      if (conversationObj?.company_name) {
        let key =
          conversationObj?.company_name
            ?.trim()
            ?.replaceAll(" ", "")
            ?.toLowerCase() + conversationObj?.platform;
        let oldMergeObj = mergeViewObj[key] || {};
        mergeViewObj[key] = {
          id: key,
          ...oldMergeObj,
          name: conversationObj?.company_name,
          ids: [...(mergeViewObj?.[key]?.ids || []), conversationObj?.id],
          platform: conversationObj?.platform,
          isAutoGentrated: true,
        };
      }
      // conversationArray = [...conversationArray, conversationObj]
      conversationArray.push(conversationObj);
    }
    for (const key of Object.keys(mergeViewObj || {})) {
      if (mergeViewObj?.[key]?.ids?.length <= 1) delete mergeViewObj[key];
    }
    // console.log(conversationArray);
    let totalChannels = 0;
    if (conversationExtra.totalWhatsappConversations) totalChannels++;
    if (conversationExtra.totalFacebookConversations) totalChannels++;
    if (conversationExtra.totalInstagramConversations) totalChannels++;
    // console.log({ ...chatsGroupings, ...mergeViewObj });
    dispatch({
      type: EDIT_CHATS,
      payload: {
        chatsGroupings: { ...chatsGroupings, ...mergeViewObj },
        newContacts,
        recentConversations,
        followupContacts: followupContacts
          .reverse()
          .splice(0, 5)
          .map((i) => ({
            ...i,
            lastmessageObj: getLastMessageFromObj(
              i?.conversation?.[0],
              i.platform
            ),
          })),
        totalConversationsCount,
        conversations: conversationArray,
        conversationExtra: {
          ...conversationExtra,
          conversationsByMonth,
          conversationsByDate,
          conversationsByYear: Object.values(conversationsInaYear).reverse(),
          allMediaInYear: Object.values(allMediaInYear).reverse(),
          conversationsStartedInaYear: Object.values(
            conversationsStartedInaYear
          )
            .map((i) => i?.length)
            .reverse(),
          totalChannels,
        },
      },
    });
    dispatch({
      type: EDIT_CHATS_CHILDREN_SPREAD,
      payload: {
        key: "conversationExtra",
        payload: {
          ..._conversationExtra,
          ...conversationExtra,
          conversationsByMonth,
          conversationsByDate,
          conversationsByYear: Object.values(conversationsInaYear).reverse(),
          allMediaInYear: Object.values(allMediaInYear).reverse(),
          conversationsStartedInaYear: Object.values(
            conversationsStartedInaYear
          )
            .map((i) => i?.length)
            .reverse(),
          totalChannels,
        },
      },
    });
  };
};

export const addResponseInChat = (res, id, basicInfo, fromSocket) => {
  return async (dispatch, getState) => {
    const state = getState();
    let chats = state.chats.conversations;
    let addedCustomer = {};
    let isDeleted = res?.message.is_deleted;

    if (isArrayWithValues(chats)) {
      let customer = chats.find((i) => i.id == id || i.platform_id == id);
      let filteredCustomers = [];

      if (customer) {
        addedCustomer = customer;
        if (isDeleted)
          customer.conversation = customer.conversation?.map((i) =>
            i?.message?.mid == res?.message?.mid
              ? { ...i, is_deleted: true }
              : i
          );
        else customer.conversation = [...(customer.conversation || []), res];
        // ?.push(res);
        filteredCustomers = chats.filter(
          (o) => Number(o.id) !== Number(customer.id)
        );
        // console.log(customer, customer.conversation, "<< chats customer");
        if (isArrayWithValues(filteredCustomers))
          filteredCustomers.unshift({
            ...customer,
            platform: basicInfo?.platform,
          });
      } else {
        let res = {};
        if (!getIsBackendMigrated("chats")) {
          res = await getConversationById(
            { platform: basicInfo?.platform },
            basicInfo?.id
          );
        } else {
          let chatUser = await getChatsUsers({ params: { platform_id: id } });
          if (chatUser?.success) res.data = chatUser?.data?.[0];
        }
        if (isObjWithValues(res)) {
          addedCustomer = res.data;
          filteredCustomers = [...chats];
          filteredCustomers.unshift({
            ...res.data,
            platform: basicInfo.platform,
          });
        }
      }
      if (
        isArrayWithValues(chats) &&
        // chats.length !== filteredCustomers.length &&
        isObjWithValues(addedCustomer)
      ) {
        let name =
          addedCustomer.platform === "facebook"
            ? `${addedCustomer.first_name} ${addedCustomer.last_name}`
            : addedCustomer.platform === "whatsapp" ||
              addedCustomer.platform === "instagram"
            ? addedCustomer.name
            : "";
        if (fromSocket)
          dispatch(
            setGlobalToast({
              show: true,
              severity: addedCustomer.platform,
              message: `New message received from ${name}`,
            })
          );
        // customerInAudio.play();
      }
      // let customerArray = chats.map((o) =>
      //   Number(o.id) === Number(customer.id) ? customer : o
      // );

      dispatch({
        type: EDIT_CHATS,
        payload: {
          conversations: filteredCustomers,
          fetchingConversations: false,
        },
      });
    }
  };
};
export const assignedFilter = (customer, filter) => {
  return (dispatch, getState) => {
    const state = getState();
    let chats = state.chats.conversations;

    if (isArrayWithValues(chats)) {
      // let customer = chats.find(i => Number(i.id) === Number(id))s
      customer.status = filter;
      let customerArray = chats.map((o) =>
        Number(o.id) === Number(customer.id) ? customer : o
      );
      dispatch({
        type: EDIT_CHATS,
        payload: {
          conversations: customerArray,
          fetchingConversations: false,
        },
      });
    }
  };
};

export const deleteTemplate = (obj, setDeleting) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    const state = getState();
    let templates = state.chats.whatsappTempletes;
    setDeleting(obj.id);
    if (isArrayWithValues(templates)) {
      try {
        const res = await axios({
          url: `${website}/wp-json/store/v1/social/whatsapp/templates`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          data: {
            payload: {
              name: obj.name,
            },
          },
        });
        if (res.data.success) {
          dispatch({
            type: EDIT_CHATS,
            payload: {
              whatsappTempletes: templates.filter((o) => o.name !== obj.name),
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
      setDeleting(false);
    }
  };
};

export const readMessage = (customer) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (getIsBackendMigrated("chats")) {
      await readMessageFunction({ id: customer?.id });
      return;
    }
    if (!website || !token) return false;
    const state = getState();
    let conversations = state.chats.conversations;
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/conversation/${customer.id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          platform: customer.platform,
          payload: {
            status_updated: Date.now(),
          },
          is_read: true,
        },
      });

      if (res.data.success) {
        let resConversations = customer.conversation.map((i) => ({
          ...i,
          is_read: 1,
        }));
        let newconversations = conversations.map((i) =>
          i.id === customer.id ? { ...i, conversation: resConversations } : i
        );

        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: newconversations,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteConversation = (customer, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    if (getIsBackendMigrated("chats")) {
      setLoading && setLoading(true);
      try {
        let idArray = customer?.map((obj) => obj?.id);
        let res = await bulkActionChatUser({
          data: {
            delete: idArray,
          },
        });
        if (res?.success) {
          let state = getState();
          onSuccess && onSuccess();
          let conversations = state.chats.conversations;
          conversations = conversations?.filter(
            (obj) => !idArray?.includes(obj?.id)
          );
          dispatch({
            type: EDIT_CHATS,
            payload: {
              conversations: conversations,
            },
          });
        }
        setLoading && setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading && setLoading(false);
      }

      return;
    }
    for await (const customerInfo of isArrayWithValues(customer)
      ? customer
      : [customer]) {
      try {
        const res = await axios({
          url: `${website}/wp-json/store/v1/social/conversation/${customerInfo.id}`,
          method: "DELETE",
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            platform: customerInfo.platform,
          },
        });
        if (res.data.success) {
          const state = getState();
          let conversations = state.chats.conversations;
          dispatch(
            addUserActivity({
              event: "delete_chat",
              event_info: {
                name: getFullName(customerInfo),
              },
            })
          );
          let newconversations = conversations?.filter(
            (i) => i.id !== customerInfo.id
          );
          dispatch({
            type: EDIT_CHATS,
            payload: {
              conversations: newconversations,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const spamCustomer = (customer, val) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    const state = getState();
    let conversations = state.chats.conversations;
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/conversation/${customer.id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          platform: customer.platform,
          payload: {
            status: val,
            status_updated: Date.now(),
          },
        },
      });
      if (res.data?.success) {
        let newconversations = [];
        if (val)
          newconversations = conversations.filter((i) => i.id !== customer.id);
        if (!val) newconversations = conversations.unshif(customer);
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: newconversations,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addChatInRedux = (newChat) => {
  return (dispatch, getState) => {
    const state = getState();
    let conversations = state.chats.conversations || [];
    let newConversations = [...conversations, newChat];
    dispatch({
      type: EDIT_CHATS,
      payload: {
        conversations: newConversations,
      },
    });
  };
};

export const updateChatObject = (customer, data, setState) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    const state = getState();
    let conversations = state.chats.conversations || [];

    try {
      setState && setState(true);
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/conversation/${customer?.id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        params: { platform: customer?.platform },
        data,
      });

      if (res.data.success) {
        let resObj = res?.data?.data;
        // console.log(resObj, { ...customer, ...resObj, platform: customer?.platform });
        resObj.conversation = customer?.conversation;
        let newconversations = conversations.map((o) =>
          o.id === res?.data?.data?.id
            ? { ...o, ...customer, ...resObj, platform: customer?.platform }
            : o
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: newconversations,
          },
        });
      }
      setState && setState(false);
    } catch (error) {
      setState && setState(true);
      console.log(error);
      setState && setState(false);
    }
    setState && setState(false);
  };
};

export const updateChatCustomer = (customersArray, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    let resArr = [];
    const website = getWebsite();
    const token = getToken();
    const state = getState();
    let conversations = state.chats.conversations || [];
    setLoading && setLoading(true);
    try {
      for await (const obj of customersArray) {
        if (isObjWithValues(obj?.data)) {
          const res = await axios({
            url: `${website}/wp-json/store/v1/social/conversation/${obj?.id}`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            method: "PUT",
            params: { platform: obj?.platform },
            data: {
              payload: {
                ...obj?.data,
                // customer_id: selectedCustomer.id,
              },
            },
          });
          if (res?.data.success && isObjWithValues(res?.data?.data)) {
            resArr.push(res?.data?.data);
            conversations = conversations.map((o) =>
              o.id === res?.data?.data?.id
                ? {
                    ...res?.data?.data,
                    conversation: o.conversation,
                    platform: o.platform,
                  }
                : o
            );
          }
        }
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setLoading && setLoading(false);
    onSuccess && onSuccess(resArr?.length);

    dispatch({
      type: EDIT_CHATS,
      payload: {
        conversations: conversations,
      },
    });
  };
};

export const setChatsbyOptions = (options) => {
  return async (dispatch, getState) => {
    const state = getState();
    const website = getWebsite();
    const token = getToken();
    dispatch({ type: EDIT_CHATS, payload: { gettingChatsOptions: true } });
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/conversations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { options: options.join(",") },
      });
      if (res?.data?.success && isArrayWithValues(res?.data?.data)) {
        let conversations = state.chats.conversations || [];
        let newConversations = [...conversations, ...res?.data?.data];
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: newConversations,
            gettingChatsOptions: false,
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const deprecated_getUsersWithTasks = () => {
  return async (dispatch) => {
    const website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/user_events`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "GET",
        });
        if (isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_CHATS,
            payload: {
              users_with_tasks: data.data,
            },
          });
          dispatch(
            getUserTasks(
              Object.keys(data.data)
                .map((i) => `user_events_${i}`)
                .join()
            )
          );
        } else
          dispatch({
            type: EDIT_CHATS,
            payload: {
              no_user_tasks: true,
            },
          });
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        users_with_tasks_fetched: true,
      },
    });
  };
};
export const getUsersWithTasks = () => {
  return async (dispatch) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_getUsersWithTasks());
    let token = getToken();
    if (token) {
      try {
        let { data } = await axios({
          url: `${backendDoamin}/${backendApiEndpoints.settings}/user_events`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        });
        if (isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_CHATS,
            payload: {
              users_with_tasks: data.data,
            },
          });
          dispatch(
            getUserTasks(
              Object.keys(data.data)
                .map((i) => `user_events_${i}`)
                .join()
            )
          );
        } else
          dispatch({
            type: EDIT_CHATS,
            payload: {
              no_user_tasks: true,
            },
          });
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        users_with_tasks_fetched: true,
      },
    });
  };
};
export const deprecated_getUserTasks = (options) => {
  return async (dispatch) => {
    const website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/?options=${options}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "GET",
        });
        if (isObjWithValues(data.data)) {
          let usersTasks = { ...data.data };
          Object.keys(usersTasks)?.forEach((key) => {
            if (!usersTasks[key]) {
              delete usersTasks[key];
            }
          });
          dispatch(setupUserTasks(usersTasks));
          dispatch({
            type: EDIT_CHATS,
            payload: {
              usersTasks,
              allUserTasksFetched: true,
            },
          });
        } else {
        }
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        // usersTasks,
        allUserTasksFetched: true,
      },
    });
    // dispatch({
    //   type: EDIT_CHATS,
    //   payload: {
    //     users_with_tasks_fetched: true,
    //   },
    // });
  };
};
export const getUserTasks = (options) => {
  return async (dispatch) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_getUserTasks(options));
    let token = getToken();
    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingUserTasks: true,
      },
    });
    if (token) {
      try {
        let { data } = await axios({
          url: `${backendDoamin}/${backendApiEndpoints.user_tasks}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        });
        if (isArrayWithValues(data.data)) {
          let usersTasks = [...data.data];
          // Object.keys(usersTasks).forEach((key) => {
          //   if (!usersTasks[key]) {
          //     delete usersTasks[key];
          //   }
          // });
          dispatch(setupUserTasks(usersTasks));
          dispatch({
            type: EDIT_CHATS,
            payload: {
              usersTasks,
              allUserTasksFetched: true,
            },
          });
        } else {
        }
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        // usersTasks,
        fetchingUserTasks: false,
        allUserTasksFetched: true,
      },
    });
    // dispatch({
    //   type: EDIT_CHATS,
    //   payload: {
    //     users_with_tasks_fetched: true,
    //   },
    // });
  };
};

export const deprecated_setupUserTasks = (usersTasks) => {
  return async (dispatch, getState) => {
    let state = getState();
    let team_members = state.settings.teamMembers;
    const isAdmin = state.user.isAdmin;
    const teamMemberId = state.user?.id;
    let conversations = state.chats.conversations;
    let _usersTasks = state.chats.usersTasks;
    let _conversationExtra = state.chats.conversationExtra;
    const allUserTasks = state.chats.allUserTasks;

    if (!isObjWithValues(usersTasks) && !isObjWithValues(_usersTasks)) return;
    if (!isObjWithValues(usersTasks)) usersTasks = _usersTasks;
    let _allTasks = [];
    dispatch({
      type: EDIT_CHATS,
      payload: {
        settingsUpUserTasks: true,
      },
    });

    let monthsObj = {};
    let conversationExtra = {
      totalTasks: 0,
      totalTask: 0,
      totalReminder: 0,
      totalCall: 0,
    };

    for (let i = 0; i <= 11; i++) {
      let currentDate = new Date();
      let _date = new Date(currentDate.setMonth(currentDate.getMonth() - i));
      monthsObj[`a${formatDate(_date, "mm-yyyy")}`] = 0;
    }
    let activitesByMonth = { ...monthsObj };

    let crm_ids = [];
    let crm_users = [];

    for (let tasks of Object.values(usersTasks)) {
      if (!isObjWithValues(tasks)) continue;
      // conversationExtra.totalTasks += Object.keys(tasks).length;
      for (let task of Object.values(tasks)) {
        let { owner, date_created, type, user_from, user_id } = task;
        let alreadyAddedTask = allUserTasks?.find((i) => i.id == task.id);
        if (
          type &&
          conversationExtra.hasOwnProperty(
            `total${capitalizeFirstLetter(type)}`
          )
        )
          if ((!isAdmin && owner == teamMemberId) || isAdmin)
            conversationExtra[`total${capitalizeFirstLetter(type)}`] += 1;
        let monthDate = formatDate(new Date(date_created), "mm-yyyy");
        if (activitesByMonth.hasOwnProperty(`a${monthDate}`))
          activitesByMonth[`a${monthDate}`] += 1;

        let obj = { ...task };
        let userDetails = alreadyAddedTask?.userDetails || {};
        if (isArrayWithValues(conversations)) {
          let user = conversations.find((i) => i.id == task.user_id);
          if (user)
            userDetails = {
              name: getNameFromPlatform(user),
              profile_pic: user.profile_pic,
            };
        }
        if (user_from === "crm" && !isObjWithValues(userDetails))
          crm_ids.push(user_id);

        obj.userDetails = userDetails;

        if (owner != "admin") {
          let teamMember = team_members?.find((i) => i.id == owner);
          if (teamMember)
            obj.teamMemberInfo = {
              name: `${teamMember.first_name} ${teamMember.last_name}`,
            };
        } else if (owner == "admin") {
          obj.teamMemberInfo = { name: "admin" };
        }
        _allTasks.push(obj);
      }
    }

    crm_ids = [...new Set(crm_ids)];

    if (isArrayWithValues(crm_ids)) {
      let page = 1;
      let per_page = 100;
      let batchLength = parseInt(crm_ids?.length / per_page) || 1;
      let idsToFetch = [...crm_ids];
      for await (let i of Array.from(new Array(batchLength).fill(1))) {
        let fetchIds = idsToFetch.slice(0, per_page);
        if (isArrayWithValues(fetchIds)) {
          let customers = await fetchJweroCustomers({
            params: {
              per_page,
              page,
              includes: fetchIds.join(),
              _fields: "id,first_name,last_name,email,billing,meta_data",
            },
          });
          if (isArrayWithValues(customers))
            crm_users = crm_users.concat(customers);
        }
        page++;
      }
    }
    let allTasksWithCRMDetails = [];
    for (let task of _allTasks) {
      let { user_from, userDetails } = task;
      let userDetailsObj = userDetails;
      if (user_from === "crm" && !isObjWithValues(userDetails)) {
        let user = crm_users.find((i) => i.id);
        if (isObjWithValues(user)) {
          let { first_name, last_name, email, billing, meta_data } = user;
          let profile_pic = meta_data?.find(
            (i) => i.key === "profile_url"
          )?.value;
          userDetailsObj = {
            name:
              `${first_name || billing.first_name || ""} ${
                last_name || billing.last_name || ""
              }`.trim() || email,
            profile_pic,
          };
        }
        allTasksWithCRMDetails.push({
          ...task,
          userDetails: userDetailsObj,
        });
      } else allTasksWithCRMDetails.push(task);
    }
    _allTasks = allTasksWithCRMDetails;

    // if (isArrayWithValues(crm_users)) {
    //   let user = crm_users.find((i) => i.id == task.user_id);
    //   if (user)
    //     userDetails = {
    //       name: getNameFromPlatform(user),
    //       profile_pic: user.profile_pic,
    //     };
    // }

    if (isArrayWithValues(_allTasks)) {
      _allTasks = _allTasks.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      _allTasks = _allTasks.sort((a, b) => {
        if (a.status === "complete" && b.status !== "complete") {
          return 1;
        }
        if (a.status !== "complete" && b.status === "complete") {
          return -1;
        }
        if (a.status < b.status) {
          return -1;
        }
        if (a.status > b.status) {
          return 1;
        }
        return 0;
      });
    }

    if (!isAdmin) _allTasks = _allTasks.filter((i) => i.owner == teamMemberId);
    conversationExtra.totalTasks = _allTasks.length;
    dispatch({
      type: EDIT_CHATS,
      payload: {
        allUserTasks: _allTasks,
        allTasks: _allTasks.filter((i) => i.type === "task"),
        allReminders: _allTasks.filter((i) => i.type === "reminder"),
        allCalls: _allTasks.filter((i) => i.type === "call"),
        settingsUpUserTasks: false,
      },
    });
    dispatch({
      type: EDIT_CHATS_CHILDREN_SPREAD,
      payload: {
        key: "conversationExtra",
        payload: {
          ..._conversationExtra,
          ...conversationExtra,
          activitesByMonth: Object.values(activitesByMonth).reverse(),
        },
      },
    });
    return;
    // let updatedTasks = [];
    // for (let obj of Object.values(tasks)) {
    //   let updatedObj = { ...obj };
    //   updatedTasks.push(updatedObj);
    // }
    // dispatch({
    //   type: EDIT_CHATS,
    //   payload: {
    //     user_tasks: updatedTasks,
    //   },
    // });
  };
};
export const setupUserTasks = (usersTasks) => {
  return async (dispatch, getState) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_setupUserTasks(usersTasks));
    let state = getState();
    let team_members = state.settings.teamMembers;
    const isAdmin = state.user.isAdmin;
    const teamMemberId = state.user?.id;
    let conversations = state.chats.conversations;
    let _usersTasks = state.chats.usersTasks;
    let _conversationExtra = state.chats.conversationExtra;
    const allUserTasks = state.chats.allUserTasks;
    console.log(usersTasks, _usersTasks, "<<< usersTasks");
    console.log(1, "<<< usersTasks");

    if (!isArrayWithValues(usersTasks) && !isArrayWithValues(_usersTasks))
      return;
    if (!isArrayWithValues(usersTasks)) usersTasks = _usersTasks;
    let _allTasks = [];
    dispatch({
      type: EDIT_CHATS,
      payload: {
        settingsUpUserTasks: true,
      },
    });

    let monthsObj = {};
    let conversationExtra = {
      totalTasks: 0,
      totalTask: 0,
      totalReminder: 0,
      totalCall: 0,
    };

    console.log(2, "<<< usersTasks");
    for (let i = 0; i <= 11; i++) {
      let currentDate = new Date();
      let _date = new Date(currentDate.setMonth(currentDate.getMonth() - i));
      monthsObj[`a${formatDate(_date, "mm-yyyy")}`] = 0;
    }
    let activitesByMonth = { ...monthsObj };

    let crm_ids = [];
    let crm_users = [];

    for (let task of usersTasks) {
      // if (!isObjWithValues(tasks)) continue;
      // conversationExtra.totalTasks += Object.keys(tasks).length;
      // for (let task of Object.values(tasks)) {
      let { owner, date_created, type, user_from, user_id } = task;
      let alreadyAddedTask = allUserTasks?.find((i) => i.id == task.id);
      if (
        type &&
        conversationExtra.hasOwnProperty(`total${capitalizeFirstLetter(type)}`)
      )
        if ((!isAdmin && owner == teamMemberId) || isAdmin)
          conversationExtra[`total${capitalizeFirstLetter(type)}`] += 1;
      let monthDate = formatDate(new Date(date_created), "mm-yyyy");
      if (activitesByMonth.hasOwnProperty(`a${monthDate}`))
        activitesByMonth[`a${monthDate}`] += 1;

      let obj = { ...task };
      let userDetails = obj.userDetails || alreadyAddedTask?.userDetails || {};
      if (isArrayWithValues(conversations) && !isObjWithValues(userDetails)) {
        let user = conversations.find((i) => i.id == task.user_id);
        if (user)
          userDetails = {
            name: getNameFromPlatform(user),
            profile_pic: user.profile_pic,
          };
      }
      if (user_from === "crm" && !isObjWithValues(userDetails))
        crm_ids.push(user_id);

      obj.userDetails = userDetails;

      if (owner != "admin") {
        let teamMember = team_members?.find((i) => i.id == owner);
        if (teamMember)
          obj.teamMemberInfo = {
            name: `${teamMember.first_name} ${teamMember.last_name}`,
          };
      } else if (owner == "admin") {
        obj.teamMemberInfo = { name: "admin" };
      }
      _allTasks.push(obj);
      // }
    }

    crm_ids = [...new Set(crm_ids)];

    if (isArrayWithValues(crm_ids)) {
      let page = 1;
      let per_page = 100;
      let batchLength = parseInt(crm_ids?.length / per_page) || 1;
      let idsToFetch = [...crm_ids];
      for await (let i of Array.from(new Array(batchLength).fill(1))) {
        let fetchIds = idsToFetch.slice(0, per_page);
        if (isArrayWithValues(fetchIds)) {
          let customers = await fetchJweroCustomers({
            params: {
              per_page,
              page,
              includes: fetchIds.join(),
              _fields: "id,first_name,last_name,email,billing,meta_data",
            },
          });
          if (isArrayWithValues(customers))
            crm_users = crm_users.concat(customers);
        }
        page++;
      }
    }
    let allTasksWithCRMDetails = [];
    for (let task of _allTasks) {
      let { user_from, userDetails } = task;
      let userDetailsObj = userDetails;
      if (user_from === "crm" && !isObjWithValues(userDetails)) {
        let user = crm_users.find((i) => i.id);
        if (isObjWithValues(user)) {
          let { first_name, last_name, email, billing, meta_data } = user;
          let profile_pic = meta_data?.find(
            (i) => i.key === "profile_url"
          )?.value;
          userDetailsObj = {
            name:
              `${first_name || billing.first_name || ""} ${
                last_name || billing.last_name || ""
              }`.trim() || email,
            profile_pic,
          };
        }
        allTasksWithCRMDetails.push({
          ...task,
          userDetails: userDetailsObj,
        });
      } else allTasksWithCRMDetails.push(task);
    }
    _allTasks = allTasksWithCRMDetails;

    // if (isArrayWithValues(crm_users)) {
    //   let user = crm_users.find((i) => i.id == task.user_id);
    //   if (user)
    //     userDetails = {
    //       name: getNameFromPlatform(user),
    //       profile_pic: user.profile_pic,
    //     };
    // }

    if (isArrayWithValues(_allTasks)) {
      _allTasks = _allTasks.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      _allTasks = _allTasks.sort((a, b) => {
        if (a.status === "complete" && b.status !== "complete") {
          return 1;
        }
        if (a.status !== "complete" && b.status === "complete") {
          return -1;
        }
        if (a.status < b.status) {
          return -1;
        }
        if (a.status > b.status) {
          return 1;
        }
        return 0;
      });
    }

    if (!isAdmin) _allTasks = _allTasks.filter((i) => i.owner == teamMemberId);
    conversationExtra.totalTasks = _allTasks.length;
    console.log(_allTasks, "<<<< _allTasks");
    dispatch({
      type: EDIT_CHATS,
      payload: {
        allUserTasks: _allTasks,
        allTasks: _allTasks.filter((i) => i.type === "task"),
        allReminders: _allTasks.filter((i) => i.type === "reminder"),
        allCalls: _allTasks.filter((i) => i.type === "call"),
        settingsUpUserTasks: false,
      },
    });
    dispatch({
      type: EDIT_CHATS_CHILDREN_SPREAD,
      payload: {
        key: "conversationExtra",
        payload: {
          ..._conversationExtra,
          ...conversationExtra,
          activitesByMonth: Object.values(activitesByMonth).reverse(),
        },
      },
    });
    return;
    // let updatedTasks = [];
    // for (let obj of Object.values(tasks)) {
    //   let updatedObj = { ...obj };
    //   updatedTasks.push(updatedObj);
    // }
    // dispatch({
    //   type: EDIT_CHATS,
    //   payload: {
    //     user_tasks: updatedTasks,
    //   },
    // });
  };
};

export const deprecated_addUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    const website = getWebsite();
    let token = getToken();
    let state = getState();
    let usersTasks = state.chats.usersTasks;
    if (website && token) {
      try {
        let calendarEventobj = getGoogleEventObjByEvent(obj);
        let calendarData = await createGoogleCalendarEvent(calendarEventobj);
        setLoading && setLoading(true);
        await axios({
          url: `${website}/wp-json/store/v1/settings/user_events_${obj.user_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: `user_events_${obj.user_id}`,
            data: { [obj.id]: { ...obj, calendar_id: calendarData?.id } },
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "User activity added successfully",
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            usersTasks: {
              ...(usersTasks || {}),
              [`user_events_${obj.user_id}`]: {
                ...(usersTasks?.[`user_events_${obj.user_id}`] || {}),
                [obj.id]: obj,
              },
            },
          },
        });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const addUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_addUserTask(obj, setLoading, onSuccess));
    let token = getToken();
    let state = getState();
    let usersTasks = state.chats.usersTasks;
    if (token) {
      try {
        let calendarEventobj = getGoogleEventObjByEvent(obj);
        let calendarData = await createGoogleCalendarEvent(calendarEventobj);
        setLoading && setLoading(true);
        await axios({
          url: `${backendDoamin}/${backendApiEndpoints.user_tasks}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          data: { ...obj, calendar_id: calendarData?.id },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "User activity added successfully",
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            usersTasks: [...(usersTasks || {})].concat(obj),
            // usersTasks: [
            //   ...(usersTasks || {}),
            //   [`user_events_${obj.user_id}`]: {
            //     ...(usersTasks?.[`user_events_${obj.user_id}`] || {}),
            //     [obj.id]: obj,
            //   },
            // ],
          },
        });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const deprecated_updateUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    const website = getWebsite();
    let token = getToken();
    let state = getState();
    let usersTasks = state.chats.usersTasks;
    if (website && token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/user_events_${obj.user_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { type: `user_events_${obj.user_id}`, data: { [obj.id]: obj } },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "User activity updated successfully",
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            usersTasks: {
              ...(usersTasks || {}),
              [`user_events_${obj.user_id}`]: {
                ...(usersTasks[`user_events_${obj.user_id}`] || {}),
                [obj.id]: obj,
              },
            },
          },
        });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const updateUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_updateUserTask(obj, setLoading, onSuccess));
    let token = getToken();
    let state = getState();
    let usersTasks = state.chats.usersTasks;
    console.log(obj, "updateUserTask");
    if (token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${backendDoamin}/${backendApiEndpoints.user_tasks}/${obj.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "PUT",
          data: obj,
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "User activity updated successfully",
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            usersTasks: [...(usersTasks || {})].map((i) =>
              i.id === obj.id ? obj : i
            ),
          },
        });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const deprecated_deleteUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    const website = getWebsite();
    let token = getToken();
    let state = getState();
    let usersTasks = { ...(state.chats.usersTasks || {}) };
    if (website && token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/user_events_${obj.user_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          data: { id: obj.id },
        });
        if (
          data.success
          //  && isObjWithValues(data.data)
        ) {
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: "User activity deleted successfully",
            })
          );
          usersTasks[`user_events_${obj.user_id}`] = data.data;
          dispatch({
            type: EDIT_CHATS,
            payload: {
              usersTasks: usersTasks,
            },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't delete user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const deleteUserTask = (obj, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    if (!getIsBackendMigrated("chats"))
      return dispatch(deprecated_deleteUserTask(obj, setLoading, onSuccess));
    const website = getWebsite();
    let token = getToken();
    let state = getState();
    let usersTasks = { ...(state.chats.usersTasks || {}) };
    if (website && token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/user_events_${obj.user_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          data: { id: obj.id },
        });
        if (
          data.success
          //  && isObjWithValues(data.data)
        ) {
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: "User activity deleted successfully",
            })
          );
          usersTasks[`user_events_${obj.user_id}`] = data.data;
          dispatch({
            type: EDIT_CHATS,
            payload: {
              usersTasks: usersTasks,
            },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't delete user activity! please try again later",
          })
        );
        console.log(getAxiosError(error), "<<<< add activity error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const deprecated_addUserEvent = (obj, setLoading) => {
  return async (dispatch) => {
    const website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/user_events`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { type: "user_events", data: { [obj.id]: obj } },
        });
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "success",
        //     message: "User activity added successfully",
        //   })
        // );
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add EVENT error");
      }
      setLoading && setLoading(false);
    }
  };
};
export const addUserEvent = (obj, setLoading) => {
  return async (dispatch) => {
    // if (!getIsBackendMigrated("chats"))
    return dispatch(deprecated_addUserEvent(obj, setLoading));
    let token = getToken();
    if (token) {
      try {
        setLoading && setLoading(true);
        let { data } = await axios({
          url: `${backendDoamin}/${backendApiEndpoints.settings}/user_events`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "PUT",
          data: { type: "user_events", data: { [obj.id]: obj } },
        });
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "success",
        //     message: "User activity added successfully",
        //   })
        // );
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     severity: "error",
        //     message: "Couldn't add user activity! please try again later",
        //   })
        // );
        console.log(getAxiosError(error), "<<<< add EVENT error");
      }
      setLoading && setLoading(false);
    }
  };
};

export const addCannedMessage = (obj, setLoading, onSuccess) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      setLoading && setLoading(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/canned_messages`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: { type: "canned_messages", data: { [obj.id]: obj } },
        });
        if (data.success && isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_CHATS,
            payload: {
              cannedMessages: data.data,
            },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error), "error");
      }
      setLoading && setLoading(false);
    }
  };
};

export const addNewCustomerInChats = (obj) => {
  return (dispatch, getState) => {
    let state = getState();
    let chats = state.chats.conversations || [];
    // chats.unshif(obj)
    console.log([obj, ...chats]);
    dispatch({
      type: EDIT_CHATS,
      payload: {
        conversations: [obj, ...chats],
      },
    });
  };
};

export const addMessageInRedux = (response, id) => {
  return (dispatch, getState) => {
    let state = getState();

    if (response?.success) {
      let chats = state.chats.conversations || [];
      let customer = chats.find((obj) => obj?.id === id);
      if (customer) {
        customer.conversation.push(response?.data);
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: chats.map((obj) =>
              obj?.id === id ? customer : obj
            ),
          },
        });
      }
    }
  };
};
export const updateCrmIdinSetting = (crm_id, profiles) => {
  return (dispatch, getState) => {
    let state = getState();
    let settings = state.chats.mergeProfiles || {};
    let preObj = Object.values(settings || {}).find(
      (obj) => obj?.crm_id == crm_id
    );
    let data = {};
    if (isObjWithValues(preObj)) {
      data = {
        [preObj?.id]: {
          ...preObj,
          profiles: [...(preObj?.profiles || []), ...(profiles || [])],
        },
      };
    } else {
      let id = `${Date.now()}_${getRandomString(5)}`;
      data = {
        [id]: {
          id,
          crm_id: crm_id,
          profiles: profiles,
        },
      };
    }

    if (data) {
      dispatch(putMergeProfiles(data));
    }
  };
};

export const putMergeProfiles = (data, setLoading, onSuccess) => {
  return async (dispatch, getState) => {
    setLoading && setLoading(true);
    let website = getWebsite();
    let token = getToken();
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/merge_profiles`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        type: "merge_profiles",
        data: {
          ...data,
        },
      },
    });
    if (res?.data?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: { mergeProfiles: res?.data?.data },
      });
      // let state = getState()
      // let conversations = state.chats.conversations
      // dispatch(setUpConversations(conversations))

      onSuccess && onSuccess();
    }
    setLoading && setLoading(false);
  };
};

export const putBroadcastList = (data) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    const res = await axios({
      url: `${website}/wp-json/store/v1/settings/broadcast_list`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        type: "broadcast_list",
        data: {
          ...data,
        },
      },
    });
    if (res?.data?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: { broadcastList: res?.data?.data },
      });
      // setShowTextFeild(false);
    }
  };
};

export const setUpConversationsAccess = () => {
  return (dispatch, getState) => {
    const state = getState();
    let teamMembers = state.settings.teamMembers;
    let allConversations = [...(state.chats.conversations || [])];
    let isAdmin = state.user?.isAdmin;
    let subuserEmail = state.user?.email;
    if (!allConversations) return;
    if (isAdmin)
      return dispatch({
        type: EDIT_CHATS,
        payload: { conversations: allConversations },
      });
    let accessObj =
      isArrayWithValues(teamMembers) &&
      teamMembers?.find((i) => i.email === subuserEmail);
    if (accessObj) {
      let {
        assignedChatCustomers,
        chatCustomerExcluded,
        chatCustomerIncluded,
        excludedChatCustomers,
      } = accessObj;
      if (
        isArrayWithValues(assignedChatCustomers) &&
        isArrayWithValues(allConversations)
      ) {
        if (chatCustomerExcluded)
          allConversations = allConversations?.filter(
            (i) =>
              !Boolean(
                excludedChatCustomers?.find(
                  (accessObj) => accessObj.value == i.id
                )
              )
          );
        else if (chatCustomerIncluded)
          allConversations = allConversations?.filter((i) =>
            Boolean(
              assignedChatCustomers?.find(
                (accessObj) => accessObj.value == i.id
              )
            )
          );
      }

      return dispatch({
        type: EDIT_CHATS,
        payload: { conversations: allConversations },
      });
    }
  };
};

export const setCountOnSegment = (obj, setLoading) => {
  return async (dispatch, getState) => {
    setLoading && setLoading(true);

    let params = returnOptOutParams(obj);
    let count = await getCustomerTotal({
      params: params,
    });
    let state = getState();
    const customerSegments = state.chats.customerSegments;
    dispatch({
      type: EDIT_CHATS,
      payload: {
        customerSegments: {
          ...(customerSegments || {}),
          [obj?.id]: {
            ...obj,
            count,
          },
        },
      },
    });
    setLoading && setLoading(false);
  };
};

export const getEmailTemplate = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            getttingEamilTemplate: true,
          },
        });
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/email_marketing_templates`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        if (res?.data?.type === "success") {
          const sortedArray = Object.entries(res?.data?.data || {}).sort(
            (a, b) => b?.created - a?.created
          );

          const sortedObj = Object.fromEntries(sortedArray);

          dispatch({
            type: EDIT_CHATS,
            payload: {
              emailTemplates: sortedObj,
              // res?.data?.data,
            },
          });
        }
      } catch (error) {}
      dispatch({
        type: EDIT_CHATS,
        payload: {
          emailTemplateFetched: true,
          getttingEamilTemplate: false,
        },
      });
    }
  };
};

export const getCampaignHistory = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: EDIT_CHATS,
        payload: {
          getttingCampaignHistory: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/campaign_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (res?.data?.type === "success") {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            campaignHistory: res?.data?.data,
          },
        });
        dispatch(updateCampaignAnalytics(Object.values(res?.data?.data || {})));
        dispatch(setUpFlowHistory({ campaignHistory: res?.data?.data }));
      }
      dispatch({
        type: EDIT_CHATS,
        payload: {
          getttingCampaignHistory: false,
          campaignHistoryFetched: true,
        },
      });
    }
  };
};

export const updateCampaignAnalytics = (campaignArray) => {
  return async (dispatch) => {
    let filteredCampaignArray =
      campaignArray.filter((campaign) => campaign?.is_campaign) || [];
    let totalCampaigns = filteredCampaignArray?.length;

    let totalCustomers = 0;
    let emailCustomers = 0;
    let openCustomer = 0;
    let reopenedCustomer = 0;
    let campaignLength = {
      email: 0,
      sms: 0,
      whatsapp: 0,
    };
    let segmentObj = {};
    let segmentInfo = {};
    let smsCustomerObject = {};
    let pushNotificationCustomerObject = {};
    for (const campaignObj of campaignArray || []) {
      // getting SMS History
      if (campaignObj?.campaign_info?.campaign_type === "sms") {
        let sentInfoKeys = Object.keys(campaignObj?.sent_info || {});
        for (const key of sentInfoKeys || []) {
          for (const customerObj of campaignObj?.sent_info?.[key] || []) {
            let preArr = smsCustomerObject[customerObj?.id] || [];
            if (
              key === "success" &&
              campaignObj?.sent_info?.["delivered"]?.find(
                (o) => o.id == customerObj?.id
              )
            ) {
              continue;
            }
            smsCustomerObject = {
              ...smsCustomerObject,
              [customerObj?.id]: [
                ...preArr,
                {
                  time: campaignObj?.start_time,
                  template_name: campaignObj?.campaign_info?.template_name,
                  campaign_name: campaignObj?.campaign_info?.name,
                  category: campaignObj?.campaign_info?.category,
                  status: key === "success" ? "initiated" : key,
                  phone: `${customerObj?.dialcode_mobile || ""}${
                    customerObj?.phone || ""
                  }`,
                  first_name: customerObj?.first_name || "",
                  last_name: customerObj?.last_name || "",
                },
              ],
            };
          }
        }
      }
      if (campaignObj?.campaign_info?.campaign_type === "push_notification") {
        let sentInfoKeys = Object.keys(campaignObj?.sent_info || {});
        if (isArrayWithValues(sentInfoKeys))
          for (const key of sentInfoKeys) {
            if (
              isArrayWithValues(campaignObj?.sent_info?.[key]) &&
              campaignObj?.sent_info?.[key]?.length < 4000
            )
              for (const customerObj of campaignObj?.sent_info?.[key]) {
                let preArr =
                  pushNotificationCustomerObject[customerObj?.id] || [];
                pushNotificationCustomerObject = {
                  ...pushNotificationCustomerObject,
                  [customerObj?.id]: [
                    ...preArr,
                    {
                      time: campaignObj?.start_time,
                      payload: campaignObj?.campaign_info?.payload,

                      // template_name: campaignObj?.campaign_info?.template_name,
                      campaign_name: campaignObj?.campaign_info?.name,
                      category: campaignObj?.campaign_info?.category,
                      status: key,
                      // phone: `${customerObj?.dialcode_mobile || ""}${customerObj?.phone || ""}`,
                      // first_name: customerObj?.first_name || "",
                      // last_name: customerObj?.last_name || "",
                    },
                  ],
                };
              }
          }
      }
    }

    for (const campaignObj of filteredCampaignArray || []) {
      if (campaignObj?.campaign_info?.campaign_type)
        campaignLength = {
          ...campaignLength,
          [campaignObj?.campaign_info?.campaign_type]:
            validateNumber(
              campaignLength[campaignObj?.campaign_info?.campaign_type]
            ) + 1,
        };
      totalCustomers += validateNumber(campaignObj?.total_customers);
      if (campaignObj?.campaign_info?.campaign_type === "email") {
        emailCustomers += validateNumber(campaignObj?.total_customers);
        openCustomer += validateNumber(
          Object.keys(campaignObj?.opened || {})?.length
        );
        reopenedCustomer += validateNumber(
          Object.keys(campaignObj?.reopened || {})?.length
        );
      }

      for (const segtmentStr of campaignObj?.campaign_info?.segment_id || []) {
        segmentObj[segtmentStr] = (segmentObj[segtmentStr] || 0) + 1;
        segmentInfo[segtmentStr] = {
          totalSent: (segmentInfo?.[segtmentStr]?.totalSent || 0) + 1,
          campaignNames: [
            ...new Set([
              ...(segmentInfo?.[segtmentStr]?.campaignNames || []),
              campaignObj?.campaign_info?.name,
            ]),
          ],
        };
      }
    }

    let arrayTopCampaign = [...filteredCampaignArray]
      ?.sort(
        (a, b) =>
          validateNumber(Object.keys(b?.opened || {})?.length) -
          validateNumber(Object.keys(a?.opened || {})?.length)
      )
      ?.slice(0, 5);
    let topCampaign = {
      name: arrayTopCampaign?.map((o) => [`${o?.campaign_info?.name || ""}`]),
      data: arrayTopCampaign?.map(
        (o) => `${validateNumber(Object.keys(o?.opened || {})?.length)}`
      ),
      series: [
        {
          name: "Total Customer",
          data: arrayTopCampaign?.map((o) => o?.total_customers),
        },
        {
          name: `Opend`,
          data: arrayTopCampaign?.map(
            (o) => `${validateNumber(Object.keys(o?.opened || {})?.length)}`
          ),
        },
      ],
    };

    const sortedArr = Object.entries(segmentObj || {}).sort(
      (a, b) => b[1] - a[1]
    );

    const sortedObj = Object.fromEntries(sortedArr);
    let topSegment = {
      ids: Object.keys(sortedObj || {})?.slice(0, 5),
      data: Object.values(sortedObj || {})?.slice(0, 5),
    };
    let timeArray = filteredCampaignArray
      ?.map((obj) => [
        ...Object.values(obj?.opened || {})?.map((o) => validateNumber(o.time)),
      ])
      ?.flat();

    let campaignAnalytics = {
      avgOpenRate: Math.round((openCustomer / emailCustomers) * 100),
      avgReopenedRate: Math.round((reopenedCustomer / emailCustomers) * 100),
      avgUnopenRate: 100 - Math.round((openCustomer / emailCustomers) * 100),
      totalCampaigns,
      topCampaign,
      topSegment,
      campaignLength,
      topIdealTime: findMostOpenedTimePeriod(timeArray)?.sort(
        (a, b) => b?.percentage - a?.percentage
      ),
      segmentInfo,
    };

    dispatch({
      type: EDIT_CHATS,
      payload: {
        campaignAnalytics: campaignAnalytics,
        smsCustomerObject,
        pushNotificationCustomerObject,
      },
    });
  };
};

export const updateCampaignHistory = (obj) => {
  return async (dispatch, getState) => {
    let state = getState();
    let campaignHistory = state.chats.campaignHistory;
    if (isObjWithValues(campaignHistory)) {
      // await dispatch(getCampaignHistory())
      let campaignHistory = state.chats.campaignHistory;
      dispatch({
        type: EDIT_CHATS,
        payload: {
          campaignHistory: {
            ...campaignHistory,
            [obj?.id]: { ...(campaignHistory?.[obj?.id] || {}), ...obj },
          },
        },
      });
    }
  };
};

export const setUpCampaignHistory = ({ history }) => {
  return (dispatch) => {
    let crmEmailHistory = {};
    let campaignHistory = history || {};
    for (const historyObj of Object.values(campaignHistory)) {
      let historySentInfo = historyObj?.sent_info || {};
      // if (historySentInfo?.campaign_info?.type !== "email") continue;

      for (const sentType of Object.keys(historySentInfo)) {
        if (isArrayWithValues(historySentInfo?.[sentType])) {
          for (const customerObj of historySentInfo?.[sentType]) {
            crmEmailHistory[customerObj?.id] = Array.isArray(
              crmEmailHistory[customerObj?.id]
            )
              ? crmEmailHistory[customerObj?.id]
              : [];
            crmEmailHistory[customerObj?.id]?.push({
              campaign_info: historyObj?.campaign_info,
              time: historyObj?.start_time,
              sent: sentType,
              opened: historyObj?.opened?.[customerObj?.id],
              reopened: historyObj?.reopened?.[customerObj?.id],
            });
          }
        }
      }
    }
    dispatch({
      type: EDIT_CHATS,
      payload: {
        crmEmailHistory: crmEmailHistory,
      },
    });
  };
};

//////// updated /////////

export const getAllTemplates = (ids, auth_key) => {
  return async (dispatch) => {
    let templatesArray = [];
    dispatch({
      type: EDIT_CHATS,
      payload: {
        fetchingSmsTempletes: true,
      },
    });

    try {
      let getTemplateId = await apiCallFromSocket({
        url: `https://control.msg91.com/api/v5/flows`,
        params: {
          ...msg91Params,
        },
        headers: {
          authkey: auth_key,
        },
      });
      // console.log(getTemplateId, "<< id");
      let idsArray =
        getTemplateId?.data?.data?.data?.map((o) => o?.flow_id) || [];
      for await (const id of idsArray || []) {
        let template = await getSignalMsgTemplate(id, auth_key);
        if (template) templatesArray = [...templatesArray, template];
      }

      dispatch({
        type: EDIT_CHATS,
        payload: {
          smsTemplates: templatesArray,
          fetchingSmsTempletes: false,
        },
      });
    } catch (error) {
      console.log(getAxiosError(error));
      dispatch({
        type: EDIT_CHATS,
        payload: {
          smsTemplates: [],
          fetchingSmsTempletes: false,
        },
      });
    }
  };
};

export const bulkActionChats = ({ data, setLoading, onSuccess, params }) => {
  return async (dispatch, getState) => {
    setLoading && setLoading(true);
    try {
      let res = await bulkActionChatUser({
        data,
        params,
      });
      if (res?.success) {
        let state = getState();

        let conversations = state.chats.conversations;
        let key = Object.keys(data || {})?.[0];
        let responseArray = res?.data?.[key];
        onSuccess && onSuccess(responseArray);
        if (key === "update")
          conversations = conversations?.map((obj) => {
            let updateUser = responseArray?.find((o) => o?.id === obj?.id);
            if (updateUser) {
              obj = { ...obj, ...updateUser };
            }
            return obj;
          });
        else if (key === "create")
          conversations = [
            ...(responseArray || [])?.filter((o) => o?.id),
            ...(conversations || []),
          ];
        dispatch({
          type: EDIT_CHATS,
          payload: {
            conversations: conversations,
          },
        });
      }
      setLoading && setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading && setLoading(false);
    }
  };
};

export const handleSocialMessage = ({ payloadObject }) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let platform_id =
        payloadObject?.basicInfo?.id || payloadObject?.mainPayload?.from;
      let conversations = state.chats.conversations || [];
      if (!isArrayWithValues(conversations)) return;
      let reduxUser = conversations?.find(
        (obj) => obj?.platform_id === platform_id
      );
      let messages = payloadObject?.mainPayload?.messages;
      if (reduxUser) {
        let updatedConversations = [];

        conversations?.forEach((obj) => {
          if (obj?.platform_id === platform_id) {
            if (isArrayWithValues(messages)) {
              let conversations = obj.conversation || [];
              obj.conversation = conversations?.concat(messages);
              updatedConversations.unshift(obj);
            } else {
              obj.conversation = [
                ...(obj?.conversation || []),
                payloadObject?.payload || {},
              ];
              updatedConversations.unshift(obj);
            }
          } else updatedConversations?.push(obj);
        });
        conversations = updatedConversations;
        // conversations?.map((obj) => {
      } else {
        try {
          let newUser = await getChatsUsers({
            params: {
              platform_id,
            },
          });
          if (newUser?.success) {
            let [user] = newUser?.data;
            conversations = [user, ...conversations];
          }
        } catch (error) {
          console.log(error);
        }
      }
      dispatch({
        type: EDIT_CHATS,
        payload: {
          conversations: conversations,
        },
      });
    } catch (error) {
      console.log(error, "<<<<<<<<<<< handleSocialMessage");
    }
  };
};

export const getChatDashboardAction = () => {
  return async (dispatch) => {
    try {
      let res = await getChatDashboard();
      if (res?.success) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            chatsDashboard: res?.data,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
};

export const assignBroadcast = ({
  setLoading,
  usersMetaData = [],
  // remove,
  newBroadcast,
  broadcastId,
}) => {
  return async (dispatch, getState) => {
    let state = getState();
    let broadcast = state?.chats?.chat_broadcasts;
    broadcast = { ...broadcast, ...newBroadcast };
    dispatch(bulkActionChats({ data: { update: usersMetaData } }));

    let broadcastData = {};
    Object.entries(broadcast || {})?.forEach(([key, value]) => {
      let newCustomersIds = usersMetaData
        ?.filter((obj) => obj?.meta_data?.broadcast?.includes(key))
        ?.map((obj) => obj?.id);
      let newContactsValue = [
        ...new Set([...(value.contact_ids || []), ...newCustomersIds]),
      ];
      if (broadcastId && key === broadcastId) {
        value.contact_ids = newContactsValue?.filter(
          (id) => !usersMetaData?.map((obj) => obj?.id)?.includes(id)
        );
      } else {
        value.contact_ids = newContactsValue;
      }
      // value.contact_ids = remove
      //   ? newContactsValue?.filter(
      //       (id) => !usersMetaData?.map((obj) => obj?.id)?.includes(id)
      //     )
      //   : newContactsValue;
      broadcastData[key] = value;
    });
    let res = await settingApi({
      data: {
        data: broadcastData,
      },
      // data: broadcastData,
      method: "POST",
      endpoint: "chat_broadcasts",
    });
    if (res?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: { chat_broadcasts: res?.data },
      });
    }
  };
};

export const updateChatUserInRedux = ({ user }) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let conversations = state.chats?.conversations || [];

      conversations = conversations?.map((obj) => {
        if (`${obj.id}` === `${user.id}`) {
          let updated = unformatChatUser({ user });
          return { ...obj, ...updated };
        } else return obj;
      });
      dispatch({
        type: EDIT_CHATS,
        payload: {
          conversations,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
};
export const deleteChatUserInRedux = ({ user }) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let conversations = state.chats?.conversations || [];

      conversations = conversations?.filter((obj) => {
        return `${obj?.id}` !== `${user?.id}`;
      });
      dispatch({
        type: EDIT_CHATS,
        payload: {
          conversations,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
};
