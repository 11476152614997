import {
  Alert,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  ConfimPopup,
  callSocket,
  commerceUpdateApi,
  formatServerValue,
  getAdminName,
  getEmail,
  getId,
  getMasterPricingHistoryObj,
  getPricingdiff,
  getWebsite,
  goldName,
  isArrayWithValues,
  isDev,
  isEarlyAccess,
  isObjWithValues,
  isOurWebsite,
  validateUpdatePricing,
} from "../../helper";
import { styled } from "@mui/material/styles";
import Gold from "./Gold";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  EDIT_SETTINGS,
  addMetalPricingHistory,
  fetchAllSettings,
  getProductDefaultSettings,
  updateMarketPlaceProducts,
  updateMasterPricings,
} from "../../redux/actions/settingActions";
import Silver from "./Silver";
import PropTypes from "prop-types";
import Platinum from "./Platinum";
import Labour from "./Labour";
import Diamond from "./Diamond";
import Additional from "./Additional";
import { useTheme } from "@mui/system";
import { Cached, History, YouTube } from "@mui/icons-material";
import Gemstone from "./Gemstone";
import { fetchTaxonomies } from "../../redux/actions/productActions";
import { sendNewRatesPushNotification } from "../../redux/actions/hubActions";
import {
  setGlobalToast,
  setGlobalTutorial,
  setHelperData,
} from "../../redux/actions/helperActions";
import { getIp, tutorialData } from "../../App";
import Margin from "./Margin";
import ProductUpdateHistory, {
  getStrFromActivity,
} from "../settings/history/ProductUpdateHistory";
import { UAParser } from "ua-parser-js";
import { findDifferences, findDifferencesBetweenObjects } from "./helper";

const defaultTabs = [
  // { label: "Gold", value: "gold" },
  // { label: "Silver", value: "silver" },
  // { label: "Platinum", value: "platinum" },
  { label: "Labour", value: "labour" },
  // { label: "Diamond", value: "diamond" },
  // { label: "Gemstone", value: "gemstone" },
  { label: "Additional", value: "additional" },
  { label: "Margin", value: "margin" },
];
// ?.filter((i) => (!isDev() ? i.value !== "margin" : true));
// .filter(i => isDev() ? i.value !== 'margin': );

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  marginBottom: "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: "#635ee7",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "5px 8px",
    minWidth: "50px",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    "&.Mui-selected": {
      color: theme.palette.text.primary,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
// hallmark , certificate, rodiam

const Pricing = forwardRef(({ hideUpdate = false, updateLoading }, ref) => {
  const [allTabs, setAllTabs] = useState(defaultTabs);
  const [selectedTabName, setSelectedTabName] = useState("");
  const [changePurity, setChangePurity] = useState({
    gold: [],
    silver: [],
    platinum: [],
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [goldPurities, setGoldPurities] = useState([]);
  const [silverPurities, setSilverPurities] = useState([]);
  const [platinumPurities, setPlatinumPurities] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState("INR");
  const [showSync, setShowSync] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [activity, setActivity] = useState({});
  const dispatch = useDispatch();
  const goldRef = useRef();
  const silverRef = useRef();
  const platinumRef = useRef();
  const labourRef = useRef();
  const diamondRef = useRef();
  const gemstoneRef = useRef();
  const marginRef = useRef();
  const additionalRef = useRef();
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const fetchingMasterPricing = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const changeInCombination = useSelector(
    (state) => state?.helper?.changeInCombination
  );
  const oldMasterSetting = useSelector(
    (state) => state?.helper?.oldMasterSetting
  );
  const [toast, setToast] = useState({ show: false });
  const [showHistoryAlert, setShowHistoryAlert] = useState(false);

  const reduxProductPriceUpdateHistoryRef = useSelector(
    (state) => state?.settings?.productPriceUpdateHistoryRef
  );
  const productPriceUpdateHistory = useSelector(
    (state) => state?.settings?.productPriceUpdateHistory
  );

  useEffect(() => {
    if (
      isObjWithValues(reduxProductPriceUpdateHistoryRef) &&
      reduxProductPriceUpdateHistoryRef?.failed?.length > 0
    ) {
      setShowHistoryAlert(true);
    } else {
      setShowHistoryAlert(false);
    }

    return () => {};
  }, [reduxProductPriceUpdateHistoryRef]);

  useImperativeHandle(ref, () => ({
    onSave(afterUpdate) {
      onUpdate({}, afterUpdate);
    },
    // getAlert() {
    //   alert("getAlert from Child");
    // }
  }));

  useEffect(() => {
    if (isObjWithValues(productSettings))
      if (isArrayWithValues(productSettings.currency)) {
        let { currency } = productSettings;
        setSelectedCurrency(currency[0].value);
        setAllCurrency(productSettings.currency);
      }
  }, [productSettings]);

  useEffect(() => {
    if (!isObjWithValues(oldMasterSetting))
      dispatch(
        setHelperData({
          oldMasterSetting: masterPricing,
        })
      );

    return () => {};
  }, [masterPricing]);

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());
  }, []);

  useEffect(() => {
    if (isObjWithValues(productSettings)) {
      let {
        types,
        gold_purities,
        silver_purities,
        platinum_purities,

        default_currency,
        collections,
      } = productSettings;

      let array = [];
      if (default_currency) {
        setDefaultCurrency(default_currency);
        setSelectedCurrency(default_currency);
      }
      if (isArrayWithValues(types)) {
        if (
          types.find((i) => i.value === "gold") &&
          !allTabs.find((i) => i.value === "gold")
        ) {
          array.push({ label: "Gold", value: "gold" });
          if (isArrayWithValues(gold_purities)) setGoldPurities(gold_purities);
        }
        if (
          types.find((i) => i.value === "silver") &&
          !allTabs.find((i) => i.value === "silver")
        ) {
          array.push({ label: "Silver", value: "silver" });
          if (isArrayWithValues(silver_purities))
            setSilverPurities(silver_purities);
        }
        if (
          types.find((i) => i.value === "platinium") &&
          !allTabs.find((i) => i.value === "platinium")
        ) {
          array.push({ label: "Platinum", value: "platinium" });
          if (isArrayWithValues(platinum_purities))
            setPlatinumPurities(platinum_purities);
        }

        if (
          types.find((i) => i.value === "diamond") &&
          !allTabs.find((i) => i.value === "diamond")
        ) {
          array.push({ label: "Diamond", value: "diamond" });
          // if (isArrayWithValues(platinum_purities))
          //   setPlatinumPurities(platinum_purities);
        }
        if (
          types.find((i) => i.value === "gemstone") &&
          !allTabs.find((i) => i.value === "gemstone")
        ) {
          array.push({ label: "Gemstone", value: "gemstone" });
          // if (isArrayWithValues(platinum_purities))
          //   setPlatinumPurities(platinum_purities);
        }
      }
      // console.log(
      //   collections,
      //   collections.find((i) => i.value === "gemstone-jewellery"),
      //   !allTabs.find((i) => i.value === "gemstone")
      // );
      // if (isArrayWithValues(collections)) {
      //   if (
      //     collections.find((i) => i.value === 'gemstone-jewellery"') &&
      //     !allTabs.find((i) => i.value === "gemstone")
      //   ) {
      //     array.push({ label: "Gemstone", value: "gemstone" });
      //   }
      // }
      // console.log(array);
      setAllTabs((state) => [...array, ...state]);
      if (onlyChats || user_products?.includes("whatsapp")) {
        let chatsTabs = ["gold", "silver", "platinium"];
        setAllTabs([
          { label: "Gold", value: "gold" },
          { label: "Silver", value: "silver" },
          { label: "Platinum", value: "platinium" },
        ]);
      }
    }
    dispatch(getProductDefaultSettings());
  }, [productSettings]);

  useEffect(() => {
    // if (!isObjWithValues(productSettings)) dispatch(getProductSettings());
    // if (!isObjWithValues(masterPricing))
    //   dispatch(getMasterPricings({ setLoading: setUpdating }));
    // if (!isObjWithValues(reduxProductSettings)) getProductSettings(isMounted);
    // else setSettingsFromRedux({ ...reduxProductSettings });
  }, []);

  const fetchMasterPricings = () => {
    dispatch(fetchAllSettings({ setLoading: setUpdating }));
    // dispatch(getMasterPricings({ setLoading: setUpdating }));
  };

  useEffect(() => {
    if (isArrayWithValues(allTabs)) setSelectedTabName(allTabs[0].value);
    // setSelectedTab(allTabs.length - 4);
  }, [allTabs]);
  useEffect(() => {
    if (allTabs[selectedTab]) setSelectedTabName(allTabs[selectedTab].value);
  }, [selectedTab]);

  const onPricngChange = (metal, purity) => {
    let oldPurities = changePurity[metal] || [];
    setChangePurity((pre) => ({ ...pre, [metal]: [...oldPurities, purity] }));
  };

  useEffect(() => {
    console.log(changeInCombination);

    return () => {};
  }, [changeInCombination]);

  const getActivity = () => {
    let activity = {};
    const updateDiff = (obj) => {
      activity = { ...activity, ...(obj || {}) };
    };
    const extractActivity = (inputJson) => {
      const history = {};

      try {
        for (const category in inputJson) {
          const categoryItems = inputJson[category];

          categoryItems.forEach((item) => {
            const modifiedProps = item.modifiedProperties;

            for (const prop in modifiedProps) {
              const lastValue = modifiedProps[prop].lastValue;
              const currentValue = modifiedProps[prop].currentValue;

              if (
                !history[category] &&
                JSON.stringify(lastValue) !== JSON.stringify(currentValue)
              ) {
                if (category === "additionalPrice") {
                  history["additional"] = {};
                } else {
                  history[category] = {};
                }
              }

              if (
                category === "gold" ||
                category === "silver" ||
                category === "platinum"
              ) {
                for (const key in currentValue) {
                  if (
                    (prop === "automatic" || prop === "enabled_purities") &&
                    lastValue[key]?.rate !== currentValue[key]?.rate
                  ) {
                    history[category][key] = {
                      new: currentValue[key].rate,
                      old: lastValue[key]?.rate || "",
                    };
                  }
                }
              } else if (category === "diamond" || category === "gemstone") {
                if (
                  item.name &&
                  JSON.stringify(lastValue) !== JSON.stringify(currentValue)
                ) {
                  history[category][item.name] = {
                    new: currentValue,
                    old: lastValue || "",
                  };
                }
              } else if (category === "labour" && item.name) {
                // for (const key in currentValue) {
                const historyKey = `${item.name} - ${prop}`;
                if (
                  JSON.stringify(lastValue) !== JSON.stringify(currentValue)
                ) {
                  history[category][historyKey] = {
                    new: currentValue,
                    old: lastValue || "",
                  };
                }
                // }
              } else if (category === "additionalPrice") {
                for (const key in currentValue) {
                  if (
                    JSON.stringify(lastValue[key]) !==
                    JSON.stringify(currentValue[key])
                  ) {
                    history["additional"][prop] = {
                      new: currentValue?.INR,
                      old: lastValue?.INR || "",
                    };
                  }
                }
              } else if (category === "margin" && prop === "INR") {
                lastValue.forEach((lastVal, index) => {
                  const currVal = currentValue[index];
                  if (lastVal.margin !== currVal.margin) {
                    const historyKey = lastVal.title || `Item ${index + 1}`;
                    history[category][historyKey] = {
                      new: currVal.margin,
                      old: lastVal.margin,
                    };
                  }
                });
              }
            }
          });
        }
      } catch (e) {
        console.log(e);
      }

      if (history?.platinum) {
        if (!isObjWithValues(history?.platinum)) delete history.platinum;
      }
      if (history?.silver) {
        if (!isObjWithValues(history?.silver)) delete history.silver;
      }

      return history;
    };

    let silver_pricing = silverRef.current.getSilverPricing(updateDiff);

    let gold_pricing = goldRef.current.getGoldPricing(updateDiff);

    let platinum_pricing = platinumRef.current.getPlatinumPricing(updateDiff);

    let labour_pricing = labourRef.current.getLabourPricing();

    let diamond_pricing = diamondRef.current.getDiamondPricing();

    let gemstone_pricing = gemstoneRef.current.getGemstonePricing();

    let additional_pricing = additionalRef.current.getAdditionalPricing();

    let margin_pricing = marginRef.current.getMarginPricing();

    let { default_currency: currency } = productSettings || {};
    let {
      gold_pricing: old_gold_pricing,
      silver_pricing: old_silver_pricing,
      platinum_pricing: old_platinum_pricing,
      diamond_pricing: old_diamond_pricing,
      gemstone_pricing: old_gemstone_pricing,
      labour_pricing: old_labour_pricing,
      additional_pricing: old_additional_pricing,
      margin_pricing: old_margin_pricing_pricing,
    } = oldMasterSetting || {};

    let obj = {};
    let goldDifference = isObjWithValues(old_gold_pricing)
      ? findDifferencesBetweenObjects(
          old_gold_pricing[currency],
          gold_pricing[currency]
        )
      : [];
    let silverDifference = isObjWithValues(old_silver_pricing)
      ? findDifferencesBetweenObjects(
          old_silver_pricing[currency],
          silver_pricing[currency]
        )
      : [];
    let platinumDifference = isObjWithValues(old_platinum_pricing)
      ? findDifferencesBetweenObjects(
          old_platinum_pricing[currency],
          platinum_pricing[currency]
        )
      : [];
    let diamondDifference = isObjWithValues(old_diamond_pricing)
      ? findDifferences(
          old_diamond_pricing[currency],
          diamond_pricing[currency]
        )
      : [];
    let gemstoneDifference = isObjWithValues(old_gemstone_pricing)
      ? findDifferences(
          old_gemstone_pricing[currency],
          gemstone_pricing[currency]
        )
      : [];
    let labourDifference = isObjWithValues(old_labour_pricing)
      ? findDifferences(old_labour_pricing[currency], labour_pricing[currency])
      : [];
    let additional_pricingDifference = isObjWithValues(old_additional_pricing)
      ? findDifferencesBetweenObjects(
          old_additional_pricing,
          additional_pricing
        )
      : [];
    let marginDifference = isObjWithValues(old_margin_pricing_pricing)
      ? findDifferencesBetweenObjects(
          old_margin_pricing_pricing,
          margin_pricing
        )
      : [];

    if (isArrayWithValues(goldDifference)) obj.gold = goldDifference;
    if (isArrayWithValues(silverDifference)) obj.silver = silverDifference;
    if (isArrayWithValues(platinumDifference))
      obj.platinum = platinumDifference;
    if (isArrayWithValues(diamondDifference)) obj.diamond = diamondDifference;
    if (isArrayWithValues(gemstoneDifference))
      obj.gemstone = gemstoneDifference;
    if (isArrayWithValues(labourDifference)) obj.labour = labourDifference;
    if (isArrayWithValues(additional_pricingDifference))
      obj.additionalPrice = additional_pricingDifference;
    if (isArrayWithValues(marginDifference)) obj.margin = marginDifference;

    setActivity(getStrFromActivity(extractActivity(obj)) || {});
  };

  const onUpdate = (
    {
      gold_pricing,
      silver_pricing,
      platinum_pricing,
      labour_pricing,
      diamond_pricing,
      gemstone_pricing,
      additional_pricing,
      margin_pricing,
      setLoading,
      callback,
      skipMasterUpdateHook,
    },
    afterUpdate,
    clicked
  ) => {
    // here we're checking that other than metal there is any change, if not that means metal is changed but we're not comparing so could be empty metal update

    let activity = {};
    const updateDiff = (obj) => {
      activity = { ...activity, ...(obj || {}) };
    };
    let metalPricesUpdated =
      !labour_pricing &&
      !diamond_pricing &&
      !gemstone_pricing &&
      !additional_pricing &&
      !margin_pricing;

    if (!silver_pricing)
      silver_pricing = silverRef.current.getSilverPricing(updateDiff);

    if (!gold_pricing)
      gold_pricing = goldRef.current.getGoldPricing(updateDiff);

    if (!platinum_pricing)
      platinum_pricing = platinumRef.current.getPlatinumPricing(updateDiff);

    if (!labour_pricing) labour_pricing = labourRef.current.getLabourPricing();

    if (!diamond_pricing)
      diamond_pricing = diamondRef.current.getDiamondPricing();

    if (!gemstone_pricing)
      gemstone_pricing = gemstoneRef.current.getGemstonePricing();

    if (!additional_pricing)
      additional_pricing = additionalRef.current.getAdditionalPricing();

    if (!margin_pricing) margin_pricing = marginRef.current.getMarginPricing();

    // let
    let goldKey = validateUpdatePricing(gold_pricing, defaultCurrency);
    let silverkey = validateUpdatePricing(silver_pricing, defaultCurrency);
    let platinumkey = validateUpdatePricing(platinum_pricing, defaultCurrency);

    if (goldKey) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${goldName[goldKey]} gold value is empty`,
          severity: "error",
        })
      );
      return;
    }
    if (silverkey) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${silverkey} Silver value is empty`,
          severity: "error",
        })
      );
      return;
    }
    if (platinumkey) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${platinumkey} Platinum value is empty`,
          severity: "error",
        })
      );
      return;
    }

    dispatch(
      updateMasterPricings({
        data: {
          gold_pricing,
          silver_pricing,
          platinum_pricing,
          labour_pricing,
          diamond_pricing,
          gemstone_pricing,
          additional_pricing,
          margin_pricing,
          last_updated: Math.floor(Date.now() / 1000),
        },
        setLoading: (value) => {
          setUpdating(value);
          setLoading && setLoading(value);
          updateLoading && updateLoading(value);
        },
        callback,
        setToast,
        skipMasterUpdateHook,
        afterUpdate: async () => {
          console.log("changePurity", changePurity);
          if (skipMasterUpdateHook) return;
          if (typeof afterUpdate === "function") await afterUpdate();

          dispatch(
            addMetalPricingHistory(
              getMasterPricingHistoryObj(
                {
                  gold_pricing,
                  silver_pricing,
                  platinum_pricing,
                },
                defaultCurrency
              )
            )
          );

          let { default_currency: currency } = productSettings || {};
          // let old_gold_pricing = oldMasterSetting?.gold_pricing || {};
          // let old_silver_pricing = oldMasterSetting?.silver_pricing || {};
          // let old_platinum_pricing = oldMasterSetting?.platinum_pricing || {};

          // let old_diamond_pricing = oldMasterSetting?.diamond_pricing;
          // let old_gemstone_pricing = oldMasterSetting?.gemstone_pricing;
          // let old_labour_pricing = oldMasterSetting?.labour_pricing;
          // let old_additional_pricing = oldMasterSetting?.additional_pricing;
          let {
            gold_pricing: old_gold_pricing,
            silver_pricing: old_silver_pricing,
            platinum_pricing: old_platinum_pricing,
            diamond_pricing: old_diamond_pricing,
            gemstone_pricing: old_gemstone_pricing,
            labour_pricing: old_labour_pricing,
            additional_pricing: old_additional_pricing,
            margin_pricing: old_margin_pricing_pricing,
          } = oldMasterSetting || {};

          let obj = {};
          let goldDifference = isObjWithValues(old_gold_pricing)
            ? findDifferencesBetweenObjects(
                old_gold_pricing[currency],
                gold_pricing[currency]
              )
            : [];
          let silverDifference = isObjWithValues(old_silver_pricing)
            ? findDifferencesBetweenObjects(
                old_silver_pricing[currency],
                silver_pricing[currency]
              )
            : [];
          let platinumDifference = isObjWithValues(old_platinum_pricing)
            ? findDifferencesBetweenObjects(
                old_platinum_pricing[currency],
                platinum_pricing[currency]
              )
            : [];
          let diamondDifference = isObjWithValues(old_diamond_pricing)
            ? findDifferences(
                old_diamond_pricing[currency],
                diamond_pricing[currency]
              )
            : [];
          let gemstoneDifference = isObjWithValues(old_gemstone_pricing)
            ? findDifferences(
                old_gemstone_pricing[currency],
                gemstone_pricing[currency]
              )
            : [];
          let labourDifference = isObjWithValues(old_labour_pricing)
            ? findDifferences(
                old_labour_pricing[currency],
                labour_pricing[currency]
              )
            : [];
          let additional_pricingDifference = isObjWithValues(
            old_additional_pricing
          )
            ? findDifferencesBetweenObjects(
                old_additional_pricing,
                additional_pricing
              )
            : [];
          let marginDifference = isObjWithValues(old_margin_pricing_pricing)
            ? findDifferencesBetweenObjects(
                old_margin_pricing_pricing,
                margin_pricing
              )
            : [];

          if (isArrayWithValues(goldDifference)) obj.gold = goldDifference;
          if (isArrayWithValues(silverDifference))
            obj.silver = silverDifference;
          if (isArrayWithValues(platinumDifference))
            obj.platinum = platinumDifference;
          if (isArrayWithValues(diamondDifference))
            obj.diamond = diamondDifference;
          if (isArrayWithValues(gemstoneDifference))
            obj.gemstone = gemstoneDifference;
          if (isArrayWithValues(labourDifference))
            obj.labour = labourDifference;
          if (isArrayWithValues(additional_pricingDifference))
            obj.additionalPrice = additional_pricingDifference;
          if (isArrayWithValues(marginDifference))
            obj.margin = marginDifference;

          dispatch(
            setHelperData({
              changeInCombination: false,
              oldMasterSetting: {},
            })
          );

          console.log("master_update_hook", obj);

          if (
            (isObjWithValues(obj) || isObjWithValues(activity)) &&
            isEarlyAccess()
          ) {
            let hookRes = await callSocket({
              endpoint: "master_update_hook",
              method: "POST",
              data: {
                website: getWebsite(),
                historyData: {
                  activity,
                  master_update: obj,
                  ip: await getIp(),
                  ua: new UAParser().getUA(),
                  isAdmin: isAdmin,
                  by: getId(),
                },
                email: getEmail(),
                userName: getAdminName(),
                pricingInfo: {
                  old: {
                    diamond:
                      isArrayWithValues(diamondDifference) &&
                      old_diamond_pricing,
                    gemstone:
                      isArrayWithValues(gemstoneDifference) &&
                      old_gemstone_pricing,
                    labour:
                      isArrayWithValues(labourDifference) && old_labour_pricing,
                  },
                  new: {
                    diamond:
                      isArrayWithValues(diamondDifference) && diamond_pricing,
                    gemstone:
                      isArrayWithValues(gemstoneDifference) && gemstone_pricing,
                    labour:
                      isArrayWithValues(labourDifference) && labour_pricing,
                  },
                },
                require_history: true,
              },
            });

            console.log("masterUpdateHook", hookRes);

            if (isObjWithValues(hookRes?.data?.data)) {
              dispatch({
                type: EDIT_SETTINGS,
                payload: {
                  productPriceUpdateHistory: {
                    ...(productPriceUpdateHistory || {}),
                    [hookRes?.data?.data?.id]: hookRes?.data?.data,
                  },
                },
              });
              setShowSync(true);
            }

            dispatch(
              setGlobalToast({
                show: true,
                message: "Price update started",
                message: "success",
              })
            );
          }

          if (metalPricesUpdated) {
            dispatch(sendNewRatesPushNotification());
            commerceUpdateApi();
            dispatch(updateMarketPlaceProducts());
          }
        },
        updateGoogleProducts: metalPricesUpdated,
      })
    );
  };

  const onTabChange = (value) => {
    // if (!changeInCombination) {
    setSelectedTab(value);
    // } else {
    // }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  console.log("isHaveAccess()", isEarlyAccess());

  return (
    <Box>
      <ConfimPopup
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
          setActivity({});
        }}
        heading={
          isObjWithValues(activity)
            ? "Are you sure you want to update pricing ?"
            : "There are no changes to apply"
        }
        // content={"You can only update the pricing 3 times in 24hrs"}
        content={
          isObjWithValues(activity)
            ? Object.keys(activity || {})?.map((key) => {
                return (
                  <Fragment key={key}>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: 0, marginTop: 8 }}
                    >
                      • {formatServerValue(key || "")}
                    </Typography>
                    <Stack>
                      {activity[key]?.map((str) => {
                        return <Typography>{str}</Typography>;
                      })}
                    </Stack>
                  </Fragment>
                );
              })
            : "Please update values to continue"
        }
        closeLabel={!isObjWithValues(activity) ? "Close" : null}
        button={
          isObjWithValues(activity) ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpenConfirm(false);
                onUpdate({}, "", true);
              }}
            >
              Confirm
            </Button>
          ) : null
        }
      />
      <ProductUpdateHistory
        closeSync={() => {
          setShowSync(false);
        }}
        showSync={showSync}
      />
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast((state) => ({ ...state, show: false }))}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Helmet title="Masters" />
      {/* <Grid justifyContent="space-between" container spacing={2} p={6} pt={4}>
        <Grid item></Grid>
      </Grid> */}
      <Stack
        direction={"row"}
        alignItems="flex-end"
        justifyContent={"space-between"}
        spacing={2}
        p={isMobile ? 3 : 3}
        pt={1}
        sx={{ display: hideUpdate ? "none" : "flex" }}
      >
        <Box>
          {/* <Typography variant="h3" gutterBottom display="inline">
            Pricing Masters
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={3}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Pricing Masters</Typography>
          </Breadcrumbs> */}
        </Box>
        <Stack direction="row" justifyContent={"flex-end"}>
          {isOurWebsite() && (
            <IconButton onClick={() => setShowSync(true)}>
              {showHistoryAlert && isDev() ? (
                <Badge color="error" variant="dot">
                  <History />
                </Badge>
              ) : (
                <History />
              )}
            </IconButton>
          )}
          <IconButton
            onClick={() =>
              dispatch(
                setGlobalTutorial({
                  show: true,
                  url: tutorialData?.metal_pricing?.link,
                  title: tutorialData?.metal_pricing?.title,
                })
              )
            }
          >
            <YouTube />
          </IconButton>
          <LoadingButton
            loading={updating || fetchingMasterPricing}
            // variant="contained"
            // loadingPosition=""
            onClick={fetchMasterPricings}
            startIcon={<Cached />}
          >
            {isMobile ? "" : "Refresh"}
          </LoadingButton>
          <LoadingButton
            className={changeInCombination ? "btn--jump" : ""}
            // loading={updating}
            disabled={updating || fetchingMasterPricing}
            variant="contained"
            // loadingPosition=""
            onClick={() => {
              getActivity();
              setOpenConfirm(true);
            }}
          >
            {isDev() ? "Apply" : "Save"} Changes
          </LoadingButton>
        </Stack>
      </Stack>
      {/* <Stack direction={"row"} justifyContent="flex-end">
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Default</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCurrency}
            label="Default"
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {allCurrency.map((i) => {
              return (
                <MenuItem
                  value={i.value}
                >{`${i.label} (${i.symbol})`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack> */}
      <StyledTabs
        value={selectedTab}
        onChange={(e, value) => onTabChange(value)}
        aria-label="styled tabs example"
        variant="scrollable"
      >
        {allTabs.map((i) => (
          <StyledTab label={i.label} />
        ))}
        {/* <StyledTab label="Grouping" />
        <StyledTab label="Size Masters" />
        <StyledTab label="Team members" />
        <StyledTab label="Product Shortcuts" /> */}
        {/* <StyledTab label="Masters" /> */}
      </StyledTabs>
      {/* <Stack direction="row" sx={{ overflow: "auto" }}>
        {allTabs.map((i) => {
          const isSelected = i.value === selectedTab;
          return (
            <Button
              //   variant={isSelected ? "contained" : "text"}
              disableRipple
              sx={{
                mx: 2,
                backgroundColor: isSelected ? "#eee" : "transparent",
                color: isSelected ? "primary.main" : "text.secondary",
                whiteSpace: "nowrap",
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack> */}
      <Box>
        <Box sx={{ display: selectedTabName === "gold" ? "block" : "none" }}>
          <Gold
            changePurity={changePurity}
            onPricngChange={onPricngChange}
            ref={goldRef}
            selectedCurrency={selectedCurrency}
            goldPurities={goldPurities}
            productSettings={productSettings}
          />
        </Box>
        <Box sx={{ display: selectedTabName === "silver" ? "block" : "none" }}>
          <Silver
            changePurity={changePurity}
            onPricngChange={onPricngChange}
            ref={silverRef}
            selectedCurrency={selectedCurrency}
            silverPurities={silverPurities}
            productSettings={productSettings}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "platinium" ? "block" : "none" }}
        >
          <Platinum
            changePurity={changePurity}
            onPricngChange={onPricngChange}
            ref={platinumRef}
            selectedCurrency={selectedCurrency}
            platinumPurities={platinumPurities}
            productSettings={productSettings}
          />
        </Box>
        <Box sx={{ display: selectedTabName === "labour" ? "block" : "none" }}>
          <Labour
            ref={labourRef}
            productSettings={productSettings}
            selectedCurrency={selectedCurrency}
            defaultCurrency={defaultCurrency}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            onMasterUpdate={onUpdate}
          />
        </Box>
        <Box sx={{ display: selectedTabName === "diamond" ? "block" : "none" }}>
          <Diamond
            ref={diamondRef}
            onMasterUpdate={onUpdate}
            selectedCurrency={selectedCurrency}
            defaultCurrency={defaultCurrency}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            productSettings={productSettings}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "gemstone" ? "block" : "none" }}
        >
          <Gemstone
            ref={gemstoneRef}
            onMasterUpdate={onUpdate}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            defaultCurrency={defaultCurrency}
            selectedCurrency={selectedCurrency}
            productSettings={productSettings}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "additional" ? "block" : "none" }}
        >
          <Additional
            ref={additionalRef}
            productSettings={productSettings}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            defaultCurrency={defaultCurrency}
            selectedCurrency={selectedCurrency}
            onMasterUpdate={onUpdate}
          />
        </Box>
        <Box sx={{ display: selectedTabName === "margin" ? "block" : "none" }}>
          <Margin
            ref={marginRef}
            onMasterUpdate={onUpdate}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            defaultCurrency={defaultCurrency}
            selectedCurrency={selectedCurrency}
            productSettings={productSettings}
          />
        </Box>
        {/* <TabPanel value={selectedTab} index={0}>
          <Gold ref={goldRef} goldPurities={goldPurities} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Silver ref={silverRef} silverPurities={silverPurities} />
        </TabPanel> */}
        {/* {selectedTabName === "gold" && (
          <Gold ref={goldRef} goldPurities={goldPurities} />
        )}
        {selectedTabName === "silver" && (
          <Silver ref={silverRef} silverPurities={silverPurities} />
        )} */}
      </Box>
    </Box>
  );
});

export default Pricing;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
