import axios from "axios";
import {
  calculateTotalDiamondWeight,
  calculateTotalGemstoneWeight,
  createCustomCustomer,
  formatDate,
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  goldPurities,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  unformatServerValue,
  updateJweroBatchCustomers,
  updateJweroBatchProducts,
  validateNumber,
} from "../../../../helper";
import { isValidDate } from "../../../../utils/dateFunctions";
import { getAllLabourMasterPricing } from "../../../products/AddProduct";
import {
  diamondCut,
  diamondShapes,
  diamondTypes,
  gemstoneQualities,
  gemstoneShapes,
  gemstoneTypes,
} from "../../../settings/MasterSettings";
import { getCustomerIdFromAllCustomers } from "../../../customers/helpers";

const updateCustomersFromSheet = async ({
  customers = [],
  setProgress,
  setLoading,
  allCustomersWithId,
  setLoadingText,
  isUpdate = false,
  states,
  productSettings,
  CRMCustomFields,
}) => {
  setLoading && setLoading(true);
  let action = isUpdate ? "update" : "create";
  console.log(customers, "1lksjfl");
  // //   let headerRow = products?.splice(0, 1);
  setLoadingText && setLoadingText("Starting syncing process...");
  let customersToUpdate = [];
  try {
    customers?.forEach((customer) => {
      let obj = getProductObjectFromSheetArray({
        customer,
        states,
        productSettings,
        CRMCustomFields,
      });
      // console.log(obj, "product asdkfj");
      if (isUpdate) {
        let id = getCustomerIdFromAllCustomers(allCustomersWithId, obj);
        obj.id = id;
      }
      customersToUpdate.push(obj);
    });
  } catch (error) {
    console.log(error);
    return { error: true };
  }
  console.log(customersToUpdate, "customersToUpdate");
  // return { error: true };

  let bulkObj = await addAndUpdateBulkProducts({
    bulkObj: { create: [], update: [], [action]: customersToUpdate },
    setProgress,
  });

  console.log(bulkObj, "<< bulkObj");

  // let batchSize = 100;
  // let customersLength = customers?.length;
  // let totalBatchLength = Math.round(customersLength / batchSize) || 1;
  let updatedCustomers = bulkObj?.[action];

  // let progressLength = 100;
  // let addProgressAtaTime = progressLength / totalBatchLength;

  // for await (let i of Array.from(new Array(totalBatchLength).fill(1))) {
  //   let customersUpdatingNow = customersToUpdate?.splice(0, batchSize);
  //   let data = await updateJweroBatchCustomers({
  //     payload: { [action]: customersUpdatingNow },
  //   });
  //   let customersResponse = data?.[action];

  //   customersResponse = customersResponse?.map((customer, index) => {
  //     if (customer.error) {
  //       let sku = customersUpdatingNow?.[index]?.sku;
  //       if (sku) customer.sku = sku;
  //       return customer;
  //     }

  //     return customer;
  //   });

  //   if (isArrayWithValues(customersResponse))
  //     updatedCustomers = updatedCustomers.concat(customersResponse);

  //   setProgress((state) => (state += addProgressAtaTime));
  //   setLoadingText &&
  //     setLoadingText(`Updated ${updatedCustomers?.length} customers...`);
  // }
  // console.log(totalBatchLength, updatedCustomers, "updatedCustomers");
  setLoading && setLoading(false);
  let invalidProducts = updatedCustomers.filter((i) => i.error);
  return { updatedCustomers, invalidProducts };
};

export const getProductObjectFromSheetArray = ({
  customer,
  headerRow,
  productArray,
  states,
  countries,
  productSettings,
  CRMCustomFields,
  dropdowns,
}) => {
  let customerObj = {
    // index,
    ...customer,
  };
  let dateKeys = ["user_birthday", "user_anniversary"];
  let meta_data = [];
  for (let key of dateKeys) {
    if (isValidDate(customerObj[key]))
      customerObj[key] = formatDate(new Date(customerObj[key]), "yyyy-mm-dd");
  }
  // let length = headerRow.length;
  // let whiteSpacesToRemove = [
  //   "phone",
  //   "whatsapp",
  //   "dialcode_whatsapp",
  //   "dialcode_mobile",
  //   "email",
  // ];
  // for (let i = 0; i < length; i++) {
  //   let keyObj = sheetHeaders.find((obj) => obj.label === headerRow[i]);
  //   if (keyObj) {
  //     if (whiteSpacesToRemove?.includes(keyObj.value))
  //       customerObj[keyObj.value] = `${productArray?.[i]}`
  //         .replace(/\s/g, "")
  //         .trim();
  //     else customerObj[keyObj.value] = productArray?.[i];
  //   }
  // }
  // if (customerObj.phone)
  //   customerObj.phone = `${customerObj.phone}`?.replace(/\s/g, "").trim();
  // if (customerObj.whatsapp)
  //   customerObj.whatsapp = `${customerObj.whatsapp}`
  //     ?.replace(/\s/g, "")
  //     .trim();
  // if (customerObj.dialcode_whatsapp)
  //   customerObj.dialcode_whatsapp = `${customerObj.dialcode_whatsapp}`
  // if (customerObj.dial)
  //   customerObj.dial = `${customerObj.dial}`
  //     ?.replace(/\s/g, "")
  //     .trim();
  if (customerObj?.phone) {
    meta_data.push({
      key: "digits_phone_no",
      value: customerObj?.phone,
    });
  }
  if (customerObj?.dialcode_mobile) {
    meta_data.push({
      key: "digt_countrycode",
      value: `+${customerObj?.dialcode_mobile}`,
    });
  }
  if (customerObj?.dialcode_mobile && customerObj?.phone) {
    meta_data.push({
      key: "digits_phone",
      value: `+${customerObj?.dialcode_mobile}${customerObj?.phone}`,
    });
  }
  let storeId = getStoreId();
  let meta_keys = [
    "dialcode_mobile",
    "phone",
    "dialcode_whatsapp",
    "whatsapp",
    `group_name_${storeId}`,
    "address_1",
    "address_2",
    "city",
    "postcode",
    "state",
    "country",
    "customer_since",
    "first_purchase",
    "total_purchase",
    "visits_in_showroom",
    "last_visit_in_showrrom",
    "visits_on_website",
    "last_visit_on_website",
    "user_birthday",
    "user_anniversary",
    "profession",
    "annual_income",
    "ethincity",
    "language",
    "religion",
    "Sub-Sect",
    "customer_facebook",
    "customer_instagram",
    "customer_twitter",
    "customer_linkedin",
    "customer_youtube",
    "customer_pinterest",
    "branch_from",
    "facebook_id",
    "whatsapp_id",
    "has_call_logs",
    "gender",
    "contact_type",
  ];
  if (isArrayWithValues(CRMCustomFields)) {
    let temp = CRMCustomFields?.map((i) => i?.value);
    meta_keys = meta_keys?.concat(temp);
  }

  let billingKeys = [
    "first_name",
    "last_name",
    "company",
    "address_1",
    "address_2",
    "city",
    "postcode",
    "country",
    "state",
    "email",
    "phone",
  ];
  let showInObject = ["first_name", "last_name", "email", "phone"];

  let countryCode = countries?.find(
    (i) => i?.label?.toLowerCase() === customer?.country?.toLowerCase()
  )?.code;

  let stateObj = states?.[customer?.country]?.find(
    (i) => i.label?.toLowerCase() === customer?.state?.toLowerCase()
  );

  if (!stateObj)
    stateObj = states?.[countryCode]?.find(
      (i) => i.label?.toLowerCase() === customer?.state?.toLowerCase()
    );

  if (customer?.state && stateObj) customerObj.state = stateObj.code;
  if (countryCode) customerObj.country = countryCode;

  let billing = {};
  for (let i of billingKeys) {
    if (customerObj.hasOwnProperty(i)) billing[i] = customerObj[i];
    if (!showInObject.includes(i)) delete customerObj[i];
  }
  if (customerObj[`group_name_${storeId}`]) {
    let assignedTags =
      typeof customerObj[`group_name_${storeId}`] === "string"
        ? customerObj[`group_name_${storeId}`].split(",")
        : isArrayWithValues(customerObj[`group_name_${storeId}`])
        ? customerObj[`group_name_${storeId}`]
        : [];
    let tagsList = dropdowns?.[`group_name_${storeId}`];
    console.log(tagsList, dropdowns);
    customerObj[`group_name_${storeId}`] = assignedTags?.map((i) => {
      let obj = tagsList?.find((j) => {
        console.log(
          unformatServerValue(j.value?.trim()),
          unformatServerValue(i?.trim())
        );
        return (
          unformatServerValue(j.value?.trim()) ===
          unformatServerValue(i?.trim())
        );
      });
      console.log(obj, "<< obj");
      if (obj) return obj.value;
      return i;
    });
  }

  for (let i in meta_keys)
    if (customerObj.hasOwnProperty([meta_keys[i]])) {
      meta_data.push({
        key: meta_keys[i],
        value: customerObj[meta_keys[i]] || "",
      });
      delete customerObj[meta_keys[i]];
    }
  customerObj.meta_data = meta_data;
  customerObj.billing = billing;
  if (!customerObj.email || !customerObj?.billing?.email) {
    delete customerObj?.email;
    delete customerObj?.billing?.email;
  }

  return customerObj;
};

const addAndUpdateBulkProducts = async ({
  bulkObj = {},
  setSyncLoading,
  sheetErrors,
  setProgress,
}) => {
  // const WooCommerce = await getWooCommerce();
  const website = getWebsite();
  const token = getToken();
  let length = 0;
  let createIds = [];
  let batchLength = 0;
  let batchLengthFloat = 0;
  let batchSize = 100;
  if (Array.isArray(bulkObj.update) || Array.isArray(bulkObj.create)) {
    length += validateNumber(bulkObj?.update?.length);
    length += validateNumber(bulkObj?.create?.length);
  }
  if (length) {
    batchLength = parseInt(length / batchSize);
    batchLengthFloat = parseFloat(length / batchSize);
  } else return bulkObj;
  // console.log(length);
  // return;

  if (length / batchSize > batchLength) batchLength += 1;

  let currentBatch = 1;
  let tempBulkObj = { ...bulkObj };

  let responseObj = {
    create: [],
    update: [],
  };

  let flag = 0;
  let progressLength = 100;
  let addProgressAtaTime = progressLength / batchLength;

  // creating ids for create customers
  if (isArrayWithValues(tempBulkObj?.create)) {
    let batchSize = 500,
      totalSize = tempBulkObj?.create?.length;
    let allCreateArray = [...tempBulkObj?.create];
    let totalRounds = Math.ceil(totalSize / batchSize);
    for await (let _i of Array.from(new Array(totalRounds).fill(1))) {
      let currentArray = allCreateArray?.splice(0, batchSize);
      let data = await createCustomCustomer(
        currentArray?.map((obj) => {
          let tempObj = { ...obj };
          if (isArrayWithValues(obj.meta_data)) {
            for (let metaObj of obj.meta_data)
              tempObj[metaObj.key] = metaObj.value;
          }
          return {
            value:
              obj?.email ||
              obj?.billing?.email ||
              obj?.billing?.phone ||
              tempObj?.whatsapp,
          };
        }),
        true
      );
      if (isObjWithValues(data?.data)) {
        let _data = Object.values(data?.data);
        createIds = createIds.concat(_data.map((i) => i.id));
        currentArray = _data?.map((i) => {
          let { id, value } = i || {};
          let customer = currentArray.find((i) => {
            let tempObj = { ...i };
            if (isArrayWithValues(i.meta_data)) {
              for (let metaObj of i.meta_data)
                tempObj[metaObj.key] = metaObj.value;
            }
            return (
              i.email === value ||
              i.billing?.email === value ||
              i?.billing?.phone === value ||
              tempObj?.whatsapp === value
            );
          });
          if (customer) return { ...customer, id };
          return i;
        });
        if (isArray(tempBulkObj.update))
          tempBulkObj.update = tempBulkObj.update?.concat(currentArray);
      }
    }
    tempBulkObj.create = [];
  }

  for await (let i of Array(batchLength)) {
    flag++;
    let tempBatchObj = {
      create: [],
      update: [],
    };
    if (tempBulkObj?.create?.length >= batchSize) {
      let { create } = tempBulkObj;
      tempBatchObj.create = create.splice(0, batchSize);
      tempBulkObj.create = [...create];
    } else if (
      tempBulkObj?.create?.length < batchSize
      // &&tempBulkObj?.create?.length > 0
    ) {
      tempBatchObj.create = [...tempBulkObj.create];
      if (tempBulkObj?.update?.length >= batchSize) {
        let { update } = tempBulkObj;
        tempBatchObj.update = update.splice(
          0,
          batchSize - validateNumber(tempBatchObj?.create?.length)
        );
        tempBulkObj.update = [...update];
      } else if (
        tempBulkObj?.update?.length < batchSize &&
        tempBulkObj?.update?.length > 0
      ) {
        let createLength = validateNumber(tempBatchObj?.create?.length);
        if (tempBulkObj?.update?.length > batchSize - createLength) {
          let { update } = tempBulkObj;
          tempBatchObj.update = update.splice(0, batchSize - createLength);
          tempBulkObj.update = [...update];
        } else {
          let { update } = tempBulkObj;
          tempBatchObj.update = update;
          tempBulkObj.update = [];
        }
      }
    }

    // try {
    //   if (isArrayWithValues(tempBatchObj.create)) {
    //     let updatedCustomers = [];
    //     for await (let customer of tempBatchObj.create) {
    //       let obj = { ...customer };
    //       let tempObj = {};
    //       let { billing, meta_data } = customer;
    //       let metaKeys = ["dialcode_mobile", "dialcode_whatsapp", "whatsapp"];
    //       if (isArrayWithValues(meta_data)) {
    //         for (let metaObj of meta_data)
    //           if (metaKeys.includes(metaObj.key))
    //             tempObj[metaObj.key] = metaObj.value;

    //         let data = await createCustomCustomer({
    //           value:
    //             obj?.email ||
    //             obj?.billing?.email ||
    //             obj?.billing?.phone ||
    //             tempObj?.whatsapp,
    //         });
    //         if (data.success) {
    //           createIds.push(data.data);
    //           updatedCustomers.push({ id: data.data, ...customer });
    //         }
    //       }
    //     }
    //     tempBatchObj.create = [];
    //     tempBatchObj.update = tempBatchObj.update.concat(updatedCustomers);
    //   }
    // } catch (error) {}
    console.log(tempBatchObj, "BATCH OBJ");

    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers/batch`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: { ...tempBatchObj },
      });
      // const res = await WooCommerce.post("products/batch", bulkObj);
      let { data } = res;
      // console.log(data.create, tempBatchObj.create);
      if (
        isArrayWithValues(data.create) &&
        isArrayWithValues(tempBatchObj.create)
      ) {
        let index = 0;
        let createArray = [];
        for (let productRes of data.create) {
          let productObj = tempBatchObj.create[index];
          createArray.push({
            ...productRes,
            index: productObj.index,
            error: productRes.error,
          });
          // if (isObjWithValues(productRes.error)) {
          //   let { message } = productRes.error;
          //   console.log(message, "ERROR MEsSAGE");
          //   sheetErrors = sheetErrors[productObj.index]
          //     ? {
          //         ...sheetErrors,
          //         [productObj.index]: {
          //           ...sheetErrors[productObj.index],
          //           reasons: [
          //             ...(sheetErrors[productObj.index].reasons || []),
          //             message,
          //           ],
          //         },
          //       }
          //     : {
          //         ...sheetErrors,
          //         [productObj.index]: {
          //           sku: productObj.sku,
          //           index: productObj.index,
          //           reasons: [message],
          //         },
          //       };
          //   // setSheetErrors((state) =>

          //   // );
          // }
          index++;
        }
        // responseObj.create = createArray.concat(data.create);
        // responseObj.create = responseObj.create.concat(createArray);
      }
      if (
        isArrayWithValues(data.update) &&
        isArrayWithValues(tempBatchObj.update)
      ) {
        let updateArray = [];
        let createArray = [];
        let index = 0;
        for (let productRes of data.update) {
          let productObj = tempBatchObj.update[index];
          if (createIds.includes(productObj.id))
            createArray.push({
              ...productRes,
              index: productObj.index,
              error: productRes.error,
            });
          else
            updateArray.push({
              ...productRes,
              index: productObj.index,
              error: productRes.error,
            });
          index++;
        }
        // responseObj.update = updateArray.concat(data.update);
        responseObj.create = responseObj.create.concat(createArray);
        responseObj.update = responseObj.update.concat(updateArray);
      }
      // return res.data;
      console.log(data, `BATCH ${flag} is Updated`);
    } catch (error) {
      console.log(getAxiosError(error), "BATCH UPDATE ERROR");
    }
    await new Promise((r) => setTimeout(r, 2000));

    if (batchLength === flag) {
      console.log(responseObj, "RESPONSE OBJ");
      // setSyncLoading(90);
      return responseObj;
    }
    setProgress && setProgress((state) => (state += addProgressAtaTime));
    // else
    //   setSyncLoading((state) =>
    //     state + 60 / batchLengthFloat >= 90 ? 90 : state + 60 / batchLengthFloat
    //   );
  }
  return {};
};

export { updateCustomersFromSheet };
