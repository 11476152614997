export function checkIflast({ nodes, edges, nodeId }) {
  const matchingEdges = edges.find((edge) => edge.source === nodeId);

  function notUserNext(matchingEdges) {
    const id = matchingEdges?.target;
    const node = nodes.find((node) => node.id === id);
    if (node?.type === "user" || node?.type === "user-attachment") {
      return true;
    }
    return false;
  }
  if (matchingEdges) {
    if (notUserNext(matchingEdges)) {
      return false;
    }
    return true;
  }
  return false;
}
