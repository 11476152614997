import { Add } from "@material-ui/icons";
import {
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  KeyboardReturn,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isObjWithValues,
  isArrayWithValues,
  fetchProducts,
  formatServerValue,
  fetchCustomers,
} from "../../helper";

import { getDiamondQuality } from "../products/AddProduct";

function SelectCustomer({
  onClose,
  onSelect,
  createButton,
  createOnClick,
  heading,
}) {
  const [allCustomer, setAllCustomer] = useState([]);
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  // const [productSettings, setProductSettings] = useState({});
  // const [allCollections, setAllCollections] = useState([]);
  // const [allCategories, setAllCategories] = useState([]);
  // const [allGoldPurities, setAllGoldPurities] = useState([]);
  // const [allDiamondQualities, setAllDiamondQualities] = useState([]);
  // const [allDiamondLabs, setAllDiamondLabs] = useState([]);
  // const [products, setProducts] = useState([]);
  const [defaultParams, setDefaultParams] = useState({
    per_page: 20,
    page: 1,
  });
  const [search, setSearch] = useState("");
  const [params, setParams] = useState(defaultParams);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  // const [filtersToShow, setFiltersToShow] = useState([]);
  const [productLoading, setProductsLoading] = useState(false);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );

  useEffect(() => {
    fetchCustomers(defaultParams, setAllCustomer, setFetchingCustomers);
  }, []);
  // useEffect(() => {
  //     if (isArrayWithValues(allReduxCustomers)) { setAllCustomer(allReduxCustomers) }
  // }, [allReduxCustomers])

  // const reduxTaxonomies = useSelector((state) => state.product.taxonomies);
  // const [selectedFilters, setSelectedFilters] = useState({
  //   collections: [],
  //   categories: [],
  //   gold_kt: [],
  //   diamond: [],
  //   diamond_lab: [],
  // });
  const scrollRef = useRef();

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   // if (!isObjWithValues(reduxProductSettings)) dispatch(getProductSettings());
  //   // if (!isObjWithValues(masterPricing)) dispatch(getMasterPricings());
  //   // if (!isObjWithValues(reduxTaxonomies)) dispatch(fetchTaxonomies());
  //   // if (!isArrayWithValues(products))
  //   //     fetchProducts(params, setProducts, setProductsLoading);
  //   // if()
  // }, []);

  // useEffect(() => {
  //   if (isObjWithValues(reduxProductSettings)) {
  //     setProductSettings({ ...reduxProductSettings });

  //     if (isArrayWithValues(reduxProductSettings.collections))
  //       setAllCollections([...reduxProductSettings.collections]);

  //     if (isArrayWithValues(reduxProductSettings.categories))
  //       setAllCategories([...reduxProductSettings.categories]);

  //     if (isArrayWithValues(reduxProductSettings.gold_purities))
  //       setAllGoldPurities([...reduxProductSettings.gold_purities]);
  //   }
  // }, [reduxProductSettings]);

  // useEffect(() => {
  //   if (
  //     isObjWithValues(masterPricing) &&
  //     isObjWithValues(masterPricing.diamond_pricing) &&
  //     productSettings &&
  //     productSettings.default_currency
  //   ) {
  //     let diamondQualities = getDiamondQuality(
  //       masterPricing.diamond_pricing[productSettings.default_currency]
  //     );
  //     if (diamondQualities) setAllDiamondQualities(diamondQualities);
  //   }
  //   if (
  //     isObjWithValues(productSettings) &&
  //     isArrayWithValues(productSettings.diamond_labs)
  //   )
  //     setAllDiamondLabs(productSettings.diamond_labs);
  // }, [masterPricing, productSettings]);

  // useEffect(() => {
  //   onFiltersChange();
  // }, [selectedFilters]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (e, f) => {
    const scrollTop = scrollRef.current.scrollTop;
    const totalHeight = scrollRef.current.scrollHeight;
    const height = scrollRef.current.offsetHeight;
    if (totalHeight - height - scrollTop < 10 && !productLoading) {
      setParams((state) => ({ ...state, page: state.page + 1 }));
      // dispatch(fetchCustomers({ ...params, order: "desc" }))
      fetchCustomers(
        { ...params, page: params.page + 1 },
        (allCustomer) => setAllCustomer((state) => state.concat(allCustomer)),
        setLoadingCustomer
      );
    }
    // if (
    //   window.innerHeight + document.documentElement.scrollTop !==
    //   document.documentElement.offsetHeight
    // )
    //   return;
    // console.log("Fetch more list items!");
  };

  // const onFilterValuePress = (key, value) =>
  //   setSelectedFilters((state) => ({ ...state, [key]: value }));

  // const onFiltersChange = () => {
  //   let filters = { ...selectedFilters };
  //   let taxonomies = { ...reduxTaxonomies };
  //   let filtersToShow = [];
  //   for (let key in filters)
  //     if (isArrayWithValues(filters[key]))
  //       for (let label of filters[key]) {
  //         if (key === "gold_kt") {
  //           let obj = allGoldPurities.find((i) => i.value === label);
  //           filtersToShow.push({ key, label, _label: obj.label });
  //         } else filtersToShow.push({ key, label });
  //       }
  //   setFiltersToShow(filtersToShow);

  //   let updatedParams = { ...params, page: 1 };
  //   let impParams = ["per_page", "page", "serach", "sku"];
  //   for (let i in updatedParams)
  //     if (!impParams.includes(i)) delete updatedParams[i];

  //   let customFilterFlag = 0;
  //   for (let key in filters)
  //     if (isArrayWithValues(filters[key]))
  //       for (let value of filters[key]) {
  //         if (key === "diamond_lab" || key === "diamond" || key === "gold_kt") {
  //           updatedParams[`custom_filter[${customFilterFlag}][key]`] = key;
  //           updatedParams[`custom_filter[${customFilterFlag}][value]`] = value;
  //           updatedParams[`custom_filter[${customFilterFlag}][compare]`] =
  //             "LIKE";
  //           customFilterFlag++;
  //         } else {
  //           if (isArrayWithValues(taxonomies[key])) {
  //             let obj = taxonomies[key].find(
  //               (i) => i.slug === value || i.value === value
  //             );
  //             if (obj && obj.id)
  //               updatedParams[key] = updatedParams[key]
  //                 ? `${updatedParams[key]},${obj.id}`
  //                 : `${obj.id}`;
  //           }
  //         }
  //       }

  //   setParams(updatedParams);
  //   fetchProducts(updatedParams, setProducts, setProductsLoading);
  // };

  // const deleteFilter = (obj) =>
  //   setSelectedFilters((state) => ({
  //     ...state,
  //     [obj.key]: state[obj.key].filter((i) => i !== obj.label),
  //   }));

  // const onProductClick = (product) => {
  //   if (selectedProducts.find((i) => i.id === product.id))
  //     setSelectedProducts((state) => state.filter((i) => i.id !== product.id));
  //   else setSelectedProducts((state) => [...state, { ...product }]);
  // };

  const onSearch = () => {
    setParams((state) => ({ ...state, search, page: 1 }));
    fetchCustomers({ ...params, search }, setAllCustomer, setFetchingCustomers);
  };
  const onSearchChange = (e) => {
    if (search.length > e.target.value.length && e.target.value.length === 0)
      fetchCustomers(
        { ...params, search: "" },
        setAllCustomer,
        setLoadingCustomer
      );

    setSearch(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <Box sx={{ p: 1, overflow: "hidden", position: "relative" }}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Typography variant="h4">
          {heading ? heading : "Select Customer"}
        </Typography>

        {createButton ? (
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={createOnClick}
          >
            Create{" "}
          </Button>
        ) : (
          <IconButton onClick={() => onClose && onClose()}>
            <Close />
          </IconButton>
        )}
      </Stack>
      {/* <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        sx={{
          position: "absolute",
          bottom: 20,
          // left: 10,
          right: 20,
          zIndex: 10,
        }}
        onClick={() => onSelect && onSelect(selectedProducts)}
      >
        <Check />
      </Fab> */}

      <TextField
        value={search}
        onChange={onSearchChange}
        sx={{ mb: 2, mt: 3 }}
        variant="outlined"
        fullWidth
        size="small"
        type="search"
        placeholder="Search Customer"
        InputProps={{
          sx: { borderRadius: "10px" },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <KeyboardReturn size="18px" />
            </InputAdornment>
          ),
        }}
        onKeyDown={onKeyDown}
        // onSubmit={() => console.log("submit")}
      />

      {/* <Stack
                direction="row"
                mt={2}
                alignItems={"center"}
                sx={{ overflowX: "scroll" }}
                className="scrollbar-hidden"
            >
                <FilterDropdown
                    data={allCollections}
                    value={selectedFilters.collections}
                    label="Collections"
                    minWidth="100px"
                    onChange={(e) => onFilterValuePress("collections", e.target.value)}
                />
                <FilterDropdown
                    data={allCategories}
                    value={selectedFilters.categories}
                    onChange={(e) => onFilterValuePress("categories", e.target.value)}
                    label="Categories"
                    minWidth="100px"
                />
                <FilterDropdown
                    data={allGoldPurities}
                    value={selectedFilters.gold_kt}
                    onChange={(e) => onFilterValuePress("gold_kt", e.target.value)}
                    label="Gold Purity"
                    minWidth="100px"
                />
                <FilterDropdown
                    data={allDiamondQualities}
                    value={selectedFilters.diamond}
                    onChange={(e) => onFilterValuePress("diamond", e.target.value)}
                    label="Diamond Purity"
                    minWidth="100px"
                />
                <FilterDropdown
                    data={allDiamondLabs}
                    value={selectedFilters.diamond_lab}
                    onChange={(e) => onFilterValuePress("diamond_lab", e.target.value)}
                    label="Diamond Labs"
                    minWidth="100px"
                />
            </Stack> */}

      {/* <Stack
                direction="row"
                my={2}
                alignItems={"center"}
                className="scrollbar-hidden"
                sx={{ overflowX: "scroll" }}
                spacing={2}
            >
                {filtersToShow.map((i) => {
                    return (
                        <Chip
                            label={i._label || formatServerValue(i.label) || i.label}
                            onDelete={() => deleteFilter(i)}
                        />
                    );
                })}
            </Stack> */}

      <Box
        sx={{
          overflowY: "scroll",
          height: "100vh",
          position: "relative",
          pb: 6,
        }}
        className="scrollbar-hidden"
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {fetchingCustomers && (
          <Box
            sx={{
              top: "30%",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ textAlign: "center" }} />
          </Box>
        )}

        {allCustomer.map((i) => {
          let { first_name, last_name, email, id, avatar_url } = i;

          return (
            <Stack
              direction="row"
              py={3}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedCustomer(i);
                onSelect && onSelect(i);
              }}
            >
              {selectedCustomer?.id === id ? (
                <CheckBox style={{ height: "22px", width: "22px" }} />
              ) : (
                <CheckBoxOutlineBlank
                  style={{ height: "22px", width: "22px" }}
                />
              )}
              <Avatar
                src={avatar_url}
                sx={{
                  height: "50px",
                  width: "50px",
                  margin: "0px 8px",
                }}
              />
              {/* <img
                                src={image}
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50%",
                                    margin: "0px 8px",
                                }}
                                onError={() => (image = "")}
                            /> */}
              <Box>
                <Typography variant="h6" fontSize="14px">
                  {`${first_name || ""} ${last_name || ""}`}
                </Typography>
                <Typography mt={0.5} color="text.secondary">
                  {email || ""}
                </Typography>
              </Box>
            </Stack>
          );
        })}

        {loadingCustomer && isArrayWithValues(allCustomer) ? (
          <Typography mt={3} sx={{ textAlign: "center" }} alignItems="center">
            <CircularProgress
              style={{
                height: "13px",
                width: "13px",
                marginRight: "7px",
                marginTop: "3px",
              }}
            />
            Fetching More Customer...
          </Typography>
        ) : (
          <Box sx={{ height: "50px" }} />
        )}
        <Box sx={{ height: "240px" }} />
      </Box>
    </Box>
  );
}

export default SelectCustomer;

const FilterDropdown = ({ data, label, value, onChange, minWidth }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <FormControl
      variant="standard"
      sx={{
        "&:hover": {
          border: "0px solid transparent",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: "10px",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        minWidth: minWidth || "120px",
      }}
      size="small"
    >
      <Select
        disableUnderline
        id="demo-multiple-checkbox"
        multiple
        open={open}
        // input={<OutlinedInput label={label} />}
        renderValue={(selected) => label}
        value={value}
        onChange={(e) => {
          handleClose();
          onChange(e);
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ backgroundColor: "transparent" }}
        SelectDisplayProps={{ style: { backgroundColor: "transparent" } }}
        sx={{
          margin: "4px 6px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
        size="small"
      >
        {isArrayWithValues(data) &&
          data.length > 0 &&
          data.map((obj) => {
            return (
              <MenuItem
                key={obj.id}
                value={obj.value}
                sx={{
                  borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                }}
              >
                <Checkbox checked={value.indexOf(obj.value) > -1} />
                <ListItemText primary={obj.label} />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
