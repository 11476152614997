import { isArrayWithValues } from "jwero-javascript-utils";
import { backendApiCall } from "../../helper";
import axios from "axios";

export const SET_TITLE = "SET_TITLE";
export const SET_NODES = "SET_NODES";
export const ADD_NODE = "ADD_NODE";
export const DELETE_NODE = "DELETE_NODE";
export const UPDATE_NODE = "UPDATE_NODE";
export const UPDATE_EDGES = "UPDATE_EDGES";
export const REMOVE_AND_UPDATE_EDGE = "REMOVE_AND_UPDATE_EDGE";
export const ADD_NODE_IN_BETWEEN = "ADD_NODE_IN_BETWEEN";
export const FETCHING_NODES = "FETCHING_NODES";
export const ONCHANGE_NODES = "ONCHANGE_NODES";
export const SET_EDGES = "SET_EDGES";
export const ADD_EDGE = "ADD_EDGE";
export const FETCHING_EDGES = "FETCHING_EDGES";
export const ONCHANGE_EDGES = "ONCHANGE_EDGES";
export const FIRST_NODE_ID = "FIRST_NODE_ID";
export const OPEN_SIDEBAR_USER = "OPEN_SIDEBAR_USER";
export const CLOSE_SIDEBAR_USER = "CLOSE_SIDEBAR_USER";
export const OPEN_SIDEBAR_BOT = "OPEN_SIDEBAR_BOT";
export const CLOSE_SIDEBAR_BOT = "CLOSE_SIDEBAR_BOT";
export const SIDEBAR_USER_ATTACHMENT = "SIDEBAR_USER_ATTACHMENT";
export const SIDEBAR_QUESTION = "SIDEBAR_QUESTION";
export const SIDEBAR_FILTER = "SIDEBAR_FILTER";
export const SIDEBAR_WHATSAPP_TEMPLATE = "SIDEBAR_WHATSAPP_TEMPLATE";
export const SIDEBAR_GO_TO_STEP = "SIDEBAR_GO_TO_STEP";
export const ADD_USER_COUNT = "ADD_USER_COUNT";
export const RESET_USER_COUNT = "RESET_USER_COUNT";
export const UPDATE_USER_CONNECTED = "UPDATE_USER_CONNECTED";
export const CURRENT_NODE_ID = "CURRENT_NODE_ID";
export const UPDATE_NODE_DATA = "UPDATE_NODE_DATA";
export const TEST_BOT_ADD_MESSAGES = "TEST_BOT_ADD_MESSAGES";
export const TEST_BOT_TYPING_INDICATOR = "TEST_BOT_TYPING_INDICATOR";
export const RESET_NODE_ID = "RESET_NODE_ID";
export const RESET_TEST_BOT_MESSAGES = "RESET_TEST_BOT_MESSAGES";
export const SET_NODES_EDGES_EMPTY = "SET_NODES_EDGES_EMPTY";
export const SET_DIALOG_BOX = "SET_DIALOG_BOX";
export const SET_MODE = "SET_MODE";
export const EDIT_ELEMENT_IN_REDUX = "EDIT_ELEMENT_IN_REDUX";
export const DELETE_WITH_CHILDREN = "DELETE_WITH_CHILDREN";

export function editElementInRedux(payload) {
  return { type: EDIT_ELEMENT_IN_REDUX, payload };
}

// export const editElementInRedux = (payload) => {
//   return { type: EDIT_ELEMENT_IN_REDUX, payload };
// };

export const getFlows = () => {
  return async (dispatch) => {
    let addPredfined = false;
    try {
      dispatch(editElementInRedux({ flowsListLoading: true }));

      let res = await backendApiCall({
        endpoint: "flow",
        params: { per_page: 100 },
      });
      if (res.success) {
        dispatch(editElementInRedux({ flowsList: res?.data }));
      }
      addPredfined = !isArrayWithValues(res?.data);
    } catch (error) {
    } finally {
      dispatch(editElementInRedux({ flowsListLoading: false }));
      if (addPredfined) {
        let { data } = await axios({
          url: "https://tiarabytj.com/wp-json/store/v1/settings/predefined_workflows",
          headers: {
            apikey: "eca2c452-753f-4d18-ad23-c76ceec5f3a6",
          },
        });
        if (isArrayWithValues(data?.data))
          dispatch(editElementInRedux({ flowsList: data?.data }));
      }
    }
  };
};

export const updateFlowInRedux = ({ flow }) => {
  return (dispatch, getState) => {
    let state = getState();
    let flowsList = state?.flow?.flowsList || [];
    flowsList = flowsList?.map((i) => {
      if (i?.id === flow?.id) return { ...i, ...flow };
      return i;
    });
    dispatch(editElementInRedux({ flowsList }));
  };
};
export const deleteFlowInRedux = ({ flow_id }) => {
  return (dispatch, getState) => {
    let state = getState();
    let flowsList = state?.flow?.flowsList || [];
    flowsList = flowsList?.filter((i) => {
      return i?.id !== flow_id;
    });
    dispatch(editElementInRedux({ flowsList }));
  };
};
